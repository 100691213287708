import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import WarehouseService from '../../shared/services/WarehouseService';
import {Company} from '../../shared/CommonInterfaces';
import EdiFiles from './EdiFiles';
import EdiFileDetail from './EdiFileDetail';
import EdiFilesService from './EdiFilesService';
import EdiFilesServiceV2 from './EdiFilesServiceV2';
import EdiFileDetailContent from './EdiFileDetailContent';

interface AppProps {
  authenticityToken: string;
  currentCompany: Company;
}

function EdiFilesApp(props: AppProps) {
  const ediFilesService = new EdiFilesService(props.authenticityToken);
  const warehouseService = new WarehouseService(props.authenticityToken);
  const v2EdiFilesService = new EdiFilesServiceV2();
  const adminSignedIn = window.localStorage.getItem('adminSignedIn') === 'true';

  return (
    <Router>
      <Switch>
        <Route
          path="/s/edi"
          exact
          render={(routeProps) => (
            <EdiFiles
              {...routeProps}
              {...props}
              ediFilesService={ediFilesService}
              v2EdiFilesService={v2EdiFilesService}
            />
          )}
        />
        <Route
          path="/s/edi/:id"
          exact
          render={(routeProps) => (
            <EdiFileDetail
              {...routeProps}
              {...props}
              ediFilesService={ediFilesService}
              warehouseService={warehouseService}
              adminSignedIn={adminSignedIn}
            />
          )}
        />
        <Route
          path="/s/edi/:id/contents"
          exact
          render={(routeProps) => <EdiFileDetailContent {...routeProps} {...props} />}
        />
      </Switch>
    </Router>
  );
}

export default EdiFilesApp;
