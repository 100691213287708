import {ApiResponse} from '../CommonInterfaces';
import {
  FilterOptionsForWarehouseResponse,
  GetShipmentsParams,
  RemoveLpnFromShipmentRequest,
  RemoveLpnFromShipmentResponse,
  ReplaceLpnsOnShipmentRequest,
  ReplaceLpnsOnShipmentResponse,
  Shipment,
  ShipmentDetailsInterface,
  ShipmentOverageResponse,
  ShipmentResponse,
  UpdateShipmentRequest
} from '../../warehouse/outbound-shipments/ShipmentInterfaces';
import InternalAPIService from './InternalAPIService';

class OutboundShipmentService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '');
  }

  public async getShipmentsDeprecated(params: GetShipmentsParams): Promise<ApiResponse<ShipmentResponse>> {
    return await this.makeGetRequest('/api/v2/outbound/shipments', params);
  }

  public async shipmentDetailsDeprecated(shipmentId: number): Promise<ApiResponse<ShipmentDetailsInterface>> {
    return await this.makeGetRequest(`/api/v2/outbound/shipments/${shipmentId}`);
  }

  public async updateShipment(request: UpdateShipmentRequest): Promise<ApiResponse<Shipment>> {
    return await this.makePatchRequest(`/api/v2/outbound/shipments/${request.shipmentId}`, {
      num_pallets_built: request.numPalletsBuilt,
      notes: request.notes
    });
  }

  public async ship(shipmentId: number): Promise<ApiResponse<Shipment>> {
    return await this.makePostRequest(`/api/v2/outbound/shipments/${shipmentId}/ship`, {});
  }

  public async removeLpnFromShipment(
    request: RemoveLpnFromShipmentRequest
  ): Promise<ApiResponse<RemoveLpnFromShipmentResponse>> {
    return await this.makeDeleteRequest(
      `/api/v2/outbound/shipments/${request.shipmentId}/lpns?lpn_barcode=${request.lpnBarcode}`
    );
  }

  public async replaceLpnsOnShipment(
    request: ReplaceLpnsOnShipmentRequest
  ): Promise<ApiResponse<ReplaceLpnsOnShipmentResponse>> {
    return await this.makePutRequest(`/api/v2/outbound/shipments/${request.shipmentId}/lpns`, {
      lpn_id_to_remove: request.lpnIdToRemove,
      lpn_id_to_add: request.lpnIdToAdd
    });
  }

  public async calculateOverages(shipmentId: number): Promise<ApiResponse<ShipmentOverageResponse>> {
    return await this.makeGetRequest(`/api/v2/outbound/shipments/${shipmentId}/overages`);
  }

  public async filterOptionsForWarehouse(warehouseId: number): Promise<ApiResponse<FilterOptionsForWarehouseResponse>> {
    return await this.makeGetRequest(`/api/v2/warehouse_shipments/filter_options?warehouseId=${warehouseId}`);
  }
}

export default OutboundShipmentService;
