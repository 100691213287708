import * as React from 'react';
import {FC} from 'react';
import {OrdersViewType} from '../OutboundOrdersInterfaces';

interface Props {
  viewType: OrdersViewType;
  setViewType: (newViewType: OrdersViewType) => void;
}

const OrdersViewTypeButton: FC<Props> = (props) => {
  return (
    <div className="btn-group view-toggle">
      <a
        id="orders-toggle"
        className={`btn ${props.viewType === OrdersViewType.orders ? 'cta' : 'secondary'}`}
        onClick={() => props.setViewType(OrdersViewType.orders)}
      >
        <i className="fa fa-shopping-cart" />
        &nbsp; Orders
      </a>
      <a
        id="lines-toggle"
        className={`btn ${props.viewType === OrdersViewType.lines ? 'cta' : 'secondary'}`}
        onClick={() => props.setViewType(OrdersViewType.lines)}
      >
        <i className="fa fa-list-ul" />
        &nbsp; Lines
      </a>
    </div>
  );
};
export default OrdersViewTypeButton;
