import {LegacyModal} from '@flexe/ui-components';
import * as React from 'react';
import {Wave} from '../WaveInterfaces';
import {PickWaveStatus} from '../WaveBatchInterfaces';
import {ErrorCard} from '../../outbound-shipments/shared/ErrorCard';

type DisableCancelReason = 'lpns_associated';
interface Props {
  toggleModal: () => void;
  show: boolean;
  wave: Wave;
  onClickConfirm: () => void;
  isFreight?: boolean;
  disableCancelReason?: DisableCancelReason;
}

const CancelWaveModal: React.FC<Props> = ({
  toggleModal,
  show,
  wave,
  onClickConfirm,
  isFreight = false,
  disableCancelReason
}) => {
  const renderBodyText = () => {
    if (isFreight) {
      if (wave.status === PickWaveStatus.not_started) {
        return (
          <>
            <p data-testid="freight_notstarted_message">Are you sure you want to cancel this Wave?</p>
          </>
        );
      } else {
        return (
          <>
            <p data-testid="freight_started_message">
              Canceling this Wave will leave any picked units in their current location. Picked goods will need to be
              moved back to inventory.
            </p>
            <p>Unshipped Shipments will return to a status of "Not Started."</p>
          </>
        );
      }
    } else {
      return (
        <>
          <p data-testid="nonfreight_message">
            Canceling this Wave will undo any work against it and leave any picked, but unpacked, units in their current
            location. Picked goods will need to be moved back to inventory.
          </p>

          <p>Unshipped Shipments will return to a status of "Not Started."</p>
        </>
      );
    }
  };

  return (
    <LegacyModal
      id="confirm_terminate_wave_action_modal"
      toggleModal={toggleModal}
      size="small"
      show={show}
      title={'Cancel Wave ' + wave.id}
      footer={
        <div className="action-buttons">
          <button className="btn" onClick={toggleModal}>
            Close
          </button>
          <button className="btn disable-all" disabled={!!disableCancelReason} onClick={onClickConfirm}>
            Confirm
          </button>
        </div>
      }
    >
      <div className="modal-contents">
        <h3></h3>
        {renderBodyText()}
        {disableCancelReason === 'lpns_associated' && (
          <ErrorCard>Picked LPNs must be removed from the Shipment before this Wave can be canceled.</ErrorCard>
        )}
      </div>
    </LegacyModal>
  );
};

export default CancelWaveModal;
