import {LocationCategory, LocationCategoryEditableCategories} from './LocationsInterfaces';

//use this method to get a list of all location categories
export class LocationsHelper {
  public static FilterLocationCategories(isCrossdockEnabled: boolean) {
    return Object.keys(LocationCategory).filter((key) => {
      switch (key) {
        case LocationCategory.crossdock.toLowerCase():
          return isCrossdockEnabled;
        default:
          return true;
      }
    });
  }

  //use this method to get a list of all location categories except for sys reserved (lost, virtual, trans, mhe)
  public static FilterLocationCategoriesEditableCategories(isCrossdockEnabled: boolean) {
    return Object.keys(LocationCategoryEditableCategories).filter((key) => {
      switch (key) {
        case LocationCategoryEditableCategories.crossdock.toLowerCase():
          return isCrossdockEnabled;
        default:
          return true;
      }
    });
  }
}
