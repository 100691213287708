import * as React from 'react';
import {Modal} from '@flexe/ui-components';

export interface Props {
  title: string;
  toggle: () => void;
}

export const ConfirmationFormModal: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <div className={'confirmation-form-modal'} data-testid={'confirmation-form-modal'}>
        <Modal isOpen={true} toggleModal={props.toggle} title={props.title}>
          <div className={'modal-contents'} data-testid={'confirmation-form-modal-contents'}>
            {props.children}
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};
