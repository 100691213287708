import * as React from 'react';
import {TransitionState} from '../../shared/CommonInterfaces';
import {TransitionStateDisplay} from '../../shared/constants';

interface BatchStatusTabProps {
  status: TransitionState;
  totalShipments: number;
  isSelected: boolean;
  onTabSelect();
}

const BatchStatusTab: React.FC<BatchStatusTabProps> = (props) => {
  const {status, totalShipments, isSelected, onTabSelect} = props;
  const getDotForStatus = () => {
    if (status !== TransitionState.Active) {
      return <span className={`dot ${status}`}></span>;
    }
  };

  return (
    <div className="batch-status-tab">
      <button
        className={`btn btn-default${isSelected ? ' activeTab' : ''}`}
        type="button"
        onClick={() => onTabSelect()}
        aria-haspopup="true"
        aria-expanded="false"
      >
        {getDotForStatus()}
        {TransitionStateDisplay.get(status)}
        <span className="count">{totalShipments >= 0 ? totalShipments : ''}</span>
      </button>
    </div>
  );
};
export default BatchStatusTab;
