import * as React from 'react';
import {FunctionComponent} from 'react';
import classNames from 'classnames';

interface Props {
  text: string | React.ReactElement;
  handleClick?: any; // only omit this if using FlexeButton as the submit or cancel button in a form!
  type?: 'submit' | 'reset' | 'button' | undefined;
  level?: string;
  title?: string; // tooltip/hover text
  isDisabled?: boolean;
  id?: string;
  testid?: string;
  tabIndex?: number;
}

const FlexeButton: FunctionComponent<Props> = (props) => {
  const classes = ['flexe-button', props.level ? `btn-${props.level}` : 'btn', props.isDisabled && 'disabled'];

  let handleClick = null;

  if (props.handleClick) {
    handleClick = (e) => {
      e.preventDefault();
      props.handleClick(e);
    };
  }

  return (
    <button
      className={classNames(classes)}
      type={props.type}
      onClick={handleClick}
      disabled={props.isDisabled}
      title={props.title}
      id={props.id}
      data-testid={props.testid}
      tabIndex={props.tabIndex}
    >
      {props.text}
    </button>
  );
};

export default FlexeButton;
