import {format} from 'date-fns';

export function formatDate(dateAsString: string) {
  if (dateAsString) {
    const formattedDate = format(dateAsString, 'M/D/YY');
    return formattedDate;
  } else {
    return null;
  }
}

export function formatDateTime(dateTimeAsString: string) {
  if (dateTimeAsString) {
    const formattedDate = format(dateTimeAsString, 'M/D/YY hh:mm A');
    return formattedDate;
  } else {
    return null;
  }
}
