import * as React from 'react';
import {get} from 'lodash';
import {compareDesc, format as formatDate} from 'date-fns';
import {Loader} from '@flexe/ui-components';
import {ActionInfo} from '../CommonInterfaces';
import {CycleCountBatch, CycleCountDetails, CycleCountStatus, CycleCountStatusDisplay} from './CycleCountInterfaces';

interface Props {
  isLoading: boolean;
  cycleCount: CycleCountDetails;
  knownBatches: CycleCountBatch[];
  closeBatchesButton: JSX.Element;
}

class CCDetailSidebar extends React.Component<Props> {
  public render() {
    return (
      <div className="container sidebar cycle-counts" id="detailsSidebar">
        <div className="col-xs-12">
          <h3>Details</h3>
          <Loader loading={this.props.isLoading} />
          {this.props.cycleCount && this.renderSidebar()}
        </div>
      </div>
    );
  }

  private renderSidebar(): JSX.Element {
    const cc = this.props.cycleCount;
    const showBatches =
      this.props.knownBatches.length > 0 &&
      (cc.state === CycleCountStatus.pendingStart || cc.state === CycleCountStatus.inProgress);

    const sortedCounters = (cc.cycleCountItems ?? [])
      .filter((item) => item.counted)
      .map((item) => item.counted)
      .sort((a, b) => compareDesc(a.at, b.at));
    const countedBy: ActionInfo = sortedCounters[0];
    return (
      <dl>
        <dd>Status</dd>
        <dt>
          <span className={`label label-default ${this.props.cycleCount.state}`}>
            {CycleCountStatusDisplay[this.props.cycleCount.state]}
          </span>
        </dt>
        {cc.created && this.renderTimeAwareActor(cc.created, 'Created')}
        {countedBy && this.renderTimeAwareActor(countedBy, 'Counted')}
        {cc.submitted && this.renderTimeAwareActor(cc.submitted, 'Submitted')}
        {cc.completed && this.renderTimeAwareActor(cc.completed, 'Approved')}
        {cc.userComments && (
          <React.Fragment>
            <dd key="dd">Notes</dd>
            <dt key="dt">{cc.userComments}</dt>
          </React.Fragment>
        )}
        {showBatches && this.renderBatches()}
      </dl>
    );
  }

  private renderTimeAwareActor(twa: ActionInfo, identifier: string): JSX.Element {
    return (
      <React.Fragment>
        <dd key="dd">{identifier}</dd>
        <dt key="dt">
          {get(twa, 'by.name')}
          <br />
          {formatDate(twa.at, 'MM/DD/YY h:mma')}
        </dt>
      </React.Fragment>
    );
  }

  private renderBatches(): JSX.Element {
    const activeBatches = this.props.knownBatches.filter((batch) => batch.status === 'started');
    const pendingBatches = this.props.knownBatches
      .filter((batch) => batch.status === 'new')
      .filter((batch) => !!batch.assignedTo); // only show pre-assigned pending batches
    return (
      <React.Fragment>
        {this.renderActiveBatches(activeBatches)}
        {activeBatches.length > 0 && this.props.closeBatchesButton}
        {this.renderPendingBatches(pendingBatches)}
      </React.Fragment>
    );
  }

  // I would like for this to render the Locations the user is assigned to
  // but doing so requires handling a potentially overly large list and no.
  private renderActiveBatches(batches: CycleCountBatch[]): JSX.Element[] {
    return batches.map((batch: CycleCountBatch, i: number) => {
      return (
        <div key={`dd-${i}`}>
          <dd>Active Worker</dd>
          <dt key="dt">
            Assigned to: {get(batch.assignedTo, 'name') || 'N/A'}
            <br />
            Started at: {formatDate(batch.lastTouchedAt, 'MM/DD/YY h:mma')}
          </dt>
        </div>
      );
    });
  }

  private renderPendingBatches(batches: CycleCountBatch[]): JSX.Element[] {
    return batches.map((batch: CycleCountBatch, i: number) => {
      return (
        <div key={`dd-${i}`}>
          <dd>Pending Work</dd>
          <dt key="dt">
            Assigned to: {get(batch.assignedTo, 'name') || 'N/A'}
            <br />
            Number of locations: {batch.locations.length}
          </dt>
        </div>
      );
    });
  }
}

export default CCDetailSidebar;
