import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {parse as parseQueryString} from 'query-string';
import {Reservation, Warehouse} from '../shared/CommonInterfaces';
import LocationsIndex from './LocationsIndex';
import LocationsDetail from './LocationsDetail';
import {LocationFilters} from './LocationsInterfaces';

interface LocationProps {
  authenticityToken: string;
  warehouse: Warehouse;
  reservation: Reservation;
  activeWarehouses: Warehouse[];
  inactiveWarehouses: Warehouse[];
  initialPageSize?: number;
  enableZones: boolean;
  isNewTableEnabled: boolean;
}

interface LocationState {
  selectedWarehouse?: Warehouse;
  filters: LocationFilters;
  continuationToken: string;
  currentPage: number;
  pageSize: number;
}

class Locations extends React.Component<LocationProps, LocationState> {
  public static defaultProps: Partial<LocationProps> = {
    initialPageSize: 50
  };
  constructor(props) {
    super(props);
    this.state = {
      selectedWarehouse: props.warehouse,
      filters: {},
      continuationToken: null,
      currentPage: 1,
      pageSize: props.initialPageSize
    };
  }

  public render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/wh/locations/:id"
            render={(routeProps) => (
              <LocationsDetail
                {...routeProps}
                authenticityToken={this.props.authenticityToken}
                selectedWarehouse={this.state.selectedWarehouse}
                enableZones={this.props.enableZones}
                isNewTableEnabled={this.props.isNewTableEnabled}
              />
            )}
          />
          <Route
            path="/wh/locations"
            render={(routeProps) => (
              // if a SKU has been passed in the url, use it to set a filter value in filters
              <LocationsIndex
                {...routeProps}
                authenticityToken={this.props.authenticityToken}
                continuationToken={this.state.continuationToken}
                selectedWarehouse={this.state.selectedWarehouse}
                activeWarehouses={this.props.activeWarehouses}
                inactiveWarehouses={this.props.inactiveWarehouses}
                filters={this.parseFiltersFromQueryString(routeProps)}
                currentPage={this.state.currentPage}
                pageSize={this.state.pageSize}
                handleFilterChange={this.updateFiltersState}
                handleContinuationTokenChange={this.updateContinuationTokenState}
                handlePageChange={this.updateCurrentPage}
                handleWarehouseChange={this.updateSelectedWarehouse}
                handlePageSizeChange={this.updatePageSize}
                enableZones={this.props.enableZones}
              />
            )}
          />
        </Switch>
      </Router>
    );
  }

  private parseFiltersFromQueryString = (routeProps) => {
    const filtersNotAllowMultiple = ['hasLpn', 'isEmpty', 'isPrimaryPickLocation', 'isAllocatable'];
    const parsedQuery = parseQueryString(routeProps.location.search);
    const filters = this.state.filters;
    // The enum ones are a little tricky to use, so be aware that you have to pass
    // in a potentially altered value to match what server-side expects.
    Object.keys(parsedQuery).forEach((key) => {
      const queryValue = parsedQuery[key];
      if (filtersNotAllowMultiple.indexOf(key) >= 0) {
        filters[key] = queryValue;
      } else if (queryValue) {
        filters[key] = [].concat(queryValue);
      }
    });
    return filters;
  };

  private updateFiltersState = (filters) => {
    this.setState({filters});
  };

  private updateContinuationTokenState = (continuationToken) => {
    this.setState({continuationToken});
  };

  private updateCurrentPage = (currentPage) => {
    this.setState({currentPage});
  };

  private updateSelectedWarehouse = (selectedWarehouse) => {
    this.setState({selectedWarehouse});
  };

  private updatePageSize = (pageSize) => {
    this.setState({pageSize});
  };
}

export default Locations;
