import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {Loader, Table} from '@flexe/ui-components';
import {get} from 'lodash';
import Filters from '../../shared-v2/Filters';
import {Company, Filter, FilterOption, FilterValue} from '../../../shared/CommonInterfaces';
import InternalAPIServiceV2 from '../../../shared/services/InternalAPIServiceV2';
import {OMSFeatureFlags, OMSReservation} from '../../ecommerce-orders/OrdersAppInterfaces';
import OutboundOrdersService from '../OutboundOrdersService';
import {PageSize} from '../generic/PageSizeInput';
import {CancelOrderLineResponse, OrderLine} from '../OutboundOrdersInterfaces';
import {orderLineFilterDefs, getFilterDisplayValue, TrackedFilterValues} from '../helpers/OutboundOrdersFilters';
import OrderLineDetailModal from '../modals/OrderLineDetailModal';
import {isLineCancelable} from '../helpers/OrderLines';
import ConfirmCancelLineModal from '../modals/ConfirmLineCancelModal';
import OrderLineDetailModalV2 from '../modals/OrderLineDetailModalV2';
import {OrdersPageError, startNewLongRunningAction} from './OutboundOrdersV2';
import {buildRow, buildTableHeaders} from './OutboundOrderLinesTableRow';

interface Props {
  outboundOrdersService: OutboundOrdersService;
  pageSize: PageSize;
  setErrors: (v: OrdersPageError) => void;
  authenticityToken: string;
  currentCompany: Company;
  reservations: OMSReservation[];
  reservationOptions: FilterOption[];
  distributionByLotReservationIds: string[];
  filterValues: TrackedFilterValues;
  handleFilterChange: (filters: FilterValue[]) => void;
  featureFlags: OMSFeatureFlags;
}

interface LinesTableInfo {
  continuationTokens: string[];
  lines: OrderLine[];
  checkedLineIds: {[index: string]: boolean};
  modalShownForLine?: OrderLine;
  showConfirmCancelLineModal: boolean;
  totalCount: number;
  currentPage: number;
}

const buildInitialLinesTableInfo = (): LinesTableInfo => {
  return {
    continuationTokens: [],
    lines: [],
    checkedLineIds: {},
    showConfirmCancelLineModal: false,
    totalCount: 0,
    currentPage: 1
  };
};

const buildFilters = (filterValues: TrackedFilterValues, reservationOptions: FilterOption[]): Filter[] => {
  return orderLineFilterDefs.map((filterDef) => {
    const value = getFilterDisplayValue(filterValues, filterDef.key);

    return {
      ...filterDef,
      value,
      ...(filterDef.key === 'reservationIds' && {options: reservationOptions})
    };
  });
};

const getPageOfLines = async (
  pageSize: PageSize,
  filterValues: TrackedFilterValues,
  setErrors: (v: OrdersPageError) => void,
  outboundOrdersService: OutboundOrdersService,
  currentLinesTableInfo: LinesTableInfo
): Promise<LinesTableInfo> => {
  const newLinesTableInfo: LinesTableInfo = {...currentLinesTableInfo};

  if (!outboundOrdersService) {
    setErrors({errorType: 'UnexpectedFailure'});
    return newLinesTableInfo;
  }

  const error: OrdersPageError = {errorType: 'None'};
  const currentPage = newLinesTableInfo.currentPage;
  const response = await outboundOrdersService.getLines(
    filterValues.apiFilters,
    currentPage > 1 ? newLinesTableInfo.continuationTokens[currentPage - 2] : null,
    pageSize,
    null,
    {returnAllErrors: true}
  );

  if (!response) {
    newLinesTableInfo.lines = [];
    newLinesTableInfo.totalCount = 0;

    error.errorType = 'UnexpectedFailure';
  } else if (response.errors && response.errors.length > 0) {
    newLinesTableInfo.lines = [];
    newLinesTableInfo.totalCount = 0;

    error.errorType = 'ApiGetError';
    error.details = InternalAPIServiceV2.extractErrorSummaries(response.errors);
  } else {
    const newContinuationTokens: string[] = newLinesTableInfo.continuationTokens;
    // TODO this returning an array is bizarre
    const newToken = [get(response, 'continuationToken')][0];
    if (currentPage > newContinuationTokens.length) {
      newContinuationTokens.push(newToken);
    } else {
      newContinuationTokens[currentPage - 1] = newToken;
    }
    newLinesTableInfo.continuationTokens = newContinuationTokens;

    const tempLines = get(response, 'lines') || ([] as OrderLine[]);
    newLinesTableInfo.lines = outboundOrdersService.assignOrderLineState(tempLines);
    newLinesTableInfo.totalCount = get(response, 'total');
  }

  setErrors(error);
  return newLinesTableInfo;
};

const buildLinesRows = (
  linesTableInfo: LinesTableInfo,
  reservations: OMSReservation[],
  onToggleOneLineChecked: (event: React.ChangeEvent<HTMLInputElement>, lineId: string) => void,
  onClickOrderLineId: (lineId: string) => void
): any[][] => {
  if (!linesTableInfo || !linesTableInfo.lines) {
    return [];
  }

  return linesTableInfo.lines.map((line: OrderLine) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    buildRow(line, linesTableInfo.checkedLineIds, reservations, onToggleOneLineChecked, onClickOrderLineId)
  );
};

const reloadOrderLine = async (
  setErrors: (v: OrdersPageError) => void,
  outboundOrdersService: OutboundOrdersService,
  currentLinesTableInfo: LinesTableInfo
): Promise<LinesTableInfo> => {
  const displayedLine = currentLinesTableInfo.modalShownForLine;
  const newLinesTableInfo = {...currentLinesTableInfo, modalShownForLine: undefined};
  const error: OrdersPageError = {errorType: 'None'};

  const response = await outboundOrdersService.getLines({lineIds: [displayedLine.id]}, null, 1, displayedLine.orderId, {
    returnAllErrors: true
  });

  if (!response) {
    error.errorType = 'UnexpectedFailure';
  } else if (response.errors && response.errors.length > 0) {
    error.errorType = 'ApiGetError';
    error.details = InternalAPIServiceV2.extractErrorSummaries(response.errors);
  } else if (!response.lines || response.lines.length !== 1) {
    error.errorType = 'ApiGetError';
    error.details = ['Retrieving the updated line did not yield a single response'];
  } else {
    const retrievedLine = outboundOrdersService.assignOrderLineState(response.lines)[0];
    newLinesTableInfo.lines = newLinesTableInfo.lines.map((l) => (l.id === retrievedLine.id ? retrievedLine : l));
    newLinesTableInfo.modalShownForLine = retrievedLine;
  }

  setErrors(error);
  return newLinesTableInfo;
};

interface InternalCancelOrderLineResponse {
  // The api response doesn't reliably return order or line id,
  //  making error reporting a pain. So let's track it.
  orderId: string;
  lineId: string;
  apiResponse: CancelOrderLineResponse;
}

const responseHasErrors = (response: InternalCancelOrderLineResponse) =>
  response.apiResponse.errors && response.apiResponse.errors.length > 0;

const cancelLines = async (
  allLines: OrderLine[],
  checkedLineIds: {[index: string]: boolean},
  outboundOrdersService: OutboundOrdersService
): Promise<InternalCancelOrderLineResponse[]> => {
  const selectedLines = allLines.filter((line) => checkedLineIds[line.id]);
  return await Promise.all(
    selectedLines.map((line) =>
      outboundOrdersService.cancelOrderLine(line.orderId, line.id, {returnAllErrors: true}).then((response) => ({
        apiResponse: response,
        orderId: line.orderId,
        lineId: line.id
      }))
    )
  );
};

const extractErrorsFromCancellation = (cancelResults: InternalCancelOrderLineResponse[]): OrdersPageError => {
  const errorMessages = cancelResults
    .filter((response) => responseHasErrors(response))
    .map((response) => {
      // Combine all errors, but strip out the order and line ID returned in this message,
      // Since most other errors don't include that and we have to add our own line and order IDs to them
      //  Error message = Failed to cancel shipments: [111, 222, 333] for order: 444, line: 555
      const delimitedErrors = InternalAPIServiceV2.extractErrorSummaries(response.apiResponse.errors)
        .join(', ')
        .replace(/ for order: \d+, line: \d+/g, '');
      return `Line ${response.lineId} on Order ${response.orderId}: ${delimitedErrors}`;
    });

  if (errorMessages.length < 1) {
    return {errorType: 'None'};
  }

  return {
    errorType: 'ApiCancelError',
    details: errorMessages
  };
};

const extractSuccessesFromCancellation = (
  cancelResults: InternalCancelOrderLineResponse[],
  currentLinesTableInfo: LinesTableInfo,
  outboundOrdersService: OutboundOrdersService
): LinesTableInfo => {
  // Note we're actually ignoring a niche thing. 200 responses return {...OrderLine}, but
  //  202 responses return {errors: [], orderLine: OrderLine}
  // That was never well handled and we're removing 202s soon, so not changing that
  const successes = cancelResults.filter((response) => !responseHasErrors(response));

  const updatedLines = currentLinesTableInfo.lines.map((line) => {
    const matchingSuccess = successes.find((response) => response.apiResponse.id === line.id);
    return matchingSuccess ? matchingSuccess.apiResponse : line;
  });

  // Keep lines checked if we didn't succeed in cancelling
  const linesToKeepChecked: {[index: string]: boolean} = {};
  Object.entries(currentLinesTableInfo.checkedLineIds)
    .filter(([lineId, isChecked]) => isChecked && !successes.some((line) => line.apiResponse.id === lineId))
    .forEach(([lineId, _]) => (linesToKeepChecked[lineId] = true));

  return {
    ...currentLinesTableInfo,
    lines: outboundOrdersService.assignOrderLineState(updatedLines),
    checkedLineIds: linesToKeepChecked,
    showConfirmCancelLineModal: false
  };
};

const OutboundOrderLinesTable: FC<Props> = (props) => {
  const [linesTableInfo, setLinesTableInfo] = useState<LinesTableInfo>(buildInitialLinesTableInfo());
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Since pageSize is required, this handles initial load as well
  useEffect(() => loadLines(buildInitialLinesTableInfo()), [props.pageSize, props.filterValues]);

  const changePage = (newPage: number) => {
    // Page changes mean we can keep all the current data, but load a different page
    const newOrdersTableInfo: LinesTableInfo = {...linesTableInfo, currentPage: newPage};
    loadLines(newOrdersTableInfo);
  };

  const loadLines = (newLinesTableInfo: LinesTableInfo) => {
    startNewLongRunningAction(setIsLoading, props.setErrors);

    getPageOfLines(props.pageSize, props.filterValues, props.setErrors, props.outboundOrdersService, newLinesTableInfo)
      .then((result) => {
        setLinesTableInfo(result);
        setIsLoading(false);
      })
      .catch(() => {
        props.setErrors({errorType: 'UnexpectedFailure'});
        // Don't update table info because we failed to load the new page
        setIsLoading(false);
      });
  };

  const onToggleLinesChecked = (event: React.ChangeEvent<HTMLInputElement>, lineId?: string) => {
    if (!event || !event.target) {
      return;
    }

    const newCheckedLineIds = {...linesTableInfo.checkedLineIds};
    const isChecked = event.target.checked;
    if (lineId) {
      newCheckedLineIds[lineId] = isChecked;
    } else {
      linesTableInfo.lines.forEach((line: OrderLine) => {
        if (isLineCancelable(line)) {
          newCheckedLineIds[line.id] = isChecked;
        }
      });
    }
    setLinesTableInfo({...linesTableInfo, checkedLineIds: newCheckedLineIds});
  };
  const onToggleAllLinesChecked = (event: React.ChangeEvent<HTMLInputElement>) => onToggleLinesChecked(event);
  const onToggleOneLineChecked = (event: React.ChangeEvent<HTMLInputElement>, lineId: string) =>
    onToggleLinesChecked(event, lineId);

  const onClickOrderLineId = (lineId: string) => {
    const clickedLine = linesTableInfo.lines.find((l) => l.id === lineId);
    if (!clickedLine) {
      props.setErrors({errorType: 'UnexpectedFailure', details: ['Could not find selected line to launch modal.']});
    } else {
      setLinesTableInfo({...linesTableInfo, modalShownForLine: clickedLine});
    }
  };
  const onCloseLineDetailModal = () => setLinesTableInfo({...linesTableInfo, modalShownForLine: undefined});
  // TODO after we release V2, get rid of this reload paradigm.
  //  Update/cancel returns the updated line, so we can just use that result
  //  And callback to this to update the table
  const onReloadModalLine = async () => {
    // Don't set isLoading here - it will cause the modal to refresh and
    //  Potentially drop errors, as currently designed anyway.
    const newLinesTableInfo = await reloadOrderLine(props.setErrors, props.outboundOrdersService, linesTableInfo);
    setLinesTableInfo(newLinesTableInfo);
  };

  const onClickCancelLinesButton = () => setLinesTableInfo({...linesTableInfo, showConfirmCancelLineModal: true});
  const onCloseConfirmLineCancelModal = () => setLinesTableInfo({...linesTableInfo, showConfirmCancelLineModal: false});
  const cancelSelectedLines = () => {
    startNewLongRunningAction(setIsLoading, props.setErrors);

    cancelLines(linesTableInfo.lines, linesTableInfo.checkedLineIds, props.outboundOrdersService)
      .then((cancelResults) => {
        const error = extractErrorsFromCancellation(cancelResults);
        const newLinesTableInfo = extractSuccessesFromCancellation(
          cancelResults,
          linesTableInfo,
          props.outboundOrdersService
        );

        setLinesTableInfo(newLinesTableInfo);
        props.setErrors(error);
        setIsLoading(false);
      })
      .catch(() => {
        props.setErrors({errorType: 'UnexpectedFailure', details: ['Please reload the page']});
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  const hasCheckedLine = Object.values(linesTableInfo.checkedLineIds).filter((v) => v).length > 0;
  const cancellableLines = linesTableInfo.lines.filter((line) => isLineCancelable(line));
  const hasUncheckedLine = cancellableLines.some((line) => !linesTableInfo.checkedLineIds[line.id]);

  return (
    <>
      <div className="row filters">
        <Filters
          filters={buildFilters(props.filterValues, props.reservationOptions)}
          filterChangeHandler={(newFilters) => props.handleFilterChange(newFilters)}
        />
        {hasCheckedLine && (
          <div className="col-md-12">
            <button className="btn cta" onClick={onClickCancelLinesButton}>
              Cancel Selected Lines
            </button>
          </div>
        )}
      </div>
      {linesTableInfo.lines.length === 0 ? (
        <p>No order lines matching the current filters were found.</p>
      ) : (
        <>
          <Table
            tableClass="table-striped outbound-orders-shared-table"
            tableData={{
              headers: buildTableHeaders(
                linesTableInfo.currentPage,
                linesTableInfo.totalCount,
                cancellableLines.length > 0,
                hasUncheckedLine,
                props.pageSize,
                changePage,
                onToggleAllLinesChecked
              ),
              rows: buildLinesRows(linesTableInfo, props.reservations, onToggleOneLineChecked, onClickOrderLineId)
            }}
          />
          {linesTableInfo.modalShownForLine &&
            (props.featureFlags.showNewOrderLineModal ? (
              <OrderLineDetailModalV2
                orderLine={linesTableInfo.modalShownForLine}
                featureFlags={props.featureFlags}
                allReservationsForShipper={props.reservations}
                toggleModal={onCloseLineDetailModal}
              />
            ) : (
              <OrderLineDetailModal
                line={linesTableInfo.modalShownForLine}
                authenticityToken={props.authenticityToken}
                currentCompany={props.currentCompany}
                reservations={props.reservations}
                toggleOrderLineModal={onCloseLineDetailModal}
                reloadOrderLine={onReloadModalLine}
                distributionByLotReservationIds={props.distributionByLotReservationIds}
                showCalculatedShipByDate={props.featureFlags.showCalculatedShipByDate}
              />
            ))}
        </>
      )}
      {linesTableInfo.showConfirmCancelLineModal && (
        <ConfirmCancelLineModal
          lines={linesTableInfo.lines.filter((line) => linesTableInfo.checkedLineIds[line.id])}
          toggleShowModal={onCloseConfirmLineCancelModal}
          cancelLines={cancelSelectedLines}
        />
      )}
    </>
  );
};
export default OutboundOrderLinesTable;
