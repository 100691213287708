import {LpnContentDetail, LpnSearchDetails} from '../../lpns/LpnsInterfaces';
import {LineItem, RetailVarianceConfig, Shipment} from './ShipmentInterfaces';

export enum VarianceStatus {
  NOT_PICKED,
  NO_VARIANCE,
  SHORT_PICKED,
  OVER_PICKED,
  SHORT_PICKED_BREACHES_THRESHOLD,
  OVER_PICKED_BREACHES_THRESHOLD
}

export const VarianceBreachesThresholdErrorMsg =
  'The difference between the number of items picked and the ' +
  'number of items ordered is too large. The shipping customer does not accept this significant of a change.';

export const VarianceWithinThresholdWarningMsg =
  'This shipment is either Short or Over. ' +
  'There is a difference between the number of items picked and the number of items ordered.';

const varianceStatusToErrorMsgMap = new Map<VarianceStatus, string>([
  [VarianceStatus.NO_VARIANCE, ''],
  [VarianceStatus.NOT_PICKED, ''],
  [VarianceStatus.SHORT_PICKED, VarianceWithinThresholdWarningMsg],
  [VarianceStatus.OVER_PICKED, VarianceWithinThresholdWarningMsg],
  [VarianceStatus.SHORT_PICKED_BREACHES_THRESHOLD, VarianceBreachesThresholdErrorMsg],
  [VarianceStatus.OVER_PICKED_BREACHES_THRESHOLD, VarianceBreachesThresholdErrorMsg]
]);

export const VarianceStatusToBackgroundStyleMap = new Map<VarianceStatus, any>([
  [VarianceStatus.NO_VARIANCE, null],
  [VarianceStatus.NOT_PICKED, null],
  [VarianceStatus.SHORT_PICKED, 'warn'],
  [VarianceStatus.OVER_PICKED, 'warn'],
  [VarianceStatus.SHORT_PICKED_BREACHES_THRESHOLD, 'invalid'],
  [VarianceStatus.OVER_PICKED_BREACHES_THRESHOLD, 'invalid']
]);

export class ShipmentRetailVariance {
  private shipment: Shipment;
  private lpns: LpnSearchDetails[];
  private retailVarianceConfig: RetailVarianceConfig;

  private overallShipmentVarianceStatus;
  private skuToVarianceStatusMap = new Map<string, VarianceStatus>();

  constructor(shipment: Shipment, lpns: LpnSearchDetails[], retailVarianceConfig: RetailVarianceConfig) {
    this.overallShipmentVarianceStatus = VarianceStatus.NO_VARIANCE;
    this.shipment = shipment;
    this.lpns = lpns;
    this.retailVarianceConfig = retailVarianceConfig;
    if (this.shipment !== null) {
      this.calculateRetailVariance();
    }
  }

  public getRetailVarianceWarningMessage(): string {
    return varianceStatusToErrorMsgMap.get(this.overallShipmentVarianceStatus);
  }

  public getSkuToVarianceStatusMap() {
    return this.skuToVarianceStatusMap;
  }

  public getOverallShipmentVarianceStatus() {
    return this.overallShipmentVarianceStatus;
  }

  private calculateRetailVariance() {
    const expectedPickSkuQtyMapping = new Map<string, number>();
    const actualPickSkuQtyMapping = new Map<string, number>();

    if (!this.retailVarianceConfig) {
      return;
    }

    this.shipment.line_items.forEach((item) =>
      expectedPickSkuQtyMapping.set(
        item.sku,
        (expectedPickSkuQtyMapping.get(item.sku) || 0) + this.getExpectedPickQtyInEaches(item)
      )
    );

    this.lpns.forEach((lpn) =>
      actualPickSkuQtyMapping.set(
        lpn.contents[0].item.sku,
        (actualPickSkuQtyMapping.get(lpn.contents[0].item.sku) || 0) + this.getActualPickQtyInEaches(lpn.contents[0])
      )
    );

    let shipmentShortage = Number.MAX_SAFE_INTEGER;
    let shipmentOverage = Number.MIN_SAFE_INTEGER;

    for (const skuToQty of Array.from(expectedPickSkuQtyMapping.entries())) {
      const sku = skuToQty[0];

      if (!actualPickSkuQtyMapping.has(sku)) {
        this.skuToVarianceStatusMap.set(sku, VarianceStatus.NOT_PICKED);
      } else {
        const picked = actualPickSkuQtyMapping.get(sku);
        const expected = skuToQty[1];
        if (picked === expected) {
          this.skuToVarianceStatusMap.set(sku, VarianceStatus.NO_VARIANCE);
        } else if (picked > expected) {
          const skuOverage = (picked / expected) * 100 - 100;
          shipmentOverage = Math.max(skuOverage, shipmentOverage);
          this.skuIsOverPicked(sku, shipmentOverage, skuOverage);
        } else if (picked < expected) {
          const skuShortage = 100 - (picked / expected) * 100;
          shipmentShortage = Math.min(skuShortage, shipmentShortage);
          this.skuIsShortPicked(sku, shipmentShortage, skuShortage);
        }
      }
    }
  }

  private skuIsShortPicked(sku: string, shipmentShortage: number, skuShortage: number) {
    this.overallShipmentVarianceStatus = VarianceStatus.SHORT_PICKED;
    this.skuToVarianceStatusMap.set(sku, VarianceStatus.SHORT_PICKED);

    if (this.retailVarianceConfig !== null) {
      if (shipmentShortage > this.retailVarianceConfig.short_percentage) {
        this.overallShipmentVarianceStatus = VarianceStatus.SHORT_PICKED_BREACHES_THRESHOLD;
      }
      if (skuShortage > this.retailVarianceConfig.short_percentage) {
        this.skuToVarianceStatusMap.set(sku, VarianceStatus.SHORT_PICKED_BREACHES_THRESHOLD);
      }
    }
  }

  private skuIsOverPicked(sku: string, shipmentOverage: number, skuOverage: number) {
    this.overallShipmentVarianceStatus = VarianceStatus.OVER_PICKED;
    this.skuToVarianceStatusMap.set(sku, VarianceStatus.OVER_PICKED);
    if (this.retailVarianceConfig !== null) {
      if (shipmentOverage > this.retailVarianceConfig.overage_percentage) {
        this.overallShipmentVarianceStatus = VarianceStatus.OVER_PICKED_BREACHES_THRESHOLD;
      }
      if (skuOverage > this.retailVarianceConfig.overage_percentage) {
        this.skuToVarianceStatusMap.set(sku, VarianceStatus.OVER_PICKED_BREACHES_THRESHOLD);
      }
    }
  }

  private getExpectedPickQtyInEaches(item: LineItem): number {
    return item.packaging === 'carton' ? item.units_per_packaging.each : item.quantity;
  }

  private getActualPickQtyInEaches(lpn: LpnContentDetail): number {
    return lpn.quantity.unit === 'carton' ? lpn.quantityInAllUnits.each : lpn.quantity.amount;
  }
}
