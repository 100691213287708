import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import FlexeContext, {FlexeContextValues} from '../../contexts/FlexeContext';
import {Warehouse} from '../../shared/CommonInterfaces';
import LoadsIndex from './LoadsIndex';
import LoadDetail from './LoadDetail';

interface AppProps {
  authenticityToken: string;
  activeWarehouses: Warehouse[];
  selectedWarehouse: Warehouse;
  parcelTrailerLoadingEnabled: boolean;
  freightTrailerLoadingEnabled: boolean;
  enableLoadBlockIfNoLabelsModal: boolean;
}

function LoadsApp(props: AppProps) {
  const flexeContextValue: FlexeContextValues = {
    authenticityToken: props.authenticityToken
  };

  return (
    <FlexeContext.Provider value={flexeContextValue}>
      <Router>
        <Switch>
          <Route path="/wh/loads" exact render={(routeProps) => <LoadsIndex {...routeProps} {...props} />} />
          <Route
            path="/wh/loads/:id"
            exact
            render={(routeProps) => (
              <LoadDetail {...routeProps} {...props} loadId={parseInt(routeProps.match.params.id, 10)} />
            )}
          />
        </Switch>
      </Router>
    </FlexeContext.Provider>
  );
}

export default LoadsApp;
