import {Document, ShipmentDocument} from '../shared/CommonInterfaces';
import {OutboundDocument} from '../shared/services/OutboundDocumentService';

/**
 * A unification of the properties on various blob storage db tables used here.
 */
export interface BlobStorage {
  typeTitle: string;
  typeSubtitle: string;
  availableForDownload: boolean;
  secureDownloadUrl: string;
  createdAt: string;
  isGeneratedBol: boolean;
  isSsccLabels: boolean;
  isSsccCartonLabels: boolean;
  isSsccPalletLabels: boolean;
  isFreightPackingList: boolean;
  isSticker: boolean;
  isQueued?: boolean;
  fileName?: string;
}

/**
 * Input for convertToBlobStorage
 */
export interface BlobStorageConversionProp {
  document?: Document;
  label?: ShipmentDocument;
  outboundDoc?: OutboundDocument;
  dbTable: BlobDbTableType;
}

/**
 * The types of interfaces that can be converted into a BlobStorage interface
 * no-shadow disabled here because I don't fully understand what's going on here.
 */
/* eslint-disable @typescript-eslint/no-shadow */
export enum BlobDbTableType {
  Document,
  ShipmentDocument,
  OutboundDocument
}
/* eslint-enable @typescript-eslint/no-shadow */

/**
 * Convert a model based on a db table to a generic blob storage interface
 * @param source A rich object containing the source model object plus the type description of the object
 * @param documentIdentifier Additional, optional identifier that may describe a document (fetched from a separate API)
 * @returns A BlobStorage representation of the model
 */
export const convertToBlobStorage = (source: BlobStorageConversionProp, documentIdentifier?: string): BlobStorage => {
  let blobStorage: BlobStorage;

  switch (source.dbTable) {
    case BlobDbTableType.Document:
      if (source.document == null) {
        throw new Error('Bad input! Document is required!');
      }
      blobStorage = {
        typeTitle: source.document.documentType.toString(),
        typeSubtitle: documentIdentifier || '',
        secureDownloadUrl: source.document.note.url,
        createdAt: source.document.createdAt,
        availableForDownload: !!source.document.note.url,
        isGeneratedBol: source.document.documentType.toString() === 'Generated Bill Of Lading',
        isSsccLabels: false,
        isSsccCartonLabels: false,
        isSsccPalletLabels: false,
        isFreightPackingList: false,
        isSticker: false,
        fileName: source.document.note.name
      };
      break;
    case BlobDbTableType.ShipmentDocument:
      if (source.label == null) {
        throw new Error('Bad input! Label is required!');
      }
      blobStorage = {
        typeTitle: source.label.labelType.displayName,
        typeSubtitle: source.label.labelType.subDisplayName,
        secureDownloadUrl: source.label.labelDetails.secureDownloadUrl,
        createdAt: source.label.labelDetails.updatedAt ?? '',
        availableForDownload: !!source.label.labelDetails.secureDownloadUrl,
        isGeneratedBol: false,
        isSsccLabels: source.label.labelType.enumValue === 'sscc_labels',
        isSsccCartonLabels: source.label.labelType.enumValue === 'carton_sscc_labels',
        isSsccPalletLabels: source.label.labelType.enumValue === 'pallet_sscc_labels',
        isFreightPackingList: source.label.labelType.enumValue === 'freight_packing_list',
        isSticker: false,
        fileName: source.label.fileName
      };
      break;
    case BlobDbTableType.OutboundDocument:
      if (source.outboundDoc == null) {
        throw new Error('Bad input! Outbound document is required!');
      }
      blobStorage = {
        typeTitle: source.outboundDoc.labelType.displayName,
        typeSubtitle: source.outboundDoc.labelType.subDisplayName,
        secureDownloadUrl: source.outboundDoc.labelDetails.secureDownloadUrl,
        createdAt: source.outboundDoc.labelDetails.updatedAt ?? '',
        availableForDownload: source.outboundDoc.generationStatus === 'generated',
        isGeneratedBol: false,
        isSsccLabels: false,
        isSsccCartonLabels: false,
        isSsccPalletLabels: false,
        isFreightPackingList: false,
        isSticker: source.outboundDoc.labelType.enumValue === 'sticker',
        isQueued: source.outboundDoc.generationStatus === 'queued',
        fileName: source.outboundDoc.fileName
      };
      break;
    default:
      throw new Error('Bad input! Unknown type of BlobDbTableType!');
  }

  return blobStorage;
};
