import * as React from 'react';
import {ExpandoData} from '../shared/CommonInterfaces';
import Expando from './Expando';

interface Props {
  expandoData: ExpandoData[];
}

interface State {
  toggleStates: boolean[];
}

class ExpandoGroup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const toggleStates = props.expandoData.map((ed, idx) => {
      return idx === 0 || ed.defaultOpen;
    });

    this.state = {
      toggleStates
    };
  }

  public render() {
    return (
      <div className="expando-group">
        {this.props.expandoData.map((ed: ExpandoData, idx: number) => {
          return (
            <Expando
              key={idx}
              index={idx}
              headerText={ed.headerText}
              headerIcon={ed.headerIcon}
              open={this.state.toggleStates[idx]}
              onClick={this.toggle}
              hide={ed.hide}
            >
              {ed.content}
            </Expando>
          );
        })}
      </div>
    );
  }

  private toggle = (event) => {
    const clickedIndex = parseInt(event.currentTarget.getAttribute('data-index'), 10);
    const toggleStates = this.state.toggleStates.map((toggleState: boolean, idx: number) => {
      return idx === clickedIndex ? !toggleState : toggleState;
    });
    this.setState({toggleStates});
  };
}

export default ExpandoGroup;
