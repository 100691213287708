import * as React from 'react';
import {Document} from '../../shared/CommonInterfaces';
import {formatDateTime} from '../Utilities';
import {RetailOrderDetails} from '../../shared/services/RetailFulfillmentService';
import {HeaderPaneOrderInformation} from './HeaderPaneOrderInformation';
import {HeaderPaneRoutingInformation} from './HeaderPaneRoutingInformation';
import {HeaderPaneShipmentDetails} from './HeaderPaneShipmentDetails';
import {HeaderPaneDocuments} from './HeaderPaneDocuments';

interface DetailsHeaderPaneProps {
  orderDetails: RetailOrderDetails;
  documents: Document[];
  onDocumentSavedHandler: (document: Document) => void;
  enablePurchaseOrder: boolean;
  orderHasGeneratedLabels: boolean; // TODO: pull from API
  onErrorHandler: (error: string) => void;
}

export const DetailsHeaderPane: React.FC<DetailsHeaderPaneProps> = (props) => {
  const breadcrumbs = (): React.ReactFragment => {
    return (
      <div className="breadcrumbs delivery">
        Fulfillment
        <i className="fa fa-angle-right"></i>
        <a href="/wh/fulfillment/retail">Retail Fulfillment</a>
        <i className="fa fa-angle-right"></i>
        Order Detail
      </div>
    );
  };

  const shipmentHeader = (): React.ReactFragment => {
    const momentFormat = 'MM/DD/YYYY [at] h:mm A z';
    let headerIcon: React.ReactFragment;
    let headerSubtext: React.ReactFragment;

    if (props.orderDetails.state === 'completed') {
      headerIcon = (
        <div className="floaty-token header-badge check">
          <i className="fa fa-check"></i>
        </div>
      );

      headerSubtext = (
        <React.Fragment>
          <b>Completed:</b>
          {' ' + formatDateTime(props.orderDetails.completedAt, momentFormat, props.orderDetails.warehouse.timezone)}
          {' by ' + props.orderDetails.completedBy}
        </React.Fragment>
      );
    } else {
      headerIcon = (
        <div className="floaty-token header-badge">
          <i className="fa fa-truck"></i>
        </div>
      );

      headerSubtext = (
        <React.Fragment>
          <b>Submitted:</b>
          {' ' + formatDateTime(props.orderDetails.createdAt, momentFormat, props.orderDetails.warehouse.timezone)}
        </React.Fragment>
      );
    }

    return (
      <div className="shipment-head">
        {headerIcon}
        <h1>Order #{props.orderDetails.id}</h1>

        <div className="shipment-head-metadata">
          <b>Status:</b>
          {' ' + props.orderDetails.state}
          &nbsp;&mdash;&nbsp;
          {headerSubtext}
        </div>
      </div>
    );
  };

  return (
    <div id="topline-details" className="row no-margin">
      <div className="col-sm-6 space-below">{breadcrumbs()}</div>

      <div className="col-md-12 space-below-lg">{shipmentHeader()}</div>

      <HeaderPaneOrderInformation orderDetails={props.orderDetails} enablePurchaseOrder={props.enablePurchaseOrder} />

      <HeaderPaneRoutingInformation orderDetails={props.orderDetails} />

      <HeaderPaneShipmentDetails orderDetails={props.orderDetails} />

      <HeaderPaneDocuments
        orderDetails={props.orderDetails}
        documents={props.documents}
        onDocumentSavedHandler={props.onDocumentSavedHandler}
        orderHasGeneratedLabels={props.orderHasGeneratedLabels}
        onErrorHandler={props.onErrorHandler}
      />
    </div>
  );
};
