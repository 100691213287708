import * as React from 'react';
import {FC} from 'react';
import {getTotalLpnQuantityString, TLpnContent} from '../ShipmentHelper';
import {PackagingToValue} from '../../../shared/CommonInterfaces';
import {WarningCard} from '../shared/WarningCard';

export const ShipmentIsShortWarning: FC<{
  isShort: boolean;
  shortContents: TLpnContent[];
}> = ({isShort, shortContents}) => {
  if (!isShort) {
    return null;
  }

  const shortContentsSortedByIncreasingGranularity = shortContents.sort(
    (a, b) => PackagingToValue[b.unitOfMeasure] - PackagingToValue[a.unitOfMeasure]
  );

  return (
    <WarningCard>
      <div data-testid="shipment-is-short-warning-text">
        <b>This shipment is short.</b> If shipped now, this order will be short by{' '}
        {getTotalLpnQuantityString(shortContentsSortedByIncreasingGranularity)}.
      </div>
    </WarningCard>
  );
};
