/** @jsxRuntime classic */
/** @jsx jsx */

import {useState} from 'react';
import {css, jsx} from '@emotion/react';
import {Button, Column, Heading, Icon, Row, useTable} from '@flexe/ui-components';
import {MobileUser, MobileWarehouse} from '../userManagementInterfaces';
import {rowStyle, userRowStyle} from '../styles';
import AddMobileUserModal from './AddMobileUserModal';

const userNameStyle = css({width: '20%'});

interface Props {
  authenticityToken: string;
  onRemoveMobileUserFromWarehouseAsync: (userId: number, warehouseId: number) => Promise<void>;
  users: MobileUser[];
  warehouse: MobileWarehouse;
}

const MobileWarehouse = ({authenticityToken, onRemoveMobileUserFromWarehouseAsync, users, warehouse}: Props) => {
  const {tableStyles} = useTable({});
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  const handleAddUserButtonPress = () => {
    setIsAddUserModalOpen(true);
  };

  const handleAddMobileUserModalClose = () => {
    setIsAddUserModalOpen(false);
  };

  const handleRemoveMobileUserButtonPressAsync = async (userId: number) => {
    await onRemoveMobileUserFromWarehouseAsync(userId, warehouse.id);
  };

  const usersWhoCanBeAdded = users.filter((user) => !warehouse.users.some((u) => u.id === user.id));

  const sortAlphabetically = (unsortedUsers: MobileUser[]) => {
    return unsortedUsers.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <Column tag="section">
      <Heading level="h3">
        {warehouse.name}
        <small> {warehouse.address}</small>
      </Heading>
      <div title={usersWhoCanBeAdded.length === 0 ? 'All mobile users are already assigned to this warehouse' : null}>
        <Button onPress={handleAddUserButtonPress} isDisabled={usersWhoCanBeAdded.length === 0}>
          <Row margin={['0', '100']}>
            <Icon icon="plus" />
            <span>Add User</span>
          </Row>
        </Button>
      </div>
      {isAddUserModalOpen && (
        <AddMobileUserModal
          authenticityToken={authenticityToken}
          onModalClose={handleAddMobileUserModalClose}
          warehouseId={warehouse.id}
          warehouseName={warehouse.name}
          users={usersWhoCanBeAdded}
        />
      )}
      {warehouse.users.length !== 0 && (
        <table {...tableStyles}>
          <thead>
            <tr css={rowStyle}>
              <th css={userNameStyle}>Username</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortAlphabetically(warehouse.users).map((mobileUser) => (
              <tr key={mobileUser.id} css={userRowStyle}>
                <td>{mobileUser.userName}</td>
                <td>
                  <Button
                    onPress={() => handleRemoveMobileUserButtonPressAsync(mobileUser.id)}
                    isDisabled={mobileUser.isProcessing}
                  >
                    <Row margin={['0', '100']}>
                      <Icon icon="close" />
                      <span>Remove</span>
                    </Row>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Column>
  );
};

export default MobileWarehouse;
