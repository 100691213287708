import * as React from 'react';
import {FunctionComponent} from 'react';
import classNames from 'classnames';
import DoWhenClickOutside from '../shared/DoWhenClickOutside';

export interface KebabMenuOption {
  optionText: string;
  optionAction?: () => void;
  optionHref?: string;
  isActionDangerous?: boolean;
  isActionDisabled?: boolean;
}

interface Props {
  options: KebabMenuOption[];
  displayBeside?: boolean;
  displayAbove?: boolean;
}

// TODO devs _need_ to wrap this component in a parent with position: relative set explicitly. Update this component
// to be wrapped in a parent component with the positioning already set to avoid confusion.
export const CustomKebabMenu: FunctionComponent<Props> = (props) => {
  const [showDropdown, setShowDropdown] = React.useState(false);

  const kebabMenuClasses = ['kebab-menu'];
  const dangerousClasses = ['dangerous-action'];
  const disabledClasses = ['disabled-action'];
  const listDisplayClasses = [
    'dropdown-menu',
    'main-dropdown',
    'show',
    props.displayBeside && 'display-beside',
    props.displayAbove && 'display-above'
  ];

  const toggleShowDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const renderOptionsList = (): React.ReactFragment => {
    return (
      <ul className={classNames(listDisplayClasses)} data-testid="option-set">
        {props.options.map((item, index) => renderOption(item, index))}
      </ul>
    );
  };

  const renderOption = (item: KebabMenuOption, index: number): React.ReactFragment => {
    const onClickFn = item.optionAction;

    let optionClasses = [];
    if (item.isActionDangerous) {
      optionClasses = dangerousClasses;
    }
    if (item.isActionDisabled) {
      optionClasses = disabledClasses;
    }

    let option;
    if (item.optionHref) {
      option = (
        <a href={item.optionHref} data-testid={item.optionText + '-option'} className={classNames(optionClasses)}>
          {item.optionText}
        </a>
      );
    } else {
      option = (
        <span className={classNames(optionClasses)} onClick={onClickFn} data-testid={item.optionText + '-option'}>
          {item.optionText}
        </span>
      );
    }

    return (
      <React.Fragment key={'option-' + index}>
        <li className="option">{option}</li>
      </React.Fragment>
    );
  };

  return (
    <DoWhenClickOutside callback={() => setShowDropdown(false)}>
      <div data-testid="kebab-menu" className={classNames(kebabMenuClasses)}>
        <span data-testid="ellipsis-icon-anchor" className="kebab" onClick={toggleShowDropdown}>
          {showDropdown ? (
            <span className="fa-stack">
              <i className="fas fa-ellipsis-v fa-stack-1x fa-inverse"></i>
            </span>
          ) : (
            <i className="fas fa-ellipsis-v"></i>
          )}
        </span>

        {showDropdown && renderOptionsList()}
      </div>
    </DoWhenClickOutside>
  );
};

export default CustomKebabMenu;
