import {Packaging} from './CommonInterfaces';

export const PluralPackaging = new Map<Packaging, string>([
  [Packaging.each, 'eaches'],
  [Packaging.carton, 'cartons'],
  [Packaging.pallet, 'pallets']
]);

export const SingularPackaging = new Map<string, Packaging>([
  ['eaches', Packaging.each],
  ['cartons', Packaging.carton],
  ['pallets', Packaging.pallet]
]);

export const PluralPackagingAbbreviations = new Map<Packaging, string>([
  [Packaging.each, 'ea'],
  [Packaging.carton, 'cs'],
  [Packaging.pallet, 'plt']
]);

export const PluralPackagingStringAbbreviations = new Map<string, string>([
  ['each', 'ea'],
  ['carton', 'cs'],
  ['pallet', 'plt']
]);
