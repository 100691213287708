import * as React from 'react';
import {Link, RouteProps} from 'react-router-dom';
import {cloneDeep, get, isEqual, set} from 'lodash';
import {format as formatDate} from 'date-fns';
import {Loader, Pagination, Tab, Table, TableHeader, Tabs} from '@flexe/ui-components';
import {Company, Packaging} from '../../shared/CommonInterfaces';
import EdiFilesForResource from '../edi-files/EdiFilesForResource';
import {OMSFeatureFlags, reservationsToOMSReservations} from '../ecommerce-orders/OrdersAppInterfaces';
import OrderLineDetailModal from './modals/OrderLineDetailModal';
import {
  AllocationError,
  OrderLine,
  OrderStatus,
  OrderStatusDisplay,
  OutboundOrder,
  UpdateOrderParams
} from './OutboundOrdersInterfaces';
import OutboundOrdersService from './OutboundOrdersService';
import ConfirmCancelLineModal from './modals/ConfirmLineCancelModal';
import ReservationsService from './ReservationsService';
import OrderAttachmentsTab from './OrderAttachmentsTab';
import {Reservation} from './ReservationsInterfaces';
import ReservationDropDown from './inputs/ReservationDropDown';
import {getHasLotForDisplay, getOrderLineStatusDisplayLabel, isLineCancelable} from './helpers/OrderLines';
import {showAttachmentsTab} from './helpers/Attachments';
import {shouldShowLotInfo} from './helpers/FeatureToggles';
import OrderLineDetailModalV2 from './modals/OrderLineDetailModalV2';

interface Props {
  authenticityToken: string;
  currentCompany: Company;
  outboundOrdersService?: OutboundOrdersService;
  order?: OutboundOrder;
  adminSignedIn: boolean;
  currentEnvironment: string;
  shipperHasOmniReservation: boolean;
  distributionByLotReservationIds: string[];
  featureFlags: OMSFeatureFlags;
}

interface State {
  loadingOrder: boolean;
  loadingErrors: boolean;
  loadingLines: boolean;
  editMode: boolean;
  // labelsArray is an array of key/value pairs used during edit workflow which is
  // then translated to a hash in updateOrderParams.labels on submit
  // e.g. [['foo','bar'],...] translated to { foo: 'bar', ... }
  labelsArray: string[][];
  beforeEditLabelsArray: string[][];
  beforeEditParams: UpdateOrderParams;
  updateOrderParams: UpdateOrderParams;
  orderId: string;
  order: OutboundOrder;
  lines: OrderLine[];
  selectedOrderLineToView: OrderLine;
  selectedOrderLineIds: string[];
  showLabels: boolean;
  showConfirmCancelLinesModal: boolean;
  showOrderLineModal: boolean;
  allocationErrors: AllocationError[];
  totalLines: number;
  currentLinesPage: number;
  linesContinuationTokens: string[];
  releaseToWarehouseInProgress: boolean;
  selectedTab: TabValue;
  hasEdiFiles: boolean;
  errors?: string[];
  updatedReservation: boolean;
  newReservationId: string;
  reservations: Reservation[];
}

enum TabValue {
  lines = 'lines',
  files = 'files',
  attachments = 'attachments'
}

const tsFormat = 'MM/DD/YY h:mma';
const pageSize = 50;

class OutboundOrderDetail extends React.Component<Props & RouteProps, State> {
  private outboundOrdersService: OutboundOrdersService;
  private reservationsService: ReservationsService;

  constructor(props) {
    super(props);
    this.outboundOrdersService = props.outboundOrdersService || new OutboundOrdersService();
    this.reservationsService = new ReservationsService();
    this.state = {
      loadingOrder: false,
      loadingErrors: false,
      loadingLines: false,
      editMode: false,
      labelsArray: [],
      beforeEditLabelsArray: [],
      beforeEditParams: {},
      updateOrderParams: {},
      orderId: props.match.params.id,
      order: props.order || null,
      lines: [],
      selectedOrderLineToView: null,
      selectedOrderLineIds: [],
      showLabels: true,
      releaseToWarehouseInProgress: false,
      showConfirmCancelLinesModal: false,
      showOrderLineModal: false,
      allocationErrors: null,
      totalLines: 0,
      currentLinesPage: 1,
      linesContinuationTokens: [],
      selectedTab: TabValue.lines,
      hasEdiFiles: false,
      errors: null,
      updatedReservation: false,
      newReservationId: null,
      reservations: []
    };
  }

  public async componentDidMount() {
    if (!this.props.order) {
      this.loadOrder();
    }
    this.loadAllocationErrors();
    this.loadOrderLines();
    this.loadReservations();
  }

  public render() {
    const {
      errors,
      selectedTab,
      orderId,
      order,
      lines,
      loadingOrder,
      editMode,
      labelsArray,
      updateOrderParams,
      selectedOrderLineToView,
      selectedOrderLineIds,
      showLabels,
      showConfirmCancelLinesModal,
      showOrderLineModal,
      reservations,
      releaseToWarehouseInProgress
    } = this.state;
    let reservationUpdateControl;
    if (editMode && reservations && reservations.length > 1) {
      reservationUpdateControl = [
        <dd key="dd">Requested Reservation ID</dd>,
        <dt key="dt">
          <ReservationDropDown
            order={this.state.order}
            reservations={reservationsToOMSReservations(
              this.state.reservations,
              this.props.distributionByLotReservationIds
            )}
            initialReservationId={this.state.order.reservationId}
            onReservationUpdated={this.handleReservationIdUpdated}
          />
        </dt>
      ];
    } else if (order && order.reservationId) {
      reservationUpdateControl = [<dd key="dd">Requested Reservation ID</dd>, <dt key="dt">{order.reservationId}</dt>];
    } else {
      reservationUpdateControl = null;
    }

    return (
      <div id="fulfillment-component" className="sidebar-layout">
        <div className="container-fluid">
          <div id="page-header" className="row space-below">
            <div className="breadcrumbs col-xs-12">
              <Link to="/s/fulfillment/orders">Orders</Link>
              &nbsp;
              <i className="fa fa-angle-right"></i>
              &nbsp; Order {orderId}
            </div>
            {errors && errors.length > 0 && (
              <div className="alert alert-danger space-above space-below" role="alert">
                <ul>
                  {errors.map((e, i) => (
                    <li key={i}>{e}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="col-xs-6">
              <h1>Order {orderId}</h1>
            </div>
            <div className="col-xs-6">
              {!editMode && selectedOrderLineIds && selectedOrderLineIds.length > 0 && (
                <div className="pull-right">
                  <a className="btn cta" onClick={this.toggleCancelModal}>
                    Cancel Lines
                  </a>
                  <i
                    className="fa fa-lg fa-question-circle"
                    data-toggle="tooltip"
                    aria-hidden="true"
                    title={
                      'By cancelling, we will attempt to cancel this order line and all corresponding shipments. ' +
                      'Shipments that have been waved or shipped can no longer be cancelled. ' +
                      "If any of an order line's shipments are cancelled, we will mark the order line as cancelled, " +
                      'even if some of its shipments have not been cancelled.'
                    }
                  ></i>
                </div>
              )}
              {!editMode && order && order.state === OrderStatus.open && (
                <a className="btn pull-right" onClick={this.toggleEditMode}>
                  Edit
                </a>
              )}
              {editMode && (
                <a className="btn cta pull-right" onClick={this.editOrderInfo}>
                  Save Changes
                </a>
              )}
              {editMode && (
                <a className="btn flat pull-right" onClick={this.toggleEditMode}>
                  Cancel Edit
                </a>
              )}
            </div>
            <div className="col-xs-12">
              <Tabs tabs={this.getTabs(order)} tabEvent={this.handleTabEvent} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {selectedTab === TabValue.lines && this.displayLinesWithErrors()}
              {order && order.externalId && order.externalId.length > 0 && (
                <EdiFilesForResource
                  authenticityToken={this.props.authenticityToken}
                  currentCompany={this.props.currentCompany}
                  resourceType="outboundOrder"
                  resourceValue={order.id}
                  hide={selectedTab !== TabValue.files}
                  onFilesLoaded={this.checkFilesLoaded}
                />
              )}
              {order && (
                <OrderAttachmentsTab
                  hide={selectedTab !== TabValue.attachments}
                  outboundOrdersService={this.outboundOrdersService}
                  orderId={this.state.orderId}
                  adminSignedIn={this.props.adminSignedIn}
                  companyId={this.props.currentCompany.id}
                  orderLabels={this.state.order.labels}
                />
              )}
            </div>
          </div>
        </div>
        <div className="container sidebar">
          <div className="col-xs-12">
            <h3>Details</h3>
            <Loader loading={loadingOrder} />
            {order && [
              <dl key="release">
                {editMode &&
                  order &&
                  order.state === OrderStatus.open && [
                    <dd className="space-below">Order Override</dd>,
                    <dt>
                      <p className="release-button-description">
                        Would you like to allocate and release this order to the warehouse now?
                      </p>
                      <div
                        className="release-button-container"
                        data-toggle="tooltip"
                        title={
                          this.state.releaseToWarehouseInProgress
                            ? 'Order has already been released to the warehouse'
                            : undefined
                        }
                      >
                        <button
                          className="btn release-button"
                          onClick={this.releaseForPicking}
                          disabled={this.state.releaseToWarehouseInProgress}
                        >
                          Allocate and Release
                        </button>
                      </div>
                    </dt>
                  ]}
              </dl>,
              <dl key="labels">
                {(editMode || order.labels) && [
                  <dd key="dd" className="space-below">
                    <a className="grey2" onClick={this.toggleLabels}>
                      Labels
                      <i className={`fa fa-${showLabels ? 'minus' : 'plus'}-circle pull-right`}></i>
                    </a>
                  </dd>,
                  <dt key="dt">
                    {!editMode && showLabels && (
                      <ul className="no-padding">
                        {Object.keys(order.labels)
                          .sort()
                          .map((key, i) => {
                            return (
                              <li key={i}>
                                <b>{key}:</b> {order.labels[key]}
                              </li>
                            );
                          })}
                      </ul>
                    )}
                    {editMode && showLabels && (
                      <ul className="no-padding">
                        {labelsArray.map((label, i) => {
                          return (
                            <li key={i} className="row space-below">
                              <div className="col-xs-5">
                                <input
                                  type="text"
                                  placeholder="Key"
                                  data-index={i}
                                  name="key"
                                  value={label[0]}
                                  onChange={this.setLabelKey}
                                />
                              </div>
                              <div className="col-xs-5 no-padding">
                                <input
                                  type="text"
                                  placeholder="Value"
                                  data-index={i}
                                  name="value"
                                  value={label[1]}
                                  onChange={this.setLabelValue}
                                />
                              </div>
                              <div className="col-xs-2">
                                <a className="grey2" data-index={i} onClick={this.removeLabel}>
                                  <i className="fa fa-trash"></i>
                                </a>
                              </div>
                            </li>
                          );
                        })}
                        <li className="space-above space-below">
                          <a className="grey2" onClick={this.addLabel}>
                            <i className="fa fa-plus"></i>
                            &nbsp; New Label
                          </a>
                        </li>
                      </ul>
                    )}
                  </dt>
                ]}
              </dl>,
              <dl key="main">
                <dd>Status</dd>
                <dt>
                  <span className={`label label-default ${order.state}`}>{OrderStatusDisplay[order.state]}</span>
                </dt>

                {order.externalId && [<dd key="dd">External Order ID</dd>, <dt key="dt">{order.externalId}</dt>]}

                {order.createdAt && [
                  <dd key="dd">Created At</dd>,
                  <dt key="dt">{formatDate(order.createdAt, tsFormat)}</dt>
                ]}

                {order.createdBy && [<dd key="dd">Created By</dd>, <dt key="dt">{get(order, 'createdBy.name')}</dt>]}

                {reservationUpdateControl}

                <dd>Ship To</dd>
                <dt>
                  {editMode ? (
                    <address>
                      <input
                        type="text"
                        placeholder="Name"
                        name="recipient.name"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'recipient.name')}
                      />
                      <br />
                      <input
                        type="text"
                        placeholder="Address Line 1"
                        name="recipient.address.line1"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'recipient.address.line1')}
                      />
                      <br />
                      <input
                        type="text"
                        placeholder="Address Line 2"
                        name="recipient.address.line2"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'recipient.address.line2')}
                      />
                      <br />
                      <input
                        type="text"
                        placeholder="City"
                        name="recipient.address.city"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'recipient.address.city')}
                      />
                      <br />
                      <input
                        type="text"
                        placeholder="State / Province"
                        name="recipient.address.region"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'recipient.address.region')}
                      />
                      <br />
                      <input
                        type="text"
                        placeholder="ZIP / Postcode"
                        name="recipient.address.postcode"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'recipient.address.postcode')}
                      />
                      <br />
                      <input
                        type="text"
                        placeholder="Country"
                        name="recipient.address.country"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'recipient.address.country')}
                      />
                      <br />
                    </address>
                  ) : (
                    <address>
                      {get(order, 'recipient.name')}
                      <br />
                      {get(order, 'recipient.address.line1')}
                      <br />
                      {get(order, 'recipient.address.line2') && (
                        <span>
                          {order.recipient.address.line2}
                          <br />
                        </span>
                      )}
                      {get(order, 'recipient.address.city') && <span>{get(order, 'recipient.address.city')}, </span>}
                      {get(order, 'recipient.address.region')} {get(order, 'recipient.address.postcode')}
                      <br />
                      {get(order, 'recipient.address.country')}
                    </address>
                  )}
                </dt>

                {((!editMode && get(order, 'recipient.phone')) || editMode) && [
                  <dd key="dd">Phone</dd>,
                  <dt key="dt">
                    {editMode ? (
                      <input
                        type="text"
                        placeholder="Customer Phone"
                        name="recipient.phone"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'recipient.phone')}
                      />
                    ) : (
                      order.recipient.phone
                    )}
                  </dt>
                ]}

                {((!editMode && get(order, 'recipient.email')) || editMode) && [
                  <dd key="dd">Email</dd>,
                  <dt key="dt">
                    {editMode ? (
                      <input
                        type="text"
                        placeholder="Customer Email"
                        name="recipient.email"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'recipient.email')}
                      />
                    ) : (
                      order.recipient.email
                    )}
                  </dt>
                ]}

                {((!editMode && get(order, 'shipping.serviceType')) || editMode) && [
                  <dd key="dd">Service Type</dd>,
                  <dt key="dt">
                    {editMode ? (
                      <input
                        type="text"
                        placeholder="Service Type"
                        name="shipping.serviceType"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'shipping.serviceType')}
                      />
                    ) : (
                      order.shipping.serviceType
                    )}
                  </dt>
                ]}

                {((!editMode && get(order, 'shipping.carrierBillingAccountId')) || editMode) && [
                  <dd key="dd">Carrier Billing Account ID</dd>,
                  <dt key="dt">
                    {editMode ? (
                      <input
                        type="text"
                        placeholder="Carrier Billing Account ID"
                        name="shipping.carrierBillingAccountId"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'shipping.carrierBillingAccountId')}
                      />
                    ) : (
                      order.shipping.carrierBillingAccountId
                    )}
                  </dt>
                ]}

                {((!editMode && get(order, 'shipping.labelReference1')) || editMode) && [
                  <dd key="dd">Label Reference 1</dd>,
                  <dt key="dt">
                    {editMode ? (
                      <input
                        type="text"
                        placeholder="Label Reference 1"
                        name="shipping.labelReference1"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'shipping.labelReference1')}
                      />
                    ) : (
                      order.shipping.labelReference1
                    )}
                  </dt>
                ]}

                {((!editMode && get(order, 'shipping.labelReference2')) || editMode) && [
                  <dd key="dd">Label Reference 2</dd>,
                  <dt key="dt">
                    {editMode ? (
                      <input
                        type="text"
                        placeholder="Label Reference 2"
                        name="shipping.labelReference2"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'shipping.labelReference2')}
                      />
                    ) : (
                      order.shipping.labelReference2
                    )}
                  </dt>
                ]}

                {((!editMode && get(order, 'shipping.signatureConfirmation')) || editMode) && [
                  <dd key="dd">Signature Confirmation</dd>,
                  <dt key="dt">
                    {editMode ? (
                      <input
                        type="text"
                        placeholder="Signature Confirmation"
                        name="shipping.signatureConfirmation"
                        onChange={this.updateUpdateOrderParams}
                        value={get(updateOrderParams, 'shipping.signatureConfirmation')}
                      />
                    ) : order.shipping.signatureConfirmation ? (
                      order.shipping.signatureConfirmation
                    ) : null}
                  </dt>
                ]}

                {((!editMode && get(order, 'shipping.instructions')) || editMode) && [
                  <dd key="dd">Shipping Instructions</dd>,
                  <dt key="dt">
                    {editMode ? (
                      <textarea
                        placeholder="Shipping instructions"
                        name="shipping.instructions"
                        onChange={this.updateUpdateOrderParams}
                        defaultValue={get(updateOrderParams, 'shipping.instructions')}
                      />
                    ) : (
                      order.shipping.instructions
                    )}
                  </dt>
                ]}
              </dl>
            ]}
          </div>
        </div>
        {showConfirmCancelLinesModal && (
          <ConfirmCancelLineModal
            lines={lines.filter((l) => selectedOrderLineIds.includes(l.id))}
            toggleShowModal={this.toggleCancelModal}
            cancelLines={this.cancelOrderLines}
          />
        )}
        {showOrderLineModal &&
          (this.props.featureFlags.showNewOrderLineModal ? (
            <OrderLineDetailModalV2
              orderLine={selectedOrderLineToView}
              featureFlags={this.props.featureFlags}
              allReservationsForShipper={reservationsToOMSReservations(
                this.state.reservations,
                this.props.distributionByLotReservationIds
              )}
              toggleModal={this.toggleOrderLineModal}
            />
          ) : (
            <OrderLineDetailModal
              order={this.state.order}
              line={selectedOrderLineToView}
              authenticityToken={this.props.authenticityToken}
              currentCompany={this.props.currentCompany}
              reservations={reservationsToOMSReservations(
                this.state.reservations,
                this.props.distributionByLotReservationIds
              )}
              toggleOrderLineModal={this.toggleOrderLineModal}
              reloadOrderLine={this.reloadOrderLine}
              distributionByLotReservationIds={this.props.distributionByLotReservationIds}
              showCalculatedShipByDate={this.props.featureFlags.showCalculatedShipByDate}
            />
          ))}
      </div>
    );
  }

  private handleReservationIdUpdated = (reservationId: string) => {
    this.setState({newReservationId: reservationId || null, updatedReservation: true});
  };

  private loadOrder = async () => {
    let errors = cloneDeep(this.state.errors) || [];
    this.setState({loadingOrder: true});
    const orderResponse = await this.outboundOrdersService.getOrders({}, null, 1, this.state.orderId);
    if (orderResponse && orderResponse.errors && orderResponse.errors.length > 0) {
      const newErrors = this.outboundOrdersService.processErrors(orderResponse.errors);
      errors = errors.concat(newErrors);
    }
    const order = orderResponse.outboundOrders ? orderResponse.outboundOrders[0] : (null as OutboundOrder);
    this.setState({
      order,
      loadingOrder: false,
      selectedTab: TabValue.lines,
      errors
    });
  };

  private loadAllocationErrors = async () => {
    let errors = cloneDeep(this.state.errors) || [];
    this.setState({loadingErrors: true});
    const errorsResponse = await this.outboundOrdersService.getOrderErrors(this.state.orderId);
    if (errorsResponse && errorsResponse.errors && errorsResponse.errors.length > 0) {
      const newErrors = this.outboundOrdersService.processErrors(errorsResponse.errors);
      errors = errors.concat(newErrors);
    }
    this.setState({
      allocationErrors: errorsResponse.allocationErrors,
      loadingErrors: false,
      errors
    });
  };

  private loadOrderLines = async () => {
    let errors = cloneDeep(this.state.errors) || [];
    this.setState({loadingLines: true});
    const linesResponse = await this.outboundOrdersService.getLines({}, null, pageSize, this.state.orderId);
    if (linesResponse && linesResponse.errors && linesResponse.errors.length > 0) {
      const newErrors = this.outboundOrdersService.processErrors(linesResponse.errors);
      errors = errors.concat(newErrors);
    }
    let lines = linesResponse.lines || ([] as OrderLine[]);
    if (lines) {
      lines = this.outboundOrdersService.assignOrderLineState(lines);
    }
    this.setState({
      lines,
      linesContinuationTokens: [get(linesResponse, 'continuationToken')],
      totalLines: linesResponse.total,
      loadingLines: false,
      errors
    });
  };

  private loadReservations = async () => {
    const reservationData = await this.reservationsService.getReservations();
    if (reservationData?.data?.reservations) {
      this.setState({reservations: reservationData.data.reservations});
    } else {
      this.setState({errors: ['Error fetching reservations. Please try again later.', ...this.state.errors]});
    }
  };

  private reloadOrderLine = async () => {
    let errors;
    let lines;
    const line = this.state.selectedOrderLineToView;
    const response = await this.outboundOrdersService.getLines({}, null, 1, line.orderId);
    if (response && (!response.errors || response.errors.length === 0)) {
      lines = get(response, 'lines');
      if (lines) {
        lines = this.outboundOrdersService.assignOrderLineState(lines);
      }
      const selectedLine = lines.find((l) => l.id === line.id);
      this.setState({selectedOrderLineToView: selectedLine});
    } else if (response.errors) {
      errors = this.outboundOrdersService.processErrors(response.errors);
      this.setState({selectedOrderLineToView: null});
    }
    this.setState({
      lines,
      linesContinuationTokens: [get(response, 'continuationToken')],
      totalLines: response.total,
      loadingLines: false,
      errors
    });
  };

  private checkFilesLoaded = (hasEdiFiles: boolean) => {
    this.setState({hasEdiFiles});
  };

  private handleTabEvent = (tabKey: TabValue) => {
    if (this.state.selectedTab !== tabKey) {
      this.setState({selectedTab: tabKey});
    }
  };

  private getTabs(order: OutboundOrder): Tab[] {
    const tabs = [
      {
        active: this.state.selectedTab === TabValue.lines,
        key: TabValue.lines,
        subTitle: 'Lines',
        title: null
      }
    ];

    if (this.state.hasEdiFiles) {
      tabs.push({
        active: this.state.selectedTab === TabValue.files,
        key: TabValue.files,
        subTitle: 'Files',
        title: null
      });
    }

    const labels = order && order.labels ? order.labels : {};
    if (
      showAttachmentsTab(this.props.featureFlags.attachmentsTabEnabled, labels, this.props.shipperHasOmniReservation)
    ) {
      tabs.push({
        active: this.state.selectedTab === TabValue.attachments,
        key: TabValue.attachments,
        subTitle: 'Attachments',
        title: null
      });
    }

    return tabs;
  }

  private getOrderErrorsTableData() {
    const headers = [{className: 'id-header', element: 'Order Error Code'}, {element: 'Details'}] as TableHeader[];
    const rows = this.buildOrderErrorsRows();
    return {
      headers,
      rows
    };
  }

  private getOrderLineErrorsTableData() {
    const headers = [
      {className: 'id-header', element: 'FLEXE Line ID'},
      {element: 'Line Error Code'},
      {element: 'Details'}
    ] as TableHeader[];
    const rows = this.buildOrderLineErrorsRows();
    return {
      headers,
      rows
    };
  }

  private buildOrderErrorsRows() {
    if (this.state.allocationErrors && this.state.allocationErrors.length > 0) {
      // only want order-level allocation errors (i.e. without order line designation)
      return this.state.allocationErrors
        .filter((e) => !e.orderLineId)
        .map((e) => {
          return [
            <span key={'orderErrCode'} className="red2">
              {e.code}
            </span>,
            <span key={'orderErrDetail'} className="red2">
              {e.details}
            </span>
          ];
        });
    }
    return [];
  }

  private buildOrderLineErrorsRows() {
    if (this.state.allocationErrors && this.state.allocationErrors.length > 0) {
      // Only want line-level allocation errors (with a line id)
      return this.state.allocationErrors
        .filter((e) => !!e.orderLineId)
        .map((e) => {
          return [
            <a key="lineErrorLink" data-id={e.orderLineId} onClick={this.selectOrderLineToView}>
              {e.orderLineId}
            </a>,
            <span key="lineErrorCode" className="red2">
              {e.code}
            </span>,
            <span key="lineErrorDetail" className="red2">
              {e.details}
            </span>
          ];
        });
    }
    return [];
  }

  private getLinesTableData(showLotInfo: boolean) {
    const headers = [
      {
        className: 'select-header header-icon',
        element: (
          <input
            type="checkbox"
            checked={this.state.selectedOrderLineIds.length === this.state.lines.length}
            onChange={this.handleSelectAllLines}
          />
        )
      },
      {
        className: 'issue-header header-icon',
        element: <i className="fa fa-exclamation-triangle red3"></i>
      },
      {className: 'id-header header-10pct', element: 'FLEXE Line ID'},
      {className: 'header-12pct', element: 'External Line ID'},
      {className: `header-${showLotInfo ? '10' : '14'}pct`, element: 'Status'},
      {className: 'header-14pct', element: 'Reservation'},
      {className: 'header-10pct', element: 'SKU'},
      ...(showLotInfo ? [{className: 'header-7pct', element: 'Has Lot?'}] : []),
      {className: 'header-7pct', element: 'Quantity'},
      {className: 'header-7pct', element: 'Unit of Measure'},
      {className: 'header-10pct', element: 'Created'},
      {
        className: 'pagination-header header-remainder',
        element: (
          <Pagination
            page={this.state.currentLinesPage}
            pageSize={pageSize}
            paginationHandler={(page) => this.handleLinesPagination(page)}
            totalCount={this.state.totalLines}
          />
        )
      }
    ] as TableHeader[];
    const rows = this.buildLinesRows(showLotInfo);
    return {
      headers,
      rows
    };
  }

  private buildLinesRows(showLotInfo: boolean) {
    if (this.state.lines && this.state.lines.length > 0) {
      const rows = this.state.lines.map((line: OrderLine) => {
        const row = [
          isLineCancelable(line) ? (
            <input
              type="checkbox"
              checked={this.state.selectedOrderLineIds.indexOf(line.id) > -1}
              data-line-id={line.id}
              onChange={this.handleSelectOrderLine}
            />
          ) : null,
          line.hasErrors ? <i key={'lineErrors'} className="fa fa-exclamation-triangle red3"></i> : null,
          <a key={'lineLink'} data-id={line.id} onClick={this.selectOrderLineToView}>
            {line.id}
          </a>,
          <div key={'lineExtID'}>{line.externalId}</div>,
          getOrderLineStatusDisplayLabel(line, 'lineBadge'),
          <div>{this.getReservationToDisplay(line.requestedReservationIds, line.plannedReservationIds)}</div>,
          <div key={'lineSKU'}>{line.sku}</div>,
          ...(showLotInfo ? [<div key="lotInfo">{getHasLotForDisplay(line.inventoryLotControl)}</div>] : []),
          <div key={'lineQuant'}>{line.quantity}</div>,
          <div key={'lineUOM'}>{line.unitOfMeasure || Packaging.each}</div>,
          <div key={'lineCreated'}>{line.createdAt ? formatDate(line.createdAt, tsFormat) : null}</div>,
          <a key={'lineLink2'} data-id={line.id} onClick={this.selectOrderLineToView} className="pull-right">
            <i className="fa fa-chevron-right"></i>
          </a>
        ];
        return row;
      });
      return rows;
    }
    return [];
  }

  private getReservationToDisplay(requestedReservationIds: string[] = [], plannedReservationIds: string[] = []) {
    const reservations = new Set([...requestedReservationIds, ...plannedReservationIds]);

    if (reservations.size > 1) {
      return <i>multiple</i>;
    } else if (reservations.size === 1) {
      return this.getDisplayNameForReservation(reservations.values().next().value);
    } else {
      return '-';
    }
  }

  private getDisplayNameForReservation(reservationId: string) {
    const reservation = this.state.reservations.find((res) => res.id.toString() === reservationId);

    return reservation
      ? `${reservation.id}: ${reservation.warehouse.name}, ${reservation.warehouse.address.locality}`
      : reservationId;
  }

  private handleSelectOrderLine = async (event) => {
    const target = event.currentTarget;
    const checked = target.checked;
    const lineId = target.getAttribute('data-line-id');
    const selectedOrderLineIds = cloneDeep(this.state.selectedOrderLineIds);
    if (checked) {
      selectedOrderLineIds.push(lineId);
    } else {
      const index = selectedOrderLineIds.indexOf(lineId);
      selectedOrderLineIds.splice(index, 1);
    }
    this.setState({selectedOrderLineIds});
  };

  private handleSelectAllLines = (event) => {
    this.setState({
      selectedOrderLineIds: event.currentTarget.checked ? this.state.lines.map((l) => l.id) : []
    });
  };

  private toggleCancelModal = () => {
    this.setState({
      showConfirmCancelLinesModal: !this.state.showConfirmCancelLinesModal
    });
  };

  private toggleOrderLineModal = () => {
    this.setState({
      errors: null,
      showOrderLineModal: !this.state.showOrderLineModal
    });
  };

  private selectOrderLineToView = (event) => {
    const lineId = event.currentTarget.getAttribute('data-id');
    const selectedOrderLineToView = this.state.lines.find((l) => l.id === lineId);
    if (selectedOrderLineToView) {
      this.setState({selectedOrderLineToView, showOrderLineModal: true});
    }
  };

  private releaseForPicking = async () => {
    const response = await this.outboundOrdersService.releaseForPicking(this.state.orderId);
    let errors = [];
    if (response && response.errors && response.errors.length > 0) {
      const newErrors = this.outboundOrdersService.processErrors(response.errors);
      errors = errors.concat(newErrors);
    }
    if (errors.length === 0) {
      this.setState(
        {
          errors,
          releaseToWarehouseInProgress: true
        },
        () => {
          this.loadOrder();
          this.loadOrderLines();
        }
      );
    } else {
      this.setState({errors});
    }
  };

  private toggleEditMode = async () => {
    const order = this.state.order;
    const labels = cloneDeep(order.labels) || {};
    const labelsArray = Object.keys(labels)
      .sort()
      .map((key) => [key, labels[key]]);
    const recipient = cloneDeep(order.recipient);
    const shipping = cloneDeep(order.shipping);
    const reservationId = order.reservationId;

    const params = {labels: {}, recipient, shipping, reservationId};
    const array = labelsArray;

    if (this.state.editMode) {
      this.setState({
        newReservationId: null,
        updatedReservation: false
      });
    } else {
      this.setState({
        beforeEditParams: params,
        beforeEditLabelsArray: array
      });
    }

    this.setState({
      editMode: !this.state.editMode,
      labelsArray: array,
      updateOrderParams: params
    });
  };

  private toggleLabels = () => {
    this.setState({showLabels: !this.state.showLabels});
  };

  private removeLabel = async (event) => {
    const index = Number(event.currentTarget.getAttribute('data-index'));
    const labelsArray = cloneDeep(this.state.labelsArray);
    labelsArray.splice(index, 1);
    this.setState({labelsArray});
  };

  private addLabel = () => {
    const labelsArray = cloneDeep(this.state.labelsArray);
    labelsArray.push(['', '']);
    this.setState({labelsArray});
  };

  private setLabelKey = async (event) => {
    const key = event.currentTarget.value;
    const index = Number(event.currentTarget.getAttribute('data-index'));
    const labelsArray = cloneDeep(this.state.labelsArray);
    labelsArray[index][0] = key;
    this.setState({labelsArray});
  };

  private setLabelValue = async (event) => {
    const value = event.currentTarget.value;
    const index = Number(event.currentTarget.getAttribute('data-index'));
    const labelsArray = cloneDeep(this.state.labelsArray);
    labelsArray[index][1] = value;
    this.setState({labelsArray});
  };

  private updateUpdateOrderParams = async (event) => {
    const name = event.currentTarget.getAttribute('name');
    let value = event.currentTarget.value;
    if (value.trim() === '' || (event.currentTarget.type === 'checkbox' && !event.currentTarget.checked)) {
      value = null;
    }
    const updateOrderParams = cloneDeep(this.state.updateOrderParams);
    set(updateOrderParams, name, value);
    this.setState({updateOrderParams});
  };

  private editOrderInfo = async () => {
    let errors = [];
    let response;
    let blankOrDupKeys = false;
    const updateOrderParams = cloneDeep(this.state.updateOrderParams);
    this.state.labelsArray.forEach((label) => {
      if (
        this.state.labelsArray.some((l) => !l[0]) ||
        this.state.labelsArray.filter((l) => l[0].toLowerCase() === label[0].toLowerCase()).length > 1
      ) {
        blankOrDupKeys = true;
      } else {
        updateOrderParams.labels[label[0]] = label[1];
      }
    });

    if (this.state.updatedReservation) {
      const oldReservationId = updateOrderParams.reservationId;
      const newReservationId = this.state.newReservationId;
      set(updateOrderParams, 'reservationId', newReservationId);
    }

    if (blankOrDupKeys) {
      errors.push('No duplicate or blank keys allowed in Labels');
    } else {
      // only make update order api call if order has been updated
      if (
        !isEqual(this.state.updateOrderParams, this.state.beforeEditParams) ||
        !isEqual(this.state.labelsArray, this.state.beforeEditLabelsArray) ||
        this.state.updatedReservation
      ) {
        response = await this.outboundOrdersService.editOrder(this.state.orderId, updateOrderParams);
        if (response && response.errors && response.errors.length > 0) {
          const newErrors = this.outboundOrdersService.processErrors(response.errors);
          errors = errors.concat(newErrors);
        }
      }
    }
    if (errors.length === 0) {
      this.setState(
        {
          errors,
          editMode: false,
          labelsArray: [],
          updateOrderParams: {},
          newReservationId: null,
          updatedReservation: false
        },
        () => {
          this.loadOrder();
          this.loadOrderLines();
        }
      );
    } else {
      this.setState({errors});
    }
  };

  private cancelOrderLines = async () => {
    let errors = [];
    await Promise.all(
      this.state.selectedOrderLineIds.map(async (lineId) => {
        const l = this.state.lines.find((line) => line.id === lineId);
        // We check cancelable here because the select all checkbox makes the assumption that all lines are checked
        //  There are better ways to do this, but we'll worry about that later.
        if (l && isLineCancelable(l)) {
          const response = await this.outboundOrdersService.cancelOrderLine(l.orderId, l.id);
          if (response && response.errors && response.errors.length > 0) {
            const newErrors = this.outboundOrdersService.processErrors(response.errors);
            errors = errors.concat(newErrors);
          }
        }
      })
    );
    if (errors.length === 0) {
      this.setState({selectedOrderLineIds: []}, () => {
        this.loadOrder();
        this.loadOrderLines();
      });
    }
    this.setState({errors, showConfirmCancelLinesModal: false});
  };

  private async handleLinesPagination(page: number) {
    let errors;
    this.setState({loadingLines: true});
    const continuationToken = page > 1 ? this.state.linesContinuationTokens[page - 2] : null;
    const response = await this.outboundOrdersService.getLines({}, continuationToken, pageSize, this.state.orderId);
    const updatedContinuationTokens = this.state.linesContinuationTokens.slice();
    updatedContinuationTokens.push(get(response, 'continuationToken'));
    let lines = response.lines || ([] as OrderLine[]);
    if (lines) {
      lines = this.outboundOrdersService.assignOrderLineState(lines);
    }
    if (response && (!response.errors || response.errors.length === 0)) {
      this.setState({
        lines,
        linesContinuationTokens: updatedContinuationTokens,
        currentLinesPage: page,
        totalLines: response.total
      });
    } else if (response.errors) {
      errors = this.outboundOrdersService.processErrors(response.errors);
    }
    this.setState({errors, loadingLines: false});
  }

  private displayLinesWithErrors() {
    const {order, lines, allocationErrors, loadingLines, loadingErrors} = this.state;
    const returnTables = [];

    // TODO this is because our snapshot tests are completely broken
    //  lines is a promise here. Submitting a follow up ticket
    const cleanedLines = lines.length > 0 ? lines : [];
    const showLotInfo = shouldShowLotInfo(order, cleanedLines, this.props.distributionByLotReservationIds);
    if (lines) {
      returnTables.push([
        <Loader key="lineLoader" loading={loadingLines} />,
        <div key="lineTable" className="card col-sm-12">
          <Table tableData={this.getLinesTableData(showLotInfo)} tableClass="outbound-orders-shared-table" />
        </div>
      ]);
      if (allocationErrors) {
        returnTables.push(<Loader key="errorLoader" loading={loadingErrors} />);
        const orderErrorTable = this.getOrderErrorsTableData();
        const orderLineErrorTable = this.getOrderLineErrorsTableData();
        if (orderErrorTable.rows.length > 0) {
          returnTables.push(
            <div key="orderErrorTable" className="card col-sm-12" style={{marginTop: 15}}>
              <Table tableData={orderErrorTable} />
            </div>
          );
        }
        if (orderLineErrorTable.rows.length > 0) {
          returnTables.push(
            <div key="orderLineErrorTable" className="card col-sm-12" style={{marginTop: 15}}>
              <Table tableData={orderLineErrorTable} />
            </div>
          );
        }
      }
    }
    return returnTables;
  }
}

export default OutboundOrderDetail;
