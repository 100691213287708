import * as React from 'react';

export interface GenericErrorDisplay {
  header: string;
  details?: string[];
}

export interface Props {
  genericError: GenericErrorDisplay;
  // React doesn't like you to access or name properties key
  parentKey?: number | string;
}

/**
 * Makes an alert box for a single GenericErrorDisplay
 * @param props
 */
export const DisplayGenericError: React.FC<Props> = (props) => {
  if (!props.genericError) {
    return null;
  }

  // For errors, it's fine to use index as a key. Errors should not be
  // dynamic/constantly changing such that performance causes issues.
  const body = (
    <span className="no-top-margin">
      {props.genericError.header}
      {props.genericError.details && (
        <ul>
          {props.genericError.details.map((detail: string, index: number) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
      )}
    </span>
  );

  if (props.parentKey) {
    return (
      <div className="alert alert-danger" role="alert" key={`${props.parentKey}_detail`}>
        {body}
      </div>
    );
  }
  return (
    <div className="alert alert-danger" role="alert">
      {body}
    </div>
  );
};

function buildDisplay(genericError: GenericErrorDisplay, index: number): JSX.Element {
  const key = `generic_error_${index}`;
  return <DisplayGenericError genericError={genericError} parentKey={key} key={key} />;
}

/**
 * Makes an alert box for each GenericErrorDisplay in a list
 * @param genericErrors
 */
export function DisplayGenericErrors(genericErrors: GenericErrorDisplay[]) {
  if (!genericErrors || genericErrors.length < 1) {
    return null;
  }

  // For errors, it's fine to use index as a key. Errors should not be
  // dynamic/constantly changing such that performance causes issues.
  return genericErrors.map((genericError: GenericErrorDisplay, index: number) => buildDisplay(genericError, index));
}

/**
 * Makes an alert box for each GenericErrorDisplay in a list,
 *  where each box is wrapped in a div as well
 * @param genericErrors
 * @param wrapperClassName The class to apply to the div around each box
 */
export function DisplayGenericErrorsWithWrapper(genericErrors: GenericErrorDisplay[], wrapperClassName: string) {
  if (!genericErrors || genericErrors.length < 1) {
    return null;
  }

  // For errors, it's fine to use index as a key. Errors should not be
  // dynamic/constantly changing such that performance causes issues.
  return genericErrors.map((genericError: GenericErrorDisplay, index: number) => (
    <div className={wrapperClassName} key={`generic_error_${index}_wrapper`}>
      {buildDisplay(genericError, index)}
    </div>
  ));
}
