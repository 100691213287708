import * as React from 'react';
import {LegacyModal, Table} from '@flexe/ui-components';
import {CarrierAccount, EnableReservationParams} from '../SharedInterfaces';
import {Reservation} from '../../../reservations/SharedInterfaces';

interface EnableReservationProps {
  account: CarrierAccount;
  reservations: Reservation[];
  enableReservationParams: EnableReservationParams;
  enableReservation: () => Promise<void>;
  toggleEnableReservationModal: () => void;
  handleReservationChange: (e) => void;
  addReservation: () => void;
  removeReservation: (e) => void;
}

export default function EnableReservationModal({
  account,
  reservations,
  enableReservationParams,
  enableReservation,
  handleReservationChange,
  toggleEnableReservationModal,
  addReservation,
  removeReservation
}: EnableReservationProps) {
  const reservationOptions = GetReservationOptions(reservations, enableReservationParams);
  const tableData = GetTableData(reservations, enableReservationParams, removeReservation);
  return (
    <LegacyModal
      id="enable_reservation_modal"
      title={'Enable Reservation'}
      show={true}
      size="small"
      toggleModal={toggleEnableReservationModal}
      footer={
        <EnableReservationFooter
          enableReservation={enableReservation}
          toggleEnableReservationModal={toggleEnableReservationModal}
        />
      }
      disableClose={false}
    >
      <div>
        {reservationOptions && reservationOptions.length > 0 && (
          <div className="row">
            <label className="col-sm-10">
              <select
                name="reservation"
                id="reservation-select"
                data-testid="reservation-select"
                onChange={handleReservationChange}
              >
                <option value="">Select a reservation...</option>
                {reservationOptions}
              </select>
            </label>
            <div className="col-sm-2">
              <button className="btn pull-right" onClick={addReservation}>
                Add
              </button>
            </div>
          </div>
        )}
        <hr />
        {tableData.rows && tableData.rows.length > 0 && (
          <div className="row">
            <h5>
              Enabled for Carrier Account {account.carrierDisplayName} - {account.accountNumber}
            </h5>
            <Table tableClass="table-striped" tableData={tableData} />
          </div>
        )}
      </div>
    </LegacyModal>
  );
}

interface EnableReservationFooterProps {
  enableReservation: () => Promise<void>;
  toggleEnableReservationModal: () => void;
}

function EnableReservationFooter({enableReservation, toggleEnableReservationModal}: EnableReservationFooterProps) {
  return (
    <div>
      <a onClick={toggleEnableReservationModal}>Cancel</a>
      <button type="button" className="btn" onClick={enableReservation}>
        Save Changes
      </button>
    </div>
  );
}

function GetReservationOptions(reservations: Reservation[], enableReservationParams: EnableReservationParams): any {
  return reservations
    .filter((reservation) => {
      return !enableReservationParams.reservationsToEnable.includes(reservation.id);
    })
    .map((reservation) => {
      return (
        <option key={reservation.id} value={reservation.id}>
          {`${reservation.id} - ${reservation.warehouseName}`}
        </option>
      );
    });
}

function GetEnabledReservations(
  reservations: Reservation[],
  enableReservationParams: EnableReservationParams
): Reservation[] {
  return reservations.filter((reservation) => {
    return enableReservationParams.reservationsToEnable.includes(reservation.id);
  });
}

function GetTableData(
  reservations: Reservation[],
  enableReservationParams: EnableReservationParams,
  removeReservation: (e) => void
) {
  const headers = [{element: 'Reservation'}, {element: 'Reservation Nick Name'}, {element: 'Warehouse'}, {element: ''}];
  let rows;
  const enabledReservations = GetEnabledReservations(reservations, enableReservationParams);
  if (enabledReservations && enabledReservations.length > 0) {
    rows = enabledReservations.map((reservation) => {
      const row = [
        <div>{reservation.id}</div>,
        <div>{reservation.nickname}</div>,
        <div>{reservation.warehouseName}</div>,
        <div>
          <a data-id={reservation.id} data-testid={`${reservation.id}-remove`} onClick={removeReservation}>
            <i className="fa fa-times red3"></i>
          </a>
        </div>
      ];
      return row;
    });
  } else {
    rows = [];
  }
  return {
    headers,
    rows
  };
}
