import * as React from 'react';
import {FC, useState, useEffect, useMemo, useRef} from 'react';
import {Box, Tab, Tabs} from '@mui/material';
import OmniReportingService from '../shared/services/OmniReportingService';

interface Props {
  authenticityToken: string;
}

const OmniReporting: FC<Props> = (props) => {
  const {authenticityToken} = props;

  const omniReportingService = useMemo(() => new OmniReportingService(authenticityToken), [authenticityToken]);
  const [iFrameSrc, setIFrameSrc] = useState<string>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [tabValue, setTabValue] = React.useState(0);
  const errorsRef = useRef(null);
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  useEffect(() => {
    getReport();
  }, [authenticityToken]);

  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions, @typescript-eslint/no-shadow
  function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const getReport = async () => {
    try {
      const response = await omniReportingService.getDashboard('warehouse_kpis');

      if (response.data?.url) {
        setIFrameSrc(response.data.url);
      }

      if (response.errors && response.errors.length > 0) {
        response.errors.map((e) => {
          setErrors([e.detail.toString()].concat(errors || []));
        });
      }
    } catch (e) {
      if (typeof e === 'string') {
        setErrors([e].concat(errors || []));
      } else if (e instanceof Error) {
        setErrors([e.message].concat(errors || []));
      }
    }
  };

  const handleChange = async (event: React.SyntheticEvent, newValue: number) => {
    await getReport();
    setTabValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      'id': `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  return (
    <div id="omnichannel-shipment-details-page">
      <h2 style={{padding: '10px', marginBottom: '0px'}}>Performance & Reporting</h2>
      <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            <Tab sx={{fontSize: 14}} label="Warehouse KPIs" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          {errors && errors.length > 0 && (
            <div className="alert alert-danger popup-error" role="alert" ref={errorsRef}>
              <ul>
                {errors.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ul>
            </div>
          )}
          {errors && errors.length === 0 && <iframe src={iFrameSrc} width={'100%'} style={{height: '100vh'}} />}
        </CustomTabPanel>
      </Box>
    </div>
  );
};
export default OmniReporting;
