import * as React from 'react';
import {get} from 'lodash';
import * as moment from 'moment-timezone';
import {Document} from './CommonInterfaces';
import DocumentUploadModal from './DocumentUploadModal';

interface Props {
  documents: Document[];
  documentsRoute: string;
  notableType: string;
  notableId: number;
  showUploadModal: boolean;
  warehouseTimeZone?: string;
  showDocumentCreationTime?: boolean;
  toggleUploadModal();
  onDocumentSaved(savedDoc: Document);
}

export const DocumentManagerSmall: React.FC<Props> = (props) => {
  return (
    <div className="document-manager-small">
      <h4 className="text-uppercase">
        <i className="fa fa-file grey3"></i>&nbsp;Documents &amp; Labels
      </h4>

      {props.documents &&
        props.documents.map((doc, idx) => {
          const fileName = get(doc, 'note.name');
          const fileUrl = get(doc, 'note.url');
          let formattedTime = '';
          if (props.warehouseTimeZone !== '') {
            const fileCreatedAt = moment(doc.createdAt)
              .tz(props.warehouseTimeZone)
              .format('MM/DD/YYYY h:mm A z');
            formattedTime = ` (at ${fileCreatedAt})`;
          }

          return (
            <table key={idx} className="packing-list-table space-below">
              <thead>
                <tr>
                  <th colSpan={2}>
                    <b>{doc.documentType}</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href={fileUrl} target="_blank">
                      {fileName}
                    </a>
                    {props.showDocumentCreationTime && formattedTime}
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}

      <a className="add-documents" onClick={props.toggleUploadModal}>
        <i className="fa fa-plus"></i> Add documents
      </a>

      <DocumentUploadModal
        documents={props.documents}
        documentsRoute="/api/v2/documents"
        notableType={props.notableType}
        notableId={props.notableId}
        showUploadModal={props.showUploadModal}
        toggleUploadModal={props.toggleUploadModal}
        onDocumentSaved={props.onDocumentSaved}
      />
    </div>
  );
};

DocumentManagerSmall.defaultProps = {
  warehouseTimeZone: '',
  showDocumentCreationTime: false
};

export default DocumentManagerSmall;
