import * as React from 'react';
import FileStorageService from './FileStorageService';

interface Props {
  fileStorageService: FileStorageService;
  fileKey: string;
}

interface State {
  content: string;
}

// This class is a hot fix so that file storage content can be viewed and redirected to the blob contents
// with the response data. Ideally, we have a better view/editor for these needs, but this is to maintain
// the exiting ability to right click download buttons and open in a new tab.
class FileStorageContent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    this.loadContents();
  }

  public render() {
    return <div>redirecting...</div>;
  }

  private redirectToFileContents(contents) {
    // HACK to create the contents of the current file storage key as this page's content
    window.location.href = window.URL.createObjectURL(new Blob([contents]));
  }
  private async loadContents() {
    const {fileStorageService, fileKey} = this.props;
    await fileStorageService
      .downloadByFileKey(fileKey, {
        responseType: 'blob',
        timeout: 30000
      })
      .then((response) => {
        try {
          // attempt to capture the blob if exists
          response.blob().then((responseBlob) => {
            // HACK to create the contents of the current file storage key as this page's content
            window.location.href = window.URL.createObjectURL(responseBlob);
          });
        } catch (e) {
          // HANDLE where the returned response (not always a blob), is actually a raw json.
          // attempt to capture if this is a json object, and stringify contents, or assign
          // the contents of the file as is.
          let contents = response;
          try {
            contents = JSON.stringify(response);
            // hack because typeof throws an error here, want to check if the json string is actually an
            // encoded string vs. encoded json
            if (contents[0] === '"') {
              contents = response;
            }
          } finally {
            // ensure it always runs, and do not worry about the catch.
          }
          // We default the contents to be the response, and the catch above is only for validatng
          // that the content if json is valid.
          // TODO: Make a better experience than overwriting the page contents
          this.redirectToFileContents(contents);
        }
      })
      .catch((err) => {
        // TODO: Make a better experience than overwriting the page contents
        this.redirectToFileContents(err);
      });
  }
}

export default FileStorageContent;
