import * as React from 'react';
import {ChangeEvent, ReactNode} from 'react';
import {Loader} from '@flexe/ui-components';
import {OrderInventoryLotControl, OrderLine, OutboundOrder, UpdateLineParams} from '../OutboundOrdersInterfaces';
import {OMSReservation} from '../../ecommerce-orders/OrdersAppInterfaces';
import {getLotExpirationDateForDisplay, isLineEditable} from '../helpers/OrderLines';
import {Packaging} from '../../../shared/CommonInterfaces';
import DropDown, {DropDownColor, DropDownOption, DropDownStyle} from '../../../shared/DropDown';
import ReservationDropDown from './ReservationDropDown';
import CurrencyDropdown from './CurrencyDropdown';

interface Props {
  order: OutboundOrder;
  orderLine: OrderLine;
  saveEdits: (_) => void; // TODO this will possibly return errors
  allReservationsForShipper: OMSReservation[];
}

type ComponentMode = 'Read-Only' | 'Edit' | 'Loading / Processing';

interface ContentsState {
  mode: ComponentMode;
  updatedValues: UpdateLineParams;
}

const buildUneditedLineInfo = (orderLine: OrderLine, mode: ComponentMode = 'Read-Only'): ContentsState => {
  return {
    mode,
    updatedValues: {
      ...orderLine,
      unitOfMeasure: orderLine.unitOfMeasure ? orderLine.unitOfMeasure : 'each'
    }
  };
};

const buildContentsList = (
  order: OutboundOrder,
  orderLine: OrderLine,
  showLotInfo: boolean,
  contentsState: ContentsState,
  setNewLineValues: SetNewLineValues,
  allReservationsForShipper: OMSReservation[]
) => {
  const requestedReservationCell = buildRequestedReservationCell(
    order,
    orderLine,
    contentsState,
    setNewLineValues,
    allReservationsForShipper
  );
  const skuCell = buildSkuCell(orderLine, contentsState, setNewLineValues);
  const lotCodeCell = buildLotCodeCell(orderLine, contentsState, setNewLineValues);
  const expirationDateCell = buildSimpleContentsDisplayCell({
    rawValue: getLotExpirationDateForDisplay(orderLine.inventoryLotControl)
  }); // TODO when we populate order and support lot really
  const quantityCell = buildQuantityCell(orderLine, contentsState, setNewLineValues);
  const itemPriceCell = buildItemPriceCell(orderLine, contentsState, setNewLineValues);

  return (
    <table className="table outbound-orders-shared-table">
      <tbody>
        {buildContentsListRow('Requested Reservation', requestedReservationCell)}
        {buildContentsListRow('SKU', skuCell)}
        {showLotInfo && buildContentsListRow('Lot Code', lotCodeCell)}
        {showLotInfo && buildContentsListRow('Expiration Date', expirationDateCell)}
        {buildContentsListRow('Quantity', quantityCell)}
        {buildContentsListRow('Item Price per Unit & Currency', itemPriceCell)}
      </tbody>
    </table>
  );
};

const buildLotCodeCell = (
  orderLine: OrderLine,
  contentsState: ContentsState,
  setNewLineValues: SetNewLineValues
): ReactNode => {
  if (contentsState.mode === 'Edit') {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value;
      // Ensure blank inputs are treated as undefined
      const lotCode = newValue && newValue.trim() === '' ? undefined : newValue;
      const inventoryLotControl: OrderInventoryLotControl = {
        lotCode,
        expirationDate: contentsState.updatedValues.inventoryLotControl?.expirationDate
      };
      setNewLineValues(inventoryLotControl);
    };

    return (
      <div>
        <input
          type="text"
          placeholder="Lot Code"
          name="lotCode"
          onChange={onChange}
          value={contentsState.updatedValues.inventoryLotControl?.lotCode}
        />
      </div>
    );
  }

  return buildSimpleContentsDisplayCell({rawValue: orderLine.inventoryLotControl?.lotCode});
};

const buildSkuCell = (
  orderLine: OrderLine,
  contentsState: ContentsState,
  setNewLineValues: SetNewLineValues
): ReactNode => {
  if (contentsState.mode === 'Edit') {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value;
      // Ensure blank inputs are treated as undefined
      const sku = newValue && newValue.trim() === '' ? undefined : newValue;
      setNewLineValues({sku});
    };

    return (
      <div>
        <input type="text" placeholder="SKU" name="sku" onChange={onChange} value={contentsState.updatedValues.sku} />
      </div>
    );
  }

  return buildSimpleContentsDisplayCell({rawValue: orderLine.sku});
};

const buildRequestedReservationCell = (
  order: OutboundOrder,
  orderLine: OrderLine,
  contentsState: ContentsState,
  setNewLineValues: SetNewLineValues,
  allReservationsForShipper: OMSReservation[]
): ReactNode => {
  if (contentsState.mode === 'Edit') {
    const handleReservationIdUpdated = (reservationId: string) => setNewLineValues({reservationId});
    return (
      <div>
        <ReservationDropDown
          order={order}
          reservations={allReservationsForShipper}
          initialReservationId={orderLine.reservationId}
          onReservationUpdated={handleReservationIdUpdated}
        />
      </div>
    );
  }

  return buildSimpleContentsDisplayCell({
    rawValue: orderLine.reservationId,
    defaultValue: 'not specified'
  });
};

const buildSimpleContentsDisplayCell = (value: ContentsValue) => {
  const displayValue = value.rawValue || value.defaultValue;

  return (
    <div>
      <span>{displayValue}</span>
    </div>
  );
};

const uomOptionEach = {name: Packaging.each, value: Packaging.each};
const uomOptions = [uomOptionEach, {name: Packaging.carton, value: Packaging.carton}];

const matchUomOption = (uom: string) => uomOptions.find((option) => option.value === uom) || uomOptionEach;

const buildQuantityCell = (orderLine: OrderLine, contentsState: ContentsState, setNewLineValues: SetNewLineValues) => {
  if (contentsState.mode === 'Edit') {
    const onQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
      const quantity = Number(event.currentTarget.value);
      setNewLineValues({quantity});
    };

    const onUomChange = (option: DropDownOption) => setNewLineValues({unitOfMeasure: option.value});

    return (
      <div>
        <input
          type="number"
          placeholder="Quantity"
          name="quantity"
          onChange={onQuantityChange}
          value={contentsState.updatedValues.quantity}
        />
        &nbsp;
        <DropDown
          color={DropDownColor.white}
          options={uomOptions}
          style={DropDownStyle.matchFont}
          selected={matchUomOption(contentsState.updatedValues.unitOfMeasure)}
          onSelect={onUomChange}
        />
      </div>
    );
  }

  const uom = orderLine.unitOfMeasure ? orderLine.unitOfMeasure : Packaging.each;
  return (
    <div>
      <span>
        {orderLine.quantity} {uom}
      </span>
    </div>
  );
};

const buildItemPriceCell = (orderLine: OrderLine, contentsState: ContentsState, setNewLineValues: SetNewLineValues) => {
  if (contentsState.mode === 'Edit') {
    const onItemPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.currentTarget.value;
      const itemPrice = rawValue ? Number(rawValue) : undefined;
      setNewLineValues({itemPrice});
    };

    const onItemCurrencyChange = (itemCurrency?: string) => setNewLineValues({itemCurrency});

    return (
      <div>
        <input
          type="number"
          placeholder="Price"
          name="price"
          min={0}
          step={0.01}
          onChange={onItemPriceChange}
          value={contentsState.updatedValues.itemPrice}
        />
        &nbsp;
        <CurrencyDropdown
          selectedCurrencyValue={contentsState.updatedValues.itemCurrency}
          onCurrencyUpdated={onItemCurrencyChange}
        />
      </div>
    );
  }

  const displayValue =
    orderLine.itemPrice || orderLine.itemCurrency ? (
      <>
        {orderLine.itemPrice} {orderLine.itemCurrency}
      </>
    ) : (
      <i>not specified</i>
    );

  return (
    <div>
      <span>{displayValue}</span>
    </div>
  );
};

interface ContentsValue {
  rawValue?: string | number;
  defaultValue?: string;
}
const buildContentsListRow = (label: string, value: string | ReactNode) => {
  return (
    <tr className="tight borderless">
      <td className="header-10pct">
        <b>{label}:</b>
      </td>
      <td className="header-25pct">
        <div className="subtle-hover">{value}</div>
      </td>
    </tr>
  );
};

type SetNewLineValues = (valuesToSet: object) => void;

const OrderLineContents: React.FC<Props> = (props: Props) => {
  const {orderLine} = props;
  const [contentsState, setContentsState] = React.useState<ContentsState>(buildUneditedLineInfo(orderLine));

  if (contentsState.mode === 'Loading / Processing') {
    return <Loader loading={true} />;
  }

  const onClickEdit = () => setContentsState({...contentsState, mode: 'Edit'});
  const onClickCancel = () => setContentsState(buildUneditedLineInfo(props.orderLine));

  // It would be nice to strongly type valuesToSet, but I don't see a clean way
  //  So just use object for now
  const setNewLineValues: SetNewLineValues = (valuesToSet: object) => {
    const updatedValues = {...contentsState.updatedValues, ...valuesToSet};
    setContentsState({...contentsState, updatedValues});
  };

  const onClickSave = () => {
    props.saveEdits(contentsState.updatedValues);
    setContentsState({...contentsState, mode: 'Loading / Processing'});
    // TODO actually save and error handle
    setContentsState(buildUneditedLineInfo(props.orderLine));
    // TODO finish and error handle
  };

  const editButton =
    contentsState.mode === 'Read-Only' && isLineEditable(orderLine) ? (
      <a onClick={onClickEdit}>
        <i className="fa fa-pencil" aria-hidden={true}></i>
      </a>
    ) : (
      undefined
    );

  // TODO remove when we support lotinfo
  const placeholderBoolean = false;

  const finishEditButtons =
    contentsState.mode === 'Edit' ? (
      <div className="no-hover update-button-container">
        <a className="btn cta" onClick={onClickSave} aria-disabled={false}>
          Update
        </a>
        <a className="btn cta" onClick={onClickCancel} aria-disabled={false}>
          Cancel
        </a>
      </div>
    ) : (
      undefined
    );

  return (
    <>
      <h4>
        Contents
        {editButton}
      </h4>
      {buildContentsList(
        props.order,
        orderLine,
        placeholderBoolean,
        contentsState,
        setNewLineValues,
        props.allReservationsForShipper
      )}
      {finishEditButtons}
    </>
  );
};
export default OrderLineContents;
