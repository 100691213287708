import * as React from 'react';

interface InputProperties {
  inputLabel: string;
  warningMessage?: string;
  maxNum?: number;
}
interface InputStates {
  isValid?: boolean;
}
class InputWithWarning extends React.Component<InputProperties, InputStates> {
  constructor(props) {
    super(props);
    this.state = {isValid: true};
    this.validateInput = this.validateInput.bind(this);
  }

  public render() {
    let displayError;
    if (!this.state.isValid) {
      displayError = (
        <p style={{color: 'red', marginBottom: '0px', fontWeight: 500}}>
          <i className="fa fa-warning"></i> {this.props.warningMessage}
        </p>
      );
    }
    return (
      <div>
        <label className="col-sm-12 space-below">
          {this.props.inputLabel} <br />
          <input
            type="number"
            min="0"
            placeholder="Pallets built..."
            name="num_pallets"
            style={{width: '50%'}}
            onChange={this.validateInput}
          />
          {displayError}
        </label>
      </div>
    );
  }

  private validateInput(e): void {
    if (this.props.maxNum && e.target.value > this.props.maxNum) {
      this.setState({isValid: false});
    } else {
      this.setState({isValid: true});
    }
  }
}

export default InputWithWarning;
