import * as React from 'react';
import classNames from 'classnames';

interface Props {
  definitions: {term: string; definition: React.ReactNode}[];
  termWeight?: 'semi-bold' | 'normal';
  italicizeDefinitions?: boolean;
  singleLine?: boolean;
}

const DefinitionList: React.FC<Props> = (props: Props) => {
  const pairClasses = ['pair', props.singleLine && 'single-line'];

  const termClasses = [
    'term',
    props.termWeight === 'semi-bold' && 'semi-bold',
    props.termWeight === 'normal' && 'de-bold',
    props.singleLine && 'single-line'
  ];

  const definitionClasses = ['definition', props.italicizeDefinitions && 'italics', props.singleLine && 'single-line'];

  return (
    <dl className="definition-list">
      {props.definitions.map((definition) => {
        return (
          <div key={`${definition.term}`} className={classNames(pairClasses)}>
            <dt className={classNames(termClasses)}>{definition.term}:</dt>
            {props.singleLine && <React.Fragment> </React.Fragment>}
            <dd className={classNames(definitionClasses)}>{definition.definition}</dd>
          </div>
        );
      })}
    </dl>
  );
};

export default DefinitionList;
