import {ActorInfo, AddressData} from '../../shared/CommonInterfaces';
import {Reservation} from '../../reservations/SharedInterfaces';

export interface Carriers {
  [index: string]: CarrierDetails;
}

export interface CarrierDetails {
  displayName: string;
  fields: CarrierField[];
  usageNotes: string;
}

export interface CarrierField {
  name: string;
  displayName: string;
  dataName: string;
  type: string;
  optional: boolean;
  readOnly: boolean;
  default: object;
  editable: boolean;
  [key: string]: any;
}

export interface CreateParams {
  carrier: string;
  description: string;
  accountReference: string;
  accountInfo: object;
}

export interface UpdateParams {
  carrier: string;
  description: string;
  accountReference: string;
  accountInfo: object;
}

export interface EnableReservationParams {
  reservationsToEnable: number[];
  shipperOwnedCarrierAccount: boolean;
}

export interface ReservationBrief {
  id: number;
  name: string;
}

export interface CarrierAccount {
  carrierAccountId: number;
  carrier: string;
  carrierUserId: string;
  carrierDisplayName: string;
  description: string;
  ownerCompanyId: number;
  ownerCompanyName: string;
  accountNumber: string;
  reservations: ReservationBrief[];
  txnState: 'disabled' | 'enabled' | 'pending';
  shipperOwned: boolean;
  currentProblems: string[];
  accountReference: string;
  externalAccountInfo: {[key: string]: any};
}

export interface CarrierAccountStateV2 {
  accounts: CarrierAccount[];
  carrierDefinitions: Carriers;
  selectedCarrier: CarrierDetails;
  selectedAccount: CarrierAccount;
  reservationToEnable: number;
  loading: boolean;
  errors: string[];
  popupErrors: string[];
  successMessage: string;
  createAccountParams: CreateParams;
  updateAccountParams: UpdateParams;
  enableReservationParams: EnableReservationParams;
  showViewAccountModal: boolean;
  showCreateAccountModal: boolean;
  showDeleteAccountModal: boolean;
  showUpdateAccountModal: boolean;
  showEnableReservationModal: boolean;
  updateModalButtonDisabled: boolean;
  reservations: Reservation[];
  isProcessing: boolean;
  showDocumentationModal: boolean;
}

export interface CarrierBillingAccount {
  id: string;
  friendlyName: string;
  carrier: 'ups' | 'fedex' | 'ontrac' | '';
  country: string;
  accountId: string;
  postcode: string;
  companyChannelId?: string;
}

export enum CarrierBillingAccountField {
  id = 'id',
  friendlyName = 'friendlyName',
  carrier = 'carrier',
  country = 'country',
  accountId = 'accountId',
  postcode = 'postcode',
  companyChannelId = 'companyChannelId'
}

export interface CarrierBillingAccountServiceInterface {
  getAccounts(): Promise<{errors: string[]; accounts: CarrierBillingAccount[]}>;
  createAccount(account: CarrierBillingAccount): Promise<{errors: string[]}>;
  updateAccount(account: CarrierBillingAccount): Promise<{errors: string[]}>;
  deleteAccount(account: CarrierBillingAccount): Promise<{errors: string[]}>;
}

export interface VendorServiceInterface {
  getVendors(): Promise<Vendor[]>;
}

export interface Vendor {
  vendorName: string;
  returnAddress: AddressData;
  id: number;
}

export interface Template {
  id: number;
  templateType: string;
  name: string;
  url: string;
  active: boolean;
  createdBy: ActorInfo;
  createdAt: string;
  generic: boolean;
}

export interface UploadParams {
  templateName: string;
  templateFile: object;
}

export interface PackingSlipState {
  templates: Template[];
  loading: boolean;
  errors: any;
  showUploadModal: boolean;
  showDeactivateModal: boolean;
  showDeleteModal: boolean;
  deleteTemplateId: number;
  activeTemplateId: number;
  genericTemplateUrl: string;
  uploadParams: UploadParams;
  fileSelectErrors: any;
}

export interface ShippoSettings {
  hasApiKey: boolean;
  shippoApiKey?: string;
}
