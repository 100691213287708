import * as React from 'react';
import RetailFulfillmentService, {
  BuildPalletsRequest,
  RetailOrderDetails
} from '../../shared/services/RetailFulfillmentService';
import {ApiResponse, Document, DocumentType} from '../../shared/CommonInterfaces';

interface BuildPalletsStepProps {
  orderDetails: RetailOrderDetails;
  retailFulfillmentService: RetailFulfillmentService;
  documents: Document[];
  recommendedPalletQuantity: number;
  onSuccess: (successMessage: string) => void;
  onError: (errorMessage: string) => void;
}

export const BuildPalletsStep: React.FC<BuildPalletsStepProps> = (props) => {
  const [palletsBuilt, setPalletsBuilt] = React.useState(0);
  const [fieldValidationError, setFieldValidationError] = React.useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true);

  async function buildPallets() {
    const orderId: number = props.orderDetails.id;
    const request: BuildPalletsRequest = {
      numPallets: palletsBuilt
    };

    const response: ApiResponse<any> = await props.retailFulfillmentService.buildPallets(orderId, request);

    if (response.errors) {
      const errorMessage: string = response.errors[0].detail;
      props.onError(errorMessage);
      return;
    }

    props.onSuccess('Submitted pallet quantity.');
  }

  const buildInstructionsPrompt = () => {
    const buildInstructionsDocs = props.documents.filter((document: Document) => {
      return document.documentType === DocumentType.retailBuildInstructions;
    });

    if (buildInstructionsDocs.length > 0) {
      return (
        <React.Fragment>
          <p>Print and distribute the following build instruction(s):</p>
          {buildInstructionsDocumentList(buildInstructionsDocs)}
          <hr />
        </React.Fragment>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const buildInstructionsDocumentList = (documents: Document[]) => {
    return documents.map((document: Document, index: number) => {
      return (
        <p key={`build-instructions-${index}`}>
          <i className="fa fa-print"></i>&nbsp;
          <a href={document.note.url} target="_blank">
            {document.note.name}
          </a>
        </p>
      );
    });
  };

  const buildPalletsStep = () => {
    if (
      ['new', 'confirmed'].includes(props.orderDetails.state) ||
      props.orderDetails.palletsBuilt > 0 ||
      props.orderDetails.shipmentType === 'parcel'
    ) {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <React.Fragment>
        <div className="col-sm-4">
          <div className="row step-wrap">
            <h4 className="col-sm-12">Pick Pending</h4>
            <div className="col-sm-12">
              {buildInstructionsPrompt()}
              <p>Once inventory has been picked and palletized, submit the number of pallets built for this order:</p>
            </div>
            <label className="col-sm-12">
              Pallets Built (Recommended Quantity: {props.recommendedPalletQuantity})<br />
              <input
                type="number"
                min="0"
                id="pallets-built-input"
                name="pallets_built"
                value={palletsBuilt}
                onChange={(event) => setPalletsBuilt(Number(event.target.value))}
              />
            </label>
            <div className="col-sm-12 space-below">
              {fieldValidationError && (
                <span id="build-pallets-field-validation-error" className="validation-error">
                  {fieldValidationError}
                </span>
              )}
            </div>
            <div className="col-sm-7">
              <button
                id="build-pallets-button"
                data-testid="build-pallets-button"
                disabled={submitButtonDisabled}
                onClick={() => buildPallets()}
              >
                <i className="fa fa-check"></i>
                &nbsp;Submit Pallet Quantity
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  React.useEffect(() => {
    if (palletsBuilt > props.recommendedPalletQuantity) {
      setFieldValidationError('Warning: Specified pallet quantity is more than recommended quantity');
    } else {
      setFieldValidationError(null);
    }

    if (palletsBuilt > 0) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  }, [palletsBuilt]);

  return buildPalletsStep();
};

export default BuildPalletsStep;
