import {Loader, Pagination} from '@flexe/ui-components';
import * as React from 'react';
import {useEffect, useState} from 'react';
import WarehouseSelector from '../../shared/WarehouseSelector';
import {Reservation, SortOption, SortOrder, TransitionState, Warehouse} from '../../shared/CommonInterfaces';
import BatchWavingService from '../../shared/services/BatchWavingService';
import WarehouseService from '../../shared/services/WarehouseService';
import PageReloader from '../batch-waving/PageReloader';
import WarehouseReservationUtil from '../../shared/utilities/WarehouseReservationUtil';
import SelectorWithCheckbox, {SelectOption} from '../../shared/SelectorWithCheckbox';
import {TransitionStateDisplay} from '../../shared/constants';
import AlertWavingIssues from '../batch-waving/AlertWavingIssues';
import BatchSchedulingModal from './BatchSchedulingModal';
import BatchFiltersLabelGroup from './BatchFiltersLabelGroup';
import AdditionalBatchFilters from './AdditionalBatchFilters';
import CompactBatchStatusTabs from './CompactBatchStatusTabs';
import CompactBatchTile from './CompactBatchTile';
import {BatchData, BatchesRequest, CompactBatchCounts} from './BatchInterfaces';

interface CompactBatchListProps {
  selectedWarehouse: Warehouse;
  warehouses: Warehouse[];
  carriers: object;
  batchService: BatchWavingService;
  warehouseService: WarehouseService;
  isSchedulingModalEnabled: boolean;
  isAddBatchScheduleEnabled: boolean;
  isFreightWorkflowEnabled: boolean;
  isHidePrintedFilterEnabled: boolean;
  isTrailerLoadingEnabled: boolean;
  onWarehouseSelect(warehouse: Warehouse);
}

const CompactBatchList: React.FC<CompactBatchListProps> = (props) => {
  const initialCounts = {
    active: null,
    new: null,
    not_started: null,
    in_progress: null
  };

  const defaultSortOption = 'createdAtDesc';

  const sortByOptionsMap: Map<string, SortOption> = new Map([
    [
      'createdAtDesc',
      {
        displayName: 'Newest First',
        sortBy: 'created_at',
        sortOrder: SortOrder.DESC
      }
    ],
    [
      'createdAtAsc',
      {
        displayName: 'Oldest First',
        sortBy: 'created_at',
        sortOrder: SortOrder.ASC
      }
    ],
    [
      'packStartedAsc',
      {
        displayName: 'Pack Started Earliest',
        sortBy: 'pack_started_at',
        sortOrder: SortOrder.ASC
      }
    ],
    [
      'packStartedDesc',
      {
        displayName: 'Pack Started Latest',
        sortBy: 'pack_started_at',
        sortOrder: SortOrder.DESC
      }
    ],
    [
      'pickStartedAsc',
      {
        displayName: 'Pick Started Earliest',
        sortBy: 'pick_started_at',
        sortOrder: SortOrder.ASC
      }
    ],
    [
      'pickStartedDesc',
      {
        displayName: 'Pick Started Latest',
        sortBy: 'pick_started_at',
        sortOrder: SortOrder.DESC
      }
    ]
  ]);

  const pageSize = 10;
  const {
    selectedWarehouse,
    warehouses,
    carriers,
    batchService,
    warehouseService,
    onWarehouseSelect,
    isSchedulingModalEnabled
  } = props;
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [timerStartsAt, setTimerStartsAt] = useState<Date>(new Date(Date.now()));
  const [batches, setBatches] = useState<BatchData[]>([]);
  const [sortOptions, setSortOptions] = useState<SelectOption[]>([]);
  const [selectedSortOption, setSelectedSortOption] = useState<string>(defaultSortOption);
  const [filterValues, setFilterValues] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [counts, setCounts] = useState<CompactBatchCounts>(initialCounts);
  const [totalBatchesForSelectedTab, setTotalBatchesForSelectedTab] = useState<number>(0);
  const [continuationTokens, setContinuationTokens] = useState<string[]>([]);
  const [reservationIdToName, setReservationIdToName] = useState({});
  const [selectedStatusTab, setSelectedStatusTab] = useState<TransitionState>(TransitionState.Active);
  const [showDateFilterAlert, setShowDateFilterAlert] = useState<boolean>(false);
  const [showBatchSchedulingModal, setShowBatchSchedulingModal] = useState<boolean>(false);
  const [reloadChildrenTrigger, setReloadChildrenTrigger] = useState<boolean>(false);
  const isInitialMount = React.useRef(true);

  useEffect(() => {
    loadForWarehouse();
    configureSortByOptions();

    if (props.isTrailerLoadingEnabled) {
      TransitionStateDisplay.set(TransitionState.Completed, 'Packed');
    }
  }, [selectedWarehouse]);

  useEffect(() => {
    if (!isLoading) {
      getBatches();
    }
  }, [currentPage, continuationTokens]);

  useEffect(() => {
    if (!isLoading) {
      resetPagination();
    }
    configureSortByOptions();

    // TODO: make this less gross. somehow.
    const selectedFilters = Object.keys(filterValues);
    const isTerminalState =
      selectedStatusTab === TransitionState.Completed || selectedStatusTab === TransitionState.Cancelled;
    const hasCarrierJoin = selectedFilters.some((key) => key === 'carrier' || key === 'serviceTypes');
    const hasStartFilter = selectedFilters.some((key) => key === 'startDate');
    const hasEndFilter = selectedFilters.some((key) => key === 'endDate');
    setShowDateFilterAlert(isTerminalState && hasCarrierJoin && !(hasStartFilter && hasEndFilter));
  }, [selectedSortOption, filterValues, selectedStatusTab]);

  const loadForWarehouse = async () => {
    setTimerStartsAt(new Date(Date.now()));
    setIsLoading(true);
    const allErrors = await Promise.all([loadReservations(), getBatches()]);
    setIsLoading(false);
    // we don't want to reload children components on initial load
    if (!isInitialMount.current) {
      setReloadChildrenTrigger(!reloadChildrenTrigger);
    }
    const loadErrors = [].concat(...allErrors);
    handleErrors(loadErrors);

    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  };

  const loadReservations = async () => {
    const response = await warehouseService.getReservationsForWarehouse(selectedWarehouse.id);
    if (responseHasNoErrors(response) && response.data && response.data.reservations) {
      setReservations(response.data.reservations);
      setReservationIdToName(WarehouseReservationUtil.getReservationIdToName(response.data.reservations));
    }
    return response.errors;
  };

  const configureSortByOptions = () => {
    const sortOptionsArray: SelectOption[] = [];
    if (selectedStatusTab === TransitionState.Active || selectedStatusTab === TransitionState.InProgress) {
      sortByOptionsMap.forEach((option, optionKey) => {
        sortOptionsArray.push({
          name: option.displayName,
          value: optionKey
        });
      });
    } else {
      sortOptionsArray.push({
        name: sortByOptionsMap.get('createdAtDesc').displayName,
        value: 'createdAtDesc'
      });
      sortOptionsArray.push({
        name: sortByOptionsMap.get('createdAtAsc').displayName,
        value: 'createdAtAsc'
      });
    }
    if (!sortOptionsArray.map((option) => option.value).includes(selectedSortOption)) {
      setSelectedSortOption(defaultSortOption);
    }
    setSortOptions(sortOptionsArray);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const getBatches = async () => {
    setIsLoading(true);
    const continuationToken = currentPage > 1 ? continuationTokens[currentPage - 2] : null;
    const batchRequest: BatchesRequest = {
      continuationToken,
      pageSize,
      sortBy: sortByOptionsMap.get(selectedSortOption).sortBy,
      sortOrder: sortByOptionsMap.get(selectedSortOption).sortOrder,
      filters: {
        batchStatus: selectedStatusTab,
        warehouseId: selectedWarehouse.id
      }
    };
    const filterKeys = Object.keys(filterValues);
    filterKeys.map((filterKey) => {
      batchRequest.filters[filterKey] = filterValues[filterKey];
    });
    const batchesResponse = await batchService.getBatchesCompactInfo(batchRequest);
    if (responseHasNoErrors(batchesResponse)) {
      setBatches(batchesResponse.data.batches);
      setCounts(batchesResponse.data.counts);
      setTotalBatchesForSelectedTab(batchesResponse.data.counts[selectedStatusTab]);

      if (batchesResponse.data.continuationToken) {
        continuationTokens[currentPage - 1] = batchesResponse.data.continuationToken;
      }
    }
    setIsLoading(false);
    return batchesResponse.errors;
  };

  const handleBatchConfirm = async (batch: BatchData) => {
    const response = await batchService.confirmBatches([batch.id]);
    if (responseHasNoErrors(response)) {
      await getBatches();
    } else {
      handleErrors(response.errors);
    }
  };

  const handleBatchCancelled = async () => {
    resetPagination();
  };

  const handleErrors = (responseErrors) => {
    setErrors(responseErrors.map((e) => e.detail));
  };

  const resetPagination = () => {
    setContinuationTokens([]);
    setCurrentPage(1);
  };

  const handleWarehouseSelect = (warehouse: Warehouse) => {
    resetPagination();
    onWarehouseSelect(warehouse);
  };

  const batchList = (
    <div className="batch-list">
      {batches.length > 0 ? (
        batches.map((batch) => {
          return (
            <CompactBatchTile
              key={batch.id}
              batchData={batch}
              batchService={batchService}
              carriers={carriers}
              reservationDisplayName={reservationIdToName[batch.reservation.id]}
              handleBatchConfirm={() => handleBatchConfirm(batch)}
              handleBatchCancelled={() => handleBatchCancelled()}
              usePackedTerms={props.isTrailerLoadingEnabled}
            />
          );
        })
      ) : (
        <h4 className="margin-top">{`No batches matching ${TransitionStateDisplay.get(selectedStatusTab)}.`}</h4>
      )}
    </div>
  );

  const reservationDropdown = () => {
    const allReservationsOption = {name: 'All Reservations', value: 'all'};
    const reservationOptions = [allReservationsOption];
    reservations.forEach((reservation) => {
      reservationOptions.push({
        name: `${reservationIdToName[reservation.id]}`,
        value: `${reservation.id}`
      });
    });

    const onReservationSelect = (value: string) => {
      const filterValuesCopy = {...filterValues};
      if (value === allReservationsOption.value) {
        delete filterValuesCopy.reservationId; // no reservation id means all reservations
      } else {
        filterValuesCopy.reservationId = parseInt(value, 10);
      }
      setFilterValues(filterValuesCopy);
    };
    const selected = filterValues.reservationId ? filterValues.reservationId.toString() : allReservationsOption.value;

    return (
      <SelectorWithCheckbox
        options={reservationOptions}
        selected={[selected]}
        title={'All Reservations'}
        onSelect={onReservationSelect}
      />
    );
  };

  const batchesName = () => {
    if (props.isFreightWorkflowEnabled) {
      return 'Batches';
    } else {
      return 'eComm Batches';
    }
  };

  return (
    <div id="compact-batch-list">
      {isLoading && <Loader loading={isLoading} />}
      {errors && errors.length > 0 && (
        <div className="col-md-12 space-below">
          <div className="alert error-banner">
            <div className="error-banner-text">
              {errors.join(', ')}&nbsp;
              <a onClick={refreshPage}>
                <strong>
                  <u>Refresh and try again</u>
                </strong>
              </a>
            </div>
          </div>
        </div>
      )}
      {showBatchSchedulingModal && (
        <BatchSchedulingModal
          reservations={reservations}
          reservationIdToName={reservationIdToName}
          selectedReservationId={filterValues.reservationId}
          showBatchSchedulingModal={showBatchSchedulingModal}
          closeBatchSchedulingModal={() => setShowBatchSchedulingModal(false)}
          batchService={props.batchService}
          showAddNewButton={props.isAddBatchScheduleEnabled}
        />
      )}
      <div className="title-header">
        <div className="title-header-controls">
          <div>
            <WarehouseSelector
              selectedWarehouse={selectedWarehouse}
              activeWarehouses={warehouses}
              onSelect={(warehouse: Warehouse) => handleWarehouseSelect(warehouse)}
            />
            <h2>{batchesName()}</h2>
            <div id="documentation">
              <a
                target="_blank"
                href="https://flexesupport.zendesk.com/hc/en-us/articles/360040843192-Mobile-Pick-ecomm-"
              >
                Learn about Picking an eCommerce Shipment
              </a>
              <br></br>
              <br></br>
            </div>
          </div>
          <div className="top-right-controls">
            <PageReloader
              timerUpdateFrequency={6000}
              timerStartsAt={timerStartsAt}
              onReload={() => loadForWarehouse()}
            />
            <AlertWavingIssues
              warehouseId={selectedWarehouse.id}
              batchWavingService={props.batchService}
              reloadTrigger={reloadChildrenTrigger}
            />
            {/* TODO: what is this styling why is styling evil */}
            {isSchedulingModalEnabled && (
              <button className="btn btn-default no-cta schedule" onClick={() => setShowBatchSchedulingModal(true)}>
                <i className="fas fa-calendar"></i> Schedule
              </button>
            )}
          </div>
        </div>
        <div className="filter-controls">
          {reservationDropdown()}
          <AdditionalBatchFilters
            batchFilters={filterValues}
            carriers={carriers}
            batchService={batchService}
            isHidePrintedFilterEnabled={props.isHidePrintedFilterEnabled}
            handleBatchFiltersUpdate={setFilterValues}
          />
        </div>
        {showDateFilterAlert && (
          <div className="alert alert-info">
            <p className="content">
              Limiting data to 2 weeks. Please provide filters for both 'batch created starting' and 'batch created
              ending'.
            </p>
          </div>
        )}
        <BatchFiltersLabelGroup
          carriers={carriers}
          batchFilters={filterValues}
          handleBatchFiltersUpdate={setFilterValues}
        />
      </div>
      <div className="compact-batch-controls">
        <div className="compact-batch-sort">
          <CompactBatchStatusTabs
            countsByProcessStatus={counts}
            selectedTab={selectedStatusTab}
            onTabSelect={(state: TransitionState) => setSelectedStatusTab(state)}
          />
          <SelectorWithCheckbox
            options={sortOptions}
            selected={[selectedSortOption]}
            onSelect={(value: string) => setSelectedSortOption(value)}
            prefixText="Sort By: "
          />
        </div>
        <Pagination
          page={currentPage}
          pageSize={pageSize}
          paginationHandler={(page) => setCurrentPage(page)}
          totalCount={totalBatchesForSelectedTab}
        />
      </div>
      {batchList}
    </div>
  );
};

const responseHasNoErrors = (response) => {
  return response && (!response.errors || response.errors.length === 0);
};

export default CompactBatchList;
