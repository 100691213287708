export interface WarehouseTask {
  id: number;
  assignedToUser: string;
  assignedToUserName: string;
  // TODO: Remove optionality when removing feature flag 'omni-task-filter'.
  batchId?: number | null;
  executingDeviceId: number;
  taskType: string;
  warehouseId: number;
  reservationId: number;
  priority: number;
  equipmentId: number;
  startedAt: string;
  endedAt: string;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  createdByType: string;
  lastModifiedById: number;
  lastModifiedByType: string;
  steps: WarehouseTaskStep[];
  status: WarehouseTaskStatus;
  isWarehouseSfsEnabled?: boolean;
}

export interface WarehouseTaskResponse {
  result: WarehouseTask[];
  continuationToken: string;
}

export interface WarehouseTaskStep {
  id: number;
  performedById: string;
  sourceLocation: Location;
  destinationLocation: Location;
  performedByUserName: string;
  itemId: number;
  sku: string;
  uom: string;
  updatedAt: string;
  status: WarehouseTaskStepStatus;
}

export interface Location {
  id: number;
  name: string;
}

export enum WarehouseTaskStatus {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  COMPLETED_WITH_EXCEPTION = 'completed_with_exception',
  CANCELLED = 'cancelled',
  HALTED = 'halted'
}

export enum WarehouseTaskStepStatus {
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  COMPLETED_WITH_EXCEPTION = 'completed_with_exception',
  CANCELLED = 'cancelled',
  VOIDED = 'voided'
}
