import * as React from 'react';
import {Loader} from '@flexe/ui-components';
import {Document} from './SharedInterfaces';

interface DocumentListProps {
  documents: Document[];
  loading: boolean;
  isBatched: boolean;
  isFreight: boolean;
}

function DocumentList(props: DocumentListProps) {
  const documents = {};
  let documentTypes = props.documents.map((document) => document.type);
  documentTypes = documentTypes.sort().filter((item, pos) => documentTypes.indexOf(item) === pos);

  documentTypes.forEach((type) => {
    documents[type] = props.documents.filter((document) => document.type === type);
  });

  const documentList = documentTypes.map((type, index) => {
    return (
      <div key={index} className="document-list">
        <div className="bold">{type}:</div>
        <ul>
          {documents[type].map((document, docIndex) => {
            return (
              <li key={docIndex}>
                <a href={document.src} target="_blank">
                  {document.filename}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  });

  return (
    <div className="document-list-component">
      <Loader loading={props.loading} />
      {!props.loading && documentList}
      {!props.loading &&
        !props.isBatched &&
        !props.isFreight &&
        (Object.keys(documents).length === 0 || documentTypes.indexOf('Shipping Label') === -1) && (
          <p>
            <i>
              Shipping label is being generated&hellip;
              <br />
              Please check back in a few minutes.
            </i>
          </p>
        )}
    </div>
  );
}

export default DocumentList;
