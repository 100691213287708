import {ApiResponse, UserData} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

class UserService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2');
  }

  public async getUserContactInfo(id: number, resId: number, isShipper: boolean): Promise<ApiResponse<UserData>> {
    const params = {
      reservationIdForWarehouseCompanyId: isShipper ? resId : null
    };
    return await this.makeGetRequest(`${this.baseUrl}/users/${id}`, params);
  }
}

export default UserService;
