/** @jsx jsx */
import * as React from 'react';
import * as moment from 'moment-timezone';
import {findIndex, isEqual, uniq} from 'lodash';
import {jsx} from '@emotion/react';
import {Destination, SelectedShipment, Shipment, shipmentToSelectedShipment} from '../ShipmentInterfaces';
import ShipmentStatusMap from '../ShipmentConstants';
import {Packaging} from '../../../shared/CommonInterfaces';
import {PluralPackagingAbbreviations} from '../../../shared/CommonMappings';
import {renderItemLink} from '../../../../libs/helpers';
import {getQuantityAsMap, getUniqueSkuCount} from '../ShipmentHelper';
import {ColumnNames} from './ShipmentTableHeader';

interface ShipmentRowProps {
  shipment: Shipment;
  selectedShipments: SelectedShipment[];
  setSelectedShipments: (shipmentIds: SelectedShipment[]) => void;
  disableSelectedShipments: boolean;
  isFreightTrailerLoadingEnabled: boolean;
  isFreightWorkflowEnabled: boolean;
  isOmniEnabled: boolean;
  showShipmentSkuModal: boolean;
  setShowShipmentSkuModal: (showModal: boolean) => void;
  shipmentForSku: Shipment;
  setShipmentForSku: (shipmentForSku: Shipment) => void;
  setShowLpnContentsModal: (showModal: boolean) => void;
  setShipmentForLPN: (shipment: Shipment) => void;
  columnsToHide: ColumnNames[];
  showCarrierUnderPro: boolean;
  onRemoveShipment: (shipment: Shipment) => void;
  shipmentReservationLpnEnabled: boolean;
  isFreightLoadGroupUIEnabled: boolean;
}

const ShipmentRow: React.FC<ShipmentRowProps> = (props: ShipmentRowProps) => {
  const {
    columnsToHide,
    isFreightTrailerLoadingEnabled,
    shipment,
    showCarrierUnderPro,
    isFreightLoadGroupUIEnabled
  } = props;
  const id = shipment.id;
  const selectedShipment: SelectedShipment = shipmentToSelectedShipment(shipment);
  const shipmentMode = shipment.transportation.ship_mode;
  const trackingNumber = shipment.transportation.tracking_number || shipment.transportation.pro_number;
  const shipBy = moment(shipment.ship_by)
    .tz('GMT')
    .format('MM/DD/YYYY');

  const selected = () => {
    return findIndex(props.selectedShipments, selectedShipment) > -1;
  };

  const selectShipment = () => {
    props.setSelectedShipments(props.selectedShipments.concat([selectedShipment]));
  };

  const deselectShipment = () => {
    const updatedSelectedShipments = props.selectedShipments.filter((s) => !isEqual(s, selectedShipment));
    props.setSelectedShipments(updatedSelectedShipments);
  };

  const handleSelectionChange = () => {
    if (selected()) {
      deselectShipment();
    } else {
      selectShipment();
    }
  };

  const carrier = () => {
    const text = shipment.transportation.carrier.display_name || shipment.transportation.carrier.scac;

    if (text) {
      return <div>{text}</div>;
    } else {
      return <div className="shipment-row-empty-value" />;
    }
  };

  const mode = () => {
    if (props.isFreightWorkflowEnabled) {
      return <div className="shipment-row-context">{shipmentMode}</div>;
    } else {
      return '';
    }
  };

  const onSkusClicked = () => {
    props.setShipmentForSku(shipment);
    props.setShowShipmentSkuModal(true);
  };

  const totalUnits = () => {
    if (shipment.line_items.some((item) => item.units_per_packaging == null)) {
      const quantityMap = getQuantityAsMap(shipment.line_items);
      const buffer = [];
      if (quantityMap.has(Packaging.each)) {
        buffer.push(
          <div>
            {quantityMap.get(Packaging.each)} {PluralPackagingAbbreviations.get(Packaging.each)}
          </div>
        );
      }
      if (quantityMap.has(Packaging.carton)) {
        buffer.push(
          <div>
            {quantityMap.get(Packaging.carton)} {PluralPackagingAbbreviations.get(Packaging.carton)}
          </div>
        );
      }
      if (quantityMap.has(Packaging.pallet)) {
        buffer.push(
          <div>
            {quantityMap.get(Packaging.pallet)} {PluralPackagingAbbreviations.get(Packaging.pallet)}
          </div>
        );
      }
      return buffer;
    } else {
      let totalUnitText: string = '';
      const units = uniq(shipment.line_items.map((lineItem) => lineItem.packaging)).sort((unitA, unitB) => {
        if (unitA === Packaging.each) {
          return -1;
        } else if (unitB === Packaging.each) {
          return 1;
        } else if (unitA === Packaging.carton) {
          return -1;
        } else if (unitB === Packaging.carton) {
          return 1;
        } else {
          return 0;
        }
      });

      const unit = Packaging[units[0]] || Packaging.each;

      const total = shipment.line_items
        .map((lineItem) => lineItem.units_per_packaging[unit])
        .reduce((sum, lineItemUnits) => sum + lineItemUnits, 0);

      totalUnitText = `${total} ${PluralPackagingAbbreviations.get(unit)}`;
      return <div>{totalUnitText}</div>;
    }
  };

  const totalSkus = () => {
    const uniqueSkuCount = getUniqueSkuCount(shipment);
    let skuDom = null;

    if (uniqueSkuCount === 1) {
      // There was exactly one SKU so show the SKU not a count of them
      skuDom = (
        <u className="sku-link" title={shipment.line_items[0].sku}>
          {renderItemLink(shipment.line_items[0].inventory_id, shipment.line_items[0].sku, false)}
        </u>
      );
    } else if (uniqueSkuCount > 0) {
      // There are multiple SKUs and their details should be shown on click
      skuDom = (
        <a onClick={onSkusClicked}>
          <u>{uniqueSkuCount} SKUs</u>
        </a>
      );
    } else {
      // Otherwise just let the user know how many SKUs there are
      skuDom = <div>{uniqueSkuCount} SKUs</div>;
    }

    return skuDom;
  };

  const status = () => {
    const statusObject = ShipmentStatusMap.get(shipment.status);
    const className = `shipment-status-dot ${statusObject.className}`;

    return (
      <React.Fragment>
        <span className={className} />
        {statusObject.text}
      </React.Fragment>
    );
  };

  const onLpnClicked = () => {
    props.setShipmentForLPN(props.shipment);
    props.setShowLpnContentsModal(true);
  };

  const lpnCounts = shipment.num_pallets_built;

  const shouldShowColumn = (columnName: ColumnNames) => !columnsToHide.includes(columnName);

  const createDestinationDom = (destination: Destination) => {
    let addrDetails: string = '';
    for (const addr of [destination.address_line_2, destination.address_line_3]) {
      const addrLine = addr || '';
      // Pack the details into a single variable, adding a space before the new content if there is existing content.
      addrDetails += addrDetails.length > 0 ? ` ${addrLine}` : addrLine;
    }
    // Add an extra space at the end of the text if there is any content.
    if (addrDetails.length > 0) {
      addrDetails += ' ';
    }

    return (
      <React.Fragment>
        <div>{destination.address_line_1}</div>
        <div>{`${addrDetails}${destination.city}, ${destination.state} ${destination.postal_code}`}</div>
      </React.Fragment>
    );
  };

  const createLoadDetailsDom = () => {
    if (!shipment.freight_load_group && !shipment.destination_tag) {
      return '--';
    } else {
      return (
        <React.Fragment>
          {shipment.freight_load_group ? <div>Group: {shipment.freight_load_group}</div> : null}
          {shipment.destination_tag ? <div>Dest. ID: {shipment.destination_tag}</div> : null}
        </React.Fragment>
      );
    }
  };

  return (
    <tr className="shipment-list-row">
      {shouldShowColumn('shipmentId') && (
        <td className="shipment-table-select-col">
          <div className="row vertical-align">
            {!props.disableSelectedShipments && (
              <input id={id.toString()} type="checkbox" checked={selected()} onChange={handleSelectionChange} />
            )}
            <div>
              <div className="shipment-row-id">
                {props.isOmniEnabled && <a href={`/wh/shipments/${id}`}>{id}</a>}
                {!props.isOmniEnabled && <span>{id}</span>}
              </div>
              <div className="shipment-row-context">{shipment.reservation.shipper_name}</div>
            </div>
          </div>
        </td>
      )}
      {shouldShowColumn('loadDetails') && !props.isFreightLoadGroupUIEnabled && <td>{shipment.destination_tag}</td>}
      {shouldShowColumn('orderId') && <td>{shipment.order_id}</td>}
      {shouldShowColumn('po') && (
        <td>
          {shipment.purchase_order}
          {isFreightTrailerLoadingEnabled && shipment.order_id && (
            <div className="shipment-row-context">Order ID: {shipment.order_id}</div>
          )}
        </td>
      )}
      {shouldShowColumn('status') && <td>{status()}</td>}
      {shouldShowColumn('carrier') && (
        <td>
          {carrier()}
          {mode()}
        </td>
      )}
      {shouldShowColumn('pro/track') && (
        <td>
          {trackingNumber}
          {showCarrierUnderPro && (
            <div className="shipment-row-context">
              {shipment.transportation.carrier?.display_name || shipment.transportation.carrier?.scac}
            </div>
          )}
        </td>
      )}
      {shouldShowColumn('shipBy') && <td>{shipBy}</td>}
      {shouldShowColumn('contents') && (
        <td>
          {totalUnits()}
          {totalSkus()}
        </td>
      )}
      {shouldShowColumn('lpns') && (
        <td>
          {props.shipmentReservationLpnEnabled && lpnCounts != null && lpnCounts > 0 && (
            <a onClick={onLpnClicked}>
              <u>{lpnCounts}</u>
            </a>
          )}
          {!props.shipmentReservationLpnEnabled && '--'}
          {props.shipmentReservationLpnEnabled && (lpnCounts === null || lpnCounts === 0) && 0}
        </td>
      )}
      {shouldShowColumn('destination') && <td>{createDestinationDom(shipment.destination)}</td>}
      {shouldShowColumn('loadDetails') && props.isFreightLoadGroupUIEnabled && <td>{createLoadDetailsDom()}</td>}
      {shouldShowColumn('remove') && (
        <td>
          <button
            className="remove-button"
            id="remove-shipment-button"
            title={'Remove shipment from load'}
            onClick={() => {
              props.onRemoveShipment(props.shipment);
            }}
          >
            <i className="fa fa-trash" /> Remove
          </button>
        </td>
      )}
    </tr>
  );
};

export default ShipmentRow;
