import * as React from 'react';
import LocationsService, {LocationContent} from '../../locations/LocationsService';
import RetailFulfillmentService, {RetailOrderDetails} from '../../shared/services/RetailFulfillmentService';
import {ApiResponse} from '../../shared/CommonInterfaces';
import OutboundStagingTypeAhead from './OutboundStagingTypeAhead';

interface OutboundStagingUpdateProps {
  authenticityToken: string;
  orderDetails: RetailOrderDetails;
  isLpnEnabled: boolean;
  retailFulfillmentService: RetailFulfillmentService;
  onSuccess: (successMessage: string) => void;
  onError: (errorMessage: string) => void;
}

const OutboundStagingUpdate: React.FC<OutboundStagingUpdateProps> = (props) => {
  const locationsService = new LocationsService(props.authenticityToken);

  const [currentLocationId, setCurrentLocationId] = React.useState(null);

  const handleTypeAheadSelect = (locationId: string, _: LocationContent[]) => {
    setCurrentLocationId(locationId);
  };

  const saveButton = () => {
    return (
      <button id="complete-button" className="btn no-cta" onClick={() => saveOutboundStagingLocation()}>
        {' '}
        <i className="fa fa-check"></i>
        {' Save'}
      </button>
    );
  };

  const unmodifiableMessage = () => {
    let message: string = 'Staging location cannot be modified';

    if (!['confirmed', 'pickup_scheduled'].includes(props.orderDetails.state)) {
      // eslint-disable-next-line
      message += "; order must be in 'confirmed' or 'pickup_scheduled' state";
    } else if (props.orderDetails.pickingHasStarted) {
      message += '; picking for order has begun';
    } else {
      message += '; something went wrong';
    }

    return <i>{message}</i>;
  };

  async function saveOutboundStagingLocation() {
    const response: ApiResponse<any> = await props.retailFulfillmentService.updatePreferedStagingLocation(
      props.orderDetails.id,
      currentLocationId
    );

    if (response.errors) {
      const errorMessage: string = response.errors[0].detail;
      props.onError(errorMessage);
      return;
    }

    props.onSuccess('Successfully saved outbound staging location!');
  }

  const allowLocationChange = () => {
    return (
      !props.orderDetails.pickingHasStarted && ['confirmed', 'pickup_scheduled'].includes(props.orderDetails.state)
    );
  };

  const stagingLocationLabel = () => {
    if (props.orderDetails.preferredStagingLocation) {
      return props.orderDetails.preferredStagingLocation.label;
    } else {
      return null;
    }
  };

  const outboundStagingUpdate = () => {
    if (props.orderDetails.state === 'new' || !props.isLpnEnabled) {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <React.Fragment>
        <div className="row outbound-staging-update-component">
          <h4>{allowLocationChange() && 'Set '} Preferred Staging Location</h4>

          <OutboundStagingTypeAhead
            authenticityToken={props.authenticityToken}
            warehouseId={props.orderDetails.warehouse.id}
            locationsService={locationsService}
            onSelectCallback={handleTypeAheadSelect}
            currentLocationLabel={stagingLocationLabel()}
            disable={!allowLocationChange()}
          />

          {allowLocationChange() ? saveButton() : unmodifiableMessage()}
        </div>
      </React.Fragment>
    );
  };

  return outboundStagingUpdate();
};

export default OutboundStagingUpdate;
