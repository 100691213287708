import * as React from 'react';
import {useContext, useState} from 'react';
import {Loader} from '@flexe/ui-components';
import ItemMasterContext from '../../contexts/ItemMasterContext';

export interface ItemMasterIndexProps {
  placeholder?: string;
}

const ItemMasterIndex: React.FC<ItemMasterIndexProps> = (props) => {
  const context = useContext(ItemMasterContext);
  const itemMasterService = context.itemMasterService;
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div>This is Item Master and you are viewing it as a {context.isShipper ? 'Shipper' : 'Warehouse'} User</div>
      )}
    </>
  );
};

export default ItemMasterIndex;
