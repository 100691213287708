import * as React from 'react';
import * as moment from 'moment';
import {useContext} from 'react';
import StatusDot from '../../../shared/StatusDot';
import CustomKebabMenu from '../../../shared/CustomKebabMenu';
import {calculateTransitionState, handlePrintAllDocuments, transitionStateToDotStatus} from '../WaveShared';
import {WaveBatch} from '../WaveBatchInterfaces';
import {TransitionStateDisplay} from '../../../shared/constants';
import {WaveDetailsContext} from './WaveDetailsContext';

// eslint-disable-next-line @typescript-eslint/ban-types, no-empty-pattern
const ContentsBatchesTable: React.FC<{}> = ({}) => {
  const {batches} = useContext(WaveDetailsContext);

  const dateFormat = (date: string): string => {
    return moment(date).format('hh:mm A');
  };

  const getPickTime = (batch: WaveBatch): string => {
    return batch.startedAt ? `Started at ${dateFormat(batch.startedAt)}` : '--';
  };

  function getRenderBatchRowData(curBatch: WaveBatch) {
    const {pickSteps} = curBatch;
    let expectedEa = 0;
    let pickedEa = 0;

    const expectedSkus: string[] = [];
    const pickedSkus: string[] = [];

    for (const curPickStep of pickSteps) {
      pickedEa += curPickStep.pickedQuantity;
      expectedEa += curPickStep.requiredQuantity;

      if (!expectedSkus.includes(curPickStep.itemSku)) {
        expectedSkus.push(curPickStep.itemSku);
      }

      if (curPickStep.pickedQuantity === curPickStep.requiredQuantity) {
        if (!pickedSkus.includes(curPickStep.itemSku)) {
          pickedSkus.push(curPickStep.itemSku);
        }
      }
    }

    const expectedSkuCount = expectedSkus.length;
    const pickedSkuCount = pickedSkus.length;

    return {expectedEa, pickedEa, expectedSkuCount, pickedSkuCount};
  }

  function renderBatchRow(curBatch: WaveBatch) {
    const {expectedEa, pickedEa, expectedSkuCount, pickedSkuCount} = getRenderBatchRowData(curBatch);
    const documentIds = curBatch.documents.reverse().map((doc) => doc.id);
    const {pickSteps} = curBatch;
    const dotStatus = transitionStateToDotStatus(calculateTransitionState(pickSteps));

    return (
      <tr key={`wave-details-content-batches-row-${curBatch.id}`}>
        <td>
          <a href={`/wh/batches/${curBatch.id}`}>{curBatch.id}</a>
        </td>
        <td>
          <StatusDot status={dotStatus} />
          {TransitionStateDisplay.get(calculateTransitionState(pickSteps))}
        </td>
        <td>
          <div className="info-pair">
            <span className="title">{curBatch.currentPicker}</span>
            <span className="light-text">{getPickTime(curBatch)}</span>
          </div>
        </td>
        <td>
          <div className="info-pair">
            <span className="title">{expectedEa} ea</span>
            <span className="light-text underline">{expectedSkuCount} SKUs</span>
          </div>
        </td>
        <td>
          <div className="info-pair">
            <span className="title">{pickedEa} ea</span>
            <span className="light-text underline">{pickedSkuCount} SKUs</span>
          </div>
        </td>
        <td className="kebab">
          <CustomKebabMenu
            options={[{optionText: 'Print Batch', optionAction: () => handlePrintAllDocuments(documentIds)}]}
          />
        </td>
      </tr>
    );
  }

  return (
    <table className="table contents-table">
      <thead>
        <tr>
          <th>Batch ID</th>
          <th>Status</th>
          <th>Picker</th>
          <th>Expected</th>
          <th>Picked</th>
          <th className="kebab-header"></th>
        </tr>
      </thead>
      <tbody>{batches.map((curBatch) => renderBatchRow(curBatch))}</tbody>
    </table>
  );
};

export default ContentsBatchesTable;
