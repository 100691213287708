import * as React from 'react';

interface NotesProps {
  notes: string;
  onNotesSaved: (notes: string) => void;
}

function Notes(props: NotesProps) {
  const [editing, setEditing] = React.useState(false);
  const [notes, setNotes] = React.useState(props.notes);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (editing) {
      ref.current.focus();
    }
  }, [editing]);

  React.useEffect(() => {
    setNotes(props.notes);
  }, [props.notes]);

  const handleSave = () => {
    props.onNotesSaved(notes);
    setEditing(false);
  };

  const handleCancel = () => {
    setNotes(props.notes);
    setEditing(false);
  };

  const valueDisplay = () => {
    let value = '';

    if (notes) {
      value = notes;
    }

    if (!editing) {
      return <div>{value}</div>;
    } else {
      return (
        <textarea
          value={value}
          maxLength={1024}
          onChange={(event) => setNotes(event.target.value)}
          ref={ref}
          className="notes-input"
        />
      );
    }
  };

  const editControls = () => {
    if (!editing) {
      return (
        <a onClick={() => setEditing(true)}>
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </a>
      );
    } else {
      return (
        <div className="notes-edit-controls">
          <a className="submit-edit" onClick={handleSave} title="Update">
            <i className="fa fa-check" aria-hidden="true"></i>
          </a>
          <a className="cancel-edit" onClick={handleCancel} title="Cancel">
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>
      );
    }
  };

  return (
    <div className="notes-component">
      <div className="bold">Notes</div>
      {valueDisplay()}
      {editControls()}
    </div>
  );
}

export default Notes;
