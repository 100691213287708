import {ParseError as PapaParseError, ParseResult} from 'papaparse';
import * as React from 'react';

const NUMBER_REG_EX = /^\d(\.?\d)*$/;
const INTEGER_REG_EX = /^\d+$/;
const BOOLEAN_REG_EX = new RegExp(/^(true|false)$/, 'i');
const MAX_PG_NUMERIC_VALUE = 100000;
const MAX_INT_32_VALUE = 0x7fffffff;

export enum CsvFieldType {
  string, // eslint-disable-line id-denylist
  number, // eslint-disable-line id-denylist
  enum,
  integer,
  boolean // eslint-disable-line id-denylist
}

export interface CsvColumn {
  required?: boolean;
  requiredIfPresent?: string;
  type: CsvFieldType;
  enum?: any;
  instructions?: React.ReactNode[];
}

export interface CsvColumns {
  [key: string]: CsvColumn;
}

interface HeaderCache {
  [key: string]: string;
}

type HeaderNormalizer = (receivedHeader: string) => string;
export interface CsvValidatorOptions {
  headerNormalizer?: HeaderNormalizer;
}

export interface ParseError {
  row?: number;
  message: string;
  rowData?: any;
}

class CsvValidator {
  private static validateField(
    rowIdx: number,
    rowData: any,
    normalizedFieldKey: string,
    columnSpec: CsvColumn,
    fieldValue: any
  ): ParseError[] {
    // Seeds & misc variables
    const errors: ParseError[] = [];

    // Check if any required fields are missing
    if (!fieldValue) {
      if (columnSpec.required) {
        errors.push(CsvValidator.buildMissingRequiredFieldError(rowIdx, rowData, normalizedFieldKey));
      }

      // Check fields that are required when another field is present
      const otherFieldKey = columnSpec.requiredIfPresent;
      if (otherFieldKey) {
        if (rowData[otherFieldKey]) {
          errors.push(CsvValidator.buildMissingOtherFieldError(rowIdx, rowData, normalizedFieldKey, otherFieldKey));
        }
      }
    } else if (fieldValue) {
      // Check that fields match their type
      switch (columnSpec.type) {
        case CsvFieldType.enum:
          if (!columnSpec.enum[fieldValue]) {
            errors.push(CsvValidator.buildEnumFieldError(rowIdx, rowData, normalizedFieldKey, columnSpec));
          }
          break;
        case CsvFieldType.number:
          if (!NUMBER_REG_EX.test(fieldValue) || parseInt(fieldValue, 10) > MAX_PG_NUMERIC_VALUE) {
            errors.push(CsvValidator.buildNumberFieldError(rowIdx, rowData, normalizedFieldKey));
          }
          break;
        case CsvFieldType.boolean:
          if (!BOOLEAN_REG_EX.test(fieldValue)) {
            errors.push(CsvValidator.buildBooleanFieldError(rowIdx, rowData, normalizedFieldKey));
          }
          break;
        case CsvFieldType.integer:
          if (!INTEGER_REG_EX.test(fieldValue) || parseInt(fieldValue, 10) > MAX_INT_32_VALUE) {
            errors.push(CsvValidator.buildIntegerFieldError(rowIdx, rowData, normalizedFieldKey));
          }
      }
    }

    return errors;
  }

  private static buildMissingRequiredFieldError(rowIdx: number, rowData: any, normalizedFieldKey: string): ParseError {
    return {rowData, row: rowIdx, message: `Required '${normalizedFieldKey}' field is missing.`};
  }

  private static buildMissingOtherFieldError(
    rowIdx: number,
    rowData: any,
    normalizedFieldKey: string,
    otherFieldKey: string
  ): ParseError {
    return {
      rowData,
      row: rowIdx,
      message: `'${normalizedFieldKey}' field is required when '${otherFieldKey}' field is present.`
    };
  }

  private static buildBooleanFieldError(rowIdx: number, rowData: any, normalizedFieldKey: string): ParseError {
    return {rowData, row: rowIdx, message: `'${normalizedFieldKey}' field must be a boolean (true/false).`};
  }

  private static buildEnumFieldError(
    rowIdx: number,
    rowData: any,
    normalizedFieldKey: string,
    columnSpec: CsvColumn
  ): ParseError {
    const enumKeysArr = [];
    for (const enumKey in columnSpec.enum) {
      if (columnSpec.enum[enumKey]) {
        enumKeysArr.push(enumKey);
      }
    }
    const enumKeysStr = enumKeysArr.join(', ');
    return {rowData, row: rowIdx, message: `'${normalizedFieldKey}' field must be one of: [${enumKeysStr}].`};
  }

  private static buildIntegerFieldError(rowIdx: number, rowData: any, normalizedFieldKey: string): ParseError {
    return {
      rowData,
      row: rowIdx,
      message:
        `'${normalizedFieldKey}' field must be an integer and smaller than ` +
        `${MAX_INT_32_VALUE.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.`
    };
  }

  private static buildNumberFieldError(rowIdx: number, rowData: any, normalizedFieldKey: string): ParseError {
    return {
      rowData,
      row: rowIdx,
      message:
        `'${normalizedFieldKey}' field must be a number and smaller than ` +
        `${MAX_PG_NUMERIC_VALUE.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.`
    };
  }

  /**
   * Uses and maintains a cache for repeated
   * headerNormalizer calls
   * @param receivedHeader
   * @param headerNormalizer
   * @param headerCache
   * @private
   */
  private static normalizeHeader(
    receivedHeader: string,
    headerNormalizer: HeaderNormalizer,
    headerCache: HeaderCache
  ): string {
    // Since normalizing headers can be expensive, try to do so once
    if (headerCache[receivedHeader]) {
      return headerCache[receivedHeader];
    }

    const normalizedHeader = headerNormalizer(receivedHeader);
    headerCache[receivedHeader] = normalizedHeader;
    return normalizedHeader;
  }

  /**
   * Validate CSV Results from Papa Parse. Only supports results from a CSV with headers.
   *  Otherwise, inputParseResult will be in the wrong format
   * @param {Object} inputParseResult The Papa Parse results object passed to the `complete()`,
   *  `step()`, or `chunk()` method in the config object.
   * @param {Object} columnSpecs Each key in `requiredCsvColumns` points to an object with properties that
   *    specify the validation rules for a CSV column.
   * @param {Object} options Optional configuration for the converter.
   * @returns {Object[]} An array of error objects
   */
  public validate(
    inputParseResult: ParseResult,
    columnSpecs: CsvColumns,
    options?: CsvValidatorOptions
  ): [ParseError[], ParseResult] {
    // Figure out how to normalize the keys
    let headerNormalizer: HeaderNormalizer;
    if (options && options.headerNormalizer) {
      headerNormalizer = options.headerNormalizer;
    } else {
      headerNormalizer = (receivedHeader: string) => receivedHeader.trim();
    }

    // Initialize outputs
    let errors: ParseError[] = [];
    const headerCache: HeaderCache = {};
    const columnHeaders = inputParseResult.meta.fields.map((col) =>
      CsvValidator.normalizeHeader(col, headerNormalizer, headerCache)
    );
    const parseResult: ParseResult = {
      data: this.normalizeData(inputParseResult, headerNormalizer, headerCache),
      errors: inputParseResult.errors.map((err: PapaParseError) => ({...err} as PapaParseError)),
      meta: {...inputParseResult.meta, fields: columnHeaders}
    };

    // Get and validate received columns
    //  Note this doesn't check for extra columns
    for (const reqColumn in columnSpecs) {
      if (columnSpecs[reqColumn].required && !parseResult.meta.fields.includes(reqColumn)) {
        errors.push({message: `Required column '${reqColumn}' is missing.`});
      }
    }

    // Check for invalid data. Loop over input to add in all data.
    parseResult.data.forEach((rowData: any, dataIdx: number) => {
      for (const normalizedFieldKey in rowData) {
        // eslint-disable-next-line no-prototype-builtins
        if (rowData.hasOwnProperty(normalizedFieldKey)) {
          const columnSpec = columnSpecs[normalizedFieldKey];

          // Note this also doesn't check for extra columns
          if (columnSpec && columnHeaders.includes(normalizedFieldKey)) {
            const fieldValue = rowData[normalizedFieldKey];
            // dataIdx is just an array index, so need to increment by 2 to align
            // with the file rows. (header is row 1)
            const newErrors = CsvValidator.validateField(
              dataIdx + 2,
              rowData,
              normalizedFieldKey,
              columnSpec,
              fieldValue
            );
            errors = errors.concat(newErrors);
          }
        }
      }
    });

    return [errors, parseResult];
  }

  private normalizeData(parseResult: ParseResult, headerNormalizer: HeaderNormalizer, headerCache: HeaderCache): any[] {
    return parseResult.data.map((rowData) => {
      const normalizedRowData = {};

      for (const rawKey in rowData) {
        // eslint-disable-next-line no-prototype-builtins
        if (rowData.hasOwnProperty(rawKey)) {
          // Papa Parse puts extra fields into a __parsed_extra array.
          // This should be ignored otherwise it'll blow up when trying to .trim() it below.
          if (rawKey === '__parsed_extra') {
            continue;
          }
          const normalizedKey = CsvValidator.normalizeHeader(rawKey, headerNormalizer, headerCache);
          normalizedRowData[normalizedKey] = rowData[rawKey].trim();
        }
      }
      return normalizedRowData;
    });
  }
}

export default CsvValidator;
