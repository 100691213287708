import * as React from 'react';
import {get} from 'lodash';
import {AxiosError} from 'axios';
import {CarrierBillingAccount} from '../account-settings/SharedInterfaces';
import {CarrierShippingOption, ShipmentDetails} from './SharedInterfaces';
import FulfillmentService from './FulfillmentService';
// eslint-disable-next-line
import CarrierBillingAccountService from '../account-settings/carrier-billing-accounts/CarrierBillingAccountService';

interface Props {
  fulfillmentService?: FulfillmentService;
  carrierBillingAccountService?: CarrierBillingAccountService;
  shipment: ShipmentDetails;
  canEdit: boolean;
  handleCheckShippingLabelUpdate?: any;
}

interface State {
  errors: any;
  editing: boolean;
  carrierShippingOptions: CarrierShippingOption[];
  carrierBillingOptions: CarrierBillingAccount[];
  selectedCarrierAccountId: number;
  selectedServiceLevel: string;
  selectedCarrierBillingAccountId: string;
}

class ShippingOptionOverride extends React.Component<Props, State> {
  private fulfillmentService: FulfillmentService;
  private carrierBillingAccountService: CarrierBillingAccountService;

  constructor(props) {
    super(props);
    this.fulfillmentService = props.fulfillmentService;
    this.carrierBillingAccountService = props.carrierBillingAccountService;
    this.state = {
      editing: false,
      errors: null,
      carrierShippingOptions: [],
      carrierBillingOptions: [],
      selectedCarrierAccountId: null,
      selectedServiceLevel: null,
      selectedCarrierBillingAccountId: null
    };
  }

  public async componentDidMount() {
    this.loadShippingOptions();
  }

  public render() {
    const uniqueCarrierIds = Array.from(new Set(this.state.carrierShippingOptions.map((o) => o.carrierAccountId)));
    const accountOptions = uniqueCarrierIds.map((id, index) => {
      const c = this.state.carrierShippingOptions.find((o) => o.carrierAccountId === id);
      return (
        <option key={index} value={c.carrierAccountId}>
          {`${c.carrierDisplayName} ${c.carrierAccountNumber || ''}`}
        </option>
      );
    });
    accountOptions.unshift(
      <option key="blank" value="">
        Select a carrier account...
      </option>
    );

    let shippingOptions;
    let filteredCarrierBillingOptions;
    if (this.state.selectedCarrierAccountId) {
      shippingOptions = this.state.carrierShippingOptions
        .filter((c) => c.carrierAccountId === this.state.selectedCarrierAccountId)
        .map((c, index) => {
          return (
            <option key={index} value={c.serviceLevel}>
              {c.serviceLevelDisplayName}
              {c.durationDays ? `, ${c.durationDays}-day` : ''}
              {c.displayAmount ? ` - ${c.displayAmount}` : ''}
            </option>
          );
        });
      shippingOptions.unshift(
        <option key="blank" value="">
          Select a service level...
        </option>
      );

      const selectedCarrier = this.state.carrierShippingOptions.find(
        (cso) => cso.carrierAccountId === this.state.selectedCarrierAccountId
      );

      filteredCarrierBillingOptions = this.state.carrierBillingOptions
        .filter((cba) => cba.carrier === selectedCarrier.carrier)
        .map((cba, index) => (
          <option key={index} value={cba.id}>
            {cba.friendlyName}
          </option>
        ));

      filteredCarrierBillingOptions.unshift(
        <option key="blank" value="">
          Select a carrier billing account...
        </option>
      );
    }

    return (
      <li className="shipping-option-override row">
        <div className="bold col-sm-5">Requested Carrier: </div>
        {this.state.carrierShippingOptions.length > 0 && (
          <div className="col-sm-6">
            {this.state.editing && (
              <div className="form-inline">
                <select
                  name="carrier_account"
                  className="form-control"
                  value={this.state.selectedCarrierAccountId || ''}
                  onChange={this.handleAccountChange}
                >
                  {accountOptions}
                </select>
                {this.state.selectedCarrierAccountId && (
                  <select
                    name="shipping_option"
                    className="form-control"
                    title="Service Level"
                    value={this.state.selectedServiceLevel || ''}
                    onChange={this.handleShippingOptionChange}
                  >
                    {shippingOptions}
                  </select>
                )}
                {this.state.selectedCarrierAccountId && filteredCarrierBillingOptions.length > 1 && (
                  <select
                    name="carrier_billing_account"
                    className="form-control"
                    title="Carrier Billing Account"
                    value={this.state.selectedCarrierBillingAccountId || ''}
                    onChange={this.handleCarrierBillingAccountChange}
                  >
                    {filteredCarrierBillingOptions}
                  </select>
                )}
                <div className="shipping-option-edit-controls">
                  <a className="submit-edit" onClick={this.handleEdit} title="Update">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </a>
                  <a className="cancel-edit" onClick={this.toggleEdit} title="Cancel">
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            )}
            {!this.state.editing && (
              <span>
                {this.displayShippingOption(this.state.selectedCarrierAccountId, this.state.selectedServiceLevel) ||
                  this.shipmentShippingOption()}
              </span>
            )}
            {this.props.canEdit && !this.state.editing && (
              <a onClick={this.toggleEdit}>
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </a>
            )}
          </div>
        )}
        {this.state.carrierShippingOptions.length < 1 && (
          <div className="col-sm-6">{this.shipmentShippingOption()}</div>
        )}
        {this.state.errors && (
          <ul className="col-sm-12 red2">
            {this.state.errors.map((e, idx) => (
              <li key={idx}>{e}</li>
            ))}
          </ul>
        )}
        {this.state.editing && !this.state.errors && (
          <p className="col-sm-12 red2">
            NOTE: Changing the carrier and/or service level on this shipment will permanently void any existing shipping
            label and attempt to generate a new one.
          </p>
        )}
      </li>
    );
  }

  private displayShippingOption(accountId: number, serviceLevel: string) {
    if (accountId && serviceLevel) {
      const option = this.state.carrierShippingOptions.find(
        (cso) => cso.carrierAccountId === accountId && cso.serviceLevel === serviceLevel
      );
      if (option) {
        return `${option.carrierDisplayName} ${option.carrierAccountNumber || ''}: ${option.serviceLevelDisplayName}`;
      }
    }
    return null;
  }

  private shipmentShippingOption() {
    const shipment = this.props.shipment;
    let optionDisplay = null;
    let carrierServiceType = null;
    optionDisplay = get(shipment, 'shippingLabelRequestedInfo.carrierDisplayName') || 'N/A';
    carrierServiceType = get(shipment, 'shippingLabelRequestedInfo.carrierServiceType');
    if (get(shipment, 'shippingLabelInfo.carrierAccountNumber')) {
      optionDisplay += ` ${shipment.shippingLabelInfo.carrierAccountNumber}`;
    }
    if (carrierServiceType) {
      optionDisplay += ` - ${carrierServiceType}`;
    }
    return optionDisplay;
  }

  private async loadShippingOptions() {
    let errors;
    const shipmentId = this.props.shipment.shipmentId;
    if (shipmentId) {
      try {
        const response = await this.fulfillmentService.getShippingOptions(shipmentId);
        const carrierShippingOptions = response.data.shippingOptions;
        const carrierBillingAccounts = await this.carrierBillingAccountService.getAccounts();
        if (carrierBillingAccounts.errors.length === 0) {
          const carrierBillingOptions = carrierBillingAccounts.accounts;
          this.setState({carrierShippingOptions, carrierBillingOptions});
        } else {
          errors = carrierBillingAccounts.errors.join('\n');
        }
      } catch (errorResponse) {
        errors = this.processErrorResponse(errorResponse);
      } finally {
        this.setState({errors});
      }
    }
  }

  private handleEdit = async () => {
    let errors;
    const shipmentId = this.props.shipment.shipmentId;
    const shippingOptionParams = {
      carrierAccountId: this.state.selectedCarrierAccountId,
      carrierBillingAccountId: this.state.selectedCarrierBillingAccountId,
      serviceLevel: this.state.selectedServiceLevel
    };
    if (shipmentId && shippingOptionParams) {
      try {
        const response = await this.fulfillmentService.setShippingOption(shipmentId, shippingOptionParams);
        this.setState({editing: false});
        this.props.handleCheckShippingLabelUpdate();
      } catch (errorResponse) {
        errors = this.processErrorResponse(errorResponse);
      } finally {
        this.setState({errors});
      }
    }
  };

  private processErrorResponse = (errorResponse: AxiosError) => {
    const errors = get(errorResponse, 'response.data.errors');
    return errors.map((error) => {
      if (error.source && error.source.pointer) {
        return `${error.detail} (${error.source.pointer})`;
      } else {
        return `${error.detail}`;
      }
    });
  };

  private handleAccountChange = (event) => {
    const selectedCarrierAccountId = event.target.value.length > 0 ? parseInt(event.target.value, 10) : undefined;
    this.setState({
      selectedCarrierAccountId,
      selectedCarrierBillingAccountId: null,
      selectedServiceLevel: null
    });
  };

  private handleShippingOptionChange = (event) => {
    const selectedServiceLevel = event.target.value.length > 0 ? event.target.value : undefined;
    this.setState({selectedServiceLevel});
  };

  private handleCarrierBillingAccountChange = (event) => {
    const selectedCarrierBillingAccountId = event.target.value.length > 0 ? event.target.value : undefined;
    this.setState({selectedCarrierBillingAccountId});
  };

  private toggleEdit = () => {
    this.setState({
      editing: !this.state.editing,
      selectedCarrierAccountId: null,
      selectedServiceLevel: null,
      errors: null
    });
  };
}

export default ShippingOptionOverride;
