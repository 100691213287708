import {Link} from 'react-router-dom';
import {format as formatDate} from 'date-fns';
import * as React from 'react';
import {Pagination, TableHeader} from '@flexe/ui-components';
import {OMSReservation} from '../../ecommerce-orders/OrdersAppInterfaces';
import {OrderStatusDisplay, OutboundOrder} from '../OutboundOrdersInterfaces';
import {getTableDisplayValueForReservations} from '../helpers/Reservations';
import {PageSize} from '../generic/PageSizeInput';
import {dateFormat} from './OutboundOrdersV2';

export const buildTableHeaders = (
  currentPage: number,
  totalRecordCount: number,
  pageSize: PageSize,
  changePage: (page: number) => void
): TableHeader[] => {
  return [
    {
      className: 'issue-header header-icon',
      element: <i className="fa fa-exclamation-triangle red3" />
    },
    {className: 'id-header header-9pct', element: 'FLEXE Order ID'},
    {className: 'header-14pct', element: 'External Order ID'},
    {className: 'header-7pct', element: 'Status'},
    {className: 'header-15pct', element: 'Reservation'},
    {className: 'header-18pct', element: 'Name'},
    {className: 'header-18pct', element: 'Address'},
    {className: 'header-9pct', element: 'Created'},
    {
      className: 'pagination-header header-remainder',
      element: (
        <Pagination
          page={currentPage}
          pageSize={pageSize}
          paginationHandler={(page) => changePage(page)}
          totalCount={totalRecordCount}
        />
      )
    }
  ];
};

export const buildRow = (order: OutboundOrder, reservations: OMSReservation[]) => {
  const url = `/s/fulfillment/orders/${order.id}`;
  return [
    order.hasErrors ? <i className="fa fa-exclamation-triangle red3" /> : null,
    <Link to={url}>{order.id}</Link>,
    <div>{order.externalId}</div>,
    <div className={`label label-default label-div subtle-hover ${order.state}`}>
      {OrderStatusDisplay[order.state]}
    </div>,
    <div>
      {getTableDisplayValueForReservations(order.requestedReservationIds, order.plannedReservationIds, reservations)}
    </div>,
    <div>{order.recipient.name}</div>,
    <div>{order.recipient.address.line1}</div>,
    <div>{order.createdAt ? formatDate(order.createdAt, dateFormat) : null}</div>,
    <Link className="pull-right" to={url}>
      <i className="fa fa-chevron-right" />
    </Link>
  ];
};
