import {ResponseError} from '../../shared/CommonInterfaces';
import InternalAPIService from '../../shared/services/InternalAPIService';
import {EdiFilesState} from './EdiFilesInterfaces';

class EdiFilesService extends InternalAPIService {
  private listStateForFrito: EdiFilesState = {
    continuationTokens: [],
    errors: null,
    loading: true,
    carriers: [],
    serviceTypes: {},
    showUploadModal: false,
    showUploadInstructions: false,
    uploading: false,
    uploadParams: {file: {}},
    fileSelectErrors: null,
    uploadFileType: 'outbound_order_csv',
    filters: {fileType: 'frito_reports'},
    files: [],
    fileExchangeToolTip: false,
    currentPage: 1,
    totalCount: 0,
    show: false
  };

  private listState: EdiFilesState = {
    continuationTokens: [],
    errors: null,
    loading: true,
    carriers: [],
    serviceTypes: {},
    showUploadModal: false,
    showUploadInstructions: false,
    uploading: false,
    uploadParams: {file: {}},
    fileSelectErrors: null,
    uploadFileType: 'outbound_order_csv',
    filters: {},
    files: [],
    fileExchangeToolTip: false,
    currentPage: 1,
    totalCount: 0,
    show: false
  };

  constructor(authenticityToken: string) {
    super(authenticityToken, '/s/fulfillment/');
  }

  public async getCarriersAndServiceTypes() {
    return await this.makeGetRequest(`${this.baseUrl}ecommerce_v2/valid_carriers_and_service_types`);
  }

  public processErrors = (errors: ResponseError[]) => {
    return errors.map((error) => error.detail || error.title);
  };

  public getListCacheForFrito(): EdiFilesState {
    return this.listStateForFrito;
  }

  public setListCache(state: EdiFilesState) {
    this.listState = state;
  }

  public getListCache(): EdiFilesState {
    return this.listState;
  }
}

export default EdiFilesService;
