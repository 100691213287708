import * as React from 'react';
import {DetailedErrorsTable, DetailedInfoBox, InfoBox} from '@flexe/ui-components';
import {ParseError} from '../../../../libs/CsvValidator';

interface Props {
  invalidRowsCsvBlob: Blob;
  maxParsingErrorsAllowed: number;
  parseErrors: ParseError[];
}

const ParsingErrors = ({invalidRowsCsvBlob, maxParsingErrorsAllowed, parseErrors}: Props) => {
  const parseErrorsWithoutRows = parseErrors.filter((error) => error.row === undefined);
  const parseErrorsWithRows = parseErrors
    .filter((error) => error.row !== undefined)
    .sort((errorA, errorB) => (errorA.row < errorB.row ? -1 : 1));
  const rowsByParseError = {};

  parseErrorsWithRows.forEach((errorWithRow) => {
    if (rowsByParseError[errorWithRow.message]) {
      rowsByParseError[errorWithRow.message].push(errorWithRow.row);
    } else {
      rowsByParseError[errorWithRow.message] = [errorWithRow.row];
    }
  });

  const parseErrorRowDetails = [];

  for (const parseError in rowsByParseError) {
    // eslint-disable-next-line no-prototype-builtins
    if (rowsByParseError.hasOwnProperty(parseError)) {
      parseErrorRowDetails.push({message: parseError, rowsAffected: rowsByParseError[parseError]});
    }
  }

  return parseErrors.length > maxParsingErrorsAllowed ? (
    <div className="bulk-cycle-count-csv-modal__results">
      <InfoBox
        info={
          'Too many errors encountered while parsing the CSV. ' +
          'Please verify the file is correctly formatted and try again later.'
        }
        infoType="error"
      />
    </div>
  ) : (
    <>
      {parseErrorsWithoutRows.map((error) => (
        <div className="bulk-cycle-count-csv-modal__results">
          <InfoBox info={error.message} infoType="error" />
        </div>
      ))}
      {parseErrorsWithRows.length > 0 && (
        <div className="bulk-cycle-count-csv-modal__results">
          <DetailedInfoBox
            info={`${parseErrorsWithRows.length} Cycle Count${
              parseErrorsWithRows.length > 1 ? 's' : ''
            } failed to update: Edit the following ${parseErrorsWithRows.length > 1 ? 's' : ''} before creating again.`}
            infoType="error"
          >
            <a
              href={window.URL.createObjectURL(invalidRowsCsvBlob)}
              download="invalid_rows.csv"
              className="bulk-cycle-count-csv-modal__invalid-rows-csv"
            >
              Download a csv of the invalid rows
            </a>
            <DetailedErrorsTable
              headers={['Error', 'Affected Row Number(s)']}
              details={parseErrorRowDetails}
              renderInfoBoxDetailTableRow={(infoBoxDetail) => (
                <tr data-testid={'bulkCsvCCError'}>
                  <td>{infoBoxDetail.message}</td>
                  <td>{infoBoxDetail.rowsAffected.join(',')}</td>
                </tr>
              )}
            />
          </DetailedInfoBox>
        </div>
      )}
    </>
  );
};

export default ParsingErrors;
