import {ApiResponse, Packaging, SortOrder} from '../CommonInterfaces';
import {ShippingAddress} from '../../retail/ShippingAddressFields';
import {RetailFulfillmentUpdateRequest} from '../../shipper/retail-fulfillment/RetailFulfillmentInterfaces';
import {RetailShipmentDetails} from '../../shipper/retail-fulfillment/create/ShipmentDetails';
import {RetailShippingInformation} from '../../shipper/retail-fulfillment/create/ShippingInformation';
import InternalAPIService from './InternalAPIService';

interface RetailFulfillmentCreateInventory {
  sku: string;
  count: {
    amount: number;
    unit: string;
  };
}
export interface RetailFulfillmentCreateRequest {
  reservationId: number;
  shipmentDetails: RetailShipmentDetails;
  shippingInformation: RetailShippingInformation;
  instructions?: string;
  inventory: RetailFulfillmentCreateInventory[];
}

export interface GetRetailOrdersRequest {
  states: string[];
  reservationId?: number;
  warehouseId?: number;
  orderId?: number;
  customerUUID?: string;
  billOfLading?: string;
  masterBillOfLading?: string;
  shipmentTypesToExclude?: string[];
  externalLoadId?: string;
  sortDirection?: SortOrder;
  sortBy?: RetailSortBy;
  pageSize?: number;
  continuationToken?: string;
}

enum RetailSortBy {
  routeBy = 'route_by',
  shipWithinStart = 'ship_within_start',
  shipWithinEnd = 'ship_within_end'
}

export interface GetRetailOrdersResponse {
  continuationToken: string;
  counts: {
    new?: number;
    confirmed?: number;
    pickup_scheduled?: number;
    completed?: number;
    cancelled?: number;
  };
  orders: RetailOrder[];
}

export interface GetRetailOrderDetailsRequest {
  orderId: number;
}

export interface UpdateTrailerInfoRequest {
  trailerNumber: string;
  proNumber: string;
  sealNumber: string;
}

export interface UpdatePreferredStagingLocationRequest {
  stagingLocationId: number;
}

export interface BulkSchedulePickupRequest {
  orderIds: number[];
  scac?: string;
  masterBillOfLadingNumber?: string;
  consolidatorBound?: boolean;
  startDateTime?: Date;
  endDateTime?: Date;
  timezone?: string;
  changeShippingAddress?: boolean;
  shippingAddress?: ShippingAddress;
}

export interface CompleteOrderRequest {
  trailerNumber: string;
  proNumber: string;
  sealNumber: string;
  outboundLocationId?: string;
  lpnsToBeOutbounded?: string;
}

export interface BuildPalletsRequest {
  numPallets: number;
}

export interface RetailOrder {
  id: number;
  type: string;
  reservation: {
    id: number;
    type: string;
  };
  warehouse: {
    id: number;
    type: string;
  };
  shipperCompany: {
    id: number;
    name: string;
    type: string;
  };
  state: string;
  customerUuid: string;
  purchaseOrder: string;
  masterBillOfLadingNumber: string;
  billOfLadingNumber: string;
  routeBy: Date;
  shipWithinStart: Date;
  shipWithinEnd: Date;
  palletsBuilt: number;
  trailerNumber: string;
  proNumber: string;
  scac: string;
  createdAt: Date;
  updatedAt: Date;
  orderItems: OrderItem[];
}

export interface RetailOrderDetails {
  id: number;
  type: string;
  reservation: {
    id: number;
    type: string;
  };
  warehouse: {
    id: number;
    type: string;
    timezone?: string;
  };
  shipperCompany: {
    id: number;
    name: string;
    type: string;
    warehouseVisibleNickname?: string;
  };
  routingDetails: {
    displayName: string;
  };
  shipTo: ShippingAddress;
  state: string;
  customerUuid: string;
  purchaseOrder: string;
  masterBillOfLadingNumber: string;
  billOfLadingNumber: string;
  routeBy: Date;
  instructions: string;
  pickupWindowStart: Date;
  pickupWindowEnd: Date;
  shipWithinStart: Date;
  shipWithinEnd: Date;
  palletsBuilt: number;
  trailerNumber: string;
  pickingHasStarted: boolean;
  pickStatus: string;
  allowShowingPickStatus: boolean;
  destinationRetailer: string;
  ssccLabelsStillGenerating: boolean;
  proNumber: string;
  scac: string;
  sealNumber: string;
  shipmentType: string;
  shipmentMethod: string;
  createdAt: Date;
  updatedAt: Date;
  completedAt: Date;
  completedBy: string;
  cancelledAt: Date;
  cancelledBy: string;
  orderItems: OrderItem[];
  originalOrderItems?: OrderItem[];
  estimatedWeight?: string;
  preferredStagingLocation?: {
    id: number;
    label: string;
  };
}

export interface OrderItem {
  id: number;
  inventoryId: number;
  sku?: string;
  skuDescription?: string;
  packaging: Packaging;
  quantity: number;
  unitConversions: UnitConversions;
  ssccLabels?: SsccPallet[];
  lpnBarcode?: string;
  createdAt: Date;
  updatedAt: Date;
}

interface UnitConversions {
  eaches: number;
  cartons: number;
}

export interface SsccPallet {
  label: string;
  palletNumber: string;
  contents: SsccPalletContents;
}

export interface SsccPalletContents {
  sku: string;
  skuDescription: string;
  quantity: number;
  packaging: string;
}

class RetailFulfillmentService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/s/retail_fulfillment/shipments');
  }

  public async createOrder(request: RetailFulfillmentCreateRequest): Promise<ApiResponse<any>> {
    return await this.makePostRequest('/api/fulfillment/retail/create', request);
  }

  public async updateOrder(orderId: number, request: RetailFulfillmentUpdateRequest): Promise<ApiResponse<any>> {
    if (request.shipmentInfo) {
      request.shipmentInfo.carrier = request.shipmentInfo.carrier ? request.shipmentInfo.carrier : null;
      request.shipmentInfo.serviceType = request.shipmentInfo.serviceType ? request.shipmentInfo.serviceType : null;
    }
    return await this.makePatchRequest(`${this.baseUrl}/${orderId}`, request);
  }

  public async getOrders(request: GetRetailOrdersRequest): Promise<ApiResponse<GetRetailOrdersResponse>> {
    return await this.makeGetRequest('/api/fulfillment/retail/orders', request);
  }

  public async getOrderDetails(request: GetRetailOrderDetailsRequest): Promise<ApiResponse<RetailOrderDetails>> {
    return await this.makeGetRequest(`/api/fulfillment/retail/order/${request.orderId}/details`);
  }

  public async updateTrailerInfo(orderId: number, request: UpdateTrailerInfoRequest): Promise<ApiResponse<any>> {
    return await this.makePatchRequest(`/api/fulfillment/retail/order/${orderId}/trailer_info`, request);
  }

  public async cancelOrders(orderIds: string[]): Promise<ApiResponse<any>> {
    return await this.makePatchRequest('/api/fulfillment/retail/bulk_cancel', {orderIds});
  }

  public async bulkSchedulePickup(request: BulkSchedulePickupRequest): Promise<ApiResponse<any>> {
    return await this.makePatchRequest('/api/fulfillment/retail/bulk_schedule_pickup', request);
  }

  public async confirmOrders(orderIds: string[]): Promise<ApiResponse<any>> {
    return await this.makePatchRequest('/api/fulfillment/retail/bulk_confirm', {orderIds});
  }

  public async buildPallets(orderId: number, request: BuildPalletsRequest): Promise<ApiResponse<any>> {
    return await this.makePatchRequest(`/api/fulfillment/retail/order/${orderId}/build`, request);
  }

  public async updatePreferedStagingLocation(orderId: number, stagingLocationId: number): Promise<ApiResponse<any>> {
    const path = `/api/fulfillment/retail/order/${orderId}/preferred_staging_location`;
    return await this.makePatchRequest(path, {stagingLocationId});
  }

  public async completeOrder(orderId: number, request: CompleteOrderRequest): Promise<ApiResponse<any>> {
    return await this.makePatchRequest(`/api/fulfillment/retail/order/${orderId}/complete`, request);
  }
}

export default RetailFulfillmentService;
