/** @jsxRuntime classic */
/** @jsx jsx */

import {useState} from 'react';
import {css, jsx} from '@emotion/react';
import {Column, Heading, Row, Text} from '@flexe/ui-components';
import {containerStyle} from '../styles';
import UserManagementService from '../UserManagementService';
import {generateNotification} from '../notifications/notification-utility';

interface NewUserFormProps {
  authenticityToken: string;
}

const fullWidthInputStyle = css({width: '100%'});

const NewUserForm = ({authenticityToken}: NewUserFormProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isProcessing, setProcessing] = useState(false);

  const userManagementService = new UserManagementService(authenticityToken);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const result = await userManagementService.createUserAsync({
      firstName,
      lastName,
      email
    });
    const error = result.errors[0];

    if (error) {
      generateNotification({level: 'error', message: error.detail});
      setProcessing(false);
    } else {
      generateNotification({
        level: 'success',
        message: `${result.data.firstName} ${result.data.lastName} - Added to your company`
      });
      setTimeout(() => (window.location.href = `/user_management/${result.data.id}/new_roles`), 2000);
    }
  };

  return (
    <div css={containerStyle}>
      <Column>
        <Heading level="h2">Add User</Heading>
        <Text tag="p">Allow another user in your organization to manage different responsibilities.</Text>
        <form role="form" id="new_user" onSubmit={handleFormSubmit}>
          <Column>
            <input type="hidden" name="authenticity_token" value={authenticityToken} />

            <Row childWidths={['grid-2', 'grid-4']} margin={['0', '200']}>
              <Text tag="label" htmlFor="first-name" type="h90">
                First name
              </Text>
              <input
                type="text"
                id="first-name"
                name="user[first_name]"
                value={firstName}
                onChange={handleFirstNameChange}
                css={fullWidthInputStyle}
              />
            </Row>
            <Row childWidths={['grid-2', 'grid-4']} margin={['0', '200']}>
              <Text tag="label" htmlFor="last-name" type="h90">
                Last name
              </Text>
              <input
                type="text"
                id="last-name"
                name="user[last_name]"
                value={lastName}
                onChange={handleLastNameChange}
                css={fullWidthInputStyle}
              />
            </Row>
            <Row childWidths={['grid-2', 'grid-4']} margin={['0', '200']}>
              <Text tag="label" htmlFor="email" type="h90">
                Email
              </Text>
              <input
                type="email"
                id="email"
                name="user[email]"
                value={email}
                onChange={handleEmailChange}
                css={fullWidthInputStyle}
              />
            </Row>

            <div>
              <input
                type="submit"
                value="Add User"
                className="btn"
                disabled={[firstName, lastName, email].some((field) => field.trim() === '') || isProcessing}
              />
            </div>
          </Column>
        </form>
      </Column>
    </div>
  );
};

export default NewUserForm;
