import * as React from 'react';
import * as moment from 'moment-timezone';
import {Loader, Table} from '@flexe/ui-components';
import {TableData} from '@flexe/ui-components';
import {RetailOrder} from '../shared/services/RetailFulfillmentService';
import {SortOrder} from '../shared/CommonInterfaces';
import SortableTableHeader from '../shared/SortableTableHeader';
import {WriteableProp} from '../../libs/CustomHooks';
import WarehouseService from '../shared/services/WarehouseService';
import {CONFIRMED_ORDERS_TAB, NEW_ORDERS_TAB} from './RetailConstants';

const UNSPECIFIED_TEXT = 'Unspecified';

interface RetailOrderDetailsTableProps {
  counts: Counts;
  selectedTab: string;
  bulkActionButton: object;
  path: string;
  shouldShowSelectAllCheckbox: any;
  shouldShowRowCheckbox: any;
  isLoading: boolean;
  sortBy: WriteableProp<string>;
  sortDirection: WriteableProp<string>;
  selectAllIsChecked: WriteableProp<boolean>;
  selectedOrders: WriteableProp<RetailOrder[]>;
  retailOrders: RetailOrder[];
  warehouseTimezone: string;
  enablePurchaseOrder: boolean;
}

interface Counts {
  new: number;
  confirmed: number;
  pickup_scheduled: number;
  completed: number;
  cancelled: number;
}

const RetailOrderDetailsTable: React.FC<RetailOrderDetailsTableProps> = (props) => {
  const {
    selectedTab,
    sortBy,
    sortDirection,
    path,
    shouldShowSelectAllCheckbox,
    isLoading,
    retailOrders,
    shouldShowRowCheckbox,
    bulkActionButton,
    selectedOrders,
    selectAllIsChecked,
    warehouseTimezone
  } = props;

  const uuidHeader = (
    <a className="sortable-header" onClick={() => handleSorting('purchase_order_uuid')}>
      <SortableTableHeader
        isActive={sortBy.value === 'purchase_order_uuid'}
        sortDirection={sortDirection.value}
        headerText={props.enablePurchaseOrder ? 'UUID' : 'PO'}
      />
    </a>
  );
  const poHeader = (
    <a className="sortable-header" onClick={() => handleSorting('purchase_order')}>
      <SortableTableHeader
        isActive={sortBy.value === 'purchase_order'}
        sortDirection={sortDirection.value}
        headerText={'PO'}
      />
    </a>
  );
  const routeByHeader = (
    <a className="sortable-header" onClick={() => handleSorting('route_by')}>
      <SortableTableHeader
        isActive={sortBy.value === 'route_by'}
        sortDirection={sortDirection.value}
        headerText="Route By"
      />
    </a>
  );
  const reqStartHeader = (
    <a className="sortable-header" onClick={() => handleSorting('ship_within_start')}>
      <SortableTableHeader
        isActive={sortBy.value === 'ship_within_start'}
        sortDirection={sortDirection.value}
        headerText="Requested Ship Start"
      />
    </a>
  );
  const reqEndHeader = (
    <a className="sortable-header" onClick={() => handleSorting('ship_within_end')}>
      <SortableTableHeader
        isActive={sortBy.value === 'ship_within_end'}
        sortDirection={sortDirection.value}
        headerText="Requested Ship End"
      />
    </a>
  );

  const createOrderQuantities = (order: RetailOrder) => {
    const eaches: number = order.orderItems.reduce((sum, current) => sum + current.unitConversions.eaches, 0);
    const cartons: number = order.orderItems.reduce((sum, current) => sum + current.unitConversions.cartons, 0);

    return (
      <div>
        <b>{cartons} cartons</b>
        <br />({eaches} eaches)
      </div>
    );
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;

    let newSelectedOrders: RetailOrder[] = [];

    if (isChecked) {
      newSelectedOrders = Array.from(retailOrders);
    } // If not checked, use the empty array to unselect everything

    selectedOrders.set(newSelectedOrders);
    selectAllIsChecked.set(isChecked);
  };

  const handleSelect = (event) => {
    const isChecked = event.target.checked;
    const orderId = parseInt(event.target.getAttribute('data-order-id'), 10);

    // We have to set new Array objects here, otherwise React's
    // object reference comparison will not detect a change in state
    // and thus will not re-render
    let newSelectedOrders = [];
    const prevSelectedOrders = selectedOrders.value;
    if (isChecked) {
      const orderAlreadySelected = !!prevSelectedOrders.find((o) => o.id === orderId);

      if (!orderAlreadySelected) {
        const order: RetailOrder = retailOrders.find((o) => o.id === orderId);
        prevSelectedOrders.push(order);

        newSelectedOrders = Array.from(prevSelectedOrders);
      } else {
        newSelectedOrders = prevSelectedOrders;
      }
    } else {
      selectAllIsChecked.set(false);
      newSelectedOrders = Array.from(prevSelectedOrders.filter((o) => o.id !== orderId));
    }

    selectedOrders.set(newSelectedOrders);
  };

  const createSelectAllCheckbox = () => {
    if (shouldShowSelectAllCheckbox(selectedTab)) {
      return <input type="checkbox" checked={selectAllIsChecked.value} onChange={(event) => handleSelectAll(event)} />;
    } else {
      return null;
    }
  };

  const orderIsSelected = (order: RetailOrder) => {
    const orderSearch = selectedOrders.value.find((o) => o.id === order.id);

    return !!orderSearch;
  };

  const createRowCheckbox = (order: RetailOrder) => {
    if (shouldShowRowCheckbox(order)) {
      return (
        <input
          type="checkbox"
          data-order-id={order.id}
          checked={orderIsSelected(order)}
          onChange={(event) => handleSelect(event)}
        />
      );
    } else {
      return null;
    }
  };

  const formatInWarehouseTimezone = (date: Date) => {
    const momentDate = moment(date);
    const dateFormat = 'MM/DD/YYYY h:mm a (z)';

    try {
      return momentDate.tz(warehouseTimezone).format(dateFormat);
    } catch {
      // fallback on the user's local timezone if warehouseTimezone is not valid
      return momentDate.tz(moment.tz.guess(true)).format(dateFormat);
    }
  };

  const formatTableData = (orders: RetailOrder[], tab: string): TableData => {
    const showPalletColumn = tab !== CONFIRMED_ORDERS_TAB && tab !== NEW_ORDERS_TAB;
    return {
      headers: buildHeaders(showPalletColumn),
      rows: buildRows(orders, showPalletColumn)
    };
  };

  const buildHeaders = (showPalletColumn: boolean) => {
    const headers = [
      {element: createSelectAllCheckbox()},
      {element: 'Reservation'},
      {element: 'FLEXE ID'},
      {element: uuidHeader},
      {element: 'BOL #'},
      {element: 'Master BOL'},
      {element: routeByHeader},
      {element: reqStartHeader},
      {element: reqEndHeader},
      {element: 'Quantity'}
    ];

    if (showPalletColumn) {
      headers.push({element: 'Pallets'});
    }

    if (props.enablePurchaseOrder) {
      headers.splice(4, 0, {element: poHeader});
    }

    headers.push({element: ''});

    return headers;
  };

  const buildRows = (orders: RetailOrder[], showPalletColumn: boolean) => {
    return orders.map((order: RetailOrder) => {
      const row = [
        createRowCheckbox(order),
        `#${order.reservation.id}`,
        <a href={`${path}/${order.id}`}>{order.id}</a>,
        order.customerUuid,
        order.billOfLadingNumber,
        order.masterBillOfLadingNumber,
        order.routeBy ? formatInWarehouseTimezone(order.routeBy) : <i>{UNSPECIFIED_TEXT}</i>,
        formatInWarehouseTimezone(order.shipWithinStart),
        formatInWarehouseTimezone(order.shipWithinEnd),
        createOrderQuantities(order)
      ];

      if (showPalletColumn) {
        row.push(order.palletsBuilt);
      }

      if (props.enablePurchaseOrder) {
        row.splice(4, 0, order.purchaseOrder);
      }

      row.push(
        <a href={`${path}/${order.id}`}>
          <i className="fa fa-chevron-right"></i>
        </a>
      );

      return row;
    });
  };

  const handleSorting = (sortByColumn: string) => {
    if (sortBy.value === sortByColumn && sortDirection.value === SortOrder.ASC) {
      sortDirection.set(SortOrder.DESC);
    } else if (sortBy.value === sortByColumn && sortDirection.value === SortOrder.DESC) {
      sortDirection.set(null);
      sortBy.set(null);
    } else {
      sortDirection.set(SortOrder.ASC);
      sortBy.set(sortByColumn);
    }
  };

  return (
    <div>
      <Loader loading={isLoading} />
      {!isLoading && retailOrders.length === 0 && <p>No orders matched your filters.</p>}
      <div className="bulk-buttons">{bulkActionButton}</div>

      <Table tableData={formatTableData(retailOrders, selectedTab)} />
    </div>
  );
};

export default RetailOrderDetailsTable;
