import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {get} from 'lodash';
import {Loader, Table} from '@flexe/ui-components';
import Filters from '../../shared-v2/Filters';
import {Filter, FilterOption, FilterValue} from '../../../shared/CommonInterfaces';
import InternalAPIServiceV2 from '../../../shared/services/InternalAPIServiceV2';
import {OMSReservation} from '../../ecommerce-orders/OrdersAppInterfaces';
import OutboundOrdersService from '../OutboundOrdersService';
import {OrderStatus, OrderStatusDisplay, OrderType, OutboundOrder} from '../OutboundOrdersInterfaces';
import {PageSize} from '../generic/PageSizeInput';
import {getFilterDisplayValue, orderFilterDefs, TrackedFilterValues} from '../helpers/OutboundOrdersFilters';
import {OrdersPageError, startNewLongRunningAction} from './OutboundOrdersV2';
import {buildRow, buildTableHeaders} from './OutboundOrdersTableRow';

interface Props {
  companyId: number;
  outboundOrdersService: OutboundOrdersService;
  pageSize: PageSize;
  setErrors: (v: OrdersPageError) => void;
  reservations: OMSReservation[];
  reservationOptions: FilterOption[];
  filterValues: TrackedFilterValues;
  handleFilterChange: (filters: FilterValue[]) => void;
}

interface OrdersTableInfo {
  continuationTokens: string[];
  orders: OutboundOrder[];
  totalOrderCount: number;
  currentPage: number;
}

const buildInitialOrdersTableInfo = (): OrdersTableInfo => {
  return {
    continuationTokens: [],
    orders: [],
    totalOrderCount: 0,
    currentPage: 1
  };
};

const orderStatusOptions: FilterOption[] = [
  {
    displayName: 'Choose a status...',
    value: ''
  },
  {
    displayName: OrderStatusDisplay[OrderStatus.open],
    value: OrderStatus.open
  },
  {
    displayName: OrderStatusDisplay[OrderStatus.closed],
    value: OrderStatus.closed
  }
];

const orderTypeOptions: FilterOption[] = [
  {
    displayName: 'Choose an order type...',
    value: ''
  },
  {
    displayName: OrderType.distribution,
    value: OrderType.distribution
  },
  {
    displayName: OrderType.ecommerce,
    value: OrderType.ecommerce
  }
];

const buildFilters = (
  filterValues: TrackedFilterValues,
  reservationOptions: FilterOption[],
  companyId: number
): Filter[] => {
  return orderFilterDefs.map((filterDef) => {
    const value = getFilterDisplayValue(filterValues, filterDef.key);

    return {
      ...filterDef,
      value,
      // Legacy behavior was to default to shopify_order_number for all customers, but
      // that doesn't make sense for most customers and there isn't a good global default.
      // Dermalogica (company 4614) frequently searches by shopify_order_number, so leave it for them only
      // (aka don't add clicks)
      ...(filterDef.key === 'labels' && {defaultKey: companyId === 4614 ? 'shopify_order_number' : undefined}),
      ...(filterDef.key === 'orderType' && {options: orderTypeOptions}),
      ...(filterDef.key === 'reservationIds' && {options: reservationOptions}),
      ...(filterDef.key === 'state' && {options: orderStatusOptions})
    };
  });
};

const getPageOfOrders = async (
  pageSize: PageSize,
  filterValues: TrackedFilterValues,
  setErrors: (v: OrdersPageError) => void,
  outboundOrdersService: OutboundOrdersService,
  currentOrdersTableInfo: OrdersTableInfo
): Promise<OrdersTableInfo> => {
  const newOrdersTableInfo: OrdersTableInfo = {...currentOrdersTableInfo};

  if (!outboundOrdersService) {
    setErrors({errorType: 'UnexpectedFailure'});
    return newOrdersTableInfo;
  }

  const ordersError: OrdersPageError = {errorType: 'None'};
  const currentPage = newOrdersTableInfo.currentPage;

  const ordersResponse = await outboundOrdersService.getOrders(
    filterValues.apiFilters,
    currentPage > 1 ? newOrdersTableInfo.continuationTokens[currentPage - 2] : null,
    pageSize,
    null,
    {returnAllErrors: true}
  );

  if (!ordersResponse) {
    newOrdersTableInfo.orders = [];
    newOrdersTableInfo.totalOrderCount = 0;

    ordersError.errorType = 'UnexpectedFailure';
  } else if (ordersResponse.errors && ordersResponse.errors.length > 0) {
    newOrdersTableInfo.orders = [];
    newOrdersTableInfo.totalOrderCount = 0;

    ordersError.errorType = 'ApiGetError';
    ordersError.details = InternalAPIServiceV2.extractErrorSummaries(ordersResponse.errors);
  } else {
    const newContinuationTokens: string[] = newOrdersTableInfo.continuationTokens;
    // TODO this returning an array is bizarre
    const newToken = [get(ordersResponse, 'continuationToken')][0];
    if (currentPage > newContinuationTokens.length) {
      newContinuationTokens.push(newToken);
    } else {
      newContinuationTokens[currentPage - 1] = newToken;
    }
    newOrdersTableInfo.continuationTokens = newContinuationTokens;
    newOrdersTableInfo.orders = get(ordersResponse, 'outboundOrders') || ([] as OutboundOrder[]);
    newOrdersTableInfo.totalOrderCount = get(ordersResponse, 'total');
  }

  setErrors(ordersError);
  return newOrdersTableInfo;
};

export const buildOrdersRows = (ordersTableInfo: OrdersTableInfo, reservations: OMSReservation[]) => {
  if (!ordersTableInfo || !ordersTableInfo.orders) {
    return [];
  }
  return ordersTableInfo.orders.map((order) => buildRow(order, reservations));
};

const OutboundOrdersTable: FC<Props> = (props) => {
  const [ordersTableInfo, setOrdersTableInfo] = useState<OrdersTableInfo>(buildInitialOrdersTableInfo());
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Since pageSize is required, this handles initial load as well
  useEffect(() => loadOrders(buildInitialOrdersTableInfo()), [props.pageSize, props.filterValues]);

  const loadOrders = (newOrdersTableInfo: OrdersTableInfo) => {
    startNewLongRunningAction(setIsLoading, props.setErrors);

    getPageOfOrders(
      props.pageSize,
      props.filterValues,
      props.setErrors,
      props.outboundOrdersService,
      newOrdersTableInfo
    )
      .then((result) => {
        setOrdersTableInfo(result);
        setIsLoading(false);
      })
      .catch((_) => {
        props.setErrors({errorType: 'UnexpectedFailure'});
        // Don't update table info because we failed to load the new page
        setIsLoading(false);
      });
  };

  const changePage = (newPage: number) => {
    // Page changes mean we can keep all the current data, but load a different page
    const newOrdersTableInfo: OrdersTableInfo = {...ordersTableInfo, currentPage: newPage};
    loadOrders(newOrdersTableInfo);
  };

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  return (
    <>
      <div className="row filters">
        <Filters
          filters={buildFilters(props.filterValues, props.reservationOptions, props.companyId)}
          filterChangeHandler={(newFilters) => props.handleFilterChange(newFilters)}
        />
      </div>
      {ordersTableInfo.orders.length === 0 ? (
        <p>No orders matching the current filters were found.</p>
      ) : (
        <Table
          tableClass="table-striped outbound-orders-shared-table"
          tableData={{
            headers: buildTableHeaders(
              ordersTableInfo.currentPage,
              ordersTableInfo.totalOrderCount,
              props.pageSize,
              changePage
            ),
            rows: buildOrdersRows(ordersTableInfo, props.reservations)
          }}
        />
      )}
    </>
  );
};
export default OutboundOrdersTable;
