import {FC} from 'react';
import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {Destination} from '../ShipmentInterfaces';
import {humanReadablePhoneNumber} from '../../../shared/utilities/DataFormatting';

interface Props {
  destination: Destination;
  shipperNotes?: string;
}

export const ShippingInfo: FC<Props> = (props) => {
  const [showNotesModal, setShowNotesModal] = React.useState<boolean>(false);

  const {destination, shipperNotes} = props;

  const shipToInfo = (
    <React.Fragment>
      <div className="header">Ship To:</div>
      <div className="body">
        <div className="address-row">{destination.name}</div>
        <div className="address-row">{destination.address_line_1}</div>
        <div className="address-row">{destination.address_line_2}</div>
        <div className="address-row">{destination.address_line_3}</div>
        <div className="address-row">
          {`${destination.city}, ${destination.state} ${destination.postal_code}, ${destination.country}`}
        </div>
        <div className="address-row">{humanReadablePhoneNumber(destination.phone)}</div>
      </div>
    </React.Fragment>
  );

  const notes = (
    <div id="shipper-notes">
      <div className="header">Shipper Notes:</div>
      <div className="body">{shipperNotes}</div>
    </div>
  );

  /*
       Determines whether or not the shipper notes are so long that they overflow their text box. When true we add
       a link to open a modal that shows the notes in full.
     */
  const notesOverflow = (): boolean => {
    const element = document.getElementById('shipper-notes');
    if (!element) {
      return false;
    }
    return element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight;
  };

  const notesModal = (): React.ReactFragment => {
    return (
      <div id="shipper-notes-modal">
        <LegacyModal
          id="shipper-notes-modal"
          show={showNotesModal}
          title="Shipper Notes"
          size="small"
          toggleModal={() => setShowNotesModal(!showNotesModal)}
        >
          <p>{shipperNotes}</p>
        </LegacyModal>
      </div>
    );
  };

  const shipperInfo = (): React.ReactFragment => {
    if (shipperNotes != null) {
      return (
        <div id="ship-info-card">
          <div className="ship-to ship-info-content">{shipToInfo}</div>

          <div id="notes-content" className="notes-content">
            {notes}
            {notesOverflow() && (
              <div id="overflow-controls">
                ...&nbsp;<a onClick={() => setShowNotesModal(true)}>Read More</a>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div id="ship-info-card">
          <div className="ship-to ship-info-content">{shipToInfo}</div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      {notesModal()}
      {shipperInfo()}
    </React.Fragment>
  );
};
