import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {Item} from '../../shared/services/ItemMasterService';
import FlexeButton from '../FlexeButton';
import {CycleCountItem} from './CycleCountInterfaces';
import {ItemPropertiesDetailsModalState} from './CycleCountDetail';
import ItemPropertiesMetadataForm from './ItemPropertiesMetadataForm';

interface Props {
  id: string;
  forShipper: boolean;
  showItemPropertiesDetailsModal: ItemPropertiesDetailsModalState;
  disabled: boolean;
  cycleCountItem: CycleCountItem;
  activeWorkersExist: boolean;
  renderCurrentWorkers: () => JSX.Element;
  toggleItemDetailsModalWithId: (id: string) => void;
  saveLpnMetadataEdits: (data) => Promise<void>;
  inventoryInfoForCCItem: Item[];
}

const ItemPropertiesDetailsModal: FunctionComponent<Props> = (props) => {
  const expectedItd = props.cycleCountItem.metadata?.expected?.id.toString();
  const itdsMatch = expectedItd === props.showItemPropertiesDetailsModal.id;

  return (
    <LegacyModal
      id={expectedItd}
      title={`Edit ${props.cycleCountItem.inventory.sku} Properties`}
      show={itdsMatch && props.showItemPropertiesDetailsModal.visible}
      size="small"
      toggleModal={() => {
        props.toggleItemDetailsModalWithId(expectedItd);
      }}
      footer={
        <FlexeButton
          text="Cancel"
          testid={expectedItd}
          level={'collapsed-link'}
          handleClick={() => {
            props.toggleItemDetailsModalWithId(expectedItd);
          }}
        />
      }
      disableClose={false}
    >
      {props.activeWorkersExist ? (
        <div className="row space-below">
          <p>
            Editing or submitting are disabled while mobile users are in progress. To edit here, mobile users must
            submit their work from the device.
          </p>
          <p>The following workers are still active:</p>
          {props.renderCurrentWorkers()}
        </div>
      ) : (
        <ItemPropertiesMetadataForm
          id={expectedItd}
          forShipper={props.forShipper}
          disabled={props.disabled}
          cycleCountItem={props.cycleCountItem}
          toggleItemDetailsModalWithId={props.toggleItemDetailsModalWithId}
          saveLpnMetadataEdits={props.saveLpnMetadataEdits}
          inventoryInfoForCCItem={props.inventoryInfoForCCItem}
        />
      )}
    </LegacyModal>
  );
};

export default ItemPropertiesDetailsModal;
