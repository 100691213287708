import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';

interface GenerateLPNProps {
  showModal?: boolean;
}

interface GenerateLPNState {
  showModal: boolean;
  numLabels?: string;
  error?: string;
}

class GenerateLPNs extends React.Component<GenerateLPNProps, GenerateLPNState> {
  constructor(props) {
    super(props);
    this.state = {
      numLabels: '',
      showModal: props.showModal || false,
      error: null
    };
  }

  public render() {
    return (
      <div id="generate-lpns-component">
        <button id="print-lpns-button" onClick={this.showLPNModal}>
          Print LPN Labels
        </button>
        <LegacyModal
          id="generate-lpns-modal"
          size="small"
          show={this.state.showModal}
          toggleModal={this.hideLPNModal}
          title={'Print LPN Labels'}
          footer={
            <div>
              <button id="lpn-cancel-button" className="btn-default" onClick={this.hideLPNModal}>
                Cancel
              </button>
              <button
                id="lpn-generate-button"
                className="btn-primary"
                onClick={this.handleCreate}
                disabled={this.hasError() || this.state.numLabels === ''}
              >
                Generate LPN Labels
              </button>
            </div>
          }
        >
          <div>
            <div className="form-group">
              <label className="form-control-label" htmlFor="generate-lpn-input">
                # of Labels
              </label>
              <input
                id="generate-lpn-input"
                className="form-control"
                value={this.state.numLabels}
                type="number"
                min="1"
                max="99"
                onChange={this.handleChange}
              />
            </div>
            <div className={'col-md-12' + (this.hasError() ? ' alert alert-danger' : '')}>
              <span id="generate-lpn-error">{this.state.error}</span>
            </div>
          </div>
        </LegacyModal>
      </div>
    );
  }

  private hasError = (): boolean => {
    return this.state.error !== null;
  };

  private toggleLPNModal = (showModal: boolean) => {
    this.setState({
      numLabels: '',
      showModal
    });
  };

  private hideLPNModal = () => {
    this.toggleLPNModal(false);
  };

  private showLPNModal = () => {
    this.toggleLPNModal(true);
  };

  private handleCreate = () => {
    window.open('/api/v2/lpns/generate/' + this.state.numLabels + '.pdf', '_blank');
    this.hideLPNModal();
  };

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = event.target.value;
    this.setState({
      numLabels: newVal,
      error: this.isValidNumber(newVal) ? null : 'Please enter a positive number.'
    });
  };

  private isValidNumber = (numLabelStr: string): boolean => {
    const numLabels: number = parseInt(numLabelStr, 10);
    return numLabels > 0;
  };
}
export default GenerateLPNs;
