import * as React from 'react';
import {ItemQuantity, Packaging} from '../../../CommonInterfaces';

const quantityStrings = {
  [Packaging.carton]: 'Carton',
  [Packaging.each]: 'Each',
  [Packaging.pallet]: 'Pallet'
};
export function UnitCell({quantity}: {quantity: ItemQuantity}) {
  const quantityString = quantityStrings[quantity.unit];
  if (quantity.amount === 1) {
    return <>{quantityString}</>;
  } else if (quantity.unit === Packaging.each) {
    return <>{quantityString}es</>;
  } else {
    return <>{quantityString}s</>;
  }
}
