import {ApiResponse, ShipmentDocumentType} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

/**
 * A representation of the Outbound Document model
 */
export interface OutboundDocument {
  id: number;
  fileName?: string;
  generationStatus?: string;
  labelType: {
    enumValue: ShipmentDocumentType;
    displayName: string;
    subDisplayName: string | null;
  };
  shipmentId: number;
  error?: string;
  labelDetails: {
    secureDownloadUrl: string;
    updatedAt: string | null;
  };
}

export interface OutboundDocumentsResponse {
  documents: OutboundDocument[];
}

class OutboundDocumentService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/outbound_documents');
  }

  /**
   * Fetch a set of documents from the server.
   * @param shipmentId Required, shipmentId of the shipment associated to the documents
   * @returns An array of outbound documents
   */
  public async getOutboundDocuments(shipmentId: number): Promise<ApiResponse<OutboundDocumentsResponse>> {
    const data = {
      shipment_id: shipmentId
    };
    const response = await this.makeGetRequest(`${this.baseUrl}/`, data);
    return response;
  }

  /**
   * Get a combined PDF of the given documentType
   * associated with the provided shipment ID for the user to print
   * @param shipmentId Required, shipmentId of the shipment associated to the documents
   * @param documentType Required, documentType to fetch
   * @param customDocumentName Required, the human readable custom name of document
   * @param quantity Optional, the number of copies to turn into a multi-page PDF
   * @returns a generated PDF
   */
  public async printDocuments(
    shipmentId: number,
    documentType: string,
    customDocumentName: string,
    quantity: number = 1
  ): Promise<ApiResponse<any>> {
    // TODO: VENCOM-816 define this return type
    const data = {
      shipment_id: shipmentId,
      document_type: documentType,
      custom_document_name: customDocumentName,
      quantity
    };
    const response = await this.makeGetRequest(`${this.baseUrl}/print_documents`, data);
    return response;
  }

  /**
   * Trigger the generation or regeneration of documents
   * @param shipmentId Required, shipmentId of the shipment associated to the labels
   * @param documentType Required, the type of document to generate
   * @param customDocumentName Required, the custom document name
   * @returns no data; may return errors
   */
  public async generateDocuments(
    shipmentId: number,
    documentType: string,
    customDocumentName: string
    // eslint-disable-next-line @typescript-eslint/ban-types
  ): Promise<ApiResponse<{}>> {
    const data = {
      shipment_id: shipmentId,
      document_type: documentType,
      custom_document_name: customDocumentName
    };
    const response = await this.makePostRequest(`${this.baseUrl}/generate_documents`, data);
    return response;
  }

  /**
   * Triggers the creation of rows in the outbound_documents table,
   * if the given shipment should have documents generated at some point
   * @param shipmentId Required, shipmentId of the shipment associated to the labels
   * @returns no data; may return errors
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public async planDocuments(shipmentId: number): Promise<ApiResponse<{}>> {
    const data = {
      shipment_id: shipmentId
    };
    const response = await this.makePostRequest(`${this.baseUrl}/plan_documents`, data);
    return response;
  }
}

export default OutboundDocumentService;
