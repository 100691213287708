import * as React from 'react';
import classNames from 'classnames';
import StatusTab from './StatusTab';

interface TabData {
  title: string;
  status: string;
  count?: number;
  active?: boolean;
  // track selectedStatusFilter in state, pass
  // {selectedStatusFilter === '<whatever string you need>'}
  // as the boolean prop to trigger changes
}

interface Props {
  data: TabData[];
  handleTabSelect: (event: any) => void;
}

const FilterBar: React.FC<Props> = (props) => {
  const handleTabClick = (event: any) => {
    const tabKey = event.currentTarget.getAttribute('data-status');
    props.handleTabSelect(tabKey);
  };

  const tabs = props.data.map((tab, i) => {
    const listItemClasses = [tab.active && 'active'];

    return (
      <li key={`${i}-${tab.status}`} className={classNames(listItemClasses)}>
        <a className="filter-bar-link" data-status={tab.status} onClick={handleTabClick}>
          <StatusTab status={tab.status} title={tab.title} active={tab.active} count={tab.count} />
        </a>
      </li>
    );
  });

  const filterBarClasses = ['filter-bar'];

  return (
    <ul className={classNames(filterBarClasses)} id="filter-bar-component">
      {tabs}
    </ul>
  );
};

export default FilterBar;
