/** @jsxRuntime classic */
/** @jsx jsx */

import {useState} from 'react';
import {css, jsx} from '@emotion/react';
import {Column, Modal, Row, Text} from '@flexe/ui-components';
import {MobileUser} from '../userManagementInterfaces';

/* the 'position: absolute' makes it so the open modal doesn't take up space in the parent's
   Section component */
const containerStyle = css({h3: {marginBottom: 0}, position: 'absolute'});

interface Props {
  authenticityToken: string;
  onModalClose: () => void;
  warehouseId: number;
  warehouseName: string;
  users: MobileUser[];
}

const AddMobileUserModal = ({authenticityToken, onModalClose, warehouseId, warehouseName, users}: Props) => {
  const [selectedUserId, setSelectedUserId] = useState('');

  const handleUserSelect = (event) => {
    setSelectedUserId(event.target.value);
  };

  return (
    <div css={containerStyle}>
      <Modal isOpen title={`Add Mobile User to ${warehouseName}`} toggleModal={onModalClose} isDismissable>
        <form action="/user_management/add_mobile_user_to_warehouse" method="post">
          <input type="hidden" name="authenticity_token" value={authenticityToken} />
          <input type="hidden" name="warehouse_id" value={warehouseId} />

          {/* This appears to be necessary Rails magic to make the form submission a PATCH */}
          <input type="hidden" name="_method" value="patch"></input>

          <Column>
            <Row childWidths={['grid-2', 'grid-10']} margin={['0', '200']}>
              <Text tag="label" htmlFor="user-select" type="h90">
                Mobile user
              </Text>
              <select id="user-select" name="mobile_user_id" onChange={handleUserSelect}>
                <option value="">Please select a mobile user</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.displayName}
                  </option>
                ))}
              </select>
            </Row>

            <div>
              <input type="submit" value="Add User" disabled={selectedUserId === ''} className="btn" />
            </div>
          </Column>
        </form>
      </Modal>
    </div>
  );
};

export default AddMobileUserModal;
