/** @jsxRuntime classic */
/** @jsx jsx */
import axios from 'axios';
import * as React from 'react';
import {useState} from 'react';
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import {Button, InfoBox, Modal, Text} from '@flexe/ui-components';
import useDownloadLink from '../../shared/useDownloadLink';

interface Props {
  authenticityToken: string;
  isOpen: boolean;
  toggleModal: () => void;
}

const footerStyles = css({
  textAlign: 'right'
});

const buttonSpacingStyles = css({
  marginRight: `${tokens.spacing.v100.value}px`
});

const DownloadInventoryModal: React.FunctionComponent<Props> = (props) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const handleModalToggle = () => {
    props.toggleModal();
  };

  const handleDownloadAsync = async () => {
    /*
     * Gets the file from the server (must have an empty data payload or it will strip the headers),
     * creates a blob from the response, and then opens the blob in a new window. We're doing this here
     * instead of using ItemMasterService because v1 of ItemMasterService doesn't support headers, and
     * we can't extend v2 because we need to authenticity_tokens - which aren't supported in v2 - for
     * the other modals.
     */
    setIsDownloading(true);
    try {
      const response = await axios.get('/v2/item-master', {headers: {'Content-Type': 'text/csv'}, data: {}});
      useDownloadLink({response, fileName: 'flexe_inventory_snapshot.csv', testId: 'download-inventory-modal-link'});
      setIsDownloading(false);
      handleModalToggle();
    } catch {
      setIsError(true);
      setIsDownloading(false);
    }
  };

  return (
    <Modal isOpen={props.isOpen} toggleModal={handleModalToggle} title="Download Inventory as CSV" size="medium">
      <Text tag="p">
        Click below to download a CSV snapshot of your inventory, which may then be edited and uploaded to update your
        inventory records in bulk.
      </Text>
      {isError && (
        <InfoBox info="There was an error fetching your inventory." infoType="error">
          Please contact FLEXE for support.
        </InfoBox>
      )}
      <div css={footerStyles}>
        <span css={buttonSpacingStyles}>
          <Button onPress={handleModalToggle} isDisabled={isDownloading}>
            Cancel
          </Button>
        </span>
        <Button visualType="primary" onPress={handleDownloadAsync} isDisabled={isDownloading}>
          Download
        </Button>
      </div>
    </Modal>
  );
};

export default DownloadInventoryModal;
