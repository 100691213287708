import axios from 'axios';
import InternalAPIService from '../../../shared/services/InternalAPIService';
import {ApiResponse} from '../../../shared/CommonInterfaces';
import {Vendor, VendorServiceInterface} from '../SharedInterfaces';

const BASE_URL = '/api/v2/vendors/';

class VendorService extends InternalAPIService implements VendorServiceInterface {
  constructor(authenticityToken: string) {
    super(authenticityToken, BASE_URL);
  }

  public async getVendors(): Promise<Vendor[]> {
    const response = (await this.makeGetRequest(BASE_URL)) as ApiResponse<Vendor[]>;
    return response.data;
  }
}

export default VendorService;
