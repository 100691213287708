import * as React from 'react';
import {get} from 'lodash';
import {Table, TableData, TableHeader} from '@flexe/ui-components';
import {OrderInventory} from '../BatchInterfaces';

interface InventorySplitProps {
  orderItems: OrderInventory[];
  newShipmentItems: OrderInventory[];
  handleAmountChange(event);
}

function InventorySplit(props: InventorySplitProps) {
  return (
    <div className="inventory-split-component">
      <div>
        <h4 className="blue3">Split the following to a new shipment</h4>
        <Table tableClass="table-striped" tableData={getTableData()} />
      </div>
    </div>
  );

  function getTableData(): TableData {
    return {
      headers: [
        {element: 'SKU'},
        {element: 'Description'},
        {element: 'Original Qty.'},
        {element: 'Qty. to Split'}
      ] as TableHeader[],
      rows: buildRows()
    };
  }

  function buildRows(): any[][] {
    return props.orderItems.map((inventory) => {
      return [
        inventory.sku,
        inventory.description,
        `${inventory.quantity} ${inventory.packaging}`,
        <input
          name={inventory.sku}
          data-packaging={inventory.packaging}
          type="number"
          max={inventory.quantity}
          min={0}
          onChange={props.handleAmountChange}
          value={getInventoryQuantity(inventory.sku, inventory.packaging)}
        />
      ];
    });
  }

  function getInventoryQuantity(sku: string, packaging: string): number {
    const inventory = props.newShipmentItems.filter((inv) => inv.sku === sku && inv.packaging === packaging);
    return get(inventory[0], 'quantity', 0);
  }
}

export default InventorySplit;
