import * as moment from 'moment-timezone';
import {PluralPackaging, PluralPackagingAbbreviations, SingularPackaging} from '../CommonMappings';
import {Packaging} from '../CommonInterfaces';

export const humanReadablePhoneNumber = (phone: string): string => {
  if (!phone) {
    return null;
  } else if (phone.length < 10) {
    //we don't know how to format this
    return phone;
  }

  const numberPattern = /\d+/;
  const numbers = phone.match(numberPattern).toString();

  const countryCode = numbers.substring(0, numbers.length - 10);
  const last10Digits = numbers.substring(numbers.length - 10);

  /*
        country codes vary in length and format (spaces in between digits).
        Instead of having logic for each country, let's just print them with no spaces between.
        So the output will always be: '+<country code> 555 555-5555'. This is fine for U.S. numbers
        but won't always be entirely accurate for every other country
        */
  return '+'
    .concat(countryCode)
    .concat(' ')
    .concat(last10Digits.substring(0, 3))
    .concat(' ')
    .concat(last10Digits.substring(3, 6))
    .concat('-')
    .concat(last10Digits.substring(6, 10));
};

export const formatDate = (date: Date, format: string, timezone?: string): string => {
  if (date) {
    if (timezone) {
      return moment(date)
        .tz(timezone)
        .format(format);
    }
    return moment(date).format(format);
  }
  return '';
};

export const pluralizeUnit = (unit: string, count: number, useAbbreviations: boolean = false): string => {
  if (useAbbreviations) {
    return PluralPackagingAbbreviations.get(Packaging[unit]);
  }
  if (count === 1) {
    return unit;
  }
  return PluralPackaging.get(Packaging[unit]) || unit;
};

export const singularizeUnit = (unit: string) => SingularPackaging.get(unit) || unit;

export const getQTYUoM = (quantity: number, packaging: string, useAbbreviations: boolean = false): string => {
  return quantity + ' ' + pluralizeUnit(packaging, quantity, useAbbreviations) + ' ';
};

export const convertToEaches = (quantity: number, packaging: string, unitsPerParent: number): number => {
  if (packaging === Packaging.each) {
    return quantity;
  } else if (packaging === Packaging.carton) {
    return quantity * unitsPerParent;
  }
};

export const convertToCartons = (quantity: number, packaging: string, unitsPerParent: number): number => {
  if (packaging === 'carton') {
    return quantity;
  } else if (packaging === 'each') {
    return quantity / unitsPerParent;
  }
};
