import * as React from 'react';
import {Loader} from '@flexe/ui-components';
import {FulfillmentType} from './DashboardInterfaces';
import {renderContent} from './Helpers';

interface Props {
  loading: boolean;
  fulfillmentType: FulfillmentType;
  completedCount: number;
  errors: string[];
}

class CompletedFulfillmentActivityCard extends React.Component<Props> {
  public render() {
    return (
      <div className="card col-sm-12">
        <Loader loading={this.props.loading} />
        {!this.props.loading && renderContent(this.props.errors, this.renderGaugeRow)}
      </div>
    );
  }

  private renderGaugeRow = () => {
    const fulfillmentType = this.props.fulfillmentType;
    return (
      <div className="row">
        <h4 className="col-sm-12">Completed {fulfillmentType}s</h4>
        <h3 className="successGreen">{this.props.completedCount.toLocaleString()}</h3>
        <br />
        <small className="grey3">Total {fulfillmentType}s completed since midnight.</small>
      </div>
    );
  };
}

export default CompletedFulfillmentActivityCard;
