/** @jsxRuntime classic */
/** @jsx jsx */

import * as React from 'react';
import {useState} from 'react';
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import {Button, Column, Heading, Text, useTable} from '@flexe/ui-components';
import {roleToTitleCase} from '../../helpers';
import AddUserModal from '../shared/AddUserModal';
import UserWithRoles from '../shared/UserWithRoles';
import {Reservation, Role, SiteType, User, UserWithRoles as UserWithRolesInfo} from '../../userManagementInterfaces';

import {rowStyle} from '../../styles';
import RoleTooltip from '../shared/RoleTooltip';

const nameHeaderStyle = css({width: '40%'});
const noReservationUsersStyle = css({backgroundColor: tokens.color.base.red.v50.value});

interface Props {
  activeUsers: User[];
  adminUserNames: string;
  authenticityToken: string;
  onRoleToggleAsync: (
    user: UserWithRolesInfo,
    reservationId: number,
    role: string,
    enableRole: boolean
  ) => Promise<void>;
  reservation: Reservation;
  roles: Role[];
}

const Reservation = ({
  activeUsers,
  adminUserNames,
  authenticityToken,
  onRoleToggleAsync,
  reservation,
  roles
}: Props) => {
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const {tableStyles} = useTable({});

  const handleRoleToggleAsync = async (user: UserWithRolesInfo, role: string, enableRole: boolean) => {
    await onRoleToggleAsync(user, reservation.id, role, enableRole);
  };

  const handleAddUserButtonPress = () => {
    setIsAddUserModalOpen(true);
  };

  const handleModalClose = () => {
    setIsAddUserModalOpen(false);
  };

  const usersWhoCanBeAdded = activeUsers.filter(
    (activeUser) => !reservation.users.some((reservationUser) => reservationUser.id === activeUser.id)
  );

  return (
    <Column tag="section" alignmentHorizontal="start">
      <Heading level="h3">
        {`Reservation ${reservation.id}: ${reservation.warehouse.name} `}
        <small>{reservation.warehouse.address}</small>
      </Heading>
      <div title={usersWhoCanBeAdded.length === 0 ? 'All active users are already assigned to this reservation' : null}>
        <Button isDisabled={usersWhoCanBeAdded.length === 0} onPress={handleAddUserButtonPress}>
          {'Add Users to Reservation'}
        </Button>
      </div>
      {isAddUserModalOpen && (
        <AddUserModal
          authenticityToken={authenticityToken}
          onModalClose={handleModalClose}
          roles={roles.filter((role) => role.canBeToggled).map((role) => role.title)}
          siteId={reservation.id}
          siteName={`Reservation ${reservation.id}: ${reservation.warehouse.name}`}
          siteType={SiteType.Reservation}
          users={usersWhoCanBeAdded}
        />
      )}
      <table {...tableStyles}>
        <thead>
          <tr css={rowStyle}>
            <th css={nameHeaderStyle}>Name</th>
            {roles.map((role) => (
              <th key={role.title}>
                {roleToTitleCase(role.title)} <RoleTooltip role={role} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {reservation.users.length === 0 ? (
            <tr css={rowStyle}>
              <td colSpan={roles.length + 1} css={noReservationUsersStyle}>
                <Column>
                  <strong>No Users Assigned to this Reservation</strong>
                  <Text tag="p">
                    {'Default Manager, Billing, Operations, and Reporting roles assigned to company admin users: '}
                    <strong>{adminUserNames}</strong>
                  </Text>
                </Column>
              </td>
            </tr>
          ) : (
            <React.Fragment>
              {reservation.users.map((user) => (
                <UserWithRoles
                  key={user.id}
                  onRoleToggleAsync={handleRoleToggleAsync}
                  user={user}
                  possibleRoles={roles}
                />
              ))}
              <tr css={rowStyle}>
                <td colSpan={roles.length + 1}>
                  * For any missing roles to this reservation, they will default to the admin users:{' '}
                  <strong>{adminUserNames}</strong>
                </td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
    </Column>
  );
};

export default Reservation;
