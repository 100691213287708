/** @jsx jsx */
import * as React from 'react';
import {ComponentProps, FC, ReactElement} from 'react';
import {css, jsx, SerializedStyles} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import {Icon} from '@flexe/ui-components';

export const CardWithIcon: FC<{
  cardStyles: SerializedStyles;
  icon: ReactElement<ComponentProps<typeof Icon>>;
}> = ({cardStyles, children, icon}) => (
  <div
    css={css`
      border-radius: ${tokens?.border.round.hairline.radius.value};
      border-style: solid;
      border-width: ${tokens?.border.round.hairline.width.value};
      display: flex;
      margin-bottom: ${tokens?.spacing.v100.value}px;
      margin-top: ${tokens?.spacing.v100.value}px;
      padding: ${tokens?.spacing.v100.value}px ${tokens?.spacing.v200.value}px;
      ${cardStyles};
    `}
  >
    <div
      css={css`
        margin-right: ${tokens?.spacing.v200.value}px;
      `}
    >
      {icon}
    </div>
    <div
      css={css`
        flex-direction: column;
      `}
    >
      {children}
    </div>
  </div>
);
