import * as React from 'react';
import {cloneDeep} from 'lodash';
import {RetailOrderDetails, SsccPallet} from '../../shared/services/RetailFulfillmentService';
import {PrintSsccLabelsButton} from './PrintSsccLabelsButton';
import {PrintSsccLabelsModalTableRow} from './PrintSsccLabelsModalTableRow';

interface PrintSsccLabelsModalProps {
  orderDetails: RetailOrderDetails;
  ssccPallets: SsccPallet[];
  showModal: boolean;
  toggleModalHandler: () => void;
}

export const PrintSsccLabelsModal: React.FC<PrintSsccLabelsModalProps> = (props) => {
  const [selectedSsccLabels, setSelectedSsccLabels] = React.useState([]);
  const [selectAllIsChecked, setSelectAllIsChecked] = React.useState(false);

  const modalId = 'print-specific-labels-modal';

  const modalBody = () => {
    return (
      <table id="print-modal-sscc-pallet-table" className="table">
        {tableHeaders()}
        {tableRows()}
      </table>
    );
  };

  const tableHeaders = () => {
    return (
      <thead>
        <tr>
          <th>{selectAllCheckbox()}</th>
          <th>Pallet #</th>
          <th>SKU</th>
          <th>Description</th>
          <th>Quantity</th>
          <th>SSCC</th>
        </tr>
      </thead>
    );
  };

  const tableRows = () => {
    const rows = props.ssccPallets.map((pallet: SsccPallet) => {
      return (
        <PrintSsccLabelsModalTableRow
          key={'pallet-row-' + pallet.palletNumber}
          pallet={pallet}
          selectedLabels={selectedSsccLabels}
          onSelectHandler={onSelectHandler}
          onUnselectHandler={onUnselectHandler}
        />
      );
    });

    return <tbody>{rows}</tbody>;
  };

  const selectAllCheckbox = () => {
    return <input type="checkbox" checked={selectAllIsChecked} onChange={(event) => handleSelectAll(event)} />;
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;

    const selectedLabels = [];
    if (isChecked) {
      props.ssccPallets.forEach((pallet: SsccPallet) => {
        selectedLabels.push(pallet.label);
      });
    }

    setSelectedSsccLabels(selectedLabels);
    setSelectAllIsChecked(isChecked);
  };

  const onSelectHandler = (palletLabel: string) => {
    const newSelectedLabels = cloneDeep(selectedSsccLabels);
    if (!newSelectedLabels.includes(palletLabel)) {
      newSelectedLabels.push(palletLabel);
    }

    setSelectedSsccLabels(newSelectedLabels);
  };

  const onUnselectHandler = (palletLabel: string) => {
    const newSelectedLabels = selectedSsccLabels.filter((label) => label !== palletLabel);

    setSelectedSsccLabels(newSelectedLabels);
    setSelectAllIsChecked(false);
  };

  const modalFooter = () => {
    let button;
    if (selectedSsccLabels.length > 0) {
      button = (
        <PrintSsccLabelsButton
          orderId={props.orderDetails.id}
          barcodes={selectedSsccLabels}
          elementId="print-specific-pallets-confirm-button"
          buttonText={buttonText()}
        />
      );
    } else {
      button = (
        <button id="print-specific-pallets-confirm-button" className="btn" disabled={true}>
          {buttonText()}
        </button>
      );
    }

    return (
      <React.Fragment>
        {infoText()}
        {button}
      </React.Fragment>
    );
  };

  const infoText = () => {
    const selectedPallets = props.ssccPallets.filter((pallet) => selectedSsccLabels.includes(pallet.label));

    const uniqueSkus = new Set();
    selectedPallets.forEach((pallet) => uniqueSkus.add(pallet.contents.sku));

    let palletWord = 'pallet';
    if (selectedPallets.length !== 1) {
      palletWord += 's';
    }

    let skuWord = 'SKU';
    if (uniqueSkus.size !== 1) {
      skuWord += 's';
    }

    return (
      <span id="print-info-text">
        {selectedPallets.length} {palletWord} of {uniqueSkus.size} {skuWord} selected
      </span>
    );
  };

  const buttonText = () => {
    const numLabels = selectedSsccLabels.length;
    let text = 'Print ' + numLabels + ' Label';

    if (numLabels !== 1) {
      text += 's';
    }

    return text;
  };

  const renderModal = () => {
    if (!props.showModal) {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <div id={modalId} data-testid={modalId}>
        <div className="modal-backdrop"></div>
        <div id="print-modal" className="modal" tabIndex={-1} role="dialog">
          <div id="print-modal-dialog" className="modal-dialog" role="document">
            <div id="print-modal-content" className="modal-content">
              <div id="print-modal-header" className="modal-header">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  data-testid="close-btn"
                  onClick={props.toggleModalHandler}
                >
                  <span id="modal-close-button" aria-hidden="true">
                    &times;
                  </span>
                </button>
                <h5 id="print-modal-title" className="modal-title">
                  Print Specific Labels for Order #{props.orderDetails.id}
                </h5>
              </div>

              <div className="modal-body">{modalBody()}</div>

              <div className="modal-footer">{modalFooter()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
};

export default PrintSsccLabelsModal;
