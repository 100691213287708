import {FC} from 'react';
import * as React from 'react';
import {Modal} from '@flexe/ui-components';

interface Props {
  isOpen: boolean;
  title: string;
  maxQty: number;
  toggleModal: () => void;
  cancelPrint: () => void;
  confirmPrint: (qty: number) => void;
}

export const PrintModal: FC<Props> = (props) => {
  const [stickerQty, setStickerQty] = React.useState<string>('');
  const [qtyError, setQtyError] = React.useState<string>('');
  const [disabled, setDisabled] = React.useState<boolean>(true);

  React.useEffect(() => {
    setDisabled(!!qtyError || stickerQty === '');
  }, [qtyError, stickerQty]);

  const handleCancel = () => {
    setStickerQty('');
    setQtyError('');
    props.cancelPrint();
  };

  const handleToggle = () => {
    setStickerQty('');
    setQtyError('');
    props.toggleModal();
  };

  const isValidQuantity = (quantity: string): boolean => {
    const qty: number = parseInt(quantity, 10);
    return qty > 0 && qty <= props.maxQty;
  };

  const handleQtyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQty = event.target.value;
    setStickerQty(newQty);
    setQtyError(isValidQuantity(newQty) ? '' : `Please enter a positive number less than ${props.maxQty + 1}.`);
  };

  const handlePrintConfirm = () => {
    props.confirmPrint(parseInt(stickerQty, 10));
    setStickerQty('');
    setQtyError('');
  };

  return (
    <Modal isOpen={props.isOpen} toggleModal={handleToggle} title={props.title}>
      <div id="print-modal-contents">
        <label htmlFor="print-quantity-input">Please enter a quantity</label>
        <input
          name="print-quantity-input"
          id="print-quantity-input"
          className="form-control"
          value={stickerQty}
          type="number"
          min="1"
          max={props.maxQty.toString()}
          onChange={handleQtyChange}
        />
        {qtyError && <div className="alert alert-danger">{qtyError}</div>}
        <div className="btn-wrapper">
          <button type="button" className="btn-default" onClick={handleCancel}>
            Cancel
          </button>
          <button
            title="confirm-print"
            type="button"
            className="btn-primary"
            onClick={handlePrintConfirm}
            disabled={disabled}
          >
            Print
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PrintModal;
