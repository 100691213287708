import * as moment from 'moment-timezone';

// TODO: This same type of method exists in a few different places,
// so we should refactor these to consolidate them
export const formatDateTime = (dt: Date, format: string, timezone: string): string => {
  const momentDate = moment(dt);

  try {
    return momentDate.tz(timezone).format(format);
  } catch {
    // fallback on the user's local timezone if warehouseTimezone is not valid
    return momentDate.tz(moment.tz.guess(true)).format(format);
  }
};
