import * as React from 'react';
import * as ReactDOM from 'react-dom';
import axios from 'axios';
import {CarrierAccountsByReservation, Reservation} from './SharedInterfaces';
import {ReservationDetail, Roles} from './Reservation';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
interface ReservationsProps {
  reservations: Reservation[];
  userRoles: Roles;
  authenticityToken: string;
}

interface ReservationsState {
  reservations: Reservation[];
  userRoles: Roles;
  authenticityToken: string;
  reservationCarrierAccountInfo: CarrierAccountsByReservation;
}

class Reservations extends React.Component<ReservationsProps, ReservationsState> {
  constructor(props) {
    super(props);
    this.state = {
      reservations: props.reservations,
      userRoles: props.userRoles,
      reservationCarrierAccountInfo: null,
      authenticityToken: props.authenticityToken
    };
  }

  public async componentDidMount() {
    this.loadReservationCarrierAccountInfo();
  }

  public render() {
    const reservations = this.state.reservations.map((reservation) => {
      return (
        <ReservationDetail
          key={`reservation-${reservation.id}`}
          reservation={reservation}
          userRoles={this.state.userRoles}
          authenticityToken={this.state.authenticityToken}
          carrierAccounts={
            this.state.reservationCarrierAccountInfo ? this.state.reservationCarrierAccountInfo[reservation.id] : []
          }
        />
      );
    });
    return (
      <div id="reservations-component">
        {!this.state.reservations ||
          (this.state.reservations.length === 0 && (
            <div>
              <p>You don't have any reservations.</p>
              {('manager' in this.state.userRoles || 'admin' in this.state.userRoles) && (
                <p>We'll automatically compare all available warehouses in your area to find you the perfect match.</p>
              )}
            </div>
          ))}
        {this.isUserOnlyReporting() ? (
          <div>
            <p>Your account is restricted to Reporting only.</p>
            <p>
              If you require additional access, please contact your company admin or support@flexe.com for further
              assistance.
            </p>
          </div>
        ) : (
          this.state.userRoles && reservations
        )}
      </div>
    );
  }

  private isUserOnlyReporting() {
    return (
      this.state.userRoles &&
      Object.keys(this.state.userRoles).length === 1 &&
      Object.keys(this.state.userRoles)[0] === 'reporting'
    );
  }

  private async loadReservationCarrierAccountInfo() {
    this.getReservationCarrierAccountInfos()
      .then((response) => {
        // success
        const reservationCarrierAccountInfo = response.data.accountsByReservationId;
        this.setState({
          reservationCarrierAccountInfo
        });
      })
      .catch((errorResponse) => {
        // TODO
      });
  }

  private async getReservationCarrierAccountInfos() {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get('/s/carrier_accounts/account_infos_by_reservation', {
        params: {
          authenticity_token: this.state.authenticityToken
        }
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }
}

export default Reservations;
