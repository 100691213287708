import * as React from 'react';
import {Icon} from '@flexe/ui-components';
import FlexeButton from '../FlexeButton';

export interface Props {
  buttonText: string;
  enableButton: boolean;
  submitting: boolean;
  footerText?: string;
  onConfirm: () => void;
}

export const ConfirmationFormFooter: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <div className={'confirmation-form-modal-footer'} data-testid={'confirmation-form-modal-footer'}>
        <FlexeButton
          text={props.submitting ? <Icon icon="circle-notch" animation="spin-fast" /> : props.buttonText}
          isDisabled={!props.enableButton}
          handleClick={props.onConfirm}
          level="primary"
          data-testid={'confirmation-form-modal-submit-btn'}
        />
        {props.footerText != null && <p>{props.footerText}</p>}
      </div>
    </React.Fragment>
  );
};
