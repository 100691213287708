import axios from 'axios';
import {get} from 'lodash';
import InternalAPIService from '../shared/services/InternalAPIService';

/**
 * Wrapper for API calls to the new warehouse task service
 */
class WarehouseTasksService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '');
  }

  /**
   * Public  method for calling the GET /api/v2/warehouse_tasks API
   * @param warehouseId the warehouse we are querying against
   * @param continuationToken (optional) if retrieving multiple pages provide the last returned value
   * @param pageSize number of results to return
   * @param filters JSON object specifying fields + values to use in the DB query ('where' clause)
   */
  public async getWarehouseTasks(
    warehouseId: number,
    pageSize: number = 50,
    continuationToken?: string,
    filters?: object
  ) {
    const response = await axios.get('/api/v2/warehouse_tasks', {
      params: {warehouseId, continuationToken, pageSize, filters}
    });
    return get(response, 'data.data');
  }
}

export default WarehouseTasksService;
