import * as React from 'react';
import {LegacyModal, Table, TableData, TableHeader} from '@flexe/ui-components';
import {Cartonization, OrderInventory} from '../BatchInterfaces';
import {CompanyType, DocumentNotableType, TransitionState} from '../../../shared/CommonInterfaces';
import {OrderDetail, OrderRequiredLabel} from '../BatchInterfaces';
import BatchWavingService from '../../../shared/services/BatchWavingService';
import ShipmentService from '../../../shared/services/ShipmentService';
import DocumentsService from '../../../shared/services/DocumentsService';
import WarehouseService from '../../../shared/services/WarehouseService';
import FlexeContext from '../../../contexts/FlexeContext';
import {renderItemLink} from '../../../../libs/helpers';
import ShippingLabelService from '../../../shared/services/ShippingLabelService';
import ExternalTrackingNumber from './ExternalTrackingNumber';
import CancelShipment from './CancelShipment';
import SplitShipment from './SplitShipment';

interface OrderProps {
  batchService: BatchWavingService;
  documentsService: DocumentsService;
  labelGenerationEnabled: boolean;
  orderId: number;
  reservationId: number;
  shipmentService: ShipmentService;
  shippingLabelService: ShippingLabelService;
  warehouseService: WarehouseService;
  splitEnabled: boolean;
  whCancelShipmentEnabled: boolean;
  handleError(message, response);
  handleSuccess(message);
}

interface OrderState {
  showModal: boolean;
  orderDetail?: OrderDetail;
  hasExternalShippingLabel: boolean;
}

class BatchOrderDetail extends React.Component<OrderProps, OrderState> {
  public static contextType = FlexeContext;
  private batchService: BatchWavingService;
  private documentsService: DocumentsService;
  private shipmentService: ShipmentService;
  private notableType: string;
  private warehouseService: WarehouseService;

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      hasExternalShippingLabel: false
    };
    this.batchService = this.props.batchService;
    this.documentsService = this.props.documentsService;
    this.shipmentService = this.props.shipmentService;
    this.notableType = DocumentNotableType.ecomShipment;
    this.warehouseService = this.props.warehouseService;
  }

  public render() {
    let titleModifier = 'New';
    if (this.state.orderDetail && this.state.orderDetail.status === TransitionState.Confirmed) {
      titleModifier = 'In-Progress';
    } else if (this.state.orderDetail && this.state.orderDetail.status === TransitionState.Completed) {
      titleModifier = 'Completed';
    } else if (this.state.orderDetail && this.state.orderDetail.status === TransitionState.Cancelled) {
      titleModifier = 'Cancelled';
    }

    let totalItemQuantity = 0;
    if (this.state.orderDetail && this.state.orderDetail.items) {
      this.state.orderDetail.items.map((item) => (totalItemQuantity += item.quantity));
    }

    const terminalStates = [TransitionState.Completed, TransitionState.Cancelled];
    const showCancelButton =
      this.props.whCancelShipmentEnabled &&
      this.state.orderDetail &&
      !terminalStates.includes(this.state.orderDetail.status);
    const showSplitButton =
      totalItemQuantity > 1 &&
      this.props.splitEnabled &&
      !this.state.orderDetail.isRebinned &&
      this.state.orderDetail.status === TransitionState.Confirmed;

    return (
      <div className="batch-order-component">
        <a onClick={this.toggleModal}>
          <i className="fa fa-chevron-right"></i>
        </a>
        <LegacyModal
          id={`order-${this.props.orderId}-modal`}
          show={this.state.showModal && !!this.state.orderDetail}
          toggleModal={this.toggleModal}
          title={`${titleModifier} Shipment Details`}
          footer={
            (showCancelButton || showSplitButton) && (
              <div className="text-left">
                {[
                  showSplitButton && (
                    <SplitShipment
                      key={0}
                      orderId={this.props.orderId}
                      shipmentService={this.props.shipmentService}
                      orderItems={this.sortInventory(this.state.orderDetail.items)}
                      handleSuccess={this.props.handleSuccess}
                      labelGenerationEnabled={this.props.labelGenerationEnabled}
                    />
                  ),
                  showCancelButton && (
                    <CancelShipment
                      key={1}
                      shipmentId={this.props.orderId}
                      reservationId={this.props.reservationId}
                      shipmentService={this.props.shipmentService}
                      handleSuccess={this.props.handleSuccess}
                      handleError={this.props.handleError}
                    />
                  )
                ]}
              </div>
            )
          }
        >
          <div>{this.getModalContent()}</div>
        </LegacyModal>
      </div>
    );
  }

  private getModalContent(): JSX.Element {
    if (this.state.orderDetail) {
      return (
        <div>
          <div className="row order-detail-header">
            <div className="col-sm-6">
              <h4 className="text-uppercase">Shipment Info</h4>
              <div className="row">
                <div className="col-sm-6">
                  <strong>Shipment ID:</strong>
                </div>
                <div className="col-sm-6 text-right">{this.props.orderId}</div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <strong>Items:</strong>
                </div>
                <div className="col-sm-6 text-right">{this.state.orderDetail.items.length}</div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <strong>Customer UUID:</strong>
                </div>
                <div className="col-sm-6 text-right">{this.state.orderDetail.customerUuid}</div>
              </div>
              {this.state.orderDetail.parentCustomerUuid && (
                <div className="row">
                  <div className="col-sm-6">
                    <strong>Parent UUID:</strong>
                  </div>
                  <div className="col-sm-6 text-right">{this.state.orderDetail.parentCustomerUuid}</div>
                </div>
              )}
            </div>
            <div className="col-sm-6">
              <h4 className="text-uppercase">
                <i className="fa fa-map-marker"></i> Destination Info
              </h4>
              <div className="row">
                <div className="col-sm-6">
                  <strong>Ship to:</strong>
                </div>
                <div className="col-sm-6 text-right">{this.state.orderDetail.shipTo}</div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <strong>Address:</strong>
                </div>
                <div className="col-sm-6 text-right">
                  <address>
                    {this.state.orderDetail.addressLines.map((line, index) => (
                      <span key={index}>{line}, </span>
                    ))}
                    <br />
                    {this.state.orderDetail.addressLocality}, {this.state.orderDetail.addressRegion}&nbsp;
                    {this.state.orderDetail.addressPostcode}, {this.state.orderDetail.addressCountry}
                  </address>
                </div>
              </div>
              {this.state.orderDetail.shipToPhone && (
                <div className="row">
                  <div className="col-sm-6">
                    <strong>Phone:</strong>
                  </div>
                  <div className="col-sm-6 text-right">{this.state.orderDetail.shipToPhone}</div>
                </div>
              )}
              {this.state.orderDetail.shipToEmail && (
                <div className="row">
                  <div className="col-sm-6">
                    <strong>Email:</strong>
                  </div>
                  <div className="col-sm-6 text-right">{this.state.orderDetail.shipToEmail}</div>
                </div>
              )}
              {this.state.orderDetail.shippingReference1 && (
                <div className="row">
                  <div className="col-sm-6">
                    <strong>Shipping Reference 1:</strong>
                  </div>
                  <div className="col-sm-6 text-right">{this.state.orderDetail.shippingReference1}</div>
                </div>
              )}
              {this.state.orderDetail.shippingReference2 && (
                <div className="row">
                  <div className="col-sm-6">
                    <strong>Shipping Reference 2:</strong>
                  </div>
                  <div className="col-sm-6 text-right">{this.state.orderDetail.shippingReference2}</div>
                </div>
              )}
            </div>
          </div>

          {this.state.hasExternalShippingLabel && (
            <ExternalTrackingNumber orderDetail={this.state.orderDetail} shipmentService={this.shipmentService} />
          )}

          {this.state.orderDetail.requiredLabels.length > 0 && (
            <div className="required-label-info">
              <h4>Shipment Documents</h4>
              <Table
                tableClass="table-striped"
                tableData={this.getDocumentsTableData(this.state.orderDetail.requiredLabels)}
              />
            </div>
          )}
          <div className="inventory-info">
            <h4>Shipment Contents</h4>
            {this.getCartonizedContents()}
            {this.state.orderDetail.cartonizations.length < 1 && (
              <Table tableClass="table-striped" tableData={this.getInventoryTableData(this.state.orderDetail.items)} />
            )}
          </div>
          {this.state.orderDetail.splitShipmentRequiredLabels?.length > 0 && (
            <div className="required-label-info">
              <h4>Shipment Documents Related to This Shipment</h4>
              <Table
                tableClass="table-striped"
                tableData={this.getDocumentsTableData(this.state.orderDetail.splitShipmentRequiredLabels)}
              />
            </div>
          )}
        </div>
      );
    }
    return null;
  }

  private getDocumentsTableData(labels: OrderRequiredLabel[]): TableData {
    return {
      headers: this.buildDocumentHeaders(),
      rows: this.buildDocumentRows(labels)
    };
  }

  private buildDocumentHeaders(): TableHeader[] {
    return [{element: 'Document Type'}, {element: 'Carrier-assigned ID'}, {element: 'Document Link'}];
  }

  private buildDocumentRows(labels: OrderRequiredLabel[]): any[][] {
    if (!labels?.length) {
      return [];
    }
    return labels
      .filter((doc) => !doc.url?.includes('.zpl'))
      .sort((a, b) => a.type.localeCompare(b.type))
      .map((label) => {
        // These values MUST be in the same order as the headers in this.buildLabelHeaders()
        return [
          label.type,
          label.carrierAssignedId || '(None)',
          label.url ? <a href={label.url}>{label.filename}</a> : '--'
        ];
      });
  }

  private getInventoryTableData(orderItems: OrderInventory[]): TableData {
    return {
      headers: [{element: 'SKU'}, {element: 'Description'}, {element: 'Quantity'}, {element: 'Unit'}] as TableHeader[],
      rows: this.buildInventoryRows(orderItems)
    };
  }

  private buildInventoryRows(orderItems: OrderInventory[]): any[][] {
    return this.sortInventory(orderItems).map((item) => {
      const sku = item.id ? renderItemLink(item.id, item.sku, false) : item.sku;
      return [sku, item.description, item.quantity, item.packaging];
    });
  }

  private getCartonizedContents() {
    return this.state.orderDetail.cartonizations.map((cartonization: Cartonization) => {
      return (
        <div className="cartonization-info">
          <h6>
            <i>Packaging - {cartonization.shipmentPackagingDescription}</i>
          </h6>
          <Table tableClass="table-striped" tableData={this.getInventoryTableData(cartonization.items)} />
        </div>
      );
    });
  }

  private toggleModal = async () => {
    if (!this.state.showModal) {
      const response = await this.props.batchService.getOrder(this.props.orderId);
      const docsResponse = await this.props.documentsService.getDocuments(
        CompanyType.shipper,
        this.props.reservationId,
        this.notableType,
        this.props.orderId
      );

      const orderDetail = response.data;
      let hasExternalShippingLabel = false;
      if (orderDetail && docsResponse) {
        orderDetail.documents = docsResponse;
        hasExternalShippingLabel = orderDetail.requiredLabels.some(
          (label) => label.isExternalLabel && label.type === 'Shipping Label'
        );
      }

      if (response && response.errors.length === 0) {
        this.setState({
          showModal: !this.state.showModal,
          orderDetail,
          hasExternalShippingLabel
        });
      } else {
        this.props.handleError('There was an error retrieving the details of this shipment', response);
      }
    } else {
      this.setState({
        showModal: !this.state.showModal
      });
    }
  };

  private sortInventory(inventory: OrderInventory[]): OrderInventory[] {
    return inventory.sort((a, b) => {
      const skuA = a.sku.toUpperCase();
      const skuB = b.sku.toUpperCase();
      if (skuA < skuB) {
        return -1;
      } else if (skuA > skuB) {
        return 1;
      }
      return 0;
    });
  }
}

export default BatchOrderDetail;
