/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import {EditableBy, InventoryUploadCsvColumnInfoGroup, ModalAction, RequiredFor} from './ItemMasterInterfaces';

const italics = css({
  fontStyle: 'italic'
});

const conditionallyRequiredStyles = css({
  fontWeight: tokens.font.h100.weight.value,
  fontStyle: 'italic'
});

export const composableInstructionsTableHeaders: React.ReactNode[] = [
  'Column Header',
  'Sample Input',
  'Description',
  'Guidelines'
];

export const getInventoryUploadCsvColumnInfoGroups = (
  showImsAlternateId: boolean = false,
  useReleaseDate: boolean = false,
  useSerialNumberProgram: boolean = false
): InventoryUploadCsvColumnInfoGroup[] => {
  const preliminarySet = [
    {
      columns: [
        {
          name: 'sku',
          requiredFor: RequiredFor.UpdateAndCreate,
          sampleInput: 'SKU-123ABC',
          description: 'A unique item code',
          guidelines: ['Alphanumeric string, special characters allowed']
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    {
      columns: [
        {
          name: 'description',
          requiredFor: RequiredFor.Create,
          sampleInput: 'Large widget - black / white / blue',
          description: 'A description of the SKU',
          guidelines: ['Alphanumeric string, special characters allowed']
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    showImsAlternateId && {
      columns: [
        {
          name: 'alternate_id',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '1234-AB.cd',
          description: 'An alternate unique item code',
          guidelines: [
            'Alphanumeric string; can only contain dots, dashes, letters, and numbers. It cannot contain spaces.'
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'inventory_group',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'Widgets',
          description: 'Identifies items to be grouped per billing requirements',
          guidelines: [
            'Alphanumeric string, special characters allowed',
            <React.Fragment>
              <span css={conditionallyRequiredStyles}>Required</span> if inventory groups are enabled
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'cartons_per_layer',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '4',
          description: 'The number of cartons that make up a single layer in a pallet (Ti)',
          guidelines: [
            'All three values must be whole numbers greater than zero',
            <React.Fragment>
              {/* eslint-disable-next-line max-len */}
              <span css={italics}>cartons_per_pallet</span> must match <span css={italics}>cartons_per_layer</span> x{' '}
              <span css={italics}>layers_per_pallet</span>
            </React.Fragment>
          ]
        },
        {
          name: 'layers_per_pallet',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '3',
          description: 'The number of layers per pallet (Hi)',
          guidelines: []
        },
        {
          name: 'cartons_per_pallet',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '12',
          description: 'The total number of cartons per pallet (TiHi)',
          guidelines: []
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    {
      columns: [
        {
          name: 'pallet_length',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '44',
          description: 'The length of a pallet',
          guidelines: [
            'Dimensions must be a number greater than zero; decimals are allowed',
            <React.Fragment>
              Units must be one of the following: <span css={italics}>[ft_i], in, inch, m, cm</span>
            </React.Fragment>,
            'Either all or none of these values should be provided'
          ]
        },
        {
          name: 'pallet_width',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '38',
          description: 'The width of a pallet',
          guidelines: []
        },
        {
          name: 'pallet_height',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '52',
          description: 'The height of a pallet',
          guidelines: []
        },
        {
          name: 'pallet_dimension_unit',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'in',
          description: 'The unit for these dimensions',
          guidelines: []
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    {
      columns: [
        {
          name: 'pallet_weight',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '145.4',
          description: 'The weight of a pallet',
          guidelines: ['Weight must be a number greater than zero; decimals are allowed']
        },
        {
          name: 'pallet_weight_unit',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'lb',
          description: 'The units for this weight',
          guidelines: [
            <React.Fragment>
              Units must be one of the following: <span css={italics}>lb, pound, oz, kg, g</span>
            </React.Fragment>,
            <React.Fragment>
              {/* eslint-disable-next-line max-len */}
              <span css={italics}>pallet_weight_unit</span> is <span css={conditionallyRequiredStyles}>required</span>{' '}
              if <span css={italics}>pallet_weight</span> is provided
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    {
      columns: [
        {
          name: 'stackable',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'TRUE',
          description: 'If true, pallets can be stacked during storage',
          guidelines: [
            <React.Fragment>
              Must be either <span css={italics}>TRUE</span> or <span css={italics}>FALSE</span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'carton_ship_alone',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'TRUE',
          description: 'If true, carton must be shipped separately',
          guidelines: [
            <React.Fragment>
              Must be either <span css={italics}>TRUE</span> or <span css={italics}>FALSE</span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'carton_ship_as_is',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'TRUE',
          description: 'If true, no overbox is required for shipping',
          guidelines: [
            <React.Fragment>
              Must be either <span css={italics}>TRUE</span> or <span css={italics}>FALSE</span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'carton_length',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '12',
          description: 'The length of a carton',
          guidelines: [
            'Dimensions must be a number greater than zero; decimals are allowed',
            <React.Fragment>
              Units must be one of the following: <span css={italics}>[ft_i], in, inch, m, cm</span>
            </React.Fragment>,
            'Either all or none of these values should be provided'
          ]
        },
        {
          name: 'carton_width',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '9.4',
          description: 'The width of a carton',
          guidelines: []
        },
        {
          name: 'carton_height',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '8',
          description: 'The height of a carton',
          guidelines: []
        },
        {
          name: 'carton_dimension_unit',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'in',
          description: 'The unit for these dimensions',
          guidelines: []
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    {
      columns: [
        {
          name: 'carton_weight',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '4.2',
          description: 'The weight of a carton',
          guidelines: ['Weight must be a number greater than zero; decimals are allowed']
        },
        {
          name: 'carton_weight_unit',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'lb',
          description: 'The units for this weight',
          guidelines: [
            <React.Fragment>
              Units must be one of the following: <span css={italics}>lb, pound, oz, kg, g</span>
            </React.Fragment>,
            <React.Fragment>
              {/* eslint-disable-next-line max-len */}
              <span css={italics}>carton_weight_unit</span> is <span css={conditionallyRequiredStyles}>required</span>{' '}
              if <span css={italics}>carton_weight</span> is provided
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    {
      columns: [
        {
          name: 'carton_barcode',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '777435999122',
          description: 'A unique, scannable ID value for a carton of the SKU',
          guidelines: ['Alphanumeric string']
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    {
      columns: [
        {
          name: 'carton_barcode_type',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'UPC',
          description: 'The type of barcode',
          guidelines: [
            <React.Fragment>
              Must be one of the following: <span css={italics}>UPC, EAN</span>
            </React.Fragment>,
            <React.Fragment>
              <span css={conditionallyRequiredStyles}>Required</span> if <span css={italics}>carton_barcode</span> is
              provided
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'each_ship_alone',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'TRUE',
          description: 'If true, item must be shipped separately',
          guidelines: [
            <React.Fragment>
              Must be either <span css={italics}>TRUE</span> or <span css={italics}>FALSE</span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'each_ship_as_is',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'FALSE',
          description: 'If true, no overbox is required for shipping',
          guidelines: [
            <React.Fragment>
              Must be either <span css={italics}>TRUE</span> or <span css={italics}>FALSE</span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'each_length',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '5',
          description: 'The length of an each',
          guidelines: [
            'Dimensions must be a number greater than zero; decimals are allowed',
            <React.Fragment>
              Units must be one of the following: <span css={italics}>[ft_i], in, inch, m, cm</span>
            </React.Fragment>,
            'Either all or none of these values should be provided'
          ]
        },
        {
          name: 'each_width',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '6',
          description: 'The width of an each',
          guidelines: []
        },
        {
          name: 'each_height',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '2.8',
          description: 'The height of an each',
          guidelines: []
        },
        {
          name: 'each_dimension_unit',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'in',
          description: 'The unit for these dimensions',
          guidelines: []
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    {
      columns: [
        {
          name: 'each_weight',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '14.1',
          description: 'The weight of an each',
          guidelines: ['Weight must be a number greater than zero; decimals are allowed']
        },
        {
          name: 'each_weight_unit',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'lb',
          description: 'The units for this weight',
          guidelines: [
            <React.Fragment>
              Units must be one of the following: <span css={italics}>lb, pound, oz, kg, g</span>
            </React.Fragment>,
            <React.Fragment>
              {/* eslint-disable-next-line max-len */}
              <span css={italics}>each_weight_unit</span> is <span css={conditionallyRequiredStyles}>required</span> if{' '}
              <span css={italics}>each_weight</span> is provided
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    {
      columns: [
        {
          name: 'eaches_per_carton',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '6',
          description: 'The number of eaches contained in a carton',
          guidelines: ['Whole number, greater than zero']
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    {
      columns: [
        {
          name: 'each_barcode',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '777435999122',
          description: 'A unique, scannable ID value for an each of the SKU',
          guidelines: ['Alphanumeric string']
        }
      ],
      editableBy: EditableBy.ShipperAndWarehouse
    },
    {
      columns: [
        {
          name: 'each_barcode_type',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'UPC',
          description: 'The type of barcode',
          guidelines: [
            <React.Fragment>
              Must be one of the following: <span css={italics}>UPC, EAN</span>
            </React.Fragment>,
            <React.Fragment>
              <span css={conditionallyRequiredStyles}>Required</span> if <span css={italics}>each_barcode</span> is
              provided
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    !useSerialNumberProgram && {
      columns: [
        {
          name: 'each_serial_number_capture_mode',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'OUTBOUND_CAPTURE',
          description: 'Identifies when serial numbers will be captured',
          guidelines: [
            <React.Fragment>
              Must be one of the following:
              <br />
              <span css={italics}>NONE, OUTBOUND_CAPTURE</span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    !useSerialNumberProgram && {
      columns: [
        {
          name: 'each_serial_number_patterns',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '"LL-NNNN, NNN-LLL"',
          description: 'Define acceptable formats for captured serial numbers',
          guidelines: ['Must be provided as a comma-separated list of formats, enclosed by quotes']
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'hazmat',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'TRUE',
          description: 'If true, the SKU is designated as hazardous materials',
          guidelines: [
            <React.Fragment>
              <span css={italics}>hazmat</span> must be either <span css={italics}>TRUE</span> or{' '}
              <span css={italics}>FALSE</span>
            </React.Fragment>,
            'Either all or none of these values should be provided'
          ]
        },
        {
          name: 'pkg_code',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'PI967',
          description: 'Provides information on the chemicals inside',
          guidelines: []
        },
        {
          name: 'un_number',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'UN1078',
          description: 'The specific hazmat code for the SKU',
          guidelines: []
        },
        {
          name: 'limited_quantity',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'TRUE',
          description:
            'If true, indicates the item is subject to shipment quantity restrictions in compliance with standard hazmat processing',
          guidelines: []
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'lot_code_tracking_enabled',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'TRUE',
          description: 'If true, lot code tracking is enabled for the SKU',
          guidelines: [
            <React.Fragment>
              Must be either <span css={italics}>TRUE</span> or <span css={italics}>FALSE</span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'expiry_date_tracking_enabled',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'TRUE',
          description: 'If true, expiration date tracking is enabled for the SKU',
          guidelines: [
            <React.Fragment>
              Must be either <span css={italics}>TRUE</span> or <span css={italics}>FALSE</span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'bag_and_tape_required',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'TRUE',
          description: 'If true, additional packaging is required',
          guidelines: [
            <React.Fragment>
              Must be either <span css={italics}>TRUE</span> or <span css={italics}>FALSE</span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'velocity',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'steady',
          description: 'Identifies the frequency of use for the SKU',
          guidelines: [
            'Must be one of the following:',
            <React.Fragment>
              <span css={italics}>fast, steady, slow</span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    {
      columns: [
        {
          name: 'country_of_origin',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'US',
          description: 'Country in which the item was made',
          guidelines: [
            <React.Fragment>
              <span css={italics}>Must be a valid ISO 3166 country code</span>
            </React.Fragment>
          ]
        },
        {
          name: 'hs_code',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: '546254',
          description:
            'The harmonized system code is required when exporting goods, the maximum character limit is 12, ' +
            'please remove special characters if needed to reach the character limit',
          guidelines: [
            <React.Fragment>
              <span css={conditionallyRequiredStyles}>
                hs_code and country_of_origin are both required when shipping internationally
              </span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    useReleaseDate && {
      columns: [
        {
          name: 'release_date',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'yyyy-MM-dd',
          description:
            'This is a deprecated column specific to pokemon release date behavior if you do not' +
            ' know about this ask support or #dev-ims for help',
          guidelines: [
            <React.Fragment>
              Must be the specified release date like <span css={italics}>2023-04-01</span> or empty
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    },
    useSerialNumberProgram && {
      columns: [
        {
          name: 'serial_number_program',
          requiredFor: RequiredFor.NeitherUpdateNorCreate,
          sampleInput: 'serial_number_capture',
          description:
            'The serial number program the item is enrolled in. The warehouse will process the items in' +
            " accordance with the program's functionality.",
          guidelines: [
            'Must be one of the following:',
            <React.Fragment>
              <span css={italics}>serial_number_capture</span>
            </React.Fragment>
          ]
        }
      ],
      editableBy: EditableBy.Shipper
    }
  ];
  return preliminarySet.filter((item: InventoryUploadCsvColumnInfoGroup) => {
    return item.editableBy !== undefined;
  });
};

export const getValidationHeaders = (
  editableBy: EditableBy,
  action: ModalAction,
  showImsAlternateId: boolean,
  useReleaseDate: boolean,
  useSerialNumberProgram: boolean
) => {
  const required: string[] = [];
  const optional: string[] = [];

  if (action) {
    const groupsToInclude = getInventoryUploadCsvColumnInfoGroups(
      showImsAlternateId,
      useReleaseDate,
      useSerialNumberProgram
    ).filter((group) => {
      return group.editableBy === editableBy || group.editableBy === EditableBy.ShipperAndWarehouse;
    });

    groupsToInclude.forEach((group) => {
      group.columns.forEach((column) => {
        if (column.requiredFor === RequiredFor[action] || column.requiredFor === RequiredFor.UpdateAndCreate) {
          required.push(column.name);
        } else {
          optional.push(column.name);
        }
      });
    });
  }

  return {
    required,
    optional
  };
};
