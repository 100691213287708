import * as React from 'react';
import {get} from 'lodash';
import {stringify as queryStringify} from 'query-string';
import {LegacyModal, Loader, Pagination} from '@flexe/ui-components';
import {History as ReactHistory} from 'history';
import {CookiesProvider} from 'react-cookie';
import WarehouseService from '../shared/services/WarehouseService';
import Filters from '../shared/Filters';
import WarehouseSelector from '../shared/WarehouseSelector';
import ErrorDisplay from '../shared/ErrorDisplay';
import {ApiResponse, FilterType, Response, Warehouse} from '../shared/CommonInterfaces';
import {displayPackaging, renderItemLink, renderLocationLink, renderLpnLink} from '../../libs/helpers';
import FlexeButton from '../shared/FlexeButton';
import FileDownloadLink from '../shared/FileDownloadLink';
import LocationsAddEditModal from './LocationsAddEditModal/LocationsAddEditModal';
import {LocationsHelper} from './LocationsHelper';
import LocationsService from './LocationsService';
import {
  BulkToggleLocationResponse,
  Location,
  LocationAllocatable,
  LocationCategory,
  LocationCategoryStrings,
  LocationContentEntityType,
  LocationFilters,
  LocationPrimaryPickLocation,
  LocationSku,
  LocationStatus
} from './LocationsInterfaces';

// We need to use require() to load Papa because otherwise, TypeScript doesn't allow Papa.LocalChunkSize to be changed.
declare function require(name: string);
/* eslint-disable-next-line */
const Papa = require('papaparse');
Papa.LocalChunkSize = 1024 * 1024; // 1 MB chunk size

interface Props {
  authenticityToken: string;
  continuationToken: string;
  selectedWarehouse: Warehouse;
  activeWarehouses: Warehouse[];
  inactiveWarehouses: Warehouse[];
  filters: LocationFilters;
  currentPage: number;
  pageSize: number;
  history: ReactHistory<any>;
  enableZones: boolean;
  handleFilterChange(filters);
  handleContinuationTokenChange(continuationToken);
  handlePageChange(currentPage);
  handleWarehouseChange(selectedWarehouse);
  handlePageSizeChange(pageSize);
}

interface State {
  locations: Location[];
  didHitLocationContentRetrievalLimit: boolean;
  selectedLocations: {
    [key: string]: Location | false;
  };
  selectedLocsWithContentCount: number;
  fetchingLocations: boolean;
  errorResponse?: Response;
  errorMessage: string;
  locationToggleErrors: ApiResponse<BulkToggleLocationResponse>;
  totalLocationsCount: number;
  showBulkImportModal: boolean;
  showBulkActionModal: boolean;
  directedPutAwayEnabled?: boolean;
  showDownloadCsvDropdown: boolean;
  inventoryCsvDownloadStarted: boolean;
  showInventoryCsvDownloadingModal: boolean;
  crossdockEnabled?: boolean;
  showSerial?: boolean;
}
const DIRECTED_PUT_AWAY_FEATURE_FLAG = 'directed-putaway';
const MAX_CONTENT_RESULTS_PER_LOCATION = 250;
/* eslint-enable */
class LocationsIndex extends React.Component<Props, State> {
  private continuationTokens: string[] = [];
  private locationsService: LocationsService;
  private warehouseService: WarehouseService;

  constructor(props: Props) {
    super(props);

    this.locationsService = new LocationsService(props.authenticityToken);
    this.warehouseService = new WarehouseService(props.authenticityToken);

    this.state = {
      locations: [],
      didHitLocationContentRetrievalLimit: false,
      selectedLocations: {},
      selectedLocsWithContentCount: 0,
      fetchingLocations: false,
      errorResponse: null,
      errorMessage: '',
      locationToggleErrors: null,
      totalLocationsCount: 0,
      showBulkImportModal: false,
      showBulkActionModal: false,
      showDownloadCsvDropdown: false,
      inventoryCsvDownloadStarted: false,
      showInventoryCsvDownloadingModal: false,
      crossdockEnabled: false,
      showSerial: false
    };
  }

  public async componentDidMount() {
    this.updateUrl(this.props.filters);
    await this.fetchLocations(
      this.props.selectedWarehouse.id,
      this.props.pageSize,
      this.props.continuationToken,
      this.props.filters
    );
    await this.loadDirectedPutAwayFeatureFlag();
    await this.checkIfCrossdockEnabled();
  }

  public render() {
    const {currentPage, activeWarehouses, inactiveWarehouses} = this.props;
    let selectedLocsCount = 0;
    for (const locId in this.state.selectedLocations) {
      if (this.state.selectedLocations[locId]) {
        selectedLocsCount++;
      }
    }
    const allLocationsSelected = this.state.locations.every((loc) => {
      return !!this.state.selectedLocations[loc.id];
    });
    const maxContentsPerPage = MAX_CONTENT_RESULTS_PER_LOCATION * this.props.pageSize;
    const pagination = (
      <Pagination
        page={currentPage}
        pageSize={this.props.pageSize}
        paginationHandler={(page) => this.handlePagination(page)}
        totalCount={this.state.totalLocationsCount}
      />
    );
    const bulkActionDisableClass = selectedLocsCount === 0 ? ' disabled' : '';

    return (
      <div className="container-fluid locations-index">
        <div className="row">
          <div className="col-sm-6">
            <h1>Locations</h1>
            <h4>
              <CookiesProvider>
                <WarehouseSelector
                  selectedWarehouse={this.props.selectedWarehouse}
                  activeWarehouses={activeWarehouses}
                  inactiveWarehouses={inactiveWarehouses}
                  onSelect={this.handleWarehouseSelected}
                />
              </CookiesProvider>
            </h4>
          </div>
          <div className={'col-sm-6 locations-csv-buttons'}>
            {this.renderGenericDownloadButtons()}
            <FlexeButton
              id="bulk_import_button"
              text={
                <>
                  <i className="fa fa-upload"></i>
                  Upload CSV
                </>
              }
              handleClick={this.showBulkImportModal}
            />
          </div>
        </div>

        <Filters filters={this.getFilters()} filterChangeHandler={this.handleFilterChange} />
        {this.state.didHitLocationContentRetrievalLimit && (
          <div className="alert alert-warning hit-location-content-limit">
            <div className="content">
              Over {maxContentsPerPage} location contents were found, only showing the first&nbsp;
              {maxContentsPerPage} results.
            </div>
          </div>
        )}

        {selectedLocsCount > 0 && (
          <div className="alert alert-info selected-locations-count">
            <div className="content">
              You have {selectedLocsCount} {selectedLocsCount > 1 ? 'locations' : 'location'} selected.&nbsp;&nbsp;
            </div>
          </div>
        )}

        <div className="bulk-action-buttons">
          <a className={`btn clear-selections btn-sm${bulkActionDisableClass}`} onClick={this.clearSelectedLocations}>
            Clear Selections
          </a>
          <a className={`btn perform-bulk-action btn-sm${bulkActionDisableClass}`} onClick={this.toggleBulkActionModal}>
            Bulk Activate/Lock/Deactivate
          </a>
        </div>

        {this.state.fetchingLocations ? (
          <Loader loading={true} />
        ) : !this.state.locations.length || this.state.errorResponse ? (
          this.state.errorResponse ? (
            <div className="alert alert-info" role="alert">
              <p className="content"> {this.state.errorResponse.response.data.errors[0].detail}</p>
            </div>
          ) : (
            <div className="alert alert-info" role="alert">
              <p className="content">No locations were found for the provided search criteria.</p>
            </div>
          )
        ) : (
          <div>
            <table className="locations table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={allLocationsSelected}
                      onChange={this.toggleAllLocationsOnCurrentPage}
                      title={`${allLocationsSelected ? 'Deselect' : 'Select'} all locations on this page`}
                    />
                  </th>
                  {/* eslint-disable-next-line max-len */}
                  {/* if you add a column here, increase the colSpan count in the <tfoot> so the bottom row displays correctly*/}
                  <th>Location</th>
                  <th>Status</th>
                  <th>Allocatable</th>
                  <th>Category</th>
                  {this.props.enableZones && <th>Put Away Zone</th>}
                  <th>Res ID</th>
                  <th>SKU</th>
                  <th>LPN</th>
                  <th>Lot Code</th>
                  <th>Expiration Date</th>
                  <th>Quantity</th>
                  <th>Unit</th>
                  <th>{pagination}</th>
                </tr>
              </thead>
              {/*)}*/}
              {this.state.locations.map((location: Location, locIdx: number) => {
                let skuRows = [];
                if (location.contents.length === 0) {
                  // Just show the location info, no content
                  skuRows.push(this.renderSkuRow(null, 0, location, locIdx));
                } else {
                  if (location.contents.length > 1) {
                    // Show the "Multi-Sku" row with toggle
                    skuRows.push(this.renderSkuRow(null, -1, location, locIdx));
                  }
                  skuRows = skuRows.concat(
                    location.contents.map((locContent, skuIdx) => {
                      return this.renderSkuRow(locContent, skuIdx, location, locIdx);
                    })
                  );
                }
                return <tbody key={location.id}>{skuRows}</tbody>;
              })}
              <tfoot>
                <tr>
                  {' '}
                  <th colSpan={this.props.enableZones ? 13 : 12}></th>
                  <th>{pagination}</th>
                </tr>
              </tfoot>
            </table>
            <div className="form-group pull-right">
              <label htmlFor="pageSizeSelect">Select Page Size</label>
              <select
                className="form-control"
                id="pageSizeSelect"
                value={this.props.pageSize}
                onChange={this.changePageSize}
              >
                <option>50</option>
                <option>100</option>
                <option>150</option>
                <option>200</option>
                <option>250</option>
                <option>300</option>
              </select>
            </div>
          </div>
        )}
        {this.state.showBulkImportModal && (
          <LocationsAddEditModal
            crossdockEnabled={this.state.crossdockEnabled}
            directedPutAwayEnabled={this.state.directedPutAwayEnabled}
            locationsService={this.locationsService}
            onModalToggle={this.toggleBulkImportModal}
            warehouseId={this.props.selectedWarehouse.id}
            zonesEnabled={this.props.enableZones}
          />
        )}

        <LegacyModal
          id="inventory_csv_downloading_modal"
          toggleModal={this.toggleInventoryCsvDownloadingModal}
          size="small"
          show={this.state.showInventoryCsvDownloadingModal}
          title="Downloading Location Inventory CSV"
        >
          <div className="modal-contents">
            <h3>
              Please wait until the download is complete. This may take a few minutes, depending on the size of your
              file.
            </h3>
          </div>
        </LegacyModal>

        <LegacyModal
          id="bulk_action_modal"
          toggleModal={this.toggleBulkActionModal}
          size="small"
          show={this.state.showBulkActionModal}
          title="Bulk Activate/Lock/Deactivate"
          footer={
            <div className="action-buttons">
              <a className="btn" onClick={this.toggleBulkActionModal}>
                Cancel
              </a>
              <a className="btn disable-all" onClick={this.disableSelectedLocations}>
                Deactivate All
              </a>
              <a className="btn lock-all" onClick={this.lockSelectedLocations}>
                Lock All
              </a>
              <a className="btn enable-all" onClick={this.enableSelectedLocations}>
                Activate All
              </a>
            </div>
          }
        >
          <div className="modal-contents">
            <h3>You have {selectedLocsCount} locations selected</h3>
            {!!this.state.selectedLocsWithContentCount && (
              <div className="alert alert-danger">
                <div className="content">
                  <p>
                    These locations can't be deactivated. {this.state.selectedLocsWithContentCount} of the selected
                    locations have content.
                  </p>
                </div>
              </div>
            )}
            {!!this.state.locationToggleErrors && (
              <div className="alert alert-danger">
                <div className="content">
                  <b>No changes were made, at least one location contains an error.</b>
                  <p>{this.state.locationToggleErrors}</p>
                </div>
              </div>
            )}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="location">Location</th>
                  <th className="status">Status</th>
                  <th className="category">Category</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(this.state.selectedLocations)
                  .sort((locAid, locBid) => {
                    const locA = this.state.selectedLocations[locAid];
                    const locB = this.state.selectedLocations[locBid];
                    const locAcontents = get(locA, 'contents', []);
                    const locBcontents = get(locB, 'contents', []);
                    return locBcontents.length - locAcontents.length;
                  })
                  .map((locId) => {
                    const loc = this.state.selectedLocations[locId];
                    if (loc) {
                      const hasContents = loc.contents.length && this.state.selectedLocsWithContentCount;
                      return (
                        <tr key={loc.id} className={hasContents ? 'has-contents' : ''}>
                          <td className="location">{loc.label}</td>
                          <td className="status">{loc.state}</td>
                          <td className="category">{loc.category}</td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  })}
              </tbody>
            </table>
          </div>
        </LegacyModal>
      </div>
    );
  }

  private renderSkuRow(locContent: LocationSku, idx: number, location: Location, locIdx: number) {
    if (location.collapse && location.contents.length > 1 && idx >= 0) {
      return null;
    }

    const rowClassName = location.state === LocationStatus.inactive ? LocationStatus.inactive : null;

    return (
      <tr key={idx} className={rowClassName}>
        {this.renderLocationColumns(location, idx)}
        {this.renderContentColumns(locContent, location, idx, locIdx)}
      </tr>
    );
  }

  private renderLocationColumns(location: Location, idx: number) {
    if (idx === -1 || location.contents.length === 0 || (idx === 0 && location.contents.length === 1)) {
      const isLost = LocationCategoryStrings.CATEGORY_LOST === location.category;
      return [
        <td key="select">
          <input
            type="checkbox"
            data-location-id={location.id}
            checked={!!this.state.selectedLocations[location.id]}
            disabled={isLost}
            onChange={this.selectLocation}
          />
        </td>,
        <td key="label">{renderLocationLink(location.id, location.label)}</td>,
        <td key="state" className="capitalize">
          {location.state}
        </td>,
        <td key="allocatable" className="capitalize">
          {LocationAllocatable[location.allocatable]}
        </td>,
        <td key="category">{LocationCategory[location.category] || <span>&mdash;</span>}</td>,
        <>{this.props.enableZones && <td key="putAwayZone">{location.putAwayZone}</td>}</>
      ];
    } else {
      return <td colSpan={this.props.enableZones ? 6 : 5}></td>;
    }
  }

  private generateCsvDownloadPaths() {
    let csvDownloadPath = '/wh/locations_v2/contents_csv.csv';
    let masterCsvDownloadPath = '/wh/locations_v2/export_location_data.csv';

    const csvDownloadParams = {warehouseId: this.props.selectedWarehouse.id};
    Object.keys(this.props.filters).forEach((key) => (csvDownloadParams[key] = this.props.filters[key]));

    const csvDownloadQueryString = queryStringify(csvDownloadParams, {arrayFormat: 'bracket'});
    if (csvDownloadQueryString.length) {
      csvDownloadPath += `?${csvDownloadQueryString}`;
      masterCsvDownloadPath += `?${csvDownloadQueryString}`;
    }

    return {csvDownloadPath, masterCsvDownloadPath};
  }

  private renderGenericDownloadButtons(): JSX.Element {
    const {csvDownloadPath, masterCsvDownloadPath} = this.generateCsvDownloadPaths();

    return (
      <>
        <FileDownloadLink
          title={'Any virtual, lost, mhe and/or transitional locations are not included'}
          testId={'master-csv-button'}
          href={masterCsvDownloadPath}
          text={'Download Location Master CSV'}
          showAsButton
        />
        <FileDownloadLink
          testId={`inventory-csv-button_${csvDownloadPath}`}
          text={this.getDownloadTextWithFilter()}
          onClick={() => this.downloadInventoryCsv(csvDownloadPath)}
          showAsButton
        />
      </>
    );
  }

  private getDownloadTextWithFilter() {
    const numberOfAppliedFilters = this.getNumberOfAppliedFilters();
    return `Download Location Inventory CSV${
      numberOfAppliedFilters !== 0 ? ` (${numberOfAppliedFilters} filter${numberOfAppliedFilters > 1 ? 's' : ''})` : ''
    }`;
  }

  private getNumberOfAppliedFilters = () =>
    this.getFilters().reduce((runningFilterTotal, currentFilter) => {
      let numberOfAppliedFilters = 0;

      if (currentFilter.value) {
        numberOfAppliedFilters = currentFilter.allowMultiple ? currentFilter.value.length : 1;
      }

      return runningFilterTotal + numberOfAppliedFilters;
    }, 0);

  private downloadInventoryCsv = (path: string) => {
    if (!this.state.inventoryCsvDownloadStarted) {
      this.setState({inventoryCsvDownloadStarted: true});
      fetch(path).then((response) => {
        this.setState({inventoryCsvDownloadStarted: false});
        const contentDisposition = response.headers.get('Content-Disposition');
        const filenamePartStart = 'filename=';
        const filenamePartEnd = ';';
        const filenameStart = contentDisposition.indexOf(filenamePartStart) + filenamePartStart.length;
        const filenameEnd = contentDisposition.indexOf(filenamePartEnd, filenameStart);
        const filename = contentDisposition.substring(filenameStart, filenameEnd).replace(/"/g, '');
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          a.click();
        });
      });
    } else {
      this.toggleInventoryCsvDownloadingModal(null);
    }
  };

  private toggleInventoryCsvDownloadingModal = (event) => {
    this.setState({showInventoryCsvDownloadingModal: !this.state.showInventoryCsvDownloadingModal});
  };

  private renderContentColumns(locContent: LocationSku, location: Location, idx: number, locIdx: number) {
    const itemCount = location.contents.length;
    if (itemCount === 0) {
      // There are no skus in the location
      return <td colSpan={8} style={{textAlign: 'center'}}></td>;
    } else if (itemCount > 1 && idx === -1) {
      // There are more than one skus and we are at the -1 index
      const lpns = location.contents
        .filter((item) => item.entity && item.entity.type === LocationContentEntityType.LPN)
        .map((item) => item.entity.id);
      const distinctLpnCount = new Set(lpns).size;
      const numLpns = distinctLpnCount > 0 ? distinctLpnCount : '-';
      const lots = location.contents
        .filter((item) => item.inventoryTrackingData && item.inventoryTrackingData.lotCode)
        .map((item) => item.inventoryTrackingData.lotCode);
      const expiry = location.contents
        .filter((item) => item.inventoryTrackingData && item.inventoryTrackingData.expirationDate)
        .map((item) => item.inventoryTrackingData.expirationDate);
      const distinctLotCount = new Set(lots).size;
      const numLots = distinctLotCount > 0 ? distinctLotCount : '-';
      const distinctExpiryCount = new Set(expiry).size;
      const numExpiry = distinctExpiryCount > 0 ? distinctExpiryCount : '-';
      return [
        <td key="reservationId"></td>,
        <td key="sku">{location.sku}</td>,
        <td key="lpnBarcode">{numLpns}</td>,
        <td key="lotCode">{numLots}</td>,
        <td key="expirationDate">{numExpiry}</td>,
        <td key="quantity"></td>,
        <td key="packaging"></td>,
        <td key="toggle" className="toggle-skus">
          <a onClick={this.toggleMoreSkus} data-location-index={locIdx}>
            {location.collapse ? 'Show ' : 'Hide '}
            {itemCount} Items&nbsp;
            {location.collapse ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-up"></i>}
          </a>
        </td>
      ];
    } else {
      const reservationId = get(locContent, 'reservation.id');
      const quantity = get(locContent, 'quantity.amount');
      const packaging = get(locContent, 'quantity.unit');
      const lpn = get(locContent, 'entity.id');
      const lot = get(locContent, 'inventoryTrackingData.lotCode');
      const expiry = get(locContent, 'inventoryTrackingData.expirationDate');

      return [
        <td key="reservationId">{reservationId}</td>,
        <td key="sku">{renderItemLink(locContent.inventory.id, locContent.inventory.sku, false)}</td>,
        <td key="lpnBarcode">{lpn ? renderLpnLink(lpn, false) : '-'}</td>,
        <td key="lotCode">{lot ? lot : '-'}</td>,
        <td key="expirationDate">{expiry ? expiry : '-'}</td>,
        <td key="quantity">{quantity}</td>,
        <td key="packaging">{displayPackaging(packaging, quantity)}</td>,
        <td key="empty"></td>
      ];
    }
  }

  private selectLocation = (event) => {
    const locId = parseInt(event.target.getAttribute('data-location-id'), 10);
    const selectedLoc = this.state.locations.find((loc) => loc.id === locId);
    if (selectedLoc && this.state.selectedLocations[locId] !== undefined) {
      const selectedLocations = {...this.state.selectedLocations};
      // eslint-disable-next-line no-extra-boolean-cast
      selectedLocations[locId] = !!selectedLocations[locId] ? false : selectedLoc;
      this.setState({selectedLocations});
    }
  };

  private toggleAllLocationsOnCurrentPage = (event) => {
    const selectedLocations = {...this.state.selectedLocations};
    this.state.locations.forEach((loc) => {
      if (loc.category === LocationCategoryStrings.CATEGORY_LOST) {
        selectedLocations[loc.id] = false;
      } else {
        selectedLocations[loc.id] = event.target.checked ? loc : false;
      }
    });
    this.setState({selectedLocations});
  };

  private clearSelectedLocations = (event) => {
    const selectedLocations = {...this.state.selectedLocations};
    for (const locId of Object.keys(selectedLocations)) {
      // eslint-disable-next-line no-prototype-builtins
      if (selectedLocations.hasOwnProperty(locId)) {
        selectedLocations[locId] = false;
      }
    }
    this.setState({selectedLocations});
  };

  private toggleBulkActionModal = (event) => {
    this.setState({
      showBulkActionModal: !this.state.showBulkActionModal,
      selectedLocsWithContentCount: 0,
      locationToggleErrors: null
    });
  };

  private disableSelectedLocations = (event) => {
    // Count how many of the selected locations have content
    const selectedLocsWithContentCount = Object.keys(this.state.selectedLocations).filter((locId) => {
      const location = this.state.selectedLocations[locId];
      if (location) {
        const contents = get(location, 'contents');
        return contents && contents.length;
      }
      return false;
    }).length;
    this.setState({selectedLocsWithContentCount});
    if (!selectedLocsWithContentCount) {
      // We can submit the selected locations since none have content
      const selectedLocationIds = Object.keys(this.state.selectedLocations).filter((locId) => {
        return this.state.selectedLocations[locId];
      });
      this.bulkToggleLocations(LocationStatus.inactive, this.props.selectedWarehouse.id, selectedLocationIds);
    }
  };

  private lockSelectedLocations = (event) => {
    const selectedLocationIds = Object.keys(this.state.selectedLocations).filter(
      (locId) => this.state.selectedLocations[locId]
    );
    this.bulkToggleLocations(LocationStatus.locked, this.props.selectedWarehouse.id, selectedLocationIds);
  };

  private enableSelectedLocations = (event) => {
    // Create an array of the selected location IDs
    const selectedLocationIds = Object.keys(this.state.selectedLocations).filter((locId) => {
      return this.state.selectedLocations[locId];
    });
    this.bulkToggleLocations(LocationStatus.active, this.props.selectedWarehouse.id, selectedLocationIds);
  };

  private bulkToggleLocations = async (state: LocationStatus, warehouseId: number, locationIds: string[]) => {
    let errors;
    const response = await this.locationsService.bulkToggleLocations(state, warehouseId, locationIds);

    if (response && response.errors.length === 0) {
      // Reload locations and return the first page
      this.fetchLocations(
        this.props.selectedWarehouse.id,
        this.props.pageSize,
        this.props.continuationToken,
        this.props.filters
      );
      // Close the bulk action modal
      this.setState({showBulkActionModal: false, selectedLocations: {}});
    } else {
      errors = response.errors;
      this.setState({locationToggleErrors: errors[0].detail});
    }
  };

  private toggleMoreSkus = (event) => {
    const locIdx = parseInt(event.currentTarget.getAttribute('data-location-index'), 10);
    const locations = this.state.locations.map((loc, idx) => {
      if (idx === locIdx) {
        loc.collapse = !loc.collapse;
      }
      return loc;
    });
    this.setState({locations});
  };

  private handleWarehouseSelected = async (selectedWarehouse: Warehouse) => {
    // Set the selected warehouse, reset to page 1, and clear any selected locations
    this.props.handlePageChange(1);
    this.props.handleWarehouseChange(selectedWarehouse);
    this.setState({selectedLocations: {}});
    this.resetContinuationTokens();
    await this.loadDirectedPutAwayFeatureFlag();
    // Fetch the locations for the selected warehouse
    await this.fetchLocations(
      selectedWarehouse.id,
      this.props.pageSize,
      this.props.continuationToken,
      this.props.filters
    );
  };

  private async fetchLocations(warehouseId: number, pageSize: number, continuationToken?: string, filters?: object) {
    this.clearErrors();
    this.setState({fetchingLocations: true});
    try {
      const {
        locations,
        didHitLocationContentRetrievalLimit,
        totalLocationsCount,
        continuationToken: nextPageToken
      } = await this.locationsService.getLocations(warehouseId, pageSize, continuationToken, filters);

      if (nextPageToken && this.props.currentPage > this.continuationTokens.length) {
        this.continuationTokens.push(nextPageToken);
      }

      const selectedLocations = {...this.state.selectedLocations};
      // If a location id hasn't been added to the keys in selectedLocations, add it and set it to false
      locations.forEach((location) => {
        if (selectedLocations[location.id] === undefined) {
          selectedLocations[location.id] = false;
        }
      });

      const processedLocations = locations
        ? locations.map((location: any) => {
            // Count the unique SKUs in each location
            const uniqueSkus = new Set();
            for (const item of location.contents) {
              uniqueSkus.add(item.inventory.sku);
              if (uniqueSkus.size > 1) {
                break;
              }
            }

            // Based on the number of unique SKus, set the 'sku' property for displaying in the location table row
            switch (uniqueSkus.size) {
              case 0:
                location.sku = null;
                break;
              case 1:
                location.sku = location.contents[0].inventory.sku;
                break;
              default:
                location.sku = 'Multi-SKU';
            }
            location.collapse = true;
            return location;
          })
        : [];

      this.setState({
        locations: processedLocations,
        didHitLocationContentRetrievalLimit,
        selectedLocations,
        totalLocationsCount
      });
    } catch (errorResponse) {
      this.setState({errorResponse});
    } finally {
      this.setState({fetchingLocations: false});
    }
  }

  private async handlePagination(page: number) {
    const continuationToken = page > 1 ? this.continuationTokens[page - 2] : null;
    this.props.handleContinuationTokenChange(continuationToken);
    this.props.handlePageChange(page);
    this.fetchLocations(this.props.selectedWarehouse.id, this.props.pageSize, continuationToken, this.props.filters);
  }

  private changePageSize = (event) => {
    this.resetContinuationTokens();
    const pageSize = parseInt(event.target.value, 10);
    this.props.handlePageSizeChange(pageSize);
    this.props.handlePageChange(1);
    this.fetchLocations(this.props.selectedWarehouse.id, pageSize, null, this.props.filters);
  };

  private handleFilterChange = (filterData) => {
    this.resetContinuationTokens();
    const filters = this.compactFilters(filterData);
    this.updateUrl(filters);
    this.props.handleFilterChange(filters);
    this.props.handlePageChange(1);
    // pass in the updated filters object as it hasn't propogated to this.props.filters yet. no idea how that works.
    this.fetchLocations(this.props.selectedWarehouse.id, this.props.pageSize, null, filters);
  };

  private resetContinuationTokens() {
    this.continuationTokens = []; // Reset any saved continuation tokens
    this.props.handleContinuationTokenChange(null);
  }

  private updateUrl(filters) {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: queryStringify(filters)
    });
  }

  /*
    NOTE: If you need to a new filter which has 'allowMultiple: false',
    you have to add its name into filtersNotAllowMultiple array in Locations.tsx
    to prevent it parses the filter value as array from query string of URL.
  */
  private getFilters() {
    const statusOptions = Object.keys(LocationStatus).map((state) => {
      return {
        displayName: LocationStatus[state],
        value: state
      };
    });
    const categoryOptions = LocationsHelper.FilterLocationCategories(this.state.crossdockEnabled).map((cat) => {
      return {
        displayName: LocationCategory[cat],
        value: cat
      };
    });
    const primaryPickLocationOptions = Object.keys(LocationPrimaryPickLocation).map((item) => {
      return {
        displayName: LocationPrimaryPickLocation[item],
        value: item
      };
    });
    const allocatableOptions = Object.keys(LocationAllocatable).map((allocate) => {
      return {
        displayName: LocationAllocatable[allocate],
        value: allocate
      };
    });
    const filters = [
      {
        displayName: 'Location Name',
        key: 'locationLabel',
        type: FilterType.String,
        allowMultiple: true,
        value: this.getFilterValue('locationLabel')
      },
      {
        displayName: 'Location Status',
        key: 'locationStatus',
        type: FilterType.Dropdown,
        allowMultiple: true,
        options: [
          {
            displayName: 'Choose a State',
            value: ''
          },
          ...statusOptions
        ],
        value: this.getFilterValue('locationStatus')
      },
      {
        displayName: 'Location Category',
        key: 'locationCategory',
        type: FilterType.Dropdown,
        allowMultiple: true,
        options: [
          {
            displayName: 'Choose a Category',
            value: ''
          },
          ...categoryOptions
        ],
        value: this.getFilterValue('locationCategory')
      },
      {
        displayName: 'SKU',
        key: 'sku',
        type: FilterType.String,
        allowMultiple: true,
        value: this.getFilterValue('sku')
      },
      {
        displayName: 'Reservation',
        key: 'reservationId',
        type: FilterType.Number,
        allowMultiple: true,
        value: this.getFilterValue('reservationId')
      },
      {
        displayName: 'LPN Barcode',
        key: 'lpnBarcode',
        type: FilterType.String,
        allowMultiple: true,
        value: this.getFilterValue('lpnBarcode')
      },
      {
        displayName: 'Contains LPNs',
        key: 'hasLpn',
        type: FilterType.Present,
        allowMultiple: false,
        value: this.getFilterValue('hasLpn')
      },
      {
        displayName: 'Contains No Inventory',
        key: 'isEmpty',
        type: FilterType.Present,
        allowMultiple: false,
        value: this.getFilterValue('isEmpty')
      },
      {
        displayName: 'Primary Pick Location',
        key: 'isPrimaryPickLocation',
        type: FilterType.Radio,
        allowMultiple: false,
        options: [...primaryPickLocationOptions],
        value: this.getFilterValue('isPrimaryPickLocation')
      },
      {
        displayName: 'Allocatable',
        key: 'isAllocatable',
        type: FilterType.Radio,
        allowMultiple: false,
        options: [...allocatableOptions],
        value: this.getFilterValue('isAllocatable')
      },
      {
        displayName: 'Lot Code',
        key: 'lotCode',
        type: FilterType.String,
        allowMultiple: true,
        value: this.getFilterValue('lotCode')
      },
      {
        displayName: 'Expiration Date',
        key: 'expirationDate',
        type: FilterType.Date,
        allowMultiple: true,
        value: this.getFilterValue('expirationDate')
      },
      {
        displayName: 'Serial Number',
        key: 'serialNumber',
        type: FilterType.String,
        allowMultiple: true,
        value: this.getFilterValue('serialNumber')
      }
    ];
    if (this.props.enableZones) {
      filters.push({
        displayName: 'Put Away Zone',
        key: 'putAwayZone',
        type: FilterType.String,
        allowMultiple: true,
        value: this.getFilterValue('putAwayZone')
      });
    }
    return filters;
  }

  private getFilterValue(key: string) {
    // eslint-disable-next-line no-prototype-builtins
    if (this.props.filters.hasOwnProperty(key)) {
      return this.props.filters[key];
    }
    return null;
  }

  private compactFilters(filterData) {
    const filters = {};
    filterData.forEach((fData) => {
      if (fData.filter.type === FilterType.Present || fData.filter.type === FilterType.Radio) {
        filters[fData.filter.key] = fData.value;
      } else {
        if (filters[fData.filter.key]) {
          filters[fData.filter.key].push(fData.value);
        } else {
          filters[fData.filter.key] = [fData.value];
        }
      }
    });
    return filters;
  }

  private showBulkImportModal = (event) => {
    this.setState({showBulkImportModal: true});
  };

  private toggleBulkImportModal = (locationsHaveChanged: boolean) => {
    if (locationsHaveChanged) {
      this.fetchLocations(
        this.props.selectedWarehouse.id,
        this.props.pageSize,
        this.props.continuationToken,
        this.props.filters
      );
    }

    this.setState({showBulkImportModal: !this.state.showBulkImportModal});
  };

  private clearErrors = () => {
    this.setState({errorResponse: null, errorMessage: ''});
  };

  /**
   * Get directed-putaway feature flag based on the current warehouse id.
   * Parse the return value and set it to the component state.
   */
  private async loadDirectedPutAwayFeatureFlag() {
    const warehouseId: number = this.props.selectedWarehouse.id;
    const response = await this.warehouseService.getFeatureFlag(DIRECTED_PUT_AWAY_FEATURE_FLAG, warehouseId);
    this.setState({
      directedPutAwayEnabled: !!response.data.value
    });
  }

  /**
   * Load all reservations associated with the current warehouse and,
   * if any have crossdock enabled, set crossdockEnabled as true in the component state.
   */
  private async checkIfCrossdockEnabled() {
    const warehouseId: number = this.props.selectedWarehouse.id;
    const response = await this.warehouseService.getReservationsForWarehouse(warehouseId);
    this.setState({
      crossdockEnabled: response.data.reservations.some((res) => res.crossdockEnabled)
    });
  }
}

export default LocationsIndex;
