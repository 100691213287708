// specifically used as an embeddable table of EDI files for display on various detail pages

import * as React from 'react';
import {Loader, Table, TableHeader} from '@flexe/ui-components';
import {format as formatDate} from 'date-fns';
import CustomKebabMenu, {KebabMenuOption} from '../../shared/CustomKebabMenu';
import FileStorageService from '../file-storage/FileStorageService';
import {CreateAttachmentModal} from './modals/CreateAttachmentModal';
import OutboundOrdersService from './OutboundOrdersService';
import {OrderAttachment} from './OutboundOrdersInterfaces';
import {ConfirmDeleteAttachmentModal} from './modals/ConfirmAttachmentDeleteModal';
import {LabelList} from './helpers/Labels';

interface Props {
  hide?: boolean;
  orderId: string;
  outboundOrdersService?: OutboundOrdersService;
  adminSignedIn: boolean;
  companyId: number;
  orderLabels: LabelList;
}

interface State {
  loading: boolean;
  attachments: OrderAttachment[];
  showCreateAttachmentModal: boolean;
  showDeleteConfirmModal: boolean;
  selectedAttachment?: OrderAttachment;
  errors?: string[];
}

class OrderAttachmentsTab extends React.Component<Props, State> {
  private outboundOrdersService: OutboundOrdersService;
  private fileStorageService: FileStorageService;

  constructor(props) {
    super(props);
    this.outboundOrdersService = props.outboundOrdersService || new OutboundOrdersService();
    this.fileStorageService = new FileStorageService();
    this.state = {
      loading: true,
      showCreateAttachmentModal: false,
      attachments: [],
      showDeleteConfirmModal: false,
      selectedAttachment: null,
      errors: null
    };
  }
  public async componentDidMount() {
    this.loadOrderAttachments();
  }

  public render() {
    const {showCreateAttachmentModal, loading, errors, attachments, showDeleteConfirmModal} = this.state;
    if (!this.props.hide) {
      if (loading) {
        return <Loader loading={true} />;
      } else {
        return (
          <div id="attachments-tab">
            {errors && errors.length > 0 && (
              <div className="alert alert-danger space-above space-below" role="alert">
                <ul>
                  {errors.map((e, i) => (
                    <li key={i}>{e}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="col-xs-6">
              <h4>Attachments</h4>
            </div>
            <div className="col-xs-6">
              <a className="pull-right" onClick={this.toggleAddAttachment}>
                Add Attachment
              </a>
            </div>
            <div className="col-xs-12">
              {attachments && attachments.length > 0 ? (
                <Table tableData={this.getTableData()} />
              ) : (
                <span className="no-attachments">No attachments.</span>
              )}
            </div>
            {showCreateAttachmentModal && (
              <CreateAttachmentModal
                fileStorageService={this.fileStorageService}
                outboundOrdersService={this.outboundOrdersService}
                toggleCreateAttachmentModal={this.toggleAddAttachment}
                orderId={this.props.orderId}
                companyId={this.props.companyId}
                orderLabels={this.props.orderLabels}
                reloadAttachments={this.loadOrderAttachments}
                adminSignedIn={this.props.adminSignedIn}
              />
            )}
            {showDeleteConfirmModal && (
              <ConfirmDeleteAttachmentModal
                toggleShowModal={this.toggleShowDeleteConfirmModal}
                deleteAttachment={this.deleteAttachment}
              />
            )}
          </div>
        );
      }
    } else {
      return null;
    }
  }

  private deleteAttachment = async () => {
    let errors = [];
    const deleteResponse = await this.outboundOrdersService.deleteAttachment(
      this.props.orderId,
      this.state.selectedAttachment.id
    );
    if (deleteResponse && deleteResponse.errors && deleteResponse.errors.length > 0) {
      const newErrors = this.outboundOrdersService.processErrors(deleteResponse.errors);
      if (this.state.errors) {
        errors = this.state.errors.concat(newErrors);
      } else {
        errors = newErrors;
      }
      this.setState({errors});
    } else {
      this.loadOrderAttachments();
    }
    this.toggleShowDeleteConfirmModal();
  };

  private toggleAddAttachment = () => {
    this.setState({
      showCreateAttachmentModal: !this.state.showCreateAttachmentModal
    });
  };

  private loadOrderAttachments = async () => {
    const attachmentsResponse = await this.outboundOrdersService.getOrderAttachments(this.props.orderId);
    const attachments = attachmentsResponse?.attachments || ([] as OrderAttachment[]);
    this.setState({
      attachments,
      loading: false
    });
  };

  private downloadAttachment = async (attachment: OrderAttachment) => {
    const fileMetaData = await this.fileStorageService.getFileMetadata(attachment.fileKey);
    this.fileStorageService.downloadFile(fileMetaData);
  };

  private toggleShowDeleteConfirmModal = () => {
    this.setState({
      showDeleteConfirmModal: !this.state.showDeleteConfirmModal
    });
  };

  private handleDeleteAttachment = (attachment) => {
    this.setState({
      selectedAttachment: attachment
    });
    this.toggleShowDeleteConfirmModal();
  };

  private downloadOption = (attachment: OrderAttachment): KebabMenuOption => {
    return {
      optionText: 'Download',
      optionAction: () => this.downloadAttachment(attachment)
    };
  };

  private deleteOption = (attachment: OrderAttachment): KebabMenuOption => {
    return {
      optionText: 'Delete',
      isActionDangerous: true,
      optionAction: () => this.handleDeleteAttachment(attachment)
    };
  };

  private ellipsisMenuOptions = (attachment: OrderAttachment) => {
    const options: KebabMenuOption[] = [];

    options.push(this.downloadOption(attachment));
    options.push(this.deleteOption(attachment));

    return options;
  };

  private getTableData = () => {
    const headers = [{element: 'Name'}, {element: 'Type'}, {element: 'Added At'}, {element: ''}] as TableHeader[];

    const rows = this.state.attachments.map((attachment: OrderAttachment) => {
      const row: (string | JSX.Element)[] = [
        attachment.displayName,
        attachment.type,
        formatDate(attachment.createdAt, 'MM/DD/YY h:mma'),
        <div className="ellipsis-container">
          <CustomKebabMenu options={this.ellipsisMenuOptions(attachment)} />
        </div>
      ];
      return row;
    });
    return {
      headers,
      rows
    };
  };
}

export default OrderAttachmentsTab;
