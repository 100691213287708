import * as React from 'react';
import {TableHeader} from '@flexe/ui-components';
import {PickMethod, SkuSingleLineItem, WaveParameters} from '../ecommerce-batches/BatchInterfaces';
import {displayPackaging} from '../../../libs/helpers';
import {SkuLeaderboard} from './WaveInterfaces';
import {
  MULTI_SKU_SHIPMENT_PROFILE,
  SINGLE_SKU_MULTI_ITEM_SHIPMENT_PROFILE,
  SINGLE_SKU_SINGLE_ITEM_SHIPMENT_PROFILE
} from './WaveBatchConstants';

interface FilterResultsHVWTableProps {
  skuLeaderboards: Map<string, SkuLeaderboard>;
  canShowFullPalletPull: boolean;
  disableMultiSkuWaving: boolean;
  handleShowWaveModal(
    hvwWaveParameters: object,
    subTotalShipmentsOrPallets: number,
    fullPalletsOnly: boolean,
    unitsPerPalletCount: number
  );
}

const FilterResultsHVWTable: React.FC<FilterResultsHVWTableProps> = (props) => {
  const [error, setError] = React.useState<string>('');
  const maxRows = 5;
  const requiredBatchAttributes = [
    {
      name: 'hazmat',
      value: 'Hazmat'
    },
    {
      name: 'siteToStore',
      value: 'S2S'
    },
    {
      name: 'packTimeLabelGeneration',
      value: 'PTLG'
    },
    {
      name: 'overbox',
      value: 'Overbox'
    }
  ];

  const formatQuantityAndUnit = (quantity: number, unit: string) => {
    const formattedQuantity = numberWithCommas(quantity);
    if (!unit) {
      return formattedQuantity;
    }

    const formattedUnit = displayPackaging(unit, quantity);
    return formattedQuantity + ' ' + formattedUnit;
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const unitsPerPallet = (lineItem: SkuSingleLineItem) => {
    return lineItem.packaging === 'each' ? lineItem.eachesPerPallet : lineItem.cartonsPerPallet;
  };

  const getBatchAttributesToDisplay = (lineItem: SkuSingleLineItem) => {
    return (
      <span>
        {requiredBatchAttributes.map((requiredBatchAttribute, index) => {
          const classname = `batch-attributes-${requiredBatchAttribute.name}`;
          if (lineItem[requiredBatchAttribute.name] && lineItem[requiredBatchAttribute.name] === true) {
            return (
              <span className={classname} key={index}>
                {requiredBatchAttribute.value}
              </span>
            );
          }
        })}
      </span>
    );
  };

  const parseSkuCount = (mainSku, skuListArr) => {
    if (!skuListArr) {
      setError(`Failed to find skus associated with ${mainSku}`);
      return -1;
    }
    if (!Array.isArray(skuListArr)) {
      return 0;
    }

    const set = new Set();
    skuListArr.forEach((skuList) => {
      const skusSplit = skuList.split(',');
      skusSplit.forEach((sku) => {
        set.add(sku);
      });
    });
    return set.size - 1;
  };

  const getNumFullPallets = (current: SkuSingleLineItem, isMultiUnit: boolean) => {
    let numFullPallets = 0;
    if (isMultiUnit) {
      return numFullPallets;
    }

    if (current.packaging === 'each' && current.eachesPerPallet > 0 && current.quantity >= current.eachesPerPallet) {
      numFullPallets = Math.floor(current.quantity / current.eachesPerPallet);
    } else if (current.packaging === 'carton') {
      if (current.cartonsPerPallet > 0 && current.quantity >= current.cartonsPerPallet) {
        numFullPallets = Math.floor(current.quantity / current.cartonsPerPallet);
      }
    }
    return numFullPallets;
  };

  const createSkuLeaderboardFromTableData = (tableData) => {
    if (!tableData || !tableData.headers || !(tableData.headers.length === 4 || tableData.headers.length === 6)) {
      return null;
    }
    const columnWidths = tableData.headers.length === 4 ? [3, 3, 3, 3] : [2, 2, 2, 2, 3, 1];
    const headerRow = (
      <div key="header-row" className="row leaderboard-header">
        {tableData.headers.map((header, index) => {
          return (
            <div
              key={index}
              className={`col-xs-${columnWidths[index]}
                                 ${header.className ? header.className : ''}`}
            >
              {header.element}
            </div>
          );
        })}
      </div>
    );

    if (!tableData.rows || !(tableData.rows.length > 0) || !tableData.rows[0] || !(tableData.rows[0].length > 0)) {
      return headerRow;
    }

    const dataRows = tableData.rows.map((row, rowIndex) => {
      return (
        <div
          key={rowIndex}
          className={`row leaderboard-row ${rowIndex % 2 === 0 ? 'leaderboard-row-highlighted' : ''}`}
        >
          {row.map((column, colIndex) => {
            return (
              <div key={colIndex} className={`col-xs-${columnWidths[colIndex]}`}>
                {column}
              </div>
            );
          })}
        </div>
      );
    });

    return [headerRow, dataRows];
  };

  const getMultiSkuTableData = (leaderboard: SkuLeaderboard) => {
    const headers = [
      {className: 'id-header', element: 'Shipments'},
      {element: 'Total Units'},
      {element: 'Top Mover Units'},
      {element: ''},
      {element: 'Top Mover SKU'},
      {element: ''}
    ] as TableHeader[];
    const rows =
      0 === leaderboard.skuSummary.length || !leaderboard.dataHasLoaded
        ? [[]]
        : leaderboard.skuSummary.slice(0, maxRows).map((current: SkuSingleLineItem, index) => {
            //ex: 'SKUNAME, + 3 other skus'
            const otherSkusCount = parseSkuCount(current.sku, current.allShipmentSkus);
            if (otherSkusCount === -1) {
              return [[]];
            }
            let topMoverSku = `${current.sku}, + ${numberWithCommas(otherSkusCount)} other sku`;
            if (otherSkusCount !== 1) {
              topMoverSku += 's';
            }
            const topMoverQuantity = formatQuantityAndUnit(current.topMoverQuantity, current.packaging);
            return [
              <span className="shipments">{numberWithCommas(current.totalShipments)}</span>,
              <span className="units">{numberWithCommas(current.quantity)}</span>,
              <span className="topMoverUnits">{topMoverQuantity}</span>,
              getBatchAttributesToDisplay(current),
              <div className="topMover">
                <span className="skus">{topMoverSku}</span>
                <span className="description">{current.description}</span>
              </div>,
              <div className="leaderboard-button">
                <button
                  className="btn, leaderboard-button"
                  id={`create-sku-wave-single-${current.sku}-${leaderboard.shipmentProfile}`}
                  data-testid={`create-sku-wave-single-${current.sku}-${leaderboard.shipmentProfile}`}
                  disabled={props.disableMultiSkuWaving}
                  onClick={() => {
                    skuWave(
                      current,
                      leaderboard.shipmentProfile,
                      leaderboard.defaultPickMethod,
                      false,
                      current.totalShipments
                    );
                  }}
                >
                  Wave
                </button>
              </div>
            ];
          });

    return {
      headers,
      rows
    };
  };

  const getSingleSkuTableData = (leaderboard: SkuLeaderboard) => {
    const headers = [{className: 'id-header', element: 'Shipments'}, {element: 'Units'}, {}, {}] as TableHeader[];

    const rows =
      0 === leaderboard.skuSummary.length || !leaderboard.dataHasLoaded
        ? [[]]
        : leaderboard.skuSummary.slice(0, maxRows).map((current: SkuSingleLineItem, index) => {
            const numFullPallets = getNumFullPallets(current, leaderboard.isMultiUnit);
            const palletEquiv = formatQuantityAndUnit(unitsPerPallet(current), current.packaging);
            const pallets = numFullPallets > 1 ? 'Pallets' : 'Pallet';
            const batchFullPalletsButton =
              numFullPallets && props.canShowFullPalletPull ? (
                <button
                  className="btn leaderboard-button"
                  id="create-full-pallet-wave"
                  onClick={() => {
                    skuWave(current, leaderboard.shipmentProfile, leaderboard.defaultPickMethod, true, numFullPallets);
                  }}
                >
                  Batch: Up To {numFullPallets} Full {pallets}{' '}
                </button>
              ) : null;
            return [
              <span className="shipments">{numberWithCommas(current.totalShipments)}</span>,
              <span className="units">
                <span className="quantity">{formatQuantityAndUnit(current.quantity, current.packaging)}</span>
                <span className="sku">{current.sku}</span>
                <span className="description">{current.description}</span>
                <span className="description">{'Full pallet ' + palletEquiv}</span>
              </span>,
              getBatchAttributesToDisplay(current),
              <div className="leaderboard-button">
                {batchFullPalletsButton}
                <button
                  className="btn leaderboard-button"
                  id={`create-sku-wave-single-${current.sku}-${leaderboard.shipmentProfile}`}
                  data-testid={`create-sku-wave-single-${current.sku}-${leaderboard.shipmentProfile}`}
                  onClick={() => {
                    skuWave(
                      current,
                      leaderboard.shipmentProfile,
                      leaderboard.defaultPickMethod,
                      false,
                      current.totalShipments
                    );
                  }}
                >
                  Wave
                </button>
              </div>
            ];
          });

    return {
      headers,
      rows
    };
  };

  const skuLeaderboardTable = (tableTitle: string, leaderboard: SkuLeaderboard) => {
    return (
      <div className="sku-summary">
        <h4 className="table-title">{tableTitle}</h4>

        {createSkuLeaderboardFromTableData(
          leaderboard.isMultiSku ? getMultiSkuTableData(leaderboard) : getSingleSkuTableData(leaderboard)
        )}
        <span
          className={
            0 === leaderboard.skuSummary.length && leaderboard.dataHasLoaded
              ? 'no-matching-shipments'
              : 'no-matching-shipments-hidden'
          }
        >
          <br />
          No matching shipments
        </span>
        <span className={`fa-lg ${leaderboard.dataHasLoaded ? 'spinner-hidden' : ''}`}>
          <i className="fa fa-spinner fa-spin"></i>
        </span>
      </div>
    );
  };

  const getHVWWaveParameters = (
    lineItem: SkuSingleLineItem,
    shipmentProfile: string,
    maxOrdersOverride: number,
    pickMethod: PickMethod
  ) => {
    return {
      maxOrders: maxOrdersOverride || lineItem.totalShipments,
      skus: [lineItem.sku],
      pickMethod,
      shipmentProfile,
      ...(shipmentProfile !== MULTI_SKU_SHIPMENT_PROFILE && {
        packaging: lineItem.packaging
      }),
      isHvw: true
    };
  };

  const skuWave = (
    lineItem: SkuSingleLineItem,
    shipmentProfile: string,
    pickMethod: PickMethod,
    fullPalletsOnly: boolean = false,
    totalShipmentsOrPallet: number = 0
  ) => {
    const unitsPerPalletCount = unitsPerPallet(lineItem);
    const hvwWaveParameters = getHVWWaveParameters(lineItem, shipmentProfile, null, pickMethod);

    props.handleShowWaveModal(hvwWaveParameters, totalShipmentsOrPallet, fullPalletsOnly, unitsPerPalletCount);
  };
  return (
    <div id="filter-results-hvw-table">
      <div className="row">
        <div className="col-md-6 leaderboard-spacing">
          {skuLeaderboardTable(
            'Single\u00A0SKU,\u00A0Single\u00A0Unit',
            props.skuLeaderboards.get(SINGLE_SKU_SINGLE_ITEM_SHIPMENT_PROFILE)
          )}
        </div>
        <div className="col-md-6 leaderboard-spacing">
          {skuLeaderboardTable(
            'Single\u00A0SKU,\u00A0Multi\u00A0Unit',
            props.skuLeaderboards.get(SINGLE_SKU_MULTI_ITEM_SHIPMENT_PROFILE)
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 leaderboard-spacing">
          {skuLeaderboardTable('Multi\u00A0Sku', props.skuLeaderboards.get(MULTI_SKU_SHIPMENT_PROFILE))}
        </div>
      </div>
    </div>
  );
};

export default FilterResultsHVWTable;
