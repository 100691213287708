import * as moment from 'moment';
import {getTime} from 'date-fns';
import {ApiResponse, Document, Packaging} from '../../shared/CommonInterfaces';
import ContainersService from '../../shared/services/ContainersService';
import {LpnSearchResponse, LpnStatus} from '../../lpns/LpnsInterfaces';
import {convertToEaches} from '../../shared/utilities/DataFormatting';
import {
  ContainerDelivery,
  ContainerDeliveryState,
  DeliverySummaryData,
  PackingLists,
  PoTabMapData,
  SkusTabData,
  SkuTableEntryId,
  ToCapturePropertyKeysAndValues
} from './DropoffInterfaces';
import {PurchaseOrderContentData} from './PurchaseOrderContentRow';

export default class DropoffHelper {
  public static hoursAndMinutesToMs(hoursMins: string) {
    if (hoursMins && hoursMins.match(':')) {
      const timeParts = hoursMins.split(' ');
      const meridian = timeParts[1];
      const timeVals = timeParts[0].split(':');
      let hrs = parseInt(timeVals[0], 10) % 12;
      hrs += meridian === 'PM' ? 12 : 0;
      const mins = parseInt(timeVals[1], 10);
      return getTime(Date.UTC(1970, 0, 1, hrs, mins, 0));
    }
    return 0;
  }

  public static msToHoursAndMinutes(ms: number): string {
    const time = moment.utc(ms);
    return time.format('h:mm A');
  }

  public static hasBillOfLading(documents: Document[], shipperCompanyId: number) {
    const idx = documents.findIndex((doc) => {
      return doc.documentType === 'Bill Of Lading' && doc.company.id !== shipperCompanyId;
    });
    return idx !== -1;
  }

  public static isInboundConfirmedButNotReceiving(containerDelivery: ContainerDelivery) {
    return (
      containerDelivery.txnState === ContainerDeliveryState.confirmed &&
      (containerDelivery.receiving.startAt === null ||
        containerDelivery.receiving.endAt === null ||
        containerDelivery.actualArrivalTime === null)
    );
  }

  public static buildDeliverySummary(packingLists: PackingLists, poTabMapData: PoTabMapData): DeliverySummaryData {
    const posTabDataRows: PurchaseOrderContentData[] = this.buildPoTabData(poTabMapData);
    const skusTabDataRows: SkusTabData[] = Array.from(this.buildSkusTabData(packingLists).values());

    const tableDataRows: DeliverySummaryData = {
      skus: skusTabDataRows,
      purchaseOrders: posTabDataRows
    };
    return tableDataRows;
  }

  public static buildSkusTabData(packingLists: PackingLists): Map<string, SkusTabData> {
    // key: combination of sku + po + uom + damaged
    // value: a sku data
    const skusTabDataMap: Map<string, SkusTabData> = new Map<string, SkusTabData>();
    // for context: shippable + damaged = actual
    // packingLists.expected iteration

    // first iterate through expected packing lists to get all expected values updated.
    packingLists.expected.forEach((expl) => {
      const skuTableEntryId: SkuTableEntryId = {
        inventoryId: expl.inventory.id,
        purchaseOrderNumber: expl.purchaseOrderNumber,
        uoms: expl.uoms
      };
      const skuTableEntryIdKey: string = JSON.stringify(skuTableEntryId);
      // If key exists with same inventoryId, skuTableEntryIdKey, uoms, damaged, packaging, sum up the quantity.
      if (skusTabDataMap.has(skuTableEntryIdKey)) {
        const existingSkuData: SkusTabData = skusTabDataMap.get(skuTableEntryIdKey);
        existingSkuData.expectedQuantity.amount += expl.quantity.amount;
      } else {
        const skuData: SkusTabData = {
          expectedPackingListId: expl.id,
          inventoryId: expl.inventory.id, // need this to call renderItemLink()
          sku: expl.inventory.sku, // data we have so far
          description: expl.inventory.description, // data we have so far
          po: expl.purchaseOrderNumber || '-', // data we have so far
          expectedInner: expl.uoms?.eachesPerInnerPack, // data we have so far
          expectedOuter: expl.uoms?.eachesPerOuterCase, // data we have so far
          receivedInner: null, // data we don't have yet
          receivedOuter: null, // data we don't have yet
          expectedQuantity: {...expl.quantity}, // data we have so far
          shippableQuantity: {
            unit: expl.quantity.unit,
            amount: 0
          }, // data we don't have yet
          damagedQuantity: {
            unit: expl.quantity.unit,
            amount: 0
          }, // data we don't have yet
          packagingConversionFactor: expl.inventory?.properties?.each?.unitsPerParent || null,
          pallets: expl.pallets
        };
        skusTabDataMap.set(skuTableEntryIdKey, skuData);
      }
    });

    // packingLists.shippable iteration
    packingLists.shippable.forEach((shpl) => {
      const skuTableEntryId: SkuTableEntryId = {
        inventoryId: shpl.inventory.id,
        purchaseOrderNumber: shpl.purchaseOrderNumber,
        uoms: shpl.uoms
      };
      const skuTableEntryIdKey: string = JSON.stringify(skuTableEntryId);
      let skuData: SkusTabData;
      if (skusTabDataMap.has(skuTableEntryIdKey)) {
        // when there are same sku with same po, if the user receives with any existing uoms, we update that row
        // if the user receives with uoms that does not match any lines, we create a new row
        skuData = skusTabDataMap.get(skuTableEntryIdKey);
        skuData.shippablePackingListId = shpl.id;
        // sum up the quantity if received same sku/po/uom
        // if the units are the same, we can simply sum the amounts
        if (skuData.shippableQuantity.unit === shpl.quantity.unit) {
          skuData.shippableQuantity.amount += shpl.quantity.amount;
        } else if (shpl.inventory?.properties?.each?.unitsPerParent) {
          // if the units are not the same, convert to eaches and sum
          const conversionFactor: number = shpl.inventory?.properties?.each?.unitsPerParent;
          skuData.receivedInMultipleUnits = skuData.shippableQuantity.amount !== 0;

          const totalInEaches =
            convertToEaches(skuData.shippableQuantity.amount, skuData.shippableQuantity.unit, conversionFactor) +
            convertToEaches(shpl.quantity.amount, shpl.quantity.unit, conversionFactor);
          if (shpl.quantity.unit === Packaging.each) {
            skuData.shippableQuantity.amount = totalInEaches;
          } else {
            skuData.shippableQuantity.amount = totalInEaches / conversionFactor;
          }
          // display in the packaging that the item was actually received
          skuData.shippableQuantity.unit = shpl.quantity.unit;
        } else {
          // missing the inventory's unitsPerParent property. If we get to this, so many other things have gone wrong.
          skuData.receivedInMultipleUnits = skuData.shippableQuantity.amount !== 0;
        }

        // so that the Received Inner/Outer UoM won't show '-' but the same as expected uom
        skuData.receivedInner = shpl.uoms?.eachesPerInnerPack;
        skuData.receivedOuter = shpl.uoms?.eachesPerOuterCase;

        skusTabDataMap.set(skuTableEntryIdKey, skuData);
      } else {
        // else it means we received something (different SKU + PO + UOM combination) unexpected,
        // therefore create a new table row with all expected values as 0.
        skuData = {
          shippablePackingListId: shpl.id,
          inventoryId: shpl.inventory.id,
          sku: shpl.inventory.sku,
          description: shpl.inventory.description,
          po: shpl.purchaseOrderNumber || '-',
          expectedInner: null,
          expectedOuter: null,
          receivedInner: shpl.uoms?.eachesPerInnerPack,
          receivedOuter: shpl.uoms?.eachesPerOuterCase,
          expectedQuantity: {
            unit: shpl.quantity.unit,
            amount: 0
          },
          shippableQuantity: {...shpl.quantity},
          damagedQuantity: {
            unit: shpl.quantity.unit,
            amount: 0
          },
          packagingConversionFactor: shpl.inventory?.properties?.each?.unitsPerParent || null,
          pallets: shpl.pallets,
          receivedInMultipleUnits: false
        };
        skusTabDataMap.set(skuTableEntryIdKey, skuData);
      }
    });

    packingLists.damaged.forEach((dmgpl) => {
      const skuTableEntryId: SkuTableEntryId = {
        inventoryId: dmgpl.inventory.id,
        purchaseOrderNumber: dmgpl.purchaseOrderNumber,
        uoms: dmgpl.uoms
      };
      const skuTableEntryIdKey: string = JSON.stringify(skuTableEntryId);
      let skuData: SkusTabData;
      if (skusTabDataMap.has(skuTableEntryIdKey)) {
        // when there are same sku with same po, if the user receives with any existing uoms, we update that row
        // if the user receives with uoms that does not match any lines, create a new row
        const skusTabData: SkusTabData = skusTabDataMap.get(skuTableEntryIdKey);
        skusTabData.damagedPackingListId = dmgpl.id;

        // sum up the quantity if received same sku/po/uom
        skusTabData.damagedQuantity.amount += dmgpl.quantity.amount;
        skusTabData.damagedQuantity.unit = dmgpl.quantity.unit;

        // so that the Received Inner/Outer UoM won't show '-' but the same as expected uom
        skusTabData.receivedInner = dmgpl.uoms?.eachesPerInnerPack;
        skusTabData.receivedOuter = dmgpl.uoms?.eachesPerOuterCase;

        skusTabDataMap.set(skuTableEntryIdKey, skusTabData);
      } else {
        // else it means we received something unexpected,
        // therefore create a new table row with all expected values as 0.
        skuData = {
          damagedPackingListId: dmgpl.id,
          inventoryId: dmgpl.inventory.id,
          sku: dmgpl.inventory.sku,
          description: dmgpl.inventory.description,
          po: dmgpl.purchaseOrderNumber || '-',
          expectedInner: null,
          expectedOuter: null,
          receivedInner: dmgpl.uoms.eachesPerInnerPack,
          receivedOuter: dmgpl.uoms.eachesPerOuterCase,
          expectedQuantity: {
            unit: dmgpl.quantity.unit,
            amount: 0
          },
          shippableQuantity: {
            unit: dmgpl.quantity.unit,
            amount: 0
          },
          damagedQuantity: {...dmgpl.quantity},
          packagingConversionFactor: dmgpl.inventory?.properties?.each?.unitsPerParent || null,
          pallets: dmgpl.pallets
        };
        skusTabDataMap.set(skuTableEntryIdKey, skuData);
      }
    });
    return skusTabDataMap;
  }

  public static buildPoTabData(poTabMapData: PoTabMapData) {
    const poContainerIdsMap = poTabMapData?.poContainerIdsMap;
    const containerIdResIdMap = poTabMapData?.containerIdResIdMap;
    const reservationIdNameMap = poTabMapData?.reservationIdNameMap;

    const poDataRows = [];

    poContainerIdsMap?.forEach((containerIdsSet, purchaseOrder) => {
      const inboundIds = Array.from(containerIdsSet);
      const reservationNames = [];

      for (const item of inboundIds) {
        const resId = containerIdResIdMap.get(item);

        // when the reservation is only allowed for warehouse user but not the warehouse,
        // we skip this reservation. It happens when the user has multi warehouses access.
        if (!reservationIdNameMap.has(resId)) {
          continue;
        }
        const reservationName = reservationIdNameMap.get(resId)?.toString();
        if (reservationName) {
          reservationNames.push(reservationName);
        }
      }

      const data: PurchaseOrderContentData = {purchaseOrder, inboundIds, reservationNames};
      poDataRows.push(data);
    });

    return poDataRows;
  }

  // build the request and call the API to write the capturable properties
  public static updateCapturablePropertiesAsync = async (
    propertiesMap: Map<string, string>,
    containerDeliveryId: number,
    containersService: ContainersService
  ) => {
    const properties: ToCapturePropertyKeysAndValues = {};
    propertiesMap.forEach((propertyValue, propertyKey) => {
      properties[propertyKey] = propertyValue;
    });
    return await containersService.updateToCapturePropertyValues(containerDeliveryId, properties);
  };

  public static getCurrentIndexRangeOfLpn(
    lpnAmountPerApiCall: number,
    counter: number,
    maxNumOfApiCalls: number,
    lpnBarcodeList: string[]
  ) {
    const startIndex = counter * lpnAmountPerApiCall;
    const endIndex =
      counter === maxNumOfApiCalls - 1 ? lpnBarcodeList.length - 1 : (counter + 1) * lpnAmountPerApiCall - 1;

    return {startIndex, endIndex};
  }

  public static updateLpnStateData(
    lpnBarcodeList: string[],
    apiResponse: ApiResponse<LpnSearchResponse>,
    lpnData: Map<string, LpnStatus>
  ) {
    let lpnBarcodeStateMap = new Map();
    if (apiResponse) {
      lpnBarcodeStateMap = new Map<string, LpnStatus>(apiResponse.data.lpns.map((l) => [l.lpnBarcode, l.state]));
    }

    for (const lpnBarcode of lpnBarcodeList) {
      if (apiResponse && lpnBarcodeStateMap.has(lpnBarcode)) {
        lpnData[lpnBarcode] = lpnBarcodeStateMap.get(lpnBarcode);
      } else {
        lpnData[lpnBarcode] = null;
      }
    }
  }
}
