import * as React from 'react';
import ContainersService from '../../shared/services/ContainersService';
import LpnService from '../../shared/services/LpnService';
import InventoryService from '../../shared/services/InventoryService';
import DocumentsService from '../../shared/services/DocumentsService';
import WarehouseService from '../../shared/services/WarehouseService';
import UserService from '../../shared/services/UserService';
import PackingListService from '../../shared/services/PackingListService';

export interface ShipperContainerDeliveryContextValues {
  containerDeliveryId: number;
  containersService: ContainersService;
  lpnService: LpnService;
  inventoryService: InventoryService;
  documentsService: DocumentsService;
  warehouseService: WarehouseService;
  userService: UserService;
  packingListService: PackingListService;
}

const defaultValues: ShipperContainerDeliveryContextValues = {
  containerDeliveryId: -1,
  containersService: null,
  lpnService: null,
  inventoryService: null,
  documentsService: null,
  warehouseService: null,
  userService: null,
  packingListService: null
};

const ShipperContainerDeliveryContext = React.createContext(defaultValues);

export default ShipperContainerDeliveryContext;
