import * as React from 'react';
import {get, map} from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {LegacyModal} from '@flexe/ui-components';
import DocumentService from '../shared/services/DocumentsService';
import FlexeContext from '../contexts/FlexeContext';
import {Document, DocumentType} from './CommonInterfaces';
import ErrorDisplay from './ErrorDisplay';

interface Props {
  documents: Document[];
  documentsRoute: string;
  notableType: string;
  notableId: number;
  showUploadModal: boolean;
  toggleUploadModal();
  onDocumentSaved(savedDoc: Document);
}

export const DocumentUploadModal: React.FC<Props> = (props) => {
  const documentService = new DocumentService(null);
  const context = React.useContext(FlexeContext);
  const [notes, setNotes] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [fileAddError, setFileAddError] = React.useState('');
  const [isFileSelected, setIsFileSelected] = React.useState(false);

  const handleToggle = () => {
    setUploading(false);
    setIsFileSelected(false);
    props.toggleUploadModal();
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setUploading(true);

    const formAction = get(event, 'target.action');
    const formElements = get(event, 'target.elements');

    if (formElements) {
      try {
        if (notes.length < 1) {
          setFileAddError('You must select a file for uploading');
          return;
        }
        // 50MB max upload size
        if (notes.some((note) => note.size > 52428800)) {
          setFileAddError('File is too large. Maximum size: 50MB');
          return;
        }

        setFileAddError('');
        await Promise.all(
          notes.map(async (note: Document) => {
            const params = {
              authenticity_token: context.authenticityToken,
              meta: {correlationId: uuidv4()},
              data: {
                notableType: props.notableType,
                notableId: props.notableId,
                documentType: formElements.documentType.value,
                description: formElements.description.value,
                note
              }
            };
            const savedDoc = await documentService.saveDocument(formAction, params);
            if (savedDoc) {
              props.onDocumentSaved(savedDoc);
              return savedDoc;
            }
          })
        );

        props.toggleUploadModal();
      } catch (errorResponse) {
        const errorMessages = get(errorResponse, 'response.data.errors', []).map((error) => error.detail);
        setFileAddError(errorMessages.join('. '));
      } finally {
        setUploading(false);
        setNotes([]);
        setIsFileSelected(false);
      }
    }
  };

  return (
    <LegacyModal
      id="document_upload_modal"
      title="Upload New Document for this Shipment"
      show={props.showUploadModal}
      size="small"
      toggleModal={handleToggle}
    >
      <form
        className="clearfix row fileupload"
        id="upload_document"
        encType="multipart/form-data"
        action={props.documentsRoute}
        acceptCharset="UTF-8"
        method="post"
        aria-label="form"
        onSubmit={handleFormSubmit}
      >
        {fileAddError && <ErrorDisplay errorText={fileAddError} />}
        <div className="col-md-12">
          <div className="form-group">
            <label className="sr-only control-label" htmlFor="document_note">
              Note
            </label>
            <input
              name="note"
              data-testid="fileInputTestId"
              id="document_note"
              type="file"
              onChange={(event) => {
                const files = (event.target as HTMLInputElement).files;
                if (files) {
                  setNotes(map(files));
                  setIsFileSelected(true);
                }
              }}
              multiple={true}
              accept="application/pdf, image/jpeg, image/png, application/msword,
                             application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                             text/plain, application/octet-stream"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="control-label" htmlFor="document_type">
              Document Type:
            </label>
            <select className="form-control" name="documentType" id="document_type">
              {Object.keys(DocumentType).map((docType) => {
                return (
                  <option key={docType} value={DocumentType[docType]}>
                    {DocumentType[docType]}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="control-label" htmlFor="document_description">
              Description:
            </label>
            <input
              placeholder="Any additional notes for this document..."
              className="form-control"
              name="description"
              id="document_description"
              title="Any additional notes for this document..."
              type="text"
            />
          </div>
        </div>
        <div className="col-md-12">
          <button className="btn" id="filesubmit" disabled={uploading || !isFileSelected}>
            {uploading && <i className="fa fa-spinner fa-spin" style={{padding: 0}}></i>}
            <span style={{paddingLeft: '1rem'}}>Upload Document</span>
          </button>
        </div>
      </form>
    </LegacyModal>
  );
};

export default DocumentUploadModal;
