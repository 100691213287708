import {ApiResponse} from '../CommonInterfaces';
import * as WorkOrders from '../../work-orders/WorkOrdersInterfaces';
import {KitWorkOrderStartResponse, WipLocationInformation} from '../../work-orders/WorkOrdersInterfaces';
import {LocationContents} from '../../locations/LocationsService';
import InternalAPIService from './InternalAPIService';

class WorkOrdersService extends InternalAPIService {
  constructor(authenticityToken: string) {
    if (location.hostname === 'localhost') {
      super(authenticityToken, 'http://localhost:8080/api/v2/work-orders');
    } else {
      super(authenticityToken, '/api/v2/work-orders');
    }
  }

  public async getKitWorkOrders(): Promise<ApiResponse<WorkOrders.KitWorkOrder[]>> {
    return await this.makeGetRequest(`${this.baseUrl}/kit_work_orders`);
  }

  public async getAvailableKitWIPLocations(kitId: number): Promise<ApiResponse<WipLocationInformation[]>> {
    return await this.makeGetRequest(`${this.baseUrl}/kits/${kitId}/availableWipLocations`);
  }

  public async getKitWorkOrderDetails(id: number): Promise<ApiResponse<WorkOrders.KitWorkOrderDetail>> {
    return await this.makeGetRequest(`${this.baseUrl}/kit_work_order/${id}`);
  }

  public async createKitWorkOrder(
    kitWorkOrderCreationRequest: WorkOrders.KitWorkOrderCreationRequest
  ): Promise<WorkOrders.KitWorkOrderCreationResponse> {
    return await this.makeBasicPostRequest(`${this.baseUrl}/kit_work_order`, kitWorkOrderCreationRequest);
  }

  public async startKitWorkOrder(
    id: number,
    targetStatus: string
  ): Promise<ApiResponse<WorkOrders.KitWorkOrderStartResponse>> {
    // TODO: remove this hard-coded stuff and use the commented out line below
    // the theory as I understand it is that we send the work order ID (and target status???) to the endpoint
    // and get back the updated work order details (or not?)
    // This hardcoded list is completely made up
    const updatedKitWorkOrder = {
      meta: {
        responseCreatedAt: '',
        correlationId: ''
      },
      links: {
        self: ''
      },
      data: {
        workOrderId: 1,
        reservationId: 1234,
        shipper: {id: 1, name: 'Test Shipper'},
        warehouse: {id: 2, name: 'Test Warehouse'},
        completeBy: '10/10/2021',
        completedAt: '2021-10-07T15:33:41.263517',
        completedById: '1212',
        createdAt: '2021-10-03T15:33:41.263517',
        description: 'Test Kit',
        kitInventoryId: 1234,
        kitSku: 'Test Sku',
        productType: 'Kit to stock',
        quantityCompleted: 500,
        quantityRequested: 500,
        status: 'completed',
        unitOfMeasure: 'Eaches',
        instructions: 'Fragile'
      },
      errors: []
    };
    return (updatedKitWorkOrder as unknown) as ApiResponse<KitWorkOrderStartResponse>;
    // return await this.makePatchRequest(`${this.baseUrl}/kit_work_order/${id}/start`, targetStatus);
  }

  public async addCompletedKits(
    workOrderId: number,
    addKitsRequest: WorkOrders.AddCompletedKitsRequest
  ): Promise<ApiResponse<WorkOrders.AddCompletedKitsResponse>> {
    return await this.makeBasicPostRequest(
      `${this.baseUrl}/kit_work_order/${workOrderId}/complete_batch`,
      addKitsRequest
    );
  }

  public async getPossibleKitSkus(
    kitSkusRequest: WorkOrders.KitSkusRequest
  ): Promise<ApiResponse<WorkOrders.KitSku[]>> {
    return await this.makeGetRequest(`${this.baseUrl}/kits`);
  }

  public async completeKitWorkOrder(
    id: number,
    message: string
  ): Promise<ApiResponse<WorkOrders.KitWorkOrderCompleteResponse>> {
    return await this.makeBasicPostRequest(`${this.baseUrl}/kit_work_order/${id}/complete`, {message});
  }
}
export default WorkOrdersService;
