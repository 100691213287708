import * as React from 'react';
import {get} from 'lodash';
import objectToFormData from 'object-to-formdata';
import axios from 'axios';
import uuidv4 from 'uuid/v4';
import {LegacyModal} from '@flexe/ui-components';
import DocumentService from '../shared/services/DocumentsService';
import {CompanyType, Document, DocumentType} from './CommonInterfaces';

interface Props {
  documents: Document[];
  authenticityToken: string;
  documentsRoute: string;
  notableType: string;
  uploadButtonText: string;
  notableId: number;
  onDocumentSaved(savedDoc: Document);
  onDocumentDeleted(deletedDoc: Document);
}

interface State {
  showModal: boolean;
  showDeleteModal: boolean;
  note?: any;
  uploading: boolean;
  error: string;
  documentToDelete?: Document;
}

class DocumentManager extends React.Component<Props, State> {
  private documentService: DocumentService;

  constructor(props: Props) {
    super(props);

    this.documentService = new DocumentService(null);

    this.state = {
      showModal: false,
      showDeleteModal: false,
      note: null,
      uploading: false,
      error: '',
      documentToDelete: null
    };
  }

  public render() {
    const docToDelete = this.state.documentToDelete;
    return (
      <div className="document-manager">
        <a className="btn space-below" href="#" onClick={this.toggleModal}>
          {this.props.uploadButtonText}
        </a>

        {this.props.documents.length > 0 && (
          <table className="table delivery-documents">
            <thead>
              <tr>
                <th className="file">
                  File
                  <small>Only images, PDF, MS Word, MS Excel, CSV, tec, and text files are allowed</small>
                </th>
                <th className="uploader">Uploader</th>
                <th className="type">Type</th>
                <th className="notes">Notes</th>
                <th className="action"></th>
              </tr>
            </thead>
            <tbody>
              {this.props.documents.map((doc, idx) => {
                const fileName = get(doc, 'note.name');
                const fileUrl = get(doc, 'note.url');
                return (
                  <tr key={idx}>
                    <td>
                      <a href={fileUrl} target="_blank">
                        {fileName}
                      </a>
                    </td>
                    <td>
                      <p>{CompanyType[doc.uploader]}</p>
                    </td>
                    <td>
                      <p>{doc.documentType}</p>
                    </td>
                    <td>
                      <p>{doc.description}</p>
                    </td>
                    <td>
                      {doc.deletable && (
                        <a href="#" data-id={doc.id} onClick={this.handleDelete}>
                          Delete
                        </a>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <LegacyModal
          id="document_upload_modal"
          title="Upload New Document for this Shipment"
          show={this.state.showModal}
          size="small"
          toggleModal={this.toggleModal}
        >
          <form
            className="clearfix row fileupload"
            id="new_document"
            encType="multipart/form-data"
            action={this.props.documentsRoute}
            acceptCharset="UTF-8"
            method="post"
            onSubmit={this.handleFormSubmit}
          >
            <div className="col-md-12">
              <div className="form-group">
                <label className="sr-only control-label" htmlFor="document_note">
                  Note
                </label>
                <input
                  name="note"
                  id="document_note"
                  type="file"
                  onChange={this.handleFileAdded}
                  accept="application/pdf, image/jpeg, image/png, application/msword,
                             application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                             text/plain, application/octet-stream"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="control-label" htmlFor="document_type">
                  Document Type:
                </label>
                <select className="form-control" name="documentType" id="document_type">
                  {Object.keys(DocumentType).map((docType) => {
                    return (
                      <option key={docType} value={DocumentType[docType]}>
                        {DocumentType[docType]}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="control-label" htmlFor="document_description">
                  Description:
                </label>
                <input
                  placeholder="Any additional notes for this document..."
                  className="form-control"
                  name="description"
                  id="document_description"
                  title="Any additional notes for this document..."
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-12">
              <button className="btn" id="filesubmit" disabled={this.state.uploading}>
                {this.state.uploading && <i className="fa fa-spinner fa-spin" style={{padding: 0}}></i>}
                <span style={{paddingLeft: '1rem'}}>Upload Document</span>
              </button>
            </div>
          </form>
        </LegacyModal>

        {docToDelete && (
          <LegacyModal
            id="delete_document_modal"
            title="Delete this Document?"
            show={this.state.showDeleteModal}
            size="small"
            toggleModal={this.toggleDeleteModal}
            footer={
              <div>
                <a href="#" className="btn" onClick={this.toggleDeleteModal}>
                  No
                </a>
                <a href="#" className="btn" onClick={this.deleteDocument}>
                  Yes
                </a>
              </div>
            }
          >
            <dl>
              <dt>File:</dt>
              <dd>{docToDelete.note.name}</dd>
              <dt>UploaderM</dt>
              <dd>{CompanyType[docToDelete.uploader]}</dd>
              <dt>Type</dt>
              <dd>{docToDelete.documentType}</dd>
              <dt>Notes</dt>
              <dd>{docToDelete.description}</dd>
            </dl>
          </LegacyModal>
        )}
      </div>
    );
  }

  private handleFileAdded = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({note: file});
    }
  };

  private toggleModal = () => {
    this.setState({showModal: !this.state.showModal});
  };

  private toggleDeleteModal = () => {
    this.setState({showDeleteModal: !this.state.showDeleteModal});
  };

  private handleDelete = (event) => {
    event.preventDefault();
    const docId = parseInt(event.currentTarget.getAttribute('data-id'), 10);
    const documentToDelete = this.props.documents.find((doc) => {
      return doc.id === docId;
    });
    this.setState({documentToDelete, showDeleteModal: true});
  };

  private handleFormSubmit = (event) => {
    event.preventDefault();
    this.submitForm(event);
  };

  private async submitForm(event) {
    event.preventDefault();
    this.setState({uploading: true});
    const formAction = get(event, 'target.action');
    const formElements = get(event, 'target.elements');
    if (formElements) {
      try {
        if (!this.state.note) {
          throw new Error('You must select a file for uploading');
        }
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
        const params = {
          authenticity_token: this.props.authenticityToken,
          meta: {correlationId: uuidv4()},
          data: {
            notableType: this.props.notableType,
            notableId: this.props.notableId,
            documentType: formElements.documentType.value,
            description: formElements.description.value,
            note: this.state.note
          }
        };
        const savedDoc = await this.documentService.saveDocument(formAction, params);
        if (savedDoc) {
          this.props.onDocumentSaved(savedDoc);
        }
      } catch (error) {
        this.setState({error});
      } finally {
        this.setState({uploading: false});
        this.toggleModal();
      }
    }
  }

  private deleteDocument = async () => {
    try {
      const deletedDoc = await this.documentService.deleteDocument(
        this.props.documentsRoute,
        this.state.documentToDelete.id,
        this.props.authenticityToken
      );
      if (deletedDoc) {
        this.props.onDocumentDeleted(deletedDoc);
      }
    } catch (error) {
      this.setState({error});
    } finally {
      this.toggleDeleteModal();
    }
  };
}

export default DocumentManager;
