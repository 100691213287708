import * as React from 'react';
import {FunctionComponent} from 'react';
import {Filter, FilterType} from '../../shared/CommonInterfaces';
import DoWhenClickOutside from '../../shared/DoWhenClickOutside';

interface WaveBatchFiltersProps {
  filters: Filter[];
  filterChangeHandler: any;
}

const WaveBatchFilters: FunctionComponent<WaveBatchFiltersProps> = (props) => {
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const [showFilterDropdownOptions, setShowFilterDropdownOptions] = React.useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = React.useState<Filter>(null);
  const [typeAheadOptions, setTypeAheadOptions] = React.useState<JSX.Element>();
  const [inputValue, setInputValue] = React.useState<string>('');

  React.useEffect(() => {
    if (selectedFilter && selectedFilter.type === FilterType.TypeAhead) {
      props.filters.forEach((filter) => {
        if (filter.key === selectedFilter.key) {
          getTypeAheadOptions(filter);
        }
      });
    }
  }, [props.filters]);

  React.useEffect(() => {
    setShowFilterDropdownOptions(false);
    setTypeAheadOptions(null);
    setInputValue('');
  }, [selectedFilter]);

  const getDropwDownDisplay = () => {
    return selectedFilter ? (
      <div>
        {selectedFilter.displayName}
        <span className="caret" />
      </div>
    ) : (
      'Add Filter'
    );
  };
  const handleFilterSelect = (filter: Filter) => {
    setShowOptions(false);
    setSelectedFilter(filter);
    if (filter.type === FilterType.Present) {
      handleAddFilter(filter);
    }
  };
  const handleDropdownOptionSelect = (value: string | boolean) => {
    setShowFilterDropdownOptions(false);
    props.filterChangeHandler(selectedFilter.key, value);
    if (!selectedFilter.allowMultiple) {
      setSelectedFilter(null);
    }
  };

  const handleFilterInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleTypeAheadSelection = (value) => {
    setTypeAheadOptions(null);
    setInputValue('');
    selectedFilter.typeAheadHandler('');
    props.filterChangeHandler(selectedFilter.key, value);
    if (!selectedFilter.allowMultiple) {
      setSelectedFilter(null);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddFilter();
    }
  };
  const handleAddFilter = (filter: Filter = selectedFilter, value: string = inputValue) => {
    setInputValue('');
    props.filterChangeHandler(filter.key, value);
    if (!filter.allowMultiple || filter.type === FilterType.Present) {
      setSelectedFilter(null);
    }
  };

  const handleCloseInputFilter = () => {
    setInputValue('');
    setSelectedFilter(null);
  };

  const handleTypeAhead = async (event) => {
    const query = event.target.value;
    setInputValue(query);
    if (query.length > 2) {
      await selectedFilter.typeAheadHandler(query);
    } else {
      setTypeAheadOptions(null);
    }
  };

  const submitCancel = (
    <React.Fragment>
      <a className="submit-btn icon" data-testid="check-button" onClick={() => handleAddFilter()}>
        <i className="fa fa-check" aria-hidden="true"></i>
      </a>
      <a className="text-danger icon" data-testid="cancel-button" onClick={() => handleCloseInputFilter()}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </a>
    </React.Fragment>
  );

  const dropdown = selectedFilter && selectedFilter.type === FilterType.Dropdown && (
    <div className="wave-batch-filter-dropdown">
      <button
        className={`btn btn-default dropdown-toggle${showFilterDropdownOptions ? ' focus' : ''}`}
        type="button"
        id="filter-dropdown"
        data-testid="filter-dropdown"
        data-toggle="dropdown"
        onClick={() => handleClickDropdownButton()}
        aria-haspopup="true"
        aria-expanded="false"
      >
        {selectedFilter.placeHolder ? selectedFilter.placeHolder : 'Select One'}
        <span className="caret"></span>
      </button>
      {showFilterDropdownOptions && (
        <ul className="dropdown-menu show">
          {selectedFilter.options.map((option) => {
            return (
              <li key={`dropdown-value-${option.value}`}>
                <a
                  data-testid={`${option.value}-anchor`}
                  onClick={() => handleDropdownOptionSelect(option.value.toString())}
                >
                  {option.displayName}
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );

  const filterInput = selectedFilter && selectedFilter.type === FilterType.String && (
    <div className="filter-input-div">
      <input
        name={selectedFilter.key}
        className="form-control filter-input"
        type="text"
        data-testid="filter-input"
        onChange={(event) => handleFilterInputChange(event)}
        value={inputValue}
        onKeyPress={(event) => handleKeyPress(event)}
        placeholder={selectedFilter.placeHolder ? selectedFilter.placeHolder : `Enter ${selectedFilter.displayName}`}
      />
      {submitCancel}
    </div>
  );

  const getTypeAheadOptions = (filter: Filter = selectedFilter) => {
    setTypeAheadOptions(null);
    if (filter.options && filter.options.length > 0) {
      const typeAheads = (
        <ul className="dropdown-menu show">
          {filter.options.map((option, index) => {
            return (
              <li key={index}>
                <a onClick={() => handleTypeAheadSelection(option.value)}>{option.displayName}</a>
              </li>
            );
          })}
        </ul>
      );
      setTypeAheadOptions(typeAheads);
    }
  };

  const typeAheadInput = selectedFilter && selectedFilter.type === FilterType.TypeAhead && (
    <div className="filter-input-div">
      <input
        name={selectedFilter.key}
        className="form-control filter-input"
        type="text"
        data-testid="typeahead-input"
        value={inputValue}
        onChange={(event) => handleTypeAhead(event)}
        placeholder={selectedFilter.placeHolder ? selectedFilter.placeHolder : `Enter ${selectedFilter.displayName}`}
      />
      {typeAheadOptions}
    </div>
  );

  const dateInput = selectedFilter && selectedFilter.type === FilterType.Date && (
    <div className="filter-input-div">
      <input
        name={selectedFilter.key}
        value={inputValue}
        onChange={handleFilterInputChange}
        onKeyPress={handleKeyPress}
        className="form-control filter-input"
        type="date"
        data-testid="filter-input"
        date-format="yyyy-mm-dd"
      />
      {submitCancel}
    </div>
  );

  const handleClickFilterButton = () => {
    setShowOptions(!showOptions);
    setShowFilterDropdownOptions(false);
  };

  const handleClickDropdownButton = () => {
    setShowOptions(false);
    setShowFilterDropdownOptions(!showFilterDropdownOptions);
  };

  const handleClickOutside = () => {
    setShowOptions(false);
    setShowFilterDropdownOptions(false);
  };

  return (
    <DoWhenClickOutside callback={handleClickOutside}>
      <div className="batch-wave-filters btn-group">
        <button
          className={`btn ${selectedFilter ? ' btn-default' : ' btn-primary'} dropdown-toggle${
            showOptions ? ' focus' : ''
          }`}
          type="button"
          id="filters-dropdown"
          data-testid="filters-dropdown"
          data-toggle="dropdown"
          onClick={() => handleClickFilterButton()}
          aria-haspopup="true"
          aria-expanded="false"
        >
          {getDropwDownDisplay()}
        </button>
        {showOptions && (
          <ul data-testid="filters-list" className="dropdown-menu show">
            {props.filters.map((filter) => {
              return (
                <li key={`dropdown-filter-${filter.key}`}>
                  <a data-testid={`${filter.key}-anchor`} onClick={() => handleFilterSelect(filter)}>
                    {filter.displayName}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
        {dropdown}
        {filterInput}
        {typeAheadInput}
        {dateInput}
      </div>
    </DoWhenClickOutside>
  );
};

export default WaveBatchFilters;
