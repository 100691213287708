import {isEmpty} from 'lodash';
import {
  GetFreightLoadsResponse,
  UpdateFreightLoadRequest,
  UpdateFreightLoadStopRequest
} from '../../warehouse/outbound-freight/FreightInterfaces';
import {ApiResponse} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

class OutboundFreightLoadService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '');
  }

  public async getFreightLoads(freightLoadIds: number[]): Promise<ApiResponse<GetFreightLoadsResponse>> {
    const searchString = this.createSearchStringFromArray('', 'freightLoadIds', freightLoadIds);
    return await this.makeGetRequest('/api/v2/outbound/freight_loads/', {freightLoadIds});
  }

  public async updateFreightLoad(request: UpdateFreightLoadRequest): Promise<ApiResponse<UpdateFreightLoadRequest>> {
    return await this.makePatchRequest(`/api/v2/outbound/freight_loads/${request.freightLoadId}`, {
      generate_bol: request.generateBol,
      scac: request.scac,
      scheduled_ship_date: request.scheduledShipDate,
      trailer_number: request.trailerNumber
    });
  }

  public async updateFreightLoadStop(
    request: UpdateFreightLoadStopRequest
  ): Promise<ApiResponse<UpdateFreightLoadStopRequest>> {
    return await this.makePatchRequest(`/api/v2/outbound/freight_load_stops/${request.freightLoadStopId}`, {
      generate_bol: request.generateBol,
      pro_number: request.proNumber,
      seal_number: request.sealNumber
    });
  }

  public async enqueueBillOfLadingGeneration(loadId: string): Promise<ApiResponse<UpdateFreightLoadStopRequest>> {
    return await this.makePostRequest(`/api/v2/freight_loads/${loadId}/bills_of_lading`, {});
  }

  private createSearchStringFromArray(baseString: string, parameterName: string, array: any[]): string {
    if (isEmpty(array)) {
      return baseString;
    }

    for (const item of array) {
      if (item == null) {
        continue;
      }
      const prepend = isEmpty(baseString) ? '?' : '&';
      baseString = `${baseString}${prepend}${parameterName}[]=${item.toString()}`;
    }
    return baseString;
  }
}

export default OutboundFreightLoadService;
