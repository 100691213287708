import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

interface Props {
  listItems: {[key: string]: string[]};
  title?: string;
  italics?: boolean;
  inline?: boolean;
}

const HoverableList: React.FC<Props> = (props: Props) => {
  const listItemClasses = [props.inline && 'display-inline', props.italics && 'italics'];

  return (
    <div className="hoverable-list">
      {props.title && <div className="title">{props.title}</div>}
      <ul className="item-list">
        {Object.keys(props.listItems)
          .sort()
          .map((listItem, i) => {
            return (
              <li key={listItem} className={classNames(listItemClasses)} data-testid={`tooltip-item-${i}`}>
                <a data-tip data-for={listItem}>
                  {listItem}
                </a>
                {i === Object.keys(props.listItems).length - 1 ? '' : ', '}
                <ReactTooltip id={listItem} className="persistentTooltip" delayHide={200} place="left" effect="solid">
                  <ul className="tooltip-list">
                    {props.listItems[listItem].length > 0 ? (
                      <React.Fragment>
                        {props.listItems[listItem].sort().map((subItem, idx) => {
                          return (
                            <li key={idx} data-testid={`tooltip-sub-item-${idx}`}>
                              {subItem}
                            </li>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </ReactTooltip>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default HoverableList;
