import * as React from 'react';
import {Column} from '@flexe/ui-components';
import {MobileUser, MobileWarehouse} from '../userManagementInterfaces';
import MobileUsersSection from './MobileUsersSection';
import MobileWarehouses from './MobileWarehouses';

interface Props {
  authenticityToken: string;
  isVisible: boolean;
  users: MobileUser[];
  warehouses: MobileWarehouse[];
  onDeleteMobileUserButtonPressAsync: (userId: number) => Promise<void>;
  onRemoveMobileUserFromWarehouseAsync: (userId: number, warehouseId: number) => Promise<void>;
}

const MobileUsers = ({
  authenticityToken,
  isVisible,
  users,
  warehouses,
  onDeleteMobileUserButtonPressAsync,
  onRemoveMobileUserFromWarehouseAsync
}: Props) => {
  return !isVisible ? null : (
    <Column>
      <MobileUsersSection onDeleteMobileUserButtonPressAsync={onDeleteMobileUserButtonPressAsync} users={users} />

      {warehouses.length !== 0 && (
        <MobileWarehouses
          authenticityToken={authenticityToken}
          onRemoveMobileUserFromWarehouseAsync={onRemoveMobileUserFromWarehouseAsync}
          users={users}
          warehouses={warehouses}
        />
      )}
    </Column>
  );
};

export default MobileUsers;
