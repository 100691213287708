import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {ImportCsvDropdownOption, ModalAction} from '../shared/ItemMasterInterfaces';
import ImportCsvDropdown from '../ImportCsvDropdown';
import UpsertInventoryModal from '../shared/UpsertInventoryModal';

interface Props {
  titleText: string;
  reservationName: string;
  reservationId: string | number;
  authenticityToken: string;
  showImsAlternateId: boolean;
  useReleaseDate: boolean;
  useSerialNumberProgram: boolean;
}

const ModalLauncher: FC<Props> = (props) => {
  const [selectedOption, setSelectedOption] = useState<ImportCsvDropdownOption>({name: '', value: ''});
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  useEffect(() => {
    renderCorrectModal(selectedOption.value);
  }, [selectedOption]);

  const renderCorrectModal = (uploadOption) => {
    if (uploadOption === props.reservationId.toString()) {
      setShowUpdateModal(true);
    }
  };

  const toggleUpdateModal = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const handleSelect = (opt) => {
    setSelectedOption(opt);
  };

  const options: ImportCsvDropdownOption[] = [{name: props.reservationName, value: props.reservationId.toString()}];

  return (
    <>
      <ImportCsvDropdown
        titleText={props.titleText}
        options={options}
        selected={selectedOption}
        onSelect={handleSelect}
      />
      <UpsertInventoryModal
        isShipper={false}
        action={ModalAction.Update}
        reservationId={props.reservationId.toString()}
        authenticityToken={props.authenticityToken}
        isOpen={showUpdateModal}
        toggleModal={toggleUpdateModal}
        showImsAlternateId={props.showImsAlternateId}
        useReleaseDate={props.useReleaseDate}
        useSerialNumberProgram={props.useSerialNumberProgram}
      />
    </>
  );
};

export default ModalLauncher;
