import * as React from 'react';
import WorkOrdersService from '../shared/services/WorkOrdersService';
import FlexeButton from '../shared/FlexeButton';
import NewWorkOrderModal from './NewWorkOrderModal';
import {KitWorkOrder, KitWorkOrderDetail, WorkOrderReservation} from './WorkOrdersInterfaces';
import CancelRemainingModal from './CancelRemainingModal';
import {translateWorkOrderStatus} from './WorkOrdersHelper';

//the props list could grow along with the child components within it
interface Props {
  isShipper: boolean;
  isDetailsHeader: boolean;
  companyId?: number;
  enableWorkOrders: boolean;
  workOrdersService?: WorkOrdersService;
  reservations?: WorkOrderReservation[];
  toggleNewWorkOrderModal?: () => void;
  showNewWorkOrderModal: boolean;
  kitWorkOrder?: KitWorkOrder;
  kitWorkOrderDetail?: KitWorkOrderDetail;
}

const WorkOrdersPageHeader: React.FC<Props> = (props) => {
  const [showCancelRemainingKitsModal, setshowCancelRemainingKitsModal] = React.useState<boolean>(false);

  const onCancelRemainingKitsClicked = () => {
    setshowCancelRemainingKitsModal(!showCancelRemainingKitsModal);
  };

  const renderNewWorkOrderButton = () => {
    return (
      <div className="pull-right">
        <FlexeButton
          text={
            <span>
              <i className="fa fa-plus" /> New Work Order
            </span>
          }
          handleClick={props.toggleNewWorkOrderModal}
          testid="new-work-order-btn"
        />
      </div>
    );
  };

  const renderWorkOrderDetailButtons = () => {
    //render all the buttons on the right top for the warehouse within this function
    return (
      <div>
        <div className="pull-right">
          <FlexeButton
            level="primary"
            text={<span>Cancel Remaining</span>}
            handleClick={onCancelRemainingKitsClicked}
            testid="mark-complete-button"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="row work-order-header">
      <div className="col-md-12 space-below-lg">
        <div className="row">
          <div className="col-md-6">
            {props.isDetailsHeader && !props.isShipper && (
              <span>
                <a href="/wh/work_orders">{'Work Orders '}</a> {`/ Work Order: ${props.kitWorkOrderDetail.workOrderId}`}
              </span>
            )}
            <h1 data-testid="page-title">
              {props.isDetailsHeader && !props.isShipper
                ? `Work Order ${props.kitWorkOrderDetail.workOrderId}`
                : 'Work Orders'}
            </h1>
            {props.isDetailsHeader && !props.isShipper && (
              <p>
                <b>Status:</b> {translateWorkOrderStatus(props.kitWorkOrderDetail.status)}
              </p>
            )}
          </div>
          {!props.isShipper &&
            props.kitWorkOrderDetail &&
            props.isDetailsHeader &&
            props.kitWorkOrderDetail.status === 'inProgress' && (
              // this url can be wh specific b/c shippers do not print docs
              <a
                href={`/wh/work_orders/instructions/${props.kitWorkOrderDetail.workOrderId}`}
                target="_blank"
                id="btn-print-work-order-docs"
                data-testid="work-order-print-anchor"
              >
                TEST PRINT <i className="fa fa-lg fa-print"></i>
              </a>
            )}
          <div className="col-md-6">{props.isShipper && !props.isDetailsHeader && renderNewWorkOrderButton()}</div>
          <div className="col-md-6">
            {!props.isShipper &&
              props.isDetailsHeader &&
              props.kitWorkOrderDetail &&
              props.kitWorkOrderDetail.status === 'inProgress' &&
              renderWorkOrderDetailButtons()}
          </div>
        </div>
      </div>
      <NewWorkOrderModal
        workOrdersService={props.workOrdersService}
        companyId={props.companyId}
        reservations={props.reservations}
        showNewWorkOrderModal={props.showNewWorkOrderModal}
        toggleNewWorkOrderModal={props.toggleNewWorkOrderModal}
      />
      <CancelRemainingModal
        visible={showCancelRemainingKitsModal}
        workOrderId={props.kitWorkOrderDetail ? props.kitWorkOrderDetail.workOrderId : -1}
        toggleModal={onCancelRemainingKitsClicked}
        workOrdersService={props.workOrdersService}
      />
    </div>
  );
};

export default WorkOrdersPageHeader;
