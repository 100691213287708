import * as React from 'react';
import {ReservationActivity} from './DashboardInterfaces';

interface Props {
  reservationActivities: ReservationActivity[];
}

const OverflowActivity: React.FC<Props> = (props) => {
  const {reservationActivities} = props;

  return (
    <div className="row space-above">
      <div className="col-sm-12">
        <h2>Inbound/Outbound Activity</h2>
      </div>
      <div className="col-sm-6">
        <div className="card col-sm-12">
          <div className="row">
            Active Inbounds
            <ul className="no-padding space-above">
              {reservationActivities &&
                reservationActivities.length > 0 &&
                reservationActivities.map((a, i) => {
                  const {reservation, dropoffs} = a;
                  return (
                    <li key={i}>
                      <a
                        href={`/s/dropoffs?reservation_id=${reservation.id}`}
                        className={`space-below ${dropoffs.palletized + dropoffs.container < 1 ? 'ghost' : ''}`}
                      >
                        <span>
                          <b className="black">
                            {dropoffs.palletized} Palletized Shipments, {dropoffs.container} Containers
                          </b>
                          <br />
                          <br />
                          <span className="grey3">{reservation.name}</span>
                        </span>
                        <i className="fa fa-lg fa-angle-right pull-right"></i>
                      </a>
                      <hr />
                    </li>
                  );
                })}
              {!reservationActivities ||
                (reservationActivities.length < 1 && <li>You currently have no inbound activity.</li>)}
            </ul>
            <a href="/s/dropoffs" className="pull-right">
              All Deliveries
            </a>
          </div>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="card col-sm-12">
          <div className="row">
            Active Outbounds
            <ul className="no-padding space-above">
              {reservationActivities &&
                reservationActivities.length > 0 &&
                reservationActivities.map((a, i) => {
                  const {reservation, pickups} = a;
                  return (
                    <li key={i}>
                      <a
                        href={`/s/pickups?reservation_id=${reservation.id}`}
                        className={`space-below ${pickups.palletized < 1 ? 'ghost' : ''}`}
                      >
                        <span>
                          <b className="black">{pickups.palletized} Palletized Shipments</b>
                          <br />
                          <br />
                          <span className="grey3">{reservation.name}</span>
                        </span>
                        <i className="fa fa-lg fa-angle-right pull-right"></i>
                      </a>
                      <hr />
                    </li>
                  );
                })}
              {!reservationActivities ||
                (reservationActivities.length < 1 && <li>You currently have no outbound activity.</li>)}
            </ul>
            <a href="/s/pickups" className="pull-right">
              All Pickups
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverflowActivity;
