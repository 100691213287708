import axios from 'axios';
import InternalAPIServiceV2, {InternalAPIServiceV2Options} from '../../shared/services/InternalAPIServiceV2';

const mime = require('mime-types'); // eslint-disable-line

export interface FileMetadata {
  key: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  contentType: string;
  size: number;
}

class FileStorageService extends InternalAPIServiceV2 {
  constructor() {
    super('/v2/file-storage/');
  }

  public async uploadFile(file, key: string, options?: InternalAPIServiceV2Options) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.putRequest(`${this.baseUrl}${key}`, formData, options);
  }

  public async downloadByFileKey(filkey: string, params = {}, headers = {}) {
    return await this.makeGetRequest(`${this.baseUrl}content/${filkey}`, params, null, headers);
  }

  public downloadFile(fileMetaData: FileMetadata) {
    axios({
      url: `${this.baseUrl}content/${fileMetaData.key}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': fileMetaData.contentType,
        'Content-Length': fileMetaData.size
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const extension = this.getFileExtension(fileMetaData.key, fileMetaData.contentType);
      link.href = url;
      link.setAttribute('download', `${fileMetaData.key}${extension}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  public async getFileMetadata(fileKey: string): Promise<FileMetadata> {
    return await this.makeGetRequest(`${this.baseUrl}${fileKey}`);
  }

  public getFileExtension(fileKey: string, contentType: string) {
    if (
      !fileKey.includes('.json') &&
      !fileKey.includes('.xml') &&
      !fileKey.includes('.pdf') &&
      !fileKey.includes('.csv')
    ) {
      if (contentType !== 'application/octet-stream' && mime.extension(contentType)) {
        return `.${mime.extension(contentType)}`;
      } else {
        // This includes application/octet-stream defaults to .txt
        return '.txt';
      }
    } else {
      return '';
    }
  }
}

export default FileStorageService;
