import * as React from 'react';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {TransitionState} from './CommonInterfaces';

interface ProgressBarProps {
  status: TransitionState;
  total?: number;
  progress?: number;
  backgroundColor: string;
  progressColor: string;
  notStartedColor?: string;
  displayStatusText: boolean;
  progressBarDecoration?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  status,
  total,
  progress,
  backgroundColor,
  progressColor,
  notStartedColor = '#95A3B8',
  displayStatusText,
  progressBarDecoration = false
}) => {
  const completedPercentage =
    status === TransitionState.NotStarted ? 0 : Math.floor(((progress ? progress : 0) * 100) / (total ? total : 1));
  const completedPercentageText = `${completedPercentage}% Complete`;
  return (
    <div className="progress">
      {status === TransitionState.NotStarted && (
        <div className="progress-bar" style={{width: '100%', backgroundColor: notStartedColor}}>
          {displayStatusText && <span className="completed-status-text">{completedPercentageText}</span>}
        </div>
      )}
      {(status === TransitionState.Completed || status === TransitionState.InProgress) && (
        <>
          <div className="progress-bar" style={{width: `${completedPercentage}%`, backgroundColor: `${progressColor}`}}>
            {progressBarDecoration && (
              <KeyboardDoubleArrowRightIcon style={{float: 'right', width: 'auto', height: '100%'}} />
            )}
          </div>
          <div
            className="progress-bar"
            style={{width: `${100 - completedPercentage}%`, backgroundColor: `${backgroundColor}`}}
          >
            {displayStatusText && <span className="completed-status-text">{completedPercentageText}</span>}
          </div>
        </>
      )}
    </div>
  );
};
export default ProgressBar;
