import * as React from 'react';
import {FunctionComponent} from 'react';
import FormModal from '../shared/FormModal';
import WorkOrdersService from '../shared/services/WorkOrdersService';
import FlexeButton from '../shared/FlexeButton';
import {WorkOrderReservation} from './WorkOrdersInterfaces';
import NewWorkOrderForm from './NewWorkOrderForm';

interface PageErrorObj {
  createError: string;
}

interface Props {
  workOrdersService: WorkOrdersService;
  companyId: number;
  reservations: WorkOrderReservation[];
  showNewWorkOrderModal: boolean;
  toggleNewWorkOrderModal: () => void;
}

const NewWorkOrderModal: FunctionComponent<Props> = (props) => {
  const [showPreviewView, setShowPreviewView] = React.useState<boolean>(false);
  const [pageErrors, setPageErrors] = React.useState<PageErrorObj>(null);
  const [kitToBuild, setKitToBuild] = React.useState<string>('');

  const togglePreviewView = () => {
    setShowPreviewView(!showPreviewView);
  };

  const wrappedSetPageErrors = (arg) => {
    setPageErrors(arg);
  };

  const wrappedSetKitToBuild = (arg) => {
    setKitToBuild(arg);
  };

  const closeModal = () => {
    if (showPreviewView) {
      togglePreviewView();
    }
    props.toggleNewWorkOrderModal();
  };

  const goBack = () => {
    setPageErrors(null);
    togglePreviewView();
  };

  return (
    <FormModal
      wrapperClass="new-work-order-modal"
      id="new-work-order-modal"
      title={
        showPreviewView ? (
          <span className="preview-view-title">
            <span className="title-col-btn">
              <FlexeButton
                testid="back-button-bottom"
                level="collapsed"
                text={
                  <span>
                    <i className="fa fa-arrow-left"></i>
                  </span>
                }
                handleClick={goBack}
              />
            </span>
            <span className="title-col-text">
              <span className="preview-title">Review and Submit</span>
              <span className="preview-subtitle">Work Order: {kitToBuild}</span>
            </span>
          </span>
        ) : (
          'New Work Order'
        )
      }
      show={props.showNewWorkOrderModal}
      size="medium"
      toggleModal={closeModal}
      disableClose={false}
    >
      <div data-testid="new-work-order-modal-content" className="new-work-order-modal-content">
        <NewWorkOrderForm
          workOrdersService={props.workOrdersService}
          reservations={props.reservations}
          companyId={props.companyId}
          showPreviewView={showPreviewView}
          setPageErrors={wrappedSetPageErrors}
          pageErrors={pageErrors}
          toggleFormModal={props.toggleNewWorkOrderModal}
          setKitToBuild={wrappedSetKitToBuild}
          togglePreviewView={togglePreviewView}
        />
      </div>
    </FormModal>
  );
};

export default NewWorkOrderModal;
