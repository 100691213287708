import * as React from 'react';

export interface FlexeContextValues {
  authenticityToken: string;
}

const flexeContextValues: FlexeContextValues = {
  authenticityToken: ''
};

const FlexeContext = React.createContext(
  flexeContextValues // default value
);

export default FlexeContext;
