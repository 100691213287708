import * as React from 'react';
import {FunctionComponent} from 'react';
import classNames from 'classnames';

interface Props {
  title?: string;
  disabled?: boolean;
  text: string;
  showAsButton?: boolean;
  testId?: string;
  variant?: 'primary';
}

interface FileDownloadPropsWithHref extends Props {
  href: string;
  onClick?: () => void;
}

interface FileDownloadPropsWithOnClick extends Props {
  href?: string;
  onClick: () => void;
}

const FileDownloadLink: FunctionComponent<FileDownloadPropsWithHref | FileDownloadPropsWithOnClick> = ({
  title,
  disabled,
  href,
  onClick,
  showAsButton,
  testId,
  text,
  variant
}) => {
  const classes = [
    'file-download-link',
    showAsButton && 'show-as-button',
    showAsButton && variant,
    disabled && 'disabled'
  ];

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <a
      title={title}
      className={classNames(classes)}
      data-testid={testId}
      onClick={handleClick}
      href={href}
      download={href !== undefined}
      role={onClick ? 'button' : undefined}
      // An anchor without a href attribute isn't tabbable by default, this makes it tabbable
      tabIndex={!href && !disabled ? 0 : undefined}
    >
      <i className="fa fa-download download-icon" aria-hidden="true" />
      {text}
    </a>
  );
};

export default FileDownloadLink;
