export const translateWorkOrderStatus = (originalStatus: string) => {
  switch (originalStatus) {
    case 'new':
      return 'New';
    case 'inProgress':
      return 'In Progress';
    case 'canceled':
      return 'Canceled';
    case 'completed':
      return 'Completed';
    default:
      return 'N/A';
  }
};
