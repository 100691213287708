import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';

interface Props {
  specialInstructions: string;
  processing: boolean;
  canComplete: boolean;
  onSpecialInstructionsInput(instructions: string);
  onComplete();
}

const Complete: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <li className="active" id="step_5">
        <span className="label">5</span>
        <div className="step-wrap">
          <h3>
            <span>Confirm &amp; Schedule Shipment</span>
            <a className="help-note" data-tip="Check that the information above is correct before submitting.">
              <i className="fa fa-lg fa-question-circle" />
            </a>
            <ReactTooltip place="right" />
          </h3>
          <div className="form-group">
            <textarea
              name="additionalNotes"
              placeholder="Additional notes..."
              title="Additional Notes"
              data-testid="additional-notes-input"
              value={props.specialInstructions || ''}
              onChange={(event) => props.onSpecialInstructionsInput(event.target.value)}
            />
          </div>
          <div className="submit-wrap">
            <input
              className="btn"
              disabled={!props.canComplete || props.processing}
              onClick={props.onComplete}
              type="submit"
              name="commit"
              data-testid="complete-btn"
              value={props.processing ? 'Processing...' : 'Confirm and Schedule'}
            />
            <a className="btn cancel" href="/s/fulfillment/retail">
              Cancel
            </a>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

export default Complete;
