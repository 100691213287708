import {AxiosResponse} from 'axios';
import {ApiResponseV2, ResponseErrorV2} from '../../shared/CommonInterfaces';
import InternalAPIServiceV2, {InternalAPIServiceV2Options} from '../../shared/services/InternalAPIServiceV2';
import {FulfillmentEventType} from '../dashboard/DashboardInterfaces';
import {
  AllocationErrorsResponse,
  CancelOrderLineResponse,
  CreateOrderAttachmentRequest,
  CreateOrderLineRequest,
  CreateOrderRequest,
  EditOrderResponse,
  OrderAttachmentErrorsResponse,
  OrderAttachmentsResponse,
  OrderLine,
  OrderLineReceiptsResponse,
  OrderLinesResponse,
  OrderLineStatus,
  OrdersHistogramResponse,
  OutboundOrder,
  OutboundOrdersResponse,
  ReleaseForPickingResponse,
  UpdateLineParams,
  UpdateLineResponse,
  UpdateOrderParams
} from './OutboundOrdersInterfaces';

class OutboundOrdersService extends InternalAPIServiceV2 {
  private static computeOrderLineStatus(line: OrderLine): OrderLineStatus {
    if (line.cancelledAt) {
      return line.shippedQuantity > 0 || line.allocatedQuantity > 0
        ? OrderLineStatus.partiallyCancelled
        : OrderLineStatus.cancelled;
    }

    if (line.shippedQuantity > line.quantity) {
      return OrderLineStatus.overshipped;
    }

    if (line.shippedQuantity === line.quantity) {
      return OrderLineStatus.shipped;
    }

    if (line.shippedQuantity > 0 && line.shippedQuantity < line.quantity) {
      return OrderLineStatus.partiallyShipped;
    }

    if (line.completelyShippedAt && line.shippedQuantity === 0) {
      return OrderLineStatus.noUnitsShipped;
    }

    if (line.allocatedQuantity > 0) {
      return line.allocatedQuantity < line.quantity ? OrderLineStatus.partiallyAllocated : OrderLineStatus.allocated;
    }

    return OrderLineStatus.unallocated;
  }

  constructor() {
    super('/v2/outbound/orders/');
  }

  public async getOrdersErrors(
    state: string,
    filters: any = {},
    continuationToken: string = null,
    pageSize: number = 50,
    orderId: string,
    options?: InternalAPIServiceV2Options
  ): Promise<OutboundOrdersResponse> {
    const params = {state, ...filters, continuationToken, pageSize};
    if (orderId) {
      params.ids = [orderId];
    }
    params.hasErrors = 'true';
    return await this.makeGetRequest(`${this.baseUrl}`, params, null, null, options);
  }

  public async getOrders(
    filters: any = {},
    continuationToken: string = null,
    pageSize: number = 50,
    orderId?: string,
    options?: InternalAPIServiceV2Options
  ): Promise<OutboundOrdersResponse> {
    const params = {...filters, continuationToken, pageSize};
    if (orderId) {
      params.ids = [orderId];
    }
    return await this.makeGetRequest(`${this.baseUrl}`, params, null, null, options);
  }

  public async getOrdersWithState(
    state: string,
    filters: any = {},
    continuationToken: string = null,
    pageSize: number = 50,
    orderId: string,
    options?: InternalAPIServiceV2Options
  ): Promise<OutboundOrdersResponse> {
    const data = {state, ...filters, continuationToken, pageSize};
    if (orderId) {
      data.ids = [orderId];
    }
    return await this.makeGetRequest(`${this.baseUrl}`, data, null, null, options);
    // return Promise.resolve({
    //   continuationToken: 'token',
    //   total: 1,
    //   outboundOrders: [
    //     {
    //       id: '111',
    //       externalId: 'exID1',
    //       hasErrors: false,
    //       state: OrderStatus.open,
    //       recipient: {
    //         name: 'John Doe',
    //         phone: '5555555555',
    //         email: 'john@example.com',
    //         address: {
    //           line1: '123 Main St',
    //           line2: 'Suite 500',
    //           city: 'New York',
    //           locality: 'New York',
    //           region: 'NY',
    //           postcode: '10001',
    //           country: 'US'
    //         }
    //       },
    //       shipping: {
    //         instructions: 'Do it to it. Pronto. Now.',
    //         serviceType: 'UPS Next Day',
    //         carrierBillingAccountId: '1',
    //         signatureConfirmation: null,
    //         labelReference1: '123456789',
    //         labelReference2: '987654321'
    //       },
    //       shipAfter: '2019-08-10T19:38:06.371Z',
    //       shipBefore: '2019-08-12T19:38:06.371Z',
    //       createdAt: '2019-06-06T19:38:06.371Z',
    //       createdBy: {
    //         type: 'User',
    //         id: 1,
    //         name: 'Jake Snake'
    //       },
    //       completedAt: null,
    //       completedBy: null
    //     }
    //   ]
    // } );
  }

  public async getOutboundOrder(orderId: string): Promise<OutboundOrder> {
    return await this.makeGetRequest(`${this.baseUrl}${orderId}`);
  }

  public async getOrderErrors(
    orderId: string,
    continuationToken: string = null,
    pageSize: number = 50
  ): Promise<AllocationErrorsResponse> {
    const data = {continuationToken, pageSize};
    return await this.makeGetRequest(`${this.baseUrl}${orderId}/errors`);
    // leaving stubs here until we figure out proxy for local dev
    //
    // return {
    //   continuationToken: 'token',
    //   total: 1,
    //   allocationErrors: [
    //     {
    //       id: '1',
    //       createdAt: new Date().toISOString(),
    //       code: 'some_code',
    //       details: 'a more detailed error message'
    //     },
    //     {
    //       id: '2',
    //       createdAt: new Date().toISOString(),
    //       code: 'another_code',
    //       details: 'another displayable error message'
    //     },
    //   ]
    // };
  }

  public async createOrder(params: CreateOrderRequest): Promise<AxiosResponse<ApiResponseV2>> {
    return await this.makePostOrderRequest(`${this.baseUrl}`, {...params});
  }

  public async createOrderLine(orderId: number, params: CreateOrderLineRequest): Promise<AxiosResponse<ApiResponseV2>> {
    return await this.makePostOrderRequest(`${this.baseUrl}${orderId}/lines`, {...params});
  }

  public async editOrder(orderId: string, params: UpdateOrderParams): Promise<EditOrderResponse> {
    return await this.makePutRequest(`${this.baseUrl}${orderId}`, {...params});
  }

  public async updateOrderLine(orderId: string, lineId: string, params: UpdateLineParams): Promise<UpdateLineResponse> {
    return await this.makePutRequest(`${this.baseUrl}${orderId}/lines/${lineId}`, {...params});
  }

  public async releaseForPicking(orderId: string): Promise<ReleaseForPickingResponse> {
    return await this.makePostRequest(`${this.baseUrl}${orderId}/releaseForPicking`, null);
  }

  public async getLines(
    filters: any = {},
    continuationToken: string = null,
    pageSize: number = 50,
    orderId: string = null,
    options?: InternalAPIServiceV2Options
  ): Promise<OrderLinesResponse> {
    const params = {...filters, continuationToken, pageSize};
    // if orderId is provided as a path param, expect to retrieve ONLY lines for that orderId.
    // if filtering for multiple order ids, use query params.

    return await this.makeGetRequest(`${this.baseUrl}${orderId || '-'}/lines`, params, null, null, options);
    // leaving stubs here until we figure out proxy for local dev
    //
    // return {
    //   continuationToken: 'token',
    //   total: 1,
    //   lines: [
    //     {
    //       id: '1234',
    //       externalId: '999XYZ',
    //       state: OrderLineStatus.unallocated,
    //       hasErrors: false,
    //       orderId: '111',
    //       shipmentIds: ['3167', '3168', '3169'],
    //       recipient: {
    //         name: 'John Doe',
    //         phone: '5555555555',
    //         email: 'john@example.com',
    //         address: {
    //           line1: '123 Main St',
    //           line2: 'Suite 500',
    //           locality: 'New York',
    //           city: 'New York',
    //           region: 'NY',
    //           postcode: '10001',
    //           country: 'US'
    //         }
    //       },
    //       shipping: {
    //         instructions: 'Do it to it. Pronto. Now.',
    //         serviceType: 'UPS Next Day',
    //         carrierBillingAccountId: '1',
    //         signatureConfirmation: 'adult',
    //         labelReference1: '123456789',
    //         labelReference2: '987654321'
    //       },
    //       sku: 'SKU-ABC',
    //       quantity: 2,
    //       createdAt: '2019-06-06T19:38:06.371Z',
    //       createdBy: {
    //         type: 'User',
    //         id: 1,
    //         name: 'Jake Snake'
    //       },
    //       completelyShippedAt: null,
    //       percentAllocated: 100,
    //       percentShipped: 0,
    //       allocatedQuantity: 100,
    //       shippedQuantity: 0,
    //     }
    //   ]
    // };
  }

  public async getOrderAttachments(orderId: string): Promise<OrderAttachmentsResponse> {
    return await this.makeGetRequest(`${this.baseUrl}${orderId}/attachments?showCancelled=false`);
  }

  public async createOrderAttachment(orderId: string, createAttachmentRequest: CreateOrderAttachmentRequest) {
    return await this.postRequest(`${this.baseUrl}${orderId}/attachments`, {...createAttachmentRequest});
  }

  public async deleteAttachment(orderId: string, attachmentId: string): Promise<OrderAttachmentErrorsResponse> {
    return await this.makePostRequest(`${this.baseUrl}${orderId}/attachments/${attachmentId}/cancel`, null);
  }

  public async getLineReceipts(
    orderId: string,
    lineId: string,
    continuationToken: string = null,
    pageSize: number = 50,
    filters: any = {}
  ): Promise<OrderLineReceiptsResponse> {
    const data = {...filters, continuationToken, pageSize, showCancelled: true};
    return await this.makeGetRequest(`${this.baseUrl}${orderId}/lines/${lineId}/receipts`, data);
  }

  public async getLineErrors(
    orderId: string,
    lineId: string,
    continuationToken: string = null,
    pageSize: number = 50
  ): Promise<AllocationErrorsResponse> {
    const data = {continuationToken, pageSize};
    return await this.makeGetRequest(`${this.baseUrl}${orderId}/lines/${lineId}/errors`, data);
    // leaving stubs here until we figure out proxy for local dev
    //
    // return {
    //   continuationToken: 'token',
    //   total: 1,
    //   allocationErrors: [
    //     {
    //       id: '1',
    //       createdAt: new Date().toISOString(),
    //       code: 'some_code',
    //       details: 'a more detailed error message'
    //     },
    //     {
    //       id: '2',
    //       createdAt: new Date().toISOString(),
    //       code: 'another_code',
    //       details: 'another displayable error message'
    //     },
    //   ]
    // };
  }

  public async cancelOrderLine(
    orderId: string,
    lineId: string,
    options?: InternalAPIServiceV2Options
  ): Promise<CancelOrderLineResponse> {
    return await this.makePostRequest(`${this.baseUrl}${orderId}/lines/${lineId}/cancel`, {}, null, options);
  }

  public async getHistograms(
    rangeEnd: string,
    width: number,
    numBins: number,
    type: FulfillmentEventType,
    options?: InternalAPIServiceV2Options
  ): Promise<OrdersHistogramResponse> {
    const data = {rangeEnd, width, numBins, type};
    return await this.makeGetRequest(`${this.baseUrl}histograms`, data, null, null, options);
  }

  public processErrors = (errors: ResponseErrorV2[]) => {
    return errors.map((error) => error.detail);
  };

  public assignOrderLineState(lines: OrderLine[]) {
    lines.forEach((l) => (l.state = OutboundOrdersService.computeOrderLineStatus(l)));
    return lines;
  }

  public isFileToggleOn(): boolean {
    return window.localStorage.getItem('createNewEcommerceButton') === 'true';
  }

  public isAdmin(): boolean {
    return window.localStorage.getItem('adminSignedIn') === 'true';
  }
}

export default OutboundOrdersService;
