import {Filter} from '../CommonInterfaces';

export interface ShipmentPackaging {
  id: number;
  height: number;
  length: number;
  width: number;
  boxType: 'box' | 'package' | 'envelope';
  weight?: number;
  description?: string;
  barcode?: string;
  reservationId?: number;
}

export interface ShipmentPackagingServiceInterface {
  getShipmentPackagings(filters?: Filter[]): Promise<{errors: string[]; shipmentPackagings: ShipmentPackaging[]}>;
  createPackaging(packaging: ShipmentPackaging): Promise<{errors: string[]}>;
  updatePackaging(packaging: ShipmentPackaging): Promise<{errors: string[]}>;
  deletePackaging(packaging: ShipmentPackaging): Promise<{errors: string[]}>;
}
export enum ShipmentPackagingField {
  id = 'id',
  height = 'height',
  length = 'length',
  width = 'width',
  weight = 'weight',
  boxType = 'boxType',
  description = 'description',
  barcode = 'barcode',
  reservationId = 'reservationId'
}
