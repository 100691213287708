import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {useEffect, useState} from 'react';
import WarehouseService from '../../shared/services/WarehouseService';
import BatchWavingService from '../../shared/services/BatchWavingService';
import ShipmentService from '../../shared/services/ShipmentService';
import DocumentsService from '../../shared/services/DocumentsService';
import WaveTemplateService from '../../shared/services/WaveTemplateService';
import {CompanyType, Warehouse} from '../../shared/CommonInterfaces';
import FlexeContext, {FlexeContextValues} from '../../contexts/FlexeContext';
import NewWaveTemplateForm from '../batch-waving/new-wave-template-form/NewWaveTemplateForm';
import ShippingLabelService from '../../shared/services/ShippingLabelService';
import AsyncWaving from '../batch-waving/AsyncWaving';
import BatchDetail from './BatchDetail';
import CompactBatchList from './CompactBatchList';

interface BatchesProps {
  authenticityToken: string;
  carriers: object;
  currentWarehouse: Warehouse;
  warehouses: Warehouse[];
  reservationToSortationEnabled: object;
  reservationToPickToCarton: object;
  hasWhManagerBillingPerms: boolean;
  isFreightWorkflowEnabled: boolean;
  isTrailerLoadingEnabled: boolean;
  highVolumeWavingMap: object;
  whCancelShipmentEnabled: boolean;
}

function Batches(props: BatchesProps) {
  const warehouseService = new WarehouseService(props.authenticityToken);
  const batchService = new BatchWavingService(props.authenticityToken);
  const shipmentService = new ShipmentService(props.authenticityToken);
  const documentsService = new DocumentsService(CompanyType.warehouse);
  const shippingLabelService = new ShippingLabelService(props.authenticityToken);
  const waveTemplateService = new WaveTemplateService(props.authenticityToken);
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse>(props.currentWarehouse);
  batchService.currentWarehouse = Number(selectedWarehouse.id);
  const flexeContextValue: FlexeContextValues = {
    authenticityToken: props.authenticityToken
  };

  const [isSchedulingModalEnabled, setIsSchedulingModalEnabled] = useState<boolean>(false);

  const [enableAddBatchSchedule, setEnableAddBatchSchedule] = useState<boolean>(false);

  const [isWaveByCustomAttributeEnabled, setIsWaveByCustomAttributeEnabled] = useState<boolean>(false);
  const [isHidePrintedFilterEnabled, setIsHidePrintedFilterEnabled] = useState<boolean>(false);
  const [singleAndMultiSkuEnabledForAll, setSingleAndMultiSkuEnabledForAll] = useState<boolean>(false);

  const [isCustomAttributeWaveTemplateDropdownEnabled, setIsCustomAttributeWaveTemplateDropdownEnabled] = useState<
    boolean
  >(false);

  const [isWaveBySingleMultiSkuEnabled, setIsWaveBySingleMultiSkuEnabled] = useState<boolean>(false);

  const [, setIsBatchDocDetailsEnabled] = useState<boolean>(false);

  const [isFullPalletPullForSortationEnabled, setIsFullPalletPullForSortationEnabled] = useState<boolean>(false);

  const getSelectedWarehouse = () => {
    return selectedWarehouse;
  };

  const onSelectedWarehouse = async (warehouse: Warehouse) => {
    if (props.currentWarehouse.id !== warehouse.id) {
      window.location.href = `/wh/fulfillment/ecommerce/waving?warehouse_id=${warehouse.id}`;
    }
  };

  const onSelectedReservation = async (reservationId: number) => {
    await updateReservationFeatureFlags(reservationId);
  };

  const getFeatureFlagValue = async (flag: string, warehouseId: number = null, reservationId: number = null) => {
    const featureFlagResponse = await warehouseService.getFeatureFlag(flag, warehouseId, reservationId);
    return !!featureFlagResponse.data.value;
  };

  const updateWarehouseFeatureFlagsAsync = async (warehouseId: number) => {
    const scheduledBatchingPage = getFeatureFlagValue('scheduled-batching-page', warehouseId, null);
    const showAddBatchScheduleButton = getFeatureFlagValue('show-add-batch-schedule-button', warehouseId, null);
    const waveByCustomAttribute = getFeatureFlagValue('wave-by-custom-attribute', warehouseId, null);
    const fullPalletPullForSortation = getFeatureFlagValue('full_pallet_pull_for_sortation', warehouseId, null);
    const showHidePrintedBatchesFilter = getFeatureFlagValue('hide-printed-batches-filter-enabled', warehouseId, null);
    const showSingleAndMultiSkuEnabledForAll = getFeatureFlagValue(
      'show-single-and-multi-sku-for-all',
      warehouseId,
      null
    );

    setIsSchedulingModalEnabled(await scheduledBatchingPage);
    setEnableAddBatchSchedule(await showAddBatchScheduleButton);
    setIsWaveByCustomAttributeEnabled(await waveByCustomAttribute);
    setIsFullPalletPullForSortationEnabled(await fullPalletPullForSortation);
    setIsHidePrintedFilterEnabled(await showHidePrintedBatchesFilter);
    setSingleAndMultiSkuEnabledForAll(await showSingleAndMultiSkuEnabledForAll);
  };

  useEffect(() => {
    // Feature Flags
    updateWarehouseFeatureFlagsAsync(props.currentWarehouse.id);
  }, []);

  const updateReservationFeatureFlags = async (reservationId: number) => {
    const customAttributeDropdown = getFeatureFlagValue('custom-attribute-wave-template-dropdown', null, reservationId);
    const singleMultiSkuFeatureFlagResponse = getFeatureFlagValue('wave-by-single-or-multi-sku', null, reservationId);
    const batchDocDetailsResponse = getFeatureFlagValue('rfo-batch-doc-notes-info', null, reservationId);

    setIsCustomAttributeWaveTemplateDropdownEnabled(await customAttributeDropdown);
    setIsWaveBySingleMultiSkuEnabled(await singleMultiSkuFeatureFlagResponse);
    setIsBatchDocDetailsEnabled(await batchDocDetailsResponse);
  };

  const getHighVolumeWavingEnabled = () => {
    const warehouseId = selectedWarehouse.id;
    return props.highVolumeWavingMap[warehouseId];
  };

  return (
    <FlexeContext.Provider value={flexeContextValue}>
      <Router>
        <Switch>
          <Route
            path="/wh/fulfillment/ecommerce"
            exact
            strict
            render={(routeProps) => {
              return (
                <CompactBatchList
                  batchService={batchService}
                  carriers={props.carriers}
                  selectedWarehouse={getSelectedWarehouse()}
                  warehouses={props.warehouses}
                  warehouseService={warehouseService}
                  onWarehouseSelect={(warehouse: Warehouse) => onSelectedWarehouse(warehouse)}
                  isSchedulingModalEnabled={isSchedulingModalEnabled}
                  isAddBatchScheduleEnabled={enableAddBatchSchedule && props.hasWhManagerBillingPerms}
                  isFreightWorkflowEnabled={props.isFreightWorkflowEnabled}
                  isHidePrintedFilterEnabled={isHidePrintedFilterEnabled}
                  isTrailerLoadingEnabled={props.isTrailerLoadingEnabled}
                />
              );
            }}
          />
          <Route
            path="/wh/fulfillment/ecommerce/waving"
            exact
            strict
            render={(routeProps) => (
              <AsyncWaving
                selectedWarehouse={selectedWarehouse}
                onWarehouseSelect={(warehouse: Warehouse) => onSelectedWarehouse(warehouse)}
                {...routeProps}
                {...props}
                reservationToSortationEnabled={props.reservationToSortationEnabled}
                reservationToPickToCarton={props.reservationToPickToCarton}
                carriers={props.carriers}
                isHighVolumeWavingEnabled={getHighVolumeWavingEnabled()}
                isFullPalletPullForSortationEnabled={isFullPalletPullForSortationEnabled}
                waveTemplateService={waveTemplateService}
                batchWavingService={batchService}
                warehouseService={warehouseService}
                isFreightWorkflowEnabled={props.isFreightWorkflowEnabled}
              />
            )}
          />
          <Route
            path="/wh/fulfillment/ecommerce/waving/new_template"
            exact
            strict
            render={(routeProps) => (
              <NewWaveTemplateForm
                warehouseService={warehouseService}
                selectedWarehouse={selectedWarehouse}
                carrierLabels={props.carriers}
                batchWavingService={batchService}
                waveTemplateService={waveTemplateService}
                reservationToSortationEnabled={props.reservationToSortationEnabled}
                reservationToPickToCarton={props.reservationToPickToCarton}
                waveByCustomAttributeEnabled={isWaveByCustomAttributeEnabled}
                customAttributeWaveTemplateDropdownEnabled={isCustomAttributeWaveTemplateDropdownEnabled}
                waveBySingleMultiSkuEnabled={isWaveBySingleMultiSkuEnabled}
                showSingleAndMultiSkuForAll={singleAndMultiSkuEnabledForAll}
                onSelectedReservation={onSelectedReservation}
              />
            )}
          />
          <Route
            path="/wh/fulfillment/ecommerce/:id"
            exact
            strict
            render={(routeProps) => (
              <BatchDetail
                {...routeProps}
                {...props}
                batchService={batchService}
                warehouseService={warehouseService}
                shipmentService={shipmentService}
                documentsService={documentsService}
                shippingLabelService={shippingLabelService}
              />
            )}
          />
        </Switch>
      </Router>
    </FlexeContext.Provider>
  );
}

export default Batches;
