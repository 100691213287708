import * as React from 'react';
import {
  DataGrid,
  DataGridProps,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';

/**
 * A thin wrapper around `@mui/x-data-grid`'s `DataGrid`.
 * Offers reasonable default styling and toolbar configuration.
 *
 * @param props passed directly through to wrapped `DataGrid`.
 * @returns
 */
export function FlexeDataGrid(props: DataGridProps) {
  return (
    <DataGrid
      className="flexe-mui-data-grid"
      getRowClassName={({indexRelativeToCurrentPage}) =>
        indexRelativeToCurrentPage % 2 === 0 ? 'flexe-mui-data-grid-row-even' : 'flexe-mui-data-grid-row-odd'
      }
      slots={{toolbar: CustomToolbar}}
      {...props}
    />
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter debounceMs={250} />
      <div className="flexe-mui-data-grid-toolbar-center" /> {/* used to move export button to the right */}
      <GridToolbarExport printOptions={{disableToolbarButton: true}} />
    </GridToolbarContainer>
  );
}
