import axios, {AxiosResponse} from 'axios';
// If you get undefined when invoking objectToFormData in jest environment,
// try `require('object-to-formdata')`
import objectToFormData from 'object-to-formdata';
import {v4} from 'uuid';
import {ApiResponseV2, ResponseErrorV2} from '../CommonInterfaces';

export interface InternalAPIServiceV2Options {
  returnAllErrors: boolean;
}

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
abstract class InternalAPIServiceV2 {
  protected baseUrl: string;

  protected constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  static extractErrorSummaries = (errors: ResponseErrorV2[]): string[] => {
    return errors.map((error) => error.detail || error.title);
  };

  protected handleErrorResponse(error, returnAllErrors: boolean = false) {
    return returnAllErrors ? this.handleErrorResponseAll(error) : this.handleErrorResponseManualOnly(error);
  }

  protected handleErrorResponseAll(error) {
    if (error.response && error.response.data && error.response.data.errors) {
      return error.response.data;
    }

    const statusCode = error.response?.status || 'Unknown';
    const statusText = error.response?.statusText || 'Unknown';
    const responseError: ResponseErrorV2 = {
      code: statusCode,
      title: statusText,
      detail: error.message || `Unrecognized error encountered. Code: ${statusCode}. Text: ${statusText}`,
      source: {pointer: ''}
    };

    return {errors: [responseError]};
  }
  protected handleErrorResponseManualOnly(error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data;
    }
    // Otherwise
    return null;
  }

  protected handlePostErrorResponse(error) {
    if (error.response.status !== 200 || error.response.status !== 201) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    }
    // Otherwise
    return null;
  }

  protected async makePostRequest(
    url: string,
    data,
    contentType: string = 'json',
    options?: InternalAPIServiceV2Options
  ) {
    let requestParameters: any = data;
    if (contentType === 'multipart') {
      requestParameters = objectToFormData(requestParameters);
    }
    try {
      const response = await axios.post(url, requestParameters, {
        headers: this.setHeaders()
      });
      return response.data as ApiResponseV2;
    } catch (error) {
      return this.handleErrorResponse(error, options?.returnAllErrors);
    }
  }

  protected async makePostOrderRequest(url: string, data, contentType: string = 'json') {
    let requestParameters: any = data;
    if (contentType === 'multipart') {
      requestParameters = objectToFormData(requestParameters);
    }
    try {
      const response = await axios.post(url, requestParameters, {
        headers: this.setHeaders()
      });
      return response as AxiosResponse<ApiResponseV2>;
    } catch (error) {
      return this.handlePostErrorResponse(error);
    }
  }

  protected async putRequest(url: string, data, options?: InternalAPIServiceV2Options) {
    const requestParameters: any = data;
    try {
      return await axios.put(url, requestParameters, {
        headers: this.setHeaders()
      });
    } catch (error) {
      // This changes the structure, so we have to carefully opt in
      // Without the flag on, return: { "data": { "data": null, "errors": [{...}, ...], ... } }
      // With the flag on, return:              { "data": null, "errors": [{...}, ...], ... }
      if (options?.returnAllErrors) {
        return this.handleErrorResponse(error, options?.returnAllErrors);
      }
      return error.response;
    }
  }

  protected async postRequest(url: string, data, contentType: string = 'json') {
    let requestParameters: any = data;
    if (contentType === 'multipart') {
      requestParameters = objectToFormData(requestParameters);
    }
    try {
      return await axios.post(url, requestParameters, {
        headers: this.setHeaders()
      });
    } catch (error) {
      return error.response;
    }
  }

  protected async makePutRequest(url: string, data) {
    const requestParameters: any = data;
    try {
      const response = await axios.put(url, requestParameters, {
        headers: this.setHeaders()
      });
      return response.data as ApiResponseV2;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  protected async makeGetRequest(
    url: string,
    params = null,
    data = null,
    headers = null,
    options?: InternalAPIServiceV2Options
  ) {
    try {
      const defaultHeaders = this.setHeaders();
      const combinedHeaders = headers ? {...defaultHeaders, ...headers} : defaultHeaders;
      const dataObj = data ? {data} : {};
      let config;
      if (params) {
        Object.keys(params).forEach((param) => {
          // for V2 APIs, convert arrays to csv in GET request filters
          if (Array.isArray(params[param])) {
            params[param] = params[param].join(',');
          }
        });
        config = {headers: combinedHeaders, ...dataObj, params};
      } else {
        config = {headers: combinedHeaders, ...dataObj};
      }
      const response = await axios.get(url, config);
      return response.data as ApiResponseV2;
    } catch (error) {
      return this.handleErrorResponse(error, options?.returnAllErrors);
    }
  }

  protected async makePatchRequest(url: string, data) {
    const requestParameters: any = data;
    try {
      const response = await axios.patch(url, requestParameters, {
        headers: this.setHeaders()
      });
      return response.data as ApiResponseV2;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  protected async makeDeleteRequest(url: string, data = {}) {
    const requestParameters: any = data;
    try {
      const response = await axios.delete(url, {
        headers: this.setHeaders(),
        data: requestParameters
      });

      // Replace no-content (empty response) with an empty response object
      if (response.status === 204) {
        return {} as ApiResponseV2;
      } else {
        return response.data as ApiResponseV2;
      }
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  protected setHeaders() {
    return {'X-FLEXE-Correlation-ID': v4()};
  }
}

export default InternalAPIServiceV2;
