/** @jsx jsx */
import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {difference} from 'lodash';
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import {WarningCard} from '../shared/WarningCard';
import {ErrorCard} from '../shared/ErrorCard';

/**
 * Alerts the user if there are any picked LPNs that have not yet been loaded and shipped.
 */
export const DanglingLpnsError: FC<{
  lpnBarcodes: string[];
  manifestLpnBarcodes: string[];
  setHasDanglingLpns: Dispatch<SetStateAction<boolean>>;
}> = ({lpnBarcodes, manifestLpnBarcodes, setHasDanglingLpns}) => {
  const danglingLpnBarcodes = difference(lpnBarcodes, manifestLpnBarcodes);
  const hasDanglingLpns = danglingLpnBarcodes.length !== 0;

  useEffect(() => {
    setHasDanglingLpns(hasDanglingLpns);
  }, [hasDanglingLpns, setHasDanglingLpns]);

  if (!hasDanglingLpns) {
    return null;
  }

  return (
    <ErrorCard>
      <b>There are picked LPNs still attached to the shipment.</b>&nbsp; Load or remove the following LPNs from the
      shipment before completing the shipment.
      <ul
        css={css`
          list-style: disc;
          margin-top: ${tokens?.spacing.v50.value}px;
        `}
      >
        {danglingLpnBarcodes.sort().map((_) => (
          <li key={_}>{_}</li>
        ))}
      </ul>
    </ErrorCard>
  );
};
