import * as React from 'react';

import {LegacyModal} from '@flexe/ui-components';
import {ShipmentPackaging} from './Interfaces';

interface Props {
  show: boolean;
  hideModal: () => void;
  handleSubmit: () => void;
  shipmentPackaging: ShipmentPackaging;
}

const ShipmentPackagingDeleteDialog: React.FC<Props> = (props) => {
  const {shipmentPackaging} = props;

  const footer = (
    <div>
      <a id="a-cancel" className="default-ovebox__cancel" onClick={props.hideModal}>
        Cancel
      </a>
      <button
        type="button"
        className="btn btn-primary"
        id="btn-delete"
        onClick={props.handleSubmit}
        data-testid="btn-delete"
      >
        Delete Packaging
      </button>
    </div>
  );

  if (!shipmentPackaging) {
    return null;
  }

  return (
    <LegacyModal
      id="default_overbox_delete_modal"
      title={'Delete Packaging'}
      show={props.show}
      size="small"
      transitionSpeed="fast"
      toggleModal={props.hideModal}
      footer={footer}
    >
      <div>
        <div className="row">
          <p className="col-md-12">Are you sure you wish to delete this packaging?</p>
        </div>
      </div>
    </LegacyModal>
  );
};

export default ShipmentPackagingDeleteDialog;
