import * as React from 'react';
import {Icon, Row} from '@flexe/ui-components';

interface CompleteShipmentShipButtonProps {
  isDisabled: boolean;
  isLoading: boolean;
  onPress: () => void;
  isShort: boolean;
}

export const CompleteShipmentShipButton: React.FC<CompleteShipmentShipButtonProps> = (props) => {
  const text = props.isShort ? 'Ship Short' : 'Complete Shipment';
  const content = props.isLoading ? (
    <Row margin={['0', '100']}>
      <span style={{width: '100%'}}>
        <span style={{marginRight: '5px'}}>
          <Icon icon="circle-notch" animation="spin-fast" />
        </span>
        <span>Loading...</span>
      </span>
    </Row>
  ) : (
    text
  );

  return (
    <button className="btn" disabled={props.isDisabled || props.isLoading} onClick={props.onPress}>
      {content}
    </button>
  );
};
