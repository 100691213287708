import * as React from 'react';

interface PaginationButtonsProps {
  pageSize: number;
  currentPage: number;
  shipmentsCount: number;
  setCurrentPage: (pageNumber: number) => void;
}

/**
 * @deprecated - Props are domain-specific, and it is not portable (relies on CSS via the class name).
 * Prefer "app/javascript/components/shared/PaginationButtons.tsx" instead.
 */
const PaginationButtons: React.FC<PaginationButtonsProps> = (props) => {
  const pageBack = () => {
    if (props.currentPage > 1) {
      props.setCurrentPage(props.currentPage - 1);
    }
  };

  const pageForward = () => {
    const pageSize = props.pageSize;
    const endIndexThisPage = (props.currentPage - 1) * pageSize + pageSize;
    if (endIndexThisPage < props.shipmentsCount) {
      props.setCurrentPage(props.currentPage + 1);
    }
  };

  const shipmentsCountDisplay = () => {
    const pageSize = props.pageSize;
    const totalRecords = props.shipmentsCount;
    let startIndex;

    if (props.shipmentsCount > 0) {
      startIndex = (props.currentPage - 1) * pageSize + 1;
    } else {
      startIndex = 0;
    }
    const endIndex = Math.min((props.currentPage - 1) * pageSize + pageSize, totalRecords);
    return (
      <span>
        <strong>
          {startIndex}-{endIndex}
        </strong>{' '}
        of {totalRecords}
      </span>
    );
  };

  return (
    <div className="shipment-table-control-container">
      <a
        onClick={() => {
          pageBack();
        }}
      >
        <i className="fa fa-angle-left pagination-link"></i>
      </a>
      <span className="shipments-page-counter">{shipmentsCountDisplay()}</span>
      <a
        onClick={() => {
          pageForward();
        }}
      >
        <i className="fa fa-angle-right pagination-link"></i>
      </a>
    </div>
  );
};

export default PaginationButtons;
