import axios from 'axios';
import {get} from 'lodash';
import objectToFormData from 'object-to-formdata';
import {v4} from 'uuid';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
class PackingSlipService {
  private authenticityToken: string;

  private baseUrl = '/s/packing_slips/';

  constructor(authenticityToken: string) {
    this.authenticityToken = authenticityToken;
  }

  public async getTemplates() {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get(`${this.baseUrl}templates`, {
        params: {
          authenticity_token: this.authenticityToken
        }
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async setActiveTemplate(templateId) {
    // eslint-disable-next-line no-useless-catch
    try {
      const params = {
        authenticity_token: this.authenticityToken,
        meta: {
          correlationId: v4()
        },
        data: {
          packingSlipTemplateId: templateId
        }
      };
      const response = await axios.patch(`${this.baseUrl}set_active_template`, params);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async deleteTemplate(templateId) {
    // eslint-disable-next-line no-useless-catch
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          authenticity_token: this.authenticityToken,
          meta: {correlationId: v4()},
          data: {}
        }
      };
      const response = await axios.delete(`${this.baseUrl}${templateId}`, config);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async uploadTemplate(uploadParams) {
    // eslint-disable-next-line no-useless-catch
    try {
      const params = {
        authenticity_token: this.authenticityToken,
        meta: {
          correlationId: v4()
        },
        data: uploadParams
      };
      const uploadData = objectToFormData(params);

      const response = await axios.post(`${this.baseUrl}upload_template`, uploadData);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async previewTemplate(templateId) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get(`${this.baseUrl}${templateId}/preview_template`, {
        params: {
          authenticity_token: this.authenticityToken
        }
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public processErrorResponse = (errorResponse: Response) => {
    const errors = get(errorResponse, 'response.data.errors');
    return errors.map((error) => error.detail);
  };
}

export default PackingSlipService;
