import * as React from 'react';
import {FC, useEffect, useMemo, useState} from 'react';
import {Loader} from '@flexe/ui-components';
import {
  CompanyType,
  Document,
  DocumentNotableType,
  ShipmentDocument,
  ShipmentDocumentType,
  ShipmentDocumentTypeShippingLabels
} from '../shared/CommonInterfaces';
import DocumentsService from '../shared/services/DocumentsService';
import SsccService from '../shared/services/SsccService';
import DocumentUploadModal from '../shared/DocumentUploadModal';
import GenerateLPNs from '../dropoffs/lpns/GenerateLPNs';
import {Wave} from '../warehouse/batch-waving/WaveInterfaces';
import DocumentPrintButton from '../warehouse/batch-waving/shared/DocumentPrintButton';
import OutboundDocumentService, {OutboundDocument} from '../shared/services/OutboundDocumentService';
import BatchWavingService from '../shared/services/BatchWavingService';
import {SsccLabelRequirementsInfo} from './SsccInterfaces';
import {documentTypeTitleToLabelType} from './Helpers';
import ManualGenerationDocumentRow from './ManualGenerationDocumentRow';
import {BlobDbTableType, convertToBlobStorage} from './ConvertToBlobStorage';
import ShipmentDocumentRow from './ShipmentDocumentRow';
import {PrintCartonSsccByLpnModal} from './PrintCartonSsccByLpnModal';

interface Props {
  authenticityToken: string;
  batchId: number;
  billOfLadingIsGenerating: boolean;
  bolNumber: string;
  displayPrintLpnLabelsButton: boolean;
  documentsService: DocumentsService;
  enableGeneratePackingListAfterComplete?: boolean;
  enableFreightPackingListRefresh: boolean;
  enableGenerateFreightPackingListUi?: boolean;
  enableStickersForReservation?: boolean;
  freightPackingListIsGenerating: boolean;
  reservationId: number;
  shipmentId?: number;
  shipmentIds?: number[];
  shipmentStatus: string;
  showUploadButton: boolean;
  ssccLabelsAreGenerating: boolean;
  stopId?: number;
  warnShortShipForPackingList?: boolean;
  wave?: Wave;
  checkLabelRequirementsForCompletion: (labelRequirements: SsccLabelRequirementsInfo) => void;
  enqueueSsccLabelRegeneration: () => void;
  enqueueFreightPackingListRegeneration: () => void;
  enqueueBillOfLadingGeneration: () => void;
  enablePrintCartonSsccByLpn?: boolean;
}

export const ShipmentDocuments: FC<Props> = (props) => {
  const ssccService = useMemo(() => new SsccService(props.authenticityToken), [props.authenticityToken]);
  const outboundDocumentService = useMemo(() => new OutboundDocumentService(props.authenticityToken), [
    props.authenticityToken
  ]);
  const noSsccGenerationAllowedStatuses = ['new', 'cancelled', 'completed', 'voided', 'failed'];
  const allowSsccGeneration = !noSsccGenerationAllowedStatuses.includes(props.shipmentStatus);
  const allowPackListGeneration =
    props.enableGeneratePackingListAfterComplete || !noSsccGenerationAllowedStatuses.includes(props.shipmentStatus);
  const noStickerGenerationAllowedStatuses = ['cancelled', 'voided', 'failed'];
  const allowStickerGeneration = !noStickerGenerationAllowedStatuses.includes(props.shipmentStatus);
  const [errors, setErrors] = useState<string[]>([]);
  const [documentsAreLoading, setDocumentsAreLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [shipmentDocuments, setShipmentDocuments] = useState<ShipmentDocument[]>([]);
  const [ssccLabels, setSsccLabels] = useState<ShipmentDocument[]>([]);
  const [outboundDocuments, setOutboundDocuments] = useState<OutboundDocument[]>([]);
  const [ssccLabelRequirementsInfo, setSsccLabelRequirementsInfo] = useState<SsccLabelRequirementsInfo>({
    cartonLabelsRequiredForAnyShipment: false,
    palletLabelsRequiredForAnyShipment: false,
    cartonLabelRequirementsHaveBeenMetForAllShipments: true,
    palletLabelRequirementsHaveBeenMetForAllShipments: true
  });
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState<boolean>(false);
  const [showPrintCartonSsccByLpnModal, setShowPrintCartonSsccByLpnModal] = useState<boolean>(false);

  const batchWavingService = useMemo(() => new BatchWavingService(props.authenticityToken), [props.authenticityToken]);

  const asyncGetPickStatus = async () => {
    let isShipmentShort = false;
    if (!props.warnShortShipForPackingList) return isShipmentShort;

    try {
      const response = await batchWavingService.getPickCompleteForFreightWave(props.shipmentId);
      isShipmentShort = response.data.pick_complete === undefined ? false : !response.data.pick_complete;

      if (response.errors && response.errors.length > 0) {
        setErrors([
          'An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.'
        ]);
        isShipmentShort = false;
      }
      return isShipmentShort;
    } catch (e) {
      setErrors([
        'An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.',
        e.detail
      ]);
      return false;
    }
  };

  useEffect(() => {
    getDocuments();
    getShipmentDocuments();
    getSsccLabelRequirementsInfo();
    if (props.shipmentId) {
      getSsccLabels();
      if (props.enableStickersForReservation) {
        getOutboundDocuments();
      }
    }
  }, []);

  useEffect(() => {
    getSsccLabelRequirementsInfo();
  }, [JSON.stringify(props.shipmentIds)]);

  useEffect(() => {
    props.checkLabelRequirementsForCompletion(ssccLabelRequirementsInfo);
  }, [ssccLabelRequirementsInfo]);

  const getDocuments = async () => {
    if (documentsAreLoading) {
      return;
    }

    const docErrors = [];
    setDocumentsAreLoading(true);

    try {
      let fetchedDocuments = [];
      if (props.shipmentId) {
        const shipmentDocumentsResponse = await props.documentsService.getDocuments(
          CompanyType.warehouse,
          props.reservationId,
          DocumentNotableType.ecomShipment,
          props.shipmentId
        );
        fetchedDocuments = fetchedDocuments.concat(shipmentDocumentsResponse);

        const shipperDocumentsResponse = await props.documentsService.getDocuments(
          CompanyType.shipper,
          props.reservationId,
          DocumentNotableType.ecomShipment,
          props.shipmentId
        );

        fetchedDocuments = fetchedDocuments.concat(shipperDocumentsResponse);
      }

      if (props.batchId) {
        const batchDocumentsResponse = await props.documentsService.getDocuments(
          CompanyType.warehouse,
          props.reservationId,
          DocumentNotableType.fulfillmentBatch,
          props.batchId
        );
        fetchedDocuments = fetchedDocuments.concat(batchDocumentsResponse);
      }

      if (props.stopId) {
        const stopDocumentsResponse = await props.documentsService.getDocuments(
          CompanyType.warehouse,
          props.reservationId,
          DocumentNotableType.freightLoadStop,
          props.stopId
        );
        fetchedDocuments = fetchedDocuments.concat(stopDocumentsResponse);
      }

      setDocuments(fetchedDocuments);
    } catch (error) {
      docErrors.push(error.toString());
    } finally {
      setDocumentsAreLoading(false);
      if (docErrors.length) {
        setErrors((existingErrors) => [...existingErrors, ...docErrors]);
      }
    }
  };

  const getShipmentDocuments = async () => {
    const shipmentDocErrors = [];
    setDocumentsAreLoading(true);
    try {
      if (props.shipmentId) {
        const shipmentDocumentResponse = await props.documentsService.getShipmentDocuments(props.shipmentId);
        const allowedTypesSet = new Set(ShipmentDocumentTypeShippingLabels);
        allowedTypesSet.add(ShipmentDocumentType.packingSlip);
        allowedTypesSet.add(ShipmentDocumentType.freightPackingList);
        allowedTypesSet.add(ShipmentDocumentType.insert);
        const documentsToShow = shipmentDocumentResponse.filter((aDocument: ShipmentDocument) => {
          return allowedTypesSet.has(aDocument.labelType.enumValue);
        });

        setShipmentDocuments(documentsToShow);
      }
    } catch (error) {
      shipmentDocErrors.push(error.toString());
    } finally {
      setDocumentsAreLoading(false);
      if (shipmentDocErrors.length) {
        setErrors((existingErrors) => [...existingErrors, ...shipmentDocErrors]);
      }
    }
  };

  const getSsccLabelRequirementsInfo = async () => {
    const infoErrors = [];
    setDocumentsAreLoading(true);
    try {
      const response = await ssccService.getSsccLabelRequirementsInfo(props.shipmentIds, props.reservationId);
      setSsccLabelRequirementsInfo(response.data.requirementsInfo);
    } catch (error) {
      infoErrors.push(error.toString());
    } finally {
      setDocumentsAreLoading(false);
      if (infoErrors.length) {
        setErrors((existingErrors) => [...existingErrors, ...infoErrors]);
      }
    }
  };

  const getSsccLabels = async () => {
    const labelErrors = [];
    setDocumentsAreLoading(true);
    try {
      const ssccLabelsResponse = await ssccService.getSsccLabels(props.shipmentId);
      setSsccLabels(ssccLabelsResponse.data.labels);
    } catch (error) {
      labelErrors.push(error.toString());
    } finally {
      setDocumentsAreLoading(false);
      if (labelErrors.length) {
        setErrors((existingErrors) => [...existingErrors, ...labelErrors]);
      }
    }
  };

  const generateSsccLabels = async (label) => {
    setErrors([]);
    let labelGenErrors: string[] = [];
    let labelGenResponse;
    const storage = convertToBlobStorage({label, dbTable: BlobDbTableType.ShipmentDocument});
    const docType = documentTypeTitleToLabelType[storage.typeTitle];
    try {
      labelGenResponse = await ssccService.generateSsccLabels(props.shipmentId, docType, storage.availableForDownload);
      labelGenErrors = labelGenResponse.errors.map((e) => e.detail);
    } catch (error) {
      labelGenErrors.push(error.toString());
    } finally {
      if (labelGenErrors.length) {
        setErrors((existingErrors) => [...existingErrors, ...labelGenErrors]);
      }
    }
    return labelGenResponse;
  };

  const getOutboundDocuments = async () => {
    const documentErrors = [];
    setDocumentsAreLoading(true);
    try {
      const outboundDocumentsResponse = await outboundDocumentService.getOutboundDocuments(props.shipmentId);
      setOutboundDocuments(outboundDocumentsResponse.data.documents);
      outboundDocumentsResponse.data.documents.map((doc) => {
        if (doc.generationStatus === 'error') {
          documentErrors.push(`Error for ${doc.labelType.subDisplayName}: ${doc.error.toString()}`);
        }
      });
    } catch (error) {
      documentErrors.push(error.toString());
    } finally {
      setDocumentsAreLoading(false);
      if (documentErrors.length) {
        setErrors((existingErrors) => [...existingErrors, ...documentErrors]);
      }
    }
  };

  const generateOutboundDocuments = async (outboundDoc) => {
    setErrors([]);
    let documentGenErrors: string[] = [];
    let documentGenResponse;
    const storage = convertToBlobStorage({outboundDoc, dbTable: BlobDbTableType.OutboundDocument});
    const docType = storage.typeTitle;
    const customDocName = storage.typeSubtitle;
    try {
      documentGenResponse = await outboundDocumentService.generateDocuments(props.shipmentId, docType, customDocName);
      documentGenErrors = documentGenResponse.errors.map((e) => e.detail);
    } catch (error) {
      documentGenErrors.push(error.toString());
    } finally {
      if (documentGenErrors.length) {
        setErrors((existingErrors) => [...existingErrors, ...documentGenErrors]);
      }
    }
    return documentGenResponse;
  };

  const printOutboundDocuments = async (quantity: number, docType: string, customDocumentName: string) => {
    window
      .open(
        `/api/v2/outbound_documents/print_documents?shipment_id=${props.shipmentId}&quantity=${quantity}&document_type=${docType}&custom_document_name=${customDocumentName}`,
        '_blank'
      )
      .focus();
  };

  const handleSavedDoc = (savedDoc: Document) => {
    documents.push(savedDoc);
  };

  const toggleUploadDocumentModal = () => {
    setShowUploadDocumentModal(!showUploadDocumentModal);
  };

  // As of now Waves can have multiple documents (due to an issue to be investigated in PAS-3013).
  // If this is the case use the latest document.
  const waveDocument = props.wave?.documents[props.wave.documents.length - 1];

  const openPrintCartonSsccByLpnModal = () => {
    if (!showPrintCartonSsccByLpnModal) {
      setShowPrintCartonSsccByLpnModal(true);
    }
  };

  const handleClickPrintByLpn = () => {
    openPrintCartonSsccByLpnModal();
  };

  const togglePrintCartonSsccByLpnModal = () => {
    setShowPrintCartonSsccByLpnModal(!showPrintCartonSsccByLpnModal);
  };

  return (
    <React.Fragment>
      <div id="document-upload-modal">
        <DocumentUploadModal
          documents={documents}
          documentsRoute="/api/v2/documents"
          notableType={DocumentNotableType.ecomShipment}
          notableId={props.shipmentId}
          showUploadModal={showUploadDocumentModal}
          toggleUploadModal={toggleUploadDocumentModal}
          onDocumentSaved={handleSavedDoc}
        />
      </div>
      <div id="shipment-documents-section" className="documents-section">
        <div className="container-fluid">
          <div className="documents-container">
            <header>
              <div className="title-aligned">
                <h2>Documents</h2>
              </div>
              {props.showUploadButton && (
                <div className="title-aligned right">
                  <button type="button" className="btn-link" onClick={toggleUploadDocumentModal}>
                    <i className="fa fa-plus" />
                    Upload Document
                  </button>
                </div>
              )}
            </header>

            {errors.map((error, idx) => {
              return (
                <div className="alert alert-danger" role="alert" key={`error:${idx}`}>
                  {error}
                </div>
              );
            })}

            <table className="table">
              <thead>
                <tr>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((doc, idx) => {
                  let documentIdentifier = null;
                  // If any other use case comes up to associate a document to an
                  // identifier, we should be smarter about this and just return it
                  // in the documents API instead of adding special logic here
                  if (doc.documentType.toLowerCase() === 'generated bill of lading') {
                    documentIdentifier = '#' + props.bolNumber;
                  }

                  return (
                    <ShipmentDocumentRow
                      key={`doc-${doc.id}`}
                      storageSource={{document: doc, dbTable: BlobDbTableType.Document}}
                      documentIdentifier={documentIdentifier}
                      enqueueBillOfLadingGeneration={props.enqueueBillOfLadingGeneration}
                      billOfLadingIsGenerating={props.billOfLadingIsGenerating}
                      shipmentStatus={props.shipmentStatus}
                      enqueueSsccLabelRegeneration={() => {
                        return;
                      }}
                      enqueueFreightPackingListRegeneration={() => {
                        return;
                      }}
                      ssccLabelsAreGenerating={false}
                      freightPackingListIsGenerating={false}
                      enableFreightPackingListRefresh={false}
                    />
                  );
                })}

                {shipmentDocuments.map((label) => {
                  if (
                    props.enableGenerateFreightPackingListUi &&
                    label.labelType.enumValue === 'freight_packing_list'
                  ) {
                    return (
                      <ManualGenerationDocumentRow
                        key={`packing-list-${label.id}`}
                        allowGeneration={allowPackListGeneration}
                        humanReadableDocType="Freight Packing List"
                        storage={convertToBlobStorage({label, dbTable: BlobDbTableType.ShipmentDocument})}
                        generateDocuments={props.enqueueFreightPackingListRegeneration}
                        warnShortShipForPackingList={props.warnShortShipForPackingList}
                        isShipmentShort={() => asyncGetPickStatus()}
                      />
                    );
                  } else {
                    return (
                      <ShipmentDocumentRow
                        key={label.id}
                        storageSource={{label, dbTable: BlobDbTableType.ShipmentDocument}}
                        billOfLadingIsGenerating={false}
                        ssccLabelsAreGenerating={props.ssccLabelsAreGenerating || !label.labelDetails.secureDownloadUrl}
                        freightPackingListIsGenerating={
                          props.enableFreightPackingListRefresh && props.freightPackingListIsGenerating
                        }
                        shipmentStatus={props.shipmentStatus}
                        enqueueSsccLabelRegeneration={props.enqueueSsccLabelRegeneration}
                        enqueueFreightPackingListRegeneration={props.enqueueFreightPackingListRegeneration}
                        enqueueBillOfLadingGeneration={() => {
                          return;
                        }}
                        enableFreightPackingListRefresh={props.enableFreightPackingListRefresh}
                      />
                    );
                  }
                })}
                {ssccLabels.map((label) => {
                  const blobStorage = convertToBlobStorage({label, dbTable: BlobDbTableType.ShipmentDocument});
                  const docType = documentTypeTitleToLabelType[blobStorage.typeTitle];
                  const regenMsg =
                    docType === 'pallet'
                      ? 'Regenerating will invalidate any existing labels; they will need to be reapplied.'
                      : null;
                  return (
                    <ManualGenerationDocumentRow
                      key={`sscc-${label.labelType.enumValue}-label-${label.id}`}
                      allowGeneration={allowSsccGeneration}
                      apiReadableDocType={docType}
                      humanReadableDocType="SSCC Labels"
                      regenConfirmMsg={regenMsg}
                      storage={blobStorage}
                      handleClickPrintByLpn={handleClickPrintByLpn}
                      enablePrintCartonSsccByLpn={props.enablePrintCartonSsccByLpn}
                      generateDocuments={() => generateSsccLabels(label)}
                    />
                  );
                })}
                {props.enableStickersForReservation &&
                  outboundDocuments.map((outboundDoc) => {
                    const blobStorage = convertToBlobStorage({outboundDoc, dbTable: BlobDbTableType.OutboundDocument});
                    const docType = documentTypeTitleToLabelType[blobStorage.typeTitle];
                    return (
                      <ManualGenerationDocumentRow
                        key={`outbound-doc-${outboundDoc.labelType.enumValue}-sticker-${outboundDoc.id}`}
                        allowGeneration={allowStickerGeneration}
                        apiReadableDocType={outboundDoc.labelType.enumValue}
                        humanReadableDocType={outboundDoc.labelType.subDisplayName}
                        storage={blobStorage}
                        rowLabelText={blobStorage.typeSubtitle}
                        generateDocuments={() => generateOutboundDocuments(outboundDoc)}
                        handlePrintWithQuantity={printOutboundDocuments}
                      />
                    );
                  })}
                {waveDocument && (
                  <tr>
                    <td>Wave Documents</td>
                    <td>
                      <DocumentPrintButton
                        document={waveDocument}
                        printButtonPrefix={'Print'}
                        isWaveCancelled={props.wave.status.toLowerCase() === 'cancelled'}
                      />
                    </td>
                  </tr>
                )}
                {props.displayPrintLpnLabelsButton && (
                  <tr>
                    <td>
                      <div className="print-lpns-row__title">LPN Barcodes</div>
                      <div className="print-lpns-row__subtitle">Print LPNs to consolidate Shipments to.</div>
                    </td>
                    <td>
                      <GenerateLPNs />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {showPrintCartonSsccByLpnModal && (
              <PrintCartonSsccByLpnModal
                isOpen={showPrintCartonSsccByLpnModal}
                title={'Print Carton SSCCs'}
                toggleModal={togglePrintCartonSsccByLpnModal}
                cancelPrint={togglePrintCartonSsccByLpnModal}
                shipmentId={props.shipmentId}
              />
            )}
            {documentsAreLoading && <Loader loading={documentsAreLoading} />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
