import * as React from 'react';
import {getRegionLabel, getRegions} from '../../../libs/helpers';

export interface Props {
  onRegionAbbrChange: (newSelectedRegionAbbr: string) => void;
  selectedRegionAbbr: string;
  countryAbbr: string;
}

const RegionSelect: React.FC<Props> = (props) => {
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    // Ensure we suppress any non-changes
    if (props.selectedRegionAbbr !== value) {
      props.onRegionAbbrChange(value);
    }
  };

  const regionLabel = getRegionLabel(props.countryAbbr);

  // Note the initial implementation uses asterisk to denote required.
  // As this gets expanded, that should probably be a configuration param
  return (
    <select onChange={onChange} value={props.selectedRegionAbbr}>
      <option value="" key="placeholder_value" hidden>
        {regionLabel ? `*${regionLabel}` : 'Unknown country selected'}
      </option>
      {getRegions(props.countryAbbr).map((s) => (
        <option value={s} key={s}>
          {s}
        </option>
      ))}
    </select>
  );
};
export default RegionSelect;
