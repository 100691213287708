import {FC} from 'react';
import * as React from 'react';
import {Modal, Row, Text} from '@flexe/ui-components';

interface Props {
  isOpen: boolean;
  title: string;
  regenConfirmMsg: string;
  toggleModal: () => void;
  cancelRegen: () => void;
  confirmRegen: () => void;
}

export const RegenerationConfirmationModal: FC<Props> = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggleModal={props.toggleModal} title={props.title}>
      <Text>{props.regenConfirmMsg}</Text>
      <Text level="Body1" bold={true}>
        Are you sure you wish to proceed?
      </Text>
      <Row margin={'50'} alignmentHorizontal="end">
        <button type="button" className="btn-default" onClick={props.cancelRegen}>
          Cancel
        </button>
        <button type="button" className="btn-primary" onClick={props.confirmRegen}>
          Proceed
        </button>
      </Row>
    </Modal>
  );
};

export default RegenerationConfirmationModal;
