import * as React from 'react';
import {cloneDeep} from 'lodash';
import {Loader} from '@flexe/ui-components';
import axios from 'axios';
import WarehouseService from '../../../shared/services/WarehouseService';
import {
  CarrierBillingAccount,
  CarrierBillingAccountField,
  CarrierBillingAccountServiceInterface,
  VendorServiceInterface
} from '../SharedInterfaces';
import CarrierBillingAccountDialog from './CarrierBillingAccountDialog';
import CarrierBillingAccountDeleteDialog from './CarrierBillingAccountDeleteDialog';
import CarrierBillingAccountTable from './CarrierBillingAccountTable';

interface Props {
  carrierBillingAccountService: CarrierBillingAccountServiceInterface;
  vendorService: VendorServiceInterface;
  warehouseService: WarehouseService;
}

interface State {
  accounts: CarrierBillingAccount[];
  errors: string[];
  loading: boolean;
  showCreate: boolean;
  creatingAccount: CarrierBillingAccount;
  showUpdate: boolean;
  updatingAccount: CarrierBillingAccount;
  showDelete: boolean;
  deletingAccount: CarrierBillingAccount;
  companyChannelsInfo: {[companyChannelId: number]: string};
  enableCompanyChannelsField: boolean;
}

class CarrierBillingAccountsV2 extends React.Component<Props, State> {
  private emptyAccount: CarrierBillingAccount = {
    id: undefined,
    friendlyName: '',
    carrier: '',
    country: '',
    accountId: '',
    postcode: '',
    companyChannelId: undefined
  };

  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      errors: [],
      loading: true,
      showCreate: false,
      creatingAccount: null,
      showUpdate: false,
      updatingAccount: null,
      showDelete: false,
      deletingAccount: null,
      companyChannelsInfo: {},
      enableCompanyChannelsField: false
    };
  }

  public async componentDidMount() {
    await this.loadCompanyChannels();
    await this.loadAccounts();
    await this.loadFeatureFlags();
  }

  public render() {
    const listProps = {
      handleUpdate: (id) => this.showUpdateDialog(id),
      handleDelete: (id) => this.showDeleteDialog(id),
      carrierBillingAccounts: this.state.accounts,
      companyChannelsInfo: this.state.companyChannelsInfo,
      showCompanyChannelsInfo: this.state.enableCompanyChannelsField
    };

    if (this.state.loading) {
      return <Loader loading={true} />;
    }

    return (
      <div>
        {this.state.errors.length > 0 && (
          <div className="alert alert-danger" role="alert">
            {this.state.errors.map((e, i) => (
              <span key={i}>{e}</span>
            ))}
          </div>
        )}
        <div className="row space-below-lg space-above-lg">
          <h2 className="col-md-6">
            3rd Party Carrier Billing Accounts
            <i
              className="fa fa-xs fa-question-circle"
              data-toggle="tooltip"
              aria-hidden="true"
              title={
                'When used along with a standard carrier account, allows for these accounts to be billed for ' +
                'shipping label purchases instead of the standard carrier account.'
              }
            ></i>
          </h2>
          <div className="col-md-6">
            <a className="carrier-billing-account-list__create btn pull-right" onClick={this.showCreateDialog}>
              <i className="fa fa-plus"></i>New 3rd Party Carrier Billing Account
            </a>
          </div>
        </div>
        <CarrierBillingAccountTable {...listProps} />
        <CarrierBillingAccountDialog
          cta="Create"
          show={this.state.showCreate}
          hideModal={this.hideCreateDialog}
          handleSubmit={this.handleCreateAccount}
          handleAccountFieldChange={this.handleCreateAccountFieldChange}
          account={this.state.creatingAccount}
          companyChannelsInfo={this.state.companyChannelsInfo}
          showCompanyChannelsInfo={this.state.enableCompanyChannelsField}
        />
        <CarrierBillingAccountDialog
          cta="Update"
          show={this.state.showUpdate}
          hideModal={this.hideUpdateDialog}
          handleAccountFieldChange={this.handleUpdateAccountFieldChange}
          handleSubmit={this.handleUpdateAccount}
          account={this.state.updatingAccount}
          companyChannelsInfo={this.state.companyChannelsInfo}
          showCompanyChannelsInfo={this.state.enableCompanyChannelsField}
        />
        <CarrierBillingAccountDeleteDialog
          show={this.state.showDelete}
          hideModal={this.hideDeleteDialog}
          account={this.state.deletingAccount}
          handleSubmit={this.handleDeleteAccount}
        />
      </div>
    );
  }

  private handleCreateAccountFieldChange = (fieldName: CarrierBillingAccountField, newValue) => {
    this.setState((prevState) => {
      const modifiedAccount = {...prevState.creatingAccount};
      modifiedAccount[fieldName] = newValue;
      return {
        creatingAccount: modifiedAccount
      };
    });
  };

  private handleUpdateAccountFieldChange = (fieldName: CarrierBillingAccountField, newValue) => {
    this.setState((prevState) => {
      const modifiedAccount = {...prevState.updatingAccount};
      modifiedAccount[fieldName] = newValue;
      return {
        updatingAccount: modifiedAccount
      };
    });
  };

  private handleCreateAccount = async () => {
    this.setState({loading: true, showCreate: false});
    const {errors} = await this.props.carrierBillingAccountService.createAccount(this.state.creatingAccount);
    if (errors.length > 0) {
      this.setState({errors, loading: false});
    } else {
      this.loadAccounts();
    }

    this.setState({creatingAccount: null});
  };

  private handleUpdateAccount = async () => {
    this.setState({loading: true, showUpdate: false});
    const {errors} = await this.props.carrierBillingAccountService.updateAccount(this.state.updatingAccount);
    if (errors.length > 0) {
      this.setState({errors, loading: false});
    } else {
      this.loadAccounts();
    }

    this.setState({updatingAccount: null});
  };

  private handleDeleteAccount = async () => {
    this.setState({loading: true, showDelete: false});
    const {errors} = await this.props.carrierBillingAccountService.deleteAccount(this.state.deletingAccount);
    if (errors.length > 0) {
      this.setState({errors, loading: false});
    } else {
      this.loadAccounts();
    }
    this.setState({deletingAccount: null});
  };

  private async loadCompanyChannels() {
    this.setState({errors: []});
    let newErrors = [];

    try {
      const vendorsResponse = await this.props.vendorService.getVendors();
      const companyChannelsInfo = vendorsResponse.reduce((map, vendor) => {
        map[vendor.id] = vendor.vendorName;
        return map;
      }, {});

      this.setState({companyChannelsInfo});
    } catch (err) {
      newErrors = newErrors.concat(err);
    } finally {
      this.setState({errors: newErrors});
    }
  }

  private async loadAccounts() {
    const {accounts, errors} = await this.props.carrierBillingAccountService.getAccounts();
    this.setState({
      accounts,
      errors,
      loading: false
    });
  }

  private async loadFeatureFlags() {
    try {
      const [enableCompanyChannelsFieldFF] = await axios.all([
        this.props.warehouseService.getFeatureFlag('enable_company_channels_field_on_cba')
      ]);
      this.setState({enableCompanyChannelsField: enableCompanyChannelsFieldFF.data.value});
    } catch (errorResponse) {
      /* Defaulted to false already */
    }
  }

  private showCreateDialog = () => {
    this.setState({
      showCreate: true,
      creatingAccount: cloneDeep(this.emptyAccount)
    });
  };

  private hideCreateDialog = () => {
    this.setState({
      showCreate: false,
      creatingAccount: null
    });
  };

  private showUpdateDialog = (id) => {
    this.setState((prevState) => {
      const accountForId = prevState.accounts.find((acct) => acct.id === id);
      return {
        showUpdate: true,
        updatingAccount: cloneDeep(accountForId)
      };
    });
  };

  private hideUpdateDialog = () => {
    this.setState({
      showUpdate: false,
      updatingAccount: null
    });
  };

  private showDeleteDialog = (id) => {
    this.setState((prevState) => {
      const accountForId = prevState.accounts.find((acct) => acct.id === id);

      return {
        showDelete: true,
        deletingAccount: cloneDeep(accountForId)
      };
    });
  };

  private hideDeleteDialog = () => {
    this.setState({
      showDelete: false,
      deletingAccount: null
    });
  };
}

export default CarrierBillingAccountsV2;
