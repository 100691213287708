import * as React from 'react';
import {Reservation} from '../../shipper/outbound-orders/ReservationsInterfaces';
import {Filter, FilterOption, FilterType, FilterValue} from '../CommonInterfaces';
import Filters from '../Filters';
import {ShipmentPackaging} from './Interfaces';

interface ShipmentPackagingListProps {
  handleUpdate: (id: number) => void;
  handleDelete: (id: number) => void;
  handleFetchPackagings: (filters: Filter[]) => void;
  shipmentPackagings: ShipmentPackaging[];
  isShipper: boolean;
  reservations: Reservation[];
  filters: object;
}

interface ShipmentPackagingListItemProps {
  key: number;
  index: number;
  handleUpdate: () => void;
  handleDelete: () => void;
  shipmentPackaging: ShipmentPackaging;
  isShipper: boolean;
}

const ShipmentPackagingListItem: React.FC<ShipmentPackagingListItemProps> = (props) => {
  const {handleUpdate, handleDelete, shipmentPackaging, index, isShipper} = props;

  return (
    <div className="space-below-lg default-overbox-list-item">
      <h3>{shipmentPackaging.description || `Default Overbox ${index}`}</h3>
      <p>
        <b className="default-overbox-list-item__label">Reservation:</b>
        <span>{shipmentPackaging.reservationId || '--'}</span>
      </p>
      <p>
        <b className="default-overbox-list-item__label">Height:</b>
        <span>{shipmentPackaging.height} inches</span>
      </p>
      <p>
        <b className="default-overbox-list-item__label">Length:</b>
        <span>{shipmentPackaging.length} inches</span>
      </p>
      <p>
        <b className="default-overbox-list-item__label">Width:</b>
        <span>{shipmentPackaging.width} inches</span>
      </p>
      <p>
        <b className="default-overbox-list-item__label">Weight:</b>
        <span>{shipmentPackaging.weight ? `${shipmentPackaging.weight} lbs` : '--'}</span>
      </p>
      <p>
        <b className="default-overbox-list-item__label">Box Type:</b>
        <span>{shipmentPackaging.boxType}</span>
      </p>
      <p>
        <b className="default-overbox-list-item__label">Barcode:</b>
        <span>{shipmentPackaging.barcode || '--'}</span>
      </p>
      {isShipper && (
        <div>
          <p>
            <a className="default-overbox-list-item__edit" onClick={handleUpdate}>
              <i className="fa fa-pencil"></i>
              Edit
            </a>
            <a className="default-overbox-list-item__delete" onClick={handleDelete}>
              <i className="fa fa-trash"></i>
              Delete
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

const ShipmentPackagingList: React.FC<ShipmentPackagingListProps> = (props) => {
  const {handleUpdate, handleDelete, shipmentPackagings, reservations} = props;

  const getFilters = () => {
    const reservationOptions: FilterOption[] = reservations.map((r: Reservation) => {
      let displayName: string;
      if (props.isShipper) {
        displayName = `${r.id}: ${r.warehouse.name}, ${r.warehouse.address.locality}`;
      } else {
        displayName = `${r.id}: ${r.depositor.name}`;
      }
      return {
        displayName,
        value: r.id
      };
    });
    const filters: Filter[] = [
      {
        displayName: 'Reservation',
        key: 'reservationIds',
        type: FilterType.Dropdown,
        allowMultiple: true,
        options: [
          {
            displayName: 'Choose a Reservation',
            value: ''
          },
          ...reservationOptions
        ],
        value: getFilterValue('reservationIds')
      },
      {
        displayName: 'Type',
        key: 'boxTypes',
        type: FilterType.Dropdown,
        allowMultiple: true,
        options: [
          {displayName: 'Choose a packaging type', value: ''},
          {displayName: 'Box', value: 'box'},
          {displayName: 'Package', value: 'package'},
          {displayName: 'Envelope', value: 'envelope'}
        ],
        value: getFilterValue('boxTypes')
      },
      {
        displayName: 'Packaging Name',
        key: 'descriptions',
        type: FilterType.String,
        allowMultiple: true,
        value: getFilterValue('descriptions')
      },
      {
        displayName: 'Barcode',
        key: 'barcodes',
        type: FilterType.String,
        allowMultiple: true,
        value: getFilterValue('barcodes')
      }
    ];
    return filters;
  };

  const handleFilterChange = (filterData: FilterValue[]) => {
    // TODO FE-574: This looks legitimately broken - handleFetchPackagins expects FilterValue[], compactFilters returns a map.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props.handleFetchPackagings(compactFilters(filterData));
  };

  const compactFilters = (filterData: FilterValue[]) => {
    const filters = {};
    filterData.forEach((fData: FilterValue) => {
      if (fData.filter.type === FilterType.Present || fData.filter.type === FilterType.Radio) {
        filters[fData.filter.key] = fData.value;
      } else {
        if (filters[fData.filter.key]) {
          filters[fData.filter.key].push(fData.value);
        } else {
          filters[fData.filter.key] = [fData.value];
        }
      }
    });
    return filters;
  };

  const getFilterValue = (key: string) => {
    // eslint-disable-next-line no-prototype-builtins
    if (props.filters.hasOwnProperty(key)) {
      return props.filters[key];
    }
    return null;
  };

  return (
    <div className="shipment-packaging-list">
      <div className="shipment-packaging-filters row">
        <Filters filters={getFilters()} filterChangeHandler={handleFilterChange} />
      </div>
      <div className="default-overbox-list row">
        {shipmentPackagings.length < 1 && <p>No packagings have been configured.</p>}
        {shipmentPackagings
          .sort((a, b) => a.description.localeCompare(b.description))
          .map((packaging, index) => (
            <ShipmentPackagingListItem
              key={packaging.id}
              index={index + 1}
              handleUpdate={() => handleUpdate(packaging.id)}
              handleDelete={() => handleDelete(packaging.id)}
              shipmentPackaging={packaging}
              isShipper={props.isShipper}
            />
          ))}
      </div>
    </div>
  );
};

export {ShipmentPackagingListItem, ShipmentPackagingList};
export default ShipmentPackagingList;
