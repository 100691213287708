import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {get} from 'lodash';
import {ApiResponse} from '../../shared/CommonInterfaces';

interface RetryShipmentsModalProps {
  onSubmit: () => Promise<ApiResponse<{}>>; // eslint-disable-line @typescript-eslint/ban-types
  onClose: () => void;
  show: boolean;
  numberOfShipments: number;
  retryExtraDescription?: JSX.Element;
}

const RetryShipmentsModal: React.FC<RetryShipmentsModalProps> = (props) => {
  const [submitState, setSubmitState] = React.useState<'open' | 'clicked' | 'submitted' | 'error'>('open');
  const [errorMessage, setErrorMessage] = React.useState('');
  const retryExtraDescription = props.retryExtraDescription ? props.retryExtraDescription : <div></div>;
  const onSubmitHandler = async () => {
    try {
      setSubmitState('clicked');
      await props.onSubmit();
      setSubmitState('submitted');
    } catch (error) {
      setSubmitState('error');
      setErrorMessage(processErrorResponse(error));
    }
  };

  let message;
  let primaryButtonText;
  switch (submitState) {
    case 'open':
      // eslint-disable-next-line no-case-declarations
      const isPlural = props.numberOfShipments > 1;
      message = `Are you sure you want to retry ${props.numberOfShipments} shipment${isPlural ? 's' : ''}? `;
      primaryButtonText = 'Retry Shipments';
      break;
    case 'clicked':
      message = 'Shipments are being processed. Please wait a few minutes.';
      primaryButtonText = 'Ok';
      break;
    case 'submitted':
      message = 'Shipments have been retried. Check back in a few minutes for updates.';
      primaryButtonText = 'Ok';
      break;
    case 'error':
      message = 'Encountered an error while retrying shipments. ' + errorMessage;
      primaryButtonText = 'Ok';
      break;
  }
  return (
    <LegacyModal
      id="retry-shipment-modal"
      title="Retry e-commerce fulfillment shipments"
      show={props.show}
      size="small"
      toggleModal={props.onClose}
      footer={
        <div>
          {submitState === 'open' && <a onClick={props.onClose}>Cancel</a>}
          <button
            id="retry-agree"
            type="button"
            className="btn"
            onClick={submitState === 'open' ? onSubmitHandler : props.onClose}
            disabled={submitState === 'clicked'}
          >
            {primaryButtonText}
          </button>
        </div>
      }
      disableClose={false}
    >
      <React.Fragment>
        <p>{message}</p>
        {retryExtraDescription}
      </React.Fragment>
    </LegacyModal>
  );
};

function processErrorResponse(errorResponse: Response) {
  const errors = get(errorResponse, 'response.data.errors');
  return errors.map((error) => error.detail).join(',');
}

export default RetryShipmentsModal;
