import * as React from 'react';

function StepOne(props) {
  const reservationOptions = props.reservations.map((reservation, index) => {
    return (
      <option value={reservation.id} key={index}>
        {reservation.name}
      </option>
    );
  });
  return (
    <li id="step_1" className="active">
      <span className="label">1</span>
      <div className="step-wrap">
        <h3>
          <span>Select a Warehouse</span>
        </h3>
        <label htmlFor="reservations">Currently Active Reservations:</label>
        <select id="reservations-dropdown" name="reservations" onChange={props.onChange}>
          <option value="">Choose a Warehouse Reservation</option>
          {reservationOptions}
        </select>
      </div>
    </li>
  );
}

export default StepOne;
