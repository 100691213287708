import * as React from 'react';
import {OMSReservation} from '../../ecommerce-orders/OrdersAppInterfaces';
import {FilterOption} from '../../../shared/CommonInterfaces';

const placeholderOption: FilterOption[] = [
  {
    displayName: 'Choose a reservation...',
    value: ''
  }
];

export const buildReservationOptions = (reservations: OMSReservation[]): FilterOption[] => {
  const options: FilterOption[] = reservations
    .filter((res) => {
      return res.txnState !== 'new';
    })
    .map((res) => {
      return {
        displayName: res.displayName,
        value: res.id.toString()
      };
    });

  return placeholderOption.concat(options).sort((a, b) => {
    return a.value > b.value ? 1 : a.value < b.value ? -1 : 0;
  });
};

export const getTableDisplayValueForReservations = (
  requestedReservationIds: string[],
  plannedReservationIds: string[],
  reservations: OMSReservation[]
): string | JSX.Element => {
  requestedReservationIds = requestedReservationIds || [];
  plannedReservationIds = plannedReservationIds || [];

  const orderReservations = new Set([...requestedReservationIds, ...plannedReservationIds]);
  if (orderReservations.size > 1) {
    return <i>multiple</i>;
  }

  if (orderReservations.size === 1) {
    return getDisplayNameForReservationId(orderReservations.keys().next().value, reservations);
  }

  return '-';
};

export const getDisplayNameForReservationId = (reservationId: string, reservations: OMSReservation[]): string => {
  const matchingReservation = reservations.find((r) => r.id.toString() === reservationId);
  // fallback to id just in case something went wrong instead of displaying nothing
  return matchingReservation ? matchingReservation.displayName : reservationId;
};
