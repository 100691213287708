import * as React from 'react';
import {Table} from '@flexe/ui-components';
import {TableData} from '@flexe/ui-components';
import {RetailOrder} from '../shared/services/RetailFulfillmentService';
import {Packaging} from '../shared/CommonInterfaces';

interface ModalConfirmShipmentDetailsTableProps {
  selectedOrders: RetailOrder[];
}

const ModalConfirmShipmentDetailsTable: React.FC<ModalConfirmShipmentDetailsTableProps> = (props) => {
  const getTableData = (): TableData => {
    const rowData = props.selectedOrders.map((order) => {
      let totalEaches = 0;
      let totalCartons = 0;
      if (order.orderItems) {
        order.orderItems.forEach((item) => {
          if (item.packaging === Packaging.each) {
            totalEaches += item.quantity;
          } else if (item.packaging === Packaging.carton) {
            totalCartons += item.quantity;
          }
        });
      }
      return [order.id, order.customerUuid, totalEaches, totalCartons];
    });
    return {
      headers: [{element: 'Shipment ID'}, {element: 'PO'}, {element: 'Eaches'}, {element: 'Cartons'}],
      rows: rowData
    };
  };

  return <Table tableClass="modal-shipment-details-table" tableData={getTableData()} />;
};

export default ModalConfirmShipmentDetailsTable;
