import {isPast, isToday, isValid, parse} from 'date-fns';
import * as Papa from 'papaparse';
import {CsvErrorType} from '../csv-upload/CsvUploadInterfaces';

const CsvUtil = {
  validateDateTimes: (data) => {
    let moveDateError;
    let parsedMoveDate;

    if (data.moveDate) {
      try {
        parsedMoveDate = parse(data.moveDate);
      } catch {
        // leave parsedMoveDate undefined
      }

      if (isValid(parsedMoveDate)) {
        if (!isToday(parsedMoveDate) && isPast(parsedMoveDate)) {
          moveDateError = CsvErrorType.DateInPast;
        }
      } else {
        moveDateError = CsvErrorType.InvalidDateTime;
      }
    } else {
      moveDateError = CsvErrorType.Required;
    }

    return {parsedMoveDate, moveDateError};
  },

  validateHeaders: (
    templateLocation: string,
    file: File,
    requiredFields: string[],
    optionalFields: string[],
    validCallback: () => void,
    notValidCallBack: () => void
  ) => {
    const reader = new FileReader();
    reader.readAsText(file);
    // This is a bug in the Typescript compiler. It is fixed in version 3.6, and
    // we can remove this cast to "any" when we upgrade:
    // https://github.com/microsoft/TypeScript/issues/25510
    reader.onload = (event: any) => {
      const headers = Papa.parse(event.target.result.toString(), {
        header: false,
        preview: 1
      });
      // if all required values are present and all headers are recognized
      if (
        requiredFields.every((h) => headers.data[0].includes(h)) &&
        headers.data[0].every((h) => optionalFields.concat(requiredFields).includes(h))
      ) {
        validCallback();
      } else {
        notValidCallBack();
      }
    };
  },

  fetchTemplateCSV: async (templateLocation: string) => {
    return fetch(templateLocation).then((response) => {
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      return reader.read().then((result) => {
        return decoder.decode(result.value);
      });
    });
  }
};

export default CsvUtil;
