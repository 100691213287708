import * as React from 'react';
import {get} from 'lodash';
import {LegacyModal} from '@flexe/ui-components';
import RetailFulfillmentService from '../../shared/services/RetailFulfillmentService';
import {
  Address,
  RetailFulfillmentUpdateRequest,
  ShipmentInfo,
  ShipmentInfoOptions
} from './RetailFulfillmentInterfaces';

interface UpdateProps {
  authenticityToken: string;
  buttonText: string;
  orderId: number;
  address?: Address;
  buttonClass?: string;
  shipmentInfo?: ShipmentInfo;
  shipmentInfoOptions?: ShipmentInfoOptions;
}

interface UpdateState {
  address: Address;
  editAddress: boolean;
  shipmentInfo: ShipmentInfo;
  showModal: boolean;
  error?: string;
}

class UpdateRetailFulfillmentOrder extends React.Component<UpdateProps, UpdateState> {
  private rfoService: RetailFulfillmentService;
  constructor(props) {
    super(props);
    this.state = {
      address: {
        name: get(this.props.address, 'name', ''),
        addressLine1: get(this.props.address, 'addressLine1', ''),
        addressLine2: get(this.props.address, 'addressLine2', ''),
        addressLine3: get(this.props.address, 'addressLine3', ''),
        locality: get(this.props.address, 'locality', ''),
        region: get(this.props.address, 'region', ''),
        postcode: get(this.props.address, 'postcode', ''),
        country: get(this.props.address, 'country', '')
      },
      editAddress: false,
      shipmentInfo: this.props.shipmentInfo
        ? {
            // only TBD can be updated right now, so we are only passing this in
            shipmentType: get(this.props.shipmentInfo, 'shipmentType', '')
          }
        : null,
      showModal: false
    };
    this.rfoService = new RetailFulfillmentService(this.props.authenticityToken);
  }

  public render() {
    return (
      <div id="rfo-update-component">
        <a
          id="update-button"
          className={this.props.buttonClass ? this.props.buttonClass : 'btn'}
          onClick={this.toggleModal}
        >
          {this.props.buttonText}
        </a>
        <LegacyModal
          id={`order-${this.props.orderId}-modal`}
          show={this.state.showModal}
          size="small"
          toggleModal={this.toggleModal}
          title={this.state.shipmentInfo ? 'Update Shipment Method' : 'Update Address'}
          footer={
            <div>
              <button className="btn close-button" onClick={this.toggleModal}>
                Cancel
              </button>
              <button className="btn close-button" onClick={this.updateOrder}>
                {this.state.shipmentInfo || this.state.editAddress ? 'Update' : 'Bypass Validation'}
              </button>
            </div>
          }
        >
          <div>
            {!this.state.shipmentInfo && !this.state.editAddress && this.showOriginalAddress()}
            {(this.state.shipmentInfo || this.state.editAddress) && this.getEditForm()}
          </div>
        </LegacyModal>
      </div>
    );
  }

  private showOriginalAddress() {
    return (
      <div className="original-address">
        <h5>Original Address</h5>
        <a onClick={this.handleEditAddress}>
          <i className="fa fa-pencil"></i>
        </a>
        <address>
          {this.state.address.addressLine1} <br />
          {this.state.address.addressLine2 && (
            <span>
              {this.state.address.addressLine2}
              <br />
            </span>
          )}
          {this.state.address.addressLine3 && (
            <span>
              {this.state.address.addressLine3}
              <br />
            </span>
          )}
          {`${this.state.address.locality}, ${this.state.address.region} ${this.state.address.postcode}`} <br />
          {this.state.address.country}
        </address>
      </div>
    );
  }

  private getEditForm() {
    return (
      <div>
        {this.state.error && (
          <div className="alert alert-danger">
            <span>{this.state.error}</span>
            <div className="cancel-alert">
              <a onClick={this.handleDismissAlert}>
                <i className="fa fa-times"></i>
              </a>
            </div>
          </div>
        )}
        {!this.state.shipmentInfo && this.state.address && this.updateAddressForm()}
        {this.state.shipmentInfo && this.updateShipmentInfoForm()}
      </div>
    );
  }

  private updateAddressForm() {
    return (
      <div className="update-address-form">
        <h4 className="grey0 ">Destination</h4>
        <div className="form-group required">
          <input
            className="form-control"
            id="name"
            name="name"
            placeholder="Ship to..."
            value={this.state.address.name}
            onChange={this.updateAddressField}
            required={true}
          />
        </div>
        <div className="form-group required">
          <input
            className="form-control"
            id="addressLine1"
            name="addressLine1"
            placeholder="Address Line 1..."
            value={this.state.address.addressLine1}
            onChange={this.updateAddressField}
            required={true}
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            id="addressLine2"
            name="addressLine2"
            placeholder="Address Line 2..."
            value={this.state.address.addressLine2}
            onChange={this.updateAddressField}
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            id="addressLine3"
            name="addressLine3"
            placeholder="Address Line 3..."
            value={this.state.address.addressLine3}
            onChange={this.updateAddressField}
          />
        </div>
        <div className="form-inline">
          <div className="form-group required">
            <input
              className="form-control"
              id="locality"
              name="locality"
              placeholder="City..."
              value={this.state.address.locality}
              onChange={this.updateAddressField}
              required={true}
            />
          </div>
          <div className="form-group required">
            <input
              className="form-control"
              id="region"
              name="region"
              placeholder="State or Province..."
              value={this.state.address.region}
              onChange={this.updateAddressField}
              required={true}
            />
          </div>
        </div>
        <div className="form-inline">
          <div className="form-group required">
            <input
              className="form-control"
              id="postcode"
              name="postcode"
              placeholder="Postal Code..."
              value={this.state.address.postcode}
              onChange={this.updateAddressField}
              required={true}
            />
          </div>
          <div className="form-group required">
            <input
              className="form-control"
              id="country"
              name="country"
              placeholder="Country (ex: US, CA)..."
              value={this.state.address.country}
              onChange={this.updateAddressField}
              required={true}
            />
          </div>
        </div>
      </div>
    );
  }

  private updateShipmentInfoForm() {
    const {shipmentInfoOptions} = this.props;

    const shipmentTypeOptions = shipmentInfoOptions.shipmentTypeOptions.map((option, index) => (
      <option key={index} value={option[1]}>
        {option[0]}
      </option>
    ));
    const shipmentMethodOptions = shipmentInfoOptions.shipmentMethodOptions.map((option, index) => {
      if (option[1] !== 'vrs' && !(this.state.shipmentInfo.shipmentType === 'ltl' && option[1] === 'generated')) {
        return (
          <option key={index} value={option[1]}>
            {option[0]}
          </option>
        );
      }
    });
    const carrierOptions = shipmentInfoOptions.carrierOptions.map((option, index) => (
      <option key={index} value={option[1]}>
        {option[0]}
      </option>
    ));

    let serviceTypeOptions = null;
    let carrierBillingAccountOptions = null;

    const selectedCarrier = this.state.shipmentInfo.carrier;
    if (selectedCarrier) {
      serviceTypeOptions = shipmentInfoOptions.serviceTypeOptions[selectedCarrier].map((service, index) => (
        <option key={index} value={service}>
          {service}
        </option>
      ));

      const carrierBillingAccountsForCarrier = shipmentInfoOptions.carrierBillingAccounts[selectedCarrier] || [];
      carrierBillingAccountOptions = carrierBillingAccountsForCarrier.map((acct) => (
        <option key={acct.id} value={acct.id}>
          {acct.friendlyName}
        </option>
      ));
    }

    return (
      <div className="update-shipment-info-form">
        <h4 className="grey0 ">Shipment Details</h4>
        <div className="form-group">
          <select
            className="form-control"
            id="shipmentType"
            name="shipmentType"
            value={this.state.shipmentInfo.shipmentType}
            onChange={this.updateShipmentInfoField}
          >
            <option value="">Select Shipment Type</option>
            {shipmentTypeOptions}
          </select>
        </div>
        {this.state.shipmentInfo.shipmentType && this.state.shipmentInfo.shipmentType !== 'tbd' && (
          <div className="form-group">
            <select
              className="form-control"
              id="shipmentMethod"
              name="shipmentMethod"
              value={this.state.shipmentInfo.shipmentMethod}
              onChange={this.updateShipmentInfoField}
            >
              <option value="">Select Shipment Method</option>
              {shipmentMethodOptions}
            </select>
          </div>
        )}
        {this.state.shipmentInfo.shipmentMethod === 'generated' && (
          <div>
            <div className="form-group">
              <select
                className="form-control"
                id="carrier"
                name="carrier"
                value={this.state.shipmentInfo.carrier}
                onChange={this.updateShipmentInfoField}
              >
                <option value="">Select Carrier</option>
                {carrierOptions}
              </select>
            </div>
            <div className="form-group">
              <select
                className="form-control"
                id="serviceType"
                name="serviceType"
                value={this.state.shipmentInfo.serviceType}
                onChange={this.updateShipmentInfoField}
              >
                <option value="">Select Service Type</option>
                {serviceTypeOptions}
              </select>
            </div>
            {carrierBillingAccountOptions && (
              <div className="form-group">
                <select
                  className="form-control"
                  id="carrierBillingAccountId"
                  name="carrierBillingAccountId"
                  value={this.state.shipmentInfo.carrierBillingAccountId}
                  onChange={this.updateShipmentInfoField}
                >
                  <option value="">Select Carrier Billing Account</option>
                  {carrierBillingAccountOptions}
                </select>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  private toggleModal = () => {
    this.setState({
      editAddress: false,
      showModal: !this.state.showModal
    });
  };

  private updateAddressField = (event) => {
    const address = Object.assign({}, this.state.address);
    address[event.target.name] = event.target.value;
    this.setState({
      address
    });
  };

  private updateShipmentInfoField = (event) => {
    const shipmentInfo = Object.assign({}, this.state.shipmentInfo);
    shipmentInfo[event.target.name] = event.target.value;
    if (event.target.name === 'shipmentType') {
      delete shipmentInfo.shipmentMethod;
      delete shipmentInfo.carrier;
      delete shipmentInfo.serviceType;
      delete shipmentInfo.carrierBillingAccountId;
    }
    this.setState({
      shipmentInfo
    });
  };

  private updateOrder = async () => {
    const request: RetailFulfillmentUpdateRequest = {
      address: this.state.shipmentInfo ? null : this.state.address,
      shipmentInfo: this.state.shipmentInfo,
      forceGivenAddress: !this.state.editAddress
    };
    const response = await this.rfoService.updateOrder(this.props.orderId, request);
    if (response && response.errors.length === 0) {
      this.toggleModal();
      // Reload the page for now to show new data
      window.location.reload();
    } else {
      let message = 'There was an error updating the order';
      if (response) {
        const serverErrors = response.errors.map((e) => e.detail);
        message += `: ${serverErrors.join(', ')}`;
      }
      this.setState({
        error: message
      });
    }
  };

  private handleDismissAlert = () => {
    this.setState({
      error: null
    });
  };

  private handleEditAddress = () => {
    this.setState({
      editAddress: true
    });
  };
}

export default UpdateRetailFulfillmentOrder;
