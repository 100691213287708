import * as React from 'react';
import {cloneDeep, get} from 'lodash';
import {LegacyModal} from '@flexe/ui-components';
import LocationsService, {LocationContent} from '../../locations/LocationsService';
import {Packaging} from '../../shared/CommonInterfaces';
import ContentSummaryWithShortages, {OrderContent} from './ContentSummeryWithShortages';
import OutboundStagingTypeAhead from './OutboundStagingTypeAhead';

interface CompleteState {
  confirmClose: boolean;
  orderContents: OrderContent[];
  selectedLocation: string;
  showModal: boolean;
  errors?: string;
  overShipped: boolean;
  closeButtonOneShot: boolean;
}

interface CompleteProps {
  authenticityToken: string;
  displayOnly?: boolean;
  enableLpn: boolean;
  expectedContents: ExpectedContent[];
  orderId: number;
  warehouseId: number;
  reservationId: number;
  blockOverShipment: boolean;
}

export interface UnitConversion {
  each: number;
  carton: number;
}

interface ExpectedContent {
  inventory_description: string;
  inventory_id: number;
  packaging: Packaging;
  quantity: number;
  sku: string;
  unit_conversions: UnitConversion;
}

// DEPRECATED
class CompleteWithLocations extends React.Component<CompleteProps, CompleteState> {
  private locationsService: LocationsService;
  constructor(props) {
    super(props);

    this.state = {
      confirmClose: false,
      orderContents: this.calculateContents(this.props.expectedContents),
      selectedLocation: '',
      showModal: false,
      overShipped: false,
      closeButtonOneShot: false
    };

    this.locationsService = new LocationsService(this.props.authenticityToken);

    this.handleTypeAheadSelect = this.handleTypeAheadSelect.bind(this);
  }

  public render() {
    let title = '';
    let button = null;
    let footer = null;
    if (this.props.enableLpn) {
      title = this.props.displayOnly
        ? `Preview Retail Fulfillment Order ${this.props.orderId}`
        : `Review and Close: Order ${this.props.orderId}`;
      button = (
        <a
          className={this.props.displayOnly ? 'btn cta preview-rfo' : 'btn cta complete-rfo'}
          onClick={this.toggleModal}
        >
          {this.props.displayOnly ? 'Preview Location Contents' : 'Review and Close Order'}
        </a>
      );
      footer = (
        <div className="footer">
          {this.props.displayOnly && (
            <button id="ok-button" className="btn cta" onClick={this.toggleModal}>
              Close
            </button>
          )}
          {!this.props.displayOnly && (
            <div className="row">
              <div className="confirm-close-group col-md-9">
                <label htmlFor="confirm-close">
                  <input
                    type="checkbox"
                    name="confirm-close"
                    id="confirm-close"
                    checked={this.state.confirmClose}
                    onChange={this.confirmClose}
                  />
                  By closing this order, I agree that I have reviewed all shortages and discrepancies and that they are
                  accurate.
                </label>
              </div>
              <div className="col-md-3">
                <button
                  disabled={this.checkDisabled()}
                  onClick={this.submitAndClose}
                  id="complete-button"
                  className="btn cta"
                >
                  Close Order
                </button>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      title = `${this.props.displayOnly ? 'Preview' : 'Complete'} Retail Fulfillment Order ${this.props.orderId}`;
      button = (
        <a className="btn secondary cta complete-rfo" onClick={this.toggleModal}>
          <i className="fa fa-check"></i> {this.props.displayOnly ? 'Preview Location Contents' : 'Complete Shipment'}
        </a>
      );
      footer = (
        <div>
          {this.props.displayOnly && (
            <button id="ok-button" className="btn cta" onClick={this.toggleModal}>
              Close
            </button>
          )}
          {!this.props.displayOnly && (
            <div>
              <a id="back-button" className="btn flat" onClick={this.toggleModal}>
                Back
              </a>
              <button
                disabled={this.isCompleteDisabled()}
                onClick={this.submitAndClose}
                id="complete-button"
                className="btn cta"
              >
                Complete
              </button>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="complete-with-locations-component">
        {button}
        <LegacyModal
          id="complete-with-locations-modal"
          size={this.props.enableLpn ? 'medium' : 'small'}
          show={this.state.showModal}
          toggleModal={this.toggleModal}
          title={title}
          footer={footer}
        >
          <div>
            <OutboundStagingTypeAhead
              authenticityToken={this.props.authenticityToken}
              warehouseId={this.props.warehouseId}
              locationsService={this.locationsService}
              onSelectCallback={this.handleTypeAheadSelect}
            />
            {this.props.enableLpn && (
              <ContentSummaryWithShortages
                contents={this.state.orderContents}
                blockOverShipment={this.props.blockOverShipment}
              />
            )}
            <input type="hidden" name="outbound_location_id" value={this.state.selectedLocation} />
          </div>
        </LegacyModal>
      </div>
    );
  }

  private confirmClose = (e) => {
    this.setState({confirmClose: e.target.checked});
  };

  private checkDisabled = () => {
    return (
      this.isCompleteDisabled() ||
      !this.state.confirmClose ||
      (this.props.blockOverShipment && this.state.overShipped) ||
      this.state.closeButtonOneShot
    );
  };

  private submitAndClose = () => {
    this.setState({closeButtonOneShot: true});
    this.toggleModal();
  };

  private isCompleteDisabled = (): boolean => {
    const validLpn =
      this.props.enableLpn &&
      this.state.orderContents.some((orderContent) => {
        return orderContent.actualQuantity && orderContent.actualQuantity.amount > 0;
      });
    const validLocation = !this.props.enableLpn && this.state.selectedLocation;
    return (!validLocation && !validLpn) || this.state.closeButtonOneShot;
  };

  private handleTypeAheadSelect = (locationId: string, locationContents: LocationContent[]) => {
    this.setState({
      selectedLocation: locationId,
      orderContents: this.calculateContents(this.props.expectedContents, locationContents)
    });
  };

  private consolidateLines = (a: ExpectedContent, b: ExpectedContent) => {
    if (a.packaging === b.packaging) {
      a.quantity += b.quantity;

      this.addConversions(a, b);
    } else if (a.packaging === Packaging.each && b.packaging === Packaging.carton) {
      a.quantity += b.unit_conversions.each;

      this.addConversions(a, b);
    } else {
      // a == carton and b == each

      // Down-convert a to eaches, then add eaches from b
      a.packaging = Packaging.each;
      a.quantity = a.unit_conversions.each + b.quantity;

      this.addConversions(a, b);
    }
  };

  private addConversions = (a: ExpectedContent, b: ExpectedContent) => {
    a.unit_conversions.each += b.unit_conversions.each;
    a.unit_conversions.carton += b.unit_conversions.carton;
  };

  private isLineOvershipped = (line: OrderContent) => {
    return line.actualQuantity ? line.actualQuantity.amount > line.expectedQuantity.amount : false;
  };

  private calculateContents(
    expectedContents: ExpectedContent[],
    locationContents: LocationContent[] = null
  ): OrderContent[] {
    const orderContents: OrderContent[] = [];
    const expectedSkus: string[] = [];

    const consolidatedContents: ExpectedContent[] = [];

    expectedContents.forEach((content) => {
      const duplicate = consolidatedContents.find((x) => x.sku === content.sku);

      if (duplicate) {
        this.consolidateLines(duplicate, content);
      } else {
        consolidatedContents.push(cloneDeep(content));
      }
    });

    consolidatedContents.map((content) => {
      expectedSkus.push(content.sku);

      const orderContent: OrderContent = {
        sku: content.sku,
        description: content.inventory_description,
        expectedQuantity: {
          amount: content.quantity,
          unit: content.packaging,
          conversions: {
            each: content.unit_conversions.each
          }
        },
        lpnBarcodes: []
      };

      if (locationContents !== null) {
        orderContent.actualQuantity = {
          amount: 0,
          unit: content.packaging,
          conversions: {
            each: 0
          }
        };

        const stagedContents = locationContents.filter((locationContent) => {
          if (get(locationContent, 'entity.type') === 'lpn') {
            if (get(locationContent, 'reservation.id') !== this.props.reservationId) {
              return false;
            }

            return locationContent.inventory.id === content.inventory_id;
          }
        });

        stagedContents.map((stagedContent) => {
          if (orderContent.actualQuantity.unit === stagedContent.quantity.unit) {
            orderContent.actualQuantity.amount += stagedContent.quantity.amount;

            const stagedContentEaches = this.getNumeratorFromFraction(stagedContent.quantity.conversions.each.amount);
            orderContent.actualQuantity.conversions.each += stagedContentEaches;
          } else if (
            orderContent.actualQuantity.unit === Packaging.each &&
            stagedContent.quantity.unit === Packaging.carton
          ) {
            // These conversions are represented in fractionals, like "152/5". Since we know that the staged
            // content is already in cartons, we know the number of eaches be evenly divisible by the number
            // of cartons, resulting in something like "168/1". Hence, we just grab the numerator here.
            const numStagedEaches: number = this.getNumeratorFromFraction(
              stagedContent.quantity.conversions.each.amount
            );
            orderContent.actualQuantity.amount += numStagedEaches;
            orderContent.actualQuantity.conversions.each += numStagedEaches;
          } else {
            // orderContent.actualQuantity.unit == carton && stagedContent.qty.unit == each

            const orderContentEaches: number = orderContent.actualQuantity.conversions.each;

            // Down-convert actual qty to eaches
            orderContent.actualQuantity.amount = orderContentEaches + stagedContent.quantity.amount;
            orderContent.actualQuantity.unit = Packaging.each;

            // We want expected qty units to match actual, so down convert this too
            orderContent.expectedQuantity.amount = orderContent.expectedQuantity.conversions.each;
            orderContent.expectedQuantity.unit = Packaging.each;
          }

          orderContent.lpnBarcodes.push(stagedContent.entity.id);
        });
      }

      orderContents.push(orderContent);
    });

    if (locationContents !== null) {
      const remainingContents = locationContents.filter((contents) => {
        if (get(contents, 'entity.type') === 'lpn') {
          if (get(contents, 'reservation.id') !== this.props.reservationId) {
            return false;
          }

          return !expectedSkus.includes(contents.inventory.sku);
        }
      });

      remainingContents.map((additionalContent) => {
        const orderContent: OrderContent = {
          sku: additionalContent.inventory.sku,
          description: '',
          expectedQuantity: {
            amount: 0,
            unit: additionalContent.quantity.unit,
            conversions: {
              each: 0
            }
          },
          actualQuantity: {
            amount: additionalContent.quantity.amount,
            unit: additionalContent.quantity.unit,
            conversions: {
              each: this.getNumeratorFromFraction(additionalContent.quantity.conversions.each.amount)
            }
          },
          lpnBarcodes: [additionalContent.entity.id]
        };

        orderContents.push(orderContent);
      });
    }

    if (orderContents.some(this.isLineOvershipped)) {
      this.setState({overShipped: true});
    }

    return orderContents;
  }

  private getNumeratorFromFraction = (fraction: string) => {
    return parseInt(fraction.split('/')[0], 10);
  };

  private toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };
}

export default CompleteWithLocations;
