import {PickMethod} from '../warehouse/ecommerce-batches/BatchInterfaces';
import {TransitionState} from './CommonInterfaces';
// Shared constants

/* Date/Time formatting */
export const dateFormat = 'MM-DD-YYYY';
export const iso8601Format = 'YYYY-MM-DD';
export const time24hourFormat = 'HH:mm';

export const TransitionStateDisplay = new Map([
  [TransitionState.New, 'Unconfirmed'],
  [TransitionState.Confirmed, 'Confirmed'],
  [TransitionState.Completed, 'Completed'],
  [TransitionState.Cancelled, 'Cancelled'],
  [TransitionState.NotStarted, 'Not Started'],
  [TransitionState.InProgress, 'In Progress'],
  [TransitionState.Active, 'Active']
]);

export const PickMethodDisplay = new Map([
  [PickMethod.BULK, 'Bulk Pick'],
  [PickMethod.CLUSTER, 'Cluster Pick']
]);

/* Ship Modes */
export const FreightShipMode = 'freight';
export const ParcelShipMode = 'parcel';
export type ShipMode = typeof FreightShipMode | typeof ParcelShipMode;

/* Order Sources */
export const OrderSourceUIOmnichannel = 'ui-omnichannel';
export const OrderSourceUIECommerce = 'ui-ecommerce';
