import * as React from 'react';
import {ShipperUsersData} from '../ShipperInfoInterfaces';

export interface Props {
  onUserEmailChange: (newSelectedEmail: string) => void;
  selectedUserEmail: string;
  allUserEmailData: ShipperUsersData[];
}

const ShipperUserEmailSelect: React.FC<Props> = (props) => {
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    // Ensure we suppress any non-changes
    if (props.selectedUserEmail !== value) {
      props.onUserEmailChange(value);
    }
  };

  // Note the initial implementation uses asterisk to denote required.
  // As this gets expanded, that should probably be a configuration param
  return (
    <select onChange={onChange} value={props.selectedUserEmail}>
      <option value="" key="placeholder_value" hidden>
        Select a shipper user email
      </option>
      {props.allUserEmailData.map((emailData) => (
        <option value={emailData.email} key={emailData.email}>{`${emailData.email}`}</option>
      ))}
    </select>
  );
};
export default ShipperUserEmailSelect;
