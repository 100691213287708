/** @jsxRuntime classic */
/** @jsx jsx */

import {useState} from 'react';
import {css, jsx} from '@emotion/react';
import {Column, Modal, Row, Text} from '@flexe/ui-components';
import {roleToTitleCase} from '../../helpers';
import {SiteType, User} from '../../userManagementInterfaces';

interface Props {
  authenticityToken: string;
  onModalClose: () => void;
  roles: string[];
  siteId: number;
  siteName: string;
  siteType: SiteType;
  users: User[];
}

const containerStyle = css({h3: {marginBottom: 0}, position: 'absolute'});

const AddUserModal = ({authenticityToken, onModalClose, roles, siteId, siteName, siteType, users}: Props) => {
  const [selectedUserId, setSelectedUserId] = useState(-1);
  const [selectedRoleCount, setSelectedRoleCount] = useState(0);

  const handleRoleCheckboxChange = (event) => {
    const {checked} = event.target;
    setSelectedRoleCount((oldCount) => (checked ? oldCount + 1 : oldCount - 1));
  };

  const handleUserSelect = (event) => {
    const userId = parseInt(event.target.value, 10);
    setSelectedUserId(userId);
  };

  return (
    <div css={containerStyle}>
      <Modal isOpen title={`Add User to ${siteName}`} toggleModal={onModalClose} isDismissable>
        <form action="/user_management/add_user" method="post">
          <input type="hidden" name="authenticity_token" value={authenticityToken} />
          <input
            type="hidden"
            name={siteType === SiteType.Reservation ? 'reservation_id' : 'warehouse_id'}
            value={siteId}
          />

          <Column>
            <Row childWidths={['grid-2', 'grid-10']} margin={['0', '200']}>
              <Text tag="label" htmlFor="user-select" type="h90">
                User
              </Text>
              <select id="user-select" name="user_id" onChange={handleUserSelect}>
                <option value="-1">Please select a user</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </Row>
            {roles.map((role) => (
              <Row key={role} childWidths={['fit', 'fit']} margin={['0', '50']}>
                <input type="checkbox" name={role} id={role} onChange={handleRoleCheckboxChange} value="1" />
                <Text tag="label" htmlFor={role} type="h90">
                  {roleToTitleCase(role)}
                </Text>
              </Row>
            ))}

            <div>
              <input
                type="submit"
                value="Add User"
                disabled={selectedRoleCount === 0 || selectedUserId === -1}
                className="btn"
              />
            </div>
          </Column>
        </form>
      </Modal>
    </div>
  );
};

export default AddUserModal;
