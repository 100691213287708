import * as React from 'react';
import {OrderItem} from '../../shared/services/RetailFulfillmentService';
import LineItemRow from './LineItemRow';

interface LineItemsProps {
  orderId: number;
  orderItems: OrderItem[];
  ssccEnabled: boolean;
  ssccLabelsStillGenerating: boolean;
  showLpns: boolean;
}

export const LineItems: React.FC<LineItemsProps> = (props) => {
  const tableHeader = () => {
    return (
      <thead>
        <tr>
          {props.showLpns && <th className="lpn-column">LPN</th>}
          <th className="sku-column">SKU</th>
          <th className="description-column">Description</th>
          <th className="quantity-column">Quantity</th>
          {props.ssccEnabled && <th className="pallets-column"># of Pallets</th>}
          {props.ssccEnabled && <th className="print-column"></th>}
        </tr>
      </thead>
    );
  };

  const tableRows = () => {
    const rows = props.orderItems.map((item) => {
      return (
        <LineItemRow
          key={`order-item-row-${item.id}`}
          orderId={props.orderId}
          lineItem={item}
          ssccEnabled={props.ssccEnabled}
          ssccLabelsStillGenerating={props.ssccLabelsStillGenerating}
          showLpns={props.showLpns}
        />
      );
    });

    return <tbody>{rows}</tbody>;
  };

  return (
    <table className="table table-striped line-items-table">
      {tableHeader()}
      {tableRows()}
    </table>
  );
};

export default LineItems;
