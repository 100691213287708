import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {friendlyCount, Pagination, Tabs} from '@flexe/ui-components';
import {get} from 'lodash';
import {CookiesProvider} from 'react-cookie';
import Filters from '../shared/Filters';
import RetailFulfillmentService, {RetailOrder} from '../shared/services/RetailFulfillmentService';
import WarehouseSelector from '../shared/WarehouseSelector';
import ReservationSelector, {SelectableReservation} from '../shared/ReservationSelector';
import {Company, Filter, FilterType, FilterValue, Warehouse} from '../shared/CommonInterfaces';
import {useWriteableProp} from '../../libs/CustomHooks';
import OutboundOrdersService from '../shipper/outbound-orders/OutboundOrdersService';
import {
  CANCELLED_ORDERS_TAB,
  COMPLETED_ORDERS_TAB,
  CONFIRMED_ORDERS_TAB,
  NEW_ORDERS_TAB,
  PICKUP_SCHEDULED_ORDERS_TAB
} from './RetailConstants';
import RetailOrderDetailsTable from './RetailOrderDetailsTable';
import BulkConfirmModal from './BulkConfirmModal';
import BulkSchedulePickupsModal from './BulkSchedulePickupsModal';
import BulkCancelModal from './BulkCancelModal';

interface RetailFulfillmentProps {
  retailFulfillmentService: RetailFulfillmentService;
  enablePurchaseOrder: boolean;
  activeWarehouses?: Warehouse[];
  inactiveWarehouses?: Warehouse[];
  selectedWarehouse?: Warehouse;
  reservations?: SelectableReservation[];
  selectedReservation?: SelectableReservation;
  currentCompany?: Company;
  outboundOrdersService?: OutboundOrdersService;
}

const RetailFulfillmentOrders: React.FC<RetailFulfillmentProps & RouteComponentProps> = (props) => {
  const pageSize = 10;
  const [selectedWarehouse, setSelectedWarehouse] = React.useState(props.selectedWarehouse);
  const [selectedReservation, setSelectedReservation] = React.useState(props.selectedReservation);
  const [counts, setCounts] = React.useState({
    new: 0,
    confirmed: 0,
    pickup_scheduled: 0,
    completed: 0,
    cancelled: 0
  });
  const sortBy = useWriteableProp(null);
  const sortDirection = useWriteableProp(null);
  const selectAllIsChecked = useWriteableProp(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [retailOrders, setRetailOrders] = React.useState([]);
  const [tabs, setTabs] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState('new');
  const [filterValues, setFilterValues] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [continuationTokens, setContinuationTokens] = React.useState([]);
  const selectedOrders = useWriteableProp([]);
  const [showBulkCancelButton, setShowBulkCancelButton] = React.useState(false);
  const [showBulkCancelModal, setShowBulkCancelModal] = React.useState(false);
  const [showBulkRouteButton, setShowBulkRouteButton] = React.useState(false);
  const [showBulkRouteModal, setShowBulkRouteModal] = React.useState(false);
  const [showBulkConfirmButton, setShowBulkConfirmButton] = React.useState(false);
  const [showBulkConfirmModal, setShowBulkConfirmModal] = React.useState(false);
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = React.useState<boolean>(false);

  async function getRetailOrders(request) {
    const response = await props.retailFulfillmentService.getOrders(request);
    if (response && response.errors.length === 0) {
      setRetailOrders(response.data.orders);
      setCounts({
        new: response.data.counts.new || 0,
        confirmed: response.data.counts.confirmed || 0,
        pickup_scheduled: response.data.counts.pickup_scheduled || 0,
        completed: response.data.counts.completed || 0,
        cancelled: response.data.counts.cancelled || 0
      });
      if (response.data.continuationToken) {
        continuationTokens[currentPage - 1] = response.data.continuationToken;
      }
    } else {
      let message = 'There was an error fetching orders';
      if (response) {
        const serverErrors = response.errors.map((e) => e.detail);
        message += `: ${serverErrors.join(', ')}`;
      }
      setError(message);
    }
    setHasInitiallyLoaded(true);
    setIsLoading(false);
  }

  const handleCancellationSuccess = () => {
    selectedOrders.set([]);
    setError(null);
    refreshOrders();
  };

  const handleCancellationError = (errors: string[]) => {
    let message = 'There was an error cancelling orders';
    if (errors.length > 0) {
      message += `: ${errors.join(', ')}`;
    }

    setError(message);
  };

  const handlePickupScheduledSuccess = () => {
    selectedOrders.set([]);
    setError(null);
    refreshOrders();
  };

  const handlePickupScheduledError = (errors: string[]) => {
    let message = 'There was an error scheduling pickups';
    if (errors.length > 0) {
      message += `: ${errors.join(', ')}`;
    }

    setError(message);
  };

  const handleBulkActionSuccess = () => {
    selectedOrders.set([]);
    setError(null);
    refreshOrders();
  };

  const handleConfirmError = (errors: string[]) => {
    setError(`There was an error confirming orders: ${errors.join(', ')}`);
  };

  const resetButtons = () => {
    setShowBulkCancelButton(false);
    setShowBulkRouteButton(false);
    setShowBulkConfirmButton(false);
  };

  const refreshOrders = () => {
    const filterQuery = {};
    filterValues.map((filter: FilterValue) => (filterQuery[filter.filter.key] = filter.value));
    setIsLoading(true);
    setRetailOrders([]);
    selectedOrders.set([]);
    selectAllIsChecked.set(false);
    resetButtons();

    const staticParams = {
      ...filterQuery,
      states: [selectedTab],
      continuationToken: currentPage > 1 ? continuationTokens[currentPage - 2] : null,
      sortBy: sortBy.value,
      sortDirection: sortDirection.value,
      pageSize
    };

    let getRetailOrdersParams = {};

    if (selectedWarehouse) {
      getRetailOrdersParams = {
        ...staticParams,
        warehouseId: selectedWarehouse.id,
        shipmentTypesToExclude: ['tbd']
      };
    } else {
      getRetailOrdersParams = {
        ...staticParams,
        reservationId: selectedReservation.id
      };
    }

    getRetailOrders(getRetailOrdersParams);
  };

  // TODO: This logic is gonna get messy, so make sure to refactor
  React.useEffect(() => {
    const ordersAreSelected: boolean = selectedOrders.value.length > 0;
    // Shipper side
    if (props.reservations) {
      if (selectedTab === NEW_ORDERS_TAB) {
        setShowBulkCancelButton(ordersAreSelected);
      } else if (selectedTab === CONFIRMED_ORDERS_TAB) {
        setShowBulkRouteButton(ordersAreSelected);
      }
    } else {
      // warehouse side
      if (selectedTab === NEW_ORDERS_TAB) {
        setShowBulkConfirmButton(ordersAreSelected);
      }
    }
  }, [selectedOrders.value, selectedTab]);

  React.useEffect(() => {
    refreshOrders();
  }, [currentPage, continuationTokens]);

  React.useEffect(() => {
    if (hasInitiallyLoaded) {
      setContinuationTokens([]);
      if (currentPage > 1) {
        setCurrentPage(1);
      }
    }
  }, [selectedTab, filterValues, sortBy.value, sortDirection.value, selectedWarehouse, selectedReservation]);

  React.useEffect(() => {
    setTabs([
      {
        active: selectedTab === NEW_ORDERS_TAB,
        key: NEW_ORDERS_TAB,
        subTitle: 'New Orders',
        title: friendlyCount(counts.new)
      },
      {
        active: selectedTab === CONFIRMED_ORDERS_TAB,
        key: CONFIRMED_ORDERS_TAB,
        subTitle: 'Confirmed',
        title: friendlyCount(counts.confirmed)
      },
      {
        active: selectedTab === PICKUP_SCHEDULED_ORDERS_TAB,
        key: PICKUP_SCHEDULED_ORDERS_TAB,
        subTitle: 'Pickup Scheduled',
        title: friendlyCount(counts.pickup_scheduled)
      },
      {
        active: selectedTab === CANCELLED_ORDERS_TAB,
        key: CANCELLED_ORDERS_TAB,
        pullRight: true,
        subTitle: 'Cancelled',
        title: <i className="fa fa-times-circle" aria-hidden="true"></i>
      },
      {
        active: selectedTab === COMPLETED_ORDERS_TAB,
        key: COMPLETED_ORDERS_TAB,
        pullRight: true,
        subTitle: 'Completed',
        title: <i className="fa fa-check-circle" aria-hidden="true"></i>
      }
    ]);
  }, [counts]);

  const flexeIdFilter: Filter = {
    displayName: 'FLEXE ID',
    key: 'orderId',
    type: FilterType.Number,
    value: get(filterValues, 'orderId')
  };

  const masterBolFilter: Filter = {
    displayName: 'Master BOL #',
    key: 'masterBillOfLading',
    type: FilterType.String,
    value: get(filterValues, 'masterBOL')
  };

  const bolFilter: Filter = {
    displayName: 'BOL #',
    key: 'billOfLading',
    type: FilterType.String,
    value: get(filterValues, 'billOfLading')
  };

  const reservationFilter: Filter = {
    displayName: 'Reservation ID',
    key: 'reservationId',
    type: FilterType.Number,
    value: get(filterValues, 'reservationId')
  };

  const uuidFilter: Filter = {
    displayName: 'Customer UUID',
    key: 'customerUUID',
    type: FilterType.String,
    value: get(filterValues, 'customerUUID')
  };

  const poFilter: Filter = {
    displayName: 'Purchase Order',
    key: 'purchaseOrder',
    type: FilterType.String,
    value: get(filterValues, 'purchaseOrder')
  };

  const allCrossdockFilter: Filter = {
    displayName: 'All Crossdock',
    key: 'allCrossdock',
    type: FilterType.Present,
    value: get(filterValues, 'allCrossdock')
  };

  const crossdockPickableFilter: Filter = {
    displayName: 'Crossdock Pickable',
    key: 'crossdockPickable',
    type: FilterType.Present,
    value: get(filterValues, 'crossdockPickable')
  };

  const externalLoadIdFilter: Filter = {
    displayName: 'External Load ID',
    key: 'externalLoadId',
    type: FilterType.String,
    value: get(filterValues, 'externalLoadId')
  };

  const skuFilter: Filter = {
    displayName: 'SKU',
    key: 'searchedSku',
    type: FilterType.String,
    value: get(filterValues, 'searchedSku')
  };

  const startDateFilter: Filter = {
    displayName: 'Start Date',
    key: 'shipWithinStart',
    type: FilterType.Date,
    value: get(filterValues, 'shipWithinStart')
  };

  const endDateFilter: Filter = {
    displayName: 'End Date',
    key: 'shipWithinEnd',
    type: FilterType.Date,
    value: get(filterValues, 'shipWithinEnd')
  };

  const getFilters = (): Filter[] => {
    const filters: Filter[] = [];

    // We only want the reservation filter if we're on the warehouse side
    if (props.selectedWarehouse) {
      filters.push(reservationFilter);
    }
    filters.push(flexeIdFilter);
    if (props.enablePurchaseOrder) {
      filters.push(uuidFilter);
      filters.push(poFilter);
    } else {
      filters.push({
        displayName: 'Purchase Order',
        key: 'customerUUID',
        type: FilterType.String,
        value: get(filterValues, 'customerUUID')
      });
    }

    filters.push(masterBolFilter, bolFilter);

    // On the warehouse side, we show crossdock filters
    // only if at least one of the reservations associated with selected warehouse enabled crossdock
    if (props.selectedWarehouse) {
      if (selectedWarehouse.crossdockEnabled) {
        filters.push(allCrossdockFilter, crossdockPickableFilter);
      }
    } else {
      //On the shipper side, we show crossdock filters only if the selected reservation enabled crossdock
      if (selectedReservation.crossdockEnabled) {
        filters.push(allCrossdockFilter, crossdockPickableFilter);
      }
    }

    filters.push(externalLoadIdFilter);

    if (props.selectedWarehouse) {
      if (selectedWarehouse.showSkuFilter) {
        filters.push(skuFilter);
      }
    }

    filters.push(startDateFilter);
    filters.push(endDateFilter);

    return filters;
  };

  const onTerminalTab = () => {
    return [COMPLETED_ORDERS_TAB, CANCELLED_ORDERS_TAB].includes(selectedTab);
  };

  const shouldShowSelectAllCheckbox = () => {
    if (onTerminalTab()) {
      return false;
    }

    // Select all isn't available for the confirmed tab on the shipper side or for pickup scheduled tab on both sides
    if (
      (props.reservations && selectedTab === CONFIRMED_ORDERS_TAB) ||
      selectedTab === PICKUP_SCHEDULED_ORDERS_TAB ||
      (props.selectedWarehouse && selectedTab === CONFIRMED_ORDERS_TAB)
    ) {
      return false;
    }

    return true;
  };

  const shouldShowRowCheckbox = (order: RetailOrder) => {
    if (onTerminalTab()) {
      return false;
    }

    // No reason for checkboxes here
    if (selectedTab === PICKUP_SCHEDULED_ORDERS_TAB) {
      return false;
    }

    // Or here
    if (props.selectedWarehouse && selectedTab === CONFIRMED_ORDERS_TAB) {
      return false;
    }

    // Only orders with same master BOL can be selected together on these tabs
    if (
      ((props.reservations && selectedTab === CONFIRMED_ORDERS_TAB) || selectedTab === PICKUP_SCHEDULED_ORDERS_TAB) &&
      selectedOrders.value.length > 0 &&
      order.masterBillOfLadingNumber !== selectedOrders.value[0].masterBillOfLadingNumber
    ) {
      return false;
    }

    return true;
  };

  const handlePagination = (page: number) => {
    setCurrentPage(page);
    setIsLoading(true);
  };

  const getBulkActionButton = () => {
    return (
      <div>
        {showBulkCancelButton && (
          <button id="bulk-cancel-button" className="no-cta" onClick={() => setShowBulkCancelModal(true)}>
            Bulk Cancel
          </button>
        )}

        {showBulkRouteButton && (
          <button id="bulk-route-button" className="no-cta" onClick={() => setShowBulkRouteModal(true)}>
            Bulk Route
          </button>
        )}

        {showBulkConfirmButton && (
          <button className="no-cta" id="bulk-confirm-button" onClick={() => setShowBulkConfirmModal(true)}>
            Bulk Confirm
          </button>
        )}
      </div>
    );
  };

  const warehouseTimezone = () => {
    if (selectedReservation) {
      return selectedReservation.warehouseTimezone;
    } else {
      return props.selectedWarehouse.timezone;
    }
  };

  function shouldRetailButtonBePresent() {
    let returnValue = true;
    const company = getCompanyIdentifier();
    if (company === 'fritolay' || company === 'niagara' || company === 'lol') {
      returnValue = false;
    }
    return returnValue;
  }

  function getCompanyIdentifier() {
    let companyName = null;
    if (['1000', '579', '3500'].includes(String(props.currentCompany.id))) {
      companyName = 'fritolay';
    } else if (['3475'].includes(String(props.currentCompany.id))) {
      companyName = 'niagara';
    } else if (['2012', '564', '1907'].includes(String(props.currentCompany.id))) {
      companyName = 'lol';
    }
    return companyName;
  }

  return (
    <div className="retail-fulfillment-orders-component container-fluid">
      <div className="row space-below">
        <div className="col-sm-6">
          <h2>Retail Fulfillment Requests</h2>
          {!props.reservations && (
            <div>
              <a
                target="_blank"
                href="https://flexesupport.zendesk.com/hc/en-us/articles/360041878072-Mobile-Pick-Retail-"
              >
                Learn about Picking a Retail Order
              </a>
              <br />
              <br />
            </div>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {!props.reservations && (
            <h4>
              Warehouse: <br />
              <CookiesProvider>
                <WarehouseSelector
                  selectedWarehouse={selectedWarehouse}
                  activeWarehouses={props.activeWarehouses}
                  inactiveWarehouses={props.inactiveWarehouses}
                  onSelect={(warehouse) => setSelectedWarehouse(warehouse)}
                />
              </CookiesProvider>
            </h4>
          )}
          {props.reservations && (
            <h4>
              Reservation: <br />
              <ReservationSelector
                selectedReservation={selectedReservation}
                reservations={props.reservations}
                onSelect={(reservation) => setSelectedReservation(reservation)}
              />
            </h4>
          )}
        </div>
        {props.reservations && (
          <div className="col-sm-6 text-right">
            {shouldRetailButtonBePresent() && (
              <a href={props.match.path + '/new'} className="btn" id="new-retail-request">
                <i className="fa fa-plus"></i>
                &nbsp; New Retail Request
              </a>
            )}
            <a href={props.match.path + '/new_batch'} className="btn" id="csv-import">
              <i className="fa fa-file-text"></i>
              &nbsp; CSV Import
            </a>
          </div>
        )}
      </div>

      <p className="text-uppercase">Filters</p>
      <Filters filters={getFilters()} filterChangeHandler={(updatedFilters) => setFilterValues(updatedFilters)} />

      <Tabs tabs={tabs} tabEvent={(tabKey) => setSelectedTab(tabKey)} />

      <Pagination
        page={currentPage}
        pageSize={pageSize}
        paginationHandler={handlePagination}
        totalCount={counts[selectedTab]}
      />
      <RetailOrderDetailsTable
        counts={counts}
        selectedTab={selectedTab}
        bulkActionButton={getBulkActionButton()}
        path={props.match.path}
        shouldShowSelectAllCheckbox={shouldShowSelectAllCheckbox}
        shouldShowRowCheckbox={shouldShowRowCheckbox}
        selectAllIsChecked={selectAllIsChecked}
        isLoading={isLoading}
        sortBy={sortBy}
        sortDirection={sortDirection}
        selectedOrders={selectedOrders}
        retailOrders={retailOrders}
        warehouseTimezone={warehouseTimezone()}
        enablePurchaseOrder={props.enablePurchaseOrder}
      />

      <BulkCancelModal
        showModal={showBulkCancelModal}
        toggleModal={() => {
          setShowBulkCancelModal(!showBulkCancelModal);
        }}
        selectedOrderIds={new Set(selectedOrders.value.map((o) => o.id))}
        retailFulfillmentService={props.retailFulfillmentService}
        onSuccess={() => {
          handleCancellationSuccess();
        }}
        onError={(errors: string[]) => {
          handleCancellationError(errors);
        }}
      />

      <BulkSchedulePickupsModal
        showModal={showBulkRouteModal}
        toggleModal={() => {
          setShowBulkRouteModal(!showBulkRouteModal);
        }}
        selectedOrders={selectedOrders.value}
        selectedReservation={props.selectedReservation}
        retailFulfillmentService={props.retailFulfillmentService}
        onSuccess={() => {
          handlePickupScheduledSuccess();
        }}
        onError={(errors: string[]) => {
          handlePickupScheduledError(errors);
        }}
      />

      <BulkConfirmModal
        showModal={showBulkConfirmModal}
        toggleModal={() => {
          setShowBulkConfirmModal(!showBulkConfirmModal);
        }}
        selectedOrders={selectedOrders.value}
        retailFulfillmentService={props.retailFulfillmentService}
        onSuccess={() => {
          handleBulkActionSuccess();
        }}
        onError={(errors: string[]) => {
          handleConfirmError(errors);
        }}
      />
    </div>
  );
};

export default RetailFulfillmentOrders;
