import * as React from 'react';
import {ApiResponse} from '../../shared/CommonInterfaces';
import ContainersService from '../../shared/services/ContainersService';
import {ContainerDelivery} from './DropoffInterfaces';

interface Props {
  containersService: ContainersService;
  containerDelivery: ContainerDelivery;
  containerDeliveryStartTime: number;
  containerDeliveryEndTime: number;
  instructions: string;
  setSuccessMessage: (message: string) => void;
  resetSuccessMessage: () => void;
  handleApiError: (errors: string[], response: ApiResponse<any>) => void;
  reloadContainer: () => void;
}

export const AlteredDropoffConfirmation: React.FC<Props> = (props) => {
  const {containersService, setSuccessMessage, resetSuccessMessage, handleApiError, reloadContainer} = props;

  const confirmDelivery = async () => {
    const response = await containersService.confirmDelivery({
      id: props.containerDelivery.id,
      startTime: props.containerDeliveryStartTime,
      endTime: props.containerDeliveryEndTime,
      instructions: props.instructions
    });
    if (response && response.errors.length === 0) {
      {
        setSuccessMessage('Delivery has been confirmed!');
      }
      resetSuccessMessage();
      reloadContainer();
    } else {
      {
        handleApiError([`There was an error confirming delivery #${props.containerDelivery.id}`], response);
      }
    }
  };

  return (
    <div>
      <h2>Shipment Altered</h2>
      <p>
        The Shipper has modified this shipment. Please carefully review the updated details below. If there are no
        conflicts, Accept Changes and the shipment will remain on schedule for completion with the updated and confirmed
        information.
      </p>
      <p>
        <a className="btn cta" onClick={confirmDelivery}>
          <i className="fa fa-angle-right"></i> Re-confirm Shipment
        </a>
      </p>
      <hr />
    </div>
  );
};

export default AlteredDropoffConfirmation;
