import {CarrierBillingAccount, CarrierBillingAccountServiceInterface} from '../SharedInterfaces';
import InternalAPIService from '../../../shared/services/InternalAPIService';

class CarrierBillingAccountService extends InternalAPIService implements CarrierBillingAccountServiceInterface {
  public constructor(authenticityToken: string) {
    const baseUrl = '/s/carrier_billing_accounts/';
    super(authenticityToken, baseUrl);
  }

  public async getAccounts() {
    const response = await this.makeGetRequest(this.baseUrl);
    const accounts = (response.data && response.data.carrierBillingAccounts) || [];
    const errors = this.extractErrorMessages(response);

    return {
      errors,
      accounts
    };
  }

  public async createAccount(account: CarrierBillingAccount) {
    const carrierBillingAccount = this.nullifyEmptyParams(account);
    const response = await this.makePostRequest(this.baseUrl, {
      carrierBillingAccount
    });
    const errors = this.extractErrorMessages(response);

    return {errors};
  }

  public async updateAccount(account: CarrierBillingAccount) {
    const carrierBillingAccount = this.nullifyEmptyParams(account);
    const response = await this.makePutRequest(this.baseUrl + account.id, {
      carrierBillingAccount
    });
    const errors = this.extractErrorMessages(response);

    return {errors};
  }

  public async deleteAccount(account: CarrierBillingAccount) {
    const response = await this.makeDeleteRequest(this.baseUrl + account.id);
    const errors = this.extractErrorMessages(response);

    return {errors};
  }

  private nullifyEmptyParams(account: CarrierBillingAccount): CarrierBillingAccount {
    return {
      id: account.id || null,
      friendlyName: account.friendlyName || null,
      carrier: account.carrier || null,
      country: account.country || null,
      accountId: account.accountId || null,
      postcode: account.postcode || null,
      companyChannelId: account.companyChannelId || null
    };
  }

  private extractErrorMessages(response): string[] {
    // This indicates the returned error is not a standard Flexe error.  It could be a network or rails routing error.
    // Show a generic error message
    if (!response || !response.errors) {
      return ['Unable to complete request'];
    }

    return response.errors.map(this.extractErrorMessage);
  }

  private extractErrorMessage(error): string {
    // This indicates we got a structured error response that was missing the expected detail field. Could be a bug.
    // Show a generic error message
    if (!error.detail) {
      return 'Unable to complete request';
    }

    const {detail, source} = error;
    const pointer = source && source.pointer && source.pointer.replace(/\/data\/carrierBillingAccount\//, '');
    const param = source && source.parameter;

    if (pointer || param) {
      return `${pointer || param}: ${detail}`;
    } else {
      return detail;
    }
  }
}

export default CarrierBillingAccountService;
