import {ApiResponse} from '../CommonInterfaces';
import {
  InventoryDimensionResponse,
  ShipmentSplit,
  SplitResponse
} from '../../warehouse/ecommerce-batches/shipments/ShipmentInterfaces';
import InternalAPIService from './InternalAPIService';

class ShipmentService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/wh');
  }

  public async getInventoryDimensions(skus: string[]): Promise<ApiResponse<InventoryDimensionResponse>> {
    return await this.makePostRequest(`${this.baseUrl}/inventory/dimensions`, {
      skus
    });
  }

  public async splitShipment(splits: ShipmentSplit[], originalId: number): Promise<ApiResponse<SplitResponse>> {
    return await this.makePostRequest(`${this.baseUrl}/fulfillment/ecommerce/order/${originalId}/split`, {
      splits
    });
  }

  public async cancelShipment(
    shipmentId: number,
    reservationId: number,
    cancelledReason: string
  ): Promise<ApiResponse<number>> {
    return await this.makePostRequest(`${this.baseUrl}/fulfillment/ecommerce/shipment/${shipmentId}/cancel`, {
      reservationId,
      cancelledReason
    });
  }

  public async saveShipmentAttributes(shipmentId: number, attributes: object): Promise<ApiResponse<number>> {
    return await this.makePostRequest(
      `${this.baseUrl}/fulfillment/ecommerce/shipment/${shipmentId}/shipment_attributes`,
      {
        attributes
      }
    );
  }

  public async saveExternalTrackingNumber(
    shipmentId: number,
    externalTrackingNumber: string
  ): Promise<ApiResponse<number>> {
    return await this.makePostRequest(
      `${this.baseUrl}/fulfillment/ecommerce/shipment/${shipmentId}/external_tracking_number`,
      {
        externalTrackingNumber
      }
    );
  }
}

export default ShipmentService;
