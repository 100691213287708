/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import {FC, TableHTMLAttributes, useContext, useEffect, useRef, useState} from 'react';
import {Column, Modal, Row} from '@flexe/ui-components';
import {css, jsx} from '@emotion/react';
import {chunk} from 'lodash';
import tokens from '@flexe/ui-tokens';
import FlexeContext from '../../contexts/FlexeContext';
import InventoryService, {
  LpnInventoryTrackingDataResponse,
  SerialNumbersBulkResponse
} from '../../shared/services/InventoryService';
import {useAllPaginatedResults} from '../../shared/utilities/useAllPaginatedResults';
import PaginationButtons from '../../shared/PaginationButtons';
import {Inventory, LpnContent} from '../shared/DropoffInterfaces';
import {renderItemLink, renderLpnLink} from '../../../libs/helpers';

const PAGE_SIZE = 15;

export const SerialNumbers: FC<Pick<LpnContent, 'lpnBarcode'> & {
  inboundId: number;
  inventory: Pick<Inventory, 'description' | 'id' | 'sku'>;
  onErrors: (errors: string[]) => void;
  reservationId: number;
}> = ({inboundId, inventory, lpnBarcode, onErrors, reservationId}) => {
  const serialNumbers = useSerialNumbers(lpnBarcode, reservationId, onErrors);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    serialNumbers.length !== 0 && (
      <div>
        Serial Numbers: <a onClick={() => setIsModalOpen(true)}>{serialNumbers.length}</a>
        <Modal
          isDismissable
          isOpen={isModalOpen}
          size={'large'}
          title={`Inbound Delivery #${inboundId} Contents`}
          toggleModal={() => setIsModalOpen((_) => !_)}
        >
          <Column>
            <Column margin={'0'}>
              <div
                css={css`
                  display: flex;
                `}
              >
                <Label>LPN: </Label>
                {renderLpnLink(lpnBarcode, isShipper)}
              </div>
              <div
                css={css`
                  display: flex;
                `}
              >
                <Label>SKU: </Label>
                <Column margin={'0'}>
                  <div>{inventory.description}</div>
                  {renderItemLink(inventory.id, inventory.sku, isShipper)}
                </Column>
              </div>
            </Column>
            <Column margin={'0'}>
              <Row alignmentVertical={'center'} childWidths={['fit', 'fill', 'fit']} alignmentHorizontal={'center'}>
                {/* TODO: Replace the following div with a "Download Serial Numbers" button. */}
                <div />
                <div />
                <PaginationButtons
                  count={serialNumbers.length}
                  currentPage={currentPage}
                  pageSize={PAGE_SIZE}
                  setCurrentPage={setCurrentPage}
                />
              </Row>
              <ZebraStripedTable>
                <thead>
                  <tr>
                    <th>Serial Numbers</th>
                  </tr>
                </thead>
                <tbody>
                  {chunk(serialNumbers, PAGE_SIZE)[currentPage - 1].map((serialNumber) => (
                    <tr key={serialNumber}>
                      <td>{serialNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </ZebraStripedTable>
            </Column>
          </Column>
        </Modal>
      </div>
    )
  );
};

const isShipper = window.location.pathname[1] === 's';

const useSerialNumbers = (lpnBarcode: string, reservationId: number, onError: (errors: string[]) => void) => {
  const {authenticityToken} = useContext(FlexeContext);
  const inventoryService = useRef(new InventoryService(authenticityToken)).current;
  const [lpnId, setLpnId] = useState<number | null>(null);

  useEffect(() => {
    const getLpnAsync = async () => {
      const response = await inventoryService.getLpn({barcode: lpnBarcode, reservationId});
      if (response.errors.length > 0) {
        onError(response.errors.map((_) => _.detail));
        return;
      }
      setLpnId(response.data.id);
    };
    // getLpnAsync();
  }, [inventoryService, lpnBarcode, onError, reservationId]);

  const {results: inventoryTrackingDataIds} = useAllPaginatedResults<LpnInventoryTrackingDataResponse, string[]>({
    getDataAsync: async (continuationToken) => inventoryService.getLpnInventoryTrackingData(lpnId, continuationToken),
    mapData: (data) => data.contents.map((_) => _.id.toString()),
    onErrors: (errors) => onError(errors.map((_) => _.detail)),
    shouldMakeRequest: !!lpnId
  });

  const {results: serialNumbers} = useAllPaginatedResults<SerialNumbersBulkResponse, string[]>({
    getDataAsync: async (continuationToken) =>
      inventoryService.getSerialNumbersBulk({inventoryTrackingDataIds}, continuationToken),
    mapData: (data) => data.serialNumbers.map((_) => _.serialNumber),
    onErrors: (errors) => onError(errors.map((_) => _.detail)),
    shouldMakeRequest: inventoryTrackingDataIds.length > 0
  });

  return serialNumbers;
};

const Label: FC = ({children}) => (
  <div
    css={css`
      color: #5a697d;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      padding-right: 1rem;
    `}
  >
    {children}
  </div>
);

const ZebraStripedTable: FC<TableHTMLAttributes<unknown>> = (props) => (
  <table
    {...props}
    css={css`
      border: ${tokens.border.round.hairline.width.value} solid #c9d1dc;
      border-collapse: separate;

      &,
      thead > tr {
        border-top-left-radius: ${tokens.border.round.radius.value};
        border-top-right-radius: ${tokens.border.round.radius.value};
      }

      th {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }

      tr {
        display: block;
        padding: 8px;
      }

      thead > tr,
      tbody > tr:not(:last-of-type) {
        border-bottom: ${tokens.border.round.hairline.width.value} solid #c9d1dc;
      }

      thead > tr {
        background-color: #f0f3f6;
      }

      tr:nth-of-type(even) {
        background-color: #f9fbff;
      }
    `}
  >
    {props.children}
  </table>
);

export default SerialNumbers;
