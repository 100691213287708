import * as React from 'react';
import {RouteComponentProps, RouteProps} from 'react-router-dom';
import {get} from 'lodash';
import * as qs from 'query-string';
import {Loader} from '@flexe/ui-components';
import FileStorageService from '../file-storage/FileStorageService';
import FileStorageContent from '../file-storage/FileStorageContent';
import {ResponseErrorV2} from '../../shared/CommonInterfaces';
import EdiFilesServiceV2 from './EdiFilesServiceV2';
import {V2EdiFile} from './EdiFilesInterfaces';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  fileStorageService?: FileStorageService;
  ediFilesServiceV2?: EdiFilesServiceV2;
}

export enum FileKeyOption {
  fileKey = 'fileKey',
  translationFileKey = 'translationFileKey'
}

interface State {
  ediFile?: V2EdiFile;
  fileKeyOption: FileKeyOption;
  errors: string[];
  loading: boolean;
}

class EdiFileDetailContent extends React.Component<Props & RouteProps, State> {
  private fileStorageService: FileStorageService;
  private ediFilesServiceV2: EdiFilesServiceV2;
  constructor(props) {
    super(props);
    this.fileStorageService = props.fileStorageService || new FileStorageService();
    this.ediFilesServiceV2 = props.ediFilesServiceV2 || new EdiFilesServiceV2();
    const queryString = window.location.search;
    const queryParams = qs.parse(queryString);
    const fileKeyOption = get(queryParams, 'fileKeyOption', FileKeyOption.fileKey);
    this.state = {
      fileKeyOption,
      loading: true,
      errors: []
    };
  }

  public componentDidMount() {
    this.loadFile();
  }

  public render() {
    const {loading, ediFile, fileKeyOption, errors} = this.state;
    if (loading) {
      return (
        <div className="container-fluid">
          <Loader loading={loading} />
        </div>
      );
    } else if (ediFile && ediFile[fileKeyOption]) {
      return <FileStorageContent fileStorageService={this.fileStorageService} fileKey={ediFile[fileKeyOption]} />;
    } else {
      return (
        <div className="container-fluid">
          {errors && errors.length > 0 && (
            <div className="alert alert-danger space-above space-below">
              <ul>
                {' '}
                {errors.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}{' '}
              </ul>
            </div>
          )}
        </div>
      );
    }
  }

  private loadFile = async () => {
    const {id} = this.props.match.params;
    this.setState({loading: true});
    let errors = [];
    const ediFileId = parseInt(id, 10);
    const data = await this.ediFilesServiceV2.getFile(ediFileId).catch((response) => {
      const responseErrors = response.errors as ResponseErrorV2[];
      errors = responseErrors.map((error) => error.detail);
      return null;
    });
    const ediFile = get(data, 'data', null);
    errors = ediFile !== null ? errors : errors.concat(['Edi File not found']);
    this.setState({
      ediFile,
      errors,
      loading: false
    });
  };
}

export default EdiFileDetailContent;
