import * as React from 'react';
import DatePicker from 'react-datepicker';
import * as ReactTooltip from 'react-tooltip';
import {DisplayedAttribute} from './NewRetailFulfillmentOrder';

export interface RetailShipmentDetails {
  purchaseOrder: string;
  bolNumber: string;
  shipmentType: string;
  shipmentMethod: string;
  carrier?: string;
  serviceType?: string;
  carrierBillingAccountId?: number;
  routingDetailsId?: number;
  routeBy?: Date;
  shipByStart: Date;
  shipByEnd: Date;
}

interface Props {
  shipmentDetails: RetailShipmentDetails;

  shipmentTypeOptions: DisplayedAttribute[];
  shipmentMethodOptions: DisplayedAttribute[];
  carrierOptions: DisplayedAttribute[];
  carrierServiceOptions: Map<string, string[]>;
  carrierBillingAccountOptions: Map<string, DisplayedAttribute[]>;
  routingDetailsOptions: DisplayedAttribute[];

  onInput(event);
  onChange(name, value);
}

const ShipmentDetails: React.FC<Props> = (props) => {
  const [requireShipmentMethod, setRequireShipmentMethod] = React.useState(
    props.shipmentDetails.shipmentType !== '' && props.shipmentDetails.shipmentType !== 'tbd'
  );
  const [requireServiceDetails, setRequireServiceDetails] = React.useState(
    props.shipmentDetails.shipmentMethod === 'generated' && props.shipmentDetails.shipmentType === 'parcel'
  );
  const [requireCarrierAccount, setRequireCarrierAccount] = React.useState(
    (props.carrierBillingAccountOptions[props.shipmentDetails.carrier] || []).length > 0 && requireServiceDetails
  );
  const [routeByDate, setRouteByDate] = React.useState<Date>();
  const [routeByTime, setRouteByTime] = React.useState<Date>();
  const [shipByStartDate, setShipByStartDate] = React.useState<Date>();
  const [shipByStartTime, setShipByStartTime] = React.useState<Date>();
  const [shipByEndDate, setShipByEndDate] = React.useState<Date>();
  const [shipByEndTime, setShipByEndTime] = React.useState<Date>();
  const [shipmentMethodOptions, setShipmentMethodOptions] = React.useState<DisplayedAttribute[]>(
    props.shipmentMethodOptions
  );

  let uuid = 0;

  const createOption = (displayText, value?) => {
    const elementId = ++uuid;
    const key = displayText + '_' + elementId.toString();
    return (
      <option id={elementId.toString()} key={key} value={value || ''}>
        {displayText}
      </option>
    );
  };

  const routingDetailsOptions = [createOption('Select Routing Service...')].concat(
    props.routingDetailsOptions.map((details) => {
      return createOption(details.displayName, details.value);
    })
  );

  const shipmentTypeOptions = [createOption('Select Shipment Type')].concat(
    props.shipmentTypeOptions.map((option) => {
      return createOption(option.displayName, option.value);
    })
  );

  const shipmentMethodOptionsHtml = [createOption('Select Shipment Method')].concat(
    shipmentMethodOptions.map((option) => {
      return createOption(option.displayName, option.value);
    })
  );

  const carrierOptions = [createOption('Select Carrier', '')].concat(
    props.carrierOptions.map((option) => {
      return createOption(option.displayName, option.value);
    })
  );

  const serviceTypeOptions = requireServiceDetails
    ? [createOption('Select Service Type')].concat(
        (props.carrierServiceOptions[props.shipmentDetails.carrier] || []).map((option) => {
          return createOption(option, option);
        })
      )
    : [];

  const carrierAccountOptions = requireCarrierAccount
    ? [createOption('Select Carrier Billing Account')].concat(
        props.carrierBillingAccountOptions[props.shipmentDetails.carrier].map((account) => {
          return createOption(account.displayName, account.value);
        })
      )
    : [];

  const handleShipmentTypeSelect = (event) => {
    const shipmentType = event.target.value;

    if (shipmentType === 'parcel') {
      //upload, generated
      const validShipmentMethods = props.shipmentMethodOptions.filter((method) => method.value !== 'vrs');
      setShipmentMethodOptions(validShipmentMethods);
    } else {
      //LTL
      //upload, vrs
      const validShipmentMethods = props.shipmentMethodOptions.filter((method) => method.value !== 'generated');
      setShipmentMethodOptions(validShipmentMethods);
      setRequireServiceDetails(false);
      setRequireCarrierAccount(false);
    }

    if (shipmentType) {
      setRequireShipmentMethod(shipmentType !== 'tbd');
      setRequireServiceDetails(props.shipmentDetails.shipmentMethod === 'generated' && shipmentType === 'parcel');
    } else {
      setRequireShipmentMethod(false);
    }

    props.onInput(event);
  };

  const handleShipmentMethodSelect = (event) => {
    const shipmentMethod = event.target.value;
    setRequireServiceDetails(shipmentMethod === 'generated' && props.shipmentDetails.shipmentType === 'parcel');
    props.onInput(event);
  };

  const handleCarrierSelect = (event) => {
    const carrier = event.target.value;

    if (carrier) {
      if (props.carrierBillingAccountOptions) {
        const accounts = props.carrierBillingAccountOptions[carrier] || [];
        setRequireCarrierAccount(accounts.length > 0);
      } else {
        setRequireCarrierAccount(false);
      }
    }

    //reset selections that are no longer valid after carrier change
    if (carrier !== props.shipmentDetails.carrier) {
      props.onChange('serviceType', '');
      props.onChange('carrierBillingAccountId', null);
    }

    props.onInput(event);
  };

  const buildDateTime = (date, time): Date => {
    const combined = new Date();
    combined.setFullYear(date.getFullYear());
    combined.setMonth(date.getMonth());
    combined.setDate(date.getDate());
    combined.setHours(time.getHours());
    combined.setMinutes(time.getMinutes());
    combined.setSeconds(0);
    combined.setMilliseconds(0);
    return combined;
  };

  const buildRouteBy = (date, time) => {
    if (!date || !time) {
      return;
    }

    props.onChange('routeBy', buildDateTime(date, time));
  };

  const buildShipByStart = (date, time) => {
    if (!date || !time) {
      return;
    }

    props.onChange('shipByStart', buildDateTime(date, time));
  };

  const buildShipByEnd = (date, time) => {
    if (!date || !time) {
      return;
    }

    props.onChange('shipByEnd', buildDateTime(date, time));
  };

  const handleRouteByDateChange = (date) => {
    setRouteByDate(date);
    buildRouteBy(date, routeByTime);
  };

  const handleRouteByTimeChange = (time) => {
    setRouteByTime(time);
    buildRouteBy(routeByDate, time);
  };

  const handleShipByStartDateChange = (date) => {
    setShipByStartDate(date);
    buildShipByStart(date, shipByStartTime);
  };

  const handleShipByStartTimeChange = (time) => {
    setShipByStartTime(time);
    buildShipByStart(shipByStartDate, time);
  };

  const handleShipByEndDateChange = (date) => {
    setShipByEndDate(date);
    buildShipByEnd(date, shipByEndTime);
  };

  const handleShipByEndTimeChange = (time) => {
    setShipByEndTime(time);
    buildShipByEnd(shipByEndDate, time);
  };

  return (
    <React.Fragment>
      <li className="active" id="step_2">
        <span className="label">2</span>
        <div className="step-wrap">
          <h3>
            <span>Order Details</span>
            <a className="help-note" data-tip="Fill out the following fields for this retail fulfillment order.">
              <i className="fa fa-lg fa-question-circle" />
            </a>
            <ReactTooltip place="right" />
          </h3>
          <div className="input-wrap">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group required">
                  <input
                    placeholder="PO #"
                    type="text"
                    title="PO #"
                    name="purchaseOrder"
                    data-testid="po-input"
                    value={props.shipmentDetails.purchaseOrder || ''}
                    onChange={(event) => props.onInput(event)}
                  ></input>
                </div>
              </div>
              {props.routingDetailsOptions.length > 0 && (
                <div className="col-md-4">
                  <div className="form-group required">
                    <select
                      data-testid="routing-details-input"
                      name="routingDetailsId"
                      onChange={(event) => props.onInput(event)}
                    >
                      {routingDetailsOptions}
                    </select>
                  </div>
                </div>
              )}
              <div className="col-md-4">
                <div className="form-group required">
                  <input
                    placeholder="BOL #"
                    data-testid="bol-input"
                    type="text"
                    title="BOL #"
                    name="bolNumber"
                    value={props.shipmentDetails.bolNumber || ''}
                    onChange={(event) => props.onInput(event)}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="input-wrap">
            <h4>Shipment Details</h4>
            <div>
              <div id="rfo-shipment-details-div" className="row">
                <div className="col-md-4">
                  <div className="form-group required">
                    <select
                      placeholder="Select Shipment Type"
                      data-testid="shipment-type-input"
                      name="shipmentType"
                      value={props.shipmentDetails.shipmentType || ''}
                      onChange={handleShipmentTypeSelect}
                    >
                      {shipmentTypeOptions}
                    </select>
                  </div>
                </div>
                {props.shipmentDetails.shipmentType === 'tbd' && (
                  <div id="rfo-shipment-tbd-div" className="col-md-8">
                    <div className="form-group tbd-shipment-msg">
                      <p>TBD orders will not be visible to the warehouse until Shipment Details have been selected</p>
                    </div>
                  </div>
                )}

                {requireShipmentMethod && (
                  <div id="rfo-shipment-method-div" className="col-md-3">
                    <div className="form-group required">
                      <select
                        placeholder="Select Shipment Method"
                        data-testid="shipment-method-input"
                        name="shipmentMethod"
                        value={props.shipmentDetails.shipmentMethod || ''}
                        onChange={handleShipmentMethodSelect}
                      >
                        {shipmentMethodOptionsHtml}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {requireServiceDetails && (
              <div className="row">
                <div className="col-md-4" id="rfo-carrier-div">
                  <div className="form-group required">
                    <select
                      placeholder="Select Carrier"
                      name="carrier"
                      data-testid="carrier-input"
                      value={props.shipmentDetails.carrier || ''}
                      onChange={handleCarrierSelect}
                    >
                      {carrierOptions}
                    </select>
                  </div>
                </div>
                <div className="col-md-4" id="rfo-service-type-div">
                  <div className="form-group required">
                    <select
                      placeholder="Select Service Type"
                      name="serviceType"
                      data-testid="service-type-input"
                      value={props.shipmentDetails.serviceType || ''}
                      onChange={(event) => props.onInput(event)}
                    >
                      {serviceTypeOptions}
                    </select>
                  </div>
                </div>
                {requireCarrierAccount && (
                  <div className="col-md-4" id="rfo-carrier-billing-account-div">
                    <div className="form-group">
                      <select
                        placeholder="Select Carrier Billing Account"
                        name="carrierBillingAccountId"
                        data-testid="billing-account-input"
                        onChange={(event) => props.onInput(event)}
                      >
                        {carrierAccountOptions}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="input-wrap">
            <h4>Route By Deadline (optional)</h4>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <DatePicker
                    placeholderText="Route by date..."
                    name="routeByDate"
                    dateFormat="MM/dd/yyyy"
                    selected={routeByDate}
                    onChange={(date) => handleRouteByDateChange(date)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <DatePicker
                    placeholderText="Route by time..."
                    name="routeByTime"
                    timeIntervals={30}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="h:mm aa"
                    selected={routeByTime}
                    onChange={(time) => handleRouteByTimeChange(time)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="input-wrap">
            <h4>Requested Ship Window</h4>
            <div className="row space-above">
              <div className="col-md-4">
                <b>Start</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group required">
                  <DatePicker
                    placeholderText="Start date..."
                    name="shipWithinStartDate"
                    dateFormat="MM/dd/yyyy"
                    selected={shipByStartDate}
                    onChange={(date) => handleShipByStartDateChange(date)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group required">
                  <DatePicker
                    placeholderText="Start time..."
                    name="shipWithinStartTime"
                    timeIntervals={30}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="h:mm aa"
                    selected={shipByStartTime}
                    onChange={(time) => handleShipByStartTimeChange(time)}
                  />
                </div>
              </div>
            </div>
            <div className="row space-above">
              <div className="col-md-4">
                <b>End</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group required">
                  <DatePicker
                    placeholderText="End date..."
                    name="shipWithinEndDate"
                    dateFormat="MM/dd/yyyy"
                    selected={shipByEndDate}
                    onChange={(date) => handleShipByEndDateChange(date)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group required">
                  <DatePicker
                    placeholderText="End time..."
                    name="shipWithinEndTime"
                    timeIntervals={30}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="h:mm aa"
                    selected={shipByEndTime}
                    onChange={(time) => handleShipByEndTimeChange(time)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

export default ShipmentDetails;
