import * as React from 'react';
import classNames from 'classnames';
import {ShippingAddressErrorMessages} from './BulkSchedulePickupsModal';

export interface ShippingAddress {
  name: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  locality: string;
  region: string;
  postcode: string;
  country: string;
}

interface ShippingAddressFieldsProps {
  onChange: (ShippingAddress) => void;
  errors: ShippingAddressErrorMessages;
}

const ShippingAddressFields: React.FC<ShippingAddressFieldsProps> = (props) => {
  const [name, setName] = React.useState('');
  const [addressLine1, setAddressLine1] = React.useState('');
  const [addressLine2, setAddressLine2] = React.useState('');
  const [addressLine3, setAddressLine3] = React.useState('');
  const [locality, setCity] = React.useState('');
  const [region, setRegion] = React.useState('');
  const [postcode, setPostalCode] = React.useState('');
  const [country, setCountry] = React.useState('');

  React.useEffect(() => {
    const shippingAddress: ShippingAddress = {
      name,
      addressLine1,
      addressLine2,
      addressLine3,
      locality,
      region,
      postcode,
      country
    };

    props.onChange(shippingAddress);
  }, [name, addressLine1, addressLine2, addressLine3, locality, region, postcode, country]);

  const errors = props.errors;

  // eslint-disable-next-line no-prototype-builtins
  const nameClassNames = classNames('form-group col-sm-12', {'has-error': errors.hasOwnProperty('name')});
  // eslint-disable-next-line no-prototype-builtins
  const address1ClassNames = classNames('form-group col-sm-12', {'has-error': errors.hasOwnProperty('addressLine1')});
  // eslint-disable-next-line no-prototype-builtins
  const cityClassNames = classNames('form-group col-sm-6', {'has-error': errors.hasOwnProperty('locality')});
  // eslint-disable-next-line no-prototype-builtins
  const regionClassNames = classNames('form-group col-sm-6', {'has-error': errors.hasOwnProperty('region')});
  // eslint-disable-next-line no-prototype-builtins
  const postcodeClassNames = classNames('form-group col-sm-4', {'has-error': errors.hasOwnProperty('postcode')});
  // eslint-disable-next-line no-prototype-builtins
  const countryClassNames = classNames('form-group col-sm-8', {'has-error': errors.hasOwnProperty('country')});

  return (
    <div className="shipping-address-fields col-sm-12">
      <div className="row">
        <h5 className="col-sm-12">Shipping Address</h5>
        <label className={nameClassNames}>
          Name
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <span className="help-block">{errors.name || ''}</span>
        </label>

        <label className={address1ClassNames}>
          Address 1
          <input
            type="text"
            className="form-control"
            value={addressLine1}
            onChange={(event) => {
              setAddressLine1(event.target.value);
            }}
          />
          <span className="help-block">{errors.addressLine1 || ''}</span>
        </label>

        <label className="form-group col-sm-12">
          Address 2 <span className="optional">(optional)</span>
          <input
            type="text"
            className="form-control"
            value={addressLine2}
            onChange={(event) => {
              setAddressLine2(event.target.value);
            }}
          />
        </label>

        <label className="form-group col-sm-12">
          Address 3 <span className="optional">(optional)</span>
          <input
            type="text"
            className="form-control"
            value={addressLine3}
            onChange={(event) => {
              setAddressLine3(event.target.value);
            }}
          />
        </label>

        <label className={cityClassNames}>
          City
          <input
            type="text"
            className="form-control"
            value={locality}
            onChange={(event) => {
              setCity(event.target.value);
            }}
          />
          <span className="help-block">{errors.locality || ''}</span>
        </label>

        <label className={regionClassNames}>
          State / Province / Region
          <input
            type="text"
            className="form-control"
            value={region}
            onChange={(event) => {
              setRegion(event.target.value);
            }}
          />
          <span className="help-block">{errors.region || ''}</span>
        </label>

        <label className={postcodeClassNames}>
          Postal Code
          <input
            type="text"
            className="form-control"
            value={postcode}
            onChange={(event) => {
              setPostalCode(event.target.value);
            }}
          />
          <span className="help-block">{errors.postcode || ''}</span>
        </label>

        <label className={countryClassNames}>
          Country
          <input
            type="text"
            className="form-control"
            value={country}
            placeholder='ISO3166-1 alpha-2 code. E.G. "US"'
            onChange={(event) => {
              setCountry(event.target.value);
            }}
          />
          <span className="help-block">{errors.country || ''}</span>
        </label>
      </div>
    </div>
  );
};

export default ShippingAddressFields;
