import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {isNullOrEmpty} from '../StringHelper';

interface Props {
  value?: string;
  placeholderValue?: string;
  minLength?: number;
  maxLength?: number;
  isEditable: boolean;
  onSave?: (newValue: string) => Promise<boolean>;
}

export const ConfirmationFormRowString: React.FC<Props> = (props) => {
  const ref = useRef(null);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [valueToDisplay, setValueToDisplay] = useState<string>(null);

  const minLength = props.minLength ?? 0;
  const maxLength = props.maxLength ?? 100;

  useEffect(() => {
    if (enabled) {
      setValueToDisplay(props.value);
    } else {
      setValueToDisplay(props.value ?? props.placeholderValue);
    }
  }, [props.value, props.placeholderValue]);

  //focus on the input when editing so the user doesn't have to click on it
  useEffect(() => {
    if (enabled) {
      ref.current.focus();
      // wipe the placeholder text when entering edit mode
      if (valueToDisplay === props.placeholderValue) {
        setValueToDisplay(null);
      }
    }
  }, [enabled]);

  const onSave = async () => {
    setEnabled(false);

    // save the value but change it back to what it was if the save fails
    if (props.onSave) {
      if (!(await props.onSave(valueToDisplay))) {
        if (isNullOrEmpty(props.value)) {
          setValueToDisplay(props.placeholderValue);
        } else {
          setValueToDisplay(props.value);
        }
      } else {
        if (isNullOrEmpty(valueToDisplay)) {
          setValueToDisplay(props.placeholderValue);
        }
      }
    }
  };

  const editingDOM = (
    <div>
      <input
        type={'text'}
        value={valueToDisplay || ''}
        maxLength={maxLength}
        minLength={minLength}
        onChange={(event) => setValueToDisplay(event.target.value)}
        ref={ref}
      />
      <a onClick={onSave}>Save</a>
    </div>
  );

  const editableDOM = (
    // href must be present for the link to be visible to tests
    <div>
      <a onClick={() => setEnabled(true)} href={'#'}>
        {valueToDisplay}
      </a>
    </div>
  );

  const plainDOM = <div>{valueToDisplay}</div>;

  const valueDOM = () => {
    if (props.isEditable) {
      if (enabled) {
        return editingDOM;
      } else {
        return editableDOM;
      }
    } else {
      return plainDOM;
    }
  };

  return (
    <React.Fragment>
      <div className="modal-form-field-value">{valueDOM()}</div>
    </React.Fragment>
  );
};
