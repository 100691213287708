import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {Loader} from '@flexe/ui-components';
import EdiFilesService from '../../edi-files/EdiFilesService';
import {DisplayGenericErrors, GenericErrorDisplay} from '../../../shared/GenericErrorDisplay';
import FileStorageService from '../../file-storage/FileStorageService';
import EdiFilesServiceV2 from '../../edi-files/EdiFilesServiceV2';
import {FilterValue} from '../../../shared/CommonInterfaces';
import {Props, ServiceTypes} from '../OutboundOrders';
import OutboundOrdersService from '../OutboundOrdersService';
import CreateSingleOrderButton from '../buttons/CreateSingleOrderButton';
import BulkCancelOrdersModalButton from '../buttons/BulkCancelOrdersModalButton';
import BulkCreateOrdersModalButton from '../buttons/BulkCreateOrdersModalButton';
import {OrdersViewType} from '../OutboundOrdersInterfaces';
import OrdersViewTypeButton from '../buttons/OrdersViewTypeButton';
import PageSizeInput, {PageSize} from '../generic/PageSizeInput';
import OutboundOrdersUploadModal from '../modals/OutboundOrdersUploadModal';
import {
  csvCreateFileUploadTypeV1,
  csvCreateFileUploadTypeV2,
  csvCreateHeadersV1,
  csvCreateHeadersV2,
  csvCreateOrderByLotHeaders
} from '../helpers/OutboundOrderCsv';
import OutboundOrdersBulkCancelModal from '../modals/OutboundOrdersBulkCancelModal';
import {buildReservationOptions} from '../helpers/Reservations';
import {
  extractFiltersFromSearchParams,
  extractNewFilters,
  FilterDefinition,
  filterKeyCreatedStarting,
  getDefaultCreatedAtFilters,
  getSearchParamsFromFilters,
  makeBlankFilters,
  orderFilterDefs,
  orderLineFilterDefs,
  TrackedFilterValues
} from '../helpers/OutboundOrdersFilters';
import {getURLSearchParams, pushNewURLState, replaceURLState, subscribeToURLStatePop} from '../helpers/URLManipulation';
import OutboundOrderLinesTable from './OutboundOrderLinesTable';
import OutboundOrdersTable from './OutboundOrdersTable';

export interface OrdersSearchPageState {
  pageSize: PageSize;
  viewType: OrdersViewType;
  filterValues: TrackedFilterValues; // There's a few ways to solve it, but tracking filters here because we can grab them from the URL
  showBulkCancellationModal: boolean;
  showBulkCreateModal: boolean;
  errors: GenericErrorDisplay[];
}

const initialPageSize = PageSize.Fifty;
const initialViewType = OrdersViewType.orders;
const makeInitialPageState = (
  viewType: OrdersViewType,
  pageSize: PageSize,
  filterValues: TrackedFilterValues
): OrdersSearchPageState => ({
  viewType,
  pageSize,
  filterValues,
  showBulkCancellationModal: false,
  showBulkCreateModal: false,
  errors: []
});

export interface OrdersPageError {
  errorType: 'None' | 'UnexpectedFailure' | 'ApiGetError' | 'ApiCancelError';
  details?: string[];
}

interface CustomerConfiguration {
  carriers: string[];
  serviceTypes: ServiceTypes;
}
const initialCustomerConfiguration = null;
const bulkOrderCreateTemplatePath = '/static/files/OutboundOrderBulkCreateV2.csv';
const orderByLotCreateTemplatePath = '/static/files/OutboundOrderByLotBulkCreate.csv';

export const dateFormat = 'MM/DD/YY h:mma';

const loadCustomerConfiguration = async (ediFilesService: EdiFilesService): Promise<CustomerConfiguration> => {
  if (!ediFilesService) {
    return initialCustomerConfiguration;
  }

  const data = await ediFilesService.getCarriersAndServiceTypes();
  // this ridiculousness is to remove a ® character from the
  // API-returned value for the carrier UPS
  const cleanedServiceTypes = data.data.serviceTypes;
  /* eslint-disable */
  cleanedServiceTypes['UPS'] = cleanedServiceTypes['UPS®'];
  delete cleanedServiceTypes['UPS®'];
  /* eslint-enable */

  return {
    carriers: data.data.carriers,
    serviceTypes: cleanedServiceTypes
  };
};

// enums are funky
// Matching to a string enum from a string isn't perfect. Since these values won't change much,
//  Just do a compare
const getMatchingOrdersViewType = (lookupValue: string): OrdersViewType | undefined => {
  switch (lookupValue) {
    case OrdersViewType.orders:
      return OrdersViewType.orders;
    case OrdersViewType.lines:
      return OrdersViewType.lines;
    default:
      return undefined;
  }
};

// Number enum matching has various solutions
const getMatchingPageSize = (lookupValue: string): PageSize | undefined => {
  const lookupNum = Number(lookupValue);
  if (isNaN(lookupNum)) {
    return undefined;
  }

  return lookupNum in PageSize ? (lookupNum as PageSize) : undefined;
};

const getViewTypeFromSearchParams = (searchParams: URLSearchParams): OrdersViewType => {
  const input = searchParams.get('viewType');
  const match = getMatchingOrdersViewType(input);

  return match ? match : initialViewType;
};

const getPageSizeFromSearchParams = (searchParams: URLSearchParams): PageSize => {
  const input = searchParams.get('pageSize');
  const match = getMatchingPageSize(input);

  return match ? match : initialPageSize;
};

const setSearchParamsViewType = (searchParams: URLSearchParams, viewType: OrdersViewType): void =>
  searchParams.set('viewType', viewType);

const setSearchParamsPageSize = (searchParams: URLSearchParams, pageSize: PageSize): void =>
  searchParams.set('pageSize', pageSize.toString());

const getFilterDefs = (viewType: OrdersViewType): FilterDefinition[] =>
  viewType === OrdersViewType.orders ? orderFilterDefs : orderLineFilterDefs;

const buildInitialFilters = (viewType: OrdersViewType, searchParams?: URLSearchParams): TrackedFilterValues => {
  const initialFilters = searchParams
    ? extractFiltersFromSearchParams(searchParams, getFilterDefs(viewType))
    : makeBlankFilters();

  if (Object.keys(initialFilters.inputFilters).length < 1) {
    const [displayValue, apiParam] = getDefaultCreatedAtFilters();
    initialFilters.inputFilters[filterKeyCreatedStarting] = displayValue;
    initialFilters.apiFilters[filterKeyCreatedStarting] = apiParam;
  }

  return initialFilters;
};

export const startNewLongRunningAction = (
  setIsLoading: (v: boolean) => any,
  setErrors: (v: OrdersPageError) => void
): void => {
  setErrors({errorType: 'None'});
  setIsLoading(true);
};

const OutboundOrdersV2: FC<Props> = (props) => {
  const [ordersSearchPageState, setOrdersSearchPageState] = useState<OrdersSearchPageState>(
    makeInitialPageState(initialViewType, initialPageSize, makeBlankFilters())
  );
  const [customerConfiguration, setCustomerConfiguration] = useState<CustomerConfiguration>(
    initialCustomerConfiguration
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const searchParams = getURLSearchParams();
    const pageSize = getPageSizeFromSearchParams(searchParams);
    const viewType = getViewTypeFromSearchParams(searchParams);
    const filterValues = buildInitialFilters(viewType, searchParams);

    subscribeToURLStatePop(handleURLStatePop);

    const pageState = {...ordersSearchPageState, pageSize, viewType, filterValues};
    const newSearchParams = getSearchParamsFromFilters(filterValues, getFilterDefs(viewType));
    setSearchParamsPageSize(newSearchParams, pageSize);
    setSearchParamsViewType(newSearchParams, viewType);
    replaceURLState(newSearchParams);
    setOrdersSearchPageState(pageState);
    loadCustomerConfiguration(props.ediFilesService || new EdiFilesService(props.authenticityToken)).then((result) => {
      setCustomerConfiguration(result);
      setIsLoading(false);
    });
  }, []);

  const handleURLStatePop = (poppedUrlSearchParams: URLSearchParams) => {
    const pageSize = getPageSizeFromSearchParams(poppedUrlSearchParams);
    const viewType = getViewTypeFromSearchParams(poppedUrlSearchParams);
    const filterValues = extractFiltersFromSearchParams(poppedUrlSearchParams, getFilterDefs(viewType));

    setOrdersSearchPageState(makeInitialPageState(viewType, pageSize, filterValues));
  };

  const toggleBulkCreateModal = () =>
    setOrdersSearchPageState({
      ...ordersSearchPageState,
      showBulkCreateModal: !ordersSearchPageState.showBulkCreateModal
    });
  const toggleBulkCancellationModal = () =>
    setOrdersSearchPageState({
      ...ordersSearchPageState,
      showBulkCancellationModal: !ordersSearchPageState.showBulkCancellationModal
    });

  const setViewType = (newViewType: OrdersViewType) => {
    if (newViewType !== ordersSearchPageState.viewType) {
      const pageSize = ordersSearchPageState.pageSize;

      // Changing view type should reset filters
      const newFilterValues = buildInitialFilters(newViewType);
      const searchParams = getSearchParamsFromFilters(newFilterValues, getFilterDefs(newViewType));

      setSearchParamsViewType(searchParams, newViewType);
      setSearchParamsPageSize(searchParams, pageSize);

      pushNewURLState(searchParams);
      setOrdersSearchPageState(makeInitialPageState(newViewType, pageSize, newFilterValues));
    }
  };
  const handleFilterChange = (filters: FilterValue[]): void => {
    // Keep other state on filter change
    const viewType = ordersSearchPageState.viewType;
    const pageSize = ordersSearchPageState.pageSize;

    const filterValues = extractNewFilters(filters);
    const searchParams = getSearchParamsFromFilters(filterValues, getFilterDefs(viewType));

    setSearchParamsViewType(searchParams, ordersSearchPageState.viewType);
    setSearchParamsPageSize(searchParams, ordersSearchPageState.pageSize);

    pushNewURLState(searchParams);
    setOrdersSearchPageState(makeInitialPageState(viewType, pageSize, filterValues));
  };

  const setPageSize = (newPageSize: PageSize) => {
    if (newPageSize !== ordersSearchPageState.pageSize) {
      // Keep other state on pageSize change
      const viewType = ordersSearchPageState.viewType;
      const filterValues = ordersSearchPageState.filterValues;

      const searchParams = getURLSearchParams();
      setSearchParamsPageSize(searchParams, newPageSize);

      pushNewURLState(searchParams);
      setOrdersSearchPageState(makeInitialPageState(viewType, newPageSize, filterValues));
    }
  };

  const setErrors = (rawError: OrdersPageError) => {
    const errors: GenericErrorDisplay[] = [];
    switch (rawError.errorType) {
      case 'None':
        break;

      case 'UnexpectedFailure':
        errors.push({
          header: 'Unexpected error. Please check your connection and refresh. Contact Flexe if the error persists.',
          details: rawError.details
        });
        break;

      case 'ApiGetError':
        errors.push({
          header: 'Failed to retrieve data:',
          details: rawError.details
        });
        break;

      case 'ApiCancelError':
        errors.push({
          header: 'The following lines are still selected because they could not be cancelled:',
          details: rawError.details
        });
        break;

      default:
        // This shouldn't be necessary but be safe
        errors.push({
          header: 'Unhandled error type',
          details: rawError.details
        });
    }

    setOrdersSearchPageState({...ordersSearchPageState, errors});
  };

  if (isLoading) {
    return (
      <div className="container-fluid fulfillment-index outbound-orders">
        <Loader loading={isLoading} />
      </div>
    );
  }

  const reservationOptions = buildReservationOptions(props.omsReservations);
  const outboundOrdersService = props.outboundOrdersService || new OutboundOrdersService();
  const fileStorageService = props.fileStorageService || new FileStorageService();
  const ediFilesServiceV2 = props.v2EdiFilesService || new EdiFilesServiceV2();

  const tableToShow =
    ordersSearchPageState.viewType === OrdersViewType.orders ? (
      <OutboundOrdersTable
        companyId={props.currentCompany.id}
        reservations={props.omsReservations}
        reservationOptions={reservationOptions}
        outboundOrdersService={outboundOrdersService}
        pageSize={ordersSearchPageState.pageSize}
        filterValues={ordersSearchPageState.filterValues}
        handleFilterChange={handleFilterChange}
        setErrors={setErrors}
      />
    ) : (
      <OutboundOrderLinesTable
        outboundOrdersService={outboundOrdersService}
        pageSize={ordersSearchPageState.pageSize}
        filterValues={ordersSearchPageState.filterValues}
        handleFilterChange={handleFilterChange}
        setErrors={setErrors}
        authenticityToken={props.authenticityToken}
        currentCompany={props.currentCompany}
        reservations={props.omsReservations}
        reservationOptions={reservationOptions}
        distributionByLotReservationIds={props.distributionByLotReservationIds}
        featureFlags={props.featureFlags}
      />
    );

  const distributionByLotShipper = props.distributionByLotReservationIds.length > 0;
  const templatePath = distributionByLotShipper ? orderByLotCreateTemplatePath : bulkOrderCreateTemplatePath;
  const csvHeaders = distributionByLotShipper ? csvCreateOrderByLotHeaders : csvCreateHeadersV2;
  const errorDisplays = DisplayGenericErrors(ordersSearchPageState.errors);

  return (
    <div className="container-fluid fulfillment-index outbound-orders">
      <div className="row">
        <div className="col-sm-4">
          <h1>Outbound Orders</h1>
        </div>
        <div className="col-sm-8">
          <BulkCreateOrdersModalButton toggleModal={toggleBulkCreateModal} />
          <a href="/s/fulfillment/ecommerce" className="btn pull-right">
            View Shipments
          </a>
          <CreateSingleOrderButton showOmniOrderCreateBtn={props.shipperHasOmniReservation} />
          <BulkCancelOrdersModalButton
            outboundOrdersService={props.outboundOrdersService}
            showOrderCancellationByCsvButton={ordersSearchPageState.showBulkCancellationModal}
            toggleModal={toggleBulkCancellationModal}
          />
        </div>
      </div>
      <div id="fulfillment-component">
        <div className="row space-below-sm">
          <div className="col-md-4">
            <OrdersViewTypeButton viewType={ordersSearchPageState.viewType} setViewType={setViewType} />
          </div>
          <div className="col-xl-1 pull-right">
            <PageSizeInput label="Rows per Page" value={ordersSearchPageState.pageSize} onValueChange={setPageSize} />
          </div>
        </div>
        {errorDisplays}
        {errorDisplays && errorDisplays.length > 0 && <div className="row space-below"></div>}
        {tableToShow}
      </div>
      <OutboundOrdersUploadModal
        csvTemplate={{filepath: templatePath, displayName: 'CSV template'}}
        distributionByLotShipper={distributionByLotShipper}
        serviceTypes={customerConfiguration.serviceTypes}
        carriers={customerConfiguration.carriers}
        showUploadModal={ordersSearchPageState.showBulkCreateModal}
        headers={csvHeaders}
        fileUploadType={csvCreateFileUploadTypeV2}
        fileStorageService={fileStorageService}
        ediFilesServiceV2={ediFilesServiceV2}
        // TODO: delete oldRequiredHeaders once old template has been retired
        oldHeaders={csvCreateHeadersV1}
        oldFileUploadType={csvCreateFileUploadTypeV1}
        toggleUploadModal={toggleBulkCreateModal}
      />
      <OutboundOrdersBulkCancelModal
        currentCompany={props.currentCompany}
        showUploadModal={ordersSearchPageState.showBulkCancellationModal}
        toggleUploadModal={toggleBulkCancellationModal}
        authenticityToken={props.authenticityToken}
      />
    </div>
  );
};
export default OutboundOrdersV2;
