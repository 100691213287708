import {ApiResponse} from '../CommonInterfaces';
import {
  OutboundManifestListResponse,
  OutboundTrailerManifestDetail,
  VacantDockLocationsResponse
} from '../../warehouse/loads/ManifestInterfaces';
import InternalAPIService from './InternalAPIService';

class OutboundManifestService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/outbound_manifests');
  }

  public async listOutboundManifests(
    filters: any,
    pageSize: number,
    continuationToken: string = null
  ): Promise<ApiResponse<OutboundManifestListResponse>> {
    const data = {filters, pageSize, continuationToken};
    return await this.makePostRequest(`${this.baseUrl}/list`, data);
  }

  public async getManifestDetail(id: number): Promise<ApiResponse<OutboundTrailerManifestDetail>> {
    return await this.makeGetRequest(`${this.baseUrl}/${id}`);
  }

  public async removeShipment(manifestId: number, shipmentId: number) {
    const data = {shipmentId};
    return await this.makePatchRequest(`${this.baseUrl}/${manifestId}/remove_shipment`, data);
  }

  public async removeLpn(manifestId: number, lpnBarcode: string) {
    const data = {lpnBarcode};
    return await this.makePatchRequest(`${this.baseUrl}/${manifestId}/remove_lpn`, data);
  }

  public async completeAndSubmitToCarrier(manifestId: number): Promise<ApiResponse<OutboundTrailerManifestDetail>> {
    return await this.makePatchRequest(`${this.baseUrl}/${manifestId}/complete_and_submit_to_carrier`, {});
  }

  public async cancelManifest(manifestId: number): Promise<ApiResponse<OutboundTrailerManifestDetail>> {
    return await this.makePatchRequest(`${this.baseUrl}/${manifestId}/cancel_manifest`, {});
  }

  public async downloadManifest(manifestId: number): Promise<ApiResponse<OutboundTrailerManifestDetail>> {
    return await this.makeGetRequest(`${this.baseUrl}/${manifestId}/download.csv`, {});
  }

  public async retrieveVacantDockLocations(warehouseId: number): Promise<ApiResponse<VacantDockLocationsResponse>> {
    return await this.makeGetRequest(`/api/v2/outbound/manifests/vacant-docks-by-warehouse-id`, {
      'warehouse-id': warehouseId
    });
  }
}

export default OutboundManifestService;
