import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';
import {RetailOrderDetails} from '../../shared/services/RetailFulfillmentService';
import {formatDateTime} from '../Utilities';

interface HeaderPaneRoutingInformationProps {
  orderDetails: RetailOrderDetails;
}

export const HeaderPaneRoutingInformation: React.FC<HeaderPaneRoutingInformationProps> = (props) => {
  const warehouseTimezone: string = props.orderDetails.warehouse.timezone;

  const routingInformation = (): React.ReactFragment => {
    return (
      <div id="routing-information" className="col-md-3 quarters">
        <h4 className="text-uppercase">Routing</h4>
        <div className="row no-margin">
          <ul className="col-sm-12 no-padding">
            {requestedPickupDates()}
            {confirmedPickupDates()}
            {routeByDate()}
          </ul>
        </div>
        <div className="vertical-rule hidden-xs"></div>
      </div>
    );
  };

  const momentDatePartFormat = 'MM/DD/YYYY';
  const momentTimePartFormat = 'h:mm A z';

  const requestedPickupDates = (): React.ReactFragment => {
    if (!props.orderDetails.pickupWindowStart) {
      return (
        <li className="row">
          <div className="col-sm-12">
            <b>Requested Pickup Dates:</b>

            <a href="#" className="help-note" data-tip data-for="requested-pickup-dates-tooltip">
              <i className="fa fa-lg fa-question-circle"></i>
            </a>
            <ReactTooltip className="document-tooltip" place="right" type="light" id="requested-pickup-dates-tooltip">
              Originally requested pickup date/time window.
            </ReactTooltip>

            <br />

            <p className="pull-left">
              <i className="fa fa-calendar-check-o blue3"></i>
            </p>

            <p className="pull-left space-left">
              {formatDateTime(props.orderDetails.shipWithinStart, momentDatePartFormat, warehouseTimezone)}
              <br />
              <span className="time-label grey3">
                {formatDateTime(props.orderDetails.shipWithinStart, momentTimePartFormat, warehouseTimezone)}
              </span>
            </p>

            <p className="pull-left space-left">
              <i className="fa fa-arrows-h"></i>
            </p>

            <p className="pull-left space-left">
              {formatDateTime(props.orderDetails.shipWithinEnd, momentDatePartFormat, warehouseTimezone)}
              <br />
              <span className="time-label grey3">
                {formatDateTime(props.orderDetails.shipWithinEnd, momentTimePartFormat, warehouseTimezone)}
              </span>
            </p>
          </div>
        </li>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  const confirmedPickupDates = (): React.ReactFragment => {
    if (props.orderDetails.pickupWindowStart) {
      return (
        <li className="row">
          <div className="col-sm-12">
            <b>Confirmed Pickup:</b>

            <a href="#" className="help-note" data-tip data-for="confirmed-pickup-dates-tooltip">
              <i className="fa fa-lg fa-question-circle"></i>
            </a>
            <ReactTooltip className="document-tooltip" place="right" type="light" id="confirmed-pickup-dates-tooltip">
              Actual pickup date/time window confirmed by the routing company.
            </ReactTooltip>

            <br />

            <p className="pull-left">
              <i className="fa fa-calendar-check-o blue3"></i>
            </p>

            <p id="confirmed-pickup-dates" className="pull-left space-left">
              {formatDateTime(props.orderDetails.pickupWindowStart, momentDatePartFormat, warehouseTimezone)}
              <br />
              <span className="time-label grey3">
                {formatDateTime(props.orderDetails.pickupWindowStart, momentTimePartFormat, warehouseTimezone)}
              </span>
            </p>

            <p className="pull-left space-left">
              <i className="fa fa-arrows-h"></i>
            </p>

            <p className="pull-left space-left">
              {formatDateTime(props.orderDetails.pickupWindowEnd, momentDatePartFormat, warehouseTimezone)}
              <br />
              <span className="time-label grey3">
                {formatDateTime(props.orderDetails.pickupWindowEnd, momentTimePartFormat, warehouseTimezone)}
              </span>
            </p>
          </div>
        </li>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  const routeByDate = (): React.ReactFragment => {
    if (props.orderDetails.routeBy) {
      return (
        <li className="row">
          <div className="col-sm-12">
            <b className="red2">Order Ready for Pickup</b>

            <a href="#" className="help-note" data-tip data-for="route-by-date-tooltip">
              <i className="fa fa-lg fa-question-circle"></i>
            </a>
            <ReactTooltip className="document-tooltip" place="right" type="light" id="route-by-date-tooltip">
              All pallets must be built and the shipment scheduled by this date in order to ensure pickup within the
              desired window.
            </ReactTooltip>

            <br />

            <p className="pull-left">
              <i className="fa fa-hand-paper-o red3"></i>
            </p>

            <p id="route-by-date" className="pull-left space-left">
              {formatDateTime(props.orderDetails.routeBy, momentDatePartFormat, warehouseTimezone)}
              <br />
              <span className="time-label grey3">
                {formatDateTime(props.orderDetails.routeBy, momentTimePartFormat, warehouseTimezone)}
              </span>
            </p>
          </div>
        </li>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  return <React.Fragment>{routingInformation()}</React.Fragment>;
};
