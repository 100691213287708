import * as React from 'react';
import WarningModal from '../../../shared/WarningModal';
import {CancellationReasonCode} from '../../../shared/properties/CancellationReason';
import {CancellationReasonString} from '../../../shared/properties/CancellationReason';

const useState = React.useState;

export default function CancelShipment({shipmentId, reservationId, shipmentService, handleSuccess, handleError}) {
  const [showModalForRsnCode, setShowModalForRsnCode] = useState(false);
  const [selCancelledReason, setSelCancelledReason] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [disableCheckBoxDM, setDisableCheckBoxDM] = useState(false);
  const [disableCheckBoxSH, setDisableCheckBoxSH] = useState(false);
  const [disableCheckBoxMR, setDisableCheckBoxMR] = useState(false);
  const [disableCheckBoxEX, setDisableCheckBoxEX] = useState(false);

  async function cancelShipment() {
    if (selCancelledReason.trim() !== '') {
      const response = await shipmentService.cancelShipment(shipmentId, reservationId, selCancelledReason);
      if (response && response.errors.length === 0) {
        setShowModalForRsnCode(false);
        handleSuccess(`Shipment ${shipmentId} successfully cancelled!`);
      } else {
        let message = 'There was an error cancelling your shipment';
        if (response) {
          const serverErrors = response.errors.map((e) => e.detail);
          message += `: ${serverErrors.join(', ')}`;
        }
        setShowModalForRsnCode(false);
        handleError(message);
      }
    } else {
      setShowErrorAlert(true);
    }
    enableReasonCode();
  }
  function handleChange(evt) {
    if (evt.target.checked) {
      setSelCancelledReason(evt.target.value);
      if (evt.target.name !== CancellationReasonCode.DM) {
        setDisableCheckBoxDM(true);
      }
      if (evt.target.name !== CancellationReasonCode.SH) {
        setDisableCheckBoxSH(true);
      }
      if (evt.target.name !== CancellationReasonCode.MR) {
        setDisableCheckBoxMR(true);
      }
      if (evt.target.name !== CancellationReasonCode.EX) {
        setDisableCheckBoxEX(true);
      }
    } else {
      setSelCancelledReason('');
      enableReasonCode();
    }
  }
  function enableReasonCode() {
    setDisableCheckBoxDM(false);
    setDisableCheckBoxSH(false);
    setDisableCheckBoxMR(false);
    setDisableCheckBoxEX(false);
  }
  function resetModalAndRsnCode() {
    setShowModalForRsnCode(false);
    setSelCancelledReason('');
    enableReasonCode();
  }
  const modalTitle = <div className="displayStyle">Cancel Shipment</div>;
  const modalBodyForRsnCode = (
    <div className="cancellation-modal_rsn-body">
      <div className="displayStyle">
        <p>
          <i className="fa fa-exclamation-triangle fa-2x" />
          &nbsp;Are you sure you want to cancel Shipment {shipmentId}? <br />
        </p>
      </div>
      <p className="displayStyle">Select a reason to continue:</p>
      <div className="displayStyle">
        <input
          data-testid="checkboxDM"
          type="checkbox"
          id={CancellationReasonCode.DM}
          name={CancellationReasonCode.DM}
          value={CancellationReasonCode.DM}
          disabled={disableCheckBoxDM}
          onChange={(e) => handleChange(e)}
        />
        &nbsp;{CancellationReasonString.DM}
        <br />
      </div>
      <div className="displayStyle">
        <input
          data-testid="checkboxSH"
          type="checkbox"
          id={CancellationReasonCode.SH}
          name={CancellationReasonCode.SH}
          value={CancellationReasonCode.SH}
          disabled={disableCheckBoxSH}
          onChange={(e) => handleChange(e)}
        />
        &nbsp;{CancellationReasonString.SH}
        <br />
      </div>
      <div className="displayStyle">
        <input
          data-testid="checkboxMR"
          type="checkbox"
          id={CancellationReasonCode.MR}
          name={CancellationReasonCode.MR}
          value={CancellationReasonCode.MR}
          disabled={disableCheckBoxMR}
          onChange={(e) => handleChange(e)}
        />
        &nbsp;{CancellationReasonString.MR}
        <br />
      </div>
      <div className="displayStyle">
        <input
          data-testid="checkboxEX"
          type="checkbox"
          id={CancellationReasonCode.EX}
          name={CancellationReasonCode.EX}
          disabled={disableCheckBoxEX}
          value={CancellationReasonCode.EX}
          onChange={(e) => handleChange(e)}
        />
        &nbsp;{CancellationReasonString.EX}
        <br />
      </div>
    </div>
  );
  return (
    <div className="cancel-shipment-component">
      <button className="no-cta cancel-button list-view issue" onClick={() => setShowModalForRsnCode(true)}>
        Cancel Shipment
      </button>
      {showModalForRsnCode && (
        <WarningModal
          elementId="modal-RsnCode"
          toggleModalHandler={() => resetModalAndRsnCode()}
          onConfirmClick={() => cancelShipment()}
          confirmButtonText="Yes, Cancel Shipment"
          modalTitle={modalTitle}
          modalBody={modalBodyForRsnCode}
        />
      )}
      {showErrorAlert && (
        <div className="col-md-12 space-below">
          <div className="alert alert-error" data-testid="reason-code-failure-alert" role="alert">
            <p>
              <br /> {'Please select a valid reason code to proceed'} <br />
            </p>
            <button type="button" className="close" onClick={() => setShowErrorAlert(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
