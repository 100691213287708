import * as React from 'react';
import {FunctionComponent} from 'react';
import classNames from 'classnames';
import FlexeButton from './FlexeButton';
import FlexeLinkButton from './FlexeLinkButton';

/*
This component is designed to be rendered by CardList and SHOULD NOT be used independently of CardList
*/

interface Props {
  title: string;
  info: string;
  id?: string | number;
  buttonText?;
  actionHint?: string;
  actionClick?: any;
  actionRoute?: any;
  actionClickDisabled?: boolean;
  needsAttention?: boolean;
  optionMenu?: React.ReactElement;
}

const CardListItem: FunctionComponent<Props> = (props) => {
  const cardListItemClasses = ['card-list-item', 'row', props.needsAttention && 'needs-attention'];
  const actionWidthClasses = ['list-item-action', 'col-md-3'];

  const actionHintClasses = ['button-hint', props.needsAttention && 'needs-attention'];

  const TitleClasses = ['item-title', props.needsAttention && 'needs-attention'];

  const renderAction = () => {
    if (props.actionClick) {
      return (
        <FlexeButton
          level="primary"
          text={props.buttonText || 'Next'}
          handleClick={props.actionClick}
          isDisabled={props.actionClickDisabled}
        />
      );
    } else if (props.actionRoute) {
      return (
        <FlexeLinkButton level="primary" href={props.actionRoute}>
          {props.buttonText || 'Next'}
        </FlexeLinkButton>
      );
    } else {
      return null;
    }
  };

  return (
    <li data-testid={`${props.title}-card`} className={classNames(cardListItemClasses)}>
      <div className="list-info col-md-9">
        <p data-testid={props.title}>
          <span className={classNames(TitleClasses)}>{props.title}</span>
          <br />
          {props.info}
        </p>
      </div>
      <div data-testid={props.info} className={classNames(actionWidthClasses)}>
        {props.actionHint && <span className={classNames(actionHintClasses)}>{props.actionHint}</span>}
        {/* render link styled as button, or button, depending on desired action */}
        {renderAction()}
        {props.optionMenu && <div className="option-menu-wrapper">{props.optionMenu}</div>}
      </div>
    </li>
  );
};

export default CardListItem;
