import axios from 'axios';
import {format} from 'date-fns';
import {v4} from 'uuid';
import {ApiRequest, ApiResponse} from '../CommonInterfaces';
import {
  CreateWaveTemplateRequest,
  CreateWaveTemplateResponse,
  DeleteWaveTemplateRequest,
  DeleteWaveTemplateResponse,
  GetPastDueRequest,
  GetWaveTemplateResponse,
  GetWaveTemplatesRequest,
  SkuData
} from '../../warehouse/batch-waving/WaveInterfaces';
import * as BatchWaving from '../../warehouse/ecommerce-batches/BatchInterfaces';
import InternalAPIService from './InternalAPIService';

class WaveTemplateService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/wh/wave_template');
  }

  public async getPastDue(
    warehouseId: number,
    currentDate: string,
    currentDateTime: string
  ): Promise<ApiResponse<GetWaveTemplateResponse>> {
    let query: GetPastDueRequest;
    if (currentDate) {
      query = {
        warehouseId,
        currentDate
      };
    } else {
      query = {
        warehouseId,
        currentDateTime
      };
    }

    return this.makeGetRequest(`${this.baseUrl}/past_due_shipments`, query);
  }

  public async getWaveTemplates(
    warehouseId: number,
    shipByDate: string
  ): Promise<ApiResponse<GetWaveTemplateResponse>> {
    const query: GetWaveTemplatesRequest = {
      warehouseId,
      shipByDate
    };
    return this.makeGetRequest(this.baseUrl, query);
  }

  public async createWaveTemplate(
    templateName: string,
    wave: BatchWaving.WaveParameters
  ): Promise<ApiResponse<CreateWaveTemplateResponse>> {
    const requestParameters: ApiRequest<CreateWaveTemplateRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        templateName,
        wave
      }
    };
    try {
      const response = await axios.post(`${this.baseUrl}`, requestParameters);
      return response.data as ApiResponse<CreateWaveTemplateResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async deleteWaveTemplate(templateId: number): Promise<ApiResponse<DeleteWaveTemplateResponse>> {
    const requestParameters: ApiRequest<DeleteWaveTemplateRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        templateId
      }
    };
    try {
      const response = await axios.delete(`${this.baseUrl}/${templateId}`, requestParameters);
      return {data: {status: response.status}} as ApiResponse<DeleteWaveTemplateResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }
}

export default WaveTemplateService;
