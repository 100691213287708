/** @jsxRuntime classic */
/** @jsx jsx */

import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';

interface Props {
  onTabClick: (key: string) => void;
  tabs: Tab[];
}

interface Tab {
  isActive: boolean;
  key: string;
  title: string;
}

const listStyles = css({
  'borderBottom': '1px solid #ddd',
  'paddingLeft': 0,
  'marginBottom': 0,
  'listStyle': 'none',
  '&:after': {content: '""', display: 'table', clear: 'both'}
});

const getTabStyles = (isActive) =>
  css({
    display: 'block',
    float: 'left',
    marginBottom: '-1px',
    backgroundColor: isActive ? '#fff' : '#e1e4e8',
    color: '#555968',
    cursor: isActive ? 'default' : 'pointer',
    border: '1px solid #ddd',
    borderBottomColor: 'transparent',
    marginRight: '2px',
    borderRadius: '4px 4px 0 0',
    padding: `${tokens.spacing.v100.value}px ${tokens.spacing.v200.value}px`
  });

const Tabs = ({onTabClick, tabs}: Props) => {
  return (
    <ul css={listStyles}>
      {tabs.map((tab) => (
        <li key={tab.key} onClick={() => onTabClick(tab.key)} css={getTabStyles(tab.isActive)}>
          {tab.title}
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
