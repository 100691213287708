import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Modal} from '@flexe/ui-components';
import FlexeContext from '../../../contexts/FlexeContext';
import LoadsService from '../../../shared/services/LoadsService';
import {CreateLoadResultInfo, UpsertLoadRequest, UpsertLoadState} from '../LoadInterfaces';
import OutboundManifestService from '../../../shared/services/OutboundManifestService';
import {VacantDockLocation} from '../ManifestInterfaces';
import PreviewShipments from './PreviewShipments';

interface Props {
  isOpen: boolean;
  toggleModal: () => void;
  cancelUpsert: () => void;
  warehouseId: number;
  loadState: UpsertLoadState; // the state of the load at time of modal entry
  onCreateLoad?: () => void;
  onEditLoad?: (loadState: UpsertLoadState) => void;
}

export enum UpsertMode {
  CREATE = 'Create Load',
  EDIT = 'Edit Load'
}

export function UpsertLoadModal(props: Props) {
  const upsertMode = props.loadState != null ? UpsertMode.EDIT : UpsertMode.CREATE;
  const {authenticityToken} = useContext(FlexeContext);
  const loadsService = new LoadsService(authenticityToken);
  const outboundManifestService = new OutboundManifestService(authenticityToken);

  const [creationResultInfo, setCreationResultInfo] = useState<CreateLoadResultInfo>(null);
  const [error, setError] = useState<string | null>();
  const [dockLocs, setDockLocs] = useState<VacantDockLocation[]>([]);
  const [loadState, setLoadState] = useState<UpsertLoadState>(props.loadState);

  useEffect(() => {
    setLoadState(props.loadState);
  }, [props.loadState]);

  useEffect(() => {
    if (props.isOpen) {
      fetchVacantDockLocations();
    }
  }, [props.isOpen]);

  const fetchVacantDockLocations = async () => {
    try {
      const response = await outboundManifestService.retrieveVacantDockLocations(props.warehouseId);
      if (response.errors?.length > 0) {
        setError(response.errors[0]?.detail || 'An internal error has occurred');
      }

      const loadIsAlreadyAssignedDock = props.loadState?.dockLocationId != null;
      if (loadIsAlreadyAssignedDock) {
        const assignedDock: VacantDockLocation = {
          location_id: props.loadState.dockLocationId,
          location_label: props.loadState.dockLocationLabel,
          location_barcode_text: props.loadState.dockLocationLabel // doesn't have to be correct
        };
        setDockLocs(response.data.vacant_locations.concat([assignedDock]));
      } else {
        setDockLocs(response.data.vacant_locations);
      }
    } catch (e) {
      setError(e.toString());
    }
  };

  const handleToggle = () => {
    clearState();
    props.toggleModal();
  };

  const editsWereMade = (): boolean => {
    const scacChanged = loadState?.scac !== props.loadState?.scac;
    const trailerNumberChanged = loadState?.trailerNumber !== props.loadState?.trailerNumber;
    const proNumberChanged = loadState?.proNumber !== props.loadState?.proNumber;
    const dockLocationChanged = loadState?.dockLocationLabel !== props.loadState?.dockLocationLabel;
    const sealNumberChanged = loadState?.sealNumber !== props.loadState?.sealNumber;

    return [scacChanged, trailerNumberChanged, proNumberChanged, dockLocationChanged, sealNumberChanged].some(
      (change) => change
    );
  };

  const onCancelEdits = () => {
    setLoadState(props.loadState);
  };

  // returns T/F based on success
  const onUpsertLoad = async () => {
    setError(null);
    if (!editsWereMade() && upsertMode === UpsertMode.EDIT) {
      return;
    }
    try {
      const dockLocation = dockLocs.find((loc) => loc.location_label === loadState?.dockLocationLabel);

      const data: UpsertLoadRequest = {
        loadId: props.loadState?.loadId,
        warehouseId: props.warehouseId,
        scac: loadState?.scac,
        trailerNumber: loadState?.trailerNumber,
        proNumber: loadState?.proNumber,
        sealNumber: loadState?.sealNumber,
        dockLocationId: dockLocation ? dockLocation.location_id : null
      };

      const response = await loadsService.upsertLoad(data);

      if (response.errors?.length <= 0) {
        if (upsertMode === UpsertMode.CREATE) {
          setCreationResultInfo({
            loadId: response.data.id
          });
          props.onCreateLoad();
        } else if (upsertMode === UpsertMode.EDIT) {
          const newLoadState = {
            ...loadState
          };
          setLoadState(newLoadState);
          props.onEditLoad(newLoadState); // this callback handles hiding the modal
        }
      } else {
        if (upsertMode === UpsertMode.CREATE) {
          setCreationResultInfo({
            error: response.errors[0].detail || 'An Internal Error Occurred',
            loadId: -1
          });
        } else if (upsertMode === UpsertMode.EDIT) {
          setError(response.errors[0].detail || 'An Internal Error Occurred');
          onCancelEdits();
        }
      }
    } catch (e) {
      setError(e.toString());
      onCancelEdits();
    }
  };

  const clearState = () => {
    setError(null);
    setCreationResultInfo(null);
    setLoadState(props.loadState);
  };

  return (
    <Modal isOpen={props.isOpen} toggleModal={handleToggle} title={upsertMode} size="full">
      {error != null && (
        <div className="alert alert-danger" role="alert" key={`error:${1}`}>
          {error}
        </div>
      )}
      <div id="upsert-load-modal-contents">
        {
          <PreviewShipments
            loadState={loadState}
            upsertMode={upsertMode}
            onUpsertLoad={onUpsertLoad}
            creationResultInfo={creationResultInfo}
            toggleModal={handleToggle}
            vacantDockLocations={dockLocs}
            onEdit={(newLoadState) => setLoadState(newLoadState)}
          />
        }
      </div>
    </Modal>
  );
}
