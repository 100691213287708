import * as React from 'react';
import {useMemo} from 'react';
import {
  getGridStringOperators,
  GridCellParams,
  GridColDef,
  GridComparatorFn,
  GridFilterOperator,
  GridRenderCellParams,
  gridStringOrNumberComparator
} from '@mui/x-data-grid';
import {ItemLinkCell} from '../../shared/mui/flexe-data-grid/ItemLinkCell';
import {DateTimeCell} from '../../shared/mui/flexe-data-grid/loads-grid/DateTimeCell';
import {Warehouse} from '../../shared/CommonInterfaces';
import {LoadStatusMap} from './LoadConstants';

/**
 * Used to get rows, columns, and loading state for an @mui/x-data-grid DataGrid
 *
 *  If this callback changes between renders, new callback values are ignored.
 * @param baseUrl URL to prefix paths with, e.g. /s for shipper pages or /wh for warehouse pages
 * @param warehouse warehouse that is fulfilling the loads
 */
export const LoadsGridColumnDefiniton = (baseUrl: string, warehouse: Warehouse) => {
  const wrapFilterOperator = (operator: GridFilterOperator) => {
    const getApplyFilterFn: GridFilterOperator['getApplyFilterFn'] = (filterItem, column) => {
      const innerFilterFn = operator.getApplyFilterFn(filterItem, column);
      // return if the operator doesn't have a default filter function
      // this shouldn't happen as long as we only wrap MUI's default operators
      if (!innerFilterFn) {
        return innerFilterFn;
      }

      return (params: GridCellParams) => {
        // pass the dock name to the filter function so that the filter doesn't try to match on the
        // complete dockLocation object
        const dockName = (params.value as Link)?.value;
        return innerFilterFn({
          ...params,
          value: dockName
        });
      };
    };

    return {
      ...operator,
      getApplyFilterFn
    };
  };

  const wrapFilterOperatorLoadDetails = (operator: GridFilterOperator) => {
    const getApplyFilterFn: GridFilterOperator['getApplyFilterFn'] = (filterItem, column) => {
      const innerFilterFn = operator.getApplyFilterFn(filterItem, column);
      // return if the operator doesn't have a default filter function
      // this shouldn't happen as long as we only wrap MUI's default operators
      if (!innerFilterFn) {
        return innerFilterFn;
      }

      return (params: GridCellParams<LoadsGridContent>) => {
        const loadGroup = params.row.loadGroup;
        const destinationId = params.row.destinationId;

        return (
          innerFilterFn({
            ...params,
            value: loadGroup
          }) ||
          innerFilterFn({
            ...params,
            // A delimiter between the joined destination tags to limit false positives when searching in searchbar
            // example: Tags: 12, 23, 31. Without delimiter 122331 and a search for 22 would result in hit, which is wrong
            value: destinationId?.join('[--]')
          })
        );
      };
    };

    return {
      ...operator,
      getApplyFilterFn
    };
  };

  const getApplyFilterFnDockLocation = (value: string) => {
    if (!value) return null;
    return (params: GridCellParams): boolean => {
      const dockName = (params.value as Link)?.value;
      if (!dockName) return false;
      return dockName.toLowerCase().includes(value.toLowerCase());
    };
  };

  const getApplyFilterFnLoadDetails = (value: string) => {
    if (!value) return null;
    return (params: GridCellParams<LoadsGridContent>): boolean => {
      const loadGroup = params.row.loadGroup;
      const destinationId = params.row.destinationId;
      return (
        loadGroup?.toLowerCase().includes(value.toLowerCase()) ||
        destinationId?.filter((dest) => {
          return dest?.toLowerCase().includes(value.toLowerCase());
        })?.length > 0
      );
    };
  };

  // modifies the sort comparator for the loading location column to be based on the location name instead of the Link object
  const loadingLocationSortComparator: GridComparatorFn = (v1, v2, param1, param2) => {
    return gridStringOrNumberComparator((v1 as Link)?.value, (v2 as Link)?.value, param1, param2);
  };

  const dockLocationOperators = getGridStringOperators().map((operator) => wrapFilterOperator(operator));
  const loadDetailsOperators = getGridStringOperators().map((operator) => wrapFilterOperatorLoadDetails(operator));

  const columnFlexWidths = new Map<string, number>();

  const buildDestinationIdString = (destinationIds: string[]) => {
    if (!destinationIds) {
      return null;
    }
    if (destinationIds.length > 1) {
      return 'Multiple';
    }
    if (destinationIds.length === 1) {
      return destinationIds[0];
    }
    return null;
  };

  // flex totals must add up to 1
  columnFlexWidths.set('id', 0.15);
  columnFlexWidths.set('status', 0.1);
  columnFlexWidths.set('mode', 0.1);
  columnFlexWidths.set('scac', 0.05);
  columnFlexWidths.set('trailerNumber', 0.1);
  columnFlexWidths.set('createdAt', 0.05);
  columnFlexWidths.set('loadingLocation', 0.15);
  columnFlexWidths.set('shipmentCount', 0.1);
  columnFlexWidths.set('loadDetails', 0.2);

  return useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'Load ID',
        renderCell: (params: GridRenderCellParams<LoadsGridContent>) => {
          return (
            <ItemLinkCell
              baseUrl={`${baseUrl}/loads`}
              item={{
                id: params.id,
                value: params.row.id.toString()
              }}
            />
          );
        },
        flex: columnFlexWidths.get('id')
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: (params) => {
          const loadState = params.value as string;
          const statusObject = LoadStatusMap.get(loadState);
          const className = `shipment-status-dot ${statusObject.className}`;
          return (
            <div>
              <span className={className} />
              {statusObject.text}
            </div>
          );
        },
        minWidth: 150,
        flex: columnFlexWidths.get('status')
      },
      {field: 'mode', headerName: 'Mode', type: 'string', flex: columnFlexWidths.get('mode'), minWidth: 100},
      {field: 'scac', headerName: 'SCAC', flex: columnFlexWidths.get('scac')},
      {field: 'trailerNumber', headerName: 'Trailer #', flex: columnFlexWidths.get('trailerNumber')},
      {
        field: 'createdAt',
        headerName: 'Created',
        type: 'date',
        renderCell: (params: GridRenderCellParams<LoadsGridContent>) => {
          return <DateTimeCell dateString={params.row.createdAt.toString() || ''} warehouse={warehouse} />;
        },
        flex: columnFlexWidths.get('createdAt')
      },
      {
        field: 'dockLocation',
        headerName: 'Loading Location',
        renderCell: ({value}) => {
          if (value == null) {
            return null;
          }
          return <ItemLinkCell baseUrl={`${baseUrl}/locations`} item={value} />;
        },
        filterOperators: dockLocationOperators,
        getApplyQuickFilterFn: getApplyFilterFnDockLocation,
        sortComparator: loadingLocationSortComparator,
        minWidth: 150,
        flex: columnFlexWidths.get('loadingLocation')
      },
      {
        field: 'shipmentCount',
        type: 'number',
        align: 'left', // numbers auto align to the right for some reason
        headerAlign: 'left',
        headerName: 'Shipment Count',
        flex: columnFlexWidths.get('shipmentCount')
      },
      {
        field: 'loadDetails',
        headerName: 'Load Details',
        renderCell: (params: GridRenderCellParams<LoadsGridContent>) => {
          const destinationId = buildDestinationIdString(params.row.destinationId);
          if (!params.row.loadGroup && !destinationId) {
            return '--';
          } else {
            return (
              <div>
                {params.row.loadGroup ? <div>Group: {params.row.loadGroup}</div> : null}
                {destinationId ? <div>Dest. ID: {destinationId}</div> : null}
              </div>
            );
          }
        },
        getApplyQuickFilterFn: getApplyFilterFnLoadDetails,
        filterOperators: loadDetailsOperators,
        flex: columnFlexWidths.get('loadDetails')
      }
    ],
    [baseUrl, dockLocationOperators, loadDetailsOperators, warehouse]
  );
};

export interface LoadsGridContent {
  id: number;
  status: string;
  mode: string;
  scac?: string;
  createdAt: Date;
  trailerNumber?: string;
  dockLocation: Link;
  shipmentCount: number;
  destinationId?: string[];
  loadGroup?: string;
}

// ItemLinkCell requires inputs to be formatted this way
interface Link {
  id: number;
  value: string;
}
