import * as React from 'react';
import {get} from 'lodash';
import {Response} from '../shared/CommonInterfaces';

interface Props {
  errorText?: string;
  errorResponse?: Response;
}

function ErrorDisplay(props: Props) {
  const errorList = processErrorResponse(props.errorResponse);
  if (props.errorText || errorList) {
    return (
      <div className="alert alert-danger">
        <div className="content">
          {props.errorText && <p>{props.errorText}</p>}
          {errorList}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function processErrorResponse(errorResponse: Response) {
  let errorMessage;
  const errors = get(errorResponse, 'response.data.errors');

  if (errors && errors.length) {
    errorMessage = (
      <ul>
        {errors.map((error, idx) => {
          return <li key={idx}>{error.detail}</li>;
        })}
      </ul>
    );
  }

  return errorMessage;
}

export default ErrorDisplay;
