import * as React from 'react';
import {useEffect, useState} from 'react';
import BatchWavingService from '../../shared/services/BatchWavingService';

interface ShipmentStatusSummaryProps {
  batchWavingService: BatchWavingService;
  warehouseId: number;
  shipOnDate: string;
  reloadTrigger: boolean;
}
const ShipmentStatusSummary: React.FC<ShipmentStatusSummaryProps> = (props) => {
  const [total, setTotal] = useState<number>(null);
  const [unWaved, setUnWaved] = useState<number>(null);
  const [notStarted, setNotStarted] = useState<number>(null);
  const [inProgress, setInProgress] = useState<number>(null);
  const [packed, setPacked] = useState<number>(null);
  const [complete, setComplete] = useState<number>(null);
  const [packedEnabled, setPackedEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    loadSummary();
  }, [props.warehouseId, props.shipOnDate, props.reloadTrigger]);

  const loadSummary = async () => {
    setIsLoading(true);
    setTotal(null);
    setUnWaved(null);
    setNotStarted(null);
    setInProgress(null);
    setPacked(null);
    setComplete(null);

    const response = await props.batchWavingService.getWavingSummary(props.warehouseId, props.shipOnDate);
    setIsLoading(false);
    if (response && (!response.errors || response.errors.length === 0) && response.data) {
      setTotal(response.data.total);
      setUnWaved(response.data.unWaved);
      setNotStarted(response.data.notStarted);
      setInProgress(response.data.inProgress);
      setPacked(response.data.packed);
      setComplete(response.data.complete);
      setPackedEnabled(response.data.packedEnabled);
    }
  };

  return (
    <div className={'shipment-status-summary'}>
      <ShipmentCount title={'Total Shipments'} count={total} isLoading={isLoading} />
      <ShipmentCount title={'Un-Waved'} count={unWaved} isLoading={isLoading} />
      <span className={'status-bar status-bar-not-started'} />
      <ShipmentCount title={'Not Started'} count={notStarted} isLoading={isLoading} />
      <span className={'status-bar status-bar-in-progress'} />
      <ShipmentCount title={'In Progress'} count={inProgress} isLoading={isLoading} />
      {packedEnabled && (
        <>
          <span className={'status-bar status-bar-packed'} />
          <ShipmentCount title={'Packed'} count={packed} isLoading={isLoading} />
        </>
      )}
      <span className={'status-bar status-bar-complete'} />
      <ShipmentCount title={'Complete'} count={complete} isLoading={isLoading} />
    </div>
  );
};

interface ShipmentCountProps {
  title: string;
  count: number;
  isLoading: boolean;
}

const ShipmentCount: React.FC<ShipmentCountProps> = (props) => {
  const countDisplayValue = props.count === null || props.isLoading ? '--' : props.count;
  return (
    <div className={'shipment-summary-count'}>
      <h3 className={'shipment-summary-count-title'}>{props.title}</h3>
      <p className={'shipment-summary-count-number'}>{countDisplayValue}</p>
    </div>
  );
};

export default ShipmentStatusSummary;
