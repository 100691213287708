/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import {FC, ReactNode, useEffect, useState} from 'react';
import {css, jsx} from '@emotion/react';
import {DropEvent} from 'react-dropzone';
import tokens from '@flexe/ui-tokens';
import {Button, DetailedInfoBox, Expander, FileSelector, InfoBox, Link, Modal, Text} from '@flexe/ui-components';
import Papa from 'papaparse';
import {
  buildInvalidHeaderError,
  buildMissingHeaderError,
  cloneWithoutLodash,
  formatHeadersForValidation,
  getExtraHeaders,
  getMissingHeaders
} from '../ItemMasterHelpers';
import ItemMasterService, {UpsertInventoryRequest} from '../ItemMasterService';
import {
  composableInstructionsTableHeaders,
  getInventoryUploadCsvColumnInfoGroups,
  getValidationHeaders
} from './InventoryUploadValidationData';
import InstructionsTable from './InstructionsTable';
import {EditableBy, ItemMasterMessage, ModalAction} from './ItemMasterInterfaces';

// this length was chosen arbitrarily and can be changed at any time to suit the Design team
const MAX_DISPLAYABLE_ERROR_COUNT = 5;

const embeddedListStyles = css({
  'paddingInlineStart': '0',
  'marginBottom': '0',
  '& li': {
    margin: '8px 0'
  },
  '& li:first-of-type': {
    marginTop: '0'
  },
  '& li:last-child': {
    marginBottom: '0'
  }
});

const footerStyles = css({
  textAlign: 'right',
  paddingTop: `${tokens.spacing.v200.value}px`
});

const fileSelectorWrapStyles = css({
  maxWidth: '80%',
  paddingBottom: `${tokens.spacing.v400.value}px`
});

const msgWrapStyles = css({
  maxWidth: '80%',
  paddingBottom: `${tokens.spacing.v200.value}px`
});

const overrideModalHeaderStyles = css({
  fontSize: `${tokens.font.b400.size.value}`,
  fontWeight: tokens.font.b400.weight.value,
  color: 'blue'
});

export interface UpsertInventoryModalProps {
  authenticityToken: string;
  isOpen: boolean;
  action: ModalAction;
  isShipper: boolean;
  reservationId?: string;
  showImsAlternateId: boolean;
  useReleaseDate: boolean;
  useSerialNumberProgram: boolean;
  toggleModal: () => void;
}

const UpsertInventoryModal: FC<UpsertInventoryModalProps> = (props) => {
  const itemMasterService = new ItemMasterService(props.authenticityToken);
  const validationHeaders = getValidationHeaders(
    props.isShipper ? EditableBy.Shipper : EditableBy.Warehouse,
    props.action,
    props.showImsAlternateId,
    props.useReleaseDate,
    props.useSerialNumberProgram
  );
  // TODO Fix static csv templates: https://flexe-inc.atlassian.net/browse/IMS-6896
  const csvTemplateDownloadLink = `/static/files/${
    props.isShipper
      ? `${
          props.action === ModalAction.Create
            ? `${
                props.showImsAlternateId
                  ? 'ShipperBulkCreateInventoryAltId'
                  : props.useReleaseDate
                  ? 'ShipperBulkCreateInventoryReleaseDate'
                  : props.useSerialNumberProgram
                  ? 'ShipperBulkCreateInventorySerialNumberProgram'
                  : 'ShipperBulkCreateInventory'
              }`
            : `${
                props.showImsAlternateId
                  ? 'ShipperBulkUpdateInventoryAltId'
                  : props.useReleaseDate
                  ? 'ShipperBulkUpdateInventoryReleaseDate'
                  : props.useSerialNumberProgram
                  ? 'ShipperBulkUpdateInventorySerialNumberProgram'
                  : 'ShipperBulkUpdateInventory'
              }`
        }`
      : 'WarehouseBulkUpdateInventory'
  }.csv`;

  const maxFileSizeMB = 2;
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
  const requiredHeaders: string[] = validationHeaders.required;
  const optionalHeaders: string[] = validationHeaders.optional;

  const [isExpanderOpen, setIsExpanderOpen] = useState<boolean>(false);
  const [chosenFile, setChosenFile] = useState<any>({});
  const [fileErrors, setFileErrors] = useState<ReactNode>(null);
  const [headerValidationErrors, setHeaderValidationErrors] = useState<ItemMasterMessage[]>(null);
  const [responseErrors, setResponseErrors] = useState<ItemMasterMessage[]>(null);
  const [successMsg, setSuccessMsg] = useState<{[key: string]: number}>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);
  const [uploadParams, setUploadParams] = useState<any>({file: {}});

  useEffect(() => {
    if (chosenFile.name) {
      const clonedUploadParams: any = cloneWithoutLodash(uploadParams);
      clonedUploadParams.file = chosenFile;
      validateForCurrentHeaders(chosenFile, clonedUploadParams);
    }
  }, [chosenFile]);

  /************************
   *
   * toggle functions
   *
   ***********************/

  const toggleExpander = () => {
    setIsExpanderOpen(!isExpanderOpen);
  };

  const handleModalToggle = () => {
    setChosenFile({});
    setIsExpanderOpen(false);
    setFileErrors(null);
    setHeaderValidationErrors(null);
    setResponseErrors(null);
    setSuccessMsg(null);
    setUploadComplete(false);
    props.toggleModal();
  };

  /************************
   *
   * validation functions
   *
   ***********************/

  const validateFileChoice = (choice: File[]) => {
    setFileErrors(null);
    setHeaderValidationErrors(null);
    setResponseErrors(null);
    setSuccessMsg(null);
    const file = choice[0];
    if (!file || !file.name || !file.name.match(/\.csv$/)) {
      setFileErrors(<InfoBox info="The selected file is not in .csv format." infoType="error" />);
    } else if (file.size > maxFileSizeBytes) {
      setFileErrors(<InfoBox info={`Please select a .csv file less than ${maxFileSizeMB} MB.`} infoType="error" />);
    } else {
      setChosenFile(file);
      setUploadParams({file: {}});
    }
  };

  const validateForCurrentHeaders = (file: File, clonedUploadParams: File) => {
    setChosenFile(file);
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event) => {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      const headers = Papa.parse(event.target.result.toString(), {
        header: false,
        preview: 1
      }).data[0];
      const validationErrs: ItemMasterMessage[] = [];
      if (headers.length === 1 && headers[0].trim() === '') {
        setFileErrors(<InfoBox info="The selected file contains no data." infoType="error" />);
        return;
      }
      const formattedHeaders = formatHeadersForValidation(headers);
      const extraHeaders = getExtraHeaders(formattedHeaders, [...optionalHeaders, ...requiredHeaders]);
      const missingHeaders = getMissingHeaders(formattedHeaders, requiredHeaders);
      if (extraHeaders.length === 0 && missingHeaders.length === 0) {
        setUploadParams(clonedUploadParams);
      }
      if (extraHeaders.length > 0) {
        validationErrs.push(buildInvalidHeaderError(extraHeaders));
      }
      if (missingHeaders.length > 0) {
        validationErrs.push(buildMissingHeaderError(missingHeaders));
      }
      if (validationErrs.length > 0) {
        setHeaderValidationErrors(validationErrs);
      }
    };
  };

  /************************
   *
   * render helper functions
   *
   ***********************/

  const renderHeaderErrors = (errs): ReactNode => {
    return (
      <React.Fragment>
        {errs.map((err, i) => {
          return (
            <InfoBox key={`${err.detail}-${i}`} info={err.msg} infoType="error">
              {err.detail}
            </InfoBox>
          );
        })}
      </React.Fragment>
    );
  };

  const renderMsgList = (details: ItemMasterMessage[]) =>
    details.length <= MAX_DISPLAYABLE_ERROR_COUNT ? renderShortList(details) : renderTruncatedlist(details);

  const renderResponseErrors = (errs: ItemMasterMessage[]): ReactNode => {
    return (
      <DetailedInfoBox
        info={`${errs.length} error${errs.length === 1 ? '' : 's'} occurred during upload:`}
        infoType="error"
      >
        {renderMsgList(errs)}
      </DetailedInfoBox>
    );
  };

  const renderSuccessMsg = (msgArray: ItemMasterMessage[]) => {
    return (
      <DetailedInfoBox
        info={`Successfully ${props.action === ModalAction.Update ? 'updated' : 'created'} ${msgArray.length} item${
          msgArray.length === 1 ? '' : 's'
        }:`}
        infoType="success"
      >
        {renderMsgList(msgArray)}
      </DetailedInfoBox>
    );
  };

  const renderShortList = (arr: ItemMasterMessage[]) => {
    return (
      <ul css={embeddedListStyles}>
        {arr.map((a, i) => {
          return (
            <li key={`${a.detail}-${i}`}>
              {a.msg}
              {a.detail && ` ${a.detail}`}
            </li>
          );
        })}
      </ul>
    );
  };

  const renderTruncatedlist = (arr: ItemMasterMessage[]) => {
    const subArr = arr.slice(0, MAX_DISPLAYABLE_ERROR_COUNT);
    return (
      <ul css={embeddedListStyles}>
        {subArr.map((a) => {
          return (
            <li>
              {a.msg}
              {a.detail && ` ${a.detail}`}
            </li>
          );
        })}
        <li>and {arr.length - MAX_DISPLAYABLE_ERROR_COUNT} more</li>
      </ul>
    );
  };

  /************************
   *
   * async calls
   *
   ***********************/

  const handleCsvUploadAsync = async () => {
    setUploading(true);
    const apiParams: UpsertInventoryRequest = props.isShipper
      ? {
          file: uploadParams.file
        }
      : {
          file: uploadParams.file,
          reservationId: props.reservationId
        };
    const response = await itemMasterService.upsertInventoryAsync(apiParams);
    if (response.errors && response.errors.length > 0) {
      const resErrors: ItemMasterMessage[] = response.errors.map((error) => {
        return {msg: error.title, detail: error.detail};
      });
      setResponseErrors(resErrors);
    } else {
      setSuccessMsg(response.data.succeeded);
    }
    setChosenFile({});
    setUploadComplete(true);
    setUploading(false);
    setUploadParams({file: {}});
  };

  /************************
   *
   * "event handlers"
   *
   ***********************/

  const handleFileDrop = (droppedFiles: File[]) => {
    setIsExpanderOpen(false);
    setUploadComplete(false);
    validateFileChoice(droppedFiles);
  };

  const fileInputHandleFileChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setIsExpanderOpen(false);
    if (file && file.size <= maxFileSizeBytes) {
      if (file.name && file.name.match(/\.csv$/)) {
        setChosenFile(file);
        setUploadParams({file: {}});
        setFileErrors(null);
        setHeaderValidationErrors(null);
        setResponseErrors(null);
        setSuccessMsg(null);
      } else {
        setFileErrors(<InfoBox info="The selected file is not in .csv format." infoType="error" />);
      }
    } else {
      setFileErrors(
        <InfoBox
          info="Something went wrong."
          infoType="error"
        >{`Your file must be a .csv and must be less than ${maxFileSizeMB} MB in size.`}</InfoBox>
      );
    }
    setUploadComplete(false);
  };

  const handleAcceptedFiles = (acceptedFiles: File[], event: DropEvent) => {
    const uploadParamsState: any = cloneWithoutLodash(uploadParams);
    uploadParamsState.file = chosenFile;
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        toggleModal={handleModalToggle}
        title={`${props.action === ModalAction.Update ? 'Update' : 'Create'} Items via CSV`}
        subtitle={
          <React.Fragment>
            <Text tag="p" level="Body1" bold={false}>
              Upload a CSV file to {`${props.action === ModalAction.Update ? 'update' : 'create new'}`} items. Please
              ensure you are using the current CSV <Link href={csvTemplateDownloadLink}>template</Link>.
            </Text>
          </React.Fragment>
        }
        size="full"
      >
        <div css={fileSelectorWrapStyles}>
          <FileSelector
            dropzoneProps={{
              onFileDrop: handleFileDrop,
              onAcceptedFiles: handleAcceptedFiles,
              onRejectedFiles: () => {
                return;
              },
              onDropzoneError: () => {
                return;
              },
              acceptedFileTypes: {'text/csv': []},
              multiple: false
            }}
            fileInputAccept=".csv"
            fileInputOnChange={fileInputHandleFileChange}
            selectedFile={chosenFile ? chosenFile.name : ''}
          />
        </div>
        <div css={msgWrapStyles}>
          {fileErrors}
          {headerValidationErrors && renderHeaderErrors(headerValidationErrors)}
          {responseErrors && renderResponseErrors(responseErrors)}
          {successMsg &&
            renderSuccessMsg(
              Object.keys(successMsg).map((key) => {
                return {msg: key};
              })
            )}
        </div>
        <Expander
          title="See a sample of the data required for your file"
          isOpen={isExpanderOpen}
          onToggle={toggleExpander}
          maxHeight={2500}
        >
          <InstructionsTable
            action={props.action || ModalAction.Create}
            editableBy={props.isShipper ? EditableBy.Shipper : EditableBy.Warehouse}
            headers={composableInstructionsTableHeaders}
            rows={getInventoryUploadCsvColumnInfoGroups(
              props.showImsAlternateId,
              props.useReleaseDate,
              props.useSerialNumberProgram
            )}
          />
        </Expander>
        <div css={footerStyles}>
          {uploadComplete && !responseErrors && !fileErrors && !headerValidationErrors ? (
            <Button visualType="primary" onPress={handleModalToggle}>
              Exit
            </Button>
          ) : (
            <Button
              visualType="primary"
              onPress={handleCsvUploadAsync}
              isDisabled={!chosenFile.name || !!responseErrors || !!fileErrors || !!headerValidationErrors || uploading}
            >
              Submit
            </Button>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UpsertInventoryModal;
