import * as React from 'react';
import {FilterOption} from './CommonInterfaces';

interface Props {
  label: string;
  options: FilterOption[];
  name: string;
  selected: string;
  onSelect: (value: string) => void;
}

const SelectFilter: React.FunctionComponent<Props> = (props) => {
  const handleValueChange = (event) => {
    props.onSelect(event.target.value);
  };

  const options = props.options.map((opt) => {
    return (
      <option value={opt.value} key={opt.value}>
        {opt ? opt.displayName : ''}
      </option>
    );
  });

  return (
    <div>
      <div className="add-filter">
        <h5>{props.label}</h5>
        <div className="form-group filter-input">
          <select
            name={props.name}
            data-testid={props.name}
            value={props.selected ? props.selected : ''}
            onChange={handleValueChange}
            className="form-control"
          >
            {options}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SelectFilter;
