import * as React from 'react';
import {cloneDeep, get, uniqWith} from 'lodash';
import {Company, Document, ExpectedInboundPackaging, Packaging, Reservation} from '../../shared/CommonInterfaces';
import {
  ContainerDelivery,
  Inventory,
  InventoryProperties,
  PackingListItem,
  Pallet,
  ReceivedType
} from '../shared/DropoffInterfaces';
import {DropDownOption} from '../../shared/DropDown';
import InventoryService from '../../shared/services/InventoryService';
import ContainersService from '../../shared/services/ContainersService';
import {InventoryPropertyMap} from '../shared/completion-workflow/EnterSkuProperties';
import {FulfillmentInboundCompletion} from './FulfillmentInboundCompletion';
import FulfillmentInboundSteps from './FulfillmentInboundSteps';

interface Props {
  availableStagingLocations: DropDownOption[];
  containerDelivery: ContainerDelivery;
  containersService: ContainersService;
  enableInboundPackaging: boolean;
  inventoryService: InventoryService;
  packingLists: {
    expected: PackingListItem[];
    shippable: PackingListItem[];
    damaged: PackingListItem[];
  };
  pallets: Pallet[];
  receivingListPath: string;
  reservation: Reservation;
  shipperCompany: Company;
  showUploadDocumentModal: boolean;
  documents: Document[];
  notableId: number;
  isBolUploaded: boolean;
  handleSavedDoc(savedDoc: Document);
  handleContainerDeliveryUpdate(containerDelivery: ContainerDelivery);
  handleErrors(errors: string[], response);
  reloadContainer();
  resetSuccessMessage();
  setSuccessMessage(successMessage: string);
  toggleUploadDocumentModal();
  handlePackingListChange(packingList: PackingListItem[], additional: boolean);
}

interface State {
  additionalPackingLists: PackingListItem[];
  expectedPackingLists: PackingListItem[];
  putAwayLocation: DropDownOption;
  selectedInventoryId: string;
  showCompleteModal: boolean;
  actualArrivalTime?: Date;
  cartonsPerPallet?: number;
  daysToArrive?: number;
  palletCount?: number;
  updatedProperties?: {
    [inventoryId: number]: InventoryProperties;
  };
  palletLabelQuantities: {
    [inventoryId: string]: {
      shippable: number;
      damaged: number;
    };
  };
}

class FulfillmentInboundConfirmed extends React.Component<Props, State> {
  public static getDerivedStateFromProps(nextProps) {
    return {
      additionalPackingLists: nextProps.packingLists.shippable,
      expectedPackingLists: nextProps.packingLists.expected
    };
  }

  constructor(props) {
    super(props);

    const allPackingLists = this.props.packingLists.shippable.concat(this.props.packingLists.expected);
    const allInventories = uniqWith(
      allPackingLists.map((pl) => pl.inventory),
      (a, b) => a.id === b.id
    );
    const palletLabelQuantities = {};
    allInventories.forEach((inv) => {
      const invCarton = allPackingLists.filter(
        (list) => list.inventory.id === inv.id && list.quantity.unit === 'carton'
      );
      const cartonsPerPallet = get(inv.properties, 'carton.unitsPerParent', null);
      if (invCarton[0] && cartonsPerPallet) {
        palletLabelQuantities[inv.id.toString()] = {
          shippable: Math.ceil(invCarton[0].quantity.amount / cartonsPerPallet)
        };
      }
    });

    this.state = {
      additionalPackingLists: this.props.packingLists.shippable,
      expectedPackingLists: this.props.packingLists.expected,
      putAwayLocation: this.props.availableStagingLocations[0],
      selectedInventoryId: '',
      showCompleteModal: false,
      palletLabelQuantities
    };
  }

  public render() {
    return (
      <div id="confirmed-dropoff-component">
        <FulfillmentInboundSteps
          containerDeliveryId={this.props.containerDelivery.id}
          hasBol={this.props.containerDelivery.hasBillOfLading}
          printLabels={this.props.reservation.usesMobile}
          additionalPackingLists={this.state.additionalPackingLists}
          expectedPackingLists={this.state.expectedPackingLists}
          putAwayLocation={this.state.putAwayLocation}
          putAwayLocations={this.props.availableStagingLocations}
          receivingListPath={this.props.receivingListPath}
          shipperCompany={this.props.shipperCompany}
          reservation={this.props.reservation}
          actualArrivalTime={this.state.actualArrivalTime}
          inventoryService={this.props.inventoryService}
          enableInboundPackaging={this.props.enableInboundPackaging}
          updatedProperties={this.state.updatedProperties}
          palletLabelQuantities={this.state.palletLabelQuantities}
          showUploadDocumentModal={this.props.showUploadDocumentModal}
          handleSavedDoc={this.props.handleSavedDoc}
          documents={this.props.documents}
          notableId={this.props.notableId}
          onAddAdditionalSku={this.handleAddAdditionalSku}
          onPutawayLocationSelect={this.handlePutawayLocationSelect}
          onReceivedCountChange={this.handleReceivedCountChange}
          onActualInboundPackagingChange={this.handleActualInboundPackagingChange}
          onRemoveAdditionalSku={this.handleRemoveAdditionalSku}
          onUpdateActualArrivalTime={this.handleUpdateActualArrivalTime}
          toggleUploadDocumentModal={this.props.toggleUploadDocumentModal}
          handlePalletLabelCountChange={this.handlePalletLabelCountChange}
          handleUpdatedProperties={this.handleUpdatedProperties}
        />
        <FulfillmentInboundCompletion
          containerDelivery={this.props.containerDelivery}
          containersService={this.props.containersService}
          additionalPackingLists={this.state.additionalPackingLists}
          expectedPackingLists={this.state.expectedPackingLists}
          enableInboundPackaging={this.props.enableInboundPackaging}
          reservation={this.props.reservation}
          shipperCompany={this.props.shipperCompany}
          handleErrors={this.props.handleErrors}
          reloadContainer={this.props.reloadContainer}
          resetSuccessMessage={this.props.resetSuccessMessage}
          setSuccessMessage={this.props.setSuccessMessage}
          putAwayLocation={this.state.putAwayLocation}
          putAwayLocations={this.props.availableStagingLocations}
          palletLabelQuantities={this.state.palletLabelQuantities}
          actualArrivalTime={this.state.actualArrivalTime}
          updatedProperties={this.state.updatedProperties}
          isBolUploaded={this.props.isBolUploaded}
        />
      </div>
    );
  }

  private handleActualInboundPackagingChange = (actualInboundPackagingId) => {
    const InboundPackagingMap = new Map<number, string>([
      [1, ExpectedInboundPackaging.FloorLoaded],
      [2, ExpectedInboundPackaging.Palletized],
      [3, ExpectedInboundPackaging.ParcelReceive]
    ]);
    const containerDelivery = cloneDeep(this.props.containerDelivery);
    containerDelivery.actualInboundPackagingId = actualInboundPackagingId;
    containerDelivery.actualInboundPackaging = InboundPackagingMap.get(actualInboundPackagingId);

    this.props.handleContainerDeliveryUpdate(containerDelivery);
  };

  private handleAddAdditionalSku = (selectedInventory: Inventory, selectedPackaging: Packaging) => {
    const additionalPackingLists = cloneDeep(this.state.additionalPackingLists);
    const allPackingLists = cloneDeep(this.state.expectedPackingLists).concat(
      cloneDeep(this.state.additionalPackingLists)
    );
    const existingPackingLists = allPackingLists.filter((pl) => {
      return pl.quantity.unit === selectedPackaging && pl.inventory.id === selectedInventory.id;
    });
    // ensure same sku and packaging are not being added more than once to this list
    if (existingPackingLists.length < 1) {
      additionalPackingLists.push({
        quantity: {
          unit: selectedPackaging,
          amount: 0
        },
        inventory: selectedInventory
      });
    }
    this.props.handlePackingListChange(additionalPackingLists, true);
  };

  private handlePutawayLocationSelect = (putAwayLocation: DropDownOption) => {
    this.setState({putAwayLocation});
    this.props.handleErrors([], null);
  };

  private handleReceivedCountChange = (
    id: number,
    qty: number,
    unit: Packaging,
    type: ReceivedType,
    additional: boolean
  ) => {
    const packingLists = additional ? this.state.additionalPackingLists : this.state.expectedPackingLists;
    const updatedPackingLists = packingLists.map((item) => {
      const itemCopy = cloneDeep(item);
      if (item.inventory.id === id && item.quantity.unit === unit) {
        itemCopy[type] = qty;
      }
      return itemCopy;
    });

    this.props.handlePackingListChange(updatedPackingLists, additional);
  };

  private handleRemoveAdditionalSku = (inventoryId: number, packaging: Packaging) => {
    if (inventoryId && packaging) {
      const additionalPackingLists = this.state.additionalPackingLists.filter((item) => {
        return !(item.inventory.id === inventoryId && item.quantity.unit === packaging);
      });
      this.props.handlePackingListChange(additionalPackingLists, true);
    }
  };

  private handleUpdateActualArrivalTime = (actualArrivalTime) => {
    this.setState({actualArrivalTime});
  };

  private handlePalletLabelCountChange = (e) => {
    const inventoryId = parseInt(e.target.getAttribute('data-id'), 10);
    const type = e.target.getAttribute('data-type');
    const qty = parseInt(e.target.value, 10);
    const palletLabelQuantities = cloneDeep(this.state.palletLabelQuantities);
    if (!palletLabelQuantities[inventoryId]) {
      palletLabelQuantities[inventoryId] = {shippable: 0, damaged: 0};
    }
    palletLabelQuantities[inventoryId][type] = qty;
    this.setState({palletLabelQuantities});
  };

  private handleUpdatedProperties = (properties: InventoryPropertyMap) => {
    this.props.handleErrors([], null);
    const updatedInventoryIds = Object.keys(properties).map((key) => Number(key));
    const palletLabelQuantities = cloneDeep(this.state.palletLabelQuantities);
    const allPackingLists = this.state.additionalPackingLists.concat(this.state.expectedPackingLists);
    const allInventories = uniqWith(
      allPackingLists.map((pl) => pl.inventory),
      (a, b) => a.id === b.id
    );
    allInventories.forEach((inv) => {
      if (updatedInventoryIds.includes(inv.id)) {
        const updatedProperties: InventoryProperties = properties[inv.id];
        const packingLists = allPackingLists.filter((list) => list.inventory.id === inv.id);
        packingLists.forEach((list) => {
          const {unit, amount} = list.quantity;
          const unitsPerPallet = this.unitsPerPallet(unit, updatedProperties);
          const shippable = unitsPerPallet > 0 ? Math.ceil(amount / unitsPerPallet) : 0;
          palletLabelQuantities[inv.id] = {
            shippable,
            damaged: 0
          };
        });
      }
    });
    this.setState({
      palletLabelQuantities,
      updatedProperties: properties
    });
  };

  private unitsPerPallet(unit: Packaging, inventory: InventoryProperties) {
    const cartonsPerPallet = get(inventory.properties, 'carton.unitsPerParent', 0);
    const eachesPerCarton = get(inventory.properties, 'each.unitsPerParent', 0);

    let unitsPerPallet;
    if (unit === 'carton') {
      unitsPerPallet = cartonsPerPallet;
    } else if (unit === 'each') {
      unitsPerPallet = cartonsPerPallet * eachesPerCarton;
    } else {
      unitsPerPallet = 1;
    }

    return unitsPerPallet;
  }
}

export default FulfillmentInboundConfirmed;
