import * as React from 'react';
import {useState} from 'react';
import DropDown, {DropDownColor, DropDownOption} from '../../shared/DropDown';

interface CreatedAfterProps {
  onCreatedAfterChange: (date: Date) => void;
}

const CreatedAfter: React.FC<CreatedAfterProps> = (props) => {
  enum optionsEnum {
    today,
    lastSevenDays,
    lastThirtyDays,
    lastNinetyDays,
    anytime
  }

  const options = [
    {name: 'Today', value: optionsEnum.today},
    {name: 'Last 7 Days', value: optionsEnum.lastSevenDays},
    {name: 'Last 30 Days', value: optionsEnum.lastThirtyDays},
    {name: 'Last 90 Days', value: optionsEnum.lastNinetyDays},
    {name: 'Anytime', value: optionsEnum.anytime}
  ];
  // NOTE: make sure to keep the default in sync with the value returned by getDefaultCreatedAfter
  const [selectedOption, setSelectedOption] = useState<DropDownOption>(options[1]);

  const onSelect = (option: DropDownOption) => {
    setSelectedOption(option);

    switch (option.value) {
      case optionsEnum.today:
        props.onCreatedAfterChange(tOffsetDays(-1));
        break;
      case optionsEnum.lastSevenDays:
        props.onCreatedAfterChange(tOffsetDays(-7));
        break;
      case optionsEnum.lastThirtyDays:
        props.onCreatedAfterChange(tOffsetDays(-30));
        break;
      case optionsEnum.lastNinetyDays:
        props.onCreatedAfterChange(tOffsetDays(-90));
        break;
      case optionsEnum.anytime:
        props.onCreatedAfterChange(null);
        break;
      default:
        // This shouldn't happen but just in case it does, default to today
        setSelectedOption(options[0]);
        props.onCreatedAfterChange(tOffsetDays(-1));
    }
  };

  return (
    <div className="loads-created-after">
      <DropDown
        options={options}
        selected={selectedOption}
        onSelect={onSelect}
        color={DropDownColor.white}
        prefixText="Created: "
      />
    </div>
  );
};

const tOffsetDays = (offset: number) => {
  const currentDate = new Date();
  const offsetDate = new Date();
  offsetDate.setDate(currentDate.getDate() + offset);
  return offsetDate;
};

// NOTE: make sure to keep this value in sync with the default value of selectedOption
export const getDefaultCreatedAfter = () => {
  return tOffsetDays(-7);
};

export default CreatedAfter;
