import * as React from 'react';
import {Icon, Modal, Text, UITable, UITableCell, UITableContainer, UITableRow} from '@flexe/ui-components';
import {ModalProps} from '@flexe/ui-components/dist/types/components/Modal/Modal';
import FlexeButton from '../../../../shared/FlexeButton';
import {LineItem, Location} from '../../ShipmentInterfaces';
import {PluralPackagingStringAbbreviations} from '../../../../shared/CommonMappings';
import {renderItemLink, renderLocationLink} from '../../../../../libs/helpers';
import {UnpackWorkflowState} from './UnpackWorkflow';

export interface UnpackShipmentModalProps extends Pick<ModalProps, 'toggleModal'> {
  onUnpack: () => void;
  shipmentId: number;
  shipmentLineItems: LineItem[];
  unpackLocation: Location;
  workflowState: UnpackWorkflowState;
}
export const UnpackShipmentModal: React.FC<UnpackShipmentModalProps> = (props) => {
  const {toggleModal, onUnpack, shipmentId, shipmentLineItems, unpackLocation} = props;
  const headers = [];
  return (
    <div data-testid="unpack-shipment-modal">
      <Modal isOpen={true} toggleModal={toggleModal} title={'Unpack Shipment #' + shipmentId}>
        <div className="modal-contents">
          <div className="unpack-text-container">
            <Text>
              Unpacking this Shipment will disassociate the units Picked for it and leave them in the Location where the
              package is. The Shipment will return to a “Not Started” status and can be Picked again.
            </Text>
          </div>
          <div>
            <Text>
              <b>Unpacking:</b>
            </Text>
            <div className="unpack-details-container">
              <div className="table-input-area">
                <Text>{shipmentLineItems.length} SKUs: </Text>
                <UITable headerBorder={false} zebraStripe={false}>
                  <UITableContainer tag="thead">
                    <UITableRow>
                      {headers.map((header: any) => (
                        <UITableCell tag="th">{header}</UITableCell>
                      ))}
                    </UITableRow>
                  </UITableContainer>
                  <UITableContainer tag="tbody">
                    {shipmentLineItems.map((item: LineItem, idx) => (
                      <UITableRow key={idx}>
                        <UITableCell tag="td">{renderItemLink(item.inventory_id, item.sku, false)}</UITableCell>
                        <UITableCell tag="td">
                          {item.quantity}{' '}
                          {PluralPackagingStringAbbreviations.get(item.packaging) || 'unknown packaging'}
                        </UITableCell>
                      </UITableRow>
                    ))}
                  </UITableContainer>
                </UITable>
              </div>
            </div>
            <Text>
              <b>To:</b> Location: {renderLocationLink(unpackLocation?.id, unpackLocation?.name)}
            </Text>
          </div>
        </div>
        <div className="action-buttons modal-footer">
          <FlexeButton text="Go Back" handleClick={toggleModal} level="collapsed" />
          <FlexeButton
            text={
              props.workflowState === UnpackWorkflowState.waitingOnServer ? (
                // The wrapper div is a hack to make the button appear to not change width. If the button text
                // changes, the padding amount will likely also have to change.
                <div className={'unpack-waiting-padding'} aria-live={'polite'}>
                  <Icon icon="circle-notch" animation="spin-fast" />
                </div>
              ) : (
                'Unpack Shipment'
              )
            }
            isDisabled={props.workflowState === UnpackWorkflowState.waitingOnServer}
            handleClick={onUnpack}
            level="danger"
          />
        </div>
      </Modal>
    </div>
  );
};

export default UnpackShipmentModal;
