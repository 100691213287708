import * as React from 'react';
import {uniq} from 'lodash';
import {determineLink, renderItemLink, renderLocationLink, renderShipment} from '../../../libs/helpers';
import {ParsedWavingAlert} from '../ecommerce-batches/BatchInterfaces';

interface Props {
  alert: ParsedWavingAlert;
}

export const ItemExceedsDimsDetail: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {'Could not wave '}{' '}
      <b>
        {`Shipment ${uniq(props.alert.shipmentIds).join(', ')},`}
        {' item '}
        {renderItemLink(props.alert.itemId, props.alert.sku, false)}
      </b>
      {' exceeds sortable dimensions. Please update item and try again.'}
    </React.Fragment>
  );
};

export const ItemMissingDimsDetail: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {'Could not wave '}
      <b>
        {`Shipment ${uniq(props.alert.shipmentIds).join(', ')},`}
        {' item '}
        {renderItemLink(props.alert.itemId, props.alert.sku, false)}
      </b>
      {' is missing dimensions. Please update item and try again.'}
    </React.Fragment>
  );
};

export const ItemNeedsRestockDetail: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {`Could not wave ${uniq(props.alert.shipmentIds).length} shipments, item `}
      <b>{renderItemLink(props.alert.itemId, props.alert.sku, false)}</b>
      {' needs '} <b>{`${props.alert.totalQuantity}`}</b> {' more eaches to be replenished.'}
      {' Please replenish the item into a primary pick location and try again.'}
    </React.Fragment>
  );
};

export const ShipmentTooBigDetail: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <b>{'Split shipments!'}</b>
      {` ${uniq(props.alert.shipmentIds).length} shipments were split into new shipments because they were too large to
      sort. Those new shipments will be ready to wave shortly.`}
    </React.Fragment>
  );
};

export const InternalDetail: React.FC<Props> = (props) => {
  if (
    Array.isArray(props.alert.shipmentIds) &&
    props.alert.shipmentIds.length > 0 &&
    props.alert.shipmentIds[0] !== undefined &&
    props.alert.shipmentIds[0] !== null
  ) {
    return (
      <React.Fragment>
        {'Could not wave '} <b>{`Shipment ${uniq(props.alert.shipmentIds).join(', ')},`}</b>
        {' an error occurred while processing. Please try again.'}
      </React.Fragment>
    );
  } else {
    return <React.Fragment>{'Wave blocked.  An error occurred while processing. Please try again.'}</React.Fragment>;
  }
};

export const NoSuitablePickCartFoundDetail: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {'Could not wave '} <b>{`Shipment ${uniq(props.alert.shipmentIds).join(', ')}.`}</b>
      {' No pick cart found for '} <b>{`${props.alert.shipmentPackagingDescription}`} </b>
      {'. Please contact FLEXE support.'}
    </React.Fragment>
  );
};

export const LocationLocked: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {'Wave blocked. Location '}
      <b>{renderLocationLink(props.alert.locationId, String(props.alert.locationLabel))}</b>
      {' is locked. Reason: '} <b>{`${props.alert.lockReason}`}</b>
    </React.Fragment>
  );
};

export const InsufficientQuantityError: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {'Wave blocked.  At least one item '}
      <b>{renderItemLink(props.alert.itemId, props.alert.sku, false)}</b>
      {' is short quantity.'}
    </React.Fragment>
  );
};

export const InsufficientInventoryError: React.FC<Props> = ({alert}) => {
  return (
    <React.Fragment>
      {`Incomplete `}
      <b>{determineLink(alert.waveId, 'WavingBatching::Models::PickWave', null, false)}</b>
      {`, `}
      <b>{renderShipment(alert.shipmentIds?.[0], false, false, true, false)}</b>
      {` contains at least one `}
      <b>{renderItemLink(alert.itemId, alert.sku, false)}</b>
      {` short inventory${alert.lotCode != null ? ' for the ordered lot ' + alert.lotCode : ''}.`}
    </React.Fragment>
  );
};

export const NoRebinConfiguredDetail: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {'Could not size batch to a rebin, no rebins are configured in the system. Please contact FLEXE support.'}
    </React.Fragment>
  );
};

export const NoPickCartConfiguredDetail: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {`Could not size batch to a pick cart,
      no default pick cart details are configured in the system. Please contact FLEXE support.`}
    </React.Fragment>
  );
};

export const UnableToSplitShipmentsDetail: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {'Unable to automatically split large shipments '}
      <b>{uniq(props.alert.shipmentIds).join(', ')}</b>
      {'. Please manually split and wave again.'}
    </React.Fragment>
  );
};

export const ShipmentRecartonized: React.FC<Props> = (props) => {
  if (props.alert.shipmentPackagingDescription) {
    return (
      <React.Fragment>
        {'Packaging '}
        <b>{`${props.alert.shipmentPackagingDescription} `}</b>
        {'is not available. '}
        <b>{`Shipment(s) ${uniq(props.alert.shipmentIds).join(', ')} `}</b>
        {'will be re-processed to fit in different packagings(s). Those new shipments will be ready to wave shortly.'}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <b>{`Shipment(s) ${uniq(props.alert.shipmentIds).join(', ')} `}</b>
        {'will be re-processed to fit in different carton(s). Those new shipments will be ready to wave shortly.'}
      </React.Fragment>
    );
  }
};

export const NoAvailableCartonForShipment: React.FC<Props> = (props) => {
  if (props.alert.shipmentPackagingDescription) {
    return (
      <React.Fragment>
        {'Packaging '}
        <b>{`${props.alert.shipmentPackagingDescription} `}</b>
        {'is not available. Could not batch '}
        <b>{`shipment(s) ${uniq(props.alert.shipmentIds).join(', ')}.`}</b>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {'Could not batch'}
        <b>{` shipment(s) ${uniq(props.alert.shipmentIds).join(', ')}`}</b>
        {'. One or more SKUs cannot be overboxed in any existing packaging.'}
      </React.Fragment>
    );
  }
};

export const NoAvailableInventoryForShipment: React.FC<Props> = ({alert}) => {
  const shipments = uniq(alert.shipmentIds);

  const maxNumShipmentDisplayed = 5;
  const shipmentsToDisplay = Math.min(shipments.length, maxNumShipmentDisplayed);
  const displayedShipments = shipments.slice(0, shipmentsToDisplay);
  const numShipmentsTruncated = shipments.length - displayedShipments.length;
  const truncatedString = numShipmentsTruncated > 0 ? ` and ${numShipmentsTruncated} others` : '';

  return (
    <React.Fragment>
      {'Shipment(s) '}
      {displayedShipments.map((shipmentId, i) => (
        <b>
          {renderShipment(shipmentId, false, false, true, true)}
          {i < displayedShipments.length - 1 ? ', ' : ''}
        </b>
      ))}
      <b>{`${truncatedString}`}</b>
      {" can't be waved because there are no eligible inventory remaining for it."}
    </React.Fragment>
  );
};
