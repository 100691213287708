import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';

interface Props {
  showCcSwitchModal: boolean;
  toggleCCTypeModal: (event: any) => Promise<void>;
  handleCycleCountTypeSelection: () => void;
}

const CycleCountTypeSwitchModal: FunctionComponent<Props> = (props) => {
  return (
    <LegacyModal
      id="cc-type-switch-modal"
      title="Swap Cycle Count Type"
      show={props.showCcSwitchModal}
      size="small"
      toggleModal={props.toggleCCTypeModal}
      footer={
        <div>
          <a onClick={props.toggleCCTypeModal}>Cancel</a>
          <button
            id="cc-type-switch-agree"
            type="button"
            className="btn cta"
            onClick={props.handleCycleCountTypeSelection}
          >
            Confirm
          </button>
        </div>
      }
      disableClose={false}
    >
      <div>
        <h4>Are you sure that you want to swap Cycle Count type?</h4>
        <br />
        You will lose <b>all</b> of your previous selections.
      </div>
    </LegacyModal>
  );
};

export default CycleCountTypeSwitchModal;
