/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import {FC} from 'react';
import {Button, Icon, Text} from '@flexe/ui-components';
import tokens from '@flexe/ui-tokens';
import {css, jsx} from '@emotion/react';
import {CompanyType, Document} from '../../shared/CommonInterfaces';

interface Props {
  key: number;
  document: Document;
  isEditMode: boolean;
  documentIdentifier?: string;
  onDeleteDocsFromUi?: (documentId) => void;
}

export const InboundDocumentsRow: FC<Props> = (props) => {
  const storage = convertToBlobStorage(props.document, props.documentIdentifier);
  const {onDeleteDocsFromUi, document, isEditMode} = props;
  const currentCompanyType = window.location.pathname[1] === 's' ? CompanyType.shipper : CompanyType.warehouse;
  const handlePrint = () => {
    window.open(storage.secureDownloadUrl, '_blank').focus();
  };

  const handleClickDeleteButton = () => {
    onDeleteDocsFromUi(document.id);
  };

  const documentNotAvailable = !storage.availableForDownload;

  const documentDetailsTextCss = css({
    margin: 'auto auto 0px auto',
    paddingTop: '15px'
  });

  return (
    <tr
      css={css`
        display: table;
      `}
    >
      <td data-testid={props.documentIdentifier}>
        <div css={documentDetailsTextCss}>
          <Text level="Body2" bold={true}>
            {document.note.name}
          </Text>
          <Text level="Body2" color={tokens?.color.brand.asphalt.v60.value}>
            Type: {storage.typeTitle}
          </Text>
          <Text color={tokens?.color.brand.asphalt.v50.value}>{document.description}</Text>
        </div>
      </td>
      <td>
        <div
          className="document-buttons-container"
          css={css`
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            text-align: center;
          `}
        >
          <div
            className={'document-uploader-text'}
            css={css`
              padding-right: 20px;

              p {
                margin: 0;
              }
            `}
          >
            <Text level="Body2" color={tokens?.color.brand.asphalt.v50.value}>
              {`Uploaded by: ${document?.company.name || ''} @ ${new Date(document?.createdAt).toLocaleString() || ''}`}
            </Text>
          </div>
          {isEditMode && document.uploader === currentCompanyType && (
            <div data-testid={`delete-button-${document.id}`}>
              <Button visualType="icon" onPress={handleClickDeleteButton}>
                <Icon icon="trash"></Icon>
              </Button>
            </div>
          )}
          {!isEditMode && (
            <button
              type="button"
              disabled={documentNotAvailable}
              className="shipment-document-print"
              onClick={handlePrint}
              title={documentNotAvailable ? 'The document is not available' : ''}
            >
              {documentNotAvailable ? (
                'The document is not available'
              ) : (
                <span>
                  <i className="fa fa-print" />
                  &nbsp; Print &nbsp;
                </span>
              )}
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

/**
 * A unification of the properties on various blob storage db tables used here.
 */
export interface BlobStorage {
  typeTitle: string;
  typeSubtitle: string;
  availableForDownload: boolean;
  secureDownloadUrl: string;
  createdAt: string;
}

/**
 * Convert a model based on a db table to a generic blob storage interface
 * @param source A rich object containing the source model object plus the type description of the object
 * @param documentIdentifier Additional, optional identifier that may describe a document (fetched from a separate API)
 * @returns A BlobStorage representation of the model
 */
export const convertToBlobStorage = (document: Document, documentIdentifier?: string): BlobStorage => {
  return {
    typeTitle: document.documentType?.toString(),
    typeSubtitle: documentIdentifier || '',
    secureDownloadUrl: document.note?.url,
    createdAt: document.createdAt,
    availableForDownload: !!document.note?.url
  };
};
