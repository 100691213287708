import * as React from 'react';
import {COUNTRY_ABBR_CANADA, COUNTRY_ABBR_UNITED_STATES, countryAbbrToName} from '../../../libs/helpers';

export interface Props {
  onCountryAbbrChange: (newSelectedCountryAbbr: string) => void;
  selectedCountryAbbr: string;
}

const CountrySelect: React.FC<Props> = (props) => {
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    // Ensure we suppress any non-changes
    if (props.selectedCountryAbbr !== value) {
      props.onCountryAbbrChange(value);
    }
  };

  // Note the initial implementation uses asterisk to denote required.
  // As this gets expanded, that should probably be a configuration param
  return (
    <select onChange={onChange} value={props.selectedCountryAbbr}>
      <option value="" key="placeholder_value" hidden>
        *Country
      </option>
      {[COUNTRY_ABBR_CANADA, COUNTRY_ABBR_UNITED_STATES].map((abbr) => (
        <option value={abbr} key={abbr}>{`${countryAbbrToName(abbr)} (${abbr})`}</option>
      ))}
    </select>
  );
};
export default CountrySelect;
