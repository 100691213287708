export const cloneWithoutLodash = (obj) => {
  const clone = {};
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      clone[key] = obj[key];
    }
  }
  return clone;
};

export const formatHeadersForValidation = (headerArr: string[]) => {
  return headerArr.map((header) => {
    const idxOptional = header.indexOf('(optional)');
    if (idxOptional >= 0) {
      header = header.slice(0, idxOptional);
    }
    const idxRequired = header.indexOf('(required)');
    if (idxRequired >= 0) {
      header = header.slice(0, idxRequired);
    }
    // this 'any' cast is required because
    // .replaceAll doesn't work with our current lib compiler options
    return (header as any)
      .trim()
      .toLowerCase()
      .replaceAll(' ', '_');
  });
};

export const getMissingHeaders = (uploadedHeaders: string[], requiredHeaders: string[]) => {
  return requiredHeaders.filter((header) => !uploadedHeaders.includes(header));
};

export const getExtraHeaders = (uploadedHeaders: string[], possibleHeaders: string[]) => {
  return (
    uploadedHeaders
      .filter((header) => !possibleHeaders.includes(header))
      // the UX does not validate csa columns
      .filter((header) => !header.toLowerCase().startsWith('csa_'))
  );
};

export const buildMissingHeaderError = (headers): {msg: string; detail: string} => {
  const msg = headers.length === 1 ? 'Required header is missing: ' : 'Required headers are missing: ';
  let detail = '';
  if (headers.length < 6) {
    detail = headers.join(', ');
  } else {
    detail += `${headers[0]}, ${headers[1]}, ${headers[2]} ... + ${headers.length - 3} more`;
  }
  return {
    msg,
    detail
  };
};

export const buildInvalidHeaderError = (headers): {msg: string; detail: string} => {
  const msg = headers.length === 1 ? 'Invalid header found: ' : 'Invalid headers found: ';
  let detail = '';
  if (headers.length < 6) {
    headers.map((h: string, i: number) => {
      return (detail += `${i > 0 ? ', ' : ''}${h}`);
    });
  } else {
    detail += `${headers[0]}, ${headers[1]}, ${headers[2]} ... + ${headers.length - 3} more`;
  }
  return {
    msg,
    detail
  };
};
