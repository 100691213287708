import {get} from 'lodash';
import {ApiResponse} from '../../shared/CommonInterfaces';
import InternalAPIServiceV2, {InternalAPIServiceV2Options} from '../../shared/services/InternalAPIServiceV2';
import {
  V2EdiFile,
  V2EdiFileAssociationsResponse,
  V2EdiFileErrorsResponse,
  V2EdiFileResponse,
  V2EdiFilesResponse
} from './EdiFilesInterfaces';

class EdiFilesServiceV2 extends InternalAPIServiceV2 {
  public static readonly outgoingFileTypes = [
    'outbound',
    'outbound_flexe_success_v1_outboundOrder_csv',
    'outbound_flexe_error_v1_outboundOrder_csv',
    // 'outbound_flexe_success_v2_outboundOrder_csv', Not currently available
    // 'outbound_flexe_error_v2_outboundOrder_csv', Not currently available
    'outgoing_as2_edi_file',
    'outgoing_sftp_edi_file'
  ];
  public static readonly incomingFileTypes = [
    'inbound', // Deprecated removal pending Walmart migration
    'x12_incoming_file_parse', // Deprecated removal pending Boomi removal
    'incoming_x12_edi_file',
    'incoming_flexe_inbound_container_dropoff_upsert',
    'incoming_flexe_inventory_item_upsert',
    'incoming_flexe_outbound_order_upsert',
    'incoming_flexe_outbound_order_patch_update',
    // 'inbound_flexe_create_v2_outboundOrder_csv', Not currently available
    'inbound_flexe_create_v1_outboundOrder_csv',
    'incoming_csv_bulk_order_cancel'
  ];
  public static readonly localeFileTypes = {
    outbound: 'Legacy File sent by Flexe',
    outbound_flexe_success_v1_outboundOrder_csv: 'Outbound Order Legacy CSV Success',
    outbound_flexe_error_v1_outboundOrder_csv: 'Outbound Order Legacy CSV Errors',
    outbound_flexe_success_v2_outboundOrder_csv: 'Outbound Order CSV Success',
    outbound_flexe_error_v2_outboundOrder_csv: 'Outbound Order CSV Errors',
    outgoing_as2_edi_file: 'Outgoing AS2 File sent by Flexe',
    outgoing_sftp_edi_file: 'Outgoing SFTP File sent by Flexe',
    inbound: 'Legacy File received by Flexe',
    inbound_flexe_create_v1_outboundOrder_csv: 'Create Outbound Order Legacy CSV',
    incoming_flexe_inbound_container_dropoff_upsert: 'Inbound Container Dropoff Upsert',
    incoming_flexe_outbound_order_upsert: 'Outbound Order Upsert',
    incoming_flexe_outbound_order_patch_update: 'Outbound Order Patch Upsert',
    incoming_flexe_inventory_item_upsert: 'Item Upsert',
    inbound_flexe_create_v2_outboundOrder_csv: 'Create Outbound Order CSV',
    x12_incoming_file_parse: 'Incoming X12 Legacy File received by Flexe',
    incoming_x12_edi_file: 'Incoming X12 File received by Flexe',
    frito_reports: 'File exchange reports for Frito',
    incoming_csv_bulk_order_cancel: 'Outbound Order Bulk Cancel'
  };

  public static fileOutgoingFromFlexe(ediFile: V2EdiFile): boolean {
    return EdiFilesServiceV2.outgoingFileTypes.indexOf(ediFile.type) > -1;
  }

  public static getNameFromStorageObject(object: string): string {
    if (object && object.length > 0) {
      const objectParts = object.split('/');
      return objectParts[objectParts.length - 1];
    } else {
      return '';
    }
  }

  constructor() {
    super('/v2/files/');
  }

  public async getFiles(
    filters = {},
    pageSize: number = 50,
    continuationToken: string = null
  ): Promise<ApiResponse<V2EdiFilesResponse>> {
    const v2Filters = {
      pageSize,
      continuationToken,
      hasErrors: get(filters, 'hasErrors', null),
      externalId: get(filters, 'externalId', null),
      externalIdPartial: get(filters, 'externalIdPartial', null),
      fileName: get(filters, 'fileName', null),
      createdAtFrom: get(filters, 'createdAtFrom', null),
      createdAtTo: get(filters, 'createdAtTo', null),
      entityId: get(filters, 'entityId', null),
      entityType: get(filters, 'entityType', null),
      type: get(filters, 'type', null)
    };
    const entityId = 'entityId';
    const entityType = 'entityType';
    const fileType = 'type';

    if ('fileType' in filters) {
      v2Filters[fileType] = get(filters, 'fileType');
    } else if ('toFlexe' in filters) {
      const filterIncomingFiles = get(filters, 'toFlexe');
      v2Filters[fileType] = filterIncomingFiles
        ? EdiFilesServiceV2.incomingFileTypes.join(',')
        : EdiFilesServiceV2.outgoingFileTypes.join(',');
    }
    if ('sku' in filters) {
      v2Filters[entityId] = get(filters, 'sku');
      v2Filters[entityType] = 'Inventory';
    } else if ('inboundOrder' in filters) {
      v2Filters[entityId] = get(filters, 'inboundOrder');
      v2Filters[entityType] = 'ContainerDelivery';
    } else if ('outboundOrder' in filters) {
      v2Filters[entityId] = get(filters, 'outboundOrder');
      v2Filters[entityType] = 'OutboundOrder';
    }
    return await this.makeGetRequest(`${this.baseUrl}`, v2Filters);
  }

  public async getFile(fileId: number): Promise<V2EdiFileResponse> {
    return await this.makeGetRequest(`${this.baseUrl}${fileId}`);
  }

  public async getFileAssociations(
    fileId: number,
    entityType: string,
    pageSize: number = 50,
    continuationToken: string = null
  ): Promise<V2EdiFileAssociationsResponse> {
    const params = {
      entityType,
      pageSize,
      continuationToken
    };
    return await this.makeGetRequest(`${this.baseUrl}${fileId}/associations`, params);
  }

  public async getErrors(
    fileId: number,
    pageSize: number = 50,
    continuationToken: string = null
  ): Promise<V2EdiFileErrorsResponse> {
    const params = {
      pageSize,
      continuationToken
    };
    return await this.makeGetRequest(`${this.baseUrl}${fileId}/errors`, params);
  }

  public async redriveEdiFile(fileId: number): Promise<ApiResponse<V2EdiFileResponse>> {
    return await this.makePostRequest(`${this.baseUrl}${fileId}/redrive`, {});
  }

  public async completeEdiFile(fileId: number): Promise<ApiResponse<V2EdiFileResponse>> {
    return await this.makePostRequest(`${this.baseUrl}${fileId}/complete`, {});
  }

  public async createEdiFile(
    fileType: string,
    fileKey: string,
    metadata: object,
    options?: InternalAPIServiceV2Options
  ): Promise<ApiResponse<V2EdiFileResponse>> {
    const newEdiFile = {
      type: fileType,
      fileKey,
      translationFileKey: fileKey,
      metadata
    };

    return await this.makePostRequest(`${this.baseUrl}`, newEdiFile, null, options);
  }

  public isAdmin(): boolean {
    return window.localStorage.getItem('adminSignedIn') === 'true';
  }

  public getSupportedCSVUploadFileType(fileType: string): string {
    if (fileType === 'outbound_order_csv') {
      return 'inbound_flexe_create_v1_outboundOrder_csv';
    } else if (fileType === 'outbound_order_csv_v2') {
      return 'inbound_flexe_create_v2_outboundOrder_csv';
    }
    return fileType;
  }
}

export default EdiFilesServiceV2;
