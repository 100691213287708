import * as React from 'react';
import * as Papa from 'papaparse';
import StepOne from '../shared/csv-upload/StepOne';
import StepTwo from '../shared/csv-upload/StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';

interface BulkPalletizedDropoffState {
  step: number;
  parsedData?: any;
  parseErrors?: Papa.ParseError[];
  reservationId?: number;
  requests?: any;
  successfulParse?: boolean;
}

interface BulkPalletizedDropoffProps {
  reservations: any;
  sampleFileUrl: string;
  authenticityToken: string;
  successRedirect: string;
}

class BulkPalletizedDropoff extends React.Component<BulkPalletizedDropoffProps, BulkPalletizedDropoffState> {
  constructor(props) {
    super(props);
    this.state = {
      step: 1
    };
  }

  public render() {
    return (
      <div id="batch-retail-component">
        <ul className="dropoff palletized" id="workflow-steps">
          <StepOne onChange={this.handleReservationSelection} reservations={this.props.reservations} />

          {this.state.step >= 2 && (
            <StepTwo
              handleFileUpload={this.handleFileUpload}
              parseErrors={this.state.parseErrors}
              sampleFileUrl={this.props.sampleFileUrl}
              successfulParse={this.state.successfulParse}
              helpContent={
                <div>
                  <h5>Date Format</h5>
                  <p className="note">
                    Dates should be <code>MM/DD/YY</code> for best results.
                  </p>
                  <h5>Multiple SKUs</h5>
                  <p className="note">
                    When including multiple SKUs per shipment, use seperate lines with all the columns matching except
                    SKU and Quantity.
                  </p>
                </div>
              }
            />
          )}

          {this.state.step >= 3 && (
            <StepThree rawRequests={this.state.parsedData} handleConfirmedRequests={this.handleConfirmedRequests} />
          )}

          {this.state.step >= 4 && (
            <StepFour
              requests={this.state.requests}
              reservationId={this.state.reservationId}
              authenticityToken={this.props.authenticityToken}
              successRedirect={this.props.successRedirect}
            />
          )}
        </ul>
      </div>
    );
  }

  protected handleReservationSelection = (event) => {
    const reservationId = event.target.value;
    if (reservationId) {
      this.setState({
        reservationId,
        step: 2
      });
    }
  };

  protected handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          if (results.errors.length === 0) {
            this.setState({
              parsedData: results.data,
              step: 3,
              successfulParse: true
            });
          } else {
            this.setState({
              parsedData: null,
              step: 2,
              parseErrors: results.errors,
              successfulParse: false
            });
          }
        },
        header: true,
        skipEmptyLines: 'greedy'
      });
    }
  };

  protected handleConfirmedRequests = (requests) => {
    this.setState({
      requests,
      step: 4
    });
  };
}

export default BulkPalletizedDropoff;
