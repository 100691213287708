import * as React from 'react';
import {FC, useState} from 'react';
import {Icon} from '@flexe/ui-components';
import {CreateLoadResultInfo, UpsertLoadState} from '../LoadInterfaces';
import {VacantDockLocation} from '../ManifestInterfaces';
import LoadInfo from './LoadInfo';
import {UpsertMode} from './UpsertLoadModal';

interface Props {
  upsertMode: UpsertMode;
  loadState?: UpsertLoadState;
  vacantDockLocations: VacantDockLocation[];
  onUpsertLoad: () => Promise<void>;
  toggleModal: () => void;
  creationResultInfo?: CreateLoadResultInfo;
  onEdit: (editState: UpsertLoadState) => void;
}

export const PreviewShipments: FC<Props> = (props) => {
  const [loadState, setLoadState] = useState<UpsertLoadState>(props.loadState);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleUpsertConfirm = async () => {
    setSubmitting(true);
    await props.onUpsertLoad();
    setSubmitting(false);
  };

  const handleLoadEdit = (newLoadState: UpsertLoadState) => {
    setLoadState(newLoadState);
    props.onEdit(newLoadState);
  };

  const loadUpsertForm = (
    <>
      <div className="load-info-box">
        <LoadInfo
          loadState={loadState}
          upsertMode={props.upsertMode}
          dockLocs={props.vacantDockLocations}
          onEdit={handleLoadEdit}
        ></LoadInfo>
      </div>
      <div className="btn-wrapper">
        <button
          title="confirm-create-load"
          type="button"
          disabled={submitting}
          className="btn-primary"
          onClick={handleUpsertConfirm}
        >
          {submitting && (
            <div>
              <Icon icon="circle-notch" animation="spin" size="lg" />
            </div>
          )}
          {!submitting && <div>{props.upsertMode}</div>}
        </button>
      </div>
    </>
  );

  const loadCreationWaitNotification = (
    <div className="status processing">
      <Icon icon="circle-notch" color="#4dc2f6" animation="spin" size="7x" />
      <h2>Load creation in progress...</h2>
      <p>You may close this modal if you wish.</p>
    </div>
  );

  const loadCreationResultDisplay = (
    <div className={`status status-${props.creationResultInfo?.error ? 'error' : 'success'}`}>
      <Icon
        icon={`${props.creationResultInfo?.error ? 'warning' : 'check-circle'}`}
        // the following color hex values are copied from colors.scss ($invalid and $valid)
        color={`${props.creationResultInfo?.error ? '#DA5347' : '#0DC075'}`}
        size="7x"
      />
      <h1>
        <br />
        {props.creationResultInfo?.error ? 'There was a problem creating the Load' : 'Load created successfully'}
      </h1>
      <h4>
        {props.creationResultInfo?.error
          ? props.creationResultInfo?.error
          : 'Load ' +
            props.creationResultInfo?.loadId +
            " is ready to view.  Try refreshing the page if you don't see it right away."}
      </h4>
      {!props.creationResultInfo?.error && <a href={`/wh/loads/${props.creationResultInfo?.loadId}`}>Go to New Load</a>}
      <div>
        <br />
        <br />
        <a className="btn btn-primary" onClick={props.toggleModal}>
          OK
        </a>
        <br />
        <br />
        <br />
      </div>
    </div>
  );

  return (
    <div>
      {props.upsertMode === UpsertMode.EDIT || (!submitting && !props.creationResultInfo) ? (
        loadUpsertForm
      ) : (
        <div className="view-wrapper">{submitting ? loadCreationWaitNotification : loadCreationResultDisplay}</div>
      )}
    </div>
  );
};

export default PreviewShipments;
