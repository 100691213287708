import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import FlexeButton from '../shared/FlexeButton';
import StatusTab from '../shared/StatusTab';
import {StartWorkOrderModalState} from './WorkOrdersIndex';

interface Props {
  showStartWorkOrderModal: StartWorkOrderModalState;
  disabled: boolean;
  workOrderId: number;
  toggleStartWorkOrderModalWithEvent: (event: any) => void;
  handleStart: (event: any) => void;
}

const StartWorkOrderModal: FunctionComponent<Props> = (props) => {
  const idsMatch = props.workOrderId.toString() === props.showStartWorkOrderModal.id;

  return (
    <LegacyModal
      id="start-work-order-modal"
      title="Start Work Order"
      show={idsMatch && props.showStartWorkOrderModal.visible}
      size="small"
      toggleModal={props.toggleStartWorkOrderModalWithEvent}
      footer={
        <React.Fragment>
          <p className="status-change-warning">
            <sup>*</sup>Do not start a kit work order until you are going to start building kits.
          </p>
          <FlexeButton
            text="No, not yet."
            testid={`cancel-btn-${props.workOrderId}`}
            handleClick={props.toggleStartWorkOrderModalWithEvent}
          />
          <FlexeButton
            text="Yes, confirm and start work"
            id={`${props.workOrderId}`}
            testid={`start-btn-${props.workOrderId}`}
            level={'confirm'}
            handleClick={props.handleStart}
          />
        </React.Fragment>
      }
      disableClose={false}
    >
      <div>
        <h4>Are you going to start building these kits right now?</h4>
        <div className="status-wrap">
          <StatusTab title="Not Started" status={'neutral'} />
          <i className="fa fa-arrow-right"></i>
          <StatusTab title="In Progress" status={'valid'} />
        </div>
        <p>
          Starting this work order will send a message to the customer letting them know this is now{' '}
          <span className="target-status">In Progress</span>.
        </p>
      </div>
    </LegacyModal>
  );
};

export default StartWorkOrderModal;
