import * as React from 'react';
import classNames from 'classnames';
import * as Papa from 'papaparse';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {LegacyModal} from '@flexe/ui-components';
import {ParseResult} from 'papaparse';
import {UploadParams} from '../../edi-files/EdiFilesInterfaces';
import CsvUploadDropzone from '../../../shared/CsvUploadDropzone';
import CsvUploadBulkCreateInstructionsTable from '../../../shared/CsvUploadBulkCreateInstructionsTable';
import FlexeButton from '../../../shared/FlexeButton';
import ShowHide from '../../../shared/ShowHide';
import {DisplayGenericErrorsWithWrapper, GenericErrorDisplay} from '../../../shared/GenericErrorDisplay';
import {CSVTemplate} from '../helpers/OutboundOrderCsv';

/**
 * Given the papa.Parse result (with config headers = false),
 * gets the received headers
 * @param parseResult The papa.Parse result
 * @param normalize Whether to normalize the headers, which...
 * - strips "(optional)" and "(required)"
 * - trims
 * - converts to lower case
 * - replaces underscore with space
 * No normalization means "just return what was input)
 * @return Array of header strings
 */
function getHeadersFromParseResult(parseResult: ParseResult, normalize: boolean): string[] {
  if (!parseResult || !parseResult.data || !parseResult.data[0]) {
    return [];
  }

  if (!normalize) {
    return parseResult.data[0].map((header) => header);
  }

  return parseResult.data[0].map((header) => {
    // Remove optional and required from the column headers
    const idxOptional = header.indexOf('(optional)');
    if (idxOptional >= 0) {
      header = header.slice(0, idxOptional);
    }
    const idxRequired = header.indexOf('(required)');
    if (idxRequired >= 0) {
      header = header.slice(0, idxRequired);
    }

    return header
      .trim()
      .toLowerCase()
      .replaceAll('_', ' ');
  });
}

/**
 * Checks if all headersToTest are in headersToTestAgainst
 * @param headersToTest Array of header names
 * @param headersToTestAgainst Array of header names
 * @return Array of headers in headersToTest, but not headersToTestAgainst
 */
function getMissingHeaders(headersToTest: string[], headersToTestAgainst: string[]) {
  const result: string[] = [];

  headersToTest.forEach((header: string) => {
    if (!headersToTestAgainst.includes(header)) {
      result.push(header);
    }
  });

  return result;
}

/**
 * Given a list of all allowed headers, get
 * the required header names and all header names
 * @param inputHeaders Array of all header names + whether it's required (Yes for required).
 *  Format: [ ['header1', 'Yes'], ['header2', 'No'], ...]
 * @return Tuple part 1 = list of required header names, part 2 = list of all header names
 */
function buildRequiredAndAllCsvHeaderArrays(inputHeaders?: string[][]): [string[], string[]] {
  const requiredHeaders: string[] = [];
  const allHeaders: string[] = [];

  if (inputHeaders && inputHeaders.length > 0) {
    inputHeaders.forEach((header) => {
      const name = header[0];
      allHeaders.push(name);
      if (header[1] === 'Yes') {
        requiredHeaders.push(name);
      }
    });
  }

  return [requiredHeaders, allHeaders];
}

const buildTemplateLink = (template: CSVTemplate) => <a href={template.filepath}>{template.displayName}</a>;
const buildTemplateLinkList = (templates: CSVTemplate[]) => (
  <ul>
    {templates.map((template, idx) => (
      <li key={`templates-${idx}`}>{buildTemplateLink(template)}</li>
    ))}
  </ul>
);

const buildTemplateHelpText = (templates: CSVTemplate[]) => {
  if (templates.length < 1) {
    return '';
  }

  if (templates.length === 1) {
    return <>Please ensure you are using the {buildTemplateLink(templates[0])} provided.</>;
  }

  return (
    <>
      Please use one of the following templates:
      {buildTemplateLinkList(templates)}
    </>
  );
};

const buildOutOfDateTemplateHelpText = (templates: CSVTemplate[]) => {
  if (templates.length <= 1) {
    return (
      <>
        <p>Your CSV upload template is out-of-date. This template will stop being supported in the near future.</p>
        <p className="bold">
          Please download the new {buildTemplateLink(templates[0])} and update your process as soon as possible.
        </p>
      </>
    );
  }
  return (
    <>
      <p>Your CSV upload template is out-of-date. This template will stop being supported in the near future.</p>
      <p className="bold">
        Please download the update your process as soon as possible to use one of the following new templates:
        {buildTemplateLinkList(templates)}
      </p>
    </>
  );
};

// this type means that headers,
// oldHeaders, and templateUpdateDateString
// must ALL be provided when backwardsCompatible={true}
type CompatibilityProps =
  | {
      backwardsCompatible?: false;
      headers: string[][];
      fileUploadType: string;
      oldHeaders?: never;
      oldFileUploadType?: never;
      templateUpdateDateString?: never;
    }
  | {
      backwardsCompatible: true;
      headers: string[][];
      fileUploadType: string;
      oldHeaders: string[][];
      oldFileUploadType: string;
      templateUpdateDateString: string;
    };

export type UploadHandlerCallback = (errors: GenericErrorDisplay[]) => void;
export type BeforeOpenHandlerCallback = (errors: GenericErrorDisplay[]) => void;

export type UploadHandler = (params: UploadParams, type: string, callback: UploadHandlerCallback) => void;
export type BeforeOpenHandler = (callback: BeforeOpenHandlerCallback) => void;
export interface Section {
  title: string;
  body: JSX.Element;
}

interface CommonProps {
  id: string;
  show: boolean;
  title: string;
  actionDescription: string;
  csvTemplates: CSVTemplate[];
  instructionSupplementalSections?: Section[];
  instructionsTableHeaders: React.ReactNode[];
  instructionsTableRows: React.ReactNode[][];
  uploading: boolean;
  wrapperClass?: string;
  handleUpload: UploadHandler;
  handleBeforeOpen?: BeforeOpenHandler;
  children?: React.ReactNode;
  disableSubmit?: boolean;

  toggleModal(e: any): void;
}

export type Props = CommonProps & CompatibilityProps;

/*****
 * This component implements functionality the OMS team needs until
 * the front-end team can create a standard shared component
 *****/
const CsvBulkUploadModalOMS: React.FC<Props> = (props: Props) => {
  /* Misc constants */
  const transitionTimeout = 1;
  const maxFileSizeMB = 2; // max .csv file size in MB
  const maxFileSize = maxFileSizeMB * 1024 * 1024;

  /* State */
  const [showVersionAlert, setShowVersionAlert] = React.useState<boolean>(false);
  const [showUploadInstructions, setShowUploadInstructions] = React.useState<boolean>(false);
  const [chosenFileErrors, setChosenFileErrors] = React.useState<GenericErrorDisplay[]>([]);
  const [chosenFileType, setChosenFileType] = React.useState<string>('');
  const [chosenFile, setChosenFile] = React.useState<File>(null);
  const [uploadErrors, setUploadErrors] = React.useState<GenericErrorDisplay[]>([]);
  const [beforeOpenErrors, setBeforeOpenErrors] = React.useState<GenericErrorDisplay[]>([]);

  /* Effect-based hooks */
  React.useEffect(() => {
    if (chosenFile && chosenFile.name) {
      validateHeaders(chosenFile);
    }
  }, [chosenFile]);

  React.useEffect(() => {
    if (!props.show) {
      clearChosenFile();
    }
  }, [props.show]);

  React.useEffect(() => {
    if (props.handleBeforeOpen) {
      props.handleBeforeOpen(handleBeforeOpenCallback);
    }
  }, []);

  /* Simple toggles */
  const toggleVersionAlert = () => {
    setShowVersionAlert(!showVersionAlert);
  };

  const toggleInstructions = () => {
    setShowUploadInstructions(!showUploadInstructions);
  };

  /* Basic file input handling */
  const clearChosenFile = () => {
    setChosenFileType('');
    setChosenFile(null);
    setChosenFileErrors([]);
    setUploadErrors([]);
  };

  const dropzoneClearSelection = () => {
    clearChosenFile();
  };

  const handleDropzoneError = () => {
    setChosenFileErrors([{header: 'Please select a single CSV for upload.'}]);
  };

  /* File change */
  const handleFileChange = (file) => {
    clearChosenFile();

    if (file) {
      const errors: GenericErrorDisplay[] = [];

      if (file.size > maxFileSize) {
        errors.push({header: `Please select a .csv file less than ${maxFileSizeMB} MB.`});
      } else if (file.name && file.name.match(/\.csv$/)) {
        setChosenFile(file);
      } else {
        errors.push({header: 'The selected file is not in .csv format.'});
      }
      setChosenFileErrors(errors);
    }
  };

  const fileInputHandleFileChange = (e) => {
    handleFileChange(e.target.files[0]);
  };

  const dropzoneHandleFileChange = (selectedFile) => {
    handleFileChange(selectedFile);
  };

  const handleDrop = (acceptedFiles) => {
    dropzoneClearSelection();
    handleFileChange(acceptedFiles[0]);
  };

  const validateHeaders = (file: File) => {
    Papa.parse(file, {
      header: false,
      preview: 1,
      complete: (parseResult: ParseResult) => {
        const receivedHeaders = getHeadersFromParseResult(parseResult, true);
        // Start with new headers
        const [newReqHeaders, newAllHeaders] = buildRequiredAndAllCsvHeaderArrays(props.headers);

        const missingReqHeaders: string[] = getMissingHeaders(newReqHeaders, receivedHeaders);
        const extraHeaders: string[] = getMissingHeaders(receivedHeaders, newAllHeaders);

        if (missingReqHeaders.length < 1 && extraHeaders.length < 1) {
          handleHeadersMatchTemplate(false);
        } else {
          const newHeadersErrors: GenericErrorDisplay[] = [];
          if (missingReqHeaders.length > 0) {
            newHeadersErrors.push({
              header: 'The following required columns are missing from the file:',
              details: missingReqHeaders
            });
          }
          if (extraHeaders.length > 0) {
            newHeadersErrors.push({
              header: 'The following columns are in the file, but are unsupported:',
              details: extraHeaders
            });
          }

          if (props.backwardsCompatible && validateOldHeaders(parseResult)) {
            handleHeadersMatchTemplate(true);
          } else {
            setChosenFileErrors(newHeadersErrors);
          }
        }
      }
    });
  };

  const validateOldHeaders = (parseResult: ParseResult): boolean => {
    const receivedHeaders = getHeadersFromParseResult(parseResult, false);
    const [oldReqHeaders, oldAllHeaders] = buildRequiredAndAllCsvHeaderArrays(props.oldHeaders);

    const missingReqHeaders: string[] = getMissingHeaders(oldReqHeaders, receivedHeaders);
    if (missingReqHeaders.length > 0) {
      return false;
    }

    const extraHeaders: string[] = getMissingHeaders(receivedHeaders, oldAllHeaders);
    return extraHeaders.length < 1;
  };

  const handleHeadersMatchTemplate = (isOldVersion: boolean) => {
    if (isOldVersion) {
      setShowVersionAlert(true);
      setChosenFileType(props.oldFileUploadType);
    } else {
      setChosenFileType(props.fileUploadType);
    }
  };

  /* Upload handling */
  const handleUploadLocal = () => {
    // Clear errors to make it clearer on resubmit if
    //  the same error happens again
    setUploadErrors([]);
    props.handleUpload({file: chosenFile}, chosenFileType, handleUploadCallback);
  };

  const handleUploadCallback = (errors: GenericErrorDisplay[]) => {
    if (!errors || errors.length < 1) {
      // Success, so clear this out
      clearChosenFile();
    } else {
      setUploadErrors(errors);
    }
  };

  const handleBeforeOpenCallback = (errors: GenericErrorDisplay[]) => {
    setBeforeOpenErrors(errors);
  };

  /* Actual display! */
  const renderModalTitle = () => {
    return (
      <div className="modal-title">
        <h4>{props.title}</h4>
        <p className="modal-subtitle">
          Upload a CSV to {props.actionDescription}. {buildTemplateHelpText(props.csvTemplates)}
        </p>
        {props.backwardsCompatible && (
          <div className="inform-msg">
            <span>
              <i className="fa fa-lg fa-exclamation-circle" />
              Update! The CSV template changed on {props.templateUpdateDateString}. Please ensure you are using the
              latest version.
            </span>
          </div>
        )}
      </div>
    );
  };

  const modalClasses = ['react-modal', 'csv-bulk-create-modal', props.wrapperClass && props.wrapperClass];
  const dialogClasses = ['modal-dialog', 'modal-fullscreen'];
  const chosenFileClasses = ['filename', chosenFile && chosenFile.name && 'valid-file'];

  // Note it would be nice to not always disable the uploadErrors button
  // So they can correct the error without re-selecting the file
  // But submit doesn't work well if you do that.
  const haveErrors =
    (chosenFileErrors && chosenFileErrors.length > 0) ||
    (uploadErrors && uploadErrors.length > 0) ||
    (beforeOpenErrors && beforeOpenErrors.length > 0);
  const disableUpload =
    props.uploading || !chosenFile || !chosenFile.name || !chosenFileType || haveErrors || props.disableSubmit;

  const rawSections = props.instructionSupplementalSections || [];
  const supplementalSections = rawSections.map((section, i) => (
    <div key={`supplemental-section-${i}`}>
      <h5>{section.title}</h5>
      {section.body}
    </div>
  ));

  return (
    <React.Fragment>
      <TransitionGroup>
        {props.show && (
          <CSSTransition classNames="react-modal" timeout={transitionTimeout}>
            <div className={classNames(modalClasses)}>
              <div className="modal-backdrop"></div>
              <div className="modal" id={props.id} role="dialog" aria-labelledby={`${props.id}_label`}>
                <div className={classNames(dialogClasses)} role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <FlexeButton
                        text={<span aria-hidden="true">&times;</span>}
                        handleClick={props.toggleModal}
                        level="collapsed"
                      />
                      {renderModalTitle()}
                    </div>
                    <div className="modal-body">
                      {haveErrors && (
                        <h5>
                          Once the following errors have been addressed, please reselect the file to enable submission.
                        </h5>
                      )}
                      {DisplayGenericErrorsWithWrapper(chosenFileErrors, 'inner-content')}
                      {DisplayGenericErrorsWithWrapper(uploadErrors, 'inner-content')}
                      {DisplayGenericErrorsWithWrapper(beforeOpenErrors, 'inner-content')}
                      <div className="inner-content">
                        <CsvUploadDropzone
                          actionDescription={props.actionDescription}
                          clearDropzoneFiles={dropzoneClearSelection}
                          handleFileDrop={handleDrop}
                          handleFileChange={dropzoneHandleFileChange}
                          handleDropzoneError={handleDropzoneError}
                        />
                        <span className="upload-choice-spacer">or</span>
                        <span>
                          <label className="file-upload-mock-button" htmlFor="file-input">
                            Choose File
                          </label>
                          <input
                            className="file-upload-invisible-input"
                            id="file-input"
                            type="file"
                            accept=".csv"
                            onChange={fileInputHandleFileChange}
                            data-testid="file-input"
                          />
                        </span>
                        <div className="chosen-file">
                          File:
                          <span className={classNames(chosenFileClasses)}>
                            {chosenFile && chosenFile.name ? ` ${chosenFile.name}` : ' No file chosen'}
                          </span>
                        </div>
                        {props.children && (
                          <>
                            <hr />
                            <div className="container additional-modal-inputs">{props.children}</div>
                            <hr />
                          </>
                        )}
                        <div>
                          <button
                            id="upload-agree"
                            type="button"
                            className="btn"
                            onClick={handleUploadLocal}
                            disabled={disableUpload}
                          >
                            {props.uploading && <i className="fa fa-spinner fa-spin no-padding"></i>}
                            <span>Submit</span>
                          </button>
                        </div>
                        <div className="row">
                          <ShowHide
                            index={1}
                            headerText="See more about what inputs your file needs"
                            open={showUploadInstructions}
                            onClick={toggleInstructions}
                          >
                            <React.Fragment>
                              <span id="top-of-show-hide"></span>
                              {supplementalSections}
                              {supplementalSections.length > 0 && <h5>File Format</h5>}
                              <CsvUploadBulkCreateInstructionsTable
                                headers={props.instructionsTableHeaders}
                                data={props.instructionsTableRows}
                              />
                              <a href="#top-of-show-hide">
                                Back to Top <i className="fa fa-arrow-up" />
                              </a>
                            </React.Fragment>
                          </ShowHide>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      <LegacyModal
        id="version-alert-modal"
        title="Out of Date Template"
        size="small"
        show={showVersionAlert}
        toggleModal={toggleVersionAlert}
        transitionSpeed="fast"
      >
        <div>{buildOutOfDateTemplateHelpText(props.csvTemplates)}</div>
      </LegacyModal>
    </React.Fragment>
  );
};

export default CsvBulkUploadModalOMS;
