/** @jsxRuntime classic */
/** @jsx jsx */

import * as React from 'react';
import {jsx} from '@emotion/react';
import {useTable} from '@flexe/ui-components';

const SampleDataTable = () => {
  const {tableStyles} = useTable({headerBorder: true});
  return (
    <React.Fragment>
      <div className="bulk-cycle-count-csv-modal__table-legend">
        <p className="bulk-cycle-count-csv-modal__table-info-margin">
          <b>How to group Locations and SKUs to create counts</b>
        </p>
        <p>The counts created are determined by the way inputs are entered into the Location and SKU columns.</p>
        <ul className="bulk-cycle-count-csv-modal__list-info">
          <li>If a row contains only a Location, a count for that location will be created</li>
          <li>If a row contains only a SKU, a count will be created for every location containing that SKU</li>
          <li>
            If a row contains both a Location and a SKU, counts will be created for the specified location and all
            locations containing that SKU
          </li>
        </ul>
      </div>
      <table {...tableStyles}>
        <thead>
          <tr>
            <th>Column&nbsp;Header</th>
            <th>Sample&nbsp;Input</th>
            <th>Description</th>
            <th>Guidelines</th>
          </tr>
        </thead>
        <tbody className="bulk-cycle-count-csv-modal__sample-data-table-body">
          <tr>
            <td>locationLabel</td>
            <td>FS-A001-C22</td>
            <td>How the location is identified and labeled</td>
            <td>Alphanumeric string, must be unique</td>
          </tr>
          <tr>
            <td>sku</td>
            <td>LGWGTBLK</td>
            <td>The SKU (item-code)</td>
            <td>Alphanumeric string, must be unique</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default SampleDataTable;
