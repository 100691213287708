import {ResponseErrorV2} from '../CommonInterfaces';
import InternalAPIServiceV2 from './InternalAPIServiceV2';

export interface Item {
  companyId: number;
  id: number;
  sku: string;
  description: string;
  inventoryGroup?: string;
  cartonsPerLayer?: number;
  layersPerPallet?: number;
  cartonsPerPallet?: number;
  stackable?: boolean;
  lotCodeTrackingEnabled?: boolean;
  expiryDateTrackingEnabled?: boolean;
  bagAndTapeRequired?: boolean;
  isHazmat: boolean;
  isOrmd: boolean;
  unNumber?: string;
  pkgCode?: string;
  releaseDate?: string;
  velocity?: string;
  properties: ItemResponseProperties[];
}

export interface ItemResponseProperties {
  parentPackaging: string;
  packaging: string;
  unitsPerParent?: number;
  length?: number;
  width?: number;
  height?: number;
  dimensionUnit?: string;
  weight?: number;
  weightUnit?: string;
  shipAlone?: boolean;
  shipAsIs?: boolean;
  barcode?: string;
  barcodeType?: string;
  serialNumberCaptureMode?: string;
  serialNumberPatterns?: string;
}

export interface ItemsResponse {
  data: {items: Item[]; continuationToken: string};
  errors: ResponseErrorV2[];
}

class ItemMasterService extends InternalAPIServiceV2 {
  constructor() {
    super('/v2/item-master');
  }

  public getItems(itemIds: number[], continuationToken: string): Promise<ItemsResponse | null> {
    // InternalAPIServiceV2 doesn't follow our array query parameter guidelines
    // from https://flexe-inc.atlassian.net/wiki/spaces/ENG/pages/2400944181/Flexe+API+Standards.
    // Instead, build them ourselves with URLSearchParams.
    const params = new URLSearchParams();
    for (const id of itemIds) {
      params.append('item-ids[]', id.toString());
    }
    if (continuationToken) {
      params.append('continuation-token', continuationToken);
    }

    // We need {} to make axios respect Content-Type header. Ref: https://github.com/axios/axios/issues/89.
    // It's possible this workaround can be removed if https://flexe-inc.atlassian.net/browse/IMS-7100 is fixed.
    return this.makeGetRequest(`${this.baseUrl}`, params, {}, {'Content-Type': 'application/json'});
  }
}

export default ItemMasterService;
