import * as React from 'react';
import {useContext} from 'react';
import * as moment from 'moment-timezone';
import {PickBatchDocument} from '../WaveInterfaces';
import DocumentPrintButton from '../shared/DocumentPrintButton';
import {PickWaveStatus} from '../WaveBatchInterfaces';
import {BatchDetailsContext} from './BatchDetailsContext';

// eslint-disable-next-line @typescript-eslint/ban-types
const BatchDocuments: React.FC<{}> = () => {
  const {batch, wave} = useContext(BatchDetailsContext);

  const getDocumentCreateDate = (document: PickBatchDocument) => {
    return moment
      .utc(document?.createdAt)
      .tz(moment.tz.guess())
      .format('MMM D, h:mm a');
  };

  const batchDocument = batch?.documents[batch.documents.length - 1];

  return (
    <div className="widget-container">
      <div className="container-fluid">
        <div className="header">
          <h2>Documents</h2>
        </div>

        <div className="pick-wave-batch-documents-row">
          <div className="info-pair" style={{justifyContent: 'center'}}>
            <span className="title">Batch Label</span>
          </div>
          <div className="info-pair">
            <div className="button-container">
              <DocumentPrintButton
                className={'shipment-document-print'}
                document={batchDocument}
                isWaveCancelled={wave.status === PickWaveStatus.cancelled}
                printButtonPrefix={'Print'}
              />
            </div>
            <span className="value">
              {batchDocument?.createdAt && `Last generated ${getDocumentCreateDate(batchDocument)}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchDocuments;
