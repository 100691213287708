import {format as formatDate} from 'date-fns';
import {OrderLineReceipt} from '../OutboundOrdersInterfaces';
import {dateFormat} from './Constants';

export function getCalculatedShipByDateForDisplay(receipts: OrderLineReceipt[]): string | undefined {
  // Unfortunately we use null a lot in Typescript, so we still have to
  //  check required inputs
  if (!receipts) {
    return undefined;
  }

  // There isn't a good reason for calculatedShipByDate to only be on some receipts
  //  so don't overly complicate by trying to handle that case
  const dateStrings = new Set(
    receipts
      .filter((receipt) => !receipt.cancelledAt && receipt.calculatedShipByDate)
      .map((receipt) => receipt.calculatedShipByDate)
  );

  if (dateStrings.size < 1) {
    return undefined;
  }

  let maxDate: Date;
  dateStrings.forEach((dateString) => {
    const date = new Date(dateString);
    if (!maxDate || date > maxDate) {
      maxDate = date;
    }
  });

  // maxDate should never be undefined here, but handle it anyway
  return maxDate ? formatDate(maxDate, dateFormat) : undefined;
}
