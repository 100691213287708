import * as React from 'react';
import {GridColDef} from '@mui/x-data-grid';
import {useEffect, useMemo, useRef, useState} from 'react';
import {ItemQuantity} from '../../../CommonInterfaces';
import {SkuCell} from './SkuCell';
import {UnitCell} from './UnitCell';
import {LpnCell} from './LpnCell';

const DEFAULT_COLUMN_WIDTH = 200;

/**
 * Used to get rows, columns, and loading state for an @mui/x-data-grid DataGrid
 *
 * @param fetchLocationContentsPage Callback for fetching more data. Only initial value is used.
 *  If this callback changes between renders, new callback values are ignored.
 * @param baseUrl URL to prefix paths with, e.g. /s for shipper pages or /wh for warehouse pages
 * @returns
 */
export function useLocationContentsDataGrid(
  fetchLocationContentsPage: (continuationToken: string | null) => Promise<LocationContentPage>,
  baseUrl: string
) {
  const [rows, setRows] = useState<LocationContentsGridLocationContent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchPage] = useState(() => fetchLocationContentsPage);
  const continuationToken = useRef<string | null>(null);
  useEffect(() => {
    let isCancelled = false;
    (async () => {
      let allRows = [];
      do {
        const page = await fetchPage(continuationToken.current);
        if (isCancelled) {
          return;
        }
        continuationToken.current = page.continuationToken;
        allRows = [...allRows, ...page.contents];
      } while (continuationToken.current);
      setRows(allRows);
      setIsLoading(false);
    })();

    return () => {
      isCancelled = true;
    };
  }, [fetchPage]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'reservationId',
        headerName: 'Reservation ID',
        type: 'number',
        valueFormatter: ({value}) => value?.toString(), // Override default formatting primarily to remove commas. E.g. want 12345 but get 12,345 by default.
        width: 180,
        // Numbers are right-aligned by default.
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'parentLpnBarcode',
        headerName: 'Parent LPN',
        type: 'string',
        renderCell: ({value}) => <LpnCell baseUrl={baseUrl} lpnBarcode={value} />,
        minWidth: DEFAULT_COLUMN_WIDTH,
        flex: 1
      },
      {
        field: 'lpnBarcode',
        headerName: 'LPN',
        type: 'string',
        renderCell: ({value}) => <LpnCell baseUrl={baseUrl} lpnBarcode={value} />,
        minWidth: DEFAULT_COLUMN_WIDTH,
        flex: 1
      },
      {
        field: 'sku',
        headerName: 'SKU',
        type: 'string',
        renderCell: ({row}) => <SkuCell baseUrl={baseUrl} item={row.sku} />,
        valueGetter: ({value}) => value.sku,
        minWidth: DEFAULT_COLUMN_WIDTH,
        flex: 1
      },
      {field: 'description', headerName: 'Description', type: 'string', minWidth: DEFAULT_COLUMN_WIDTH, flex: 1},
      {field: 'upc', headerName: 'UPC Barcode', minWidth: DEFAULT_COLUMN_WIDTH, flex: 1},
      {
        field: 'quantity',
        headerName: 'Quantity',
        type: 'number',
        width: 140,
        // Numbers are right-aligned by default.
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'unit',
        headerName: 'Unit',
        width: 100,
        valueGetter: ({value}) => value.unit,
        renderCell: ({row}) => <UnitCell quantity={row.unit} />
      },
      {
        field: 'uomJson',
        headerName: 'UoM',
        minWidth: DEFAULT_COLUMN_WIDTH,
        valueGetter: ({row}) => (row.uomJson ? parseCustomUoms(row.uomJson) : null)
      },
      {field: 'lotCode', headerName: 'Lot Code', minWidth: DEFAULT_COLUMN_WIDTH, flex: 1},
      {field: 'expirationDate', headerName: 'Expiration Date', minWidth: 180},
      {field: 'manufactureDate', headerName: 'Manufacture Date', minWidth: DEFAULT_COLUMN_WIDTH}
    ],
    [baseUrl]
  );

  return {rows, columns, isLoading};
}

export interface LocationContentsGridLocationContent {
  reservationId: number;
  parentLpnBarcode?: string;
  lpnBarcode: string;
  id: string;
  sku: {id: number; sku: string};
  description: string;
  upc: string;
  quantity: number;
  unit: ItemQuantity;
  uomJson: string;
  lotCode: string;
  expirationDate: string;
  manufactureDate: string;
}

export interface LocationContentPage {
  contents: LocationContentsGridLocationContent[];
  continuationToken: string | null;
}

function parseCustomUoms(uomJson: string) {
  const uoms = JSON.parse(uomJson);
  const eachesPerInner = uoms.eaches_per_inner_pack;
  const eachesPerOuterPack = uoms.eaches_per_outer_case;
  return `${eachesPerInner} each/inner pack, ${eachesPerOuterPack} each/outer case`;
}
