import * as React from 'react';

interface Props {
  children?: JSX.Element;
  headerIcon?: JSX.Element; // deprecated
  headerText: string | JSX.Element;
  index: number;
  open: boolean;
  hide?: boolean;
  onClick(event);
}

function Expando(props: Props) {
  const expandoClasses = props.open ? 'expando open' : 'expando';
  const direction = props.open ? 'down' : 'right';
  return (
    // TODO: remove this inline style
    <div className={expandoClasses} style={props.hide ? {display: 'none'} : null}>
      <div className="header" data-index={props.index} onClick={props.onClick}>
        <i className={`fa fa-chevron-${direction} direction`}></i>
        {props.headerIcon}
        {props.headerText}
      </div>
      <div className="content">{props.children}</div>
    </div>
  );
}

export default Expando;
