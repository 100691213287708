import * as React from 'react';
import {FunctionComponent} from 'react';

interface Props {
  showDropdown: boolean;
  showMoveToList: boolean;
  containerDeliveryId: number;
  inProgressDropoffIds: number[];
  showEditToggle: boolean;
  toggleShowDropdown: () => void;
  toggleShowMoveToList: () => void;
  editLpn: () => void;
  moveLPNto: (moveToContainerDropoffId: number) => Promise<void>;
  confirmLPNDelete: () => void;
  editSkuMenuProps?: {
    editSku: () => void;
    confirmRemoveSku: () => void;
  };
}

const EditLpnMenu: FunctionComponent<Props> = (props) => {
  const {editSkuMenuProps: {editSku, confirmRemoveSku} = {}} = props;
  return (
    <div className="edit-controls col-md-1">
      <a data-testid="ellipses-icon-anchor" onClick={props.toggleShowDropdown}>
        {props.showDropdown ? (
          <span className="fa-stack">
            <i className="fas fa-square fa-stack-2x"></i>
            <i className="fas fa-ellipsis-v fa-stack-1x fa-inverse"></i>
          </span>
        ) : (
          <i className="fas fa-ellipsis-v"></i>
        )}
      </a>

      {props.showDropdown && (
        <div className="LPN-modification-dropdown-menus">
          <ul className="dropdown-menu main-dropdown show">
            {props.showEditToggle && (
              <li>
                <a onClick={props.editSkuMenuProps ? editSku : props.editLpn}>Edit</a>
              </li>
            )}
            {!props.editSkuMenuProps && (
              <li data-testid="move_lpn_li">
                <a onClick={props.toggleShowMoveToList}>
                  Change Delivery
                  <i className="fas fa-angle-right"></i>
                </a>
                {props.showMoveToList && (
                  <ul className="dropdown-menu lpn-list-sub-dropdown show">
                    <li className="selected-dropoff">
                      <i className="fas fa-check successGreen"></i>
                      {`Inbound #${props.containerDeliveryId}`}
                    </li>
                    <li className="divider"></li>
                    {props.inProgressDropoffIds.map((dropoffId) => {
                      if (dropoffId !== props.containerDeliveryId) {
                        return (
                          <li key={`in-progress-dropoff - ${dropoffId}`}>
                            <a onClick={() => props.moveLPNto(dropoffId)}>{`Inbound #${dropoffId}`}</a>
                          </li>
                        );
                      }
                    })}
                  </ul>
                )}
              </li>
            )}
            <li className="divider"></li>
            <li data-testid="delete_lpn_li">
              <a onClick={props.editSkuMenuProps ? confirmRemoveSku : props.confirmLPNDelete} className="delete-text">
                {props.editSkuMenuProps ? 'Remove Item' : 'Delete LPN'}
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default EditLpnMenu;
