import * as React from 'react';
import {FC, useState, useEffect} from 'react';
import {groupBy} from 'lodash';
import {Skeleton} from '@mui/material';
import {Shipment} from '../../ShipmentInterfaces';
import {getBooleanFeatureFlag} from '../../ShipmentHelper';
import {LocationContent} from '../../../../locations/LocationsService';
import {LpnSearchDetails} from '../../../../lpns/LpnsInterfaces';
import {pluralizeUnit} from '../../../../shared/utilities/DataFormatting';
import {useManifestLpns} from '../UseManifestLpns';
import {Packaging, TransitionState} from '../../../../shared/CommonInterfaces';
import ProgressBar from '../../../../shared/ProgressBar';
import {getLoadedData, getShippedData, getStagedData} from './shipment-progress-helpers';

// Shipment Documents are FKA required labels so that is why this FF is named the way.
const FF_SHOW_SHIPMENT_PROGRESS = 'WMS_7277_shipment_progress_widget';

interface Props {
  authenticityToken: string;
  shipment: Shipment;
  lpns: LpnSearchDetails[];
  lpnsLoading: boolean;
  looseGoodsInStagingLocation: LocationContent[];
  isFreightTrailerLoadingEnabled: boolean;
}

export const ShipmentProgress: FC<Props> = (props) => {
  const {
    isFreightTrailerLoadingEnabled,
    lpns,
    shipment,
    looseGoodsInStagingLocation,
    lpnsLoading,
    authenticityToken
  } = props;

  const {manifestLpns, hasFetched} = useManifestLpns(props.shipment, isFreightTrailerLoadingEnabled);
  const [stagedQuantities, setStagedQuantities] = useState<object>({});
  const [stagedQuantityEaches, setStagedQuantityEaches] = useState<number>(0);
  const [loadedQuantities, setLoadedQuantities] = useState<object>({});
  const [loadedQuantityEaches, setLoadedQuantityEaches] = useState<number>(0);
  const [shippedQuantities, setShippedQuantities] = useState<object>({});
  const [shippedQuantityEaches, setShippedQuantityEaches] = useState<number>(0);
  // Should be removed when feature flag is removed
  const [showShipmentProgress, setShowShipmentProgress] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const lineItemsByPackaging = groupBy(shipment.line_items, (lineItem) => lineItem.packaging);

  useEffect(() => {
    const flags = [[FF_SHOW_SHIPMENT_PROGRESS, setShowShipmentProgress]] as const;

    for (const [flag, setterFn] of flags) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getBooleanFeatureFlag(flag, setterFn, shipment, authenticityToken, errors, setErrors);
    }
  }, [shipment, authenticityToken, errors]);

  useEffect(() => {
    getStagedData(
      shipment,
      lpns,
      manifestLpns,
      looseGoodsInStagingLocation,
      isFreightTrailerLoadingEnabled,
      setStagedQuantities,
      setStagedQuantityEaches
    );
    getLoadedData(manifestLpns, setLoadedQuantities, setLoadedQuantityEaches);
    getShippedData(
      shipment,
      manifestLpns,
      isFreightTrailerLoadingEnabled,
      setShippedQuantities,
      setShippedQuantityEaches
    );
  }, [shipment, lpns, manifestLpns, looseGoodsInStagingLocation, showShipmentProgress, isFreightTrailerLoadingEnabled]);

  const totalEachesExpected = shipment.line_items.reduce(
    (accumulator, current) => accumulator + current.ordered_units_per_packaging.each,
    0
  );

  const expectedQuantityCount = (packaging: Packaging) => {
    return lineItemsByPackaging?.[packaging]?.reduce((accumulator, current) => accumulator + current.quantity, 0);
  };

  const expectedQuantityString = (packaging: Packaging) => {
    const expectedquantity = expectedQuantityCount(packaging);
    if (expectedquantity) {
      return `${expectedquantity} ${pluralizeUnit(packaging, expectedquantity)}`;
    } else {
      return null;
    }
  };

  const fetchProgressColor = (current, target) => {
    if (current === target) {
      return '#0DC075';
    } else {
      return '#7A60C6';
    }
  };

  const fetchBackgroundColor = (current, target) => {
    if (current === target) {
      return '#0DC075';
    } else if (current === 0) {
      return '#C9D1DC';
    } else {
      return '#BEACF0';
    }
  };

  const header = () => (
    <>
      <h2>Progress</h2>
      <span className="progress-contents-total">
        <b>
          Expected:{' '}
          {[
            expectedQuantityString(Packaging.each),
            expectedQuantityString(Packaging.carton),
            expectedQuantityString(Packaging.pallet)
          ]
            .filter(Boolean)
            .join(' / ')}
        </b>
      </span>
    </>
  );

  const shouldShowProgressDecoration = (current: number, target: number): boolean => {
    if (current === 0 || current === target) {
      return false;
    }
    return true;
  };

  const loadedCase = (): boolean => {
    return !lpnsLoading && (hasFetched || !isFreightTrailerLoadingEnabled);
  };

  const buildQtyByUoMList = (qtyByUoMObject: object) => {
    if (Object.keys(qtyByUoMObject).length === 0) {
      return <span className="progress-contents-span">0 Units</span>;
    }
    return Object.keys(qtyByUoMObject).map((key: Packaging) => {
      return (
        <span className="progress-contents-span" key={`staged-${key}`}>
          {qtyByUoMObject[key]} {pluralizeUnit(key, Number(qtyByUoMObject[key]))}
        </span>
      );
    });
  };

  return (
    <>
      {showShipmentProgress && (
        <div id="shipment-progress">
          <div className="container-fluid">
            <div className="progress-container">
              <header>{header()}</header>
              {!loadedCase() && (
                <>
                  <h1>
                    <Skeleton variant="rounded" height={30} />
                  </h1>
                  <h4>
                    <Skeleton variant="rounded" height={20} />
                  </h4>
                  <h4>
                    <Skeleton variant="rounded" height={20} />
                  </h4>
                </>
              )}
              {loadedCase() && (
                <div className="progress-grid">
                  <div className="progress-grid-row">
                    <div className="progress-grid-col staged">
                      <span className="progress-contents-total">
                        <b>Staged:</b>
                      </span>
                      <ProgressBar
                        status={TransitionState.InProgress}
                        total={totalEachesExpected}
                        progress={stagedQuantityEaches}
                        backgroundColor={fetchBackgroundColor(stagedQuantityEaches, totalEachesExpected)}
                        progressColor={fetchProgressColor(stagedQuantityEaches, totalEachesExpected)}
                        displayStatusText={false}
                        progressBarDecoration={shouldShowProgressDecoration(stagedQuantityEaches, totalEachesExpected)}
                      />
                      {buildQtyByUoMList(stagedQuantities)}
                    </div>
                    {isFreightTrailerLoadingEnabled && (
                      <div className="progress-grid-col loaded">
                        <span className="progress-contents-total">
                          <b>Loaded:</b>
                        </span>
                        <ProgressBar
                          status={TransitionState.InProgress}
                          total={totalEachesExpected}
                          progress={loadedQuantityEaches}
                          backgroundColor={fetchBackgroundColor(loadedQuantityEaches, totalEachesExpected)}
                          progressColor={fetchProgressColor(loadedQuantityEaches, totalEachesExpected)}
                          displayStatusText={false}
                          progressBarDecoration={shouldShowProgressDecoration(
                            loadedQuantityEaches,
                            totalEachesExpected
                          )}
                        />
                        {buildQtyByUoMList(loadedQuantities)}
                      </div>
                    )}
                    <div className="progress-grid-col shipped">
                      <span className="progress-contents-total">
                        <b>Shipped:</b>
                      </span>
                      <ProgressBar
                        status={TransitionState.InProgress}
                        total={totalEachesExpected}
                        progress={shippedQuantityEaches}
                        backgroundColor={fetchBackgroundColor(shippedQuantityEaches, totalEachesExpected)}
                        progressColor={fetchProgressColor(shippedQuantityEaches, totalEachesExpected)}
                        displayStatusText={false}
                        progressBarDecoration={shouldShowProgressDecoration(shippedQuantityEaches, totalEachesExpected)}
                      />
                      {buildQtyByUoMList(shippedQuantities)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface FallbackProps {
  authenticityToken: string;
  shipment: Shipment;
}

export const ShipmentProgressFallback: React.FC<FallbackProps> = (props) => {
  const {shipment, authenticityToken} = props;

  const [showShipmentProgress, setShowShipmentProgress] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  useEffect(() => {
    const flags = [[FF_SHOW_SHIPMENT_PROGRESS, setShowShipmentProgress]] as const;

    for (const [flag, setterFn] of flags) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getBooleanFeatureFlag(flag, setterFn, shipment, authenticityToken, errors, setErrors);
    }
  }, [shipment, authenticityToken, errors]);
  return (
    <>
      {showShipmentProgress && (
        <div id="shipment-progress">
          <div className="container-fluid">
            <div className="progress-container">
              <header>
                <h2>Progress</h2>
                <span className="progress-contents-total">
                  <b>Unable to load shipment progress.</b>
                </span>
              </header>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
