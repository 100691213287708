import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';

interface Props {
  showCancelModal: boolean;
  disabled: boolean;
  targetState: string;
  ccId: string;
  transitionCycleCountState: (event: any) => Promise<void>;
  toggleCancelModal: () => void;
}

const CycleCountCancelModal: FunctionComponent<Props> = (props) => {
  return (
    <LegacyModal
      id="cancel-cycle-count-modal"
      title="Cancel Cycle Count"
      show={props.showCancelModal}
      size="small"
      toggleModal={props.toggleCancelModal}
      footer={
        <div>
          <a onClick={props.toggleCancelModal}>No</a>
          <button
            id="perform-cancel"
            type="button"
            className="btn btn-primary"
            disabled={props.disabled}
            onClick={props.transitionCycleCountState}
            data-target-state={props.targetState}
          >
            Yes
          </button>
        </div>
      }
      disableClose={false}
    >
      <div>
        <p>Are you sure you want to cancel cycle count #{props.ccId}?</p>
      </div>
    </LegacyModal>
  );
};

export default CycleCountCancelModal;
