import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import RetailFulfillmentService, {RetailOrder} from '../shared/services/RetailFulfillmentService';
import {ApiResponse} from '../shared/CommonInterfaces';
import ModalConfirmShipmentDetailsTable from './ModalConfirmShipmentDetailsTable';

interface BulkConfirmModalProps {
  showModal: boolean;
  toggleModal: () => void;
  selectedOrders: RetailOrder[];
  retailFulfillmentService: RetailFulfillmentService;
  onSuccess: () => void;
  onError: (errors: string[]) => void;
}

const BulkConfirmModal: React.FC<BulkConfirmModalProps> = (props) => {
  async function confirmOrders() {
    const orderIds: string[] = props.selectedOrders.map((o) => o.id.toString());
    const response: ApiResponse<any> = await props.retailFulfillmentService.confirmOrders(orderIds);

    props.toggleModal();

    if (response.errors) {
      props.onError(response.errors.map((error) => error.detail));
      return;
    }

    props.onSuccess();
  }

  const footer = (
    <div id="bulk-confirm-modal-footer">
      <button id="bulk-confirm-modal-cancel-button" className="no-cta" onClick={props.toggleModal}>
        Cancel
      </button>
      <button id="bulk-confirm-modal-confirm-button" onClick={confirmOrders}>
        Confirm Orders
      </button>
    </div>
  );

  return (
    <div>
      <LegacyModal
        id="bulk-cancel-modal"
        title="Bulk Confirm"
        size="small"
        show={props.showModal}
        toggleModal={props.toggleModal}
        footer={footer}
      >
        <div>
          <h3>Confirm Shipments</h3>
          <p className="grey2 note">
            Confirm your facility can carry out the work to fulfill these shipments. FLEXE inventory data indicates that
            sufficient inventory is available to fulfill this request..
          </p>
          <ModalConfirmShipmentDetailsTable selectedOrders={props.selectedOrders} />
        </div>
      </LegacyModal>
    </div>
  );
};

export default BulkConfirmModal;
