import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Company, Reservation} from '../../shared/CommonInterfaces';
import FlexeContext, {FlexeContextValues} from '../../contexts/FlexeContext';
import Fulfillment from './Fulfillment';
import FulfillmentDetail from './FulfillmentDetail';
import FulfillmentService from './FulfillmentService';

interface AppProps {
  authenticityToken: string;
  reservations: Reservation[];
  hasCarrierAccounts: boolean;
  defaultShipByStartingFilterDate: string;
  omnichannelEnabled: boolean;
  currentCompany: Company;
  showBulkCancelModal: boolean;
}

function FulfillmentApp(props: AppProps) {
  const fulfillmentService = new FulfillmentService(props.authenticityToken);

  const flexeContextValue: FlexeContextValues = {
    authenticityToken: props.authenticityToken
  };

  return (
    <FlexeContext.Provider value={flexeContextValue}>
      <Router>
        <Switch>
          <Route
            path="/s/fulfillment/ecommerce"
            exact
            render={(routeProps) => <Fulfillment {...routeProps} {...props} fulfillmentService={fulfillmentService} />}
          />
          <Route
            path="/s/fulfillment/ecommerce/:id"
            render={(routeProps) => (
              <FulfillmentDetail {...routeProps} {...props} fulfillmentService={fulfillmentService} />
            )}
          />{' '}
        </Switch>
      </Router>
    </FlexeContext.Provider>
  );
}

export default FulfillmentApp;
