/** @jsxRuntime classic */
/** @jsx jsx */

import * as React from 'react';
import {useEffect, useState} from 'react';
import {jsx} from '@emotion/react';
import {Column, Row, Text} from '@flexe/ui-components';
import {generateNotification} from '../notifications/notification-utility';
import {Site} from '../userManagementInterfaces';
import {roleToTitleCase} from '../helpers';
import {containerStyle} from '../styles';

interface Props {
  alertMessage?: string;
  assignableReservationRoles: string[];
  assignableWarehouseRoles: string[];
  authenticityToken: string;
  notice?: string;
  user: NewRolesFormUser;
  reservations: Site[];
  warehouses: Site[];
}

interface NewRolesFormUser {
  id: number;
  name: string;
}

const NewRolesForm = ({
  alertMessage,
  assignableReservationRoles,
  assignableWarehouseRoles,
  authenticityToken,
  notice,
  reservations,
  user,
  warehouses
}: Props) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState<Site>();
  const [selectedReservation, setSelectedReservation] = useState<Site>();
  const [selectedRoleCount, setSelectedRoleCount] = useState(0);

  useEffect(() => {
    if (alertMessage) {
      generateNotification({level: 'error', message: alertMessage, location: 'in-place', durationMs: null});
    }

    if (notice) {
      generateNotification({level: 'success', message: notice});
    }
  }, [alertMessage, notice]);

  const handleWarehouseSelection = (event) => {
    const parsedId = parseInt(event.target.value, 10);
    setSelectedWarehouse(warehouses.find((warehouse) => warehouse.id === parsedId));
  };

  const handleReservationSelection = (event) => {
    const parsedId = parseInt(event.target.value, 10);
    setSelectedReservation(reservations.find((reservation) => reservation.id === parsedId));
  };

  const handleRoleCheckboxChange = (event) => {
    const {checked} = event.target;
    setSelectedRoleCount((oldCount) => (checked ? oldCount + 1 : oldCount - 1));
  };

  const isWarehouseSelect = warehouses.length !== 0;

  const roles = isWarehouseSelect ? assignableWarehouseRoles : assignableReservationRoles;

  return (
    <div css={containerStyle}>
      <Column>
        <Text>
          Add <strong>{user.name}</strong> to:
        </Text>
        <form action="/user_management/add_user" method="post">
          <Column>
            <input type="hidden" name="authenticity_token" value={authenticityToken} />
            <input type="hidden" name="user_id" value={user.id} />

            <Row childWidths={['grid-2', 'grid-6']} margin={['0', '200']}>
              {isWarehouseSelect ? (
                <SiteSelect
                  sites={warehouses}
                  fieldName="warehouse_id"
                  onSelect={handleWarehouseSelection}
                  placeholderText="Please select a warehouse"
                  label="Warehouse"
                />
              ) : (
                <SiteSelect
                  sites={reservations}
                  fieldName="reservation_id"
                  onSelect={handleReservationSelection}
                  placeholderText="Please select a reservation"
                  label="Reservation"
                />
              )}
            </Row>

            {roles.map((role) => {
              if (role === 'inventory_control' && !selectedWarehouse?.isInventoryControlRoleEnabled) {
                return null;
              }

              return <RoleCheckbox key={role} role={role} onChange={handleRoleCheckboxChange} />;
            })}

            <div>
              <input
                type="submit"
                value="Submit"
                className="btn"
                disabled={
                  (warehouses.length !== 0 && !selectedWarehouse) ||
                  (reservations.length !== 0 && !selectedReservation) ||
                  selectedRoleCount === 0
                }
              />
            </div>
          </Column>
        </form>
      </Column>
    </div>
  );
};

const SiteSelect = ({sites, fieldName, onSelect, placeholderText, label}) => (
  <React.Fragment>
    <Text tag="label" htmlFor={fieldName} type="h90">
      {label}
    </Text>
    <select id={fieldName} name={fieldName} onChange={onSelect}>
      <option value={-1}>{placeholderText}</option>
      {sites.map((site) => (
        <option key={site.id} value={site.id}>
          {site.name}
        </option>
      ))}
    </select>
  </React.Fragment>
);

const RoleCheckbox = ({role, onChange}) => (
  <Row childWidths={['fit', 'fit']} margin={['0', '50']}>
    <input type="checkbox" name={role} id={role} onChange={onChange} value="1" />
    <Text tag="label" htmlFor={role} type="h90">
      {roleToTitleCase(role)}
    </Text>
  </Row>
);

export default NewRolesForm;
