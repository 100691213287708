import * as React from 'react';
import {useEffect, useState} from 'react';
import {isEqual} from 'lodash';
import {Filter, FilterType, FilterValue} from '../../shared/CommonInterfaces';

// styles are from app/assets/stylesheets/components/filters.scss

interface FilterState {
  addingFilter: boolean;
  filtersInUse: string[];
  filterToAdd: number;
  filterValue: any;
  selectedFilters: FilterValue[];
}

interface FilterProps {
  filters: Filter[];
  filterChangeHandler: any;
}

class Filters extends React.Component<FilterProps, FilterState> {
  constructor(props) {
    super(props);
    const filterValues = this.props.filters.filter((filter) => filter.value);
    const splitFilters = [];
    filterValues.forEach((currFilter) => {
      if (currFilter.allowMultiple) {
        //take the array of values from a Multi-Filter and split it into
        // many individual filter badges
        const splitup = [].concat(currFilter.value).map((val) => ({...currFilter, value: val}));
        splitFilters.push(...splitup);
      } else {
        splitFilters.push(currFilter);
      }
    });
    this.state = {
      addingFilter: false,
      filterToAdd: 0,
      filterValue: '',
      filtersInUse: [],
      selectedFilters: splitFilters.map((filter) => ({
        filter,
        value: filter.value
      }))
    };
  }

  public render() {
    const allowMultiple = this.props.filters.some((filter) => filter.allowMultiple);
    const currentFilter = this.props.filters[this.state.filterToAdd];
    const filterOptions = this.props.filters.map((filter, index) => {
      if (filter.allowMultiple || this.state.filtersInUse.indexOf(filter.key) === -1) {
        return (
          <option key={index} value={index}>
            {filter.displayName}
          </option>
        );
      }
    });

    let filterInput = null;
    if (allowMultiple || this.state.filtersInUse.length < this.props.filters.length) {
      switch (currentFilter.type) {
        case FilterType.DataList:
          // eslint-disable-next-line no-case-declarations
          const options = currentFilter.options
            ? currentFilter.options.map((option) => {
                return (
                  <option value={option.value} key={option.value}>
                    {option.displayName}
                  </option>
                );
              })
            : null;
          filterInput = (
            <div>
              <input
                name={currentFilter.key}
                className="form-control"
                list={currentFilter.key}
                value={this.state.filterValue}
                onChange={this.handleValueChange}
                onKeyPress={this.handleKeyPress}
                placeholder={`Enter ${currentFilter.displayName}`}
              />
              <datalist id={currentFilter.key}>{options}</datalist>
            </div>
          );
          break;
        case FilterType.Date:
          filterInput = (
            <input
              name={currentFilter.key}
              value={this.state.filterValue}
              onChange={this.handleValueChange}
              onKeyPress={this.handleKeyPress}
              className="form-control"
              type="date"
              date-format="mm/dd/yyyy"
            />
          );
          break;
        case FilterType.Dropdown:
          // eslint-disable-next-line no-case-declarations
          const ddOptions = currentFilter.options
            ? currentFilter.options.map((option) => {
                return (
                  <option value={option.value} key={option.value}>
                    {option.displayName}
                  </option>
                );
              })
            : null;
          filterInput = (
            <select
              name={currentFilter.key}
              value={this.state.filterValue}
              onChange={this.handleValueChange}
              onKeyPress={this.handleKeyPress}
              className="form-control"
            >
              {ddOptions}
            </select>
          );
          break;
        case FilterType.Number:
          filterInput = (
            <input
              name={currentFilter.key}
              value={this.state.filterValue}
              onChange={this.handleValueChange}
              onKeyPress={this.handleKeyPress}
              className="form-control"
              type="number"
              placeholder={`Enter ${currentFilter.displayName}`}
            />
          );
          break;
        case FilterType.TypeAhead:
          // eslint-disable-next-line no-case-declarations
          let typeAheadOptions = null;
          if (currentFilter.options && currentFilter.options.length > 0) {
            typeAheadOptions = (
              <ul className="dropdown-menu show">
                {currentFilter.options.map((option, index) => {
                  return (
                    <li key={index}>
                      <a onClick={this.handleTypeAheadSelection(option.value)}>{option.displayName}</a>
                    </li>
                  );
                })}
              </ul>
            );
          }
          filterInput = (
            <div>
              <input
                name={currentFilter.key}
                className="form-control"
                type="text"
                value={this.state.filterValue}
                onChange={this.handleTypeAhead}
                placeholder={`Enter ${currentFilter.displayName}`}
              />
              {typeAheadOptions}
            </div>
          );
          break;
        case FilterType.Present:
          break;
        case FilterType.LabelSearch:
          // TODO: Pass in multiple defaultKeys mapped from options and/or typeahead DB Query
          filterInput = (
            <LabelFilter
              defaultKey={currentFilter.defaultKey}
              updateFilterValue={(keyValPair) => this.setState({filterValue: keyValPair})}
            />
          );
          break;

        default:
          filterInput = (
            <input
              name={currentFilter.key}
              className="form-control"
              type="text"
              value={this.state.filterValue}
              onChange={this.handleValueChange}
              onKeyPress={this.handleKeyPress}
              placeholder={`Enter ${currentFilter.displayName}`}
            />
          );
          break;
      }
    }
    const filters = (
      <ul id="filter-list">
        {this.state.selectedFilters.map((selected, index) => {
          return (
            <li className="filter-button" key={index}>
              <div className="filter-display-name">{selected.filter.displayName}</div>
              <div className="filter-value">
                {/*Label Search Filter needs to be formatted from Object -> String*/}
                {selected.filter.type === FilterType.LabelSearch
                  ? `${selected.value.labelKey} -> ${selected.value.labelValue}`
                  : selected.value}
                <a className="filter-remove" data-index={index} onClick={this.handleRemoveFilter}>
                  <i className="fa fa-times-circle" aria-hidden="true"></i>
                </a>
              </div>
            </li>
          );
        })}
      </ul>
    );

    const displayAddFilterToggle =
      !this.state.addingFilter && (this.state.filtersInUse.length < this.props.filters.length || allowMultiple);

    const displayFilterSelect =
      this.state.addingFilter && (this.state.filtersInUse.length < this.props.filters.length || allowMultiple);

    return (
      <div id="filters-component">
        <div className="add-filter">
          {!allowMultiple && this.state.filtersInUse.length === this.props.filters.length && (
            <small>All Filters Applied</small>
          )}
          {displayAddFilterToggle && (
            <a className="new-filter" onClick={this.toggleAddFilter}>
              <i className="fa fa-plus-circle" aria-hidden="true"></i> New Filter
            </a>
          )}
          {displayFilterSelect && (
            <div className="form-inline">
              <div id="filter-select" className="form-group">
                <select value={this.state.filterToAdd} onChange={this.handleFilterSelect}>
                  {filterOptions}
                </select>
              </div>
              <div className="form-group filter-input">{filterInput}</div>
              <div className="form-group" id="filter-actions">
                <a className="submit-btn" onClick={this.handleAddFilter}>
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a className="text-danger" onClick={this.toggleAddFilter}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="selected-filters">
          {this.state.selectedFilters.length === 0 && (
            <small>
              <em>No filters applied</em>
            </small>
          )}
          {filters}
        </div>
      </div>
    );
  }

  private handleValueChange = (event) => {
    this.setState({
      filterValue: event.target.value
    });
  };

  private handleAddFilter = () => {
    if (this.state.filterValue) {
      const filterValIsString = typeof this.state.filterValue === 'string';
      const currValue = (filterValIsString && this.state.filterValue.trim()) || this.state.filterValue;
      if (!currValue) {
        return;
      }
      const currFilter = this.props.filters[this.state.filterToAdd];
      const newFiltersToAdd =
        currFilter.allowMultiple && typeof currValue === 'string'
          ? //    If user puts in csv of IDs, split up into individual badges
            //    TODO Validate the UX of this design
            currValue
              .split(',')
              .filter((val) => !!val.trim()) //remove just space values
              .map((val) => ({filter: currFilter, value: val.trim()}))
          : {filter: currFilter, value: currValue};
      const updatedFilters = this.state.selectedFilters.slice().concat(newFiltersToAdd);
      const {newFilterIndex, updatedFiltersInUse} = this.getFilterDisplayValues(updatedFilters);
      this.setState({
        addingFilter: false,
        filterToAdd: newFilterIndex,
        filterValue: '',
        filtersInUse: updatedFiltersInUse,
        selectedFilters: updatedFilters
      });
      this.props.filterChangeHandler(updatedFilters);
    }
  };
  private handleRemoveFilter = (event) => {
    const index = event.currentTarget.getAttribute('data-index');
    const updatedFilters = this.state.selectedFilters.slice();
    updatedFilters.splice(index, 1);
    const {newFilterIndex, updatedFiltersInUse} = this.getFilterDisplayValues(updatedFilters);
    this.setState({
      filterToAdd: newFilterIndex,
      filtersInUse: updatedFiltersInUse,
      selectedFilters: updatedFilters
    });
    this.props.filterChangeHandler(updatedFilters);
  };

  private toggleAddFilter = () => {
    this.setState({
      addingFilter: !this.state.addingFilter,
      filterValue: ''
    });
  };

  private handleFilterSelect = (event) => {
    const index = event.target.value;
    const selectedFilter = this.props.filters[index];
    const startingValue =
      !selectedFilter.allowMultiple && (selectedFilter.value || selectedFilter.type === FilterType.Present)
        ? 'true'
        : null || '';
    this.setState({
      filterToAdd: index,
      filterValue: startingValue
    });
  };

  private handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleAddFilter();
    }
  };

  private handleTypeAhead = async (event) => {
    const query = event.target.value;
    const filter = this.props.filters[this.state.filterToAdd];
    this.setState({
      filterValue: query
    });
    if (query.length > 2) {
      await filter.typeAheadHandler(event.target.value);
    }
  };

  private handleTypeAheadSelection = (value) => (event) => {
    const filter = this.props.filters[this.state.filterToAdd];
    filter.typeAheadHandler(null);
    this.setState(
      {
        filterValue: value
      },
      this.handleAddFilter
    );
  };

  private getFilterDisplayValues(filters: FilterValue[]) {
    const updatedFiltersInUse = filters.map((selectedFilter) => {
      return selectedFilter.filter.key;
    });
    let newFilterIndex = 0;
    for (let i = 0; i < this.props.filters.length; i++) {
      if (this.props.filters[i].allowMultiple || updatedFiltersInUse.indexOf(this.props.filters[i].key) === -1) {
        newFilterIndex = i;
        break;
      }
    }
    return {
      newFilterIndex,
      updatedFiltersInUse
    };
  }
}

export const LabelFilter = (props) => {
  const [labelKey, setLabelKey] = useState(props.defaultKey || '');
  const [labelValue, setLabelValue] = useState('');

  useEffect(() => {
    if (labelKey.trim() && labelValue.trim()) {
      //stores the filter value as an object; serialized to query string in OutboundOrders.handleFilterChange
      props.updateFilterValue({labelKey, labelValue});
    }
  }, [labelKey, labelValue]);

  return (
    <div style={{marginTop: -23 + 'px'}}>
      <div style={{display: 'inline-block', marginRight: 20 + 'px'}}>
        <label style={{display: 'block'}}> Label Key </label>
        {/* TODO this should be a dropdown with all available label keys present*/}
        <input
          name="labelKey"
          value={labelKey}
          onChange={(e) => setLabelKey(e.target.value)}
          className="form-control"
          type="text"
        />
      </div>
      <div style={{display: 'inline-block', marginRight: 20 + 'px'}}>
        <label style={{display: 'block'}}> Label Value </label>
        <input
          name="labelKey"
          value={labelValue}
          onChange={(e) => setLabelValue(e.target.value)}
          className="form-control"
          type="text"
        />
      </div>
    </div>
  );
};

export default Filters;
