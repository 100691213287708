import {ParseError as PapaParseError, ParseResult} from 'papaparse';
import {Packaging} from '../../shared/CommonInterfaces';
import {PluralPackaging} from '../../shared/CommonMappings';
import CsvValidator, {CsvValidatorOptions, ParseError} from '../../../libs/CsvValidator';
import {AvailableInventory, INV_CSV_COLUMNS_SPEC, Line, WarehouseAvailability} from './InventorySelection';

/**
 * Transforms an array of CsvColumns to the format that
 * CsvBulkCreateModal requires for headers
 */
export function csvFormatToModalHeaders(): string[][] {
  const headers: string[][] = [];
  const csvFormat = INV_CSV_COLUMNS_SPEC;

  for (const csvFormatKey in csvFormat) {
    // eslint-disable-next-line no-prototype-builtins
    if (csvFormat.hasOwnProperty(csvFormatKey)) {
      const isRequired = csvFormat[csvFormatKey].required ? 'Yes' : 'No';
      headers.push([csvFormatKey.toLowerCase(), isRequired]);
    }
  }
  return headers;
}

/**
 * Papa.parse complete callback to do additional validation
 * and set errors and/or results into the parent component
 * @param results The Papa.parse result
 * @param csvValidator A CsvValidator to call for additional validation
 * @param reservationInventory The inventory at the selected reservation
 * @param setValidLinesAndErrors Callback to set any valid results and line-level errors.
 *  May not be called if no lines are processed.
 * @param setFileErrors Callback to set error messages that blocked file processing.
 *  Will only be called if errors are present.
 */
export function parseOnComplete(
  results: ParseResult,
  csvValidator: CsvValidator,
  reservationInventory: AvailableInventory[],
  setValidLinesAndErrors: (validLines: Line[], lineErrors: string[]) => any,
  setFileErrors: (errors: string[]) => any
) {
  // Remember setValidLines should typically close the modal, so
  // don't call it if adding file level errors

  if (results.errors && results.errors.length > 0) {
    const errs = results.errors.map((it) => parseErrorToString(it));
    setFileErrors(errs);
  } else {
    const options: CsvValidatorOptions = {
      headerNormalizer: (receivedHeader: string) => receivedHeader.toLowerCase().trim()
    };

    const [validationErrors, normalizedResults] = csvValidator.validate(results, INV_CSV_COLUMNS_SPEC, options);

    const errs = validationErrors.map((it) => parseErrorToString(it));
    if (errs.length === 0) {
      processValidParsedCsv(normalizedResults, reservationInventory, setValidLinesAndErrors);
    } else {
      setFileErrors(errs);
    }
  }
}

function parseErrorToString(error: ParseError | PapaParseError): string {
  // Intentionally don't allow row = 0 in this condition
  if (error.row) {
    return `Row ${error.row}: ${error.message}`;
  }
  return error.message;
}

/**
 * Should not typically be called anymore.
 * Only exported for backwards compatibility.
 * Processor for CSV results that pass validation.
 * @param parsedCSV The validated Papa.parse result
 * @param reservationInventory The inventory at the selected reservation
 * @param setValidLinesAndErrors Callback to set any valid results and line-level errors.
 *  Will always be called if parsedCSV was passed as expected
 */
export function processValidParsedCsv(
  parsedCSV: ParseResult,
  reservationInventory: AvailableInventory[],
  setValidLinesAndErrors: (validLines: Line[], lineErrors: string[]) => any
) {
  if (!parsedCSV) {
    return;
  }

  const validLines: Line[] = [];
  const errs: string[] = [];

  parsedCSV.data.forEach((line, idx) => {
    if (!line.sku && !line.unit && !line.quantity) {
      return; // Protect from parsing mishaps. This is a blank line.
    }

    const skuInventory: AvailableInventory = reservationInventory.find((it) => it.sku === line.sku);

    if (!skuInventory) {
      //either an invalid sku or zero on-hand. +2 because row 1 is the header
      errs.push(`SKU ${line.sku} (row ${idx + 2}) has no inventory at the selected warehouse`);
      return;
    }

    const availableEaches = skuInventory.availability.reduce((sum, current) => sum + current.availableEaches, 0);
    const availableCartons = skuInventory.availability.reduce((sum, current) => sum + current.availableCartons, 0);
    const availablePallets = skuInventory.availability.reduce((sum, current) => sum + current.availablePallets, 0);
    const availableAmount = getAvailableAmount(line.unit, availableEaches, availableCartons, availablePallets);

    if (availableAmount === undefined) {
      errs.push(`${line.unit} is not a valid unit`);
    } else if (line.quantity > availableAmount) {
      const packagingDescriptor = availableAmount === 1 ? line.unit : PluralPackaging.get(line.unit);
      errs.push(`${line.sku} only has ${availableAmount} available ${packagingDescriptor}`);
    } else {
      //We can assume availability is checked for only one warehouse until order planning is supported
      const availability: WarehouseAvailability = {
        reservationId: skuInventory.availability[0].reservationId,
        warehouseName: skuInventory.availability[0].warehouseName,
        availableEaches,
        availableCartons,
        availablePallets
      };

      validLines.push({
        inventoryId: skuInventory.id,
        sku: skuInventory.sku,
        description: skuInventory.description,
        packaging: line.unit,
        quantity: Number(line.quantity),
        availability: [availability]
      });
    }
  });

  setValidLinesAndErrors(validLines, errs);
}

function getAvailableAmount(
  unitToRetrieve: Packaging,
  availableEaches: number,
  availableCartons: number,
  availablePallets: number
): number | undefined {
  switch (unitToRetrieve) {
    case Packaging.each: {
      return availableEaches;
    }
    case Packaging.carton: {
      return availableCartons;
    }
    case Packaging.pallet: {
      return availablePallets;
    }
    default: {
      return undefined;
    }
  }
}
