import * as React from 'react';
import {get, isEmpty, last} from 'lodash';
import {addDays, format as formatDate, parse as parseDate} from 'date-fns';
import {Link} from 'react-router-dom';
import * as qs from 'query-string';
import * as ReactTooltip from 'react-tooltip';
import {LegacyModal, Loader, Pagination, Table, TableHeader} from '@flexe/ui-components';
import {v4} from 'uuid';
import {ApiResponse, Company, FilterType, Reservation} from '../../shared/CommonInterfaces';
import Expando from '../../shared/Expando';
import Filters from '../../shared/Filters';
import CsvUtil from '../../shared/utilities/CsvUtil';
import DocumentationTooltip from '../../shared/DocumentationTooltip';
import FileStorageService from '../file-storage/FileStorageService';
import InventoryService from '../../shared/services/InventoryService';
import OutboundOrdersService from '../outbound-orders/OutboundOrdersService';
import {OutboundOrder} from '../outbound-orders/OutboundOrdersInterfaces';
import WarehouseService from '../../shared/services/WarehouseService';
import {EdiFilesState, V2EdiFile, V2EdiFilesResponse} from './EdiFilesInterfaces';
import EdiFilesService from './EdiFilesService';
import EdiFilesServiceV2 from './EdiFilesServiceV2';
import EdiFileHelpers from './EdiFileHelpers';
import InboundServiceV2, {InboundSearchShipment} from './InboundServiceV2';

interface Props {
  authenticityToken: string;
  currentCompany: Company;
  reservations?: Reservation[];
  inventoryService?: InventoryService;
  ediFilesService?: EdiFilesService;
  v2EdiFilesService?: EdiFilesServiceV2;
  outboundOrderService?: OutboundOrdersService;
  fileStorageService?: FileStorageService;
  inboundService?: InboundServiceV2;
  warehouseService?: WarehouseService;
}

interface SimpleItemStruct {
  id: number;
  item_code: string;
}

const maxFileSizeMB = 2; // max .docx file size in MB
const maxFileSize = maxFileSizeMB * 1024 * 1024;

class EdiFiles extends React.Component<Props, EdiFilesState> {
  private pageSize = 50;
  private ediFilesService: EdiFilesService;
  private fileStorageService: FileStorageService;
  private v2EdiFilesService: EdiFilesServiceV2;
  private inventoryService: InventoryService;
  private outboundOrderService: OutboundOrdersService;
  private inboundService: InboundServiceV2;
  private shipperName: string;
  private templatePath: string;

  // FeatureFlagging
  private warehouseService: WarehouseService;

  constructor(props) {
    super(props);
    this.inventoryService = props.inventoryService || new InventoryService(props.authenticityToken);
    this.ediFilesService = props.ediFilesService || new EdiFilesService(props.authenticityToken);
    this.fileStorageService = props.fileStorageService || new FileStorageService();
    this.v2EdiFilesService = props.v2EdiFilesService || new EdiFilesServiceV2();
    this.outboundOrderService = props.outboundOrderService || new OutboundOrdersService();
    this.inboundService = props.inboundService || new InboundServiceV2();
    this.fileStorageService = props.FileStorageService || new FileStorageService();
    this.shipperName = get(props, 'currentCompany.name') || 'Shipper';
    // OMS-5186: Filter by file_type="frito_reports"
    // TODO: Remove me when no longer wanting to default frito
    this.state =
      props.currentCompany.id === 3500 || props.currentCompany.id === 579
        ? this.ediFilesService.getListCacheForFrito()
        : this.ediFilesService.getListCache();
    this.templatePath = '/static/files/OutboundOrderBulkCreate.csv';

    this.warehouseService = new WarehouseService(props.authenticityToken);
  }

  public async componentDidMount() {
    this.loadServiceTypes();

    const initFromQueryParams = (params: object) => {
      const filters = this.extractFilters(params);
      this.loadFiles(filters);
    };

    const queryString = window.location.search;
    if (queryString && queryString.length > 0) {
      const queryParams = qs.parse(queryString);
      initFromQueryParams(queryParams);
    } else {
      this.loadFiles({});
    }
    window.onpopstate = () => initFromQueryParams(window.history.state);
  }

  public render() {
    const {files} = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          {this.state.errors && (
            <div className="alert alert-danger" role="alert">
              {this.state.errors.map((e, i) => (
                <span key={i}>{e}</span>
              ))}
            </div>
          )}

          <div className="col-xs-6 space-below-lg">
            <h2>
              <span>File Exchange</span>
              <div className="position-management">
                <a onClick={this.showModal}>
                  {' '}
                  <i className="fa fa-sm fa-question-circle" title="Watch training video on File Exchange" />
                </a>
                <DocumentationTooltip
                  showModal={this.state.show}
                  zendeskLink="https://flexesupport.zendesk.com/hc/en-us/articles/4408779095572-CSV-Uploads"
                  linkDescription="Watch training video
            on File Exchange"
                />
              </div>
            </h2>
          </div>

          <div className="col-xs-6 space-below-lg">
            {
              <a className="btn pull-right" href="/s/fulfillment/orders">
                New CSV Upload via Orders page
              </a>
            }
          </div>
          <div id="file-exchange-files" className="col-xs-12">
            {!this.state.loading && (
              <Filters
                filters={this.getFilters()}
                filterChangeHandler={(filters) => this.handleFilterChange(filters)}
              />
            )}
            {files && files.length > 0 && !this.state.loading && <Table tableData={this.getTableData()} />}
            {files && files.length < 1 && !this.state.loading && (
              <p className="space-above">No files matching the current selection.</p>
            )}
            <Loader loading={this.state.loading} />
            {this.state.showUploadModal && this.getUploadModal()}
          </div>
        </div>
      </div>
    );
  }

  private loadFiles = async (filters) => {
    const continuationToken =
      this.state.currentPage > 1 ? this.state.continuationTokens[this.state.currentPage - 2] : null;
    if ('sku' in filters) {
      await this.getInventoryBySkuWithCallback(get(filters, 'sku'), async (item) => {
        const updatedValue = {sku: item.id};
        const updatedValues = {...filters, ...updatedValue};
        const data = await this.v2EdiFilesService.getFiles(updatedValues, this.pageSize, continuationToken);
        this.updateStateFromFilesResponse(data, filters);
      });
    } else if ('inboundOrder' in filters) {
      await this.getInboundsByDetailsWithCallback(get(filters, 'inboundOrder'), async (orderData) => {
        const updatedValue = {inboundOrder: orderData.id};
        const updatedValues = {...filters, ...updatedValue};
        const data = await this.v2EdiFilesService.getFiles(updatedValues, this.pageSize, continuationToken);
        this.updateStateFromFilesResponse(data, filters);
      });
    } else if ('outboundOrder' in filters) {
      await this.getOutboundOrderIdByExternalIdWithCallback(get(filters, 'outboundOrder'), async (orderData) => {
        const updatedValue = {outboundOrder: orderData.id};
        const updatedValues = {...filters, ...updatedValue};
        const data = await this.v2EdiFilesService.getFiles(updatedValues, this.pageSize, continuationToken);
        this.updateStateFromFilesResponse(data, filters);
      });
    } else {
      const data = await this.v2EdiFilesService.getFiles(filters, this.pageSize, continuationToken);
      this.updateStateFromFilesResponse(data, filters);
    }
  };

  private extractFilters = (params) => {
    const validFilters = this.getFilters().map((f) => f.key);
    Object.keys(params).forEach((f) => {
      if (!validFilters.includes(f)) {
        delete params[f];
      } else if (f === 'toFlexe') {
        params[f] = params[f] === 'true';
      }
    });
    if (isEmpty(params)) {
      window.history.replaceState({}, '', window.location.pathname);
    } else {
      window.history.replaceState(params, '', `${window.location.pathname}?${qs.stringify(params)}`);
    }
    return params;
  };

  private getFilters = () => {
    //TODO : Remove this flag when we decide what the right nomenclature for PO is.
    //TODO: Upon decision make external id consistent across all UI
    // Until then only expose to internal FLEXE users
    const filtersList = [
      {
        displayName: 'Source',
        key: 'toFlexe',
        type: FilterType.Dropdown,
        options: [
          {displayName: 'Select file source...', value: ''},
          {displayName: 'FLEXE', value: 'FLEXE'},
          {displayName: this.shipperName, value: this.shipperName}
        ],
        value: this.getFilterValue('toFlexe')
      },
      {
        displayName: 'Sent/Received (exact)',
        key: 'createdAt',
        type: FilterType.Date,
        value: this.getFilterValue('createdAt')
      },
      {
        displayName: 'Sent/Received Start',
        key: 'createdAtFrom',
        type: FilterType.Date,
        value: this.getFilterValue('createdAtFrom')
      },
      {
        displayName: 'Sent/Received End',
        key: 'createdAtTo',
        type: FilterType.Date,
        value: this.getFilterValue('createdAtTo')
      },
      {
        displayName: 'Filename',
        key: 'fileName',
        type: FilterType.String,
        value: this.getFilterValue('fileName')
      },
      {
        displayName: 'SKU',
        key: 'sku',
        type: FilterType.String,
        value: this.getFilterValue('sku')
      },
      {
        displayName: 'Inbound Order',
        key: 'inboundOrder',
        type: FilterType.String,
        value: this.getFilterValue('inboundOrder')
      },
      {
        displayName: 'Outbound Order',
        key: 'outboundOrder',
        type: FilterType.String,
        value: this.getFilterValue('outboundOrder')
      },
      {
        displayName: 'File Type',
        key: 'fileType',
        type: FilterType.String,
        value: this.getFilterValue('fileType')
      },
      {
        displayName: 'Reference ID',
        key: 'externalIdPartial',
        type: FilterType.String,
        value: this.getFilterValue('externalIdPartial')
      },
      {
        displayName: 'Issues Only',
        key: 'hasErrors',
        type: FilterType.Present,
        value: this.getFilterValue('hasErrors')
      }
    ];
    return filtersList;
  };

  private getFilterValue(key: string) {
    const {filters} = this.state;
    if (![null, undefined].includes(filters[key])) {
      if (key === 'toFlexe') {
        return filters[key] === true ? this.shipperName : 'FLEXE';
      } else if (key === 'hasErrors') {
        return filters[key] === true ? 'true' : 'false';
      } else {
        return filters[key];
      }
    }
    return null;
  }

  private getInboundsByDetailsWithCallback = async (details, callbackFunc: (o: InboundSearchShipment) => void) => {
    await this.inboundService.search({details}).then((response) => {
      const shipments = get(response, 'shipments');
      if (shipments.length > 0) {
        callbackFunc(last(shipments));
      } else {
        this.setState({
          loading: false,
          // show no files were found
          files: [],
          continuationTokens: []
        });
      }
    });
  };

  private getOutboundOrderIdByExternalIdWithCallback = async (externalId, callbackFunc: (o: OutboundOrder) => void) => {
    await this.outboundOrderService.getOrders({externalIds: externalId}).then((response) => {
      const total = get(response, 'total');
      if (total > 0) {
        const orders = get(response, 'outboundOrders');
        callbackFunc(last(orders));
      } else {
        this.setState({
          loading: false,
          // show no files were found
          files: [],
          continuationTokens: []
        });
      }
    });
  };

  private getInventoryBySkuWithCallback = async (sku, callbackFunc: (o: SimpleItemStruct) => void) => {
    await this.inventoryService.getInventories(null, sku, null, true).then((response) => {
      const inventorySummary = get(response, 'inventory_summary');
      if (inventorySummary.length > 0) {
        const firstItem = inventorySummary.find((obj) => {
          return obj.item_code === sku;
        });
        callbackFunc(firstItem);
      } else {
        this.setState({
          loading: false,
          // show no files were found
          files: [],
          continuationTokens: []
        });
      }
    });
  };

  private updateStateFromFilesResponse(data: ApiResponse<V2EdiFilesResponse>, filters, currentPage: number = 1) {
    window.history.pushState(filters, '', `${window.location.pathname}?${qs.stringify(filters)}`);
    const contToken = get(data, 'data.continuationToken');
    const files = get(data, 'data.files');
    const totalCount = get(data, 'data.totalCount');
    this.setState(
      {
        continuationTokens: [contToken],
        totalCount,
        currentPage,
        filters,
        loading: false,
        files
      },
      () => this.ediFilesService.setListCache(this.state)
    );
  }

  private handleFilterChange = async (filters) => {
    this.setState({
      loading: true
    });
    const filterValues: any = {};
    filters.forEach((selected) => {
      if (selected.filter.key === 'hasErrors') {
        filterValues[selected.filter.key] = selected.value === 'true';
      } else if (selected.filter.key === 'toFlexe' && selected.value) {
        filterValues[selected.filter.key] = selected.value !== 'FLEXE';
      } else if (selected.filter.key === 'createdAt') {
        const localDateStart = parseDate(selected.value);
        const localDateEnd = addDays(localDateStart, 1);
        filterValues.createdAtFrom = localDateStart.toISOString();
        filterValues.createdAtTo = localDateEnd.toISOString();
      } else if (['createdAtFrom', 'createdAtTo'].includes(selected.filter.key)) {
        const localDate = parseDate(selected.value);
        // inclusive start, exclusive end so offset by 1 day
        const filterDate = selected.filter.key === 'createdAtTo' ? addDays(localDate, 1) : localDate;
        filterValues[selected.filter.key] = filterDate.toISOString();
      } else {
        filterValues[selected.filter.key] = selected.value;
      }
    });
    this.loadFiles(filterValues);
  };

  private getTableData = () => {
    const headers = this.getHeaders() as TableHeader[];
    const rows = this.state.files.map((file: V2EdiFile) => {
      const detailUrl = `/s/edi/${file.id}`;
      const row: (string | JSX.Element)[] = this.getRows(file, detailUrl);
      return row;
    });
    return {
      headers,
      rows
    };
  };

  private getRows(file: V2EdiFile, detailUrl) {
    const rowsList = [
      file.hasErrors ? <i className="fa fa-exclamation-triangle red3"></i> : null,
      EdiFilesServiceV2.fileOutgoingFromFlexe(file) ? 'FLEXE' : this.shipperName
    ];

    rowsList.push(<div className="overflow-susceptible">{file.externalId}</div>);
    rowsList.push(file.createdAt ? formatDate(file.createdAt, 'MM/DD/YY h:mma') : null);
    rowsList.push(
      <span className={`label label-default ${file.processedAt ? 'bg-successGreen' : 'bg-blue3'}`}>
        {file.processedAt ? formatDate(file.processedAt, 'MM/DD/YY h:mma') : 'Pending'}
      </span>
    );
    rowsList.push(
      <Link to={detailUrl}>
        {file.fileKey ? file.fileKey : EdiFilesServiceV2.getNameFromStorageObject(file.storageObjectName)}
      </Link>,
      this.getEdiFileDownloads(file)
    );
    rowsList.push(
      <Link className="pull-right" to={detailUrl}>
        <i className="fa fa-chevron-right"></i>
      </Link>
    );
    return rowsList;
  }

  private getHeaders() {
    const headersList = [
      {
        className: 'issue-header',
        element: <i className="fa fa-exclamation-triangle red3"></i>
      },
      {element: 'Source'},
      {element: 'Reference ID'},
      {element: 'Sent/Received (PT)'},
      {element: 'Processed Date (PT)'},
      {element: 'Filename', className: 'filename-header'},
      {element: 'Download', className: 'download-header'}
    ];

    headersList.push({
      className: 'pagination-header',
      element: (
        <Pagination
          page={this.state.currentPage}
          pageSize={this.pageSize}
          paginationHandler={(page) => this.handlePagination(page)}
          totalCount={this.state.totalCount}
        />
      )
    });
    return headersList;
  }

  private getEdiFileDownloads = (ediFile: V2EdiFile) => {
    if (ediFile.fileKey) {
      return (
        <div>
          <a
            className="btn"
            title="Edi File"
            href={`/s/edi/${ediFile.id}/contents`}
            onClick={async (e) => {
              e.preventDefault();
              await EdiFileHelpers.downloadFileByKey(this.fileStorageService, ediFile.fileKey);
            }}
          >
            <i className="fa fa-download" />
          </a>
          {ediFile.translationFileKey && (
            <a
              className="btn"
              title="Translation File"
              href={`/s/edi/${ediFile.id}/contents?fileKeyOption=translationFileKey`}
              onClick={async (e) => {
                e.preventDefault();
                await EdiFileHelpers.downloadFileByKey(this.fileStorageService, ediFile.translationFileKey);
              }}
            >
              <i className="fa fa-file-code" />
            </a>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  private handlePagination = (page: number) => {
    this.setState(
      {
        loading: true,
        currentPage: page
      },
      async () => {
        const data = await this.v2EdiFilesService.getFiles(
          this.state.filters,
          this.pageSize,
          this.state.currentPage > 1 ? this.state.continuationTokens[this.state.currentPage - 2] : null
        );
        const updatedContinuationTokens = this.state.continuationTokens.slice();
        const contToken = get(data, 'data.continuationToken');
        const files = get(data, 'data.files');
        const totalCount = get(data, 'data.totalCount');
        updatedContinuationTokens.push(contToken);
        this.setState(
          {
            continuationTokens: updatedContinuationTokens,
            files,
            totalCount,
            loading: false
          },
          () => this.ediFilesService.setListCache(this.state)
        );
      }
    );
  };

  private toggleInstructions = () => {
    this.setState({showUploadInstructions: !this.state.showUploadInstructions});
  };

  private toggleUploadModal = () => {
    this.setState({
      uploadParams: {file: {}},
      showUploadModal: !this.state.showUploadModal
    });
  };

  private showModal = () => {
    this.setState((prev) => ({
      show: !prev.show
    }));
  };

  private resetFileSelection = (e) => {
    e.target.value = null;
    this.setState({
      uploadParams: {file: {}},
      fileSelectErrors: null
    });
  };

  private handleUploadFileChange = (e) => {
    const files = e.target.files;
    let errors;
    const file = files[0];
    if (file && file.size <= maxFileSize) {
      if (file.name && file.name.match(/\.csv$/)) {
        const uploadParamsState = {...this.state.uploadParams};
        uploadParamsState.file = file;
        const rows = this.getEcommerceOrdersCsvInstructionsTableData(true).rows;
        const [requiredFields, optionalFields] = this.getRequiredAndOptionalCsvFields(rows);
        CsvUtil.validateHeaders(
          this.templatePath,
          file,
          requiredFields,
          optionalFields,
          () => {
            this.setState({uploadParams: uploadParamsState});
          },
          () => {
            this.setState({
              fileSelectErrors: <span>The selected file headers do not match the template.</span>
            });
          }
        );
      } else {
        errors = <span>The selected file is not in .csv format.</span>;
      }
    } else {
      errors = <span>Please select a .csv file less than {maxFileSizeMB}MB.</span>;
    }
    if (errors) {
      this.setState({fileSelectErrors: errors});
    }
  };

  private getUploadModal() {
    return (
      <LegacyModal
        id="ecom_csv_upload_modal"
        title="Upload Fulfillment Orders"
        show={this.state.showUploadModal}
        size="fullscreen"
        toggleModal={this.toggleUploadModal}
        footer={
          <div>
            <a onClick={this.toggleUploadModal}>Cancel</a>
            <button
              id="upload-agree"
              type="button"
              className="btn"
              onClick={this.handleUpload}
              disabled={isEmpty((this.state.uploadParams.file as File).name)}
            >
              {this.state.uploading && <i className="fa fa-spinner fa-spin no-padding"></i>}
              <span>Submit</span>
            </button>
          </div>
        }
        disableClose={false}
      >
        <div>
          {this.state.fileSelectErrors && (
            <div className="alert alert-danger" role="alert">
              {this.state.fileSelectErrors}
            </div>
          )}
          <div className="row space-below-lg">
            <label className="col-md-3">
              New import file (.csv)
              <br />
              <input
                id="file-input"
                type="file"
                accept=".csv"
                onClick={this.resetFileSelection}
                onChange={this.handleUploadFileChange}
              />
            </label>
            <div className="col-md-5">
              <a href={this.templatePath} style={{top: '10px'}}>
                <i className="fa fa-download"></i>
                &nbsp; Download example template
              </a>
            </div>
          </div>
          <div className="row">
            <Expando
              index={1}
              headerText="More info about importing via CSV"
              headerIcon={
                <span>
                  <i className="fa fa-info-circle"></i>&nbsp;
                </span>
              }
              open={this.state.showUploadInstructions}
              onClick={this.toggleInstructions}
            >
              <Table tableData={this.getEcommerceOrdersCsvInstructionsTableData()} />
            </Expando>
          </div>
        </div>
      </LegacyModal>
    );
  }

  private handleUpload = async () => {
    if (!this.state.fileSelectErrors) {
      const fileKey = `csv_upload_legacy_${v4()}`;
      await this.handleFileUpload(fileKey, {});
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  private async handleFileUpload(fileKey: string, metadata: {}) {
    let errors;
    this.setState({uploading: true});

    const fileStorageResponse = await this.fileStorageService.uploadFile(this.state.uploadParams.file, fileKey);

    if (!fileStorageResponse) {
      errors = 'Could not reach Flexe to upload file. Please try again and contact Flexe if the error persists.';
    } else if (fileStorageResponse?.data?.errors?.length > 0) {
      errors = this.ediFilesService.processErrors(fileStorageResponse.data.errors);
    } else {
      const ediFileResponse = await this.v2EdiFilesService.createEdiFile(
        this.v2EdiFilesService.getSupportedCSVUploadFileType(this.state.uploadFileType),
        fileKey,
        metadata
      );

      if (!ediFileResponse) {
        errors = 'Could not reach Flexe to upload file. Please try again and contact Flexe if the error persists.';
      } else if (ediFileResponse.errors && ediFileResponse.errors.length > 0) {
        errors = this.ediFilesService.processErrors(ediFileResponse.errors);
      } else {
        await this.loadFiles(this.state.filters);
      }
    }

    this.setState({
      uploadParams: {file: {}},
      showUploadModal: false,
      uploading: false,
      errors
    });
  }

  private getRequiredAndOptionalCsvFields = (rows: object[]): [string[], string[]] => {
    const requiredFields = [];
    const optionalFields = []; // This will include deprecated fields so validation works

    if (rows && rows.length > 0) {
      rows.forEach((row) => {
        const name = row[0].props.children.toString();
        if (row[1] === 'Yes') {
          requiredFields.push(name);
        } else {
          optionalFields.push(name);
        }
      });
    }
    return [requiredFields, optionalFields];
  };

  private getEcommerceOrdersCsvInstructionsTableData(includeDeprecated: boolean = false) {
    // This should be strongly typed, but CSV is being overhauled.
    // Casing and field order is assumed in getRequiredAndOptionalCsvFields
    const headers = [{element: 'Field'}, {element: 'Required?'}, {element: 'Description'}, {element: 'Example'}];
    const rows = [
      [
        <code>external_id</code>,
        'Yes',
        'Your own internal ID for tracking this order, such as a Purchase Order number',
        'ABCD-1234'
      ],
      [
        <code>service_type</code>,
        'No',
        'Carrier service level',
        <div>
          If carrier is specified, this column must also be specified.
          <br />
          Hover to see service level options for each of the following carriers:
          <ul>
            {Object.keys(this.state.serviceTypes).map((c, i) => {
              return (
                <li key={i}>
                  <a data-tip={''} data-for={c}>
                    {c}
                  </a>
                  <ReactTooltip id={c} className="persistentTooltip" delayHide={200} place="left" effect="solid">
                    <ul>
                      {this.state.serviceTypes[c].map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </ReactTooltip>
                </li>
              );
            })}
          </ul>
          <br />
          <br />
          To specify a carrier, independent of service level, simply use one of the following:
          {this.state.carriers.map((c, i) => {
            return (
              <span key={i}>
                <code>{c}</code>
                {i + 1 < this.state.carriers.length && <span>, </span>}
              </span>
            );
          })}
          .
        </div>
      ],
      [
        <code>carrier_billing_account_id</code>,
        'No',
        <span>
          The FLEXE-provided ID for a third party carrier billing account&nbsp; (can be found{' '}
          <a href="/s/account_settings/carrier_billing_accounts" target="_blank">
            here
          </a>
          ).
          <br />
          <br />
          If you specify this value, you must also fill out the 'service_type' fields.
        </span>,
        '85b4e415-e6ed-48be-8eec-244af5c95ec0'
      ],
      [<code>name</code>, 'Yes', 'Name of the customer/recipient', 'Jane Doe'],
      [<code>email</code>, 'No', 'Email address of the customer/recipient', 'jane.doe@example.com'],
      [<code>address_1</code>, 'Yes', 'Line 1 of recipient address', '123 Main St'],
      [<code>address_2</code>, 'No', 'Line 2 of recipient address', 'Suite 500'],
      [<code>address_3</code>, 'No', 'Line 3 of recipient address', null],
      [<code>city</code>, 'Yes', 'City of recipient address', 'Seattle'],
      [<code>state</code>, 'Yes', 'State/Province of recipient address', 'WA'],
      [<code>postal_code</code>, 'Yes', 'ZIP/Postcode of recipient address', '98104'],
      [<code>country</code>, 'Yes', 'Country of recipient address. 2-character country code (ISO 3166 alpha-2).', 'US'],
      [
        <code>phone</code>,
        'No',
        <span>
          Phone number of recipient OR your company support.
          <br />
          This column must be exactly 10 digits.
        </span>,
        '5555555555'
      ],
      [
        <code>reference_1</code>,
        'No',
        "Recipient's reference number for this order. This column must be <= 50 characters long.",
        '123456'
      ],
      [
        <code>reference_2</code>,
        'No',
        "Recipient's second reference number for this order. This column must be <= 50 characters long.",
        'ABCDEF'
      ],
      [
        <code>signature_confirmation</code>,
        'No',
        'Flag indicating whether adult signature is required upon delivery',
        <span>
          <b>Accepted values:</b>
          <br />
          <code>adult</code>
        </span>
      ],
      [
        <code>reservation_id</code>,
        'No',
        `ID of reservation you wish to ship from. If not provided, FLEXE will automatically route
          your orders based on distance and availability of inventory across your warehouses.`,
        '1'
      ],
      [<code>sku</code>, 'Yes', 'SKU to be included on this shipment', 'SKU-A'],
      [<code>quantity</code>, 'Yes', 'Number of eaches of said SKU to be included on this shipment', 2],
      [
        <code>order_labels</code>,
        'No',
        <span>
          A dictionary of custom properties represented in a csv as a sequence of key-value pairs separated by commas.
          For instance, the example shown specifies an order to ship via FLEXE's LTL workflow.
          <br />
          <br />
          Labels prefixed with "flexe_" are reserved for internal use. Please do not set labels with this prefix unless
          you have received guidance to do so.
        </span>,
        <span>
          <code>flexe_ltl_v1</code>=true,<code>flexe_ship_within_end</code>=2021-11-31T17:56
        </span>
      ],
      [
        <code>uom</code>,
        'No',
        'Optional Unit of Measure for the line. LTL supports carton and each. Parcel only supports each.' +
          'If left blank, defaults to each.',
        <span>
          <code>each</code>
        </span>
      ],
      [
        <code>shipment_notes</code>,
        'No',
        // eslint-disable-next-line max-len
        "Optional notes to to add to the shipment. These notes are only visible to users in Flexe (i.e. they won't appear on shipping labels)",
        'Miscellaneous notes or instructions for the warehouse.'
      ]
    ];

    if (includeDeprecated) {
      rows.push([
        <code>order_number</code>,
        'Deprecated',
        'This field is deprecated and should not be used. It has no effect on orders within FLEXE. ' +
          'the order_labels field should be used instead if it is necessary to associate additional ' +
          'information against an order.',
        'N/A'
      ]);

      rows.push([<code>address_4</code>, 'Deprecated', 'Line 4 of recipient address', null]);
    }

    return {
      headers,
      rows
    };
  }

  private loadServiceTypes = async () => {
    const data = await this.ediFilesService.getCarriersAndServiceTypes();
    this.setState({
      carriers: data.data.carriers,
      serviceTypes: data.data.serviceTypes
    });
  };
}

export default EdiFiles;
