import axios from 'axios';
import * as React from 'react';

interface NicknameProps {
  canEdit: boolean;
  value: string;
  reservationId: number;
  authenticityToken: string;
}

interface NicknameState {
  editErrors: string;
  editing: boolean;
  nickname: string;
  originalNickname: string;
}

const NICKNAME_MAX_LENGTH = 25;

class Nickname extends React.Component<NicknameProps, NicknameState> {
  constructor(props) {
    super(props);
    this.state = {
      editErrors: null,
      editing: false,
      nickname: this.props.value,
      originalNickname: this.props.value
    };
  }

  public render() {
    return (
      <div className="nickname">
        <strong>Nickname: </strong>
        {this.state.editing && (
          <div className="form-inline nickname-input">
            <input
              name="nickname"
              className="form-control"
              type="text"
              value={this.state.nickname || ''}
              placeholder="Enter nickname"
              maxLength={NICKNAME_MAX_LENGTH}
              onChange={(e) => this.handleChange(e)}
            />
            <div className="nickname-edit-controls">
              <a className="submit-edit" onClick={() => this.handleEdit()}>
                <i className="fa fa-check" aria-hidden="true"></i>
              </a>
              <a className="cancel-edit" onClick={() => this.toggleEdit()}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        )}
        {!this.state.editing && <span>{this.state.nickname || <i>No nickname specified</i>}</span>}
        {this.props.canEdit && !this.state.editing && (
          <a onClick={() => this.toggleEdit()}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </a>
        )}
      </div>
    );
  }

  private handleEdit() {
    axios
      .patch(`/s/reservations/${this.props.reservationId}/update_depositor_reservation_nickname`, {
        authenticity_token: this.props.authenticityToken,
        nickname: this.state.nickname,
        reservation_id: this.props.reservationId
      })
      .then((res) => {
        this.setState({
          editing: false
        });
      })
      .catch((error) => {
        this.setState({
          editErrors: error
        });
      });
  }

  private handleChange(event) {
    this.setState({
      nickname: event.target.value
    });
  }

  private toggleEdit() {
    this.setState({
      editing: !this.state.editing,
      nickname: this.state.originalNickname
    });
  }
}

export default Nickname;
