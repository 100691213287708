import {FC, useEffect} from 'react';
import * as React from 'react';
import {formatDate} from '../shared/utilities/DataFormatting';
import {BlobStorage, BlobStorageConversionProp, convertToBlobStorage} from './ConvertToBlobStorage';

interface Props {
  storageSource: BlobStorageConversionProp;
  documentIdentifier?: string;
  billOfLadingIsGenerating: boolean;
  ssccLabelsAreGenerating: boolean;
  freightPackingListIsGenerating: boolean;
  shipmentStatus: string;
  enableFreightPackingListRefresh: boolean;
  checkForSsccLabels?: (labelsHaveGenerated: boolean) => void;
  enqueueBillOfLadingGeneration: () => void;
  enqueueSsccLabelRegeneration: () => void;
  enqueueFreightPackingListRegeneration: () => void;
}

const ShipmentDocumentRow: FC<Props> = (props) => {
  const storage = convertToBlobStorage(props.storageSource, props.documentIdentifier);
  const documentNotAvailable =
    (storage.isGeneratedBol && props.billOfLadingIsGenerating) ||
    (storage.isSsccLabels && props.ssccLabelsAreGenerating) ||
    (storage.isFreightPackingList && props.freightPackingListIsGenerating) ||
    !storage.availableForDownload;

  useEffect(() => {
    if (!!props.checkForSsccLabels && storage.isSsccLabels) {
      props.checkForSsccLabels(storage.availableForDownload);
    }
  }, [storage.availableForDownload]);

  const handlePrint = () => {
    window.open(storage.secureDownloadUrl, '_blank').focus();
  };

  return (
    <tr>
      <td>
        {storage.typeTitle}
        {props.documentIdentifier && <span className="document-identifier">{props.documentIdentifier}</span>}
        {storage.availableForDownload && !!storage.fileName && (
          <p>
            <a href={storage.secureDownloadUrl}>{storage.fileName}</a>
          </p>
        )}
      </td>
      <td>
        <button
          type="button"
          disabled={documentNotAvailable}
          className="document-print"
          onClick={handlePrint}
          title={documentNotAvailable ? 'The document is not available' : ''}
        >
          <i className="fa fa-print" />
          Print
        </button>
        {storage.isGeneratedBol && (
          <div className="document-refresh">
            <em>{createGenerationMessage(storage, props.billOfLadingIsGenerating)}</em>
            {!props.billOfLadingIsGenerating && storage.availableForDownload && (
              <a onClick={props.enqueueBillOfLadingGeneration}>
                <i className="fa fa-refresh"></i>
                Refresh
              </a>
            )}
          </div>
        )}
        {storage.isSsccLabels && (
          <div className="document-refresh">
            <em>{createGenerationMessage(storage, props.ssccLabelsAreGenerating)}</em>
            {!props.ssccLabelsAreGenerating && props.shipmentStatus !== 'completed' && (
              <a onClick={props.enqueueSsccLabelRegeneration}>
                <i className="fa fa-refresh"></i>
                Refresh
              </a>
            )}
          </div>
        )}
        {storage.isFreightPackingList && (
          <div className="document-refresh">
            {props.enableFreightPackingListRefresh && (
              <em>{createGenerationMessage(storage, props.freightPackingListIsGenerating)}</em>
            )}
            {!props.freightPackingListIsGenerating &&
              props.shipmentStatus !== 'completed' &&
              props.enableFreightPackingListRefresh &&
              storage.availableForDownload && (
                <a onClick={props.enqueueFreightPackingListRegeneration}>
                  <i className="fa fa-refresh"></i>
                  Refresh
                </a>
              )}
          </div>
        )}
      </td>
    </tr>
  );
};

export default ShipmentDocumentRow;

function createGenerationMessage(storage: BlobStorage, isGenerating: boolean): string {
  let message = '';

  if (isGenerating) {
    if (storage.isGeneratedBol) {
      message = 'Generating Bill of Lading. Please refresh the page in a few moments';
    } else if (storage.isSsccLabels) {
      message = 'Generating SSCC labels. Please refresh the page in a few moments';
    } else if (storage.isFreightPackingList) {
      message = 'Generating Freight Packing List. Please refresh the page in a few moments';
    }
  } else {
    if (storage.isGeneratedBol && !storage.availableForDownload) {
      message = 'Bill of Lading will be available after the load is complete';
    } else {
      message = storage.createdAt
        ? `Last generated ${formatDate(new Date(storage.createdAt), 'MMM D, h:mm A')}`
        : undefined;
    }
  }

  return message;
}
