import {uniq} from 'lodash';
import {LocationContent} from '../../locations/LocationsService';
import {LpnSearchDetails} from '../../lpns/LpnsInterfaces';
import {Packaging, PackagingToValue} from '../../shared/CommonInterfaces';
import WarehouseService from '../../shared/services/WarehouseService';
import {ManifestLpn} from '../loads/ManifestInterfaces';
import {pluralizeUnit} from '../../shared/utilities/DataFormatting';
import {LineItem, Shipment} from './ShipmentInterfaces';

export const getQuantityAsMap = (
  lineItems: LineItem[],
  additionalParams: AdditionalParams = {useOrderedQty: false}
) => {
  const quantityMap = new Map();
  lineItems.forEach((lineItem) => {
    const packaging = additionalParams.useOrderedQty ? lineItem.ordered_packaging : lineItem.packaging;
    const quantity = additionalParams.useOrderedQty ? lineItem.ordered_quantity : lineItem.quantity;
    const baseAmount = quantityMap.get(packaging) || 0;
    quantityMap.set(packaging, baseAmount + quantity);
  });
  return quantityMap;
};

interface AdditionalParams {
  useOrderedQty: boolean;
}

export const getTotalQuantity = (shipment: Shipment, unit: Packaging, additionalParams: AdditionalParams): number => {
  let total = 0;

  shipment.line_items.forEach((lineItem) => {
    if (additionalParams.useOrderedQty) {
      total += lineItem.ordered_units_per_packaging[unit];
    } else {
      total += lineItem.units_per_packaging[unit];
    }
  });
  return total;
};

export const getTotalQuantityString = (shipment: Shipment, useAbbreviations: boolean = false): string => {
  const quantityMap = new Map();
  shipment.line_items.forEach((lineItem) => {
    const baseAmount = quantityMap.get(lineItem.packaging) || 0;
    quantityMap.set(lineItem.packaging, baseAmount + lineItem.quantity);
  });

  return getStringFromQuantityMap(quantityMap, useAbbreviations);
};

/**
 * Gets the most granular unit (e.g., of ["each", "carton"], "each" would be returned).
 */
export const getMostGranularCommonUnit = (
  items: Shipment['line_items'],
  additionalParams: AdditionalParams
): Packaging =>
  Packaging[
    PackagingToValue[
      Math.min.apply(
        null,
        items.map((item) => {
          let packaging;

          if (additionalParams.useOrderedQty) {
            packaging = item.ordered_packaging;
          } else {
            packaging = item.packaging;
          }

          return PackagingToValue[packaging];
        })
      )
    ]
  ];

export const getTotalLpnQuantity = (lpns: LpnSearchDetails[], greatestCommonUnit: Packaging): number => {
  let total = 0;
  if (lpns) {
    lpns.forEach((lpn) => {
      if (lpn && lpn.contents) {
        lpn.contents.forEach((content) => {
          if (content && content.quantityInAllUnits && content.quantityInAllUnits[greatestCommonUnit]) {
            total += content.quantityInAllUnits[greatestCommonUnit];
          }
        });
      }
    });
  }
  return total;
};

export interface TLpnContent {
  quantity: number;
  unitOfMeasure: Packaging;
}

export const lpnContentsFromManifestLpns = (lpns: ManifestLpn[]): TLpnContent[] =>
  lpns.reduce(
    (acc, cur) => [
      ...acc,
      ...cur.manifest_contents.map((_) => ({
        quantity: _.quantity,
        unitOfMeasure: _.unit_of_measure as Packaging
      }))
    ],
    [] as TLpnContent[]
  );

export const lpnContentsFromLpnSearchDetails = (lpns: LpnSearchDetails[]): TLpnContent[] =>
  lpns.reduce(
    (acc, cur) => [
      ...acc,
      ...cur.contents.map((_) => ({
        quantity: _.quantity.amount,
        unitOfMeasure: _.quantity.unit
      }))
    ],
    [] as TLpnContent[]
  );

export const getTotalLpnQuantityString = (lpns: TLpnContent[]): string => {
  const quantityMap = lpns.reduce((map, content) => {
    map.set(content.unitOfMeasure, (map.get(content.unitOfMeasure) ?? 0) + content.quantity);
    return map;
  }, new Map());

  return getStringFromQuantityMap(quantityMap);
};

export const getTotalLooseQuantity = (looseGoods: LocationContent[], greatestCommonUnit: Packaging): number => {
  let total = 0;
  if (looseGoods) {
    looseGoods.forEach((looseGood) => {
      if (looseGood.quantity.unit === greatestCommonUnit) {
        total += looseGood.quantity.amount;
      } else {
        let fraction = null;
        switch (greatestCommonUnit) {
          case Packaging.each:
            fraction = looseGood.quantity.conversions.each.amount;
            break;
          case Packaging.carton:
            fraction = looseGood.quantity.conversions.carton.amount;
            break;
          case Packaging.pallet:
            fraction = looseGood.quantity.conversions.pallet.amount;
            break;
        }
        total += parseStringFractionToFloat(fraction);
      }
    });
  }

  return total;
};

export const getTotalLooseQuantityString = (looseGoods: LocationContent[]): string => {
  const quantityMap = new Map();
  if (looseGoods) {
    looseGoods.forEach((looseGood) => {
      const baseAmount = quantityMap.get(looseGood.quantity.unit) || 0;
      quantityMap.set(looseGood.quantity.unit, baseAmount + looseGood.quantity.amount);
    });
    return getStringFromQuantityMap(quantityMap);
  }
  return '0';
};

/**
 * Given an input of a string that represents a fraction (ie. "1/2") return the
 * numerical representation of it (ie. 0.5).
 * @param fraction A string fraction. The numerator and denominator should be in base 10.
 * @returns A number representing the "same" fraction. Note as with all floats, there may be rounding issues.
 * @throws Nothing. This function swallows all exceptions (ex. a malformed fraction) and returns 0 in those cases.
 */
export const parseStringFractionToFloat = (fraction: string): number => {
  let parsedFloat = 0;
  try {
    const aStr = fraction.split('/').reduce((numerator, denominator, idx) => {
      return (parseInt(numerator, 10) / (idx === 1 ? parseInt(denominator, 10) : 1)).toString();
    });
    parsedFloat = parseFloat(aStr);
  } catch (e) {
    // Swallow error
  }
  return parsedFloat;
};

/**
 * Get a boolean Feature Flag that is targeted based on shipment reservation Id and store it to local state.
 * If the shipment is null nothing will be fetched. Useful for calling in a useEffect cb that reruns based on
 * the shipment.
 *
 * @param featureFlagKey The FF key as seen in LD.
 * @param booleanSetStateCallback The set function from a useState<boolean> function
 * @param shipment The shipment related to the Feature Flag. Used to lookup the reservation id
 * @param authenticityToken Authenticity token to create a warehouse service.
 * @param errors An array of existing errors to add to. If you would prefer only these errors, pass an empty list.
 * @param setErrorsCallback Callback to set the errors objects in state. Useful for displaying via react components.
 * @returns Promise<void>
 */
export const getBooleanFeatureFlag = async (
  featureFlagKey: string,
  booleanSetStateCallback: (newState: boolean) => void,
  shipment: Shipment,
  authenticityToken: string,
  errors: string[],
  setErrorsCallback: (newErrors: string[]) => void
): Promise<void> => {
  if (!shipment) {
    return;
  }

  const warehouseService = new WarehouseService(authenticityToken);
  const response = await warehouseService.getFeatureFlag(featureFlagKey, null, shipment.reservation.id);

  if (response.errors && response.errors.length > 0) {
    setErrorsCallback([].concat(errors).concat(response.errors));
  } else {
    booleanSetStateCallback(!!response.data.value);
  }
};

/**
 * Given a shipment, find how many unique SKUs are on it.
 * @param shipment The shipment to check
 * @returns The count of unique SKUs.
 */
export const getUniqueSkuCount = (shipment: Shipment): number => {
  const skuList = shipment.line_items.map((line) => {
    return line.sku;
  });

  return uniq(skuList).length;
};

export const getStringFromQuantityMap = (quantityMap: Map<string, number>, useAbbreviations: boolean = false) => {
  if (quantityMap.size === 0) {
    return '0';
  }

  return Array.from(
    quantityMap,
    ([packaging, quantity]) => `${quantity} ${pluralizeUnit(packaging, quantity, useAbbreviations)}`
  ).join(', ');
};
