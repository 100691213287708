import * as React from 'react';
import WorkOrdersService from '../shared/services/WorkOrdersService';
import FlexeButton from '../shared/FlexeButton';
import WorkOrderDetailTable from './WorkOrderDetailTable';
import * as WorkOrder from './WorkOrdersInterfaces';
import WorkOrdersPageHeader from './WorkOrdersPageHeader';
import AddCompletedKitsModal from './AddCompletedKitsModal';
import {WipLocationInformation} from './WorkOrdersInterfaces';

interface Props {
  workOrdersService: WorkOrdersService;
  isShipper: boolean;
  enableWorkOrders: boolean;
  workOrderId: string;
}

const WorkOrderDetail: React.FC<Props> = (props: Props) => {
  const emptyWorkOrder = {
    workOrderId: 0,
    reservationId: 0,
    shipper: {id: 0, name: ''},
    warehouse: {id: 0, name: ''},
    completeBy: '',
    completedAt: '',
    completedById: '0',
    createdAt: '',
    description: '',
    kitInventoryId: 0,
    kitSku: '',
    quantityCompleted: 0,
    quantityRequested: 0,
    status: '',
    instructions: ''
  };

  const [workOrder, setWorkOrder] = React.useState<WorkOrder.KitWorkOrderDetail>(emptyWorkOrder);
  const [isLoadingWorkOrder, setIsLoadingWorkOrder] = React.useState<boolean>(true);
  const [showAddCompletedKitsModal, setshowAddCompletedKitsModal] = React.useState<boolean>(false);
  const [isLoadingLocations, setIsLoadingLocations] = React.useState<boolean>(true);
  const [availableWipLocations, setAvailableWipLocations] = React.useState<WipLocationInformation[]>([]);

  const fetchWorkOrder = async () => {
    await loadWorkOrder(props.workOrderId);
  };

  React.useEffect(() => {
    fetchWorkOrder();
  }, []);

  React.useEffect(() => {
    if (!isLoadingWorkOrder) {
      loadAvailableLocations();
    }
  }, [workOrder]);

  const responseHasErrors = (response) => {
    return response && response.errors && response.errors.length;
  };

  const loadWorkOrder = async (woId: string) => {
    setIsLoadingWorkOrder(true);
    const response = await props.workOrdersService.getKitWorkOrderDetails(parseInt(woId, 10));
    setIsLoadingWorkOrder(false);
    if (!responseHasErrors(response)) {
      const kitWO = response.data;
      //these two are now fixed and they don't exist in the kit work order DB
      setWorkOrder(kitWO);
    }
    return response.errors;
  };

  const loadAvailableLocations = async () => {
    setIsLoadingLocations(true);
    const locs = await props.workOrdersService.getAvailableKitWIPLocations(workOrder.kitInventoryId);

    if (locs.errors.length) {
      setAvailableWipLocations([]);
    } else {
      setAvailableWipLocations(locs.data);
    }

    setIsLoadingLocations(false);
  };

  const onKitsCompletedSuccessfully = () => {
    toggleAddCompleteKitsVisibility();
    fetchWorkOrder();
  };

  const toggleAddCompleteKitsVisibility = () => {
    setshowAddCompletedKitsModal(!showAddCompletedKitsModal);
  };

  return (
    <div className="work-order-main" id="work-order-main">
      <AddCompletedKitsModal
        showModal={showAddCompletedKitsModal}
        workOrdersService={props.workOrdersService}
        workOrder={workOrder}
        onKitsCompletedSuccessfully={onKitsCompletedSuccessfully}
        toggleModal={toggleAddCompleteKitsVisibility}
        isLoading={isLoadingLocations || isLoadingWorkOrder}
        availableWIPLocations={availableWipLocations}
      />
      <WorkOrdersPageHeader
        isShipper={props.isShipper}
        kitWorkOrderDetail={workOrder}
        isDetailsHeader={true}
        enableWorkOrders={true}
        showNewWorkOrderModal={false}
        workOrdersService={props.workOrdersService}
      />

      <div className="container-fluid" id="work-order-detail-main">
        {!props.isShipper && workOrder.status === 'inProgress' && (
          <div className="work-order-count-container" data-testid="work-order-count-container">
            <p className="work-order-count-text">
              {workOrder.quantityCompleted} of {workOrder.quantityRequested} completed
            </p>
            <FlexeButton
              text={
                <span>
                  <i className="fa fa-plus" /> Add completed kits
                </span>
              }
              testid="add-completed-kits-button"
              level="primary"
              handleClick={toggleAddCompleteKitsVisibility}
            />
          </div>
        )}
        <WorkOrderDetailTable kitWorkOrderDetail={workOrder} />
      </div>
    </div>
  );
};

export default WorkOrderDetail;
