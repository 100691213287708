/** @jsxRuntime classic */
/** @jsx jsx */

import {jsx} from '@emotion/react';

import {rowStyle} from '../../styles';
import {Role, UserWithRoles} from '../../userManagementInterfaces';

interface Props {
  onRoleToggleAsync: (user: UserWithRoles, role: string, enableRole: boolean) => Promise<void>;
  possibleRoles: Role[];
  user: UserWithRoles;
}

const UserWithRoles = ({onRoleToggleAsync, possibleRoles, user}: Props) => (
  <tr css={rowStyle}>
    <td>{user.name}</td>
    {possibleRoles.map((role) => (
      <td key={role.title}>
        <input
          type="checkbox"
          checked={user.roles.includes(role.title)}
          onChange={() => onRoleToggleAsync(user, role.title, !user.roles.includes(role.title))}
          disabled={!role.canBeToggled || user.isProcessing}
        />
      </td>
    ))}
  </tr>
);

export default UserWithRoles;
