import * as React from 'react';
import {format as formatDate} from 'date-fns';
import {WarehouseTaskStep} from './WarehouseTasksInterfaces';
import {Location} from './WarehouseTasksInterfaces';

interface WarehouseTaskStepRowProps {
  taskStep: WarehouseTaskStep;
}

/**
 * Component representing one entry in the task steps table
 */
class WarehouseTaskStepRow extends React.Component<WarehouseTaskStepRowProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const taskStep = this.props.taskStep;
    return (
      <tr data-task-id={taskStep.id}>
        <td>{taskStep.id}</td>
        <td>{taskStep.performedByUserName}</td>
        <td>{this.locationLink(taskStep.sourceLocation)}</td>
        <td>{this.locationLink(taskStep.destinationLocation)}</td>
        <td>
          {taskStep.itemId && (
            <a target="__blank" href={`/wh/inventories/${taskStep.itemId}`}>
              {taskStep.sku}
            </a>
          )}
        </td>
        <td>{taskStep.uom}</td>
        <td>
          <time>{formatDate(taskStep.updatedAt, 'M/D/YY hh:mm a')}</time>
        </td>
        <td>{taskStep.status}</td>
      </tr>
    );
  }

  private locationLink(location: Location) {
    if (location === null) {
      return '';
    }

    return (
      <a target="__blank" href={`/wh/locations/${location.id}`}>
        {location.name}
      </a>
    );
  }
}

export default WarehouseTaskStepRow;
