import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Warehouse} from '../shared/CommonInterfaces';
import SpaConfigurationsIndex from './SpaConfigurationsIndex';
import SpaConfigurationsRulesService from './SpaConfigurationsRulesService';

interface Props {
  warehouse: Warehouse;
  authenticityToken: string;
  initialPageSize?: number;
  activeWarehouses: Warehouse[];
  inactiveWarehouses: Warehouse[];
  displayNewRulesTable: boolean;
}

interface State {
  selectedWarehouse?: Warehouse;
  currentPage: number;
  pageSize: number;
  continuationToken: string;
}

class SpaConfigurations extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    initialPageSize: 50
  };
  public spaConfigRulesService = new SpaConfigurationsRulesService(this.props.authenticityToken);

  constructor(props: Props) {
    super(props);
    this.state = {
      currentPage: this.props.displayNewRulesTable ? 0 : 1,
      pageSize: props.initialPageSize,
      continuationToken: null,
      selectedWarehouse: props.warehouse
    };
  }

  public render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/wh/spa_configurations"
            render={() => (
              <SpaConfigurationsIndex
                currentPage={this.state.currentPage}
                pageSize={this.state.pageSize}
                activeWarehouses={this.props.activeWarehouses}
                selectedWarehouse={this.state.selectedWarehouse}
                inactiveWarehouses={this.props.inactiveWarehouses}
                authenticityToken={this.props.authenticityToken}
                continuationToken={this.state.continuationToken}
                handleContinuationTokenChange={this.updateContinuationTokenState}
                handlePageChange={this.updateCurrentPage}
                handleWarehouseChange={this.updateSelectedWarehouse}
                spaConfigRulesService={this.spaConfigRulesService}
                handlePageSizeChange={this.updatePageSize}
                displayNewRulesTable={this.props.displayNewRulesTable}
              />
            )}
          />
        </Switch>
      </Router>
    );
  }

  private updateContinuationTokenState = (continuationToken) => {
    this.setState({continuationToken});
  };

  private updateCurrentPage = (currentPage) => {
    this.setState({currentPage});
  };

  private updateSelectedWarehouse = (selectedWarehouse) => {
    this.setState({selectedWarehouse});
  };

  private updatePageSize = (pageSize) => {
    this.setState({pageSize});
  };
}

export default SpaConfigurations;
