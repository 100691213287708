import ThemeProvider from '@mui/system/ThemeProvider';
import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import {Loader} from '@flexe/ui-components';
import {groupBy, map, min, sum} from 'lodash';
import {GridAlignment} from '@mui/x-data-grid/models/colDef/gridColDef';
import DeleteIcon from '@mui/icons-material/Delete';
import LoopIcon from '@mui/icons-material/Loop';
import {flexeMuiTheme} from '../../../shared/mui/default-mui-theme';
import {FlexeDataGrid} from '../../../shared/mui/flexe-data-grid/FlexeDataGrid';
import {LpnContentDetail, LpnDetails, LpnStatus} from '../../../lpns/LpnsInterfaces';
import {displayPackaging, renderItemLink, renderLocationLink, renderLpnLink} from '../../../../libs/helpers';
import {Location, Shipment} from '../ShipmentInterfaces';
import {ManifestLpn} from '../../loads/ManifestInterfaces';
import {ShipmentLpnConfirmRemoveModal} from './ShipmentLpnConfirmRemoveModal';
import {ShipmentLpnReplaceModal} from './ShipmentLpnReplaceModal';

interface Props {
  shipment: Shipment;
  lpns: LpnDetails[];
  manifestLpns: ManifestLpn[];
  lpnsLoading: boolean;
  isFreightTrailerLoadingEnabled: boolean;
  stagingLocation: Location;
  onLpnsUpdated: (palletCount: number) => Promise<void>;
}

export const ShipmentLpns: FC<Props> = (props: Props) => {
  const showEditLpnButton = props.shipment.status !== 'completed';
  const isManifestLpn = (lpn: string) => props.manifestLpns.some((mlpn) => mlpn.lpn_barcode === lpn);
  const [showingRemoveModal, setShowingRemoveModal] = useState<boolean>(false);
  const [showingReplaceModal, setShowingReplaceModal] = useState<boolean>(false);
  const [lpnBeingEdited, setLpnBeingEdited] = useState<LpnDetails>(null);
  const [paginationModel, setPaginationModel] = React.useState({pageSize: 10, page: 0});
  const [columns, setColumns] = useState([]);

  function renderSkuList(contents: LpnContentDetail[]) {
    if (contents.length > 1) return <div>Multi-SKU</div>;

    const item = contents[0].item;
    return (
      <div>
        {item.description}
        <br />
        {renderItemLink(item.id, item.sku, false)}
      </div>
    );
  }

  function renderContentsOrArchived(lpnDetail: LpnDetails) {
    if (lpnDetail.state === LpnStatus.archived) {
      return <div>—</div>;
    } else {
      return renderContents(lpnDetail.contents);
    }
  }

  function renderContents(contents: LpnContentDetail[]) {
    const unitGroupedContents = groupBy(contents, (content: LpnContentDetail) => content.quantity.unit as string);
    const totalQtyUnits = map(unitGroupedContents, (contentsGroup: LpnContentDetail[], unit: string) => ({
      unit,
      amount: sum(contentsGroup.map((content) => content.quantity.amount))
    }));
    return (
      <div>
        {totalQtyUnits.map((contentGroup, idx: number) => (
          <div key={idx}>
            {contentGroup.amount} {displayPackaging(contentGroup.unit, contentGroup.amount)}
            <br />
          </div>
        ))}
      </div>
    );
  }

  function renderLocationOrArchived(lpnDetail: LpnDetails) {
    if (lpnDetail.state === LpnStatus.archived) {
      return <div />;
    } else {
      return renderLocationLink(lpnDetail.location.id, lpnDetail.location.label);
    }
  }

  const renderCsvContents = (contents: LpnContentDetail[]) => {
    const skuUnitGroups = Object.values(
      groupBy(contents, (content: LpnContentDetail) => content.item.sku + content.quantity.unit)
    );
    return skuUnitGroups
      .map((group: LpnContentDetail[]) => {
        const totalQty = sum(group.map((content: LpnContentDetail) => content.quantity.amount));
        const displayablePackaging = displayPackaging(group[0].quantity.unit, totalQty);
        return `${totalQty} ${displayablePackaging} of ${group[0].item.sku}`;
      })
      .join(', ');
  };

  const lpnFilter = (value: string) => {
    return (params: GridCellParams): boolean => {
      const idMatches = params.row.lpnId.toString().startsWith(value);
      const barcodeMatches = params.row.lpnBarcode.startsWith(value);
      return idMatches || barcodeMatches;
    };
  };
  const parentLpnFilter = (value: string) => {
    return (params: GridCellParams): boolean => {
      const parentIdMatches = params.row.parentLpnId != null && params.row.parentLpnId.toString().startsWith(value);
      const parentBarcodeMatches = params.row.parentLpnBarcode != null && params.row.parentLpnBarcode.startsWith(value);
      return parentIdMatches || parentBarcodeMatches;
    };
  };
  const skusFilter = (value: string) => {
    return (params: GridCellParams): boolean => {
      return params.row.contents.some((content: LpnContentDetail) => content.item.sku.startsWith(value));
    };
  };

  const columnOptions = {
    flex: 1,
    sortable: false,
    filterable: false,
    headerAlign: 'left' as GridAlignment,
    align: 'left' as GridAlignment,
    disableColumnMenu: true
  };

  useEffect(() => {
    const cols: GridColDef[] = [
      {
        headerName: 'LPN',
        field: 'lpnBarcode',
        renderCell: (params) => renderLpnLink(params.row.lpnBarcode, false),
        getApplyQuickFilterFn: lpnFilter,
        filterable: true,
        ...columnOptions
      },
      {
        headerName: 'LPN Type',
        field: 'lpnType',
        renderCell: (params) => <div>{params.row.lpnType}</div>,
        ...columnOptions
      },
      {
        headerName: 'SKUs',
        field: 'skus',
        renderCell: (params) => renderSkuList(params.row.contents),
        getApplyQuickFilterFn: skusFilter,
        filterable: true,
        disableExport: true,
        ...columnOptions
      },
      {
        headerName: 'Parent LPN',
        field: 'parentLpnBarcode',
        renderCell: (params) =>
          params.row.parentLpnBarcode ? renderLpnLink(params.row.parentLpnBarcode, false) : <div />,
        getApplyQuickFilterFn: parentLpnFilter,
        filterable: true,
        ...columnOptions
      },
      {
        headerName: 'Contents',
        field: 'contents',
        renderCell: (params) => renderContentsOrArchived(params.row),
        valueFormatter: ({value}) => renderCsvContents(value),
        ...columnOptions
      }
    ];
    if (props.isFreightTrailerLoadingEnabled) {
      cols.push({
        headerName: 'Location',
        field: 'location',
        renderCell: (params) => renderLocationOrArchived(params.row),
        valueFormatter: ({value}) => `${value.id} - ${value.label}`,
        ...columnOptions
      });
    }
    if (showEditLpnButton) {
      cols.push({
        headerName: 'Edit Menu',
        field: 'editMenu',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          const isLoaded = isManifestLpn(params.row.lpnBarcode);
          return [
            <GridActionsCellItem
              icon={<LoopIcon />}
              showInMenu={true}
              onClick={() => {
                setLpnBeingEdited(params.row);
                setShowingReplaceModal(true);
              }}
              disabled={isLoaded}
              label={isLoaded ? 'Cannot Replace a Loaded LPN' : 'Replace LPN'}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              showInMenu={true}
              className={'dangerous-option'}
              onClick={() => {
                setLpnBeingEdited(params.row);
                setShowingRemoveModal(true);
              }}
              disabled={isLoaded}
              label={isLoaded ? 'Cannot Remove a Loaded LPN' : 'Remove LPN from Shipment'}
            />
          ];
        },
        disableExport: true,
        ...columnOptions
      });
    }

    setColumns(cols);
  }, [props.lpns]);

  const onEditModalConfirm = async (palletCount: number) => {
    setLpnBeingEdited(null);
    await props.onLpnsUpdated(palletCount);
    setShowingRemoveModal(false);
    setShowingReplaceModal(false);
  };

  const extractRowId = (lpn: LpnDetails) => `${lpn.lpnBarcode}-${lpn.lpnId}`;
  const generateExportFileName = () => {
    return `Shipment ${props.shipment.id} - LPNs Report - ${new Date().toLocaleDateString()}`;
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter debounceMs={250} />
        <div className="flexe-mui-data-grid-toolbar-center" /> {/* used to move export button to the right */}
        <GridToolbarExport
          printOptions={{disableToolbarButton: true}}
          csvOptions={{fileName: generateExportFileName()}}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <div id="shipment-details-lpns-tab" className="lpn-container">
      {props.lpnsLoading && <Loader loading={props.lpnsLoading} />}
      {!props.lpnsLoading && (
        <div>
          <ThemeProvider theme={flexeMuiTheme}>
            <FlexeDataGrid
              columns={columns}
              rows={props.lpns}
              autoHeight
              getRowId={extractRowId}
              initialState={{}}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[10, 25, 50, 100]} // > 100 is only possible in the pro version
              getRowHeight={() => 'auto'}
              slots={{toolbar: CustomToolbar}}
              disableColumnSelector
              disableRowSelectionOnClick
              disableDensitySelector
              disableVirtualization // without this, not all columns will be visible for tests
            />
          </ThemeProvider>
          <ShipmentLpnConfirmRemoveModal
            show={showingRemoveModal}
            lpn={lpnBeingEdited}
            shipment={props.shipment}
            toggleModal={() => {
              setLpnBeingEdited(null);
              setShowingRemoveModal(false);
            }}
            onConfirm={onEditModalConfirm}
            stagingLocation={props.stagingLocation}
          />
          <ShipmentLpnReplaceModal
            show={showingReplaceModal}
            lpn={lpnBeingEdited}
            shipment={props.shipment}
            toggleModal={() => {
              setLpnBeingEdited(null);
              setShowingReplaceModal(false);
            }}
            onConfirm={onEditModalConfirm}
          />
        </div>
      )}
    </div>
  );
};
