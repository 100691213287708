/** @jsxRuntime classic */
/** @jsx jsx */
import {FC, Fragment, useCallback, useMemo} from 'react';
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';

const iconStyles = css`
  color: #374057;
  font-size: 2rem;
`;

interface PaginationButtonsProps {
  count: number;
  currentPage: number;
  pageSize: number;
  setCurrentPage: (pageNumber: number) => void;
}

const PaginationButtons: FC<PaginationButtonsProps> = ({count, currentPage, pageSize, setCurrentPage}) => {
  const endIndex = useMemo(() => Math.floor(count / pageSize) + 1, [count, pageSize]);

  const isFirstPage = currentPage < 2;
  const isLastPage = currentPage >= endIndex;

  const pageBack = () => {
    if (isFirstPage) {
      return;
    }
    setCurrentPage(currentPage - 1);
  };

  const pageForward = () => {
    if (isLastPage) {
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  return (
    <div
      css={css`
        align-items: center;
        background: ${tokens.color.base.white.value};
        border: ${tokens.border.round.hairline.width.value} solid #afbaca;
        border-radius: ${tokens.border.round.radius.value};
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 8px 12px;

        > *:not(:last-child) {
          margin-right: 16px;
        }
      `}
    >
      <a onClick={pageBack} role="button">
        <i className="fa fa-angle-left" css={iconStyles} />
      </a>
      <span>
        <PaginationText
          count={count}
          pageEnd={Math.min((currentPage - 1) * pageSize + pageSize, count)}
          pageStart={count > 0 ? (currentPage - 1) * pageSize + 1 : 0}
        />
      </span>
      <a onClick={pageForward} role="button">
        <i className="fa fa-angle-right" css={iconStyles} />
      </a>
    </div>
  );
};

const PaginationText: FC<{
  count: number;
  pageEnd: number;
  pageStart: number;
}> = ({count, pageEnd, pageStart}) => (
  <Fragment>
    <strong>
      {pageStart} - {pageEnd}
    </strong>{' '}
    of {count}
  </Fragment>
);

export default PaginationButtons;
