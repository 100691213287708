import * as React from 'react';
import * as WorkOrders from './WorkOrdersInterfaces';

interface Props {
  workOrderType: string;
  reservationName: string;
  reservationId: string | number;
  kitSku: WorkOrders.KitSku;
  completeBy: string;
  quantity: number;
  notes?: string;
}

const NewWorkOrderFormPreview: React.FC<Props> = (props) => {
  const calculateTotalComponents = () => {
    let total = 0;
    props.kitSku.kitItems.forEach((item) => {
      total = total + item.count;
    });
    return total;
  };

  return (
    <div className="preview-wrapper">
      <div className="label-row row">
        <div className="col-sm-4">Type</div>
        <div className="col-sm-4">Warehouse</div>
        <div className="col-sm-4">Complete By</div>
      </div>
      <div className="value-row row">
        <div className="col-sm-4">{props.workOrderType}</div>
        <div className="col-sm-4">
          {props.reservationName}
          <br />({props.reservationId})
        </div>
        <div className="col-sm-4">{props.completeBy}</div>
      </div>
      <div className="label-row row">
        <div className="col-sm-4">Kit to Build</div>
        <div className="col-sm-4">Unique Components</div>
        <div className="col-sm-4">Total Components</div>
      </div>
      <div className="value-row row">
        <div className="col-sm-4">
          {props.kitSku.sku}
          <br />
          {props.kitSku.description}
        </div>
        <div className="col-sm-4">{props.kitSku.kitItems.length}</div>
        <div className="col-sm-4">{calculateTotalComponents()}</div>
      </div>
      <div className="label-row row">
        <div className="col-sm-12">Quantity Requested</div>
      </div>
      <div className="value-row row">
        <div className="col-sm-12">{props.quantity}</div>
      </div>
      <div className="label-row row">
        <div className="col-sm-8">Components</div>
        <div className="col-sm-4">Unit Count</div>
      </div>
      {props.kitSku.kitItems.map((item, i) => {
        return (
          <div className="value-row row" key={`${i}-${item.id}`}>
            <div className="col-sm-8">{item.sku}</div>
            <div className="col-sm-4">
              {item.count} {item.count === 1 ? 'unit' : 'units'}
            </div>
          </div>
        );
      })}
      <div data-testid="notes-header" className={props.notes ? 'label-row row' : 'label-row row do-not-render'}>
        <div className="col-sm-12">Notes and Instructions</div>
      </div>
      <div data-testid="notes-value" className={props.notes ? 'value-row row' : 'value-row row do-not-render'}>
        <div className="col-sm-12">{props.notes}</div>
      </div>
    </div>
  );
};

export default NewWorkOrderFormPreview;
