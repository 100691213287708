import * as React from 'react';
import * as moment from 'moment';
import {useEffect, useState} from 'react';
import classNames from 'classnames';
import DoWhenClickOutside from '../../shared/DoWhenClickOutside';
import BatchWavingService from '../../shared/services/BatchWavingService';
import {BatchErrorGroupingMethods, ParsedWavingAlert, WavingAlert} from '../ecommerce-batches/BatchInterfaces';
import {BATCH_ERROR_TEMPLATE_MAP} from './WaveBatchConstants';

interface Props {
  warehouseId: number;
  batchWavingService: BatchWavingService;
  reloadTrigger?: boolean; // used to trigger this component to reload
}

const AlertWavingIssues: React.FC<Props> = (props) => {
  const [active, setActive] = useState<boolean>(false);
  const [parsedAlerts, setParsedAlerts] = useState([]);
  const [unreadIds, setUnreadIds] = useState<number[]>([]);

  useEffect(() => {
    loadUnresolved();
  }, [props.warehouseId, props.reloadTrigger]);

  const loadUnresolved = async () => {
    const response = await props.batchWavingService.getUnresolvedWavingAlerts(props.warehouseId);
    if (response && (!response.errors || response.errors.length === 0)) {
      const allUnresolved = response.data.unresolvedAlerts;
      const groupedAllUnresolved = allUnresolved.reduce((result, alert) => {
        if (!result[alert.batchErrorType]) {
          result[alert.batchErrorType] = [];
        }
        result[alert.batchErrorType].push(alert);
        return result;
      }, {});

      let allParsedAlerts: ParsedWavingAlert[] = [];
      let displayableAlerts = [];

      for (const alertType in groupedAllUnresolved) {
        if (BATCH_ERROR_TEMPLATE_MAP.get(alertType)) {
          allParsedAlerts = allParsedAlerts.concat(parseAlertsForType(alertType, groupedAllUnresolved[alertType]));
          displayableAlerts = displayableAlerts.concat(groupedAllUnresolved[alertType]);
        }
      }

      allParsedAlerts.sort((first, second) => second.latestTime - first.latestTime);
      setParsedAlerts(allParsedAlerts);

      const unread = displayableAlerts.reduce((ids, alert) => {
        if (!alert.readAt) {
          ids.push(alert.id);
        }
        return ids;
      }, []);
      setUnreadIds(unread);
    } else {
      // TODO handle errors
    }
  };

  const groupAlertsBySku = (alerts: WavingAlert[]) => {
    const map = new Map<string, ParsedWavingAlert>();
    alerts.forEach((alert) => {
      const sku: string = alert.itemCode || '';
      if (!map.get(sku)) {
        map.set(sku, {
          sku,
          itemId: alert.inventoryId,
          lotCode: alert.lotCode,
          batchErrorType: alert.batchErrorType,
          alertIds: [],
          shipmentIds: [],
          waveId: alert.waveId,
          batchIds: [],
          latestTime: 0,
          totalQuantity: 0
        });
      }
      const skuRecord = map.get(sku);
      const createdAt = moment(alert.createdAt).valueOf();
      skuRecord.alertIds.push(alert.id);
      if (!skuRecord.shipmentIds.includes(alert.shipmentId)) {
        skuRecord.shipmentIds.push(alert.shipmentId);
      }
      if (!skuRecord.batchIds.includes(alert.batchId)) {
        skuRecord.batchIds.push(alert.batchId);
      }
      if (alert.quantity) {
        skuRecord.totalQuantity += alert.quantity;
      }
      if (!skuRecord.latestTime || skuRecord.latestTime < createdAt) {
        skuRecord.latestTime = createdAt;
      }
    });
    return map;
  };

  const groupAlertsByLocationIdAndLockReason = (alerts: WavingAlert[]) => {
    const map = new Map<string, ParsedWavingAlert>();
    alerts.forEach((alert) => {
      const locationIdAndLockReason: string = String(alert.locationId) + ':' + String(alert.lockReason);
      if (!map.get(locationIdAndLockReason)) {
        map.set(locationIdAndLockReason, {
          shipmentPackagingDescription: alert.shipmentPackagingDescription,
          batchErrorType: alert.batchErrorType,
          alertIds: [],
          shipmentIds: [],
          locationId: alert.locationId,
          locationLabel: alert.locationLabel,
          lockReason: alert.lockReason,
          latestTime: 0,
          totalQuantity: 0
        });
      }
      const parsedWavingAlert = map.get(locationIdAndLockReason);
      const createdAt = moment(alert.createdAt).valueOf();
      parsedWavingAlert.alertIds.push(alert.id);
      if (!parsedWavingAlert.latestTime || parsedWavingAlert.latestTime < createdAt) {
        parsedWavingAlert.latestTime = createdAt;
      }
    });
    return map;
  };

  const groupAlertsByCarton = (alerts: WavingAlert[]) => {
    const map = new Map<string, ParsedWavingAlert>();
    alerts.forEach((alert) => {
      const carton: string = alert.shipmentPackagingId || '';
      if (!map.get(carton)) {
        map.set(carton, {
          shipmentPackagingDescription: alert.shipmentPackagingDescription,
          batchErrorType: alert.batchErrorType,
          alertIds: [],
          shipmentIds: [],
          latestTime: 0,
          totalQuantity: 0
        });
      }
      const cartonRecord = map.get(carton);
      const createdAt = moment(alert.createdAt).valueOf();
      cartonRecord.alertIds.push(alert.id);
      cartonRecord.shipmentIds.push(alert.shipmentId);
      if (!cartonRecord.latestTime || cartonRecord.latestTime < createdAt) {
        cartonRecord.latestTime = createdAt;
      }
    });
    return map;
  };

  const parseAlertsForType = (alertType, alertGroup): ParsedWavingAlert[] => {
    const formattedMessages = [];
    const template = BATCH_ERROR_TEMPLATE_MAP.get(alertType);
    if (!template) {
      return;
    }
    if (template.groupErrorsBy) {
      let keyToAlertMap = new Map();
      switch (template.groupErrorsBy) {
        case BatchErrorGroupingMethods.SKU:
          keyToAlertMap = groupAlertsBySku(alertGroup);
          break;
        case BatchErrorGroupingMethods.SHIPMENT_PACKAGING:
          keyToAlertMap = groupAlertsByCarton(alertGroup);
          break;
        case BatchErrorGroupingMethods.LOCATION_ID_AND_LOCK_REASON:
          keyToAlertMap = groupAlertsByLocationIdAndLockReason(alertGroup);
          break;
      }
      keyToAlertMap.forEach((alert, key) => {
        alert.detail = template.getErrorTemplate(alert);
        formattedMessages.push(alert);
      });
    } else if (template.groupIntoOne) {
      const combinedAlert = groupSameTypeAlertsIntoOne(alertGroup);
      combinedAlert.detail = template.getErrorTemplate(combinedAlert);
      formattedMessages.push(combinedAlert);
    }
    return formattedMessages;
  };

  const groupSameTypeAlertsIntoOne = (alertGroup): ParsedWavingAlert => {
    if (!alertGroup || alertGroup.length === 0) {
      return;
    }
    const alertIds = [];
    const shipmentIds = [];
    let latestTime = 0;
    alertGroup.forEach((alert) => {
      shipmentIds.push(alert.shipmentId);
      alertIds.push(alert.id);
      latestTime = latestTime > moment(alert.createdAt).valueOf() ? latestTime : moment(alert.createdAt).valueOf();
    });
    return {
      batchErrorType: alertGroup[0].batchErrorType,
      alertIds,
      shipmentIds,
      latestTime
    };
  };

  const markAlertsAsRead = async () => {
    if (unreadIds.length > 0) {
      await props.batchWavingService.markAlertsAsRead(unreadIds);
      setUnreadIds([]);
    }
  };

  const toggleAlertList = async () => {
    if (active) {
      setActive(false);
    } else {
      setActive(true);
      markAlertsAsRead();
    }
  };

  const handleClickOutside = (_) => {
    setActive(false);
  };

  const getAllAlertIds = () => {
    return parsedAlerts.reduce((allIds, parsedAlert) => {
      allIds.push(...parsedAlert.alertIds);
      return allIds;
    }, []);
  };

  const handleClearAll = async () => {
    const allAlertIds = getAllAlertIds();
    if (allAlertIds.length > 0) {
      await props.batchWavingService.resolveAlerts(allAlertIds);
      setParsedAlerts([]);
    }
    setActive(false);
  };

  const getOnCloseHandler = (parsedAlert, index) => {
    return async () => {
      const modifiedParsedAlerts = [...parsedAlerts];
      modifiedParsedAlerts.splice(index, 1);
      setParsedAlerts(modifiedParsedAlerts);
      await props.batchWavingService.resolveAlerts(parsedAlert.alertIds);
    };
  };

  return (
    <DoWhenClickOutside callback={handleClickOutside}>
      <div id={'async-waving-alerts'}>
        <button id={'alert-button'} className={classNames('btn', {active})} onClick={toggleAlertList}>
          Alerts
          {parsedAlerts.length > 0 && (
            <span id={'alert-button-count'} className={unreadIds.length > 0 ? 'has-unread' : ''}>
              {parsedAlerts.length}
            </span>
          )}
        </button>
        {active && (
          <div id={'alert-list'}>
            <div className={'alert-list-title'}>
              Alerts
              <button className={'alert-clear-all'} onClick={handleClearAll}>
                Clear All
              </button>
            </div>
            {parsedAlerts.length > 0 && (
              <ul className={'alert-list-body'}>
                {parsedAlerts.map((parsedAlert, index) => {
                  return (
                    <AlertListItem
                      key={parsedAlert.alertIds.join(',')}
                      parsedAlert={parsedAlert}
                      onClose={getOnCloseHandler(parsedAlert, index)}
                    />
                  );
                })}
              </ul>
            )}
            {parsedAlerts.length === 0 && <p className={'no-alerts'}>There are no alerts.</p>}
          </div>
        )}
      </div>
    </DoWhenClickOutside>
  );
};

interface AlertListItemProps {
  parsedAlert: any;
  onClose();
}

const AlertListItem: React.FC<AlertListItemProps> = (props) => {
  return (
    <li data-testid={props.parsedAlert.alertIds.join(',')} className={'alert-list-item'}>
      <div className={'alert-list-item-body'}>
        <button className={'alert-list-item-clear'} onClick={props.onClose}>
          &times;
        </button>
        <p className={'alert-list-item-description'}>{props.parsedAlert.detail}</p>
      </div>
      <span className={'alert-list-item-time'}>{moment(props.parsedAlert.latestTime).fromNow()}</span>
    </li>
  );
};

export default AlertWavingIssues;
