import * as React from 'react';
import {WarehouseTask} from './WarehouseTasksInterfaces';
import WarehouseTaskRow from './WarehouseTaskRow';

interface WarehouseTasksTableProps {
  isOmniTaskFilterEnabled: boolean;
  tasksToDisplay: WarehouseTask[];
  handlePause: (warehouseID, taskIds) => void;
}

interface WarehouseTasksTableState {
  selectedTasks: Set<number>;
}

/**
 * Simple table displaying the warehouse tasks for a specific warehouse
 */
class WarehouseTasksTable extends React.Component<WarehouseTasksTableProps, WarehouseTasksTableState> {
  private allSelected: boolean;

  constructor(props) {
    super(props);

    this.allSelected = false;

    this.state = {
      selectedTasks: new Set()
    };
  }

  public componentDidUpdate(
    prevProps: Readonly<WarehouseTasksTableProps>,
    prevState: Readonly<WarehouseTasksTableState>,
    snapshot?: any
  ) {
    this.clearSelectionWhenDisplayedTasksChange(prevProps, prevState);
  }

  public render() {
    return (
      <table className="table movements">
        <thead>
          <tr>
            <td colSpan={3}>
              <a className={'btn clear-selections'} onClick={this.clearSelectedTasks}>
                Clear Selections
              </a>
              <a
                className="btn halt-tasks"
                onClick={() => {
                  this.props.handlePause(this.props.tasksToDisplay[0].warehouseId, this.state.selectedTasks);
                }}
              >
                Pause
              </a>
            </td>
          </tr>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={this.allSelected}
                onChange={this.toggleSelectAll}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            </th>
            <th>{this.props.isOmniTaskFilterEnabled ? 'Task ID' : 'Id'}</th>
            {this.props.isOmniTaskFilterEnabled && <th>Reference ID</th>}
            <th>Task Type</th>
            <th>Assigned to User</th>
            <th>Executing Device ID</th>
            <th>Priority</th>
            <th>Started At</th>
            <th>Last Updated At</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {this.props.tasksToDisplay.map((whTask) => {
            return [
              <WarehouseTaskRow
                key={`task-${whTask.id}`}
                isOmniTaskFilterEnabled={this.props.isOmniTaskFilterEnabled}
                warehouseTask={whTask}
                selectCallback={this.selectTask}
                selected={this.state.selectedTasks.has(whTask.id)}
              />
            ];
          })}
        </tbody>
      </table>
    );
  }

  private clearSelectionWhenDisplayedTasksChange(
    prevProps: Readonly<WarehouseTasksTableProps>,
    prevState: Readonly<WarehouseTasksTableState>
  ) {
    // Don't clear selections if we have not changed our displayed tasks
    if (prevProps.tasksToDisplay === this.props.tasksToDisplay) {
      return;
    }

    // If anything is currently selected - clear the selection data
    if (prevState.selectedTasks.size > 0) {
      // Always check this because setState will cause an infinite loop here
      this.setState({selectedTasks: new Set()});
    }
  }

  private toggleSelectAll = () => {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      this.state.selectedTasks.clear();
      this.props.tasksToDisplay.forEach((task) => {
        this.state.selectedTasks.add(task.id);
      });
    } else {
      this.state.selectedTasks.clear();
    }

    // Call setState to trigger render updates
    this.setState({
      selectedTasks: this.state.selectedTasks
    });
  };

  private selectTask = (taskId) => {
    if (this.state.selectedTasks.has(taskId)) {
      this.allSelected = false;
      this.state.selectedTasks.delete(taskId);
    } else {
      this.state.selectedTasks.add(taskId);
    }

    // Call setState to trigger render updates
    this.setState({
      selectedTasks: this.state.selectedTasks
    });
  };

  private clearSelectedTasks = () => {
    this.allSelected = false;
    this.setState({
      selectedTasks: new Set()
    });
  };
}

export default WarehouseTasksTable;
