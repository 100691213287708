import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import * as ReactTooltip from 'react-tooltip';
import {CarrierAccount, CarrierDetails, UpdateParams} from '../SharedInterfaces';
import {CarrierField, CarrierFieldTypesMap} from './AccountFields';

interface UpdateAccountProps {
  updateAccountParams: UpdateParams;
  account: CarrierAccount;
  carrierDefinition: CarrierDetails;
  showAccountReference: boolean;
  isProcessing: boolean;
  errors: string[];
  toggleUpdateAccountModal: () => void;
  updateAccount: () => void;
  handleUpdateAccountInfoChange: (e: any) => void;
  handleUpdateAccountChange: (e: any) => void;
}

export default function UpdateAccount({
  updateAccountParams,
  account,
  carrierDefinition,
  showAccountReference,
  isProcessing,
  errors,
  toggleUpdateAccountModal,
  updateAccount,
  handleUpdateAccountInfoChange,
  handleUpdateAccountChange
}: UpdateAccountProps) {
  const [validReference, setValidReference] = React.useState(true);
  const errorsRef = React.useRef(null);

  React.useEffect(() => {
    if (
      showAccountReference &&
      // eslint-disable-next-line no-useless-escape
      (!updateAccountParams.accountReference || /^[a-zA-Z0-9_\.\-]+$/.test(updateAccountParams.accountReference))
    ) {
      setValidReference(true);
    } else if (showAccountReference) {
      setValidReference(false);
    }
  }, [updateAccountParams.accountReference]);

  React.useEffect(() => {
    if (errors.length > 0 && errorsRef.current) {
      errorsRef.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [errors]);

  return (
    <LegacyModal
      id="edit_account_modal"
      title={`Edit Account (${account.carrierDisplayName} - ${account.accountNumber || 'NA'})`}
      show={true}
      size="small"
      toggleModal={toggleUpdateAccountModal}
      footer={
        <UpdateAccountFooter
          toggleUpdateAccountModal={toggleUpdateAccountModal}
          validReference={validReference}
          updateAccount={updateAccount}
          isProcessing={isProcessing}
        />
      }
      disableClose={true}
      hideClose={true}
    >
      <div>
        {errors && errors.length > 0 && (
          <div className="alert alert-danger popup-error" role="alert" ref={errorsRef}>
            <span>Carrier Account Update Errors Detected:</span>
            <br />
            <ul>
              {errors.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          </div>
        )}
        {showAccountReference && (
          <div className="row account-reference">
            <label data-tip data-for="account-reference-tip" htmlFor="carrier-account-reference">
              Account Reference
            </label>
            <br />
            <input
              name="accountReference"
              id="carrier-account-reference"
              onChange={handleUpdateAccountChange}
              placeholder="Example: my-fave-account.1"
              value={updateAccountParams.accountReference || ''}
              style={{background: validReference ? 'transparent' : 'red'}}
            />
            <ReactTooltip id="account-reference-tip" place="bottom">
              Use this to easily identify the account
            </ReactTooltip>
            <p>allowed characters: A-Z a-z 0-9 - _ .</p>
          </div>
        )}
        <div className="row">
          <label htmlFor="carrier-description">Description</label>
          <br />
          <textarea
            name="description"
            id="carrier-description"
            onChange={handleUpdateAccountChange}
            placeholder="Add any additional notes..."
            value={updateAccountParams.description || ''}
          />
        </div>
        <br />
        <div className="row">
          {carrierDefinition.fields.map((field) => (
            <CarrierField
              key={field.name}
              fieldName={field.name}
              fieldDisplayName={field.displayName}
              fieldValue={updateAccountParams.accountInfo[field.name]}
              fieldType={field.type}
              onChangeFunction={handleUpdateAccountInfoChange}
              disabled={!field.editable}
              optional={field.optional}
            />
          ))}
        </div>
      </div>
    </LegacyModal>
  );
}

function UpdateAccountFooter({toggleUpdateAccountModal, validReference, updateAccount, isProcessing}) {
  return (
    <div>
      <a onClick={() => toggleUpdateAccountModal()}>Cancel</a>
      <button
        id="edit-account-agree"
        type="button"
        className="btn"
        onClick={() => updateAccount()}
        disabled={!validReference || isProcessing}
      >
        {isProcessing && <i className="fa fa-spinner fa-spin no-padding"></i>}
        Update
      </button>
    </div>
  );
}
