import axios from 'axios';
import {get} from 'lodash';
import uuidv4 from 'uuid/v4';
import objectToFormData from 'object-to-formdata';
import {CompanyType, Document, ShipmentDocument} from '../CommonInterfaces';

class DocumentsService {
  private userType?: CompanyType;
  private baseUrl = '/api/v2';

  constructor(userType: CompanyType) {
    this.userType = userType;
  }

  public async getDocuments(forSide: CompanyType, reservationId: number, notableType: string, notableId: number) {
    const params = {
      notableType,
      notableId,
      reservationIdForShipperCompanyId: null,
      reservationIdForWarehouseCompanyId: null
    };
    if (this.userType === CompanyType.warehouse && forSide === CompanyType.shipper) {
      params.reservationIdForShipperCompanyId = reservationId;
    } else if (this.userType === CompanyType.shipper && forSide === CompanyType.warehouse) {
      params.reservationIdForWarehouseCompanyId = reservationId;
    }
    const response = await axios.get(`${this.baseUrl}/documents`, {params});
    const documents = get(response, 'data.data.documents', []).map((doc: Document) => {
      doc.uploader = forSide;
      return doc;
    });
    return documents;
  }

  public async saveDocument(formAction, params) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const formData = objectToFormData(params);
    const response = await axios.post(formAction, formData, config);
    return get(response, 'data.data');
  }

  public async deleteDocument(documentsRoute, docId, authenticityToken) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        authenticity_token: authenticityToken,
        meta: {correlationId: uuidv4()},
        data: {}
      }
    };
    const response = await axios.delete(`${documentsRoute}/${docId}`, config);
    return get(response, 'data.data');
  }

  /**
   * Fetch a set of shipment documents (AKA required_labels) from the server.
   * @param shipmentId Required, shipmentId of the shipment associated to the label
   * @returns A list of Shipment Documents that match the filters
   */
  public async getShipmentDocuments(shipmentId: number): Promise<ShipmentDocument[]> {
    const config = {
      params: {
        shipment_id: shipmentId
      }
    };

    const response = await axios.get(`${this.baseUrl}/shipment_documents`, config);
    return get(response, 'data.data.labels');
  }
}

export default DocumentsService;
