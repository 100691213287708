import * as React from 'react';
import {uniq} from 'lodash';
import {SelectedShipment, Shipment} from '../ShipmentInterfaces';
import SelectAll from '../shipment-list/SelectAll';
import SelectedShipmentsLabel from '../shipment-list/SelectedShipmentsLabel';
import WaveButton from './WaveButton';

interface WavingControlsProps {
  shipments: Shipment[];
  selectedShipments: SelectedShipment[];
  setShowWavingModal: (show: boolean) => void;
  setSelectedShipments: (selectedShipments: SelectedShipment[]) => void;
  isFreightTrailerLoadingEnabled: boolean;
}

const WavingControls: React.FC<WavingControlsProps> = ({
  shipments,
  selectedShipments,
  setShowWavingModal,
  setSelectedShipments,
  isFreightTrailerLoadingEnabled
}) => {
  const selectedReservationIdsCount = () => {
    return uniq(selectedShipments.map((selectedShipment) => selectedShipment.reservationId)).length;
  };
  const warnAcrossReservations = () => {
    return selectedReservationIdsCount() > 1;
  };

  const warnMultipleShipModes = () => {
    return uniq(selectedShipments.map((selectedShipment) => selectedShipment.shipMode)).length > 1;
  };

  const warnNotNewShipments = () => {
    if (selectedShipments.length === 0) {
      return false;
    }

    const selectedStatuses = uniq(selectedShipments.map((selectedShipment) => selectedShipment.status));

    if (selectedStatuses.length > 1) {
      return true;
    }

    if (!selectedStatuses.includes('new')) {
      return true;
    }
  };

  const freightConsolidationShipmentSelected = () => selectedShipments.some((s) => s.isFreightConsolidationEnabled);

  const warnMultipleDestinationTags = () => {
    if (selectedShipments.length > 1 && (isFreightTrailerLoadingEnabled || freightConsolidationShipmentSelected())) {
      const uniqueDestTags = uniq(selectedShipments.map((selectedShipment) => selectedShipment.destinationTag));
      return (
        uniqueDestTags.length > 1 ||
        (freightConsolidationShipmentSelected() &&
          (uniqueDestTags.includes(null) || uniqueDestTags.includes(undefined)))
      );
    } else {
      return false;
    }
  };

  const warnMultipleLoadGroup = () => {
    if (selectedShipments.length > 1 && (isFreightTrailerLoadingEnabled || freightConsolidationShipmentSelected())) {
      const selectedShipmentsLoadGroups = selectedShipments
        .filter((selectedShipment) => selectedShipment.freightLoadGroup)
        .map((selectedShipment) => selectedShipment.freightLoadGroup);
      const uniqueLoadGroups = uniq(selectedShipmentsLoadGroups);
      return uniqueLoadGroups.length > 1;
    } else {
      return false;
    }
  };

  const FREIGHT_CONSOLIDATE_MAX_SHIPMENTS = 100;
  const warnTooManyShipmentsForFreightConsolidate = () =>
    freightConsolidationShipmentSelected() && selectedShipments.length > FREIGHT_CONSOLIDATE_MAX_SHIPMENTS;
  const waveDisabled = () => {
    const idsCount = selectedReservationIdsCount();
    return (
      idsCount === 0 ||
      warnAcrossReservations() ||
      warnMultipleShipModes() ||
      warnNotNewShipments() ||
      warnMultipleDestinationTags() ||
      warnMultipleLoadGroup() ||
      warnTooManyShipmentsForFreightConsolidate()
    );
  };
  const warningLabel = () => {
    if (warnMultipleShipModes()) {
      return 'Waving unavailable when multiple shipment modes selected.';
    } else if (warnAcrossReservations()) {
      return 'Waving across reservations isn`t available.';
    } else if (warnNotNewShipments()) {
      return 'Only shipments that are not started may be waved.';
    } else if (warnMultipleDestinationTags()) {
      return 'Shipments with different Destination IDs must be waved separately.';
    } else if (warnMultipleLoadGroup()) {
      return 'Shipments with different Load group must be waved separately.';
    } else if (warnTooManyShipmentsForFreightConsolidate()) {
      return `Cannot wave more than ${FREIGHT_CONSOLIDATE_MAX_SHIPMENTS} Shipments.`;
    } else {
      return '';
    }
  };

  return (
    <div id="waving-controls-container" className="row vertical-align pull-left">
      <SelectAll
        shipments={shipments}
        selectedShipments={selectedShipments}
        setSelectedShipments={setSelectedShipments}
      />
      <WaveButton setShowWavingModal={setShowWavingModal} disabled={waveDisabled} />
      <SelectedShipmentsLabel
        selectedShipmentsLabel={`${selectedShipments.length} selected shipment${
          selectedShipments.length === 1 ? '' : 's'
        }`}
        warningLabel={warningLabel()}
      />
    </div>
  );
};

export default WavingControls;
