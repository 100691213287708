import * as React from 'react';
import * as moment from 'moment-timezone';
import Toggle from 'react-toggle';
import {Template} from '../SharedInterfaces';

interface TemplateListProps {
  templates: Template[];
  handleTemplateToggleActive: any;
  handlePreviewTemplate: any;
  handleDeleteTemplate: any;
}

function TemplateList(props: TemplateListProps) {
  moment.tz.setDefault();
  const templateList = props.templates.map((template, idx) => {
    const date = moment(template.createdAt, moment.ISO_8601);

    return (
      <li className="template space-below space-above" key={idx}>
        <label className="col-md-2">
          Active
          <br />
          <Toggle
            data-active={template.active}
            data-id={template.id}
            checked={template.active}
            onChange={props.handleTemplateToggleActive}
          />
        </label>
        <div className="col-xs-10">
          <h3>{template.name}</h3>
          <p>
            {'Uploaded by: '}
            {template.generic ? 'FLEXE' : template.createdBy.displayName}
            {!template.generic && <span>&mdash; {date.format('MM/DD/YY h:mma z')}</span>}
          </p>
          <p>
            <a data-id={template.id} onClick={props.handlePreviewTemplate}>
              <i className="fa fa-eye"></i>
              Preview
            </a>
            {/*
              { !template.generic &&
              <a>
                <i className="fa fa-upload"></i>
                Upload New Version
              </a>
            } */}
            <a href={template.url}>
              <i className="fa fa-download"></i>
              Download Template (.docx)
            </a>
            {!template.active && !template.generic && (
              <a data-id={template.id} onClick={props.handleDeleteTemplate}>
                <i className="fa fa-trash"></i>
                Delete
              </a>
            )}
          </p>
        </div>
      </li>
    );
  });

  return (
    <ul className="template-list">
      {templateList}
      {templateList.length < 1 && <p>No packing slip templates available for your account.</p>}
    </ul>
  );
}

export default TemplateList;
