/** @jsxRuntime classic */
/** @jsx jsx */

import {css, jsx} from '@emotion/react';
import {Button, Column, Heading, Icon, Link, Row, useTable} from '@flexe/ui-components';

import {MobileUser} from '../userManagementInterfaces';

import {rowStyle, userRowStyle} from '../styles';

interface Props {
  onDeleteMobileUserButtonPressAsync: (userId: number) => Promise<void>;
  users: MobileUser[];
}

const nameStyle = css({width: '40%'});

const noUsersCellStyle = css({textAlign: 'center'});

const MobileUsersSection = ({onDeleteMobileUserButtonPressAsync, users}: Props) => {
  const {tableStyles} = useTable({});

  const handleCreateNewMobileUserButtonPress = () => {
    window.location.href = '/user_management/new_mobile_user';
  };

  const sortAlphabetically = (unsortedUsers: MobileUser[]) => {
    return unsortedUsers.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <Column tag="section" alignmentHorizontal="start">
      <Heading level="h2">Mobile Users</Heading>
      <Link
        href="https://flexesupport.zendesk.com/hc/en-us/articles/4409790570260-Creating-New-Mobile-Users-"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn about Mobile Device Setup
      </Link>

      <Button onPress={handleCreateNewMobileUserButtonPress}>Create New Mobile Device User</Button>

      <table {...tableStyles}>
        <thead>
          <tr css={rowStyle}>
            <th css={nameStyle}>Name</th>
            <th>Username</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.length === 0 ? (
            <tr css={userRowStyle}>
              <td colSpan={3} css={noUsersCellStyle}>
                <i>No mobile device users.</i>
              </td>
            </tr>
          ) : (
            sortAlphabetically(users).map((user) => (
              <tr css={userRowStyle} key={user.id}>
                <td>{user.name}</td>
                <td>{user.userName}</td>
                <td>
                  <div
                    title={
                      !user.canBeDeleted
                        ? // eslint-disable-next-line max-len
                          'Your user permissions do not give you access to this action.\nFor further assistance please contact your account administrator.'
                        : null
                    }
                  >
                    <Button
                      isDisabled={user.isProcessing || !user.canBeDeleted}
                      onPress={() => onDeleteMobileUserButtonPressAsync(user.id)}
                    >
                      <Row margin={['0', '100']}>
                        <Icon icon="trash" />
                        <span>Delete</span>
                      </Row>
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </Column>
  );
};

export default MobileUsersSection;
