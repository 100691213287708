import * as React from 'react';
import Dropzone from 'react-dropzone';
import {MoreExplicitUploadParams} from './CommonInterfaces';

export interface Props {
  actionDescription: string;
  clearDropzoneFiles: () => void;
  handleDropzoneError: () => void;
  handleFileChange: (e: MoreExplicitUploadParams) => void;
  handleFileDrop: (droppedFiles: any) => void;
}

const CsvUploadDropzone: React.FC<Props> = (props: Props) => {
  const handleFileDrop = (droppedFiles) => {
    if (droppedFiles.length) {
      props.handleFileDrop(droppedFiles);
    } else {
      props.handleDropzoneError();
    }
  };

  return (
    <Dropzone onDrop={handleFileDrop} accept=".csv" multiple={false}>
      {({getRootProps, getInputProps}) => (
        <span
          {...getRootProps({
            className: 'dropzone'
          })}
        >
          <input {...getInputProps()} />
          <div className="title-icon-wrapper">
            <i className="fa fa-2x fa-upload title-icon-icon" />
            <div className="title-icon-text">
              <p className="title">Upload CSV</p>
              <p className="subtitle">Drag and drop or click to choose a .csv file to {props.actionDescription}</p>
            </div>
          </div>
        </span>
      )}
    </Dropzone>
  );
};

export default CsvUploadDropzone;
