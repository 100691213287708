export const getURLSearchParams = (): URLSearchParams => {
  return new URLSearchParams(window.location.search);
};

const buildURL = (params: URLSearchParams): string => `${window.location.pathname}?${params.toString()}`;

// We can't store URLSearchParams because it tries to clone the state object
interface CacheableSearchParamsData {
  key: string;
  value: string;
}
interface CacheableSearchParams {
  data: CacheableSearchParamsData[];
}
const convertToCacheableSearchParams = (urlSearchParams: URLSearchParams): CacheableSearchParams => {
  const cache: CacheableSearchParams = {data: []};
  urlSearchParams.forEach((value, key) => cache.data.push({key, value}));
  return cache;
};
export const convertToURLSearchParams = (cache: CacheableSearchParams): URLSearchParams => {
  const urlSearchParams = new URLSearchParams();
  cache.data.forEach((d) => urlSearchParams.append(d.key, d.value));
  return urlSearchParams;
};

export const replaceURLState = (newParams: URLSearchParams): void => {
  // Storing the params is a little redundant, but easiest to work with
  window.history.replaceState(convertToCacheableSearchParams(newParams), undefined, buildURL(newParams));
};

export const pushNewURLState = (newParams: URLSearchParams): void => {
  // Storing the params is a little redundant, but easiest to work with
  window.history.pushState(convertToCacheableSearchParams(newParams), undefined, buildURL(newParams));
};

export const subscribeToURLStatePop = (handler: (poppedParams: URLSearchParams) => void): void => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
  window.onpopstate = (event: PopStateEvent) => handler(convertToURLSearchParams(event.state));
};
