import * as React from 'react';
import {format as formatDate} from 'date-fns';
import {
  OrderInventoryLotControl,
  OrderLine,
  OrderLineStatus,
  OrderLineStatusDisplay
} from '../OutboundOrdersInterfaces';
import {dateFormat} from './Constants';

const ShippedStates = [OrderLineStatus.shipped, OrderLineStatus.overshipped, OrderLineStatus.partiallyShipped];

export function getOrderLineStatusDisplayValue(line: OrderLine): string {
  const baseStatus = OrderLineStatusDisplay[line.state];

  if (line.completelyShippedAt && ShippedStates.includes(line.state)) {
    const shipDate = formatDate(line.completelyShippedAt, 'MM/DD');
    return `${baseStatus} ${shipDate}`;
  }

  if (line.state === OrderLineStatus.partiallyAllocated) {
    return `${baseStatus} (${line.allocatedQuantity} of ${line.quantity})`;
  }

  return baseStatus;
}

export function getOrderLineStatusDisplayLabel(line: OrderLine, key?: string): JSX.Element {
  return (
    <div key={key} className={`label label-default label-div subtle-hover ${line.state}`}>
      {getOrderLineStatusDisplayValue(line)}
    </div>
  );
}

export const isLineCancelable = (line: OrderLine): boolean => !isLineInTerminalState(line);

export const isLineEditable = (line: OrderLine): boolean => !isLineInTerminalState(line);

// This should not be exported. Only export functions with very clear
//  use cases (i.e. check whether it's canceled or editable).
const isLineInTerminalState = (line: OrderLine): boolean => {
  return (
    !!line.completelyShippedAt ||
    [
      OrderLineStatus.partiallyCancelled,
      OrderLineStatus.cancelled,
      OrderLineStatus.shipped,
      OrderLineStatus.overshipped
    ].includes(line.state)
  );
};

export function lineHasLotInfoSpecified(line: OrderLine): boolean {
  return !!(
    line &&
    line.inventoryLotControl &&
    (line.inventoryLotControl.lotCode || line.inventoryLotControl.expirationDate)
  );
}

export function getLotExpirationDateForDisplay(lotInfo?: OrderInventoryLotControl): string | undefined {
  if (!lotInfo || !lotInfo.expirationDate) {
    return undefined;
  }

  return formatDate(lotInfo.expirationDate, dateFormat);
}

export function getHasLotForDisplay(lotInfo?: OrderInventoryLotControl): string {
  if (lotInfo && (lotInfo.lotCode || lotInfo.expirationDate)) {
    return 'Yes';
  }

  return 'No';
}
