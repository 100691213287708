import * as React from 'react';
import classNames from 'classnames';
import StatusDot from './StatusDot';

interface Props {
  key: string;
  name: string;
  value: string;
  subcategory: boolean;
  checked: boolean;
  dotStyleMap: {[key: string]: string};
  onChange: (selected: any) => void;
}

const CheckboxDropdownOption: React.FC<Props> = (props) => {
  const classes = ['checkbox-dropdown-option', props.subcategory && 'subcategory'];

  const handleClick = (e) => {
    const selected = {};
    selected[e.target.value] = e.target.checked;
    props.onChange(selected);
  };

  return (
    <div className={classNames(classes)}>
      <input
        onChange={handleClick}
        type="checkbox"
        id={props.value}
        name={props.name}
        value={props.value}
        checked={props.checked}
        data-testid={props.value}
      />
      <StatusDot status={props.dotStyleMap[props.value] as React.ComponentProps<typeof StatusDot>['status']} />
      <label htmlFor={props.value}>{props.name}</label>
    </div>
  );
};

export default CheckboxDropdownOption;
