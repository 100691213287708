import {format} from 'date-fns';
import * as React from 'react';
import PickupRequest from './PickupRequest';
import ValidatedPickupRequest from './ValidatedPickupRequest';

interface StepThreeProps {
  rawRequests: any[];
  handleConfirmedRequests: any;
  submitFailed: boolean;
  validateRequests?: (requests: any[]) => PickupRequest[];
}

interface StepThreeState {
  validatedRequests: PickupRequest[];
}

class StepThree extends React.Component<StepThreeProps, StepThreeState> {
  private static defaultProps = {
    // We pass this as a prop so it can be overridden in test, allowing us to avoid coupling tests to the clock-based
    // validation. The default implementation for non-test is provided here.
    validateRequests: (requests) => {
      return requests.map((request) => {
        return new ValidatedPickupRequest(request);
      });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      validatedRequests: props.validateRequests(props.rawRequests)
    };
  }

  public componentWillReceiveProps(props) {
    this.setState({
      validatedRequests: props.validateRequests(props.rawRequests)
    });
  }

  public render() {
    let invalidRequests = false;
    const requests = this.state.validatedRequests ? (
      this.state.validatedRequests.map((request, index) => {
        const requestValid = Object.keys(request.errors).length === 0;
        if (!requestValid && !invalidRequests) {
          invalidRequests = true;
        }
        return (
          <tr key={index} className={requestValid ? '' : 'bg-danger'}>
            <td className={request.errors.moveDate ? 'text-danger moveDate' : 'moveDate'}>
              {request.data.moveDate ? format(request.data.moveDate, 'M/D/YYYY') : request.errors.moveDate}
            </td>
            <td className={request.errors.shipmentNumber ? 'text-danger shipmentNumber' : 'shipmentNumber'}>
              {request.data.shipmentNumber || request.errors.shipmentNumber}
            </td>
            <td className={request.errors.trailerNumber ? 'text-danger trailerNumber' : 'trailerNumber'}>
              {request.data.trailerNumber || request.errors.trailerNumber}
            </td>
            <td
              className={request.errors.shipmentDescription ? 'text-danger shipmentDescription' : 'shipmentDescription'}
            >
              {request.data.shipmentDescription || request.errors.shipmentDescription}
            </td>
            <td className={request.errors.itemCode ? 'text-danger itemCode' : 'itemCode'}>
              {request.data.itemCode || request.errors.itemCode}
            </td>
            <td className={request.errors.cartonQty ? 'text-danger cartonQty' : 'cartonQty'}>
              {request.data.cartonQty || request.errors.cartonQty}
            </td>
          </tr>
        );
      })
    ) : (
      <tr></tr>
    );
    return (
      <li id="step-3" className="active">
        <span className="label">3</span>
        <div className="step-wrap">
          <h3>
            <span>Confirm Pallet Pickups</span>
          </h3>
          <p className="note">Uploaded Requests</p>
          <table
            className={
              this.state.validatedRequests && this.state.validatedRequests.length > 0
                ? 'show table table-striped'
                : 'hidden'
            }
          >
            <thead>
              <tr>
                <th>Shipment Date</th>
                <th>Shipment Number</th>
                <th>Trailer Number</th>
                <th>Shipment Description</th>
                <th>SKU</th>
                <th>Carton Qty</th>
              </tr>
            </thead>
            <tbody>{requests}</tbody>
          </table>
          <div
            id="no-data"
            className={!this.state.validatedRequests || this.state.validatedRequests.length === 0 ? 'show' : 'hidden'}
          >
            <h4>
              <i className="fa fa-times-circle text-danger"></i>
              CSV contains no valid data. Please try and re-upload.
            </h4>
          </div>
          <div id="validation-success" className={!invalidRequests && this.state.validatedRequests ? 'show' : 'hidden'}>
            <h4>
              <i className="fa fa-check-circle text-success"></i> Requests validated successfully.
            </h4>
            <p className="note">
              Note: Carton quantities are converted to pallets. If the quantity is not a multiple of the SKU's cartons
              per pallet then the number of pallets is rounded up.
            </p>
          </div>
          <div id="validation-failure" className={invalidRequests && this.state.validatedRequests ? 'show' : 'hidden'}>
            <h4>
              <i className="fa fa-times-circle text-danger"></i>
              &nbsp;Requests have errors, please fix and re-upload CSV.
            </h4>
          </div>
          <p className="note">
            <button
              type="button"
              className="btn submit-requests"
              onClick={this.handleSubmission}
              disabled={invalidRequests || this.props.submitFailed}
            >
              Submit Requests
            </button>
          </p>
        </div>
      </li>
    );
  }

  private handleSubmission = () => {
    this.props.handleConfirmedRequests(this.state.validatedRequests);
  };
}

export default StepThree;
