import * as React from 'react';
import {LineItem, Location} from '../../ShipmentInterfaces';
import UnpackService from '../../../../shared/services/UnpackService';
import {ResponseErrorV2} from '../../../../shared/CommonInterfaces';
import UnpackShipmentModal from './UnpackShipmentModal';
import UnpackCompletedModal from './UnpackCompletedModal';

export enum UnpackWorkflowState {
  'closed' = 0,
  'waitingForUser' = 10,
  'waitingOnServer' = 20,
  'completedError' = 30,
  'completedSuccess' = 40,
  'userAborted' = 50
}

interface UnpackWorkflowProps {
  authenticityToken: string;
  workflowState: UnpackWorkflowState;
  setWorkflowState: (state: UnpackWorkflowState) => void;
  shipmentId: number;
  shipmentLineItems: LineItem[];
  unpackLocation: Location;
  loadId: number;
}

export const UnpackWorkflow: React.FC<UnpackWorkflowProps> = (props) => {
  const [responseErrors, setResponseErrors] = React.useState<ResponseErrorV2[]>([]);

  const unpackService = React.useMemo<UnpackService>(() => new UnpackService(props.authenticityToken), [
    props.authenticityToken
  ]);

  const triggerUnpackAsync = async () => {
    props.setWorkflowState(UnpackWorkflowState.waitingOnServer);

    const response = await unpackService.unpackParcelShipment(props.shipmentId);

    if (response?.errors?.length > 0 || !response?.data) {
      setResponseErrors(response.errors);
      props.setWorkflowState(UnpackWorkflowState.completedError);
    } else {
      setResponseErrors([]);
      props.setWorkflowState(UnpackWorkflowState.completedSuccess);
    }
  };

  const triggerUnpack = () => {
    triggerUnpackAsync().catch(() => {
      setResponseErrors([
        {
          code: null,
          title: null,
          detail: 'Failed to connect to server.',
          source: null
        }
      ]);
      props.setWorkflowState(UnpackWorkflowState.completedError);
    });
  };

  switch (props.workflowState) {
    case UnpackWorkflowState.closed:
    case UnpackWorkflowState.userAborted:
      return null;
    case UnpackWorkflowState.waitingForUser:
    case UnpackWorkflowState.waitingOnServer:
      return (
        <UnpackShipmentModal
          {...props}
          onUnpack={triggerUnpack}
          toggleModal={() => props.setWorkflowState(UnpackWorkflowState.userAborted)}
        />
      );
    case UnpackWorkflowState.completedError:
    case UnpackWorkflowState.completedSuccess:
      return <UnpackCompletedModal {...props} errors={responseErrors} />;
  }
};

export default UnpackWorkflow;
