import * as React from 'react';
import {OrderDetail} from '../BatchInterfaces';
import FlexeContext from '../../../contexts/FlexeContext';
import ShipmentService from '../../../shared/services/ShipmentService';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const navIcon = require('../../../../../assets/images/common/navigation.svg');

interface ExternalTrackingNumberProps {
  orderDetail: OrderDetail;
  shipmentService: ShipmentService;
}

interface ExternalTrackingNumberState {
  externalTrackingNumber: string;
  externalTrackingNumberField: string;
  externalTrackingNumberDefined: boolean;
  editingExternalTrackingNumber: boolean;
  errors: string[];
}

export class ExternalTrackingNumber extends React.Component<ExternalTrackingNumberProps, ExternalTrackingNumberState> {
  public static contextType = FlexeContext;
  private orderDetail: OrderDetail;
  private shipmentService: ShipmentService;

  constructor(props) {
    super(props);
    this.orderDetail = this.props.orderDetail;
    this.shipmentService = this.props.shipmentService;
    const trackingNumberExists = this.isValidTrackingNumber(this.orderDetail.carrierAssignedId);

    this.state = {
      externalTrackingNumber: this.orderDetail.carrierAssignedId,
      externalTrackingNumberField: this.orderDetail.carrierAssignedId,
      externalTrackingNumberDefined: trackingNumberExists,
      editingExternalTrackingNumber: false,
      errors: []
    };

    //create handles for callbacks so they can be used by the external html components
    this.onEditExternalTrackingNumber = this.onEditExternalTrackingNumber.bind(this);
    this.onCancelExternalTrackingNumber = this.onCancelExternalTrackingNumber.bind(this);
    this.onAddExternalTrackingNumber = this.onAddExternalTrackingNumber.bind(this);
    this.onInputExternalTrackingNumber = this.onInputExternalTrackingNumber.bind(this);
    this.clearExternalTrackingNumberInput = this.clearExternalTrackingNumberInput.bind(this);
    this.externalTrackingChange = this.externalTrackingChange.bind(this);
  }

  public render() {
    const props: ExternalTrackingProps = {
      onSaveCallback: this.onInputExternalTrackingNumber,
      onAddCallback: this.onAddExternalTrackingNumber,
      onClearCallback: this.clearExternalTrackingNumberInput,
      onCancelCallback: this.onCancelExternalTrackingNumber,
      onInputChangedCallback: this.externalTrackingChange,
      onEditCallback: this.onEditExternalTrackingNumber,
      externalTrackingNumberField: this.state.externalTrackingNumberField,
      externalTrackingNumber: this.state.externalTrackingNumber
    };

    return (
      <div className="external-tracking-area">
        {this.state.errors.length > 0 && CompletionErrors({errors: this.state.errors})}
        {!this.state.editingExternalTrackingNumber && !this.state.externalTrackingNumberDefined && AddButton(props)}
        {!this.state.editingExternalTrackingNumber &&
          this.state.externalTrackingNumberDefined &&
          ViewableExternalTrackingNumber(props)}
        {this.state.editingExternalTrackingNumber && EditableExternalTrackingNumber(props)}
      </div>
    );
  }

  private onInputExternalTrackingNumber() {
    const input = this.state.externalTrackingNumberField;
    if (input !== this.state.externalTrackingNumber) {
      this.saveExternalTrackingNumber(input);
    } else {
      this.setState({
        editingExternalTrackingNumber: false,
        externalTrackingNumberDefined: this.isValidTrackingNumber(input)
      });
    }
  }

  private saveExternalTrackingNumber = async (externalTrackingNumber: string) => {
    const response = await this.shipmentService.saveExternalTrackingNumber(this.orderDetail.id, externalTrackingNumber);

    if (response && response.errors.length > 0) {
      const serverErrors = response.errors.map((e) => e.detail);
      this.setState({
        editingExternalTrackingNumber: false,
        errors: serverErrors
      });
    } else {
      this.setState({
        externalTrackingNumber,
        externalTrackingNumberDefined: this.isValidTrackingNumber(externalTrackingNumber),
        editingExternalTrackingNumber: false,
        errors: []
      });
      this.props.orderDetail.carrierAssignedId = externalTrackingNumber;
    }
  };

  private onCancelExternalTrackingNumber() {
    //there was a valid external tracking number before we edited and decided to cancel
    const keepPreviousValue = this.isValidTrackingNumber(this.state.externalTrackingNumber);
    this.setState({
      editingExternalTrackingNumber: false,
      externalTrackingNumberDefined: keepPreviousValue,
      externalTrackingNumberField: this.state.externalTrackingNumber
    });
  }

  private onEditExternalTrackingNumber() {
    this.setState({
      editingExternalTrackingNumber: true,
      externalTrackingNumberDefined: false
    });
  }

  private onAddExternalTrackingNumber() {
    this.setState({
      editingExternalTrackingNumber: true
    });
  }

  private readonly externalTrackingChange = (event) => {
    this.setState({
      externalTrackingNumberField: event.target.value
    });
  };

  private clearExternalTrackingNumberInput() {
    this.setState({
      externalTrackingNumberField: ''
    });
  }

  private isValidTrackingNumber(trackingNumber: string) {
    return trackingNumber !== undefined && trackingNumber !== null && trackingNumber.length > 0;
  }
}

interface ExternalTrackingProps {
  externalTrackingNumberField: string;
  externalTrackingNumber: string;
  onAddCallback: () => void;
  onInputChangedCallback: (event) => void;
  onSaveCallback: () => void;
  onCancelCallback: () => void;
  onClearCallback: () => void;
  onEditCallback: () => void;
}

function AddButton(props: ExternalTrackingProps) {
  return (
    <div>
      <button
        className="add-tracking-id"
        id="add-external-tracking-number-btn"
        onClick={() => {
          props.onAddCallback();
        }}
      >
        <img src={navIcon} alt="navIcon" /> Add Tracking ID
      </button>
    </div>
  );
}

function EditableExternalTrackingNumber(props: ExternalTrackingProps) {
  return (
    <div>
      <dl>
        <div className="row property-list-item">
          <dt className="col-xs-4 readonly-property">Tracking Number</dt>
          <dd className="col-xs-8">
            <input
              className="shipment-property-input"
              type="text"
              placeholder="Type or Scan Tracking Number"
              value={props.externalTrackingNumberField}
              onChange={props.onInputChangedCallback}
            />
            <button
              className="close-icon"
              type="reset"
              onClick={() => {
                props.onClearCallback();
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </dd>
        </div>
      </dl>
      <div>
        <button
          className="save-btn"
          id="save-external-label"
          onClick={() => {
            props.onSaveCallback();
          }}
        >
          Save
        </button>
        <button
          className="cancel-btn no-cta flat"
          id="cancel-external-label"
          onClick={() => {
            props.onCancelCallback();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

function ViewableExternalTrackingNumber(props: ExternalTrackingProps) {
  return (
    <div>
      <dl>
        <div className="property-list-item row">
          <dt className="col-xs-4 readonly-property">Tracking Number</dt>
          <dd className="col-xs-8">
            <div className="readonly-property">
              {props.externalTrackingNumber}
              <button
                className="no-cta edit"
                onClick={() => {
                  props.onEditCallback();
                }}
                id="external-tracking-number-edit"
              >
                {' '}
                Edit
              </button>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  );
}

function CompletionErrors({errors}) {
  const errorsToDisplay = errors.map((error, index) => {
    return (
      <div key={`error_${index}`} className="alert alert-danger">
        {error}
      </div>
    );
  });

  return <div>{errorsToDisplay}</div>;
}

export default ExternalTrackingNumber;
