import * as React from 'react';
import {ReservationDisplayInfos, SelectedShipment, Shipment} from '../ShipmentInterfaces';
import ShipmentTableHeader, {ColumnNames, SortState} from './ShipmentTableHeader';
import ShipmentRow from './ShipmentRow';
import ShipmentSkuModal from './ShipmentSkuModal';

interface ShipmentTableProps {
  shipments: Shipment[];
  selectedShipments: SelectedShipment[];
  setSelectedShipments: (shipmentIds: SelectedShipment[]) => void;
  disableSelectedShipments: boolean;
  isFreightTrailerLoadingEnabled: boolean;
  isFreightWorkflowEnabled: boolean;
  isOmniEnabled: boolean;
  setShowLpnContentsModal: (showModal: boolean) => void;
  setShipmentForLPN: (shipment: Shipment) => void;
  columnsToHide: ColumnNames[];
  showCarrierUnderPro: boolean;
  enableSortableHeaders: boolean;
  onSortChanged: (state: SortState) => void;
  onRemoveShipment: (shipment: Shipment) => void;
  reservationDisplayInfos?: ReservationDisplayInfos;
  isFreightLoadGroupUIEnabled: boolean;
}

const ShipmentTable: React.FC<ShipmentTableProps> = (props) => {
  const {
    isFreightTrailerLoadingEnabled,
    shipments,
    showCarrierUnderPro,
    reservationDisplayInfos,
    isFreightLoadGroupUIEnabled
  } = props;

  const [showShipmentSkuModal, setShowShipmentSkuModal] = React.useState<boolean>(false);
  const [shipmentForSku, setShipmentForSku] = React.useState<Shipment>();

  return (
    <>
      <ShipmentSkuModal
        showModal={showShipmentSkuModal}
        setShowModal={setShowShipmentSkuModal}
        shipment={shipmentForSku}
      />

      <div className="row">
        <table id="shipment-table" className="table-striped">
          <ShipmentTableHeader
            columnsToHide={props.columnsToHide}
            onSortChanged={props.onSortChanged}
            enableSortableHeaders={props.enableSortableHeaders}
            isFreightLoadGroupUIEnabled={props.isFreightLoadGroupUIEnabled}
          />
          <tbody>
            {shipments.map((shipment, idx) => (
              <ShipmentRow
                key={idx}
                shipment={shipment}
                shipmentReservationLpnEnabled={reservationDisplayInfos?.[shipment.reservation.id]?.displayLpns || false}
                selectedShipments={props.selectedShipments}
                setSelectedShipments={props.setSelectedShipments}
                disableSelectedShipments={props.disableSelectedShipments}
                isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
                isFreightWorkflowEnabled={props.isFreightWorkflowEnabled}
                isOmniEnabled={props.isOmniEnabled}
                showShipmentSkuModal={showShipmentSkuModal}
                setShowShipmentSkuModal={setShowShipmentSkuModal}
                shipmentForSku={shipmentForSku}
                setShipmentForSku={setShipmentForSku}
                setShowLpnContentsModal={props.setShowLpnContentsModal}
                setShipmentForLPN={props.setShipmentForLPN}
                columnsToHide={props.columnsToHide}
                showCarrierUnderPro={showCarrierUnderPro}
                onRemoveShipment={props.onRemoveShipment}
                isFreightLoadGroupUIEnabled={props.isFreightLoadGroupUIEnabled}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ShipmentTable;
