import * as React from 'react';
import {get} from 'lodash';
import axios from 'axios';
import RetailFulfillmentService from '../../shared/services/RetailFulfillmentService';
import {GetRetailOrderDetailsRequest, RetailOrderDetails} from '../../shared/services/RetailFulfillmentService';
import ErrorDisplay from '../../shared/ErrorDisplay';
import {CompanyType, Document, DocumentNotableType, RetailConfig} from '../../shared/CommonInterfaces';
import DocumentsService from '../../shared/services/DocumentsService';
import {DetailsHeaderPane} from './DetailsHeaderPane';
import LineItemsSection from './LineItemsSection';
import ProcessStepsSection from './ProcessStepsSection';
import OutboundStagingUpdate from './OutboundStagingUpdate';

interface RetailOrderDetailsProps {
  hasGeneratedLabels: boolean; // TODO: just pull this from RFO details API request
  isLpnEnabled: boolean;
  isSealEnabled: boolean;
  enablePurchaseOrder: boolean;
  isMobileLocationEnabled: boolean;
  retailConfig: RetailConfig;
  recommendedPalletQuantity: number;
  shipmentId: number;
  authenticityToken: string;
}

const RetailOrderDetails: React.FC<RetailOrderDetailsProps> = (props) => {
  const retailFulfillmentService = new RetailFulfillmentService(props.authenticityToken);
  const documentsService = new DocumentsService(CompanyType.warehouse);
  const documentNotableType = DocumentNotableType.retailFulfillmentOrder;

  const [orderDetails, setOrderDetails] = React.useState(null);
  const [documents, setDocuments] = React.useState([]);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState<boolean>(false);
  const [error, setError] = React.useState(null);

  async function getRetailOrderDetails() {
    const request: GetRetailOrderDetailsRequest = {
      orderId: props.shipmentId
    };

    const response = await retailFulfillmentService.getOrderDetails(request);
    if (response && response.errors.length === 0) {
      setOrderDetails(response.data);
    } else {
      let message = 'There was an error fetching the order';
      if (response) {
        const serverErrors = response.errors.map((e) => e.detail);
        message += `: ${serverErrors.join(', ')}`;
      }
      setError(message);
    }
  }

  async function loadDocuments() {
    let err;

    if (!orderDetails) {
      return; // Can't do anything until order details are loaded
    }

    try {
      const [shipperDocs, warehouseDocs] = await axios.all([
        documentsService.getDocuments(
          CompanyType.shipper,
          orderDetails.reservation.id,
          documentNotableType,
          orderDetails.id
        ),
        documentsService.getDocuments(
          CompanyType.warehouse,
          orderDetails.reservation.id,
          documentNotableType,
          orderDetails.id
        )
      ]);

      const updatedDocuments = shipperDocs.concat(warehouseDocs);
      setDocuments(updatedDocuments);
    } catch (errorResponse) {
      err = get(errorResponse, 'response.data.errors', []).map((e) => e.detail);

      if (err.length > 0) {
        err = err.join('; ');
      }
    } finally {
      onErrorHandler(err);
    }
  }

  React.useEffect(() => {
    getRetailOrderDetails();
  }, []);

  React.useEffect(() => {
    loadDocuments();
  }, [orderDetails]);

  const onDocumentSavedHandler = (document: Document) => {
    setDocuments(documents.concat(document));
  };

  const onSuccessHandler = (newSuccessMessage: string) => {
    getRetailOrderDetails();
    setSuccessMessage(newSuccessMessage);
    setShowSuccessAlert(true);
  };

  const onErrorHandler = (newError: string) => {
    setError(newError);
  };

  const successDisplay = () => {
    if (!showSuccessAlert) {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <div className="col-md-12 space-below">
        <div className="alert alert-success" role="alert">
          <div className="content">
            {successMessage}
            <button type="button" className="close" onClick={() => setShowSuccessAlert(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const errorDisplay = () => {
    if (!error) {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <div className="col-md-12 space-below">
        <ErrorDisplay errorText={error} />
      </div>
    );
  };

  return (
    <div id="retail-order-details-component">
      {successDisplay()}
      {errorDisplay()}

      {orderDetails && (
        <React.Fragment>
          <DetailsHeaderPane
            orderDetails={orderDetails}
            enablePurchaseOrder={props.enablePurchaseOrder}
            orderHasGeneratedLabels={props.hasGeneratedLabels}
            documents={documents}
            onDocumentSavedHandler={onDocumentSavedHandler}
            onErrorHandler={onErrorHandler}
          />

          <div className="container-fluid">
            <OutboundStagingUpdate
              orderDetails={orderDetails}
              isLpnEnabled={props.isLpnEnabled}
              retailFulfillmentService={retailFulfillmentService}
              authenticityToken={props.authenticityToken}
              onSuccess={onSuccessHandler}
              onError={onErrorHandler}
            />

            <ProcessStepsSection
              orderDetails={orderDetails}
              documents={documents}
              onDocumentSavedHandler={onDocumentSavedHandler}
              retailFulfillmentService={retailFulfillmentService}
              retailConfig={props.retailConfig}
              recommendedPalletQuantity={props.recommendedPalletQuantity}
              isLpnEnabled={props.isLpnEnabled}
              isMobileLocationEnabled={props.isMobileLocationEnabled}
              palletLevelSsccLabelsEnabled={props.retailConfig.palletLevelSsccLabelsEnabled}
              blockOverShipment={props.retailConfig.blockOverShipment}
              authenticityToken={props.authenticityToken}
              onSuccess={onSuccessHandler}
              onError={onErrorHandler}
            />

            <LineItemsSection
              orderDetails={orderDetails}
              palletLevelSsccLabelsEnabled={props.retailConfig.palletLevelSsccLabelsEnabled}
              isLpnEnabled={props.isLpnEnabled}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default RetailOrderDetails;
