import * as React from 'react';
import {FC} from 'react';

export enum PageSize {
  TwentyFive = 25,
  Fifty = 50,
  SeventyFive = 75,
  OneHundred = 100
}

const options = Object.values(PageSize)
  .filter((value) => !isNaN(Number(value)))
  .map((value) => {
    const valueNum = Number(value);

    return (
      <option key={valueNum} value={valueNum}>
        {valueNum}
      </option>
    );
  });

interface Props {
  label?: string;
  value: PageSize;
  onValueChange: (newValue: PageSize) => void;
}

const PageSizeInput: FC<Props> = (props) => {
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event && event.target && event.target.value) {
      const newValue = +event.target.value;
      if (PageSize[newValue] && newValue !== props.value) {
        props.onValueChange(newValue);
      }
    }
  };

  return (
    <>
      {props.label && <label htmlFor="pageSizeSelect">{props.label}</label>}
      <select id="pageSizeSelect" value={props.value} onChange={onChange} className="form-control">
        {options}
      </select>
    </>
  );
};

export default PageSizeInput;
