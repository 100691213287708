import * as React from 'react';
import {ApiResponse, Document, RetailConfig} from '../../shared/CommonInterfaces';
import RetailFulfillmentService, {
  BulkSchedulePickupRequest,
  RetailOrderDetails
} from '../../shared/services/RetailFulfillmentService';
import {UpdateShipment} from './UpdateShipment';
import {BuildPalletsStep} from './BuildPalletsStep';
import {SchedulePickup} from './SchedulePickup';

interface ProcessStepsSectionProps {
  orderDetails: RetailOrderDetails;
  retailFulfillmentService: RetailFulfillmentService;
  retailConfig: RetailConfig;
  isMobileLocationEnabled: boolean;
  isLpnEnabled: boolean;
  blockOverShipment: boolean;
  palletLevelSsccLabelsEnabled: boolean;
  authenticityToken: string;
  documents: Document[];
  onDocumentSavedHandler: (document: Document) => void;
  recommendedPalletQuantity: number;
  onSuccess: (successMessage: string) => void;
  onError: (errorMessage: string) => void;
}

export const ProcessStepsSection: React.FC<ProcessStepsSectionProps> = (props) => {
  const confirmationStep = () => {
    if (props.orderDetails.state !== 'new') {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <div className="col-sm-4">
        <div className="row step-wrap">
          <h4 className="col-sm-12">Awaiting Confirmation</h4>
          <div className="col-sm-12">
            <p>
              Please confirm that your warehouse will be able to fulfill this order, given the information listed above.
            </p>
            <button id="confirm-button" className="no-cta" onClick={() => confirmOrders()}>
              Confirm Order
            </button>
          </div>
        </div>
      </div>
    );
  };

  async function confirmOrders() {
    const orderIds: string[] = [props.orderDetails.id.toString()];
    const response: ApiResponse<any> = await props.retailFulfillmentService.confirmOrders(orderIds);

    if (response.errors) {
      // should only have 1 error message here since we're only submitting one
      // order to the endpoint
      const errorMessage: string = response.errors[0].detail;
      props.onError(errorMessage);
      return;
    }

    props.onSuccess('Successfully confirmed order!');
  }

  async function schedulePickup() {
    const orderIds: number[] = [props.orderDetails.id];
    const request: BulkSchedulePickupRequest = {
      orderIds
    };

    const response: ApiResponse<any> = await props.retailFulfillmentService.bulkSchedulePickup(request);

    if (response.errors) {
      // should only have 1 error message here since we're only submitting one
      // order to the endpoint
      const errorMessage: string = response.errors[0].detail;
      props.onError(errorMessage);
      return;
    }

    props.onSuccess('Successfully scheduled pickup!');
  }

  const buildPalletsStep = () => {
    return (
      <BuildPalletsStep
        orderDetails={props.orderDetails}
        retailFulfillmentService={props.retailFulfillmentService}
        documents={props.documents}
        recommendedPalletQuantity={props.recommendedPalletQuantity}
        onSuccess={props.onSuccess}
        onError={props.onError}
      />
    );
  };

  const schedulePickupStep = () => {
    if (props.orderDetails.state === 'confirmed' && props.retailConfig.bypassPickupDetails) {
      return bypassPickupMessage();
    } else if (props.orderDetails.state === 'confirmed' && props.retailConfig.responsibleRouter === 'warehouse') {
      return routingInformation();
    } else if (!['new', 'pickup_scheduled', 'completed'].includes(props.orderDetails.state)) {
      return awaitingPickupMessage();
    }

    return <React.Fragment></React.Fragment>;
  };

  const bypassPickupMessage = () => {
    return (
      <div className="col-sm-4">
        <div className="row step-wrap">
          <h4 className="col-sm-12">Routing &amp; Scheduling</h4>

          <p className="col-sm-12">Pickup details not required.</p>
          <div className="col-sm-12 space-below">
            <button
              id="schedule-pickups-button"
              data-testid="bypass-schedule-pickups-button"
              onClick={() => schedulePickup()}
            >
              Schedule Pickup
            </button>
          </div>
        </div>
      </div>
    );
  };

  const routingInformation = () => {
    return (
      <SchedulePickup
        orderDetails={props.orderDetails}
        retailFulfillmentService={props.retailFulfillmentService}
        onSuccess={() => props.onSuccess('Successfully scheduled pickup.')}
        onError={props.onError}
      />
    );
  };

  const awaitingPickupMessage = () => {
    return (
      <div className="col-sm-12 col-md-4">
        <div className="row step-wrap">
          <h4 className="col-sm-12">Pickup pending scheduling</h4>
          <p className="col-sm-12">Awaiting submission of scheduling details from shipper...</p>
        </div>
      </div>
    );
  };

  const terminalStates = ['completed', 'cancelled'];

  const updateShipmentStep = () => {
    if (terminalStates.includes(props.orderDetails.state)) {
      return <React.Fragment></React.Fragment>;
    }

    const completeShipmentUponUpdate =
      props.orderDetails.state === 'pickup_scheduled' &&
      (props.orderDetails.palletsBuilt > 0 || props.orderDetails.shipmentType === 'parcel');

    return (
      <UpdateShipment
        orderDetails={props.orderDetails}
        retailFulfillmentService={props.retailFulfillmentService}
        completeShipmentUponUpdate={completeShipmentUponUpdate}
        isMobileLocationEnabled={props.isMobileLocationEnabled}
        isLpnEnabled={props.isLpnEnabled}
        blockOverShipment={props.blockOverShipment}
        palletLevelSsccLabelsEnabled={props.palletLevelSsccLabelsEnabled}
        authenticityToken={props.authenticityToken}
        onSuccess={props.onSuccess}
        onError={props.onError}
      />
    );
  };

  return (
    <div id="process-steps-section" data-testid="process-steps-section" className="row process-steps">
      {confirmationStep()}
      {buildPalletsStep()}
      {schedulePickupStep()}
      {updateShipmentStep()}
    </div>
  );
};

export default ProcessStepsSection;
