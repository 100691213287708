import * as React from 'react';
import {differenceWith, intersectionWith, isEqual, uniq} from 'lodash';
import {SelectedShipment, Shipment, shipmentToSelectedShipment} from '../ShipmentInterfaces';

interface SelectAllProps {
  shipments: Shipment[];
  selectedShipments: SelectedShipment[];
  setSelectedShipments: (selectedShipments: SelectedShipment[]) => void;
}
const SelectAll: React.FC<SelectAllProps> = (props) => {
  const anyShipmentsSelectedThisPage = () => {
    return intersectionWith(props.selectedShipments, thisPageShipments(), isEqual).length > 0;
  };

  const thisPageShipments = (): SelectedShipment[] => {
    return props.shipments.map(shipmentToSelectedShipment);
  };

  const selectAllThisPage = () => {
    const updatedShipmentIds = uniq(props.selectedShipments.concat(thisPageShipments()));
    props.setSelectedShipments(updatedShipmentIds);
  };

  const deselectAllThisPage = () => {
    const updatedShipmentIds = differenceWith(props.selectedShipments, thisPageShipments(), isEqual);
    props.setSelectedShipments(updatedShipmentIds);
  };

  const handleSelectAllChange = () => {
    if (anyShipmentsSelectedThisPage()) {
      deselectAllThisPage();
    } else {
      selectAllThisPage();
    }
  };

  return (
    <form className="shipment-table-control-container">
      <input
        id="select-all-checkbox"
        className="select-all-checkbox"
        type="checkbox"
        checked={anyShipmentsSelectedThisPage()}
        onChange={handleSelectAllChange}
      />
      <label htmlFor="select-all-checkbox" className="select-all-checkbox__label">
        {anyShipmentsSelectedThisPage() ? 'Deselect All' : 'Select All'}
      </label>
    </form>
  );
};

export default SelectAll;
