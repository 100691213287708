import * as React from 'react';
import {FC} from 'react';

interface Props {
  toggleModal: () => void;
}

const BulkCreateOrdersModalButton: FC<Props> = (props) => {
  return (
    <button className="pull-right match-other-btns" onClick={props.toggleModal}>
      <i className="fa fa-file-text" />
      &nbsp; CSV Import
    </button>
  );
};
export default BulkCreateOrdersModalButton;
