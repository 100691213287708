/** @jsxRuntime classic */
/** @jsx jsx */

import {useEffect, useState} from 'react';
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import {Loader} from '@flexe/ui-components';
import Tabs from './Tabs';
import {
  MobileUser,
  MobileWarehouse,
  PageData,
  User,
  UserManagementProps,
  UserWithRoles
} from './userManagementInterfaces';
import UserManagementService from './UserManagementService';
import {generateNotification} from './notifications/notification-utility';
import {containerStyle} from './styles';
import Users from './users/Users';
import MobileUsers from './mobile-users/MobileUsers';

export enum UserManagementTabs {
  Users = 'users', // eslint-disable-line @typescript-eslint/no-shadow
  MobileUsers = 'mobile' // eslint-disable-line @typescript-eslint/no-shadow
}

const tabsContainerStyle = css({marginBottom: `${tokens.spacing.v200.value}px`});

const updateMobileUserIsProcessing = ({users, userId}: {users: MobileUser[]; userId: number}) =>
  users.map((user) =>
    user.id === userId
      ? {
          ...user,
          isProcessing: true
        }
      : user
  );

const updateMobileWarehouseUserIsProcessing = ({
  warehouses,
  userId,
  warehouseId
}: {
  warehouses: MobileWarehouse[];
  userId: number;
  warehouseId: number;
}) =>
  warehouses.map((warehouse) =>
    warehouse.id === warehouseId
      ? {
          ...warehouse,
          users: updateMobileUserIsProcessing({users: warehouse.users, userId})
        }
      : warehouse
  );

/*
  This counter will ensure that simultaneous update actions only result in a single rerender. It increments
  whenever an update is made and decrements when it returns. If it equals 0, that means all update actions
  have been completed and it is time to rerender.
*/
let updateActionsCounter = 0;

const UserManagement = ({authenticityToken, currentUserId, initialTab, notice}: UserManagementProps) => {
  const [pageData, setPageData] = useState<PageData | undefined>();
  const [activeTab, setActiveTab] = useState(
    initialTab?.toLocaleLowerCase() === UserManagementTabs.MobileUsers
      ? UserManagementTabs.MobileUsers
      : UserManagementTabs.Users
  );

  const userManagementService = new UserManagementService(authenticityToken);

  useEffect(() => {
    if (notice) {
      generateNotification({level: 'success', message: notice});
    }
  }, [notice]);

  useEffect(() => {
    loadDataAsync();
  }, []);

  useEffect(() => {
    if (activeTab === UserManagementTabs.MobileUsers) {
      return history.replaceState(null, '', `${window.location.pathname}?tab=mobile`);
    } else {
      return history.replaceState(null, '', window.location.pathname);
    }
  }, [activeTab]);

  const loadDataAsync = async () => {
    const response = await userManagementService.getPageDataAsync();
    if (response.errors?.length > 0) {
      generateNotification({level: 'error', message: response.errors[0].detail});
    } else {
      setPageData(response.data);
    }
  };

  const handleTabClick = (clickedTabKey) => {
    let newlySelectedTab: UserManagementTabs;

    switch (clickedTabKey) {
      case 'flexe-user-tab':
        newlySelectedTab = UserManagementTabs.Users;
        break;
      case 'mobile-user-tab':
        newlySelectedTab = UserManagementTabs.MobileUsers;
        break;
      default:
        newlySelectedTab = UserManagementTabs.Users;
    }

    setActiveTab(newlySelectedTab);
  };

  const handleRemoveMobileUserFromWarehouseAsync = async (userId: number, warehouseId: number) => {
    setPageData({
      ...pageData,
      mobileWarehouses: updateMobileWarehouseUserIsProcessing({
        warehouses: pageData.mobileWarehouses,
        userId,
        warehouseId
      })
    });

    updateActionsCounter++;
    const result = await userManagementService.removeMobileUserFromWarehouseAsync(userId, warehouseId);
    updateActionsCounter--;

    const error = result.errors[0];

    if (error) {
      generateNotification({level: 'error', message: error.detail});
    } else {
      const targetWarehouse = pageData.mobileWarehouses.find((warehouse) => warehouse.id === warehouseId);
      const targetUser = targetWarehouse.users.find((user) => user.id === userId);
      generateNotification({level: 'success', message: `Removed ${targetUser.userName} from ${targetWarehouse.name}`});
    }

    if (updateActionsCounter === 0) {
      await loadDataAsync();
    }
  };

  const handleDeleteMobileUserButtonPressAsync = async (userId: number) => {
    setPageData({
      ...pageData,
      mobileUsers: updateMobileUserIsProcessing({
        users: pageData.mobileUsers,
        userId
      })
    });

    updateActionsCounter++;
    const result = await userManagementService.deleteMobileUserAsync(userId);
    updateActionsCounter--;

    const error = result.errors[0];

    if (error) {
      generateNotification({level: 'error', message: error.detail});
    } else {
      const targetUser = pageData.mobileUsers.find((user) => user.id === userId);
      generateNotification({level: 'success', message: `Mobile user ${targetUser.userName} deleted`});
    }

    if (updateActionsCounter === 0) {
      await loadDataAsync();
    }
  };

  const handleWarehouseRoleToggleAsync = async (
    user: UserWithRoles,
    warehouseId: number,
    role: string,
    enableRole: boolean
  ) => {
    setPageData({
      ...pageData,
      warehouses: pageData.warehouses.map((warehouse) =>
        warehouse.id === warehouseId
          ? {
              ...warehouse,
              users: warehouse.users.map((warehouseUser) =>
                warehouseUser.id === user.id ? {...user, isProcessing: true} : warehouseUser
              )
            }
          : warehouse
      )
    });

    updateActionsCounter++;
    const result = await userManagementService.updateWarehouseUserRoleAsync(user.id, warehouseId, role, enableRole);
    updateActionsCounter--;

    const updateSucceeded = result.errors?.length === 0;

    if (!updateSucceeded) {
      // TODO: PORT-395 Display localized error returned from controller (it doesn't return one yet)
      generateNotification({level: 'error', message: 'An error occurred, please try again later.'});
    }

    if (updateActionsCounter === 0) {
      await loadDataAsync();
    }
  };

  const handleReservationRoleToggleAsync = async (
    user: UserWithRoles,
    reservationId: number,
    role: string,
    enableRole: boolean
  ) => {
    setPageData({
      ...pageData,
      reservations: pageData.reservations.map((reservation) =>
        reservation.id === reservationId
          ? {
              ...reservation,
              users: reservation.users.map((reservationUser) =>
                reservationUser.id === user.id ? {...user, isProcessing: true} : reservationUser
              )
            }
          : reservation
      )
    });

    updateActionsCounter++;
    const result = await userManagementService.updateReservationUserRoleAsync(user.id, reservationId, role, enableRole);
    updateActionsCounter--;

    const updateSucceeded = result.errors?.length === 0;

    if (!updateSucceeded) {
      // TODO: PORT-395 Display localized error returned from controller (it doesn't return one yet)
      generateNotification({level: 'error', message: 'An error occurred, please try again later.'});
    }

    if (updateActionsCounter === 0) {
      await loadDataAsync();
    }
  };

  const handleDeactivateAsync = async (user: User) => {
    setPageData({
      ...pageData,
      activeUsers: pageData.activeUsers.map((currentUser) =>
        currentUser.id === user.id ? {...user, isProcessing: true} : currentUser
      )
    });

    updateActionsCounter++;
    const result = await userManagementService.deactivateUserAsync(user.id);
    updateActionsCounter--;

    const error = result.errors[0];

    if (error) {
      generateNotification({level: 'error', message: error.detail});
      return;
    }

    generateNotification({level: 'success', message: `${user.name} was successfully deactivated!`});

    if (updateActionsCounter === 0) {
      await loadDataAsync();
    }
  };

  const handleReactivateCorrespondenceOnlyUserAsync = async (user: User) => {
    setPageData({
      ...pageData,
      correspondenceOnlyUsers: pageData.correspondenceOnlyUsers.map((currentUser) =>
        currentUser.id === user.id ? {...user, isProcessing: true} : currentUser
      )
    });
    await reactivateUserCoreAsync(user);
  };

  const handleReactivateInactiveUserAsync = async (user: User) => {
    setPageData({
      ...pageData,
      inactiveUsers: pageData.inactiveUsers.map((currentUser) =>
        currentUser.id === user.id ? {...user, isProcessing: true} : currentUser
      )
    });
    await reactivateUserCoreAsync(user);
  };

  const handleActiveUserCompanyLevelRoleToggleAsync = async (user: User, role: string, enableRole: boolean) => {
    setPageData({
      ...pageData,
      activeUsers: pageData.activeUsers.map((activeUser) =>
        activeUser.id === user.id ? {...user, isProcessing: true} : activeUser
      )
    });

    updateActionsCounter++;
    const result = await userManagementService.updateCompanyUserRoleAsync(user.id, role, enableRole);
    updateActionsCounter--;

    const updateSucceeded = result.errors?.length === 0;

    if (!updateSucceeded) {
      generateNotification({level: 'error', message: 'An error occurred, please try again later.'});
    }

    if (updateActionsCounter === 0) {
      await loadDataAsync();
    }
  };

  const reactivateUserCoreAsync = async (user: User) => {
    updateActionsCounter++;
    const result = await userManagementService.reactivateUserAsync(user.id);
    updateActionsCounter--;

    const error = result.errors[0];

    if (error) {
      generateNotification({level: 'error', message: error.detail});
      return;
    }

    generateNotification({level: 'success', message: `${user.name} was successfully re-activated!`});

    if (updateActionsCounter === 0) {
      await loadDataAsync();
    }
  };

  return !pageData ? (
    <Loader loading />
  ) : (
    <div css={containerStyle}>
      {pageData.isMobileEnabled && (
        <div css={tabsContainerStyle}>
          {/* TODO: PORT-347 Replace this temporary Tabs component with the ui-components version once it's created */}
          <Tabs
            tabs={[
              {
                isActive: activeTab === UserManagementTabs.Users,
                key: 'flexe-user-tab',
                title: 'Flexe.com users'
              },
              {
                isActive: activeTab === UserManagementTabs.MobileUsers,
                key: 'mobile-user-tab',
                title: 'Mobile device users'
              }
            ]}
            onTabClick={handleTabClick}
          />
        </div>
      )}
      <Users
        activeUsers={pageData.activeUsers.map((user) => ({...user, isActive: true}))}
        adminUserNames={pageData.adminUserNames}
        allReservationRoles={pageData.allReservationRoles}
        allWarehouseRoles={pageData.allWarehouseRoles}
        authenticityToken={authenticityToken}
        companyName={pageData.companyName}
        correspondenceOnlyUsers={pageData.correspondenceOnlyUsers.map((user) => ({
          ...user,
          isActive: false
        }))}
        currentUserId={currentUserId}
        inactiveUsers={pageData.inactiveUsers.map((user) => ({...user, isActive: false}))}
        isMobileEnabled={pageData.isMobileEnabled}
        isVisible={activeTab === UserManagementTabs.Users}
        reservations={pageData.reservations}
        allCompanyRoles={pageData.allCompanyRoles || []}
        userManagementService={userManagementService}
        warehouses={pageData.warehouses}
        onWarehouseRoleToggleAsync={handleWarehouseRoleToggleAsync}
        onReservationRoleToggleAsync={handleReservationRoleToggleAsync}
        onDeactivateAsync={handleDeactivateAsync}
        onReactivateCorrespondenceOnlyUserAsync={handleReactivateCorrespondenceOnlyUserAsync}
        onReactivateInactiveUserAsync={handleReactivateInactiveUserAsync}
        onRoleToggleActiveUserAsync={handleActiveUserCompanyLevelRoleToggleAsync}
      />
      <MobileUsers
        authenticityToken={authenticityToken}
        isVisible={activeTab === UserManagementTabs.MobileUsers}
        users={pageData.mobileUsers}
        warehouses={pageData.mobileWarehouses}
        onDeleteMobileUserButtonPressAsync={handleDeleteMobileUserButtonPressAsync}
        onRemoveMobileUserFromWarehouseAsync={handleRemoveMobileUserFromWarehouseAsync}
      />
    </div>
  );
};

export default UserManagement;
