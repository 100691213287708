import * as React from 'react';
import {useEffect, useState} from 'react';
import {Icon, LegacyModal} from '@flexe/ui-components';
import {flatten, uniq} from 'lodash';
import FlexeContext from '../../../contexts/FlexeContext';
import {Location, SelectedShipment, shipmentToSelectedShipment} from '../ShipmentInterfaces';
import LocationsService from '../../../locations/LocationsService';
import StagingLocationTypeAhead from '../../staging-location/StagingLocationTypeAhead';
import {FreightShipMode} from '../../../shared/constants';
import PickConsolidationService from '../../../shared/services/PickConsolidationService';

export interface OnWaveClickOptions {
  stagingLocation: Location | null;
  batchCount: number | null;
}

interface WavingModalProps {
  shouldFilterStagingLocations: boolean;
  onClose: () => void;
  onWaveClickAsync: (options: OnWaveClickOptions) => Promise<void>;
  pickConsolidationService?: PickConsolidationService;
  selectedShipments: SelectedShipment[];
  show: boolean;
  warehouseId: number;
  isSfsEnabled: boolean;
  isFreightTrailerLoadingEnabled: boolean;
}

const WavingModal: React.FC<WavingModalProps> = ({
  onClose,
  onWaveClickAsync,
  pickConsolidationService,
  selectedShipments,
  shouldFilterStagingLocations,
  show,
  warehouseId,
  isSfsEnabled,
  isFreightTrailerLoadingEnabled
}) => {
  const {authenticityToken} = React.useContext(FlexeContext);
  const locationsService = new LocationsService(authenticityToken);
  const [batchCount, setBatchCount] = useState<number | string>(1);
  const [stagingLocation, setStagingLocation] = useState<Location>(null);
  const [stagingLocationInputString, setStagingLocationInputString] = useState('');
  const [isWaveRequestPending, setIsWaveRequestPending] = useState(false);

  const shouldShowStagingLocation = () => {
    const isSingleShipment = selectedShipments.length === 1;

    return (
      isFreightTrailerLoadingEnabled ||
      (isSfsEnabled && allFreight(selectedShipments) && (isSingleShipment || allSameDestination(selectedShipments)))
    );
  };

  const enableBatchCount = isSfsEnabled && shouldEnableBatchCountInput(selectedShipments);

  const isStagingLocationInputInvalid =
    shouldShowStagingLocation() &&
    stagingLocationInputString.trim() !== '' &&
    stagingLocation?.name !== stagingLocationInputString.trim();

  useEffect(() => {
    if (!show) {
      setBatchCount(1);
      setStagingLocation(null);
      setStagingLocationInputString('');
      setIsWaveRequestPending(false);
    }
  }, [show]);

  const handleStagingLocationSelect = (locationId: string, locationLabel: string) => {
    if (locationId) {
      setStagingLocation({id: parseInt(locationId, 10), name: locationLabel});
      setStagingLocationInputString(locationLabel);
    } else {
      setStagingLocation(null);
    }
  };

  const handleTypingInput = (locationString: string) => {
    setStagingLocationInputString(locationString);
  };

  const handleBatchCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value, min, max} = event.target;
    let newValue: number | string = '';

    if (value) {
      newValue = Math.max(Number(min), Math.min(Number(max), Number(value)));
    }

    setBatchCount(newValue);
  };

  const handleBatchCountBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const {value, min} = event.target;

    // If the user has emptied out the batchCount value, set it back to the min when the input loses focus
    if (!value) {
      setBatchCount(Number(min));
    }
  };

  const handleWaveShipmentsClick = async () => {
    setIsWaveRequestPending(true);
    await onWaveClickAsync({stagingLocation, batchCount: enableBatchCount ? Number(batchCount) : null});
  };

  const shipmentContents = enableBatchCount ? getShipmentContents(selectedShipments) : null;

  const shipmentNoun = `Shipment${selectedShipments.length === 1 ? '' : 's'}`;

  const handleCloseButtonClick = () => {
    if (!isWaveRequestPending) {
      onClose();
    }
  };

  return (
    <LegacyModal
      id="waving-modal"
      size="medium"
      show={show}
      toggleModal={handleCloseButtonClick}
      title={
        <>
          <h4 className="waving-modal__header">Create Wave</h4>
          <p className="waving-modal__subtitle">{`Waving ${selectedShipments.length} ${shipmentNoun}`}</p>
        </>
      }
      footer={
        <div>
          <button className="btn no-cta waving-modal__cancel-button" onClick={onClose} disabled={isWaveRequestPending}>
            Cancel
          </button>
          <button
            className="btn waving-modal__wave-button"
            onClick={handleWaveShipmentsClick}
            disabled={isWaveRequestPending || isStagingLocationInputInvalid}
          >
            <>
              {isWaveRequestPending && (
                <span className="waving-modal__request-spinner">
                  <Icon icon={'circle-notch'} animation="spin-fast" />
                </span>
              )}
              {`${isWaveRequestPending ? 'Waving' : 'Wave'} ${shipmentNoun}`}
            </>
          </button>
        </div>
      }
    >
      <div className="waving-modal__body">
        {enableBatchCount && (
          <>
            <p>Select the number of Batches to split the Wave into. Batches are split by pick location.</p>
            <p>
              <span className="shipment-contents__label">Contents:</span>
              {`${shipmentContents.uniqueSkuCount} SKU${shipmentContents.uniqueSkuCount === 1 ? '' : 's'}, ${
                shipmentContents.unitCount
              } Unit${shipmentContents.unitCount === 1 ? '' : 's'}`}
            </p>
            <BatchCountInput onChange={handleBatchCountChange} onBlur={handleBatchCountBlur} value={batchCount} />
          </>
        )}

        {shouldShowStagingLocation() && (
          <StagingLocationSelector
            locationsService={locationsService}
            onStagingLocationSelect={handleStagingLocationSelect}
            onTypingInput={handleTypingInput}
            pickConsolidationService={pickConsolidationService}
            shipmentIds={selectedShipments.map((shipment) => shipment.shipmentId)}
            usePickConsolidationService={shouldFilterStagingLocations}
            warehouseId={warehouseId}
          />
        )}
      </div>
    </LegacyModal>
  );
};

interface BatchCountInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  value: number | string;
}

const BatchCountInput = ({onChange, onBlur, value}: BatchCountInputProps) => (
  <div className="waving-modal-input waving-modal-input--batch-count">
    <label className="waving-modal-input__label" htmlFor="batch-count-input">
      Batches per Wave
    </label>
    <input
      id="batch-count-input"
      className={'waving-modal-input__input'}
      type="number"
      min={1}
      max={100}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
    />
    <span className="waving-modal-input__helper-text">*There is one Batch per Wave by default</span>
  </div>
);

interface StagingLocationSelectorProps {
  locationsService: LocationsService;
  onStagingLocationSelect: (locationId: string, locationLabel: string) => void;
  onTypingInput: (query: string) => void;
  pickConsolidationService: PickConsolidationService;
  shipmentIds: number[];
  usePickConsolidationService: boolean;
  warehouseId: number;
}

const StagingLocationSelector = ({
  locationsService,
  onStagingLocationSelect,
  onTypingInput,
  pickConsolidationService,
  shipmentIds,
  usePickConsolidationService,
  warehouseId
}: StagingLocationSelectorProps) => (
  <div className="waving-modal-input waving-modal-input--staging-location">
    <label className="waving-modal-input__label">Staging location</label>
    <div className="waving-modal-input__input">
      <StagingLocationTypeAhead
        warehouseId={warehouseId}
        locationsService={locationsService}
        pickConsolidationService={pickConsolidationService}
        usePickConsolidationService={usePickConsolidationService}
        shipmentIds={shipmentIds}
        onSelectCallback={onStagingLocationSelect}
        onTypeCallback={onTypingInput}
        currentLocationLabel={null}
        disable={false}
      />
    </div>
    <span className="waving-modal-input__helper-text">
      *Optional: Waves are staged where the first Batch is dropped off by default
    </span>
  </div>
);

interface AggregatedShipmentContents {
  uniqueSkuCount: number;
  unitCount: number;
}

export const getShipmentContents = (selectedShipments: SelectedShipment[]): AggregatedShipmentContents => {
  const allSkus = flatten(selectedShipments.map((shipment) => shipment.lineItems.map((lineItem) => lineItem.sku)));

  const unitCount = selectedShipments.reduce(
    (currentUnitCount, shipment) =>
      currentUnitCount + shipment.lineItems.reduce((count, lineItem) => count + lineItem.quantity, 0),
    0
  );

  return {uniqueSkuCount: uniq(allSkus).length, unitCount};
};

const allFreight = (shipments: SelectedShipment[]) => shipments.every((s) => s.shipMode === FreightShipMode);

const allSameDestination = (shipments: SelectedShipment[]) => {
  const destination = shipments[0]?.destinationTag;
  return !!destination && shipments.every((s) => s.destinationTag === destination);
};

const allConsolidationEnabled = (shipments: SelectedShipment[]) =>
  shipments.every((s) => s.isFreightConsolidationEnabled);

const allSplitWavesEnabled = (shipments: SelectedShipment[]) => shipments.every((s) => s.isSplitWavesEnabled);

const shouldEnableBatchCountInput = (shipments: SelectedShipment[]) => {
  if (shipments.length === 0) return false;

  const isSingleShipment = shipments.length === 1;

  return (
    allFreight(shipments) &&
    allSplitWavesEnabled(shipments) &&
    (isSingleShipment || (allConsolidationEnabled(shipments) && allSameDestination(shipments)))
  );
};

export default WavingModal;
