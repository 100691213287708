import {FC} from 'react';
import * as React from 'react';

export interface UpdateControlsProps {
  cancelHandler: () => void;
  saveHandler: () => void;
  saveButtonEnabled: boolean;
}

export const UpdateControls: FC<UpdateControlsProps> = ({cancelHandler, saveHandler, saveButtonEnabled}) => {
  return (
    <div className="update-controls row">
      <div className="col-md-12">
        <a onClick={cancelHandler}>Cancel</a>
        <button disabled={!saveButtonEnabled} onClick={saveHandler}>
          Save
        </button>
      </div>
    </div>
  );
};

export default UpdateControls;
