import * as React from 'react';
import {Pagination, TableHeader} from '@flexe/ui-components';
import {Link} from 'react-router-dom';
import {format as formatDate} from 'date-fns';
import {Packaging} from '../../../shared/CommonInterfaces';
import {OMSReservation} from '../../ecommerce-orders/OrdersAppInterfaces';
import {PageSize} from '../generic/PageSizeInput';
import {OrderLine} from '../OutboundOrdersInterfaces';
import {getOrderLineStatusDisplayLabel, isLineCancelable} from '../helpers/OrderLines';
import {getTableDisplayValueForReservations} from '../helpers/Reservations';
import {dateFormat} from './OutboundOrdersV2';

export const buildTableHeaders = (
  currentPage: number,
  totalRecordCount: number,
  hasCancellableLines: boolean,
  hasUncheckedLine: boolean,
  pageSize: PageSize,
  changePage: (page: number) => void,
  onToggleAllLinesChecked: (event: React.ChangeEvent<HTMLInputElement>) => void
): TableHeader[] => {
  return [
    {
      className: 'select-header header-icon',
      element: hasCancellableLines ? (
        <input type="checkbox" checked={!hasUncheckedLine} onChange={onToggleAllLinesChecked} />
      ) : (
        ''
      )
    },
    {
      className: 'issue-header header-icon',
      element: <i className="fa fa-exclamation-triangle red3"></i>
    },
    {className: 'id-header header-9pct', element: 'FLEXE Line ID'},
    {className: 'header-14pct', element: 'External Line ID'},
    {className: 'id-header header-9pct', element: 'FLEXE Order ID'},
    {className: 'header-12pct', element: 'Status'},
    {className: 'header-15pct', element: 'Reservation'},
    {className: 'header-10pct', element: 'SKU'},
    {className: 'header-7pct', element: 'Quantity'},
    {className: 'header-5pct', element: 'Unit of Measure'},
    {className: 'header-9pct', element: 'Created'},
    {
      className: 'pagination-header header-remainder',
      element: (
        <Pagination
          page={currentPage}
          pageSize={pageSize}
          paginationHandler={(page) => changePage(page)}
          totalCount={totalRecordCount}
        />
      )
    }
  ];
};

export const buildRow = (
  line: OrderLine,
  checkedLineIds: {[index: string]: boolean},
  reservations: OMSReservation[],
  onToggleOneLineChecked: (event: React.ChangeEvent<HTMLInputElement>, lineId: string) => void,
  onClickOrderLineId: (lineId: string) => void
): any[] => {
  const ordersUrl = `/s/fulfillment/orders/${line.orderId}`;

  return [
    isLineCancelable(line) && (
      <input
        type="checkbox"
        checked={!!checkedLineIds[line.id]}
        data-line-id={line.id}
        onChange={(event) => onToggleOneLineChecked(event, line.id)}
      />
    ),
    line.hasErrors && <i className="fa fa-exclamation-triangle red3" />,
    <a data-id={line.id} onClick={() => onClickOrderLineId(line.id)}>
      {line.id}
    </a>,
    <div>{line.externalId}</div>,
    <Link to={ordersUrl}>{line.orderId}</Link>,
    getOrderLineStatusDisplayLabel(line),
    <div>
      {getTableDisplayValueForReservations(line.requestedReservationIds, line.plannedReservationIds, reservations)}
    </div>,
    <div>{line.sku}</div>,
    <div>{line.quantity}</div>,
    <div>{line.unitOfMeasure || Packaging.each}</div>,
    <div>{line.createdAt && formatDate(line.createdAt, dateFormat)}</div>,
    <a data-id={line.id} onClick={() => onClickOrderLineId(line.id)} className="pull-right">
      <i className="fa fa-chevron-right" />
    </a>
  ];
};
