import * as React from 'react';
import {FC, useEffect, useMemo, useState} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import {flexeMuiTheme} from '../../../shared/mui/default-mui-theme';
import {FlexeDataGrid} from '../../../shared/mui/flexe-data-grid/FlexeDataGrid';
import {LoadLpn} from '../LoadInterfaces';
import {ItemLinkCell} from '../../../shared/mui/flexe-data-grid/ItemLinkCell';
import {Packaging} from '../../../shared/CommonInterfaces';
import {PluralPackagingAbbreviations} from '../../../shared/CommonMappings';
import {ManifestLpn} from '../ManifestInterfaces';

interface Props {
  lpns: ManifestLpn[];
  isLoading: boolean;
  onUnloadLpn: (lpnBarcode: string) => Promise<void>;
}

const DEFAULT_COLUMN_WIDTH = 200;
const DEFAULT_PAGE_SIZE = 50;

const LoadLpnsTable: FC<Props> = (props) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [loading, setLoading] = useState<boolean>(props.isLoading);

  // trigger re-renders on loading changes
  useEffect(() => {
    setLoading(props.isLoading);
  }, [props.isLoading]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'lpnBarcode',
        headerName: 'LPNs',
        renderCell: (params) => {
          return (
            <ItemLinkCell
              baseUrl={`../lpns`}
              item={{
                id: params.row.lpnBarcode,
                value: params.row.lpnBarcode
              }}
            />
          );
        },
        minWidth: DEFAULT_COLUMN_WIDTH,
        flex: 1
      },
      {
        field: 'shipmentId',
        headerName: 'Shipment ID',
        renderCell: (params) => {
          return (
            (params.row.shipmentId === 0 && '--') || (
              <ItemLinkCell
                baseUrl={`../shipments`}
                item={{
                  id: params.row.shipmentId,
                  value: params.row.shipmentId
                }}
              />
            )
          );
        },
        minWidth: DEFAULT_COLUMN_WIDTH,
        flex: 1
      },
      {
        field: 'contentQty',
        headerName: 'Contents',
        type: 'number',
        align: 'left', // numbers auto align to the right for some reason
        headerAlign: 'left',
        renderCell: (params) => {
          return (
            <div>{`${params.row.contentQty} ${PluralPackagingAbbreviations.get(params.row.contentUnitOfMeasure)}`}</div>
          );
        },
        minWidth: DEFAULT_COLUMN_WIDTH,
        flex: 1
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            showInMenu={true}
            onClick={() => props.onUnloadLpn(params.row.lpnBarcode)}
            label={'Unload LPN'}
          />
        ]
      }
    ],
    []
  );

  const rows: LoadLpn[] =
    props.lpns?.map((lpn, _) => {
      let contentAmt: number;
      let contentUoM: Packaging;

      const cartonCount = lpn.manifest_contents.reduce((sum, element) => {
        return sum + element.quantity_in_all_units.carton;
      }, 0);

      if (cartonCount <= 0) {
        const eachesCount = lpn.manifest_contents.reduce((sum, element) => {
          return sum + element.quantity_in_all_units.each;
        }, 0);
        contentAmt = eachesCount;
        contentUoM = Packaging.each;
      } else {
        contentAmt = cartonCount;
        contentUoM = Packaging.carton;
      }

      return {
        id: lpn.lpn_detail_id?.toString() || lpn.lpn_barcode,
        lpnBarcode: lpn.lpn_barcode,
        shipmentId: lpn.shipment_id,
        contentQty: contentAmt,
        contentUnitOfMeasure: contentUoM
      };
    }) || [];

  const columnVisibilityModel = useMemo<GridColumnVisibilityModel>(() => {
    return {
      id: false,
      lpnBarcode: !props.isLoading,
      shipmentId: !props.isLoading,
      contents: !props.isLoading
    };
  }, [columns, rows, props.isLoading]);

  return (
    <div className="contents-table">
      <ThemeProvider theme={flexeMuiTheme}>
        <FlexeDataGrid
          autoHeight
          columns={columns}
          rows={rows}
          rowCount={rows.length}
          loading={loading}
          pageSizeOptions={[20, 50, 100]} // max is 100
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          columnVisibilityModel={columnVisibilityModel}
          getRowHeight={() => 'auto'}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '8px'}
          }}
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          slots={{toolbar: CustomToolbar}}
        />
      </ThemeProvider>
    </div>
  );

  // removes the export button but includes the quick filter
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter
          sx={{'border': 1, 'borderColor': '#C9D1DC', 'backgroundColor': '#fff', 'padding-bottom': 0}}
          debounceMs={250}
        />
      </GridToolbarContainer>
    );
  }
};

export default LoadLpnsTable;
