import {Loader} from '@flexe/ui-components';
import * as React from 'react';
import {useEffect, useMemo, useRef, useState} from 'react';
import {Wave} from '../WaveInterfaces';
import {WaveBatch} from '../WaveBatchInterfaces';
import BatchWavingService from '../../../shared/services/BatchWavingService';
import BatchContentsTable from './BatchContentsTable';
import {BatchDetailsContext} from './BatchDetailsContext';
import BatchDetailsHeader from './BatchDetailsHeader';
import BatchDocuments from './BatchDocuments';

interface Props {
  authenticityToken: string;
  batchId: string;
}

const BatchDetails: React.FC<Props> = ({authenticityToken, batchId}) => {
  const batchWavingService = new BatchWavingService(authenticityToken);
  const [errors, setErrors] = useState<string[]>([]);
  const [batch, setBatch] = useState<WaveBatch>(null);
  const [wave, setWave] = useState<Wave>(null);
  const [displayFromLpnColumn, setDisplayFromLpnColumn] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const resultRef = useRef(null);

  useEffect(() => {
    asyncGetBatchById();
  }, []);

  useEffect(() => {
    asyncGetWaveById();
  }, [batch]);

  const asyncGetBatchById = async () => {
    const response = await batchWavingService.getBatchById(batchId, true);
    if (response.data) {
      if (response.data.batch) {
        setBatch(response.data.batch);
      }

      setDisplayFromLpnColumn(response.data.displayFromLpnColumn);
    }
    if (response.errors && response.errors.length > 0) {
      const getBatchErrors = [].concat(errors);

      response.errors.map((e) => {
        if (e.status === 404) {
          getBatchErrors.push(`Batch not found with ID ${batchId}`);
        } else {
          getBatchErrors.push(`${e.status} - ${e.detail}`);
        }
      });
      const nullBatch: WaveBatch = {
        id: parseInt(batchId, 10) || 0,
        waveId: null,
        reservationId: null,
        barcode: null,
        status: null,
        createdAt: null,
        startedAt: null,
        currentPicker: null,
        currentMhe: null,
        taskId: null,
        pickSteps: [],
        documents: [],
        lastLocation: null
      };
      const nullWave: Wave = {
        id: null,
        reservation: null,
        barcode: null,
        stagingLocation: null,
        status: null,
        createdAt: null,
        waveRequest: null,
        sortMhe: null,
        documents: [],
        shipmentIds: [],
        waveAttributes: {
          fullPalletPull: true,
          sortationEnabled: true,
          pickMethod: 'cluster-pick',
          isOversized: false,
          overbox: true,
          packTimeLabelGeneration: true,
          pickToCarton: true,
          printPackingSlipsSeparately: false,
          shipAsIsRequested: true,
          shouldRebin: true
        }
      };
      setWave(nullWave);
      setBatch(nullBatch);
      setIsLoading(false);
      setErrors(getBatchErrors);
    }
  };

  const asyncGetWaveById = async () => {
    if (!batch) {
      return;
    }
    const response = await batchWavingService.getWaveById(batch.waveId);
    if (response.data && response.data.wave) {
      setWave(response.data.wave);
      setIsLoading(false);
    }
  };

  const contextValue = useMemo(
    () => ({
      authenticityToken,
      batchWavingService,
      batch,
      wave
    }),
    [batch, wave]
  );

  const renderNavigation = () => (
    <div className="breadcrumbs delivery" ref={resultRef}>
      <a href="/wh/waves">Waves & Batches</a>
      {wave.id && <span className="navigation-separator">/</span>}
      {wave.id && <a href={`/wh/waves/${wave.id}`}>Wave #{wave.id}</a>}
      <span className="navigation-separator">/</span>
      Batch #{batch.id}
    </div>
  );

  return (
    <BatchDetailsContext.Provider value={contextValue}>
      <div className="details-page">
        {errors &&
          errors.length > 0 &&
          errors.map((error, idx) => {
            return (
              <div className="alert alert-danger" role="alert" key={`error:${idx}`}>
                {error}
              </div>
            );
          })}
        <div className="details-background">
          <div className="details-container container-fluid">
            <div className="details">
              {!isLoading && renderNavigation()}
              {isLoading && <Loader loading={isLoading} />}
              {!isLoading && <BatchDetailsHeader />}
            </div>
          </div>
        </div>
        <div>
          {!isLoading && <BatchDocuments />}
          {!isLoading && <BatchContentsTable displayFromLpnColumn={displayFromLpnColumn} />}
        </div>
      </div>
    </BatchDetailsContext.Provider>
  );
};

export default BatchDetails;
