import * as Papa from 'papaparse';
import * as React from 'react';
import Help from './Help';

interface StepTwoState {
  showNotes: boolean;
}

interface StepTwoProps {
  helpContent: JSX.Element;
  parseErrors?: Papa.ParseError[];
  sampleFileUrl: string;
  successfulParse?: boolean;
  handleFileUpload(event);
}

class StepTwo extends React.Component<StepTwoProps, StepTwoState> {
  constructor(props) {
    super(props);
    this.state = {
      showNotes: false
    };
  }

  public render() {
    const parseErrors = this.props.parseErrors
      ? this.props.parseErrors.map((error, index) => {
          return (
            <li key={index}>
              {error.row ? `Row: ${error.row} ` : ''}
              {error.message}
            </li>
          );
        })
      : null;
    return (
      <li id="step-2" className="active">
        <span className="label">2</span>
        <div className="step-wrap">
          <h3>
            <span>Upload CSV to Import</span>
          </h3>
          <div className="form-group">
            <label htmlFor="csvUpload">Choose File</label>
            <input name="csvUpload" type="file" accept=".csv" onChange={this.props.handleFileUpload} />
          </div>
          <h5>Download a Template</h5>
          <p>
            <a href={this.props.sampleFileUrl} target="_blank">
              <i className="fa fa-download"></i> Example Upload File (.CSV)
            </a>
          </p>
          <Help showNotes={this.state.showNotes} onClick={this.handleShowNotes}>
            {this.props.helpContent}
          </Help>
          <div id="parse-success" className={this.props.successfulParse ? 'show' : 'hidden'}>
            <h4>
              <i className="fa fa-check-circle text-success"></i> CSV Parsed Successfully
            </h4>
          </div>
          <div id="parse-fail" className={this.props.successfulParse === false ? 'show' : 'hidden'}>
            <h4>
              <i className="fa fa-times-circle text-danger"></i> CSV Not Parsed Successfully
            </h4>
            <p>
              <em>Please fix the errors below and reupload your CSV:</em>
            </p>
            <ul className="errors">{parseErrors}</ul>
          </div>
        </div>
      </li>
    );
  }

  private handleShowNotes = () => {
    this.setState({
      showNotes: !this.state.showNotes
    });
  };
}

export default StepTwo;
