import * as React from 'react';
import {SsccPallet} from '../../shared/services/RetailFulfillmentService';

interface PrintSsccLabelsModalTableRowProps {
  pallet: SsccPallet;
  selectedLabels: string[];
  onSelectHandler: (palletLabel: string) => void;
  onUnselectHandler: (palletLabel: string) => void;
}

export const PrintSsccLabelsModalTableRow: React.FC<PrintSsccLabelsModalTableRowProps> = (props) => {
  const [isSelected, setIsSelected] = React.useState(false);

  React.useEffect(() => {
    const selected = props.selectedLabels.includes(props.pallet.label);
    setIsSelected(selected);
  }, [props.selectedLabels]);

  const tableRow = () => {
    return (
      <tr>
        <td>{selectCheckboxForPallet()}</td>
        <td># {props.pallet.palletNumber}</td>
        <td>{props.pallet.contents.sku}</td>
        <td>{props.pallet.contents.skuDescription}</td>
        <td>{props.pallet.contents.quantity + ' ' + props.pallet.contents.packaging}</td>
        <td>{props.pallet.label}</td>
      </tr>
    );
  };

  const selectCheckboxForPallet = () => {
    return <input type="checkbox" checked={isSelected} onChange={(event) => handleSelectPallet(event)} />;
  };

  const handleSelectPallet = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      props.onSelectHandler(props.pallet.label);
    } else {
      props.onUnselectHandler(props.pallet.label);
    }

    setIsSelected(isChecked);
  };

  return tableRow();
};

export default PrintSsccLabelsModalTableRow;
