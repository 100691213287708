import {Loader, Table, TableHeader} from '@flexe/ui-components';
import * as React from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {RouteProps} from 'react-router-dom';
import {format as formatDate} from 'date-fns';
import OutboundShipmentService from '../../shared/services/OutboundShipmentService';
import WarehouseService from '../../shared/services/WarehouseService';
import {renderItemLink} from '../../../libs/helpers';
import CarrierBillingAccountService from '../account-settings/carrier-billing-accounts/CarrierBillingAccountService';
import FulfillmentService from './FulfillmentService';
import DetailsHeader from './DetailsHeader';
import {FulfillmentDetailProps} from './SharedInterfaces';
import {ShipmentDetails} from './SharedInterfaces';
import {SplitSource} from './SharedInterfaces';
import RelatedShipments from './RelatedShipments';

const ECOMMERCE_CHANNELS_UPDATED_API_PARAMS_FLAG = 'ecommerce-channels-updated-api-params';
const DISTRIBUTION_BY_LOT_CONFIGURATION_FLAG = 'distribution_by_lot_configuration';

interface InventoryData {
  id: number;
  sku: string;
  description: string;
  quantity: number;
  packaging: string;
  lot_code?: string;
  expiration_date?: string;
}

interface FulfillmentDetailState {
  shipmentId: number;
  details: ShipmentDetails;
  inventoryData: InventoryData[];
  loading: boolean;
  showModal: boolean;
  usesOrderManager: boolean;
  bypassPickupDetails: boolean;
  showLotIdAndExpiration: boolean;
  error: string;
}

class FulfillmentDetail extends React.Component<FulfillmentDetailProps & RouteProps, FulfillmentDetailState> {
  private fulfillmentService: FulfillmentService;
  private outboundShipmentService: OutboundShipmentService;
  private carrierBillingAccountService: CarrierBillingAccountService;
  private warehouseService: WarehouseService;
  private showVendor: boolean;

  constructor(props) {
    super(props);
    this.fulfillmentService = props.fulfillmentService || new FulfillmentService(props.authenticityToken);
    this.outboundShipmentService =
      props.outboundShipmentService || new OutboundShipmentService(props.authenticityToken);
    this.carrierBillingAccountService =
      props.carrierBillingAccountService || new CarrierBillingAccountService(props.authenticityToken);
    this.warehouseService = props.warehouseService || new WarehouseService(props.authenticityToken);
    this.showVendor = false;
    this.state = {
      details: {
        notes: null,
        documents: [],
        isBatched: false,
        issues: [],
        shipmentId: null,
        shipMode: null,
        po: null,
        repairable: false,
        reservationFriendlyName: null,
        shipOn: null,
        shipToName: null,
        shipToAddress: null,
        shippingLabelInfo: null,
        state: null
      },
      inventoryData: [],
      loading: true,
      shipmentId: props.shipmentId || Number(props.match.params.id),
      showModal: false,
      usesOrderManager: false,
      bypassPickupDetails: false,
      showLotIdAndExpiration: false,
      error: null
    };
  }

  public async componentDidMount() {
    this.loadShipment();

    const ecommerceChannelsFlagState = await this.warehouseService.getFeatureFlag(
      ECOMMERCE_CHANNELS_UPDATED_API_PARAMS_FLAG,
      null,
      null,
      this.props.currentCompany.id
    );
    this.showVendor = !!ecommerceChannelsFlagState.data.value;

    this.setState({loading: false, error: null});
  }

  public render() {
    const cancelDescription =
      this.state.details.parentCustomerUuid && this.state.details.splitSource === SplitSource.MpsSplit ? (
        <div>
          <b>Warning!</b> This shipment is part of a multi-package shipment.
          <br></br> By cancelling this shipment you are also cancelling the connected shipments.
        </div>
      ) : (
        `Are you sure you want to cancel Shipment #${this.state.shipmentId}?`
      );
    const cancelProceedDescription =
      this.state.details.parentCustomerUuid && this.state.details.splitSource === SplitSource.MpsSplit ? (
        <div>Yes, cancel all shipments</div>
      ) : (
        <div>Yes</div>
      );

    return (
      <div id="fulfillment-detail-component">
        {this.state.error && (
          <div className="alert alert-danger" role="alert">
            {this.state.error}
          </div>
        )}
        <div className="container-fluid" id="topline-details" key="topline-details">
          <DetailsHeader
            fulfillmentService={this.fulfillmentService}
            carrierBillingAccountService={this.carrierBillingAccountService}
            outboundShipmentService={this.outboundShipmentService}
            hasCarrierAccounts={this.props.hasCarrierAccounts}
            details={this.state.details}
            usesOrderManager={this.state.usesOrderManager}
            toggleModal={this.toggleModal}
            handleCancel={this.handleCancel}
            authenticityToken={this.props.authenticityToken}
            handleCheckShippingLabelUpdate={this.handleCheckShippingLabelUpdate}
            showVendor={this.showVendor}
            loading={this.state.loading}
            handleShipmentUpdated={this.handleShipmentUpdated}
            omnichannelEnabled={this.props.omnichannelEnabled}
            bypassPickupDetails={this.state.bypassPickupDetails}
          />
        </div>
        <div className="container-fluid fulfillment-shipment-detail" key="inventory-list">
          <Loader loading={this.state.loading} />
          {!this.state.loading && <Table tableClass="table-striped space-above-lg" tableData={this.getTableData()} />}
        </div>
        {this.state.details?.relatedShipmentsRequiredLabels && (
          <div className="container-fluid related-shipments">
            <RelatedShipments requiredLabels={this.state.details?.relatedShipmentsRequiredLabels} />
          </div>
        )}
        <TransitionGroup>
          {this.state.showModal && (
            <CSSTransition classNames="cancel-modal" timeout={250}>
              <div className="cancel-modal">
                <div className="modal-backdrop"></div>
                <div className="modal" id="myModal" role="dialog" aria-labelledby="myModalLabel">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button type="button" className="close" aria-label="Close" onClick={this.toggleModal}>
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 className="modal-title" id="myModalLabel">
                          Cancel Shipment #{this.state.shipmentId}
                        </h4>
                      </div>
                      <div className="modal-body">
                        <p>{cancelDescription}</p>
                      </div>
                      <div className="modal-footer text-center">
                        <button type="button" className="btn" onClick={this.toggleModal}>
                          No
                        </button>
                        <button id="cancel-agree" type="button" className="btn" onClick={this.handleCancel}>
                          {cancelProceedDescription}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    );
  }

  private toggleModal = () => {
    this.setState({showModal: !this.state.showModal});
  };

  private handleCancel = async () => {
    this.setState({
      showModal: false
    });
    const response = await this.fulfillmentService.cancelShipments([this.state.details.shipmentId]);
    // eslint-disable-next-line no-prototype-builtins
    if (response.hasOwnProperty('successes') && response.successes.length) {
      this.loadShipment();
    } else if (response.errors && response.errors.length > 0) {
      const errorMsg: string = response.errors.map((e) => e.msg).join(', ');
      this.setState({error: errorMsg});
    }
  };

  private handleShipmentUpdated = async () => {
    this.loadShipment();
  };

  private handleCheckShippingLabelUpdate = () => {
    this.setState({loading: true});
    // wait 3 seconds to reload shipment info in hopes that new shipping label has been generated by then
    setTimeout(() => this.loadShipment(), 3000);
    setTimeout(() => this.setState({loading: false}), 3500);
  };

  private async loadShipment() {
    const response = await this.fulfillmentService.getShipment(this.state.shipmentId);
    const responseData = response.data;

    const showLotIdAndExp = await this.shouldShowLotIdAndExpiration(
      responseData.distributionByLotEnabled,
      responseData.shipment.details.reservation_id || null
    );

    this.setState({
      details: responseData.shipment.details as ShipmentDetails,
      inventoryData: responseData.shipment.inventoryData as InventoryData[],
      usesOrderManager: responseData.usesOrderManager,
      bypassPickupDetails: responseData.bypassPickupDetails,
      showLotIdAndExpiration: showLotIdAndExp
    });
  }

  private async shouldShowLotIdAndExpiration(distributionByLotEnabled, reservationId) {
    if (!distributionByLotEnabled) {
      return false;
    }

    const distributionByLotFlagState = await this.warehouseService.getFeatureFlag(
      DISTRIBUTION_BY_LOT_CONFIGURATION_FLAG,
      null,
      reservationId
    );
    return !!distributionByLotFlagState.data.value;
  }

  private getTableData() {
    let headers = [
      {element: 'SKU'},
      {element: 'Description'},
      {element: 'Quantity'},
      {element: 'Packaging'}
    ] as TableHeader[];

    if (this.state.showLotIdAndExpiration) {
      headers = headers.concat([{element: 'Lot Code'}, {element: 'Expiration Date'}] as TableHeader[]);
    }

    const rows = this.buildRows();
    return {
      headers,
      rows
    };
  }

  private buildRows() {
    if (this.state.inventoryData && this.state.inventoryData.length > 0) {
      return this.state.inventoryData.map((item: InventoryData) => {
        const rowData = [
          <div>{renderItemLink(item.id, item.sku, true)}</div>,
          <div>{item.description}</div>,
          item.quantity,
          item.packaging
        ];

        if (this.state.showLotIdAndExpiration) {
          rowData.push(
            item.lot_code || '',
            <div>{item.expiration_date ? formatDate(item.expiration_date, 'MMM DD, YYYY') : ''}</div>
          );
        }

        return rowData;
      });
    }
    return [];
  }
}

export default FulfillmentDetail;
