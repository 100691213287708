import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import BatchWavingService from '../../shared/services/BatchWavingService';
import {TransitionState} from '../../shared/CommonInterfaces';
import {getOrderCountForState} from './BatchHelpers';
import {OrderStateCounts} from './BatchInterfaces';

interface CancelProps {
  batchId: number;
  orderStateCounts: OrderStateCounts;
  batchService: BatchWavingService;
  pickStarted: boolean;
  handleCancellation();
}

interface CancelState {
  confirmedBatch: string;
  pickStarted: boolean;
  showModal: boolean;
  errors?: string;
}

class BatchCancel extends React.Component<CancelProps, CancelState> {
  private batchService: BatchWavingService;
  constructor(props) {
    super(props);
    this.state = {
      confirmedBatch: '',
      pickStarted: props.pickStarted,
      showModal: false
    };
    this.batchService = this.props.batchService;
  }

  public render() {
    return (
      <div id="batch-cancel-component">
        <button id="batch-cancel-button" onClick={this.toggleModal}>
          {getOrderCountForState(this.props.orderStateCounts, TransitionState.Completed) > 0
            ? 'Partially Complete Batch'
            : 'Cancel Batch'}
        </button>
        <LegacyModal
          id="cancel-modal"
          size="small"
          show={this.state.showModal}
          toggleModal={this.toggleModal}
          title="Cancel Batch"
          footer={
            <div>
              <button id="no-button" className="btn" onClick={this.toggleModal}>
                No
              </button>
              <button
                id="yes-button"
                className="btn"
                onClick={this.handleCancel}
                disabled={
                  (this.props.pickStarted ||
                    getOrderCountForState(this.props.orderStateCounts, TransitionState.Completed) > 0) &&
                  this.props.batchId.toString() !== this.state.confirmedBatch
                }
              >
                Yes
              </button>
            </div>
          }
        >
          <div>
            {this.state.errors && <div className="alert alert-danger">{this.state.errors}</div>}
            <p className="grey1 note">
              Are you certain you would like to{' '}
              {getOrderCountForState(this.props.orderStateCounts, TransitionState.Completed) > 0
                ? 'partially complete this batch'
                : 'cancel this batch'}
              ?
            </p>
            {this.props.pickStarted &&
              getOrderCountForState(this.props.orderStateCounts, TransitionState.Completed) === 0 && (
                <div>
                  <p className="grey1 note">
                    Picking for this batch has already begun. Are you sure you want to cancel it?
                  </p>
                  <br />
                  <p className="grey1 note">
                    If you are certain you understand the consequences of canceling this batch, please type the batch ID
                    below:
                  </p>
                  <br />
                  <input
                    id="confirm-batch-input"
                    value={this.state.confirmedBatch}
                    type="text"
                    placeholder="Verify Batch to Cancel"
                    onChange={this.handleChange}
                  />
                </div>
              )}
            {getOrderCountForState(this.props.orderStateCounts, TransitionState.Completed) > 0 && (
              <div>
                <p className="grey1 note">
                  Partially completing this batch will move{' '}
                  {getOrderCountForState(this.props.orderStateCounts, TransitionState.Confirmed)} orders back to new
                  state to be re-batched. There are currently{' '}
                  {getOrderCountForState(this.props.orderStateCounts, TransitionState.Completed)} completed orders in
                  this batch.
                </p>
                <br />
                <p className="grey1 note">
                  If you are certain you understand the consequences of partially completing this batch, please type the
                  batch ID below:
                </p>
                <br />
                <input
                  id="confirm-batch-input"
                  value={this.state.confirmedBatch}
                  type="text"
                  placeholder="Verify Batch to Partially Complete"
                  onChange={this.handleChange}
                />
              </div>
            )}
          </div>
        </LegacyModal>
      </div>
    );
  }

  private toggleModal = () => {
    this.setState({
      confirmedBatch: '',
      errors: '',
      pickStarted: this.props.pickStarted,
      showModal: !this.state.showModal
    });
  };

  private handleCancel = async () => {
    const shouldCancel = getOrderCountForState(this.props.orderStateCounts, TransitionState.Completed) === 0;

    const response = shouldCancel
      ? await this.batchService.cancelBatches([this.props.batchId])
      : await this.batchService.partiallyCompleteBatches([this.props.batchId]);
    if (response && response.errors.length === 0) {
      this.toggleModal();
      this.props.handleCancellation();
    } else {
      let message = 'There was an issue transitioning this batch';
      if (response) {
        const serverErrors = response.errors.map((e) => e.detail);
        message += `: ${serverErrors.join(', ')}`;
      }
      this.setState({
        errors: message
      });
    }
  };

  private handleChange = (event) => {
    this.setState({
      confirmedBatch: event.target.value
    });
  };
}

export default BatchCancel;
