import * as React from 'react';
import {OrderItem, RetailOrderDetails, SsccPallet} from '../../shared/services/RetailFulfillmentService';
import LineItems from './LineItems';
import PrintSsccLabelsButton from './PrintSsccLabelsButton';
import PrintSsccLabelsModal from './PrintSsccLabelsModal';
import {LpnCompletionModal} from './LpnCompletionModal';

interface LineItemsSectionProps {
  orderDetails: RetailOrderDetails;
  palletLevelSsccLabelsEnabled: boolean;
  isLpnEnabled: boolean;
}

export const LineItemsSection: React.FC<LineItemsSectionProps> = (props) => {
  const [ssccEnabled, setSsccEnabled] = React.useState(false);
  const [ssccPallets, setSsccPallets] = React.useState([]);
  const [showPrintModal, setShowPrintModal] = React.useState(false);
  const [showPreviewModal, setShowPreviewModal] = React.useState(false);

  React.useEffect(() => {
    if (!props.orderDetails) {
      return;
    }

    const pallets = [];
    props.orderDetails.orderItems.forEach((item: OrderItem) => {
      if (!item.ssccLabels) {
        return;
      }

      item.ssccLabels.forEach((pallet: SsccPallet) => pallets.push(pallet));
    });

    setSsccEnabled(
      props.palletLevelSsccLabelsEnabled && !props.orderDetails.ssccLabelsStillGenerating && pallets.length > 0
    );

    setSsccPallets(pallets);
  }, [props.orderDetails]);

  const printLabelsButtons = () => {
    if (!ssccEnabled) {
      return ssccLabelsStillGeneratingWarning();
    }

    const buttonText = (
      <React.Fragment>
        <i className="fa fa-print"></i>
        &nbsp;Print Labels for All Pallets ({ssccPallets.length})
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <PrintSsccLabelsButton
          orderId={props.orderDetails.id}
          elementId={'print-all-labels-button'}
          buttonText={buttonText}
        />

        <button id="print-specific-pallets-button" className="btn btn-primary" onClick={() => setShowPrintModal(true)}>
          <i className="fa fa-print"></i>
          &nbsp;Print Specific Pallets
        </button>
      </React.Fragment>
    );
  };

  const ssccLabelsStillGeneratingWarning = () => {
    const blank = <React.Fragment></React.Fragment>;

    // This isn't even relevant if the reservation config is off
    if (!props.palletLevelSsccLabelsEnabled) {
      return blank;
    }

    // If labels aren't still generating but total pallets is 0,
    // then the async label generator hasn't started working yet
    if (!props.orderDetails.ssccLabelsStillGenerating && ssccPallets.length > 0) {
      return blank;
    }

    // We don't want this message to show for old completed or cancelled orders
    if (props.orderDetails.state !== 'pickup_scheduled') {
      return blank;
    }

    return (
      <span className="labels-still-generating-warning-message">
        Pallet labels are still being generated, please refresh the page in a few moments&hellip;
      </span>
    );
  };

  const shippedQuantities = () => {
    return (
      <React.Fragment>
        {props.isLpnEnabled && props.orderDetails.state === 'completed' && (
          <h4 className="space-above">Quantity Shipped</h4>
        )}
        <LineItems
          orderId={props.orderDetails.id}
          orderItems={props.orderDetails.orderItems}
          ssccEnabled={false}
          ssccLabelsStillGenerating={false}
          showLpns={false}
        />
      </React.Fragment>
    );
  };

  const shippedLpns = () => {
    if (!props.isLpnEnabled || props.orderDetails.state !== 'completed') {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <React.Fragment>
        <h4 className="space-above">LPNs on Shipment</h4>
        <LineItems
          orderId={props.orderDetails.id}
          orderItems={props.orderDetails.orderItems}
          ssccEnabled={false}
          ssccLabelsStillGenerating={false}
          showLpns={true}
        />
      </React.Fragment>
    );
  };

  const originalOrderItems = () => {
    if (props.orderDetails.state !== 'completed' || !props.orderDetails.originalOrderItems) {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <React.Fragment>
        <h4 className="space-above">Original Shipment Requested</h4>
        <LineItems
          orderId={props.orderDetails.id}
          orderItems={props.orderDetails.originalOrderItems}
          ssccEnabled={false}
          ssccLabelsStillGenerating={false}
          showLpns={false}
        />
      </React.Fragment>
    );
  };

  const displayPreviewLocationContentsButton = () => {
    if (!props.isLpnEnabled) {
      return false;
    }

    if (!['confirmed', 'pickup_scheduled'].includes(props.orderDetails.state)) {
      return false;
    }

    // This logic is retained from existing behavior - we don't expect LPN-enabled
    // reservations to use parcel shipments but this is a safeguard just in case
    if (
      props.orderDetails.state === 'pickup_scheduled' &&
      (props.orderDetails.palletsBuilt > 0 || props.orderDetails.shipmentType === 'parcel')
    ) {
      return false;
    }

    return true;
  };

  const previewLocationContents = () => {
    if (!displayPreviewLocationContentsButton()) {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <React.Fragment>
        <a className="btn cta preview-rfo" onClick={() => setShowPreviewModal(true)}>
          Preview Location Contents
        </a>

        <LpnCompletionModal
          orderDetails={props.orderDetails}
          displayOnly={true}
          authenticityToken={null} // not relevant for preview-only mode
          blockOverShipment={false} // not relevant for preview-only mode
          showModal={showPreviewModal}
          toggleModal={() => setShowPreviewModal(!showPreviewModal)}
          onConfirm={() => void 0} // not relevant for preview-only mode
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <hr />
      {previewLocationContents()}
      {printLabelsButtons()}
      <div id="line-items-section">
        {shippedQuantities()}
        {shippedLpns()}
        {originalOrderItems()}
      </div>
      <PrintSsccLabelsModal
        orderDetails={props.orderDetails}
        ssccPallets={ssccPallets}
        showModal={showPrintModal}
        toggleModalHandler={() => setShowPrintModal(!showPrintModal)}
      />
    </React.Fragment>
  );
};

export default LineItemsSection;
