import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';

interface Props {
  showLpnDeleteModal: boolean;
  lpnBarcode: string;
  deleteLPN: (event: any) => Promise<void>;
  toggleLpnDeleteModal: () => void;
}

const DeleteLpnConfirmationModal: FunctionComponent<Props> = (props) => {
  return (
    <LegacyModal
      id="confirm-lpn-deletion-modal"
      title={`Delete LPN ${props.lpnBarcode}`}
      show={props.showLpnDeleteModal}
      size="small"
      toggleModal={props.toggleLpnDeleteModal}
      footer={
        <div>
          <a onClick={props.toggleLpnDeleteModal}>No</a>
          <button id="perform-deletion" type="button" className="btn btn-primary" onClick={props.deleteLPN}>
            Yes, permanently delete this LPN
          </button>
        </div>
      }
      disableClose={false}
    >
      <div>
        <p>Are you sure you want to delete this LPN? This action cannot be undone.</p>
      </div>
    </LegacyModal>
  );
};

export default DeleteLpnConfirmationModal;
