import * as React from 'react';
import {useRef} from 'react';
import {useEffect} from 'react';

/*
  Invokes the given callback when a DOM element not wrapped by the component is clicked on.
  An example use case is to close dropdowns when clicking outside the list.

  ex:
  <DoWhenClickOutside callback={closeDropDown}>
    <DropDown {...props}/>
  </DoWhenClickOutside>
*/

interface Props {
  callback(event);
}

const DoWhenClickOutside: React.FC<Props> = (props) => {
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
      // inside click
      return;
    }
    props.callback(event);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={'do-when-click-outside-container'} ref={wrapperRef}>
      {props.children}
    </div>
  );
};

export default DoWhenClickOutside;
