import * as React from 'react';
import {useContext, useState} from 'react';
import {Tab, Tabs} from '@flexe/ui-components';
import {WaveDetailsContext} from './WaveDetailsContext';
import ContentsBatchesTable from './ContentsBatchesTable';
import ContentShipmentsTable from './ContentShipmentsTable';

enum ActiveTable {
  BATCHES = 'BATCHES',
  SHIPMENTS = 'SHIPMENTS'
}

// eslint-disable-next-line @typescript-eslint/ban-types
const WaveContents: React.FC<{}> = () => {
  const [activeTable, setActiveTable] = useState<string>(ActiveTable.BATCHES);
  const {batches} = useContext(WaveDetailsContext);
  const {shipments} = useContext(WaveDetailsContext);

  function handleTabEvent(newTab: string) {
    setActiveTable(newTab);
  }

  const tabs: Tab[] = [
    {
      key: ActiveTable.BATCHES,
      title: `Batches (${batches.length})`,
      active: activeTable === ActiveTable.BATCHES
    },
    {
      key: ActiveTable.SHIPMENTS,
      title: `Shipments (${shipments.length})`,
      active: activeTable === ActiveTable.SHIPMENTS
    }
  ];

  const getTable = () => {
    switch (activeTable) {
      case ActiveTable.BATCHES:
        return <ContentsBatchesTable />;
      case ActiveTable.SHIPMENTS:
        return <ContentShipmentsTable />;
    }
  };

  return (
    <div className="wave-contents widget-container">
      <div className="container-fluid">
        <div className="header">
          <h2>Contents</h2>
        </div>

        <div className="widget-details">
          <Tabs tabs={tabs} tabEvent={handleTabEvent} />
        </div>
        <div className="table-holder">{getTable()}</div>
      </div>
    </div>
  );
};

export default WaveContents;
