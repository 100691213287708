import {formatDate} from '../shared/utilities/DataFormatting';
import {BlobStorage} from './ConvertToBlobStorage';

// we need a better way to do this, otherwise this will need a new entry for every customer-defined sticker name
export const documentTypeTitleToLabelType: {[title: string]: string} = {
  'Pallet SSCC Labels': 'pallet',
  'Carton SSCC Labels': 'carton',
  'Pallet Placards': 'sticker'
};

export function createGenerationMessage(docType: string, storage: BlobStorage, isGenerating: boolean): string {
  let message = '';
  const showLastGeneratedDate = storage.createdAt && storage.availableForDownload;

  if (isGenerating) {
    message = `Generating ${docType}, please refresh the page in a few moments`;
  } else {
    message = showLastGeneratedDate ? `Last generated ${formatDate(new Date(storage.createdAt), 'MMM D, h:mm A')}` : '';
  }
  return message;
}
