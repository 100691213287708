import * as React from 'react';
import * as JsBarcode from 'jsbarcode';

export enum BarcodePreviewFormat {
  CODE128 = 'CODE128',
  UPC = 'UPC',
  PHARMACODE = 'pharmacode',
  EAN13 = 'EAN13',
  EAN8 = 'EAN8',
  EAN5 = 'EAN5',
  EAN2 = 'EAN2'
}

interface BarcodePreviewProps {
  id: number;
  barcode: string;
  width: number;
  height: number;
  format?: BarcodePreviewFormat;
}

// An interface for rendering barcodes nicely on the flexe site, taken from https://github.com/lindell/JsBarcode
const BarcodePreview: React.FC<BarcodePreviewProps> = (props) => {
  const {id, width, height, barcode, format} = props;

  const barcodeFormat = format || BarcodePreviewFormat.CODE128;

  React.useEffect(() => {
    JsBarcode(`#barcode-preview-${id}`, barcode || '', {
      width,
      height,
      format: barcodeFormat
    });
  }, []);

  return (
    <div className="barcode-preview">
      <svg id={'barcode-preview-' + id}></svg>
    </div>
  );
};

export {BarcodePreview, BarcodePreviewProps};
export default BarcodePreview;
