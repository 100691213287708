import * as React from 'react';
import {FunctionComponent} from 'react';
import {useForm} from 'react-hook-form';
import {format as formatDate} from 'date-fns';
import {cloneDeep} from 'lodash';
import FlexeButton from '../FlexeButton';
import {Item} from '../../shared/services/ItemMasterService';
import {CycleCountItem} from './CycleCountInterfaces';

interface Props {
  id: string;
  forShipper: boolean;
  disabled: boolean;
  cycleCountItem: CycleCountItem;
  toggleItemDetailsModalWithId: (id: string) => void;
  saveLpnMetadataEdits: (data) => Promise<void>;
  inventoryInfoForCCItem: Item[];
}

// list input names here for react-hook-form
interface Inputs {
  lotCode: string;
  expirationDate: Date;
}

const ItemPropertiesMetadataForm: FunctionComponent<Props> = (props) => {
  const {handleSubmit, register} = useForm<Inputs>();

  const onSubmit = (data) => {
    const updatedItem: CycleCountItem = cloneDeep(props.cycleCountItem);
    updatedItem.metadata.actual = {
      id: null,
      metadata: {
        lotCode: null,
        expirationDate: null
      },
      uoms: {
        eachesPerInnerPack: null,
        eachesPerOuterCase: null
      }
    };
    updatedItem.metadata.actual.id = data.itdId;

    if (data.lotCode) {
      updatedItem.metadata.actual.metadata.lotCode = data.lotCode;
    }
    if (data.expDate) {
      updatedItem.metadata.actual.metadata.expirationDate = data.expDate;
    }
    props.toggleItemDetailsModalWithId(data.itdId);
    props.saveLpnMetadataEdits(updatedItem);
  };

  const dateAcrobatics = (dateFromMetadata: string) => {
    // incoming date format: 2021-08-31
    if (dateFromMetadata) {
      return formatDate(dateFromMetadata.slice(0, 10), 'YYYY-MM-DD');
    } else {
      return null;
    }
  };

  // the optional chaining operators here support loose goods with ITDs that will add metadata later
  const actualMetadata = props.cycleCountItem.metadata?.actual?.metadata;
  const expectedMetadata = props.cycleCountItem.metadata?.expected?.metadata;
  const actualMetadataExists = Boolean(actualMetadata);
  const [expiryDateVal, setExpiryDateVal] = React.useState(
    actualMetadataExists
      ? dateAcrobatics(actualMetadata?.expirationDate || '')
      : dateAcrobatics(expectedMetadata?.expirationDate || '')
  );
  const [lotCodeVal, setLotCodeVal] = React.useState(
    actualMetadataExists ? actualMetadata?.lotCode || '' : expectedMetadata?.lotCode || ''
  );

  const registerExpDateChange = (e) => {
    setExpiryDateVal(e.target.value);
  };

  const registerLotCodeChange = (e) => {
    setLotCodeVal(e.target.value);
  };

  let lotCodeIsEnabled = false;
  let expDateIsEnabled = false;
  if (props.inventoryInfoForCCItem && props.inventoryInfoForCCItem.length > 0) {
    lotCodeIsEnabled = props.inventoryInfoForCCItem[0].lotCodeTrackingEnabled === true;
    expDateIsEnabled = props.inventoryInfoForCCItem[0].expiryDateTrackingEnabled === true;
  }

  return (
    <form
      data-testid="itd-metadata-form"
      className="lpn-metadata"
      id="lpn-metadata-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div data-testid="lot-code">
        {lotCodeIsEnabled ? (
          <div className="form-field">
            <label>Lot Code:</label>
            <input
              data-testid="lot-code-field"
              name="lotCode"
              value={lotCodeVal || ''}
              onChange={registerLotCodeChange}
              ref={register}
            />
          </div>
        ) : null}
      </div>
      <div data-testid="expiration-date">
        {expDateIsEnabled ? (
          <div className="form-field">
            <label>Expiration Date:</label>
            <input
              type="date"
              data-testid="exp-date-field"
              name="expDate"
              value={expiryDateVal || ''}
              onChange={registerExpDateChange}
              ref={register}
            />
          </div>
        ) : null}
      </div>
      <div className="form-field hidden">
        <input
          data-testid="itd-id-field"
          name="itdId"
          ref={register}
          value={props.cycleCountItem.metadata?.expected?.id || -1}
          readOnly={true}
        />
      </div>
      <FlexeButton level="primary" text="Save" type="submit" isDisabled={props.disabled} />
    </form>
  );
};

export default ItemPropertiesMetadataForm;
