/* eslint-disable max-len */
import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {Item} from '../../shared/services/ItemMasterService';
import FlexeButton from '../FlexeButton';
import {CycleCountItem} from './CycleCountInterfaces';
import {LpnDetailsModalState} from './CycleCountDetail';
import LpnMetadataForm from './LpnMetadataForm';

interface Props {
  id: string;
  forShipper: boolean;
  showLpnDetailsModal: LpnDetailsModalState;
  disabled: boolean;
  cycleCountItem: CycleCountItem;
  activeWorkersExist: boolean;
  renderCurrentWorkers: () => JSX.Element;
  toggleLpnDetailsModalWithData: (data: string) => void;
  toggleLpnDetailsModalWithEvent: (event: any) => void;
  saveLpnMetadataEdits: (data) => Promise<void>;
  inventoryInfoForCCItem: Item[];
}

const LpnDetailsModal: FunctionComponent<Props> = (props) => {
  const linkPrefix = props.forShipper ? 's' : 'wh';
  const lpnsMatch = props.cycleCountItem.lpnBarcode === props.showLpnDetailsModal.id;

  return (
    <LegacyModal
      id={props.cycleCountItem.lpnBarcode}
      title={`Edit LPN ${props.cycleCountItem.lpnBarcode}`}
      show={lpnsMatch && props.showLpnDetailsModal.visible}
      size="small"
      toggleModal={props.toggleLpnDetailsModalWithEvent}
      footer={
        <FlexeButton
          text="Cancel"
          testid={props.cycleCountItem.lpnBarcode}
          level={'collapsed-link'}
          handleClick={props.toggleLpnDetailsModalWithEvent}
        />
      }
      disableClose={false}
    >
      {props.activeWorkersExist ? (
        <React.Fragment>
          <div className="row space-below">
            <p>
              Editing or submitting are disabled while mobile users are in progress. To edit here, mobile users must
              submit their work from the device.
            </p>
            <p>The following workers are still active:</p>
            {props.renderCurrentWorkers()}
          </div>
          <div>
            You can still{' '}
            <a target="_blank" href={`/${linkPrefix}/lpns/${props.cycleCountItem.lpnBarcode}`}>
              view details
            </a>{' '}
            for {props.cycleCountItem.lpnBarcode}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            <a target="_blank" href={`/${linkPrefix}/lpns/${props.cycleCountItem.lpnBarcode}`}>
              View details
            </a>{' '}
            for {props.cycleCountItem.lpnBarcode}
          </p>
          <LpnMetadataForm
            id={props.cycleCountItem.lpnBarcode}
            forShipper={props.forShipper}
            disabled={props.disabled}
            cycleCountItem={props.cycleCountItem}
            toggleLpnDetailsModalWithData={props.toggleLpnDetailsModalWithData}
            toggleLpnDetailsModalWithEvent={props.toggleLpnDetailsModalWithEvent}
            saveLpnMetadataEdits={props.saveLpnMetadataEdits}
            inventoryInfoForCCItem={props.inventoryInfoForCCItem}
          />
        </React.Fragment>
      )}
    </LegacyModal>
  );
};

export default LpnDetailsModal;
