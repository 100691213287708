import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {WaveDetailsContext} from './WaveDetailsContext';

// eslint-disable-next-line @typescript-eslint/ban-types
const WaveProgress: React.FC<{}> = () => {
  const {batches} = useContext(WaveDetailsContext);
  const [totalUnits, setTotalUnits] = useState<string>(null);
  const [pickedUnits, setPickedUnits] = useState<string>(null);

  useEffect(() => {
    calculateExpectedTotalUnits();
    calculateTotalPickedUnits();
  }, [batches]);

  const calculateExpectedTotalUnits = (): void => {
    let total = 0;
    batches.forEach((batch) => {
      total += batch.pickSteps.reduce((previousValue, step) => previousValue + step.requiredQuantity, 0);
    });
    setTotalUnits(total.toString());
  };

  const calculateTotalPickedUnits = (): void => {
    let total = 0;
    batches.forEach((batch) => {
      total += batch.pickSteps.reduce((previousValue, step) => previousValue + step.pickedQuantity, 0);
    });
    setPickedUnits(total.toString());
  };

  return (
    <div className="widget-container">
      <div className="container-fluid">
        <div className="header">
          <h2>Progress</h2>
        </div>

        <div className="widget-details">
          <div className="info-pair">
            <span className="title">Expected Total:</span>
            <span className="value">{totalUnits}</span>
          </div>
          <div className="info-pair">
            <span className="title">Picked:</span>
            <span className="value">{pickedUnits}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaveProgress;
