import * as React from 'react';

function Help(props) {
  return (
    <div>
      <h4>
        <a onClick={props.onClick}>
          More about uploading CSV files
          <i className={props.showNotes ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i>
        </a>
      </h4>
      <div id="help-content" className={props.showNotes ? 'show' : 'hidden'}>
        {props.children}
      </div>
    </div>
  );
}

export default Help;
