import * as React from 'react';
import {Cookies, withCookies} from 'react-cookie';
import {FilterOption} from './CommonInterfaces';

// This component is a near-duplicate of WarehouseSelector, except that this version
// takes an array of FIlterOptions instead of an array of Warehouses

interface Props {
  cookies?: Cookies;
  selectedWarehouse: FilterOption;
  activeWarehouses: FilterOption[];
  inactiveWarehouses?: FilterOption[];
  onSelect(selectedWarehouse: FilterOption);
}

interface State {
  open: boolean;
}

class FilterOptionWarehouseSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false
    };
  }

  public componentDidMount() {
    // Close the dropdown when the user clicks off of it
    document.body.addEventListener('mouseup', this.closeDropdownIfClickedElsewhere);
  }

  public componentWillUnmount() {
    // Remove the event handler on the body when the component unmounts
    document.body.removeEventListener('mouseup', this.closeDropdownIfClickedElsewhere);
  }

  public render() {
    const {selectedWarehouse, activeWarehouses, inactiveWarehouses} = this.props;
    let warehouseOptions = [];

    if (activeWarehouses && activeWarehouses.length) {
      warehouseOptions.push(
        <li key="active-header" className="dropdown-header">
          Warehouses
        </li>
      );
      warehouseOptions = warehouseOptions.concat(
        activeWarehouses.map((wh) => this.createWarehouseOption(wh, selectedWarehouse))
      );
    }

    if (inactiveWarehouses && inactiveWarehouses.length) {
      warehouseOptions.push(
        <li key="inactive-header" className="dropdown-header">
          Warehouses not currently storing inventory
        </li>
      );
      warehouseOptions = warehouseOptions.concat(
        inactiveWarehouses.map((wh) => this.createWarehouseOption(wh, selectedWarehouse))
      );
    }

    return (
      <React.Fragment>
        <span>Warehouse:</span>
        <div className="dropdown warehouse-selector" data-testid="fo-wh-selector">
          <a
            data-testid="select-warehouse"
            className="selected-warehouse"
            title="Select a warehouse..."
            onClick={this.toggleDropdown}
          >
            <span className="val">{this.getWarehouseFriendlyName(selectedWarehouse)}</span>
            <span className="caret"></span>
          </a>
          {this.state.open && (
            <ul className="dropdown-menu right-align show" data-testid="wh-option-list">
              {warehouseOptions}
            </ul>
          )}
        </div>
      </React.Fragment>
    );
  }

  private createWarehouseOption(wh: FilterOption, selectedWarehouse: FilterOption) {
    const selectedClass = wh.value === selectedWarehouse.value ? 'selected' : '';
    return (
      <li key={wh.value}>
        <a
          href="#"
          onClick={this.handleSelect}
          className={selectedClass}
          data-id={wh.value}
          data-testid={`${wh.value}${selectedClass}`}
        >
          {this.getWarehouseFriendlyName(wh)}
        </a>
      </li>
    );
  }

  private getWarehouseFriendlyName(wh: FilterOption) {
    return `${wh.displayName}`;
  }

  private toggleDropdown = (event) => {
    event.preventDefault();
    this.setState({open: !this.state.open});
  };

  private closeDropdownIfClickedElsewhere = (event) => {
    let element = event.target;
    while (element.parentNode) {
      if (element.classList && element.classList.contains('warehouse-selector')) {
        // Do nothing
        return;
      } else {
        element = element.parentNode;
      }
    }
    event.preventDefault();
    this.setState({open: false});
  };

  private handleSelect = (event) => {
    event.preventDefault();
    const selectedId = parseInt(event.currentTarget.getAttribute('data-id'), 10);

    const selectedWarehouse = this.props.activeWarehouses.concat(this.props.inactiveWarehouses).find((wh) => {
      return wh.value === selectedId;
    });

    const {cookies} = this.props;

    if (selectedWarehouse) {
      if (cookies) {
        cookies.set('selected_warehouse_id', selectedWarehouse.value, {path: '/'});
      }
      this.props.onSelect(selectedWarehouse);
      this.setState({open: false});
    }
  };
}

export default withCookies(FilterOptionWarehouseSelector);
