import axios from 'axios';
import * as React from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

export interface ShippingAddress {
  id?: number;
  owner?: any;
  addr_line_1: string;
  addr_line_2: string;
  addr_line_3?: string;
  addr_line_4?: string;
  addr_locality: string;
  addr_region: string;
  addr_postcode: string;
  addr_country: string;
  name: string;
  phone: string;
}

interface ShippingAddressProps {
  authenticityToken: string;
  canEdit: boolean;
  reservationId: number;
  returnAddress?: ShippingAddress;
  warehouseAddress: string;
  warehouseName: string;
}

interface ShippingAddressState {
  editing: boolean;
  addr_country: string;
  addr_line_1: string;
  addr_line_2: string;
  addr_locality: string;
  addr_region: string;
  addr_postcode: string;
  name: string;
  phone: string;
  shippingAddress: ShippingAddress;
  showModal: boolean;
  inputErrors: string[];
  errorMessage: string;
}

export class ShippingAddressDetail extends React.Component<ShippingAddressProps, ShippingAddressState> {
  constructor(props) {
    super(props);
    if (this.props.returnAddress) {
      this.state = {
        addr_country: this.props.returnAddress.addr_country,
        addr_line_1: this.props.returnAddress.addr_line_1,
        addr_line_2: this.props.returnAddress.addr_line_2,
        addr_locality: this.props.returnAddress.addr_locality,
        addr_postcode: this.props.returnAddress.addr_postcode,
        addr_region: this.props.returnAddress.addr_region,
        editing: false,
        errorMessage: null,
        inputErrors: [],
        name: this.props.returnAddress.name,
        phone: this.props.returnAddress.phone,
        shippingAddress: this.props.returnAddress,
        showModal: false
      };
    } else {
      this.state = {
        addr_country: '',
        addr_line_1: '',
        addr_line_2: '',
        addr_locality: '',
        addr_postcode: '',
        addr_region: '',
        editing: false,
        errorMessage: null,
        inputErrors: [],
        name: '',
        phone: '',
        shippingAddress: null,
        showModal: false
      };
    }
  }

  public render() {
    let displayAddress = this.props.warehouseAddress;
    if (this.state.shippingAddress) {
      displayAddress = `${this.state.shippingAddress.addr_line_1} ${this.state.shippingAddress.addr_line_2}
         ${this.state.shippingAddress.addr_locality}, ${this.state.shippingAddress.addr_region}
         ${this.state.shippingAddress.addr_postcode}`;
    }
    return (
      <div className="shipping-address">
        <h3>
          Return info
          {this.props.canEdit && (
            <a onClick={() => this.toggleModal()}>
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
          )}
        </h3>
        <hr className="no-margin" />
        <p className="space-above space-below">
          <strong>Contact:</strong>
          {this.state.shippingAddress ? this.state.shippingAddress.name : <em>Not specified</em>}
        </p>
        <p className="space-below">
          <strong>Phone Number:</strong>
          {this.state.shippingAddress ? this.state.shippingAddress.phone : <em>Not specified</em>}
        </p>
        <p className="space-below">
          <strong>Returns:</strong>
          <br />
          {displayAddress}
        </p>
        <TransitionGroup>
          {this.state.showModal && (
            <CSSTransition classNames="shipping-address-modal" timeout={250}>
              <div className="address-modal">
                <div className="modal-backdrop"></div>
                <div className="modal" id="myModal" role="dialog" aria-labelledby="myModalLabel">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button type="button" className="close" aria-label="Close" onClick={() => this.toggleModal()}>
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 className="modal-title" id="myModalLabel">
                          Edit Return Address
                        </h4>
                      </div>
                      <div className="modal-body">
                        <p>
                          <strong>{this.props.warehouseName}</strong>
                        </p>
                        {this.state.errorMessage && (
                          <div className="alert alert-danger" role="alert">
                            {this.state.errorMessage}
                          </div>
                        )}
                        <div className="row">
                          <div
                            className={
                              this.state.inputErrors.indexOf('name') === -1
                                ? 'col-md-6 form-group'
                                : 'col-md-6 form-group has-error'
                            }
                          >
                            <label htmlFor="name">Return Contact</label>
                            <input
                              required
                              type="text"
                              value={this.state.name}
                              className="form-control"
                              name="name"
                              placeholder="Name"
                              onChange={(e) => this.handleAddressChange(e)}
                            />
                          </div>
                          <div
                            className={
                              this.state.inputErrors.indexOf('phone') === -1
                                ? 'col-md-6 form-group'
                                : 'col-md-6 form-group has-error'
                            }
                          >
                            <label htmlFor="phone">Phone</label>
                            <input
                              required
                              type="text"
                              value={this.state.phone}
                              className="form-control"
                              name="phone"
                              placeholder="Phone"
                              onChange={(e) => this.handleAddressChange(e)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={
                              this.state.inputErrors.indexOf('addr_line_1') === -1
                                ? 'col-md-6 form-group'
                                : 'col-md-6 form-group has-error'
                            }
                          >
                            <label htmlFor="address1">Address 1</label>
                            <input
                              required
                              type="text"
                              value={this.state.addr_line_1}
                              className="form-control"
                              name="addr_line_1"
                              placeholder="Address Line 1"
                              onChange={(e) => this.handleAddressChange(e)}
                            />
                          </div>
                          <div className="col-md-6 form-group">
                            <label htmlFor="address2">Address 2</label>
                            <input
                              type="text"
                              value={this.state.addr_line_2}
                              className="form-control"
                              name="addr_line_2"
                              placeholder="Address Line 2"
                              onChange={(e) => this.handleAddressChange(e)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={
                              this.state.inputErrors.indexOf('addr_locality') === -1
                                ? 'col-md-8 form-group'
                                : 'col-md-8 form-group has-error'
                            }
                          >
                            <label htmlFor="city">City</label>
                            <input
                              required
                              type="text"
                              value={this.state.addr_locality}
                              className="form-control"
                              name="addr_locality"
                              placeholder="City"
                              onChange={(e) => this.handleAddressChange(e)}
                            />
                          </div>
                          <div
                            className={
                              this.state.inputErrors.indexOf('addr_region') === -1
                                ? 'col-md-4 form-group'
                                : 'col-md-4 form-group has-error'
                            }
                          >
                            <label htmlFor="state">{this.state.addr_country === 'CA' ? 'Province' : 'State'}</label>
                            <input
                              required
                              type="text"
                              value={this.state.addr_region}
                              className="form-control"
                              maxLength={2}
                              name="addr_region"
                              placeholder="State"
                              onChange={(e) => this.handleAddressChange(e)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={
                              this.state.inputErrors.indexOf('addr_postcode') === -1
                                ? 'col-md-4 form-group'
                                : 'col-md-4 form-group has-error'
                            }
                          >
                            <label htmlFor="zip">{this.state.addr_country === 'CA' ? 'Postal Code' : 'Zip Code'}</label>
                            <input
                              required
                              type="text"
                              value={this.state.addr_postcode}
                              className="form-control"
                              name="addr_postcode"
                              placeholder="Zip"
                              onChange={(e) => this.handleAddressChange(e)}
                            />
                          </div>
                          <div
                            className={
                              this.state.inputErrors.indexOf('addr_country') === -1
                                ? 'col-md-8 form-group'
                                : 'col-md-8 form-group has-error'
                            }
                          >
                            <label htmlFor="addr_country">Country</label>
                            <select
                              value={this.state.addr_country}
                              className="form-control"
                              name="addr_country"
                              onChange={(e) => this.handleAddressChange(e)}
                            >
                              <option value="">Choose a Country</option>
                              <option value="US">United States</option>
                              <option value="CA">Canada</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <a onClick={() => this.toggleModal()}>Cancel</a>
                        <button type="button" className="btn" onClick={() => this.updateShippingAddress()}>
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    );
  }

  private handleAddressChange(event) {
    const updatedState = {};
    updatedState[event.target.name] = event.target.value;
    this.setState(updatedState);
  }

  private toggleModal() {
    this.setState({showModal: !this.state.showModal});
  }

  private updateShippingAddress() {
    const validationErrors = this.validateShippingAddress();
    if (validationErrors.length === 0) {
      if (this.state.shippingAddress) {
        axios
          .patch(`/s/reservations/${this.props.reservationId}/shipping_address/${this.state.shippingAddress.id}`, {
            address: {
              addr_country: this.state.addr_country,
              addr_line_1: this.state.addr_line_1,
              addr_line_2: this.state.addr_line_2,
              addr_locality: this.state.addr_locality,
              addr_postcode: this.state.addr_postcode,
              addr_region: this.state.addr_region,
              name: this.state.name,
              phone: this.state.phone
            },
            authenticity_token: this.props.authenticityToken
          })
          .then((res) => {
            this.setState({
              errorMessage: null,
              inputErrors: [],
              shippingAddress: {
                addr_country: this.state.addr_country,
                addr_line_1: this.state.addr_line_1,
                addr_line_2: this.state.addr_line_2,
                addr_locality: this.state.addr_locality,
                addr_postcode: this.state.addr_postcode,
                addr_region: this.state.addr_region,
                id: this.state.shippingAddress.id,
                name: this.state.name,
                phone: this.state.phone
              },
              showModal: !this.state.showModal
            });
          })
          .catch((error) => {
            this.handleError();
          });
      } else {
        axios
          .post(`/s/reservations/${this.props.reservationId}/shipping_address`, {
            address: {
              addr_country: this.state.addr_country,
              addr_line_1: this.state.addr_line_1,
              addr_line_2: this.state.addr_line_2,
              addr_locality: this.state.addr_locality,
              addr_postcode: this.state.addr_postcode,
              addr_region: this.state.addr_region,
              name: this.state.name,
              phone: this.state.phone
            },
            authenticity_token: this.props.authenticityToken
          })
          .then((res) => {
            if (res.data) {
              this.setState({
                errorMessage: null,
                inputErrors: [],
                shippingAddress: res.data,
                showModal: !this.state.showModal
              });
            } else {
              this.handleError();
            }
          })
          .catch((error) => {
            this.handleError();
          });
      }
    } else {
      this.setState({
        errorMessage: 'Provide required information',
        inputErrors: validationErrors
      });
    }
  }

  private validateShippingAddress() {
    // only validates required fields
    const errors = [];
    const requiredKeys = [
      'addr_country',
      'addr_line_1',
      'addr_locality',
      'addr_region',
      'addr_postcode',
      'name',
      'phone'
    ];
    requiredKeys.forEach((key) => {
      if (!this.state[key]) {
        errors.push(key);
      }
    });
    return errors;
  }

  private handleError() {
    this.setState({
      errorMessage: 'Something went wrong. Please double check input and try again.'
    });
  }
}
