import * as React from 'react';
import {cloneDeep, get} from 'lodash';
import axios from 'axios';
import {useContext, useState} from 'react';
import {useEffect} from 'react';
import {AlteredDropoffConfirmation} from '../shared/AlteredDropoffReconfirmation';
import ConfirmationOfDelivery from '../shared/ConfirmationOfDelivery';
import {
  ContainerDelivery,
  ContainerDeliveryInProgressStates,
  ContainerDeliveryState,
  LpnContent,
  PackingLists
} from '../shared/DropoffInterfaces';
import {
  ApiResponse,
  Company,
  CompanyType,
  Document,
  DocumentNotableType,
  Reservation
} from '../../shared/CommonInterfaces';
import DropoffHelper from '../shared/DropoffHelper';
import DropoffContext from '../../contexts/DropoffContext';
import {EMPTY_PACKING_LISTS} from '../shared/DropoffConstants';
import {BLANK_CONTAINER} from '../shared/DropoffConstants';
import {DeliverySummary} from '../shared/DeliverySummary';
import {InboundDetailsHeaderPane} from '../shared/InboundDetailsHeaderPane';
import {LpnStatus} from '../../lpns/LpnsInterfaces';
import LpnConfirmedDropoff from './LpnConfirmedDropoff';

const LpnDropoff: React.FC = () => {
  const context = useContext(DropoffContext);
  const notableType = DocumentNotableType.container;
  //state
  const [errors, setErrors] = React.useState<string[]>([]);
  const [successMessage, setSuccessMessage] = React.useState<string>(null);
  const [containerDelivery, setContainerDelivery] = React.useState<ContainerDelivery>(BLANK_CONTAINER);
  const [containerDeliveryStartTime, setContainerDeliveryStartTime] = React.useState<number>(
    context.containerDeliveryStartTime
  );
  const [containerDeliveryEndTime, setContainerDeliveryEndTime] = React.useState<number>(
    context.containerDeliveryEndTime
  );
  const [shipperCompany, setShipperCompany] = React.useState<Company>({
    id: 0,
    enableTihiDuringInbound: false
  });
  const [reservation, setReservation] = React.useState<Reservation>({
    id: 0,
    supportsFulfillment: true,
    usesMobile: false
  });
  const [lpnContents, setLpnContents] = React.useState<LpnContent[]>([]);
  const [packingLists, setPackingLists] = React.useState<PackingLists>(EMPTY_PACKING_LISTS);
  const [originalPackingLists, setOriginalPackingLists] = React.useState<PackingLists>(EMPTY_PACKING_LISTS);
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [instructions, setInstructions] = React.useState<string>(null);
  const [inProgressDropoffIds, setInProgressDropoffIds] = React.useState<number[]>([]);
  const [containerLoaded, setContainerLoaded] = React.useState<boolean>(false);
  const [lpnStateData, setLpnStateData] = React.useState(new Map<string, LpnStatus>());
  const [inputTextMap, setInputTextMap] = useState<Map<string, string>>(new Map<string, string>());
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isBolUploaded, setIsBolUploaded] = useState<boolean>(false);

  const {
    containersService,
    containerDeliveryId,
    lpnService,
    documentsService,
    enableInboundPackaging,
    receivingListPath,
    warehouseService,
    showEditLpnForAsn
  } = context;

  //replaces componentDidMount
  useEffect(() => {
    loadContainer();
  }, []);

  useEffect(() => {
    if (containerLoaded) {
      loadDocuments();
    }
    // if these start_time and end_time are NOT entered yet, we know this is a new container delivery.
    // We cannot rely on the txn state because in the UI, confirming is a 2-step process.

    if (containerLoaded && DropoffHelper.isInboundConfirmedButNotReceiving(containerDelivery)) {
      setIsEditing(true);
    }
  }, [containerLoaded]);

  const handleInputTextMap = (eventTargetValue: string, propertyKey: string) => {
    const updatedInputTextMap: Map<string, string> = new Map<string, string>(inputTextMap);
    updatedInputTextMap.set(propertyKey, eventTargetValue);
    setInputTextMap(updatedInputTextMap);
  };

  const loadContainer = async () => {
    const response = await containersService.getShipment(containerDeliveryId);
    if (response && response.errors.length === 0) {
      const responseContainerDelivery: ContainerDelivery = response.data.shipmentDetails;
      const startTime = responseContainerDelivery.receiving.startAt
        ? DropoffHelper.hoursAndMinutesToMs(responseContainerDelivery.receiving.startAt)
        : containerDeliveryStartTime;
      const endTime = responseContainerDelivery.receiving.endAt
        ? DropoffHelper.hoursAndMinutesToMs(responseContainerDelivery.receiving.endAt)
        : containerDeliveryEndTime;
      const responsePackingLists = response.data.packingLists;
      const responseInProgressDropoffIds = response.data.inProgressForReservation
        .sort((d1, d2) => (new Date(d1.createdAt) > new Date(d2.createdAt) ? -1 : 1))
        .map((dropoff) => dropoff.id);
      setOriginalPackingLists(cloneDeep(responsePackingLists));

      setContainerDelivery(responseContainerDelivery);
      setContainerDeliveryStartTime(startTime);
      setContainerDeliveryEndTime(endTime);
      setShipperCompany(response.data.shipperCompany);
      setReservation(response.data.reservation);
      // This will be updated to lpnContents once container API schema is updated.
      if (context.lpnReceiveOnly) {
        await loadLpnStateData(response.data.lpnContents);
      }
      setLpnContents(response.data.lpnContents);
      setInProgressDropoffIds(responseInProgressDropoffIds);
      setInstructions(response.data.shipmentDetails.instructions);
      setContainerLoaded(true);
      setPackingLists(cloneDeep(responsePackingLists));
    } else {
      handleApiError([`There was an error retrieving information for dropoff #${containerDeliveryId}`], response);
      setContainerLoaded(false);
    }
  };

  const recalculatePackingListsBasedOnLpns = (lpns: LpnContent[]) => {
    const newPackingLists = cloneDeep(originalPackingLists);
    const updatedPackingLists = containersService.calculatePackingListsBasedOnLpns(
      newPackingLists.expected,
      newPackingLists.actual,
      lpns
    );

    newPackingLists.expected = updatedPackingLists.expected;
    newPackingLists.shippable = updatedPackingLists.shippable;
    setPackingLists(newPackingLists);
    setLpnContents(lpns);
  };

  const onUpdateInstructions = (newInstructions: string) => {
    const updatedContainerDelivery = cloneDeep(containerDelivery);
    updatedContainerDelivery.instructions = newInstructions;
    setContainerDelivery(updatedContainerDelivery);
  };

  const handleIsBolUploaded = (isUploaded: boolean) => {
    setIsBolUploaded(isUploaded);
  };

  const loadDocuments = async () => {
    let err;
    try {
      const [shipperDocs, warehouseDocs] = await axios.all([
        documentsService.getDocuments(CompanyType.shipper, reservation.id, notableType, containerDeliveryId),
        documentsService.getDocuments(CompanyType.warehouse, reservation.id, notableType, containerDeliveryId)
      ]);
      const updatedDocuments = shipperDocs.concat(warehouseDocs);
      const updatedContainerDelivery = cloneDeep(containerDelivery);
      updatedContainerDelivery.hasBillOfLading = DropoffHelper.hasBillOfLading(updatedDocuments, shipperCompany.id);
      setDocuments(updatedDocuments);
      setContainerDelivery(updatedContainerDelivery);
    } catch (errorResponse) {
      err = get(errorResponse, 'response.data.errors', []).map((error) => error.detail);
    } finally {
      setErrors(err);
    }
  };

  const loadLpnStateData = async (snapshots) => {
    const lpnData: Map<string, LpnStatus> = new Map<string, LpnStatus>();

    // the number of lpn in one api call, we don't want to put for example 3000 lpns in param of one GET API call
    const lpnAmountPerApiCall = 200;
    // requested lpn barcode string array
    const lpnBarcodeList = snapshots.map(({lpnBarcode}) => lpnBarcode);

    // each lpnService API call carry at most the amount of lpnAmountPerApiCall of lpn barcode
    // e.g. 3100 lpns will trigger Math.ceil(3100 / lpnAmountPerApiCall) lpnService call
    const maxNumOfApiCalls = Math.ceil(lpnBarcodeList.length / lpnAmountPerApiCall);
    let counter = 0;
    while (counter < maxNumOfApiCalls) {
      const {startIndex, endIndex} = DropoffHelper.getCurrentIndexRangeOfLpn(
        lpnAmountPerApiCall,
        counter,
        maxNumOfApiCalls,
        lpnBarcodeList
      );

      // call /api/v2/lpns/retrieve to get the list of LPN response
      const response = await lpnService.searchLpns({
        LpnBarcodes: lpnBarcodeList.slice(startIndex, endIndex + 1),
        ShipmentIds: [],
        ReservationIds: [],
        Skus: [],
        IncludeArchived: true
      });
      DropoffHelper.updateLpnStateData(lpnBarcodeList, response, lpnData);
      counter++;
    }
    setLpnStateData(lpnData);
  };

  const resetSuccessMessage = () => {
    window.setTimeout(() => {
      setSuccessMessage(null);
    }, 5000);
  };

  const handleErrors = (err: string[], response) => {
    handleApiError(err, response);
  };

  const handleContainerDeliveryUpdate = (cd: ContainerDelivery) => {
    setContainerDelivery(cd);
  };

  const handleSavedDoc = (savedDoc: Document) => {
    const updatedDocuments = documents.concat(savedDoc);
    const updatedContainerDelivery = cloneDeep(containerDelivery);
    updatedContainerDelivery.hasBillOfLading = DropoffHelper.hasBillOfLading(updatedDocuments, shipperCompany.id);
    setDocuments(updatedDocuments);
    setContainerDelivery(updatedContainerDelivery);
  };

  const handleApiError = (err: string[], response: ApiResponse<any>) => {
    if (response) {
      err[0] += ':';
      response.errors.map((error) => err.push(error.detail));
    }
    setErrors(err);
  };
  const isUsingExpectedLpns = (pl = packingLists) => {
    // Have to have this length check for the initial render,
    // otherwise expected packing lists should always exist
    return pl.expected.length > 0 && !!pl.expected[0].lpn;
  };

  const showReadOnlyPackingLists =
    containerDelivery.txnState === ContainerDeliveryState.new ||
    containerDelivery.txnState === ContainerDeliveryState.altered ||
    containerDelivery.txnState === ContainerDeliveryState.completed ||
    containerDelivery.txnState === ContainerDeliveryState.underReview;

  const renderSkuSummary = () => {
    if (showReadOnlyPackingLists && !isUsingExpectedLpns()) {
      return (
        <DeliverySummary
          packingLists={packingLists}
          lpnContents={lpnContents}
          lpnReceiveOnly={context.lpnReceiveOnly}
          reservationId={reservation.id}
          containerDeliveryId={containerDelivery.id}
          inProgressDropoffIds={inProgressDropoffIds}
          lpnStateData={lpnStateData}
          recalculatePackingListsBasedOnLpns={recalculatePackingListsBasedOnLpns}
          containerDeliveryState={containerDelivery.txnState as ContainerDeliveryState}
        />
      );
    } else {
      return null;
    }
  };

  const renderExpectedLpnContents = () => {
    // eslint-disable-next-line max-len
    if (
      isUsingExpectedLpns() &&
      !ContainerDeliveryInProgressStates.includes(containerDelivery.txnState as ContainerDeliveryState)
    ) {
      return (
        <DeliverySummary
          packingLists={packingLists}
          lpnContents={lpnContents}
          lpnReceiveOnly={context.lpnReceiveOnly}
          reservationId={reservation.id}
          containerDeliveryId={containerDelivery.id}
          inProgressDropoffIds={inProgressDropoffIds}
          lpnStateData={lpnStateData}
          recalculatePackingListsBasedOnLpns={recalculatePackingListsBasedOnLpns}
          containerDeliveryState={containerDelivery.txnState as ContainerDeliveryState}
          showEditLpnForAsn={showEditLpnForAsn}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className="content">
      {
        <div className="new-inbound-header-pane">
          <InboundDetailsHeaderPane
            containerDelivery={containerDelivery}
            containersService={containersService}
            warehouseService={warehouseService}
            onUpdateInstructions={(newInstructions: string) => onUpdateInstructions(newInstructions)}
            documents={documents}
            onDocumentSaved={(document: Document) => handleSavedDoc(document)}
            errors={errors}
            reservation={reservation}
            reservationId={reservation.id}
            successMessage={successMessage}
            enableInboundPackaging={enableInboundPackaging}
            onInputTextMap={handleInputTextMap}
            inputTextMap={inputTextMap}
            isEditing={isEditing}
            handleIsBolUploaded={handleIsBolUploaded}
            isLpnReceiveEnabled={true}
            isShipper={false}
            isEditMode={false}
            handleRefreshPage={() => window.location.reload()}
          />
        </div>
      }

      <div className="container-fluid" id="dropoff-detail">
        {containerDelivery.txnState === ContainerDeliveryState.new && (
          <ConfirmationOfDelivery
            containerDelivery={containerDelivery}
            containerDeliveryStartTime={containerDeliveryStartTime}
            containerDeliveryEndTime={containerDeliveryEndTime}
            containersService={containersService}
            instructions={instructions}
            handleApiError={handleApiError}
            setSuccessMessage={setSuccessMessage}
            resetSuccessMessage={resetSuccessMessage}
            reloadContainer={loadContainer}
            reservation={reservation}
          />
        )}

        {containerDelivery.txnState === ContainerDeliveryState.altered && (
          <AlteredDropoffConfirmation
            containersService={containersService}
            containerDelivery={containerDelivery}
            instructions={instructions}
            containerDeliveryStartTime={containerDeliveryStartTime}
            containerDeliveryEndTime={containerDeliveryEndTime}
            handleApiError={handleApiError}
            setSuccessMessage={setSuccessMessage}
            resetSuccessMessage={resetSuccessMessage}
            reloadContainer={loadContainer}
          />
        )}

        {ContainerDeliveryInProgressStates.includes(containerDelivery.txnState as ContainerDeliveryState) && (
          <LpnConfirmedDropoff
            lpnReceiveOnly={context.lpnReceiveOnly}
            lpnStateData={lpnStateData}
            enableInboundPackaging={enableInboundPackaging}
            containerDelivery={containerDelivery}
            containersService={containersService}
            lpnContents={lpnContents}
            packingLists={packingLists}
            isUsingExpectedLpns={isUsingExpectedLpns()}
            reservation={reservation}
            shipperCompany={shipperCompany}
            handleContainerDeliveryUpdate={handleContainerDeliveryUpdate}
            handleErrors={handleErrors}
            reloadContainer={loadContainer}
            resetSuccessMessage={resetSuccessMessage}
            setSuccessMessage={setSuccessMessage}
            documents={documents}
            onDocumentSaved={(document: Document) => handleSavedDoc(document)}
            recalculatePackingListsBasedOnLpns={recalculatePackingListsBasedOnLpns}
            receivingListPath={receivingListPath}
            inProgressDropoffIds={inProgressDropoffIds}
            inputTextMap={inputTextMap}
            setIsEditing={setIsEditing}
            isBolUploaded={isBolUploaded}
            showEditLpnForAsn={showEditLpnForAsn}
          />
        )}
        {renderSkuSummary()}
        {renderExpectedLpnContents()}
      </div>
    </div>
  );
};

export default LpnDropoff;
