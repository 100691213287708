import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import axios from 'axios';

const DEFAULT_SESSION_TIMEOUT = 1000 * 60 * 60 * 24; // 24 hours
const IDLE_MODAL_LIFESPAN = 1000 * 60 * 5; // 5 min
const ACTIVITY_CHECK_INTERVAL = 1000 * 60 * 5; // 5 min
const BUFFER = 1000 * 10;

const EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange'
];

const FLEXE_MESSAGE_KEY = 'flexe_message';

interface IdleMonitorProp {
  sessionTimeoutInSeconds: number;
  logout: (event: Event) => void;
}

interface IdleMonitorState {
  modalOpen: boolean;
  countdown: number;
}

class IdleMonitor extends React.Component<IdleMonitorProp, IdleMonitorState> {
  private isUserActive: boolean;
  private sessionTimeoutDuration: number;

  private idleModalTimer;
  private idleModalEndTimer;
  private checkUserActionTimer;
  private countdownInterval;

  constructor(props) {
    super(props);

    this.sessionTimeoutDuration = DEFAULT_SESSION_TIMEOUT;
    this.state = {
      modalOpen: false,
      countdown: 0
    };
  }

  public componentDidMount() {
    this.isUserActive = false;
    this.idleModalTimer = null;
    this.idleModalEndTimer = null;
    this.checkUserActionTimer = null;
    this.countdownInterval = null;

    EVENTS.forEach((event) => {
      window.addEventListener(event, this.updateIsUserActive);
    });

    window.addEventListener('storage', this.receiveMessage);
    this.idleModalTimer = setTimeout(this.onIdle, this.sessionTimeoutDuration - IDLE_MODAL_LIFESPAN - BUFFER);
    this.checkUserActionTimer = setTimeout(this.extendSession, ACTIVITY_CHECK_INTERVAL);
  }

  public render() {
    return (
      <div>
        <LegacyModal
          id="session-timeout-warning"
          title="Your session is about to expire"
          show={this.state.modalOpen}
          size="small"
          toggleModal={null}
          footer={
            <div>
              <a onClick={(event) => this.handleLogout(event)}>No, sign me out</a>
              <button
                id="perform-stay-in"
                type="button"
                className="btn btn-clear"
                onClick={(event) => this.handleStayLoggedIn()}
              >
                Yes, keep me signed in
              </button>
            </div>
          }
          hideClose={true}
        >
          <div style={{color: 'black'}}>
            <p>You will be logged out in {this.formatCountdownTime(this.state.countdown)}.</p>
            <p>Do you want to stay signed in?</p>
          </div>
        </LegacyModal>
      </div>
    );
  }

  private updateIsUserActive = () => {
    this.isUserActive = true;
  };

  private onIdle = () => {
    clearTimeout(this.idleModalTimer);
    clearTimeout(this.idleModalEndTimer);
    clearTimeout(this.checkUserActionTimer);
    this.setState({modalOpen: true});
    this.setState({countdown: IDLE_MODAL_LIFESPAN});
    this.countdownInterval = setInterval(this.updateCountDown, 1000);
    this.idleModalEndTimer = setTimeout((event) => this.handleLogout(event), IDLE_MODAL_LIFESPAN);
  };

  private extendSession = (forced = false) => {
    if (forced || this.isUserActive) {
      axios.get('/extend_session').then((resp) => {
        const newSessionTimeoutInSeconds = parseInt(resp.data, 10);
        if (isNaN(newSessionTimeoutInSeconds)) {
          // Somehow the session expired already so logout
          this.handleLogout(null);
        } else {
          // Session has been extended successfully
          if (newSessionTimeoutInSeconds === 0) {
            // No session timeout so stop IdleMonitor
            this.stopIdleMonitor();
          } else {
            // Reset modalTimer
            this.sessionTimeoutDuration = newSessionTimeoutInSeconds * 1000;
            clearTimeout(this.idleModalTimer);
            this.idleModalTimer = setTimeout(this.onIdle, this.sessionTimeoutDuration - IDLE_MODAL_LIFESPAN - BUFFER);

            this.broadcastMessage({newSessionTimeoutInMilliseconds: this.sessionTimeoutDuration});
          }
        }
      });
    }
    this.checkUserActionTimer = setTimeout(this.extendSession, ACTIVITY_CHECK_INTERVAL);
    this.isUserActive = false;
  };

  private stopIdleMonitor = () => {
    this.setState({modalOpen: false});
    clearTimeout(this.idleModalTimer);
    clearTimeout(this.idleModalEndTimer);
    clearTimeout(this.checkUserActionTimer);
    clearInterval(this.countdownInterval);
  };

  private handleLogout = (event) => {
    this.broadcastMessage({newSessionTimeoutInMilliseconds: -1});
    this.logout(event);
  };

  private logout = (event) => {
    this.setState({modalOpen: false});
    clearTimeout(this.idleModalTimer);
    clearTimeout(this.idleModalEndTimer);
    clearTimeout(this.checkUserActionTimer);
    clearInterval(this.countdownInterval);
    this.props.logout(event);
  };

  private handleStayLoggedIn = () => {
    this.extendSession(true);
    this.setState({modalOpen: false});
    this.setState({countdown: 0});
    clearTimeout(this.idleModalEndTimer);
    clearInterval(this.countdownInterval);
  };

  private updateCountDown = () => {
    if (this.state.modalOpen && this.state.countdown > 0) {
      this.setState({countdown: this.state.countdown - 1000});
    }
  };

  private formatCountdownTime = (msec) => {
    const min = Math.floor(msec / 60000);
    const sec = (msec % 60000) / 1000;

    if (min > 0) {
      return min + ' min ' + sec + ' sec';
    } else {
      return sec + ' sec';
    }
  };

  private broadcastMessage = (message) => {
    const msg = JSON.stringify(message);
    window.localStorage.setItem(FLEXE_MESSAGE_KEY, msg);
    window.localStorage.removeItem(FLEXE_MESSAGE_KEY);
  };

  private receiveMessage = (event) => {
    if (event.key !== FLEXE_MESSAGE_KEY) {
      return;
    }
    const message = JSON.parse(event.newValue);
    if (!message) {
      return;
    }

    this.handleBroadcastMessage(message.newSessionTimeoutInMilliseconds);
  };

  private handleBroadcastMessage = (newSessionTimeoutInMilliseconds) => {
    if (newSessionTimeoutInMilliseconds > 0) {
      this.setState({modalOpen: false});
      this.setState({countdown: 0});
      clearTimeout(this.idleModalTimer);
      clearTimeout(this.idleModalEndTimer);
      clearTimeout(this.checkUserActionTimer);
      clearInterval(this.countdownInterval);

      this.idleModalTimer = setTimeout(this.onIdle, newSessionTimeoutInMilliseconds - IDLE_MODAL_LIFESPAN - BUFFER);
      this.checkUserActionTimer = setTimeout(this.extendSession, ACTIVITY_CHECK_INTERVAL);
      this.isUserActive = false;
    } else {
      this.logout(null);
    }
  };
}

export default IdleMonitor;
