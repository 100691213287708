import * as React from 'react';
import {ReactFragment} from 'react';
import * as _ from 'lodash';
import {Line} from '../../shared-v2/InventorySelection';
import {Destination} from '../../../warehouse/outbound-shipments/ShipmentInterfaces';
import {Packaging, SelectableWarehouse} from '../../../shared/CommonInterfaces';
import {formatDate, pluralizeUnit} from '../../../shared/utilities/DataFormatting';
import {countryAbbrToName} from '../../../../libs/helpers';
import {AttachmentRow, AttachmentTable} from './AttachmentTable';

export interface Props {
  notes: string;
  shipTo: Destination;
  selectedWarehouse: SelectableWarehouse;
  shipBy: Date;
  shipAfter: Date;
  inventory: Line[];
  attachmentRows: AttachmentRow[];
  onEnterNotes: (notes: string) => void;
}

const dateFormat = 'MMM Do YYYY';

const OrderSummary: React.FC<Props> = (props) => {
  const {notes, shipTo, selectedWarehouse, shipBy, shipAfter, inventory, onEnterNotes, attachmentRows} = props;

  const shipFrom = selectedWarehouse ? (
    <div>
      <div>{selectedWarehouse.warehouseName}</div>
      <div>{selectedWarehouse.warehouseStreet}</div>
      <div>
        {`${selectedWarehouse.warehouseCity} ${selectedWarehouse.warehouseState} ${selectedWarehouse.warehouseZip}`}
      </div>
      <br />
    </div>
  ) : null;

  const inventoryDisplay = inventory.map((inv) => {
    return (
      <tr key={inv.inventoryId.toString()}>
        <td className="col-md-3">{_.escape(inv.sku)}</td>
        <td className="col-md-2">{`${inv.quantity} ${pluralizeUnit(inv.packaging, inv.quantity)}`}</td>
        <td className="col-md-7">{_.escape(inv.description)}</td>
      </tr>
    );
  });

  const inventorySummary = (): ReactFragment => {
    if (inventory.length === 0) {
      return <b>0 SKUS</b>;
    }

    const totalQuantities = new Map<string, number>([
      [Packaging.each, 0],
      [Packaging.carton, 0],
      [Packaging.pallet, 0]
    ]);

    inventory.forEach((i) => {
      totalQuantities.set(i.packaging, totalQuantities.get(i.packaging) + i.quantity);
    });

    const eaches = totalQuantities.get(Packaging.each);
    const cartons = totalQuantities.get(Packaging.carton);
    const pallets = totalQuantities.get(Packaging.pallet);

    return (
      <b>
        {inventory.length} {inventory.length === 1 ? 'SKU, ' : 'SKUS, '}
        {pallets > 0 ? `${pallets} ${pluralizeUnit(Packaging.pallet, pallets)} ` : ''}
        {cartons > 0 ? `${cartons} ${pluralizeUnit(Packaging.carton, cartons)} ` : ''}
        {eaches > 0 ? `${eaches} ${pluralizeUnit(Packaging.each, eaches)}` : ''}
      </b>
    );
  };

  return (
    <React.Fragment>
      <div className="col-md-12 shipping-info inner">
        <div className="col-md-4">
          <div className="col-md-3">
            <b>Ship to:</b>
          </div>
          <div className="col-md-9">
            {shipTo && (
              <div>
                <div>{shipTo.name}</div>
                <div>{shipTo.address_line_1}</div>
                {shipTo.address_line_2 && <div>{shipTo.address_line_2}</div>}
                {shipTo.address_line_3 && <div>{shipTo.address_line_3}</div>}
                <div>{`${shipTo.city} ${shipTo.state} ${shipTo.postal_code}`}</div>
                <div>{countryAbbrToName(shipTo.country)}</div>
                {(shipTo.phone || shipTo.email) && <br />}
                {shipTo.phone && <div>{shipTo.phone}</div>}
                {shipTo.email && <div>{shipTo.email}</div>}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-4">
          <div className="col-md-3">
            <b>Ship From:</b>
          </div>
          <div className="col-md-9">{shipFrom}</div>
        </div>
        <div className="col-md-4">
          {shipAfter && (
            <div>
              <b>Ship After: </b>
              {formatDate(shipAfter, dateFormat)}
            </div>
          )}
          <div className="ship-by">
            <b>Ship By: </b>
            {formatDate(shipBy, dateFormat)}
          </div>
        </div>
      </div>
      <div className="col-md-12 sku-summary inner">{inventorySummary()}</div>
      <div className="col-md-12 inventory">
        {inventoryDisplay.length > 0 && (
          <table className="table inventory-selection">
            <thead>
              <tr key="inv-table-header">
                <th>SKU</th>
                <th>Quantity</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>{inventoryDisplay}</tbody>
          </table>
        )}
      </div>
      <div className="col-md-12 inner">
        <span>
          <b>Attachments:</b>
        </span>
        {attachmentRows.length < 1 ? <p>None</p> : <AttachmentTable attachmentRows={attachmentRows} />}
      </div>
      <div className="col-md-12 notes inner">
        <span>
          <b>Notes:</b>
        </span>
        <div>
          <textarea
            id="notes-input"
            value={notes}
            placeholder="Add Details about this order..."
            onChange={(event) => onEnterNotes(event.target.value)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderSummary;
