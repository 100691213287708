import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {CycleCountDetails} from './CycleCountInterfaces';

interface Props {
  showSubmitModal: boolean;
  disabled: boolean;
  targetState: string;
  cycleCount: CycleCountDetails;
  toggleSubmitModal: () => void;
  transitionCycleCountState: (event: any) => Promise<void>;
  updateCycleCountNotes: (notes: string) => void;
}

let textAreaValue: string = '';
const handleTextAreaChange = (event) => {
  textAreaValue = event.currentTarget.value;
};

const handleSubmitClick = (event, props) => {
  props.updateCycleCountNotes(textAreaValue);
  props.transitionCycleCountState(event);
};

const CycleCountSubmitModal: FunctionComponent<Props> = (props) => {
  return (
    <LegacyModal
      id="submit-cycle-count-modal"
      title="Submit Cycle Count"
      show={props.showSubmitModal}
      size="small"
      toggleModal={props.toggleSubmitModal}
      footer={
        <div>
          <a onClick={props.toggleSubmitModal}>Cancel</a>
          <button
            id="perform-submit"
            data-testid="perform-submit"
            type="button"
            className="btn btn-primary"
            disabled={props.disabled}
            onClick={(event) => handleSubmitClick(event, props)}
            data-target-state={props.targetState}
          >
            Submit
          </button>
        </div>
      }
      disableClose={false}
    >
      <div>
        <textarea
          data-testid="notes-text-area"
          placeholder="Please provide a brief description of this cycle count..."
          onChange={handleTextAreaChange}
        >
          {props.cycleCount && props.cycleCount.userComments}
        </textarea>
      </div>
    </LegacyModal>
  );
};

export default CycleCountSubmitModal;
