import {ChangeEvent, FunctionComponent} from 'react';
import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import {formatDate} from './utilities/DataFormatting';
import HeaderDetail from './HeaderDetail';

export enum HeaderEditableDetailType {
  date = 'date',
  text = 'text',
  number = 'number' // eslint-disable-line id-denylist
}

type staticOnChangeType = (value: unknown) => void;
type dynamicOnChangeType = (event: ChangeEvent<HTMLInputElement>, propertyKey: string) => void;
type onChangeType = staticOnChangeType | dynamicOnChangeType;
type AugmentedDetailType = HeaderEditableDetailType | AugmentedDateType;

interface AugmentedDateType {
  displayDateFormat?: string;
  type: HeaderEditableDetailType.date;
}

function isAugmentedDateType(maybeDateOptions: AugmentedDetailType): maybeDateOptions is AugmentedDateType {
  return (maybeDateOptions as AugmentedDateType)?.type === HeaderEditableDetailType.date;
}

export interface HeaderEditableDetailProps {
  label: string;
  propertyKey?: string;
  value: any;
  type: AugmentedDetailType;
  onChange: onChangeType;
  classes?: string[];
  isEditing?: boolean;
  editingInstructions?: string;
  maxLength?: number;
  toolTipId?: string;
  required?: boolean;
  validations?: {
    min?: string;
  };
}

const displayDateFormat = 'MMMM D'; //ex: June 4
const inputDateFormat = 'YYYY-MM-DD'; //must be this format to display properly in a datetime-local input box

const editableDateDetail = (
  label: string,
  value: Date,
  required: boolean,
  onChange: onChangeType,
  isEditing: boolean,
  classes,
  toolTipId,
  editingInstructions,
  propertyKey?: string,
  validations?: {
    min?: string;
  },
  dateOptions?: Exclude<AugmentedDateType, 'type'>
) => {
  let displayedValue;

  if (!isEditing) {
    displayedValue = formatDate(value, dateOptions?.displayDateFormat ?? displayDateFormat, 'UTC');
    return <HeaderDetail label={label} value={displayedValue} hideWhenEmpty={false} />;
  } else {
    displayedValue = formatDate(value, inputDateFormat, 'UTC');
    return renderEditableDetail(
      label,
      displayedValue,
      classes,
      required,
      HeaderEditableDetailType.date,
      onChange,
      Number.MAX_SAFE_INTEGER,
      toolTipId,
      editingInstructions,
      propertyKey,
      validations
    );
  }
};

const editableTextDetail = (
  label: string,
  value: string,
  required: boolean,
  onChange: onChangeType,
  isEditing: boolean,
  classes: string[],
  maxLength: number,
  toolTipId?: string,
  editingInstructions?: string,
  inputType: HeaderEditableDetailType = HeaderEditableDetailType.text,
  propertyKey?: string,
  validations?: {
    min?: string;
  }
) => {
  const displayedValue = value ? value : '';

  if (!isEditing) {
    return HeaderDetail({label, value: displayedValue, hideWhenEmpty: false, classes});
  }

  return renderEditableDetail(
    label,
    displayedValue,
    classes,
    required,
    inputType,
    onChange,
    maxLength,
    toolTipId,
    editingInstructions,
    propertyKey,
    validations
  );
};

const renderEditableDetail = (
  label: string,
  value: any,
  classes: string[],
  required: boolean,
  inputType: HeaderEditableDetailType,
  onChange,
  maxLength: number,
  toolTipId?: string,
  editingInstructions?: string,
  propertyKey?: string,
  validations?: {
    min?: string;
  }
) => {
  classes.push('detail-being-edited');
  return (
    <div className="detail-field">
      <span className={'identifier' + (required ? ' required' : '')}>{label}</span>
      {toolTipId && editingInstructions && (
        <span>
          <a href="#" className="help-note" data-tip data-for={toolTipId}>
            <i className="fa fa-lg fa-question-circle"></i>
          </a>
          <ReactTooltip className="document-tooltip" place="right" type="light" id={toolTipId}>
            {editingInstructions}
          </ReactTooltip>
        </span>
      )}
      <input
        data-testid={'detail-editing-input-' + label}
        className={classNames(classes)}
        type={inputType}
        maxLength={maxLength}
        min={validations?.min}
        onChange={
          propertyKey ? (event) => onChange(event.target.value, propertyKey) : (event) => onChange(event.target.value)
        }
        value={value}
      />
    </div>
  );
};

const HeaderEditableDetail: FunctionComponent<HeaderEditableDetailProps> = (props: HeaderEditableDetailProps) => {
  const {classes = [], maxLength = Number.MAX_SAFE_INTEGER, isEditing = false} = props;

  if (props.type === HeaderEditableDetailType.number || props.type === HeaderEditableDetailType.text) {
    return editableTextDetail(
      props.label,
      props.value,
      props.required,
      props.onChange,
      isEditing,
      classes,
      maxLength,
      props.toolTipId,
      props.editingInstructions,
      props.type,
      props.propertyKey,
      props.validations
    );
  } else {
    return editableDateDetail(
      props.label,
      props.value,
      props.required,
      props.onChange,
      isEditing,
      classes,
      props.toolTipId,
      props.editingInstructions,
      props.propertyKey,
      props.validations,
      isAugmentedDateType(props.type) ? props.type : null
    );
  }
};

export default HeaderEditableDetail;
