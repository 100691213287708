import * as React from 'react';

interface Props {
  children?: JSX.Element;
  headerText: string | JSX.Element;
  index: number;
  open: boolean;
  onClick(event);
}

function ShowHide(props: Props) {
  const showHideClasses = props.open ? 'show-hide open' : 'show-hide';
  const direction = props.open ? 'down' : 'right';
  return (
    <div className={showHideClasses}>
      <div className="header" data-index={props.index} onClick={props.onClick}>
        {// eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
        `${props.headerText} `}
        <i className={`fa fa-chevron-${direction} direction`} />
      </div>
      <div className="content">{props.children}</div>
    </div>
  );
}

export default ShowHide;
