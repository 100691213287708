import axios from 'axios';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
class ReservationService {
  private authenticityToken: string;

  private baseUrl = '/s/reservations/';

  constructor(authenticityToken: string) {
    this.authenticityToken = authenticityToken;
  }

  public async getReservations() {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get(`${this.baseUrl}shipper_reservations`, {
        data: {
          authenticity_token: this.authenticityToken
        }
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }
}

export default ReservationService;
