import * as React from 'react';
import {ReservationActivity} from './DashboardInterfaces';
import FulfillmentActivity from './FulfillmentActivity';
import OverflowActivity from './OverflowActivity';

interface Props {
  authenticityToken: string;
  hasFulfillmentReservations: boolean;
  reservationActivities: ReservationActivity[];
}

interface State {
  errors: string[];
}

class Dashboard extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <div className="container-fluid" id="dashboard">
        <div className="row space-below">
          <h1 className="col-sm-12 black">Dashboard</h1>
        </div>
        {this.props.hasFulfillmentReservations && (
          <FulfillmentActivity authenticityToken={this.props.authenticityToken} />
        )}
        <OverflowActivity reservationActivities={this.props.reservationActivities} />
      </div>
    );
  }
}

export default Dashboard;
