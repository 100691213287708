import * as React from 'react';
import BasicTableCell from './BasicTableCell';

interface Props {
  data: React.ReactNode[];
}

const BasicTableRow: React.FC<Props> = (props: Props) => {
  return (
    <tr className="instructions-table-row">
      {props.data.map((datum, i) => {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
        return <BasicTableCell key={`${datum}-${i}`} content={datum} />;
      })}
    </tr>
  );
};

export default BasicTableRow;
