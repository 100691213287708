import {ApiResponse} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

export interface UpdatePackingListRequest {
  packingListId: number;
  quantity: number;
}

class PackingListService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2');
  }

  public async updatePackingList(request: UpdatePackingListRequest): Promise<Record<string, never>> {
    return this.makePatchRequest(`${this.baseUrl}/packing_lists/${request.packingListId}`, {
      quantity: request.quantity
    });
  }
}

export default PackingListService;
