import * as React from 'react';
import classNames from 'classnames';
import * as ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import RetailFulfillmentService from '../../shared/services/RetailFulfillmentService';
import {BulkSchedulePickupRequest, RetailOrderDetails} from '../../shared/services/RetailFulfillmentService';
import {ApiResponse} from '../../shared/CommonInterfaces';

interface SchedulePickupProps {
  orderDetails: RetailOrderDetails;
  retailFulfillmentService: RetailFulfillmentService;
  onSuccess: () => void;
  onError: (error: string) => void;
}

interface FormValidationErrorMessages {
  scac?: string;
  masterBol?: string;
  pickupStartTime?: string;
  pickupStartDate?: string;
  pickupEndDate?: string;
  pickupEndTime?: string;
}

// TODO SA-4384 - This code was lifted from BulkSchedulePickupsModal, so
// refactor these two components
export const SchedulePickup: React.FC<SchedulePickupProps> = (props) => {
  const [scac, setScac] = React.useState('');
  const [masterBol, setMasterBol] = React.useState(props.orderDetails.id.toString());
  const [pickupStartDate, setPickupStartDate] = React.useState<Date>();
  const [pickupStartTime, setPickupStartTime] = React.useState<Date>();
  const [pickupEndDate, setPickupEndDate] = React.useState<Date>();
  const [pickupEndTime, setPickupEndTime] = React.useState<Date>();
  const [consolidatorBound, setConsolidatorBound] = React.useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = React.useState<FormValidationErrorMessages>({});

  async function schedulePickups() {
    if (!validateFields()) {
      return;
    }

    const startDateTime = new Date(
      Date.UTC(
        pickupStartDate.getFullYear(),
        pickupStartDate.getMonth(),
        pickupStartDate.getDate(),
        pickupStartTime.getHours(),
        pickupStartTime.getMinutes()
      )
    );

    const endDateTime = new Date(
      Date.UTC(
        pickupEndDate.getFullYear(),
        pickupEndDate.getMonth(),
        pickupEndDate.getDate(),
        pickupEndTime.getHours(),
        pickupEndTime.getMinutes()
      )
    );

    const request: BulkSchedulePickupRequest = {
      orderIds: [props.orderDetails.id],
      scac,
      masterBillOfLadingNumber: masterBol,
      consolidatorBound,
      startDateTime,
      endDateTime,
      timezone: props.orderDetails.warehouse.timezone,
      changeShippingAddress: false
    };

    const response: ApiResponse<BulkSchedulePickupRequest> = await props.retailFulfillmentService.bulkSchedulePickup(
      request
    );

    if (response.errors) {
      props.onError(response.errors.map((error) => error.detail).join());
      return;
    }

    props.onSuccess();
    return response;
  }

  const fieldRequiredText = 'This field is required.';

  const validateFields = () => {
    const errors: FormValidationErrorMessages = {};

    if (scac.length > 4 || scac.length < 2) {
      errors.scac = 'Must be 2-4 characters long.';
    }

    if (masterBol.length === 0) {
      errors.masterBol = fieldRequiredText;
    }

    if (!pickupStartDate) {
      errors.pickupStartDate = fieldRequiredText;
    }

    if (!pickupStartTime) {
      errors.pickupStartTime = fieldRequiredText;
    }

    if (!pickupEndDate) {
      errors.pickupEndDate = fieldRequiredText;
    }

    if (!pickupEndTime) {
      errors.pickupEndTime = fieldRequiredText;
    }

    setValidationErrorMessages(errors);
    return Object.keys(errors).length === 0;
  };

  const preventDefault = (e) => e.preventDefault();

  // eslint-disable-next-line no-prototype-builtins
  const scacClassNames = classNames('form-group', {'has-error': validationErrorMessages.hasOwnProperty('scac')});
  const masterBolClassNames = classNames('form-group', {
    // eslint-disable-next-line no-prototype-builtins
    'has-error': validationErrorMessages.hasOwnProperty('masterBol')
  });
  const startDateClassNames = classNames('form-group', {
    // eslint-disable-next-line no-prototype-builtins
    'has-error': validationErrorMessages.hasOwnProperty('pickupStartDate')
  });
  const startTimeClassNames = classNames('form-group', {
    // eslint-disable-next-line no-prototype-builtins
    'has-error': validationErrorMessages.hasOwnProperty('pickupStartTime')
  });
  const endDateClassNames = classNames('form-group', {
    // eslint-disable-next-line no-prototype-builtins
    'has-error': validationErrorMessages.hasOwnProperty('pickupEndDate')
  });
  const endTimeClassNames = classNames('form-group', {
    // eslint-disable-next-line no-prototype-builtins
    'has-error': validationErrorMessages.hasOwnProperty('pickupEndTime')
  });

  return (
    <div className="col-sm-4">
      <div className="row step-wrap">
        <h4 className="col-sm-12">Provide pickup details</h4>
        <div className="col-sm-6">
          <label className={scacClassNames} onClick={preventDefault}>
            SCAC
            <input
              type="text"
              className="form-control"
              placeholder="2-4 digit code..."
              value={scac}
              onChange={(event) => {
                setScac(event.target.value);
              }}
            />
            {validationErrorMessages.scac && <span className="help-block">{validationErrorMessages.scac}</span>}
          </label>

          <label className={startDateClassNames} onClick={preventDefault}>
            Pickup Window Start Date
            <DatePicker
              className="form-control"
              selected={pickupStartDate}
              dateFormat="MM/dd/yyyy"
              onChange={(date) => {
                setPickupStartDate(date);
              }}
              placeholderText="Select Date..."
            />
            {validationErrorMessages.pickupStartDate && (
              <span className="help-block">{validationErrorMessages.pickupStartDate}</span>
            )}
          </label>

          <label className={endDateClassNames} onClick={preventDefault}>
            Pickup Window End Date
            <DatePicker
              className="form-control"
              selected={pickupEndDate}
              dateFormat="MM/dd/yyyy"
              onChange={(date) => {
                setPickupEndDate(date);
              }}
              placeholderText="Select Date..."
            />
            {validationErrorMessages.pickupEndDate && (
              <span className="help-block">{validationErrorMessages.pickupEndDate}</span>
            )}
          </label>
        </div>

        <div className="col-sm-6">
          <label className={masterBolClassNames} onClick={preventDefault}>
            Master Bill of Lading
            <input
              type="text"
              className="form-control"
              value={masterBol}
              placeholder="BOL #..."
              onChange={(event) => {
                setMasterBol(event.target.value);
              }}
            />
            {validationErrorMessages.masterBol && (
              <span className="help-block">{validationErrorMessages.masterBol}</span>
            )}
          </label>

          <label className={startTimeClassNames} onClick={preventDefault}>
            Pickup Window Start Time
            <DatePicker
              className="form-control"
              wrapperClassName="form-control"
              selected={pickupStartTime}
              timeIntervals={30}
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              onChange={(time) => {
                setPickupStartTime(time);
              }}
              placeholderText="Select Time..."
            />
            {validationErrorMessages.pickupStartTime && (
              <span className="help-block">{validationErrorMessages.pickupStartTime}</span>
            )}
          </label>

          <label className={endTimeClassNames} onClick={preventDefault}>
            Pickup Window End Time
            <DatePicker
              className="form-control"
              wrapperClassName="form-control"
              selected={pickupEndTime}
              timeIntervals={30}
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              onChange={(time) => {
                setPickupEndTime(time);
              }}
              placeholderText="Select Time..."
            />
            {validationErrorMessages.pickupEndTime && (
              <span className="help-block">{validationErrorMessages.pickupEndTime}</span>
            )}
          </label>
        </div>

        <label className="col-sm-6">
          Consolidator Bound &nbsp;
          <input
            id="consolidator-bound"
            type="checkbox"
            checked={consolidatorBound}
            onChange={(event) => {
              setConsolidatorBound(event.target.checked);
            }}
          />
          &nbsp;&nbsp;
          <a data-tip="Identify if this order is bound for a fulfillment consolidator">
            <i className="fa fa-lg fa-question-circle"></i>
          </a>
          <ReactTooltip place="top" />
        </label>

        <div className="col-sm-12">
          <button id="schedule-pickups-button" data-testid="proper-schedule-pickups-button" onClick={schedulePickups}>
            Schedule Pickups
          </button>
        </div>
      </div>
    </div>
  );
};

export default SchedulePickup;
