import * as React from 'react';
import {Text} from '@flexe/ui-components';
import {FunctionComponent} from 'react';
import {renderInboundLink} from '../../../libs/helpers';

export interface PurchaseOrderContentRowProps {
  data: PurchaseOrderContentData;
  isShipper: boolean;
}

export interface PurchaseOrderContentData {
  purchaseOrder: string;
  inboundIds: number[];
  reservationNames: string[];
}

const PurchaseOrderContentRow: FunctionComponent<PurchaseOrderContentRowProps> = (
  props: PurchaseOrderContentRowProps
) => (
  <div data-testid="purchase-order-content-row" className={'lpn-row'}>
    <div className="row">
      <div className="col-md-2" key={'po-' + props.data.purchaseOrder}>
        {props.data.purchaseOrder}
      </div>
      <div className="col-md-2" key={'po-' + props.data.purchaseOrder + '-inboundId'}>
        {props.data.inboundIds.map((inboundId) => (
          <div key={inboundId}>
            <Text>{renderInboundLink(inboundId, props.isShipper, false)}</Text>
            <br />
          </div>
        ))}
      </div>
      <div className="col-md-2" key={'po-' + props.data.purchaseOrder + '-reservation'}>
        {props.data.reservationNames.map((res) => {
          return (
            <div key={res}>
              <Text>{res}</Text>
              <br />
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

export default PurchaseOrderContentRow;
