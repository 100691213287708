import {CsvColumns, CsvFieldType} from '../../../../libs/CsvValidator';

export enum CsvErrorType {
  Required = 'Value is required'
}

export interface ValidationErrors {
  [Key: string]: CsvErrorType;
}

export const cycleCountBulkUploadColumnsSpec: CsvColumns = {
  locationLabel: {
    required: false,
    type: CsvFieldType.string
  },
  sku: {
    required: false,
    type: CsvFieldType.string
  }
};
