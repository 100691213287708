import * as React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {CompanyType} from '../../shared/CommonInterfaces';
import ContainersService from '../../shared/services/ContainersService';
import LpnService from '../../shared/services/LpnService';
import InventoryService from '../../shared/services/InventoryService';
import DocumentsService from '../../shared/services/DocumentsService';
import WarehouseService from '../../shared/services/WarehouseService';
import FlexeContext from '../../contexts/FlexeContext';
import ShipperContainerDeliveryContext from '../../dropoffs/contexts/ShipperContainerDeliveryContext';
import UserService from '../../shared/services/UserService';
import PackingListService from '../../shared/services/PackingListService';
import InboundDeliveryDetails from './InboundDeliveryDetails';

interface Props {
  containerDeliveryId: number;
  authenticityToken: string;
}

const InboundDeliveriesRouter: React.FC<Props> = ({authenticityToken, containerDeliveryId}) => (
  <FlexeContext.Provider
    value={{
      authenticityToken
    }}
  >
    <ShipperContainerDeliveryContext.Provider
      value={{
        containerDeliveryId,
        containersService: new ContainersService(authenticityToken),
        lpnService: new LpnService(authenticityToken),
        inventoryService: new InventoryService(authenticityToken),
        documentsService: new DocumentsService(CompanyType.shipper),
        warehouseService: new WarehouseService(authenticityToken),
        userService: new UserService(authenticityToken),
        packingListService: new PackingListService(authenticityToken)
      }}
    >
      <Router>
        <Route path="/s/dropoffs/container/:id" render={() => <InboundDeliveryDetails />} />
      </Router>
    </ShipperContainerDeliveryContext.Provider>
  </FlexeContext.Provider>
);

export default InboundDeliveriesRouter;
