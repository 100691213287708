import * as React from 'react';
import {FunctionComponent} from 'react';
import classNames from 'classnames';

export interface SelectOption {
  name: string;
  value: string;
}

interface Props {
  options: SelectOption[];
  selected: string;
  prefixText?: string;
  onSelect(option: string);
}

const FlexeSelect: FunctionComponent<Props> = (props) => {
  const FlexeSelectClasses = ['flexe-select'];

  const onChange = (event) => {
    props.onSelect(event.target.value);
  };

  return (
    <div className={classNames(FlexeSelectClasses)}>
      <label htmlFor="select">{props.prefixText}</label>
      <select id="select" onChange={onChange}>
        {props.options.map(({name, value}, index) => {
          return (
            <option key={index} value={value}>
              {name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default FlexeSelect;
