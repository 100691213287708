import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {EmailAddress} from '@flexe/ui-components';
import NavigationService from '../shared/services/NavigationService';
import IdleMonitor from '../auth/IdleMonitor';
import {Nav} from './LayoutInterfaces';

/* eslint-disable @typescript-eslint/no-var-requires */
const flexeLogo = require('flexe_white_logo_nav.png');
const flexeShipperLogo = require('flexe_black_logo_nav.png');
const flexeWarehouserLogo = require('flexe_white_logo_nav.png');
/* eslint-enable @typescript-eslint/no-var-requires */

interface NavigationProps extends RouteComponentProps<{application: string}> {
  authenticityToken: string;
  homeUrl: string;
  navigation: Nav[];
  userNavigation: Nav[];
  sessionId?: string;
}

interface NavigationState {
  isShipper: boolean;
  isWarehouse: boolean;
  navigation: Nav[];
  userNavigation: Nav[];
}

function ChevronDownIcon() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="down-chev">
      <title id="title">Down Chevron Icon</title>
      <path
        id="a"
        d="M12.071 14.642a.499.499 0 0 1-.382-.146l-4.243-4.242a.5.5 0 0 1 .708-.708l3.917 3.918 3.918-3.918a.5.5 0 0 1 .707.708l-4.242 4.242a.499.499 0 0 1-.383.146z"
      />
    </svg>
  );
  /* eslint-enable max-len */
}

class Navigation extends React.Component<NavigationProps, NavigationState> {
  private navService: NavigationService;
  private PUBLIC_PAGES = ['/users/sign_in', '/users/sign_up', '/users/confirmation/new', '/users/password/new'];
  constructor(props: NavigationProps) {
    super(props);
    this.state = {
      isShipper: this.props.match.params.application === 's',
      isWarehouse: this.props.match.params.application === 'wh',
      navigation: this.props.navigation,
      userNavigation: this.props.userNavigation
    };

    this.navService = new NavigationService(this.props.authenticityToken);
  }

  public async componentDidMount() {
    const showNav = !this.PUBLIC_PAGES.includes(window.location.pathname);
    if (this.props.sessionId && showNav) {
      const response = await this.navService.getNavigation();
      this.setState({
        isShipper: response.isShipper,
        navigation: response.navigation,
        userNavigation: response.userNavigation
      });
    } else {
      //User not logged in, don't bother with API request
      this.setState({
        navigation: [],
        userNavigation: []
      });
    }
  }

  public render() {
    return this.renderMidnightNavigation();
  }

  private renderMidnightNavigation() {
    return (
      <div className={this.state.isShipper ? 'shipper-nav' : 'warehouse-nav'}>
        <a className="flexe-logo" href={this.props.homeUrl}>
          <img src={this.state.isShipper ? flexeShipperLogo : flexeWarehouserLogo} alt="Flexe" />
        </a>
        {this.state.userNavigation.length > 0 && this.userNav(this.state.userNavigation[0])}
        <div className="application-nav">
          {this.state.navigation.map((nav, index) => {
            if (nav.attributes && nav.attributes.includes('header')) {
              return this.navHeader(nav, index);
            }
            return this.navItem(nav, index);
          })}
        </div>
        <div className="contact-panel">
          <div className="contact-nav">
            <span>Contact Us</span> <ChevronDownIcon />
          </div>
          <div id="contact-sub-nav">
            <div className="nav-header">Contact Us</div>
            <a className="nav-item" href="tel:+18557337788">
              855.733.7788
            </a>
            <EmailAddress
              localPart={this.state.userNavigation.length > 0 ? 'support' : 'new.warehouse'}
              className={'nav-item'}
            />
            {this.state.isShipper ? null : (
              <a
                href={
                  'https://flexesupport.zendesk.com/hc/en-us/articles/' +
                  '360047776852-On-Call-Support-Full-SOP-External-'
                }
                className="nav-item"
                target="_blank"
              >
                On Call Support SOP
              </a>
            )}
          </div>
        </div>
        {this.renderIdleMonitor()}
      </div>
    );
  }

  private userNav(userNavigation: Nav) {
    return (
      <div className="user-panel">
        <div className="user-nav">
          <span>{userNavigation.name}</span> <ChevronDownIcon />
        </div>
        <div id="user-sub-nav">
          <a href="/users/sign_out" onClick={this.signOut} className="sign-out">
            Sign Out
          </a>
          {userNavigation.subNav.map((nav, index) => this.navHeader(nav, index))}
        </div>
      </div>
    );
  }

  private navItem(nav: Nav, index: number) {
    const classes = nav.attributes && nav.attributes.length > 0 ? nav.attributes.join(' ') : '';
    const activeClass = this.determineActiveClass(nav.url);

    return (
      <a key={index} className={`nav-item ${classes} ${activeClass}`} href={nav.url}>
        {nav.name}
      </a>
    );
  }

  private determineActiveClass(url: string): string {
    let activeClass = '';
    if (
      this.props.location.pathname.includes('cycle_counts') ||
      this.props.location.pathname.includes('movements') ||
      this.props.location.pathname.includes('ecommerce')
    ) {
      activeClass = url === this.props.location.pathname ? 'active' : '';
    } else {
      activeClass = this.props.location.pathname.includes(url) ? 'active' : '';
    }

    return activeClass;
  }

  private navHeader(nav: Nav, index: number) {
    if (nav.subNav && nav.subNav.length > 0) {
      return (
        <div key={index}>
          <div className="nav-header">{nav.name}</div>
          {nav.subNav.map((subNav, subIndex) => this.navItem(subNav, subIndex))}
        </div>
      );
    }
  }

  private renderIdleMonitor() {
    if (this.state.navigation.length > 0) {
      return <IdleMonitor sessionTimeoutInSeconds={null} logout={this.signOut} />;
    } else {
      return '';
    }
  }

  private signOut = (e) => {
    if (e) {
      e.preventDefault();
    }
    window.location.href = '/users/sign_out';
  };
}

export default Navigation;
