import {get} from 'lodash';
import InternalAPIServiceV2 from '../../shared/services/InternalAPIServiceV2';

export interface Item {
  companyId: number;
  id: number;
  sku: string;
  description: string;
  inventoryGroup?: string;
  cartonsPerLayer?: number;
  layersPerPallet?: number;
  cartonsPerPallet?: number;
  stackable?: boolean;
  lotCodeTrackingEnabled?: boolean;
  expiryDateTrackingEnabled?: boolean;
  bagAndTapeRequired?: boolean;
  isHazmat: boolean;
  isOrmd: boolean;
  unNumber?: string;
  pkgCode?: string;
  releaseDate?: string;
  velocity?: string;
  properties: ItemResponseProperties[];
}

export interface ItemResponseProperties {
  parentPackaging: string;
  packaging: string;
  unitsPerParent?: number;
  length?: number;
  width?: number;
  height?: number;
  dimensionUnit?: string;
  weight?: number;
  weightUnit?: string;
  shipAlone?: boolean;
  shipAsIs?: boolean;
  barcode?: string;
  barcodeType?: string;
  serialNumberCaptureMode?: string;
  serialNumberPatterns?: string;
}

class InventoryServiceV2 extends InternalAPIServiceV2 {
  constructor() {
    super('/v2/item-master');
  }

  public async getItem(itemId: string): Promise<Item> {
    const response = await this.makeGetRequest(`${this.baseUrl}/${itemId}`);
    return get(response, 'data.data', response) as Item;
  }
}

export default InventoryServiceV2;
