import InternalAPIService from '../../shared/services/InternalAPIService';
import {Filter} from '../CommonInterfaces';
import {ShipmentPackaging, ShipmentPackagingServiceInterface} from './Interfaces';

class ShipmentPackagingService extends InternalAPIService implements ShipmentPackagingServiceInterface {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/shipment_packaging');
  }

  public async getShipmentPackagings(filters?: Filter[]) {
    const response = await this.makeGetRequest(this.baseUrl, filters);
    const shipmentPackagings = (response.data && response.data.defaultOverboxes) || [];
    const errors = this.extractErrorMessages(response);

    return {
      errors,
      shipmentPackagings
    };
  }

  public async createPackaging(defaultOverbox: ShipmentPackaging) {
    const defaultOverboxNullified = this.nullifyEmptyParams(defaultOverbox);
    const response = await this.makePostRequest(this.baseUrl, {
      defaultOverboxNullified
    });
    const errors = this.extractErrorMessages(response);

    return {errors};
  }

  public async updatePackaging(defaultOverbox: ShipmentPackaging) {
    const defaultOverboxNullified = this.nullifyEmptyParams(defaultOverbox);
    const response = await this.makePutRequest(`${this.baseUrl}/${defaultOverboxNullified.id}`, {
      defaultOverboxNullified
    });
    const errors = this.extractErrorMessages(response);

    return {errors};
  }

  public async deletePackaging(defaultOverbox: ShipmentPackaging) {
    const response = await this.makeDeleteRequest(`${this.baseUrl}/${defaultOverbox.id}`);
    const errors = this.extractErrorMessages(response);

    return {errors};
  }

  private extractErrorMessages(response): string[] {
    // This indicates the returned error is not a standard Flexe error.  It could be a network or rails routing error.
    // Show a generic error message
    if (!response || !response.errors) {
      return ['Unable to complete request'];
    }

    return response.errors.map(this.extractErrorMessage);
  }

  private nullifyEmptyParams(defaultOverbox: ShipmentPackaging): ShipmentPackaging {
    return {
      id: defaultOverbox.id || null,
      length: defaultOverbox.length || null,
      width: defaultOverbox.width || null,
      height: defaultOverbox.height || null,
      boxType: defaultOverbox.boxType,
      reservationId: defaultOverbox.reservationId || null,
      barcode: defaultOverbox.barcode || null,
      description: defaultOverbox.description || null,
      weight: defaultOverbox.weight || null
    };
  }

  private extractErrorMessage(error): string {
    // This indicates we got a structured error response that was missing the expected detail field. Could be a bug.
    // Show a generic error message
    if (!error.detail) {
      return 'Unable to complete request';
    }

    const {detail, source} = error;
    const pointer = source && source.pointer && source.pointer.replace(/\/data\/defaultOverbox\//, '');
    const param = source && source.parameter;

    if (pointer || param) {
      return `${pointer || param}: ${detail}`;
    } else {
      return detail;
    }
  }
}

export default ShipmentPackagingService;

export const MAX_ONERATE_VOLUMES_INCHES = {
  box: 644,
  envelope: 250,
  package: 644
};
