/** @jsxRuntime classic */
/** @jsx jsx */

import {css, jsx} from '@emotion/react';
import * as React from 'react';
import {ReactNode} from 'react';
import {Text, useTable} from '@flexe/ui-components';
import tokens from '@flexe/ui-tokens';
import {EditableBy, InventoryUploadCsvColumnInfoGroup, ModalAction, RequiredFor} from './ItemMasterInterfaces';

const embeddedListStyles = css({
  'padding': '0',
  'marginBottom': '0',
  '& li': {
    margin: `${tokens.spacing.v100.value}px 0`
  },
  '& li:first-of-type': {
    marginTop: '0'
  },
  '& li:last-child': {
    marginBottom: '0'
  }
});

const legendStyles = css({
  margin: `${tokens.spacing.v100.value}px 0`
});

const requiredStyles = css({
  color: tokens.color.base.red.v300.value
});

interface Props {
  action: ModalAction;
  editableBy: EditableBy;
  headers: ReactNode[];
  rows: InventoryUploadCsvColumnInfoGroup[];
}

const InstructionsTable = ({action, editableBy, headers, rows}: Props) => {
  const {tableStyles} = useTable({
    headerBorder: true,
    zebraStripe: true
  });

  return (
    <React.Fragment>
      <div css={legendStyles}>
        <Text tag="span" color={tokens.color.base.red.v300.value}>
          *
        </Text>
        <Text tag="span" color={tokens.color.base.gray.v300.value} fontStyle="italic">
          {' '}
          Required
        </Text>
      </div>
      <table {...tableStyles}>
        <thead>
          <tr>
            {headers.map((header: string) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows
            .filter((group) => group.editableBy === editableBy || group.editableBy === EditableBy.ShipperAndWarehouse)
            .map((group) => (
              <tr key={group.columns.map((column) => column.name).join(',')}>
                <td>
                  <ul css={embeddedListStyles}>
                    {group.columns.map((column) => (
                      <li key={column.name}>
                        {/* this toString() is required b/c requiredFor and action are both enums */}
                        {(column.requiredFor === action.toString() ||
                          column.requiredFor === RequiredFor.UpdateAndCreate) && <span css={requiredStyles}>* </span>}
                        {column.name}
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <ul css={embeddedListStyles}>
                    {group.columns.map((column) => (
                      <li key={column.name}>{column.sampleInput}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  <ul css={embeddedListStyles}>
                    {group.columns.map((column) => (
                      <li key={column.name}>{column.description}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  <ul css={embeddedListStyles}>
                    {group.columns.map((column) => (
                      <React.Fragment key={column.name}>
                        {column.guidelines.map((guideline, i) => (
                          <li key={`${column.name}-${i}`}>{guideline}</li>
                        ))}
                      </React.Fragment>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default InstructionsTable;
