import * as React from 'react';
import {cloneDeep, get, set} from 'lodash';
import {Table, TableData} from '@flexe/ui-components';
import {Inventory, InventoryProperties} from '../DropoffInterfaces';
import InventoryService, {InventoryUpdateRequestObject} from '../../../shared/services/InventoryService';

export interface InventoryPropertyMap {
  [inventoryId: string]: InventoryProperties;
}

interface MissingInventoryState {
  updatedProperties: InventoryPropertyMap;
  error?: string;
}

interface MissingInventoryProps {
  inventoryService: InventoryService;
  newInventories: Inventory[];
  shippableNewInventories: number[];
  reservationId: number;
  handleUpdatedProperties(properties: {[inventoryId: string]: InventoryProperties});
}

interface PropertyInputProps {
  inventoryId: number;
  name: string;
  value: any;
  label?: string;
  handlePropertyUpdate(e);
}

function SkuPropertyInput(props: PropertyInputProps) {
  return (
    <div className="form-group">
      <div className="input-group">
        <input
          type="number"
          className="form-control"
          name={props.name}
          value={props.value}
          min={1}
          data-id={props.inventoryId}
          onChange={props.handlePropertyUpdate}
        />
      </div>
    </div>
  );
}

function SkuShipProperty(props: PropertyInputProps) {
  return (
    <div className="checkbox">
      <label>
        <input
          type="checkbox"
          data-id={props.inventoryId}
          name={props.name}
          checked={props.value}
          onChange={props.handlePropertyUpdate}
        />{' '}
        {props.label}
      </label>
    </div>
  );
}

class EnterSkuProperties extends React.Component<MissingInventoryProps, MissingInventoryState> {
  constructor(props: MissingInventoryProps) {
    super(props);

    this.state = {
      updatedProperties: this.mapInventoryProperties(this.props.newInventories)
    };
  }

  public render() {
    const shippableInventories = this.props.newInventories.filter((inv) =>
      this.props.shippableNewInventories.includes(inv.id)
    );
    const originalProperties: InventoryPropertyMap = this.mapInventoryProperties(shippableInventories);
    const allUpdated: boolean = this.props.newInventories.every((inv) => {
      return get(this.state.updatedProperties, `${inv.id}.updated`, false) as boolean;
    });
    return (
      <div id="add-missing-inventory-properties-component">
        {this.state.error && (
          <div className="alert alert-danger">
            <span>{this.state.error}</span>
            <div className="cancel-alert pull-right">
              <a onClick={this.handleDismissAlert}>
                <i className="fa fa-times"></i>
              </a>
            </div>
          </div>
        )}
        <Table tableData={this.getTableData(originalProperties)} />
        {allUpdated ? (
          <p className="small">* Refresh the page if you need to make more changes</p>
        ) : (
          <button className="btn update-props" onClick={this.handleUpdate}>
            Save Changes
          </button>
        )}
      </div>
    );
  }

  private mapInventoryProperties(inventories: Inventory[]): InventoryPropertyMap {
    const properties = {};
    inventories
      .filter((inv: Inventory) => this.props.shippableNewInventories.includes(inv.id))
      .forEach((inv: Inventory) => {
        // We were having a problem where cartonsPerLayer was ending up undefined.
        // So... fix it with a sledgehammer - if this isn't truthy, set it to 0.
        // (which is not truthy, so if it's set to zero... we'll set it again. Whatever.)
        const cpl = get(inv, 'cartonsPerLayer', 0) || 0;
        const lpp = get(inv, 'layersPerPallet', 0) || 0; // Setting this one too, just in case.

        properties[inv.id] = {
          cartonsPerLayer: cpl,
          layersPerPallet: lpp,
          updated: false,
          properties: {
            each: {
              shipAlone: get(inv.properties, 'each.shipAlone', false),
              shipAsIs: get(inv.properties, 'each.shipAsIs', false),
              unitsPerParent: get(inv.properties, 'each.unitsPerParent', false)
            },
            carton: {
              shipAlone: get(inv.properties, 'carton.shipAlone', false),
              shipAsIs: get(inv.properties, 'carton.shipAsIs', false),
              unitsPerParent: get(inv.properties, 'carton.unitsPerParent', false)
            }
          }
        } as InventoryProperties;
      });
    return properties;
  }

  private getTableData(originalProperties: InventoryPropertyMap): TableData {
    return {
      headers: [
        {element: 'SKU Details'},
        {element: 'Each Properties'},
        {element: 'Carton Properties'},
        {element: 'Layer Properties'},
        {element: 'Pallet Properties'}
      ],
      rows: this.getTableRows(originalProperties)
    };
  }

  private getTableRows(originalProperties: InventoryPropertyMap) {
    const shippableInventories = this.props.newInventories.filter((inv) =>
      this.props.shippableNewInventories.includes(inv.id)
    );
    return shippableInventories.map((inv) => {
      return [
        <div className="sku-details">
          {inv.sku} <br />
          <span className="description">{inv.description}</span>
        </div>,
        this.getEachProperties(inv, originalProperties),
        this.getCartonProperties(inv, originalProperties),
        this.getLayerProperties(inv, originalProperties),
        this.getPalletProperties(inv, originalProperties)
      ];
    });
  }

  private getEachProperties(inv: Inventory, originalProperties: InventoryPropertyMap) {
    const updated = get(this.state.updatedProperties[inv.id], 'updated', null);
    let eachesPerCarton: JSX.Element;
    let shipProperties: JSX.Element;
    const shipAlone: boolean = this.state.updatedProperties[inv.id]
      ? this.state.updatedProperties[inv.id].properties.each.shipAlone
      : (get(originalProperties, `${inv.id}.properties.each.shipAlone`, false) as boolean);
    const shipAsIs: boolean = this.state.updatedProperties[inv.id]
      ? this.state.updatedProperties[inv.id].properties.each.shipAsIs
      : (get(originalProperties, `${inv.id}.properties.each.shipAsIs`, false) as boolean);

    if (updated) {
      eachesPerCarton = <span>: {this.state.updatedProperties[inv.id].properties.each.unitsPerParent}</span>;
      shipProperties = (
        <div className="each-properties">
          Ship Alone: {shipAlone ? 'Yes' : 'No'}
          <br />
          Ship As Is: {shipAsIs ? 'Yes' : 'No'}
        </div>
      );
    } else {
      eachesPerCarton = (
        <SkuPropertyInput
          inventoryId={inv.id}
          name="properties.each.unitsPerParent"
          value={
            Number(get(this.state.updatedProperties, `${inv.id}.properties.each.unitsPerParent`)) ||
            Number(get(originalProperties, `${inv.id}.properties.each.unitsPerParent`, 0))
          }
          handlePropertyUpdate={this.handlePropertyUpdate}
        />
      );

      shipProperties = (
        <div className="form-group">
          <SkuShipProperty
            inventoryId={inv.id}
            name="properties.each.shipAlone"
            value={shipAlone}
            label="Ship Alone"
            handlePropertyUpdate={this.handlePropertyUpdate}
          />
          <SkuShipProperty
            inventoryId={inv.id}
            name="properties.each.shipAsIs"
            value={shipAsIs}
            label="Ship As Is"
            handlePropertyUpdate={this.handlePropertyUpdate}
          />
        </div>
      );
    }

    return (
      <div className="each-properties">
        <span className="property-name">Eaches per Carton</span>
        {eachesPerCarton}
        {shipProperties}
      </div>
    );
  }

  private getCartonProperties(inv: Inventory, originalProperties: InventoryPropertyMap) {
    const updated = get(this.state.updatedProperties[inv.id], 'updated', null);
    let cartonsPerLayer: JSX.Element;
    let shipProperties: JSX.Element;
    const shipAlone = this.state.updatedProperties[inv.id]
      ? this.state.updatedProperties[inv.id].properties.carton.shipAlone
      : (get(originalProperties, `${inv.id}.properties.carton.shipAlone`, false) as boolean);
    const shipAsIs = this.state.updatedProperties[inv.id]
      ? this.state.updatedProperties[inv.id].properties.carton.shipAsIs
      : (get(originalProperties, `${inv.id}.properties.carton.shipAsIs`, false) as boolean);
    if (updated) {
      cartonsPerLayer = <span>: {this.state.updatedProperties[inv.id].cartonsPerLayer}</span>;
      shipProperties = (
        <div>
          Ship Alone: {shipAlone ? 'Yes' : 'No'}
          <br />
          Ship As Is: {shipAsIs ? 'Yes' : 'No'}
        </div>
      );
    } else {
      cartonsPerLayer = (
        <SkuPropertyInput
          inventoryId={inv.id}
          name="cartonsPerLayer"
          value={
            Number(get(this.state.updatedProperties, `${inv.id}.cartonsPerLayer`)) ||
            Number(get(originalProperties, `${inv.id}.cartonsPerLayer`, 0))
          }
          handlePropertyUpdate={this.handlePropertyUpdate}
        />
      );

      shipProperties = (
        <div className="form-group">
          <SkuShipProperty
            inventoryId={inv.id}
            name="properties.carton.shipAlone"
            value={shipAlone}
            label="Ship Alone"
            handlePropertyUpdate={this.handlePropertyUpdate}
          />
          <SkuShipProperty
            inventoryId={inv.id}
            name="properties.carton.shipAsIs"
            value={shipAsIs}
            label="Ship As Is"
            handlePropertyUpdate={this.handlePropertyUpdate}
          />
        </div>
      );
    }

    return (
      <div className="carton-properties">
        <span className="property-name">Cartons per Layer</span>
        {cartonsPerLayer}
        {shipProperties}
      </div>
    );
  }

  private getLayerProperties(inv: Inventory, originalProperties: InventoryPropertyMap) {
    let layersPerPallet: JSX.Element;
    if (get(this.state.updatedProperties[inv.id], 'updated', null)) {
      layersPerPallet = <span>: {this.state.updatedProperties[inv.id].layersPerPallet}</span>;
    } else {
      layersPerPallet = (
        <SkuPropertyInput
          inventoryId={inv.id}
          name="layersPerPallet"
          value={
            Number(get(this.state.updatedProperties, `${inv.id}.layersPerPallet`)) ||
            Number(get(originalProperties, `${inv.id}.layersPerPallet`, 0))
          }
          handlePropertyUpdate={this.handlePropertyUpdate}
        />
      );
    }

    return (
      <div className="layer-properties">
        <span className="property-name">Layers per Pallet</span>
        {layersPerPallet}
      </div>
    );
  }

  private getPalletProperties(inv: Inventory, originalProperties: InventoryPropertyMap) {
    const cartonsPerPallet =
      get(this.state.updatedProperties, `${inv.id}.properties.carton.unitsPerParent`) ||
      get(originalProperties, `${inv.id}.properties.carton.unitsPerParent`, '');
    return (
      <div className="pallet-properties">
        <span className="property-name">Cartons per Pallet</span>
        {get(this.state.updatedProperties[inv.id], 'updated', null) ? (
          <span>: {cartonsPerPallet}</span>
        ) : (
          <p>{cartonsPerPallet}</p>
        )}
      </div>
    );
  }

  private handlePropertyUpdate = (e) => {
    const inventoryId = parseInt(e.target.getAttribute('data-id'), 10);
    const property = e.target.name;
    const properties = cloneDeep(this.state.updatedProperties);

    if (!properties[inventoryId]) {
      const originalProperties = this.mapInventoryProperties(this.props.newInventories);
      properties[inventoryId] = cloneDeep(originalProperties[inventoryId]);
    }
    if (property.includes('ship')) {
      const value = e.target.checked;
      set(properties, `${inventoryId}.${property}`, value);
    } else {
      const quantity = parseInt(e.target.value, 10);
      set(properties, `${inventoryId}.${property}`, quantity);
      if (properties[inventoryId].cartonsPerLayer && properties[inventoryId].layersPerPallet) {
        properties[inventoryId].properties.carton.unitsPerParent =
          properties[inventoryId].cartonsPerLayer * properties[inventoryId].layersPerPallet;
      }
    }

    this.setState({
      updatedProperties: properties
    });
  };

  private handleUpdate = async () => {
    if (this.validProperties()) {
      this.handleDismissAlert();
      const response = await this.props.inventoryService.update(this.props.reservationId, this.formatRequest());
      if (response && get(response, 'data.succeeded') && Object.keys(response.data.succeeded).length > 0) {
        const successfulUpdates = response.data.succeeded;
        const properties = cloneDeep(this.state.updatedProperties);
        Object.keys(successfulUpdates).forEach((sku) => {
          const inventoryId = successfulUpdates[sku];
          if (properties[inventoryId]) {
            properties[inventoryId].updated = true;
          } else {
            const allProperties = this.mapInventoryProperties(this.props.newInventories);
            properties[inventoryId] = allProperties[inventoryId];
            properties[inventoryId].updated = true;
          }
        });
        this.setState(
          {
            updatedProperties: properties
          },
          this.props.handleUpdatedProperties(properties)
        );
        if (response.errors.length > 0) {
          this.handleError('There was an error updating some SKU properties', response);
        }
      } else {
        this.handleError('There was an error updating SKU properties', response);
      }
    } else {
      this.handleError('All SKU properties must be greater than 0', null);
    }
  };

  private handleError(message: string, response) {
    if (response) {
      const serverErrors = response.errors.map((e) => e.detail);
      message += `: ${serverErrors.join(', ')}`;
    }

    this.setState({
      error: message
    });
  }

  private validProperties(): boolean {
    const inventoryProperties = this.state.updatedProperties;
    return this.props.shippableNewInventories.every((invId) => {
      if (inventoryProperties[invId]) {
        // We only need to validate actual user input, if they are defaulting the system values its fine
        return (
          inventoryProperties[invId].cartonsPerLayer > 0 &&
          inventoryProperties[invId].properties.carton.unitsPerParent > 0 &&
          inventoryProperties[invId].properties.each.unitsPerParent > 0 &&
          inventoryProperties[invId].layersPerPallet > 0
        );
      }
      return true;
    });
  }

  private handleDismissAlert = () => {
    this.setState({
      error: null
    });
  };

  private formatRequest(): InventoryUpdateRequestObject[] {
    return this.props.newInventories
      .filter((inv) => this.props.shippableNewInventories.includes(inv.id))
      .map((inv) => {
        const valuesToUse = this.state.updatedProperties[inv.id] || inv;
        // If user updated, use those, otherwise take system values already set
        return {
          sku: inv.sku,
          txnState: 'enabled',
          defaultBuildInstructions: {
            cartonsPerLayer: valuesToUse.cartonsPerLayer,
            cartonsPerPallet: valuesToUse.properties.carton.unitsPerParent,
            layersPerPallet: valuesToUse.layersPerPallet
          },
          properties: [
            {
              packaging: 'each',
              parentPackaging: 'carton',
              unitsPerParent: valuesToUse.properties.each.unitsPerParent,
              shipAlone: valuesToUse.properties.each.shipAlone,
              shipAsIs: valuesToUse.properties.each.shipAsIs
            },
            {
              packaging: 'carton',
              parentPackaging: 'pallet',
              unitsPerParent: valuesToUse.properties.carton.unitsPerParent,
              shipAlone: valuesToUse.properties.carton.shipAlone,
              shipAsIs: valuesToUse.properties.carton.shipAsIs
            },
            {
              packaging: 'pallet',
              parentPackaging: null
            }
          ]
        };
      });
  }
}

export default EnterSkuProperties;
