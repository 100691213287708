import * as React from 'react';
import {FC} from 'react';
import DropDown, {DropDownColor, DropDownOption, DropDownStyle} from '../../../shared/DropDown';

const placeholder: DropDownOption = {
  name: 'Select currency...',
  value: ''
};

// This component is designed expecting only string values.
// If value is ever a number, the component code will need updating
// We're starting with CA and US currencies even though the API supports a lot more
//  because we're unlikely to need others and our dropdowns don't work well with large option sets.
const currencyOptions: DropDownOption[] = [
  placeholder,
  {
    name: 'CAD',
    value: 'CAD'
  },
  {
    name: 'USD',
    value: 'USD'
  }
];

interface Props {
  selectedCurrencyValue?: string;
  onCurrencyUpdated: (newCurrencyValue?: string) => void;
}

const findMatchingOption = (currencyValue?: string): DropDownOption => {
  if (!currencyValue) {
    return placeholder;
  }

  const matchedOption = currencyOptions.find((opt) => opt.value === currencyValue);
  if (matchedOption) {
    return matchedOption;
  }

  // Make a pseudo option if they set an unsupported currency
  return {name: currencyValue, value: currencyValue};
};

const CurrencyDropdown: FC<Props> = (props) => {
  return (
    <DropDown
      color={DropDownColor.white}
      style={DropDownStyle.matchFont}
      options={currencyOptions}
      selected={findMatchingOption(props.selectedCurrencyValue)}
      onSelect={(option) => {
        const newCurrencyValue = option.value === placeholder.value ? undefined : (option.value as string);
        props.onCurrencyUpdated(newCurrencyValue);
      }}
    />
  );
};
export default CurrencyDropdown;
