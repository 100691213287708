import {Counts, FilterOption, Inventory, Reservation, TransitionState} from '../../shared/CommonInterfaces';
import WarehouseService from '../../shared/services/WarehouseService';
import {Company} from '../../shared/CommonInterfaces';
import FulfillmentService from './FulfillmentService';

export interface Address {
  company: string;
  country: string;
  line1: string;
  line2: string;
  line3: string;
  locality: string;
  postcode: string;
  region: string;
  errors?: string[];
}

export interface AddressValidationResponse {
  address: Address;
  isValid: boolean;
  messages: AddressValidationMessage[];
}

export interface AddressValidationMessage {
  text: string;
  [key: string]: string | boolean | number;
}

export interface CarrierShippingOption {
  carrierAccountId: number;
  carrierAccountNumber: string;
  carrier: string;
  carrierDisplayName: string;
  serviceLevel: string;
  serviceLevelDisplayName: string;
  durationDays?: number;
  displayAmount?: string;
}

export interface RequiredLabel {
  shipmentId: number;
  type: string;
  carrierAssignedId: string;
  filename: string;
  url: string;
  isExternalLabel: boolean;
}

export interface ShippingLabelInfo {
  carrier: string;
  carrierDisplayName: string;
  carrierServiceType: string;
  carrierAssignedId?: string;
  carrierAccountId?: number;
  carrierAccountNumber?: string;
}

export interface Shipment {
  freightLoadStop?: FreightLoadStop;
  isBatched: boolean;
  issues: ShipmentIssue[];
  shipmentId: number;
  po: string;
  repairable: boolean;
  reservationFriendlyName: string;
  shipMode: string;
  shipAfter?: string;
  shipOn: string;
  shipToName: string;
  shipToAddress: Address;
  validatedShipToAddress?: Address;
  shippingLabelInfo: ShippingLabelInfo;
  state: TransitionState;
  orderReferenceId?: number;
  customerReferenceNumber?: string;
  vendorName?: string;
  createdAt?: Date;
  warehouseTimezone?: string;
  parentCustomerUuid?: string;
  splitSource?: SplitSource;
  relatedShipmentsRequiredLabels?: RequiredLabel[];
}

export interface FreightLoadStop {
  bolNumber: string;
  proNumber: string;
  sealNumber: string;
  freightLoad: FreightLoad;
}

export interface FreightLoad {
  scac: string;
  scheduledShipDate: string;
  trailerNumber: string;
}

interface ShipmentIssue {
  description: string;
  jobId: string;
}

export enum SplitSource {
  MpsSplit = 'mps_split',
  WarehouserSplit = 'warehouser_split'
}

export interface Document {
  filename: string;
  type: string;
  src: string;
}

export interface ShipmentDetails extends Shipment {
  documents: Document[];
  notes: string;
}

export interface FulfillmentProps {
  authenticityToken: string;
  hasCarrierAccounts: boolean;
  reservations?: Reservation[];
  fulfillmentService?: FulfillmentService;
  defaultShipByStartingFilterDate?: string;
  omnichannelEnabled: boolean;
  showBulkCancelModal: boolean;
}

export interface FulfillmentDetailProps extends FulfillmentProps {
  shipmentId?: number;
  warehouseService?: WarehouseService;
  currentCompany: Company;
}

export interface FulfillmentState {
  continuationTokens: string[];
  counts: Counts;
  errors: any;
  currentPage: number;
  filters: object;
  loading: boolean;
  newContinuationToken: string;
  newShipments: Shipment[];
  shipments: Shipment[];
  reservations: FilterOption[];
  selectedAddress: Address;
  selectedShipmentIds: number[];
  selectedShipmentForRepair: Shipment;
  selectedTab: string;
  showCancelModal: boolean;
  cancellationReason: string | number;
  showRetryModal: boolean;
  showFixAddressModal: boolean;
  usesOrderManager: boolean;
  distributionByLotEnabled: boolean;
}

export interface ShipmentsResponse {
  continuationToken: string;
  counts: Counts;
  reservations: ReservationFilterOption[];
  shipments: Shipment[];
}

export interface ShipmentResponse {
  shipment: {
    details: ShipmentDetails;
    inventoryData: Inventory[];
  };
  usesOrderManager: boolean;
}

export interface ShipmentCancelResponse {
  successes: number[];
  issues: string[];
  errors: ShipmentCancelError[];
}

export interface ShipmentCancelError {
  shipment_id: number;
  msg: string;
}

export interface ReservationFilterOption extends FilterOption {
  usesOrderManager: boolean;
  distributionByLotEnabled: boolean;
}
