import * as React from 'react';
import {cloneDeep} from 'lodash';
import FilterLabel from '../../shared/FilterLabel';

interface Props {
  carriers: any;
  batchFilters: any;
  handleBatchFiltersUpdate(newFilters: any);
}

const BatchFiltersLabelGroup: React.FC<Props> = (props) => {
  const {batchFilters, handleBatchFiltersUpdate} = props;

  const removeFilterValue = (key: string, value?: string) => {
    const newFilterValues = cloneDeep(batchFilters);
    if (key === 'serviceTypes' || key === 'skus') {
      const index = newFilterValues[key].indexOf(value, 0);
      if (index > -1) {
        newFilterValues[key].splice(index, 1);
      }
      if (newFilterValues[key].length === 0) {
        delete newFilterValues[key];
      }
    } else {
      delete newFilterValues[key];
    }
    handleBatchFiltersUpdate(newFilterValues);
  };

  return (
    <div className="filter-labels">
      {batchFilters.batchId && (
        <FilterLabel
          labelOption={{
            displayName: `Batch ID: ${batchFilters.batchId}`,
            value: batchFilters.batchId
          }}
          key={'batchId-filter-label'}
          onClose={() => removeFilterValue('batchId')}
        />
      )}
      {batchFilters.orderId && (
        <FilterLabel
          labelOption={{
            displayName: `Shipment ID: ${batchFilters.orderId}`,
            value: batchFilters.orderId
          }}
          key={'orderId-filter-label'}
          onClose={() => removeFilterValue('orderId')}
        />
      )}
      {batchFilters.orderShipOn && (
        <FilterLabel
          labelOption={{
            displayName: `Shipment Ship On: ${batchFilters.orderShipOn}`,
            value: batchFilters.orderShipOn
          }}
          key="orderShipOn-filter-label"
          onClose={() => removeFilterValue('orderShipOn')}
        />
      )}
      {batchFilters.purchaseOrder && (
        <FilterLabel
          labelOption={{
            displayName: `Purchase Order: ${batchFilters.purchaseOrder}`,
            value: batchFilters.purchaseOrder
          }}
          key="purchaseOrder-filter-label"
          onClose={() => removeFilterValue('purchaseOrder')}
        />
      )}
      {batchFilters.startDate && (
        <FilterLabel
          labelOption={{
            displayName: `Batch Created Starting: ${batchFilters.startDate}`,
            value: batchFilters.startDate
          }}
          key="startDate-filter-label"
          onClose={() => removeFilterValue('startDate')}
        />
      )}
      {batchFilters.endDate && (
        <FilterLabel
          labelOption={{
            displayName: `Batch Created Ending: ${batchFilters.endDate}`,
            value: batchFilters.endDate
          }}
          key="endDate-filter-label"
          onClose={() => removeFilterValue('endDate')}
        />
      )}
      {batchFilters.carrier && (
        <FilterLabel
          labelOption={{
            displayName: `Carrier: ${props.carriers[batchFilters.carrier] || batchFilters.carrier}`,
            value: batchFilters.carrier
          }}
          key="carrier-filter-label"
          onClose={() => removeFilterValue('carrier')}
        />
      )}
      {batchFilters.serviceTypes &&
        batchFilters.serviceTypes.map((serviceType) => {
          return (
            <FilterLabel
              labelOption={{
                displayName: `Service Type: ${serviceType}`,
                value: serviceType
              }}
              key={`serviceTypes-filter-label-${serviceType}`}
              onClose={() => removeFilterValue('serviceTypes', serviceType)}
            />
          );
        })}
      {batchFilters.shipAsIs !== undefined && (
        <FilterLabel
          labelOption={{
            displayName: `Ship As Is: ${batchFilters.shipAsIs}`,
            value: batchFilters.shipAsIs
          }}
          key="shipAsIs-filter-label"
          onClose={() => removeFilterValue('shipAsIs')}
        />
      )}
      {batchFilters.packTimeLabelGeneration !== undefined && (
        <FilterLabel
          labelOption={{
            displayName: `Pack Time Label Generation: ${batchFilters.packTimeLabelGeneration}`,
            value: batchFilters.packTimeLabelGeneration
          }}
          key="ptlg-filter-label"
          onClose={() => removeFilterValue('packTimeLabelGeneration')}
        />
      )}
      {batchFilters.skus &&
        batchFilters.skus.map((sku) => {
          return (
            <FilterLabel
              labelOption={{
                displayName: `SKU: ${sku}`,
                value: sku
              }}
              key={`skus-filter-label-${sku}`}
              onClose={() => removeFilterValue('skus', sku)}
            />
          );
        })}
      {batchFilters.hidePrinted !== undefined && (
        <FilterLabel
          labelOption={{
            displayName: 'Hide Printed',
            value: batchFilters.hidePrinted
          }}
          key="hidePrinted-filter-label"
          onClose={() => removeFilterValue('hidePrinted')}
        />
      )}
    </div>
  );
};

export default BatchFiltersLabelGroup;
