/** @jsxRuntime classic */
/** @jsx jsx */

import * as React from 'react';
import {css, jsx} from '@emotion/react';
import {Button, Column, Heading, Link, useTable} from '@flexe/ui-components';
import {Role, User as UserInfo} from '../userManagementInterfaces';

import {rowStyle} from '../styles';
import {roleToTitleCase} from '../helpers';
import User from './User';
import RoleTooltip from './shared/RoleTooltip';

interface Props {
  companyName?: string;
  currentUserId: number;
  isActiveUsers: boolean;
  onReactivateAsync: (user: UserInfo) => Promise<void>;
  onDeactivateAsync: (user: UserInfo) => Promise<void>;
  onRoleToggleAsync: (user: UserInfo, role: string, enableRole: boolean) => Promise<void>;
  allCompanyRoles: Role[];
  title: string;
  isShipper: boolean;
  users: UserInfo[];
}

const nameAndEmailHeaderStyle = css({width: '40%'});

const UsersSection = ({
  users,
  companyName,
  currentUserId,
  isActiveUsers,
  onReactivateAsync,
  onDeactivateAsync,
  onRoleToggleAsync,
  isShipper,
  allCompanyRoles,
  title
}: Props) => {
  const {tableStyles} = useTable({});

  const handleAddNewUserButtonPress = () => {
    window.location.href = '/user_management/new';
  };

  const sortAlphabetically = (unsortedUsers: UserInfo[]) => {
    return unsortedUsers.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <Column tag="section" alignmentHorizontal="start">
      <Heading level="h2">{title}</Heading>
      {isActiveUsers && (
        <React.Fragment>
          <Link
            href={
              isShipper
                ? 'https://flexesupport.zendesk.com/hc/en-us/articles/360042455071-User-Management-for-Shippers'
                : 'https://flexesupport.zendesk.com/hc/en-us/articles/4409786145428-Creating-New-Flexe-Users'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn about Active Users Setup
          </Link>

          <Button onPress={handleAddNewUserButtonPress}>{`Add New User to ${companyName}`}</Button>
        </React.Fragment>
      )}

      <table {...tableStyles}>
        <thead>
          <tr css={rowStyle}>
            <th css={nameAndEmailHeaderStyle}>Name</th>
            <th css={nameAndEmailHeaderStyle}>Email</th>
            <React.Fragment>
              {allCompanyRoles.map((role) => (
                <th css={nameAndEmailHeaderStyle} key={role.title}>
                  {roleToTitleCase(role.title)} <RoleTooltip role={role} />
                </th>
              ))}
            </React.Fragment>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortAlphabetically(users).map((user) => (
            <User
              key={user.id}
              currentUserId={currentUserId}
              onReactivateClickAsync={onReactivateAsync}
              onDeactivateClickAsync={onDeactivateAsync}
              allCompanyRoles={allCompanyRoles}
              onRoleToggleAsync={onRoleToggleAsync}
              user={user}
            />
          ))}
        </tbody>
      </table>
    </Column>
  );
};

export default UsersSection;
