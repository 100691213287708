import * as React from 'react';
import {EmailAddress, LegacyModal} from '@flexe/ui-components';
import BatchWavingService from '../../shared/services/BatchWavingService';

interface CompleteProps {
  batchId: number;
  batchService: BatchWavingService;
  handleCompletion();
}

interface CompleteState {
  confirmedBatch: string;
  showModal: boolean;
  errors?: string[];
}

function CompletionErrors({errors}) {
  const errorsToDisplay = errors.map((error, index) => {
    return (
      <div key={`error_${index}`} className="alert alert-danger">
        {error}
      </div>
    );
  });

  return <div>{errorsToDisplay}</div>;
}

class BatchComplete extends React.Component<CompleteProps, CompleteState> {
  private batchService: BatchWavingService;
  constructor(props) {
    super(props);
    this.state = {
      confirmedBatch: '',
      showModal: false,
      errors: null
    };
    this.batchService = this.props.batchService;
  }

  public render() {
    return (
      <div id="batch-complete-component">
        <button id="batch-complete-button" onClick={this.toggleModal}>
          Complete All Requests
        </button>
        <LegacyModal
          id="complete-modal"
          size="small"
          show={this.state.showModal}
          toggleModal={this.toggleModal}
          title={'Complete Batch'}
          footer={
            <div>
              <button id="no-button" className="btn" onClick={this.toggleModal}>
                No
              </button>
              <button
                id="yes-button"
                className="btn"
                onClick={this.handleComplete}
                disabled={this.props.batchId.toString() !== this.state.confirmedBatch}
              >
                Yes
              </button>
            </div>
          }
        >
          <div>
            {this.state.errors && CompletionErrors({errors: this.state.errors})}
            <p>Are you certain you would like to manually complete this batch?</p>
            <div>
              <p>
                Manually completing the orders in a batch should be a last resort. If you are having issues with your
                mobile device, please notify <EmailAddress localPart={'support'} />.
              </p>
              <p>
                If you are certain you understand the consequences of manually completing this batch, please type the
                batch ID below:
              </p>
              <input
                id="confirm-batch-input"
                value={this.state.confirmedBatch}
                type="text"
                placeholder="Verify Batch to Manually Complete"
                onChange={this.handleChange}
              />
            </div>
          </div>
        </LegacyModal>
      </div>
    );
  }

  private toggleModal = () => {
    this.setState({
      confirmedBatch: '',
      showModal: !this.state.showModal,
      errors: null
    });
  };

  private handleComplete = async () => {
    if (this.state.confirmedBatch === this.props.batchId.toString()) {
      const response = await this.batchService.completeBatches([this.props.batchId]);
      if (response && response.errors.length === 0) {
        this.handleApiSuccess(response.data);
      } else {
        let message = 'There was an issue completing your batch';
        if (response) {
          const serverErrors = response.errors.map((e) => e.detail);
          message += `: ${serverErrors.join(', ')}`;
        }
        this.setState({
          errors: [message]
        });
      }
    }
  };

  private handleApiSuccess = (data) => {
    if (data.failedOrders.length > 0) {
      let displayErrors: string[] = [];
      displayErrors.push('Some shipments were not completed successfully.');

      const shipmentFailures: string[] = data.failedOrders.map((fo) => {
        return `Shipment ${fo.orderId} failed: ${fo.reason}`;
      });

      displayErrors = displayErrors.concat(shipmentFailures);

      this.setState({
        errors: displayErrors
      });
    } else {
      this.props.handleCompletion();
    }
  };

  private handleChange = (event) => {
    this.setState({
      confirmedBatch: event.target.value
    });
  };
}

export default BatchComplete;
