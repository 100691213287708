import * as React from 'react';
import {FunctionComponent} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import CardListItem from './CardListItem';
import FlexeButton from './FlexeButton';

export interface ListItem {
  title: string;
  info: string;
  id?: string | number;

  // CardListItem can render with or without a button; it can also render a link styled to look like a button
  // 1. If no value is given, actionHint will not render at all
  // 2. If no value is given, buttonText will render as "Next"
  // 3. If rendering a button, define your event handler in your parent component code, where context exists
  // 3a: Pass your event handler to actionClick; it will trickle down to CardListItem and be called upon button click
  // 4. If rendering a link that looks like a button,
  //    define your href in your parent component code, where context exists
  // 4a. Pass your href to actionRoute; it will trickle down to CardListItem
  //    and appear as a button that will trigger a navigation event
  actionHint?: string;
  buttonText?: string;
  actionClick?: any; // an item may have either an actionClick to handle a button click
  actionRoute?: string; // OR an actionRoute to hit an endpoint/go to a url, NOT BOTH
  actionClickDisabled?: boolean; // whether the action button should be disabled

  // if true, the needsAttention classes will be applied to the CardListItem
  needsAttention?: boolean;
  optionMenu?: React.ReactElement;
}

interface Props {
  listTitle: string;
  listItems: ListItem[];

  // How to use the following optional props:

  // BUTTON CLICK EVENTS (opening a modal, toggling state, etc)
  // 1. Define your event handler in your parent component code, where context exists
  // 2. Pass the handler to the appropriate handle_____ prop
  // 3. The button rendered at the top of the CardList will call your event handler
  handleCreate?: any;
  handleDelete?: any;
  // NAVIGATION EVENTS (going to another page, calling an api endpoint, etc)
  // 1. Define your route/URL/endpoint in your parent component code, where context exists
  // 2. Pass the route to the appropriate _____Route prop
  // 3. The link rendered at the top of the CardList will contain your route/URL/endpoint
  createRoute?: string;
  deleteRoute?: string;
  // Text to display when the card list has no items
  emptyListText?: string;
}

const CardList: FunctionComponent<Props> = (props) => {
  const CardListClasses = ['card-list'];
  return (
    <section className={classNames(CardListClasses)}>
      <div className="list-header row">
        <h2 data-testid="list-title">{props.listTitle}</h2>
        {/* If the list includes actions, render link or button depending on desired action  */}
        <div data-testid="list-buttons" className="list-buttons">
          {props.createRoute && (
            <Link data-testid="create-new-list-item" to={props.createRoute}>
              Create New
            </Link>
          )}
          {props.handleCreate && <FlexeButton level="collapsed" text="Create New" handleClick={props.handleCreate} />}
          {props.deleteRoute && (
            <Link data-testid="delete-list-item" to={props.deleteRoute}>
              Delete
            </Link>
          )}
          {props.handleDelete && <FlexeButton level="collapsed" text="Delete" handleClick={props.handleDelete} />}
        </div>
        {props.listItems.length === 0 && props.emptyListText && <p>{props.emptyListText}</p>}
      </div>
      <ul data-testid="list-items">
        {props.listItems.map((listItem, i) => {
          return (
            <CardListItem
              key={`${listItem.title}-${i}`}
              title={listItem.title}
              info={listItem.info}
              actionHint={listItem.actionHint}
              buttonText={listItem.buttonText}
              actionClick={listItem.actionClick}
              actionRoute={listItem.actionRoute}
              actionClickDisabled={listItem.actionClickDisabled}
              needsAttention={listItem.needsAttention}
              optionMenu={listItem.optionMenu} // this should be a KebabMenu component
              {...props}
            />
          );
        })}
      </ul>
    </section>
  );
};

export default CardList;
