import FileStorageService from '../file-storage/FileStorageService';

class EdiFileHelpers {
  public static downloadFileByKey = async (fileStorageService: FileStorageService, fileKey: string) => {
    const fileMetaData = await fileStorageService.getFileMetadata(fileKey);
    fileStorageService.downloadFile(fileMetaData);
  };
}

export default EdiFileHelpers;
