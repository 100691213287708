import {format, isValid} from 'date-fns';
import * as React from 'react';
import PalletizedDropoffRequest from './PalletizedDropoffRequest';

interface StepThreeProps {
  rawRequests: any[];
  handleConfirmedRequests: any;
}

interface StepThreeState {
  validatedRequests: PalletizedDropoffRequest[];
}

class StepThree extends React.Component<StepThreeProps, StepThreeState> {
  constructor(props) {
    super(props);
    this.state = {
      validatedRequests: this.validateRequests(props.rawRequests)
    };
  }

  public componentWillReceiveProps(props) {
    this.setState({
      validatedRequests: this.validateRequests(props.rawRequests)
    });
  }

  public render() {
    let invalidRequests = false;
    const requests = this.state.validatedRequests ? (
      this.state.validatedRequests.map((request, index) => {
        const requestValid = Object.keys(request.errors).length === 0;
        if (!requestValid && !invalidRequests) {
          invalidRequests = true;
        }
        return (
          <tr key={index} className={requestValid ? '' : 'bg-danger'}>
            <td className={request.errors.moveDate ? 'text-danger moveDate' : 'moveDate'}>
              {request.moveDate && isValid(request.moveDate)
                ? format(request.moveDate, 'M/D/YYYY')
                : request.errors.moveDate || ''}
            </td>
            <td className={request.errors.shipmentId ? 'text-danger shipmentId' : 'shipmentId'}>
              {request.shipmentId || request.errors.shipmentId}
            </td>
            <td className={request.errors.trailerNumber ? 'text-danger trailerNumber' : 'trailerNumber'}>
              {request.trailerNumber || request.errors.trailerNumber}
            </td>
            <td className={request.errors.description ? 'text-danger description' : 'description'}>
              {request.description || request.errors.description}
            </td>
            <td className={request.errors.inventoryData ? 'text-danger inventory-items' : 'inventory-items'}>
              {request.errors.inventoryData ||
                request.inventoryData.map((inventory, invIndex) => {
                  return (
                    <div key={invIndex}>
                      {inventory.quantity} cartons of {inventory.sku}
                    </div>
                  );
                })}
            </td>
          </tr>
        );
      })
    ) : (
      <tr></tr>
    );
    return (
      <li id="step-3" className="active">
        <span className="label">3</span>
        <div className="step-wrap">
          <h3>
            <span>Confirm Palletized Delivery</span>
          </h3>
          <p className="note">Uploaded Requests</p>
          <table
            className={
              this.state.validatedRequests && this.state.validatedRequests.length > 0
                ? 'show table table-striped'
                : 'hidden'
            }
          >
            <thead>
              <tr>
                <th>ETA</th>
                <th>Shipment ID</th>
                <th>Trailer Number</th>
                <th>Description</th>
                <th>Inventory Items</th>
              </tr>
            </thead>
            <tbody>{requests}</tbody>
          </table>
          <div
            id="no-data"
            className={!this.state.validatedRequests || this.state.validatedRequests.length === 0 ? 'show' : 'hidden'}
          >
            <h4>
              <i className="fa fa-times-circle text-danger"></i>
              CSV contains no valid data. Please try and re-upload.
            </h4>
          </div>
          <div id="validation-success" className={!invalidRequests && this.state.validatedRequests ? 'show' : 'hidden'}>
            <h4>
              <i className="fa fa-check-circle text-success"></i> Requests validated successfully.
            </h4>
          </div>
          <div id="validation-failure" className={invalidRequests && this.state.validatedRequests ? 'show' : 'hidden'}>
            <h4>
              <i className="fa fa-times-circle text-danger"></i>
              Requests have errors, please fix and re-upload CSV.
            </h4>
          </div>
          <p className="note">
            <button type="button" className="btn" onClick={this.handleSubmission} disabled={invalidRequests}>
              Submit Requests
            </button>
          </p>
        </div>
      </li>
    );
  }

  private validateRequests(requests: any[]): PalletizedDropoffRequest[] {
    let validatedRequests: PalletizedDropoffRequest[];
    if (requests && requests.length > 0) {
      validatedRequests = requests.map((request) => {
        return new PalletizedDropoffRequest(request);
      });
      const UUIDs = validatedRequests.map((request) => request.shipmentId);
      const duplicateUUIDs = UUIDs.filter((uuid, i) => UUIDs.lastIndexOf(uuid) === i && UUIDs.indexOf(uuid) !== i);
      if (duplicateUUIDs) {
        validatedRequests = this.collapseMultiLineUUIDs(duplicateUUIDs, validatedRequests);
      }
    }
    return validatedRequests;
  }

  private handleSubmission = () => {
    this.props.handleConfirmedRequests(this.state.validatedRequests);
  };

  private collapseMultiLineUUIDs(
    multiLineUUIDs: string[],
    requests: PalletizedDropoffRequest[]
  ): PalletizedDropoffRequest[] {
    const results = requests.filter((request) => multiLineUUIDs.indexOf(request.shipmentId) === -1);
    multiLineUUIDs.forEach((uuid, index) => {
      const duplicates = requests.filter((request) => request.shipmentId === uuid);
      let inventory = duplicates[0].inventoryData.slice();
      for (let i = 1; i < duplicates.length; i++) {
        inventory = inventory.concat(duplicates[i].inventoryData);
      }
      duplicates[0].inventoryData = inventory.slice();
      results.push(duplicates[0]);
    });
    return results;
  }
}

export default StepThree;
