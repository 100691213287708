import * as React from 'react';

export interface WriteableProp<T> {
  value: T;
  set(val: T);
}

// combines state and setState into one object
export function useWriteableProp<T>(initialState?: T): WriteableProp<T> {
  const [value, set] = React.useState(initialState);

  return {value, set};
}
