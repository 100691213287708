import * as React from 'react';
import {ChangeEvent} from 'react';

interface TimeSelectProps {
  className?: string;
  name: string;
  value?: string;
  format24hour?: boolean;
  onChange(event: ChangeEvent<HTMLSelectElement>);
}

function TimeSelect(props: TimeSelectProps) {
  return (
    <select
      className={props.className || ''}
      name={props.name}
      value={props.value || ''}
      id={props.name}
      onChange={props.onChange}
    >
      <option value="">Select Time</option>
      <option value={props.format24hour ? '00:00' : '12:00 AM'}>12:00 AM</option>
      <option value={props.format24hour ? '00:30' : '12:30 AM'}>12:30 AM</option>
      <option value={props.format24hour ? '01:00' : '1:00 AM'}>1:00 AM</option>
      <option value={props.format24hour ? '01:30' : '1:30 AM'}>1:30 AM</option>
      <option value={props.format24hour ? '02:00' : '2:00 AM'}>2:00 AM</option>
      <option value={props.format24hour ? '02:30' : '2:30 AM'}>2:30 AM</option>
      <option value={props.format24hour ? '03:00' : '3:00 AM'}>3:00 AM</option>
      <option value={props.format24hour ? '03:30' : '3:30 AM'}>3:30 AM</option>
      <option value={props.format24hour ? '04:00' : '4:00 AM'}>4:00 AM</option>
      <option value={props.format24hour ? '04:30' : '4:30 AM'}>4:30 AM</option>
      <option value={props.format24hour ? '05:00' : '5:00 AM'}>5:00 AM</option>
      <option value={props.format24hour ? '05:30' : '5:30 AM'}>5:30 AM</option>
      <option value={props.format24hour ? '06:00' : '6:00 AM'}>6:00 AM</option>
      <option value={props.format24hour ? '06:30' : '6:30 AM'}>6:30 AM</option>
      <option value={props.format24hour ? '07:00' : '7:00 AM'}>7:00 AM</option>
      <option value={props.format24hour ? '07:30' : '7:30 AM'}>7:30 AM</option>
      <option value={props.format24hour ? '08:00' : '8:00 AM'}>8:00 AM</option>
      <option value={props.format24hour ? '08:30' : '8:30 AM'}>8:30 AM</option>
      <option value={props.format24hour ? '09:00' : '9:00 AM'}>9:00 AM</option>
      <option value={props.format24hour ? '09:30' : '9:30 AM'}>9:30 AM</option>
      <option value={props.format24hour ? '10:00' : '10:00 AM'}>10:00 AM</option>
      <option value={props.format24hour ? '10:30' : '10:30 AM'}>10:30 AM</option>
      <option value={props.format24hour ? '11:00' : '11:00 AM'}>11:00 AM</option>
      <option value={props.format24hour ? '11:30' : '11:30 AM'}>11:30 AM</option>
      <option value={props.format24hour ? '12:00' : '12:00 PM'}>12:00 PM</option>
      <option value={props.format24hour ? '12:30' : '12:30 PM'}>12:30 PM</option>
      <option value={props.format24hour ? '13:00' : '1:00 PM'}>1:00 PM</option>
      <option value={props.format24hour ? '13:30' : '1:30 PM'}>1:30 PM</option>
      <option value={props.format24hour ? '14:00' : '2:00 PM'}>2:00 PM</option>
      <option value={props.format24hour ? '14:30' : '2:30 PM'}>2:30 PM</option>
      <option value={props.format24hour ? '15:00' : '3:00 PM'}>3:00 PM</option>
      <option value={props.format24hour ? '15:30' : '3:30 PM'}>3:30 PM</option>
      <option value={props.format24hour ? '16:00' : '4:00 PM'}>4:00 PM</option>
      <option value={props.format24hour ? '16:30' : '4:30 PM'}>4:30 PM</option>
      <option value={props.format24hour ? '17:00' : '5:00 PM'}>5:00 PM</option>
      <option value={props.format24hour ? '17:30' : '5:30 PM'}>5:30 PM</option>
      <option value={props.format24hour ? '18:00' : '6:00 PM'}>6:00 PM</option>
      <option value={props.format24hour ? '18:30' : '6:30 PM'}>6:30 PM</option>
      <option value={props.format24hour ? '19:00' : '7:00 PM'}>7:00 PM</option>
      <option value={props.format24hour ? '19:30' : '7:30 PM'}>7:30 PM</option>
      <option value={props.format24hour ? '20:00' : '8:00 PM'}>8:00 PM</option>
      <option value={props.format24hour ? '20:30' : '8:30 PM'}>8:30 PM</option>
      <option value={props.format24hour ? '21:00' : '9:00 PM'}>9:00 PM</option>
      <option value={props.format24hour ? '21:30' : '9:30 PM'}>9:30 PM</option>
      <option value={props.format24hour ? '22:00' : '10:00 PM'}>10:00 PM</option>
      <option value={props.format24hour ? '22:30' : '10:30 PM'}>10:30 PM</option>
      <option value={props.format24hour ? '23:00' : '11:00 PM'}>11:00 PM</option>
      <option value={props.format24hour ? '23:30' : '11:30 PM'}>11:30 PM</option>
    </select>
  );
}

export default TimeSelect;
