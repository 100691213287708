import * as React from 'react';
import {cloneDeep, get} from 'lodash';
import {LegacyModal} from '@flexe/ui-components';
import LocationsService, {LocationContent} from '../../locations/LocationsService';
import {Packaging} from '../../shared/CommonInterfaces';
import {OrderItem, RetailOrderDetails} from '../../shared/services/RetailFulfillmentService';
import OutboundStagingTypeAhead from './OutboundStagingTypeAhead';
import ContentSummaryWithShortages, {OrderContent} from './ContentSummaryWithShortages';

interface CompleteProps {
  authenticityToken: string;
  displayOnly?: boolean;
  orderDetails: RetailOrderDetails;
  blockOverShipment: boolean;
  showModal: boolean;
  toggleModal: () => void;
  onConfirm: (outboundLocationId: number, lpnsToBeOutbounded?: string) => void;
}

export const LpnCompletionModal: React.FC<CompleteProps> = (props) => {
  const locationsService = new LocationsService(props.authenticityToken);

  const [confirmClose, setConfirmClose] = React.useState(false);
  const [orderContents, setOrderContents] = React.useState([]);
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [overShipped, setOvershipped] = React.useState(null);
  const [closeButtonOneShot, setCloseButtonOneShot] = React.useState(false);

  React.useEffect(() => {
    setOrderContents(calculateContents(props.orderDetails.orderItems));
  }, [props.orderDetails]);

  React.useEffect(() => {
    if (!props.blockOverShipment) {
      setOvershipped(false);
    } else {
      if (orderContents.some(isLineOvershipped)) {
        setOvershipped(true);
      }
    }
  }, [orderContents]);

  const modalTitle = () => {
    if (props.displayOnly) {
      return `Preview Retail Fulfillment Order ${props.orderDetails.id}`;
    } else {
      return `Review and Close: Order ${props.orderDetails.id}`;
    }
  };

  const modalFooter = () => {
    let footerContents;

    if (props.displayOnly) {
      footerContents = (
        <button id="ok-button" className="btn cta" onClick={props.toggleModal}>
          Close
        </button>
      );
    } else {
      footerContents = (
        <div className="row">
          <div className="confirm-close-group col-md-9">
            <label htmlFor="confirm-close">
              <input
                type="checkbox"
                name="confirm-close"
                id="confirm-close"
                checked={confirmClose}
                onChange={confirmCloseHandler}
                disabled={overShipped}
              />
              By closing this order, I agree that I have reviewed all shortages and discrepancies and that they are
              accurate.
            </label>
          </div>
          <div className="col-md-3">
            <button
              id="complete-button"
              disabled={disableCompleteButton()}
              onClick={completeHandler}
              className="btn cta"
            >
              Close Order
            </button>
          </div>
        </div>
      );
    }

    return <div className="footer"> {footerContents} </div>;
  };

  const render = () => {
    return (
      <div className="complete-with-locations-component">
        <LegacyModal
          id="complete-with-locations-modal"
          size={'medium'}
          show={props.showModal}
          toggleModal={props.toggleModal}
          title={modalTitle()}
          footer={modalFooter()}
        >
          <div>
            <OutboundStagingTypeAhead
              authenticityToken={props.authenticityToken}
              warehouseId={props.orderDetails.warehouse.id}
              locationsService={locationsService}
              onSelectCallback={handleTypeAheadSelect}
            />

            <ContentSummaryWithShortages contents={orderContents} orderIsOvershipped={overShipped} />
          </div>
        </LegacyModal>
      </div>
    );
  };

  const confirmCloseHandler = (e) => {
    setConfirmClose(e.target.checked);
  };

  const disableCompleteButton = () => {
    return isCompleteDisabled() || !confirmClose || (props.blockOverShipment && overShipped) || closeButtonOneShot;
  };

  const completeHandler = () => {
    //prevent multiple requests
    setCloseButtonOneShot(true);
    const lpnsToBeOutbounded = encodeContents(orderContents);

    props.onConfirm(selectedLocation, lpnsToBeOutbounded);
  };

  const encodeContents = (contents: OrderContent[]): string => {
    const filteredContents: OrderContent[] = contents.filter((content) => {
      return content.actualQuantity && content.actualQuantity.amount > 0;
    });
    return encodeURIComponent(JSON.stringify(filteredContents));
  };

  const isCompleteDisabled = (): boolean => {
    const validLpn = orderContents.some((orderContent) => {
      return orderContent.actualQuantity && orderContent.actualQuantity.amount > 0;
    });

    const validLocation = !!selectedLocation;

    // Complete should be disabled (ie. return true) if any of the values to check are false
    return !(validLocation && validLpn);
  };

  const handleTypeAheadSelect = (locationId: string, locationContents: LocationContent[]) => {
    setSelectedLocation(locationId);
    setOrderContents(calculateContents(props.orderDetails.orderItems, locationContents));
  };

  const consolidateLines = (a: OrderItem, b: OrderItem) => {
    if (a.packaging === b.packaging) {
      a.quantity += b.quantity;

      addConversions(a, b);
    } else if (a.packaging === Packaging.each && b.packaging === Packaging.carton) {
      a.quantity += b.unitConversions.eaches;

      addConversions(a, b);
    } else {
      // a == carton and b == each

      // Down-convert a to eaches, then add eaches from b
      a.packaging = Packaging.each;
      a.quantity = a.unitConversions.eaches + b.quantity;

      addConversions(a, b);
    }
  };

  const addConversions = (a: OrderItem, b: OrderItem) => {
    a.unitConversions.eaches += b.unitConversions.eaches;
    a.unitConversions.cartons += b.unitConversions.cartons;
  };

  const isLineOvershipped = (line: OrderContent) => {
    return line.actualQuantity ? line.actualQuantity.amount > line.expectedQuantity.amount : false;
  };

  const calculateContents = (expectedContents: OrderItem[], locationContents: LocationContent[] = null) => {
    const calculatedOrderContents: OrderContent[] = [];
    const expectedSkus: string[] = [];

    const consolidatedContents: OrderItem[] = [];

    expectedContents.forEach((content) => {
      const duplicate = consolidatedContents.find((x) => x.sku === content.sku);

      if (duplicate) {
        consolidateLines(duplicate, content);
      } else {
        consolidatedContents.push(cloneDeep(content));
      }
    });

    consolidatedContents.map((content) => {
      expectedSkus.push(content.sku);

      const orderContent: OrderContent = {
        sku: content.sku,
        description: content.skuDescription,
        expectedQuantity: {
          amount: content.quantity,
          unit: content.packaging,
          conversions: {
            each: content.unitConversions.eaches
          }
        },
        lpnBarcodes: []
      };

      if (locationContents !== null) {
        orderContent.actualQuantity = {
          amount: 0,
          unit: content.packaging,
          conversions: {
            each: 0
          }
        };

        const stagedContents = locationContents.filter((locationContent) => {
          if (get(locationContent, 'entity.type') === 'lpn') {
            if (get(locationContent, 'reservation.id') !== props.orderDetails.reservation.id) {
              return false;
            }

            return locationContent.inventory.id === content.inventoryId;
          }
        });

        stagedContents.map((stagedContent) => {
          if (orderContent.actualQuantity.unit === stagedContent.quantity.unit) {
            orderContent.actualQuantity.amount += stagedContent.quantity.amount;

            const stagedContentEaches = getNumeratorFromFraction(stagedContent.quantity.conversions.each.amount);
            orderContent.actualQuantity.conversions.each += stagedContentEaches;
          } else if (
            orderContent.actualQuantity.unit === Packaging.each &&
            stagedContent.quantity.unit === Packaging.carton
          ) {
            // These conversions are represented in fractionals, like "152/5". Since we know that the staged
            // content is already in cartons, we know the number of eaches be evenly divisible by the number
            // of cartons, resulting in something like "168/1". Hence, we just grab the numerator here.
            const numStagedEaches: number = getNumeratorFromFraction(stagedContent.quantity.conversions.each.amount);
            orderContent.actualQuantity.amount += numStagedEaches;
            orderContent.actualQuantity.conversions.each += numStagedEaches;
          } else {
            // orderContent.actualQuantity.unit == carton && stagedContent.qty.unit == each

            const orderContentEaches: number = orderContent.actualQuantity.conversions.each;

            // Down-convert actual qty to eaches
            orderContent.actualQuantity.amount = orderContentEaches + stagedContent.quantity.amount;
            orderContent.actualQuantity.unit = Packaging.each;

            // We want expected qty units to match actual, so down convert this too
            orderContent.expectedQuantity.amount = orderContent.expectedQuantity.conversions.each;
            orderContent.expectedQuantity.unit = Packaging.each;
          }

          orderContent.lpnBarcodes.push(stagedContent.entity.id);
        });
      }

      calculatedOrderContents.push(orderContent);
    });

    if (locationContents !== null) {
      const remainingContents = locationContents.filter((contents) => {
        if (get(contents, 'entity.type') === 'lpn') {
          if (get(contents, 'reservation.id') !== props.orderDetails.reservation.id) {
            return false;
          }

          return !expectedSkus.includes(contents.inventory.sku);
        }
      });

      remainingContents.map((additionalContent) => {
        const orderContent: OrderContent = {
          sku: additionalContent.inventory.sku,
          description: '',
          expectedQuantity: {
            amount: 0,
            unit: additionalContent.quantity.unit,
            conversions: {
              each: 0
            }
          },
          actualQuantity: {
            amount: additionalContent.quantity.amount,
            unit: additionalContent.quantity.unit,
            conversions: {
              each: getNumeratorFromFraction(additionalContent.quantity.conversions.each.amount)
            }
          },
          lpnBarcodes: [additionalContent.entity.id]
        };

        calculatedOrderContents.push(orderContent);
      });
    }

    return calculatedOrderContents;
  };

  const getNumeratorFromFraction = (fraction: string) => {
    return parseInt(fraction.split('/')[0], 10);
  };

  return render();
};

export default LpnCompletionModal;
