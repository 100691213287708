import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {GridColDef, GridPaginationModel, GridRenderCellParams, GridToolbar} from '@mui/x-data-grid';
import {flexeMuiTheme} from '../shared/mui/default-mui-theme';
import {FlexeDataGrid} from '../shared/mui/flexe-data-grid/FlexeDataGrid';
import {Rule} from './SpaConfigurationsInterfaces';
import {SuggestedLocation} from './SpaConfigurationsRulesService';

export interface Props {
  suggestions: SuggestedLocation[];
  noSuggestionsMsg: string;
}

export function TestSuggestionTable(props: Props) {
  const {suggestions, noSuggestionsMsg} = props;

  function NoRowsOverlay() {
    return <div style={{textAlign: 'center', marginTop: '10%'}}>{noSuggestionsMsg}</div>;
  }

  return (
    <div style={{height: 400}}>
      <ThemeProvider theme={flexeMuiTheme}>
        <FlexeDataGrid
          columns={getColumns()}
          rows={suggestions}
          autoHeight
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '8px'},
            '&, [class^=MuiDataGrid-cell]': {border: 'none'}
          }}
          hideFooter={true} // remove pagination
          disableColumnFilter
          disableColumnSelector
          disableRowSelectionOnClick
          disableDensitySelector
          slots={{toolbar: GridToolbar, noRowsOverlay: NoRowsOverlay}}
          slotProps={{
            toolbar: {
              showQuickFilter: false,
              printOptions: {disableToolbarButton: true},
              csvOptions: {disableToolbarButton: true}
            }
          }}
        />
      </ThemeProvider>
    </div>
  );
}

function getColumns(): GridColDef[] {
  const columns: GridColDef[] = [
    {
      field: 'label',
      headerName: 'Location',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'category',
      headerName: 'Location Category',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'zoneCategory',
      headerName: 'Zone Category',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'quantity',
      headerName: 'Currently Stored Quantity',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'packaging',
      headerName: 'Currently Stored in Packaging',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    }
  ];

  return columns;
}

export default TestSuggestionTable;
