import * as React from 'react';
import {LegacyModal, Loader} from '@flexe/ui-components';
import * as moment from 'moment';
import {cloneDeep} from 'lodash';
import BatchWavingService from '../../shared/services/BatchWavingService';
import {WaveTemplate} from './WaveInterfaces';

interface AsyncBatchCreatorProps {
  templateToWave: WaveTemplate;
  showModal: boolean;
  isSortationEnabled: boolean;
  isPickToCarton: boolean;
  carriers: object;
  wavingService: BatchWavingService;
  reservationIdToName: object;
  fullPalletsOnly?: boolean;
  unitsPerPalletCount?: number;
  isSfsEnabled: boolean;
  toggleModal();
  onWaveSuccess();
  onWaveFailure();
}
const AsyncBatchCreator: React.FC<AsyncBatchCreatorProps> = (props) => {
  const {
    templateToWave,
    showModal,
    isSortationEnabled,
    isPickToCarton,
    carriers,
    wavingService,
    reservationIdToName,
    fullPalletsOnly,
    unitsPerPalletCount,
    isSfsEnabled,
    toggleModal,
    onWaveSuccess,
    onWaveFailure
  } = props;
  const unit = fullPalletsOnly ? 'pallets' : 'shipments';
  const numberOfShipmentsOrPalletsError = `Enter a value less than the total number of ${unit}.`;
  const numberOfBatchesError = `Enter a value less than the number of ${unit}.`;

  const [numberOfShipmentsOrPallets, setNumberOfShipmentsOrPallets] = React.useState<number>(null);
  const [numberOfBatches, setNumberOfBatches] = React.useState<number>(null);
  const [shipmentsPerBatch, setShipmentsPerBatch] = React.useState<number>(null);

  const [validNumberOfShipmentsOrPallets, setValidNumberOfShipmentsOrPallets] = React.useState<boolean>(true);
  const [validNumberOfBatches, setValidNumberOfBatches] = React.useState<boolean>(true);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const isCreateBatchEnabled = (): boolean => {
    return (
      numberOfShipmentsOrPallets > 0 &&
      validNumberOfShipmentsOrPallets &&
      ((numberOfBatches > 0 && validNumberOfBatches) || isSortationEnabled || isPickToCarton) &&
      !isLoading
    );
  };

  const waveModalTitle = () => {
    let shipByText;
    if (templateToWave.waveFilters.cutoffDate) {
      shipByText =
        templateToWave.waveFilters.cutoffDate === new Date()
          ? 'Today'
          : moment(templateToWave.waveFilters.cutoffDate.toString()).format('MMM D');
    } else if (templateToWave.waveFilters.shipByTime) {
      const shipByTime = moment(templateToWave.waveFilters.shipByTime.toString());
      shipByText = shipByTime.diff(moment(new Date()), 'days') === 0 ? 'Today' : shipByTime.format('MMM D');
    }
    let sortationText;
    if (isPickToCarton) {
      sortationText = (
        <div>
          <b>{'Pick To Carton: '}</b>
          {'On'}
        </div>
      );
    } else if (isSortationEnabled) {
      sortationText = (
        <div>
          <b>{'Sortation: '}</b>
          {'Rebin On'}
        </div>
      );
    } else {
      sortationText = <br />;
    }
    return (
      <div>
        <h5>{templateToWave.templateName}</h5>
        <b>{'Ship By: '}</b>
        {shipByText}
        <br />
        <b>{'Reservation: '}</b>
        {reservationIdToName[templateToWave.waveFilters.reservationId]}
        <br />
        <b>{'Carrier: '}</b>
        {carriers[templateToWave.waveFilters.carriers[0]]}
        {sortationText}
        <br />
        <b>{fullPalletsOnly ? 'Total Full Pallets: ' : 'Total Shipments: '}</b>
        {templateToWave.count}
      </div>
    );
  };

  const waveModalNumberOfBatchesInput = (
    <React.Fragment>
      <label className="form-label" htmlFor="numberOfBatches">
        {'How many batches?'}
      </label>
      <input
        data-testid="numberOfBatchesInput"
        type="text"
        className={`form-control${validNumberOfBatches ? '' : ' error'}`}
        id="numberOfBatches"
        value={numberOfBatches || ''}
        onChange={(event) => handleChangeBatchSizing(event, setNumberOfBatches, setShipmentsPerBatch)}
      />
      {!validNumberOfBatches && <span className="error">{numberOfBatchesError}</span>}
    </React.Fragment>
  );

  const waveModalShipmentsPerBatchInput = (
    <React.Fragment>
      <label className="form-label" htmlFor="shipmentsPerBatch">
        {'Shipments per batch'}
      </label>
      <input
        data-testid="shipmentsPerBatchInput"
        type="text"
        className={`form-control${validNumberOfBatches ? '' : ' error'}`}
        id="shipmentsPerBatch"
        value={shipmentsPerBatch || ''}
        onChange={(event) => handleChangeBatchSizing(event, setShipmentsPerBatch, setNumberOfBatches)}
      />
    </React.Fragment>
  );

  const waveModalShipmentsPerBatchInfo = (
    <span className="input-inline-label" aria-hidden="true">
      {`${shipmentsPerBatch} per batch`}
    </span>
  );

  const waveModalNonsortContent = () => {
    const showShipmentsPerBatchInput = !fullPalletsOnly;
    const hasInputValue = numberOfShipmentsOrPallets > 0 && numberOfBatches > 0;
    return (
      <div className="modal-input-div">
        {waveModalNumberOfBatchesInput}
        {showShipmentsPerBatchInput && waveModalShipmentsPerBatchInput}
        {!showShipmentsPerBatchInput && hasInputValue && waveModalShipmentsPerBatchInfo}
      </div>
    );
  };

  const waveModalMainContent = (
    <div className="modal-input-div">
      <label className="form-label" htmlFor="numberOfShipmentsOrPalletsToBatch">
        {`How many ${fullPalletsOnly ? 'pallets' : 'shipments'} to ${isSfsEnabled ? 'wave' : 'batch'}?`}
      </label>
      <input
        data-testid="numberOfShipmentsOrPalletsInput"
        type="text"
        className={`form-control${validNumberOfShipmentsOrPallets ? '' : ' error'}`}
        id="numberOfShipmentsOrPalletsToBatch"
        value={numberOfShipmentsOrPallets || ''}
        onChange={(event) => handleChangeNumberOfShipmentsOrPallets(event)}
        placeholder={`${templateToWave.count}(max)`}
      />
      <span className="input-inline-label" aria-hidden="true">{`of ${templateToWave.count} Total`}</span>
      {!validNumberOfShipmentsOrPallets && <span className="error">{numberOfShipmentsOrPalletsError}</span>}
    </div>
  );

  const waveModalBody = (
    <div id="waveModalContent" className="col-md-8 col-sm-12">
      {waveModalMainContent}
      <br />
      {!(isSortationEnabled || isPickToCarton) && waveModalNonsortContent()}
    </div>
  );

  const waveModalFooter = (
    <div>
      <a className="btn btn-primary-alt" onClick={() => cancelCreate()}>
        {'Cancel'}
      </a>
      <button className="btn" onClick={() => createBatches()} disabled={!isCreateBatchEnabled()}>
        {`Create ${isSfsEnabled ? 'Waves' : 'Batches'}`}
      </button>
    </div>
  );

  React.useEffect(() => {
    setValidNumberOfShipmentsOrPallets(numberOfShipmentsOrPallets <= templateToWave.count);

    if (!(isSortationEnabled || isPickToCarton)) {
      setValidNumberOfBatches(numberOfBatches <= Number(numberOfShipmentsOrPallets));
    }
  }, [numberOfShipmentsOrPallets, numberOfBatches]);

  const handleChangeNumberOfShipmentsOrPallets = (event) => {
    const value = Number(event.target.value);
    if (Number.isInteger(value) && value >= 0) {
      setNumberOfShipmentsOrPallets(value);
    }
  };

  const handleChangeBatchSizing = (event, setEdited: (n: number) => void, setAlso: (n: number) => void) => {
    const value = Number(event.target.value);
    if (Number.isInteger(value) && value >= 0) {
      setEdited(value);
      setAlso(value === 0 ? 0 : Math.ceil(Number(numberOfShipmentsOrPallets) / value));
    }
  };

  const cancelCreate = () => {
    setNumberOfShipmentsOrPallets(null);
    setNumberOfBatches(null);
    setShipmentsPerBatch(null);
    toggleModal();
  };

  const createBatches = async () => {
    setIsLoading(true);
    const waveParameter = cloneDeep(templateToWave.waveFilters);
    if (fullPalletsOnly) {
      waveParameter.maxOrders = numberOfShipmentsOrPallets * unitsPerPalletCount;
      waveParameter.fullPalletPull = true;
    } else {
      waveParameter.maxOrders = numberOfShipmentsOrPallets;
    }
    if (!(isSortationEnabled || isPickToCarton) && numberOfBatches > 0) {
      waveParameter.batchCount = numberOfBatches;
    }
    const response = await wavingService.previewWave(waveParameter);
    setIsLoading(false);
    cancelCreate();
    if (response && response.errors.length === 0) {
      onWaveSuccess();
    } else {
      onWaveFailure();
    }
  };

  return (
    <div id="async-waving-creator">
      {isLoading && <Loader loading={isLoading} />}
      <LegacyModal
        id="waveModal"
        title={waveModalTitle()}
        hideClose={true}
        show={showModal}
        size="small"
        toggleModal={toggleModal}
        footer={waveModalFooter}
      >
        {waveModalBody}
      </LegacyModal>
    </div>
  );
};

export default AsyncBatchCreator;
