import * as React from 'react';
import classNames from 'classnames';
import {FreightShipMode, ParcelShipMode, ShipMode} from '../../../shared/constants';

interface ModeFilterProps {
  mode: ShipMode;
  onModeChange: (mode: ShipMode) => void;
}

interface ModeOption {
  text: string;
  value?: ShipMode;
  active: boolean;
}

const ModeFilter: React.FC<ModeFilterProps> = (props) => {
  const selectMode = (option: ModeOption) => {
    if (props.mode !== option.value) {
      props.onModeChange(option.value);
    }
  };

  const button = (option: ModeOption) => {
    const baseClasses = 'btn btn-default btn-md';
    const buttonClasses = option.active ? `${baseClasses} active` : baseClasses;

    return (
      <button
        className={buttonClasses}
        onClick={() => {
          selectMode(option);
        }}
      >
        {option.text}
      </button>
    );
  };

  const options: ModeOption[] = [
    {text: 'All', value: null, active: !props.mode},
    {text: 'Freight', value: FreightShipMode, active: props.mode === FreightShipMode},
    {text: 'Parcel', value: ParcelShipMode, active: props.mode === ParcelShipMode}
  ];

  const listClasses = ['list-inline', 'pull-left'];

  return (
    <ul className={classNames(listClasses)} id="mode-filter">
      {options.map((option) => (
        <li key={option.text}>{button(option)}</li>
      ))}
    </ul>
  );
};

export default ModeFilter;
