import * as React from 'react';

import DropDown, {DropDownColor, DropDownOption, DropDownType} from '../../../shared/DropDown';
import ShipmentStatusMap from '../ShipmentConstants';

interface StatusFilterProps {
  status: string;
  onStatusChange: (status: string) => void;
}

const StatusFilter: React.FC<StatusFilterProps> = (props) => {
  const options: DropDownOption[] = [{name: 'All', value: null}];

  ShipmentStatusMap.forEach((statusObject, status) => {
    const className = `shipment-status-dot ${statusObject.className}`;

    const name = (
      <React.Fragment>
        <span className={className} />
        {statusObject.text}
      </React.Fragment>
    );

    options.push({name, value: status});
  });

  const selectedOption = options.find((option) => option.value === props.status);

  const onSelect = (selected) => {
    props.onStatusChange(selected.value);
  };

  return (
    <div>
      <DropDown
        options={options}
        selected={selectedOption}
        color={DropDownColor.white}
        type={DropDownType.secondary}
        prefixText="Status:  "
        onSelect={onSelect}
      />
    </div>
  );
};

export default StatusFilter;
