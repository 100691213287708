// specifically used as an embeddable table of EDI files for display on various detail pages

import * as React from 'react';
import {get} from 'lodash';
import {format as formatDate} from 'date-fns';
import {Loader, Pagination, Table, TableHeader} from '@flexe/ui-components';
import {Company} from '../../shared/CommonInterfaces';
import {V2EdiFile} from './EdiFilesInterfaces';
import EdiFilesServiceV2 from './EdiFilesServiceV2';

interface Props {
  authenticityToken: string;
  resourceType: 'inboundOrder' | 'outboundOrder' | 'itemId';
  resourceValue: string;
  currentCompany: Company;
  hide?: boolean;
  onFilesLoaded: string | ((hasEdiFiles: boolean) => void);
}

interface State {
  continuationTokens: string[];
  loading: boolean;
  files: V2EdiFile[];
  currentPage: number;
  totalCount: number;
}

class EdiFilesForResource extends React.Component<Props, State> {
  private pageSize = 50;
  private ediFilesService: EdiFilesServiceV2;
  private shipperName: string;

  constructor(props) {
    super(props);
    this.ediFilesService = props.ediFilesService || new EdiFilesServiceV2();
    this.shipperName = get(props, 'currentCompany.name') || 'Shipper';
    this.state = {
      continuationTokens: [],
      loading: true,
      files: [],
      currentPage: 1,
      totalCount: 0
    };
  }
  public async componentDidMount() {
    this.loadFiles();
  }

  public render() {
    return (
      <div className="clearfix">
        {this.state.files && this.state.files.length > 0 && !this.state.loading && !this.props.hide && (
          <Table tableData={this.getTableData()} />
        )}
        <Loader loading={this.state.loading && !this.props.hide} />
      </div>
    );
  }

  private loadFiles = async () => {
    const filters = {};
    if (this.props.resourceType === 'itemId') {
      const skuFilterKey = 'sku';
      filters[skuFilterKey] = this.props.resourceValue;
    } else {
      filters[this.props.resourceType] = this.props.resourceValue;
    }
    const data = await this.ediFilesService.getFiles(
      filters,
      this.pageSize,
      this.state.currentPage > 1 ? this.state.continuationTokens[this.state.currentPage - 2] : null
    );
    const continuationToken = get(data, 'data.continuationToken');
    const files = get(data, 'data.files');
    const totalCount = get(data, 'data.totalCount');
    this.setState({
      continuationTokens: [continuationToken],
      files,
      totalCount,
      loading: false
    });
    const hasEdiFiles = files && files.length > 0;
    if (typeof this.props.onFilesLoaded === 'string') {
      // barf hack needed until we fully React-ify front-end
      window[this.props.onFilesLoaded](hasEdiFiles);
    } else {
      this.props.onFilesLoaded(hasEdiFiles);
    }
  };

  private getTableData = () => {
    const displayErrorsCell = this.state.files.some((f) => f.hasErrors);
    const headers = [
      {element: 'Source'},
      {element: 'Date'},
      {element: 'File'},
      // TODO - re-enable this if/when the data becomes available
      // { element: 'Size' },
      {
        className: 'pagination-header',
        element: (
          <Pagination
            page={this.state.currentPage}
            pageSize={this.pageSize}
            paginationHandler={(page) => this.handlePagination(page)}
            totalCount={this.state.totalCount}
          />
        )
      }
    ] as TableHeader[];
    if (displayErrorsCell) {
      headers.unshift({element: ''});
    }

    const rows = this.state.files.map((file: V2EdiFile) => {
      const row: (string | JSX.Element)[] = [
        EdiFilesServiceV2.fileOutgoingFromFlexe(file) ? 'FLEXE' : this.shipperName,
        file.processedAt ? formatDate(file.processedAt, 'MM/DD/YY h:mma') : null,
        file.fileKey ? file.fileKey : EdiFilesServiceV2.getNameFromStorageObject(file.storageObjectName),
        // TODO - re-enable this if/when the data becomes available
        // fileSizeDisplay,
        <a className="pull-right" href={`/s/edi/${file.id}`}>
          <i className="fa fa-chevron-right"></i>
        </a>
      ];
      if (displayErrorsCell) {
        row.unshift(file.hasErrors ? <i className="fa fa-exclamation-triangle red3"></i> : null);
      }
      return row;
    });
    return {
      headers,
      rows
    };
  };

  private handlePagination = (page: number) => {
    this.setState(
      {
        loading: true,
        currentPage: page
      },
      async () => {
        const filters = {};
        filters[this.props.resourceType] = this.props.resourceValue;
        const data = await this.ediFilesService.getFiles(
          filters,
          this.pageSize,
          this.state.currentPage > 1 ? this.state.continuationTokens[this.state.currentPage - 2] : null
        );
        const continuationToken = get(data, 'data.continuationToken');
        const files = get(data, 'data.files');
        const totalCount = get(data, 'data.totalCount');
        const updatedContinuationTokens = this.state.continuationTokens.slice();
        updatedContinuationTokens.push(continuationToken);
        this.setState({
          continuationTokens: updatedContinuationTokens,
          files,
          totalCount,
          loading: false
        });
      }
    );
  };
}

export default EdiFilesForResource;
