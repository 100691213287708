/** @jsxRuntime classic */
/** @jsx jsx */

import * as React from 'react';
import {jsx} from '@emotion/react';
import {Button} from '@flexe/ui-components';
import {Role, User as UserInfo} from '../userManagementInterfaces';

import {userRowStyle} from '../styles';

interface Props {
  currentUserId: number;
  onReactivateClickAsync: (user: UserInfo) => Promise<void>;
  onDeactivateClickAsync: (user: UserInfo) => Promise<void>;
  onRoleToggleAsync: (user: UserInfo, role: string, enableRole: boolean) => Promise<void>;
  allCompanyRoles: Role[];
  user: UserInfo;
}

const User = ({
  currentUserId,
  onDeactivateClickAsync,
  onReactivateClickAsync,
  onRoleToggleAsync,
  allCompanyRoles,
  user
}: Props) => {
  return (
    // HACK: Setting height here to avoid rows without buttons being shorter than rows with buttons
    <tr css={userRowStyle}>
      <td>{`${user.name}${user.isAdmin ? ' (admin)' : ''}`}</td>
      <td>{user.email}</td>
      <React.Fragment>
        {allCompanyRoles.map((role) => (
          <td key={role.title}>
            <input
              type="checkbox"
              checked={(user.companyRoles || []).includes(role.title)}
              onChange={() => onRoleToggleAsync(user, role.title, !(user.companyRoles || []).includes(role.title))}
              disabled={!role.canBeToggled || user.isProcessing}
            />
          </td>
        ))}
      </React.Fragment>
      <td>
        {user.id !== currentUserId && (
          <div
            title={
              user.isActive && !user.canBeDeactivated
                ? // eslint-disable-next-line max-len
                  'Your user permissions do not give you access to this action.\nFor further assistance please contact your account administrator.'
                : null
            }
          >
            <Button
              onPress={user.isActive ? () => onDeactivateClickAsync(user) : () => onReactivateClickAsync(user)}
              isDisabled={(user.isActive && !user.canBeDeactivated) || user.isProcessing}
            >
              {user.isActive ? 'Deactivate User' : 'Re-activate User'}
            </Button>
          </div>
        )}
      </td>
    </tr>
  );
};

export default User;
