import {FunctionComponent} from 'react';
import * as React from 'react';
import classNames from 'classnames';

export interface HeaderDetailProps {
  label: string;
  value?: any;
  hideWhenEmpty?: boolean;
  classes?: string[];
}

const HeaderDetail: FunctionComponent<HeaderDetailProps> = (props: HeaderDetailProps) => {
  const {classes = []} = props;

  const hideWhenEmpty = props.hideWhenEmpty !== false;
  let value = props.value;
  if (!value && hideWhenEmpty) {
    return null;
  }

  if (!value) {
    classes.push('pending-value');
    value = 'Pending';
  }

  return (
    <div className="detail-field">
      <span className="identifier">{props.label}</span>
      <span className={classNames(classes)}>{value}</span>
    </div>
  );
};

export default HeaderDetail;
