import * as React from 'react';

export function CarrierFieldTypesMap(field) {
  switch (field) {
    case 'Boolean':
      return 'checkbox';
    case 'Integer':
      return 'number';
    default:
      return 'text';
  }
}

export function CarrierField({
  fieldName,
  fieldDisplayName,
  fieldValue,
  fieldType,
  onChangeFunction,
  disabled,
  optional
}) {
  return (
    <label htmlFor={fieldName} key={fieldName} className="carrier-account-field col-md-6 space-below">
      {fieldDisplayName}
      <br />
      <input
        type={CarrierFieldTypesMap(fieldType)}
        name={fieldName}
        value={fieldValue}
        checked={CarrierFieldTypesMap(fieldType) === 'checkbox' ? fieldValue : undefined}
        onChange={onChangeFunction}
        disabled={disabled}
      />
      <br />
      {!optional && <span className="required-indicator">&#42; Required</span>}
    </label>
  );
}
