import * as React from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import classNames from 'classnames';
import FlexeButton from './FlexeButton';

interface Props {
  id: string;
  title?: string | JSX.Element;
  show: boolean;
  children: JSX.Element;
  wrapperClass?: string;
  size?: 'small' | 'medium' | 'fullscreen';
  disableClose?: boolean;
  hideClose?: boolean;
  transitionSpeed?: 'fast' | 'slow';
  toggleModal(event: any): void;
}

function FormModal(props: Props) {
  const transitionTimeouts = {
    fast: 1,
    slow: 250
  };
  const transitionSpeed = props.transitionSpeed || 'slow';
  const transitionTimeout = transitionTimeouts[transitionSpeed];

  const modalClasses = ['react-modal', 'form-modal', props.wrapperClass && props.wrapperClass];

  return (
    <TransitionGroup>
      {props.show && (
        <CSSTransition classNames="react-modal" timeout={transitionTimeout}>
          <div className={classNames(modalClasses)}>
            <div className="modal-backdrop"></div>
            <div className="modal" id={props.id} role="dialog" aria-labelledby={`${props.id}_label`}>
              <div className={`modal-dialog${props.size ? ` modal-${props.size}` : ''}`} role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    {!props.hideClose && (
                      <FlexeButton
                        text={<span aria-hidden="true">&times;</span>}
                        handleClick={props.toggleModal}
                        isDisabled={props.disableClose}
                        level="collapsed"
                      />
                    )}
                    {typeof props.title === 'string' ? (
                      <h4 className="modal-title" id={`${props.id}_label`}>
                        {props.title}
                      </h4>
                    ) : (
                      props.title
                    )}
                  </div>
                  <div className="modal-body">{props.children}</div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

export default FormModal;
