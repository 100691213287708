import * as React from 'react';
import {stringify as queryStringify} from 'query-string';
import {Icon} from '@flexe/ui-components';
import WarehouseService from '../../shared/services/WarehouseService';
import {PackingListItem} from './DropoffInterfaces';

interface Props {
  supportsFulfillment: boolean;
  expectedPackingLists: PackingListItem[];
  shippablePackingLists: PackingListItem[];
  damagedPackingLists: PackingListItem[];
  hasLpns: boolean;
  containerDeliveryId: number;
}

export default function ContainerContents({
  supportsFulfillment,
  expectedPackingLists,
  shippablePackingLists,
  damagedPackingLists,
  hasLpns,
  containerDeliveryId
}: Props) {
  const generatePalletLabelUrl = (params: string) => {
    return `/api/v2/containers/${containerDeliveryId}/content_labels.pdf?${params}`;
  };

  const getPalletLabelUrlForExpected = (
    expectedPackingList: PackingListItem,
    damagedPackingList?: PackingListItem,
    shippablePackingList?: PackingListItem
  ) => {
    const unit = expectedPackingList.quantity.unit;
    const numShippable = shippablePackingList?.pallets || 1;
    const numDamaged = damagedPackingList?.pallets || 0;
    return generatePalletLabelUrl(
      queryStringify({
        inventoryId: expectedPackingList.inventory.id,
        numShippable,
        numDamaged,
        unit
      })
    );
  };

  const getPalletLabelUrlForUnexpectedShippable = (
    shippablePackingList: PackingListItem,
    damagedPackingList?: PackingListItem
  ) => {
    const unit = shippablePackingList.quantity.unit;
    const numShippable = shippablePackingList.pallets || 1;
    const numDamaged = damagedPackingList?.pallets || 0;
    return generatePalletLabelUrl(
      queryStringify({
        inventoryId: shippablePackingList.inventory.id,
        numShippable,
        numDamaged,
        unit
      })
    );
  };
  return (
    <div>
      <h3>{hasLpns ? 'SKU Summary' : 'Container Contents'}</h3>
      <table className="table">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Description</th>
            <th>{supportsFulfillment ? 'Expected' : 'Quantity'}</th>
            {!supportsFulfillment && <th>Cartons per Pallet</th>}
            {supportsFulfillment && [
              <th key="shippable">Shippable Received</th>,
              <th key="damaged">Damaged Received</th>,
              <th key="unit">Unit</th>
            ]}
            {supportsFulfillment && !hasLpns && <th key="labels">Pallet Labels</th>}
          </tr>
        </thead>
        <tbody>
          {supportsFulfillment && [
            expectedPackingLists.map((epl, idx) => {
              const shippablePackingList = shippablePackingLists.find((spl) => {
                return epl.inventory.id === spl.inventory.id && epl.quantity.unit === spl.quantity.unit;
              });
              const damagedPackingList = damagedPackingLists.find((dpl) => {
                return epl.inventory.id === dpl.inventory.id && epl.quantity.unit === dpl.quantity.unit;
              });
              const labelUrl = getPalletLabelUrlForExpected(epl, damagedPackingList, shippablePackingList);
              return (
                <tr key={`expected${idx}`}>
                  <td>{epl.inventory.sku}</td>
                  <td>{epl.inventory.description}</td>
                  <td>{epl.quantity.amount}</td>
                  <td>{shippablePackingList ? shippablePackingList.quantity.amount : '0'}</td>
                  <td>{damagedPackingList ? damagedPackingList.quantity.amount : '0'}</td>
                  <td>{epl.quantity.unit}</td>
                  {!hasLpns && (
                    <td>
                      <a className="btn" target="_blank" href={labelUrl}>
                        <Icon icon="print" /> Print
                      </a>
                    </td>
                  )}
                </tr>
              );
            }),
            shippablePackingLists
              // check for any unexpected shippable items
              .filter((spl) => {
                return (
                  expectedPackingLists.filter((pl) => {
                    return pl.inventory.id === spl.inventory.id && pl.quantity.unit === spl.quantity.unit;
                  }).length < 1
                );
              })
              .map((spl, idx) => {
                const damagedPackingList = damagedPackingLists.find((dpl) => {
                  return spl.inventory.id === dpl.inventory.id && spl.quantity.unit === dpl.quantity.unit;
                });
                const labelUrl = getPalletLabelUrlForUnexpectedShippable(spl, damagedPackingList);
                return (
                  <tr key={`unexpected${idx}`}>
                    <td>{spl.inventory.sku}</td>
                    <td>{spl.inventory.description}</td>
                    <td>0</td>
                    <td>{spl.quantity.amount}</td>
                    <td>{damagedPackingList ? damagedPackingList.quantity.amount : '0'}</td>
                    <td>{spl.quantity.unit}</td>
                    {!hasLpns && (
                      <td>
                        <a className="btn" target="_blank" href={labelUrl}>
                          <Icon icon="print" /> Print
                        </a>
                      </td>
                    )}
                  </tr>
                );
              })
          ]}
          {!supportsFulfillment &&
            shippablePackingLists.map((pl, idx) => {
              return (
                <tr key={`actual${idx}`}>
                  <td>{pl.inventory.sku}</td>
                  <td>{pl.inventory.description}</td>
                  <td>
                    {pl.quantity.amount} {pl.quantity.unit}
                  </td>
                  <td>{pl.inventory.properties.carton.unitsPerParent}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
