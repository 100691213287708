import * as React from 'react';
import {get, isEmpty} from 'lodash';
import {Item} from '../../shared/services/ItemMasterService';
import CycleCountsService from '../services/CycleCountsService';
import LocationsService from '../../locations/LocationsService';
import Expando from '../Expando';
import {CycleCountDetails, CycleCountItem, CycleCountStatus, CycleCountType} from './CycleCountInterfaces';
import {CCItemAddExtraRow} from './CCItemAddExtraRow';
import {isPlaceholderItem} from './helpers';
import CycleCountItemsList from './CycleCountItemsList';
import {ItemPropertiesDetailsModalState, LpnDetailsModalState} from './CycleCountDetail';

interface Props {
  itemIndex: number;
  forShipper: boolean;
  byLocation: boolean;
  viewByLocation: boolean;
  isEditing: boolean;
  isReviewing: boolean;
  usesMobileLocations: boolean;
  ccState: string;
  ccType: string;
  ccItems: CycleCountItem[];
  showLpnDetailsModal: LpnDetailsModalState;
  showItemPropertiesDetailsModal: ItemPropertiesDetailsModalState;
  disabled: boolean;
  cycleCount: CycleCountDetails;
  renderCurrentWorkers: () => JSX.Element;
  toggleLpnDetailsModalWithData: (data: string) => void;
  toggleLpnDetailsModalWithEvent: (event: any) => void;
  toggleItemDetailsModalWithId: (id: string) => void;
  saveLpnMetadataEdits: (data) => Promise<void>;
  onUpdateItem: (CycleCountItem) => void;
  onAddNewItem: (CycleCountItem) => void;
  onWarehouserError: (errors: any) => void;
  warehouseId: number;
  activeWorkersExist: boolean;
  locationsService: LocationsService;
  cycleCountsService: CycleCountsService;
  enableLpnCycleCounts: boolean;
  isReservationLPNEnabled: boolean;
  enableLpnPropertiesCCButton: boolean;
  carryForwardItdIdAfterDecomp: boolean;
  hideAddItemButton: boolean;
  editMetadataOnLooseGoods: boolean;
  enableMobileCountConfiguredUomUpdates: boolean;
  inventoryInfos: Item[];
}

interface State {
  isExpanded: boolean;
  isAddingExtraRow: boolean;
}

class CCItemExpando extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: true,
      isAddingExtraRow: false
    };
  }

  public render() {
    return (
      <Expando
        key={this.props.itemIndex}
        index={this.props.itemIndex}
        headerText={this.renderHeader()}
        open={this.state.isExpanded}
        onClick={this.toggleExpando}
      >
        <div className="row">
          {this.renderTables()}
          <div className="col-sm-12">{this.renderAdditionalRow()}</div>
        </div>
      </Expando>
    );
  }

  private toggleExpando = () => {
    const isExpanded = !this.state.isExpanded;
    this.setState({isExpanded});
  };

  private toggleIsAddingExtraRow = () => {
    const isAddingExtraRow = !this.state.isAddingExtraRow;
    this.setState({isAddingExtraRow});
  };

  private onNewItemSelected = (item: CycleCountItem) => {
    this.toggleIsAddingExtraRow();
    this.props.onAddNewItem(item);
  };

  private renderHeader(): JSX.Element {
    const realItems = this.props.ccItems.filter((item) => !isPlaceholderItem(item));
    const realLocations = this.props.ccItems.filter((item, index, self) => {
      return !isPlaceholderItem(item) && index === self.findIndex((i) => i.location.id === item.location.id);
    });
    const countedItems = this.props.ccItems
      .filter((item) => !isPlaceholderItem(item))
      .filter((item) => !isEmpty(item.countedQuantities));

    // Group counted items by Location Id and filter locations out with empty counts
    const countedLocations = Array.from(
      this.props.ccItems
        .reduce(
          (entryMap, e) => entryMap.set(e.location.id, [...(entryMap.get(e.location.id) || []), e.countedQuantities]),
          new Map()
        )
        .values()
    ).filter((e) => !e.some((x) => isEmpty(x)));

    const identifier = get(this.props.ccItems[0], this.props.viewByLocation ? 'location.label' : 'inventory.sku') || '';
    const additionalDetail = !this.props.byLocation && (
      <small>{get(this.props.ccItems[0], 'inventory.description')}</small>
    );

    const showProgress = this.props.ccState === CycleCountStatus.inProgress;
    const countedText = (this.props.viewByLocation ? 'items' : 'locations') + ' counted';
    const realToCount = this.props.viewByLocation ? realItems.length : realLocations.length;
    const count = this.props.viewByLocation ? countedItems.length : countedLocations.length;
    const progressClass = count === realToCount ? 'successGreen' : 'red2';
    const countProgressDetail = showProgress && (
      <div className={`col-xs-4 ${progressClass}`}>
        <small>
          {count} / {realToCount} {countedText}
        </small>
      </div>
    );

    return (
      <div className="row">
        <div className="col-xs-8">
          {identifier} {additionalDetail}
        </div>
        {countProgressDetail}
      </div>
    );
  }

  private renderAdditionalRow(): JSX.Element {
    const mayAddRowsInViewType = this.props.byLocation
      ? this.props.ccType === CycleCountType.location
      : this.props.ccType === CycleCountType.item;
    const mayAddRowsForLpnDecompAtPutaway = !this.props.carryForwardItdIdAfterDecomp;
    const mayAddRows = this.props.hideAddItemButton //ff on
      ? this.props.isEditing && //true
        this.props.usesMobileLocations && //true
        mayAddRowsInViewType && //true
        this.props.enableLpnCycleCounts === true && //true
        !this.props.isReservationLPNEnabled && //no lpn or lpn decomp res
        mayAddRowsForLpnDecompAtPutaway //not carry ff
      : //FF OFF
        this.props.isEditing && //true
        this.props.usesMobileLocations && //true
        mayAddRowsInViewType && //true
        this.props.enableLpnCycleCounts === true && //true
        !this.props.isReservationLPNEnabled; //lpn decomp res

    // mayAddRows == can the user access the option to add an extra row here
    // isAddingExtraRow == the user clicked the + and is selecting the new identifier

    if (mayAddRows && this.state.isAddingExtraRow) {
      return (
        <CCItemAddExtraRow
          {...this.props}
          onItemSelected={this.onNewItemSelected}
          toggleIsCurrentlyAdding={this.toggleIsAddingExtraRow}
        />
      );
    } else if (mayAddRows) {
      return this.renderAddOption();
    }
    // returns nothing otherwise
  }

  private renderAddOption(): JSX.Element {
    return (
      <a onClick={this.toggleIsAddingExtraRow} data-index={this.props.itemIndex} tabIndex={-1}>
        <i className="fa fa-plus"></i>
        &nbsp; Add {this.props.byLocation ? 'Inventory' : 'Location'}
      </a>
    );
  }

  private renderTables(): JSX.Element {
    if (this.props.enableLpnCycleCounts) {
      const skuCycleCountListProps = {
        ...this.props,
        ccItems: this.props.ccItems.filter((cci) => cci.lpnBarcode == null),
        enableLpnCycleCounts: false,
        enableLpnPropertiesCCButton: false
      };
      const lpnCycleCountListProps = {
        ...this.props,
        ccItems: this.props.ccItems.filter((cci) => cci.lpnBarcode)
      };

      return (
        <>
          {skuCycleCountListProps.ccItems.length > 0 ? <CycleCountItemsList {...skuCycleCountListProps} /> : null}
          {lpnCycleCountListProps.ccItems.length > 0 ? <CycleCountItemsList {...lpnCycleCountListProps} /> : null}
        </>
      );
    } else {
      return <CycleCountItemsList {...this.props} />;
    }
  }
}

export default CCItemExpando;
