// This function takes response data from a request,
// and creates an invisible download link (added right to the document).
// It then triggers a click on that link, which will download the file.
const useDownloadLink = ({response, fileName, testId}: {response: any; fileName: string; testId: string}) => {
  const type = response.headers['content-type'];
  const blob = new Blob([response.data], {type});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.setAttribute('testId', testId);
  link.click();
};

export default useDownloadLink;
