import {groupBy} from 'lodash';
import * as React from 'react';
import {FC, useState, useEffect} from 'react';
import {Tabs} from '@flexe/ui-components';
import {Location, Shipment} from '../ShipmentInterfaces';
import {FreightShipMode} from '../../../shared/constants';
import {getMostGranularCommonUnit, getTotalQuantity} from '../ShipmentHelper';
import {usePalletQuantityErrorMessage} from '../PalletQuantityError';
import {ShipmentRetailVariance} from '../ShipmentRetailVariance';
import {LocationContent} from '../../../locations/LocationsService';
import {ManifestLpn} from '../../loads/ManifestInterfaces';
import {LpnSearchDetails, LpnType} from '../../../lpns/LpnsInterfaces';
import {pluralizeUnit} from '../../../shared/utilities/DataFormatting';
import {ShipmentLineItems} from './ShipmentLineItems';
import {ShipmentLpns} from './ShipmentLpns';
import {ShipmentManifests} from './ShipmentManifests';
import {useManifestLpns} from './UseManifestLpns';

enum ContentTab {
  MANIFESTS = 'manifests',
  SHIPMENT_LINE_ITEMS = 'line_items',
  LPNS = 'lpns'
}

interface Props {
  shipment: Shipment;
  lpns: LpnSearchDetails[];
  lpnsLoading: boolean;
  looseGoodsInStagingLocation: LocationContent[];
  shipmentVariance: ShipmentRetailVariance;
  stagingLocation: Location;

  palletCount?: number;
  recommendedPalletQuantity: number;
  setPalletCount?: (count: number) => void;
  savePalletCount?: () => void;
  setTrailerLoadedState?: (isTrailerLoaded: boolean) => void;

  showLpnPanel: boolean;

  isFreightTrailerLoadingEnabled: boolean;
  viewOnlyPalletsLoaded?: boolean;
  onLpnsUpdated: (palletCount: number) => Promise<void>;

  selectedTab?: string;
}

export const ShipmentContents: FC<Props> = (props) => {
  const {isFreightTrailerLoadingEnabled, setTrailerLoadedState} = props;
  const greatestCommonUnit = getMostGranularCommonUnit(props.shipment.line_items, {useOrderedQty: true});
  const total = getTotalQuantity(props.shipment, greatestCommonUnit, {useOrderedQty: true});

  const isFreight = props.shipment.transportation.ship_mode === FreightShipMode;
  const showLpnPanel = props.showLpnPanel && isFreight;
  const {manifestLpns} = useManifestLpns(props.shipment, isFreightTrailerLoadingEnabled, setTrailerLoadedState);
  const manifestLpnsByManifestId = groupManifestLpnsByNonNilManifestId(manifestLpns);

  const palletErrorMessage = usePalletQuantityErrorMessage(props);
  const [selectedTab, setSelectedTab] = useState(props.selectedTab || ContentTab.SHIPMENT_LINE_ITEMS);
  const [tabs, setTabs] = useState(<></>);

  useEffect(() => {
    setTabs(buildContentsTabs());
  }, [props.lpns, selectedTab, manifestLpns]);

  const cartonCount = () => {
    const numCartons = props.lpns.filter((lpn) => lpn.lpnType === LpnType.carton).length;
    return numCartons === 0 ? <></> : <span className="num-cartons">Cartons: {numCartons}</span>;
  };

  const palletCount = () => {
    const numPallets = props.lpns.filter((lpn) => lpn.lpnType === LpnType.pallet).length;
    return numPallets === 0 ? <></> : <span className="num-pallets">Pallets: {numPallets}</span>;
  };

  const header = () => (
    <h2>
      Contents
      <span className="contents-total">
        <b>Expected: </b>
        {`${total} ${pluralizeUnit(greatestCommonUnit, total)}`}
      </span>
      {palletCount()}
      {cartonCount()}
      <div className="validation-error">{palletErrorMessage && <span>{palletErrorMessage}</span>}</div>
    </h2>
  );

  const onTabSelection = (key: ContentTab) => {
    if (key === selectedTab) {
      return;
    }
    setSelectedTab(key);
  };

  const buildContentsTabs = () =>
    showLpnPanel && (
      <Tabs
        tabs={[
          {
            active: selectedTab === ContentTab.SHIPMENT_LINE_ITEMS,
            key: ContentTab.SHIPMENT_LINE_ITEMS,
            title: `Lines (${props.shipment.line_items.length})`
          },
          {
            active: selectedTab === ContentTab.LPNS,
            key: ContentTab.LPNS,
            title: `LPNs (${props.lpns.length})`
          },
          ...(isFreightTrailerLoadingEnabled
            ? [
                {
                  active: selectedTab === ContentTab.MANIFESTS,
                  key: ContentTab.MANIFESTS,
                  title: `Loads (${Object.keys(manifestLpnsByManifestId).length})`
                }
              ]
            : [])
        ]}
        tabEvent={(tabKey) => onTabSelection(tabKey)}
      />
    );

  const shipmentLpnsPanel = () => {
    if (selectedTab !== ContentTab.LPNS) {
      return;
    }
    return (
      <ShipmentLpns
        shipment={props.shipment}
        lpns={props.lpns}
        manifestLpns={manifestLpns}
        lpnsLoading={props.lpnsLoading}
        isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
        stagingLocation={props.stagingLocation}
        onLpnsUpdated={props.onLpnsUpdated}
      />
    );
  };

  return (
    <div id="shipment-contents">
      <div className="container-fluid">
        <div className="contents-container">
          <header>{header()}</header>
          {tabs}
          {selectedTab === ContentTab.SHIPMENT_LINE_ITEMS && (
            <ShipmentLineItems
              manifestLpns={manifestLpns}
              shipment={props.shipment}
              lpns={props.lpns}
              shipmentVariance={props.shipmentVariance}
              looseGoodsInStagingLocation={props.looseGoodsInStagingLocation}
              isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
            />
          )}
          {shipmentLpnsPanel()}
          {selectedTab === ContentTab.MANIFESTS && <ShipmentManifests lpnsByManifestId={manifestLpnsByManifestId} />}
        </div>
      </div>
    </div>
  );
};

function groupManifestLpnsByNonNilManifestId(lpns: ManifestLpn[]) {
  return groupBy(
    lpns.filter((_) => !!_.outbound_manifest_id),
    (_) => _.outbound_manifest_id
  );
}
