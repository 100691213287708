import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import WarehouseService from '../../shared/services/WarehouseService';
import CycleCountsService from '../../shared/services/CycleCountsService';
import {FilterOption} from '../../shared/CommonInterfaces';
import LocationsService from '../../locations/LocationsService';
import InventoryService from '../services/InventoryService';
import ItemMasterService from '../services/ItemMasterService';
import CycleCountCreator from './CycleCountCreator';
import CycleCountDetail from './CycleCountDetail';
import {ReservationFilterOption} from './CycleCountInterfaces';
import CycleCountList from './CycleCountList';

interface CycleCountsProps {
  authenticityToken: string;
  isShipper: boolean;
  warehouses: FilterOption[];
  currentWarehouse: FilterOption;
  reservations?: ReservationFilterOption[];
  currentReservation?: ReservationFilterOption;
  enableLpnCycleCounts: boolean;
  enableLpnPropertiesCCButton: boolean;
  enableCycleCountCsvCreation: boolean;
  enableCycleCountReportEnhancements: boolean;
  carryForwardItdIdAfterDecomp: boolean;
  hideAddItemButton: boolean;
  editMetadataOnLooseGoods: boolean;
  enableMobileCountConfiguredUomUpdates: boolean;
}

function CycleCounts(props: CycleCountsProps) {
  const warehouseService = new WarehouseService(props.authenticityToken);
  const cycleCountsService = new CycleCountsService(props.authenticityToken);
  const locationsService = new LocationsService(props.authenticityToken);
  const inventoryService = new InventoryService(props.authenticityToken);
  const itemMasterService = new ItemMasterService();
  if (!props.isShipper) {
    cycleCountsService.currentWarehouse = Number(props.currentWarehouse.value);
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/(s|wh)/cycle_counts"
          exact
          strict
          render={(routeProps) => (
            <CycleCountList
              {...routeProps}
              {...props}
              cycleCountsService={cycleCountsService}
              warehouseService={warehouseService}
            />
          )}
        />
        <Route
          path="/(s|wh)/cycle_counts/new"
          exact
          strict
          render={(routeProps) => (
            <CycleCountCreator
              {...routeProps}
              {...props}
              cycleCountsService={cycleCountsService}
              locationsService={locationsService}
              inventoryService={inventoryService}
              warehouseService={warehouseService}
            />
          )}
        />
        <Route
          path="/(s|wh)/cycle_counts/:id"
          exact
          strict
          render={(routeProps) => (
            <CycleCountDetail
              {...routeProps}
              {...props}
              cycleCountsService={cycleCountsService}
              warehouseService={warehouseService}
              locationsService={locationsService}
              itemMasterService={itemMasterService}
              inventoryService={inventoryService}
            />
          )}
        />
      </Switch>
    </Router>
  );
}

export default CycleCounts;
