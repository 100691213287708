import * as React from 'react';
import {useState, ReactNode} from 'react';
import {v4} from 'uuid';
import HoverableList from '../../../shared/HoverableList';
import {UploadParams} from '../../edi-files/EdiFilesInterfaces';
import {GenericErrorDisplay} from '../../../shared/GenericErrorDisplay';
import FileStorageService from '../../file-storage/FileStorageService';
import EdiFilesServiceV2 from '../../edi-files/EdiFilesServiceV2';
import InternalAPIServiceV2 from '../../../shared/services/InternalAPIServiceV2';
import {
  instructionsTableHeaders,
  instructionsTableRows as baseInstructionsTableRows
} from '../helpers/OutboundOrdersUploadInstructions';
import CsvBulkUploadModalOMS, {Section, UploadHandlerCallback} from '../generic/CsvBulkUploadModalOMS';
import {CSVTemplate} from '../helpers/OutboundOrderCsv';

export interface Props {
  csvTemplate: CSVTemplate;
  distributionByLotShipper: boolean;
  serviceTypes: {[index: string]: string[]};
  carriers: string[];
  oldHeaders: string[][];
  oldFileUploadType: string;
  headers: string[][];
  fileUploadType: string;
  showUploadModal: boolean;
  toggleUploadModal: () => void;
  fileStorageService: FileStorageService;
  ediFilesServiceV2: EdiFilesServiceV2;
}

const buildCarrierInstructionsRow = (baseRow: ReactNode[], serviceTypes: {[index: string]: string[]}): ReactNode[] => {
  const carriers = Object.keys(serviceTypes).sort();

  let newDescription: ReactNode;
  let newSample: ReactNode;
  if (carriers.length > 0) {
    newSample = carriers[0];
    newDescription = (
      <>
        Must be one of the following:
        <br />
        <ul className="carrier-list">
          {carriers.map((carrier, i) => {
            return (
              <li key={carrier} className="italics display-inline">
                {carrier}
                {i === Object.keys(serviceTypes).length - 1 ? '' : ', '}
              </li>
            );
          })}
          <li className="italics display-inline">, Freight</li>
        </ul>
      </>
    );
  } else {
    newSample = 'Freight';
    newDescription = (
      <>
        Must be: <span className="italics">Freight</span>
      </>
    );
  }

  return baseRow.map((cell, idx) => {
    if (idx === 3) return newDescription;
    if (idx === 4) return newSample;
    return cell;
  });
};

const buildServiceLevelInstructionsRow = (
  baseRow: ReactNode[],
  serviceTypes: {[index: string]: string[]}
): ReactNode[] => {
  const carriers = Object.keys(serviceTypes).sort();

  let newDescription: ReactNode;
  let newSample: ReactNode;
  if (carriers.length > 0) {
    const firstCarrierWithLevels: string | undefined = carriers.find((v) => serviceTypes[v].length > 0);

    newDescription = (
      <>
        <HoverableList
          listItems={serviceTypes}
          title="Hover to see service levels available for each carrier:"
          inline={true}
          italics={true}
        />
        <br />
        <span>SCAC must be 2-4 upper-case letters or numbers</span>
      </>
    );

    newSample = (
      <>
        <span className="italics">Service Level:</span>{' '}
        {firstCarrierWithLevels ? (
          serviceTypes[firstCarrierWithLevels][0]
        ) : (
          <span className="italics">None Available</span>
        )}
        <br />
        <span className="italics">SCAC:</span> S224
      </>
    );
  } else {
    newDescription = (
      <>
        <span className="italics">No service levels are available</span>
        <br />
        <br />
        <span>SCAC must be 2-4 upper-case letters or numbers</span>
      </>
    );

    newSample = 'S224';
  }

  return baseRow.map((cell, idx) => {
    if (idx === 3) return newDescription;
    if (idx === 4) return newSample;
    return cell;
  });
};

const buildInstructionTableRows = (
  serviceTypes: {[index: string]: string[]},
  distributionByLotShipper: boolean
): ReactNode[][] => {
  const instructionsTableRows: ReactNode[][] = [];

  Object.keys(baseInstructionsTableRows).forEach((key) => {
    const baseRow = baseInstructionsTableRows[key];

    if (key === 'carrier' && Object.keys(serviceTypes).length) {
      instructionsTableRows.push(buildCarrierInstructionsRow(baseRow, serviceTypes));
    } else if (key === 'serviceLevel' && Object.keys(serviceTypes).length) {
      instructionsTableRows.push(buildServiceLevelInstructionsRow(baseRow, serviceTypes));
      // Only show instructions for these columns if the shipper has Order by Lot enabled
    } else if (key === 'lotCode' || key === 'expirationDate') {
      if (distributionByLotShipper === true) {
        instructionsTableRows.push(baseRow);
      }
    } else {
      instructionsTableRows.push(baseRow);
    }
  });

  return instructionsTableRows;
};

const uniqueIdColumn = <span className="italics">Customer Order Number</span>;
const supplementalSections: Section[] = [
  {
    title: 'How are Rows Grouped Into Orders?',
    body: (
      <p>
        Orders are identified by the {uniqueIdColumn} column. Every row in the CSV with the same {uniqueIdColumn} value
        is considered to be part of the same order. However, all rows with the same value must be next to each other.
        <br />
        <br />
        For example, if rows 2 and 4 use the same {uniqueIdColumn} and row 3 uses a different value, the upload will
        fail because rows 2 and 4 should have been next to each other.
      </p>
    )
  },
  {
    title: 'What is Order-Level vs Line-Level Data?',
    body: (
      <p>
        Order-Level data are concepts where a single value applies to the whole order. Order-level values are applied
        from the first row for each order. Flexe recommends ensuring that each Order-Level values are consistent for
        each row of an order to avoid confusion. For example, if rows 2 and 3 are for the same order, but the{' '}
        <span className="italics">Name</span> column is different on each row, an order will be created using the value
        from row 2.
        <br />
        <br />
        Line-Level data are concepts that define individual order lines. Each row will create a unique order line based
        on these Line-Level values. For example, if rows 2 and 3 are for the same order, an order with two lines will be
        created - one line based on the Line-Level data from row 2, and another for the Line-Level data from row 3.
        <br />
        <br />
        Columns are Order-Level, unless specified as Line-Level in the{' '}
        <span className="italics">Description of Use</span>.
      </p>
    )
  }
];

const uploadFileType = 'outbound_order_csv_v2';

const OutboundOrdersUploadModal: React.FC<Props> = (props: Props) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const instructionsTableRows = buildInstructionTableRows(props.serviceTypes, props.distributionByLotShipper);

  const handleUpload = async (params: UploadParams, _: string, callback: UploadHandlerCallback) => {
    const responseErrors: GenericErrorDisplay[] = [];
    setIsUploading(true);

    const fileKey = `csv_outbound_order_${v4()}`;
    let response = await props.fileStorageService.uploadFile(params.file, fileKey, {returnAllErrors: true});
    if (response && !(response.errors && response.errors.length > 0)) {
      response = await props.ediFilesServiceV2.createEdiFile(
        props.ediFilesServiceV2.getSupportedCSVUploadFileType(uploadFileType),
        fileKey,
        {},
        {returnAllErrors: true}
      );
    }

    if (!response) {
      responseErrors.push({
        header: 'Could not reach Flexe to upload file. Please try again and contact Flexe if the error persists.'
      });
    } else if (response.errors && response.errors.length > 0) {
      const errorDetails = InternalAPIServiceV2.extractErrorSummaries(response.errors);
      responseErrors.push({
        header: 'Unexpected error while attempting to submit the file:',
        details: errorDetails
      });
    } else {
      props.toggleUploadModal();
    }

    setIsUploading(false);
    callback(responseErrors);
  };

  return (
    <CsvBulkUploadModalOMS
      id="outbound-orders-upload-modal"
      title="Create Orders From a CSV"
      actionDescription="create orders"
      show={props.showUploadModal}
      csvTemplates={[props.csvTemplate]}
      toggleModal={props.toggleUploadModal}
      uploading={isUploading}
      instructionSupplementalSections={supplementalSections}
      instructionsTableHeaders={instructionsTableHeaders}
      instructionsTableRows={instructionsTableRows}
      handleUpload={handleUpload}
      // TODO change backwardsCompatible to false once the old template has been retired
      backwardsCompatible={true}
      headers={props.headers}
      fileUploadType={props.fileUploadType}
      oldHeaders={props.oldHeaders}
      oldFileUploadType={props.oldFileUploadType}
      templateUpdateDateString="2/1/22"
    />
  );
};

export default OutboundOrdersUploadModal;
