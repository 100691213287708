import * as React from 'react';
import {WarehouseTaskStep} from './WarehouseTasksInterfaces';
import WarehouseTaskStepRow from './WarehouseTaskStepRow';

interface WarehouseTaskStepsTableProps {
  isOmniTaskFilterEnabled: boolean;
  taskStepsToDisplay: WarehouseTaskStep[];
}

/**
 * Table component that displays all of the steps associated with a warehouse task
 */
class WarehouseTaskStepsTable extends React.Component<WarehouseTaskStepsTableProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>{this.props.isOmniTaskFilterEnabled ? 'Task Step ID' : 'Id'}</th>
            <th>Performed By</th>
            <th>Source Location</th>
            <th>Destination Location</th>
            <th>{this.props.isOmniTaskFilterEnabled ? 'SKU' : 'Item ID'}</th>
            <th>Unit of Measurement</th>
            <th>Last Updated At</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {this.props.taskStepsToDisplay
            .map((whTaskStep) => {
              return [<WarehouseTaskStepRow key={'taskStep' + whTaskStep.id} taskStep={whTaskStep} />];
            })
            .sort(sortBySku)}
        </tbody>
      </table>
    );
  }
}

export default WarehouseTaskStepsTable;
const sortBySku = (a, b) => {
  const sku1 = a[0].props.taskStep.sku;
  const sku2 = b[0].props.taskStep.sku;

  if (sku1 < sku2) {
    return -1;
  }
  if (sku1 > sku2) {
    return 1;
  }
  return 0;
};
