import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';

interface WavingSuccessModalProps {
  show: boolean;
  isSfsEnabled: boolean;
  onClose: () => void;
}

const WavingSuccessModal: React.FC<WavingSuccessModalProps> = ({onClose, isSfsEnabled, show}) => (
  <LegacyModal
    id="waving-success-modal"
    size="medium"
    show={show}
    toggleModal={onClose}
    footer={
      <div>
        <a href={`/wh/${isSfsEnabled ? 'waves' : 'fulfillment/ecommerce'}`}>
          Go to {isSfsEnabled ? 'Waves and Batches' : 'Batches'} Page
        </a>
        <br />
        <br />
        <a className="btn btn-modal-complete" onClick={onClose}>
          OK
        </a>
      </div>
    }
  >
    <div>
      <i className="fa fa-check success-check" aria-hidden="true"></i>
      <h1>Shipments Waved Successfully</h1>
      <p>
        {isSfsEnabled
          ? // eslint-disable-next-line max-len
            'Waved shipments will appear as waves on the "Waves and Batches" Page. The new waves may take a minute to appear.'
          : 'Waved shipments will appear as batches on the Batches Page. The new batches may take a minute to appear.'}
      </p>
    </div>
  </LegacyModal>
);

export default WavingSuccessModal;
