import * as React from 'react';
import * as Papa from 'papaparse';
import StepOne from '../shared/csv-upload/StepOne';
import StepTwo from '../shared/csv-upload/StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';

interface BatchRetailState {
  step: number;
  reservationIsOmniEnabled?: boolean;
  parsedData?: any;
  parseErrors?: Papa.ParseError[];
  reservationId?: number;
  requests?: any;
  successfulParse?: boolean;
}

interface BatchRetailProps {
  reservations: any;
  sampleFileUrl: string;
  authenticityToken: string;
  successRedirect: string;
}

class BatchRetail extends React.Component<BatchRetailProps, BatchRetailState> {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      reservationIsOmniEnabled: false
    };
  }

  public render() {
    return (
      <div id="batch-retail-component">
        {this.state.reservationIsOmniEnabled && (
          <div className="alert alert-warning" role="alert" key="omni-warning">
            <span>
              The reservation selected isn’t available to create a Retail Fulfillment Order. Please submit this request
              through <a href="/s/fulfillment/orders">Orders</a>
            </span>
          </div>
        )}
        <ul className="pickup fulfillment" id="workflow-steps">
          <StepOne onChange={this.handleReservationSelection} reservations={this.props.reservations} />

          {this.state.step >= 2 && (
            <StepTwo
              handleFileUpload={this.handleFileUpload}
              parseErrors={this.state.parseErrors}
              sampleFileUrl={this.props.sampleFileUrl}
              successfulParse={this.state.successfulParse}
              helpContent={
                <div>
                  <h5>Date and Time Format</h5>
                  <p className="note">
                    Dates should be <code>MM/DD/YY</code> for best results. Times should be in the format
                    <code>HH:MM</code>. Hours should be in
                    <a target="_blank" href="https://en.wikipedia.org/wiki/24-hour_clock">
                      {' '}
                      24 hour time
                    </a>{' '}
                    for best results.
                  </p>
                  <h5>Optional Columns</h5>
                  <p className="note">
                    Routing Service column, Route by Deadline columns and Address 2 column are the only columns which
                    are optional. Missing data from other fields will result in an error. Routing Service will default
                    to your companies default Routing Service if not provided.
                  </p>
                  <h5>Multiple SKUs</h5>
                  <p className="note">
                    When including multiple SKUs per PO, use seperate lines with all the columns matching except SKU,
                    Quantity, and Unit.
                  </p>
                </div>
              }
            />
          )}

          {this.state.step >= 3 && (
            <StepThree rawRequests={this.state.parsedData} handleConfirmedRequests={this.handleConfirmedRequests} />
          )}

          {this.state.step >= 4 && (
            <StepFour
              requests={this.state.requests}
              reservationId={this.state.reservationId}
              authenticityToken={this.props.authenticityToken}
              successRedirect={this.props.successRedirect}
            />
          )}
        </ul>
      </div>
    );
  }

  protected handleReservationSelection = (event) => {
    const reservationId = event.target.value;
    const reservation = this.props.reservations.find((res) => res.id === Number(reservationId));
    const reservationIsOmniEnabled = reservation != null ? reservation.omniEnabled : false;
    if (reservationId) {
      this.setState({
        reservationId,
        step: reservationIsOmniEnabled ? 1 : 2,
        reservationIsOmniEnabled
      });
    }
  };

  protected handleConfirmedRequests = (requests) => {
    this.setState({
      requests,
      step: 4
    });
  };

  private handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          if (results.errors.length === 0) {
            this.setState({
              parsedData: results.data,
              step: 3,
              successfulParse: true
            });
          } else {
            this.setState({
              parsedData: null,
              step: 2,
              parseErrors: results.errors,
              successfulParse: false
            });
          }
        },
        header: true,
        skipEmptyLines: 'greedy'
      });
    }
  };
}

export default BatchRetail;
