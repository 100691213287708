import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import LocationsService, {LocationContent} from '../../locations/LocationsService';
import {RetailOrderDetails} from '../../shared/services/RetailFulfillmentService';
import OutboundStagingTypeAhead from './OutboundStagingTypeAhead';

interface MobileLocationsCompletionModalProps {
  orderDetails: RetailOrderDetails;
  authenticityToken: string;
  showModal: boolean;
  toggleModal: () => void;
  onConfirm: (outboundLocationId: number, lpnsToBeOutbounded?: string) => void;
}

export const MobileLocationsCompletionModal: React.FC<MobileLocationsCompletionModalProps> = (props) => {
  const locationsService = new LocationsService(props.authenticityToken);

  const [selectedLocationId, setSelectedLocationId] = React.useState(null);
  const [closeButtonOneShot, setCloseButtonOneShot] = React.useState(false);

  const isCompleteDisabled = (): boolean => {
    const validLocation = !!selectedLocationId;
    return !validLocation || closeButtonOneShot;
  };

  const completeHandler = () => {
    //prevent multiple requests
    setCloseButtonOneShot(true);
    props.onConfirm(selectedLocationId, null);
  };

  const title = `Complete Retail Fulfillment Order ${props.orderDetails.id}`;

  const footer = (
    <div>
      <a id="back-button" className="btn flat" onClick={props.toggleModal}>
        Back
      </a>
      <button disabled={isCompleteDisabled()} id="complete-button" className="btn cta" onClick={completeHandler}>
        Complete
      </button>
    </div>
  );

  const handleTypeAheadSelect = (locationId: string, _: LocationContent[]) => {
    setSelectedLocationId(locationId);
  };

  return (
    <div className="complete-with-locations-component">
      <LegacyModal
        id="complete-with-locations-modal"
        size={'small'}
        show={props.showModal}
        toggleModal={props.toggleModal}
        title={title}
        footer={footer}
      >
        <OutboundStagingTypeAhead
          authenticityToken={props.authenticityToken}
          warehouseId={props.orderDetails.warehouse.id}
          locationsService={locationsService}
          onSelectCallback={handleTypeAheadSelect}
        />
      </LegacyModal>
    </div>
  );
};

export default MobileLocationsCompletionModal;
