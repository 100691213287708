import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import DropDown, {DropDownOption, DropDownStyle} from '../../shared/DropDown';
import {AdminCancellationReasons} from '../../shared/properties/CancellationReason';

export interface BulkCancelProps {
  showCancelModal: boolean;
  selectedOption: number | string;
  toggleCancelModal: () => void;
  handleCancel: (event: any) => Promise<void>;
  handleDropDownSelect: (reason: DropDownOption) => void;
  cancelDescription?: JSX.Element;
}

export const BulkCancelModal: FunctionComponent<BulkCancelProps> = (props) => {
  const dropDownOptions: DropDownOption[] = AdminCancellationReasons.map((code) => {
    return {
      name: code.name,
      value: code.value
    };
  });
  const getSelectedOption = (): DropDownOption | undefined => {
    return dropDownOptions.find((opt) => opt.value === props.selectedOption);
  };
  // eslint-disable-next-line no-extra-boolean-cast
  const cancelDescription = !!props.cancelDescription ? (
    props.cancelDescription
  ) : (
    <div>Shipments will be cancelled. Please select a reason code from below.</div>
  );

  return (
    <LegacyModal
      id="admin-bulk-cancel-modal"
      title="Bulk Cancel Shipment"
      show={props.showCancelModal}
      size="small"
      toggleModal={props.toggleCancelModal}
      footer={
        <div>
          <a onClick={props.toggleCancelModal}>Back</a>
          <button
            id="cancel-agree"
            type="button"
            data-target-state={'cancelled'}
            className="btn cta"
            disabled={getSelectedOption() === undefined}
            onClick={props.handleCancel}
          >
            Cancel Shipments
          </button>
        </div>
      }
      disableClose={false}
    >
      <React.Fragment>
        <p>{cancelDescription}</p>
        <div className="row space-below">
          <div>
            <label>Select reason code (required)</label>
          </div>
          <DropDown
            options={dropDownOptions}
            style={DropDownStyle.modal}
            selected={getSelectedOption()}
            onSelect={props.handleDropDownSelect}
            prefixText={'Reason for adjustment: '}
          />
        </div>
      </React.Fragment>
    </LegacyModal>
  );
};

export default BulkCancelModal;
