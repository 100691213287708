import * as React from 'react';
import {ShippingAddressDetail} from '../reservations/ShippingAddress';
import Nickname from './Nickname';
import {Reservation, ReservationCarrierAccount} from './SharedInterfaces';
import {ReservationCarrierAccountList} from './ReservationCarrierAccountList';

export interface Roles {
  [role: string]: number[];
}

interface ReservationProps {
  reservation: Reservation;
  carrierAccounts: ReservationCarrierAccount[];
  userRoles: Roles;
  authenticityToken: string;
}

export function ReservationDetail(props: ReservationProps) {
  return (
    <div className="row reservation">
      <div className="col-md-2 warehouse-image">
        <img className="img-thumbnail" src={props.reservation.warehousePhoto} />
      </div>
      <div className="col-md-10">
        <div className="row reservation-details space-below">
          <div className="col-md-9">
            <h2>{props.reservation.warehouseName}</h2>
            <Nickname
              canEdit={
                'admin' in props.userRoles ||
                ('manager' in props.userRoles && props.userRoles.manager.indexOf(props.reservation.id) > -1)
              }
              value={props.reservation.nickname}
              reservationId={props.reservation.id}
              authenticityToken={props.authenticityToken}
            />
          </div>
          <div className="col-md-3">
            {props.reservation.scopePath && (
              <a className="btn" href={props.reservation.scopePath} role="button">
                Scope &amp; Pricing
              </a>
            )}
          </div>
        </div>
        <div className="row reservation-details">
          <div className="col-md-3">
            <h3>Primary info</h3>
            <hr className="no-margin" />
            <p className="space-above space-below">
              <strong>Contact:</strong> {props.reservation.primaryContact}
            </p>
            <p className="space-above space-below">
              <strong>Phone Number:</strong> {props.reservation.primaryPhone}
            </p>
            <p className="space-above space-below">
              <strong>Address:</strong>
              <br />
              {props.reservation.warehouseAddress}
            </p>
          </div>
          {/* eslint-disable-next-line no-prototype-builtins */}
          {props.reservation.hasOwnProperty('returnAddress') && (
            <div className="col-md-3">
              <ShippingAddressDetail
                authenticityToken={props.authenticityToken}
                canEdit={
                  'admin' in props.userRoles ||
                  ('manager' in props.userRoles && props.userRoles.manager.indexOf(props.reservation.id) > -1)
                }
                reservationId={props.reservation.id}
                warehouseAddress={props.reservation.warehouseAddress}
                warehouseName={props.reservation.warehouseName}
                returnAddress={props.reservation.returnAddress}
              />
            </div>
          )}
          {props.carrierAccounts && props.carrierAccounts.length > 0 && (
            <div className="col-md-3">
              <ReservationCarrierAccountList
                authenticityToken={props.authenticityToken}
                canEdit={true}
                carrierAccounts={props.carrierAccounts}
                reservation={props.reservation}
              />
            </div>
          )}
          <div className="col-md-3 pull-right">
            <div>
              <strong>Status:</strong> {props.reservation.status}
            </div>
            <div>
              <strong>Reservation ID:</strong> {props.reservation.id}
            </div>
            <div>
              <strong>Start Date:</strong> {props.reservation.startDate}
            </div>
            {props.reservation.confirmed && (
              <div>
                <strong>
                  # Pallets Stored: <span className="pallet-count">{props.reservation.pallets}</span>
                </strong>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <hr className="col-md-12" />
      </div>
    </div>
  );
}
