import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {cloneDeep} from 'lodash';
import ShipmentService from '../../../shared/services/ShipmentService';
import {OrderInventory} from '../BatchInterfaces';
import {ShipmentSplit} from './ShipmentInterfaces';
import InventorySplit from './InventorySplit';
import ShipmentDimensions from './ShipmentDimensions';

interface SplitProps {
  labelGenerationEnabled: boolean;
  orderId: number;
  orderItems: OrderInventory[];
  shipmentService: ShipmentService;
  buttonClass?: string;
  handleSuccess(message);
}

interface SplitState {
  inventorySplitMode: boolean;
  newShipmentItems: OrderInventory[];
  splits: ShipmentSplit[];
  showModal: boolean;
  error?: string;
}

class SplitShipment extends React.Component<SplitProps, SplitState> {
  constructor(props) {
    super(props);
    this.state = {
      inventorySplitMode: true,
      newShipmentItems: this.newShipmentItems(),
      splits: [],
      showModal: false
    };
  }

  public componentDidUpdate(prevProps: SplitProps) {
    if (
      prevProps.orderId !== this.props.orderId ||
      prevProps.orderItems.length !== this.state.newShipmentItems.length
    ) {
      this.setState({
        newShipmentItems: this.newShipmentItems()
      });
    }
  }

  public render() {
    return (
      <div className="split-shipment-component">
        <button className="no-cta split-button list-view" onClick={this.toggleModal}>
          Split
        </button>
        <LegacyModal
          id={`order-${this.props.orderId}-split-modal`}
          show={this.state.showModal}
          size="small"
          toggleModal={this.toggleModal}
          title="Split Shipment"
          footer={this.getModalFooter()}
        >
          {this.getModalContent()}
        </LegacyModal>
      </div>
    );
  }

  private getModalFooter(): JSX.Element {
    const splitDisabled =
      !this.state.splits || this.state.splits.length === 0 || this.state.splits[0].dimensions.weight.amount === 0;
    return (
      <div className="text-right">
        <a className="cancel-link" onClick={this.toggleModal}>
          Cancel
        </a>
        {this.state.inventorySplitMode && (
          <button className="btn next-button" onClick={this.handleNext}>
            Next
          </button>
        )}
        {!this.state.inventorySplitMode && (
          <div className="footer-btns">
            <button className="btn back-button" onClick={this.toggleInventorySplitMode}>
              Back
            </button>
            <button className="btn submit-split-button" onClick={this.handleSplit} disabled={splitDisabled}>
              Split Shipments
            </button>
          </div>
        )}
      </div>
    );
  }

  private getModalContent(): JSX.Element {
    return (
      <div>
        {this.state.error && (
          <div className="alert alert-danger">
            <span>{this.state.error}</span>
            <div className="cancel-alert">
              <a onClick={this.handleDismissAlert}>
                <i className="fa fa-times"></i>
              </a>
            </div>
          </div>
        )}
        {this.state.inventorySplitMode && (
          <InventorySplit
            orderItems={this.props.orderItems}
            newShipmentItems={this.state.newShipmentItems}
            handleAmountChange={this.handleAmountChange}
          />
        )}
        {!this.state.inventorySplitMode && (
          <ShipmentDimensions
            shipmentService={this.props.shipmentService}
            splits={this.state.splits}
            handleSplitUpdate={this.handleSplitUpdate}
          />
        )}
      </div>
    );
  }

  private handleNext = () => {
    this.handleDismissAlert();
    const originalQuantity = this.props.orderItems.reduce((total, currentItem) => (total += currentItem.quantity), 0);
    const specifiedQuantity = this.state.newShipmentItems.reduce(
      (total, currentItem) => (total += currentItem.quantity),
      0
    );
    if (specifiedQuantity < 1) {
      this.setState({
        error: 'Specified quantities must be greater than zero.'
      });
    } else if (specifiedQuantity >= originalQuantity) {
      this.setState({
        error: 'Specified quantities must be less than the total quantities.'
      });
    } else {
      const shipmentSplits = this.initializeSplits();
      const inventoryToSubtract = new Map<string, number>();
      this.state.newShipmentItems.forEach((item) => {
        inventoryToSubtract.set(`${item.sku}:${item.packaging}`, item.quantity);
        if (item.quantity > 0) {
          shipmentSplits[0].items.push(item);
        }
      });
      this.props.orderItems.forEach((item) => {
        const itemQuantity = item.quantity - inventoryToSubtract.get(`${item.sku}:${item.packaging}`);
        if (itemQuantity > 0) {
          shipmentSplits[1].items.push({
            sku: item.sku,
            quantity: itemQuantity,
            packaging: item.packaging
          });
        }
      });
      this.setState(
        {
          splits: shipmentSplits
        },
        this.toggleInventorySplitMode
      );
    }
  };

  private handleAmountChange = (event) => {
    const packaging = event.target.getAttribute('data-packaging');
    const sku = event.target.name;
    const specifiedValue = isNaN(event.target.value) ? -1 : Number(event.target.value);
    const originalInventory = this.props.orderItems.filter((inv) => inv.sku === sku && inv.packaging === packaging)[0];
    if (specifiedValue <= originalInventory.quantity && specifiedValue >= 0) {
      let updatedAmounts = cloneDeep(this.state.newShipmentItems);
      updatedAmounts = updatedAmounts.map((inv) => {
        if (inv.sku === sku && inv.packaging === packaging) {
          inv.quantity = specifiedValue;
        }
        return inv;
      });
      this.setState({
        newShipmentItems: updatedAmounts
      });
    }
  };

  private handleSplit = async () => {
    if (this.props.labelGenerationEnabled) {
      const response = await this.props.shipmentService.splitShipment(this.state.splits, this.props.orderId);
      if (response && response.errors.length === 0) {
        const message =
          `Shipment successfully split! Shipments ${response.data.orderIds.join(', ')} have been created.` +
          ' Shipments may be delayed in appearing due to shipping label generation.';
        this.toggleModal();
        this.props.handleSuccess(message);
      } else {
        let message = 'There was an error splitting your shipment';
        if (response) {
          const serverErrors = response.errors.map((e) => e.detail);
          message += `: ${serverErrors.join(', ')}`;
        }
        this.setState({
          error: message
        });
      }
    } else {
      this.setState({
        error: 'The shipper has not configured label generation for this reservation.'
      });
    }
  };

  private handleSplitUpdate = (splits: ShipmentSplit[]) => {
    this.setState({
      splits
    });
  };

  private handleDismissAlert = () => {
    this.setState({
      error: null
    });
  };

  private toggleInventorySplitMode = () => {
    this.setState({
      inventorySplitMode: !this.state.inventorySplitMode
    });
  };

  private toggleModal = () => {
    this.setState({
      error: null,
      inventorySplitMode: true,
      showModal: !this.state.showModal
    });
  };

  private newShipmentItems(): OrderInventory[] {
    return this.props.orderItems.map((item) => {
      const newItem = cloneDeep(item);
      newItem.quantity = 0;
      return newItem;
    });
  }

  private initializeSplits(): ShipmentSplit[] {
    return [
      {
        items: [],
        dimensions: {
          weight: {
            amount: 0,
            unit: 'lb'
          },
          height: {
            amount: 0,
            unit: 'in'
          },
          width: {
            amount: 0,
            unit: 'in'
          },
          length: {
            amount: 0,
            unit: 'in'
          }
        }
      },
      {
        items: [],
        dimensions: {
          weight: {
            amount: 0,
            unit: 'lb'
          },
          height: {
            amount: 0,
            unit: 'in'
          },
          width: {
            amount: 0,
            unit: 'in'
          },
          length: {
            amount: 0,
            unit: 'in'
          }
        }
      }
    ];
  }
}

export default SplitShipment;
