import * as React from 'react';
import {TransitionState} from '../../shared/CommonInterfaces';
import BatchStatusTab from './BatchStatusTab';
import {CompactBatchCounts} from './BatchInterfaces';

interface CompactBatchStatusTabsProps {
  countsByProcessStatus: CompactBatchCounts;
  selectedTab: TransitionState;
  onTabSelect(status: TransitionState);
}

const CompactBatchStatusTabs: React.FC<CompactBatchStatusTabsProps> = (props) => {
  const {countsByProcessStatus, selectedTab, onTabSelect} = props;
  const getTabs = () => {
    return (
      <div className="compact-batch-status-tabs">
        {
          <BatchStatusTab
            status={TransitionState.Active}
            totalShipments={countsByProcessStatus[TransitionState.Active]}
            isSelected={selectedTab === TransitionState.Active}
            onTabSelect={() => onTabSelect(TransitionState.Active)}
          />
        }
        {
          <BatchStatusTab
            status={TransitionState.New}
            totalShipments={countsByProcessStatus[TransitionState.New]}
            isSelected={selectedTab === TransitionState.New}
            onTabSelect={() => onTabSelect(TransitionState.New)}
          />
        }
        {
          <BatchStatusTab
            status={TransitionState.NotStarted}
            totalShipments={countsByProcessStatus[TransitionState.NotStarted]}
            isSelected={selectedTab === TransitionState.NotStarted}
            onTabSelect={() => onTabSelect(TransitionState.NotStarted)}
          />
        }
        {
          <BatchStatusTab
            status={TransitionState.InProgress}
            totalShipments={countsByProcessStatus[TransitionState.InProgress]}
            isSelected={selectedTab === TransitionState.InProgress}
            onTabSelect={() => onTabSelect(TransitionState.InProgress)}
          />
        }
        {
          <BatchStatusTab
            status={TransitionState.Completed}
            totalShipments={countsByProcessStatus[TransitionState.Completed]}
            isSelected={selectedTab === TransitionState.Completed}
            onTabSelect={() => onTabSelect(TransitionState.Completed)}
          />
        }
        {
          <BatchStatusTab
            status={TransitionState.Cancelled}
            totalShipments={countsByProcessStatus[TransitionState.Cancelled]}
            isSelected={selectedTab === TransitionState.Cancelled}
            onTabSelect={() => onTabSelect(TransitionState.Cancelled)}
          />
        }
      </div>
    );
  };

  return getTabs();
};
export default CompactBatchStatusTabs;
