import {ApiResponseV2} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

class UnpackService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/v2/pack-service');
  }

  public async unpackParcelShipment(shipmentId: number): Promise<ApiResponseV2> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.makePostRequest(this.baseUrl + `/${shipmentId}/unpack`, null);
  }
}

export default UnpackService;
