import * as React from 'react';
import classNames from 'classnames';

export type DotStatus = 'ghost' | 'neutral' | 'support' | 'inform' | 'valid' | 'invalid';

interface Props {
  status: DotStatus;
}

const StatusDot: React.FC<Props> = (props) => {
  const statusDotClasses = [
    'status-dot',
    props.status === 'ghost' && 'status-ghost',
    props.status === 'neutral' && 'status-neutral',
    props.status === 'support' && 'status-support',
    props.status === 'inform' && 'status-inform',
    props.status === 'valid' && 'status-valid',
    props.status === 'invalid' && 'status-invalid'
  ];

  return <span className={classNames(statusDotClasses)}></span>;
};

export default StatusDot;
