import * as React from 'react';
import {FC} from 'react';
import {differenceBy, flatMap, groupBy} from 'lodash';
import {format as formatDate} from 'date-fns';
import {getQuantityAsMap, getStringFromQuantityMap} from '../ShipmentHelper';
import {singularizeUnit} from '../../../shared/utilities/DataFormatting';
import {LineItem, Shipment} from '../ShipmentInterfaces';
import {LpnStatus, LpnsResponse} from '../../../lpns/LpnsInterfaces';
import {Packaging} from '../../../shared/CommonInterfaces';
import {ShipmentRetailVariance, VarianceStatusToBackgroundStyleMap} from '../ShipmentRetailVariance';
import {renderItemLink} from '../../../../libs/helpers';
import {LocationContent} from '../../../locations/LocationsService';
import {ManifestLpn, ManifestLpnStatus} from '../../loads/ManifestInterfaces';

interface Props {
  manifestLpns: ManifestLpn[];
  shipment: Shipment;
  lpns: LpnsResponse[];
  shipmentVariance: ShipmentRetailVariance;
  looseGoodsInStagingLocation: LocationContent[];
  isFreightTrailerLoadingEnabled: boolean;
}

export const ShipmentLineItems: FC<Props> = (props) => {
  const {isFreightTrailerLoadingEnabled, lpns, manifestLpns, shipment, shipmentVariance} = props;
  // Shipped LPNs will have zeroed quantities from "lpns". Therefore, prefer using manifest LPN quantities when possible
  // over LPN quantities.
  const pickedLoadedAndShippedQty = new QuantityBySkuAggregator(
    shipment,
    notLoadedOrShippedLpns(lpns, manifestLpns),
    props.looseGoodsInStagingLocation,
    manifestLpns
  );
  const loadedAndShippedQty = new QuantityBySkuAggregator(shipment, [], [], manifestLpns);
  const shippedQty = new QuantityBySkuAggregator(
    shipment,
    [],
    [],
    manifestLpns.filter((lpn) => [ManifestLpnStatus.shipped].includes(lpn.txn_state))
  );

  // Aggregate line items by SKU so there are no duplicate lines.
  // We will combine different unit/packaging types into a single line.
  // Quantities yielded from location contents come in down-converted to the
  // most granular UoM.
  const lineItemsBySku = groupBy(shipment.line_items, (lineItem) => lineItem.sku);

  return (
    <table className="table">
      <thead>
        <tr>
          <th>{Headers.SKUID}</th>
          <th>{Headers.Description}</th>
          {shouldShowLotCodeAndExpiration(shipment) && (
            <th data-testid="th-lot-code-exp-date">{Headers.LotCodeAndExpDates}</th>
          )}
          {shouldShowQtyUom(shipment) && <th data-testid="th-qty-uom">{Headers.QTYUoM}</th>}
          {(shouldShowShippedQtyUom(shipment, isFreightTrailerLoadingEnabled) ||
            shouldShowPickedQtyUom(shipment) ||
            shouldShowLoadedQtyUom(shipment, isFreightTrailerLoadingEnabled)) && (
            <th data-testid="th-expected-uom">{Headers.ExpectedQTYUoM}</th>
          )}
          {shouldShowPickedQtyUom(shipment) && <th data-testid="th-staged-uom">{Headers.StagedQTYUoM}</th>}
          {shouldShowLoadedQtyUom(shipment, isFreightTrailerLoadingEnabled) && (
            <th data-testid="th-loaded-uom">{Headers.LoadedQtyUom}</th>
          )}
          {shouldShowShippedQtyUom(shipment, isFreightTrailerLoadingEnabled) && (
            <th data-testid="th-shipped-uom">{Headers.ShippedQtyUom}</th>
          )}
        </tr>
      </thead>
      <tbody data-testid="shipment-line-items-tbody">
        {Object.keys(lineItemsBySku).map((sku) => {
          const lineItemsForSku: LineItem[] = lineItemsBySku[sku];
          const inventoryId = lineItemsForSku[0].inventory_id;
          const description = lineItemsForSku[0].description;
          const highlightStyle = lineItemHighlightStyle(shipmentVariance, sku);
          const highlightStyleClass = highlightStyle ? `sku-highlight-variance-${highlightStyle}` : null;
          return (
            <tr key={sku} data-testid={sku} className={highlightStyleClass}>
              <td>{renderItemLink(inventoryId, sku, false)}</td>
              <td>{description}</td>
              {shouldShowLotCodeAndExpiration(shipment) && (
                <td>
                  {lineItemsForSku.map((item, i) => (
                    <div key={sku + '-lotExp-' + i.toString()} data-testid={sku + '-lotExp-' + i.toString()}>
                      {item.lot_code || '--'}
                      {item.expiration_date ? ' / ' + formatDate(item.expiration_date, 'MMM DD, YYYY') : null}
                    </div>
                  ))}
                </td>
              )}
              {
                /* The amount that was ordered. This value is always shown */
                <td>{getStringFromQuantityMap(getQuantityAsMap(lineItemsForSku, {useOrderedQty: true}))}</td>
              }
              {/*The amount that has been picked so far */
              shouldShowPickedQtyUom(shipment) && <td>{pickedLoadedAndShippedQty.getAmountDisplayString(sku)}</td>}
              {/*The amount that has been loaded so far */
              shouldShowLoadedQtyUom(shipment, isFreightTrailerLoadingEnabled) && (
                <td>{loadedAndShippedQty.getAmountDisplayString(sku)}</td>
              )}
              {/* The amount that was shipped*/
              shouldShowShippedQtyUom(shipment, isFreightTrailerLoadingEnabled) && (
                <td>
                  {isFreightTrailerLoadingEnabled
                    ? shippedQty.getAmountDisplayString(sku)
                    : getStringFromQuantityMap(getQuantityAsMap(lineItemsForSku))}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

/**
 * Filter to LPNs that have not yet been loaded or shipped. This prevents double-counting quantity.
 */
function notLoadedOrShippedLpns(lpns: LpnsResponse[], manifestLpns: ManifestLpn[]) {
  return differenceBy(
    lpns,
    manifestLpns.map((_) => ({..._, lpnBarcode: _.lpn_barcode})),
    (_) => _.lpnBarcode
  );
}

function shouldShowQtyUom(shipment: Shipment) {
  return shipment.transportation.ship_mode !== 'freight';
}

function shouldShowPickedQtyUom(shipment: Shipment) {
  return shipment.status !== 'completed' && shipment.transportation.ship_mode === 'freight';
}

function shouldShowLoadedQtyUom(shipment: Shipment, isFreightTrailerLoadingEnabled: boolean) {
  return (
    shipment.status !== 'completed' && shipment.transportation.ship_mode === 'freight' && isFreightTrailerLoadingEnabled
  );
}

function shouldShowShippedQtyUom(shipment: Shipment, isFreightTrailerLoadingEnabled: boolean) {
  return (
    (shipment.status === 'completed' && shipment.transportation.ship_mode === 'freight') ||
    isFreightTrailerLoadingEnabled
  );
}

function shouldShowLotCodeAndExpiration(shipment: Shipment) {
  return shipment.line_items.some((line_item) => line_item.lot_code || line_item.expiration_date);
}

function lineItemHighlightStyle(shipmentVariance: ShipmentRetailVariance, sku: string) {
  const map = shipmentVariance.getSkuToVarianceStatusMap();
  return map.has(sku) ? VarianceStatusToBackgroundStyleMap.get(map.get(sku)) : null;
}

class QuantityBySkuAggregator {
  public readonly map: Map<string, Map<Packaging, number>>;
  private readonly shipment: Shipment;

  constructor(
    shipment: Shipment,
    lpnDetails: LpnsResponse[],
    looseGoods: LocationContent[],
    manifestLpns: ManifestLpn[]
  ) {
    this.map = new Map();
    this.shipment = shipment;

    for (const lpnDetail of lpnDetails) {
      // skip children because the parent will have all the contents
      // Do not skip for 'flexible' LPNs because the parent in that case is not associated to this shipment
      if (lpnDetail.parentLpnId && lpnDetail.lpnType !== 'flexible') {
        continue;
      }
      // skip archived lpns
      if (lpnDetail.state === LpnStatus.archived) {
        continue;
      }
      for (const content of lpnDetail.contents) {
        this.addAmount(content.item.sku, content.quantity.unit, content.quantity.amount);
      }
    }

    for (const looseGood of looseGoods) {
      this.addAmount(looseGood.inventory.sku, looseGood.quantity.unit, looseGood.quantity.amount);
    }

    if (manifestLpns.length > 0) {
      const contents = flatMap(manifestLpns, (lpn) => lpn.manifest_contents);

      for (const content of contents) {
        this.addAmount(content.sku, Packaging[singularizeUnit(content.unit_of_measure)], content.quantity);
      }
    }
  }

  /**
   * Get a string to display to the user that is how many units have been picked
   * for a particular sku, separated by packaging type. This handles the case if a
   * given sku hasn't been picked yet.
   * @param sku The sku to display counts for
   * @returns A display string
   */
  public getAmountDisplayString(sku: string): string {
    if (!this.map[sku] || !this.shipment) {
      return '-';
    }
    return getStringFromQuantityMap(this.map[sku]);
  }

  private addAmount(sku: string, unit: Packaging, quantity: number) {
    if (!this.map[sku]) {
      this.map[sku] = new Map();
    }
    const baseAmount = this.map[sku].get(unit) || 0;
    this.map[sku].set(unit, baseAmount + quantity);
  }
}

enum Headers {
  SKUID = 'SKU',
  Description = 'Description',
  QTYUoM = 'QTY / UoM',
  ExpectedQTYUoM = 'Expected',
  PickedQTYUoM = 'Picked',
  StagedQTYUoM = 'Staged',
  LoadedQtyUom = 'Loaded',
  ShippedQtyUom = 'Shipped',
  LotCodeAndExpDates = 'Lot Code / Expiration Date'
}
