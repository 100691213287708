import * as React from 'react';
import {useEffect, useState} from 'react';
import {cloneDeep} from 'lodash';
import {Filter, FilterOption, FilterType} from '../../shared/CommonInterfaces';
import WaveBatchFilters from '../batch-waving/WaveBatchFilters';
import BatchWavingService from '../../shared/services/BatchWavingService';

interface Props {
  batchFilters: any;
  carriers: object;
  batchService: BatchWavingService;
  isHidePrintedFilterEnabled: boolean;
  handleBatchFiltersUpdate(updatedFilters: object);
}

const AdditionalBatchFilters: React.FC<Props> = (props) => {
  const [carriersToServiceLevels, setCarriersToServiceLevels] = useState<object>({});
  useEffect(() => {
    loadCarrierServiceLevel();
  }, []);

  const loadCarrierServiceLevel = async () => {
    const response = await props.batchService.getAllCarriersAndServiceTypes();
    if (response && (!response.errors || response.errors.length === 0)) {
      setCarriersToServiceLevels(response.data.mappedShippingInfo);
    }
    return response.errors;
  };

  const carrierOptions = Object.keys(props.carriers).map((carrier) => {
    return {
      displayName: props.carriers[carrier],
      value: carrier
    };
  });

  let serviceTypes;
  if (props.batchFilters.carrier) {
    serviceTypes = carriersToServiceLevels[props.batchFilters.carrier] || [];
  } else {
    const allServiceTypes = Object.keys(carriersToServiceLevels).map((carrier) => {
      return carriersToServiceLevels[carrier];
    });
    serviceTypes = [].concat(...allServiceTypes);
  }
  const serviceTypeOptions = serviceTypes.map((serviceType) => {
    return {
      displayName: serviceType,
      value: serviceType
    };
  });

  const getFilters = (): Filter[] => {
    const filters = [
      {
        displayName: 'Batch ID',
        key: 'batchId',
        type: FilterType.String,
        allowMultiple: false,
        placeHolder: 'Enter Batch ID'
      },
      {
        displayName: 'Shipment ID',
        key: 'orderId',
        type: FilterType.String,
        allowMultiple: false,
        placeHolder: 'Enter Shipment ID'
      },
      {
        displayName: 'Shipment Ship On',
        key: 'orderShipOn',
        type: FilterType.Date,
        allowMultiple: false
      },
      {
        displayName: 'Purchase Order',
        key: 'purchaseOrder',
        type: FilterType.String,
        allowMultiple: false,
        placeHolder: 'Enter Purchase Order'
      },
      {
        displayName: 'Batch Created Starting',
        key: 'startDate',
        type: FilterType.Date,
        allowMultiple: false
      },
      {
        displayName: 'Batch Created Ending',
        key: 'endDate',
        type: FilterType.Date,
        allowMultiple: false
      },
      {
        displayName: 'Carrier',
        key: 'carrier',
        type: FilterType.Dropdown,
        allowMultiple: false,
        placeHolder: 'Choose a Carrier',
        options: carrierOptions
      },
      {
        displayName: 'Service Type',
        key: 'serviceTypes',
        type: FilterType.Dropdown,
        allowMultiple: true,
        placeHolder: 'Choose a Service Type',
        options: serviceTypeOptions
      },
      {
        displayName: 'Ship As Is',
        key: 'shipAsIs',
        type: FilterType.Dropdown,
        allowMultiple: false,
        placeHolder: 'Choose Ship As Is',
        options: [
          {
            displayName: 'True',
            value: 'true'
          },
          {
            displayName: 'False',
            value: 'false'
          }
        ]
      },
      {
        displayName: 'Pack Time Label Generation',
        key: 'packTimeLabelGeneration',
        type: FilterType.Dropdown,
        allowMultiple: false,
        placeHolder: 'Choose Pack Time Label Gen',
        options: [
          {
            displayName: 'True',
            value: 'true'
          },
          {
            displayName: 'False',
            value: 'false'
          }
        ]
      },
      {
        displayName: 'SKU',
        key: 'skus',
        type: FilterType.String,
        allowMultiple: true
      }
    ];

    if (props.isHidePrintedFilterEnabled) {
      filters.push({
        displayName: 'Hide Printed',
        key: 'hidePrinted',
        type: FilterType.Present,
        allowMultiple: false
      });
    }

    return filters;
  };

  const handleWaveBatchFilterUpdate = (key: string, value: string) => {
    const newBatchFilters = cloneDeep(props.batchFilters);
    switch (key) {
      case 'skus':
      case 'serviceTypes': {
        if (value) {
          if (!newBatchFilters[key]) {
            newBatchFilters[key] = [];
          }
          if (!newBatchFilters[key].includes(value)) {
            newBatchFilters[key].push(value);
          }
        }
        break;
      }
      case 'shipAsIs':
      case 'packTimeLabelGeneration': {
        newBatchFilters[key] = value === 'true';
        break;
      }
      case 'hidePrinted':
        newBatchFilters[key] = true;
        break;
      default:
        if (value) {
          newBatchFilters[key] = value;
        }
    }
    props.handleBatchFiltersUpdate(newBatchFilters);
  };

  return (
    <div className="additional-batch-filters">
      <WaveBatchFilters filters={getFilters()} filterChangeHandler={handleWaveBatchFilterUpdate} />
    </div>
  );
};

export default AdditionalBatchFilters;
