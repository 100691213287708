import axios from 'axios';
import {get} from 'lodash';
import {ApiResponse, SimpleLocationsResponse} from '../CommonInterfaces';
import {
  InvalidLpnsAtStagingLocation,
  StagingLocationResponseInterface,
  UpdateStagingLocationInterface
} from '../../warehouse/outbound-shipments/ShipmentInterfaces';
import InternalAPIService from './InternalAPIService';

class PickConsolidationService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '');
  }

  public async stagingLocation(shipmentId: number): Promise<ApiResponse<StagingLocationResponseInterface>> {
    return await this.makeGetRequest(`/api/v2/pick_consolidations/staging_location?shipment_id=${shipmentId}`);
  }

  public async updateStagingLocation(
    request: UpdateStagingLocationInterface
  ): Promise<ApiResponse<StagingLocationResponseInterface>> {
    return await this.makePutRequest('/api/v2/pick_consolidations/staging_location', {
      location_id: request.locationId,
      warehouse_id: request.warehouseId,
      shipment_ids: request.shipmentIds
    });
  }

  public async invalidLpnsAtLocation(shipmentId: number): Promise<ApiResponse<InvalidLpnsAtStagingLocation>> {
    return await this.makeGetRequest(`/api/v2/pick_consolidations/invalid_lpns_at_location?shipment_id=${shipmentId}`);
  }

  public async getStagingLocations(
    warehouseId: number,
    locationLabel?: string,
    shipmentIds?: number[],
    limit?: number
  ): Promise<SimpleLocationsResponse> {
    try {
      const response = await axios.get('/api/v2/pick_consolidations/locations', {
        params: {
          warehouse_id: warehouseId,
          location_label: locationLabel,
          shipment_id: shipmentIds,
          limit
        }
      });
      return get(response, 'data.data');
    } catch (error) {
      throw new Error('Error retrieving staging locations.');
    }
  }
}

export default PickConsolidationService;
