import * as React from 'react';

interface Props {
  type: 'text' | 'number' | 'tel';
  id: string;
  name: string;
  value: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  error?: string | boolean;
  onChange(event);
}

function FormField(props: Props) {
  let errorMessage;
  const {type, id, placeholder, required, name, value, maxLength, error, onChange} = props;
  if (typeof error === 'string') {
    errorMessage = error;
  }
  return (
    <div className={`form-group ${props.required ? 'required' : ''} ${error ? 'has-error' : ''}`}>
      <label htmlFor={props.id} className="form-label">
        {props.label}
      </label>
      <input
        type={type}
        id={id}
        className="form-control"
        name={name}
        value={value}
        placeholder={placeholder}
        required={required}
        maxLength={maxLength}
        onChange={onChange}
      />
      {errorMessage && <span className="help-block">{errorMessage}</span>}
    </div>
  );
}

export default FormField;
