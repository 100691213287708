import * as React from 'react';
import {FunctionComponent} from 'react';
import {CustomKebabMenu, KebabMenuOption} from '../../shared/CustomKebabMenu';
import WarningModal from '../../shared/WarningModal';
import {ShipmentPackaging} from './Interfaces';

interface Props {
  handleUpdate: () => void;
  handleDelete: () => void;
  handleToggleInStock: () => void;
  isShipper: boolean;
  packaging: ShipmentPackaging;
}

const ShipmentPackagingListItemKebabMenu: FunctionComponent<Props> = (props) => {
  const [showOutOfStockModal, setShowOutOfStockModal] = React.useState(false);

  const toggleOutOfStockModal = () => {
    setShowOutOfStockModal(!showOutOfStockModal);
  };

  const handleOutOfStock = async () => {
    props.handleToggleInStock();
    toggleOutOfStockModal();
  };

  const editOption: KebabMenuOption = {
    optionText: 'Edit',
    optionAction: props.handleUpdate
  };

  const deleteOption: KebabMenuOption = {
    optionText: 'Delete',
    optionAction: props.handleDelete,
    isActionDangerous: true
  };

  const markOutOfStockOption: KebabMenuOption = {
    optionText: 'Mark Out of Stock',
    optionAction: toggleOutOfStockModal,
    isActionDangerous: true
  };

  const markInStockOption: KebabMenuOption = {
    optionText: 'Mark In Stock',
    optionAction: props.handleToggleInStock
  };

  const createOptions = (): KebabMenuOption[] => {
    const options: KebabMenuOption[] = [];

    if (props.isShipper) {
      options.push(editOption);
      options.push(deleteOption);
    }

    return options;
  };

  const outOfStockModal = (): React.ReactFragment => {
    const modalBody = (
      <div className="out-of-stock">
        <p>
          Are you sure that you are out of stock of&nbsp;
          <b>{props.packaging.description}?</b> for reservation {props.packaging.reservationId}?
        </p>
      </div>
    );

    return (
      <WarningModal
        elementId="out-of-stock-modal"
        toggleModalHandler={toggleOutOfStockModal}
        onConfirmClick={handleOutOfStock}
        confirmButtonText="Yes"
        modalTitle="Mark Out of Stock"
        modalBody={modalBody}
      />
    );
  };

  return (
    <React.Fragment>
      <CustomKebabMenu options={createOptions()} />

      {showOutOfStockModal && outOfStockModal()}
    </React.Fragment>
  );
};

export default ShipmentPackagingListItemKebabMenu;
