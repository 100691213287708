import * as React from 'react';
import {LegacyModal, Table, TableHeader} from '@flexe/ui-components';
import {OrderLine} from '../OutboundOrdersInterfaces';
import {isLineCancelable} from '../helpers/OrderLines';

interface ConfirmCancelProps {
  lines: OrderLine[];
  toggleShowModal();
  cancelLines();
}

function ConfirmCancelLineModal(props: ConfirmCancelProps) {
  const cancelableLines = props.lines.filter((line) => isLineCancelable(line));
  return (
    <LegacyModal
      id="confirm-cancel-order-lines-modal"
      title={`Cancel ${cancelableLines.length} Order Lines?`}
      show={true}
      size="medium"
      toggleModal={props.toggleShowModal}
      footer={
        <div>
          <a className="btn flat" onClick={props.toggleShowModal}>
            Cancel
          </a>
          <button id="cancel-agree" type="button" className="btn" onClick={props.cancelLines}>
            Cancel Order Lines
          </button>
        </div>
      }
      disableClose={false}
    >
      <div>
        <p>Are you sure you want to cancel the following order lines?</p>
        <Table tableData={getCancelData(cancelableLines)} />
      </div>
    </LegacyModal>
  );
}

function getCancelData(lines: OrderLine[]) {
  // We check cancelable here because the select all checkbox makes the assumption that all lines are checked
  //  There are better ways to do this, but we'll worry about that later.
  return {
    headers: [{element: 'ID'}, {element: 'Line External Id'}] as TableHeader[],
    rows: lines.filter((l) => isLineCancelable(l)).map((l) => [l.id, l.externalId])
  };
}

export default ConfirmCancelLineModal;
