import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';

interface Props {
  showStopWorkersModal: boolean;
  awaitingResponse: boolean;
  toggleStopWorkersModal: () => void;
  stopActiveWorkers: () => Promise<void>;
  renderCurrentWorkers: () => JSX.Element;
}

const CycleCountStopActiveWorkersModal: FunctionComponent<Props> = (props) => {
  return (
    <LegacyModal
      id="stop-active-workers-modal"
      title="Stop Active Workers"
      show={props.showStopWorkersModal}
      size="small"
      toggleModal={props.toggleStopWorkersModal}
      footer={
        <div>
          <a onClick={props.toggleStopWorkersModal}>Cancel</a>
          <button
            id="perform-stop-workers"
            type="button"
            className="btn btn-primary"
            disabled={props.awaitingResponse}
            onClick={props.stopActiveWorkers}
          >
            Stop active workers
          </button>
        </div>
      }
      disableClose={false}
    >
      <div>
        <p>Workers are still counting locations for this cycle count.</p>
        <p>
          <strong> Do not continue unless you are sure they are not actively counting. </strong>
        </p>
        <p>
          After pressing this button, you will be able to finish the count from here, or you can release the remaining
          work to another mobile user.
        </p>
        <p>The following workers are still active:</p>
        {props.renderCurrentWorkers()}
      </div>
    </LegacyModal>
  );
};

export default CycleCountStopActiveWorkersModal;
