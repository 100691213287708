import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';
import {SelectableWarehouse} from '../../../shared/CommonInterfaces';

interface Props {
  warehouses: SelectableWarehouse[];
  onSelect(selectableWarehouse: SelectableWarehouse);
}

const SelectWarehouse: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = React.useState<SelectableWarehouse>(null);

  const toggleDropdown = (event) => {
    event.preventDefault();
    setOpen(!open);
  };

  const createWarehouseOption = (wh: SelectableWarehouse) => {
    // the 'unselected-option' className is just to make elements easily findable in jest tests.
    // It is otherwise meaningless. The 'selected' className gives it a little checkmark
    const selectedClass =
      selectedWarehouse != null && wh.reservationId === selectedWarehouse.reservationId
        ? 'selected'
        : 'unselected-option';
    return (
      <li key={wh.reservationId}>
        <a href="#" onClick={(event) => handleSelect(event)} className={selectedClass} data-id={wh.reservationId}>
          {getWarehouseFriendlyName(wh)}
        </a>
      </li>
    );
  };

  const getWarehouseFriendlyName = (wh: SelectableWarehouse): string => {
    return (
      `Reservation #${wh.reservationId}: ${wh.warehouseName} - ${wh.warehouseStreet}, ` +
      `${wh.warehouseCity}, ${wh.warehouseState}, ${wh.warehouseZip}`
    );
  };

  const handleSelect = (event) => {
    event.preventDefault();
    const selectedId = parseInt(event.currentTarget.getAttribute('data-id'), 10);

    const warehouseThatWasSelected = props.warehouses.find((wh) => {
      return wh.reservationId === selectedId;
    });

    setSelectedWarehouse(warehouseThatWasSelected);
    props.onSelect(warehouseThatWasSelected);
    setOpen(false);
  };

  const warehouseOptions = props.warehouses.map((wh) => createWarehouseOption(wh));
  const selectedWarehouseFriendlyName =
    selectedWarehouse != null ? getWarehouseFriendlyName(selectedWarehouse) : 'Choose a warehouse reservation';

  return (
    <React.Fragment>
      <li className="active" id="step_1">
        <span className="label">1</span>
        <div className="step-wrap">
          <h3>
            <span>Select a Warehouse</span>
            <a className="help-note" data-tip="Choose a warehouse from the list below.">
              <i className="fa fa-lg fa-question-circle"></i>
            </a>
            <ReactTooltip place="right" />
          </h3>
          <p className="note">The list below reflects all currently active Reservations</p>
          <h4>
            <div className="dropdown reservation-selector">
              <a
                className="selected-reservation"
                title="Select a warehouse reservation..."
                onClick={(event) => toggleDropdown(event)}
              >
                <span className="val">{selectedWarehouseFriendlyName}</span>
                <span className="caret"></span>
              </a>
              {open && <ul className="dropdown-menu right-align show">{warehouseOptions}</ul>}
            </div>
          </h4>
        </div>
      </li>
    </React.Fragment>
  );
};

export default SelectWarehouse;
