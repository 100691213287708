import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {ReactFragment, useEffect, useState} from 'react';
import {Location, Shipment} from '../ShipmentInterfaces';
import {getMostGranularCommonUnit, getTotalQuantity} from '../ShipmentHelper';
import {usePalletQuantityErrorMessage} from '../PalletQuantityError';
import {formatDate, pluralizeUnit} from '../../../shared/utilities/DataFormatting';
import ShipmentStatusMap from '../ShipmentConstants';
import EditableValueDisplay from '../../../shared/EditableValueDisplay';
import {ShipmentRetailVariance, VarianceStatusToBackgroundStyleMap} from '../ShipmentRetailVariance';
import {LpnsResponse} from '../../../lpns/LpnsInterfaces';
import {Item} from '../../../shared/CommonInterfaces';
import {PluralPackaging} from '../../../shared/CommonMappings';
import {Wave} from '../../batch-waving/WaveInterfaces';
import {useManifestLpns} from './UseManifestLpns';
import {DanglingLpnsError} from './DanglingLpnsError';
import {useIsShipmentShort} from './UseIsShipmentShort';
import {ShipmentIsShortWarning} from './ShipmentIsShortWarning';
import ShipmentInvalidStagingLocation from './ShipmentInvalidStagingLocation';
import {DanglingLooseGoodsError, DanglingLooseGoodsErrorProps, findDanglingLooseGoods} from './DanglingLooseGoodsError';
import {CompleteShipmentShipButton} from './CompleteShipmentShipButton';

export enum ShipButtonText {
  Ship = 'Ship',
  ShipShort = 'Ship Short'
}

interface Props {
  showModal: boolean;
  toggleModal: () => void;
  bypassPickupDetails: boolean;
  shipment: Shipment;
  stagingLocation: Location;

  scac: string;
  proNumber: string;
  scheduledShipDate: string;
  palletCount: number;
  trailerNumber: string;
  sealNumber: string;
  shipmentVariance: ShipmentRetailVariance;
  viewOnlyPalletsLoaded: boolean;
  recommendedPalletQuantity: number;
  isVarianceAcknowledgmentRequired: boolean;
  lpnsOnShipment: LpnsResponse[];
  lpnsInStagingLocation: string[];
  overages: Item[];
  isFreightTrailerLoadingEnabled: boolean;
  isOvershippingBlocked: boolean;
  ssccLabelsHaveGenerated: boolean;
  ssccLabelsAreRequiredForCompletion: boolean;
  wave: Wave;
  authenticityToken: string;

  setScac: (value: string) => void;
  saveScac: () => void;
  setProNumber: (value: string) => void;
  saveProNumber: () => void;
  setScheduledShipDate: (value: string) => void;
  saveScheduledShipDate: () => void;
  setPalletCount: (value: number) => void;
  savePalletCount: () => void;
  setTrailerNumber: (value: string) => void;
  saveTrailerNumber: () => void;
  setSealNumber: (value: string) => void;
  saveSealNumber: () => void;
  setShipButtonText: (text: ShipButtonText) => void;

  onComplete: () => void;
}

export const CompleteShipmentModal: React.FC<Props> = (props) => {
  const {isFreightTrailerLoadingEnabled, setShipButtonText} = props;
  const palletErrorMessage = usePalletQuantityErrorMessage(props);

  const [isShipmentVarianceAcknowledged, setIsShipmentVarianceAcknowledged] = useState(false);
  const [isStagingLocationValid, setIsStagingLocationValid] = useState(true);
  const [hasDanglingLpns, setHasDanglingLpns] = useState(false);
  const [isCompleteEnabled, setIsCompleteEnabled] = useState<boolean>(false);
  const [danglingLooseErrorProps, setDanglingLooseErrorProps] = useState<DanglingLooseGoodsErrorProps>(null);

  const requirementsForCompletion = [
    !hasDanglingLpns,
    isStagingLocationValid,
    (danglingLooseErrorProps?.danglingLocations || []).length < 1
  ];
  if (!props.bypassPickupDetails) {
    requirementsForCompletion.push(!!props.scac);
    requirementsForCompletion.push(!!props.proNumber);
  }
  if (props.isOvershippingBlocked) {
    requirementsForCompletion.push(props.overages.length === 0);
  }
  // Only require pallet count to exist if we're working on a non-LPN
  // fulfilled shipment
  if (props.lpnsOnShipment === null || props.lpnsOnShipment.length === 0) {
    requirementsForCompletion.push(props.palletCount > 0);
  }
  if (props.ssccLabelsAreRequiredForCompletion) {
    requirementsForCompletion.push(props.ssccLabelsHaveGenerated);
  }

  const {hasFetched: hasFetchedManifestLpns, manifestLpns} = useManifestLpns(
    props.shipment,
    isFreightTrailerLoadingEnabled
  );
  const {isShort, shortContents} = useIsShipmentShort(props.shipment, isFreightTrailerLoadingEnabled);

  useEffect(() => {
    if (isShort) {
      setShipButtonText(ShipButtonText.ShipShort);
    }
  }, [isShort, setShipButtonText]);

  useEffect(() => {
    setIsShipmentVarianceAcknowledged(!props.isVarianceAcknowledgmentRequired);
  }, [props.isVarianceAcknowledgmentRequired]);

  useEffect(() => {
    checkCompleteEnabled();
  }, [
    props.scac,
    props.proNumber,
    props.palletCount,
    isStagingLocationValid,
    props.isOvershippingBlocked,
    props.overages,
    props.ssccLabelsHaveGenerated,
    props.ssccLabelsAreRequiredForCompletion,
    danglingLooseErrorProps
  ]);

  useEffect(() => {
    setDanglingLooseErrorProps(null);
    const danglingDataSetter = async () => {
      setDanglingLooseErrorProps(await findDanglingLooseGoods(props.authenticityToken, props.wave));
    };
    danglingDataSetter().catch(() => {
      setDanglingLooseErrorProps({
        danglingLocations: [],
        warnings: ['Failed to search for locations with loose goods for this shipment.']
      });
    });
  }, [props.authenticityToken, props.wave]);

  const canComplete = (requirements: boolean[]): boolean => {
    return requirements.every((req) => req === true);
  };

  const pickupDetailsMessage = `*Carrier/SCAC, Pro #, ${
    props.ssccLabelsAreRequiredForCompletion ? 'Labels, ' : ''
  }and Pallets Loaded are required to complete shipment`;
  const requiredMessage = props.bypassPickupDetails
    ? '*Pallets Loaded is required to complete shipment'
    : pickupDetailsMessage;
  const greatestCommonUnit = getMostGranularCommonUnit(props.shipment.line_items, {useOrderedQty: true});
  const total = getTotalQuantity(props.shipment, greatestCommonUnit, {useOrderedQty: true});
  const title = (
    <div>
      <span className="title">{`Complete Shipment #${props.shipment.id}`}</span>
      <div className="subtitle">
        {`${props.shipment.line_items.length} SKUs, ${total} ${pluralizeUnit(greatestCommonUnit, total)}`}
      </div>
    </div>
  );

  const dest = props.shipment.destination;
  const destText =
    `${dest.address_line_1} ${dest.address_line_2} ${dest.address_line_3}, ` +
    `${dest.city}, ${dest.state} ${dest.postal_code}`;

  const statusDot = <span className={`dot ${ShipmentStatusMap.get(props.shipment.status).className}`} />;

  const lineInfo = (label: string, value: any, leftFragment?: ReactFragment): ReactFragment => {
    const valueText = value ? value.toString() : '-';
    return (
      <React.Fragment>
        <div className="modal-row">
          <div className="left-column">{label}</div>
          <div className={'right-column' + (value ? '' : ' missing-value')}>
            {leftFragment}
            {' ' + valueText}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const editableLineInfo = (
    label: string,
    value: any,
    ghostText: string,
    inputType: string,
    onChange: (val: any) => void,
    onSave?: () => void,
    errorMessage?: string,
    maxLength: number = Number.MAX_SAFE_INTEGER
  ): ReactFragment => {
    return (
      <React.Fragment>
        <div className="modal-row">
          <div className="left-column">{label}</div>
          <div className="right-column">
            <EditableValueDisplay
              value={value}
              ghostText={ghostText}
              startEnabled={false}
              inputType={inputType}
              maxLength={maxLength}
              errorMessage={errorMessage}
              onChange={onChange}
              onSave={onSave}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const checkCompleteEnabled = () => {
    setIsCompleteEnabled(canComplete(requirementsForCompletion));
  };

  const palletsLoadedLine = props.viewOnlyPalletsLoaded ? (
    lineInfo('Pallets Loaded', props.palletCount)
  ) : (
    <React.Fragment>
      {' '}
      {editableLineInfo(
        'Pallets Loaded',
        props.palletCount,
        'Add Pallets',
        'number',
        props.setPalletCount,
        props.savePalletCount,
        palletErrorMessage
      )}{' '}
    </React.Fragment>
  );

  const labelsCreatedLine = props.ssccLabelsHaveGenerated
    ? lineInfo('Labels Created', 'Complete')
    : lineInfo('Labels Created', 'Pending');

  function shipmentVarianceNotification() {
    const varianceLevel = VarianceStatusToBackgroundStyleMap.get(
      props.shipmentVariance.getOverallShipmentVarianceStatus()
    );

    if (varianceLevel === 'invalid') {
      return retailVarianceInvalidToast;
    }

    if (varianceLevel === 'warn') {
      return retailVarianceWarningToast;
    }

    return null;
  }

  function shipmentOverageNotification() {
    if (!props.isOvershippingBlocked || props.overages.length === 0) {
      return null;
    }

    //show overages per sku with line breaks in between
    const skuReport = props.overages
      .map((item) => {
        return `${item.sku}: ${item.quantity} ${PluralPackaging.get(item.packaging)}`;
      })
      .join('\n');

    return (
      <div id="shipment-overage-notification">
        <div id="overage-symbol">&#128683;</div>
        <div className="content">
          <p>
            <b>Overage Error:</b>
            {` You must be equal to or under the expected units for each SKU to complete this shipment.
            Remove LPNs for SKUs with extra quantity to enable shipping. You may have to ship short to correct this.`}
          </p>
          <p>
            <b>Over:</b>
            <br />
            {skuReport}
          </p>
        </div>
      </div>
    );
  }

  const retailVarianceWarningToast = (
    <div className={'retail-variance-toast-warn'}>{props.shipmentVariance.getRetailVarianceWarningMessage()}</div>
  );

  const retailVarianceInvalidToast = (
    <div className={'retail-variance-toast-invalid-wrapper'}>
      <div className={'retail-variance-toast-invalid'}>{props.shipmentVariance.getRetailVarianceWarningMessage()}</div>
      <div className={'retail-variance-ack-checkbox'}>
        <input
          type={'checkbox'}
          defaultChecked={isShipmentVarianceAcknowledged}
          onChange={() => {
            setIsShipmentVarianceAcknowledged(!isShipmentVarianceAcknowledged);
          }}
        />
        <span>
          &nbsp;&nbsp;I accept responsibility for the difference between what was ordered and what I’m shipping.
        </span>
      </div>
    </div>
  );

  return (
    <LegacyModal
      id="complete_shipment_modal"
      show={props.showModal}
      size="small"
      title={title}
      toggleModal={props.toggleModal}
    >
      <div id="complete-shipment-modal-fields">
        {lineInfo('Status', ShipmentStatusMap.get(props.shipment.status).text, statusDot)}
        {lineInfo('BOL', props.shipment.transportation.bol_number)}
        {lineInfo('Ship by', formatDate(props.shipment.ship_by, 'MMMM D', 'UTC'))}
        {editableLineInfo(
          'Scheduled Ship Date',
          props.scheduledShipDate,
          'Add Ship Date',
          'date',
          props.setScheduledShipDate,
          props.saveScheduledShipDate
        )}
        {props.bypassPickupDetails && <div className="required"> {lineInfo('Carrier/SCAC', props.scac)}</div>}
        {!props.bypassPickupDetails && (
          <React.Fragment>
            <div className="required">
              {' '}
              {editableLineInfo('Carrier/SCAC', props.scac, 'Add SCAC', 'text', props.setScac, props.saveScac, '', 4)}
            </div>
            <div className="required">
              {' '}
              {editableLineInfo('PRO #', props.proNumber, 'Add PRO #', 'text', props.setProNumber, props.saveProNumber)}
            </div>
            {editableLineInfo(
              'Trailer #',
              props.trailerNumber,
              'Add Trailer #',
              'text',
              props.setTrailerNumber,
              props.saveTrailerNumber
            )}
            {editableLineInfo(
              'Seal #',
              props.sealNumber,
              'Add Seal #',
              'text',
              props.setSealNumber,
              props.saveSealNumber
            )}
          </React.Fragment>
        )}
        {lineInfo('Purchase Order', props.shipment.purchase_order)}
        {lineInfo('Destination', destText)}
        <div className="required">{palletsLoadedLine}</div>
        {props.ssccLabelsAreRequiredForCompletion && <div className="required">{labelsCreatedLine}</div>}
        {shipmentVarianceNotification()}
        <ShipmentIsShortWarning isShort={isShort} shortContents={shortContents} />
        {isFreightTrailerLoadingEnabled && hasFetchedManifestLpns && (
          <DanglingLpnsError
            lpnBarcodes={props.lpnsOnShipment.map((_) => _.lpnBarcode)}
            manifestLpnBarcodes={manifestLpns.map((_) => _.lpn_barcode)}
            setHasDanglingLpns={setHasDanglingLpns}
          />
        )}
        <DanglingLooseGoodsError {...danglingLooseErrorProps} />
        {shipmentOverageNotification()}
        <ShipmentInvalidStagingLocation
          isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
          shipment={props.shipment}
          lpnsOnShipment={props.lpnsOnShipment}
          lpnsInStagingLocation={props.lpnsInStagingLocation}
          isValid={isStagingLocationValid}
          onValidChanged={setIsStagingLocationValid}
          stagingLocation={props.stagingLocation}
        />
        <CompleteShipmentShipButton
          isDisabled={!(isCompleteEnabled && isShipmentVarianceAcknowledged)}
          isLoading={danglingLooseErrorProps == null}
          isShort={isShort}
          onPress={props.onComplete}
        />
        <div className="subtext">{requiredMessage}</div>
      </div>
    </LegacyModal>
  );
};

export default CompleteShipmentModal;
