import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {CookiesProvider} from 'react-cookie';
import {Warehouse} from '../../shared/CommonInterfaces';
import WarehouseSelector from '../../shared/WarehouseSelector';
import LoadsService from '../../shared/services/LoadsService';
import FlexeContext from '../../contexts/FlexeContext';
import {LoadsTable} from './LoadsTable';
import {UpsertLoadModal} from './create-load-modal/UpsertLoadModal';
import {LoadSummary} from './LoadInterfaces';
import CreatedAfter, {getDefaultCreatedAfter} from './CreatedAfter';

interface LoadsIndexProps {
  authenticityToken: string;
  activeWarehouses: Warehouse[];
  selectedWarehouse: Warehouse;
  parcelTrailerLoadingEnabled: boolean; // TODO remove when loads UI is fully released
  freightTrailerLoadingEnabled: boolean; // TODO remove when loads UI is fully released
}

const LoadsIndex: React.FC<LoadsIndexProps> = (props) => {
  const {authenticityToken} = useContext(FlexeContext);
  const loadsService = new LoadsService(authenticityToken);
  const [chosenWarehouse, setChosenWarehouse] = useState<Warehouse>(props.selectedWarehouse);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [rows, setRows] = useState<LoadSummary[]>([]);
  const continuationToken = useRef<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>(null);
  const [createdAfter, setCreatedAfter] = useState<Date>(getDefaultCreatedAfter());

  useEffect(() => {
    fetchLoads();
  }, [chosenWarehouse, createdAfter]);

  const fetchLoads = async () => {
    setError(null);
    setIsLoading(true);

    let allLoads: LoadSummary[] = [];
    do {
      const response = await loadsService.getLoads(chosenWarehouse.id, continuationToken.current, createdAfter);
      if (response.errors.length > 0) {
        setError(`There was an error while retrieving loads: ${response.errors[0].detail}`);
        setIsLoading(false);
        return;
      } else {
        allLoads = [...allLoads, ...response.data.loads];
        continuationToken.current = response.data.continuationToken;
      }
    } while (continuationToken.current);

    setRows(allLoads);
    setIsLoading(false);
  };

  const toggleCreateLoadModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  return (
    <div className="loads-index-page container-fluid">
      <div className="loads-header">
        <h1>Loads</h1>
        <button className="btn btn-primary create-load-button" onClick={toggleCreateLoadModal}>
          <i className="fa fa-plus" />
          New Load
        </button>
      </div>
      <h4>
        <CookiesProvider>
          <WarehouseSelector
            selectedWarehouse={chosenWarehouse}
            activeWarehouses={props.activeWarehouses}
            onSelect={setChosenWarehouse}
          />
        </CookiesProvider>
      </h4>
      <div>
        {error != null && (
          <div className="alert alert-danger" role="alert" key={`error:${1}`}>
            {error}
          </div>
        )}
      </div>
      <CreatedAfter onCreatedAfterChange={setCreatedAfter} />
      <LoadsTable data={rows} isLoading={isLoading} warehouse={chosenWarehouse} />
      <UpsertLoadModal
        isOpen={showCreateModal}
        toggleModal={toggleCreateLoadModal}
        cancelUpsert={toggleCreateLoadModal}
        warehouseId={props.selectedWarehouse.id}
        loadState={null}
        onCreateLoad={fetchLoads}
      />
    </div>
  );
};

export default LoadsIndex;
