import * as React from 'react';
import {useState} from 'react';
import {
  Button,
  DetailedInfoBox,
  Expander,
  FileSelector,
  Heading,
  InfoBox,
  Link,
  Loader,
  Modal,
  Text
} from '@flexe/ui-components';
import CsvValidator, {ParseError} from '../../../libs/CsvValidator';
import {locations_bulk_upload_columns_spec} from '../../shared/csv-upload/CsvUploadInterfaces';
import LocationsService from '../LocationsService';
import ParsingErrors from './ParsingErrors';
import SampleDataTable from './SampleDataTable';

// We need to use require() to load Papa because otherwise, TypeScript doesn't allow Papa.LocalChunkSize to be changed.
/* eslint-disable-next-line */
const Papa = require('papaparse');
Papa.LocalChunkSize = 1024 * 1024; // 1 MB chunk size

interface Props {
  crossdockEnabled: boolean;
  directedPutAwayEnabled: boolean;
  locationsService: LocationsService;
  onModalToggle: (locationsHaveChanged: boolean) => void;
  warehouseId: number;
  zonesEnabled: boolean;
}

const MAX_PARSING_ERRORS_ALLOWED = 100;

const LocationsAddEditModal = ({
  crossdockEnabled,
  directedPutAwayEnabled,
  locationsService,
  onModalToggle,
  warehouseId,
  zonesEnabled
}: Props) => {
  const [chosenFile, setChosenFile] = useState<File>();
  const [createdLabelsCount, setCreatedLabelsCount] = useState<number>(0);
  const [locationsHaveChanged, setLocationsHaveChanged] = useState<boolean>(false);
  const [invalidRowsCsvBlob, setInvalidRowsCsvBlob] = useState<Blob>();
  const [isProcessingCsvFile, setIsProcessingCsvFile] = useState<boolean>(false);
  const [parseErrors, setParseErrors] = useState<ParseError[]>([]);
  const [showSampleData, setShowSampleData] = useState<boolean>(false);
  const [updatedLabelsCount, setUpdatedLabelsCount] = useState<number>(0);
  const [uploadError, setUploadError] = useState<any>();

  const resetErrorsAndUpdateCreatedCounts = () => {
    setParseErrors([]);
    setUploadError(null);
    setUpdatedLabelsCount(0);
    setCreatedLabelsCount(0);
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles) {
      setChosenFile(acceptedFiles[0]);
      resetErrorsAndUpdateCreatedCounts();
    }
  };

  const handleFileInputChange = (event: Event) => {
    const file = (event.target as HTMLInputElement).files[0];

    if (file.name.match(/\.csv$/)) {
      setChosenFile(file);
      resetErrorsAndUpdateCreatedCounts();
    }
  };

  const handleModalToggle = () => {
    if (!isProcessingCsvFile) {
      onModalToggle(locationsHaveChanged);
    }
  };

  const handleSampleDataExpanderToggle = () => {
    setShowSampleData((isShown) => !isShown);
  };

  const handleSubmitButtonPress = () => {
    // reset errors / counts here in case user re-submits the same file
    resetErrorsAndUpdateCreatedCounts();
    setIsProcessingCsvFile(true);

    const chunkPromises = [];
    const allChunkErrors = [];
    Papa.parse(chosenFile, {
      header: true,
      skipEmptyLines: 'greedy',
      chunk: (chunk, parser) => processChunk(chunkPromises, allChunkErrors, chunk, parser),
      error: () => {
        setParseErrors([
          {
            // eslint-disable-next-line max-len
            message:
              'An error occurred while parsing the CSV. Please verify the file is correctly formatted, re-select it, and try again.'
          }
        ]);
        setIsProcessingCsvFile(false);
      },
      complete: () => onParseCompleteAsync(chunkPromises, allChunkErrors)
    });
  };

  const processChunk = (chunkPromises, allChunkErrors, chunk, parser) => {
    const chunkErrors = checkChunkForErrors(chunk);
    allChunkErrors.push(...chunkErrors);
    if (allChunkErrors.length > MAX_PARSING_ERRORS_ALLOWED) {
      parser.abort();
    } else {
      const chunkResult = uploadChunk(chunk, chunkErrors);

      if (chunkResult !== null) {
        chunkPromises.push(chunkResult);
      }
    }
  };

  const checkChunkForErrors = (chunk) => {
    // Check for invalid data
    const csvValidator = new CsvValidator();
    const [formatErrors] = csvValidator.validate(chunk, locations_bulk_upload_columns_spec);

    // Map the Papa Parse errors to our ParseError format so we can create a downloadable csv file later
    const papaParseErrors = chunk.errors.map((ppError) => {
      return {
        rowData: chunk.data.find((chunkRow, idx) => idx === ppError.row),
        // Papa Parse error rows are 0-based and don't include the header, so we need to add 2 to them
        // so they match the error rows CsvValidator outputs above
        row: ppError.row + 2,
        message: ppError.message
      };
    });
    return papaParseErrors.concat(formatErrors);
  };

  const uploadChunk = (chunk, chunkErrors) => {
    const locationData = locationsService.prepareCsvChunkForBulkCreate(chunk, chunkErrors);

    if (locationData.length === 0) {
      return null;
    }

    return locationsService.bulkCreateLocations(warehouseId, locationData);
  };

  const onParseCompleteAsync = async (chunkPromises, allChunkErrors) => {
    setParseErrors(allChunkErrors);
    const invalidRowsCsvStr = locationsService.createInvalidRowsCsv(allChunkErrors);
    if (invalidRowsCsvStr) {
      const newInvalidRowsCsvBlob = new Blob([invalidRowsCsvStr], {type: 'text/csv'});
      setInvalidRowsCsvBlob(newInvalidRowsCsvBlob);
    }
    try {
      let createdLabelsTally = 0;
      let updatedLabelsTally = 0;
      const handledChunkPromises = chunkPromises.map((promise) => {
        return promise.then((responseData) => {
          const {created_labels, updated_labels} = responseData; // eslint-disable-line @typescript-eslint/naming-convention
          createdLabelsTally += created_labels.length;
          updatedLabelsTally += updated_labels.length;
        });
      });
      await Promise.all(handledChunkPromises);
      setCreatedLabelsCount(createdLabelsTally);
      setUpdatedLabelsCount(updatedLabelsTally);
      setLocationsHaveChanged(createdLabelsTally > 0 || updatedLabelsTally > 0);
    } catch (error) {
      setUploadError(error);
    } finally {
      setIsProcessingCsvFile(false);
    }
  };

  const csvTemplateHref = `/static/files/${
    directedPutAwayEnabled
      ? zonesEnabled
        ? 'LocationBulkCreatePickableDirectedAndZone'
        : 'LocationBulkCreatePickableAndDirected'
      : zonesEnabled
      ? 'LocationBulkCreatePickableAndZoneLocation'
      : 'LocationBulkCreatePickableLocation'
  }.csv`;

  return (
    <Modal
      isOpen
      size="large"
      subtitle={
        <div className="locations-modal__subtitle">
          <Text tag="p" level="Body1" bold={false}>
            Upload a CSV file to create or edit locations. Please ensure you are using the current{' '}
            <Link href={csvTemplateHref}>CSV template</Link> provided. To remove Locations, use the Bulk Deactivate
            functionality. To learn more about this process visit our{' '}
            <Link
              href="https://flexesupport.zendesk.com/hc/en-us/articles/360047582351-Creating-and-Managing-Locations-SOP"
              target="_blank"
            >
              Help Page
            </Link>
            .
          </Text>
        </div>
      }
      title="Edit Locations"
      toggleModal={handleModalToggle}
    >
      {isProcessingCsvFile ? (
        <>
          <Heading level="h3">Please wait, your locations are being processed.</Heading>
          <Loader loading={true} />
        </>
      ) : (
        <>
          <div className="locations-modal__file-selector">
            <FileSelector
              dropzoneProps={{
                onAcceptedFiles: () => {
                  return;
                },
                onDropzoneError: () => {
                  return;
                },
                onFileDrop: handleDrop,
                onRejectedFiles: () => {
                  return;
                },
                acceptedFileTypes: {'text/csv': []}
              }}
              fileInputAccept=".csv"
              selectedFile={chosenFile && chosenFile.name}
              fileInputOnChange={handleFileInputChange}
              buttonVisualType="primary"
            />
          </div>
          <div className="locations-modal__submit-button">
            <Button
              isDisabled={!chosenFile && !isProcessingCsvFile}
              onPress={handleSubmitButtonPress}
              visualType="primary"
            >
              Submit
            </Button>
          </div>
          {!uploadError && (
            <ParsingErrors
              invalidRowsCsvBlob={invalidRowsCsvBlob}
              maxParsingErrorsAllowed={MAX_PARSING_ERRORS_ALLOWED}
              parseErrors={parseErrors}
            />
          )}
          {uploadError && (
            <div className="locations-modal__results">
              <DetailedInfoBox info={uploadError.message} infoType="error">
                <ul className="locations-modal__upload-error-list">
                  {uploadError.response.data.errors.map((error) => (
                    <li key={error.id}>{error.detail}</li>
                  ))}
                </ul>
              </DetailedInfoBox>
            </div>
          )}
          {updatedLabelsCount > 0 && (
            <div className="locations-modal__results">
              <InfoBox
                info={`${updatedLabelsCount} Existing Location${
                  updatedLabelsCount > 1 ? 's' : ''
                } Updated Successfully`}
                infoType="success"
              />
            </div>
          )}
          {createdLabelsCount > 0 && (
            <div className="locations-modal__results">
              <InfoBox
                info={`${createdLabelsCount} New Location${createdLabelsCount > 1 ? 's' : ''} Uploaded Successfully`}
                infoType="success"
              />
            </div>
          )}
          <Expander
            isOpen={showSampleData}
            onToggle={handleSampleDataExpanderToggle}
            title="See more about what inputs your file needs"
            maxHeight={1500}
          >
            <div className="locations-modal__sample-data-table">
              <SampleDataTable
                crossdockEnabled={crossdockEnabled}
                directedPutAwayEnabled={directedPutAwayEnabled}
                zonesEnabled={zonesEnabled}
              />
            </div>
          </Expander>
        </>
      )}
    </Modal>
  );
};

export default LocationsAddEditModal;
