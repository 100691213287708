/** @jsxRuntime classic */
/** @jsx jsx */

import {css, jsx} from '@emotion/react';
import {useEffect, useState} from 'react';
import {InfoBox} from '@flexe/ui-components';
import tokens from '@flexe/ui-tokens';
import {NotificationLevel} from './notification-utility';

interface Props {
  id: string;
  message: string;
  level: NotificationLevel;
  durationMs?: number;
  onTimeExpired: (id: string) => void;
  boxShadow?: boolean;
}

const waitForTimespan = async (msToWait) => {
  await new Promise((resolve) => setTimeout(resolve, msToWait));
};

const getTransparentStyle = (durationMs, boxShadow) =>
  css({
    opacity: 0,
    transition: `opacity ${durationMs ? durationMs * 0.0002 : '1'}s ease-in-out`,
    boxShadow: boxShadow ? `4px 4px 8px ${tokens.color.base.gray.v400.value}` : 'none'
  });

const getOpaqueStyle = (durationMs, boxShadow) => css`
  ${getTransparentStyle(durationMs, boxShadow)};
  opacity: 1;
`;

/**
 * This component is currently a proof of concept and shouldn't
 * be used outside the User Management component.
 */
const Notification = ({id, message, level, durationMs, onTimeExpired, boxShadow = false}: Props) => {
  const [transitionState, setTransitionState] = useState('opaque');

  const updateTransitionState = async (state) => {
    if (state === 'opaque') {
      await waitForTimespan(durationMs * 0.8);
      setTransitionState('fadingOut');
    } else if (state === 'fadingOut') {
      await waitForTimespan(durationMs * 0.2);
      onTimeExpired(id);
    }
  };

  useEffect(() => {
    if (durationMs) {
      updateTransitionState(transitionState);
    }
  }, [durationMs, transitionState]);

  return (
    <div
      css={
        transitionState === 'opaque'
          ? getOpaqueStyle(durationMs, boxShadow)
          : getTransparentStyle(durationMs, boxShadow)
      }
    >
      <InfoBox infoType={level} info={message} />
    </div>
  );
};

export default Notification;
