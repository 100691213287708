import * as React from 'react';
import {Warehouse} from '../../../CommonInterfaces';
import formatTimeWithTimezone from '../../../utilities/formatTimeWithTimezone';

interface DateTimeCellProps {
  dateString: string;
  warehouse: Warehouse;
}

export function DateTimeCell(props: DateTimeCellProps) {
  return (
    <div className="flexe-mui-data-grid-subtitle-cell">
      <div className="title">{formatTimeWithTimezone(props.warehouse.timezone, props.dateString, 'h:mm a')}</div>
      <div className="subtitle">
        {formatTimeWithTimezone(props.warehouse.timezone, props.dateString, 'MMMM D, YYYY')}
      </div>
    </div>
  );
}
