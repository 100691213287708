import * as React from 'react';
import {Button, Modal, Row, Text} from '@flexe/ui-components';
import {ModalProps} from '@flexe/ui-components/dist/types/components/Modal/Modal';
import ContainersService from '../../shared/services/ContainersService';

export interface CancelInboundModalProps extends Pick<ModalProps, 'isOpen' | 'toggleModal'> {
  containerDeliveryId: number;
  containersService: ContainersService;
  handleRefreshPage: () => void;
}

export const CancelInboundModal: React.FC<CancelInboundModalProps> = (props) => {
  const {containerDeliveryId, isOpen, toggleModal, containersService, handleRefreshPage} = props;
  const [isCancelling, setCancelling] = React.useState(false);

  const cancelDelivery = async () => {
    if (!isCancelling) {
      setCancelling(true);
      await containersService.cancelDelivery(containerDeliveryId);
      setCancelling(false);
    }
    handleRefreshPage();
  };

  return (
    <Modal isOpen={isOpen} toggleModal={toggleModal} title={'Cancel Delivery ' + containerDeliveryId}>
      <div>
        <Text>Are you sure you want to cancel this delivery? Once confirmed, a canceled delivery can't be undone.</Text>
        <Text>
          <b>IMPORTANT:</b> You are responsible for any fees or penalties resulting from this cancellation. This
          includes warehouse restocking labor and transportation cancellation penalties.
        </Text>
        <Row margin={'50'}>
          <Button onPress={cancelDelivery} isDisabled={isCancelling} visualType={'primary'}>
            Confirm Cancellation
          </Button>
          <Button onPress={toggleModal}>Back</Button>
        </Row>
      </div>
    </Modal>
  );
};
