import * as React from 'react';
import {Loader} from '@flexe/ui-components';
import {Vendor} from '../SharedInterfaces';
import VendorService from './VendorService';

interface VendorProps {
  authenticityToken: string;
  vendorService?: VendorService;
}

interface VendorListState {
  loading: boolean;
  vendors: Vendor[];
  errors: any;
}

class VendorList extends React.Component<VendorProps, VendorListState> {
  private vendorService: VendorService;

  constructor(props) {
    super(props);

    this.vendorService = this.props.vendorService || new VendorService(this.props.authenticityToken);
    this.state = {
      loading: true,
      vendors: [],
      errors: []
    };
  }

  public async componentDidMount() {
    await this.loadVendors();

    this.setState({
      loading: false
    });
  }

  public render() {
    return (
      <div className="vendor-list-container">
        {this.state.errors.length > 0 && (
          <div className="alert alert-danger" role="alert">
            {this.state.errors.map((e, i) => (
              // TODO: this is showing the error message as-is, which may not be useful/friendly to users.
              <span key={i}>{e.message}</span>
            ))}
          </div>
        )}

        <h2>Vendor Return Addresses</h2>
        <p>
          Below are the return addresses we have for your vendors on file. Please contact your Flexe representative to
          add or edit return addresses.
        </p>
        <Loader loading={this.state.loading} />
        {!this.state.loading && this.state.vendors.length > 0 && (
          <div className="vendor-list">
            {this.state.vendors.map((vendor) => {
              return this.buildVendorElement(vendor);
            })}
          </div>
        )}
      </div>
    );
  }

  public async loadVendors() {
    this.setState({errors: []});
    let newErrors = [];

    try {
      const loaded = await this.vendorService.getVendors();
      this.setState({vendors: loaded});
    } catch (err) {
      newErrors = newErrors.concat(err);
    } finally {
      this.setState({errors: newErrors});
    }
  }

  private buildVendorElement = (vendor: Vendor) => {
    return (
      <div className="vendor" key={vendor.vendorName}>
        <p className="field-label">Name:</p>
        <p className="field-value">{vendor.vendorName}</p>
        <p className="field-label">Return Address:</p>
        <div className="field-value">
          {vendor.returnAddress.name && <p>{vendor.returnAddress.name}</p>}
          <p>{vendor.returnAddress.line1}</p>
          {vendor.returnAddress.line2 && <p>vendor.returnAddress.line2</p>}
          {vendor.returnAddress.line3 && <p>vendor.returnAddress.line3</p>}
          <p>
            {vendor.returnAddress.locality}, {vendor.returnAddress.region} {vendor.returnAddress.postcode}
          </p>
          {vendor.returnAddress.phone && (
            <p>
              <a className="phone" href={'tel:' + vendor.returnAddress.phone}>
                P:
              </a>
              &nbsp;
              {vendor.returnAddress.phone}
            </p>
          )}
        </div>
      </div>
    );
  };
}

export default VendorList;
