import * as React from 'react';
import Toggle from 'react-toggle';
import {LegacyModal} from '@flexe/ui-components';
import {get} from 'lodash';
import {ShippoSettings} from '../SharedInterfaces';
import {Company} from '../../../shared/CommonInterfaces';
import WarehouseService from '../../../shared/services/WarehouseService';
import ShippingLabelsAPIService from './ShippingLabelsAPIService';

interface ShippingLabelsAPIKeyProps {
  authenticityToken: string;
  currentCompany: Company;
}

interface ShippoAPIKeyState {
  alertClass: string;
  alertText: string;
  disableUpdate: boolean;
  settings: ShippoSettings;
  showAlert: boolean;
  showConfirmModal: boolean;
}

class ShippingLabelsAPIKey extends React.Component<ShippingLabelsAPIKeyProps, ShippoAPIKeyState> {
  private shippoAPIService: ShippingLabelsAPIService;
  private warehouseService: WarehouseService;
  constructor(props) {
    super(props);

    this.state = {
      alertClass: '',
      alertText: '',
      disableUpdate: true,
      settings: {
        hasApiKey: false,
        shippoApiKey: ''
      },
      showAlert: false,
      showConfirmModal: false
    };

    this.shippoAPIService = new ShippingLabelsAPIService(this.props.authenticityToken);
    this.warehouseService = new WarehouseService(this.props.authenticityToken);
  }

  public async componentDidMount() {
    const response = await this.shippoAPIService.getShippoAPIKeySettings();
    if (response.data && (!response.errors || response.errors.length === 0)) {
      this.setState({
        settings: response.data
      });
    } else {
      this.handleResponseError(response, 'There was an error getting your settings');
    }
  }

  public render() {
    const modalFooter = (
      <div>
        <button className="btn" onClick={this.toggleConfirmModal}>
          Cancel
        </button>
        <button className="btn" onClick={this.handleUpdateSettings}>
          Confirm
        </button>
      </div>
    );

    return (
      <div id="shippo-api-key" className="row space-above-lg">
        <div className="col-md-12 space-below-lg">
          <div className="row">
            <div className="col-md-12">
              <h2>Shippo API Key</h2>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {this.state.showAlert && <div className={`alert ${this.state.alertClass}`}>{this.state.alertText}</div>}
          <div className="form-group">
            <label>
              Use FLEXE Shippo Account
              <Toggle
                data-active={!this.state.settings.hasApiKey}
                data-id="use-flexe"
                checked={!this.state.settings.hasApiKey}
                onChange={this.handleToggleActive}
              />
            </label>
          </div>
          {this.state.settings.hasApiKey && (
            <div id="add-shippo-api" className="form-group">
              <label>Shippo API Key</label>
              <input
                type="text"
                id="shippo-api-key"
                value={this.state.settings.shippoApiKey}
                onChange={this.handleShippoKeyUpdate}
              />
            </div>
          )}
          <button disabled={this.state.disableUpdate} className="btn" onClick={this.toggleConfirmModal}>
            Update
          </button>
        </div>
        <LegacyModal
          id="confirm-update-modal"
          title="Confirm Settings Update"
          show={this.state.showConfirmModal}
          size="small"
          toggleModal={this.toggleConfirmModal}
          footer={modalFooter}
        >
          <p>
            Are you sure you want to update your Shippo API settings? You will lose all carrier accounts associated with
            the previous Shippo API key.
          </p>
        </LegacyModal>
      </div>
    );
  }

  private toggleConfirmModal = () => {
    this.setState({
      showConfirmModal: !this.state.showConfirmModal
    });
  };

  private handleToggleActive = () => {
    this.setState({
      disableUpdate: false,
      settings: {
        hasApiKey: !this.state.settings.hasApiKey,
        shippoApiKey: ''
      }
    });
  };

  private handleUpdateSettings = async () => {
    const response = await this.shippoAPIService.setShippoAPIKeySettings(this.state.settings);
    if (response.data && (!response.errors || response.errors.length === 0)) {
      this.setState({
        alertClass: 'alert-success',
        alertText: 'Shippo API settings successfully updated. Please remember to add your carrier accounts.',
        disableUpdate: true,
        showAlert: true,
        settings: {
          ...this.state.settings,
          shippoApiKey: this.state.settings.hasApiKey
            ? `***${this.state.settings.shippoApiKey.slice(this.state.settings.shippoApiKey.length - 3)}`
            : this.state.settings.shippoApiKey
        }
      });
    } else {
      this.handleResponseError(response, 'There was an error updating your settings');
    }
    this.toggleConfirmModal();
  };

  private handleShippoKeyUpdate = (e) => {
    this.setState({
      disableUpdate: false,
      settings: {
        hasApiKey: true,
        shippoApiKey: e.target.value
      }
    });
  };

  private handleResponseError(response, message) {
    const errors = response.errors ? `: ${response.errors.map((error) => error.detail).join(', ')}` : '';
    this.setState({
      alertClass: 'alert-danger',
      alertText: `${message}${errors}`,
      showAlert: true
    });
  }
}

export default ShippingLabelsAPIKey;
