import {CsvErrorType, ValidationErrors} from '../shared/csv-upload/CsvUploadInterfaces';
import CsvUtil from '../shared/utilities/CsvUtil';

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
interface ContainerRequest {
  purchaseOrder: string;
  moveDate: Date;
  valueOfGoods: string;
  vendor: string;
  trailerNumber: string;
  sealNumber: string;
  instructions: string;
  customerUUID: string;
  inventoryData: InventoryData[];
  inboundPackagingType: InboundPackagingType;
  errors: ValidationErrors;
  isCrossdock: boolean;
}

enum InboundPackagingType {
  Unknown = 'unknown',
  FloorLoaded = 'floor_loaded',
  Palletized = 'palletized'
}

interface InventoryData {
  sku: string;
  quantity: number;
  packaging: string;
  attachments?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
class ContainerRequest implements ContainerRequest {
  constructor(data) {
    this.errors = {};

    this.purchaseOrder = data.purchaseOrder ? data.purchaseOrder.trim() : null;
    this.valueOfGoods = data.valueOfGoods ? data.valueOfGoods.trim() : null;
    this.vendor = data.vendor ? data.vendor.trim() : null;
    this.trailerNumber = data.trailerNumber ? data.trailerNumber.trim() : null;
    this.sealNumber = data.sealNumber ? data.sealNumber.trim() : null;
    this.instructions = data.instructions ? data.instructions.trim() : null;
    this.customerUUID = data.purchaseOrder ? data.purchaseOrder.trim() : null;
    this.inventoryData = [];
    this.inboundPackagingType = data.inboundPackagingType || InboundPackagingType.Unknown;
    this.isCrossdock = data.isCrossdock && data.isCrossdock.toLowerCase() === 'true' ? true : false;

    this.validateRequired(data);
    this.generateInventoryData(data);

    const {parsedMoveDate, moveDateError} = CsvUtil.validateDateTimes(data);

    this.moveDate = parsedMoveDate;
    if (moveDateError) {
      this.errors.moveDate = moveDateError;
    }
  }

  private validateRequired(data) {
    const fieldsToIgnore = [
      'valueOfGoods',
      'vendor',
      'sealNumber',
      'instructions',
      'inboundPackagingType',
      'sku',
      'quantity',
      'isCrossdock'
    ];
    for (const key of Object.keys(data)) {
      if (data[key] && typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
      if (fieldsToIgnore.indexOf(key) === -1 && !data[key]) {
        this.errors[key] = CsvErrorType.Required;
      }
    }
  }

  private generateInventoryData(data) {
    if (data.sku && data.quantity && data.packaging) {
      this.inventoryData.push({
        packaging: data.packaging.trim(),
        quantity: data.quantity,
        sku: data.sku.trim()
      });
    } else {
      this.errors.inventoryData = CsvErrorType.Required;
    }
  }
}

export default ContainerRequest;
