import {ContainerDelivery, InboundCapturableProperty, PackingLists} from './DropoffInterfaces';

export const BLANK_CONTAINER: ContainerDelivery = {
  id: 0,
  txnState: '',
  sealNumber: '',
  trailerNumber: '',
  purchaseOrder: '',
  expectedInboundPackaging: '',
  actualInboundPackagingId: 0,
  moveDate: '',
  inventoryMatch: false,
  instructions: '',
  monetaryValueOfGoods: '',
  valueOfGoodsAmount: 0,
  hasBillOfLading: false,
  completedBy: {
    name: '',
    type: null
  },
  receiving: {
    startAt: '0:0',
    endAt: '0:0'
  },
  isCrossdock: false,
  extraProperties: {
    provided: new Map<string, string>(),
    toCapture: new Map<string, InboundCapturableProperty>()
  }
};

export const EMPTY_PACKING_LISTS: PackingLists = {
  expected: [],
  shippable: [],
  damaged: []
};
