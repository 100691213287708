import * as React from 'react';
import {FunctionComponent, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {cloneDeep, get} from 'lodash';
import {addDays, format as formatDate, parse as parseDate} from 'date-fns';
import {Loader, Pagination, Table, TableHeader} from '@flexe/ui-components';
import Filters from '../../shared/Filters';
import {FilterOption, FilterType} from '../../shared/CommonInterfaces';
import CycleCountsService from '../../shared/services/CycleCountsService';
import WarehouseService from '../../shared/services/WarehouseService';
import FilterOptionWarehouseSelector from '../FilterOptionWarehouseSelector';
import CycleCountBulkStartModal from './CycleCountBulkStartModal';
import {CycleCount, CycleCountStatus, CycleCountStatusDisplay} from './CycleCountInterfaces';

interface Props {
  authenticityToken: string;
  isShipper: boolean;
  cycleCountsService?: CycleCountsService;
  warehouseService?: WarehouseService;
  currentWarehouse: FilterOption;
  warehouses: FilterOption[];
  currentReservation?: FilterOption;
  reservations?: FilterOption[];
  enableCycleCountReportEnhancements: boolean;
}

const CycleCountList: FunctionComponent<Props> = (props) => {
  // used to prevent state updates on unmounted components
  const isMountedRef = React.useRef(null);
  const pageSize = props.enableCycleCountReportEnhancements ? 50 : 20;

  const [continuationTokens, setContinuationTokens] = React.useState<string[]>([]);
  const [errors, setErrors] = React.useState<string[]>(null);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [stateFilters, setStateFilters] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cycleCounts, setCycleCounts] = React.useState<CycleCount[]>([]);
  const [totalCounts, setTotalCounts] = React.useState<number>(0);
  const [reservations, setReservations] = React.useState<FilterOption[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = React.useState<FilterOption>(props.currentWarehouse);
  const [disableGenerateReports, setDisableGenerateReports] = React.useState<boolean>(false);
  const [disableBulkStart, setDisableBulkStart] = React.useState<boolean>(false);
  const [warehouseIdFilterPresent, setWarehouseIdFilterPresent] = React.useState<boolean>(false);
  const [showBulkStartModal, setShowBulkStartModal] = React.useState<boolean>(false);
  const [awaitingResponse, setAwaitingResponse] = React.useState<boolean>(false);

  useEffect(() => {
    const W_ID_KEY = 'warehouseId';
    const currentWarehouseId = stateFilters[W_ID_KEY];
    if (currentWarehouseId) {
      setWarehouseIdFilterPresent(true);
    }
  }, [stateFilters]);

  useEffect(() => {
    isMountedRef.current = true;
    if (props.isShipper) {
      setReservations(props.reservations);
    } else {
      const initialWarehouse = props.cycleCountsService.selectedWarehouse
        ? props.cycleCountsService.selectedWarehouse
        : props.currentWarehouse;
      handleWarehouseSelection(initialWarehouse);
    }
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!props.isShipper) {
      getReservations();
    }
    if (props.isShipper || Object.keys(stateFilters).length > 0) {
      getCycleCounts();
    }
  }, [stateFilters]);

  const getTableData = () => {
    const pagination = (
      <Pagination
        page={currentPage}
        pageSize={pageSize}
        paginationHandler={handlePagination}
        totalCount={totalCounts}
      />
    );
    const headers: TableHeader[] = [
      {className: 'id-header', element: 'ID'},
      {element: 'Reservation'},
      {element: 'Status'},
      {element: 'SKUs'},
      {element: 'Locations'},
      {element: 'Created By'},
      {element: 'Created'},
      {element: 'Counted By'},
      {element: 'Counted'},
      {element: 'Approved By'},
      {element: 'Approved'},
      {
        className: 'pagination-header',
        element: pagination
      }
    ];

    if (warehouseIdFilterPresent) {
      headers.splice(1, 0, {element: 'Reference'});
    }

    const rows = buildRows();
    return {
      headers,
      rows
    };
  };

  const toggleBulkStartModal = () => {
    setShowBulkStartModal(!showBulkStartModal);
  };

  const buildRows = () => {
    const rows = (cycleCounts ?? []).map((cc) => {
      const id = <Link to={`/${props.isShipper ? 's' : 'wh'}/cycle_counts/${cc.id}`}>#{cc.id}</Link>;
      const reservationName = get(cc, 'reservation.name') ? cc.reservation.name : '-';
      const state = <span className={`label label-default ${cc.state}`}>{CycleCountStatusDisplay[cc.state]}</span>;
      const createdAt = get(cc, 'created.at') ? formatDate(cc.created.at, 'MM/DD/YY h:mma') : null;
      const completedAt = get(cc, 'completed.at') ? formatDate(cc.completed.at, 'MM/DD/YY h:mma') : null;
      const countedAt = get(cc, 'counted.at') ? formatDate(cc.counted.at, 'MM/DD/YY h:mma') : null;
      const cycleCountLink = (
        <Link to={`/${props.isShipper ? 's' : 'wh'}/cycle_counts/${cc.id}`}>
          <i className="fa fa-chevron-right"></i>
        </Link>
      );

      const row = [
        id,
        reservationName,
        state,
        cc.skuCount,
        cc.locCount,
        get(cc, 'created.by.name'),
        createdAt,
        get(cc, 'counted.by.name'),
        countedAt,
        get(cc, 'completed.by.name'),
        completedAt,
        cycleCountLink
      ];

      if (warehouseIdFilterPresent) {
        const referenceValue = get(cc, 'reference') ? cc.reference : '-';
        row.splice(1, 0, referenceValue);
      }

      return row;
    });

    return rows;
  };

  const getFilters = () => {
    const filterList = [
      {
        displayName: 'ID',
        key: 'ids',
        type: FilterType.String,
        value: getFilterValue('ids'),
        allowMultiple: true
      },
      {
        displayName: 'Reservation',
        key: 'reservationId',
        type: FilterType.Dropdown,
        allowMultiple: false,
        options: [
          {
            displayName: 'Choose a reservation...',
            value: ''
          },
          ...(reservations ? reservations : [])
        ]
      },
      {
        displayName: 'Date Created (Exact)',
        key: 'createdAt',
        type: FilterType.Date,
        value: getFilterValue('createdAt')
      },
      {
        displayName: 'Date Created Starting',
        key: 'createdAtStart',
        type: FilterType.Date,
        value: getFilterValue('startDateRange')
      },
      {
        displayName: 'Date Created Ending',
        key: 'createdAtEnd',
        type: FilterType.Date,
        value: getFilterValue('endDateRange')
      },
      {
        displayName: 'Status',
        key: 'ccState',
        type: FilterType.Dropdown,
        options: [
          {
            displayName: 'Choose a status...',
            value: ''
          },
          {
            displayName: CycleCountStatusDisplay[CycleCountStatus.pendingStart],
            value: CycleCountStatus.pendingStart
          },
          {
            displayName: CycleCountStatusDisplay[CycleCountStatus.inProgress],
            value: CycleCountStatus.inProgress
          },
          {
            displayName: CycleCountStatusDisplay[CycleCountStatus.inReview],
            value: CycleCountStatus.inReview
          },
          {
            displayName: CycleCountStatusDisplay[CycleCountStatus.completed],
            value: CycleCountStatus.completed
          },
          {
            displayName: CycleCountStatusDisplay[CycleCountStatus.cancelled],
            value: CycleCountStatus.cancelled
          }
        ]
      },
      {
        displayName: 'SKU',
        key: 'sku',
        type: FilterType.String,
        value: getFilterValue('sku'),
        allowMultiple: true
      }
    ];

    if (warehouseIdFilterPresent) {
      filterList.splice(1, 0, {
        displayName: 'Reference',
        key: 'reference',
        type: FilterType.String,
        value: getFilterValue('reference'),
        allowMultiple: false
      });
    }

    return filterList;
  };

  const getFilterValue = (key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (stateFilters.hasOwnProperty(key)) {
      return stateFilters[key];
    }
    return null;
  };

  const handleFilterChange = async (filterInput) => {
    setLoading(true);

    const ccStateFilters = filterInput.filter((item) => item.filter.key === 'ccState');

    if (ccStateFilters.length > 0) {
      setDisableGenerateReports(
        props.enableCycleCountReportEnhancements
          ? false
          : ccStateFilters.filter((item) => item.value === 'completed').length === 0
      );
      setDisableBulkStart(ccStateFilters.filter((item) => item.value === 'pending_start').length === 0);
    } else {
      setDisableGenerateReports(false);
      setDisableBulkStart(false);
    }

    const filterValues: any = {warehouseId: stateFilters.warehouseId};
    filterInput.forEach((selected) => {
      if (['ids', 'reservationId', 'sku', 'ccState'].includes(selected.filter.key)) {
        const val = selected.value;
        if (filterValues[selected.filter.key]) {
          if (!filterValues[selected.filter.key].includes(val)) {
            filterValues[selected.filter.key].push(val);
          }
        } else {
          filterValues[selected.filter.key] = [val];
        }
      } else if (selected.filter.key === 'createdAt') {
        const localDateStart = parseDate(selected.value);
        const localDateEnd = addDays(localDateStart, 1);
        filterValues.createdAtStart = localDateStart.toISOString();
        filterValues.createdAtEnd = localDateEnd.toISOString();
      } else if (['createdAtStart', 'createdAtEnd'].includes(selected.filter.key)) {
        const localDate = parseDate(selected.value);
        // inclusive start, exclusive end so offset by 1 day
        const filterDate = selected.filter.key === 'createdAtEnd' ? addDays(localDate, 1) : localDate;
        filterValues[selected.filter.key] = filterDate.toISOString();
      } else {
        filterValues[selected.filter.key] = selected.value;
      }
    });

    setStateFilters(filterValues);
  };

  const handlePagination = async (pageNumber) => {
    setLoading(true);

    const contToken = pageNumber > 1 ? continuationTokens[pageNumber - 2] : null;
    let errs;
    const response = await props.cycleCountsService.getCycleCounts(pageSize, contToken, stateFilters);

    if (response && response.errors.length === 0) {
      const updatedContinuationTokens = continuationTokens.slice();
      updatedContinuationTokens.push(get(response, 'data.continuationToken'));
      setContinuationTokens(updatedContinuationTokens);
      setCycleCounts(get(response, 'data.cycleCounts') as CycleCount[]);
      setCurrentPage(pageNumber);
      props.cycleCountsService.setListCache({
        continuationTokens: updatedContinuationTokens,
        errors,
        currentPage: pageNumber,
        filters: stateFilters,
        loading,
        cycleCounts: get(response, 'data.cycleCounts') as CycleCount[],
        totalCounts,
        reservations,
        selectedWarehouse
      });
    } else if (response.errors.length) {
      errs = props.cycleCountsService.processErrors(response.errors);
    }
    setErrors(errs);
    setLoading(false);
  };

  const handleWarehouseSelection = (warehouse) => {
    const filtValues = cloneDeep(stateFilters);
    filtValues.warehouseId = [warehouse.value];
    delete filtValues.reservationId;
    setNewWarehouse(warehouse, filtValues);
  };

  const setNewWarehouse = (wh, f) => {
    setSelectedWarehouse(wh);
    setStateFilters(f);
  };

  const getReservations = async () => {
    setLoading(true);
    let res = [];
    let errs;
    const response = await props.warehouseService.getReservationsForWarehouse(Number(selectedWarehouse.value));

    if (response && response.errors.length === 0) {
      res = response.data.reservations.map((reservation) => {
        return {
          displayName: reservation.name,
          value: reservation.id
        } as FilterOption;
      });
      setReservations(res);
    } else if (response.errors.length) {
      errs = props.cycleCountsService.processErrors(response.errors);
    }
    setErrors(errs);
    setLoading(false);
  };

  const getCycleCounts = async () => {
    setLoading(true);
    let errs;
    const response = await props.cycleCountsService.getCycleCounts(
      pageSize,
      currentPage > 1 ? continuationTokens[currentPage - 2] : null,
      stateFilters
    );

    if (response && response.errors && response.errors.length === 0) {
      setContinuationTokens([get(response, 'data.continuationToken')]);
      setCurrentPage(1);
      setCycleCounts(get(response, 'data.cycleCounts') as CycleCount[]);
      setTotalCounts(get(response, 'data.totalCycleCountsCount'));
      props.cycleCountsService.setListCache({
        continuationTokens: [get(response, 'data.continuationToken')],
        errors,
        currentPage: 1,
        filters: stateFilters,
        loading,
        cycleCounts: get(response, 'data.cycleCounts') as CycleCount[],
        totalCounts: get(response, 'data.totalCycleCountsCount'),
        reservations,
        selectedWarehouse
      });
    } else if (response.errors.length) {
      errs = props.cycleCountsService.processErrors(response.errors);
    }
    setErrors(errs);
    setLoading(false);
    return get(response, 'data.cycleCounts') as CycleCount[];
  };

  const renderBulkActionSection = (): JSX.Element => {
    const currentFilterKeys = Object.keys(stateFilters);
    const furtherFiltered = currentFilterKeys.length !== 1 || currentFilterKeys[0] !== 'warehouseId';
    return (
      <div>
        <div className="cycle-counts-component divider" />
        <div>
          {furtherFiltered && `${totalCounts} cycle counts matching your filters  `}
          <button
            disabled={!furtherFiltered || disableGenerateReports}
            onClick={onGenerateReportClick}
            className="btn cta"
          >
            {downloadReportsButtonText()}
          </button>
        </div>
        <div className="cycle-counts-component divider" />
      </div>
    );
  };

  const downloadReportsButtonText = () => {
    const noOfCycleCounts = cycleCounts.map((cc) => cc.id).length;
    return props.enableCycleCountReportEnhancements
      ? 'Download Reports for ' + noOfCycleCounts + ' counts'
      : 'Download Reports';
  };

  const onGenerateReportClick = async () => {
    if (props.enableCycleCountReportEnhancements) {
      const cycleCountIds = cycleCounts.map((cc) => cc.id);
      const filters = {ids: cycleCountIds};
      await props.cycleCountsService.postGenerateBulkReport(filters);
    } else {
      if (totalCounts > 50) {
        alert('Please filter to less than 50 counts per report.');
      } else {
        await props.cycleCountsService.postGenerateBulkReport(stateFilters);
      }
    }
  };

  const onStartCountsClick = async () => {
    setAwaitingResponse(true);
    const result = await props.cycleCountsService.postStartCounts(stateFilters);

    if (result.errors.length > 0) {
      setErrors(props.cycleCountsService.processErrors(result.errors));
    } else {
      await getCycleCounts();
    }

    toggleBulkStartModal();
    setAwaitingResponse(false);
  };

  const tableData = getTableData();
  return (
    <div className="cycle-counts-component container-fluid">
      {errors && (
        <div className="alert alert-danger" role="alert">
          {errors.map((e, i) => (
            <span key={i}>{e}</span>
          ))}
        </div>
      )}
      <div className="row">
        <div className="col-sm-6">
          <h1>Cycle Counts</h1>
          {!props.isShipper && selectedWarehouse && (
            <div>
              <FilterOptionWarehouseSelector
                selectedWarehouse={selectedWarehouse}
                activeWarehouses={props.warehouses}
                onSelect={handleWarehouseSelection}
              />
            </div>
          )}
          {!props.isShipper && (!props.warehouses || props.warehouses.length === 0) && (
            <h4>There are no warehouses associated with this account.</h4>
          )}
        </div>
        <div className="col-sm-6">
          {props.isShipper ? (
            <Link to={'/s/cycle_counts/new'} className="btn cta pull-right">
              <i className="fa fa-plus"></i>
              &nbsp; Create Cycle Count
            </Link>
          ) : (
            <Link to={'/wh/cycle_counts/new'} className="btn cta pull-right">
              <i className="fa fa-plus"></i>
              &nbsp; Create Cycle Count
            </Link>
          )}
        </div>
      </div>
      <Filters filters={getFilters()} filterChangeHandler={(filters) => handleFilterChange(filters)} />
      {warehouseIdFilterPresent && renderBulkActionSection()}
      <div className="row">
        {!loading && tableData.rows.length > 0 && <Table tableClass="table-striped" tableData={tableData} />}
        {!loading && tableData.rows.length === 0 && (
          <p className="col-sm-12">No cycle counts matching the current selection.</p>
        )}
        <Loader loading={loading} />
      </div>
    </div>
  );
};

export default CycleCountList;
