import * as React from 'react';
import {get, isEmpty} from 'lodash';
import {Inventory, WarehouseLocation} from '../CommonInterfaces';
import LocationsService from '../../locations/LocationsService';
import CycleCountsService from '../services/CycleCountsService';
import {BareBonesItem, CycleCountItem} from './CycleCountInterfaces';

interface Props {
  ccItems: CycleCountItem[];
  byLocation: boolean;
  onItemSelected: (CycleCountItem) => void;
  onWarehouserError: (errors: any) => void;
  toggleIsCurrentlyAdding: () => void;
  warehouseId: number;
  locationsService: LocationsService;
  cycleCountsService: CycleCountsService;
}

interface State {
  searchItems: (WarehouseLocation | BareBonesItem)[];
}

let getDataTimeout;

// Only used when in the process of searching and selecting
export class CCItemAddExtraRow extends React.Component<Props, State> {
  public constructor(props) {
    super(props);
    this.state = {
      searchItems: null
    };
  }

  public render() {
    const dropdownItems = (this.state.searchItems || []).filter((searchItem: WarehouseLocation | BareBonesItem) => {
      return !this.props.ccItems.some((countedItem) => {
        return String(get(countedItem, this.getItemMatchPath())) === String(searchItem.id);
      });
    });
    const searched = this.state.searchItems;
    const hasMatches = dropdownItems.length > 0;

    return (
      <div className="form-inline">
        <div className="form-group">
          <input
            className="col-sm-3"
            type="text"
            placeholder={this.getPlaceholderText()}
            onBlur={this.clearSearch}
            onChange={this.handleFilterChange}
          />
          <ul className={`dropdown-menu${searched ? ' show' : null}`}>
            {searched && hasMatches && this.renderDropdownList(dropdownItems)}
            {searched && !hasMatches && this.renderNoMatch()}
          </ul>
        </div>
        <div className="form-group">
          <a onClick={this.props.toggleIsCurrentlyAdding}>
            <i className="fa fa-times"></i>
          </a>
        </div>
      </div>
    );
  }

  private isLocation(x: WarehouseLocation | BareBonesItem): x is WarehouseLocation {
    return (x as WarehouseLocation).label !== undefined;
  }

  private renderDropdownList(dropdownItems: (WarehouseLocation | BareBonesItem)[]): JSX.Element[] {
    return dropdownItems.map((di: WarehouseLocation | BareBonesItem) => {
      let locationId;
      let locationLabel;
      let inventoryId;
      let display;
      if (this.isLocation(di)) {
        locationId = di.id;
        locationLabel = di.label;
        inventoryId = get(this.props.ccItems[0], 'inventory.id');
        display = di.label;
      } else {
        locationId = get(this.props.ccItems[0], 'location.id');
        locationLabel = get(this.props.ccItems[0], 'location.label');
        inventoryId = di.id;
        display = `${di.itemCode} - ${di.description} (${di.company})`;
      }
      return (
        <li
          key={di.id}
          className="selectable"
          data-location-id={locationId}
          data-location-label={locationLabel}
          data-inventory-id={inventoryId}
          onClick={this.handleSelectNewItem}
        >
          <a data-id={locationId} data-inventory-id={inventoryId}>
            {display}
          </a>
        </li>
      );
    });
  }

  private renderNoMatch(): JSX.Element {
    const t1 = this.props.byLocation ? 'items' : 'storage locations';
    const t2 = this.props.byLocation ? 'item' : 'location';
    return (
      <li>
        <a>
          No matching {t1} found.
          <br />
          Please check that the desired {t2} has not already been selected above.
        </a>
      </li>
    );
  }

  private getItemMatchPath(): string {
    return this.props.byLocation ? 'inventory.id' : 'location.id';
  }

  private getPlaceholderText(): string {
    return this.props.byLocation ? 'Select an item...' : 'Select a location...';
  }

  private handleSelectNewItem = (event) => {
    const locId = event.currentTarget.getAttribute('data-location-id');
    const locLabel = event.currentTarget.getAttribute('data-location-label');
    const invId = event.currentTarget.getAttribute('data-inventory-id');

    // if the inventory id came from a matched search, we should be able to still find
    // that item in the state list. otherwise, the invId came from the first inventory
    // item of the items associated with this cycle count. either or both should work
    // here for us - so just find one that matches what we need and make it work.

    const preExisting = this.props.ccItems[0] && this.props.ccItems[0].inventory;
    let inventory: Inventory = String(preExisting.id) === String(invId) && preExisting;
    if (!inventory) {
      const match = this.state.searchItems.find((item) => String(item.id) === String(invId));
      if (!this.isLocation(match)) {
        inventory = {
          id: Number(match.id),
          sku: match.itemCode,
          description: match.description
        };
      }
    }

    const newItem: CycleCountItem = {
      id: null,
      version: null,
      inventory,
      location: {
        id: locId,
        label: locLabel
      },
      expectedQuantities: {},
      countedQuantities: {},
      lpnBarcode: null,
      counted: null
    };

    this.props.onItemSelected(newItem);
  };

  private clearSearch = async (event) => {
    setTimeout(() => this.setState({searchItems: null}), 200);
  };

  private handleFilterChange = async (event) => {
    const searchValue = event.currentTarget.value;
    if (getDataTimeout) {
      clearTimeout(getDataTimeout);
    }
    getDataTimeout = setTimeout(() => this.searchForValue(searchValue), 500);
  };

  private searchForValue = async (value: string) => {
    let response;
    let matches;
    let errors;
    try {
      if (this.props.byLocation) {
        response = await this.props.cycleCountsService.getItemMasterData(value, this.props.warehouseId);
        matches = get(response, 'data.items');
      } else {
        response = await this.props.locationsService.getCountablePhysicalLocations(this.props.warehouseId, value, 15);
        matches = get(response, 'data.locations');
      }
      if (response.errors.length) {
        errors = this.props.cycleCountsService.processErrors(response.errors);
      } else {
        this.setState({searchItems: matches});
      }
    } catch (err) {
      errors = this.props.cycleCountsService.processErrors(err);
    } finally {
      this.props.onWarehouserError(errors);
    }
  };
}
