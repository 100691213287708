import {FC, useEffect, useState} from 'react';
import * as React from 'react';
import {Modal} from '@flexe/ui-components';
import {BlobStorage} from './ConvertToBlobStorage';
import {createGenerationMessage} from './Helpers';
import RegenerationConfirmationModal from './RegenerationConfirmationModal';
import PrintModal from './PrintModal';
import {BulkCartonSsccPrinting} from './BulkCartonSsccPrinting';

export enum BlobStorageDocType {
  generatedBol = 'isGeneratedBol',
  ssccLabels = 'isSsccLabels',
  ssccCartonLabels = 'isSsccCartonLabels',
  ssccPalletLabels = 'isSsccPalletLabels',
  freightPackingList = 'isFreightPackingList'
}

interface Props {
  allowGeneration: boolean;
  apiReadableDocType?: string;
  humanReadableDocType: string;
  regenConfirmMsg?: string;
  storage: BlobStorage;
  rowLabelText?: string;
  handlePrintWithQuantity?: (quantity: number, docType: string, customDocumentName: string) => void;
  warnShortShipForPackingList?: boolean;
  // params and return values are purposely undefined to allow for use with multiple endpoints
  generateDocuments();
  isShipmentShort?: () => Promise<boolean>;
  handleClickPrintByLpn?: () => void;
  enablePrintCartonSsccByLpn?: boolean;
}

export const ManualGenerationDocumentRow: FC<Props> = (props) => {
  const {
    allowGeneration,
    apiReadableDocType = '',
    humanReadableDocType,
    regenConfirmMsg,
    storage,
    rowLabelText,
    handlePrintWithQuantity,
    warnShortShipForPackingList,
    generateDocuments,
    isShipmentShort,
    handleClickPrintByLpn,
    enablePrintCartonSsccByLpn
  } = props;

  const [generating, setGenerating] = useState<boolean>(false);
  const [regenerating, setRegenerating] = useState<boolean>(false);
  const [showRegenWarningModal, setShowRegenWarningModal] = useState<boolean>(false);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
  const [showShortShipModal, setShowShortShipModal] = useState<boolean>(false);

  useEffect(() => {
    if (storage.isQueued) {
      setGenerating(true);
    }
  }, [storage]);

  const handleGenerationButtonClick = async () => {
    if (storage.availableForDownload && regenConfirmMsg) {
      openRegenModal();
    } else if (warnShortShipForPackingList && isShipmentShort) {
      const response = await isShipmentShort();
      if (response) {
        setShowShortShipModal(response);
      } else {
        generateDocs();
      }
    } else {
      generateDocs();
    }
  };

  const generateDocs = async () => {
    setGenerating(true);
    const response = await generateDocuments();
    if (response && !!response.errors.length) {
      setGenerating(false);
    }
  };

  const toggleRegenWarningModal = () => {
    setShowRegenWarningModal(!showRegenWarningModal);
  };

  const openRegenModal = () => {
    setShowRegenWarningModal(true);
  };

  const toggleShortShipModal = () => {
    setShowShortShipModal(!showShortShipModal);
  };

  const handleShortShipModal = () => {
    setShowShortShipModal(!showShortShipModal);
    generateDocs();
  };
  const togglePrintModal = () => {
    setShowPrintModal(!showPrintModal);
  };

  const openPrintModal = () => {
    setShowPrintModal(true);
  };

  const handleRegenProceed = () => {
    setRegenerating(true);
    generateDocuments();
    toggleRegenWarningModal();
  };

  const handlePrintProceed = (qty: number) => {
    handlePrintWithQuantity(qty, apiReadableDocType, humanReadableDocType);
    togglePrintModal();
  };

  const handlePrintClick = () => {
    if (handlePrintWithQuantity || (enablePrintCartonSsccByLpn && storage?.isSsccCartonLabels)) {
      openPrintModal();
    } else {
      window.open(storage.secureDownloadUrl, '_blank').focus();
    }
  };

  return (
    <>
      <tr>
        <td>
          {rowLabelText || storage.typeTitle}
          {storage.availableForDownload && storage.secureDownloadUrl && !!storage.fileName && (
            <p>
              <a href={storage.secureDownloadUrl}>{storage.fileName}</a>
            </p>
          )}
        </td>
        <td>
          {allowGeneration && (
            <button
              type="button"
              disabled={showRegenWarningModal || generating || regenerating}
              className="document-generate"
              onClick={handleGenerationButtonClick}
              title={generating ? 'The document is not available' : ''}
            >
              {generating || regenerating ? (
                <i className="fa fa-circle-notch fa-spin" style={{padding: 0}}></i>
              ) : (
                <i className="fa fa-magic" />
              )}
              {storage.availableForDownload && !generating
                ? `${regenerating ? 'Regenerating' : 'Regenerate'}`
                : `${generating ? 'Generating' : 'Generate'}`}
            </button>
          )}
          <button
            type="button"
            disabled={generating || regenerating || !storage.availableForDownload}
            className="document-print"
            onClick={handlePrintClick}
            title={generating ? 'The document is not available' : ''}
          >
            <i className="fa fa-print" />
            Print
          </button>
          {enablePrintCartonSsccByLpn && storage.isSsccCartonLabels && (
            <button
              type="button"
              disabled={generating || regenerating || !storage.availableForDownload}
              className="document-print"
              onClick={handleClickPrintByLpn}
              title={generating ? 'The document is not available' : ''}
              style={{marginLeft: '16px'}}
            >
              <i className="fa fa-print" />
              Print by LPN
            </button>
          )}

          <div className="document-refresh">
            <em>
              {createGenerationMessage(rowLabelText || humanReadableDocType, storage, generating || regenerating)}
            </em>
          </div>
        </td>
      </tr>
      {regenConfirmMsg && (
        <RegenerationConfirmationModal
          regenConfirmMsg={regenConfirmMsg}
          isOpen={showRegenWarningModal}
          title={`Regenerate ${storage.typeTitle}`}
          toggleModal={toggleRegenWarningModal}
          confirmRegen={handleRegenProceed}
          cancelRegen={toggleRegenWarningModal}
        />
      )}
      {enablePrintCartonSsccByLpn && storage?.isSsccCartonLabels && (
        <tr>
          <td>
            <BulkCartonSsccPrinting
              key={`bulk-carton-sscc-printing-${storage.fileName}`}
              validate={showPrintModal}
              onCancel={() => setShowPrintModal(false)}
              confirmPrint={() => {
                setShowPrintModal(false);
                window.open(storage.secureDownloadUrl, '_blank').focus();
              }}
            />
          </td>
        </tr>
      )}
      {!!handlePrintWithQuantity && (
        <PrintModal
          isOpen={showPrintModal}
          title={`Print ${rowLabelText || storage.typeTitle}`}
          maxQty={99}
          toggleModal={togglePrintModal}
          cancelPrint={togglePrintModal}
          confirmPrint={handlePrintProceed}
        />
      )}
      {!!showShortShipModal && (
        <Modal isOpen={true} toggleModal={toggleShortShipModal}>
          <div id="print-modal-contents">
            <label>The shipment is short. Do you want to proceed?</label>
            <div className="btn-wrapper">
              <button type="button" className="btn-default" onClick={toggleShortShipModal}>
                Cancel
              </button>
              <button title="confirm-short-ship" type="button" className="btn-primary" onClick={handleShortShipModal}>
                Generate
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ManualGenerationDocumentRow;
