import * as React from 'react';
import {Controller, useForm} from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import {format} from 'date-fns';
import * as moment from 'moment';
import FlexeButton from '../shared/FlexeButton';
import WorkOrdersService from '../shared/services/WorkOrdersService';
import {WorkOrderReservation} from './WorkOrdersInterfaces';
import NewWorkOrderFormPreview from './NewWorkOrderFormPreview';
import * as WorkOrders from './WorkOrdersInterfaces';

/* eslint-disable dot-notation,@typescript-eslint/dot-notation */

interface Props {
  workOrdersService: WorkOrdersService;
  reservations: WorkOrderReservation[];
  companyId: number;
  showPreviewView: boolean;
  pageErrors: PageErrorObj;
  setPageErrors: (arg: any) => void;
  setKitToBuild: (arg: any) => void;
  toggleFormModal: () => void;
  togglePreviewView: () => void;
}

// list input names here for react-hook-form
interface Inputs {
  workOrderType: ValueLabelPair<string>;
  reservation: ValueLabelPair<number>;
  skuSelect: ValueLabelPair<number>;
  quantity: number;
  completeBy: Date;
  workOrderNotes: string;
}

interface ValueLabelPair<T> {
  value: T;
  label: string;
}

interface SelectOption {
  label: string;
  value: string;
}

interface PageErrorObj {
  createError: string;
}

const NewWorkOrderForm: React.FC<Props> = (props) => {
  // used to prevent state updates on unmounted components
  const isMountedRef = React.useRef(null);
  const defaultValues = {
    workOrderType: {value: 'kit_to_stock', label: 'Kit to Stock'}
  };
  const blankKitSku = {
    id: -1,
    sku: '',
    description: '',
    companyId: -1,
    kitItems: []
  };

  // handle form functionality, state, and validation
  const {control, errors, handleSubmit, register, getValues, setValue, watch} = useForm<Inputs>({defaultValues});

  const [reservations, setReservations] = React.useState<WorkOrderReservation[]>(props.reservations);
  const [kitSkuOptions, setKitSkuOptions] = React.useState<SelectOption[]>([
    {label: 'Select a warehouse to load available kits', value: ''}
  ]);
  const [kitSkuData, setKitSkuData] = React.useState<WorkOrders.KitSku[]>([]);
  const [selectedKitSku, setSelectedKitSku] = React.useState<WorkOrders.KitSku>(blankKitSku);
  const [typeSelected, setTypeSelected] = React.useState<boolean>(false);
  const [resSelected, setResSelected] = React.useState<boolean>(false);
  const [skuSelected, setSkuSelected] = React.useState<boolean>(false);
  const [quantityProvided, setQuantityProvided] = React.useState<boolean>(false);
  const [dateSelected, setDateSelected] = React.useState<boolean>(false);
  const [allFieldsFilled, setAllFieldsFilled] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [succeeded, setSucceeded] = React.useState<boolean>(false);

  React.useEffect(() => {
    isMountedRef.current = true;
    if (resSelected) {
      loadKitSkus(getValues('reservation').value);
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [resSelected]);

  // when a sku is selected, this will trigger
  // and pass the kit title up to the modal title
  React.useEffect(() => {
    isMountedRef.current = true;
    if (skuSelected) {
      props.setKitToBuild(getValues('skuSelect').label);
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [skuSelected]);

  // when a sku is selected, this will trigger
  // and set the value for the sku data passed to the preview view
  React.useEffect(() => {
    isMountedRef.current = true;
    if (skuSelected) {
      // eslint-disable-next-line
      for (let i = 0; i < kitSkuData.length; i++) {
        if (kitSkuData[i].id === getValues('skuSelect').value) {
          setSelectedKitSku(kitSkuData[i]);
          break;
        }
      }
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [skuSelected]);

  React.useEffect(() => {
    isMountedRef.current = true;
    if (typeSelected && resSelected && skuSelected && quantityProvided && dateSelected) {
      setAllFieldsFilled(true);
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [typeSelected, resSelected, skuSelected, quantityProvided, dateSelected]);

  const loadKitSkus = async (reservationId: number) => {
    const response = await props.workOrdersService.getPossibleKitSkus({reservationId});
    if (!responseHasErrors(response) && isMountedRef.current) {
      const skus = formatSkusForSelect(response.data);
      setKitSkuOptions(skus);
      setKitSkuData(response.data);
    }
    return response.errors;
  };

  const formatSkusForSelect = (incomingSkus) => {
    const formattedSkus: SelectOption[] = [];
    incomingSkus.forEach((sku) => {
      formattedSkus.push({
        value: sku.id,
        label: `${sku.sku} - ${sku.description}`
      });
    });
    return formattedSkus;
  };

  const responseHasErrors = (response) => {
    return response && response.errors && response.errors.length;
  };

  const toggleBooleanState = (conditional, hookToUse, thingToWatch) => {
    React.useEffect(() => {
      isMountedRef.current = true;
      if (conditional) {
        if (isMountedRef.current) {
          hookToUse(true);
        }
      } else {
        if (isMountedRef.current) {
          hookToUse(false);
        }
      }
      return () => {
        isMountedRef.current = false;
      };
    }, thingToWatch);
  };

  toggleBooleanState(watch('workOrderType'), setTypeSelected, [watch('workOrderType')]);
  toggleBooleanState(watch('reservation'), setResSelected, [watch('reservation')]);
  toggleBooleanState(watch('skuSelect'), setSkuSelected, [watch('skuSelect')]);
  toggleBooleanState(watch('quantity'), setQuantityProvided, [watch('quantity')]);
  toggleBooleanState(watch('completeBy'), setDateSelected, [watch('completeBy')]);

  const formatReservationsForSelect = (availableReservations) => {
    const formattedReservations: SelectOption[] = [];
    // eslint-disable-next-line
    for (const i in availableReservations) {
      const res = availableReservations[i];
      const labelStr = `${res.displayName}, ${res.warehouseCity}, ${res.warehouseState}`;
      formattedReservations.push({value: res.id, label: labelStr});
    }
    return formattedReservations;
  };

  // this explicit call to set state is required for testing
  // without it, Jest state will not change when a reservation is chosen
  const triggerReservationState = (e) => {
    setResSelected(true);
    // return value required for onChange of controlled component
    return e[0];
  };

  // this explicit call to set state is required for testing
  // without it, Jest state will not change when a sku is chosen
  const triggerKitState = (e) => {
    setSkuSelected(true);
    // return value required for onChange of controlled component
    return e[0];
  };

  const renderValidation = (inputName, fieldErrors, state) => {
    return (
      <React.Fragment>
        {!state && (
          <p className="required-input">
            {fieldErrors && <span className="validation-msg">{inputName} is </span>}required
          </p>
        )}
        {state && (
          <p className="required-input valid">
            <i className="fas fa-check"></i> required
          </p>
        )}
      </React.Fragment>
    );
  };

  const onSubmit = (data) => {
    // eslint-disable-next-line
    console.log('data: ', data);
    setLoading(true);
    props.setPageErrors(null);
    const newWorkOrder: WorkOrders.KitWorkOrderCreationRequest = {
      reservationId: data.reservation.value,
      companyId: props.companyId,
      kitInventoryId: data.skuSelect.value,
      qtyRequested: data.quantity,
      instructions: data.workOrderNotes,
      completeByDate: data.completeBy
    };
    kitWorkOrderCreate(newWorkOrder);
  };

  const kitWorkOrderCreate = async (values: WorkOrders.KitWorkOrderCreationRequest) => {
    const response = await props.workOrdersService.createKitWorkOrder(values);
    if (response.createdKitWorkOrderId) {
      handleCreationSuccess();
    } else {
      handleCreateError();
    }
  };

  const handleCreationSuccess = () => {
    setSucceeded(true);
    setLoading(false);
    if (!loading && (!props.pageErrors || !props.pageErrors.createError)) {
      window.location.href = '/s/work_orders';
    }
  };

  // TODO: make this more robust
  const handleCreateError = () => {
    const message = 'There was an issue creating your work order';
    handleError(message);
  };

  const handleError = (message) => {
    setLoading(false);
    props.setPageErrors({createError: message});
  };

  const defaultDate = moment(Date.now())
    .add(2, 'days')
    .toDate();

  const workOrderTypeValue = getValues('workOrderType');
  const reservationValue = getValues('reservation') || {value: '', label: ''};
  const quantityValue = getValues('quantity') || 0;
  const completeByValue = getValues('completeBy') || defaultDate;
  const workOrderNotesValue = getValues('workOrderNotes') || '';

  const formatCompleteByDate = (incomingDate) => {
    const formattedDate = format(incomingDate, 'MM/DD/YY');
    return formattedDate;
  };
  const parseReservationName = (incomingRes) => {
    const splitRes = incomingRes.label.split(', ');
    return splitRes[0];
  };

  const formViewClasses = [props.showPreviewView && 'hidden', 'form-view-wrapper'];
  const formPreviewViewClasses = [!props.showPreviewView && 'hidden', 'form-view-wrapper'];

  return (
    <React.Fragment>
      <form
        data-testid="new-work-order-form"
        className="new-work-order-form"
        id="new-work-order-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={classNames(formPreviewViewClasses)} id="preview-view">
          <div className="form-body">
            <NewWorkOrderFormPreview
              workOrderType={workOrderTypeValue.label}
              reservationName={parseReservationName(reservationValue)}
              reservationId={reservationValue.value}
              kitSku={selectedKitSku}
              completeBy={formatCompleteByDate(completeByValue)}
              quantity={quantityValue}
              notes={workOrderNotesValue}
            />
          </div>
          <div className="preview-footer">
            {props.pageErrors && props.pageErrors.createError && (
              <p className="creation-error">{props.pageErrors.createError}</p>
            )}
            <span className="submit-warn">*Once submitted, this work order cannot be changed.</span>
            <div className="form-buttons">
              <FlexeButton
                testid="save-button-bottom"
                level="primary"
                text="Submit Work Order"
                type="submit"
                isDisabled={loading || succeeded}
              />
            </div>
          </div>
        </div>
        <div className={classNames(formViewClasses)} id="form-view">
          <div className="form-body">
            <fieldset form="new-work-order-form" id="fieldset-one">
              <div className="form-field">
                <label htmlFor="workOrderType">Type</label>
                <div data-testid="type-field">
                  <Controller
                    className="work-order-select"
                    as={Select}
                    name="workOrderType"
                    control={control}
                    id="workOrderType"
                    options={[{value: 'kit_to_stock', label: 'Kit to Stock'}]}
                    rules={{required: true}}
                  />
                </div>
                {renderValidation('Work order type', errors.workOrderType, typeSelected)}
              </div>
              <div className="form-field">
                <label htmlFor="reservation">Warehouse</label>
                <div data-testid="reservation-field">
                  <Controller
                    className="work-order-select"
                    as={Select}
                    name="reservation"
                    control={control}
                    id="reservation"
                    placeholder="Select a reservation"
                    options={formatReservationsForSelect(reservations)}
                    rules={{required: true}}
                    onChange={triggerReservationState}
                  />
                </div>
                {renderValidation('Warehouse', errors.reservation, resSelected)}
              </div>
              <div className="form-field">
                <label htmlFor="skuSelect">Kit to build</label>
                <div data-testid="sku-select">
                  <Controller
                    className="work-order-select"
                    as={Select}
                    name="skuSelect"
                    control={control}
                    data-testid="sku-select-controller"
                    id="skuSelect"
                    placeholder="Type to select a SKU"
                    options={kitSkuOptions}
                    isClearable={true}
                    isSearchable={true}
                    isDisabled={!resSelected}
                    rules={{required: true}}
                    onChange={triggerKitState}
                  />
                </div>
                {renderValidation('Kit SKU', errors.skuSelect, skuSelected)}
              </div>
            </fieldset>
            <fieldset form="new-work-order-form" className="row" id="fieldset-two">
              <span className="form-field col-sm-6">
                <label htmlFor="quantity">Quantity</label>
                <input
                  data-testid="quantity-field"
                  className="work-order-input"
                  name="quantity"
                  id="quantity"
                  type="number"
                  ref={register({required: true, min: {value: 1, message: 'quantity must be greater than 0'}})}
                />
                {renderValidation('Quantity', errors.quantity, quantityProvided)}
              </span>
              <span className="form-field col-sm-6">
                <label htmlFor="completeBy">Complete by date</label>
                <Controller
                  as={DatePicker}
                  control={control}
                  // When typings for react-datepicker were added, these violations existed.
                  // Ignoring since work-orders never shipped to customers.
                  // TODO FE-573 figure out how to remove the @ts-ignore
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  valueName={'selected'}
                  // TODO FE-573 figure out how to remove the @ts-ignore
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onChange={([selected]) => selected}
                  name="completeBy"
                  id="completeBy"
                  placeholderText="Date"
                  className="work-order-date-picker"
                  minDate={defaultDate}
                  rules={{required: true}}
                />
                {renderValidation('Complete by date', errors.completeBy, dateSelected)}
              </span>
            </fieldset>
            <fieldset form="new-work-order-form" id="fieldset-three">
              <div className="form-field">
                <input
                  data-testid="work-order-notes-field"
                  data-lpignore={true}
                  className="work-order-input text-input"
                  name="workOrderNotes"
                  placeholder="Add note or special instructions"
                  ref={register()}
                />
              </div>
            </fieldset>
          </div>
          <div className="form-footer">
            <div className="form-buttons">
              <FlexeButton
                testid="cancel-button-bottom"
                level="collapsed"
                text="Cancel"
                isDisabled={loading || succeeded}
                handleClick={props.toggleFormModal}
              />
              <FlexeButton
                testid="preview-button-bottom"
                level="primary"
                text={
                  <span>
                    Preview <i className="fa fa-arrow-right"></i>
                  </span>
                }
                isDisabled={loading || succeeded || !allFieldsFilled}
                handleClick={props.togglePreviewView}
              />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default NewWorkOrderForm;
