import * as React from 'react';
import {useContext, useState} from 'react';
import * as moment from 'moment-timezone';
import {PickBatchDocument} from '../WaveInterfaces';
import DocumentPrintButton from '../shared/DocumentPrintButton';
import {PickWaveStatus} from '../WaveBatchInterfaces';
import {WaveDetailsContext} from './WaveDetailsContext';

interface WaveDocumentsProps {
  waveDocRegenEnabled: boolean;
  onRegenClick: () => void;
}
const getDocumentCreateDate = (document: PickBatchDocument) => {
  return moment
    .utc(document?.createdAt)
    .tz(moment.tz.guess())
    .format('MMM D, h:mm a');
};

const createGenerationMessage = (document: PickBatchDocument, isGenerating: boolean) => {
  let message = '';

  if (isGenerating) {
    message = 'Generating Wave Documents. Please refresh the page in a few moments';
  } else if (document?.createdAt) {
    message = `Last generated ${getDocumentCreateDate(document)}`;
  }

  return message;
};
// eslint-disable-next-line @typescript-eslint/ban-types
const WaveDocuments: React.FC<WaveDocumentsProps> = ({waveDocRegenEnabled, onRegenClick}) => {
  const {wave, batches} = useContext(WaveDetailsContext);
  const [waveDocumentIsGenerating, setWaveDocumentIsGenerating] = useState<boolean>(false);

  const enqueWaveDocRegeneration = () => {
    setWaveDocumentIsGenerating(true);
    onRegenClick();
  };

  const waveDocument = wave?.documents[wave.documents.length - 1];
  const batchCount = batches.every((b) => b.documents?.length > 0) ? batches.length : 0;

  return (
    <div className="widget-container">
      <div className="container-fluid">
        <div className="header">
          <h2>Documents</h2>
        </div>

        <div className="pick-wave-batch-documents-row">
          <div className="info-pair">
            <span className="title">Pick Instructions:</span>
            <span className="value">{`${batchCount} Batch Document${batchCount !== 1 ? 's' : ''}`}</span>
          </div>
          <div className="info-pair">
            <div className="button-container">
              <DocumentPrintButton
                className={'shipment-document-print'}
                document={waveDocument}
                isWaveCancelled={wave.status === PickWaveStatus.cancelled}
                printButtonPrefix={'Print All Batches'}
              />
            </div>
            <span className="value">
              {createGenerationMessage(waveDocument, waveDocumentIsGenerating)}
              {waveDocRegenEnabled && waveDocument && !wave.waveAttributes.packTimeLabelGeneration && (
                <a onClick={enqueWaveDocRegeneration}>
                  <i className="fa fa-refresh" />
                  Refresh
                </a>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaveDocuments;
