import * as React from 'react';
import {Table} from '@flexe/ui-components';
import {TableData, TableHeader} from '@flexe/ui-components';
import {Reservation} from '../../shared/CommonInterfaces';
import {ContainerDeliveryState, Inventory, LpnMetadata, PackingListItem} from '../shared/DropoffInterfaces';
import {renderLpnLink} from '../../../libs/helpers';

// eslint-disable-next-line
const checkmarkImg = require('../../../../assets/images/common/checkmark-green.png');

interface ExpectedLpnContentsProps {
  packingLists: {
    expected: PackingListItem[];
    shippable: PackingListItem[];
    damaged: PackingListItem[];
  };
  reservation: Reservation;
  dropoffState: ContainerDeliveryState;
}

export default function ExpectedLpnContents(props: ExpectedLpnContentsProps) {
  const createTableRows = () => {
    // Use shippable packing lists to find received LPNs
    // Unique key should be tuple with (LPN barcode, inventory ID)
    const receivedLpns = new Map<string, LpnMetadata>();
    props.packingLists.shippable.forEach((pl) => {
      receivedLpns.set(createKey(pl), pl.lpn);
    });

    return props.packingLists.expected
      .sort((plA, plB) => {
        const receivedA = receivedLpns.get(createKey(plA));
        const receivedB = receivedLpns.get(createKey(plB));
        if (receivedA && !receivedB) {
          return 1;
        } else if (!receivedA && receivedB) {
          return -1;
        } else {
          return 0;
        }
      })
      .map((pl) => {
        const receivedLpn: LpnMetadata = receivedLpns.get(createKey(pl));

        const cols: any[] = [
          renderLpnLink(pl.lpn ? pl.lpn.lpnBarcode : '', false),
          createInventoryCell(pl.inventory),
          `${pl.quantity.amount} ${pl.quantity.unit}`
        ];

        if (props.dropoffState !== ContainerDeliveryState.new) {
          const receivedStatus = receivedLpn ? receivedCell() : unreceivedCell();
          cols.push(receivedStatus);

          const receivedDate = receivedLpn ? formatDateString(receivedLpn.arrivedAt) : '--';
          cols.push(receivedDate);
        }

        return cols;
      });
  };

  const createKey = (packingList: PackingListItem): string => {
    return packingList.lpn
      ? packingList.lpn.lpnBarcode
      : '' + '-' + packingList.inventory.id.toString() + '-' + packingList.quantity.unit;
  };

  const createInventoryCell = (inventory: Inventory) => {
    return (
      <div>
        <b>{inventory.description}</b>
        <br />
        {inventory.sku}
      </div>
    );
  };

  const receivedCell = () => {
    return (
      <div>
        <img src={checkmarkImg} alt="Checkmark" />
        &nbsp; Received
      </div>
    );
  };

  const unreceivedCell = () => {
    if (props.dropoffState === ContainerDeliveryState.completed) {
      return <div className="not-received-lpn-warning">Not received</div>;
    } else {
      return <i>Awaiting scan...</i>;
    }
  };

  const formatDateString = (date: Date) => {
    return new Date(date).toLocaleString('en-US', {timeZoneName: 'short'});
  };

  const createTableHeaders = (): TableHeader[] => {
    const headers = [{element: 'LPN'}, {element: 'Item'}, {element: 'Quantity'}];

    if (props.dropoffState !== ContainerDeliveryState.new) {
      headers.push({element: 'Received'});
      headers.push({element: 'Receive Date'});
    }

    return headers;
  };

  const formatTableData = (): TableData => {
    return {
      headers: createTableHeaders(),
      rows: createTableRows()
    };
  };

  return (
    <div id="expected-lpn-contents">
      <h3>Content Summary</h3>
      <Table tableData={formatTableData()} />
    </div>
  );
}
