import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import FlexeContext, {FlexeContextValues} from '../contexts/FlexeContext';
import WorkOrdersService from '../shared/services/WorkOrdersService';
import {Warehouse} from '../shared/CommonInterfaces';
import {WorkOrderReservation} from './WorkOrdersInterfaces';
import WorkOrdersIndex from './WorkOrdersIndex';
import WorkOrderDetail from './WorkOrderDetail';

interface Props {
  authenticityToken: string;
  isShipper: boolean;
  companyId: number;
  enableWorkOrders: boolean;
  currentWarehouse?: Warehouse;
  reservations?: WorkOrderReservation[];
}

const WorkOrders: React.FC<Props> = (props: Props) => {
  const flexeContextValue: FlexeContextValues = {
    authenticityToken: props.authenticityToken
  };
  const workOrdersService = new WorkOrdersService(props.authenticityToken);

  return (
    <FlexeContext.Provider value={flexeContextValue}>
      <Router>
        <Switch>
          <Route
            path="/(s|wh)/work_orders/:id"
            render={(routeProps) => {
              return (
                <WorkOrderDetail
                  {...routeProps}
                  workOrdersService={workOrdersService}
                  isShipper={props.isShipper}
                  enableWorkOrders={props.enableWorkOrders}
                  workOrderId={`${routeProps.match.params.id}`}
                />
              );
            }}
          />
          <Route
            path="/(s|wh)/work_orders"
            render={(routeProps) => {
              return (
                <WorkOrdersIndex
                  {...routeProps}
                  workOrdersService={workOrdersService}
                  isShipper={props.isShipper}
                  companyId={props.companyId}
                  enableWorkOrders={props.enableWorkOrders}
                  currentWarehouse={props.currentWarehouse}
                  reservations={props.reservations}
                />
              );
            }}
          />
        </Switch>
      </Router>
    </FlexeContext.Provider>
  );
};

export default WorkOrders;
