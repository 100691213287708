export interface LabelList {
  [p: string]: string;
}

export function isRfoOrOmniFreightLabelTrue(labels: LabelList) {
  return labels.flexe_ltl_v1 === 'true';
}
export function isOmniFreightLabelTrue(labels: LabelList) {
  return labels.flexe_freight_workflow === 'true';
}
export function isFreight(labels: LabelList) {
  return isRfoOrOmniFreightLabelTrue(labels) || isOmniFreightLabelTrue(labels);
}
