import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';

interface Props {
  showCannotEditModal: boolean;
  toggleCannotEditModal: () => void;
  renderCurrentWorkers: () => JSX.Element;
}

const CycleCountCannotEditModal: FunctionComponent<Props> = (props) => {
  return (
    <LegacyModal
      id="cannot-edit-cycle-count-modal"
      title="Cycle Count in Progress"
      show={props.showCannotEditModal}
      size="small"
      toggleModal={props.toggleCannotEditModal}
      footer={
        <div>
          <a onClick={props.toggleCannotEditModal}>OK</a>
        </div>
      }
      disableClose={false}
    >
      <div>
        <p>
          Editing or submitting are disabled while mobile users are in progress. To edit here, mobile users must submit
          their work from the device.
        </p>
        <p>The following workers are still active:</p>
        {props.renderCurrentWorkers()}
      </div>
    </LegacyModal>
  );
};

export default CycleCountCannotEditModal;
