import * as React from 'react';
import {get} from 'lodash';
import {Link} from 'react-router-dom';
import {format} from 'date-fns';
import classNames from 'classnames';
import {friendlyCount, LegacyModal, Loader, Pagination, Table, TableHeader, Tabs} from '@flexe/ui-components';
import FileDownloadLink from '../../shared/FileDownloadLink';
import {DropDownOption} from '../../shared/DropDown';
import {Counts, FilterOption, FilterType} from '../../shared/CommonInterfaces';
import Filters from '../../shared/Filters';
import {SplitSource} from './SharedInterfaces';
import {
  FulfillmentProps,
  FulfillmentState,
  ReservationFilterOption,
  Shipment,
  ShipmentCancelResponse,
  ShipmentsResponse
} from './SharedInterfaces';
import RepairShipment from './RepairShipment';
import FulfillmentService from './FulfillmentService';
import RetryShipmentsModal from './RetryShipmentsModal';
import BulkCancelModal from './BulkCancelModal';

class Fulfillment extends React.Component<FulfillmentProps, FulfillmentState> {
  private fulfillmentService: FulfillmentService;

  private tabTitles = {
    cancelled: 'Cancelled Shipments',
    completed: 'Completed Shipments',
    inProgress: 'In Progress Shipments',
    issues: 'Shipment Issues',
    new: 'New Shipments'
  };

  constructor(props) {
    super(props);
    this.fulfillmentService = props.fulfillmentService || new FulfillmentService(props.authenticityToken);
    let defaultFilters = {};
    if (props.defaultShipByStartingFilterDate) {
      defaultFilters = {filters: {ship_on_start: props.defaultShipByStartingFilterDate}};
    }
    const newState = {...this.fulfillmentService.getListCache(), ...defaultFilters};
    this.state = newState;
  }

  public async componentDidMount() {
    let response: ShipmentsResponse = await this.fulfillmentService.getShipments(
      this.state.selectedTab,
      this.state.filters,
      this.state.currentPage > 1 ? this.state.continuationTokens[this.state.currentPage - 2] : null
    );
    if (
      this.state.selectedTab === 'issues' &&
      get(response, 'data.shipments') &&
      get(response, 'data.shipments').length === 0
    ) {
      this.setState({
        selectedTab: 'new'
      });
      response = await this.fulfillmentService.getShipments(
        this.state.selectedTab,
        this.state.filters,
        this.state.currentPage > 1 ? this.state.continuationTokens[this.state.currentPage - 2] : null
      );
    }
    this.setState(
      {
        continuationTokens: [get(response, 'data.continuationToken')],
        counts: get(response, 'data.counts') as Counts,
        loading: false,
        newShipments: get(response, 'data.shipments') as Shipment[],
        shipments: get(response, 'data.shipments') as Shipment[],
        reservations: get(response, 'data.reservations') as FilterOption[],
        usesOrderManager: get(response, 'data.reservations')
          ? get(response, 'data.reservations').every(
              (reservation: ReservationFilterOption) => reservation.usesOrderManager
            )
          : false,
        distributionByLotEnabled: get(response, 'data.reservations')
          ? get(response, 'data.reservations').some(
              (reservation: ReservationFilterOption) => reservation.distributionByLotEnabled
            )
          : false
      },
      () => this.fulfillmentService.setListCache(this.state)
    );
  }

  public render() {
    let modalFooter;
    let originalAddr;
    let suggestedAddr;

    if (this.state.showCancelModal) {
      modalFooter = (
        <div>
          <a onClick={this.toggleCancelModal}>Cancel</a>
          <button id="cancel-agree" type="button" className="btn" onClick={this.handleCancel}>
            Cancel Shipments
          </button>
        </div>
      );
    }

    if (this.state.selectedShipmentForRepair) {
      originalAddr = this.state.selectedShipmentForRepair.shipToAddress;
      suggestedAddr = this.state.selectedShipmentForRepair.validatedShipToAddress;
    }

    const tableData = this.getTableData();
    const numberOfAppliedFilters = Object.keys(this.state.filters).reduce(
      (previousValue, currentValue) => previousValue + (currentValue === 'ids' ? this.getFilterValue('ids').length : 1),
      0
    );
    const issuesDownloadLinkText = `Download CSV${
      numberOfAppliedFilters !== 0 ? ` (${numberOfAppliedFilters} filter${numberOfAppliedFilters > 1 ? 's' : ''})` : ''
    }`;

    return (
      <div className="container-fluid fulfillment-index">
        {this.state.errors && (
          <div className="alert alert-danger" role="alert">
            {this.state.errors}
          </div>
        )}
        {this.fulfillmentHeader()}
        <div id="fulfillment-component">
          {JSON.stringify(this.state.newShipments) !== JSON.stringify(this.state.shipments) && (
            <button onClick={() => this.handleRefresh()} id="refresh-button" className="btn">
              <i className="fa fa-refresh" aria-hidden="true"></i>
              <strong>Incoming Shipments</strong> - Click to Refresh
            </button>
          )}
          <p className="text-uppercase">Filters</p>
          <Filters filters={this.getFilters()} filterChangeHandler={(filters) => this.handleFilterChange(filters)} />
          <Tabs tabs={this.getTabs()} tabEvent={(tabKey) => this.handleTabEvent(tabKey)} />
          <div id="table-header" className={classNames(['row', 'file-download-link-enabled'])}>
            <div className="col-md-6">
              <h5>{this.tabTitles[this.state.selectedTab]}</h5>
            </div>
            <div className="col-md-6 text-right">
              {this.state.selectedTab === 'issues' && (
                <>
                  <span className="download-issues-link">
                    <FileDownloadLink
                      disabled={this.state.shipments.length === 0}
                      text={issuesDownloadLinkText}
                      showAsButton
                      variant="primary"
                      onClick={this.handleDownloadCSV}
                    />
                  </span>

                  <button
                    id="retry-button"
                    className={'retry-button'}
                    disabled={this.state.shipments.length === 0}
                    onClick={this.toggleRetryModal}
                  >
                    <i className="fa fa-retweet"></i>{' '}
                    {this.state.selectedShipmentIds.length > 0 ? 'Retry Selected' : 'Retry All'}
                  </button>
                </>
              )}

              {!this.state.usesOrderManager &&
                (this.state.selectedTab === 'issues' || this.state.selectedTab === 'new') && (
                  <button
                    id="cancel-button"
                    className={'cancel-button'}
                    disabled={this.state.selectedShipmentIds.length === 0}
                    onClick={() => this.toggleCancelModal()}
                  >
                    <i className="fa fa-times"></i> Cancel
                  </button>
                )}
            </div>
          </div>
          {!this.state.loading && tableData.rows.length > 0 && (
            <Table tableClass={`table-striped ${this.state.selectedTab}`} tableData={tableData} />
          )}
          {!this.state.loading && tableData.rows.length === 0 && (
            <p>No shipments matched your filters. Please change your search to see results.</p>
          )}
          <Loader loading={this.state.loading} />

          {this.state.showRetryModal && (
            <RetryShipmentsModal
              show={this.state.showRetryModal}
              onSubmit={() =>
                this.fulfillmentService.retryShipmentsWithIssues(this.state.selectedShipmentIds, this.state.filters)
              }
              onClose={this.toggleRetryModal}
              numberOfShipments={
                this.state.selectedShipmentIds.length > 0
                  ? this.state.selectedShipmentIds.length
                  : this.state.counts.issues
              }
              retryExtraDescription={this.getExtraRetryModalDescription(this.state.selectedShipmentIds)}
            />
          )}
          {this.state.showCancelModal &&
            (this.props.showBulkCancelModal ? (
              // This modal is only shown in the case we want to allow the user to select a cancellation reason
              <BulkCancelModal
                handleCancel={this.handleCancel}
                handleDropDownSelect={this.handleCancelReasonSelect}
                selectedOption={this.state.cancellationReason}
                showCancelModal={true}
                toggleCancelModal={this.toggleCancelModal}
                cancelDescription={this.getBulkCancelDescription(this.state.selectedShipmentIds)}
              />
            ) : (
              <LegacyModal
                id="cancel-shipment-modal"
                title="Cancel e-commerce fulfillment shipments"
                show={this.state.showCancelModal}
                size="small"
                toggleModal={this.toggleCancelModal}
                footer={modalFooter}
                disableClose={false}
              >
                <div>
                  <p>{this.getLegacyCancelDescription(this.state.selectedShipmentIds)}</p>
                  <Table tableData={this.getSelectedShipmentsTable(this.state.selectedShipmentIds)} />
                </div>
              </LegacyModal>
            ))}
          {this.state.showFixAddressModal && this.state.selectedShipmentForRepair && (
            <RepairShipment
              fulfillmentService={this.fulfillmentService}
              handleUpdateErrors={this.handleUpdateErrors}
              shipmentId={this.state.selectedShipmentForRepair.shipmentId}
              originalAddress={originalAddr}
              reloadShipments={this.reloadShipments}
              suggestedAddress={suggestedAddr}
              showFixAddressModal={this.state.showFixAddressModal}
              toggleFixAddressModal={this.toggleFixAddressModal}
            />
          )}
        </div>
      </div>
    );
  }

  public async handleTabEvent(tabKey: string) {
    this.setState({
      loading: true,
      newShipments: [],
      shipments: [],
      selectedShipmentIds: [],
      selectedTab: tabKey
    });
    const response: ShipmentsResponse = await this.fulfillmentService.getShipments(tabKey, this.state.filters);
    this.setState(
      {
        continuationTokens: [get(response, 'data.continuationToken')],
        counts: get(response, 'data.counts') as Counts,
        currentPage: 1,
        loading: false,
        newShipments: get(response, 'data.shipments') as Shipment[],
        shipments: get(response, 'data.shipments') as Shipment[]
      },
      () => this.fulfillmentService.setListCache(this.state)
    );
  }

  private handleRefresh() {
    this.setState(
      {
        continuationTokens: [this.state.newContinuationToken],
        currentPage: 1,
        shipments: this.state.newShipments.slice()
      },
      () => this.fulfillmentService.setListCache(this.state)
    );
  }

  private handleUpdateErrors = async (errors) => {
    this.setState({
      errors
    });
  };

  private reloadShipments = async (errors = null) => {
    const response: ShipmentsResponse = await this.fulfillmentService.getShipments(
      this.state.selectedTab,
      this.state.filters
    );
    this.setState(
      {
        continuationTokens: [get(response, 'data.continuationToken')],
        counts: get(response, 'data.counts') as Counts,
        currentPage: 1,
        errors,
        loading: false,
        newShipments: get(response, 'data.shipments') as Shipment[],
        shipments: get(response, 'data.shipments') as Shipment[]
      },
      () => this.fulfillmentService.setListCache(this.state)
    );
  };

  private toggleRetryModal = () => {
    this.setState({
      showRetryModal: !this.state.showRetryModal,
      selectedShipmentForRepair: null
    });
  };

  private toggleCancelModal = () => {
    this.setState({
      showCancelModal: !this.state.showCancelModal,
      selectedShipmentForRepair: null
    });
  };

  private handleCancelReasonSelect = (reason: DropDownOption) => {
    this.setState({cancellationReason: reason.value});
  };

  private handleCancel = async () => {
    let errors = null;
    // Due to the way the dropdown code works, we have a union of "string | number" even
    // though we know we will always receive a string here OR null if we didn't use the
    // admin bulk cancel modal.
    // Anyway, to make things simpler, we'll just cast the reason code to string here
    // before passing it further downstream.
    const reason = this.state.cancellationReason ? this.state.cancellationReason.toString() : null;
    const selectedShipmentIds = this.state.selectedShipmentIds.slice();
    this.setState({
      loading: true,
      newShipments: [],
      shipments: [],
      selectedShipmentIds: [],
      showCancelModal: false
    });
    try {
      // If we have a cancel reason code, use it in the api call. Both calls hit the same endpoint
      // so the return type is the same.
      const response: ShipmentCancelResponse = reason
        ? await this.fulfillmentService.cancelShipmentsWithReason(selectedShipmentIds, reason)
        : await this.fulfillmentService.cancelShipments(selectedShipmentIds);

      if (response.errors && response.errors.length > 0) {
        const errorItems = response.errors.map((error) => {
          return <li key={error.shipment_id}>{error.msg}</li>;
        });
        errors = (
          <div>
            There were issues cancelling your shipments:
            <ul>{errorItems}</ul>
          </div>
        );
      }
    } catch (error) {
      errors = <div>There was an issue cancelling your shipments.</div>;
    }
    this.reloadShipments();
  };

  private handleDownloadCSV = async () => {
    await this.fulfillmentService.downloadFulfillmentIssuesAsync(this.state.filters);
  };

  private toggleFixAddressModal = () => {
    this.setState({
      selectedAddress: null,
      selectedShipmentForRepair: null,
      showFixAddressModal: !this.state.showFixAddressModal
    });
  };

  private handleRepair(shipment: Shipment) {
    // just address fixing for now
    this.setState({
      selectedAddress: null,
      selectedShipmentForRepair: shipment,
      showFixAddressModal: true
    });
  }

  private async handleFilterChange(filters) {
    const filterValues = {};
    filters.forEach((selected) => {
      if (selected.filter.key === 'ids') {
        const id = selected.value;
        if (filterValues[selected.filter.key]) {
          if (!filterValues[selected.filter.key].includes(id)) {
            filterValues[selected.filter.key].push(id);
          }
        } else {
          filterValues[selected.filter.key] = [id];
        }
      } else {
        filterValues[selected.filter.key] = selected.value;
      }
    });
    this.setState({
      loading: true,
      filters: filterValues
    });
    const response: ShipmentsResponse = await this.fulfillmentService.getShipments(
      this.state.selectedTab,
      filterValues
    );
    this.setState(
      {
        continuationTokens: [get(response, 'data.continuationToken')],
        counts: get(response, 'data.counts') as Counts,
        currentPage: 1,
        loading: false,
        newShipments: get(response, 'data.shipments') as Shipment[],
        shipments: get(response, 'data.shipments') as Shipment[],
        selectedShipmentIds: []
      },
      () => this.fulfillmentService.setListCache(this.state)
    );
  }

  private handleSelectShipment(shipmentId: number) {
    if (this.state.selectedShipmentIds.indexOf(shipmentId) > -1) {
      const updatedShipments = this.state.selectedShipmentIds.slice();
      updatedShipments.splice(updatedShipments.indexOf(shipmentId), 1);
      this.setState({
        selectedShipmentIds: updatedShipments
      });
    } else {
      const updatedShipments = this.state.selectedShipmentIds.slice();
      updatedShipments.push(shipmentId);
      this.setState(
        {
          selectedShipmentIds: updatedShipments
        },
        () => this.fulfillmentService.setListCache(this.state)
      );
    }
  }

  private handleSelectAll(event) {
    if (event.target.checked) {
      this.setState(
        {
          selectedShipmentIds: this.state.shipments.map((shipment) => {
            return shipment.shipmentId;
          })
        },
        () => this.fulfillmentService.setListCache(this.state)
      );
    } else {
      this.setState(
        {
          selectedShipmentIds: []
        },
        () => this.fulfillmentService.setListCache(this.state)
      );
    }
  }

  private trackingNumberHeader() {
    if (this.props.omnichannelEnabled) {
      return 'PRO/Tracking Number';
    } else {
      return 'Tracking Number';
    }
  }

  private fulfillmentHeader() {
    let headerValue = 'Fulfillment Shipments';
    if (!this.props.omnichannelEnabled) {
      headerValue = 'eCommerce ' + headerValue;
    }
    return <h2>{headerValue}</h2>;
  }

  private getTableData() {
    const headers = [
      {element: 'Res ID'},
      {className: 'id-header', element: 'FLEXE ID'},
      {element: 'Order #'},
      {element: 'Carrier'},
      {element: this.trackingNumberHeader()},
      {element: 'Ship By'},
      {element: 'Name'},
      {element: 'Address'},
      {className: 'action-header', element: 'Actions'},
      {
        className: 'pagination-header',
        element: (
          <Pagination
            page={this.state.currentPage}
            pageSize={50}
            paginationHandler={(page) => this.handlePagination(page)}
            totalCount={this.state.counts[this.state.selectedTab]}
          />
        )
      }
    ] as TableHeader[];
    if (this.state.selectedTab === 'issues') {
      headers.unshift({
        className: 'status-header',
        element: 'Status'
      });
    }
    if (!this.state.usesOrderManager && (this.state.selectedTab === 'issues' || this.state.selectedTab === 'new')) {
      headers.unshift({
        className: 'select-header',
        element: (
          <input
            type="checkbox"
            checked={this.state.selectedShipmentIds.length === this.state.shipments.length}
            onChange={(event) => this.handleSelectAll(event)}
          />
        )
      });
    }
    if (this.state.selectedTab === 'issues') {
      headers.unshift({
        className: 'issue-header',
        element: ''
      });
    }
    const rows = this.buildRows(this.state.selectedTab === 'issues');
    return {
      headers,
      rows
    };
  }

  private getSelectedShipmentsTable(selectedShipmentIds: number[]) {
    const shipmentData = this.state.shipments.filter((shipment) => {
      return selectedShipmentIds.indexOf(shipment.shipmentId) > -1;
    });
    const rows = shipmentData.map((shipment) => {
      return [shipment.shipmentId, shipment.po];
    });
    return {
      headers: [{element: 'FLEXE ID'}, {element: 'PO'}] as TableHeader[],
      rows
    };
  }

  private anyMPSShipmentWithParentCustomerUuid(shipmentIds: number[]) {
    return this.state.shipments.some((shipment) => {
      return shipmentIds.indexOf(shipment.shipmentId) > -1 &&
        !!shipment.parentCustomerUuid &&
        shipment.splitSource === SplitSource.MpsSplit
        ? true
        : false;
    });
  }

  private getLegacyCancelDescription(shipmentIds: number[]) {
    const description = this.anyMPSShipmentWithParentCustomerUuid(shipmentIds) ? (
      <div>
        <b>Warning!</b> Some of the shipments you selected are connected to other shipments which will also be
        cancelled. Are you sure you want to proceed?
      </div>
    ) : (
      <div>Are you sure you want to cancel the following shipments?</div>
    );
    return description;
  }

  private getBulkCancelDescription(shipmentIds: number[]) {
    const description = this.anyMPSShipmentWithParentCustomerUuid(shipmentIds) ? (
      <div>
        <b>Warning!</b> Some of the shipments you selected are connected to other shipments which will also be
        cancelled. Are you sure you want to proceed? Please select a reason code from below.
      </div>
    ) : (
      <div>Shipments will be cancelled. Please select a reason code from below.</div>
    );
    return description;
  }

  private getExtraRetryModalDescription(shipmentIds: number[]) {
    const description = this.anyMPSShipmentWithParentCustomerUuid(shipmentIds) ? (
      <div>
        <b>Warning!</b> Some of the shipments selected to be regenerated are connected to other shipments, so will be
        skipped. To get new labels for these shipments, instead cancel them and wait for them to be reallocated
        automatically.
      </div>
    ) : (
      <div></div>
    );
    return description;
  }

  private async handlePagination(page: number) {
    this.setState({
      loading: true
    });
    const continuationToken = page > 1 ? this.state.continuationTokens[page - 2] : null;
    const response: ShipmentsResponse = await this.fulfillmentService.getShipments(
      this.state.selectedTab,
      this.state.filters,
      continuationToken
    );
    const updatedContinuationTokens = this.state.continuationTokens.slice();
    updatedContinuationTokens.push(get(response, 'data.continuationToken'));
    this.setState(
      {
        continuationTokens: updatedContinuationTokens,
        counts: get(response, 'data.counts') as Counts,
        currentPage: page,
        loading: false,
        newShipments: get(response, 'data.shipments') as Shipment[],
        shipments: get(response, 'data.shipments') as Shipment[],
        selectedShipmentIds: []
      },
      () => this.fulfillmentService.setListCache(this.state)
    );
  }

  private buildRows(isIssues: boolean) {
    if (this.state.shipments && this.state.shipments.length > 0) {
      const rows = this.state.shipments.map((shipment: Shipment) => {
        let carrierString = get(shipment, 'shippingLabelInfo.carrierServiceType', 'N/A');
        if (carrierString == null) {
          carrierString = get(shipment, 'shippingLabelInfo.carrierDisplayName', 'N/A');
        }

        const row = [
          <div>{shipment.reservationFriendlyName}</div>,
          <Link to={`/s/fulfillment/ecommerce/${shipment.shipmentId}`}>#{shipment.shipmentId}</Link>,
          <div> {shipment.po} </div>,
          carrierString,
          <div>{get(shipment, 'shippingLabelInfo.carrierAssignedId', 'N/A')}</div>,
          format(shipment.shipOn, 'MM/DD/YYYY'),
          shipment.shipToName,
          <div>
            {`${shipment.shipToAddress.line1} ${shipment.shipToAddress.locality} ${shipment.shipToAddress.region},
             ${shipment.shipToAddress.postcode}`}
          </div>,
          shipment.repairable ? (
            <button type="button" className="btn repair-btn" onClick={() => this.handleRepair(shipment)}>
              <i className="fa fa-wrench"></i> Attempt Repair
            </button>
          ) : (
            'N/A'
          ),
          <Link
            className={isIssues ? 'pull-right text-danger' : 'pull-right'}
            to={`/s/fulfillment/ecommerce/${shipment.shipmentId}`}
          >
            <i className="fa fa-chevron-right"></i>
          </Link>
        ];
        if (isIssues) {
          row.unshift(
            <span className={isIssues ? 'text-danger text-capitalize' : 'text-capitalize'}>{shipment.state}</span>
          );
        }
        if (!this.state.usesOrderManager && (isIssues || this.state.selectedTab === 'new')) {
          row.unshift(
            <input
              type="checkbox"
              checked={this.state.selectedShipmentIds.indexOf(shipment.shipmentId) > -1}
              onChange={() => this.handleSelectShipment(shipment.shipmentId)}
            />
          );
        }
        if (isIssues) {
          row.unshift(
            <div className="issue-description">
              <i className="fa fa-exclamation-triangle text-danger"></i>
              {shipment.issues.map((issue) => issue.description).join(', ')}
            </div>
          );
        }
        return row;
      });
      return rows;
    }
    return [];
  }

  private getTabs() {
    return [
      {
        active: this.state.selectedTab === 'issues',
        key: 'issues',
        subTitle: (
          <span>
            <i className="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
            {' Issues'}
          </span>
        ),
        title: this.friendlyCounts('issues')
      },
      {
        active: this.state.selectedTab === 'new',
        key: 'new',
        subTitle: 'New Shipments',
        title: this.friendlyCounts('new')
      },
      {
        active: this.state.selectedTab === 'inProgress',
        key: 'inProgress',
        subTitle: 'In-Progress',
        title: this.friendlyCounts('inProgress')
      },
      {
        active: this.state.selectedTab === 'cancelled',
        key: 'cancelled',
        pullRight: true,
        subTitle: 'Cancelled',
        title: <i className="fa fa-times-circle" aria-hidden="true"></i>
      },
      {
        active: this.state.selectedTab === 'completed',
        key: 'completed',
        pullRight: true,
        subTitle: 'Completed',
        title: <i className="fa fa-check-circle" aria-hidden="true"></i>
      }
    ];
  }

  private friendlyCounts(key: string): string {
    // eslint-disable-next-line no-prototype-builtins
    if (this.state.counts.hasOwnProperty(key)) {
      return friendlyCount(this.state.counts[key]);
    } else {
      return '0';
    }
  }

  private getFilters() {
    const filters = [
      {
        displayName: 'Reservation ID',
        key: 'reservationId',
        options: this.state.reservations,
        type: FilterType.DataList,
        value: this.getFilterValue('reservationId')
      },
      {
        displayName: 'FLEXE ID',
        key: 'ids',
        type: FilterType.Number,
        value: this.getFilterValue('ids'),
        allowMultiple: true
      },
      {
        displayName: 'Order #',
        key: 'purchase_order',
        type: FilterType.String,
        value: this.getFilterValue('purchase_order')
      },
      {
        displayName: 'Tracking Number',
        key: 'tracking_numbers',
        type: FilterType.String,
        value: this.getFilterValue('tracking_numbers')
      },
      {
        displayName: 'Carrier',
        key: 'carrier',
        type: FilterType.String,
        value: this.getFilterValue('carrier')
      },
      {
        displayName: 'Carrier Service Type',
        key: 'carrier_service_type',
        type: FilterType.String,
        value: this.getFilterValue('carrer_service_type')
      },
      {
        displayName: 'Ship By (Exact)',
        key: 'ship_on',
        type: FilterType.Date,
        value: this.getFilterValue('ship_on')
      },
      {
        displayName: 'Ship By Starting',
        key: 'ship_on_start',
        type: FilterType.Date,
        value: this.getFilterValue('ship_on_start')
      },
      {
        displayName: 'Ship By Ending',
        key: 'ship_on_end',
        type: FilterType.Date,
        value: this.getFilterValue('ship_on_end')
      },
      {
        displayName: 'Name',
        key: 'ship_to',
        type: FilterType.String,
        value: this.getFilterValue('ship_to')
      },
      {
        displayName: 'Address',
        key: 'address_only',
        type: FilterType.String,
        value: this.getFilterValue('address_only')
      },
      {
        displayName: 'SKU',
        key: 'sku',
        type: FilterType.String,
        value: this.getFilterValue('sku')
      }
    ];

    if (this.state.distributionByLotEnabled === true) {
      filters.push({
        displayName: 'Lot Code',
        key: 'lot_code',
        type: FilterType.String,
        value: this.getFilterValue('lot_code')
      });
      filters.push({
        displayName: 'Expiration',
        key: 'expiration_date',
        type: FilterType.Date,
        allowMultiple: false,
        value: this.getFilterValue('expiration_date')
      });
    }

    return filters;
  }

  private getFilterValue(key: string) {
    // eslint-disable-next-line no-prototype-builtins
    if (this.state.filters.hasOwnProperty(key)) {
      return this.state.filters[key];
    }
    return null;
  }
}

export default Fulfillment;
