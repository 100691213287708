import * as React from 'react';
import RetailFulfillmentService, {
  CompleteOrderRequest,
  RetailOrderDetails,
  UpdateTrailerInfoRequest
} from '../../shared/services/RetailFulfillmentService';
import {ApiResponse} from '../../shared/CommonInterfaces';
import MobileLocationsCompletionModal from './MobileLocationsCompletionModal';
import LpnCompletionModal from './LpnCompletionModal';

interface UpdateShipmentProps {
  orderDetails: RetailOrderDetails;
  retailFulfillmentService: RetailFulfillmentService;
  isMobileLocationEnabled: boolean;
  isLpnEnabled: boolean;
  completeShipmentUponUpdate: boolean;
  blockOverShipment: boolean;
  palletLevelSsccLabelsEnabled: boolean;
  authenticityToken: string;
  onSuccess: (successMessage: string) => void;
  onError: (errorMessage: string) => void;
}

export const UpdateShipment: React.FC<UpdateShipmentProps> = (props) => {
  const [trailerNumber, setTrailerNumber] = React.useState('');
  const [proNumber, setProNumber] = React.useState('');
  const [sealNumber, setSealNumber] = React.useState('');
  const [showCompletionModal, setShowCompletionModal] = React.useState(false);
  const [fieldValidationError, setFieldValidationError] = React.useState(null);

  React.useEffect(() => {
    setTrailerNumber(props.orderDetails.trailerNumber || '');
    setProNumber(props.orderDetails.proNumber || '');
    setSealNumber(props.orderDetails.sealNumber || '');
  }, [props.orderDetails]);

  const updateShipmentHandler = () => {
    if (!checkUpdateFieldValidity()) {
      return;
    }

    updateTrailerInfo();
  };

  const checkUpdateFieldValidity = (): boolean => {
    // TODO (SA-4323) - update this and the API to account for seal number too
    if (trailerNumber.trim().length === 0 && proNumber.trim().length === 0) {
      setFieldValidationError('One of "Trailer Number" or "PRO Number" must be set before updating');
      return false;
    }

    setFieldValidationError(null);
    return true;
  };

  async function updateTrailerInfo() {
    const request: UpdateTrailerInfoRequest = {
      trailerNumber,
      proNumber,
      sealNumber
    };

    const response: ApiResponse<any> = await props.retailFulfillmentService.updateTrailerInfo(
      props.orderDetails.id,
      request
    );

    if (response.errors) {
      const errorMessage: string = response.errors[0].detail;
      props.onError(errorMessage);
      return;
    }

    props.onSuccess('Successfully updated shipment information!');
  }

  const completeShipmentHandler = () => {
    if (!checkCompletionFieldValidity()) {
      return;
    }

    if (props.isMobileLocationEnabled || props.isLpnEnabled) {
      setShowCompletionModal(true);
    } else {
      // Automove reservations don't require the location ID, just complete it
      completeShipment();
    }
  };

  const checkCompletionFieldValidity = (): boolean => {
    // TODO (SA-4323) - update this and the API to account for seal number too
    if (trailerNumber.trim().length === 0 || proNumber.trim().length === 0) {
      setFieldValidationError('Both "Trailer Number" and "PRO Number" must be set before completing');
      return false;
    }

    setFieldValidationError(null);
    return true;
  };

  async function completeShipment(outboundLocationId?: number, lpnsToBeOutbounded?: string) {
    const request: CompleteOrderRequest = {
      trailerNumber,
      proNumber,
      sealNumber
    };

    if (outboundLocationId) {
      request.outboundLocationId = outboundLocationId.toString();
    }

    if (lpnsToBeOutbounded) {
      request.lpnsToBeOutbounded = lpnsToBeOutbounded;
    }

    const response: ApiResponse<any> = await props.retailFulfillmentService.completeOrder(
      props.orderDetails.id,
      request
    );

    if (response.errors) {
      const errorMessage: string = response.errors[0].detail;
      props.onError(errorMessage);
      return;
    }

    props.onSuccess('Completed shipment!');
  }

  const headerText = () => {
    if (props.completeShipmentUponUpdate) {
      return 'Finalize';
    } else {
      return 'Update Shipment';
    }
  };

  const completeButtonText = () => {
    if (props.isLpnEnabled) {
      return <React.Fragment>Review and Close Order</React.Fragment>;
    } else {
      return (
        <React.Fragment>
          <i className="fa fa-check"></i>
          &nbsp;Complete Shipment
        </React.Fragment>
      );
    }
  };

  const submitButton = () => {
    if (props.completeShipmentUponUpdate) {
      if (props.palletLevelSsccLabelsEnabled && props.orderDetails.ssccLabelsStillGenerating) {
        return (
          <span className="labels-still-generating-warning-message">
            Please wait until SSCC labels are finished generating to complete the shipment.
          </span>
        );
      }

      return (
        <button id="complete-shipment" data-testid="complete-shipment" onClick={() => completeShipmentHandler()}>
          {completeButtonText()}
        </button>
      );
    } else {
      return (
        <button
          id="update-shipment"
          data-testid="update-shipment"
          className="no-cta"
          onClick={() => updateShipmentHandler()}
        >
          <i className="fa fa-check"></i>
          &nbsp;Update Shipment
        </button>
      );
    }
  };

  const onConfirmHandler = (outboundLocationId: number, lpnsToBeOutbounded?: string) => {
    completeShipment(outboundLocationId, lpnsToBeOutbounded);
  };

  const modal = () => {
    if (props.isLpnEnabled) {
      return (
        <LpnCompletionModal
          orderDetails={props.orderDetails}
          displayOnly={false}
          authenticityToken={props.authenticityToken}
          blockOverShipment={props.blockOverShipment}
          showModal={showCompletionModal}
          toggleModal={() => setShowCompletionModal(!showCompletionModal)}
          onConfirm={onConfirmHandler}
        />
      );
    } else {
      return (
        <MobileLocationsCompletionModal
          orderDetails={props.orderDetails}
          authenticityToken={props.authenticityToken}
          showModal={showCompletionModal}
          toggleModal={() => setShowCompletionModal(!showCompletionModal)}
          onConfirm={onConfirmHandler}
        />
      );
    }
  };

  return (
    <div className="col-sm-12 col-md-8">
      <div className="row step-wrap">
        <h4 className="col-sm-12">{headerText()}</h4>

        <div className="col-sm-12 col-md-4 space-below">
          <label>
            Trailer Number
            <br />
            <input
              type="text"
              placeholder="Trailer #...."
              name="trailer_number"
              value={trailerNumber}
              onChange={(event) => setTrailerNumber(event.target.value)}
            />
          </label>
        </div>
        <div className="col-sm-12 col-md-4 space-below">
          <label>
            PRO Number
            <br />
            <input
              type="text"
              placeholder="PRO #...."
              name="pro_number"
              value={proNumber}
              onChange={(event) => setProNumber(event.target.value)}
            />
          </label>
        </div>
        <div className="col-sm-12 col-md-4 space-below">
          <label>
            Seal Number
            <br />
            <input
              type="text"
              placeholder="Seal #...."
              name="seal_number"
              value={sealNumber}
              onChange={(event) => setSealNumber(event.target.value)}
            />
          </label>
        </div>

        <div className="col-sm-12 space-below">
          {submitButton()}

          {fieldValidationError && (
            <span id="update-trailer-info-field-validation-error" className="validation-error">
              {fieldValidationError}
            </span>
          )}
        </div>

        {modal()}
      </div>
    </div>
  );
};

export default UpdateShipment;
