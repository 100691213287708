import {ShipmentStatus, SortByColumns} from './ShipmentInterfaces';
import {SortableColumnNames} from './shipment-list/ShipmentTableHeader';

const ShipmentStatusMap = new Map<string, ShipmentStatus>([
  ['new', {className: 'not-started', text: 'Not Started'}],
  ['confirmed', {className: 'in-progress', text: 'In Progress'}],
  ['completed', {className: 'shipped', text: 'Shipped'}],
  ['cancelled', {className: 'cancelled', text: 'Cancelled'}],
  ['attention_required', {className: 'attention-required', text: 'Attention Required'}],
  ['voided', {className: 'voided', text: 'Voided'}],
  ['packed', {className: 'packed', text: 'Packed'}],
  ['failed', {className: 'failed', text: 'Failed'}]
]);

export const SortableColumnNameToSortByColumnMap = new Map<SortableColumnNames, SortByColumns>([
  ['shipmentId', SortByColumns.ShipmentId],
  ['po', SortByColumns.PurchaseOrder]
]);

// should be 1-1 with Fulfillment::Models::FulfillmentOrder terminal states
export const TerminalShipmentStates = ['completed', 'cancelled', 'voided', 'failed'];

// should be 1-1 with Fulfillment::Models::FulfillmentOrder in progress states
export const InProgressShipmentStates = ['new', 'confirmed', 'attention_required', 'packed'];

export default ShipmentStatusMap;
