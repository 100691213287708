import * as React from 'react';

interface Props {
  content: React.ReactNode;
}

const BasicTableCell: React.FC<Props> = (props: Props) => {
  return <td>{props.content}</td>;
};

export default BasicTableCell;
