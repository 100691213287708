import * as React from 'react';

import {LegacyModal} from '@flexe/ui-components';
import {CarrierBillingAccount, CarrierBillingAccountField} from '../SharedInterfaces';

interface Props {
  show: boolean;
  hideModal: () => void;
  handleSubmit: () => void;
  handleAccountFieldChange: (fieldName: CarrierBillingAccountField, newValue: string) => void;
  account: CarrierBillingAccount;
  cta: string;
  companyChannelsInfo: {[companyChannelId: number]: string};
  showCompanyChannelsInfo: boolean;
}

const CarrierBillingAccountDialog: React.SFC<Props> = (props) => {
  const {account} = props;
  const handleAccountFieldChange = (fieldName: CarrierBillingAccountField, e) =>
    props.handleAccountFieldChange(fieldName, e.target.value);

  const footer = (
    <div>
      <a className="carrier-billing-account__cancel" onClick={props.hideModal}>
        Cancel
      </a>
      <button type="button" className="carrier-billing-account__submit btn btn-primary" onClick={props.handleSubmit}>
        {props.cta} Billing Account
      </button>
    </div>
  );

  const formattedCompanyChannelOptions = Object.keys(props.companyChannelsInfo).map((companyChannelId) => {
    return (
      <option key={companyChannelId} value={companyChannelId}>
        {companyChannelId} - {props.companyChannelsInfo[companyChannelId]}
      </option>
    );
  });

  if (!account) {
    return null;
  }

  return (
    <LegacyModal
      id="carrier_billing_account_modal"
      title={`${props.cta} 3rd Party Carrier Billing Account`}
      show={props.show}
      size="small"
      transitionSpeed="fast"
      toggleModal={props.hideModal}
      footer={footer}
    >
      <div>
        <p>Note: a standard carrier account is still needed to successfully purchase labels</p>
        <div className="row">
          <label className="col-md-12 space-below">
            Display Name
            <br />
            <input
              type="text"
              className="carrier-billing-account__friendlyName-input"
              onChange={(e) => handleAccountFieldChange(CarrierBillingAccountField.friendlyName, e)}
              value={account.friendlyName}
            />
            <span className="required-indicator">&#42; Required</span>
          </label>

          <label className="col-md-12 space-below">
            Carrier
            <br />
            <select
              className="carrier-billing-account__carrier-input"
              onChange={(e) => handleAccountFieldChange(CarrierBillingAccountField.carrier, e)}
              value={account.carrier}
            >
              <option value="">Select a carrier...</option>
              <option value="ups">UPS</option>
              <option value="fedex">FedEx</option>
              <option value="ontrac">OnTrac</option>
            </select>
            <span className="required-indicator">&#42; Required</span>
          </label>

          <label className="col-md-12 space-below">
            Country
            <br />
            <select
              className="carrier-billing-account__country-input"
              onChange={(e) => handleAccountFieldChange(CarrierBillingAccountField.country, e)}
              value={account.country}
            >
              <option value="">Select a country...</option>
              <option value="US">US</option>
              <option value="CA">CA</option>
            </select>
            <span className="required-indicator">&#42; Required</span>
          </label>

          <label className="col-md-12 space-below">
            Account Number
            <br />
            <input
              type="text"
              className="carrier-billing-account__accountId-input"
              onChange={(e) => handleAccountFieldChange(CarrierBillingAccountField.accountId, e)}
              value={account.accountId}
            />
            <span className="required-indicator">&#42; Required</span>
          </label>

          <label className="col-md-12 space-below">
            Postal Code
            <br />
            <input
              type="text"
              className="carrier-billing-account__postcode-input"
              onChange={(e) => handleAccountFieldChange(CarrierBillingAccountField.postcode, e)}
              value={account.postcode}
            />
            <span className="required-indicator">&#42; Required</span>
          </label>
          {props.showCompanyChannelsInfo && (
            <label className="col-md-12 space-below">
              Company Channel
              <br />
              <select
                className="carrier-billing-account__companyChannel-input"
                onChange={(e) => handleAccountFieldChange(CarrierBillingAccountField.companyChannelId, e)}
                value={account.companyChannelId}
              >
                <option key={'NONE'} value={''}>
                  NONE
                </option>
                {formattedCompanyChannelOptions}
              </select>
            </label>
          )}
        </div>
      </div>
    </LegacyModal>
  );
};

export default CarrierBillingAccountDialog;
