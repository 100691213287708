import * as React from 'react';
import {useState} from 'react';

import DropDown, {DropDownColor, DropDownOption, DropDownType} from '../../../shared/DropDown';
interface ShipByProps {
  onStartChange: (date: Date) => void;
  onEndChange: (date: Date) => void;
}

const ShipBy: React.FC<ShipByProps> = (props) => {
  const options = [
    {name: 'Anytime', value: 'anytime'},
    {name: 'Today', value: 'today'},
    {name: 'Today and Tomorrow', value: 'todayAndTomorrow'},
    {name: 'Today + 2 Days', value: 'todayPlusTwo'},
    {name: 'Today + 3 Days', value: 'todayPlusThree'},
    {name: 'Past Due', value: 'pastDue'}
  ];
  const [selectedOption, setSelectedOption] = useState<DropDownOption>(options[0]);

  const today = new Date();
  const tOffsetDays = (offset: number) => {
    const d = new Date(today);
    d.setDate(d.getDate() + offset);
    return d;
  };
  const yesterday = tOffsetDays(-1);
  const tomorrow = tOffsetDays(1);
  const tPlusTwoDays = tOffsetDays(2);
  const tPlusThreeDays = tOffsetDays(3);

  const onSelect = (option: DropDownOption) => {
    switch (option.value) {
      case 'anytime':
        setSelectedOption(option);
        props.onStartChange(null);
        props.onEndChange(null);
        break;

      case 'pastDue':
        setSelectedOption(option);
        props.onStartChange(null);
        props.onEndChange(yesterday);
        break;

      case 'today':
        setSelectedOption(option);
        props.onStartChange(today);
        props.onEndChange(today);
        break;

      case 'todayAndTomorrow':
        setSelectedOption(option);
        props.onStartChange(today);
        props.onEndChange(tomorrow);
        break;

      case 'todayPlusTwo':
        setSelectedOption(option);
        props.onStartChange(today);
        props.onEndChange(tPlusTwoDays);
        break;

      case 'todayPlusThree':
        setSelectedOption(option);
        props.onStartChange(today);
        props.onEndChange(tPlusThreeDays);
        break;

      default:
        setSelectedOption(option);
        break;
    }
  };

  return (
    <div>
      <DropDown
        options={options}
        selected={selectedOption}
        color={DropDownColor.white}
        type={DropDownType.secondary}
        prefixText="Ship By:  "
        onSelect={onSelect}
      />
    </div>
  );
};

export default ShipBy;
