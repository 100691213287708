import * as React from 'react';
import BatchWavingService from '../../../shared/services/BatchWavingService';
import {WaveBatch} from '../WaveBatchInterfaces';
import {Wave} from '../WaveInterfaces';

export interface BatchDetailsContext {
  authenticityToken: string;
  batchWavingService: BatchWavingService;
  batch: WaveBatch;
  wave: Wave;
}

export const BatchDetailsContext = React.createContext<BatchDetailsContext>({
  authenticityToken: '',
  batchWavingService: null,
  batch: null,
  wave: null
});
