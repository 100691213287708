import * as React from 'react';
import {useContext} from 'react';
import {formatDate} from '../../../shared/utilities/DataFormatting';
import {WaveBatch} from '../WaveBatchInterfaces';
import {BatchDetailsContext} from './BatchDetailsContext';

const BatchDetailsHeader: React.FC = () => {
  const {batch, wave} = useContext(BatchDetailsContext);
  const batchDate = batch.createdAt ? formatDate(new Date(batch.createdAt), ' MMM D, h:mm a') : '--';

  return (
    <>
      <header>
        <h1>Batch #{batch.id}</h1>
      </header>
      {wave.reservation && (
        <div className="reservation-info">
          <span>
            {`#${wave.reservation.id} - ${wave.reservation.shipper_name}`} &raquo; {wave.reservation.warehouse_name}
          </span>
        </div>
      )}
      <div className="batch-info">
        <div className="batch-info-section">
          <div className="info-pair">
            <span className="title">Created at:</span>
            <span className="value">{batchDate}</span>
          </div>
          <div className="info-pair">
            <span className="title">Put Wall:</span>
            <span className="value">{wave.waveAttributes.shouldRebin === true ? 'Yes' : 'No'}</span>
          </div>
        </div>
        <div className="vertical-spacer"></div>
        <div className="batch-info-section">
          <div className="info-pair">
            <span className="title">{`Pick MHE:`}</span>
            <span className="value">
              <ActivePickCartsDisplay batch={batch} />
            </span>
          </div>
          <div className="info-pair">
            <span className="title">Active Picker:</span>
            <span className="value">{batch?.currentPicker ? batch.currentPicker : '--'}</span>
          </div>
        </div>
        <div className="vertical-spacer"></div>
        <div className="batch-info-section">
          <div className="info-pair">
            <span className="title">Last Picked Location:</span>
            <span className="value">
              <LastLocationDisplay batch={batch} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const ActivePickCartsDisplay = ({batch}: {batch: WaveBatch}) => {
  const pickCartsLength = batch.currentMhe?.length ?? 0;

  if (pickCartsLength === 0) {
    return <>--</>;
  }

  if (pickCartsLength === 1) {
    return <>{batch.currentMhe[0].label}</>;
  }

  return (
    <>
      {batch.currentMhe.map((cart, index) => (
        <React.Fragment key={cart.label}>
          <span>{cart.label}</span>
          {index < pickCartsLength - 1 ? ', ' : ''}
        </React.Fragment>
      ))}
    </>
  );
};

const LastLocationDisplay = ({batch}: {batch: WaveBatch}) => {
  const lastLocationLabel = batch?.lastLocation?.label;
  const lastLocationId = batch?.lastLocation?.id;

  if (!lastLocationLabel || !lastLocationId) {
    return <span>--</span>;
  }

  return <a href={`/wh/locations/${lastLocationId}`}>{lastLocationLabel}</a>;
};

export default BatchDetailsHeader;
