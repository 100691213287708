import {get} from 'lodash';
import InternalAPIServiceV2 from '../../shared/services/InternalAPIServiceV2';

export interface InboundSearch {
  counts: InboundSearchCounts;
  shipments: InboundSearchShipment[];
}

export interface InboundSearchShipment {
  id: number;
  details: string;
  is_crossdock: boolean;
  move_date: number;
  quantity: number;
  shipment_type: string;
  state: string;
  url: string;
}

interface InboundSearchCounts {
  altered: number;
  completed: number;
  confirmed: number;
  unconfirmed: number;
}

class InboundServiceV2 extends InternalAPIServiceV2 {
  constructor() {
    super('/s/dropoffs');
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public async search(filters: {}): Promise<InboundSearch> {
    const query = {
      'filters[id]': get(filters, 'id', null),
      'filters[state]': get(filters, 'state', null),
      'filters[reservation]': get(filters, 'reservation', null),
      'filters[details]': get(filters, 'details', null),
      'sort[field]': 'id',
      'sort[desc]': true
    };

    return await this.makeGetRequest(`${this.baseUrl}/search.json`, query);
  }
}

export default InboundServiceV2;
