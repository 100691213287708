import * as React from 'react';
import {uniq} from 'lodash';
import {renderItemLink, renderShipment} from '../../../../libs/helpers';
import {BatchErrorDetailProps} from './BatchErrorDetailProps';

export const ConflictingSkuRequirementsForShipment: React.FC<BatchErrorDetailProps> = ({alert}) => {
  const shipments = uniq(alert.shipmentIds);

  const maxNumShipmentDisplayed = 5;
  const shipmentsToDisplay = Math.min(shipments.length, maxNumShipmentDisplayed);
  const displayedShipments = shipments.slice(0, shipmentsToDisplay);
  const [shipmentsStr] = React.useState(`Shipment${displayedShipments.length > 1 ? 's' : ''} `);
  const numShipmentsTruncated = shipments.length - displayedShipments.length;
  const [truncatedStr] = React.useState(
    numShipmentsTruncated > 0 ? ` and ${numShipmentsTruncated} other${numShipmentsTruncated !== 1 ? 's' : ''}` : ''
  );

  return (
    <React.Fragment>
      {shipmentsStr}
      {displayedShipments.map((shipmentId, i) => (
        <b>
          {renderShipment(shipmentId, false, false, true, true)}
          {i < displayedShipments.length - 1 ? ', ' : ''}
        </b>
      ))}
      <b>{`${truncatedStr}`}</b>
      {" can't be waved because there are conflicting SKU requirements for "}
      <b>{renderItemLink(alert.itemId, alert.sku, false)}</b>
      {'.'}
    </React.Fragment>
  );
};
