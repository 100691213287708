import * as React from 'react';
import {useEffect} from 'react';
import {ExpectedInboundPackaging} from '../../../shared/CommonInterfaces';

interface Props {
  lpnReceiveOnly?: boolean;
  onActualInboundPackagingChange(actualInboundPackaging);
}

enum IconFill {
  dark = '#373B48',
  white = '#FFF'
}

const ActualInboundPackaging: React.FC<Props> = (props) => {
  const InboundPackagingMap = new Map<string, number>([
    [ExpectedInboundPackaging.FloorLoaded, 1],
    [ExpectedInboundPackaging.Palletized, 2],
    [ExpectedInboundPackaging.ParcelReceive, 3]
  ]);

  //state
  const [actualInboundPackaging, setActualInboundPackaging] = React.useState<string>('');

  useEffect(() => {
    const inboundPackagingInt = InboundPackagingMap.get(actualInboundPackaging);
    if (inboundPackagingInt) {
      props.onActualInboundPackagingChange(inboundPackagingInt);
    }
  }, [actualInboundPackaging]);

  return (
    <div className="actual-inbound-packaging">
      <PalletizedOption
        active={actualInboundPackaging === ExpectedInboundPackaging.Palletized}
        clickCallback={() => setActualInboundPackaging(ExpectedInboundPackaging.Palletized)}
      />
      <div className="separator">OR</div>
      <FloorLoadedOption
        active={actualInboundPackaging === ExpectedInboundPackaging.FloorLoaded}
        clickCallback={() => setActualInboundPackaging(ExpectedInboundPackaging.FloorLoaded)}
      />
      {props.lpnReceiveOnly && (
        <React.Fragment>
          <div className="separator">OR</div>
          <ParcelReceiveOption
            active={actualInboundPackaging === ExpectedInboundPackaging.ParcelReceive}
            clickCallback={() => setActualInboundPackaging(ExpectedInboundPackaging.ParcelReceive)}
          />
        </React.Fragment>
      )}
    </div>
  );
};

function PalletizedOption(props) {
  return (
    <a
      type="button"
      className={(props.active ? 'cta' : '') + ' btn btn-actual-inbound-packaging'}
      onClick={props.clickCallback}
    >
      <PalletizedIcon fill={props.active ? IconFill.white : IconFill.dark} />
      <div className="btn-text pull-right">
        Palletized
        <br />
        <small>Pallets are pre-assembled and ready for storage.</small>
      </div>
    </a>
  );
}

function FloorLoadedOption(props) {
  return (
    <a
      type="button"
      className={(props.active ? 'cta' : '') + ' btn btn-actual-inbound-packaging '}
      onClick={props.clickCallback}
    >
      <FloorLoadedIcon fill={props.active ? IconFill.white : IconFill.dark} />
      <div className="btn-text pull-right">
        Floor Loaded
        <br />
        <small>Pallets must be assembled prior to moving to storage.</small>
      </div>
    </a>
  );
}

function ParcelReceiveOption(props) {
  return (
    <a
      type="button"
      className={(props.active ? 'cta' : '') + ' btn btn-actual-inbound-packaging '}
      onClick={props.clickCallback}
    >
      <ParcelReceiveIcon fill={props.active ? IconFill.white : IconFill.dark} />
      <div className="btn-text pull-right">
        Parcel Receive
        <br />
        <small>Goods will arrive via parcel service.</small>
      </div>
    </a>
  );
}

function PalletizedIcon(props) {
  /* eslint-disable max-len */
  return (
    <div className="palletized pull-left">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <path
          fill={props.fill}
          fillRule="evenodd"
          d="M19.625 6.25L17.25 0h4c.25 0 .625.125.875.25l7.125 6h-9.625zM20 8.75h10v20c0 .75-.5 1.25-1.25 1.25H1.25C.5 30 0 29.5 0 28.75v-20h10v8.75h10V8.75zm-9.625-2.5H.75L8 .25c.125-.125.5-.25.75-.25h4l-2.375 6.25z"
        />
      </svg>
    </div>
  );
  /* eslint-enable max-len */
}

function FloorLoadedIcon(props) {
  /* eslint-disable max-len */
  return (
    <div className="floor-loaded pull-left">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28">
        <path
          fill={props.fill}
          fillRule="evenodd"
          d="M15 5.225L8.932 2.571 14.727.05c.137-.067.341-.067.546 0l5.795 2.521L15 5.225zm.682 1.128L21.818 3.7v7.564c0 .265-.136.464-.409.597l-5.727 2.455V6.353zm-1.364 0v7.962L8.591 11.86c-.273-.066-.41-.332-.41-.597V3.699l6.137 2.654zm8.864 12.557l-6.068-2.654 5.795-2.521c.136-.067.341-.067.546 0l5.795 2.52-6.068 2.655zm.682 1.128L30 17.384v7.564c0 .265-.136.464-.41.597L23.865 28v-7.962zm-1.364 0V28l-5.727-2.455c-.273-.066-.41-.332-.41-.597v-7.564l6.137 2.654zM6.818 18.91L.75 16.256l5.795-2.521c.137-.067.341-.067.546 0l5.795 2.52-6.068 2.655zm.682 1.128l6.136-2.654v7.564c0 .265-.136.464-.409.597L7.5 28v-7.962zm-1.364 0V28L.41 25.545C.136 25.479 0 25.213 0 24.948v-7.564l6.136 2.654z"
        />
      </svg>
    </div>
  );
  /* eslint-enable max-len */
}

function ParcelReceiveIcon(props) {
  /* eslint-disable max-len */
  return (
    <div className="parcel-receive pull-left">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28">
        <path d="M6 12H3" stroke="#46536C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
        <path d="M6 17H5" stroke="#46536C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
        <path
          fill-opacity="0"
          d="M17.829 18H23V12L19 10L18 6H10V18H12.171"
          stroke="#46536C"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path d="M1 7H6" stroke="#46536C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
        <path
          fill-opacity="0"
          d="M1 2H10V6"
          stroke="#46536C"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          fill-opacity="0"
          d="M15 22C16.6569 22 18 20.6569 18 19C18 17.3431 16.6569 16 15 16C13.3431 16 12 17.3431 12 19C12 20.6569 13.3431 22 15 22Z"
          stroke="#46536C"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path d="M14 9V11H16" stroke="#46536C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
      </svg>
    </div>
  );
  /* eslint-enable max-len */
}

export default ActualInboundPackaging;
