import * as React from 'react';
import {get} from 'lodash';
import LocationsService, {LocationContent, LocationContents} from '../../locations/LocationsService';
import {LocationCategoryStrings} from '../../locations/LocationsInterfaces';
import TypeAhead, {TypeAheadOption} from '../../shared/TypeAhead';

interface OutboundStagingTypeAheadState {
  locationQuery: string;
  outboundLocations: LocationContents[];
  typeAheadOptions: TypeAheadOption[];
  errors?: string;
}

interface OutboundStagingTypeAheadProps {
  authenticityToken: string;
  warehouseId: number;
  locationsService: LocationsService;
  onSelectCallback: (locationId: string, locationContents: LocationContent[]) => void;
  currentLocationLabel?: string;
  disable?: boolean;
}

class OutboundStagingTypeAhead extends React.Component<OutboundStagingTypeAheadProps, OutboundStagingTypeAheadState> {
  constructor(props) {
    super(props);

    let locationQuery: string = '';
    if (this.props.currentLocationLabel != null) {
      locationQuery = this.props.currentLocationLabel;
    }

    this.state = {
      locationQuery,
      outboundLocations: [],
      typeAheadOptions: []
    };
  }

  public render() {
    return (
      <div>
        {this.state.errors && <div className="alert alert-danger">{this.state.errors}</div>}
        <div className="space-below">
          <TypeAhead
            name="outbound-staging-location"
            placeholder="Type to select outbound staging location..."
            disabled={this.props.disable}
            value={this.state.locationQuery}
            options={this.state.typeAheadOptions}
            onRequestOptions={this.handleTypeAheadQuery}
            onSelect={this.handleTypeAheadSelect}
          />
        </div>
      </div>
    );
  }

  private handleTypeAheadQuery = async (query: string) => {
    this.setState({locationQuery: query});
    if (query.length >= 2) {
      try {
        const response = await this.props.locationsService.getLocations(this.props.warehouseId, 10, null, {
          locationCategory: [LocationCategoryStrings.CATEGORY_OUTBOUND_STAGING],
          locationLabel: [query]
        });
        const outboundLocations: LocationContents[] = response.locations;
        const typeAheadOptions: TypeAheadOption[] = response.locations.map((loc) => {
          return {value: loc.id.toString(), displayName: loc.label};
        });
        this.setState({
          outboundLocations,
          typeAheadOptions
        });
      } catch {
        this.setState({
          outboundLocations: [],
          typeAheadOptions: [],
          errors: 'There was error retrieving outbound staging locations'
        });
      }
    }
  };

  private handleTypeAheadSelect = (locationId: string) => {
    let availableLocations: LocationContents[] = [];
    let locationContents: LocationContent[] = null;
    let locationQuery = this.state.locationQuery;

    if (locationId) {
      availableLocations = this.state.outboundLocations.filter((locationContent) => {
        return locationContent.id === Number(locationId);
      });

      locationQuery = availableLocations.length > 0 ? get(availableLocations[0], 'label') : this.state.locationQuery;
      locationContents = availableLocations.length > 0 ? get(availableLocations[0], 'contents', []) : [];
    }

    this.props.onSelectCallback(locationId, locationContents);

    this.setState({
      locationQuery,
      typeAheadOptions: []
    });
  };
}

export default OutboundStagingTypeAhead;
