import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import FlexeContext, {FlexeContextValues} from '../contexts/FlexeContext';
import ItemMasterContext, {ItemMasterContextValues} from '../contexts/ItemMasterContext';
import ItemMasterService from './ItemMasterService';
import ItemMasterIndex from './shared/ItemMasterIndex';

interface Props {
  authenticityToken: string;
  path: string;
}

function ItemMasterRouter(props: Props) {
  const itemMasterService = new ItemMasterService(props.authenticityToken);
  const isShipper = props.path === 's';

  const flexeContextValue: FlexeContextValues = {
    authenticityToken: props.authenticityToken
  };

  const itemMasterContextValues: ItemMasterContextValues = {
    itemMasterService,
    isShipper
  };

  return (
    <FlexeContext.Provider value={flexeContextValue}>
      <ItemMasterContext.Provider value={itemMasterContextValues}>
        <Router>
          <Switch>
            <Route
              path="/(s|wh)/inventories"
              exact
              strict
              render={(routeProps) => {
                return (
                  <ItemMasterIndex
                    {...routeProps}
                    // TODO: decide between props and context
                    placeholder={''}
                  />
                );
              }}
            />
          </Switch>
        </Router>
      </ItemMasterContext.Provider>
    </FlexeContext.Provider>
  );
}

export default ItemMasterRouter;
