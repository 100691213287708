import InternalAPIService from '../shared/services/InternalAPIService';
import {PageData, User} from './userManagementInterfaces';

export interface Result<T> {
  data: T;
  errors: Error[];
}

interface Error {
  id: string;
  status: string;
  title: string;
  detail: string;
}

interface CreateUserParams {
  firstName: string;
  lastName: string;
  email: string;
}

interface CreateUserResult {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

class UserManagementService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/v2/user_management');
  }

  public async updateCompanyUserRoleAsync(userId: number, role: string, enableRole: boolean): Promise<Result<never>> {
    return await this.makePatchRequest(`${this.baseUrl}/users/${userId}/update_user_roles`, {
      resource: {
        type: 'Company'
      },
      role,
      value: enableRole
    });
  }

  public async updateWarehouseUserRoleAsync(
    userId: number,
    warehouseId: number,
    role: string,
    enableRole: boolean
  ): Promise<Result<never>> {
    return await this.makePatchRequest(`${this.baseUrl}/users/${userId}/update_user_roles`, {
      resource: {
        type: 'Warehouse',
        id: warehouseId
      },
      role,
      value: enableRole
    });
  }

  public async updateReservationUserRoleAsync(
    userId: number,
    reservationId: number,
    role: string,
    isEnabled: boolean
  ): Promise<Result<never>> {
    return await this.makePatchRequest(`${this.baseUrl}/users/${userId}/update_user_roles`, {
      resource: {
        type: 'Reservation',
        id: reservationId
      },
      role,
      value: isEnabled
    });
  }

  public async reactivateUserAsync(userId: number): Promise<Result<Partial<User>>> {
    return await this.makePatchRequest(`${this.baseUrl}/users/${userId}`, {isActive: true});
  }

  public async createUserAsync(params: CreateUserParams): Promise<Result<CreateUserResult>> {
    return await this.makePostRequest(`${this.baseUrl}/users`, params);
  }

  public async deactivateUserAsync(userId: number): Promise<Result<never>> {
    return await this.makePatchRequest(`${this.baseUrl}/users/${userId}`, {isActive: false});
  }

  public async removeMobileUserFromWarehouseAsync(userId: number, warehouseId: number): Promise<Result<never>> {
    return await this.makePatchRequest(`${this.baseUrl}/mobile_users/${userId}/remove_from_warehouse`, {
      userId,
      warehouseId
    });
  }

  public async deleteMobileUserAsync(userId: number): Promise<Result<never>> {
    return await this.makeDeleteRequest(`${this.baseUrl}/mobile_users/${userId}`);
  }

  public async getPageDataAsync(): Promise<Result<PageData>> {
    const response = await this.makeGetRequest(`${this.baseUrl}`);

    return {
      data: response.data,
      errors: response.errors
    };
  }
}

export default UserManagementService;
