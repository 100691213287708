import {ApiResponse} from '../shared/CommonInterfaces';
import InternalAPIService from '../shared/services/InternalAPIService';

export interface UpsertInventoryRequest {
  file: File;
  reservationId?: string;
}

export interface UpsertInventoryResponse {
  // the strings are SKUs
  // the numbers are inventory IDs
  succeeded: {[key: string]: number};
}

class ItemMasterService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '');
  }

  public async upsertInventoryAsync(params: UpsertInventoryRequest): Promise<ApiResponse<UpsertInventoryResponse>> {
    const rawResponse = await this.makePostRequest('/api/v2/inventories', params, 'multipart');

    const result = {
      errors: null,
      data: {succeeded: {}},
      links: rawResponse.links,
      meta: rawResponse.meta
    };

    // mine for UI-friendly details in the returned errors
    if (rawResponse.errors && rawResponse.errors.length > 0) {
      result.errors = rawResponse.errors.map((e) => {
        if (e.title.toLocaleLowerCase() === 'bulk upsert failure') {
          // these errors include the SKU
          const src = e.source.pointer.split('/');
          const adjustedRowNumber = parseInt(src.at(-1), 10) + 1;
          const errSource = e.source.sku;
          return {
            title: `Row ${adjustedRowNumber} (${errSource}):`,
            detail: e.detail
          };
        } else if (e.title.toLocaleLowerCase() === 'csv validation failure') {
          // these errors do not include the SKU
          const src = e.source.pointer.split('/');
          const adjustedRowNumber = parseInt(src.at(-2), 10) + 1;
          const errSource = src.at(-1);
          return {
            title: `Row ${adjustedRowNumber}: ${errSource}`,
            detail: e.detail
          };
        } else {
          return {
            title: e.title,
            detail: e.detail
          };
        }
      });
    } else {
      result.data.succeeded = rawResponse.data.succeeded;
    }

    return result;
  }
}

export default ItemMasterService;
