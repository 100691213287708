import * as React from 'react';
import * as Papa from 'papaparse';
import {Button} from '@flexe/ui-components';
import InboundsService from '../shared/services/InboundsService';
import {CsvDataError, CsvUploadParams, GenericUploadError, ResponseError} from '../shared/CommonInterfaces';
import CsvPostUploadStep from '../shared/CsvPostUploadStep';
import BulkContainerUploadModal from './BulkContainerUploadModal';
import {
  composableInboundPackagingEnabledInstructionsTableRows,
  composableInstructionsTableHeaders,
  composableInstructionsTableRows,
  composableSerialNumbersInstructionsTableRows
} from './BulkContainerInstructionsData';

interface BulkContainerState {
  parsedData?: any;
  parseErrors?: Papa.ParseError[];
  reservationId?: number;
  requests?: any;
  successfulParse?: boolean;
  uploadModalBtnDisabled: boolean;
  showCsvUploadModal: boolean;
  uploading: boolean;
  isSerialNumbersCaptureEnabled: boolean;
  uploadComplete: boolean;
  responseErrors: ResponseError[];
  genericErrors: GenericUploadError[];
  csvErrors: CsvDataError[];
}

interface BulkContainerProps {
  reservations: any;
  sampleFileUrl: string;
  authenticityToken: string;
  successRedirect: string;
  inboundPackagingEnabled: boolean;
  commonRequiredHeaders: string[];
  inboundPackagingRequiredHeaders: string[];
  serialNumbersEnabled: any;
  serialNumbersRequiredHeaders: string[];
}

interface ContainerCreateParams {
  reservationId: number;
  isSerialNumbersCaptureEnabled: boolean;
  file: object;
  fileType: string;
}

class BulkContainer extends React.Component<BulkContainerProps, BulkContainerState> {
  private inboundsService = new InboundsService(this.props.authenticityToken);
  private reservationOptions = this.props.reservations.map((reservation, index) => {
    return (
      <option value={reservation.id} key={index}>
        {reservation.name}
      </option>
    );
  });
  private requiredHeaders: string[] = [];
  private csvCreateFileUploadType = 'container_delivery_csv';
  // TODO: PORT-48 confirm that this upload file type can be the same as non-crossdock, no IB packaging
  private csvInboundPackagingCreateFileUploadType = 'container_delivery_csv';
  private templatePath = '/s/dropoffs/container/inbound_csv_template/';
  private instructionsTableData: React.ReactNode[][];
  private fileUploadType: string;

  constructor(props) {
    super(props);
    this.state = {
      isSerialNumbersCaptureEnabled: false,
      uploadModalBtnDisabled: true,
      showCsvUploadModal: false,
      uploading: false,
      uploadComplete: false,
      responseErrors: [],
      genericErrors: [],
      csvErrors: []
    };
  }

  public render() {
    /**
     *
     * There are 4 possible templates based on the two possible configurations for
     * 1) no inbound packaging enabled
     * 2) inbound packaging enabled
     * 3) no inbound packaging enabled + new headers
     * 4) inbound packaging enabled + new headers
     * [TODO] The fourth case can be added in the future if needed.
     * This will require backend support as well to function.
     */

    // check for packaging first. If enabled then use packaging template
    if (this.props.inboundPackagingEnabled) {
      this.requiredHeaders = this.props.commonRequiredHeaders.concat(this.props.inboundPackagingRequiredHeaders);
      this.instructionsTableData = composableInboundPackagingEnabledInstructionsTableRows;
      this.fileUploadType = this.csvInboundPackagingCreateFileUploadType;
    } else {
      this.requiredHeaders = this.props.commonRequiredHeaders;
      this.instructionsTableData = composableInstructionsTableRows;
      this.fileUploadType = this.csvCreateFileUploadType;
    }

    // serial numbers can be combined with packaging. concat headers and instructions instead of replacing
    // TODO: Remove below. https://flexe-inc.atlassian.net/browse/INBOUND-3417
    if (this.state.isSerialNumbersCaptureEnabled) {
      this.requiredHeaders = this.requiredHeaders.concat(this.props.serialNumbersRequiredHeaders);
      this.instructionsTableData = this.instructionsTableData.concat(composableSerialNumbersInstructionsTableRows);
    }

    return (
      <div id="bulk-container-create-component">
        <div className="bulk-container-create-component">
          <div className="warehouse-selection-step">
            <h3>
              <span>Select a Warehouse</span>
            </h3>
            <label htmlFor="reservations">Currently Active Reservations:</label>
            <select id="reservations-dropdown" name="reservations" onChange={this.handleReservationSelection}>
              <option value="">Choose a Warehouse Reservation</option>
              {this.reservationOptions}
            </select>
          </div>
          <div className="csv-selection-step">
            <Button
              onPress={this.toggleShowCsvUploadModal}
              isDisabled={this.state.uploadModalBtnDisabled}
              visualType="primary"
            >
              Select a CSV
            </Button>
          </div>
          {this.state.uploadComplete && (
            <CsvPostUploadStep
              responseErrors={this.state.responseErrors}
              genericErrors={this.state.genericErrors}
              csvErrors={this.state.csvErrors}
              errorBoxTitle={'No POs were processed. Errors occurred during data processing'}
            />
          )}
        </div>
        <BulkContainerUploadModal
          data-testid="bulk-container-upload-modal"
          showModal={this.state.showCsvUploadModal}
          toggleModal={this.toggleShowCsvUploadModal}
          templatePath={this.templatePath}
          isSerialNumbersCaptureEnabled={this.state.isSerialNumbersCaptureEnabled}
          handleUpload={this.handleUpload}
          instructionsTableRows={this.instructionsTableData}
          instructionsTableHeaders={composableInstructionsTableHeaders}
          backwardsCompatible={false}
          requiredHeaders={this.formatRequiredHeadersForValidation(this.requiredHeaders)}
          fileUploadType={this.fileUploadType}
          uploading={this.state.uploading}
        />
      </div>
    );
  }

  protected handleReservationSelection = (event) => {
    const reservationId = event.target.value;
    if (reservationId) {
      this.setState({
        reservationId,
        isSerialNumbersCaptureEnabled: this.props.serialNumbersEnabled[reservationId],
        uploadModalBtnDisabled: false
      });
    }
  };

  private toggleShowCsvUploadModal = () => {
    this.setState({
      showCsvUploadModal: !this.state.showCsvUploadModal
    });
  };

  private handleUpload = async (params: CsvUploadParams, type: string) => {
    this.setState({uploading: true});
    const apiParams: ContainerCreateParams = {
      reservationId: this.state.reservationId,
      isSerialNumbersCaptureEnabled: this.state.isSerialNumbersCaptureEnabled,
      file: params.file,
      fileType: type
    };

    const response = await this.inboundsService.uploadCSV(apiParams);
    this.setState({
      genericErrors: response.data.errors.generic_errors,
      csvErrors: response.data.errors.csv_errors,
      responseErrors: response.errors,
      showCsvUploadModal: false,
      uploading: false,
      uploadComplete: true
    });
  };

  private formatRequiredHeadersForValidation = (rawHeaders) => {
    const valHeaders = rawHeaders.map((header) => {
      const vHeaderArr = [];
      const idxOptional = header.indexOf('(optional)');
      if (idxOptional >= 0) {
        header = header.slice(0, idxOptional).trim();
        vHeaderArr.push(header.toUpperCase().replaceAll(' ', '_'));
        vHeaderArr.push('No');
      }
      const idxRequired = header.indexOf('(required)');
      if (idxRequired >= 0) {
        header = header.slice(0, idxRequired).trim();
        vHeaderArr.push(header.toUpperCase().replaceAll(' ', '_'));
        vHeaderArr.push('Yes');
      }
      return vHeaderArr;
    });
    return valHeaders;
  };
}

export default BulkContainer;
