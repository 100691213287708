import * as React from 'react';
import {FC, useState} from 'react';
import {ShipButtonText} from './CompleteShipmentModal';

interface Props {
  isFreight: boolean;
  isBatched: boolean;
  shipButtonText?: ShipButtonText;
  status: string;

  onStartEdits();
  onCancelEdits();
  onSave();
  onShip();
  onWave();
}

export const Actions: FC<Props> = (props) => {
  const [editing, setEditing] = useState<boolean>(false);

  const {isFreight, isBatched, shipButtonText, status, onStartEdits, onSave, onCancelEdits, onShip, onWave} = props;
  const wasShipped = status === 'completed';
  const wasCancelled = status === 'cancelled';

  const startEdit = () => {
    setEditing(true);
    onStartEdits();
  };

  const cancelEdits = () => {
    setEditing(false);
    onCancelEdits();
  };

  const save = () => {
    setEditing(false);
    onSave();
  };

  const editButton = (
    <button type="button" className="btn-default" data-testid="edit-btn" onClick={startEdit}>
      Edit
    </button>
  );

  const goBackButton = (
    <button type="button" className="btn-go-back" data-testid="cancel-btn" onClick={cancelEdits}>
      Go Back
    </button>
  );

  const saveButton = (
    <button type="button" className="btn btn-primary" data-testid="save-btn" onClick={save}>
      Save
    </button>
  );

  const waveButton = (
    <button
      type="button"
      title={'Wave the shipment'}
      disabled={false}
      className="btn btn-primary"
      data-testid="wave-btn"
      onClick={onWave}
    >
      Wave
    </button>
  );

  const shipButton = (
    <button
      type="button"
      title={'Complete the shipment'}
      disabled={false}
      className="btn btn-primary"
      data-testid="ship-btn"
      onClick={onShip}
    >
      {shipButtonText ?? 'Ship'}
    </button>
  );

  return (
    <React.Fragment>
      <div id="actions">
        {isFreight && (
          <div>
            {!editing && !wasShipped && !wasCancelled && editButton}
            {editing && goBackButton}
            {editing && saveButton}
            {!editing && !isBatched && !wasCancelled && waveButton}
            {!editing && isBatched && !wasShipped && !wasCancelled && shipButton}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
