import * as React from 'react';
import * as moment from 'moment-timezone';
import classNames from 'classnames';
import {BatchError} from '../ecommerce-batches/BatchInterfaces';
import {TransitionState} from '../../shared/CommonInterfaces';
import ProgressBar from '../../shared/ProgressBar';
import BatchWavingService from '../../shared/services/BatchWavingService';
import {KebabMenuOption} from '../../shared/CustomKebabMenu';
import DoWhenClickOutside from '../../shared/DoWhenClickOutside';
import {WaveBatch} from './WaveBatchInterfaces';
import {calculateTransitionState} from './WaveShared';

interface WaveTableBatchEntryProps {
  batchData: WaveBatch;
  batchService: BatchWavingService;
  batchError?: BatchError;
  isWaveCancelled: boolean;
}

const WaveTableBatchEntry: React.FC<WaveTableBatchEntryProps> = ({batchData, isWaveCancelled}) => {
  const [showDropdown, setShowDropdown] = React.useState(false);

  const transitionStateStatus = calculateTransitionState(batchData.pickSteps);

  const totalUnits = (): number => {
    return batchData.pickSteps.reduce((previousValue, step) => previousValue + step.requiredQuantity, 0);
  };

  const totalPickedUnits = (): number => {
    let total = 0;
    batchData.pickSteps.forEach((step) => {
      total += step.pickedQuantity;
    });
    return total;
  };

  const getPickClock = (): string => {
    switch (transitionStateStatus) {
      case TransitionState.InProgress:
      case TransitionState.Completed:
        if (!batchData.startedAt) {
          return '\u00A0';
        }
        return moment.utc(batchData.startedAt).fromNow(true) || '\u00A0';
    }
    return '\u00A0';
  };

  const getPickStartTime = (): string => {
    switch (transitionStateStatus) {
      case TransitionState.InProgress:
      case TransitionState.Completed:
        if (batchData.startedAt) {
          return moment
            .utc(batchData.startedAt)
            .tz(moment.tz.guess())
            .format('hh:mm a');
        }
    }
    return '\u00A0';
  };

  const generalInfoSection = (
    <div className="general-info-section">
      <span>
        <b>
          <a href={`/wh/batches/${batchData.id}`} target="_blank" rel="noreferrer">
            Batch {batchData.id}
          </a>
        </b>
      </span>
    </div>
  );

  const progressSection = (
    <div className="progress-section">
      <span className="units-picked-label">{`${totalPickedUnits()}/${totalUnits()} ea Picked`}</span>
      <ProgressBar
        status={transitionStateStatus}
        total={totalUnits()}
        progress={totalPickedUnits()}
        backgroundColor={'#7A60C6'}
        progressColor={transitionStateStatus === TransitionState.Completed ? '#0DC075' : '#BEACF0'}
        displayStatusText
      />
    </div>
  );

  const pickingInfoSection = (
    <div className="picking-info-section">
      <div className="info-pair">
        <span className="title">Start Time</span>
        <span className="value">{getPickStartTime()}</span>
      </div>
      <div className="info-pair">
        <span className="title">Since Start</span>
        <span className="value">{getPickClock()}</span>
      </div>
    </div>
  );

  const packingInfoSection = (
    <div className="picking-info-section">
      <div className="info-pair">
        <span className="title">Picker</span>
        <span className="value">{batchData.currentPicker || '\u00A0'}</span>
      </div>
    </div>
  );

  const batchDocument = batchData?.documents[batchData.documents.length - 1];

  const cancellationOption: KebabMenuOption = {
    optionText: 'Print Batch',
    optionHref: `/wh/logged_document/${batchDocument?.id}`,
    isActionDangerous: false
  };

  const kebabMenuClasses = ['kebab-menu'];
  const listDisplayClasses = ['dropdown-menu', 'main-dropdown', 'show'];

  const toggleShowDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="wave-batch-tile" data-testid="wave-batch-tile-test">
      {generalInfoSection}
      <span className="tile-divider"></span>
      {progressSection}
      <span className="tile-divider"></span>
      {pickingInfoSection}
      {packingInfoSection}
      <div className="kebab-menu-wrapper">
        <DoWhenClickOutside callback={() => setShowDropdown(false)}>
          <div data-testid="kebab-menu" className={classNames(kebabMenuClasses)}>
            <span data-testid="ellipsis-icon-anchor" className="kebab" onClick={toggleShowDropdown}>
              {showDropdown ? (
                <span className="fa-stack">
                  <i className="fas fa-ellipsis-v fa-stack-1x fa-inverse"></i>
                </span>
              ) : (
                <i className="fas fa-ellipsis-v"></i>
              )}
            </span>

            {showDropdown && (
              <ul className={classNames(listDisplayClasses)} data-testid="option-set">
                <li className="option">
                  <a
                    href={cancellationOption.optionHref}
                    data-testid={`${cancellationOption.optionText}-option`}
                    target="_blank"
                    className={classNames({
                      'disable-print': !batchDocument?.id || isWaveCancelled
                    })}
                  >
                    {cancellationOption.optionText}
                  </a>
                </li>
              </ul>
            )}
          </div>
        </DoWhenClickOutside>
      </div>
    </div>
  );
};

export default WaveTableBatchEntry;
