import * as React from 'react';
import {FunctionComponent} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

interface Props {
  href: string;
  level?: string; // css importance level
  newTab?: boolean; // open in a new tab or not
  onClick?: any; // onClick event
  title?: string; // tooltip/hover text
}

const FlexeLinkButton: FunctionComponent<Props> = (props) => {
  const classes = ['flexe-link-button', props.level ? `btn-${props.level}` : 'btn'];

  if (props.onClick) {
    return (
      <span className={classNames(classes)}>
        <Link to={props.href} target={props.newTab && '_blank'} onClick={props.onClick} title={props.title}>
          {props.children}
        </Link>
      </span>
    );
  } else {
    return (
      <span className={classNames(classes)}>
        <Link to={props.href} target={props.newTab && '_blank'} title={props.title}>
          {props.children}
        </Link>
      </span>
    );
  }
};

export default FlexeLinkButton;
