import * as React from 'react';
import {Item} from './CommonInterfaces';

interface Props {
  selectedInventory: Item[];
  onChange(event);
  onRemoveInventory(event);
}

function SelectedInventoryTable(props: Props) {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="sku">SKU</th>
          <th className="description">Description</th>
          <th className="available">Available</th>
          <th className="quantity">Quantity</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.selectedInventory.map((item: Item, idx: number) => {
          return (
            <tr key={idx}>
              <td className="sku">{item.sku}</td>
              <td className="description">{item.description}</td>
              <td className="available">
                {item.quantity} {item.packaging}
              </td>
              <td className="quantity">
                <input
                  type="number"
                  step="1"
                  className="item-quantity"
                  data-sku={item.sku}
                  data-packaging={item.packaging}
                  value={item.selectedQuantity}
                  onChange={props.onChange}
                />
              </td>
              <td>
                <a
                  className="btn table-action remove"
                  href="#"
                  data-sku={item.sku}
                  data-packaging={item.packaging}
                  onClick={props.onRemoveInventory}
                >
                  <i className="fa fa-lg fa-times no-margin"></i>
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default SelectedInventoryTable;
