import * as React from 'react';
import Chart from 'react-apexcharts';
import {Loader} from '@flexe/ui-components';
import DropDown, {DropDownOption, DropDownStyle} from '../../shared/DropDown';
import {FlexeColors} from '../../shared/CommonInterfaces';
import {FulfillmentType, FulfillmentWindow, HistogramChartData} from './DashboardInterfaces';
import {renderContent} from './Helpers';

interface Props {
  loading: boolean;
  title: string; // expected 'Created' or 'Completed'
  color: FlexeColors;
  errors: string[];
  fulfillmentType: FulfillmentType;
  histogramCounts: HistogramChartData[];
  currentTimeWindow: FulfillmentWindow;
  onSetTimeWindow(option: DropDownOption);
}

const timeWindowOptions = [
  {
    name: 'Past 24 Hours',
    value: FulfillmentWindow.past24Hours
  },
  {
    name: 'Past 7 Days',
    value: FulfillmentWindow.past7Days
  },
  {
    name: 'Past 12 Weeks',
    value: FulfillmentWindow.past12Weeks
  }
] as DropDownOption[];

class HistoryFulfillmentActivityCard extends React.Component<Props> {
  public render() {
    return (
      <div className="card col-sm-12">
        <div className="row">
          {this.renderHistoryHeader()}
          <Loader loading={this.props.loading} />
          {!this.props.loading && renderContent(this.props.errors, this.renderHistogramContainer)}
        </div>
      </div>
    );
  }

  private renderHistoryHeader = () => {
    return (
      <div className="col-sm-12">
        <h4 className="grey3 pull-left">
          {this.props.title} {this.props.fulfillmentType}s history
        </h4>
        <div className="pull-right">
          <DropDown
            style={DropDownStyle.flat}
            options={timeWindowOptions}
            selected={timeWindowOptions.find((o) => o.value === this.props.currentTimeWindow)}
            onSelect={this.props.onSetTimeWindow}
          />
        </div>
      </div>
    );
  };

  private renderHistogramContainer = () => {
    return <div className="col-sm-12">{this.props.histogramCounts && this.renderHistogram()}</div>;
  };

  private renderHistogram = () => {
    return (
      <Chart
        options={{
          chart: {
            id: `${this.props.title.toLowerCase()}-fulfillment-histogram`
          },
          colors: [this.props.color],
          dataLabels: {
            enabled: true,
            position: 'top'
          },
          xaxis: {
            categories: this.props.histogramCounts.map((c) => c.x),
            labels: {
              rotate: -45,
              rotateAlways: true
            }
          }
        }}
        series={[
          {
            name: this.props.title,
            data: this.props.histogramCounts.map((c) => c.y)
          }
        ]}
        type="bar"
        height="500"
        width="100%"
      />
    );
  };
}

export default HistoryFulfillmentActivityCard;
