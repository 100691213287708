import * as React from 'react';
import {Table} from '@flexe/ui-components';
import {TableData} from '@flexe/ui-components';
import {RetailOrder} from '../shared/services/RetailFulfillmentService';

interface ModalShipmentDetailsTableProps {
  selectedOrders: RetailOrder[];
}

const ModalShipmentDetailsTable: React.FC<ModalShipmentDetailsTableProps> = (props) => {
  const getTableData = (): TableData => {
    return {
      headers: [{element: 'Shipment ID'}, {element: 'PO'}, {element: 'Pallets'}],
      rows: props.selectedOrders.map((order) => {
        return [order.id, order.customerUuid, order.palletsBuilt];
      })
    };
  };

  return <Table tableClass="modal-shipment-details-table" tableData={getTableData()} />;
};

export default ModalShipmentDetailsTable;
