import {v4 as uuidV4} from 'uuid';
import {DropDownOption} from '../../../shared/DropDown';
import {isFreight, isOmniFreightLabelTrue, isRfoOrOmniFreightLabelTrue, LabelList} from './Labels';

export const AttachmentTypeOptionInsert: DropDownOption = {name: 'Insert', value: 'insert'};
export const AttachmentTypeOptionMailingLabel: DropDownOption = {name: 'Mailing Label', value: 'mailing_label'};
export const AttachmentTypeOptionMiscellaneousLabel: DropDownOption = {
  name: 'Miscellaneous Label',
  value: 'miscellaneous_label'
};
export const AttachmentTypeOptionShippingLabel: DropDownOption = {name: 'Shipping Label', value: 'shipping_label'};

// These should not be accessed directly, so do not export them
const AttachmentTypeOptionsFreight = [AttachmentTypeOptionMailingLabel, AttachmentTypeOptionMiscellaneousLabel];
const AttachmentTypeOptionsParcel = [
  AttachmentTypeOptionInsert,
  AttachmentTypeOptionMailingLabel,
  AttachmentTypeOptionMiscellaneousLabel,
  AttachmentTypeOptionShippingLabel
];

export function getAttachmentTypeOptions(labels: LabelList): DropDownOption[] {
  return isFreight(labels) ? AttachmentTypeOptionsFreight : AttachmentTypeOptionsParcel;
}

export function showAttachmentsTab(
  featureFlagIsOn: boolean,
  labels: LabelList,
  shipperHasOmniReservation: boolean
): boolean {
  // Respect the flag of course
  if (!featureFlagIsOn) {
    return false;
  }

  // If omni-freight, it's allowed
  if (isOmniFreightLabelTrue(labels)) {
    return true;
  }

  // If not omni freight or RFO, it must be parcel, so its allowed
  if (!isRfoOrOmniFreightLabelTrue(labels)) {
    return true;
  }

  // Otherwise, it might be omni-freight or RFO, so only show the tab if
  //  there's an omni res.
  return shipperHasOmniReservation;
}

export function generateFileKey(displayName: string): string {
  // periods have caused issues for shipment creation, whitespace is just confusing
  // Since the file key isn't meaningful to UI users, we'll just scrub those characters
  const scrubbedName = displayName
    .trim()
    .replace(/\s+/g, '-')
    .replace(/\.+/g, '');

  return `${scrubbedName}-${uuidV4()}`;
}
