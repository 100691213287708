import {differenceBy, flatMap} from 'lodash';
import {Packaging} from '../../../../shared/CommonInterfaces';
import {singularizeUnit} from '../../../../shared/utilities/DataFormatting';
import {ManifestContents, ManifestLpn, ManifestLpnStatus} from '../../../loads/ManifestInterfaces';
import {LpnContentDetail, LpnSearchDetails, LpnStatus} from '../../../../lpns/LpnsInterfaces';
import {LocationContent} from '../../../../locations/LocationsService';
import {LineItem, Shipment} from '../../ShipmentInterfaces';

export const getLoadedData = (
  manifestLpns: ManifestLpn[],
  setLoadedQuantities: React.Dispatch<React.SetStateAction<object>>,
  setLoadedQuantityEaches: React.Dispatch<React.SetStateAction<number>>
) => {
  let qtyByPackaging: object = {};
  let totalEaches: number = 0;

  if (manifestLpns.length > 0) {
    const contents = flatMap(manifestLpns, (lpn) => lpn.manifest_contents);
    const manifestContentTotals = getTotalsFromManifestLPNContents(contents, totalEaches, qtyByPackaging);
    totalEaches = manifestContentTotals.totalEaches;
    qtyByPackaging = manifestContentTotals.qtyByPackaging;
  }
  setLoadedQuantities(qtyByPackaging);
  setLoadedQuantityEaches(totalEaches);
  return qtyByPackaging;
};

export const getShippedData = (
  shipment: Shipment,
  manifestLpns: ManifestLpn[],
  isFreightTrailerLoadingEnabled: boolean,
  setShippedQuantities: React.Dispatch<React.SetStateAction<object>>,
  setShippedQuantityEaches: React.Dispatch<React.SetStateAction<number>>
) => {
  let qtyByPackaging: object = {};
  let totalEaches: number = 0;
  const shippedManifestLpns = manifestLpns.filter((lpn) => [ManifestLpnStatus.shipped].includes(lpn.txn_state));

  if (isFreightTrailerLoadingEnabled) {
    const contents = flatMap(shippedManifestLpns, (lpn) => lpn.manifest_contents);
    const manifestContentTotals = getTotalsFromManifestLPNContents(contents, totalEaches, qtyByPackaging);
    totalEaches = manifestContentTotals.totalEaches;
    qtyByPackaging = manifestContentTotals.qtyByPackaging;
  }

  if (shipment.status === 'completed' && !isFreightTrailerLoadingEnabled) {
    const lineItemtotals = getTotalsFromShipmentLineItems(shipment.line_items, totalEaches, qtyByPackaging);
    totalEaches = lineItemtotals.totalEaches;
    qtyByPackaging = lineItemtotals.qtyByPackaging;
  }
  setShippedQuantities(qtyByPackaging);
  setShippedQuantityEaches(totalEaches);
  return qtyByPackaging;
};

// Staged has to include loaded and shipped since it gets removed in the process
// If something got shipped, it was inevitable staged at some point
export const getStagedData = (
  shipment: Shipment,
  lpns: LpnSearchDetails[],
  manifestLpns: ManifestLpn[],
  looseGoodsInStagingLocation: LocationContent[],
  isFreightTrailerLoadingEnabled: boolean,
  setStagedQuantities: React.Dispatch<React.SetStateAction<object>>,
  setStagedQuantityEaches: React.Dispatch<React.SetStateAction<number>>
) => {
  let qtyByPackaging: object = {};
  let totalEaches: number = 0;
  for (const lpnDetail of notLoadedOrShippedLpns(lpns, manifestLpns)) {
    if (lpnDetail.parentLpnId && lpnDetail.lpnType !== 'flexible') {
      continue;
    }
    // skip archived lpns
    if (lpnDetail.state === LpnStatus.archived) {
      continue;
    }
    const lpnContentTotals = getTotalsFromLPNContents(lpnDetail.contents, totalEaches, qtyByPackaging);
    totalEaches = lpnContentTotals.totalEaches;
    qtyByPackaging = lpnContentTotals.qtyByPackaging;
  }

  const looseGoodTotals = getTotalsFromLooseGoods(looseGoodsInStagingLocation, totalEaches, qtyByPackaging);
  totalEaches = looseGoodTotals.totalEaches;
  qtyByPackaging = looseGoodTotals.qtyByPackaging;

  if (isFreightTrailerLoadingEnabled) {
    const contents = flatMap(manifestLpns, (lpn) => lpn.manifest_contents);
    const manifestContentTotals = getTotalsFromManifestLPNContents(contents, totalEaches, qtyByPackaging);
    totalEaches = manifestContentTotals.totalEaches;
    qtyByPackaging = manifestContentTotals.qtyByPackaging;
  }

  if (shipment.status === 'completed' && !isFreightTrailerLoadingEnabled) {
    const lineItemtotals = getTotalsFromShipmentLineItems(shipment.line_items, totalEaches, qtyByPackaging);
    totalEaches = lineItemtotals.totalEaches;
    qtyByPackaging = lineItemtotals.qtyByPackaging;
  }

  setStagedQuantities(qtyByPackaging);
  setStagedQuantityEaches(totalEaches);
  return qtyByPackaging;
};

const getTotalsFromShipmentLineItems = (line_items: LineItem[], totalEaches: number, qtyByPackaging: object) => {
  for (const lineItem of line_items) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const contentPackaging: Packaging = Packaging[lineItem.packaging];
    if (!qtyByPackaging[contentPackaging]) {
      qtyByPackaging[contentPackaging] = 0;
    }
    // get the numerical value from the fraction string
    totalEaches += lineItem.units_per_packaging.each;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    qtyByPackaging[contentPackaging] = qtyByPackaging[contentPackaging] + lineItem.quantity;
  }
  return {
    totalEaches,
    qtyByPackaging
  };
};

const getTotalsFromManifestLPNContents = (
  contents: ManifestContents[],
  totalEaches: number,
  qtyByPackaging: object
) => {
  for (const content of contents) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const contentPackaging: Packaging = Packaging[singularizeUnit(content.unit_of_measure)];
    if (!qtyByPackaging[contentPackaging]) {
      qtyByPackaging[contentPackaging] = 0;
    }

    totalEaches += Number(content.quantity_in_all_units[Packaging.each]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    qtyByPackaging[contentPackaging] = qtyByPackaging[contentPackaging] + content.quantity;
  }
  return {
    totalEaches,
    qtyByPackaging
  };
};

const getTotalsFromLPNContents = (contents: LpnContentDetail[], totalEaches: number, qtyByPackaging: object) => {
  for (const content of contents) {
    const contentPackaging: Packaging = content.quantity.unit;
    if (!qtyByPackaging[contentPackaging]) {
      qtyByPackaging[contentPackaging] = 0;
    }
    totalEaches += content.quantityInAllUnits[Packaging.each];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    qtyByPackaging[contentPackaging] = qtyByPackaging[contentPackaging] + content.quantity.amount;
  }
  return {
    totalEaches,
    qtyByPackaging
  };
};

const getTotalsFromLooseGoods = (contents: LocationContent[], totalEaches: number, qtyByPackaging: object) => {
  for (const looseGood of contents) {
    const contentPackaging: Packaging = looseGood.quantity.unit;
    if (!qtyByPackaging[contentPackaging]) {
      qtyByPackaging[contentPackaging] = 0;
    }
    // get the numerical value from the fraction string
    const bits = looseGood.quantity.conversions.each.amount.toString().split('/');
    totalEaches += Number(parseInt(bits[0], 10) / parseInt(bits[1], 10));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    qtyByPackaging[contentPackaging] = qtyByPackaging[contentPackaging] + looseGood.quantity.amount;
  }
  return {
    totalEaches,
    qtyByPackaging
  };
};

const notLoadedOrShippedLpns = (lpns: LpnSearchDetails[], manifestLpns: ManifestLpn[]) => {
  return differenceBy(
    lpns,
    manifestLpns.map((_) => ({..._, lpnBarcode: _.lpn_barcode})),
    (_) => _.lpnBarcode
  );
};
