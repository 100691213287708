import * as React from 'react';
import {GridRowId} from '@mui/x-data-grid';
import {Link} from 'react-router-dom';

interface ItemLinkCellProps {
  baseUrl: string;
  item: {
    id: GridRowId;
    value: string;
  };
}
/**
 * Use this component in conjunction with FlexeDataGrid
 * It renders an id-dependent link: anything that gets rendered as rows in a big table on an index page
 * that has underlying or related detail pages is a good condidate for this component.
 * Examples: a SKU, a Load, an LPN barcode, a Location, etc
 * @param baseUrl required - the link text onto which '/:id' will be appended
 * @param item required - item.value will be displayed as the link text & title, while item.id will be appended to the href value
 * @returns
 */
export function ItemLinkCell({baseUrl, item}: ItemLinkCellProps) {
  return (
    <Link className="flexe-mui-data-grid-link-cell" title={item.value} to={`${baseUrl}/${item.id}`} target="_blank">
      {item.value}
    </Link>
  );
}
