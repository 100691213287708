import * as React from 'react';
import {FunctionComponent} from 'react';
import {cloneDeep} from 'lodash';
import {OrderFilters, OrderType} from '../ecommerce-batches/BatchInterfaces';
import {Filter, FilterOption, FilterType} from '../../shared/CommonInterfaces';
import BatchWavingService from '../../shared/services/BatchWavingService';
import WaveBatchFilters from './WaveBatchFilters';

interface AdditionalShipmentFiltersProps {
  shipmentFilters: OrderFilters;
  serviceTypeOptions: FilterOption[];
  wavingService: BatchWavingService;
  handleShipmentFiltersUpdate(updatedFilters: OrderFilters);
}

const AdditionalShipmentFilters: FunctionComponent<AdditionalShipmentFiltersProps> = (props) => {
  const [filters, setFilters] = React.useState<Filter[]>();

  React.useEffect(() => {
    setFilters(getFilters([], props.serviceTypeOptions));
  }, [props.shipmentFilters, props.serviceTypeOptions]);

  const handleSkuTypeAhead = async (query: string) => {
    if (query) {
      const response = await props.wavingService.skuTypeAhead(props.shipmentFilters.reservationId, query);
      let skus: string[] = [];
      if (response && response.errors.length === 0) {
        skus = response.data.skus || [];
      } else {
        // TODO handle error
      }
      const options: FilterOption[] = skus.map((sku) => {
        return {
          displayName: sku,
          value: sku
        };
      });

      const updatedFilters = getFilters(options, props.serviceTypeOptions);
      setFilters(updatedFilters);
    }
  };

  const getFilters = (skuOptions: FilterOption[], serviceTypeOptions: FilterOption[]): Filter[] => {
    const orderTypeOptions: FilterOption[] = [
      {displayName: 'Ship Alone', value: OrderType.ShipAlone},
      {displayName: 'Single item pick and pack', value: OrderType.SingleItem},
      {displayName: 'Multi item pick and pack', value: OrderType.MultiItem}
    ];

    const filtersList = [
      {
        displayName: 'Service Level',
        key: 'serviceTypes',
        type: FilterType.Dropdown,
        allowMultiple: true,
        placeHolder: 'Choose a Service Type',
        options: serviceTypeOptions
      },
      {
        displayName: 'SKU ID',
        key: 'skus',
        type: FilterType.TypeAhead,
        typeAheadHandler: (query: string) => handleSkuTypeAhead(query),
        options: skuOptions,
        allowMultiple: true
      },
      {
        displayName: 'Pick Method',
        key: 'orderType',
        placeHolder: 'Choose a Pick Method',
        type: FilterType.Dropdown,
        options: orderTypeOptions
      },
      {
        displayName: 'PO Includes',
        key: 'purchaseOrder',
        type: FilterType.String
      },
      {
        displayName: 'Ship As Is',
        key: 'shipAsIs',
        placeHolder: 'Select Packaging',
        type: FilterType.Dropdown,
        options: [
          {
            displayName: 'Ship As Is',
            value: 'true'
          },
          {
            displayName: 'Overbox',
            value: 'false'
          }
        ]
      },
      {
        displayName: 'Includes Hazmat',
        key: 'includesHazmat',
        placeHolder: 'Includes Hazmat?',
        type: FilterType.Dropdown,
        options: [
          {
            displayName: 'True',
            value: 'true'
          },
          {
            displayName: 'False',
            value: 'false'
          }
        ]
      },
      {
        displayName: 'Pack Time Label Generation',
        key: 'packTimeLabels',
        placeHolder: 'Pack Time Labels Enabled?',
        type: FilterType.Dropdown,
        options: [
          {
            displayName: 'True',
            value: 'true'
          },
          {
            displayName: 'False',
            value: 'false'
          }
        ]
      },
      {
        displayName: 'Site To Store',
        key: 'siteToStoreOnly',
        placeHolder: 'Site to Store Shipments Only?',
        type: FilterType.Dropdown,
        options: [
          {
            displayName: 'True',
            value: 'true'
          },
          {
            displayName: 'False',
            value: 'false'
          }
        ]
      },
      {
        displayName: 'Includes Multi-Shipment PO',
        key: 'includesMultiShipmentPo',
        placeHolder: 'Includes Multi-Shipment PO?',
        type: FilterType.Dropdown,
        options: [
          {
            displayName: 'True',
            value: 'true'
          },
          {
            displayName: 'False',
            value: 'false'
          }
        ]
      }
    ];

    return filtersList;
  };

  const handleWaveBatchFilterUpdate = (key: string, value: string) => {
    const shipmentFiltersCopy = cloneDeep(props.shipmentFilters);
    switch (key) {
      case 'serviceTypes': {
        if (!shipmentFiltersCopy.serviceTypes) {
          shipmentFiltersCopy.serviceTypes = [];
        }
        if (shipmentFiltersCopy.serviceTypes.indexOf(value) === -1) {
          shipmentFiltersCopy.serviceTypes.push(value);
        }
        break;
      }
      case 'skus': {
        if (!shipmentFiltersCopy.skus) {
          shipmentFiltersCopy.skus = [];
        }
        if (shipmentFiltersCopy.skus.indexOf(value) === -1) {
          shipmentFiltersCopy.skus.push(value);
        }
        break;
      }
      case 'orderType': {
        switch (value) {
          case OrderType.ShipAlone: {
            shipmentFiltersCopy.orderType = OrderType.ShipAlone;
            break;
          }
          case OrderType.MultiItem: {
            shipmentFiltersCopy.orderType = OrderType.MultiItem;
            break;
          }
          case OrderType.SingleItem: {
            shipmentFiltersCopy.orderType = OrderType.SingleItem;
          }
        }
        break;
      }
      case 'purchaseOrder': {
        shipmentFiltersCopy.purchaseOrder = value;
        break;
      }
      case 'shipAsIs': {
        shipmentFiltersCopy.shipAsIs = value === 'true';
        break;
      }
      case 'includesHazmat': {
        shipmentFiltersCopy.includesHazmat = value === 'true';
        break;
      }
      case 'packTimeLabels': {
        shipmentFiltersCopy.packTimeLabels = value === 'true';
        break;
      }
      case 'siteToStoreOnly': {
        shipmentFiltersCopy.siteToStoreOnly = value === 'true';
        break;
      }
      case 'includesMultiShipmentPo': {
        shipmentFiltersCopy.includesMultiShipmentPo = value === 'true';
        break;
      }
    }
    props.handleShipmentFiltersUpdate(shipmentFiltersCopy);
  };
  return (
    <div className="additional-shipment-filters">
      <WaveBatchFilters
        filters={filters}
        filterChangeHandler={(key: string, value: string) => handleWaveBatchFilterUpdate(key, value)}
      />
    </div>
  );
};

export default AdditionalShipmentFilters;
