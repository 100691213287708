import * as React from 'react';
import {OrderFilters, OrderType} from '../ecommerce-batches/BatchInterfaces';
import FilterLabel from '../../shared/FilterLabel';

interface WaveParamsLabelGroupProps {
  shipmentFilters: OrderFilters;
  carriers?: object;
  reservationIdToName?: object;
  hideCutoffDate?: boolean;
  resetCarrierSelected?();
  resetReservationSelected?();
  updateAdditionalFilterSelected(filterKey: string, value?: string);
}

const ShipmentFiltersLabelGroup: React.FC<WaveParamsLabelGroupProps> = (props) => {
  const {
    shipmentFilters,
    carriers,
    reservationIdToName,
    hideCutoffDate,
    resetCarrierSelected,
    resetReservationSelected,
    updateAdditionalFilterSelected
  } = props;

  const orderTypeDisplayNames = new Map<string, string>([
    [OrderType.ShipAlone, 'Ship Alone'],
    [OrderType.MultiItem, 'Multi Item'],
    [OrderType.SingleItem, 'Single Item']
  ]);

  return (
    <div className="shipment-filters-label-group">
      {shipmentFilters.carriers && shipmentFilters.carriers.length > 0 && (
        <FilterLabel
          labelOption={{
            displayName: `Carrier: ${carriers[shipmentFilters.carriers[0]]}`,
            value: shipmentFilters.carriers[0]
          }}
          key={'carrier-filter-label'}
          showCloseButton={true}
          onClose={() => resetCarrierSelected()}
        />
      )}
      {shipmentFilters.reservationId > 0 && (
        <FilterLabel
          labelOption={{
            displayName: `Reservation: ${reservationIdToName[shipmentFilters.reservationId]}`,
            value: shipmentFilters.reservationId
          }}
          key={'reservation-filter-label'}
          showCloseButton={true}
          onClose={() => resetReservationSelected()}
        />
      )}
      {shipmentFilters.serviceTypes &&
        shipmentFilters.serviceTypes.map((serviceType) => {
          return (
            <FilterLabel
              labelOption={{
                displayName: `Service Type: ${serviceType}`,
                value: serviceType
              }}
              key={`service-type-filter-label-${serviceType}`}
              showCloseButton={true}
              onClose={() => updateAdditionalFilterSelected('serviceTypes', serviceType)}
            />
          );
        })}
      {shipmentFilters.skus &&
        shipmentFilters.skus.map((sku) => {
          return (
            <FilterLabel
              labelOption={{
                displayName: `SKU ID: ${sku}`,
                value: sku
              }}
              key={`sku-filter-label-${sku}`}
              showCloseButton={true}
              onClose={() => updateAdditionalFilterSelected('skus', sku)}
            />
          );
        })}
      {shipmentFilters.orderType && (
        <FilterLabel
          labelOption={{
            displayName: `Pick Method: ${orderTypeDisplayNames.get(shipmentFilters.orderType)}`,
            value: shipmentFilters.orderType
          }}
          key={'pick-method-filter-label'}
          showCloseButton={true}
          onClose={() => updateAdditionalFilterSelected('orderType')}
        />
      )}
      {shipmentFilters.purchaseOrder && (
        <FilterLabel
          labelOption={{
            displayName: `Purchase Order: ${shipmentFilters.purchaseOrder}`,
            value: shipmentFilters.purchaseOrder
          }}
          key={'po-filter-label'}
          showCloseButton={true}
          onClose={() => updateAdditionalFilterSelected('purchaseOrder')}
        />
      )}
      {shipmentFilters.orderId && (
        <FilterLabel
          labelOption={{
            displayName: `Shipment Id: ${shipmentFilters.orderId}`,
            value: shipmentFilters.orderId
          }}
          key={'order-id-filter-label'}
          showCloseButton={true}
          onClose={() => updateAdditionalFilterSelected('orderId')}
        />
      )}
      {shipmentFilters.address && (
        <FilterLabel
          labelOption={{
            displayName: `Address: ${shipmentFilters.address}`,
            value: shipmentFilters.address
          }}
          key={'address-filter-label'}
          showCloseButton={true}
          onClose={() => updateAdditionalFilterSelected('address')}
        />
      )}
      {!hideCutoffDate && shipmentFilters.cutoffDate && (
        <FilterLabel
          labelOption={{
            // TODO: this might be a legitimate bug that eslint is finding for us
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            displayName: `Ship On or Before: ${shipmentFilters.cutoffDate}`,
            value: shipmentFilters.cutoffDate.toString()
          }}
          key={'cutoff-date-filter-label'}
          showCloseButton={true}
          onClose={() => updateAdditionalFilterSelected('cutoffDate')}
        />
      )}
      {shipmentFilters.customerOrderUuid && (
        <FilterLabel
          labelOption={{
            displayName: `Customer UUID: ${shipmentFilters.customerOrderUuid}`,
            value: shipmentFilters.customerOrderUuid
          }}
          key={'customer-order-uuid-filter-label'}
          showCloseButton={true}
          onClose={() => updateAdditionalFilterSelected('customerOrderUuid')}
        />
      )}
      {shipmentFilters.shipAsIs !== undefined && (
        <FilterLabel
          labelOption={{
            displayName: `Packaging: ${shipmentFilters.shipAsIs ? 'Ship As Is' : 'Overbox'}`,
            value: ''
          }}
          key={'packaging-filter-label'}
          showCloseButton={true}
          onClose={() => updateAdditionalFilterSelected('shipAsIs')}
        />
      )}
      {shipmentFilters.includesHazmat !== undefined && (
        <FilterLabel
          labelOption={{
            displayName: `Includes Hazmat: ${shipmentFilters.includesHazmat ? 'True' : 'False'}`,
            value: ''
          }}
          key={'hazmat-filter-label'}
          showCloseButton={true}
          onClose={() => updateAdditionalFilterSelected('includesHazmat')}
        />
      )}
      {shipmentFilters.packTimeLabels !== undefined && (
        <FilterLabel
          labelOption={{
            displayName: `Pack Time Label Generation: ${shipmentFilters.packTimeLabels ? 'True' : 'False'}`,
            value: ''
          }}
          key={'ptlg-filter-label'}
          showCloseButton={true}
          onClose={() => updateAdditionalFilterSelected('packTimeLabels')}
        />
      )}
      {shipmentFilters.siteToStoreOnly !== undefined && (
        <FilterLabel
          labelOption={{
            displayName: `Site to Store Shipments Only: ${shipmentFilters.siteToStoreOnly ? 'True' : 'False'}`,
            value: ''
          }}
          key={'s2s-filter-label'}
          showCloseButton={true}
          onClose={() => updateAdditionalFilterSelected('siteToStoreOnly')}
        />
      )}
      {shipmentFilters.includesMultiShipmentPo !== undefined && (
        <FilterLabel
          labelOption={{
            displayName: `Includes Multi-Shipment PO: ${shipmentFilters.includesMultiShipmentPo ? 'True' : 'False'}`,
            value: ''
          }}
          key={'multi-po-filter-label'}
          showCloseButton={true}
          onClose={() => updateAdditionalFilterSelected('includesMultiShipmentPo')}
        />
      )}
    </div>
  );
};

export default ShipmentFiltersLabelGroup;
