import * as React from 'react';
import {Filter, FilterType, FilterValue} from './CommonInterfaces';
// styles are from app/assets/stylesheets/components/filters.scss

interface FilterState {
  addingFilter: boolean;
  filtersInUse: string[];
  filterToAdd: number;
  filterValue: string | number;
  selectedFilters: FilterValue[];
}

interface FilterProps {
  filters: Filter[];
  filterChangeHandler: (values: FilterValue[]) => void;
}

class Filters extends React.Component<FilterProps, FilterState> {
  /*
    This is for a case that a page is accessed with filter params and some of the filters are behind
    feature flags like below.
    http://localhost:3000/wh/locations?isPrimaryPickLocation=true
    To display selected filters, the selected filter data is passed via props(filters).
    But loading feature flag takes some time so props(filters) could be changed later once feature flags are loaded.
    This will update state(selectedFilters) when props(filters) are changed so that the selected filters are rendered.
   */
  public static getDerivedStateFromProps(nextProps) {
    const filterValues = nextProps.filters.filter((filter) => filter.value);
    const newSelectedFilters = [];
    // tl;dr flatmap out any filters that have been compressed into an array for a single value
    filterValues.forEach((filter) => {
      if (filter.value) {
        const flattened = [].concat(filter.value).map((filterValue) => {
          const filterClone = {...filter, value: filterValue};
          return {
            filter: filterClone,
            value: filterValue
          };
        });
        newSelectedFilters.push(...flattened);
      }
    });

    // This if statement is required for test cases since props(filters) is not updated by the parent component.
    // So, if there is no filters data in new props, do not overwrite selectedFilters in state.
    if (newSelectedFilters.length > 0) {
      return {
        selectedFilters: newSelectedFilters
      };
    }
    return {};
  }

  constructor(props) {
    super(props);
    const filterValues = this.props.filters.filter((filter) => filter.value);
    const selectedFilters = [];
    // tl;dr flatmap out any filters that have been compressed into an array for a single value
    filterValues.forEach((filter) => {
      if (filter.value) {
        const flattened = [].concat(filter.value).map((filterValue) => {
          const filterClone = {...filter, value: filterValue};
          return {
            filter: filterClone,
            value: filterValue
          };
        });
        selectedFilters.push(...flattened);
      }
    });
    this.state = {
      addingFilter: false,
      filterToAdd: 0,
      filterValue: '',
      filtersInUse: [],
      selectedFilters
    };
  }

  public render() {
    const allowMultiple = this.props.filters.some((filter) => filter.allowMultiple);
    const currentFilter = this.props.filters[this.state.filterToAdd] || this.props.filters[0];
    const filterOptions = this.props.filters.map((filter, index) => {
      if (filter.allowMultiple || this.state.filtersInUse.indexOf(filter.key) === -1) {
        return (
          <option key={index} value={index}>
            {filter.displayName}
          </option>
        );
      }
    });

    let filterInput = null;
    if (allowMultiple || this.state.filtersInUse.length < this.props.filters.length) {
      switch (currentFilter.type) {
        case FilterType.DataList:
          // eslint-disable-next-line no-case-declarations
          const options = currentFilter.options
            ? currentFilter.options.map((option) => {
                return (
                  <option value={option.value} key={option.value}>
                    {option.displayName}
                  </option>
                );
              })
            : null;
          filterInput = (
            <div>
              <input
                name={currentFilter.key}
                className="form-control"
                list={currentFilter.key}
                value={this.state.filterValue}
                onChange={this.handleValueChange}
                onKeyPress={this.handleKeyPress}
                placeholder={`Enter ${currentFilter.displayName}`}
              />
              <datalist id={currentFilter.key}>{options}</datalist>
            </div>
          );
          break;
        case FilterType.Date:
          filterInput = (
            <input
              name={currentFilter.key}
              value={this.state.filterValue}
              onChange={this.handleValueChange}
              onKeyPress={this.handleKeyPress}
              className="form-control"
              type="date"
              date-format="mm/dd/yyyy"
            />
          );
          break;
        case FilterType.Dropdown:
          // eslint-disable-next-line no-case-declarations
          const ddOptions = currentFilter.options
            ? currentFilter.options.map((option) => {
                return (
                  <option value={option.value} key={option.value}>
                    {option.displayName}
                  </option>
                );
              })
            : null;
          filterInput = (
            <select
              name={currentFilter.key}
              value={this.state.filterValue}
              onChange={this.handleValueChange}
              onKeyPress={this.handleKeyPress}
              className="form-control"
            >
              {ddOptions}
            </select>
          );
          break;
        case FilterType.Number:
          filterInput = (
            <input
              name={currentFilter.key}
              value={this.state.filterValue}
              onChange={this.handleValueChange}
              onKeyPress={this.handleKeyPress}
              className="form-control"
              type="number"
              placeholder={`Enter ${currentFilter.displayName}`}
            />
          );
          break;
        case FilterType.TypeAhead:
          // eslint-disable-next-line no-case-declarations
          let typeAheadOptions = null;
          if (currentFilter.options && currentFilter.options.length > 0) {
            typeAheadOptions = (
              <ul className="dropdown-menu show">
                {currentFilter.options.map((option, index) => {
                  return (
                    <li key={index}>
                      <a onClick={this.handleTypeAheadSelection(option.value)}>{option.displayName}</a>
                    </li>
                  );
                })}
              </ul>
            );
          }
          filterInput = (
            <div>
              <input
                name={currentFilter.key}
                className="form-control"
                type="text"
                value={this.state.filterValue}
                onChange={this.handleTypeAhead}
                placeholder={`Enter ${currentFilter.displayName}`}
              />
              {typeAheadOptions}
            </div>
          );
          break;
        case FilterType.Radio:
          // eslint-disable-next-line no-case-declarations
          let key = 0;
          // eslint-disable-next-line no-case-declarations
          const bOptions = currentFilter.options
            ? currentFilter.options.map((option, index) => {
                return [
                  <input
                    key={key++}
                    className="filter-radio"
                    type="radio"
                    name={currentFilter.key}
                    id={currentFilter.key + '_' + index}
                    value={option.value}
                    onChange={this.handleValueChange}
                  />,
                  <label key={key++} className="filter-radio-label" htmlFor={currentFilter.key + '_' + index}>
                    {option.displayName}
                  </label>
                ];
              })
            : null;
          filterInput = <div className="rbtn-group">{bOptions}</div>;
          break;
        case FilterType.Present:
          break;
        default:
          filterInput = (
            <input
              name={currentFilter.key}
              className="form-control"
              type="text"
              value={this.state.filterValue}
              onChange={this.handleValueChange}
              onKeyPress={this.handleKeyPress}
              placeholder={`Enter ${currentFilter.displayName}`}
            />
          );
          break;
      }
    }
    const filters = (
      <ul id="filter-list">
        {this.state.selectedFilters.map((selected, index) => {
          return (
            <li className="filter-button" key={index} data-testid="filter-button">
              <div className="filter-display-name">{selected.filter.displayName}</div>
              <div className="filter-value">
                {selected.value}
                <a
                  className="filter-remove"
                  data-index={index}
                  onClick={this.handleRemoveFilter}
                  data-testid="remove-filter-button"
                >
                  <i className="fa fa-times-circle" aria-hidden="true"></i>
                </a>
              </div>
            </li>
          );
        })}
      </ul>
    );

    const showNewFilter =
      !this.state.addingFilter && (this.state.filtersInUse.length < this.props.filters.length || allowMultiple);

    return (
      <div id="filters-component">
        <div className="add-filter">
          {!allowMultiple && this.state.filtersInUse.length === this.props.filters.length && (
            <small>All Filters Applied</small>
          )}
          {showNewFilter && (
            <a className="new-filter" onClick={this.toggleAddFilter}>
              <i className="fa fa-plus-circle" aria-hidden="true"></i> New Filter
            </a>
          )}
          {this.state.addingFilter && (this.state.filtersInUse.length < this.props.filters.length || allowMultiple) && (
            <div className="form-inline">
              <div id="filter-select" className="form-group">
                <select value={this.state.filterToAdd} onChange={this.handleFilterSelect}>
                  {filterOptions}
                </select>
              </div>
              <div className="form-group filter-input">{filterInput}</div>
              <div className="form-group" id="filter-actions">
                <a className="submit-btn" data-testid="submit-btn" onClick={this.handleAddFilter}>
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a className="text-danger" onClick={this.toggleAddFilter}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="selected-filters">
          {this.state.selectedFilters.length === 0 && (
            <small>
              <em>No filters applied</em>
            </small>
          )}
          {filters}
        </div>
      </div>
    );
  }

  private handleValueChange = (event) => {
    this.setState({
      filterValue: event.target.value
    });
  };

  private handleAddFilter = () => {
    if (this.state.filterValue) {
      const updatedFilters = this.state.selectedFilters.slice();
      updatedFilters.push({
        filter: this.props.filters[this.state.filterToAdd],
        value: this.state.filterValue
      });
      const {newFilterIndex, updatedFiltersInUse} = this.getFilterDisplayValues(updatedFilters);
      this.setState({
        addingFilter: false,
        filterToAdd: newFilterIndex,
        filterValue: '',
        filtersInUse: updatedFiltersInUse,
        selectedFilters: updatedFilters
      });
      this.props.filterChangeHandler(updatedFilters);
    }
  };

  private handleRemoveFilter = (event) => {
    const index = event.currentTarget.getAttribute('data-index');
    const updatedFilters = this.state.selectedFilters.slice();
    updatedFilters.splice(index, 1);
    const {newFilterIndex, updatedFiltersInUse} = this.getFilterDisplayValues(updatedFilters);
    this.setState({
      filterToAdd: newFilterIndex,
      filtersInUse: updatedFiltersInUse,
      selectedFilters: updatedFilters
    });
    this.props.filterChangeHandler(updatedFilters);
  };

  private toggleAddFilter = () => {
    this.setState({
      addingFilter: !this.state.addingFilter,
      filterValue: ''
    });
  };

  private handleFilterSelect = (event) => {
    const index = event.target.value;
    const selectedFilter = this.props.filters[index];
    const startingValue =
      !selectedFilter.allowMultiple && (selectedFilter.value || selectedFilter.type === FilterType.Present)
        ? 'true'
        : null || '';
    this.setState({
      filterToAdd: index,
      filterValue: startingValue
    });
  };

  private handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleAddFilter();
    }
  };

  private handleTypeAhead = async (event) => {
    const query = event.target.value;
    const filter = this.props.filters[this.state.filterToAdd];
    this.setState({
      filterValue: query
    });
    if (query.length > 2) {
      await filter.typeAheadHandler(event.target.value);
    }
  };

  private handleTypeAheadSelection = (value) => (event) => {
    const filter = this.props.filters[this.state.filterToAdd];
    filter.typeAheadHandler(null);
    this.setState(
      {
        filterValue: value
      },
      this.handleAddFilter
    );
  };

  private getFilterDisplayValues(filters: FilterValue[]) {
    const updatedFiltersInUse = filters.map((selectedFilter) => {
      return selectedFilter.filter.key;
    });
    let newFilterIndex = 0;
    for (let i = 0; i < this.props.filters.length; i++) {
      if (this.props.filters[i].allowMultiple || updatedFiltersInUse.indexOf(this.props.filters[i].key) === -1) {
        newFilterIndex = i;
        break;
      }
    }
    return {
      newFilterIndex,
      updatedFiltersInUse
    };
  }
}

export default Filters;
