import * as React from 'react';
import {get} from 'lodash';
import {PackingListItem, ReceivedType} from '../DropoffInterfaces';
import {Packaging} from '../../../shared/CommonInterfaces';

interface PackingListRowProps {
  item: PackingListItem;
  additional?: boolean;
  onReceivedCountChange(id: number, qty: number, unit: Packaging, type: ReceivedType, additional: boolean);
  onRemoveSku?(inventoryId: number, packaging: Packaging);
}

function PackingListRow({item, additional, onRemoveSku, onReceivedCountChange}: PackingListRowProps) {
  const handleReceivedCountChange = (event) => {
    const qty = parseInt(event.target.value, 10);
    const type = event.target.name;
    onReceivedCountChange(item.inventory.id, qty, item.quantity.unit, type, additional);
  };

  const handleRemoveSku = (inventoryId: number, packaging: Packaging) => () => {
    onRemoveSku(inventoryId, packaging);
  };

  const expectedCount = get(item, 'quantity.amount') || 0;
  const shippableCount = item.shippableAmount || 0;
  const damagedCount = item.damagedAmount || 0;
  const receivedCount = shippableCount + damagedCount;
  const expectedMinusReceived = expectedCount - receivedCount;

  let shortageOrOverage;
  if (expectedMinusReceived > 0) {
    shortageOrOverage = <span className="diff red2">shortage: {expectedMinusReceived}</span>;
  } else if (expectedMinusReceived < 0) {
    shortageOrOverage = <span className="diff successGreen">overage: {-expectedMinusReceived}</span>;
  }

  return (
    <tr className="packing-lists">
      <td>{item.inventory.sku}</td>
      <td>{item.inventory.description}</td>
      <td>{item.quantity.amount}</td>
      <td>
        <div className="form-group">
          <div className="input-group">
            <input
              type="number"
              className="form-control shippable"
              name="shippableAmount"
              value={item.shippableAmount || ''}
              min={0}
              onChange={handleReceivedCountChange}
            />
            <span className="input-group-addon">{item.quantity.unit}</span>
          </div>
        </div>
      </td>
      <td>
        <div className="form-group">
          <div className="input-group">
            <input
              type="number"
              className="form-control damaged bg-red6"
              name="damagedAmount"
              value={item.damagedAmount || ''}
              min={0}
              onChange={handleReceivedCountChange}
            />
            <span className="input-group-addon">{item.quantity.unit}</span>
          </div>
        </div>
      </td>
      <td>
        <span className="total-quantity">
          {receivedCount} {item.quantity.unit}
        </span>
        {shortageOrOverage}
      </td>
      <td>
        {additional && (
          <a
            className="btn sku-remove flat"
            title="Remove SKU from shipment"
            onClick={handleRemoveSku(item.inventory.id, item.quantity.unit)}
          >
            <i className="fa fa-times"></i>
          </a>
        )}
      </td>
    </tr>
  );
}

export default PackingListRow;
