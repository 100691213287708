import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';
import {LegacyModal} from '@flexe/ui-components';
import * as _ from 'lodash';
import SelectorWithCheckbox from '../../shared/SelectorWithCheckbox';
import {ApiResponse} from '../../shared/CommonInterfaces';
import ExpenseService from './ExpenseService';
import {AllPricings, CreateExpenseParameter} from './ExpensesInterfaces';

interface WarehouseExpenseCreatorProps {
  warehouseId: number;
  allPricings: AllPricings;
  reservations: any;
  billingPeriod: string;
  statementDate: string;
  authenticityToken: string;
  changePeriodClosed: boolean;
  costBasedExpenseCategoryList: string[];

  // TODO: add failure behavior
  onCreateExpenseSuccess: () => void;
  // onCreateExpenseFailure();
}
const WarehouseExpenseCreator: React.FC<WarehouseExpenseCreatorProps> = (props) => {
  const {
    warehouseId,
    allPricings,
    reservations,
    billingPeriod,
    statementDate,
    authenticityToken,
    changePeriodClosed,
    onCreateExpenseSuccess,
    costBasedExpenseCategoryList
    // onCreateExpenseFailure
  } = props;

  const expenseService = new ExpenseService(authenticityToken);
  const useState = React.useState;
  const [showCreatorModal, setShowCreatorModal] = useState(false);
  const [reservationId, setReservationId] = React.useState<number>();
  const [category, setCategory] = React.useState<string>();
  const [feeDescription, setFeeDescription] = React.useState<string>();
  const [categoryList, setCategoryList] = React.useState<string[]>([]);
  const [description, setDescription] = React.useState<string>();
  const [descriptionList, setDescriptionList] = React.useState<string[]>([]);
  const [uom, setUom] = React.useState<string>();
  const [warehouseRate, setWarehouseRate] = React.useState<number>();
  const [warehouseMarkupString, setWarehouseMarkUpString] = React.useState<string>();
  const [quantity, setQuantity] = React.useState<number>();
  const [quantityString, setQuantityString] = React.useState<string>('');
  const [quantityError, setQuantityError] = React.useState<string>();
  const [validQuantity, setValidQuantity] = React.useState<boolean>(true);
  const [validNotes, setValidNotes] = React.useState<boolean>(false);
  const [submissionNotes, setSubmissionNotes] = React.useState<string>();
  const [attachment, setAttachment] = React.useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [quantitySectionTitle, setQuantitySectionTitle] = React.useState<string>('Quantity');
  const [rateSectionTitle, setRateSectionTitle] = React.useState<string>('Rate');
  const [isCreating, setIsCreating] = React.useState<boolean>(false);

  const clearExpensePriceInfo = () => {
    setUom(null);
    setWarehouseRate(null);
    setWarehouseMarkUpString(null);
  };

  // use comma to split the number
  // example: "1234.5678" will transfer to "1,234.5678"
  const formatNumber = (rawNumber) => {
    const numberParts = rawNumber.toString().split('.');
    let left = numberParts[0];
    const right = numberParts.length === 1 ? null : numberParts[1];
    const leftLen = left.length;
    if (leftLen > 3) {
      const remainder = leftLen % 3;
      let finalLeft = left
        .slice(remainder, leftLen)
        .match(/\d{3}/g)
        .join(',');
      if (remainder > 0) {
        finalLeft = left.slice(0, remainder) + ',' + finalLeft;
      }
      left = finalLeft;
    }
    const finalResult = right == null ? left : left + '.' + right;
    return finalResult;
  };

  const handleChangeQuantity = (event) => {
    const originString = event.target.value;
    // only allow . and digit text input
    const numberString = originString.replace(/[^\d.]/g, '');
    const value = Number(numberString);
    if (isNaN(value)) {
      setQuantityString(event.target.value);
      setQuantity(null);
      setValidQuantity(false);
      setQuantityError(quantitySectionTitle + ' input must be a valid number.');
    } else if (value >= 100000000) {
      setQuantityString(event.target.value);
      setQuantity(null);
      setValidQuantity(false);
      setQuantityError(quantitySectionTitle + ' input must not exceed 100,000,000.');
    } else {
      // when the digit number more than 3, use comma split them.
      setQuantityString(formatNumber(numberString));
      setQuantity(value);
      setValidQuantity(true);
    }
  };

  const handleError = (response: ApiResponse<any>, message: string = '') => {
    if (response) {
      const serverError = response.errors[0];
      message += `${serverError.detail} (Status code: ${serverError.status}).`;
    }
    setErrorMessage(message);
  };

  const handleChangeSubmissionNotes = (event) => {
    const notes = event.target.value;
    setSubmissionNotes(notes);
    if (notes === '') {
      setValidNotes(false);
    } else {
      setValidNotes(true);
    }
  };

  const handleFileAdded = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAttachment(file);
    }
  };

  const quantityInput = (
    <React.Fragment>
      <label className="form-label" htmlFor="quantity">
        <span className="required">*</span>
        {`${quantitySectionTitle}:`}
      </label>
      <input
        data-testid="quantityInput"
        type="text"
        className={`form-control${validQuantity ? '' : ' error'}`}
        id="quantity"
        value={quantityString}
        onChange={(event) => handleChangeQuantity(event)}
      />
      {!validQuantity && (
        <span data-testid="quantityInputError" className="error">
          {quantityError}
        </span>
      )}
    </React.Fragment>
  );

  const reservationDropdown = () => {
    const options = reservations.map((reservation) => {
      return {
        name: reservation.name,
        value: `${reservation.value}`
      };
    });

    const onSelect = (value: string) => {
      const selectedReservationId = parseInt(value, 10);
      const selectedCategoryList = Object.keys(allPricings[selectedReservationId]);
      setReservationId(selectedReservationId);
      setCategoryList(selectedCategoryList);
      setCategory(null);
      setDescription(null);
      setFeeDescription(null);
      setDescriptionList([]);
      clearExpensePriceInfo();
    };

    return (
      <React.Fragment>
        <label className="form-label" htmlFor="reservationId">
          <span className="required">*</span>
          {'Reservation :'}
        </label>
        <SelectorWithCheckbox
          data-testid="reservationId"
          options={options}
          selected={[`${reservationId}`]}
          title="Select a Reservation"
          onSelect={onSelect}
        />
      </React.Fragment>
    );
  };

  const categoryDropdown = () => {
    const options = categoryList.map((c) => {
      return {
        name: c,
        value: c
      };
    });

    const onSelect = (value: string) => {
      const selectedCategory = value;
      const selectedDescriptionList = Object.keys(allPricings[reservationId][selectedCategory]);
      if (costBasedExpenseCategoryList.includes(selectedCategory)) {
        setCostBasedCategoryTitles();
      } else {
        setQuantityBasedCategoryTitles();
      }
      setCategory(selectedCategory);
      setDescriptionList(selectedDescriptionList);
      setDescription(null);
      setFeeDescription(null);
      clearExpensePriceInfo();
    };

    return (
      <React.Fragment>
        <label className="form-label" htmlFor="category">
          <span className="required">*</span>
          {'Category :'}
        </label>
        <SelectorWithCheckbox
          options={options}
          selected={[`${category}`]}
          title="Select a category"
          onSelect={onSelect}
        />
      </React.Fragment>
    );
  };

  const setCostBasedCategoryTitles = () => {
    setQuantitySectionTitle('Cost(without markup)');
    setRateSectionTitle('Markup');
  };

  const setQuantityBasedCategoryTitles = () => {
    setQuantitySectionTitle('Quantity');
    setRateSectionTitle('Rate');
  };

  const descriptionDropdown = () => {
    const options = descriptionList.map((d) => {
      return {
        name: d,
        value: d
      };
    });

    const onSelect = (selectedDescription: string) => {
      const selectedWarehousePriceInfo = allPricings[reservationId][category][selectedDescription];
      const selectedUom = selectedWarehousePriceInfo.uom;
      const selectedFeeDescription = selectedWarehousePriceInfo.description;
      const selectedWarehouseRate = Number(selectedWarehousePriceInfo.warehouse_rate);
      const selectedWarehouseMarkupString = selectedWarehousePriceInfo.warehouse_markup_string;
      setDescription(selectedDescription);
      setFeeDescription(selectedFeeDescription);
      setUom(selectedUom);
      setWarehouseRate(selectedWarehouseRate);
      setWarehouseMarkUpString(selectedWarehouseMarkupString);
    };

    return (
      <React.Fragment>
        <label className="form-label" htmlFor="description">
          <span className="required">*</span>
          {'Expense :'}
        </label>
        <SelectorWithCheckbox
          options={options}
          selected={[`${description}`]}
          title="Select a Expense Type"
          onSelect={onSelect}
        />
      </React.Fragment>
    );
  };

  const showWarehouseRate = () => {
    return (
      <React.Fragment>
        <label className="form-label" htmlFor="warehouseRate">
          {`${rateSectionTitle}:`}
        </label>
        {(warehouseMarkupString && <div className="center-content">{warehouseMarkupString}</div>) ||
          (warehouseRate && uom && (
            <div className="center-content">
              ${warehouseRate} / {uom}
            </div>
          )) || <div className="center-content">N/A</div>}
      </React.Fragment>
    );
  };

  const showWarehouseAmount = () => {
    return (
      <React.Fragment>
        <label className="form-label" htmlFor="warehouseRate">
          {'Total Expense Amount:'}
        </label>
        {(warehouseRate && uom && quantity && (
          <div className="total">${formatNumber((warehouseRate * quantity).toFixed(2))}</div>
        )) || <div className="total">$0</div>}
      </React.Fragment>
    );
  };

  const reset = () => {
    clearAllData();
    closeAllModal();
  };

  const goBack = () => {
    onlyShowCreatorModal();
  };

  const addSubmissionNotes = (
    <React.Fragment>
      <label className="form-label" htmlFor="submissionNotes">
        <span className="required">*</span>
        {'Notes:'}
      </label>
      <textarea
        className="form-control submission-notes"
        id="submissionNotes"
        name="submissionNotes"
        value={submissionNotes}
        placeholder="Add details about the expense, e.g. order number or quantity.  Do not include any rates or cost."
        onChange={(event) => handleChangeSubmissionNotes(event)}
      />
    </React.Fragment>
  );

  const addAttachment = (
    <React.Fragment>
      <label className="form-label" htmlFor="files">
        {'Add Attachment:'}
      </label>
      <input
        id="files"
        type="file"
        onChange={handleFileAdded}
        accept="application/pdf, image/jpeg, image/png, application/msword, .csv,
                             application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                             text/plain, application/octet-stream"
      />
    </React.Fragment>
  );

  const expenseCreatorModalTitle = 'Add Warehouse Expense';

  const expenseCreatorModalBody = (
    <div id="expenseCreatorModalContent" className="col-md-8 col-sm-12">
      <div className="bottom-gap">Billing Period: {billingPeriod}</div>
      <div className="bottom-gap">{reservationDropdown()}</div>
      <div className="bottom-gap">{categoryDropdown()}</div>
      <div className="bottom-gap">{descriptionDropdown()}</div>
      <div className="bottom-gap">{showWarehouseRate()}</div>
      <div className="bottom-gap quantity">{quantityInput}</div>
      <div className="bottom-gap">{showWarehouseAmount()}</div>
      <div className="bottom-gap">{addSubmissionNotes}</div>
      <div className="bottom-gap">{addAttachment}</div>
    </div>
  );

  const expenseMissingScopesModalBody = (
    <div>
      <div className="bottom-gap">
        <i className="fa fa-lg fa-warning required"></i>
        &nbsp; Cannot Add Expenses: Pricing Configurations not Found.
      </div>
      <div className="bottom-gap">Please contact billing.support@flexe.com for assistance.</div>
    </div>
  );

  const isCreateExpenseEnabled = (): boolean => {
    return validNotes && !isCreating;
  };

  const expenseCreatorModalFooter = (
    <div>
      {isCreating && <i className="fa fa-spinner fa-spin" style={{padding: '5px'}}></i>}
      <a className="btn btn-primary-alt" onClick={reset}>
        {'Cancel'}
      </a>
      <button className="btn" onClick={() => createExpense()} disabled={!isCreateExpenseEnabled()}>
        {'Create Expense'}
      </button>
    </div>
  );

  const expenseMissingScopesModalFooter = (
    <div>
      <a className="btn btn-primary-alt" onClick={reset}>
        {'Cancel'}
      </a>
    </div>
  );

  const successModalBody = (
    <div className="result-modal-body ">
      <div>
        <i className="fa fa-lg fa-check big-green"></i>
      </div>
      <div className="notice-bold">Your Expense Request is Submitted.</div>
      <div>A Flexe associate and your shipping customer will review your request for approval. Thank You!</div>
    </div>
  );

  const successModalFooter = (
    <div className="center">
      <button className="btn" onClick={reset}>
        {'Continue'}
      </button>
    </div>
  );

  const failedModalBody = (
    <div className="result-modal-body ">
      <div>
        <i className="fa fa-lg fa-warning big-red"></i>
      </div>
      <div className="notice-bold">Oops that didn't go through!</div>
      <div className="notice-error">Please go back and fill all required fields.</div>
    </div>
  );

  const failedModalFooter = (
    <div className="center">
      <button className="btn" onClick={goBack}>
        {'Back'}
      </button>
    </div>
  );

  const clearAllData = () => {
    setReservationId(null);
    setCategory(null);
    setCategoryList([]);
    setDescription(null);
    setFeeDescription(null);
    setDescriptionList([]);
    clearExpensePriceInfo();
    setQuantity(null);
    setQuantityString(null);
    setValidQuantity(true);
    setValidNotes(false);
    setQuantityError(null);
    setSubmissionNotes('');
    setAttachment(null);
    setErrorMessage(null);
    setQuantitySectionTitle('Quantity');
    setRateSectionTitle('Rate');
  };

  const createExpense = async () => {
    const expenseParameter: CreateExpenseParameter = {
      reservationId,
      category,
      description,
      feeDescription,
      quantity,
      billingPeriodStartDate: statementDate,
      submissionNotes,
      attachment
    };

    setIsCreating(true);
    const response = await expenseService.createExpense(warehouseId, expenseParameter);
    setIsCreating(false);
    if (response && response.errors.length === 0) {
      // TODO: will add it when we need
      onCreateExpenseSuccess();
      onlyShowSuccessModal();
    } else {
      onlyShowFailedModal();

      handleError(response);
    }
  };

  const missingScopesModal = (
    <LegacyModal
      id="warehouse-expense-creator"
      show={showCreatorModal && _.size(allPricings) === 0}
      size="small"
      toggleModal={() => setShowCreatorModal(false)}
      title={expenseCreatorModalTitle}
      footer={expenseMissingScopesModalFooter}
    >
      {expenseMissingScopesModalBody}
    </LegacyModal>
  );

  const creatorModal = (
    <LegacyModal
      id="warehouse-expense-creator"
      show={showCreatorModal && _.size(allPricings) > 0}
      size="small"
      toggleModal={() => setShowCreatorModal(false)}
      title={expenseCreatorModalTitle}
      footer={expenseCreatorModalFooter}
    >
      {expenseCreatorModalBody}
    </LegacyModal>
  );

  const successModal = (
    <LegacyModal
      id="warehouse-expense-create-success"
      show={showSuccessAlert}
      size="small"
      toggleModal={() => setShowSuccessAlert(false)}
      title={expenseCreatorModalTitle}
      footer={successModalFooter}
    >
      {successModalBody}
    </LegacyModal>
  );

  const failedModal = (
    <LegacyModal
      id="warehouse-expense-create-fail"
      show={showErrorAlert}
      size="small"
      toggleModal={() => setShowErrorAlert(false)}
      title={expenseCreatorModalTitle}
      footer={failedModalFooter}
    >
      {failedModalBody}
    </LegacyModal>
  );

  const closeAllModal = () => {
    setShowCreatorModal(false);
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
  };

  const onlyShowCreatorModal = () => {
    setShowCreatorModal(true);
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
  };

  const onlyShowSuccessModal = () => {
    setShowCreatorModal(false);
    setShowSuccessAlert(true);
    setShowErrorAlert(false);
  };

  const onlyShowFailedModal = () => {
    setShowCreatorModal(false);
    setShowSuccessAlert(false);
    setShowErrorAlert(true);
  };

  return (
    <div id="expense-creator">
      <button
        data-testid="expense-creator-button"
        className="no-cta pull-right"
        onClick={() => {
          if (!changePeriodClosed) {
            setShowCreatorModal(true);
          }
        }}
        data-tip
        data-for="expense-button-tip"
      >
        <i className="fa fa-plus"></i>
        &nbsp;Add Expense
      </button>
      {changePeriodClosed && (
        <ReactTooltip id="expense-button-tip" place="bottom">
          You must enter expenses within the current billing window
        </ReactTooltip>
      )}
      {missingScopesModal}
      {creatorModal}
      {successModal}
      {failedModal}
    </div>
  );
};

export default WarehouseExpenseCreator;
