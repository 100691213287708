import InternalAPIService from '../../../shared/services/InternalAPIService';
import {ApiResponse} from '../../../shared/CommonInterfaces';
import {ShippoSettings} from '../SharedInterfaces';

class ShippingLabelsAPIService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/s/shipping_labels_api_key');
  }

  public async getShippoAPIKeySettings(): Promise<ApiResponse<ShippoSettings>> {
    return await this.makeGetRequest(`${this.baseUrl}/shippo_settings`, {
      authenticity_token: this.authenticityToken
    });
  }

  public async setShippoAPIKeySettings(settings: ShippoSettings): Promise<ApiResponse<any>> {
    return await this.makePostRequest(`${this.baseUrl}/set_shippo_settings`, {
      authenticity_token: this.authenticityToken,
      hasApiKey: settings.hasApiKey,
      shippoApiKey: settings.shippoApiKey
    });
  }
}

export default ShippingLabelsAPIService;
