import * as React from 'react';
import {FC, useState} from 'react';
import DropDown, {DropDownColor, DropDownOption, DropDownStyle} from '../../../shared/DropDown';
import {OMSReservation} from '../../ecommerce-orders/OrdersAppInterfaces';
import {OutboundOrder} from '../OutboundOrdersInterfaces';
import {Reservation} from '../ReservationsInterfaces';
import {isOmniFreightLabelTrue, isRfoOrOmniFreightLabelTrue} from '../helpers/Labels';

const placeholder: DropDownOption = {
  name: 'Select a reservation...',
  value: ''
};

interface Props {
  order: OutboundOrder;
  reservations: OMSReservation[];
  initialReservationId: string;
  onReservationUpdated(reservationId);
}

const getReservationOptions = (reservations: OMSReservation[], order: OutboundOrder): DropDownOption[] => {
  const allOptions = buildAndFilterOptionList(reservations, order);
  return sortOptionList(allOptions);
};

const buildAndFilterOptionList = (reservations: OMSReservation[], order: OutboundOrder): DropDownOption[] => {
  const placeHolderOption: DropDownOption[] = [placeholder];
  const options: DropDownOption[] = reservations
    .filter(
      (res) => ['confirmed', 'pending_closure'].includes(res.txnState) && isReservationEnabledForOrderType(res, order)
    )
    .map((res) => ({
      name: res.displayName,
      value: res.id.toString()
    }));

  return placeHolderOption.concat(options);
};

const sortOptionList = (options: DropDownOption[]): DropDownOption[] => {
  return options.sort((a, b) => {
    return a.value > b.value ? 1 : a.value < b.value ? -1 : 0;
  });
};

const isReservationEnabledForOrderType = (reservation: OMSReservation, order: OutboundOrder): boolean => {
  const labels = order.labels || {};
  const isRfoOrOmniFreight = isRfoOrOmniFreightLabelTrue(labels);
  const isOmniFreight = isOmniFreightLabelTrue(labels);

  if (isRfoOrOmniFreight) {
    return reservation.retailEnabled || reservation.omniEnabled;
  } else if (isOmniFreight) {
    return reservation.omniEnabled;
  } else {
    return reservation.ecommerceEnabled;
  }
};

const ReservationDropDown: FC<Props> = ({order, reservations, initialReservationId, onReservationUpdated}) => {
  const [updatedReservation, setUpdatedReservation] = useState<boolean>(false);
  const [reservationOptions] = useState<DropDownOption[]>(getReservationOptions(reservations, order));
  const [newReservationId, setNewReservationId] = useState<string>('');

  const getSelectedReservation = () => {
    const selectedReservationId = updatedReservation ? newReservationId : initialReservationId;
    const selected = reservationOptions.find((o) => o.value === selectedReservationId);
    return selected || placeholder;
  };

  const updateReservationId = (option: DropDownOption) => {
    const castValue = option.value as string;
    const selectedReservationId = castValue ? castValue : undefined;
    onReservationUpdated(selectedReservationId);
    setUpdatedReservation(true);
    setNewReservationId(selectedReservationId);
  };

  return (
    <DropDown
      color={DropDownColor.white}
      options={reservationOptions}
      style={DropDownStyle.matchFont}
      selected={getSelectedReservation()}
      onSelect={updateReservationId}
    />
  );
};

export default ReservationDropDown;
