import * as React from 'react';
import axios from 'axios';
import {get, without} from 'lodash';
import {Packaging} from '../../../shared/CommonInterfaces';
import TypeAhead, {TypeAheadOption} from '../../../shared/TypeAhead';
import {Inventory, PackingListItem, ReceivedType} from '../DropoffInterfaces';
import PackingListRow from './PackingListRow';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

interface SkuQuantityProps {
  additionalPackingLists: PackingListItem[];
  expectedPackingLists: PackingListItem[];
  receivingListPath: string;
  reservationId: number;
  onAddAdditionalSku(selectedInventory: Inventory, selectedPackaging: string);
  onReceivedCountChange(id: number, qty: number, unit: Packaging, type: ReceivedType, additional: boolean);
  onRemoveAdditionalSku(inventoryId: number, packaging: Packaging);
}

interface SkuQuantityState {
  addingSku: boolean;
  packagingOptions: string[];
  skuQuery: string;
  typeAheadOptions: TypeAheadOption[];
  selectedInventory?: Inventory;
  selectedPackaging?: string;
}

class EnterSkuQuantities extends React.Component<SkuQuantityProps, SkuQuantityState> {
  private inventory: Inventory[];
  constructor(props) {
    super(props);

    this.state = {
      addingSku: false,
      skuQuery: '',
      packagingOptions: [],
      typeAheadOptions: []
    };
  }

  public render() {
    return (
      <div id="fulfillment_complete_and_finalize">
        <p>Print the receiving list and report received quantities below:</p>
        <a className="btn cta" href={this.props.receivingListPath} target="_blank" id="btn-print-receiving-list">
          <i className="fa fa-print" aria-hidden="true"></i> Print Receiving List
        </a>
        <table className="table confirm-quantities">
          <thead>
            <tr>
              <th>SKU</th>
              <th>Description</th>
              <th>Expected Items</th>
              <th>Shippable Items</th>
              <th>Damaged Items</th>
              <th>Total Received</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.expectedPackingLists.map((item, i) => (
              <PackingListRow
                key={`${item.inventory.sku}-${item.quantity.unit}-${i}`}
                item={item}
                onReceivedCountChange={this.props.onReceivedCountChange}
              />
            ))}
            {this.props.additionalPackingLists.map((item, i) => (
              <PackingListRow
                key={`${item.inventory.sku}-${item.quantity.unit}-${i}`}
                item={item}
                additional={true}
                onRemoveSku={this.props.onRemoveAdditionalSku}
                onReceivedCountChange={this.props.onReceivedCountChange}
              />
            ))}
            {this.state.addingSku && (
              <tr className="new-packing-list">
                <td>
                  <div className="form-group">
                    <TypeAhead
                      name="sku_select"
                      placeholder="Type to select SKU..."
                      value={this.state.skuQuery}
                      options={this.state.typeAheadOptions}
                      onRequestOptions={this.handleTypeAheadQuery}
                      onSelect={this.handleTypeAheadSelect}
                    />
                  </div>
                </td>
                <td>{this.state.selectedInventory && this.state.selectedInventory.description}</td>
                <td></td>
                <td>
                  {this.state.selectedInventory && (
                    <select onChange={this.handlePackagingSelect} value={this.state.selectedPackaging}>
                      {this.state.packagingOptions.map((packaging) => (
                        <option key={packaging} value={packaging}>
                          {packaging}
                        </option>
                      ))}
                    </select>
                  )}
                </td>
                <td>
                  {this.state.selectedInventory && (
                    <a className="btn add" onClick={this.handleAddSku}>
                      Add
                    </a>
                  )}
                </td>
                <td></td>
                <td>
                  <a
                    className="btn sku-remove flat"
                    title="Cancel adding SKU to shipment"
                    onClick={this.toggleAddAnotherSku}
                  >
                    <i className="fa fa-times"></i>
                  </a>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!this.state.addingSku && (
          <a className="btn secondary ghost sku-add" onClick={this.toggleAddAnotherSku}>
            <i className="fa fa-plus" aria-hidden="true"></i> Add another SKU
          </a>
        )}
      </div>
    );
  }

  private handleTypeAheadQuery = async (query) => {
    this.setState({skuQuery: query});
    if (query.length > 2) {
      try {
        const response = await axios.get('/widgets/inventory_picker', {
          params: {
            q: query,
            reservation_id: this.props.reservationId
          }
        });
        this.inventory = get(response, 'data.matches', []);
        const typeAheadOptions = this.inventory.map((inv) => {
          return {
            value: inv.sku,
            displayName: `${inv.sku} - ${inv.description}`
          };
        });
        this.setState({typeAheadOptions});
      } catch (error) {
        //TODO handle error
      }
    }
  };

  private toggleAddAnotherSku = () => {
    this.setState({
      addingSku: !this.state.addingSku,
      selectedInventory: null,
      skuQuery: ''
    });
  };

  private handleTypeAheadSelect = (sku) => {
    const selectedInventory = this.inventory.find((inv) => inv.sku === sku);
    const packagingOptions = selectedInventory
      ? without(Object.keys(selectedInventory.unit_conversions), 'pallet')
      : [];

    this.setState({
      skuQuery: sku,
      selectedInventory,
      selectedPackaging: packagingOptions[0],
      packagingOptions,
      typeAheadOptions: []
    });
  };

  private handlePackagingSelect = (event) => {
    this.setState({selectedPackaging: event.target.value});
  };

  private handleAddSku = () => {
    this.props.onAddAdditionalSku(this.state.selectedInventory, this.state.selectedPackaging);
    this.setState({
      addingSku: false
    });
  };
}

export default EnterSkuQuantities;
