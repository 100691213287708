import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import RetailFulfillmentService from '../shared/services/RetailFulfillmentService';
import {Company, Warehouse} from '../shared/CommonInterfaces';
import {SelectableReservation} from '../shared/ReservationSelector';
import RetailFulfillmentOrders from './RetailFulfillmentOrders';

interface AppProps {
  authenticityToken: string;
  enablePurchaseOrder: boolean;
  activeWarehouses?: Warehouse[];
  inactiveWarehouses?: Warehouse[];
  selectedWarehouse?: Warehouse;
  reservations?: SelectableReservation[];
  selectedReservation?: SelectableReservation;
  currentCompany: Company;
}

const RetailFulfillmentApp: React.FC<AppProps> = (props) => {
  const retailFulfillmentService = new RetailFulfillmentService(props.authenticityToken);

  return (
    <Router>
      <Switch>
        <Route
          path="/wh/fulfillment/retail"
          exact
          render={(routeProps) => (
            <RetailFulfillmentOrders {...routeProps} {...props} retailFulfillmentService={retailFulfillmentService} />
          )}
        />

        <Route
          path="/s/fulfillment/retail"
          exact
          render={(routeProps) => (
            <RetailFulfillmentOrders {...routeProps} {...props} retailFulfillmentService={retailFulfillmentService} />
          )}
        />
      </Switch>
    </Router>
  );
};

export default RetailFulfillmentApp;
