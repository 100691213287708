import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {isAfter} from 'date-fns';
import {cloneDeep, get, uniqWith} from 'lodash';
import {Company, Reservation} from '../../shared/CommonInterfaces';
import {ContainerDelivery, Inventory, InventoryProperties, PackingListItem} from '../shared/DropoffInterfaces';
import {DropDownOption} from '../../shared/DropDown';
import ContainersService from '../../shared/services/ContainersService';

export interface FulfillmentInboundCompletionProps {
  containerDelivery: ContainerDelivery;
  containersService: ContainersService;
  additionalPackingLists: PackingListItem[];
  expectedPackingLists: PackingListItem[];
  enableInboundPackaging: boolean;
  reservation: Reservation;
  shipperCompany: Company;
  putAwayLocation: DropDownOption;
  putAwayLocations: DropDownOption[];
  actualArrivalTime?: Date;
  palletLabelQuantities: {
    [inventoryId: string]: {
      shippable: number;
      damaged: number;
    };
  };
  updatedProperties?: {
    [inventoryId: number]: InventoryProperties;
  };
  isBolUploaded: boolean;
  handleErrors(errors: string[], response);
  reloadContainer();
  resetSuccessMessage();
  setSuccessMessage(successMessage: string);
}

const uniqByInventoryId = (a, b) => a.id === b.id;

export const FulfillmentInboundCompletion: React.FC<FulfillmentInboundCompletionProps> = (props) => {
  const [showCompleteModal, setShowCompleteModal] = React.useState<boolean>(false);
  const [disableComplete, setDisableComplete] = React.useState<boolean>(false);

  const buttonEnabled = props.isBolUploaded;
  return (
    <div className="complete-and-finalize-section">
      {showCompleteButton(
        props.containerDelivery,
        props.enableInboundPackaging,
        props.putAwayLocations && props.putAwayLocations.length > 0,
        props.isBolUploaded,
        props.putAwayLocation,
        props.actualArrivalTime
      ) && (
        // eslint-disable-next-line max-len
        <button className="btn cta btn-complete" onClick={onComplete} disabled={!buttonEnabled || isDisabled()}>
          <i className="fa fa-check"></i>
          Complete and Finalize Delivery
        </button>
      )}

      <LegacyModal
        id="complete_modal"
        show={showCompleteModal}
        size="small"
        title="Complete Inbound Delivery"
        toggleModal={() => {
          setShowCompleteModal(false);
        }}
        footer={
          <div>
            <a
              className="btn"
              onClick={() => {
                setShowCompleteModal(false);
                setEnableCompleteButton();
              }}
            >
              Cancel
            </a>
            <a className="btn btn-modal-complete" onClick={completeAndFinalizeShipment}>
              Complete
            </a>
          </div>
        }
      >
        <p>
          This delivery is scheduled to arrive in the future.
          <br />
          Are you sure you want to complete this delivery early?
        </p>
      </LegacyModal>
    </div>
  );

  function onComplete() {
    const moveDate = props.containerDelivery.moveDate;
    const today = new Date();
    setDisableCompleteButton();
    if (isAfter(moveDate, today)) {
      setShowCompleteModal(true);
    } else {
      completeAndFinalizeShipment();
    }
  }

  function isDisabled() {
    // eslint-disable-next-line no-console
    return disableComplete;
  }

  function setDisableCompleteButton() {
    setDisableComplete(true);
  }

  function setEnableCompleteButton() {
    setDisableComplete(false);
  }

  async function completeAndFinalizeShipment() {
    const inventoriesUpdated = newInventoriesUpdated(
      props.additionalPackingLists,
      props.expectedPackingLists,
      props.updatedProperties
    );
    const allItems = props.expectedPackingLists.concat(props.additionalPackingLists);
    const shippableItems = getShippableItems(allItems, props.palletLabelQuantities);
    const damagedItems = getDamagedItems(allItems, props.palletLabelQuantities);
    const shouldRequestProperties = props.shipperCompany.enableTihiDuringInbound;

    if (!inventoriesUpdated && shouldRequestProperties) {
      let errors = ['New inventories require Ti x Hi to be specified.'];
      props.handleErrors(errors, null);
      errors = [];
    } else {
      if (shippableItems.length || damagedItems.length) {
        const response = await props.containersService.completeAndFinalizeShipment({
          id: props.containerDelivery.id,
          locationId: props.putAwayLocation ? Number(props.putAwayLocation.value) : null,
          shippableInventories: shippableItems,
          damagedInventories: damagedItems,
          reservationId: props.reservation.id,
          actualArrivalTime: props.actualArrivalTime,
          actualInboundPackagingId: props.containerDelivery.actualInboundPackagingId
        });
        if (response && response.errors.length === 0) {
          {
            props.setSuccessMessage('Delivery has been completed!');
          }
          props.resetSuccessMessage();
          props.reloadContainer();
        } else {
          setEnableCompleteButton();
          let errors;
          {
            errors = [`There was an error completing delivery #${props.containerDelivery.id}`];
          }
          props.handleErrors(errors, response);
        }
      } else {
        const errors = ['Shippable and/or damaged quantities must be specified.'];
        props.handleErrors(errors, null);
      }
    }
    setShowCompleteModal(false);
  }
};

function getShippableItems(allItems, palletLabelQuantities) {
  const items = [];
  allItems.forEach((item) => {
    const itemParams = {
      inventoryId: item.inventory.id,
      quantity: item.quantity,
      pallets: 0
    };
    if (item.shippableAmount) {
      const shippableItem = cloneDeep(itemParams);
      shippableItem.quantity.amount = item.shippableAmount;
      shippableItem.pallets = get(palletLabelQuantities[item.inventory.id], 'shippable', 0);
      items.push(shippableItem);
    }
  });
  return items;
}

function getDamagedItems(allItems, palletLabelQuantities) {
  const items = [];
  allItems.forEach((item) => {
    const itemParams = {
      inventoryId: item.inventory.id,
      quantity: item.quantity,
      pallets: 0
    };
    if (item.damagedAmount) {
      const damagedItem = cloneDeep(itemParams);
      damagedItem.quantity.amount = item.damagedAmount;
      damagedItem.pallets = get(palletLabelQuantities[item.inventory.id], 'damaged', 0);
      items.push(damagedItem);
    }
  });
  return items;
}

function showCompleteButton(
  containerDelivery: ContainerDelivery,
  enableInboundPackaging: boolean,
  checkForPutAwayLocation: boolean,
  isBolUploaded: boolean,
  putAwayLocation?: DropDownOption,
  actualArrivalTime?: Date
) {
  let showButton;
  showButton = isBolUploaded && (!!containerDelivery.actualArrivalTime || !!actualArrivalTime);
  if (checkForPutAwayLocation) {
    showButton = showButton && putAwayLocation;
  }
  if (enableInboundPackaging) {
    showButton =
      showButton && (!!containerDelivery.actualInboundPackagingId || !!containerDelivery.actualInboundPackaging);
  }
  return showButton;
}

function newInventoriesUpdated(
  additionalPackingLists: PackingListItem[],
  expectedPackingLists: PackingListItem[],
  updatedProperties: object
): boolean {
  // Gather new inventory
  const allPackingLists = additionalPackingLists.concat(expectedPackingLists);
  const inventoryInContainer = uniqWith(
    allPackingLists.filter((pl) => pl.shippableAmount || pl.damagedAmount).map((pl) => pl.inventory),
    uniqByInventoryId
  );
  const newInventories = inventoryInContainer.filter((inv) => !inv.isInUse);

  // Check if new inventory id has updated properties
  let isUpdated = true;
  if (newInventories.length > 0) {
    // When a new inventory exists but has no properties associated with it, this check will return false
    isUpdated = newInventoriesPropertiesUpdated(newInventories, updatedProperties);
  }

  return isUpdated;
}

// We only do not have updated new inventory if we
// have new inventory and we have no properties associated with them
function newInventoriesPropertiesUpdated(newInventories: Inventory[], updatedProperties: object): boolean {
  // If we have no updates return false
  if (!updatedProperties) {
    return false;
  }

  return newInventories.every((inv) => {
    return Object.keys(updatedProperties).includes(inv.id.toString());
  });
}
