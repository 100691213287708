import * as React from 'react';
import {Loader} from '@flexe/ui-components';
import {CookiesProvider} from 'react-cookie';
import {get} from 'lodash';
import WarehouseSelector from '../shared/WarehouseSelector';
import {CsvDataError, GenericUploadError, Response, ResponseError, Warehouse} from '../shared/CommonInterfaces';
import ErrorDisplay from '../shared/ErrorDisplay';
import FlexeButton from '../shared/FlexeButton';
import {UploadParams} from '../shipper/edi-files/EdiFilesInterfaces';
import CsvPostUploadStep from '../shared/CsvPostUploadStep';
import FileDownloadLink from '../shared/FileDownloadLink';
import SpaConfigurationRulesBulkUploadModal from './SpaConfigurationRulesBulkUploadModal';
import SpaConfigurationsRulesService, {SpaRule} from './SpaConfigurationsRulesService';
import {Rule} from './SpaConfigurationsInterfaces';
import {instructionsTableHeaders} from './SpaConfigurationRuleInstructionsData';
import RulesTable from './RulesTable';
import TestRulesModal from './TestRulesModal';

interface Props {
  authenticityToken: string;
  continuationToken: string;
  activeWarehouses: Warehouse[];
  inactiveWarehouses: Warehouse[];
  currentPage: number;
  pageSize: number;
  selectedWarehouse: Warehouse;
  spaConfigRulesService: SpaConfigurationsRulesService;
  displayNewRulesTable: boolean;
  handlePageSizeChange(pageSize);
  handlePageChange(currentPage);

  handleWarehouseChange(selectedWarehouse);

  handleContinuationTokenChange(continuationToken);
}

interface State {
  rules: Rule[];
  errorResponse?: Response;
  errorMessage: string;
  fetchingRules: boolean;
  totalRulesCount: number;
  uploadModalBtnDisabled: boolean;
  showCsvUploadModal: boolean;
  showTestRulesUploadModal: boolean;
  uploading: boolean;
  uploadComplete: boolean;
  responseErrors: ResponseError[];
  genericErrors: GenericUploadError[];
  csvErrors: CsvDataError[];
  instructionTableHeaderRows: React.ReactNode[][];
  requiredHeaders: string[][];
}

class SpaConfigurationsIndex extends React.Component<Props, State> {
  private continuationTokens: string[] = [];
  private templatePath = '/api/v2/putaway/warehouses/' + this.props.selectedWarehouse.id + '/rules/csv_template';
  private fileUploadType: string;

  constructor(props: Props) {
    super(props);

    this.state = {
      rules: [],
      errorResponse: null,
      errorMessage: '',
      fetchingRules: false,
      totalRulesCount: 0,
      uploadModalBtnDisabled: false,
      showCsvUploadModal: false,
      showTestRulesUploadModal: false,
      uploading: false,
      uploadComplete: false,
      responseErrors: [],
      genericErrors: [],
      csvErrors: [],
      instructionTableHeaderRows: [],
      requiredHeaders: []
    };
  }

  public async componentDidMount() {
    await this.fetchRules(this.props.selectedWarehouse.id, this.props.pageSize, this.props.continuationToken);
    await this.fetchImportInstructions(this.props.selectedWarehouse.id);
  }

  public render() {
    return (
      <div className="container-fluid spa-config-index">
        <ErrorDisplay errorResponse={this.state.errorResponse} errorText={this.state.errorMessage} />
        <div className="row">
          <div className="col-sm-6">
            <h1> Zones </h1>
            <h4>
              <CookiesProvider>
                <WarehouseSelector
                  selectedWarehouse={this.props.selectedWarehouse}
                  activeWarehouses={this.props.activeWarehouses}
                  inactiveWarehouses={this.props.inactiveWarehouses}
                  onSelect={this.handleWarehouseSelected}
                />
              </CookiesProvider>
            </h4>
          </div>
          <div className={'col-md-6 spa-csv-buttons'}>
            {this.props.displayNewRulesTable && (
              <>
                <FlexeButton
                  id="config-spa-test-zone-rules-button"
                  isDisabled={this.state.rules.length < 1}
                  text={<>Test Zone Rules</>}
                  handleClick={this.toggleTestRulesUploadModal}
                />
              </>
            )}

            <>
              <FileDownloadLink
                testId={'config-spa-table-download-button'}
                href={`/api/v2/putaway/warehouses/${this.props.selectedWarehouse.id}/rules/export`}
                text={'Download CSV'}
                showAsButton
              />
            </>
            <>
              <FlexeButton
                id="bulk_import_button"
                level="primary"
                isDisabled={this.state.uploadModalBtnDisabled}
                text={
                  <>
                    <i className="fa fa-upload"></i>
                    Upload CSV
                  </>
                }
                handleClick={this.toggleShowCsvUploadModal}
              />
            </>
          </div>
        </div>
        <div>
          {this.state.uploadComplete && (
            <CsvPostUploadStep
              responseErrors={this.state.responseErrors}
              genericErrors={this.state.genericErrors}
              csvErrors={this.state.csvErrors}
            />
          )}
        </div>
        {this.state.fetchingRules ? (
          <Loader loading={true} />
        ) : (
          <RulesTable
            warehouseId={this.props.selectedWarehouse.id}
            pageSize={this.props.pageSize}
            rules={this.state.rules}
            totalRulesCount={this.state.totalRulesCount}
            currentPage={this.props.currentPage}
            handlePageChange={this.handlePagination.bind(this)}
            handlePageSizeChange={this.changePageSize.bind(this)}
            displayNewRulesTable={this.props.displayNewRulesTable}
          />
        )}
        <SpaConfigurationRulesBulkUploadModal
          id="bulk-container-create-modal"
          title={`Create SPA Rules From a CSV`}
          actionDescription={`create SPA rules`}
          show={this.state.showCsvUploadModal}
          templatePath={this.templatePath}
          toggleModal={this.toggleShowCsvUploadModal}
          instructionsTableRows={this.state.instructionTableHeaderRows}
          uploading={this.state.uploading}
          instructionsTableHeaders={instructionsTableHeaders}
          handleUpload={this.handleUpload}
          backwardsCompatible={false}
          requiredHeaders={this.state.requiredHeaders}
          fileUploadType={this.fileUploadType}
        />

        <TestRulesModal
          warehouseId={this.props.selectedWarehouse.id}
          id="test-putaway-rules"
          title={`Test Putaway Rules`}
          actionDescription={`create SPA rules`}
          show={this.state.showTestRulesUploadModal}
          toggleModal={this.toggleTestRulesUploadModal}
          uploading={this.state.uploading}
          spaConfigurationsRulesService={this.props.spaConfigRulesService}
        />
      </div>
    );
  }

  private toggleTestRulesUploadModal = () => {
    this.setState({
      showTestRulesUploadModal: !this.state.showTestRulesUploadModal
    });
  };

  private toggleShowCsvUploadModal = () => {
    this.setState({
      showCsvUploadModal: !this.state.showCsvUploadModal
    });
  };

  private handleUpload = async (params: UploadParams, type: string) => {
    this.setState({uploading: true});
    const warehouseId = this.props.selectedWarehouse.id;
    const apiParams = {
      content: params.file,
      fileType: type
    };

    const response = await this.props.spaConfigRulesService.importRules(apiParams, warehouseId);
    this.setState({
      genericErrors: Object.keys(response.data).length ? response.data.errors.generic_errors : [],
      csvErrors: Object.keys(response.data).length ? response.data.errors.csv_errors : [],
      responseErrors: response.errors,
      showCsvUploadModal: false,
      uploading: false,
      uploadComplete: true
    });
    await this.fetchRules(warehouseId, this.props.pageSize, this.props.continuationToken);
  };

  private async fetchRules(warehouseId: number, pageSize: number, continuationToken?: string) {
    this.setState({fetchingRules: true});
    this.setState({errorResponse: null});

    this.setState({errorMessage: ''});
    try {
      const response = await this.props.spaConfigRulesService.getRules(warehouseId, pageSize, continuationToken);

      if (response.errors.length > 0) {
        const errorMessage: string = this.props.spaConfigRulesService.processErrors(response.errors).join(', ');
        this.setState({errorMessage});
        this.setState({rules: [], totalRulesCount: 0});
        return;
      }

      const nextPageToken = response.data.continuationToken;
      const spaRules = response.data.rules;
      const totalRulesCount = response.data.fullResultSetSize;
      if (nextPageToken && this.props.currentPage + 1 > this.continuationTokens.length) {
        this.continuationTokens.push(nextPageToken);
      }
      const mappedRules = spaRules ? spaRules.map((spaRule, index) => this.mapRules(spaRule, index)) : [];
      this.setState({
        rules: mappedRules,
        totalRulesCount
      });
    } catch (err) {
      this.setState({errorResponse: err});
    } finally {
      this.setState({fetchingRules: false});
    }
  }

  private async fetchImportInstructions(warehouseId: number) {
    try {
      const response = await this.props.spaConfigRulesService.fetchImportInstructions(warehouseId);
      const instructions = response.data.import_instructions;

      const instructionTableHeaderRows = [];
      const requiredHeaders = [];

      instructions.forEach((header) => {
        // tslint:disable-next-line:max-line-length
        const headerRow = [
          header.name,
          header.isRequired,
          header.description,
          header.input_guidelines,
          header.sample_values
        ];
        instructionTableHeaderRows.push(headerRow);
        requiredHeaders.push([header.name.trim().toUpperCase(), header.isRequired]);
      });

      this.setState({
        instructionTableHeaderRows,
        requiredHeaders
      });
    } catch (errorResponse) {
      //TBD
    }
  }

  private async handlePagination(page: number) {
    let continuationToken = page > 1 ? this.continuationTokens[page - 2] : null;
    if (this.props.displayNewRulesTable) {
      continuationToken = page > 0 ? this.continuationTokens[page - 1] : null;
    }
    this.props.handleContinuationTokenChange(continuationToken);
    this.props.handlePageChange(page);
    await this.fetchRules(this.props.selectedWarehouse.id, this.props.pageSize, continuationToken);
  }

  private handleWarehouseSelected = async (selectedWarehouse: Warehouse) => {
    // Set the selected warehouse, reset to page 0, and clear any selected rules
    const currentPage = this.props.displayNewRulesTable ? 0 : 1;
    this.props.handlePageChange(currentPage);
    this.props.handleWarehouseChange(selectedWarehouse);

    this.resetContinuationTokens();
    // Fetch the rules for the selected warehouse
    await this.fetchRules(selectedWarehouse.id, this.props.pageSize, this.props.continuationToken);
  };

  private resetContinuationTokens() {
    this.continuationTokens = []; // Reset any saved continuation tokens
    this.props.handleContinuationTokenChange(null);
  }

  private changePageSize = (pageSize: number) => {
    const currentPage = this.props.displayNewRulesTable ? 0 : 1;
    this.resetContinuationTokens();
    this.props.handlePageSizeChange(pageSize);
    this.props.handlePageChange(currentPage);
    this.fetchRules(this.props.selectedWarehouse.id, pageSize, null);
  };

  private mapRules(rule: SpaRule, index: number): Rule {
    return {
      id: get(rule, 'priority'),
      reservationId: get(rule, 'reservation_id') == null ? ' ' : get(rule, 'reservation_id').toString(),
      sku: get(rule, 'sku') == null ? ' ' : get(rule, 'sku').toString(),
      packaging: get(rule, 'packaging') == null ? ' ' : get(rule, 'packaging'),
      itemVelocity: get(rule, 'itemVelocity') == null ? ' ' : get(rule, 'itemVelocity'),
      isHazmat: get(rule, 'isHazmat') == null ? ' ' : get(rule, 'isHazmat').toString(),
      isLotCodeTracked: get(rule, 'isLotCodeTracked') == null ? ' ' : get(rule, 'isLotCodeTracked').toString(),
      isExpirationDateTracked:
        get(rule, 'isExpirationDateTracked') == null ? ' ' : get(rule, 'isExpirationDateTracked').toString(),
      isShipAlone: get(rule, 'isShipAlone') == null ? ' ' : get(rule, 'isShipAlone').toString(),
      isShipAsIs: get(rule, 'isShipAsIs') == null ? ' ' : get(rule, 'isShipAsIs').toString(),
      zoneNames: get(rule, 'zoneNames') == null ? ' ' : get(rule, 'zoneNames'),
      routeToLocationCategory: get(rule, 'locationCategories') == null ? ' ' : get(rule, 'locationCategories'),
      routeToLocation: get(rule, 'locationLabels') == null ? ' ' : get(rule, 'locationLabels'),
      csa: get(rule, 'csa')
    };
  }
}

export default SpaConfigurationsIndex;
