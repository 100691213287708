import * as React from 'react';
import {
  BatchErrorGroupingMethods,
  BatchErrorTemplates,
  BatchErrorType,
  PickMethod
} from '../ecommerce-batches/BatchInterfaces';
import {SkuLeaderboard} from './WaveInterfaces';
import {
  InsufficientInventoryError,
  InsufficientQuantityError,
  InternalDetail,
  ItemExceedsDimsDetail,
  ItemMissingDimsDetail,
  ItemNeedsRestockDetail,
  LocationLocked,
  NoAvailableCartonForShipment,
  NoAvailableInventoryForShipment,
  NoPickCartConfiguredDetail,
  NoRebinConfiguredDetail,
  NoSuitablePickCartFoundDetail,
  ShipmentRecartonized,
  ShipmentTooBigDetail,
  UnableToSplitShipmentsDetail
} from './BatchErrorDetail';
import {ConflictingSkuRequirementsForShipment} from './waving-errors/ConflictingSkuRequirementsForShipment';

export const SINGLE_SKU_SINGLE_ITEM_SHIPMENT_PROFILE = 'single_sku_single_item';
export const SINGLE_SKU_MULTI_ITEM_SHIPMENT_PROFILE = 'single_sku_multi_item';
export const MULTI_SKU_SHIPMENT_PROFILE = 'multi_sku';

export const SINGLE_SKU_SINGLE_ITEM_LEADER_BOARD: SkuLeaderboard = {
  orderTotal: 0,
  skuSummary: [],
  dataHasLoaded: false,
  isMultiSku: false,
  isMultiUnit: false,
  shipmentProfile: SINGLE_SKU_SINGLE_ITEM_SHIPMENT_PROFILE,
  defaultPickMethod: PickMethod.BULK
};

export const SINGLE_SKU_MULTI_ITEM_LEADER_BOARD: SkuLeaderboard = {
  orderTotal: 0,
  skuSummary: [],
  dataHasLoaded: false,
  isMultiSku: false,
  isMultiUnit: true,
  shipmentProfile: SINGLE_SKU_MULTI_ITEM_SHIPMENT_PROFILE,
  defaultPickMethod: PickMethod.CLUSTER
};

export const MULTI_SKU_LEADER_BOARD: SkuLeaderboard = {
  orderTotal: 0,
  skuSummary: [],
  dataHasLoaded: false,
  isMultiSku: true,
  isMultiUnit: true,
  shipmentProfile: MULTI_SKU_SHIPMENT_PROFILE,
  defaultPickMethod: PickMethod.CLUSTER
};

const ITEM_EXCEEDS_DIMS_TEMPLATE = {
  groupErrorsBy: BatchErrorGroupingMethods.SKU,
  groupIntoOne: false,
  getErrorTemplate: (alert) => <ItemExceedsDimsDetail alert={alert} />
};

const ITEM_MISSING_DIMS_TEMPLATE = {
  groupErrorsBy: BatchErrorGroupingMethods.SKU,
  groupIntoOne: false,
  getErrorTemplate: (alert) => <ItemMissingDimsDetail alert={alert} />
};

const SHIPMENT_TOO_BIG_TEMPLATE = {
  groupIntoOne: true,
  getErrorTemplate: (alert) => <ShipmentTooBigDetail alert={alert} />
};

const NO_REBINS_CONFIGURED_TEMPLATE = {
  groupIntoOne: true,
  getErrorTemplate: (alert) => <NoRebinConfiguredDetail alert={alert} />
};

const NO_PICK_CARTS_CONFIGURED_TEMPLATE = {
  groupIntoOne: true,
  getErrorTemplate: (alert) => <NoPickCartConfiguredDetail alert={alert} />
};

const ITEM_EXCEEDS_PERCENTAGE_FILL_TEMPLATE = {
  groupErrorsBy: BatchErrorGroupingMethods.SKU,
  groupIntoOne: false,
  getErrorTemplate: (alert) => <ItemExceedsDimsDetail alert={alert} />
};

const INTERNAL_ERROR_TEMPLATE = {
  groupIntoOne: true,
  getErrorTemplate: (alert) => <InternalDetail alert={alert} />
};

const ITEM_NEEDS_RESTOCK_TEMPLATE = {
  groupErrorsBy: BatchErrorGroupingMethods.SKU,
  groupIntoOne: false,
  getErrorTemplate: (alert) => <ItemNeedsRestockDetail alert={alert} />
};

const NO_SUITABLE_PICK_CART_FOUND_TEMPLATE = {
  groupErrorsBy: BatchErrorGroupingMethods.SHIPMENT_PACKAGING,
  groupIntoOne: false,
  getErrorTemplate: (alert) => <NoSuitablePickCartFoundDetail alert={alert} />
};
const LOCATION_LOCKED_TEMPLATE = {
  groupErrorsBy: BatchErrorGroupingMethods.LOCATION_ID_AND_LOCK_REASON,
  groupIntoOne: false,
  getErrorTemplate: (alert) => <LocationLocked alert={alert} />
};
const INSUFFICIENT_QUANTITY_ERROR_TEMPLATE = {
  groupErrorsBy: BatchErrorGroupingMethods.SKU,
  groupIntoOne: false,
  getErrorTemplate: (alert) => <InsufficientQuantityError alert={alert} />
};
const INSUFFICIENT_INVENTORY_ERROR_TEMPLATE = {
  groupErrorsBy: BatchErrorGroupingMethods.SKU,
  groupIntoOne: false,
  getErrorTemplate: (alert) => <InsufficientInventoryError alert={alert} />
};
const SHIPMENT_RECARTONIZED = {
  groupErrorsBy: BatchErrorGroupingMethods.SHIPMENT_PACKAGING,
  groupIntoOne: false,
  getErrorTemplate: (alert) => <ShipmentRecartonized alert={alert} />
};

const NO_AVAILABLE_CARTON_FOR_SHIPMENT = {
  groupIntoOne: true,
  getErrorTemplate: (alert) => <NoAvailableCartonForShipment alert={alert} />
};

const UNABLE_TO_SPLIT_SHIPMENT = {
  groupIntoOne: true,
  getErrorTemplate: (alert) => <UnableToSplitShipmentsDetail alert={alert} />
};

const NO_AVAILABLE_INVENTORY_FOR_SHIPMENT_TEMPLATE = {
  groupIntoOne: true,
  getErrorTemplate: (alert) => <NoAvailableInventoryForShipment alert={alert} />
};

const CONFLICTING_SKU_REQUIREMENTS_TEMPLATE = {
  groupErrorsBy: BatchErrorGroupingMethods.SKU,
  groupIntoOne: true,
  getErrorTemplate: (alert) => <ConflictingSkuRequirementsForShipment alert={alert} />
};

export const BATCH_ERROR_TEMPLATE_MAP: Map<BatchErrorType | string, BatchErrorTemplates> = new Map([
  [BatchErrorType.ITEM_EXCEEDS_DIMS, ITEM_EXCEEDS_DIMS_TEMPLATE],
  [BatchErrorType.ITEM_MISSING_DIMS, ITEM_MISSING_DIMS_TEMPLATE],
  [BatchErrorType.SHIPMENT_TOO_BIG, SHIPMENT_TOO_BIG_TEMPLATE],
  [BatchErrorType.NO_REBINS_CONFIGURED, NO_REBINS_CONFIGURED_TEMPLATE],
  [BatchErrorType.NO_PICK_CARTS_CONFIGURED, NO_PICK_CARTS_CONFIGURED_TEMPLATE],
  [BatchErrorType.ITEM_EXCEEDS_PERCENTAGE_FILL, ITEM_EXCEEDS_PERCENTAGE_FILL_TEMPLATE],
  [BatchErrorType.INTERNAL_ERROR, INTERNAL_ERROR_TEMPLATE],
  [BatchErrorType.ITEM_NEEDS_RESTOCK, ITEM_NEEDS_RESTOCK_TEMPLATE],
  [BatchErrorType.SHIPMENT_RECARTONIZED, SHIPMENT_RECARTONIZED],
  [BatchErrorType.NO_AVAILABLE_CARTON_FOR_SHIPMENT, NO_AVAILABLE_CARTON_FOR_SHIPMENT],
  [BatchErrorType.NO_SUITABLE_PICK_CART_FOUND, NO_SUITABLE_PICK_CART_FOUND_TEMPLATE],
  [BatchErrorType.LOCATION_LOCKED, LOCATION_LOCKED_TEMPLATE],
  [BatchErrorType.INSUFFICIENT_QUANTITY_ERROR, INSUFFICIENT_QUANTITY_ERROR_TEMPLATE],
  [BatchErrorType.UNABLE_TO_SPLIT_SHIPMENT, UNABLE_TO_SPLIT_SHIPMENT],
  [BatchErrorType.INSUFFICIENT_INVENTORY_ERROR, INSUFFICIENT_INVENTORY_ERROR_TEMPLATE],
  [BatchErrorType.NO_AVAILABLE_INVENTORY_FOR_SHIPMENT, NO_AVAILABLE_INVENTORY_FOR_SHIPMENT_TEMPLATE],
  [BatchErrorType.CONFLICTING_SKU_REQUIREMENTS, CONFLICTING_SKU_REQUIREMENTS_TEMPLATE]
]);
