import * as React from 'react';
import {Link, RouteComponentProps, RouteProps} from 'react-router-dom';
import {cloneDeep, get, groupBy} from 'lodash';
import {format as formatDate} from 'date-fns';
import {Loader, Pagination, Table, TableHeader, Tabs} from '@flexe/ui-components';
import axios from 'axios';
import WarehouseService from '../../shared/services/WarehouseService';
import {OutboundOrder} from '../outbound-orders/OutboundOrdersInterfaces';
import OutboundOrdersService from '../outbound-orders/OutboundOrdersService';
import FileStorageService from '../file-storage/FileStorageService';
import {Company} from '../../shared/CommonInterfaces';
import {FileError, PaginationInfo, V2EdiFile, V2FileAssociation} from './EdiFilesInterfaces';
import EdiFilesService from './EdiFilesService';
import EdiFilesServiceV2 from './EdiFilesServiceV2';
import EdiFileHelpers from './EdiFileHelpers';
import InboundServiceV2, {InboundSearchShipment} from './InboundServiceV2';
import InventoryServiceV2, {Item} from './InventoryServiceV2';

interface MatchParams {
  id: string;
}

interface AssociationData<T> {
  entityType: string;
  data: T[];
  pagination: PaginationInfo;
}

type EdiFileAssociations = AssociationData<V2EdiFile>;

type OutboundOrderAssociations = AssociationData<OutboundOrder>;

type InboundOrderAssociations = AssociationData<InboundSearchShipment>;

type ItemAssociations = AssociationData<Item>;

export interface EdiErrors {
  data: FileError[];
  pagination: PaginationInfo;
}

export interface FeatureFlag {
  warehouseService: WarehouseService;
  redriveEnabled: boolean;
}

interface Props extends RouteComponentProps<MatchParams> {
  authenticityToken: string;
  ediFilesServiceV2?: EdiFilesServiceV2;
  ediFilesService?: EdiFilesService;
  fileStorageService?: FileStorageService;
  outboundOrderService?: OutboundOrdersService;
  inboundService?: InboundServiceV2;
  inventoryService?: InventoryServiceV2;
  currentCompany: Company;
  warehouseService: WarehouseService;
  adminSignedIn: boolean;
}

interface State {
  showExtra: boolean;
  errors: string[];
  loading: boolean;
  loadingTables: boolean;
  fileId: number;
  file: V2EdiFile;
  ediFileAssociations?: EdiFileAssociations;
  outboundOrderAssociations?: OutboundOrderAssociations;
  inboundOrderAssociations?: InboundOrderAssociations;
  itemAssociations?: ItemAssociations;
  fileErrors: EdiErrors;
  selectedTab: string;
  success?: string;
}

const initTableData = {
  data: [],
  pagination: {
    continuationTokens: [],
    currentPage: 1,
    totalCount: 0
  }
};

const initAssociationTableData = (entityType: string) => {
  return {
    entityType,
    data: [],
    pagination: {
      continuationTokens: [],
      currentPage: 1,
      totalCount: 0
    }
  };
};

class EdiFileDetail extends React.Component<Props & RouteProps, State> {
  private pageSize = 50;
  private ediFilesService: EdiFilesService;
  private ediFilesServiceV2: EdiFilesServiceV2;
  private fileStorageService: FileStorageService;
  private inventoryService: InventoryServiceV2;
  private outboundOrderService: OutboundOrdersService;
  private inboundService: InboundServiceV2;
  private shipperName: string;
  private redriveEnabled: boolean;
  private warehouseService: WarehouseService;
  private emptyFile: V2EdiFile = {
    metadata: null,
    id: null,
    createdAt: null,
    processedAt: null,
    storageObjectName: null,
    fileKey: null,
    translationFileKey: null,
    externalId: null,
    type: null,
    hasErrors: false
  };
  private notQualifiedEdiFileTypes = [
    'inbound',
    'outbound',
    'inbound_flexe_create_v1_outboundOrder_csv',
    'outbound_flexe_success_v1_outboundOrder_csv',
    'outbound_flexe_error_v1_outboundOrder_csv',
    'outbound_flexe_error_v2_outboundOrder_csv',
    'outbound_flexe_success_v2_outboundOrder_csv',
    'inbound_flexe_create_v2_outboundOrder_csv',
    'outbound_flexe_error_v2_outboundOrder_csv',
    'outbound_flexe_success_v2_outboundOrder_csv',
    'no_action'
  ];
  constructor(props) {
    super(props);
    this.inventoryService = props.inventoryService || new InventoryServiceV2();
    this.outboundOrderService = props.outboundOrderService || new OutboundOrdersService();
    this.inboundService = props.inboundService || new InboundServiceV2();
    this.ediFilesService = props.ediFilesService || new EdiFilesService(props.authenticityToken);
    this.ediFilesServiceV2 = props.ediFilesServiceV2 || new EdiFilesServiceV2();
    this.fileStorageService = props.FileStorageService || new FileStorageService();
    this.shipperName = get(props, 'currentCompany.name') || 'Shipper';
    this.redriveEnabled = props.redriveEnabled;
    this.warehouseService = new WarehouseService(props.authenticityToken);

    this.state = {
      showExtra: false,
      errors: null,
      loading: true,
      loadingTables: true,
      fileId: Number(props.match.params.id),
      file: this.emptyFile,
      ediFileAssociations: initAssociationTableData('EdiFile'),
      outboundOrderAssociations: initAssociationTableData('OutboundOrder'),
      inboundOrderAssociations: initAssociationTableData('ContainerDelivery'),
      itemAssociations: initAssociationTableData('Inventory'),
      fileErrors: initTableData,
      selectedTab: 'results',
      success: null
    };
  }

  public async componentDidUpdate(prevProps) {
    // only needed for case where this component links to its own route
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(
        {
          success: null,
          errors: null,
          fileId: Number(this.props.match.params.id),
          ediFileAssociations: initAssociationTableData('EdiFile'),
          outboundOrderAssociations: initAssociationTableData('OutboundOrder'),
          inboundOrderAssociations: initAssociationTableData('ContainerDelivery'),
          itemAssociations: initAssociationTableData('Inventory'),
          fileErrors: initTableData,
          selectedTab: 'results'
        },
        this.loadFile
      );
    }
  }

  public async componentDidMount() {
    this.loadFile();
    this.loadFeatureFlags();
  }

  public render() {
    const {errors, success, loading, loadingTables, file, selectedTab} = this.state;
    return (
      <div id="order-detail-component">
        <div id="topline-details" className="row no-margin">
          <div className="breadcrumbs col-xs-12">
            <Link to="/s/edi">File Exchange</Link> <i className="fa fa-angle-right"></i> File Detail
            <a
              style={{float: 'right'}}
              title={'Add extra features'}
              onClick={() => this.setState({showExtra: !this.state.showExtra})}
            >
              &nbsp;
            </a>
          </div>
          {((errors && errors.length > 0) || success) && (
            <div
              className={success ? 'alert alert-success' : 'alert alert-danger'}
              role="alert"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                position: 'relative',
                bottom: '0px',
                backgroundColor: success ? '#dff0d8' : '#f2dede'
              }}
            >
              {success && !errors && <p style={{marginTop: 0, marginBottom: 0, color: '#3c763d'}}>{success}</p>}

              {errors && (
                <ul style={{marginTop: 0, marginBottom: 0}}>
                  {errors.map((err, index) => {
                    return <li key={index}>{err}</li>;
                  })}
                </ul>
              )}
              <div className="cancel-alert" style={{marginLeft: 'auto', color: '#3c763d'}}>
                <a onClick={this.handleDismissAlert} style={{color: 'inherit'}}>
                  <i className={errors ? 'fa fa-times red3' : 'fa fa-times'}></i>
                </a>
              </div>
            </div>
          )}
          {!loading && [
            <div className="col-xs-6" key="header">
              <h2>
                {file.fileKey ? file.fileKey : EdiFilesServiceV2.getNameFromStorageObject(file.storageObjectName)}
              </h2>
              <div>
                <b>
                  <span title={file.type}>{get(EdiFilesServiceV2.localeFileTypes, file.type, file.type)}</span>
                  &nbsp;&mdash;&nbsp; Created: {formatDate(file.createdAt, 'MM/DD/YY h:mma')}
                  {file.processedAt && (
                    <span>&nbsp;&mdash;&nbsp; Processed: {formatDate(file.processedAt, 'MM/DD/YY h:mma')}</span>
                  )}
                </b>
              </div>
              {file.externalId && (
                <div>
                  <b>Reference ID: {file.externalId}</b>
                </div>
              )}
            </div>,
            <div className="col-xs-6" key="actions">
              {this.getEdiFileDownloads(file, 'pull-right', true)}
              {this.enableRedrive(file, 'pull-right', true)}
            </div>
          ]}
          <Loader loading={loading} />
        </div>
        <div className="container-fluid">
          <div className="row no-margin">
            <Tabs tabs={this.getTabs()} tabEvent={this.handleTabEvent} />
            {!loadingTables && selectedTab === 'results' ? (
              <div>
                <div className="col-xs-12 space-above">
                  <h3>Files</h3>
                  <Table tableData={this.filesTableData()} tableClass="table-striped space-below-lg" />
                </div>
                {this.displayAssociations()}
              </div>
            ) : (
              <Table tableData={this.errorsTableData()} tableClass="table-striped space-above-lg" />
            )}
            <Loader loading={loadingTables} />
          </div>
        </div>
      </div>
    );
  }

  private loadFile = async () => {
    this.setState({loading: true, loadingTables: true});
    const data = await this.ediFilesServiceV2.getFile(this.state.fileId);
    const file = get(data, 'data', this.emptyFile);
    this.setState(
      {
        file,
        loading: false
      },
      this.loadAssociationsAndErrors
    );
  };

  private loadFeatureFlags = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      const [isEdiRedriveEnable] = await axios.all([this.warehouseService.getFeatureFlag('enable_edi_redrive')]);
      this.redriveEnabled = isEdiRedriveEnable.data.value;
    } catch (errorResponse) {
      throw errorResponse;
    }
  };

  private loadAssociationsAndErrors = async () => {
    const errData = await this.ediFilesServiceV2.getErrors(this.state.fileId, this.pageSize);
    const fileErrors = {
      data: get(errData, 'data.errors', []),
      pagination: {
        continuationTokens: [],
        currentPage: 1,
        totalCount: get(errData, 'data.total', 0)
      }
    };
    this.setState({
      fileErrors,
      selectedTab: fileErrors.data.length > 0 ? 'errors' : 'results',
      loading: false
    });

    await this.loadEdiFileAssociations();
    await this.loadAssociations<OutboundOrder>('outboundOrderAssociations');
    await this.loadAssociations<InboundSearchShipment>('inboundOrderAssociations');
    await this.loadAssociations<Item>('itemAssociations');
    this.setState({
      selectedTab: this.hasAssociationResults() ? 'results' : 'errors',
      loadingTables: false
    });
  };
  private getTabs = () => {
    const tabs = [];
    if (this.hasAssociationResults()) {
      tabs.push({
        active: this.state.selectedTab === 'results',
        key: 'results',
        subTitle: 'Results',
        title: <i className="fa fa-list"></i>
      });
    }
    if (this.state.fileErrors.data.length > 0) {
      tabs.push({
        active: this.state.selectedTab === 'errors',
        key: 'errors',
        subTitle: `Errors (${this.state.fileErrors.data.length})`,
        title: <i className="fa fa-exclamation-triangle"></i>
      });
    }
    return tabs;
  };

  private handleTabEvent = (tabKey: string) => {
    if (this.state.selectedTab !== tabKey) {
      this.setState({
        selectedTab: tabKey
      });
    }
  };

  private displayAssociations = () => {
    const associationPaths = {
      outboundOrderAssociations: {
        table: 'outboundOrdersTableData',
        heading: 'Outbound orders'
      },
      inboundOrderAssociations: {
        table: 'containersTableData',
        heading: 'Containers'
      },
      itemAssociations: {
        table: 'inventoriesTableData',
        heading: 'Inventory'
      }
    };
    return Object.keys(associationPaths).map((key) => {
      const thisAssociation = get(this.state, key);
      const tableDataMethod = associationPaths[key].table;
      const heading = associationPaths[key].heading;
      return thisAssociation.data.length ? (
        <div className="col-xs-12 space-above" key={key}>
          <h3>{heading}</h3>
          <Table tableData={this[tableDataMethod]()} tableClass="table-striped space-below-lg" />
        </div>
      ) : null;
    });
  };

  private filesTableData = () => {
    const headers = [
      {element: ''},
      {element: 'Creator'},
      {element: 'Sent/Received'},
      {element: 'Status'},
      {element: 'Processed'},
      {element: 'Filename / FileKey'},
      {element: 'Download'},
      {
        className: 'pagination-header',
        element: (
          <Pagination
            page={this.state.ediFileAssociations.pagination.currentPage}
            pageSize={this.pageSize}
            paginationHandler={(page) => this.handlePagination(page, 'ediFileAssociations')}
            totalCount={this.state.ediFileAssociations.pagination.totalCount}
          />
        )
      }
    ] as TableHeader[];
    const rows = this.state.ediFileAssociations.data.map((file: V2EdiFile) => {
      const detailUrl = `/s/edi/${file.id}`;
      const row: (string | JSX.Element)[] = [
        file.hasErrors ? <i className="fa fa-exclamation-triangle red3"></i> : null,
        EdiFilesServiceV2.fileOutgoingFromFlexe(file) ? 'FLEXE' : this.shipperName,
        file.createdAt ? formatDate(file.createdAt, 'MM/DD/YY h:mma') : null,
        <span className={`label label-default ${file.processedAt ? 'bg-successGreen' : 'bg-blue3'}`}>
          {file.processedAt ? 'Processed' : 'Pending'}
        </span>,
        file.processedAt ? formatDate(file.processedAt, 'MM/DD/YY h:mma') : null,
        <Link to={detailUrl}>
          {file.fileKey ? file.fileKey : EdiFilesServiceV2.getNameFromStorageObject(file.storageObjectName)}
        </Link>,
        this.getEdiFileDownloads(file),
        <Link className="pull-right" to={detailUrl}>
          <i className="fa fa-chevron-right"></i>
        </Link>
      ];
      return row;
    });
    return {
      headers,
      rows
    };
  };

  private getEdiFileDownloads = (ediFile: V2EdiFile, addedClasses: string = '', withText: boolean = false) => {
    if (ediFile.fileKey) {
      return (
        <div>
          {ediFile.translationFileKey && (
            <a
              className={`btn ${addedClasses}`}
              title={`Download Functional Translation File For ${ediFile.fileKey}`}
              href={`/s/edi/${ediFile.id}/contents?fileKeyOption=translationFileKey`}
              onClick={async (e) => {
                e.preventDefault();
                await EdiFileHelpers.downloadFileByKey(this.fileStorageService, ediFile.translationFileKey);
              }}
            >
              {withText ? 'Download Translation' : <i className="fa fa-file-code" />}
            </a>
          )}
          <a
            className={`btn ${addedClasses}`}
            href={`/s/edi/${ediFile.id}/contents`}
            title={`Download File For ${ediFile.fileKey}`}
            onClick={async (e) => {
              e.preventDefault();
              await EdiFileHelpers.downloadFileByKey(this.fileStorageService, ediFile.fileKey);
            }}
          >
            {withText ? 'Download' : <i className="fa fa-download" />}
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  private postRedrive = async (ediFile) => {
    const errors = cloneDeep(this.state.errors) || [];
    const response = await this.ediFilesServiceV2.redriveEdiFile(ediFile.id);
    if (response && response.errors && response.errors.length > 0) {
      const newErrors = response.errors.map((err) => err.detail);
      this.setState({errors: errors.concat(newErrors)});
    } else if (response) {
      const success = 'Successfully Retry EDI request';
      this.setState({success});
    } else {
      this.setState({errors: errors.concat('Error retrying EDI request, Please try again')});
    }
  };

  private enableRedrive = (ediFile: V2EdiFile, addedClasses: string = '', withText: boolean = false) => {
    if (
      this.props.adminSignedIn &&
      this.redriveEnabled === true &&
      ediFile.processedAt !== undefined &&
      !this.notQualifiedEdiFileTypes.includes(ediFile.type)
    ) {
      if (
        ediFile.type === 'new_incoming_file' ||
        ediFile.type === 'x12_incoming_file_parse' ||
        ediFile.type === 'incoming_x12_edi_file' ||
        ediFile.type === 'incoming_unmapped_file' ||
        ediFile.type === 'incoming_xml_edi_file'
      ) {
        return (
          <a
            id="redrive-button"
            className={`btn ${addedClasses}`}
            href={`/s/edi/${ediFile.id}/contents`}
            // eslint-disable-next-line max-len
            title={`Redrive File For ${ediFile.fileKey}. Re-drive this file to translate the original customer file contents. This is primarily used when the mapping content has changed or data in the translation look up tables have changed. This may create another upsert request, and may need to confirm the underlying resource is accurate in the case an update occurs.`}
            onClick={(e) => {
              e.preventDefault();
              this.postRedrive(ediFile);
            }}
          >
            {withText ? 'Retry EDI Request' : <i className="fa fa-download" />}
          </a>
        );
      } else if (ediFile.type.includes('upsert') || ediFile.type.includes('patch')) {
        return (
          <a
            id="redrive-button"
            className={`btn ${addedClasses}`}
            href={`/s/edi/${ediFile.id}/contents`}
            // eslint-disable-next-line max-len
            title={`Redrive File For ${ediFile.fileKey}. Retry EDI request for another create or update request. Doing so will call the Flexe API with this given request contents. This is primarily used when the Flexe system was updated to allow the request to succeed. In other cases, you may create an unnecessary state change to a given order or item. Confirm the current PO/Item state and after the re-drive for desired change`}
            onClick={(e) => {
              e.preventDefault();
              this.postRedrive(ediFile);
            }}
          >
            {withText ? 'Retry EDI Request' : <i className="fa fa-download" />}
          </a>
        );
      } else if (ediFile.type.includes('outgoing')) {
        return (
          <a
            id="redrive-button"
            className={`btn ${addedClasses}`}
            href={`/s/edi/${ediFile.id}/contents`}
            // eslint-disable-next-line max-len
            title={`Redrive File For ${ediFile.fileKey}. Re send this file to the customer. This is primarily used when the customer claims they have not received the file, or this wish to receive the file again.`}
            onClick={(e) => {
              e.preventDefault();
              this.postRedrive(ediFile);
            }}
          >
            {withText ? 'Resend Edi Outgoing File' : <i className="fa fa-download" />}
          </a>
        );
      }
    }
  };

  private containersTableData = () => {
    const headers = [
      {className: 'id-header', element: 'FLEXE ID'},
      {element: 'Inbound Order #'},
      {element: 'State'},
      {element: 'ETA'},
      {
        className: 'pagination-header',
        element: (
          <Pagination
            page={this.state.inboundOrderAssociations.pagination.currentPage}
            pageSize={this.pageSize}
            paginationHandler={(page) => this.handlePagination(page, 'inboundOrderAssociations')}
            totalCount={this.state.inboundOrderAssociations.pagination.totalCount}
          />
        )
      }
    ] as TableHeader[];
    const rows = this.state.inboundOrderAssociations.data.map((c) => {
      const utcSeconds = c.move_date;
      const convertedDate = new Date(0);
      const detailsAsHtmlSeparator = c.details.split('<br>').join(' ');
      convertedDate.setUTCSeconds(utcSeconds);
      return [
        <a href={`/s/dropoffs/container/${c.id}`}>{c.id}</a>,
        detailsAsHtmlSeparator,
        c.state,
        formatDate(convertedDate, 'MM/DD/YYYY'),
        <a className="pull-right" href={c.url}>
          <i className="fa fa-chevron-right"></i>
        </a>
      ];
    });
    return {
      headers,
      rows
    };
  };

  private outboundOrdersTableData = () => {
    const headers = [
      {className: 'id-header', element: 'FLEXE ID'},
      {element: 'External ID'},
      {element: 'State'},
      {
        className: 'pagination-header',
        element: (
          <Pagination
            page={this.state.outboundOrderAssociations.pagination.currentPage}
            pageSize={this.pageSize}
            paginationHandler={(page) => this.handlePagination(page, 'outboundOrderAssociations')}
            totalCount={this.state.outboundOrderAssociations.pagination.totalCount}
          />
        )
      }
    ] as TableHeader[];
    const rows = this.state.outboundOrderAssociations.data.map((o) => {
      return [
        <a href={`/s/fulfillment/orders/${o.id}`}>{o.id}</a>,
        o.externalId,
        o.state,
        <a className="pull-right" href={`/s/fulfillment/orders/${o.id}`}>
          <i className="fa fa-chevron-right"></i>
        </a>
      ];
    });
    return {
      headers,
      rows
    };
  };

  private inventoriesTableData = () => {
    const headers = [
      {className: 'id-header', element: 'SKU'},
      {element: 'Description'},
      {
        className: 'pagination-header',
        element: (
          <Pagination
            page={this.state.itemAssociations.pagination.currentPage}
            pageSize={this.pageSize}
            paginationHandler={(page) => this.handlePagination(page, 'itemAssociations')}
            totalCount={this.state.itemAssociations.pagination.totalCount}
          />
        )
      }
    ] as TableHeader[];
    const rows = this.state.itemAssociations.data.map((i) => {
      return [
        <a href={`/s/inventories/${i.id}`}>{i.sku}</a>,
        i.description,
        <a className="pull-right" href={`/s/inventories/${i.id}`}>
          <i className="fa fa-chevron-right"></i>
        </a>
      ];
    });
    return {
      headers,
      rows
    };
  };

  private errorsTableData = () => {
    const headers = [
      {element: 'Errors'},
      {
        className: 'pagination-header',
        element: (
          <Pagination
            page={this.state.fileErrors.pagination.currentPage}
            pageSize={this.pageSize}
            paginationHandler={(page) => this.handlePagination(page, 'fileErrors')}
            totalCount={this.state.fileErrors.pagination.totalCount}
          />
        )
      }
    ] as TableHeader[];
    const rows = this.state.fileErrors.data.map((e) => {
      const metadata = get(e, 'metadata', null);
      return [
        <div key={`error_metadata_${e.id}`}>
          <span className="red3">
            <b>{e.errorCode}</b> {e.description}
          </span>
          {metadata === null ? null : (
            <ul className="red3 no-margin">
              {Object.keys(metadata).map((key, i) => {
                return (
                  <li key={`error_metadata_${e.id}_${key}_${i}`}>
                    <b>{key}</b>: {metadata[key]}
                  </li>
                );
              })}
            </ul>
          )}
        </div>,
        null
      ];
    });
    return {
      headers,
      rows
    };
  };

  private async handlePagination(page: number, associationStateKey: string) {
    this.setState({
      loading: true
    });
    const pagination = get(this.state, associationStateKey);
    const continuationToken = page > 1 ? pagination.continuationTokens[page - 2] : null;
    const updatedContinuationTokens = pagination.continuationTokens.slice();

    switch (associationStateKey) {
      case 'fileErrors':
        // eslint-disable-next-line no-case-declarations
        const errorData = await this.ediFilesServiceV2.getErrors(this.state.fileId, this.pageSize, continuationToken);
        // eslint-disable-next-line no-case-declarations
        const newContToken = get(errorData, 'data.data.continuationToken', null);
        updatedContinuationTokens.push(newContToken);
        // eslint-disable-next-line no-case-declarations
        const fileErrors = cloneDeep(this.state.fileErrors);
        fileErrors.pagination.continuationTokens = updatedContinuationTokens;
        this.setState({
          fileErrors
        });
        break;
      case 'ediFileAssociations':
        await this.loadEdiFileAssociations(continuationToken);
        break;
      default:
        await this.loadAssociations<Item | OutboundOrder | InboundSearchShipment>(
          associationStateKey,
          continuationToken
        );
        break;
    }
  }

  private loadEdiFileAssociations = async (continuationToken: string = null) => {
    const fileData = await this.ediFilesServiceV2.getFiles(
      {
        entityType: 'EdiFile',
        entityId: this.state.fileId
      },
      this.pageSize,
      continuationToken
    );
    const ediFileAssociations = {
      entityType: 'EdiFile',
      data: get(fileData, 'data.files', []),
      pagination: {
        continuationTokens: [],
        currentPage: 1,
        totalCount: get(fileData, 'data.total', 0)
      }
    };
    const selectedTab = ediFileAssociations.data.length > 0 ? 'results' : 'errors';
    this.setState({
      ediFileAssociations,
      selectedTab
    });
  };

  private getResourceData = (
    associationKey: string,
    distinctIds: string[]
  ): Promise<Item | OutboundOrder | InboundSearchShipment>[] => {
    switch (associationKey) {
      case 'itemAssociations':
        return distinctIds.map(async (entityId) => {
          return await this.inventoryService.getItem(entityId);
        });
      case 'inboundOrderAssociations':
        return distinctIds.map(async (entityId) => {
          const data = await this.inboundService.search({id: entityId});
          let shipments: InboundSearchShipment[] = get(data, 'shipments', []);
          const counts = get(data, 'counts');
          const stateKey = Object.keys(counts).find((countKey) => {
            return counts[countKey] > 0;
          });
          // Because this API does not respond the value if the state is not provided not in progress,
          // we have to make an additional call to get the data in other states specifically.
          if (shipments.length === 0 && stateKey) {
            const inboundDataForState = await this.inboundService.search({
              id: entityId,
              state: stateKey
            });
            shipments = get(inboundDataForState, 'shipments', []);
          }
          return shipments.pop();
        });
      case 'outboundOrderAssociations':
        return distinctIds.map(async (entityId) => {
          return await this.outboundOrderService.getOutboundOrder(entityId);
        });
    }
  };

  /**
   * Load associations from the given state key for the associations.
   * @param associationKey the key for the association used to look up the value in state.
   * @param continuationToken the token to view more associations
   */
  private async loadAssociations<T extends Item | OutboundOrder | InboundSearchShipment>(
    associationKey: string,
    continuationToken: string = null
  ) {
    const associationState: AssociationData<Item | OutboundOrder | InboundSearchShipment> = get(
      this.state,
      associationKey,
      null
    );
    if (associationState) {
      const currentAssociations: (Item | OutboundOrder | InboundSearchShipment)[] = get(associationState, 'data', []);
      const entityType = associationState.entityType;
      const currentPaginationTokens: string[] = get(associationState, 'paginationInfo.continuationTokens', []);
      await this.ediFilesServiceV2
        .getFileAssociations(this.state.fileId, associationState.entityType, this.pageSize, continuationToken)
        .then(async (fileAssociationData) => {
          const associations: V2FileAssociation[] = get(fileAssociationData, 'data.associations', []);
          const associationCount: number = get(fileAssociationData, 'data.total', 0);
          const contToken: string = get(fileAssociationData, 'data.continuationToken');
          const distinctIds = groupBy(associations, 'entityId');
          const tokens = currentPaginationTokens.concat([]);
          tokens.push(contToken);
          const newObjects = this.getResourceData(associationKey, Object.keys(distinctIds));
          Promise.all(newObjects)
            .then((awaitedItems: (Item | OutboundOrder | InboundSearchShipment)[]) => {
              const newState = {
                selectedTab: awaitedItems.length > 0 ? 'results' : this.state.selectedTab
              };
              newState[associationKey] = {
                entityType,
                data: currentAssociations.concat(awaitedItems),
                pagination: {
                  continuationTokens: tokens,
                  currentPage: get(entityType, 'pagination.currentPage', 1),
                  totalCount: associationCount
                }
              };
              this.setState(newState);
            })
            .finally(() => {
              this.setState({
                loadingTables: false
              });
            });
        });
    }
  }

  private hasAssociationResults = () => {
    const hasEdiFileAssociations = this.state.ediFileAssociations.data.length > 0;
    const hasOutboundAssociations = this.state.outboundOrderAssociations.data.length > 0;
    const hasInboundAssociations = this.state.inboundOrderAssociations.data.length > 0;
    const hasItemAssociations = this.state.itemAssociations.data.length > 0;
    return hasEdiFileAssociations || hasOutboundAssociations || hasInboundAssociations || hasItemAssociations;
  };

  private handleDismissAlert = async (event) => {
    event.preventDefault();
    this.setState({errors: null, success: null});
  };
}

export default EdiFileDetail;
