import {useEffect, useState} from 'react';
import {flatten, groupBy} from 'lodash';
import {Shipment} from '../ShipmentInterfaces';
import {getMostGranularCommonUnit, TLpnContent} from '../ShipmentHelper';
import {Packaging} from '../../../shared/CommonInterfaces';
import {useManifestLpns} from './UseManifestLpns';

export const useIsShipmentShort = (shipment: Shipment, isFreightTrailerLoadingEnabled: boolean) => {
  const [isShort, setIsShort] = useState(false);
  const [shortContents, setShortContents] = useState<TLpnContent[]>([]);

  const {hasFetched, manifestLpns} = useManifestLpns(shipment, isFreightTrailerLoadingEnabled);

  useEffect(() => {
    if (!isFreightTrailerLoadingEnabled || !hasFetched) {
      return;
    }

    const qtyByInventoryId = expectedQtyByInventoryId(shipment);
    const shippedContents = flatten(
      manifestLpns.filter((lpn) => lpn.txn_state === 'shipped').map((lpn) => lpn.manifest_contents)
    );
    const requestedShippedContents = shippedContents.filter((contents) =>
      Object.keys(qtyByInventoryId).includes(String(contents.inventory_id))
    );

    const qtyDiffByInventoryId = requestedShippedContents.reduce((qty, content) => {
      const {quantity: requestedQty, unitOfMeasure: requestedUom} = qty[content.inventory_id];

      const remainingRequestedQty = requestedQty ?? 0;
      const contentQty = content.quantity_in_all_units[requestedUom];

      return {
        ...qty,
        [content.inventory_id]: {
          ...qty[content.inventory_id],
          quantity: remainingRequestedQty - contentQty
        }
      };
    }, qtyByInventoryId);
    const calculatedShortContents = Object.values(qtyDiffByInventoryId)
      .filter((qtyDiff) => qtyDiff.quantity > 0)
      .map(
        (qtyDiff) =>
          ({
            quantity: qtyDiff.quantity,
            unitOfMeasure: qtyDiff.unitOfMeasure
          } as TLpnContent)
      );

    setIsShort(calculatedShortContents.length > 0);
    setShortContents(calculatedShortContents);
  }, [isFreightTrailerLoadingEnabled, manifestLpns]);

  return {
    isShort,
    shortContents
  };
};

interface ExpectedQuantity {
  quantity: number;
  unitOfMeasure: Packaging;
}

function expectedQtyByInventoryId(shipment: Shipment) {
  const itemsByInventoryId = groupBy(shipment.line_items, (item) => item.inventory_id);

  return Object.entries(itemsByInventoryId).reduce((map, [inventoryId, items]) => {
    const mostGranularUnit = getMostGranularCommonUnit(items, {useOrderedQty: true});
    return {
      ...map,
      [inventoryId]: {
        quantity: items.reduce((qty, item) => qty + item.ordered_units_per_packaging[mostGranularUnit], 0),
        unitOfMeasure: mostGranularUnit
      }
    };
  }, {} as Record<string, ExpectedQuantity>);
}
