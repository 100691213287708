// TODO - consider ways to maintain color codes in one place for CSS and JS
// (make colors.scss variables accessible to JS with webpack?)
export enum FlexeColors {
  black = '#0c101c',
  successGreen = '#24c26a',
  warningYellow = '#ffcc3d',
  helpOrange = '#f5a623',
  newPurple = '#8157b7',

  blue1 = '#072973',
  blue2 = '#0b3ba6',
  blue3 = '#1259ee',
  blue4 = '#7099f5',
  blue6 = '#d4e0fc',

  skyBlue3 = '#1295ee',
  skyBlue6 = '#daebfd',

  red1 = '#751e1e', // hover state on buttons icons (if applicable)
  red2 = '#b83030', // used for error text
  red3 = '#e73d3d', // typical error fill/stroke in UI elements
  red6 = '#fad5d5', // error highlight

  grey0 = '#222530',
  grey1 = '#373b48',
  grey2 = '#555968',
  grey3 = '#7a808d',
  grey4 = '#a1a7b1',
  grey5 = '#c5cad2',
  grey6 = '#e1e4e8',
  grey7 = '#f5f6f7'
}

export enum BatchingMethod {
  MANUAL = 'Manual waving',
  AUTO = 'Auto batching'
}

export enum ActorType {
  user = 'User',
  admin = 'AdminUser',
  mobile = 'MobileUser'
}

export enum Packaging {
  each = 'each',
  carton = 'carton',
  pallet = 'pallet'
}

export enum PackagingToValue {
  each = 1,
  carton = 3,
  pallet = 5
}
export interface ItemQuantity {
  unit: Packaging;
  amount: number;
}

export interface QuantityInAllUnits {
  each: number;
  carton: number;
  pallet: number;
}

export enum CompanyType {
  shipper = 'shipper',
  warehouse = 'warehouse'
}

export enum LengthUnit {
  in = 'in',
  ft = 'ft',
  cm = 'cm',
  m = 'm'
}

export interface SortOption {
  displayName: string;
  sortBy: string;
  sortOrder: SortOrder;
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export interface ActorInfo {
  type: string;
  id: number;
  // TODO - update APIs using displayName to use name instead
  name?: string;
  displayName?: string;
}

export interface ActionInfo {
  by: ActorInfo;
  at: string;
}

export interface ExpandoData {
  headerText: string | JSX.Element;
  headerIcon?: JSX.Element;
  content: JSX.Element;
  hide?: boolean;
  defaultOpen?: boolean;
}

export interface CarrierAccount {
  carrierAccountId: number;
  description: string;
  carrier: string;
  carrierDisplayName: string;
  ownerCompanyId: number;
  ownerCompanyName: string;
  accountNumber?: string;
  txnState: string;
  serviceLevels: string[];
  reservations: Reservation[];
}

export interface ReservationCarrierAccountResponse {
  carrierAccounts: CarrierAccount[];
}

export interface Counts {
  [key: string]: number;
}

export interface Conversions {
  each: number;
  carton: number;
  pallet: number;
}

export interface UnitConversions {
  each?: Conversions;
  carton?: Conversions;
  pallet?: Conversions;
}

export interface ApiResponse<T> {
  meta: ResponseMeta;
  links: ResponseLinks;
  data: T;
  errors: ResponseError[];
}

export interface PaginatedResponse {
  continuationToken?: string;
}

export interface ApiResponseV2 {
  [key: string]: any;
  errors: ResponseErrorV2[];
}

export interface ApiRequest<T> {
  authenticity_token: string;
  meta: RequestMeta;
  data: T;
}

export interface RequestMeta {
  correlationId: string;
}

export interface Response {
  response: {
    data: ApiResponse<object>;
  };
  message?: string;
}

export interface ResponseMeta {
  responseCreatedAt: string;
  correlationId: string;
}

export interface ResponseLinks {
  self: string;
}

export interface ResponseError {
  status: number;
  title: string;
  detail: string;
  source: ResponseErrorSource;
}

export interface ResponseErrorV2 {
  code: string;
  title: string;
  detail: string;
  source: ResponseErrorSource;
}

export interface ResponseErrorSource {
  pointer: string;
  parameter?: string;
}

export interface Pagination {
  pageSize?: number;
  sortOrder?: SortOrder;
  sortBy?: string;
  continuationToken: string;
}

export interface FilterOption {
  displayName: string;
  value: string | number;
}

export interface Filter {
  displayName: string;
  key: string;
  type: FilterType;
  placeHolder?: string;
  allowMultiple?: boolean;
  typeAheadHandler?: any;
  options?: FilterOption[];
  value?: string | number | (string | number)[];
  defaultKey?: string;
}

export enum FilterType {
  DataList,
  Date,
  Dropdown,
  Number, // eslint-disable-line id-denylist
  Present,
  String, // eslint-disable-line id-denylist
  TypeAhead,
  Radio,
  LabelSearch
}

export interface FilterValue {
  filter: Filter;
  value: any;
}

/*
 * NOTE: There exists multiple diverse 'Address' interfaces across components in this React codebase,
 * and they are all ~99% the same. Avoid duplication by updating and using this interface where possible.
 */
export interface AddressData {
  id?: number;
  owner?: any; // likely to be an object containing an id and 'type'
  name?: string;

  line1: string;
  line2?: string;
  line3?: string;
  locality: string; // More generic term for town/city
  region: string; // More generic term for state/province/territory
  postcode: string;
  country: string;
  phone?: string;

  city?: string; // DO NOT USE. Prefer the locality field.
  line4?: string; // There is currently no evidence any address needs this, but our DB schema has it.
}

export interface ShipToAddressErrors {
  name?: string | boolean;
  line1?: string | boolean;
  line2?: string | boolean;
  city?: string | boolean;
  locality?: string | boolean;
  region?: string | boolean;
  postcode?: string | boolean;
  country?: string | boolean;
  phone?: string | boolean;
}

// TODO - clean up redundant interfaces representing inventory/items outside this file and
// retire obsolete fields within these shared interfaces here as well (requires updating json APIs)
// https://flexe-inc.atlassian.net/browse/WSE-1316
export interface Inventory {
  id: number;
  sku: string;
  description: string;
  eaches?: number;
  unitConversions?: UnitConversions;
  eachesPerCarton?: number;
  isInUse?: boolean;
  // TODO - retire these snakecase keys (in both typescript and warehouser APIs)
  smallest_packaging?: Packaging;
  unit_conversions?: UnitConversions;
}

export interface Item {
  id?: number;
  sku: string;
  description?: string;
  quantity: number;
  selectedQuantity?: number;
  packaging: Packaging;
  inventory?: {
    id: number;
    type: 'inventory';
  };
}

export interface WarehouseLocation {
  id: number;
  label: string;
}

export interface SimpleLocationsResponse {
  locations: WarehouseLocation[];
}

export interface WarehouseData {
  id: number;
  name: string;
  receivingStartAt: string | null;
  receivingEndAt: string | null;
  line1: string;
  line2: string | null;
  locality: string;
  region: string;
  postcode: string;
  country: string;
  contactUser: {
    id: number;
  };
  phone: string;
  additionalNotes: string | null;
}

export interface UserData {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
}

export interface Document {
  id: number;
  type?: string;
  createdAt: string;
  notable?: {
    id: number;
    type: string;
  };
  company: {
    id: number;
    type: string;
    name?: string;
  };
  documentType: DocumentType;
  description: string;
  note: {
    name: string;
    url: string;
    contentType: string;
    size: number;
  };
  deletable: boolean;
  uploader: CompanyType;
}

export interface WarehouseDocument {
  id: number;
  type: DocumentType;
}

/**
 * A representation of the Shipment Document (AKA required_label) model
 */
export interface ShipmentDocument {
  id: number;
  fileName?: string;
  labelType: {
    enumValue: ShipmentDocumentType;
    displayName: string;
    subDisplayName: string | null;
  };
  shipmentId: number;
  barcode: string;
  labelDetails: {
    secureDownloadUrl: string;
    updatedAt: string | null;
  };
}

/**
 * The set of label types that are represented by Shipment Document models
 */
export enum ShipmentDocumentType {
  externalShippingLabel = 'external_shipping_label',
  LTL = 'ltl',
  parcel = 'parcel',
  parcelMultiPackage = 'parcel_multi_package',
  freightPackingList = 'freight_packing_list',
  insert = 'insert',
  packingSlip = 'packing_slip',
  wmAsn = 'wm_asn',
  sortationCenter = 'sortation_center',
  ssccLabels = 'sscc_labels',
  ssccCartonLabels = 'carton_sscc_labels',
  ssccPalletLabels = 'pallet_sscc_labels',
  sticker = 'sticker'
}

/**
 * Note: TS doesn't have the ability to union enums so create a list to represent
 * a subset with a special meaning. Not a set because of arrays are easier to iterate
 */
export const ShipmentDocumentTypeShippingLabels = [
  ShipmentDocumentType.externalShippingLabel,
  ShipmentDocumentType.LTL,
  ShipmentDocumentType.parcel,
  ShipmentDocumentType.parcelMultiPackage
];

export interface Warehouse {
  id: number;
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  timezone: string;
  crossdockEnabled: boolean;
  showSkuFilter?: boolean;
  isSfsEnabled?: boolean;
}

export interface Measurement {
  amount: string;
  unit?: LengthUnit;
}

// Jordan: we probably shouldnt be passing these class names around at all,
// but I thought I'd consolidate them in an enum at the very least
export enum DocumentNotableType {
  container = 'ContainerDelivery',
  damagedPickup = 'Core::Models::DamagedInventoryPickup',
  ecomShipment = 'Fulfillment::Models::FulfillmentOrder',
  retailFulfillmentOrder = 'Fulfillment::Models::RetailFulfillmentOrder',
  fulfillmentBatch = 'Fulfillment::Models::FulfillmentBatch',
  freightLoadStop = 'Fulfillment::Freight::Models::FreightLoadStop'
}

export enum DocumentType {
  billOfLading = 'Bill Of Lading',
  packingSlipZebra = 'Packing Slip (Zebra 4x6)',
  packingSlipLetter = 'Packing Slip (Letter)',
  pickList = 'Pick List',
  proofOfDelivery = 'Proof Of Delivery',
  receivingList = 'Receiving List',
  mailingLabels = 'Mailing Labels',
  rma = 'RMA',
  other = 'Other',
  retailBuildInstructions = 'Build Instructions',
  mobileBatchWorkItemType = 'Mobile Batch Work Items',
  generatedBillOfLading = 'Generated Bill Of Lading'
}

export interface Reservation {
  id: number;
  type?: string;
  name?: string;
  supportsFulfillment?: boolean;
  usesMobile?: boolean;
  primaryContact?: string;
  primaryPhone?: string;
  lpnEnabled?: boolean; // remove when LPNs are not feature-flagged or CCs can handle them. See PR for WSE-1742.
  crossdockEnabled?: boolean;
  dockEnabled?: boolean;
}

export interface SelectableWarehouse {
  reservationId: number;
  omniEnabled: boolean;
  warehouseName: string;
  warehouseStreet: string;
  warehouseCity: string;
  warehouseState: string;
  warehouseZip: string;
}

export interface FeatureFlagResponse {
  flag: string;
  value: any;
}

export interface Company {
  id: number;
  name?: string;
  enableTihiDuringInbound?: boolean;
}

export interface ReservationsResponse {
  reservations: Reservation[];
}

export enum TransitionState {
  New = 'new',
  Confirmed = 'confirmed',
  Completed = 'completed',
  Cancelled = 'cancelled',
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Active = 'active'
}

export enum ExpectedInboundPackaging {
  Unknown = 'unknown',
  FloorLoaded = 'floor_loaded',
  Palletized = 'palletized',
  ParcelReceive = 'parcel_receive'
}

export enum ReceiptMethodEnum {
  Web = 'web_receive',
  Mobile = 'mobile_receive'
}

export interface RetailConfig {
  bypassPickupDetails: boolean;
  responsibleRouter: string;
  palletLevelSsccLabelsEnabled: boolean;
  blockOverShipment: boolean;
}

export interface WarehouseConfigResponse {
  warehouseFulfillmentConfig: WarehouseConfig;
}

export interface WarehouseConfig {
  batchingType: BatchingMethod;
  splitOrdersEnabled: boolean;
  highVolumeWavingEnabled: boolean;
  multiBatchWaves: boolean;
}

export interface Logger {
  error: (message: any) => any;
  info: (message: any) => any;
  warn: (message: any) => any;
}

export interface CsvUploadParams {
  file: object;
}

export interface MoreExplicitUploadParams {
  [key: string]: string | number | Date;
}

export interface CsvDataError {
  name: string;
  message: string;
  column_name: string;
  rows_affected: number[];
}

export interface GenericUploadError {
  name: string;
  message: string;
}

export interface CsvUploadResponse {
  errors: {
    csv_errors: CsvDataError[];
    generic_errors: GenericUploadError[];
  };
}

export interface CompanyChannelsInfoResponse {
  companyChannelsInfo: {[companyChannelId: number]: string};
}
