import * as React from 'react';
import {ResponseError} from '../CommonInterfaces';
import {ErrorCard} from '../../warehouse/outbound-shipments/shared/ErrorCard';

export interface Props {
  error?: ResponseError;
}

export const ConfirmationFormError: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {props.error != null && (
        <div className={'confirmation-form-modal-error'} data-testid={'confirmation-form-modal-error'}>
          <ErrorCard>
            <b>{props.error.title}</b>
            <p>{props.error.detail}</p>
          </ErrorCard>
        </div>
      )}
    </React.Fragment>
  );
};
