import * as React from 'react';
import {isAfter} from 'date-fns';
import {cloneDeep} from 'lodash';
import {Company, Document, ItemQuantity, Reservation} from '../../shared/CommonInterfaces';
import {ContainerDelivery, ContainerDeliveryState, LpnContent, PackingListItem} from '../shared/DropoffInterfaces';
import ContainersService from '../../shared/services/ContainersService';
import {CompleteDropoffModal} from '../shared/CompleteDropoffModal';
import {DeliverySummary} from '../shared/DeliverySummary';
import DropoffHelper from '../shared/DropoffHelper';
import {LpnStatus} from '../../lpns/LpnsInterfaces';
import LpnDropoffConfirmationSteps from './steps/confirmation/LpnDropoffConfirmationSteps';
import LpnDropoffCompletionSteps from './steps/completion/LpnDropoffCompletionSteps';
import {ConfirmLpnDropoffModal} from './ConfirmLpnDropoffModal';

export interface LpnConfirmedDropoffProps {
  lpnStateData: Map<string, LpnStatus>;
  lpnReceiveOnly: boolean;
  containerDelivery: ContainerDelivery;
  containersService: ContainersService;
  enableInboundPackaging: boolean;
  lpnContents: LpnContent[];
  packingLists: {
    actual?: PackingListItem[];
    expected: PackingListItem[];
    shippable: PackingListItem[];
    damaged: PackingListItem[];
  };
  isUsingExpectedLpns: boolean;
  reservation: Reservation;
  shipperCompany: Company;
  documents: Document[];
  receivingListPath: string;
  inProgressDropoffIds: number[];
  inputTextMap: Map<string, string>;
  setIsEditing: (value: boolean) => void;
  isBolUploaded: boolean;
  showEditLpnForAsn?: boolean;
  handleContainerDeliveryUpdate(containerDelivery: ContainerDelivery);
  handleErrors(errors: string[], response);
  recalculatePackingListsBasedOnLpns(lpnContents: LpnContent[]);
  reloadContainer();
  resetSuccessMessage();
  setSuccessMessage(successMessage: string);
  onDocumentSaved(document: Document);
}

interface LpnConfirmedDropoffState {
  additionalPackingLists: PackingListItem[];
  expectedPackingLists: PackingListItem[];
  showModal: boolean;
  selectedReceiptMethod: string;
  parcelReceive: boolean;
  actualArrivalTime?: Date;
  disableComplete: boolean;
  areAllQuantitiesCorrect: boolean;
  isShippableSameToExpected: boolean;
}

interface ItemParams {
  inventoryId: number;
  quantity: ItemQuantity;
  pallets: number;
}

class LpnConfirmedDropoff extends React.Component<LpnConfirmedDropoffProps, LpnConfirmedDropoffState> {
  public static getDerivedStateFromProps(nextProps) {
    const packingLists = nextProps.packingLists;
    let totalActualQty = 0;
    if (packingLists.actual) {
      packingLists.actual.forEach((pl: PackingListItem) => {
        totalActualQty += pl.quantity.amount;
      });
    }

    let totalExpectedQty = 0;
    packingLists.expected.forEach((pl: PackingListItem) => {
      totalExpectedQty += pl.quantity.amount;
    });
    const isShippableSameToExpected: boolean = totalActualQty === totalExpectedQty;

    return {
      additionalPackingLists: nextProps.packingLists.shippable,
      expectedPackingLists: nextProps.packingLists.expected,
      isShippableSameToExpected
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      additionalPackingLists: this.props.packingLists.shippable,
      expectedPackingLists: this.props.packingLists.expected,
      selectedReceiptMethod: 'web-receive',
      parcelReceive: this.props.containerDelivery.expectedInboundPackaging === 'parcel_receive',
      showModal: false,
      disableComplete: false,
      areAllQuantitiesCorrect: false,
      isShippableSameToExpected: false
    };
  }

  public render() {
    return (
      <div id="confirmed-dropoff-component">
        {!this.isLpnConfirmed() && this.showLpnConfirmationSteps()}
        {this.isLpnConfirmed() && this.showLpnCompletionSteps()}
      </div>
    );
  }

  private showLpnCompletionSteps(): JSX.Element {
    return (
      <div>
        <LpnDropoffCompletionSteps
          containerDeliveryId={this.props.containerDelivery.id}
          lpnStateData={this.props.lpnStateData}
          lpnReceiveOnly={this.props.lpnReceiveOnly}
          hasBol={this.props.containerDelivery.hasBillOfLading}
          lpnContents={this.props.lpnContents}
          packingLists={this.props.packingLists}
          reservation={this.props.reservation}
          recalculatePackingListsBasedOnLpns={this.props.recalculatePackingListsBasedOnLpns}
          receivingListPath={this.props.receivingListPath}
          documents={this.props.documents}
          onDocumentSaved={this.props.onDocumentSaved}
          inProgressDropoffIds={this.props.inProgressDropoffIds}
          showEditLpnForAsn={this.props.showEditLpnForAsn}
          containerDeliveryState={this.props.containerDelivery.txnState as ContainerDeliveryState}
        />

        {this.showCompleteButton() && [this.renderCompleteSection()]}

        <CompleteDropoffModal
          showModal={this.state.showModal}
          toggleModal={this.closeModal}
          containerDelivery={this.props.containerDelivery}
          onCompleteDropoff={() => this.completeAndFinalizeShipment()}
        />
      </div>
    );
  }

  private renderCompleteSection(): JSX.Element {
    const bypassBillOfLading = this.state.parcelReceive && this.props.lpnReceiveOnly;
    const quantityCheck = this.state.areAllQuantitiesCorrect || this.state.isShippableSameToExpected;
    return (
      <div>
        {this.showCompleteButton() && [
          this.mismatchQuantityCheckbox(),
          <button
            data-testid={'complete-button'}
            className="btn cta btn-complete"
            onClick={this.handleConfirmOrCompleteClick}
            disabled={
              (!this.props.isBolUploaded && !bypassBillOfLading) || !quantityCheck || this.disableCompleteButton()
            }
          >
            <i className="fa fa-check"></i>
            Complete and Finalize Delivery
          </button>
        ]}
      </div>
    );
  }

  private mismatchQuantityCheckbox(): JSX.Element {
    if (!this.state.isShippableSameToExpected) {
      return (
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={this.state.areAllQuantitiesCorrect}
              onChange={() => this.setState({areAllQuantitiesCorrect: !this.state.areAllQuantitiesCorrect})}
            />{' '}
            The total shippable received quantity is different than total expected quantity. By checking this, you
            acknowledge that all received quantities entered above are correct.
          </label>
        </div>
      );
    }
  }

  private showLpnConfirmationSteps(): JSX.Element {
    const {
      packingLists,
      lpnContents,
      lpnReceiveOnly,
      reservation,
      containerDelivery,
      lpnStateData,
      inProgressDropoffIds,
      recalculatePackingListsBasedOnLpns
    } = this.props;
    return (
      <div>
        <LpnDropoffConfirmationSteps
          containerDeliveryId={this.props.containerDelivery.id}
          actualArrivalTime={this.state.actualArrivalTime}
          enableInboundPackaging={this.props.enableInboundPackaging}
          lpnReceiveOnly={this.props.lpnReceiveOnly}
          receivingListPath={this.props.receivingListPath}
          onActualInboundPackagingChange={this.handleActualInboundPackagingChange}
          onUpdateActualArrivalTime={this.handleUpdateActualArrivalTime}
        />

        <div style={{marginBottom: 16}}>
          <DeliverySummary
            packingLists={packingLists}
            lpnContents={lpnContents}
            lpnReceiveOnly={lpnReceiveOnly}
            reservationId={reservation.id}
            containerDeliveryId={containerDelivery.id}
            lpnStateData={lpnStateData}
            inProgressDropoffIds={inProgressDropoffIds}
            recalculatePackingListsBasedOnLpns={recalculatePackingListsBasedOnLpns}
          />
        </div>

        <button
          className="btn cta btn-complete"
          onClick={this.handleConfirmOrCompleteClick}
          disabled={!this.state.actualArrivalTime}
        >
          <i className="fa fa-check"></i>
          Confirm Arrival
        </button>

        <ConfirmLpnDropoffModal
          showModal={this.state.showModal}
          toggleModal={this.closeModal}
          containerDelivery={this.props.containerDelivery}
          onConfirmLpnDropoff={() => this.confirmLpnDropoff()}
        />
      </div>
    );
  }

  // This is a bit of strangeness about LPN dropoffs. Once the ContainerDelivery
  // itself has been put into the "confirmed" state, the LPN still has to be
  // "confirmed", which just means that the "actual arrival time" field has
  // been populated on the ContainerDelivery
  private isLpnConfirmed(): boolean {
    return this.arrivalTimeIsSet() && this.inboundPackagingIsSet();
  }

  private arrivalTimeIsSet(): boolean {
    return !!this.props.containerDelivery.actualArrivalTime;
  }

  private inboundPackagingIsSet(): boolean {
    // Inbound packaging is vacuously true if the feature is disabled,
    // or really true if the feature is enabled the actual inbound packaing
    // is specified
    return (
      !this.props.enableInboundPackaging ||
      (this.props.enableInboundPackaging && !!this.props.containerDelivery.actualInboundPackaging)
    );
  }

  private getShippableItems = () => {
    let allItems = cloneDeep(this.state.expectedPackingLists);

    if (this.props.isUsingExpectedLpns) {
      this.props.packingLists.shippable.forEach((pl) => {
        const packingList: PackingListItem = cloneDeep(pl);
        packingList.shippableAmount = packingList.quantity.amount;

        allItems.push(packingList);
      });
    } else {
      allItems = allItems.concat(this.state.additionalPackingLists);
      allItems = allItems.concat(this.props.packingLists.shippable);
    }

    const shippableItems = [];
    // Map the shippable and damaged packing lists
    allItems = this.props.packingLists.shippable;
    allItems = allItems.concat(this.props.packingLists.damaged);
    allItems.forEach((item) => {
      const itemParams: ItemParams = {
        inventoryId: item.inventory.id,
        quantity: item.quantity,
        pallets: 0
      };
      const shippableItem = cloneDeep(itemParams);
      shippableItem.quantity.amount = item.quantity.amount;
      shippableItem.pallets = 0;
      shippableItems.push(shippableItem);
    });
    return shippableItems;
  };

  private completeAndFinalizeShipment = async () => {
    const shippableItems = this.getShippableItems();
    if (shippableItems.length) {
      const response = await this.props.containersService.completeAndFinalizeShipment({
        id: this.props.containerDelivery.id,
        locationId: null,
        shippableInventories: shippableItems,
        damagedInventories: [],
        reservationId: this.props.reservation.id,
        actualArrivalTime: this.state.actualArrivalTime,
        actualInboundPackagingId: this.props.containerDelivery.actualInboundPackagingId
      });
      if (response && response.errors.length === 0) {
        {
          this.props.setSuccessMessage('Delivery has been completed!');
        }
        this.props.resetSuccessMessage();
        this.props.reloadContainer();
      } else {
        this.setEnableComplete();
        const errors = [`There was an error completing delivery #${this.props.containerDelivery.id}`];
        this.props.handleErrors(errors, response);
      }
    } else {
      this.setEnableComplete();
      let error = 'Shippable quantities must be specified. ';
      error += 'At least one LPN must be inbounded before completing the delivery.';
      this.props.handleErrors([error], null);
    }

    this.closeModal();
  };

  private handleActualInboundPackagingChange = (actualInboundPackagingId) => {
    const containerDelivery = cloneDeep(this.props.containerDelivery);
    containerDelivery.actualInboundPackagingId = actualInboundPackagingId;
    this.props.handleContainerDeliveryUpdate(containerDelivery);
  };

  private handleConfirmOrCompleteClick = () => {
    this.setDisableComplete();
    const moveDate = this.props.containerDelivery.moveDate;

    // if the supplied move/dropoff date is in the future,
    // show the confirmation dialog instead of immediately confirming/completing
    const today = new Date();
    if (isAfter(moveDate, today)) {
      this.openModal();
    } else if (!this.isLpnConfirmed()) {
      this.confirmLpnDropoff();
    } else {
      this.completeAndFinalizeShipment();
    }
  };

  private handleUpdateActualArrivalTime = (actualArrivalTime) => {
    this.setState({actualArrivalTime});
  };

  private confirmLpnDropoff = async () => {
    const updateCapturablePropertiesResponse = await DropoffHelper.updateCapturablePropertiesAsync(
      this.props.inputTextMap,
      this.props.containerDelivery.id,
      this.props.containersService
    );
    if (updateCapturablePropertiesResponse.errors.length === 0) {
      this.props.setIsEditing(false);
    }
    const response = await this.props.containersService.receiveShipment(
      this.props.containerDelivery.id,
      this.state.actualArrivalTime,
      this.props.containerDelivery.actualInboundPackagingId
    );
    if (response && response.errors.length === 0) {
      this.props.setSuccessMessage('Shipment has been confirmed for receipt!');
      this.props.resetSuccessMessage();
      this.props.reloadContainer();
      //remove error message in case there were any displayed in the UI
      this.props.handleErrors([], undefined);
    } else {
      this.setEnableComplete();
      const errors = [`There was an error confirming delivery #${this.props.containerDelivery.id} for receipt`];
      this.props.handleErrors(errors, response);
      this.props.setIsEditing(true);
    }
    this.closeModal();
  };

  private showCompleteButton() {
    let showButton = !!this.props.containerDelivery.actualArrivalTime || !!this.state.actualArrivalTime;

    if (this.props.enableInboundPackaging) {
      showButton =
        showButton &&
        (!!this.props.containerDelivery.actualInboundPackagingId ||
          !!this.props.containerDelivery.actualInboundPackaging);
    }
    return showButton;
  }

  private disableCompleteButton() {
    return this.state.disableComplete;
  }

  private closeModal = () => {
    this.setState({showModal: false});
  };

  private openModal = () => {
    this.setState({showModal: true});
  };

  private setEnableComplete = () => {
    this.setState({disableComplete: true});
  };

  private setDisableComplete = () => {
    this.setState({disableComplete: true});
  };
}

export default LpnConfirmedDropoff;
