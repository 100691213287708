/** @jsx jsx */
import {FC} from 'react';
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import {Icon} from '@flexe/ui-components';
import {CardWithIcon} from './CardWithIcon';

export const ErrorCard: FC = ({children}) => (
  <CardWithIcon
    cardStyles={css`
      background-color: ${tokens?.color.base.red.v50.value};
      border-color: ${tokens?.color.base.red.v300.value};
    `}
    icon={<Icon color={tokens?.color.base.red.v300.value} icon={'ban'} />}
  >
    {children}
  </CardWithIcon>
);
