import * as React from 'react';
import {cloneDeep} from 'lodash';
import DatePicker from 'react-datepicker';
import ActualInboundPackaging from '../../../shared/completion-workflow/ActualInboundPackaging';
import Expando from '../../../../shared/Expando';

interface Props {
  lpnReceiveOnly: boolean;
  containerDeliveryId: number;
  actualArrivalTime: Date;
  enableInboundPackaging: boolean;
  receivingListPath: string;
  onActualInboundPackagingChange(actualPackagingType: string);
  onUpdateActualArrivalTime(actualArrivalTime);
}

interface State {
  showExpandos: boolean[];
}

class LpnDropoffConfirmationSteps extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showExpandos: [true, true, true]
    };
  }

  public render() {
    const steps = this.getSteps();
    return (
      <React.Fragment>
        {this.props.lpnReceiveOnly && (
          <a
            className="btn cta space-below"
            href={this.props.receivingListPath}
            target="_blank"
            id="btn-print-receiving-list"
          >
            <i className="fa fa-print" aria-hidden="true"></i> Print Receiving List
          </a>
        )}
        {steps.map((step, idx) => {
          return (
            <Expando
              key={idx}
              index={idx + 1}
              headerIcon={<span className="workflow-step-index">{idx + 1}</span>}
              headerText={step.title}
              open={this.state.showExpandos[idx]}
              onClick={this.toggleExpando}
            >
              {step.content}
            </Expando>
          );
        })}
      </React.Fragment>
    );
  }
  private getSteps() {
    const addActualArrivalTimeStep = {
      title: 'Enter Inbound Container Arrival Time',
      content: (
        <span>
          <DatePicker
            selected={this.props.actualArrivalTime}
            onChange={this.props.onUpdateActualArrivalTime}
            maxDate={new Date(Date.now())}
            minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
            timeIntervals={15}
            showTimeSelect
            dateFormat="MMM dd - h:mm aa"
            placeholderText="Date/Time"
          />{' '}
          <b>*</b>{' '}
        </span>
      )
    };

    const iptStep = {
      title: 'Select Inbound Type',
      content: (
        <ActualInboundPackaging
          lpnReceiveOnly={this.props.lpnReceiveOnly}
          onActualInboundPackagingChange={this.props.onActualInboundPackagingChange}
        />
      )
    };

    const steps = [addActualArrivalTimeStep];
    if (this.props.enableInboundPackaging) {
      steps.push(iptStep);
    }

    return steps;
  }

  private toggleExpando = (event) => {
    // reading data-index off expando .header element
    const index = parseInt(event.currentTarget.getAttribute('data-index'), 10) - 1;
    const showExpandos = cloneDeep(this.state.showExpandos);
    showExpandos[index] = !showExpandos[index];
    this.setState({showExpandos});
  };
}

export default LpnDropoffConfirmationSteps;
