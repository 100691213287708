import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {Loader} from '@flexe/ui-components';
import PickConsolidationService from '../../shared/services/PickConsolidationService';
import WarehouseSelector from '../../shared/WarehouseSelector';
import {Reservation, SortOrder, TransitionState, Warehouse} from '../../shared/CommonInterfaces';
import SelectorWithCheckbox from '../../shared/SelectorWithCheckbox';
import WarehouseService from '../../shared/services/WarehouseService';
import BatchWavingService from '../../shared/services/BatchWavingService';
import {ShipMode} from '../../shared/constants';
import ModeFilter from '../outbound-shipments/shipment-list/ModeFilter';
import {WaveContext} from './WaveContext';
import WavesTable from './WavesTable';
import AlertWavingIssues from './AlertWavingIssues';
import PageReloader from './PageReloader';
import WaveFilter from './WaveFilter';
import {FilterKeys, FilterParams, PickWaveStatus} from './WaveBatchInterfaces';
import CompactStatusTabs from './CompactStatusTabs';
import PaginationButtons from './PaginationButtons';
import SortBy from './SortBy';

const PAGE_SIZE = 50;

interface Props {
  authenticityToken: string;
  carriers: object;
  selectedWarehouse: Warehouse;
  activeWarehouses: Warehouse[];
  newWaveModelEnabled: boolean;
}

const WavesIndex: React.FC<Props> = ({
  authenticityToken,
  selectedWarehouse,
  activeWarehouses,
  carriers,
  newWaveModelEnabled
}) => {
  const pickConsolidationService = useMemo(() => new PickConsolidationService(authenticityToken), [authenticityToken]);
  const batchWavingService = useMemo(() => new BatchWavingService(authenticityToken), [authenticityToken]);
  batchWavingService.currentWarehouse = selectedWarehouse.id;
  const warehouseService = useMemo(() => new WarehouseService(authenticityToken), [authenticityToken]);
  const [timerStartsAt, setTimerStartsAt] = useState<Date>(new Date(Date.now()));
  const [currentStatusFilter, setCurrentStatusFilter] = useState<TransitionState>(TransitionState.Active);
  const [errors, setErrors] = useState<string[]>([]);
  const [toRefresh, setToRefresh] = useState<boolean>(false);
  const [alertReloadTrigger, setAlertReloadTrigger] = useState<boolean>(false);

  const [fetchingPage, setFetchingPage] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalWavesCount, setTotalWavesCount] = useState<number>(0);

  const [sortDirection, setSortDirection] = useState<SortOrder>(SortOrder.DESC);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    sort_order: sortDirection,
    status: TransitionState.Active
  });

  const ALL_RESERVATIONS_ID_VALUE = -1;
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [reservationId, setReservationId] = useState<number>(ALL_RESERVATIONS_ID_VALUE);
  const [reservationsLoaded, setReservationsLoaded] = useState<boolean>(false);

  const [mode, setMode] = useState<ShipMode | null>(null);

  useEffect(() => {
    updateFilterParams('sort_order', sortDirection);
    setCurrentPage(1);
  }, [sortDirection]);

  useEffect(() => {
    if (newWaveModelEnabled) {
      setReservationId(ALL_RESERVATIONS_ID_VALUE);
      asyncGetReservations();
    }
  }, [newWaveModelEnabled, selectedWarehouse.id]);

  const contextValue = useMemo(
    () => ({
      authenticityToken,
      warehouse: selectedWarehouse,
      batchWavingService,
      pickConsolidationService,
      reservationIds: reservationId === ALL_RESERVATIONS_ID_VALUE ? reservations.map((_) => _.id) : [reservationId],
      filterParams,
      carriers: carriers || {},
      reservations,
      refreshing: toRefresh,
      setRefreshing: setToRefresh,
      setErrors
    }),
    [batchWavingService, pickConsolidationService, reservationId, setErrors, toRefresh, filterParams, reservations]
  );

  const asyncGetReservations = async () => {
    const response = await warehouseService.getReservationsForWarehouse(selectedWarehouse.id);
    if (response.data && response.data.reservations) {
      setReservations(response.data.reservations);
      setReservationsLoaded(true);
    }
    if (response.errors && response.errors.length > 0) {
      const getReservationsErrors = [].concat(errors);
      response.errors.forEach((_) => {
        getReservationsErrors.push(
          'An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.'
        );
      });
      setErrors(getReservationsErrors);
    }
  };

  const handleModeChange = (newMode: ShipMode) => {
    const newFilterParams: FilterParams = {...filterParams};
    if (newMode == null) {
      delete newFilterParams[FilterKeys.ShipMode];
    } else {
      newFilterParams[FilterKeys.ShipMode] = newMode;
    }
    setFilterParams(newFilterParams);
    setMode(newMode);
  };

  const updateFilterParams = (key: string, value: string) => {
    // Update filterParams if filterParams[key] is set or if there's a value to
    // set for filterParams[key]
    if (filterParams[key] || value) {
      const newFilterParams = {...filterParams};
      if (value) {
        newFilterParams[key] = value;
      } else {
        delete newFilterParams[key];
      }

      setFilterParams(newFilterParams);
    }
  };

  const selectStatusTab = (status: TransitionState) => {
    setFilterParams({
      ...filterParams,
      status: status === TransitionState.Active ? TransitionState.Active : PickWaveStatus[status]
    });

    setCurrentStatusFilter(status);
  };

  const reservationDropdown = () => {
    const options = reservations.map((reservation) => {
      return {
        name: reservation.name,
        value: `${reservation.id}`
      };
    });

    options.unshift({name: 'All', value: `${ALL_RESERVATIONS_ID_VALUE}`});

    const onSelect = (value: string) => {
      const selectedId = parseInt(value, 10);
      setReservationId(selectedId);
    };

    return (
      <SelectorWithCheckbox
        options={options}
        selected={[`${reservationId}`]}
        onSelect={onSelect}
        prefixText="Reservation: "
      />
    );
  };

  const reloadPage = () => {
    setTimerStartsAt(new Date());
    setCurrentPage(1);
    setToRefresh(true);
    setAlertReloadTrigger(!alertReloadTrigger);
  };

  const fetchPage = (page: number) => {
    setFetchingPage(true);
    setCurrentPage(page);
  };

  const handleWarehouseSelected = (warehouse: Warehouse) => {
    if (warehouse.id !== selectedWarehouse.id) {
      window.location.href = `/wh/waves?warehouse_id=${warehouse.id}`;
    }
  };

  return (
    <WaveContext.Provider value={contextValue}>
      <div id="waves-and-batches-index">
        {errors &&
          errors.map((error, idx) => (
            <div className="alert alert-danger" role="alert" key={`error:${idx}`}>
              {error}
            </div>
          ))}
        {activeWarehouses.length > 1 && (
          <div className={'warehouse-selector-container'} data-testid="warehouse-selector-container">
            <WarehouseSelector
              selectedWarehouse={selectedWarehouse}
              activeWarehouses={activeWarehouses}
              onSelect={handleWarehouseSelected}
            />

            {!newWaveModelEnabled && (
              <p className={'new-wave-model-disabled-message'}>
                {/* eslint-disable-next-line max-len */}
                This warehouse is not configured for multi-batch waves, please select a different warehouse or talk to{' '}
                <a href={'mailto:support@flexe.com'}>support@flexe.com</a>
              </p>
            )}
          </div>
        )}
        {newWaveModelEnabled && (
          <>
            <div className="title-header">
              <div className={'top-right-controls'}>
                <PageReloader timerUpdateFrequency={6000} timerStartsAt={timerStartsAt} onReload={reloadPage} />
                <AlertWavingIssues
                  warehouseId={selectedWarehouse.id}
                  batchWavingService={batchWavingService}
                  reloadTrigger={alertReloadTrigger}
                />
              </div>
              <div>
                <h2>Waves & Batches</h2>
                <div className="filter-control-container">
                  <ul className="list-inline">
                    <li>{reservationDropdown()}</li>
                  </ul>
                </div>
              </div>
              <hr />
              <WaveFilter
                allowedFilterKeys={[
                  FilterKeys.WaveId,
                  FilterKeys.BatchId,
                  FilterKeys.ShipmentId,
                  // TODO: FilterKeys.OrderReferenceId,
                  // TODO: FilterKeys.HidePrinted,
                  FilterKeys.StagingLocation,
                  FilterKeys.Carrier,
                  FilterKeys.ServiceType,
                  FilterKeys.ShipBy,
                  FilterKeys.LoadGroup,
                  FilterKeys.DestinationTag
                ]}
                onFilterChange={updateFilterParams}
              />
            </div>
            <div className="content-container">
              {fetchingPage && (
                <div id="main-loader">
                  <Loader loading={true} />
                </div>
              )}
              <div className="control-buttons-row">
                <div className="compact-status-tabs">
                  <ModeFilter mode={mode} onModeChange={handleModeChange} />
                  <CompactStatusTabs
                    statuses={[
                      TransitionState.Active,
                      TransitionState.NotStarted,
                      TransitionState.InProgress,
                      TransitionState.Completed,
                      TransitionState.Cancelled
                    ]}
                    countsByProcessStatus={{
                      active: null,
                      new: null,
                      not_started: null,
                      in_progress: null
                    }}
                    selectedTab={currentStatusFilter}
                    onTabSelect={(status: TransitionState) => selectStatusTab(status)}
                  />
                </div>
                <div className="row-group">
                  <SortBy onDirectionChange={setSortDirection} />
                  <PaginationButtons
                    currentPage={currentPage}
                    pageSize={PAGE_SIZE}
                    setCurrentPage={fetchPage}
                    wavesCount={totalWavesCount}
                  />
                </div>
              </div>
              {reservationsLoaded && (
                <WavesTable
                  pageSize={PAGE_SIZE}
                  setFetchingPage={setFetchingPage}
                  currentPage={currentPage}
                  setTotalWavesCount={setTotalWavesCount}
                />
              )}
            </div>
          </>
        )}
      </div>
    </WaveContext.Provider>
  );
};

export default WavesIndex;
