import * as React from 'react';
import DefinitionList from '../../../shared/DefinitionList';

const signatureDefinitions = [
  {term: 'no value given', definition: 'no signature required'},
  {term: 'standard', definition: 'any signature'},
  {term: 'adult', definition: "an adult's signature"},
  {term: 'certified', definition: "the recipient's signature"},
  {term: 'indirect', definition: 'a signature from anyone at the delivery address'}
];

export const instructionsTableHeaders: React.ReactNode[] = [
  'Column Header',
  'Required?',
  'Description of Use',
  'Input Guidelines',
  'Sample Input'
];

export const instructionsTableRows: {[key: string]: React.ReactNode[]} = {
  orderType: [
    'Order Type',
    '',
    <>
      Identifies order workflow type
      <br />
    </>,
    <>
      Must be one of the following:
      <br />
      <span className="italics">eCommerce, Distribution</span>
    </>,
    <>Distribution</>
  ],
  poNumber: [
    'PO Number',
    '',
    <>
      Customer purchase order reference
      <br />
      <span className="italics">
        This will be visible to the warehouse as PO Number
        <br />
        Customer Order Number will be used if PO Number is not set
      </span>
    </>,
    'Alphanumeric string',
    'PO123456'
  ],
  custOrderNumber: [
    'Customer Order Number',
    <span className="bold">Yes</span>,
    <>
      Unique, shipper-assigned identifier. This is typically the ID assigned to the order by your organization's OMS.
      <br />
      <span className="italics">
        Orders with the same ID must be grouped within the CSV
        <br />
        Searchable in Flexe's Orders page as the External ID
        <br />
        This will also always be visible to the warehouse as "customer reference number"
      </span>
    </>,
    'Alphanumeric string, special characters allowed',
    'ORD202212010001'
  ],
  labelRefOne: [
    'Label Reference 1',
    '',
    <>Text to be printed on the shipping label</>,
    'Text; 50 character max',
    '4462343'
  ],
  labelRefTwo: [
    'Label Reference 2',
    '',
    <>Text to be printed on the shipping label</>,
    'Text; 50 character max',
    'A8FG_34'
  ],
  custOrderDate: ['Customer Order Date', '', 'Source order capture date', 'MM/DD/YYYY', '11/01/2024'],
  recipientName: [
    'Name',
    <span className="bold">Yes</span>,
    <>
      Recipient / Entity name
      <br />
      <span className="italics">Will be displayed on documents</span>
    </>,
    'Text',
    'Jane Doe'
  ],
  recipientAddressOne: [
    'Address 1',
    <span className="bold">Yes</span>,
    <>
      Recipient street address line 1
      <br />
      <span className="italics">PO Box is restricted to USPS</span>
    </>,
    'Text',
    '123 Mystery Lane'
  ],
  recipientAddressTwo: ['Address 2', '', 'Recipient street address line 2', 'Text', 'Suite #104'],
  recipientAddressThree: ['Address 3', '', 'Recipient street address line 3', 'Text', 'ATTN: Gift Planning'],
  city: ['City', <span className="bold">Yes</span>, 'Recipient city', 'Text', 'Seattle'],
  stateOrProvince: [
    'State / Province',
    <span className="bold">Yes</span>,
    'Recipient state or province',
    '2-letter abbreviation',
    'WA'
  ],
  postalCode: [
    'Postal Code',
    <span className="bold">Yes</span>,
    'Recipient postal code',
    <>
      <span className="italics">US:</span> Zip Code (+ 4)
      <br />
      <span className="italics">CA:</span> 6-digit alphanumeric
    </>,
    <>
      <span className="italics">US:</span> 98104
      <br />
      <span className="italics">CA:</span> K1V 1J8
    </>
  ],
  country: ['Country', <span className="bold">Yes</span>, 'Recipient country', '2-digit ISO code', 'US'],
  phoneNumber: [
    'Phone Number',
    <span className="bold">Conditional</span>,
    <>
      Recipient phone number for delivery updates
      <br />
      <span className="italics">
        <span className="bold">Required</span> for Same Day shipping with Flexe-generated labels
      </span>
    </>,
    'Numeric string',
    '1-234-567-8910'
  ],
  email: ['Email', '', 'Recipient email address for delivery updates', 'Email address', 'example@flexe.com'],
  sku: [
    'Item / SKU',
    <span className="bold">Yes</span>,
    <>
      <span className="bold">Line-Level:</span> SKU being ordered
      <br />
      <span className="italics">Must be enabled in Flexe Item Master</span>
    </>,
    'Alphanumeric string, special characters allowed',
    '00145678'
  ],
  lotCode: [
    'Lot Code',
    <span className="bold">Conditional</span>,
    <>
      <span className="bold">Line-Level:</span> Specifies the required lot code for items fulfilling the order line
      <br />
      <span className="italics">
        <span className="bold">Required</span> if Expiration Date is given.
      </span>
      <br />
      <span className="italics">Distribution by Lot must be enabled.</span>
    </>,
    'Alphanumeric string, special characters allowed',
    'L00722666'
  ],
  expirationDate: [
    'Expiration Date',
    '',
    <>
      <span className="bold">Line-Level:</span> The expiration date for the items ordered.
      <br />
      <span className="italics">If given, Lot Code must also be given.</span>
    </>,
    'MM/DD/YYYY',
    '01/01/2024'
  ],
  customerOrderLineNumber: [
    'Customer Order Line Number',
    '',
    <>
      <span className="bold">Line-Level:</span> Unique, shipper-assigned identifier for the line. This is typically the
      ID assigned to the order line by your organization's OMS.
      <br />
      <span className="italics">Searchable in Flexe's Orders page as the External Line ID</span>
    </>,
    'Alphanumeric string, special characters allowed',
    'ORD202212010001-01'
  ],
  lineQuantity: [
    'Line QTY',
    <span className="bold">Yes</span>,
    <>
      <span className="bold">Line-Level:</span> Requested number of items per SKU
    </>,
    'Number, whole numbers only',
    '600'
  ],
  uom: [
    'Unit of Measure (UoM)',
    <span className="bold">Yes</span>,
    <>
      <span className="bold">Line-Level:</span> Requested unit of measure for the specified{' '}
      <span className="italics">Line QTY</span>
    </>,
    <>
      Must be one of the following:
      <br />
      <span className="italics">Each, Carton</span>
    </>,
    'Carton'
  ],
  itemPricePerUnit: [
    'Item Price per Unit',
    <span className="bold">Conditional</span>,
    <>
      <span className="bold">Line-Level:</span> The cost of the item per unit paid by the recipient. This is required if
      shipping internationally or if <span className="italics">Item Price Currency</span> is specified.
    </>,
    'Number',
    '25.42'
  ],
  itemPriceCurrency: [
    'Item Price Currency',
    <span className="bold">Conditional</span>,
    <>
      <span className="bold">Line-Level:</span> The currency that <span className="italics">Item Price per Unit</span>{' '}
      was provided in. This is required if shipping internationally or if{' '}
      <span className="italics">Item Price per Unit</span> is specified.
    </>,
    'ISO-4217 value',
    'USD'
  ],
  shipBy: [
    'Latest Ship Date',
    <span className="bold">Yes</span>,
    'Last allowable day for shipment',
    'MM/DD/YYYY',
    '11/04/2024'
  ],
  shipAfter: [
    'Earliest Ship Date',
    <span className="bold">Conditional</span>,
    <>
      First allowable day for shipment
      <br />
      <span className="italics">
        If earliest ship date is not provided for a legacy retail order (RFO), we will default based on the order
        creation date. Note: We will not default for a ecommerce parcel or Omnichannel freight shipment.
      </span>
    </>,
    'MM/DD/YYYY',
    '11/02/2024'
  ],
  carrier: [
    'Carrier',
    <span className="bold">Yes</span>,
    <>
      Ship method, based on associated carriers
      <br />
      <span className="italics">
        If shipping freight, input value “Freight”. This will either create a legacy retail order (RFO) or Omnichannel
        freight shipment, based on the reservation used.
      </span>
    </>,
    <>
      Must be one of the following:
      <br />
      <span className="italics">Unable to load carrier info</span>
    </>,
    'USPS'
  ],
  serviceLevel: [
    'Service Level or SCAC',
    '',
    'Service Level must be enabled through Flexe Carrier Accounts and be valid with specified carrier',
    <>
      Hover to see service levels available for each carrier:
      <br />
      <span className="italics">Unable to load service level info</span>
    </>,
    <>
      <span className="italics">Service Level:</span> Priority Mail
      <br />
      <span className="italics">SCAC:</span> S224
    </>
  ],
  bolNumber: [
    'BOL Number',
    '',
    <>
      Enter Bill of Lading number, if known (can also be populated later)
      <br />
      <span className="italics">
        This value will always be stored on the order if provided, but will only be provided to the warehouse if a
        Retail Fulfillment freight shipment is created.
      </span>
    </>,
    'Alphanumeric string',
    '2233522'
  ],
  shippingNotes: [
    'Shipping Notes',
    '',
    'Free-form text field (prints on pick list)',
    'Text',
    'Ship this with the special boxes'
  ],
  requestedReservationId: [
    'Requested Reservation ID',
    <span className="bold">Conditional</span>,
    <>
      Flexe reservation ID to specify warehouse fulfillment location
      <br />
      <span className="italics">
        <span className="bold">Required</span> if Carrier is Freight
        <br />
        If no value is given, Flexe will assign one.
      </span>
    </>,
    'Number',
    '1234'
  ],
  carrierBillingAcctId: [
    'Carrier Billing Account ID',
    '',
    <>
      Must be an Account ID set up in Carrier Accounts
      <br />
      <span className="italics">If no value given, will bill Flexe account</span>
    </>,
    'Number',
    '123456'
  ],
  customAttr: [
    'Custom Attributes',
    '',
    'Unique per shipper; allowed inputs are defined during shipper scope setup',
    <>
      See{' '}
      <a href="https://developer.flexe.com/doc/public#section/Appendix/Order-and-Line-Metadata" target="_blank">
        Flexe Developer Portal
      </a>
    </>,
    <>
      See{' '}
      <a href="https://developer.flexe.com/doc/public#section/Appendix/Order-and-Line-Metadata" target="_blank">
        Flexe Developer Portal
      </a>
    </>
  ],
  signatureRequirement: [
    'Signature Requirement',
    '',
    <>
      Specifies what type of signature is required on delivery (not applicable for Distribution and/or Freight)
      <DefinitionList definitions={signatureDefinitions} termWeight={'semi-bold'} italicizeDefinitions singleLine />
    </>,
    <>
      If provided, must be one of the following:
      <br />
      <span className="italics">standard, adult, certified, indirect</span>
    </>,
    'certified'
  ]
};
