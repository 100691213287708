/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {InfoBox} from '@flexe/ui-components';
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import {stringify as queryStringify} from 'query-string';
import {convertToCartons, convertToEaches, pluralizeUnit} from '../../shared/utilities/DataFormatting';
import PackingListService, {UpdatePackingListRequest} from '../../shared/services/PackingListService';
import ContainersService from '../../shared/services/ContainersService';
import EditLpnMenu from '../../shared/EditLpnMenu';
import {renderItemLink} from '../../../libs/helpers';
import {ItemQuantity, Packaging} from '../../shared/CommonInterfaces';
import {ContainerDelivery, ContainerDeliveryState, SkusTabData} from './DropoffInterfaces';
import UpdateControls from './UpdateControls';

export interface SkuContentRowProps {
  containerDeliveryId: number;
  content: SkusTabData;
  isShipper: boolean;
  showUoms: boolean;
  shipperProps?: {
    containersService: ContainersService;
    containerDelivery: ContainerDelivery;
    handleRefreshPage: () => void;
    packingListService: PackingListService;
  };
  showQuantityCallback: () => void;
  containerDeliveryState: ContainerDeliveryState;
}

const SkuContentRow: FunctionComponent<SkuContentRowProps> = (props: SkuContentRowProps) => {
  const BLANK_COLUMN: string = '-';
  const {
    content,
    isShipper,
    shipperProps: {containersService, containerDelivery, handleRefreshPage, packingListService} = {},
    showQuantityCallback
  } = props;
  const [editState, setEditState] = useState<'view' | 'edit' | 'removalConfirmation'>('view');
  const [showDropdown, setShowDropdown] = useState(false);
  const [errors, setErrors] = useState([]);
  const [updateProperties, setUpdateProperties] = useState({expectedQuantity: content.expectedQuantity});

  const areQuantitiesMismatched =
    content.expectedQuantity.amount === 0 ||
    convertToEaches(
      content.expectedQuantity.amount,
      content.expectedQuantity.unit,
      content.packagingConversionFactor
    ) !==
      convertToEaches(
        content.shippableQuantity.amount,
        content.shippableQuantity.unit,
        content.packagingConversionFactor
      );

  useEffect(() => {
    const closeDropdownHandler: EventListener = (event) => {
      let element = event.target as Element;
      while (element.parentElement) {
        if (element.classList && element.classList.contains('dropdown-menu')) {
          // Do nothing if clicked within the dropdown
          return;
        } else {
          element = element.parentElement;
        }
      }
      setShowDropdown(false);
    };
    document.body.addEventListener('mouseup', closeDropdownHandler);

    return () => document.body.removeEventListener('mouseup', closeDropdownHandler);
  }, []);

  useEffect(() => {
    if (areQuantitiesMismatched) {
      showQuantityCallback();
    }
  }, [areQuantitiesMismatched, showQuantityCallback]);

  const renderUomData = (innerPack: number, outerCase: number) => (
    <div>
      <span>{innerPack || BLANK_COLUMN} each/inner pack</span>
      <br />
      <span>{outerCase || BLANK_COLUMN} each/outer case</span>
    </div>
  );

  const ReceivedQuantities: React.FC<{
    expectedQuantity: ItemQuantity;
    shippableQuantity: ItemQuantity;
    receivedInMultipleUnits: boolean;
  }> = ({expectedQuantity, shippableQuantity, receivedInMultipleUnits}) => {
    if (receivedInMultipleUnits && !content.packagingConversionFactor) {
      return <span>{'- (Conversions missing)'}</span>;
    } else if (receivedInMultipleUnits || expectedQuantity.unit !== shippableQuantity.unit) {
      if (expectedQuantity.unit === 'each') {
        return (
          <span>
            {/* eslint-disable-next-line max-len */}
            {Math.round(
              convertToEaches(shippableQuantity.amount, shippableQuantity.unit, content.packagingConversionFactor)
            )}{' '}
            {pluralizeUnit(Packaging.each, shippableQuantity.amount)} (
            {Math.trunc(
              convertToCartons(shippableQuantity.amount, shippableQuantity.unit, content.packagingConversionFactor)
            )}{' '}
            {pluralizeUnit(Packaging.carton, shippableQuantity.amount)})
          </span>
        );
      } else if (expectedQuantity.unit === 'carton') {
        return (
          <span>
            {Math.trunc(
              convertToCartons(shippableQuantity.amount, shippableQuantity.unit, content.packagingConversionFactor)
            )}{' '}
            {pluralizeUnit(Packaging.carton, shippableQuantity.amount)} ({/* eslint-disable-next-line max-len */}
            {convertToEaches(shippableQuantity.amount, shippableQuantity.unit, content.packagingConversionFactor)}{' '}
            {pluralizeUnit(Packaging.each, shippableQuantity.amount)})
          </span>
        );
      }
    } else {
      return (
        <span>
          {shippableQuantity.amount} {pluralizeUnit(shippableQuantity.unit, shippableQuantity.amount)}
        </span>
      );
    }
  };

  const updateSku = async () => {
    let packingListIdToUpdate = -1;

    if (isShipper) {
      packingListIdToUpdate = content.expectedPackingListId;
    } else {
      packingListIdToUpdate = content.shippablePackingListId;
    }

    const updateItemRequest: UpdatePackingListRequest = {
      packingListId: packingListIdToUpdate,
      quantity: updateProperties.expectedQuantity.amount
    };
    const response = await packingListService.updatePackingList(updateItemRequest);
    if (response.errors) {
      setErrors(response.errors);
    } else {
      handleRefreshPage();
    }
    setEditState('view');
  };

  const toggleConfirmationModal = () =>
    setEditState(editState === 'removalConfirmation' ? 'view' : 'removalConfirmation');

  const removeSku = async () => {
    const packingListIdToUpdate = isShipper ? content.expectedPackingListId : content.shippablePackingListId;

    const response = await containersService.deletePackingList(containerDelivery.id, packingListIdToUpdate);
    if (response.status === 'success') {
      handleRefreshPage();
    } else {
      setErrors([response.message]);
    }
    setEditState('view');
  };

  return (
    <div data-testid="sku-content-row" className={'lpn-row'}>
      {editState === 'removalConfirmation' && (
        <div className="delete-modal">
          <div className="modal-backdrop"></div>
          <div className="modal" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" aria-label="Close" onClick={toggleConfirmationModal}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h5 className="modal-title">
                    Delete SKU {content.sku} with Purchase Order {content.po}
                  </h5>
                </div>
                <div className="modal-body">Are you sure you want to remove this item?</div>
                <div className="modal-footer">
                  <button type="button" onClick={removeSku} className="btn">
                    Yes, remove this item
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="lpn-row row"
        css={css`
          background-color: ${(areQuantitiesMismatched || !content.packagingConversionFactor) &&
          [ContainerDeliveryState.receiving, ContainerDeliveryState.completed].includes(props.containerDeliveryState)
            ? tokens.color.base.yellow.v50.value
            : 'inherit'};
        `}
      >
        <div className="col-md-2">{renderItemLink(content.inventoryId, content.sku, props.isShipper)}</div>
        <div className="col-md-2">{content.description}</div>
        <div className="col-md-2">{content.po || BLANK_COLUMN}</div>
        {props.showUoms && (
          <Fragment>
            <div className="col-md-1">{renderUomData(content.expectedInner, content.expectedOuter)}</div>
            <div className="col-md-1">{renderUomData(content.receivedInner, content.receivedOuter)}</div>
          </Fragment>
        )}
        {editState === 'edit' ? (
          <div className="col-md-1">
            <div className="inner-outer-container">
              <input
                type="number"
                className="form-control"
                min={1}
                value={updateProperties.expectedQuantity.amount}
                onChange={(newQuantity) => {
                  setUpdateProperties({
                    expectedQuantity: {
                      amount: Number(newQuantity.target.value),
                      unit: content.expectedQuantity.unit
                    }
                  });
                }}
              />
              <div className="inner-outer-text">{content.expectedQuantity.unit}</div>
            </div>
          </div>
        ) : (
          <div className="col-md-1">
            {/* eslint-disable-next-line max-len */}
            {content.expectedQuantity.amount}{' '}
            {pluralizeUnit(content.expectedQuantity.unit, content.expectedQuantity.amount)}
          </div>
        )}
        <div className="col-md-2">
          <ReceivedQuantities
            expectedQuantity={content.expectedQuantity}
            shippableQuantity={content.shippableQuantity}
            receivedInMultipleUnits={content.receivedInMultipleUnits}
          />
        </div>
        <div className="col-md-1">
          {content.damagedQuantity.amount} {pluralizeUnit(content.damagedQuantity.unit, content.damagedQuantity.amount)}
        </div>
        {!isShipper && (
          <div className="col-md-1">
            <PrintSkuLabelLink {...content} inboundId={props.containerDeliveryId} />
          </div>
        )}
        {isShipper &&
          editState !== 'edit' &&
          [ContainerDeliveryState.new, ContainerDeliveryState.confirmed, ContainerDeliveryState.altered].includes(
            containerDelivery.txnState as ContainerDeliveryState
          ) && (
            <div className="col-md-1">
              <EditLpnMenu
                showDropdown={showDropdown}
                showMoveToList={false}
                containerDeliveryId={null}
                inProgressDropoffIds={[]}
                showEditToggle={true}
                toggleShowDropdown={() => {
                  setShowDropdown(!showDropdown);
                }}
                toggleShowMoveToList={null}
                editLpn={null}
                moveLPNto={null}
                confirmLPNDelete={null}
                editSkuMenuProps={{
                  editSku: () => setEditState('edit'),
                  confirmRemoveSku: () => setEditState('removalConfirmation')
                }}
              />
            </div>
          )}
        {editState === 'edit' && (
          <UpdateControls saveButtonEnabled={true} cancelHandler={() => setEditState('view')} saveHandler={updateSku} />
        )}
        {errors.map((error) => (
          <InfoBox info={error} infoType="error" />
        ))}
      </div>
    </div>
  );
};

const PrintSkuLabelLink: React.FC<{
  inboundId: number;
} & Pick<SkusTabData, 'shippableQuantity' | 'pallets' | 'inventoryId'>> = ({
  inboundId,
  shippableQuantity,
  pallets,
  inventoryId
}) => {
  const unit = shippableQuantity.unit;
  const numShippable = pallets || 1;
  const numDamaged = pallets || 0;

  const params = queryStringify({
    inventoryId,
    numShippable,
    numDamaged,
    unit
  });
  const printSkuLabelUrl = `/api/v2/containers/${inboundId}/content_labels.pdf?${params}`;
  return (
    <a className="btn" target="_blank" href={printSkuLabelUrl} id="btn-print-sku">
      <i className="fa fa-print" /> Print
    </a>
  );
};

export default SkuContentRow;
