import * as React from 'react';
import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {get} from 'lodash';
import CarrierBillingAccountService from '../account-settings/carrier-billing-accounts/CarrierBillingAccountService';
import {renderOrderLink} from '../../../libs/helpers';
import OutboundShipmentService from '../../shared/services/OutboundShipmentService';
import {FreightShipMode} from '../../shared/constants';
import DocumentUploadModal from '../../shared/DocumentUploadModal';
import {DocumentNotableType} from '../../shared/CommonInterfaces';
import {formatDateTime} from '../../retail/Utilities';
import {ShipmentDetails} from './SharedInterfaces';
import DocumentList from './DocumentList';
import Notes from './Notes';
import ShippingOptionOverride from './ShippingOptionOverride';
import FulfillmentService from './FulfillmentService';

interface Props {
  fulfillmentService?: FulfillmentService;
  carrierBillingAccountService: CarrierBillingAccountService;
  outboundShipmentService: OutboundShipmentService;
  hasCarrierAccounts: boolean;
  details: ShipmentDetails;
  toggleModal: any;
  handleCancel: any;
  loading: boolean;
  usesOrderManager: boolean;
  handleCheckShippingLabelUpdate: any;
  authenticityToken: string;
  showVendor?: boolean;
  omnichannelEnabled: boolean;
  handleShipmentUpdated: () => void;
  bypassPickupDetails: boolean;
}

function DetailsHeader(props: Props) {
  const [error, setError] = React.useState(null);
  const [documents, setDocuments] = React.useState([]);
  const [showDocumentUploadModal, setShowDocumentUploadModal] = React.useState(false);
  const handleNotesSaved = async (notes: string) => {
    const response = await props.outboundShipmentService.updateShipment({
      shipmentId: props.details.shipmentId,
      notes
    });

    if (response.errors && response.errors.length > 0) {
      setError(response.errors[0].detail);
    }

    props.handleShipmentUpdated();
  };

  useEffect(() => {
    setDocuments(props.details.documents);
  }, [props.details.documents]);

  const carrierInfo = () => {
    if (!props.details.shipmentId) {
      return <React.Fragment></React.Fragment>;
    }

    if (props.details.shipMode !== 'freight') {
      return (
        <ShippingOptionOverride
          fulfillmentService={props.fulfillmentService}
          carrierBillingAccountService={props.carrierBillingAccountService}
          shipment={props.details}
          canEdit={props.hasCarrierAccounts && !props.details.isBatched && props.details.state !== 'cancelled'}
          handleCheckShippingLabelUpdate={props.handleCheckShippingLabelUpdate}
        />
      );
    } else {
      return (
        <React.Fragment>
          {!props.bypassPickupDetails && (
            <li className="row">
              <div className="bold col-sm-5">SCAC/Carrier:</div>
              <div className="col-sm-6">{props.details.freightLoadStop?.freightLoad?.scac || '--'}</div>
            </li>
          )}

          {!props.bypassPickupDetails && (
            <li className="row">
              <div className="bold col-sm-5">PRO #:</div>
              <div className="col-sm-6">{props.details.freightLoadStop?.proNumber || '--'}</div>
            </li>
          )}

          {!props.bypassPickupDetails && (
            <li className="row">
              <div className="bold col-sm-5">Seal #:</div>
              <div className="col-sm-6">{props.details.freightLoadStop?.sealNumber || '--'}</div>
            </li>
          )}

          {!props.bypassPickupDetails && (
            <li className="row">
              <div className="bold col-sm-5">Trailer #:</div>
              <div className="col-sm-6">{props.details.freightLoadStop?.freightLoad?.trailerNumber || '--'}</div>
            </li>
          )}

          <li className="row">
            <div className="bold col-sm-5">Scheduled Ship Date:</div>
            <div className="col-sm-6">{props.details.freightLoadStop?.freightLoad?.scheduledShipDate || '--'}</div>
          </li>
        </React.Fragment>
      );
    }
  };

  const assignedCarrier = () => {
    let documentTypes = documents.map((document) => document.type);
    documentTypes = documentTypes.filter((item, pos) => documentTypes.indexOf(item) === pos);
    if (!props.loading && (props.details.isBatched || documentTypes.indexOf('Shipping Label') !== -1)) {
      return (
        <li className="row">
          <div className="bold col-sm-5">Assigned Carrier:</div>
          <div className="col-sm-6">
            {get(props.details, 'shippingLabelInfo.carrierDisplayName')}-
            {get(props.details, 'shippingLabelInfo.carrierServiceType')}
          </div>
        </li>
      );
    }
  };

  const headerDescription = (headerValue) => {
    if (!props.omnichannelEnabled) {
      headerValue = 'eCommerce ' + headerValue;
    }

    return headerValue;
  };

  return (
    <div className="row">
      {props.details.repairable && (
        <div className="alert alert-danger col-sm-12">
          <span>
            {'We encountered some problems preparing this shipment. Please return to the '}
            <Link to="/s/fulfillment/ecommerce">Issues tab</Link> to repair it.
          </span>
        </div>
      )}
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <div className="col-sm-12 space-below">
        <div className="breadcrumbs">
          <Link to="/s/fulfillment/ecommerce">{headerDescription('Fulfillment')}</Link>
          <i className="fa fa-angle-right"></i>
          Shipment Detail
        </div>
      </div>
      <h2 className="col-sm-6 space-below">
        {headerDescription('Shipment Details')} - #{props.details.shipmentId}
      </h2>
      {!props.usesOrderManager && ['issues', 'new'].indexOf(props.details.state) !== -1 && (
        <div className="col-sm-6 text-right">
          <button id="cancel-button" className="btn" onClick={() => props.toggleModal()}>
            <i className="fa fa-times"></i> Cancel
          </button>
        </div>
      )}
      <div className="col-sm-12 space-below">
        <b>Status:</b>
        &nbsp;
        {props.details.state}
        &nbsp;&mdash;&nbsp;
        {
          <>
            <b>Submitted: </b>
            {formatDateTime(props.details.createdAt, 'MM/DD/YYYY [at] h:mm A z', props.details.warehouseTimezone)}
          </>
        }
      </div>
      <div className="col-sm-4 thirds">
        <h4 className="text-uppercase">
          <i className="fa fa-list-ol grey3"></i>
          &nbsp; Shipment Details
        </h4>
        <div className="row no-margin">
          <ul className="col-sm-12 no-padding">
            <li className="row">
              <div className="bold col-sm-5">Res ID:</div>
              <div className="col-sm-6">{props.details.reservationFriendlyName || 'N/A'}</div>
            </li>
            <li className="row">
              <div className="bold col-sm-5">PO:</div>
              <div className="col-sm-6">{props.details.po || 'N/A'}</div>
            </li>
            {props.details.customerReferenceNumber && (
              <li className="row">
                <div className="bold col-sm-5">Customer Reference #:</div>
                <div className="col-sm-6">{props.details.customerReferenceNumber}</div>
              </li>
            )}
            {carrierInfo()}
            {props.details.shipAfter && (
              <li className="row" id="ship-after-date">
                <div className="bold col-sm-5">Ship After:</div>
                <div className="col-sm-6">{props.details.shipAfter}</div>
              </li>
            )}
            <li className="row">
              <div className="bold col-sm-5">Ship by:</div>
              <div className="col-sm-6">{props.details.shipOn}</div>
            </li>
            {props.showVendor && props.details.vendorName && (
              <li className="row">
                <div className="bold col-sm-5">Vendor:</div>
                <div className="col-sm-6">{props.details.vendorName}</div>
              </li>
            )}
            {props.details.orderReferenceId && (
              <li className="row" id="order-reference-id-line">
                <div className="bold col-sm-5">Order Reference ID:</div>
                <div className="col-sm-6">{renderOrderLink(props.details.orderReferenceId)}</div>
              </li>
            )}
          </ul>
        </div>
        <div className="vertical-rule hidden-xs"></div>
      </div>
      <div className="col-sm-4 thirds">
        <h4 className="text-uppercase">
          <i className="fa fa-map-marker grey3"></i>
          &nbsp; Shipping Info
        </h4>
        <div className="row no-margin">
          <ul className="col-sm-12 no-padding">
            <li className="row">
              <div className="bold col-sm-5">Ship to:</div>
              <div className="col-sm-6">{props.details.shipToName}</div>
            </li>
            <li className="row">
              <div className="bold col-sm-5">Address:</div>
              <div className="col-sm-6">
                {props.details.shipToAddress && (
                  <address>
                    {props.details.shipToAddress.line1}
                    <br />
                    {props.details.shipToAddress.line2 && (
                      <span>
                        {props.details.shipToAddress.line2}
                        <br />
                      </span>
                    )}
                    {props.details.shipToAddress.line3 && (
                      <span>
                        {props.details.shipToAddress.line3}
                        <br />
                      </span>
                    )}
                    {props.details.shipToAddress.locality},{props.details.shipToAddress.region}{' '}
                    {props.details.shipToAddress.postcode}
                    <br />
                    {props.details.shipToAddress.country}
                  </address>
                )}
              </div>
            </li>
            {assignedCarrier()}
          </ul>
        </div>
        <div className="vertical-rule hidden-xs"></div>
      </div>
      <div className="col-sm-4 thirds">
        <h4 className="text-uppercase">
          <i className="fa fa-file grey3"></i>
          &nbsp; Documents &amp; Labels
        </h4>
        <div>
          <ShipmentStatus
            issues={props.details.issues}
            documents={documents}
            loading={props.loading}
            isBatched={props.details.isBatched}
            isFreight={props.details.shipMode === FreightShipMode}
          />
          {props.omnichannelEnabled && (
            <a className="docs-upload-btn" onClick={() => setShowDocumentUploadModal(true)}>
              + Upload Documents
            </a>
          )}
          {props.details.shipMode === FreightShipMode && (
            <Notes notes={props.details.notes} onNotesSaved={handleNotesSaved} />
          )}
        </div>
      </div>
      <DocumentUploadModal
        documents={[]}
        documentsRoute={'/api/v2/documents'}
        notableType={DocumentNotableType.ecomShipment}
        notableId={props.details.shipmentId}
        showUploadModal={showDocumentUploadModal}
        toggleUploadModal={() => setShowDocumentUploadModal(!showDocumentUploadModal)}
        onDocumentSaved={(savedDoc) => {
          setShowDocumentUploadModal(false);
          const newDocs = documents.concat({
            filename: savedDoc.note.name,
            type: savedDoc.documentType,
            src: savedDoc.note.url
          });
          setDocuments(newDocs);
        }}
      />
    </div>
  );
}

function ShipmentStatus(props) {
  if (props.issues.length > 0) {
    return (
      <div className="alert alert-danger" role="alert">
        <span>
          {' Oops! Shipping label failed, '}
          {props.issues.map((issue) => issue.description).join(', ')}
        </span>
      </div>
    );
  } else {
    return (
      <DocumentList
        documents={props.documents}
        loading={props.loading}
        isBatched={props.isBatched}
        isFreight={props.isFreight}
      />
    );
  }
}

export default DetailsHeader;
