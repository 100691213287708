/** @jsxRuntime classic */
/** @jsx jsx */

import {useEffect, useState} from 'react';
import {css, jsx} from '@emotion/react';
import {Column, Heading, Row, Text} from '@flexe/ui-components';
import {containerStyle} from '../styles';
import {InputFieldData} from '../userManagementInterfaces';
import {generateNotification} from '../notifications/notification-utility';

const fullWidthInputStyle = css({width: '100%'});

export interface NewMobileUserFormProps {
  alertMessage?: string;
  authenticityToken: string;
  initialName?: InputFieldData;
  initialPin?: InputFieldData;
  initialUsername?: InputFieldData;
  warehouses: NewMobileUserWarehouse[];
}

interface NewMobileUserWarehouse {
  id: string;
  name: string;
}

const NewMobileUserForm = ({
  authenticityToken,
  initialName,
  initialPin,
  initialUsername,
  alertMessage,
  warehouses
}: NewMobileUserFormProps) => {
  const [name, setName] = useState(initialName?.value || '');
  const [pin, setPin] = useState(initialPin?.value || '');
  const [username, setUsername] = useState(initialUsername?.value || '');
  const [warehouseId, setWarehouseId] = useState('');

  useEffect(() => {
    if (alertMessage) {
      generateNotification({level: 'error', message: alertMessage, location: 'in-place', durationMs: null});
    }
  }, [alertMessage]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleWarehouseChange = (event) => {
    setWarehouseId(event.target.value);
  };

  return (
    <div css={containerStyle}>
      <Column>
        <Heading level="h2">Add Mobile Device User</Heading>
        <Text tag="p">Create an account for mobile device login. ALL FIELDS ARE REQUIRED.</Text>
        <form role="form" id="new_mobile_user" action="/user_management/create_mobile_user" method="post">
          <Column>
            <input type="hidden" name="authenticity_token" value={authenticityToken} />

            <Row childWidths={['grid-2', 'grid-4']} margin={['0', '200']}>
              <Text tag="label" htmlFor="name" type="h90">
                Name
              </Text>
              <div>
                <input
                  type="text"
                  id="name"
                  name="mobile_user[name]"
                  value={name}
                  onChange={handleNameChange}
                  css={fullWidthInputStyle}
                />
                {initialName?.error && <Text tag="div">{initialName.error}</Text>}
              </div>
            </Row>

            <Row childWidths={['grid-2', 'grid-4']} margin={['0', '200']}>
              <Text tag="label" htmlFor="username" type="h90">
                Username
              </Text>
              <div>
                <input
                  type="text"
                  id="username"
                  name="mobile_user[username]"
                  value={username}
                  onChange={handleUsernameChange}
                  css={fullWidthInputStyle}
                />
                {initialUsername?.error && <Text tag="div">{initialUsername.error}</Text>}
              </div>
            </Row>

            <Row childWidths={['grid-2', 'grid-4']} margin={['0', '200']}>
              <Text tag="label" htmlFor="pin" type="h90">
                PIN
              </Text>
              <div>
                <input
                  type="text"
                  id="pin"
                  name="mobile_user[password]"
                  value={pin}
                  onChange={handlePinChange}
                  css={fullWidthInputStyle}
                />
                {initialPin?.error && <Text tag="div">{initialPin.error}</Text>}
              </div>
            </Row>

            <Row childWidths={['grid-2', 'grid-4']} margin={['0', '200']}>
              <Text tag="label" htmlFor="warehouse_id" type="h90">
                Warehouse
              </Text>
              <select id="warehouse_id" name="warehouse_id" onChange={handleWarehouseChange}>
                <option value={''}>Add mobile user to a warehouse...</option>
                {warehouses.map((warehouse) => (
                  <option key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </option>
                ))}
              </select>
            </Row>

            <div>
              <input
                type="submit"
                value="Add User"
                className="btn"
                disabled={[name, username, pin].some((field) => field.trim() === '') || warehouseId === ''}
              />
            </div>
          </Column>
        </form>
      </Column>
    </div>
  );
};

export default NewMobileUserForm;
