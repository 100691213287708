import * as React from 'react';
import {Link} from 'react-router-dom';

interface SkuCellProps {
  baseUrl: string;
  item: {
    id: number;
    sku: string;
  };
}
export function SkuCell({baseUrl, item}: SkuCellProps) {
  return (
    <Link
      className="flexe-mui-data-grid-link-cell"
      title={item.sku}
      to={`${baseUrl}/inventories/${item.id}`}
      target="_blank"
    >
      {item.sku}
    </Link>
  );
}
