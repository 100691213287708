import {Loader, Table, TableHeader} from '@flexe/ui-components';
import * as React from 'react';
import {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {renderShipment} from '../../../libs/helpers';
import {RequiredLabel} from './SharedInterfaces';

export interface RelatedShipmentsProps {
  requiredLabels: RequiredLabel[];
}

export const RelatedShipments: FunctionComponent<RelatedShipmentsProps> = (props) => {
  const headers = [
    {element: 'Shipment ID'},
    {element: 'Carrier Assigned ID'},
    {element: 'Document Link'}
  ] as TableHeader[];
  const rows = props.requiredLabels.map((requiredLabel: RequiredLabel) => {
    const labelLink = requiredLabel.url ? (
      <a href={requiredLabel.url} target="_blank">
        {requiredLabel.filename}
      </a>
    ) : (
      'N/A'
    );
    const row = [
      <div>{renderShipment(requiredLabel.shipmentId, true, false)}</div>,
      <div>{requiredLabel.carrierAssignedId}</div>,
      <div>{labelLink}</div>
    ];
    return row;
  });
  const tableData = {
    headers,
    rows
  };

  return (
    <React.Fragment>
      <div className="related-shipments">
        <h3>Related Shipments</h3>
        <Table tableData={tableData} />
      </div>
    </React.Fragment>
  );
};

export default RelatedShipments;
