import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import FlexeContext from '../../contexts/FlexeContext';
import {ApiResponse, CompanyType, ResponseError, Warehouse} from '../../shared/CommonInterfaces';
import {ShipmentDocuments} from '../../documents/ShipmentDocuments';
import OutboundFreightLoadService from '../../shared/services/OutboundFreightLoadService';
import OutboundManifestService from '../../shared/services/OutboundManifestService';
import HeaderDetail from '../../shared/HeaderDetail';
import DocumentsService from '../../shared/services/DocumentsService';
import {FreightShipMode, ParcelShipMode} from '../../shared/constants';
import {formatDate} from '../../shared/utilities/DataFormatting';
import LoadsService from '../../shared/services/LoadsService';
import {FreightLoad} from '../outbound-freight/FreightInterfaces';
import HeaderEditableDetail, {HeaderEditableDetailType} from '../../shared/HeaderEditableDetail';
import WarehouseService from '../../shared/services/WarehouseService';
import LoadContents from './load-contents/LoadContents';
import {LoadStatusMap} from './LoadConstants';
import {LoadDetailActions} from './LoadDetailActions';
import {UpsertLoadRequest, LoadDetail, UpsertLoadState} from './LoadInterfaces';
import {UpsertLoadModal} from './create-load-modal/UpsertLoadModal';
import {LoadShipConfirmation} from './LoadShipConfirmation';
import {ManifestStatus, OutboundTrailerManifestDetail} from './ManifestInterfaces';
import CancelLoadModal from './CancelLoadModal';

interface LoadDetailProps {
  loadId: number;
  selectedWarehouse: Warehouse;
  parcelTrailerLoadingEnabled: boolean;
  freightTrailerLoadingEnabled: boolean;
  enableLoadBlockIfNoLabelsModal: boolean;
}

const SHIP_CONFIRM_MODAL_FF = 'wms_7293_load_ship_confirm';

const LoadDetail: React.FC<LoadDetailProps> = (props) => {
  const {authenticityToken} = useContext(FlexeContext);
  const [showEditLoadModal, setShowEditLoadModal] = useState<boolean>(false);
  const resultRef = useRef(null);
  const outboundManifestService = new OutboundManifestService(authenticityToken);
  const freightLoadService = new OutboundFreightLoadService(authenticityToken);
  const documentsService = new DocumentsService(CompanyType.warehouse);
  const loadsService = new LoadsService(authenticityToken);
  const warehouseService = new WarehouseService(authenticityToken);

  // load is an aggregate of all load data that we display
  const [load, setLoad] = useState<LoadDetail>(null);

  // load metadata is the load fields that must be defined to perform an upsert
  const [loadMetadata, setLoadMetadata] = useState<UpsertLoadState>(null);
  const [palletsLoaded, setPalletsLoaded] = useState<number>(0);

  const [billOfLadingIsGenerating, setBillOfLadingIsGenerating] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showCancelLoadActionModal, setShowCancelLoadActionModal] = useState<boolean>(false);
  const [showShipConfirmation, setShowShipConfirmation] = useState<boolean>(false);
  const [shipConfirmEnabled, setShipConfirmEnabled] = useState<boolean>(false);
  const toggleShipConfirmation = () => setShowShipConfirmation(!showShipConfirmation);

  const breadcrumbs = (
    <div className="breadcrumbs" ref={resultRef}>
      <a href="/wh/loads">Loads</a>
      <span className="navigation-separator">/</span>
      {props.loadId}
    </div>
  );

  const toggleEditing = () => {
    setShowEditLoadModal(!showEditLoadModal && load?.shipMode === FreightShipMode);
    setEditing(!editing);
  };

  const enqueueBillOfLadingGeneration = async () => {
    setError(null);
    try {
      setBillOfLadingIsGenerating(true);
      const response = await freightLoadService.enqueueBillOfLadingGeneration(load.freightLoadId.toString());
      if (response.errors?.length > 0) {
        showApiResponseError(response);
      }
    } catch (e) {
      setError(e.toString());
    }
  };

  async function getManifestDetails(loadId: number): Promise<OutboundTrailerManifestDetail> {
    setError(null);
    try {
      const getManifestDetailResponse = await outboundManifestService.getManifestDetail(loadId);
      if (getManifestDetailResponse.errors?.length > 0) {
        showApiResponseError(getManifestDetailResponse);
      }
      return getManifestDetailResponse.data;
    } catch (e) {
      setError(e.toString());
    }
  }

  async function getFreightLoadDetails(freightLoadId: number): Promise<FreightLoad> {
    setError(null);
    try {
      const getFreightLoadsResponse = await freightLoadService.getFreightLoads([freightLoadId]);
      if (getFreightLoadsResponse.errors?.length > 0) {
        showApiResponseError(getFreightLoadsResponse);
      }
      return getFreightLoadsResponse.data.freightLoads[0];
    } catch (e) {
      setError(e.toString());
    }
  }

  const fetchLoadDetails = async () => {
    setError(null);
    const manifestDetails = await getManifestDetails(props.loadId);
    let loadDetails: LoadDetail;

    switch (manifestDetails.shipMode) {
      case FreightShipMode: {
        const freightLoad = await getFreightLoadDetails(manifestDetails.freightLoadId);
        // assumption: loads only have one stop, order by id
        const freightLoadStop = freightLoad.loadStops.sort((a, b) => a.id - b.id)[0];

        loadDetails = {
          id: manifestDetails.id,
          status: manifestDetails.status,
          shipMode: FreightShipMode,
          destinationId: freightLoadStop.destinationTag,
          createdAt: manifestDetails.createdAt,
          updatedAt: manifestDetails.updatedAt,
          scac: freightLoad.scac,
          freightLoadGroup: freightLoad.freightLoadGroup,
          proNumber: freightLoadStop.proNumber,
          trailerNumber: freightLoad.trailerNumber,
          sealNumber: freightLoadStop.sealNumber,
          dockLocationLabel: manifestDetails.doorLocation,
          dockLocationId: manifestDetails.dockLocationId,
          totalWeight: manifestDetails.totalWeight.toString(10),
          appointmentTime: freightLoad.scheduledShipDate,
          loadStopId: freightLoadStop.id,
          bolNumber: freightLoadStop.bolNumber,
          freightLoadId: freightLoad.id,
          reservationId: manifestDetails.reservationId
        };
        break;
      }
      case ParcelShipMode: {
        loadDetails = {
          id: manifestDetails.id,
          status: manifestDetails.status,
          shipMode: ParcelShipMode,
          destinationId: null,
          createdAt: manifestDetails.createdAt,
          updatedAt: manifestDetails.updatedAt,
          scac: manifestDetails.scacCode,
          freightLoadGroup: null,
          proNumber: null,
          trailerNumber: manifestDetails.trailerId,
          sealNumber: null,
          dockLocationLabel: manifestDetails.doorLocation,
          dockLocationId: manifestDetails.dockLocationId,
          totalWeight: manifestDetails.totalWeight.toString(10),
          appointmentTime: null,
          loadStopId: null,
          bolNumber: null,
          freightLoadId: null,
          reservationId: manifestDetails.reservationId
        };
        break;
      }
    }

    const editableLoadFields: UpsertLoadState = {
      loadId: loadDetails.id,
      warehouseId: props.selectedWarehouse.id,
      freightLoadId: loadDetails.freightLoadId,
      scac: loadDetails.scac,
      trailerNumber: loadDetails.trailerNumber,
      proNumber: loadDetails.proNumber,
      sealNumber: loadDetails.sealNumber,
      dockLocationId: loadDetails.dockLocationId,
      dockLocationLabel: loadDetails.dockLocationLabel,
      destinationTag: loadDetails.destinationId
    };

    setLoad(loadDetails);
    setLoadMetadata(editableLoadFields);
  };

  useEffect(() => {
    fetchLoadDetails();
    fetchFeatureFlags();
  }, [authenticityToken]);

  const fetchFeatureFlags = async () => {
    try {
      const response = await warehouseService.getFeatureFlag(SHIP_CONFIRM_MODAL_FF, props.selectedWarehouse.id);
      setShipConfirmEnabled(response.data.value);
    } catch (e) {
      setError(e.toString());
    }
  };

  const showApiResponseError = (response: ApiResponse<any>) => {
    if (response.errors?.length <= 0) {
      return;
    }

    // Grab the first error because there shouldn't ever be more than one
    const e = response.errors[0];
    setError(e.detail);
  };

  const placeholderStub = () => {
    return;
  };

  const onStartEdits = () => {
    setError(null);
    toggleEditing();
  };

  const onCancelEdits = () => {
    toggleEditing();
    setLoadMetadata({
      ...loadMetadata,
      trailerNumber: load.trailerNumber,
      scac: load.scac
    });
  };

  const parcelLoadEditsWereMade = (): boolean => {
    const trailerNumberChanged = loadMetadata?.trailerNumber !== load?.trailerNumber;
    const scacChanged = loadMetadata?.scac !== load?.scac;
    return [trailerNumberChanged, scacChanged].some((change) => change);
  };

  // scac & trailer number callbacks for the parcel load edit flow
  const onParcelTrailerNumberChange = (newTrailerNumber: string) => {
    setLoadMetadata({
      ...loadMetadata,
      trailerNumber: newTrailerNumber
    });
  };

  const onParcelScacChange = (newScac: string) => {
    setLoadMetadata({
      ...loadMetadata,
      scac: newScac
    });
  };

  /*
    Freight loads are edited and committed through the UpsertLoadModal. All we need to do is
    update the state when an edit is made so that the metadata displayed in the header is up-to-date.

    UPDATE: freight loads can now also be edited in the LoadShipConfirmation modal, but network calls
    occur in this component so this method is not used. onSaveShipConfirmationEdits is invoked for that flow instead.
   */
  const onSaveFreightLoadEdits = (loadWithEdits: UpsertLoadState) => {
    setLoadMetadata(loadWithEdits);
    toggleEditing();
  };

  // Parcel loads are edited and committed in this component using in-line edits
  const onSaveParcelLoadEdits = async () => {
    setError(null);
    if (!parcelLoadEditsWereMade()) {
      setEditing(false);
      return;
    }
    try {
      const data: UpsertLoadRequest = {
        loadId: load.id,
        warehouseId: props.selectedWarehouse.id,
        scac: loadMetadata?.scac,
        trailerNumber: loadMetadata?.trailerNumber,
        proNumber: load.proNumber,
        sealNumber: load.sealNumber,
        dockLocationId: load.dockLocationId
      };
      const response = await loadsService.upsertLoad(data);
      if (response.errors?.length > 0) {
        showApiResponseError(response);
        onCancelEdits();
      }

      setLoad((prevLoad) => ({
        ...prevLoad,
        trailerNumber: loadMetadata?.trailerNumber,
        carrier: loadMetadata?.scac
      }));
    } catch (e) {
      setError(e.toString());
      onCancelEdits();
    } finally {
      toggleEditing();
    }
  };

  // Callback for shipping a load with the LoadShipConfirmation modal.
  // Returns an error so that it can be displayed within the modal.
  const onSaveShipConfirmationEdits = async (upsert: UpsertLoadState): Promise<ResponseError> => {
    try {
      const response = await loadsService.upsertLoad(upsert);
      if (response.errors?.length > 0) {
        return response.errors[0];
      }

      setLoadMetadata(upsert);
      setLoad((prevLoad) => ({
        ...prevLoad,
        trailerNumber: upsert.trailerNumber,
        scac: upsert.scac,
        proNumber: upsert.proNumber,
        sealNumber: upsert.sealNumber
      }));
    } catch (e) {
      return e;
    }

    return null;
  };

  const onShipClick = async () => {
    if (shipConfirmEnabled && load.shipMode === FreightShipMode) {
      setShowShipConfirmation(true);
    } else {
      const shipError = await onShip();
      if (shipError) {
        setError(shipError.detail);
      }
    }
  };

  const onShip = async (): Promise<ResponseError> => {
    setError(null);
    try {
      const response = await outboundManifestService.completeAndSubmitToCarrier(load.id);
      if (response.errors?.length > 0) {
        return response.errors[0];
      } else {
        setLoad((prevLoad) => ({
          ...prevLoad,
          status: ManifestStatus.completed
        }));
      }
    } catch (e) {
      return e;
    }

    setShowShipConfirmation(false);
    return null;
  };

  const onCancelLoad = async () => {
    setError(null);
    try {
      const response = await outboundManifestService.cancelManifest(load.id);
      if (response.errors?.length > 0) {
        showApiResponseError(response);
      } else {
        setLoad((prevLoad) => ({
          ...prevLoad,
          status: ManifestStatus.cancelled
        }));
      }
    } catch (e) {
      setError(e.toString());
    }
    toggleCancelLoadModal();
  };

  const toggleCancelLoadModal = () => setShowCancelLoadActionModal(!showCancelLoadActionModal);

  return (
    <div className="load-detail-page container-fluid">
      {showCancelLoadActionModal && (
        <CancelLoadModal
          onCancel={onCancelLoad}
          loadId={load?.id.toString()}
          isOpen={showCancelLoadActionModal}
          toggleModal={toggleCancelLoadModal}
        />
      )}
      {breadcrumbs}
      <div className="load-metadata-button-wrapper">
        <LoadDetailActions
          onStartEdits={onStartEdits}
          onCancelEdits={onCancelEdits}
          onSaveEdits={onSaveParcelLoadEdits}
          onShip={onShipClick}
          onCancelLoad={toggleCancelLoadModal}
          shipMode={load?.shipMode}
          editing={editing}
          loadStatus={load?.status}
        />
      </div>
      <h1>Load #{props.loadId}</h1>
      <span className={`load-status ${LoadStatusMap.get(load?.status)?.className}`}>
        {LoadStatusMap.get(load?.status)?.text}
      </span>
      <br />
      <div>
        {error != null && (
          <div className="alert alert-danger" role="alert" key={`error:${1}`}>
            {error}
          </div>
        )}
      </div>
      <table style={{width: '100%'}}>
        <tbody>
          <tr>
            <th>
              <div id="metadata">
                <div className="col-md-4">
                  <HeaderDetail
                    label="Mode:"
                    classes={['non-bold']}
                    value={load?.shipMode.charAt(0).toUpperCase() + load?.shipMode.slice(1) || '--'}
                  />
                  {load?.freightLoadGroup ? (
                    <HeaderDetail label="Load Group:" classes={['non-bold']} value={load?.freightLoadGroup || '--'} />
                  ) : (
                    <HeaderDetail
                      label="Destination ID:"
                      classes={['non-bold']}
                      value={loadMetadata?.destinationTag || '--'}
                    />
                  )}
                  <HeaderDetail
                    label="Created At:"
                    classes={['non-bold']}
                    value={load?.createdAt ? formatDate(new Date(load.createdAt), ' MMM D, h:mm a') : '--'}
                  />
                  <HeaderDetail
                    label="Last Update:"
                    classes={['non-bold']}
                    value={load?.updatedAt ? formatDate(new Date(load?.updatedAt), ' MMM D, h:mm a') : '--'}
                  />
                  <div className="vertical-rule" />
                </div>
                <div className="col-md-2">
                  {load?.shipMode === FreightShipMode && (
                    <HeaderDetail
                      label={'SCAC:'}
                      classes={['non-bold']}
                      value={loadMetadata?.scac}
                      hideWhenEmpty={false}
                    />
                  )}
                  {load?.shipMode === ParcelShipMode && ( // SCAC is editable _in-line_ for parcel only.
                    <HeaderEditableDetail // Freight SCAC is editable via UpsertLoadModal
                      label={'SCAC:'}
                      value={loadMetadata?.scac}
                      isEditing={editing}
                      type={HeaderEditableDetailType.text}
                      onChange={onParcelScacChange}
                      classes={['carrier-input', 'non-bold']}
                      maxLength={50}
                    />
                  )}
                  {load?.shipMode === FreightShipMode && (
                    <HeaderDetail
                      label={'PRO\u00A0#:'}
                      value={loadMetadata?.proNumber}
                      classes={['pro-input', 'non-bold', 'wrap-text']}
                      hideWhenEmpty={false}
                    />
                  )}
                  <HeaderEditableDetail
                    label={'Trailer\u00A0#:'}
                    value={loadMetadata?.trailerNumber}
                    isEditing={editing && load?.shipMode === ParcelShipMode} // freight edits this via modal
                    type={HeaderEditableDetailType.text}
                    onChange={onParcelTrailerNumberChange}
                    maxLength={50}
                    classes={['trailer-input', 'non-bold', 'wrap-text']}
                  />
                  {load?.shipMode === FreightShipMode && (
                    <HeaderDetail
                      label={'Seal\u00A0#:'}
                      value={loadMetadata?.sealNumber}
                      classes={['trailer-input', 'non-bold', 'wrap-text']}
                      hideWhenEmpty={false}
                    />
                  )}
                  <div className="vertical-rule" />
                </div>
                <div className="col-md-3">
                  <HeaderDetail
                    label={'Dock Loc:'}
                    classes={['non-bold']}
                    value={
                      <a href={`/wh/locations/${loadMetadata?.dockLocationId}`}>{loadMetadata?.dockLocationLabel}</a>
                    }
                    hideWhenEmpty={false}
                  />
                  <HeaderDetail
                    label="Total Weight:"
                    classes={['non-bold']}
                    value={(load?.totalWeight || '--') + ' lb'}
                    hideWhenEmpty={false}
                  />
                  <HeaderDetail label="Appt. Time:" classes={['non-bold']} value={load?.appointmentTime} />
                </div>
              </div>
            </th>
          </tr>
          {load?.shipMode === FreightShipMode && (
            <tr>
              <td>
                <div className="container-fluid">
                  <ShipmentDocuments
                    authenticityToken={authenticityToken}
                    reservationId={load.reservationId}
                    documentsService={documentsService}
                    stopId={load.loadStopId}
                    bolNumber={load.bolNumber}
                    billOfLadingIsGenerating={billOfLadingIsGenerating}
                    enqueueBillOfLadingGeneration={enqueueBillOfLadingGeneration}
                    batchId={0} // this needs to be made real or removed
                    displayPrintLpnLabelsButton={false} // this needs to be made real or removed
                    enableFreightPackingListRefresh={false} // this needs to be made real or removed
                    freightPackingListIsGenerating={false} // this needs to be made real or removed
                    shipmentStatus={''} // this needs to be made real or removed
                    showUploadButton={false} // this needs to be made real or removed
                    ssccLabelsAreGenerating={false} // this needs to be made real or removed
                    checkLabelRequirementsForCompletion={placeholderStub} // this needs to be made real or removed
                    enqueueSsccLabelRegeneration={placeholderStub} // this needs to be made real or removed
                    enqueueFreightPackingListRegeneration={placeholderStub} // this needs to be made real or removed
                  />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <LoadContents
        authenticityToken={authenticityToken}
        warehouse={props.selectedWarehouse}
        load={load}
        setPalletsLoaded={setPalletsLoaded}
      />
      <UpsertLoadModal
        isOpen={showEditLoadModal}
        toggleModal={toggleEditing}
        cancelUpsert={toggleEditing}
        warehouseId={props.selectedWarehouse.id}
        loadState={loadMetadata}
        onEditLoad={onSaveFreightLoadEdits}
      />
      {showShipConfirmation && (
        <LoadShipConfirmation
          loadStatus={load.status}
          loadData={loadMetadata}
          palletsLoaded={palletsLoaded}
          onEditLoad={onSaveShipConfirmationEdits}
          onConfirm={onShip}
          toggle={toggleShipConfirmation}
        />
      )}
    </div>
  );
};

export default LoadDetail;
