import {format} from 'date-fns';
import * as React from 'react';
import {formatDateTime} from '../retail/Utilities';
import * as WorkOrder from './WorkOrdersInterfaces';

interface Props {
  kitWorkOrderDetail: WorkOrder.KitWorkOrderDetail;
}

function WorkOrderDetailTable({kitWorkOrderDetail}: Props) {
  const noData = '--';
  const notComplete = 'None (not complete)';
  const noSpecialInstructions = 'No special instructions';
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTimeFormat = 'MM/DD/YYYY [at] h:mm A z';
  const {
    description,
    quantityRequested,
    completedById,
    kitSku,
    createdAt,
    completeBy,
    warehouse,
    completedAt,
    instructions
  } = kitWorkOrderDetail;

  return (
    <React.Fragment>
      <h3>Details</h3>
      <div className="work-order-detail-container">
        <dl className="work-order-detail-definition-list">
          <dt>Product Key: </dt>
          <dd>{kitSku || noData}</dd>
          <dt>Product Name: </dt>
          <dd>{description || noData}</dd>
          <dt>Type: </dt>
          <dd>Kit to stock</dd>
          <dt>Quantity Requested: </dt>
          <dd>{quantityRequested || noData}</dd>
          <dt>Completed by: </dt>
          <dd>{(completedById === '0' && <i>{notComplete}</i>) || completedById}</dd>
        </dl>
        <dl className="work-order-detail-definition-list">
          <dt>Date Requested: </dt>
          <dd>{(createdAt && formatDateTime(new Date(createdAt), dateTimeFormat, currentTimeZone)) || noData}</dd>
          <dt>Complete by: </dt>
          <dd>{(completeBy && format(completeBy, 'MM/DD/YYYY')) || noData}</dd>
          <dt>Warehouse: </dt>
          <dd>{warehouse.name || noData}</dd>
          <dt>Completed on: </dt>
          <dd>
            {completedAt && formatDateTime(new Date(completedAt), dateTimeFormat, currentTimeZone)}
            {!completedAt && <i>{notComplete}</i>}
          </dd>
        </dl>
      </div>
      <div className="special-instructions">
        {instructions}
        {!instructions && <i className="no-special-instructions">{noSpecialInstructions}</i>}
      </div>
    </React.Fragment>
  );
}

export default WorkOrderDetailTable;
