import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import TypeAhead, {TypeAheadOption} from '../TypeAhead';

interface Props {
  showFilterRangeModal: boolean;
  locationRangeStart: string;
  locationRangeEnd: string;
  locationTypeaheadStartOptions: TypeAheadOption[];
  locationTypeaheadEndOptions: TypeAheadOption[];
  locationRangeLength: number;
  maxFilteredLocations: number;
  handleLocationRangeSelection: (event: any) => Promise<void>;
  updateLocationRangeStartTypeahead: (query: any) => Promise<void>;
  updateLocationRangeEndTypeahead: (query: any) => Promise<void>;
  updateLocationRangeStart: (locationRangeStart: any) => Promise<void>;
  updateLocationRangeEnd: (locationRangeEnd: any) => Promise<void>;
  toggleFilterRangeModal: (event: any) => Promise<void>;
  handleCreate: (event: any) => Promise<void>;
}

const CycleCountFilterRangeModal: FunctionComponent<Props> = (props) => {
  return (
    <LegacyModal
      id="filter-range-modal"
      title="Add Location Range"
      show={props.showFilterRangeModal}
      size="small"
      toggleModal={props.toggleFilterRangeModal}
      footer={
        <div>
          <a onClick={props.toggleFilterRangeModal}>Cancel</a>
          <button
            id="add-range-agree"
            type="button"
            className="btn cta"
            disabled={!(props.locationRangeStart && props.locationRangeEnd)}
            onClick={props.handleLocationRangeSelection}
          >
            Add to cycle count
          </button>
        </div>
      }
      disableClose={false}
    >
      <div>
        Select starting and ending locations to add a range to your cycle count.
        <br />
        <br />
        <div>
          <div className="col-sm-6">
            <label htmlFor="locationRangeStart">Start at:</label>
            <TypeAhead
              name="locationRangeStart"
              testId="locationRangeStart"
              onRequestOptions={props.updateLocationRangeStartTypeahead}
              placeholder="Type location..."
              onSelect={props.updateLocationRangeStart}
              options={props.locationTypeaheadStartOptions}
              value={props.locationRangeStart}
              dropdownCss="typeahead-dropdown-menu"
              typeaheadCss="cycle-count-typeahead"
            />
          </div>
          <div className="col-sm-6">
            <label htmlFor="locationRangeEnd">End at:</label>
            <TypeAhead
              name="locationRangeEnd"
              testId="locationRangeEnd"
              onRequestOptions={props.updateLocationRangeEndTypeahead}
              placeholder="Type location..."
              onSelect={props.updateLocationRangeEnd}
              options={props.locationTypeaheadEndOptions}
              value={props.locationRangeEnd}
              dropdownCss="typeahead-dropdown-menu"
              typeaheadCss="cycle-count-typeahead"
            />
          </div>
          <br />
          <br />
          <br />
        </div>
        <div className="col-sm-6">
          <br />
          {props.locationRangeLength <= props.maxFilteredLocations ? (
            <p>{props.locationRangeLength} locations selected in this range</p>
          ) : (
            <p>
              Too many locations selected. Only {props.maxFilteredLocations} locations will be added to the cycle count.
            </p>
          )}
        </div>
      </div>
    </LegacyModal>
  );
};

export default CycleCountFilterRangeModal;
