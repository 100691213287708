import * as React from 'react';
import {useEffect, useState} from 'react';
import {isEqual} from 'lodash';
import {Filter, FilterOption, FilterType} from '../../../shared/CommonInterfaces';
import {FilterKeys, FilterParams, FilterSearchTypes} from '../ShipmentInterfaces';
import DropDown, {DropDownColor, DropDownOption} from '../../../shared/DropDown';
import OutboundShipmentService from '../../../shared/services/OutboundShipmentService';
import TypeAhead, {TypeAheadOption} from '../../../shared/TypeAhead';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const circlePlusIcon = require('../../../../../assets/images/common/icon_circle_plus.svg');
interface Props {
  filterParams: FilterParams;
  allowedFilterKeys: FilterKeys[];
  filterSearchTypeOverrides?: Map<string, FilterSearchTypes>;
  onFilterChange(key: string, value: string);
  warehouseId?: number;
  outboundShipmentService?: OutboundShipmentService;
}

const parentFilter: Filter = {
  displayName: 'Select Filter',
  key: 'parent_filter',
  type: FilterType.Dropdown
};

const ShipmentFilter: React.FC<Props> = (props) => {
  const [filterValue, setFilterValue] = useState('');
  const [applyFilter, setApplyFilter] = useState(false);
  const [loadGroupFilterOptions, setLoadGroupFilterOptions] = useState<FilterOption[]>([]);
  const [destTagFilterOptions, setDestTagFilterOptions] = useState<FilterOption[]>([]);

  const idFilter: Filter = {
    displayName: 'Shipment ID',
    key: FilterKeys.ShipmentId,
    type: FilterType.String
  };

  const purchaseOrderFilter: Filter = {
    displayName: 'P.O.',
    key: FilterKeys.PurchaseOrder,
    type: FilterType.String
  };

  const scacFilter: Filter = {
    displayName: 'SCAC',
    key: FilterKeys.Scac,
    type: FilterType.String
  };

  const skuFilter: Filter = {
    displayName: 'SKU',
    key: FilterKeys.Sku,
    type: FilterType.String
  };

  const lotCodeFilter: Filter = {
    displayName: 'Lot ID',
    key: FilterKeys.LotCode,
    type: FilterType.String
  };

  const expirationDateFilter: Filter = {
    displayName: 'Expiration Date',
    key: FilterKeys.ExpirationDate,
    type: FilterType.Date,
    allowMultiple: false
  };

  const lpnFilter: Filter = {
    displayName: 'LPN',
    key: FilterKeys.Lpn,
    type: FilterType.String
  };

  const bolFilter: Filter = {
    displayName: 'BOL #',
    key: FilterKeys.Bol,
    type: FilterType.String
  };

  const proNumberFilter: Filter = {
    displayName: 'PRO #',
    key: FilterKeys.ProNumber,
    type: FilterType.String
  };

  const orderReferenceFilter: Filter = {
    displayName: 'Order ID',
    key: FilterKeys.OrderReferenceId,
    type: FilterType.String
  };

  const destinationTagFilter: Filter = {
    displayName: 'Destination ID',
    key: FilterKeys.DestinationTag,
    type: FilterType.TypeAhead,
    options: destTagFilterOptions,
    placeHolder: 'Choose Dest ID'
  };

  const customerReferenceNumberFilter: Filter = {
    displayName: 'Customer Reference #',
    key: FilterKeys.CustomerReferenceNumber,
    type: FilterType.String
  };

  const loadIdFilter: Filter = {
    displayName: 'Load ID',
    key: FilterKeys.LoadId,
    type: FilterType.String
  };

  // Since this filter fetches options for warehouse shipments using the outbound service,
  // you should pass warehouseId and outboundShipmentService as a prop to correctly fetch the options
  const freightLoadGroupFilter: Filter = {
    displayName: 'Load Group',
    key: FilterKeys.FreightLoadGroup,
    type: FilterType.TypeAhead,
    placeHolder: 'Choose Load Group',
    options: loadGroupFilterOptions
  };

  const filterMap = new Map<FilterKeys, Filter>([
    [FilterKeys.ShipmentId, idFilter],
    [FilterKeys.PurchaseOrder, purchaseOrderFilter],
    [FilterKeys.Scac, scacFilter],
    [FilterKeys.Bol, bolFilter],
    [FilterKeys.ProNumber, proNumberFilter],
    [FilterKeys.Lpn, lpnFilter],
    [FilterKeys.Sku, skuFilter],
    [FilterKeys.LotCode, lotCodeFilter],
    [FilterKeys.ExpirationDate, expirationDateFilter],
    [FilterKeys.OrderReferenceId, orderReferenceFilter],
    [FilterKeys.DestinationTag, destinationTagFilter],
    [FilterKeys.CustomerReferenceNumber, customerReferenceNumberFilter],
    [FilterKeys.LoadId, loadIdFilter],
    [FilterKeys.FreightLoadGroup, freightLoadGroupFilter]
  ]);

  const filters = [parentFilter, ...props.allowedFilterKeys.map((_) => filterMap.get(_))];

  parentFilter.options = filters.map((filter) => ({displayName: filter.displayName, value: filter.key}));

  const [selectedFilter, setSelectedFilter] = useState(parentFilter);

  useEffect(() => {
    if (applyFilter) {
      if (selectedFilter.key === parentFilter.key) {
        const childFilter = filters.find((filter) => filter.key === filterValue);
        setSelectedFilter(childFilter);
      } else {
        props.onFilterChange(selectedFilter.key, filterValue);
        setSelectedFilter(null);
      }

      setApplyFilter(false);
    }
  }, [applyFilter]);

  useEffect(() => {
    fetchFilterOptions();
  }, [props.allowedFilterKeys, props.warehouseId]);

  const resetFilterInput = () => {
    if (Object.values(props.filterParams).length === 0) {
      setSelectedFilter(parentFilter);
    } else {
      setSelectedFilter(null);
    }
  };

  const handleChangeAndApplyFilter = (option: DropDownOption) => {
    setFilterValue(`${option.value}`);
    setApplyFilter(true);
  };

  const handleFilterValueChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleInputChange = (event) => {
    const keycode = event.which || event.keyCode;

    if (keycode === 13) {
      setApplyFilter(true);
    }
  };

  function fetchFilterOptions() {
    let loadGroupOptions: FilterOption[] = [];
    let destTagOptions: FilterOption[] = [];
    if (
      props.allowedFilterKeys.includes(FilterKeys.FreightLoadGroup) ||
      props.allowedFilterKeys.includes(FilterKeys.DestinationTag)
    ) {
      if (props.outboundShipmentService !== undefined && props.warehouseId !== undefined) {
        props.outboundShipmentService.filterOptionsForWarehouse(props.warehouseId).then((response) => {
          if (response && response.errors && response.errors.length > 0) {
            loadGroupOptions = [];
            destTagOptions = [];
          } else {
            response.data.freight_load_groups.forEach((loadGroup) => {
              loadGroupOptions.push({
                displayName: loadGroup,
                value: loadGroup
              });
            });
            response.data.destination_tags.forEach((destTag) => {
              destTagOptions.push({
                displayName: destTag,
                value: destTag
              });
            });
          }
        });
      }
    }
    setLoadGroupFilterOptions(loadGroupOptions);
    setDestTagFilterOptions(destTagOptions);
  }

  const handleTypeAheadChangeAndApplyFilter = (value: string) => {
    setFilterValue(value);
    setApplyFilter(true);
  };

  const handleTypeAheadFilterChange = (query: string) => {
    setFilterValue(query);
  };

  const filterInput = () => {
    switch (selectedFilter.type) {
      case FilterType.Dropdown: {
        const options: DropDownOption[] = selectedFilter.options.map((option) => ({
          name: option.displayName,
          value: `${option.value}`
        }));

        return (
          <React.Fragment>
            <li id="filter-dropdown">
              <DropDown
                options={options}
                prefixText={selectedFilter.placeHolder}
                selected={selectedFilter.placeHolder ? null : options[0]}
                onSelect={handleChangeAndApplyFilter}
                color={DropDownColor.white}
              />
            </li>
            {selectedFilter.key !== parentFilter.key && (
              <li>
                <a className="text-danger" onClick={resetFilterInput}>
                  Cancel
                </a>
              </li>
            )}
          </React.Fragment>
        );
      }
      case FilterType.String: {
        return (
          <React.Fragment>
            <li className="input-with-label">
              <label className="custom-label">{checkSelectedFilter(selectedFilter)}</label>
              <input
                className="custom-input"
                type="text"
                onKeyUp={handleInputChange}
                onChange={handleFilterValueChange}
              ></input>
            </li>
            <li>
              <a
                id="add-filter"
                onClick={() => {
                  setApplyFilter(true);
                }}
              >
                Add
              </a>
            </li>
            <li>
              <a className="text-danger" onClick={resetFilterInput}>
                Cancel
              </a>
            </li>
          </React.Fragment>
        );
      }
      case FilterType.Date: {
        return (
          <div className="filter-input-div">
            <input
              name={selectedFilter.key}
              value={filterValue}
              onChange={handleFilterValueChange}
              className="form-control filter-input"
              type="date"
              data-testid="filter-input"
              date-format="yyyy-mm-dd"
            />
            {submitCancel}
          </div>
        );
      }
      case FilterType.TypeAhead: {
        const baseOptions: TypeAheadOption[] = selectedFilter.options.map((option) => ({
          value: option.value.toString(),
          displayName: option.displayName
        }));
        const normalizedValue: string = filterValue.toLowerCase();
        const filterVal: string = filterValue === selectedFilter.key ? '' : filterValue;
        const typeAheadOptions: TypeAheadOption[] = filterVal
          ? baseOptions.filter((option) => option.displayName.toLowerCase().includes(normalizedValue))
          : baseOptions;
        return (
          <React.Fragment>
            <div className="btn-group">
              <li id="filter-dropdown">
                <TypeAhead
                  name={selectedFilter.displayName}
                  placeholder={selectedFilter.placeHolder}
                  value={filterVal}
                  options={typeAheadOptions}
                  onRequestOptions={handleTypeAheadFilterChange}
                  onSelect={handleTypeAheadChangeAndApplyFilter}
                  dropdownCss="typeahead-dropdown-menu"
                />
              </li>
            </div>
            <li>
              <a
                id="add-filter"
                onClick={() => {
                  setApplyFilter(true);
                }}
              >
                Submit
              </a>
            </li>
            <li>
              <a className="text-danger" onClick={resetFilterInput}>
                Cancel
              </a>
            </li>
          </React.Fragment>
        );
      }
    }
  };

  const submitCancel = (
    <React.Fragment>
      <a className="submit-btn icon" data-testid="check-button" onClick={() => handleAddFilter()}>
        <i className="fa fa-check" aria-hidden="true"></i>
      </a>
      <a className="text-danger icon" data-testid="cancel-button" onClick={() => handleCloseInputFilter()}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </a>
    </React.Fragment>
  );

  const handleCloseInputFilter = () => {
    setFilterValue('');
    setSelectedFilter(null);
  };

  const handleAddFilter = (filter: Filter = selectedFilter, value: string = filterValue) => {
    if (filter.type === FilterType.Date && value === filter.key) {
      setFilterValue('');
      setApplyFilter(true);
    } else {
      setFilterValue(value);
      setApplyFilter(true);
    }
  };

  const checkSelectedFilter = (filter: Filter) => {
    if (props.filterSearchTypeOverrides && props.filterSearchTypeOverrides.has(filter.key)) {
      return props.filterSearchTypeOverrides.get(filter.key);
    }
    switch (filter.key) {
      case destinationTagFilter.key:
      case purchaseOrderFilter.key:
      case skuFilter.key:
      case proNumberFilter.key:
      case bolFilter.key:
      case lpnFilter.key:
      case customerReferenceNumberFilter.key: {
        return FilterSearchTypes.Contains;
      }
      default: {
        return FilterSearchTypes.Is;
      }
    }
  };

  const removeFilter = (filterKey) => {
    props.onFilterChange(filterKey, '');

    // Select parentFilter if there are no active filters or if selectedFilter
    // is the only filter (soon to be removed)
    const selectParentFilter =
      Object.values(props.filterParams).length === 0 || isEqual(Object.keys(props.filterParams), [filterKey]);

    if (selectParentFilter) {
      setSelectedFilter(parentFilter);
    } else {
      setSelectedFilter(null);
    }
  };

  const activeFilters = Object.entries(props.filterParams).map(([key, value]) => {
    const displayName = filters.find((_) => _.key === key).displayName;

    return (
      <li key={`${key}-${value}`}>
        <div className="active-filter">
          <span className="active-filter-label">
            {displayName} <strong>"{value}"</strong>
          </span>
          <span
            className="active-filter-action"
            onClick={() => {
              removeFilter(key);
            }}
          >
            x
          </span>
        </div>
      </li>
    );
  });

  const addFilter = (() => {
    if (selectedFilter) {
      const label = selectedFilter.key === parentFilter.key ? 'Filter by:' : `Filter by ${selectedFilter.displayName}:`;

      return (
        <li>
          <ul className="list-inline">
            <li>{label}</li>
            {filterInput()}
          </ul>
        </li>
      );
    }

    return (
      <li>
        <button
          className="circle-plus-button"
          title="Add Filter"
          onClick={() => {
            setSelectedFilter(parentFilter);
          }}
        >
          <img src={circlePlusIcon} alt="circlePlusIcon" />
        </button>
      </li>
    );
  })();

  return (
    <ul className="list-inline list-inline-overflow">
      {activeFilters}
      {addFilter}
    </ul>
  );
};

export default ShipmentFilter;
