import * as React from 'react';
import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import TimeSelect from '../../shared/TimeSelect';
import ContainersService from '../../shared/services/ContainersService';
import {ApiResponse, Reservation} from '../../shared/CommonInterfaces';
import DropoffContext from '../../contexts/DropoffContext';
import {allowEditNotesInShipperUIFlag, ContainerDelivery} from './DropoffInterfaces';
import DropoffHelper from './DropoffHelper';

interface Props {
  containerDelivery: ContainerDelivery;
  containerDeliveryStartTime: number;
  containerDeliveryEndTime: number;
  instructions: string;
  containersService: ContainersService;
  handleApiError: (errors: string[], response: ApiResponse<any>) => void;
  setSuccessMessage: (message: string) => void;
  resetSuccessMessage: () => void;
  reloadContainer: () => void;
  // optional because FulfillmentInbound needs these, but LpnDropoff does NOT
  inputTextMap?: Map<string, string>;
  handleCapturablePropertiesUpdated?: (value: boolean) => void;
  setIsEditing?: (value: boolean) => void;
  reservation: Reservation;
}

const ConfirmationOfDelivery: React.FC<Props> = (props) => {
  const {containersService, setSuccessMessage, resetSuccessMessage, handleApiError, reloadContainer} = props;

  const {warehouseService} = useContext(DropoffContext);
  const [allowEditNotesInShipperUI, setAllowEditNotesInShipperUI] = useState<boolean>(false);

  const [showValidation, setShowValidation] = React.useState<boolean>(false);
  const [containerDeliveryStartTime, setContainerDeliveryStartTime] = React.useState<number>(
    props.containerDeliveryStartTime
  );
  const [containerDeliveryEndTime, setContainerDeliveryEndTime] = React.useState<number>(
    props.containerDeliveryEndTime
  );
  const [instructions, setInstructions] = React.useState<string>(props.instructions);

  const containerDeliveryStartTimeFormatted = Number.isInteger(containerDeliveryStartTime)
    ? DropoffHelper.msToHoursAndMinutes(containerDeliveryStartTime)
    : props.containerDelivery.receiving.startAt;

  const containerDeliveryEndTimeFormatted = Number.isInteger(containerDeliveryEndTime)
    ? DropoffHelper.msToHoursAndMinutes(containerDeliveryEndTime)
    : props.containerDelivery.receiving.endAt;

  const timeValidation = () => {
    if (containerDeliveryStartTime > containerDeliveryEndTime) {
      return false;
    } else {
      return true;
    }
  };

  const startTimeChangeHandler = (event) => {
    setShowValidation(false);
    setContainerDeliveryStartTime(DropoffHelper.hoursAndMinutesToMs(event.target.value));
  };

  const endTimeChangeHandler = (event) => {
    setShowValidation(false);
    setContainerDeliveryEndTime(DropoffHelper.hoursAndMinutesToMs(event.target.value));
  };

  const instructionChangeHandler = (event) => {
    setInstructions(event.target.value);
  };

  useEffect(() => {
    loadFeatureFlags();
  }, [props.reservation]);

  const loadFeatureFlags = async () => {
    let featureFlagLoadErrors;
    try {
      const [editNotesInShipperUI] = await axios.all([
        warehouseService.getFeatureFlag(allowEditNotesInShipperUIFlag, null, props.reservation.id, null, null)
      ]);
      setAllowEditNotesInShipperUI(editNotesInShipperUI.data.value);
    } catch (errorResponse) {
      featureFlagLoadErrors = errorResponse.data.errors.map((error) => error.detail);
      // eslint-disable-next-line no-console
      featureFlagLoadErrors.forEach((e) => console.log(e));
    }
  };

  useEffect(() => {
    setInstructions(props.containerDelivery.instructions);
  }, [props.containerDelivery.instructions]);

  const confirmDelivery = async () => {
    // only update extra properties here if we have the info passed in as props.
    // We have the info here only when we are doing one-step confirmation for
    // no_lpn-configured-reservation scenario.
    if (props.inputTextMap && props.handleCapturablePropertiesUpdated) {
      const updateCapturablePropertiesResponse = await DropoffHelper.updateCapturablePropertiesAsync(
        props.inputTextMap,
        props.containerDelivery.id,
        containersService
      );
      if (updateCapturablePropertiesResponse.errors.length === 0) {
        props.handleCapturablePropertiesUpdated(false);
      }
    }
    if (!timeValidation()) {
      setShowValidation(true);
      return;
    }
    const response = await containersService.confirmDelivery({
      id: props.containerDelivery.id,
      startTime: containerDeliveryStartTime,
      endTime: containerDeliveryEndTime,
      instructions
    });
    if (response && response.errors.length === 0) {
      setSuccessMessage('Delivery has been confirmed!');
      resetSuccessMessage();
      reloadContainer();
      //remove any error displayed in the UI
      handleApiError([], undefined);
    } else {
      setShowValidation(false);
      handleApiError([`There was an error confirming delivery #${props.containerDelivery.id}`], response);
      props.setIsEditing(true);
    }
  };

  return (
    <div>
      <h2>Confirmation of Delivery</h2>
      <p>
        The Shipper has made a palletization request for an incoming container. Please confirm that your warehouse can
        accommodate this request.
      </p>
      <form className="form-small">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="control-label" htmlFor="containerDeliveryStartTime">
                Start time
              </label>
              <TimeSelect
                className="form-control"
                name="containerDeliveryStartTime"
                format24hour={false}
                value={containerDeliveryStartTimeFormatted}
                onChange={startTimeChangeHandler}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="control-label" htmlFor="containerDeliveryEndTime">
                End time
              </label>
              <TimeSelect
                className="form-control"
                name="containerDeliveryEndTime"
                format24hour={false}
                value={containerDeliveryEndTimeFormatted}
                onChange={endTimeChangeHandler}
              />
            </div>
          </div>
        </div>
        {!allowEditNotesInShipperUI && (
          <div className="form-group">
            <label className="control-label" htmlFor="instructions">
              Additional Shipping and Receiving Instructions
            </label>
            <textarea
              className="form-control"
              id="instructions"
              name="instructions"
              value={instructions || ''}
              onChange={instructionChangeHandler}
            />
          </div>
        )}
        {showValidation && (
          <div className="alert alert-danger">
            <span>Invalid time window: start time is greater than end time.</span>
          </div>
        )}
        <div className="form-group">
          <button
            name="button"
            type="button"
            className="btn cta btn-confirm"
            disabled={showValidation}
            onClick={confirmDelivery}
          >
            <i className="fa fa-check"></i> Confirm Delivery
          </button>
        </div>
      </form>
      <hr />
    </div>
  );
};

export default ConfirmationOfDelivery;
