import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';
import {cloneDeep} from 'lodash';
import {Loader} from '@flexe/ui-components';
import DocumentationTooltip from '../../../shared/DocumentationTooltip';
import ReservationService from '../../../reservations/ReservationService';
import {Company} from '../../../shared/CommonInterfaces';
import {CarrierAccountStateV2} from '../SharedInterfaces';
import CarrierAccountService from './CarrierAccountService';
import AccountTable from './AccountTable';
import AccountDetails from './AccountDetails';
import DeleteAccount from './DeleteAccount';
import UpdateAccount from './UpdateAccount';
import CreateAccount from './CreateAccount';
import EnableReservationModal from './EnableReservationModal';

interface CarrierAccountProps {
  authenticityToken: string;
  currentCompany?: Company;
  carrierAccountService?: CarrierAccountService;
  showAccountReference?: boolean;
}

const createAccountParamsBlankState = {
  carrier: null,
  description: null,
  accountReference: null,
  accountInfo: {}
};

const updateAccountParamsBlankState = {
  carrier: null,
  description: null,
  accountReference: null,
  accountInfo: {}
};

const enableReservationParamsBlankState = {
  reservationsToEnable: [],
  shipperOwnedCarrierAccount: true
};

class CarrierAccountsV2 extends React.Component<CarrierAccountProps, CarrierAccountStateV2> {
  private carrierAccountService: CarrierAccountService;
  private reservationService: ReservationService;

  constructor(props) {
    super(props);

    this.carrierAccountService = props.carrierAccountService || new CarrierAccountService(props.authenticityToken);
    this.reservationService = new ReservationService(props.authenticityToken);
    this.state = {
      accounts: [],
      carrierDefinitions: null,
      reservations: [],
      selectedCarrier: null,
      selectedAccount: null,
      reservationToEnable: undefined,
      loading: true,
      errors: [],
      popupErrors: [],
      successMessage: '',
      createAccountParams: cloneDeep(createAccountParamsBlankState),
      updateAccountParams: cloneDeep(updateAccountParamsBlankState),
      enableReservationParams: cloneDeep(enableReservationParamsBlankState),
      showViewAccountModal: false,
      showCreateAccountModal: false,
      showDeleteAccountModal: false,
      showUpdateAccountModal: false,
      showEnableReservationModal: false,
      updateModalButtonDisabled: false,
      isProcessing: false,
      showDocumentationModal: false
    };
  }

  public async componentDidMount() {
    this.loadAccounts();
    this.loadCarrierDefinitions();
    this.loadReservations();
  }

  public render() {
    const zendeskLink = 'https://flexesupport.zendesk.com/hc/en-us/articles/360042047892-Setting-Up-Carrier-Accounts';
    const zendeskLinkDescription = 'Setting up carrier accounts';

    return (
      <div id="carrier-account-list" className="row space-above-lg">
        {this.state.errors && this.state.errors.length > 0 && (
          <div className="alert alert-danger" role="alert">
            {this.state.errors.map((e, i) => (
              <span key={i}>{e}</span>
            ))}
          </div>
        )}
        {this.state.successMessage && (
          <div className="alert alert-success" role="alert">
            <div className="content">{this.state.successMessage}</div>
          </div>
        )}
        <div className="col-md-12 space-below-lg">
          <div className="row">
            <div className="col-md-6">
              <h2>
                <span>Carrier Accounts</span>
                <div className="position-management">
                  <a onClick={this.toggleDocumentationModal}>
                    {' '}
                    <i className="fa fa-sm fa-question-circle" title={zendeskLinkDescription} />
                  </a>
                  <DocumentationTooltip
                    showModal={this.state.showDocumentationModal}
                    zendeskLink={zendeskLink}
                    linkDescription={zendeskLinkDescription}
                  />
                </div>
              </h2>
            </div>
            <div className="col-md-6">
              {this.state.carrierDefinitions && (
                <a id="create-button" onClick={this.toggleCreateAccountModal} className="btn pull-right">
                  <i className="fa fa-plus"></i>
                  New Carrier Account
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {!this.state.loading && (
            <AccountTable
              reservations={this.state.reservations}
              showAccountReference={this.props.showAccountReference}
              accounts={this.state.accounts}
              handleDeleteAccount={this.handleDeleteAccount}
              handleFetchShippingLabel={this.handleFetchShippingLabel}
              handleUpdateAccount={this.handleUpdateAccount}
              handleViewAccount={this.handleViewAccount}
              showEnableReservationModal={this.showEnableReservationModal}
            />
          )}
          <Loader loading={this.state.loading} />
          {this.state.showViewAccountModal && this.state.carrierDefinitions && (
            <AccountDetails
              account={this.state.selectedAccount}
              carrierDefinition={this.state.carrierDefinitions[this.state.selectedAccount.carrier]}
              show={this.state.showViewAccountModal}
              toggleModal={this.toggleViewAccountModal}
            />
          )}
          {this.state.showCreateAccountModal && this.state.carrierDefinitions && (
            <CreateAccount
              showAccountReference={false}
              createAccountParams={this.state.createAccountParams}
              carrierDefinitions={this.state.carrierDefinitions}
              selectedCarrier={this.state.selectedCarrier}
              errors={this.state.popupErrors}
              toggleCreateAccountModal={this.toggleCreateAccountModal}
              handleCreateAccount={this.handleCreateAccount}
              handleCreateAccountInfoChange={this.handleCreateAccountInfoChange}
              handleCreateAccountCarrierChange={this.handleCreateAccountCarrierChange}
              handleCreateAccountDescriptionChange={this.handleCreateAccountDescriptionChange}
              handleCreateAccountReferenceChange={this.handleCreateAccountReferenceChange}
            />
          )}

          {this.state.showUpdateAccountModal && this.state.carrierDefinitions && (
            <UpdateAccount
              updateAccountParams={this.state.updateAccountParams}
              account={this.state.selectedAccount}
              carrierDefinition={this.state.carrierDefinitions[this.state.selectedAccount.carrier]}
              toggleUpdateAccountModal={this.toggleUpdateAccountModal}
              showAccountReference={this.props.showAccountReference}
              updateAccount={this.updateAccount}
              handleUpdateAccountInfoChange={this.handleUpdateAccountInfoChange}
              handleUpdateAccountChange={this.handleUpdateAccountChange}
              isProcessing={this.state.isProcessing}
              errors={this.state.popupErrors}
            />
          )}
          {this.state.showDeleteAccountModal && (
            <DeleteAccount
              account={this.state.selectedAccount}
              deleteAccount={this.deleteAccount}
              toggleDeleteAccountModal={this.toggleDeleteAccountModal}
            />
          )}
          {this.state.showEnableReservationModal && (
            <EnableReservationModal
              account={this.state.selectedAccount}
              reservations={this.state.reservations}
              enableReservationParams={this.state.enableReservationParams}
              enableReservation={this.enableReservation}
              toggleEnableReservationModal={this.toggleEnableReservationModal}
              handleReservationChange={this.handleReservationChange}
              addReservation={this.addReservation}
              removeReservation={this.removeReservation}
            />
          )}
        </div>
      </div>
    );
  }

  public async loadAccounts() {
    try {
      const response = await this.carrierAccountService.getAccounts();
      const accounts = response.data.accounts;
      this.setState({
        accounts,
        loading: false
      });
    } catch (errorResponse) {
      this.setState({
        errors: this.state.errors.concat(this.carrierAccountService.processErrorResponse(errorResponse))
      });
    }
  }

  public async loadCarrierDefinitions() {
    let errors;
    try {
      const response = await this.carrierAccountService.getCarriers();
      const carrierDefinitions = response.data.definitions;
      this.setState({carrierDefinitions});
    } catch (errorResponse) {
      errors = this.carrierAccountService.processErrorResponse(errorResponse);
    } finally {
      this.setState({errors});
    }
  }

  private async loadReservations() {
    let errors;
    try {
      const response = await this.reservationService.getReservations();
      const reservations = response.data.reservations;
      this.setState({reservations});
    } catch (errorResponse) {
      errors = this.carrierAccountService.processErrorResponse(errorResponse);
    } finally {
      this.setState({errors});
    }
  }

  private toggleDocumentationModal = () => {
    this.setState({
      showDocumentationModal: !this.state.showDocumentationModal
    });
  };

  private toggleViewAccountModal = () => {
    this.setState({
      showViewAccountModal: !this.state.showViewAccountModal,
      selectedAccount: null
    });
  };

  private toggleCreateAccountModal = () => {
    this.setState({
      showCreateAccountModal: !this.state.showCreateAccountModal,
      selectedCarrier: null,
      createAccountParams: cloneDeep(createAccountParamsBlankState),
      popupErrors: []
    });
  };

  private toggleUpdateAccountModal = () => {
    this.setState({
      showUpdateAccountModal: !this.state.showUpdateAccountModal,
      selectedAccount: null,
      updateAccountParams: cloneDeep(updateAccountParamsBlankState),
      popupErrors: []
    });
  };

  private toggleDeleteAccountModal = () => {
    this.setState({
      showDeleteAccountModal: !this.state.showDeleteAccountModal,
      selectedAccount: null
    });
  };

  private toggleEnableReservationModal = () => {
    this.setState({
      showEnableReservationModal: !this.state.showEnableReservationModal,
      selectedAccount: null,
      enableReservationParams: cloneDeep(enableReservationParamsBlankState)
    });
  };

  private handleCreateAccountCarrierChange = (e) => {
    const carrier = e.target.value;
    const createAccountParams = cloneDeep(createAccountParamsBlankState);
    createAccountParams.carrier = carrier;
    const selectedCarrier = this.state.carrierDefinitions[carrier];

    if (selectedCarrier) {
      this.saveDefaultFieldValues(createAccountParams, selectedCarrier.fields);
    }

    this.setState({createAccountParams, selectedCarrier});
  };

  // since some default value fields (especially read-only ones) may not be
  // modified, the handleCreateAccountInfoChange event won't trigger to save
  // their state. So we need to save them here upon showing the carrier options
  private saveDefaultFieldValues = (createAccountParams, fields) => {
    fields.forEach((field) => {
      if (field.default) {
        createAccountParams.accountInfo[field.name] = field.default;
      }
    });
  };

  private handleCreateAccountReferenceChange = (e) => {
    if (this.props.showAccountReference) {
      const accountReference = e.target.value;
      const createAccountParams = {...this.state.createAccountParams};
      createAccountParams.accountReference = accountReference;
      this.setState({createAccountParams});
    }
  };

  private handleCreateAccountDescriptionChange = (e) => {
    const description = e.target.value;
    const createAccountParams = {...this.state.createAccountParams};
    createAccountParams.description = description;
    this.setState({createAccountParams});
  };

  private handleCreateAccountInfoChange = (e) => {
    const input = e.target;

    const name = input.name;
    const type = input.type;
    const value = type === 'checkbox' ? input.checked : input.value;

    const createAccountParams = {...this.state.createAccountParams};
    createAccountParams.accountInfo[name] = value;
    this.setState({createAccountParams});
  };

  private getFormattedDate = (date) => {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  };

  private handleCreateAccount = async () => {
    const requiredFields = this.state.selectedCarrier.fields.filter((f) => !f.optional);
    const createAccountParams = cloneDeep(this.state.createAccountParams);
    for (const field of requiredFields) {
      if (!createAccountParams.accountInfo[field.name]) {
        createAccountParams.accountInfo[field.name] = field.type === 'Boolean' ? false : '';
      }
      if (field.type === 'Date') {
        createAccountParams.accountInfo[field.name] = this.getFormattedDate(
          createAccountParams.accountInfo[field.name]
        );
      }
    }

    try {
      const response = await this.carrierAccountService.createAccount(createAccountParams);
      this.loadAccounts();
      this.setState({
        showCreateAccountModal: false,
        selectedCarrier: null,
        popupErrors: [],
        createAccountParams: cloneDeep(createAccountParamsBlankState),
        successMessage: 'Carrier account created, pending verification. Please check back after some time.'
      });
    } catch (errorResponse) {
      this.setState({popupErrors: this.carrierAccountService.processPopupErrorResponse(errorResponse, ' | ')});
    }
  };

  private handleUpdateAccount = async (carrierAccount) => {
    const updateParams = {...this.state.updateAccountParams};
    updateParams.accountReference = carrierAccount.accountReference;
    updateParams.description = carrierAccount.description;
    updateParams.carrier = carrierAccount.carrier;
    this.state.carrierDefinitions[carrierAccount.carrier].fields.forEach((field) => {
      updateParams.accountInfo[field.name] = carrierAccount[field.dataName]
        ? carrierAccount[field.dataName]
        : carrierAccount.externalAccountInfo[field.dataName];
    });

    this.setState({
      showUpdateAccountModal: true,
      selectedAccount: carrierAccount,
      updateAccountParams: updateParams
    });
  };

  private handleUpdateAccountInfoChange = (e) => {
    const input = e.target;

    const name = input.name;
    const type = input.type;
    const value = type === 'checkbox' ? input.checked : input.value;

    const updateAccountParams = {...this.state.updateAccountParams};
    updateAccountParams.accountInfo[name] = value;
    this.setState({updateAccountParams});
  };

  private handleUpdateAccountChange = (e) => {
    const input = e.target;

    const name = input.name;
    const value = input.value;

    const updateAccountParams = {...this.state.updateAccountParams};
    updateAccountParams[name] = value;
    this.setState({updateAccountParams});
  };

  private updateAccount = async () => {
    if (this.state.selectedAccount) {
      const accountId = this.state.selectedAccount.carrierAccountId;
      let successMessage;
      try {
        this.setState({
          popupErrors: [],
          isProcessing: true
        });
        await this.carrierAccountService.updateAccount(accountId, this.state.updateAccountParams);
        successMessage = 'Carrier account updated successfully.';

        this.loadAccounts();

        this.setState({
          showUpdateAccountModal: false,
          selectedAccount: null,
          updateAccountParams: cloneDeep(updateAccountParamsBlankState),
          successMessage
        });
      } catch (errorResponse) {
        this.setState({popupErrors: this.carrierAccountService.processPopupErrorResponse(errorResponse, ' | ')});
      } finally {
        this.setState({isProcessing: false});
      }
    }
  };

  private handleViewAccount = (carrierAccount) => {
    this.setState({
      showViewAccountModal: true,
      selectedAccount: carrierAccount
    });
  };

  private handleFetchShippingLabel = async (carrierAccount) => {
    let errors;
    const accountId = parseInt(carrierAccount.carrierAccountId, 10);
    if (accountId) {
      try {
        this.setState({loading: true});
        const response = await this.carrierAccountService.getSampleShippingLabel(accountId);
        const labelUrl = response.data.data.labelImageUrl;
        if (carrierAccount.txnState === 'pending' || carrierAccount.txnState === 'disabled') {
          await this.carrierAccountService.enableCarrierAccount(accountId);
        }
        window.location.assign(labelUrl);
      } catch (errorResponse) {
        errors = this.carrierAccountService.processErrorResponse(errorResponse);
      } finally {
        this.setState({loading: false, errors});
      }
    }
  };

  private showEnableReservationModal = async (carrierAccount) => {
    const enableParams = {...this.state.enableReservationParams};
    enableParams.reservationsToEnable = carrierAccount.reservations.map((reservation) => reservation.id);
    enableParams.shipperOwnedCarrierAccount = carrierAccount.shipperOwned;

    this.setState({
      showEnableReservationModal: true,
      selectedAccount: carrierAccount,
      enableReservationParams: enableParams
    });
  };

  private handleReservationChange = (e) => {
    const reservationToEnable = parseInt(e.target.value, 10);
    this.setState({reservationToEnable});
  };

  private addReservation = () => {
    const reservationId = this.state.reservationToEnable;
    if (reservationId) {
      const enableReservationParams = this.state.enableReservationParams;
      enableReservationParams.reservationsToEnable.push(reservationId);
      this.setState({enableReservationParams});
    }
  };

  private removeReservation = (e) => {
    const reservationId = parseInt(e.currentTarget.getAttribute('data-id'), 10);
    if (reservationId) {
      const enableReservationParams = this.state.enableReservationParams;
      const index = enableReservationParams.reservationsToEnable.indexOf(reservationId);
      if (index > -1) {
        enableReservationParams.reservationsToEnable.splice(index, 1);
      }

      this.setState({enableReservationParams});
    }
  };

  private enableReservation = async () => {
    let errors;
    let successMessage;
    if (this.state.enableReservationParams) {
      const accountId = this.state.selectedAccount.carrierAccountId;
      const reservationIds = this.state.enableReservationParams.reservationsToEnable;
      const shipperOwnedCarrierAccount = this.state.enableReservationParams.shipperOwnedCarrierAccount;
      try {
        await this.carrierAccountService.enableReservations(accountId, reservationIds, shipperOwnedCarrierAccount);
        this.loadAccounts();
        successMessage = 'Reservation(s) enabled successfully';
      } catch (errorResponse) {
        errors = this.carrierAccountService.processErrorResponse(errorResponse);
      } finally {
        this.setState({
          showEnableReservationModal: false,
          selectedAccount: null,
          errors,
          enableReservationParams: cloneDeep(enableReservationParamsBlankState),
          successMessage
        });
      }
    }
  };

  private handleDeleteAccount = async (carrierAccount) => {
    this.setState({
      showDeleteAccountModal: true,
      selectedAccount: carrierAccount
    });
  };

  private deleteAccount = async () => {
    const accountId = this.state.selectedAccount.carrierAccountId;
    let errors;
    let successMessage;
    try {
      const response = await this.carrierAccountService.softDeleteAccount(accountId);
      this.loadAccounts();
      successMessage = 'Carrier account deleted successfully.';
    } catch (errorResponse) {
      errors = this.carrierAccountService.processErrorResponse(errorResponse);
    } finally {
      this.setState({
        showDeleteAccountModal: false,
        selectedAccount: null,
        errors,
        successMessage
      });
    }
  };
}

export default CarrierAccountsV2;
