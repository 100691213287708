import * as React from 'react';
import {Table, Tabs} from '@flexe/ui-components';
import {CarrierBillingAccount} from '../SharedInterfaces';
import CustomKebabMenu from '../../../shared/CustomKebabMenu';

interface CarrierBillingAccountTableProps {
  carrierBillingAccounts: CarrierBillingAccount[];
  companyChannelsInfo: {[companyChannelId: number]: string};
  handleDelete: (id: string) => void;
  handleUpdate: (id: string) => void;
  showCompanyChannelsInfo: boolean;
}

export default function CarrierBillingAccountTable({
  carrierBillingAccounts,
  companyChannelsInfo,
  handleUpdate,
  handleDelete,
  showCompanyChannelsInfo
}: CarrierBillingAccountTableProps) {
  const [selectedTab, setSelectedTab] = React.useState('All');
  const carrierMap = {All: 1};
  carrierBillingAccounts.map((x) => {
    if (!carrierMap[x.carrier]) {
      carrierMap[x.carrier] = 1;
    }
  });
  const tabs: any[] = Object.keys(carrierMap).map((carrierName) => {
    return {
      active: selectedTab === carrierName,
      key: carrierName,
      subTitle: carrierName
    };
  });

  const tableHeaders = [
    {element: 'Carrier'},
    {element: 'Flexe Account ID'},
    {element: 'Display Name'},
    {element: 'Account Number'},
    {element: 'Postal Code'},
    {element: 'Country'},
    showCompanyChannelsInfo && {element: 'Company Channel'},
    {element: 'Actions'}
  ];

  const dropdownOptions = (account) => {
    return [
      {
        optionText: 'Edit',
        optionAction: () => handleUpdate(account.id)
      },
      {
        optionText: 'Delete',
        isActionDangerous: true,
        optionAction: () => handleDelete(account.id)
      }
    ];
  };

  const tableRows = carrierBillingAccounts
    .filter((account) => selectedTab === 'All' || account.carrier === selectedTab)
    .map((account) => {
      const displayableCompanyChannelInfo =
        account.companyChannelId == null
          ? ''
          : account.companyChannelId + ' - ' + companyChannelsInfo[account.companyChannelId];

      return [
        <div>{account.carrier}</div>,
        <div>{account.id}</div>,
        <div>{account.friendlyName}</div>,
        <div>{account.accountId}</div>,
        <div>{account.postcode}</div>,
        <div>{account.country}</div>,
        showCompanyChannelsInfo && <div>{displayableCompanyChannelInfo}</div>,
        <div style={{position: 'relative'}}>
          <CustomKebabMenu options={dropdownOptions(account)} />
        </div>
      ];
    });

  return (
    <div>
      <Tabs tabs={tabs} tabEvent={(carrierName) => setSelectedTab(carrierName)} />
      <Table tableData={{headers: tableHeaders, rows: tableRows}} />
    </div>
  );
}
