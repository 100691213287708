import * as React from 'react';
import {LegacyModal, Loader} from '@flexe/ui-components';
import {PackingSlipState} from '../SharedInterfaces';
import TemplateList from './TemplateList';
import PackingSlipService from './PackingSlipService';

interface PackingSlipProps {
  authenticityToken: string;
  packingSlipService?: PackingSlipService;
}

const maxFileSizeMB = 2; // max .docx file size in MB
const maxFileSize = maxFileSizeMB * 1024 * 1024;

class PackingSlip extends React.Component<PackingSlipProps, PackingSlipState> {
  private packingSlipService: PackingSlipService;

  constructor(props) {
    super(props);
    this.packingSlipService = props.packingSlipService || new PackingSlipService(props.authenticityToken);
    this.state = {
      templates: [],
      loading: true,
      errors: null,
      showUploadModal: false,
      showDeactivateModal: false,
      showDeleteModal: false,
      deleteTemplateId: null,
      activeTemplateId: null,
      genericTemplateUrl: '',
      uploadParams: {
        templateName: '',
        templateFile: {}
      },
      fileSelectErrors: null
    };
  }

  public async componentDidMount() {
    this.loadTemplates();
  }

  public render() {
    let modalFooter;

    if (this.state.showUploadModal) {
      modalFooter = (
        <div>
          <button type="button" className="btn" onClick={this.toggleUploadModal}>
            No
          </button>
          <button id="upload-agree" type="button" className="btn" onClick={this.handleUpload}>
            Yes
          </button>
        </div>
      );
    } else if (this.state.showDeactivateModal) {
      modalFooter = (
        <div>
          <button type="button" className="btn" onClick={this.toggleDeactivateModal}>
            No
          </button>
          <button id="deactivate-agree" type="button" className="btn" onClick={this.confirmTemplateToggleActive}>
            Yes
          </button>
        </div>
      );
    } else if (this.state.showDeleteModal) {
      modalFooter = (
        <div>
          <button type="button" className="btn" onClick={this.toggleDeleteModal}>
            No
          </button>
          <button id="deactivate-agree" type="button" className="btn" onClick={this.confirmTemplateDelete}>
            Yes
          </button>
        </div>
      );
    }

    return (
      <div id="packing-slip-list" className="row space-above-lg">
        {this.state.errors && (
          <div className="alert alert-danger" role="alert">
            {this.state.errors.map((e, i) => (
              <span key={i}>{e}</span>
            ))}
          </div>
        )}
        <div className="col-md-12 space-below-lg">
          <div className="row">
            <div className="col-md-6">
              <h2>Packing Slip Templates</h2>
            </div>
            <div className="col-md-6">
              <a id="create-button" onClick={this.toggleUploadModal} className="btn pull-right">
                <i className="fa fa-plus"></i>
                New Packing Slip
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {!this.state.loading && (
            <TemplateList
              templates={this.state.templates}
              handleTemplateToggleActive={this.handleTemplateToggleActive}
              handlePreviewTemplate={this.handlePreviewTemplate}
              handleDeleteTemplate={this.handleDeleteTemplate}
            />
          )}
          <Loader loading={this.state.loading} />
          {this.state.showUploadModal && (
            <LegacyModal
              id="template_upload_modal"
              title="Upload New Packing Slip Template"
              show={this.state.showUploadModal}
              size="small"
              toggleModal={this.toggleUploadModal}
              footer={modalFooter}
              disableClose={false}
            >
              <div>
                {this.state.fileSelectErrors && (
                  <div className="alert alert-danger" role="alert">
                    {this.state.fileSelectErrors}
                  </div>
                )}
                <div className="row">
                  <label className="col-md-12 space-below-lg">
                    Name
                    <br />
                    <input type="text" className="template-name" onChange={this.handleUploadNameChange} />
                  </label>
                </div>
                <div className="row">
                  <label className="col-md-6">
                    New template file (.docx)
                    <br />
                    <input type="file" onChange={this.handleUploadFileChange} />
                  </label>
                  <div className="col-md-6">
                    <a href={this.state.genericTemplateUrl} className="space-below">
                      <i className="fa fa-download"></i>
                      Download example template
                    </a>
                  </div>
                </div>
              </div>
            </LegacyModal>
          )}
          {this.state.showDeactivateModal && (
            <LegacyModal
              id="deactivate_template_modal"
              title="Deactivate Packing Slip Generation"
              show={this.state.showDeactivateModal}
              size="small"
              toggleModal={this.toggleDeactivateModal}
              footer={modalFooter}
              disableClose={false}
            >
              <div>
                <p>
                  Disabling all packing slips will disable packing slip generation for all future fulfillment orders.
                </p>
                <p>Are you sure you wish to continue?</p>
              </div>
            </LegacyModal>
          )}
          {this.state.showDeleteModal && (
            <LegacyModal
              id="delete_template_modal"
              title="Delete Packing Slip Template"
              show={this.state.showDeleteModal}
              size="small"
              toggleModal={this.toggleDeleteModal}
              footer={modalFooter}
              disableClose={false}
            >
              <div>
                <p>
                  Are you sure you wish to delete &nbsp;
                  <b>{this.state.templates.find((template) => template.id === this.state.deleteTemplateId).name}</b>?
                </p>
                <p>This action cannot be undone.</p>
              </div>
            </LegacyModal>
          )}
        </div>
      </div>
    );
  }

  public async loadTemplates() {
    let errors;
    try {
      const response = await this.packingSlipService.getTemplates();
      const templateList = response.data.templates;
      const genericTemplate = templateList.find((template) => template.generic);
      const genericUrl = genericTemplate ? genericTemplate.url : null;
      const activeTemplate = templateList.find((template) => template.active);
      this.setState({
        templates: templateList,
        loading: false,
        genericTemplateUrl: genericUrl || '',
        activeTemplateId: activeTemplate ? parseInt(activeTemplate.id, 10) : null
      });
    } catch (errorResponse) {
      errors = this.packingSlipService.processErrorResponse(errorResponse);
    } finally {
      this.setState({errors});
    }
  }

  private toggleDeactivateModal = () => {
    const modalShown = this.state.showDeactivateModal;
    this.setState({showDeactivateModal: !modalShown});
  };

  private toggleDeleteModal = () => {
    const modalShown = this.state.showDeleteModal;
    this.setState({showDeleteModal: !modalShown});
  };

  private handleDeleteTemplate = async (e) => {
    this.setState({
      showDeleteModal: true,
      deleteTemplateId: parseInt(e.target.getAttribute('data-id'), 10)
    });
  };

  private confirmTemplateDelete = (e) => {
    this.templateDelete(this.state.deleteTemplateId);
  };

  private templateDelete = async (templateId) => {
    let errors;
    try {
      const response = await this.packingSlipService.deleteTemplate(templateId);
      const deleteTemplateId = parseInt(templateId, 10);
      const templates = [...this.state.templates];
      const index = templates.findIndex((t) => t.id === deleteTemplateId);
      if (index !== -1) {
        templates.splice(index, 1);
      }
      this.setState({templates});
    } catch (errorResponse) {
      errors = this.packingSlipService.processErrorResponse(errorResponse);
    } finally {
      this.setState({showDeleteModal: false, deleteTemplateId: null, errors});
    }
  };

  private handleTemplateToggleActive = async (e) => {
    const templateId = e.target.getAttribute('data-id');
    const active = e.target.getAttribute('data-active');
    if (active === 'true' || active === true) {
      this.setState({showDeactivateModal: true});
    } else {
      this.templateToggleActive(templateId);
    }
  };

  private confirmTemplateToggleActive = (e) => {
    this.templateToggleActive(this.state.activeTemplateId);
  };

  private templateToggleActive = async (templateId) => {
    let errors;
    const deactivating = templateId === this.state.activeTemplateId;
    if (deactivating) {
      // deactivating packing slip generation
      templateId = null;
    }
    try {
      const response = await this.packingSlipService.setActiveTemplate(templateId);
      const activeTemplateId = parseInt(templateId, 10);
      const templates = [...this.state.templates];
      templates.forEach((template, i, a) => (a[i].active = template.id === activeTemplateId));
      this.setState({templates, activeTemplateId});
    } catch (errorResponse) {
      errors = this.packingSlipService.processErrorResponse(errorResponse);
    } finally {
      this.setState({showDeactivateModal: false, errors});
    }
  };

  private toggleUploadModal = () => {
    this.setState({
      showUploadModal: !this.state.showUploadModal,
      fileSelectErrors: null
    });
  };

  private handleUploadNameChange = (e) => {
    const name = e.target.value;
    const uploadParamsState = {...this.state.uploadParams};
    uploadParamsState.templateName = name;
    this.setState({uploadParams: uploadParamsState});
  };

  private handleUploadFileChange = (e) => {
    const files = e.target.files;
    let errors;
    const file = files[0];
    if (file && file.size <= maxFileSize) {
      if (file.name && file.name.match(/\.docx$/)) {
        const uploadParamsState = {...this.state.uploadParams};
        uploadParamsState.templateFile = file;
        this.setState({uploadParams: uploadParamsState});
      } else {
        errors = <span>The selected file is not in .docx format.</span>;
      }
    } else {
      errors = <span>Please select a .docx file less than {maxFileSizeMB}MB.</span>;
    }
    this.setState({fileSelectErrors: errors});
  };

  private handleUpload = async () => {
    if (!this.state.fileSelectErrors) {
      let errors;
      try {
        const response = await this.packingSlipService.uploadTemplate(this.state.uploadParams);
        this.loadTemplates();
      } catch (errorResponse) {
        errors = this.packingSlipService.processErrorResponse(errorResponse);
      } finally {
        this.setState({showUploadModal: false, errors});
      }
    }
  };

  private handlePreviewTemplate = async (e) => {
    const templateId = e.target.getAttribute('data-id');
    let errors;
    try {
      const response = await this.packingSlipService.previewTemplate(templateId);
      const url = response.data.url;
      if (url && url.length > 0) {
        window.location.assign(url);
      }
    } catch (errorResponse) {
      errors = this.packingSlipService.processErrorResponse(errorResponse);
    } finally {
      this.setState({errors});
    }
  };
}

export default PackingSlip;
