import {UploadParams} from '../../shipper/edi-files/EdiFilesInterfaces';
import {ApiResponse, CsvUploadResponse} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

class InboundsService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/s/dropoffs/container/bulk_create_csv');
  }

  public async uploadCSV(uploadParams): Promise<ApiResponse<CsvUploadResponse>> {
    return await this.makePostRequest(this.baseUrl, uploadParams, 'multipart');
  }
}

export default InboundsService;
