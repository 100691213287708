import * as React from 'react';
import {FC, useEffect} from 'react';
import classNames from 'classnames';
import {ShipmentRetailVariance, VarianceStatus, VarianceStatusToBackgroundStyleMap} from '../ShipmentRetailVariance';

interface Props {
  shipmentId: number;
  shipmentStatusText: string;
  shipmentStatusClass: string;
  shipmentVariance: ShipmentRetailVariance;
}
export const ShipmentHeader: FC<Props> = (props) => {
  const varianceStatus: number = props.shipmentVariance.getOverallShipmentVarianceStatus();
  const shipmentVarianceClasses = [
    `shipment-variance-status-${VarianceStatusToBackgroundStyleMap.get(varianceStatus)}`
  ];
  const hideException: boolean =
    varianceStatus === VarianceStatus.NO_VARIANCE || varianceStatus === VarianceStatus.NOT_PICKED;
  let exceptionHtml;
  if (!hideException) {
    exceptionHtml = <span className={classNames(shipmentVarianceClasses)}>Exception</span>;
  }
  return (
    <React.Fragment>
      <header>
        <h1>Shipment #{props.shipmentId}</h1>
        <span id="shipment-status" className={props.shipmentStatusClass}>
          {props.shipmentStatusText}
        </span>
        {exceptionHtml}
      </header>
    </React.Fragment>
  );
};
