import * as React from 'react';
import {cloneDeep} from 'lodash';
import {Table, TableData, TableHeader} from '@flexe/ui-components';
import ShipmentService from '../../../shared/services/ShipmentService';
import {Dimension, Dimensions, ShipmentSplit, SkuInventoryMap, UnitNameMap, UnitType} from './ShipmentInterfaces';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const convert = require('convert-units');

interface DimensionProps {
  shipmentService: ShipmentService;
  splits: ShipmentSplit[];
  handleSplitUpdate(splits: ShipmentSplit[]);
}

interface DimensionState {
  inventoryDimensions: SkuInventoryMap[];
  minimumBoxWeights: Map<number, Dimension>;
  shipmentUnits: Map<number, UnitType>;
  error?: string;
}

class ShipmentDimensions extends React.Component<DimensionProps, DimensionState> {
  private readonly defaultStandardDimension: Dimension = {
    amount: 12,
    unit: 'in'
  };
  private readonly defaultMetricDimension: Dimension = {
    amount: 31,
    unit: 'cm'
  };
  constructor(props) {
    super(props);
    const shipmentUnits = new Map<number, UnitType>();
    const minimumBoxWeights = new Map<number, Dimension>();
    this.state = {
      inventoryDimensions: null,
      minimumBoxWeights,
      shipmentUnits
    };
  }

  public async componentDidMount() {
    const skus = [];
    this.props.splits.forEach((split) => {
      split.items.forEach((item) => {
        if (!skus.includes(item.sku)) {
          skus.push(item.sku);
        }
      });
    });
    const response = await this.props.shipmentService.getInventoryDimensions(skus);
    const inventoryDimensions = response.data.dimensions;
    const shipmentUnits = new Map<number, UnitType>(this.state.shipmentUnits);
    const splits: ShipmentSplit[] = cloneDeep(this.props.splits);
    const minimumBoxWeights = new Map<number, Dimension>();
    if (splits.length > 0) {
      splits.forEach((split, index) => {
        const boxWeight: Dimension = {
          amount: 0,
          unit: 'lb'
        };
        split.items.forEach((item) => {
          if (inventoryDimensions[item.sku] && inventoryDimensions[item.sku][item.packaging]) {
            const itemDimensions = inventoryDimensions[item.sku][item.packaging] as Dimensions;
            if (UnitNameMap[itemDimensions.weight.unit]) {
              const mappedUnit = UnitNameMap[itemDimensions.weight.unit];
              if (boxWeight.unit === mappedUnit.baseUnit) {
                boxWeight.amount += convert(itemDimensions.weight.amount * item.quantity)
                  .from(mappedUnit.unit)
                  .to(mappedUnit.baseUnit);
              } else {
                boxWeight.amount += convert(itemDimensions.weight.amount * item.quantity)
                  .from(mappedUnit.unit)
                  .to(boxWeight.unit);
              }
            }
          }
        });
        minimumBoxWeights.set(index, boxWeight);
        shipmentUnits.set(index, boxWeight.unit === 'kg' ? UnitType.METRIC : UnitType.STANDARD);
        split.dimensions.weight = boxWeight;
        split.dimensions.height = this.getDefaultBoxDimension(shipmentUnits.get(index));
        split.dimensions.length = this.getDefaultBoxDimension(shipmentUnits.get(index));
        split.dimensions.width = this.getDefaultBoxDimension(shipmentUnits.get(index));
      });
    }
    this.setState(
      {
        inventoryDimensions,
        minimumBoxWeights,
        shipmentUnits
      },
      this.props.handleSplitUpdate(splits)
    );
  }

  public render() {
    const shipments = this.props.splits.map((split, index) => {
      return this.renderShipment(index, split);
    });

    return <div className="shipment-dimensions-component">{shipments}</div>;
  }

  private renderShipment(shipmentNumber: number, split: ShipmentSplit): JSX.Element {
    if (!this.state.inventoryDimensions) {
      return null;
    }
    const distanceUnit = this.state.shipmentUnits.get(shipmentNumber) === UnitType.METRIC ? 'cm.' : 'in.';
    const massUnit = this.state.shipmentUnits.get(shipmentNumber) === UnitType.METRIC ? 'kg.' : 'lbs.';
    return (
      <div key={shipmentNumber} className={`shipment-${shipmentNumber}-info`}>
        <h4 className="blue3">New Shipment #{shipmentNumber + 1}</h4>
        <div className="box-dimensions">
          <p>
            <strong>Box Dimensions:</strong>
          </p>
          <div>
            <div className="form-group">
              <label htmlFor={`shipment-${shipmentNumber}-length`}>L. ({distanceUnit})</label>
              <input
                id={`shipment-${shipmentNumber}-length`}
                value={split.dimensions.length.amount}
                type="number"
                className="form-control"
                name="length"
                onChange={this.handleDimensionChange(shipmentNumber)}
                min={0}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`shipment-${shipmentNumber}-width`}>W. ({distanceUnit})</label>
              <input
                id={`shipment-${shipmentNumber}-width`}
                value={split.dimensions.width.amount}
                type="number"
                className="form-control"
                name="width"
                onChange={this.handleDimensionChange(shipmentNumber)}
                min={0}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`shipment-${shipmentNumber}-height`}>H. ({distanceUnit})</label>
              <input
                id={`shipment-${shipmentNumber}-height`}
                value={split.dimensions.height.amount}
                type="number"
                className="form-control"
                name="height"
                onChange={this.handleDimensionChange(shipmentNumber)}
                min={0}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`shipment-${shipmentNumber}-weight`}>Weight ({massUnit})</label>
              <input
                id={`shipment-${shipmentNumber}-weight`}
                value={split.dimensions.weight.amount}
                type="number"
                className="form-control"
                name="weight"
                onChange={this.handleDimensionChange(shipmentNumber)}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`shipment-${shipmentNumber}-units`}>Units</label>
              <select
                id={`shipment-${shipmentNumber}-units`}
                value={this.state.shipmentUnits.get(shipmentNumber)}
                className="form-control"
                onChange={this.handleUnitChange(shipmentNumber)}
              >
                <option value={UnitType.METRIC}>Metric (cm. / kg.)</option>
                <option value={UnitType.STANDARD}>Standard (in. / lbs.)</option>
              </select>
            </div>
          </div>
        </div>
        <Table tableClass="table-striped" tableData={this.getTableData(shipmentNumber)} />
      </div>
    );
  }

  private getTableData(shipmentNumber: number): TableData {
    return {
      headers: [
        {element: 'SKU'},
        {element: 'L.'},
        {element: 'W.'},
        {element: 'H.'},
        {element: 'Weight'},
        {element: 'Item Qty.'}
      ] as TableHeader[],
      rows: this.buildRows(shipmentNumber)
    };
  }

  private buildRows(shipmentNumber: number): any[][] {
    return this.props.splits[shipmentNumber].items.map((item) => {
      const inventoryDimensions: Dimensions = this.state.inventoryDimensions[item.sku]
        ? this.state.inventoryDimensions[item.sku][item.packaging]
        : null;
      if (inventoryDimensions) {
        return [
          item.sku,
          inventoryDimensions.length.amount
            ? `${inventoryDimensions.length.amount} ${UnitNameMap[inventoryDimensions.length.unit].unit}`
            : '',
          inventoryDimensions.width.amount
            ? `${inventoryDimensions.width.amount} ${UnitNameMap[inventoryDimensions.width.unit].unit}`
            : '',
          inventoryDimensions.height.amount
            ? `${inventoryDimensions.height.amount} ${UnitNameMap[inventoryDimensions.height.unit].unit}`
            : '',
          inventoryDimensions.weight.amount
            ? `${inventoryDimensions.weight.amount} ${UnitNameMap[inventoryDimensions.weight.unit].unit}`
            : '',
          item.quantity
        ];
      }
      return [item.sku, '', '', '', '', item.quantity];
    });
  }

  private handleDimensionChange = (shipmentNumber: number) => (event) => {
    const value = Number(event.target.value);
    if (
      (event.target.name !== 'weight' && value > 0) ||
      (event.target.name === 'weight' && value >= this.state.minimumBoxWeights.get(shipmentNumber).amount)
    ) {
      const splits: ShipmentSplit[] = cloneDeep(this.props.splits);
      splits[shipmentNumber].dimensions[event.target.name].amount = value;
      this.props.handleSplitUpdate(splits);
    }
  };

  private handleUnitChange = (shipmentNumber: number) => (event) => {
    const shipmentUnits = new Map<number, UnitType>(this.state.shipmentUnits);
    const minimumBoxWeights = new Map<number, Dimension>(this.state.minimumBoxWeights);
    const splits: ShipmentSplit[] = cloneDeep(this.props.splits);
    shipmentUnits.set(shipmentNumber, event.target.value);

    const newDistanceUnit = shipmentUnits.get(shipmentNumber) === UnitType.METRIC ? 'cm' : 'in';
    const newMassUnit = shipmentUnits.get(shipmentNumber) === UnitType.METRIC ? 'kg' : 'lb';
    splits[shipmentNumber].dimensions.height = {
      amount: convert(splits[shipmentNumber].dimensions.height.amount)
        .from(splits[shipmentNumber].dimensions.height.unit)
        .to(newDistanceUnit),
      unit: newDistanceUnit
    };
    splits[shipmentNumber].dimensions.length = {
      amount: convert(splits[shipmentNumber].dimensions.length.amount)
        .from(splits[shipmentNumber].dimensions.length.unit)
        .to(newDistanceUnit),
      unit: newDistanceUnit
    };
    splits[shipmentNumber].dimensions.width = {
      amount: convert(splits[shipmentNumber].dimensions.width.amount)
        .from(splits[shipmentNumber].dimensions.width.unit)
        .to(newDistanceUnit),
      unit: newDistanceUnit
    };
    splits[shipmentNumber].dimensions.weight = {
      amount: convert(splits[shipmentNumber].dimensions.weight.amount)
        .from(splits[shipmentNumber].dimensions.weight.unit)
        .to(newMassUnit),
      unit: newMassUnit
    };
    minimumBoxWeights.set(shipmentNumber, {
      amount: convert(minimumBoxWeights.get(shipmentNumber).amount)
        .from(minimumBoxWeights.get(shipmentNumber).unit)
        .to(newMassUnit),
      unit: newMassUnit
    });
    this.setState(
      {
        minimumBoxWeights,
        shipmentUnits
      },
      this.props.handleSplitUpdate(splits)
    );
  };

  private getDefaultBoxDimension(unitType: UnitType): Dimension {
    return cloneDeep(unitType === UnitType.METRIC ? this.defaultMetricDimension : this.defaultStandardDimension);
  }
}

export default ShipmentDimensions;
