import * as React from 'react';
import ContainersService from '../shared/services/ContainersService';
import InventoryService from '../shared/services/InventoryService';
import DocumentsService from '../shared/services/DocumentsService';
import LpnService from '../shared/services/LpnService';
import WarehouseService from '../shared/services/WarehouseService';

export interface DropoffContextValues {
  containerDeliveryId: number;
  enableInboundPackaging: boolean;
  enableLpn: boolean;
  lpnReceiveOnly: boolean;
  supportsFulfillment: boolean;
  containersService: ContainersService;
  lpnService: LpnService;
  inventoryService: InventoryService;
  documentsService: DocumentsService;
  warehouseService: WarehouseService;
  receivingListPath: string;
  containerDeliveryStartTime: number;
  containerDeliveryEndTime: number;
  warehouseId: number;
  showEditLpnForAsn: boolean;
}

const defaultValues: DropoffContextValues = {
  containerDeliveryId: -1,
  enableInboundPackaging: false,
  enableLpn: false,
  lpnReceiveOnly: false,
  supportsFulfillment: false,
  containersService: null,
  lpnService: null,
  inventoryService: null,
  documentsService: null,
  warehouseService: null,
  receivingListPath: '',
  containerDeliveryStartTime: 0,
  containerDeliveryEndTime: 0,
  warehouseId: -1,
  showEditLpnForAsn: false
};

const DropoffContext = React.createContext(defaultValues);

export default DropoffContext;
