import * as React from 'react';
import {FunctionComponent} from 'react';
import {CustomKebabMenu, KebabMenuOption} from '../../shared/CustomKebabMenu';
import BatchWavingService from '../../shared/services/BatchWavingService';
import WarningModal from '../../shared/WarningModal';
import {BatchData} from './BatchInterfaces';

interface Props {
  batch: BatchData;
  batchService: BatchWavingService;
  onBatchCancelled: () => void;
}

const CompactBatchTileKebabMenu: FunctionComponent<Props> = (props) => {
  const [showCancellationModal, setShowCancellationModal] = React.useState(false);
  const [error, setError] = React.useState(null);

  const toggleCancellationModal = () => {
    setShowCancellationModal(!showCancellationModal);
  };

  const handleCancellation = async () => {
    const shouldCancel = props.batch.completedOrderCount === 0;

    const response = shouldCancel
      ? await props.batchService.cancelBatches([props.batch.id])
      : await props.batchService.partiallyCompleteBatches([props.batch.id]);

    if (response && response.errors.length === 0) {
      toggleCancellationModal();
      props.onBatchCancelled();
    } else {
      let message = 'There was an issue cancelling this batch';

      if (response) {
        const serverErrors = response.errors.map((e) => e.detail);
        message += `: ${serverErrors.join(', ')}`;
      }

      setError(message);
    }
  };

  const detailsOption: KebabMenuOption = {
    optionText: 'View Details',
    optionHref: '/wh/fulfillment/ecommerce/' + props.batch.id
  };

  const cancellationOption: KebabMenuOption = {
    optionText: 'Cancel Batch',
    optionAction: toggleCancellationModal,
    isActionDangerous: true
  };

  const createOptions = (): KebabMenuOption[] => {
    const options: KebabMenuOption[] = [];

    options.push(detailsOption);
    options.push(cancellationOption);

    return options;
  };

  const cancellationModal = (): React.ReactFragment => {
    let modalText: React.ReactFragment;
    if (props.batch.completedOrderCount > 0) {
      modalText = (
        <span>
          <p>
            Batch in progress. Cancelling this batch will complete what is already done and send the incomplete
            shipments back to Waving.
          </p>
          <p>Are you sure you want to cancel this batch?</p>
        </span>
      );
    } else {
      modalText = <p>Are you sure you want to cancel this batch?</p>;
    }

    const modalBody = (
      <div className="cancellation-modal-body">
        {error && <div className="alert alert-danger">{error}</div>}

        {modalText}
      </div>
    );

    return (
      <WarningModal
        elementId="cancellation-modal"
        toggleModalHandler={toggleCancellationModal}
        onConfirmClick={handleCancellation}
        confirmButtonText="Yes, Cancel"
        modalTitle="Cancel Batch"
        modalBody={modalBody}
      />
    );
  };

  return (
    <React.Fragment>
      <CustomKebabMenu options={createOptions()} />

      {showCancellationModal && cancellationModal()}
    </React.Fragment>
  );
};

export default CompactBatchTileKebabMenu;
