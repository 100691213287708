import * as React from 'react';
import {displayPackaging, renderItemLink, renderLocationLink, renderLpnLink} from '../../libs/helpers';
import {MoveItem} from './LocationsInterfaces';

interface MoveItemsTableProps {
  items: MoveItem[];
}

function MoveItemsTable(props: MoveItemsTableProps) {
  if (!props.items.length) {
    return <p>No items picked or put</p>;
  } else {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Company</th>
            <th>LPN</th>
            <th>SKU</th>
            <th>Packaging</th>
            <th>Pick Qty.</th>
            <th>Pick Location</th>
            <th>Put Qty.</th>
            <th>Put Location</th>
          </tr>
        </thead>
        {props.items.map((item, idx) => {
          const picksCount = item.picks.length;
          const putsCount = item.puts.length;
          const count = Math.max(picksCount, putsCount);
          const rows = [];
          for (let i = 0; i < count; i++) {
            const pick = item.picks[i];
            const put = item.puts[i];
            rows.push(
              <tr key={i}>
                {i === 0 ? (
                  [
                    <td key="company">{item.company.name}</td>,
                    <td key="lpn">{renderLpnLink(item.lpnBarcode, false)}</td>,
                    <td key="sku">{renderItemLink(item.item.id, item.item.sku, false)}</td>,
                    <td key="packaging">{displayPackaging(item.packaging, 2)}</td>
                  ]
                ) : (
                  <td colSpan={4}></td>
                )}
                {pick ? (
                  [
                    <td key="from_qty">{pick.quantity.amount}</td>,
                    <td key="from_loc">{renderLocationLink(pick.location.id, pick.location.label)}</td>
                  ]
                ) : (
                  <td colSpan={2}></td>
                )}
                {put ? (
                  [
                    <td key="to_qty">{put.quantity.amount}</td>,
                    <td key="to_loc">{renderLocationLink(put.location.id, put.location.label)}</td>
                  ]
                ) : (
                  <td colSpan={2}></td>
                )}
              </tr>
            );
          }
          return <tbody key={idx}>{rows}</tbody>;
        })}
      </table>
    );
  }
}

export default MoveItemsTable;
