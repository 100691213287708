import * as React from 'react';
import {OrderItem, SsccPallet} from '../../shared/services/RetailFulfillmentService';
import PrintSsccLabelsButton from './PrintSsccLabelsButton';

interface LineItemRowProps {
  orderId: number;
  lineItem: OrderItem;
  ssccEnabled: boolean;
  ssccLabelsStillGenerating: boolean;
  showLpns: boolean;
}

export const LineItemRow: React.FC<LineItemRowProps> = (props) => {
  const [showPalletList, setShowPalletList] = React.useState(false);

  const tableRow = () => {
    return (
      <tr className="line-item-row">
        {props.showLpns && <td>{lpnBarcode(props.lineItem)}</td>}
        <td>{props.lineItem.sku}</td>
        <td>{props.lineItem.skuDescription}</td>
        <td>{quantityCell(props.lineItem)}</td>
        {props.ssccEnabled && <td>{ssccPallets(props.lineItem)}</td>}
        {props.ssccEnabled && <td>{printLabelsForLineItemButton(props.lineItem)}</td>}
      </tr>
    );
  };

  const lpnBarcode = (lineItem: OrderItem) => {
    if (!lineItem.lpnBarcode) {
      return '-';
    }

    const href = `/wh/lpns/${lineItem.lpnBarcode}`;

    return (
      <a href={href} target="_blank">
        {lineItem.lpnBarcode}
      </a>
    );
  };

  const ssccPallets = (lineItem: OrderItem) => {
    return (
      <div>
        {palletQuantity(lineItem)}
        {showPalletList && palletList(lineItem)}
      </div>
    );
  };

  const palletQuantity = (lineItem: OrderItem) => {
    const numPallets = lineItem.ssccLabels.length;
    const chevronClass = showPalletList ? 'fa-chevron-up' : 'fa-chevron-down';

    return (
      <div className="col-sm-12 pallet-quantity">
        <a href="#" onClick={palletListExpansionClickHandler}>
          {numPallets}&nbsp;&nbsp;<i className={'fa ' + chevronClass} aria-hidden="true"></i>
        </a>
      </div>
    );
  };

  const palletListExpansionClickHandler = (event) => {
    event.preventDefault(); // We don't want to actually navigate anywhere
    setShowPalletList(!showPalletList);
  };

  const palletList = (lineItem: OrderItem) => {
    const pallets = lineItem.ssccLabels.map((pallet: SsccPallet, index: number) => {
      const lastItemInList = index + 1 === lineItem.ssccLabels.length;

      return (
        <React.Fragment key={`line-item-${lineItem.id}-pallet-${pallet.palletNumber}`}>
          <div className="pallet-identifier">
            <span className="col-sm-8">
              Pallet #{pallet.palletNumber}: {pallet.label}
            </span>
            <span className="col-sm-4">{printLabelsForPalletButton(pallet)}</span>
          </div>

          {!lastItemInList && <div className="col-sm-12 horizontal-divider"></div>}
        </React.Fragment>
      );
    });

    return <div className="line-item-pallet-list col-sm-12">{pallets}</div>;
  };

  const quantityCell = (lineItem: OrderItem) => {
    return (
      <React.Fragment>
        <b>{lineItem.quantity + ' ' + pluralizeUnit(lineItem.packaging, lineItem.quantity)}</b>

        {lineItem.packaging === 'carton' &&
          ' (' + lineItem.unitConversions.eaches + ' ' + pluralizeUnit('each', lineItem.unitConversions.eaches) + ')'}
      </React.Fragment>
    );
  };

  const pluralizeUnit = (unit: string, count: number): string => {
    if (count === 1) {
      return unit;
    }

    switch (unit) {
      case 'carton':
        return 'cartons';
      case 'each':
        return 'eaches';
      default:
        return unit;
    }
  };

  const printLabelsForPalletButton = (pallet: SsccPallet) => {
    return (
      <PrintSsccLabelsButton
        orderId={props.orderId}
        barcodes={[pallet.label]}
        elementId={`print-labels-for-pallet-${pallet.palletNumber}-button`}
        buttonText={'Print'}
        customClassName={'pallet-print-button'}
      />
    );
  };

  const printLabelsForLineItemButton = (lineItem: OrderItem) => {
    const numPallets = lineItem.ssccLabels.length;
    const barcodes = lineItem.ssccLabels.map((pallet: SsccPallet) => pallet.label);

    return (
      <PrintSsccLabelsButton
        orderId={props.orderId}
        barcodes={barcodes}
        elementId={`print-labels-for-line-item-${lineItem.id}-button`}
        buttonText={`Print Labels (${numPallets})`}
        customClassName={'line-item-print-button'}
      />
    );
  };

  return tableRow();
};

export default LineItemRow;
