/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import DatePicker from 'react-datepicker';
import {format as formatDate} from 'date-fns';
import {Column} from '@flexe/ui-components';
import {Fragment, FunctionComponentElement} from 'react';
import {css, jsx} from '@emotion/react';
import {SerialNumbers} from './SerialNumbers';

interface CustomParamProps {
  lotCode: string;
  expirationDate: Date;
  editing: boolean;
  serialNumbers: FunctionComponentElement<typeof SerialNumbers>;
  handleLotChange(e: any): void;
  handleDateChange(date: Date): void;
}

const CustomParams = ({
  editing,
  expirationDate,
  handleDateChange,
  handleLotChange,
  lotCode,
  serialNumbers
}: CustomParamProps) => (
  <Column margin={'50'}>
    {editing ? (
      <Fragment>
        {lotCode && (
          <div className="form-group col-md-12 col-lg-6">
            <label htmlFor="lotCode">Lot Code</label>
            <input type="text" className="form-control" name="lotCode" value={lotCode} onChange={handleLotChange} />
          </div>
        )}
        {expirationDate && (
          <div className="form-group col-md-12 col-lg-6">
            <label htmlFor="expirationDate">Expiration Date</label>
            <DatePicker
              selected={expirationDate}
              onChange={handleDateChange}
              name="expirationDate"
              showTimeSelect={false}
              placeholderText="Date"
            />
          </div>
        )}
      </Fragment>
    ) : (
      <Fragment>
        <div
          css={css`
            display: none;

            :only-child {
              display: inherit;
            }
          `}
        >
          -
        </div>
        {lotCode && <div>Lot Code: {lotCode}</div>}
        {expirationDate && <div>Expiration Date: {formatDate(expirationDate, 'MM/DD/YYYY')}</div>}
        {serialNumbers}
      </Fragment>
    )}
  </Column>
);

export default CustomParams;
