import * as React from 'react';

interface WaveButtonProps {
  setShowWavingModal: (show: boolean) => void;
  disabled: () => boolean;
}

const WaveButton: React.FC<WaveButtonProps> = (props) => {
  return (
    <button
      className="wave-button"
      onClick={() => {
        props.setShowWavingModal(true);
      }}
      disabled={props.disabled()}
    >
      Wave
    </button>
  );
};

export default WaveButton;
