import * as React from 'react';
import FormModal from '../shared/FormModal';
import WorkOrdersService from '../shared/services/WorkOrdersService';
import {KitWorkOrderDetail, WipLocationInformation} from './WorkOrdersInterfaces';
import AddCompletedKitsForm from './AddCompletedKitsForm';

interface Props {
  showModal: boolean;
  toggleModal: () => void;
  workOrdersService: WorkOrdersService;
  workOrder: KitWorkOrderDetail;
  onKitsCompletedSuccessfully: () => void;
  availableWIPLocations: WipLocationInformation[];
  isLoading: boolean;
}

const AddCompletedKitsModal: React.FC<Props> = (props) => {
  const isMountedRef = React.useRef(null);

  if (props.showModal) {
    return (
      <div data-testid="add-completed-kits-form-modal-container">
        <FormModal
          id="add-completed-kits-form-modal"
          show={true}
          toggleModal={props.toggleModal}
          title="Add completed kits"
          size="small"
        >
          <div className="add-completed-kits-form-modal-content" data-testid="add-completed-kits-form-modal-content">
            <AddCompletedKitsForm
              workOrdersService={props.workOrdersService}
              workOrder={props.workOrder}
              onKitsCompletedSuccessfully={props.onKitsCompletedSuccessfully}
              availableWIPLocations={props.availableWIPLocations}
              isLoading={props.isLoading}
            />
          </div>
        </FormModal>
      </div>
    );
  } else {
    return null;
  }
};

export default AddCompletedKitsModal;
