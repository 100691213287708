import * as React from 'react';
import {useState} from 'react';
import {SortOrder} from '../../shared/CommonInterfaces';

import DropDown, {DropDownColor, DropDownOption, DropDownType} from '../../shared/DropDown';

interface SortDirectionProps {
  onDirectionChange: (direction: SortOrder) => void;
}

const SortDirection: React.FC<SortDirectionProps> = (props) => {
  const options = [
    {name: 'Newest', value: 'createdAtDesc'},
    {name: 'Oldest', value: 'createdAtAsc'}
  ];
  const [selectedOption, setSelectedOption] = useState<DropDownOption>(options[0]);

  const onSelect = (option: DropDownOption) => {
    switch (option.value) {
      case 'createdAtDesc':
        setSelectedOption(option);
        props.onDirectionChange(SortOrder.DESC);
        break;
      case 'createdAtAsc':
        setSelectedOption(option);
        props.onDirectionChange(SortOrder.ASC);
        break;
      default:
        setSelectedOption(option);
        break;
    }
  };

  return (
    <div>
      <DropDown
        options={options}
        selected={selectedOption}
        color={DropDownColor.white}
        type={DropDownType.secondary}
        prefixText="Sort By:  "
        onSelect={onSelect}
      />
    </div>
  );
};

export default SortDirection;
