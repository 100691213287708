import {ReactNode} from 'react';

export interface ItemMasterMessage {
  msg: string;
  detail?: string;
}

export interface ImportCsvDropdownOption {
  name: ReactNode;
  value: string;
}

export interface InventoryUploadCsvColumnInfo {
  name: string;
  requiredFor: RequiredFor;
  sampleInput: string;
  description: string;
  guidelines: ReactNode[];
}

export interface InventoryUploadCsvColumnInfoGroup {
  columns: InventoryUploadCsvColumnInfo[];
  editableBy: EditableBy;
}

export enum EditableBy {
  Shipper = 'Shipper',
  Warehouse = 'Warehouse',
  ShipperAndWarehouse = 'ShipperAndWarehouse'
}

export enum ModalAction {
  Update = 'Update',
  Create = 'Create'
}

export enum RequiredFor {
  Update = 'Update',
  Create = 'Create',
  UpdateAndCreate = 'UpdateAndCreate',
  NeitherUpdateNorCreate = ''
}
