export const CancellationReasonCode = {
  DM: 'DM',
  SH: 'SH',
  MR: 'MR',
  EX: 'EX'
};

export const CancellationReasonString = {
  DM: 'Damaged',
  SH: 'Shortage',
  MR: 'Miss-Receipt',
  EX: 'Expired'
};

export interface CancellationReason {
  name: string;
  value: string;
}

/*
 * List of all cancellation reasons for the Flexe-Admin Bulk Cancel display
 **/
export const AdminCancellationReasons: CancellationReason[] = [
  {name: 'Damaged', value: 'DM'},
  {name: 'Shortage', value: 'SH'},
  {name: 'Misreceived', value: 'MR'},
  {name: 'Expired', value: 'EX'},
  {name: 'Shipper Requested', value: 'SR'},
  {name: 'Flexe Regenerated', value: 'FR'}
];
