import * as React from 'react';
import {RouteProps} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import axios, {AxiosResponse} from 'axios';
import {get} from 'lodash';
import TypeAhead, {TypeAheadOption} from '../../shared/TypeAhead';
import {AddressData, ApiResponseV2, Company} from '../../shared/CommonInterfaces';
import DropDown, {DropDownOption, DropDownStyle} from '../../shared/DropDown';
import {SelectableReservation} from '../../shared/ReservationSelector';
import FlexeButton from '../../shared/FlexeButton';
import {Inventory} from '../../dropoffs/shared/DropoffInterfaces';
import {OrderSourceUIECommerce} from '../../shared/constants';
import {
  CreateOrderLineRequest,
  CreateOrderRequest,
  Labels,
  LineLabels,
  OutboundOrdersProps,
  OutboundRecipient,
  OutboundShippingDetails,
  UpdateOrderParams
} from './OutboundOrdersInterfaces';
import OutboundOrdersService from './OutboundOrdersService';
import ReservationsService from './ReservationsService';

interface OutboundOrderForm {
  reservationId?: string;
  shipTo?: string;
  shipWithinStart?: Date;
  shipWithinEnd?: Date;
  routeDateTime?: Date;
  addressLine1?: string;
  addressLine2?: string;
  phone?: string;
  email?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  country?: string;
  externalID?: number;
  flexeRetailer?: string;
  ltlFlag?: string;
  scac?: string;
  bol?: string;
  serviceType?: string;
  instructions?: string;
  carrierCode?: string;
  ponumber?: string;
  sequencenumber?: string;
  shipfromname?: string;
  shipfromid?: string;
  shipfromline1?: string;
  shipfromline2?: string;
  shipfromcity?: string;
  shipfromstate?: string;
  shipfrompostcode?: string;
  shipfromcountry?: string;
  shiptoid?: string;
  methodofpayment?: string;
  plantname?: string;
  transportationmethod?: string;
  overrideMinQty?: boolean;
}

interface OutboundOrderLineForm {
  sku?: string;
  quantity?: number;
  unitOfMeasure?: string;
  lineExternalID?: string;
  lineSkuOptions: TypeAheadOption[];
  labels?: LineLabels;
  customerUom?: string;
  customerItemNumber?: string;
  skuDescription?: string;
  requestedQuantity?: string;
}

interface OutboundProps {
  reservations?: SelectableReservation[];
  currentCompany: Company;
  currentEnvironment: string;
}

interface State {
  reservationOptions: DropDownOption[];
  isRequesting: boolean;
  order: OutboundOrderForm;
  lines: OutboundOrderLineForm[];
  errors?: string[];
  updateOrderParams?: UpdateOrderParams;
  updatedReservation: boolean;
  newReservationId: string;
  startDateTime: Date;
  endDateTime: Date;
  routeDateTime: Date;
  editing: boolean;
  skuQuery: string;
  errorMessage: string;
  confirmToolTip: boolean;
  warehouseToolTip: boolean;
  inventoryToolTip: boolean;
  orderToolTip: boolean;
  shipmentToolTip: boolean;
  orderId: string;
  customerUom: string;
  customerItemNumber: string;
  skuDescription: string;
  requestedQuantity: string;
  touched: {
    shipTo: boolean;
    addressLine1: boolean;
    city: boolean;
    state: boolean;
    zipCode: boolean;
    country: boolean;
    externalID: boolean;
    bol: boolean;
    scac: boolean;
  };
}

class OutboundOrdersManualCreate extends React.Component<OutboundProps & OutboundOrdersProps & RouteProps, State> {
  private outboundOrdersService: OutboundOrdersService;
  private reservationsService: ReservationsService;
  private inventory: Inventory[];

  constructor(props) {
    super(props);
    this.outboundOrdersService = props.outboundOrdersService || new OutboundOrdersService();
    this.reservationsService = new ReservationsService();

    const defaultOrder: OutboundOrderForm = {
      shipTo: null,
      shipWithinStart: new Date(),
      shipWithinEnd: new Date(),
      routeDateTime: new Date(),
      addressLine1: null,
      addressLine2: null,
      phone: null,
      email: null,
      zipCode: null,
      country: null,
      city: null,
      state: null,
      externalID: null,
      ltlFlag: null,
      instructions: null,
      overrideMinQty: false
    };

    const initialLines: OutboundOrderLineForm = {
      sku: null,
      quantity: null,
      unitOfMeasure: null,
      lineExternalID: null,
      lineSkuOptions: []
    };

    this.state = {
      customerItemNumber: null,
      customerUom: null,
      requestedQuantity: null,
      skuDescription: null,
      skuQuery: null,
      editing: false,
      reservationOptions: [],
      isRequesting: false,
      order: defaultOrder,
      lines: [initialLines],
      errors: null,
      updateOrderParams: {},
      updatedReservation: false,
      newReservationId: null,
      startDateTime: new Date(),
      endDateTime: new Date(),
      routeDateTime: new Date(),
      errorMessage: null,
      confirmToolTip: false,
      warehouseToolTip: false,
      inventoryToolTip: false,
      orderToolTip: false,
      shipmentToolTip: false,
      orderId: null,
      touched: {
        shipTo: false,
        addressLine1: false,
        city: false,
        state: false,
        zipCode: false,
        country: false,
        externalID: false,
        bol: false,
        scac: false
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.updateOrderLine = this.updateOrderLine.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  public async componentDidMount() {
    this.loadReservations();
  }

  public render() {
    const {lines, reservationOptions, updateOrderParams} = this.state;

    const reservationUpdateControl = [
      <dt key="dt">
        <DropDown
          style={DropDownStyle.default}
          options={reservationOptions}
          selected={this.getSelectedReservation(reservationOptions, updateOrderParams)}
          onSelect={this.setNewReservationId}
        />
      </dt>
    ];

    const additionalOrderFields = {
      tricam: [
        {
          placeholder: 'method of payment',
          id: 'methodofpayment',
          title: 'shipment method of payment',
          key: 'methodofpayment',
          value: this.state.order.methodofpayment
        },
        {
          placeholder: 'plant name',
          id: 'plantname',
          title: 'ship from plant name',
          key: 'plantname',
          value: this.state.order.plantname
        },
        {
          placeholder: 'po number',
          id: 'ponumber',
          title: 'purchase order number',
          key: 'ponumber',
          value: this.state.order.ponumber
        },
        {
          placeholder: 'sequence number',
          id: 'sequencenumber',
          title: 'sequence number or depositor reference number',
          key: 'sequencenumber',
          value: this.state.order.sequencenumber
        },
        {
          placeholder: 'ship from city',
          id: 'shipfromcity',
          title: 'ship from city',
          key: 'shipfromcity',
          value: this.state.order.shipfromcity
        },
        {
          placeholder: 'ship from country',
          id: 'shipfromcountry',
          title: 'ship from country',
          key: 'shipfromcountry',
          value: this.state.order.shipfromcountry
        },
        {
          placeholder: 'ship from id',
          id: 'shipfromid',
          title: 'ship from id',
          key: 'shipfromid',
          value: this.state.order.shipfromid
        },
        {
          placeholder: 'ship from line1',
          id: 'shipfromline1',
          title: 'ship from line1',
          key: 'shipfromline1',
          value: this.state.order.shipfromline1
        },
        {
          placeholder: 'ship from line2',
          id: 'shipfromline2',
          title: 'ship from line2',
          key: 'shipfromline2',
          value: this.state.order.shipfromline2
        },
        {
          placeholder: 'ship from name',
          id: 'shipfromname',
          title: 'ship from name',
          key: 'shipfromname',
          value: this.state.order.shipfromname
        },
        {
          placeholder: 'ship from postcode',
          id: 'shipfrompostcode',
          title: 'ship from postcode',
          key: 'shipfrompostcode',
          value: this.state.order.shipfrompostcode
        },
        {
          placeholder: 'ship from state',
          id: 'shipfromstate',
          title: 'ship from state',
          key: 'shipfromstate',
          value: this.state.order.shipfromstate
        },
        {
          placeholder: 'ship to id',
          id: 'shiptoid',
          title: 'ship to plant id',
          key: 'shiptoid',
          value: this.state.order.shiptoid
        },
        {
          placeholder: 'transportation method',
          id: 'transportationmethod',
          title: 'transportation method or type code',
          key: 'transportationmethod',
          value: this.state.order.transportationmethod
        }
      ],
      niagara: [
        {
          placeholder: 'BOL',
          id: 'bol',
          title: 'A unique # specified on a bill of lading when goods are accepted for shipment',
          key: 'bol',
          value: this.state.order.bol
        },
        {
          placeholder: 'SCAC',
          id: 'scac',
          title: 'Standard carrier alpha code. Typically four letters long',
          key: 'scac',
          value: this.state.order.scac
        }
      ],
      lol: [
        {
          placeholder: 'BOL',
          id: 'bol',
          title: 'A unique # specified on a bill of lading when goods are accepted for shipment',
          key: 'bol',
          value: this.state.order.bol
        },
        {
          placeholder: 'SCAC',
          id: 'scac',
          title: 'Standard carrier alpha code. Typically four letters long',
          key: 'scac',
          value: this.state.order.scac
        }
      ],
      fritolay: [
        {
          placeholder: 'BOL',
          id: 'bol',
          title: 'A unique # specified on a bill of lading when goods are accepted for shipment',
          key: 'bol',
          value: this.state.order.bol
        },
        {
          placeholder: 'SCAC',
          id: 'scac',
          title: 'Standard carrier alpha code. Typically four letters long',
          key: 'scac',
          value: this.state.order.scac
        }
      ]
    };

    return (
      <div className="container-fluid outbound-orders">
        {this.state.errorMessage && (
          <div className="alert alert-danger" role="alert">
            {this.state.errorMessage}
          </div>
        )}
        <div className="row space-below">
          <div className="col-sm-6">
            <h2>Create Outbound Order</h2>
          </div>
        </div>
        <ul className="pickup fulfillment" id="workflow-steps">
          <li id="step_1" className="active">
            <span className="label">1</span>
            <div className="step-wrap">
              <h3>
                <span>Shipping Information</span>
                <div className="position-management">
                  {this.state.shipmentToolTip && (
                    <div className="flexe-tooltip">Fill out shipment destination details</div>
                  )}
                  <FlexeButton
                    text={
                      <i
                        className="fa fa-lg fa-question-circle"
                        data-toggle="tooltip"
                        aria-hidden="true"
                        title="Fill out shipment destination details"
                      />
                    }
                    handleClick={() => this.setState({shipmentToolTip: !this.state.shipmentToolTip})}
                    level="collapsed"
                  />
                </div>
              </h3>
              <div className="input-wrap">
                <h4>Destination</h4>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group required">
                      <input
                        placeholder="Recipient Name"
                        required
                        className={this.shouldMarkError('shipTo') ? 'red-border' : ''}
                        onBlur={this.handleBlur('shipTo')}
                        type="text"
                        name="outbound[ship_to][name]"
                        id="outbound_ship_to_name"
                        title="Name of the recipient (required)"
                        value={this.state.order.shipTo}
                        onChange={(event) => this.updateOrder(event, 'shipTo')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group required">
                      <input
                        placeholder="Address Line 1"
                        required
                        className={this.shouldMarkError('addressLine1') ? 'red-border' : ''}
                        onBlur={this.handleBlur('addressLine1')}
                        type="text"
                        name="outbound[ship_to][address_1]"
                        id="outbound_ship_to_address_1"
                        title="Address Line 1 (required)"
                        value={this.state.order.addressLine1}
                        onChange={(event) => this.updateOrder(event, 'addressLine1')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        placeholder="Address Line 2 (optional)"
                        type="text"
                        name="outbound[ship_to][address_2]"
                        id="outbound_ship_to_address_2"
                        title="Address Line 2 (optional)"
                        value={this.state.order.addressLine2}
                        onChange={(event) => this.updateOrder(event, 'addressLine2')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group required">
                      <input
                        placeholder="City..."
                        required
                        className={this.shouldMarkError('city') ? 'red-border' : ''}
                        onBlur={this.handleBlur('city')}
                        type="text"
                        name="outbound[ship_to][locality]"
                        id="outbound_ship_to_locality"
                        title="City (required)"
                        value={this.state.order.city}
                        onChange={(event) => this.updateOrder(event, 'city')}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <input
                        placeholder="State..."
                        required
                        className={this.shouldMarkError('state') ? 'red-border' : ''}
                        onBlur={this.handleBlur('state')}
                        type="text"
                        name="outbound[ship_to][region]"
                        id="outbound_ship_to_region"
                        title="The State / Province / Region of the recipient (required)"
                        value={this.state.order.state}
                        onChange={(event) => this.updateOrder(event, 'state')}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <input
                        placeholder="ZIP / Postal Code..."
                        required
                        className={this.shouldMarkError('zipCode') ? 'red-border' : ''}
                        onBlur={this.handleBlur('zipCode')}
                        type="text"
                        name="outbound[ship_to][postal_code]"
                        id="outbound_ship_to_postal_code"
                        title="ZIP / Postal Code (required)"
                        value={this.state.order.zipCode}
                        onChange={(event) => this.updateOrder(event, 'zipCode')}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        placeholder="Phone... (optional)"
                        type="text"
                        name="outbound[ship_to][phone]"
                        id="outbound_ship_to_phone"
                        title="10-digit recipient Phone # (optional).+1 country code can be optionally added."
                        value={this.state.order.phone}
                        onChange={(event) => this.updateOrder(event, 'phone')}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <input
                        placeholder="2 character Country code"
                        required
                        className={this.shouldMarkError('country') ? 'red-border' : ''}
                        onBlur={this.handleBlur('country')}
                        type="text"
                        name="outbound[ship_to][country]"
                        id="outbound_ship_to_country"
                        title="2 character ISO code for recipient country (required) eg. US (USA), CA (Canada)"
                        value={this.state.order.country}
                        onChange={(event) => this.updateOrder(event, 'country')}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        placeholder="Email... (optional)"
                        type="text"
                        name="outbound[ship_to][email]"
                        id="outbound_ship_to_email"
                        title="Email address for recipient (optional)."
                        value={this.state.order.email}
                        onChange={(event) => this.updateOrder(event, 'email')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="step_2" className="active">
            <span className="label">2</span>
            <div className="step-wrap">
              <h3>
                <span>Select a Warehouse</span>
                <div className="position-management">
                  {this.state.warehouseToolTip && <div className="flexe-tooltip">Choose warehouse from list below</div>}
                  <FlexeButton
                    text={
                      <i
                        className="fa fa-lg fa-question-circle"
                        data-toggle="tooltip"
                        aria-hidden="true"
                        title="Choose warehouse from list below to allocate shipments against specific reservation"
                      />
                    }
                    handleClick={() => this.setState({warehouseToolTip: !this.state.warehouseToolTip})}
                    level="collapsed"
                  />
                </div>
              </h3>
              <p className="note">The list below reflects all currently active Reservations</p>
              {reservationUpdateControl}
            </div>
          </li>
          <li id="step_3" className="active">
            <span className="label">3</span>
            <div className="step-wrap">
              <h3>
                <span>Order Details</span>
                <div className="position-management">
                  {this.state.orderToolTip && (
                    <div className="flexe-tooltip">Fill out the following fields for this outbound order</div>
                  )}
                  <FlexeButton
                    text={
                      <i
                        className="fa fa-lg fa-question-circle"
                        data-toggle="tooltip"
                        aria-hidden="true"
                        title="Fill out the following fields for this outbound order"
                      />
                    }
                    handleClick={() => this.setState({orderToolTip: !this.state.orderToolTip})}
                    level="collapsed"
                  />
                </div>
              </h3>
              <div className="input-wrap">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group required">
                      <input
                        placeholder="External Order ID #"
                        required
                        className={this.shouldMarkError('externalID') ? 'red-border' : ''}
                        onBlur={this.handleBlur('externalID')}
                        type="text"
                        name="outbound[purchase_order_uuid]"
                        id="outbound_purchase_order_uuid"
                        title="unique identifier (Optional)"
                        value={this.state.order.externalID}
                        onChange={(event) => this.updateOrder(event, 'externalID')}
                      />
                    </div>
                  </div>
                </div>
                {this.getCompanyIdentifier() === 'fritolay' && (
                  <div className="input-wrap">
                    <div className="row">
                      {additionalOrderFields.fritolay.map((item) =>
                        this.getAdditionalFields(item.placeholder, item.id, item.title, item.key, item.value)
                      )}
                      <div className="col-md-4">
                        <div className="form-group required optional">
                          <label>
                            <input
                              type="checkbox"
                              className="enlarged-checkbox-input"
                              name="override order minimum requirement"
                              id="flexe_override_min_qty"
                              title="Override 780 quantity limit for normal LPN orders"
                              checked={this.state.order.overrideMinQty}
                              onChange={() => {
                                const order = this.state.order;
                                order.overrideMinQty = !order.overrideMinQty;
                                this.setState({order});
                              }}
                            />{' '}
                            <span className="enlarged-checkboxtext">Donation or Damaged Order?</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.getCompanyIdentifier() === 'niagara' && (
                  <div className="input-wrap">
                    <div className="row">
                      {additionalOrderFields.niagara.map((item) =>
                        this.getAdditionalFieldsOptional(item.placeholder, item.id, item.title, item.key, item.value)
                      )}
                    </div>
                  </div>
                )}
                {this.getCompanyIdentifier() === 'lol' && (
                  <div className="input-wrap">
                    <div className="row">
                      {additionalOrderFields.lol.map((item) =>
                        this.getAdditionalFieldsOptional(item.placeholder, item.id, item.title, item.key, item.value)
                      )}
                    </div>
                  </div>
                )}
                {this.getCompanyIdentifier() === 'tricam' && (
                  <div className="row">
                    {additionalOrderFields.tricam.map((item) =>
                      this.getAdditionalFields(item.placeholder, item.id, item.title, item.key, item.value)
                    )}
                    <div className="col-md-4">
                      <div className="form-group required">
                        <select
                          name="outbound[retailer_id]"
                          id="retailer_id"
                          onChange={(event) => this.updateOrder(event, 'flexeRetailer')}
                        >
                          <option value="">Select FLEXE Retailer Id</option>
                          <option value="home_depot">home_depot</option>
                          <option value="interline">interline</option>
                          <option value="lowes">lowes</option>
                          <option value="ace">ace</option>
                          <option value="tractor_supply">tractor_supply</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="input-wrap">
                <h4>Shipment Details</h4>
                <div id="outbound-shipment-details-div" className="row">
                  <div className="col-md-4">
                    <div className="form-group required">
                      <select
                        name="outbound[shipment_type]"
                        id="outbound_shipment_type"
                        onChange={(event) => this.updateOrder(event, 'ltlFlag')}
                      >
                        <option value="">Select Shipment Type</option>
                        <option value={this.state.order.ltlFlag}>LTL</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-wrap">
                <h4>Route By Deadline</h4>
                <div id="outbound-shipment-details-div" className="row">
                  <div className="col-md-4">
                    <div className="form-group required">
                      <DatePicker
                        selected={this.state.routeDateTime}
                        onChange={this.handleRouteDateTime}
                        showTimeSelect
                        dateFormat="Pp"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-wrap">
                <h4>Requested Ship Window</h4>
                <div className="row">
                  <div className="col-md-4">
                    <h4>Start</h4>
                    <div className="form-group required">
                      <DatePicker
                        selected={this.state.startDateTime}
                        onChange={this.handleStartDateChange}
                        showTimeSelect
                        dateFormat="Pp"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <h4>End</h4>
                    <div className="form-group required">
                      <DatePicker
                        selected={this.state.endDateTime}
                        onChange={this.handleEndDateChange}
                        showTimeSelect
                        dateFormat="Pp"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="step_4" className="active">
            <span className="label">4</span>
            <div className="step-wrap">
              <h3>
                <span>Select Inventory</span>
                <div className="position-management">
                  {this.state.inventoryToolTip && (
                    <div className="flexe-tooltip">Select inventory and specify quantities below</div>
                  )}
                  <FlexeButton
                    text={
                      <i
                        className="fa fa-lg fa-question-circle"
                        data-toggle="tooltip"
                        aria-hidden="true"
                        title="Select inventory and specify quantities below"
                      />
                    }
                    handleClick={() => this.setState({inventoryToolTip: !this.state.inventoryToolTip})}
                    level="collapsed"
                  />
                </div>
              </h3>
              <table>
                <tr>
                  <td>
                    <a className="btn add-inventory" onClick={this.handleAdd}>
                      Add Order Line
                    </a>
                  </td>
                </tr>
              </table>
              <table className="table inventory-selection">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>Packaging</th>
                    {this.getCompanyIdentifier() === 'tricam' && <th>Customer Item Number</th>}
                    {this.getCompanyIdentifier() === 'tricam' && <th>Sku Description</th>}
                    {this.getCompanyIdentifier() === 'tricam' && <th>Requested Quantity</th>}
                    {this.getCompanyIdentifier() === 'tricam' && <th>Customer Uom</th>}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {lines.map((lineInfo: OutboundOrderLineForm, lineIndex) => {
                    const lineKey = 'line_index_' + lineIndex;
                    return (
                      <tr key={lineKey}>
                        <td>
                          <div className="dropdown inventory-picker">
                            <div className="dropdown inventory-picker">{this.handleSku(lineInfo, lineIndex)}</div>
                          </div>
                        </td>
                        <td>
                          <input
                            min="0"
                            step="1"
                            type="number"
                            value={lineInfo.quantity}
                            onChange={(event) => this.updateOrderLine(event, 'quantity', lineIndex)}
                            id="sku_quantity"
                          />
                        </td>
                        <td>
                          <select
                            className="packaging"
                            name="packaging"
                            onChange={(event) => this.updateOrderLine(event, 'unitOfMeasure', lineIndex)}
                          >
                            <option value="">Select Unit of Measure</option>
                            <option value="carton">Cartons</option>
                            <option value="each">Eaches</option>
                          </select>
                        </td>
                        {this.getCompanyIdentifier() === 'tricam' && (
                          <td>
                            <div className="form-group">
                              <input
                                placeholder="customer item number"
                                type="text"
                                name="outbound[customer_item_number][gs1us][number]"
                                id="customer_item_number"
                                title="customer item number"
                                value={lineInfo.customerItemNumber}
                                onChange={(event) => this.updateOrderLine(event, 'customerItemNumber', lineIndex)}
                              />
                            </div>
                          </td>
                        )}
                        {this.getCompanyIdentifier() === 'tricam' && (
                          <td>
                            <div className="form-group">
                              <input
                                placeholder="sku description"
                                type="text"
                                name="outbound[sku_description][gs1us][number]"
                                id="sku_description"
                                title="sku_description"
                                value={lineInfo.skuDescription}
                                onChange={(event) => this.updateOrderLine(event, 'skuDescription', lineIndex)}
                              />
                            </div>
                          </td>
                        )}
                        {this.getCompanyIdentifier() === 'tricam' && (
                          <td>
                            <div className="form-group">
                              <input
                                placeholder="requested quantity"
                                type="text"
                                name="outbound[requestedQuantity][gs1us][number]"
                                id="requested_quantity"
                                title="requested_quantity"
                                value={lineInfo.requestedQuantity}
                                onChange={(event) => this.updateOrderLine(event, 'requestedQuantity', lineIndex)}
                              />
                            </div>
                          </td>
                        )}
                        {this.getCompanyIdentifier() === 'tricam' && (
                          <td>
                            <div className="form-group">
                              <input
                                placeholder="customer uom"
                                type="text"
                                name="outbound[customer_uom][gs1us][number]"
                                id="customer_uom"
                                title="customer_uom"
                                value={lineInfo.customerUom}
                                onChange={(event) => this.updateOrderLine(event, 'customerUom', lineIndex)}
                              />
                            </div>
                          </td>
                        )}
                        <td>
                          <a className="btn add-inventory" onClick={() => this.handleRemove(lineIndex)}>
                            X
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </li>
          <li id="step_5" className="active">
            <span className="label">5</span>
            <div className="step-wrap">
              <h3>
                <span>Confirm &amp; Schedule Order</span>
                <div className="position-management">
                  {this.state.confirmToolTip && (
                    <div className="flexe-tooltip">Check that the information above is correct before submitting</div>
                  )}
                  <FlexeButton
                    text={
                      <i
                        className="fa fa-lg fa-question-circle"
                        data-toggle="tooltip"
                        aria-hidden="true"
                        title="Check that the information above is correct before submitting"
                      />
                    }
                    handleClick={() => this.setState({confirmToolTip: !this.state.confirmToolTip})}
                    level="collapsed"
                  />
                </div>
              </h3>
              <div className="form-group">
                <textarea
                  name="outbound[instructions]"
                  placeholder="Additional notes..."
                  title="Additional notes..."
                  onChange={(event) => this.updateOrder(event, 'instructions')}
                />
              </div>
              <div className="submit-wrap">
                <input
                  className="btn"
                  type="submit"
                  name="commit"
                  value="Create Orders"
                  data-disable-with="Processing..."
                  disabled={this.state.isRequesting}
                  onClick={this.handleSubmit}
                />
              </div>
              <a className="btn cancel" href="/s/fulfillment/orders">
                Cancel
              </a>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  private async makeOrderRequest(): Promise<AxiosResponse<ApiResponseV2>> {
    const address: AddressData = {
      city: this.state.order.city,
      country: this.state.order.country,
      id: this.state.order.externalID,
      line1: this.state.order.addressLine1,
      line2: this.state.order.addressLine2,
      locality: '',
      name: this.state.order.shipTo,
      phone: this.state.order.phone,
      postcode: this.state.order.zipCode,
      region: this.state.order.state
    };
    const recipient: OutboundRecipient = {
      address,
      email: this.state.order.email,
      name: this.state.order.shipTo,
      phone: this.state.order.phone
    };
    const shipping: OutboundShippingDetails = {
      instructions: this.state.order.instructions,
      labelReference1: '',
      labelReference2: '',
      signatureConfirmation: ''
    };
    let labels: Labels = {};
    if (this.getCompanyIdentifier() === 'fritolay') {
      // eslint-disable-next-line no-extra-boolean-cast
      const overrideOpt = !!this.state.order.overrideMinQty ? {flexe_override_min_qty: 'true'} : {};
      labels = {
        flexe_bol_number: this.state.order.bol,
        flexe_scac: this.state.order.scac,
        flexe_ltl_v1: 'true',
        flexe_route_by: this.state.routeDateTime.toISOString(),
        flexe_ship_within_start: this.state.startDateTime.toISOString(),
        flexe_ship_within_end: this.state.endDateTime.toISOString(),
        ...overrideOpt
      };
    } else if (this.getCompanyIdentifier() === 'tricam') {
      labels = {
        flexe_ltl_v1: 'true',
        flexe_route_by: this.state.routeDateTime.toISOString(),
        flexe_ship_within_start: this.state.startDateTime.toISOString(),
        flexe_ship_within_end: this.state.endDateTime.toISOString(),
        flexe_retailer: this.state.order.flexeRetailer,
        ponumber: this.state.order.ponumber,
        sequencenumber: this.state.order.sequencenumber,
        ship_from_name: this.state.order.shipfromname,
        ship_from_id: this.state.order.shipfromid,
        ship_from_line1: this.state.order.shipfromline1,
        ship_from_line2: this.state.order.shipfromline2,
        ship_from_city: this.state.order.shipfromcity,
        ship_from_state: this.state.order.shipfromstate,
        ship_from_postcode: this.state.order.shipfrompostcode,
        ship_from_country: this.state.order.shipfromcountry,
        ship_to_id: this.state.order.shiptoid,
        method_of_payment: this.state.order.methodofpayment,
        plant_name: this.state.order.plantname,
        transportation_method: this.state.order.transportationmethod
      };
    } else if (this.getCompanyIdentifier() === 'niagara') {
      labels = {
        flexe_bol_number: this.state.order.bol,
        flexe_scac: this.state.order.scac,
        flexe_ltl_v1: 'true',
        flexe_route_by: this.state.routeDateTime.toISOString(),
        flexe_ship_within_start: this.state.startDateTime.toISOString(),
        flexe_ship_within_end: this.state.endDateTime.toISOString()
      };
    } else if (this.getCompanyIdentifier() === 'lol') {
      labels = {
        flexe_bol_number: this.state.order.bol,
        flexe_scac: this.state.order.scac,
        flexe_ltl_v1: 'true',
        flexe_route_by: this.state.routeDateTime.toISOString(),
        flexe_ship_within_start: this.state.startDateTime.toISOString(),
        flexe_ship_within_end: this.state.endDateTime.toISOString()
      };
    }

    // Regardless of configuration, order source must be this UI
    labels.flexe_order_source = OrderSourceUIECommerce;

    const params: CreateOrderRequest = {
      externalId: this.state.order.externalID ? this.state.order.externalID.toString() : undefined,
      reservationId: this.state.newReservationId,
      labels,
      recipient,
      shipping
    };
    return await this.outboundOrdersService.createOrder(params);
  }

  private async makeOrderLineRequest(line, orderId): Promise<AxiosResponse<ApiResponseV2>> {
    let labels: LineLabels = {};
    if (this.getCompanyIdentifier() === 'tricam') {
      labels = {
        customer_itemnumber: line.customerItemNumber,
        skudescription: line.skuDescription,
        requested_quantity: line.requestedQuantity,
        customer_uom: line.customerUom
      };
    }

    // Regardless of configuration, order source must be this UI
    labels.flexe_order_source = OrderSourceUIECommerce;

    const params: CreateOrderLineRequest = {
      externalID: line.lineExternalID,
      quantity: this.getQuantity(line),
      sku: line.sku,
      unitOfMeasure: line.unitOfMeasure,
      labels
    };
    return await this.outboundOrdersService.createOrderLine(orderId, params);
  }

  private getQuantity(line: any) {
    return Number(line.quantity);
  }

  private getLinesMissingInfo() {
    const missingLineInfo = [];
    let lineIndex = 1;
    if (this.state.lines.length === 0) {
      missingLineInfo.push(
        ' Atleast 1 order line is required to successfully create an order.' +
          'Please add an order line using the "Add Order Line" button'
      );
    } else {
      for (const key of this.state.lines) {
        if (!key.sku) {
          missingLineInfo.push(' Select SKU in orderline ' + lineIndex);
        } else if (!key.quantity) {
          missingLineInfo.push(' SKU quantity in orderline ' + lineIndex);
        } else if (key.quantity < 0) {
          missingLineInfo.push(' SKU quantity in orderline ' + lineIndex + ' cannot be negative');
        } else if (!key.unitOfMeasure) {
          missingLineInfo.push(' Unit of measure in orderline ' + lineIndex);
        }
        lineIndex = lineIndex + 1;
      }
    }
    return missingLineInfo;
  }

  private handleUiValidation() {
    let errorMessage = [];
    let formIsValid;
    let inputField;
    if (!this.state.order.shipTo) {
      errorMessage.push(' Recipient Name');
      formIsValid = false;
      inputField = 'shipTo';
    } else if (!this.state.order.addressLine1) {
      errorMessage.push(' Address Line 1');
      formIsValid = false;
      inputField = 'addressLine1';
    } else if (!this.state.order.city) {
      errorMessage.push(' City');
      formIsValid = false;
      inputField = 'city';
    } else if (!this.state.order.state) {
      errorMessage.push(' State');
      formIsValid = false;
      inputField = 'state';
    } else if (!this.state.order.country) {
      errorMessage.push(' Country (2 character ISO code)');
      formIsValid = false;
      inputField = 'country';
    } else if (!this.state.order.zipCode) {
      errorMessage.push(' Zip Code');
      formIsValid = false;
      inputField = 'zipCode';
    } else if (this.state.order.country.length > 2) {
      errorMessage.push(' recipient country code must be a 2 character ISO code');
      formIsValid = false;
      inputField = 'country';
    } else if (!this.state.order.externalID) {
      errorMessage.push(' External Order ID (unique order identifier)');
      formIsValid = false;
      inputField = 'externalID';
    } else if (this.getCompanyIdentifier() === 'fritolay' && !this.state.order.bol) {
      errorMessage.push(' BOL (Bill of Lading)');
      formIsValid = false;
      inputField = 'bol';
    } else if (this.getCompanyIdentifier() === 'fritolay' && !this.state.order.scac) {
      errorMessage.push(' SCAC (Standard Carrier Alpha Code)');
      formIsValid = false;
      inputField = 'bol';
    } else if (this.getLinesMissingInfo().length !== 0) {
      errorMessage.push(this.getLinesMissingInfo());
      formIsValid = false;
      inputField = 'sku';
    } else {
      errorMessage = null;
      formIsValid = true;
    }
    return {formIsValid, errorMessage, inputField};
  }

  private async createOrderLines(orderId) {
    let orderLineResponse: AxiosResponse<ApiResponseV2>;
    const lineArray = [];
    for (const line of this.state.lines) {
      orderLineResponse = await this.makeOrderLineRequest(line, orderId);
      this.setState({orderId});
      if (orderLineResponse && orderLineResponse.status === 201) {
        lineArray.push(orderLineResponse.data.id);
      } else {
        this.handleOrderLineFailures(orderLineResponse, orderId, lineArray);
      }
    }
    if (this.state.lines.length === lineArray.length) {
      window.location.href = '/s/fulfillment/orders/' + orderId;
    }
  }

  private handleOrderLineFailures(orderLineResponse, orderId, lineArray) {
    if (orderLineResponse.status === 200 || orderLineResponse.status === 201) {
      window.location.href = '/s/fulfillment/orders/' + orderId;
    } else if (orderLineResponse.status === 400 || orderLineResponse.status === 404) {
      const orderLineErrors = [];
      orderLineResponse.data.errors.map((error) => orderLineErrors.push(error.detail));
      this.setState({
        errorMessage:
          ' Order line creation failed: ' +
          orderLineErrors.toString() +
          '. Please try creating the order again or reach out to support@flexe.com if issues persist'
      });
      document.querySelector('.content-wrapper').scrollTo(0, 0);
    } else {
      this.setState({
        errorMessage:
          ' Order line creation failed unexpectedly: ' +
          'Please try creating the order again or reach out to support@flexe.com if issues persist'
      });
      document.querySelector('.content-wrapper').scrollTo(0, 0);
    }
  }

  private async handleSubmit(event) {
    event.preventDefault();
    if (this.handleUiValidation().formIsValid) {
      const orderResponse = await this.makeOrderRequest();
      if (orderResponse.status === 409) {
        this.setState({errorMessage: 'Order already exists. External Order Id must unique.'});
        document.querySelector('.content-wrapper').scrollTo(0, 0);
      } else if (orderResponse && orderResponse.status === 201) {
        this.createOrderLines(orderResponse.data.id);
      } else {
        const orderErrors = [];
        orderResponse.data.errors.map((error) => orderErrors.push(error.detail));
        this.setState({
          errorMessage:
            'order creation failed: ' +
            orderErrors.toString() +
            ' error. ' +
            'Please try creating the order again or reach out to support@flexe.com if issues persist.'
        });
        document.querySelector('.content-wrapper').scrollTo(0, 0);
      }
    } else {
      const uiErrors = [];
      document.querySelector('.content-wrapper').scrollTo(0, 0);
      this.handleUiValidation().errorMessage.map((error) => uiErrors.push(error));
      this.setState({errorMessage: ' Please fill in the required fields: ' + uiErrors.toString()});
      if (!this.state.newReservationId) {
        this.setState({errorMessage: ' Please select a reservation from the drop-down below: '});
      }
    }
  }

  private updateOrder(event, valueKey) {
    const order = this.state.order;
    order[valueKey] = event.target.value;
    this.setState({order});
  }

  private updateOrderLine(event, valueKey, lineIndex) {
    const lines = this.state.lines;
    const orderLine = lines[lineIndex];
    orderLine[valueKey] = event.target.value;
    lines[lineIndex] = orderLine;
    this.setState({lines});
  }

  private updateOrderLineValue(value, valueKey, lineIndex) {
    const lines = this.state.lines;
    const orderLine = lines[lineIndex];
    orderLine[valueKey] = value;
    lines[lineIndex] = orderLine;
    this.setState({lines});
  }

  private handleAdd(event) {
    event.preventDefault();
    const lines = this.state.lines;
    lines.push({
      sku: null,
      quantity: null,
      unitOfMeasure: null,
      lineExternalID: null,
      lineSkuOptions: [],
      customerItemNumber: null,
      skuDescription: null,
      requestedQuantity: null,
      customerUom: null
    });
    this.setState({lines});
  }

  private handleRemove(index: number) {
    event.preventDefault();
    const lines = this.state.lines;
    lines.splice(index, 1);
    this.setState({lines});
  }

  private handleBlur = (field) => (evt) => {
    this.setState({
      touched: {...this.state.touched, [field]: true}
    });
  };

  private shouldMarkError = (field) => {
    const hasError = this.handleUiValidation().inputField === field;
    const shouldShow = this.state.touched[field];

    return hasError ? shouldShow : false;
  };

  private loadReservations = async () => {
    const reservationData = await this.reservationsService.getReservations();
    if (reservationData && reservationData.data && reservationData.data.reservations) {
      const options: DropDownOption[] = reservationData.data.reservations.map((res) => {
        if (['confirmed', 'pending_closure'].includes(res.txn_state) && res.retail_enabled) {
          const option: DropDownOption = {
            name: `${res.id}: ${res.warehouse.name}, ${res.warehouse.address.locality}`,
            value: res.id.toString()
          };
          return option;
        }
      });
      this.setState({reservationOptions: options.filter((o) => o)}); //filter empty values
    } else {
      const errors = ['Error fetching reservations. Please try again later.'].concat(this.state.errors);
      this.setState({errors});
      return;
    }
  };

  private getSelectedReservation = (reservationOptions: DropDownOption[], updateOrderParams: UpdateOrderParams) => {
    const placeholder: DropDownOption = {
      name: 'Select a reservation... (required)',
      value: ''
    };
    if (!reservationOptions) {
      return placeholder;
    } else {
      let selectedReservationId;
      if (this.state.updatedReservation) {
        selectedReservationId = this.state.newReservationId;
      } else {
        selectedReservationId = updateOrderParams.reservationId;
      }
      const selected = reservationOptions.find((o) => o.value === selectedReservationId);
      return selected || placeholder;
    }
  };

  private setNewReservationId = (option: DropDownOption) => {
    let newReservationId = option.value as string;
    if (!newReservationId) {
      newReservationId = null;
    }
    this.setState({newReservationId, updatedReservation: true});
  };

  private handleStartDateChange = (date) => {
    this.setState({
      startDateTime: date
    });
  };

  private handleEndDateChange = (date) => {
    this.setState({
      endDateTime: date
    });
  };

  private handleRouteDateTime = (date) => {
    this.setState({
      routeDateTime: date
    });
  };

  private handleSku(lineInfo, lineIndex) {
    return (
      <TypeAhead
        name={'sku-select' + lineIndex}
        placeholder="Type to select SKU..."
        value={lineInfo.sku}
        options={this.state.lines[lineIndex].lineSkuOptions}
        onRequestOptions={this.handleSkuQuery.bind(this, lineIndex)}
        onSelect={this.handleSkuSelect.bind(this, lineIndex)}
      />
    );
  }

  private handleSkuQuery = async (lineIndex, query) => {
    this.setState({skuQuery: query});
    this.updateOrderLineValue(query, 'sku', lineIndex);
    if (query.length > 1) {
      const response = await axios.get('/widgets/inventory_picker', {
        params: {
          q: query,
          reservation_id: this.state.newReservationId
        }
      });
      this.inventory = get(response, 'data.matches', []);
      const typeAheadOptions = this.inventory.map((inv) => {
        return {
          value: inv.sku,
          displayName: `${inv.sku} - ${inv.description}`
        };
      });
      const lines = this.state.lines;
      const orderLine = lines[lineIndex];
      const lineSkuOptions = 'lineSkuOptions';
      orderLine[lineSkuOptions] = typeAheadOptions;
      lines[lineIndex] = orderLine;
      this.setState({lines});
    }
  };

  private handleSkuSelect(lineIndex, sku) {
    const lines = this.state.lines;
    const orderLine = lines[lineIndex];
    const skuKey = 'sku';
    const lineSkuOptionsKey = 'lineSkuOptions';
    orderLine[skuKey] = sku;
    orderLine[lineSkuOptionsKey] = [];
    lines[lineIndex] = orderLine;
    this.setState({
      skuQuery: sku,
      lines
    });
  }

  private getCompanyIdentifier() {
    let companyName = null;
    if (['1004', '557', '4444'].includes(String(this.props.currentCompany.id))) {
      companyName = 'tricam';
    } else if (['579', '3500'].includes(String(this.props.currentCompany.id))) {
      companyName = 'fritolay';
    } else if (['1000', '3475'].includes(String(this.props.currentCompany.id))) {
      companyName = 'niagara';
    } else if (['2012', '564', '1907'].includes(String(this.props.currentCompany.id))) {
      companyName = 'lol';
    }

    return companyName;
  }

  private getAdditionalFields(placeholder, id, title, key, value) {
    return (
      <div className="col-md-4">
        <div className="form-group required">
          <input
            placeholder={placeholder}
            className={this.shouldMarkError(key) ? 'red-border' : ''}
            onBlur={this.handleBlur(key)}
            type="text"
            name={title}
            id={id}
            title={title}
            value={value}
            onChange={(event) => this.updateOrder(event, key)}
          />
        </div>
      </div>
    );
  }

  private getAdditionalFieldsOptional(placeholder, id, title, key, value) {
    return (
      <div className="col-md-4">
        <div className="form-group">
          <input
            placeholder={placeholder}
            className={this.shouldMarkError(key) ? 'red-border' : ''}
            onBlur={this.handleBlur(key)}
            type="text"
            name={title}
            id={id}
            title={title}
            value={value}
            onChange={(event) => this.updateOrder(event, key)}
          />
        </div>
      </div>
    );
  }
}

export default OutboundOrdersManualCreate;
