import * as React from 'react';
import * as PropTypes from 'prop-types';
import {cloneDeep, get} from 'lodash';
import {Link, RouteProps} from 'react-router-dom';
import {EmailAddress, friendlyCount, Pagination, Tab, Table, TableData, TableHeader, Tabs} from '@flexe/ui-components';
import {
  ApiResponse,
  Filter,
  FilterOption,
  FilterType,
  TransitionState,
  WarehouseConfig
} from '../../shared/CommonInterfaces';
import BatchWavingService from '../../shared/services/BatchWavingService';
import DocumentsService from '../../shared/services/DocumentsService';
import ShipmentService from '../../shared/services/ShipmentService';
import WarehouseService from '../../shared/services/WarehouseService';
import FlexeContext from '../../contexts/FlexeContext';
import WaveBatchFilters from '../batch-waving/WaveBatchFilters';
import ShipmentFiltersLabelGroup from '../batch-waving/ShipmentFiltersLabelGroup';
import ShippingLabelService from '../../shared/services/ShippingLabelService';
import {FreightShipMode} from '../../shared/constants';
import {BatchDetails, OrderFilters, OrderResponse, OrderStateCounts} from './BatchInterfaces';
import BatchCancel from './BatchCancel';
import BatchGenerateInProgressDoc from './BatchGenerateInProgressDoc';
import BatchComplete from './BatchComplete';
import BatchOrderDetail from './shipments/BatchOrderDetail';
import SplitShipment from './shipments/SplitShipment';
import CancelShipment from './shipments/CancelShipment';
import BatchAttributes from './BatchAttributes';
import BatchDocuments from './BatchDocuments';
import {getOrderCountForState} from './BatchHelpers';

interface BatchDetailProps {
  batchService: BatchWavingService;
  documentsService: DocumentsService;
  shipmentService: ShipmentService;
  shippingLabelService: ShippingLabelService;
  warehouseService: WarehouseService;
  whCancelShipmentEnabled: boolean;
  isTrailerLoadingEnabled: boolean;
}

interface BatchDetailState {
  batchId: number;
  continuationTokens: string[];
  currentPage: number;
  filters: OrderFilters;
  labelGenerationEnabled: boolean;
  orderStateCounts: OrderStateCounts;
  orders: OrderResponse[];
  selectedOrders: number[];
  selectedTab: TransitionState;
  skuOptions: FilterOption[];
  warehouseConfig: WarehouseConfig;
  batchDetails?: BatchDetails;
  errors?: string[];
  success?: string;
}

class BatchDetail extends React.Component<BatchDetailProps & RouteProps, BatchDetailState> {
  public static contextType = FlexeContext;

  //see note at end of file for why we need to explicitly add the contextTypes property.
  public static contextTypes;
  private readonly actionableStates = [TransitionState.New, TransitionState.Confirmed];
  private batchService: BatchWavingService;
  private documentsService: DocumentsService;
  private readonly pageSize = 50;
  constructor(props) {
    super(props);
    this.state = {
      batchId: Number(props.match.params.id),
      continuationTokens: [],
      currentPage: 1,
      filters: {},
      labelGenerationEnabled: false,
      orderStateCounts: {
        new: 0,
        confirmed: 0,
        packed: 0,
        completed: 0,
        cancelled: 0
      },
      orders: [],
      selectedOrders: [],
      selectedTab: TransitionState.New,
      skuOptions: [],
      warehouseConfig: null
    };
    this.batchService = this.props.batchService;
    this.documentsService = this.props.documentsService;
  }

  public componentDidMount() {
    this.getWarehouseConfig();
  }

  public render() {
    let headerClass = 'inbox';
    let batchStatus = 'Awaiting approval';
    if (this.state.batchDetails) {
      switch (this.state.batchDetails.status) {
        case TransitionState.Confirmed:
          headerClass = 'refresh';
          batchStatus = 'In Progress';
          break;
        case TransitionState.Cancelled:
          headerClass = 'times';
          batchStatus = 'Cancelled';
          break;
        case TransitionState.Completed:
          headerClass = 'check';
          batchStatus = this.props.isTrailerLoadingEnabled ? 'Packed' : 'Complete';
          break;
      }
    }
    const batchAttributes = this.state.batchDetails ? this.state.batchDetails.batchAttributes : null;
    const confirmed = this.state.selectedTab === TransitionState.Confirmed;
    const usesMobile = get(this.state, 'batchDetails.reservation.usesMobile', false);
    const confirmedBatchStatus = get(this.state, 'batchDetails.status', '') === TransitionState.Confirmed;

    // Don't show the complete orders buttons if customer uses mobile locations
    // This is because completing orders for mobile locations-enabled reservations requires
    // declaring the specific location to remove inventory from. We don't currently expose
    // that in the frontend, so disallow the whole thing unless automove is enabled
    const showOrderActions = !usesMobile;
    return (
      <div id="batch-detail-component">
        <div className="batch-info-header">
          {(this.state.errors || this.state.success) && (
            <div className={this.state.errors ? 'alert alert-danger' : 'alert alert-success'}>
              {this.state.success && !this.state.errors && <p>{this.state.success}</p>}

              {this.state.errors && (
                <ul>
                  {this.state.errors.map((err, index) => {
                    return <li key={'error_' + index}>{err}</li>;
                  })}
                </ul>
              )}
              <div className="cancel-alert">
                <a onClick={this.handleDismissAlert}>
                  <i className="fa fa-times"></i>
                </a>
              </div>
            </div>
          )}
          <div className="breadcrumbs">
            <Link to="/wh/fulfillment/ecommerce">Fulfillment</Link> <i className="fa fa-angle-right"></i> Batch Detail
          </div>
          {this.state.batchDetails && this.actionableStates.includes(this.state.batchDetails.status) && (
            <div className="pull-right">
              <BatchGenerateInProgressDoc batchService={this.batchService} batchId={this.state.batchId} />
              <BatchCancel
                batchService={this.batchService}
                batchId={this.state.batchId}
                orderStateCounts={this.state.orderStateCounts}
                handleCancellation={this.handleCancellation}
                pickStarted={this.getPickStarted()}
              />
            </div>
          )}
          <h2>
            <div className={`floaty-token header-badge ${headerClass}`}>
              <i className={`fa fa-${headerClass}`}></i>
            </div>
            Batch #{this.state.batchId} - {batchStatus}
          </h2>
          <div className="row batch-info">
            <div className="col-md-4 right-border batch-details">
              <h4 className="text-uppercase">Reservation Info</h4>
              <div>
                <strong>Reservation:</strong> {this.state.batchDetails ? this.state.batchDetails.reservation.id : ''}
              </div>
              <div>
                <strong>Primary Contact:</strong>{' '}
                {this.state.batchDetails ? this.state.batchDetails.reservation.primaryContact : ''}
              </div>
              <div>
                <strong>Phone Number:</strong>{' '}
                {this.state.batchDetails ? this.state.batchDetails.reservation.primaryPhone : ''}
              </div>
            </div>
            <div className="col-md-4 right-border batch-details">
              <h4 className="text-uppercase">Batch Details</h4>
              <div>
                <strong>Shipments:</strong> {this.state.batchDetails ? this.state.batchDetails.orderCount : ''}
              </div>
              <div>
                <strong>Total Items:</strong> {this.state.batchDetails ? this.state.batchDetails.itemCount : ''}
              </div>
              <div>
                <strong>Unique SKUs:</strong> {this.state.batchDetails ? this.state.batchDetails.skuCount : ''}
              </div>
              <div>
                <strong>Pick Started:</strong> {this.formatPickStarted(this.state.batchDetails)}
              </div>

              {batchAttributes && <BatchAttributes batchAttributes={batchAttributes} />}
            </div>
            <div className="col-md-4 batch-details">
              <h4 className="text-uppercase">Documents &amp; Labels</h4>
              <h5 className="work-items">Batch Work Items</h5>
              {(this.state.batchDetails && (
                <BatchDocuments
                  batchService={this.batchService}
                  batchDocuments={this.state.batchDetails.documents}
                  batchId={this.state.batchId}
                  batchStatus={this.state.batchDetails.status}
                />
              )) || (
                <p>
                  <em>Documents are being generated</em>
                </p>
              )}
            </div>
          </div>
        </div>

        <WaveBatchFilters filters={this.getFilters()} filterChangeHandler={this.handleFilterChange} />
        <ShipmentFiltersLabelGroup
          shipmentFilters={this.state.filters}
          updateAdditionalFilterSelected={this.deleteFilter}
        />

        <Tabs tabs={this.getTabs()} tabEvent={this.handleTabEvent} />

        {this.getOrderActions(showOrderActions)}

        <Table tableData={this.getTableData(showOrderActions)} tableClass="table-striped" />
        {this.state.orders.length === 0 && (
          <p id="no-matching-orders">There are no shipments for this batch matching your current filters.</p>
        )}
      </div>
    );
  }

  private getPickStarted() {
    return (
      this.state.batchDetails && this.state.batchDetails.metadata && !!this.state.batchDetails.metadata.pickStartedBy
    );
  }

  private async getBatch() {
    const response = await this.batchService.getBatch(this.state.batchId);
    if (response && response.errors.length === 0) {
      this.setState(
        {
          batchDetails: response.data.batch,
          selectedTab: response.data.batch.status
        },
        this.getOrders
      );
    } else {
      this.handleError(`Could not load details for Batch #${this.state.batchId}`, response);
    }
  }

  private async getWarehouseConfig() {
    const configResponse = await this.props.warehouseService.getWarehouseConfig(this.batchService.currentWarehouse);
    if (configResponse && configResponse.errors.length === 0) {
      this.setState(
        {
          warehouseConfig: configResponse.data.warehouseFulfillmentConfig
        },
        this.getBatch
      );
    } else {
      this.handleError('There was an error retrieving your warehouse configuration', configResponse);
    }
  }

  private async getCarrierAccountsForReservation() {
    if (this.state.batchDetails) {
      const carrierResponse = await this.props.warehouseService.getReservationCarrierAccounts(
        this.state.batchDetails.reservation.id
      );
      if (carrierResponse && carrierResponse.errors.length === 0) {
        this.setState({
          labelGenerationEnabled: carrierResponse.data.carrierAccounts.length > 0
        });
      } else {
        this.handleError('There was an error retrieving reservation carrier accounts', carrierResponse);
      }
    }
  }

  private async getOrders() {
    if (this.state.batchDetails) {
      let orderFilters: OrderFilters = {
        batchId: this.state.batchId,
        reservationId: this.state.batchDetails.reservation.id
      };

      if (this.state.selectedTab === TransitionState.Completed) {
        orderFilters.orderStatuses = ['completed', 'packed'];
      } else {
        orderFilters.orderStatus = this.state.selectedTab;
      }

      orderFilters = Object.assign(orderFilters, this.state.filters);
      const continuationToken =
        this.state.currentPage > 1 ? this.state.continuationTokens[this.state.currentPage - 2] : null;
      const response = await this.batchService.getOrders(orderFilters, continuationToken, this.pageSize);
      if (response && response.errors.length === 0) {
        const continuationTokens = this.state.continuationTokens.slice();
        if (response.data.continuationToken) {
          continuationTokens.push(response.data.continuationToken);
        }
        this.setState(
          {
            continuationTokens,
            orders: response.data.orders,
            orderStateCounts: response.data.counts,
            selectedOrders: []
          },
          this.getCarrierAccountsForReservation
        );
      } else {
        this.handleError('Error retrieving shipments for this batch', response);
      }
    }
  }

  private isFreightBatch(): boolean {
    return (
      this.state.batchDetails && !!this.state.batchDetails.batchAttributes.find((attr) => attr.name === FreightShipMode)
    );
  }

  private completeOrConfirmSelectedRequestsButton(confirmed: boolean): JSX.Element {
    const classes = 'btn btn-default pull-left';

    if (confirmed) {
      if (this.isFreightBatch()) {
        return <React.Fragment></React.Fragment>;
      }

      return (
        <button className={classes} onClick={this.handleCompleteOrders}>
          Complete Selected Requests
        </button>
      );
    } else {
      return (
        <button className={classes} onClick={this.handleConfirmOrders}>
          Confirm Selected Requests
        </button>
      );
    }
  }

  private getOrderActions(showCancelButtons): JSX.Element {
    if (
      this.state.orders.length > 0 &&
      (this.state.selectedTab === TransitionState.Confirmed || this.state.selectedTab === TransitionState.New)
    ) {
      const confirmed = this.state.selectedTab === TransitionState.Confirmed;

      return showCancelButtons ? (
        <div id="order-actions">
          {this.completeOrConfirmSelectedRequestsButton(confirmed)}

          {confirmed && !this.isFreightBatch() && (
            <BatchComplete
              batchId={this.state.batchId}
              batchService={this.batchService}
              handleCompletion={this.handleCompleteBatch}
            />
          )}
          {!confirmed && (
            <button className="btn btn-default pull-left" onClick={this.handleConfirmBatch}>
              Confirm All Requests
            </button>
          )}
        </div>
      ) : (
        <div id="no-order-actions">
          <p>
            Shipments from this reservation must be completed via the mobile platform. Please contact{' '}
            <EmailAddress localPart={'support'} /> with any questions.
          </p>
        </div>
      );
    }
  }

  private getFilters(): Filter[] {
    return [
      {displayName: 'Shipment Id', key: 'orderId', type: FilterType.String},
      {
        displayName: 'Purchase Order',
        key: 'purchaseOrder',
        type: FilterType.String
      },
      {displayName: 'Address', key: 'address', type: FilterType.String},
      {
        displayName: 'Ship On or Before',
        key: 'cutoffDate',
        type: FilterType.Date
      },
      {
        displayName: 'SKU',
        key: 'skus',
        type: FilterType.TypeAhead,
        typeAheadHandler: this.handleSkuTypeAhead,
        options: this.state.skuOptions,
        allowMultiple: true
      },
      {
        displayName: 'Customer UUID',
        key: 'customerOrderUuid',
        type: FilterType.String
      }
    ];
  }

  private getTabs(): Tab[] {
    const tabs = [
      {
        active: this.state.selectedTab === TransitionState.New,
        key: TransitionState.New,
        subTitle: 'New Shipments',
        title: friendlyCount(getOrderCountForState(this.state.orderStateCounts, TransitionState.New))
      },
      {
        active: this.state.selectedTab === TransitionState.Confirmed,
        key: TransitionState.Confirmed,
        subTitle: 'In-Progress',
        title: friendlyCount(getOrderCountForState(this.state.orderStateCounts, TransitionState.Confirmed))
      },
      {
        active: this.state.selectedTab === TransitionState.Cancelled,
        key: TransitionState.Cancelled,
        pullRight: true,
        subTitle: 'Cancelled',
        title: friendlyCount(getOrderCountForState(this.state.orderStateCounts, TransitionState.Cancelled))
      },
      {
        active: this.state.selectedTab === TransitionState.Completed,
        key: TransitionState.Completed,
        pullRight: true,
        subTitle: this.props.isTrailerLoadingEnabled ? 'Packed' : 'Completed',
        title: friendlyCount(getOrderCountForState(this.state.orderStateCounts, TransitionState.Completed))
      }
    ];
    return tabs;
  }

  private getTableData(showCheckbox): TableData {
    const headers = [
      {element: 'Shipment ID'},
      {element: 'Purchase Order'},
      {element: 'Address'},
      {element: 'Ship On'},
      {element: 'SKU'},
      {element: 'Quantity'},
      {element: 'Customer UUID'},
      {
        element: (
          <Pagination
            page={this.state.currentPage}
            pageSize={this.pageSize}
            paginationHandler={this.handlePagination}
            totalCount={getOrderCountForState(this.state.orderStateCounts, this.state.selectedTab)}
          />
        )
      }
    ] as TableHeader[];
    if (this.state.batchDetails && this.actionableStates.includes(this.state.selectedTab) && showCheckbox) {
      headers.unshift({
        element: (
          <input
            type="checkbox"
            value="all"
            onChange={this.handleOrderSelection}
            checked={this.state.orders.length > 0 && this.state.orders.length === this.state.selectedOrders.length}
          />
        )
      });
    }
    return {
      headers,
      rows: this.buildRows(showCheckbox)
    };
  }

  private buildRows(showCheckbox) {
    return this.state.orders.map((data) => {
      const sortedInventory = data.inventory.sort((a, b) => {
        const skuA = a.sku.toUpperCase();
        const skuB = b.sku.toUpperCase();
        if (skuA < skuB) {
          return -1;
        } else if (skuA > skuB) {
          return 1;
        }
        return 0;
      });
      const singleItemOrder = sortedInventory.length === 1 && sortedInventory[0].quantity === 1;
      const actions = (
        <div className="order-actions">
          {[
            this.state.warehouseConfig &&
              this.state.warehouseConfig.splitOrdersEnabled &&
              this.state.selectedTab === TransitionState.Confirmed &&
              !singleItemOrder &&
              !data.order.isRebinned && (
                <SplitShipment
                  key={0}
                  orderId={data.order.id}
                  orderItems={sortedInventory}
                  shipmentService={this.props.shipmentService}
                  handleSuccess={this.handleSuccess}
                  labelGenerationEnabled={this.state.labelGenerationEnabled}
                />
              ),
            this.props.whCancelShipmentEnabled &&
              (this.state.selectedTab === TransitionState.New ||
                this.state.selectedTab === TransitionState.Confirmed) && (
                <CancelShipment
                  key={1}
                  shipmentId={data.order.id}
                  shipmentService={this.props.shipmentService}
                  handleSuccess={this.handleSuccess}
                  handleError={this.handleError}
                  reservationId={this.state.batchDetails.reservation.id}
                />
              )
          ]}
          <BatchOrderDetail
            orderId={data.order.id}
            reservationId={this.state.batchDetails.reservation.id}
            batchService={this.batchService}
            documentsService={this.documentsService}
            splitEnabled={this.state.warehouseConfig && this.state.warehouseConfig.splitOrdersEnabled}
            whCancelShipmentEnabled={this.props.whCancelShipmentEnabled}
            shipmentService={this.props.shipmentService}
            shippingLabelService={this.props.shippingLabelService}
            warehouseService={this.props.warehouseService}
            labelGenerationEnabled={this.state.labelGenerationEnabled}
            handleSuccess={this.handleSuccess}
            handleError={this.handleError}
          />
        </div>
      );
      const row = [
        data.order.id,
        data.order.purchaseOrder,
        data.order.address,
        data.order.shipOn,
        <div className="order-skus">
          {sortedInventory.map((item, index) => {
            return (
              <p key={index}>
                <Link to={`/wh/inventories/${item.id}`} target="_blank">
                  {item.sku}
                </Link>
              </p>
            );
          })}
        </div>,
        <div className="order-sku-quantities">
          {sortedInventory.map((item, index) => {
            return (
              <p key={index}>
                {item.quantity} {item.packaging}
                {item.quantity > 1 && item.packaging === 'each' && 'e'}
                {item.quantity > 1 && 's'}
              </p>
            );
          })}
        </div>,
        data.order.customerOrderUuid,
        actions
      ];
      if (this.state.batchDetails && this.actionableStates.includes(this.state.selectedTab) && showCheckbox) {
        row.unshift(
          <input
            type="checkbox"
            value={data.order.id}
            onChange={this.handleOrderSelection}
            checked={this.state.selectedOrders.includes(data.order.id)}
          />
        );
      }
      return row;
    });
  }

  private handleTabEvent = (tabKey: TransitionState) => {
    if (getOrderCountForState(this.state.orderStateCounts, tabKey)) {
      this.setState(
        {
          continuationTokens: [],
          currentPage: 1,
          selectedTab: tabKey
        },
        this.getOrders
      );
    } else {
      this.setState({
        continuationTokens: [],
        currentPage: 1,
        orders: [],
        selectedOrders: [],
        selectedTab: tabKey
      });
    }
  };

  private deleteFilter = (key: string, value: string) => {
    const filtersCopy = cloneDeep(this.state.filters);
    const filterArray = this.getFilters();
    let allowingMultiple = false;

    filterArray.forEach((filter) => {
      if (filter.key === key && filter.allowMultiple) {
        allowingMultiple = true;
      }
    });

    if (allowingMultiple) {
      const indexOfValue = filtersCopy[key].indexOf(value);
      if (indexOfValue >= 0) {
        filtersCopy[key].splice(indexOfValue, 1);
      }
    } else {
      if (filtersCopy[key]) {
        delete filtersCopy[key];
      }
    }

    this.setState({filters: filtersCopy}, this.getOrders);
  };

  private handleFilterChange = (key: string, value: string) => {
    const filtersCopy = cloneDeep(this.state.filters);
    const filterArray = this.getFilters();
    let isAllowingMultiple = false;

    filterArray.forEach((filter) => {
      if (filter.key === key && filter.allowMultiple) {
        isAllowingMultiple = true;
      }
    });

    if (isAllowingMultiple) {
      if (!filtersCopy[key]) {
        filtersCopy[key] = [];
      }

      if (!filtersCopy[key].includes(value)) {
        filtersCopy[key].push(value);
      } else {
        return;
      }
    } else {
      filtersCopy[key] = value;
    }

    this.setState({filters: filtersCopy}, this.getOrders);
  };

  private handleSkuTypeAhead = async (query: string) => {
    if (query) {
      const response = await this.batchService.skuTypeAhead(this.state.batchDetails.reservation.id, query);
      if (response && response.errors.length === 0) {
        const skus: string[] = response && response.data && response.data.skus ? response.data.skus : [];
        const skuOptions: FilterOption[] = skus.map((sku) => {
          return {
            displayName: sku,
            value: sku
          };
        });
        this.setState({
          skuOptions
        });
      } else {
        this.handleError(
          'There was an error returning skus for reservation #' + this.state.batchDetails.reservation.id.toString(),
          response
        );
      }
    } else {
      this.setState({
        skuOptions: []
      });
    }
  };

  private handlePagination = (page: number) => {
    this.setState(
      {
        currentPage: page
      },
      this.getOrders
    );
  };

  private handleOrderSelection = (event) => {
    if (event.target.value) {
      const orderId = Number(event.target.value);
      let selectedOrders = this.state.selectedOrders.slice();
      if (event.target.value === 'all') {
        selectedOrders =
          this.state.orders.length === this.state.selectedOrders.length
            ? []
            : this.state.orders.map((item) => item.order.id);
      } else if (selectedOrders.includes(orderId)) {
        selectedOrders.splice(selectedOrders.indexOf(orderId), 1);
      } else {
        selectedOrders.push(orderId);
      }
      this.setState({
        selectedOrders
      });
    }
  };

  private handleCancellation = () => {
    this.getBatch();
  };

  private handleCompleteBatch = () => {
    this.handleDismissAlert();
    this.getBatch();
  };

  private handleCompleteOrders = async () => {
    this.handleDismissAlert();
    if (this.state.selectedOrders.length > 0) {
      const response = await this.batchService.completeOrders(this.state.selectedOrders);
      if (response && response.errors.length === 0) {
        let success: string = null;
        if (response.data.completedOrderIds.length > 0) {
          success = `${response.data.completedOrderIds.length} shipment${
            response.data.completedOrderIds.length > 1 ? 's' : ''
          } successfully completed!`;
        } else {
          success = null;
        }

        let failures: string[] = null;
        if (response.data.failedOrders.length > 0) {
          failures = [];

          response.data.failedOrders.forEach((failure) => {
            const message: string = `Shipment ${failure.orderId} failed: ${failure.reason}`;
            failures.push(message);
          });
        }

        if (response.data.completedBatchIds.length > 0) {
          this.handlePartialSuccess(success, failures);
        } else {
          this.handlePartialSuccess(success, failures, this.getOrders);
        }
      } else {
        this.handleError('Selected shipments could not be completed', response);
      }
    }
  };

  private handleConfirmBatch = async () => {
    this.handleDismissAlert();
    const response = await this.batchService.confirmBatches([this.state.batchId]);
    if (response && response.errors.length === 0) {
      this.getBatch();
    } else {
      this.handleError(`Could not confirm batch #${this.state.batchId}`, response);
    }
  };

  private handleConfirmOrders = async () => {
    this.handleDismissAlert();
    if (this.state.selectedOrders.length > 0) {
      const response = await this.batchService.confirmOrders(this.state.selectedOrders);
      if (response && response.errors.length === 0) {
        const success = `${response.data.confirmedOrderIds.length} shipment${
          response.data.confirmedOrderIds.length > 1 ? 's' : ''
        } successfully confirmed!`;
        if (response.data.confirmedBatchIds.length > 0) {
          this.handleSuccess(success);
        } else {
          this.handleSuccess(success, this.getOrders);
        }
      } else {
        this.handleError('Selected shipments could not be confirmed', response);
      }
    }
  };

  private handleDismissAlert = () => {
    this.setState({
      errors: null,
      success: null
    });
  };

  private handleSuccess = (message: string, callback = null) => {
    callback = callback || this.getBatch;
    this.setState(
      {
        success: message
      },
      callback
    );
  };

  private handlePartialSuccess = (successMessage: string, errorMessages: string[], callback = null) => {
    callback = callback || this.getBatch;

    this.setState(
      {
        success: successMessage,
        errors: errorMessages
      },
      callback
    );
  };

  private handleError = (message: string, response: ApiResponse<any>) => {
    if (response) {
      const serverErrors = response.errors.map((e) => e.detail);
      message += `: ${serverErrors.join(', ')}`;
    }
    this.setState({
      errors: [message]
    });
  };

  private formatPickStarted(batchDetails: BatchDetails) {
    if (!batchDetails) {
      return '';
    }

    if (!batchDetails.metadata) {
      return 'Not Started';
    }

    if (batchDetails.metadata) {
      const user = batchDetails.metadata.pickStartedBy;
      const time = batchDetails.metadata.pickStartedAt;

      if (user && time) {
        return 'by ' + user + ' at ' + time.toLocaleString();
      }
      return 'Not Started';
    }
  }
}

// While we don't normally use prop-types, we need them in order to do shallow mounting
// with context in our enzyme tests.
// AirBnB is working on adding better context
// support to enzyme, which you can track here:
// https://github.com/airbnb/enzyme/issues/1553#issue-301920052
BatchDetail.contextTypes = {
  authenticityToken: PropTypes.any
};

export default BatchDetail;
