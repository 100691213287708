import InternalAPIService from '../../shared/services/InternalAPIService';
import {ApiResponse} from '../../shared/CommonInterfaces';
import {CreateExpenseParameter} from './ExpensesInterfaces';
import {Expense, ExpenseServiceInterface} from './ExpensesInterfaces';

class ExpenseService extends InternalAPIService implements ExpenseServiceInterface {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/warehouse');
  }

  public async createExpense(warehouseId: number, expenseParameter: CreateExpenseParameter): Promise<ApiResponse<any>> {
    if (expenseParameter.attachment !== undefined && !expenseParameter.attachment) {
      delete expenseParameter.attachment;
    }

    return await this.makePostRequest(
      `${this.baseUrl}/${warehouseId}/finance/expense`,
      {warehouseExpense: expenseParameter},
      'multipart'
    );
  }

  public async getExpenses(warehouseId: number, date: Date): Promise<Expense[]> {
    const requestParams = {authenticity_token: this.authenticityToken};
    const response = await this.makeGetRequest(
      // TODO FE-578: this might be a legitimate bug found by eslint
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${this.baseUrl}/${warehouseId}/finance/expenses?billingPeriodStartDate=${date}`,
      requestParams
    );
    if (this.responseHasErrors(response)) {
      return [];
    }
    return this.transformResponse(response);
  }

  public async getExpenseDocuments(warehouseId: number, data: Expense[]): Promise<Expense[]> {
    const response = await this.makePostRequest(`${this.baseUrl}/${warehouseId}/finance/expense-documents`, {
      expenses: data
    });
    if (this.responseHasErrors(response)) {
      return [];
    }
    return this.transformResponse(response);
  }

  public transformResponse(response): Expense[] {
    const fetchedExpenses = response.data.expenses;

    const parseStatus = (exp) => {
      const str = exp.status || 'pending';
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const parseDate = (dateStr) => {
      const date = new Date(dateStr);
      const options = {month: 'long', day: 'numeric'} as const;
      return date.toLocaleString('en-US', options);
    };

    return fetchedExpenses.map((currExpense) => {
      const status = parseStatus(currExpense);
      return {
        id: currExpense.warehouseExpenseId,
        reservationName: currExpense.reservationName,
        companyId: currExpense.companyId,
        createdAt: parseDate(currExpense.createdAt),
        createdBy: currExpense.createdByDisplayName,
        status,
        category: currExpense.category,
        expenseType: currExpense.description,
        rate: currExpense.warehouseRate,
        markupString: currExpense.warehouseMarkupString,
        quantityAndUom: currExpense.quantityAndUom,
        uom: currExpense.unitOfMeasure,
        submissionNotes: currExpense.submissionNotes || '',
        statusNotes: currExpense.statusNotes || '',
        approvalDate: status === 'Pending' ? 'NA' : parseDate(currExpense.statusUpdatedAt),
        currency: currExpense.currency,
        currencySymbol: currExpense.currencySymbol,
        warehouseAmount: currExpense.warehouseAmount,
        attachmentUrl: currExpense.attachmentUrl
      };
    });
  }

  private responseHasErrors = (response) => {
    return !response || !response.data || (response.errors && response.errors.length > 0);
  };
}

export default ExpenseService;
