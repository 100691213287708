import {Reservation} from '../CommonInterfaces';

const WarehouseReservationUtil = {
  getReservationIdToName: (reservations: Reservation[]) => {
    return reservations.reduce((map, res) => {
      map[res.id] = res.name.replace(/^#/, ''); // remove leading #
      return map;
    }, {});
  }
};

export default WarehouseReservationUtil;
