import * as React from 'react';
import FilterBar from '../shared/FilterBar';
import WorkOrdersService from '../shared/services/WorkOrdersService';
import {Warehouse} from '../shared/CommonInterfaces';
import * as WO from './WorkOrdersInterfaces';
import {WorkOrderReservation} from './WorkOrdersInterfaces';
import WorkOrderListItem from './WorkOrderListItem';
import WorkOrdersPageHeader from './WorkOrdersPageHeader';

export interface StartWorkOrderModalState {
  id: string;
  visible: boolean;
}

interface Props {
  workOrdersService: WorkOrdersService;
  isShipper: boolean;
  companyId: number;
  enableWorkOrders: boolean;
  currentWarehouse?: Warehouse;
  reservations?: WorkOrderReservation[];
}

const WorkOrdersIndex: React.FC<Props> = (props) => {
  // used to prevent state updates on unmounted components
  const isMountedRef = React.useRef(null);
  const [showNewWorkOrderModal, setShowNewWorkOrderModal] = React.useState<boolean>(false);
  const [showStartWorkOrderModal, setShowStartWorkOrderModal] = React.useState<StartWorkOrderModalState>({
    id: '',
    visible: false
  });
  const [selectedWarehouse, setSelectedWarehouse] = React.useState<Warehouse>(props.currentWarehouse);
  const [selectedStatusFilter, setSelectedStatusFilter] = React.useState<string>('');
  const [kitWorkOrders, setKitWorkOrders] = React.useState<WO.KitWorkOrder[]>([]);

  React.useEffect(() => {
    isMountedRef.current = true;
    loadWorkOrders(WO.WorkOrderType.kitToStock, 2001, '');
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const responseHasErrors = (response) => {
    return response && response.errors && response.errors.length;
  };

  const loadWorkOrders = async (workOrderType: string, reservationId: number, status: string) => {
    // future-proofing against other types of work orders
    // when there are multiple types, this file should be split up
    // and each type should have it's own "index" page
    if (workOrderType === WO.WorkOrderType.kitToStock) {
      const response = await props.workOrdersService.getKitWorkOrders();
      if (!responseHasErrors(response) && isMountedRef.current) {
        const kitWOs = response.data;
        setKitWorkOrders(kitWOs);
      }
      return response.errors;
    }
  };

  const toggleNewWorkOrderModal = () => {
    setShowNewWorkOrderModal(!showNewWorkOrderModal);
  };

  const toggleStartWorkOrderModalWithEvent = (event) => {
    event.persist();
    setShowStartWorkOrderModal({
      id: event.currentTarget.getAttribute('data-testid'),
      visible: !showStartWorkOrderModal.visible
    });
  };

  const handleStart = (event) => {
    toggleStartWorkOrderModalWithEvent(event);
    transitionWorkOrderStatus(event);
  };

  const transitionWorkOrderStatus = async (event) => {
    const response = await props.workOrdersService.startKitWorkOrder(event.currentTarget.id, 'inProgress');
    // TODO: flesh this out with whatever we want to happen when the response comes back
    // index page refresh?
    // redirect to detail page of newly created WO?
  };

  const handleTabSelect = (e) => {
    if (selectedStatusFilter === e) {
      setSelectedStatusFilter('');
    } else {
      setSelectedStatusFilter(e);
    }
  };

  const getCount = (status: string) => {
    const arr = kitWorkOrders.filter((wo) => {
      return wo.status === status;
    });
    return arr.length;
  };

  const filterStatusData = [
    {
      status: 'neutral',
      title: 'Not Started',
      count: getCount('new'),
      active: selectedStatusFilter === 'neutral'
    },
    {
      status: 'support',
      title: 'In Progress',
      count: getCount('inProgress'),
      active: selectedStatusFilter === 'support'
    },
    {
      status: 'valid',
      title: 'Complete',
      count: getCount('complete'),
      active: selectedStatusFilter === 'valid'
    },
    {
      status: 'invalid',
      title: 'Canceled',
      count: getCount('canceled'),
      active: selectedStatusFilter === 'invalid'
    }
  ];

  const filterWorkOrdersByStatus = () => {
    return kitWorkOrders.filter((wo) => {
      const woStatusMap = {
        new: 'neutral',
        inProgress: 'support',
        complete: 'valid',
        canceled: 'invalid'
      };
      return selectedStatusFilter === '' || woStatusMap[wo.status] === selectedStatusFilter;
    });
  };

  if (props.enableWorkOrders) {
    return (
      <div className="work-order-main" id="work-order-main">
        <WorkOrdersPageHeader
          isShipper={props.isShipper}
          isDetailsHeader={false}
          companyId={props.companyId}
          enableWorkOrders={props.enableWorkOrders}
          toggleNewWorkOrderModal={toggleNewWorkOrderModal}
          workOrdersService={props.workOrdersService}
          reservations={props.reservations}
          showNewWorkOrderModal={showNewWorkOrderModal}
        />
        <div className="work-order-content">
          <FilterBar data={filterStatusData} handleTabSelect={handleTabSelect} />
          <ul className="work-order-list">
            {filterWorkOrdersByStatus().map((wo, i) => {
              return (
                <WorkOrderListItem
                  key={`${wo.workOrderId}`}
                  workOrder={wo}
                  isShipper={props.isShipper}
                  showStartWorkOrderModal={showStartWorkOrderModal}
                  toggleStartWorkOrderModalWithEvent={toggleStartWorkOrderModalWithEvent}
                  disabled={false}
                  handleStart={handleStart}
                />
              );
            })}
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" id="work-order-main-disabled">
        <div className="col-md-12 space-below-lg">
          <h1>Work Orders</h1>
          <p>Work orders are not currently enabled for this reservation.</p>
        </div>
      </div>
    );
  }
};

export default WorkOrdersIndex;
