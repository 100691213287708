import * as React from 'react';
import {parse} from 'date-fns';
import {buildTextInput} from '../../../../libs/helpers';
import {SelectableWarehouse} from '../../../shared/CommonInterfaces';
import {Labels} from '../OutboundOrdersInterfaces';

interface Props {
  warehouses: SelectableWarehouse[];
  customerUUID: string;
  poNumber: string;
  labels: Labels;
  scac: string;
  onSelectWarehouse: (wh: SelectableWarehouse) => void;
  onSetCustomerUUID: (uuid: string) => void;
  onSetPoNumber: (v: string) => void;
  onAddLabel: (v: string) => void;
  onDeleteLabel: (v: string) => void;
  onSetShipByDate: (v: Date) => void;
  onSetShipAfterDate: (v: Date) => void;
  onSetScac: (v: string) => void;
}

const OrderAndCarrierDetails: React.FC<Props> = (props) => {
  const [label, setLabel] = React.useState<string>('');

  let uuid = 0;
  let defaultWarehouse;

  const {
    warehouses,
    customerUUID,
    poNumber,
    labels,
    scac,
    onSelectWarehouse,
    onSetCustomerUUID,
    onSetPoNumber,
    onAddLabel,
    onDeleteLabel,
    onSetShipByDate,
    onSetShipAfterDate,
    onSetScac
  } = props;

  const createWarehouseOption = (warehouseName: string, reservationId: number) => {
    const key = warehouseName + '_' + reservationId.toString();
    return (
      <option id={(++uuid).toString()} key={key} value={reservationId.toString()}>
        {warehouseName}
      </option>
    );
  };

  const warehouseOptions = () => {
    if (warehouses.length === 1) {
      const warehouse = warehouses[0];
      defaultWarehouse = createWarehouseOption(warehouse.warehouseName, warehouse.reservationId);
      return [defaultWarehouse];
    } else {
      //This first dummy warehouse option acts as a placeholder and as the option to choose if the user
      //doesn't want to select any warehouse.
      defaultWarehouse = createWarehouseOption('Select Warehouse', 0);
      return [defaultWarehouse].concat(
        warehouses.map((wh) => {
          return createWarehouseOption(wh.warehouseName, wh.reservationId);
        })
      );
    }
  };

  const allLabels = Object.keys(labels)
    .filter((l) => labels[l] === 'true')
    .map((l) => {
      return (
        <li key={l}>
          <div className="active-label">
            <span className="active-label-display">{l}</span>
            <span className="active-label-action" onClick={() => onDeleteLabel(l)}>
              &#9447;
            </span>
          </div>
        </li>
      );
    });

  const safeParseDateChange = (value: any) => {
    if (!value) {
      return null;
    }

    return parse(value);
  };

  return (
    <React.Fragment>
      <div id="order-and-carrier-details" className="form-step">
        <h2>2. Order and Carrier Details</h2>
        <div className="col-sm-6 inner">
          <h3>Order Information:</h3>
          {buildTextInput('PO #', poNumber || '', (v) => onSetPoNumber(v), 'Purchase Order')}
          {buildTextInput('*Customer Reference #', customerUUID || '', (v) => onSetCustomerUUID(v))}
          <div className="col-sm-10 inner">
            {buildTextInput('Add Labels', label, (v) => setLabel(v), 'Order Labels')}
          </div>
          <div className="col-sm-2 outer">
            <button
              type="button"
              className="label-submit"
              onClick={() => {
                if (label.length > 0) {
                  onAddLabel(label);
                  setLabel('');
                }
              }}
            >
              Add
            </button>
          </div>
          <ul className="list-inline">{allLabels}</ul>
          <h3>SCAC carrier code</h3>
          {buildTextInput('SCAC', scac || '', (v) => onSetScac(v), 'SCAC', 4)}
        </div>
        <div className="col-sm-6 outer">
          <h3>*Ship From:</h3>
          <select
            placeholder="Warehouse"
            name="shipFrom"
            defaultValue={defaultWarehouse}
            onChange={(event) =>
              onSelectWarehouse(warehouses.find((wh) => wh.reservationId === Number(event.target.value)))
            }
          >
            {warehouseOptions()}
          </select>
          <div className="row">
            <div className="col-sm-6">
              <h3>Ship After:</h3>
              <input
                type="date"
                onChange={(event) => onSetShipAfterDate(safeParseDateChange(event.target.value))}
                defaultValue={''}
              />
            </div>
            <div className="col-sm-6">
              <h3>*Ship By:</h3>
              <input
                type="date"
                onChange={(event) => onSetShipByDate(safeParseDateChange(event.target.value))}
                defaultValue={''}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
    </React.Fragment>
  );
};

export default OrderAndCarrierDetails;
