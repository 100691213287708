import * as React from 'react';
import {FunctionComponent, useState} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {FilterOption} from '../CommonInterfaces';
import {LocationContents} from '../../locations/LocationsService';

interface Props {
  showBulkCreateModal: boolean;
  creatingCycleCount: boolean;
  selectedWarehouse: FilterOption;
  countAllLocations: boolean;
  totalLocationsCount: number;
  toggleCreateModal: (event: any) => Promise<void>;
  selectedLocations: LocationContents[];
  handleCreate: (countAllLocation, numberOfCounts, referenceId) => Promise<void>;
}

const CycleCountBulkCreateModal: FunctionComponent<Props> = (props) => {
  const MAX_CYCLE_COUNT_LOCATIONS: number = 150;
  const [numberOfCounts, setNumberOfCounts] = useState<number>(null);
  const [numberOfCountsError, setNumberOfCountsError] = useState<string>('');
  const [referenceId, setReferenceId] = useState<string>('');
  const handleNumberOfCountsChange = (event) => {
    const inputNumber = Number(event.target.value);
    if (inputNumber >= 0) {
      setNumberOfCounts(inputNumber);
      setNumberOfCountsError('');
    }
    if (
      props.selectedLocations.length > MAX_CYCLE_COUNT_LOCATIONS * inputNumber ||
      (props.countAllLocations && props.totalLocationsCount > MAX_CYCLE_COUNT_LOCATIONS * inputNumber)
    ) {
      const noOfLocations: number = props.countAllLocations
        ? props.totalLocationsCount
        : props.selectedLocations.length;
      const minNoOfCounts: number = Math.ceil(noOfLocations / MAX_CYCLE_COUNT_LOCATIONS);
      setNumberOfCountsError(
        `A single count cannot have more than ${MAX_CYCLE_COUNT_LOCATIONS} locations.
                Please increase the number of counts to at least ${minNoOfCounts} to continue.`
      );
    }
    if (props.countAllLocations && (inputNumber === 0 || inputNumber > props.totalLocationsCount)) {
      setNumberOfCountsError(`Must be a number between 1 and ${props.totalLocationsCount}`);
    }
    if (!props.countAllLocations && (inputNumber === 0 || inputNumber > props.selectedLocations.length)) {
      setNumberOfCountsError(`Must be a number between 1 and ${props.selectedLocations.length}`);
    }
  };
  const handleReferenceIdChange = (event) => {
    const inputString = event.target.value;
    if (inputString.length <= 50) {
      setReferenceId(inputString);
    }
  };
  const countLocationsBody = (
    <div>
      {props.countAllLocations ? (
        <dl>
          <dt>Locations:</dt>
          <dd>All Locations ({props.totalLocationsCount})</dd>
        </dl>
      ) : (
        <dl>
          <dt>{props.selectedLocations.length} Locations:</dt>
          <dd>
            <ul id="locations-list">
              {props.selectedLocations.map((s, i) => (
                <li key={i}>{s.label}</li>
              ))}
            </ul>
          </dd>
        </dl>
      )}
      <dl>
        <dt>Warehouse:</dt>
        <dd>{props.selectedWarehouse.displayName}</dd>
      </dl>
      <div className="numberOfCountsDiv">
        <label className="form-label" htmlFor="numberOfCountsInput">
          {'Number of counts to create:'}
        </label>
        <div className="flex-container">
          <input
            data-testid="numberOfCountsInput"
            name="numberOfCounts"
            className={`form-control inline short${numberOfCountsError ? ' error' : ''}`}
            type="text"
            onChange={handleNumberOfCountsChange}
            value={numberOfCounts || ''}
            placeholder=""
          />
          {props.countAllLocations && numberOfCounts > 0 && numberOfCounts < props.totalLocationsCount && (
            <p className="inline" data-testid="numberPerCount">
              Approx. {Math.ceil(props.totalLocationsCount / numberOfCounts)} locations per count
            </p>
          )}

          {!props.countAllLocations && numberOfCounts > 0 && numberOfCounts < props.totalLocationsCount && (
            <p className="inline" data-testid="numberPerCount">
              Approx. {Math.ceil(props.selectedLocations.length / numberOfCounts)} locations per count
            </p>
          )}
        </div>
        <span className="error-text" data-testid="numberOfCountsError">
          {numberOfCountsError}
        </span>
      </div>
      <div className="referenceIdDiv">
        <label className="form-label" htmlFor="referenceIdInput">
          {'Reference ID'}
        </label>
        <input
          data-testid="referenceIdInput"
          name="referenceId"
          className={'form-control short'}
          type="text"
          onChange={handleReferenceIdChange}
          value={referenceId}
          placeholder=""
        />
      </div>
    </div>
  );
  const disableCreate = () => {
    const isNumberOfCountValid = props.countAllLocations
      ? Boolean(numberOfCounts) && numberOfCounts > 0 && numberOfCounts <= props.totalLocationsCount
      : Boolean(numberOfCounts) && numberOfCounts > 0 && numberOfCounts <= props.selectedLocations.length;
    const disableForMaxLocations =
      (!props.countAllLocations &&
        (props.selectedLocations.length > MAX_CYCLE_COUNT_LOCATIONS * numberOfCounts ||
          props.selectedLocations.length < numberOfCounts)) ||
      (props.countAllLocations && props.totalLocationsCount > MAX_CYCLE_COUNT_LOCATIONS * numberOfCounts);
    return props.creatingCycleCount || !isNumberOfCountValid || referenceId.trim().length < 1 || disableForMaxLocations;
  };
  return (
    <LegacyModal
      id="cycle-count-bulk-create-modal"
      title={`Create Cycle Count${props.countAllLocations ? ': All Locations' : ''}`}
      show={props.showBulkCreateModal}
      size="small"
      toggleModal={props.toggleCreateModal}
      footer={
        <div>
          <a onClick={props.toggleCreateModal}>Back</a>
          <button
            id="createButton"
            data-testid="createButton"
            type="button"
            className="btn cta"
            disabled={disableCreate()}
            onClick={() => {
              props.handleCreate(props.countAllLocations, numberOfCounts, referenceId);
            }}
          >
            Create
          </button>
        </div>
      }
      disableClose={false}
    >
      {countLocationsBody}
    </LegacyModal>
  );
};

export default CycleCountBulkCreateModal;
