import * as React from 'react';
import {useRef} from 'react';
import CheckboxDropdownOption from './CheckboxDropdownOption';
import StatusDotOverlap from './StatusDotOverlap';

interface CheckboxDropdownOptionValues {
  name: string;
  value: string;
  subcategory?: boolean;
}

interface Props {
  includeAllOption?: boolean;
  dotStyleMap: {[key: string]: string};
  optionToStatusMap: {[key: string]: string};
  optionFilters: {[key: string]: boolean};
  onStatusChange: (status: any) => void;
  applyFilters: () => void;
}

const CheckboxDropdown: React.FC<Props> = (props) => {
  const [filterDisplayString, setFilterDisplayString] = React.useState<string>('All');
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const [selectedCount, setSelectedCount] = React.useState<number>(0);
  const isInitialRender = useRef(false);

  React.useEffect(() => {
    /* Return early if this is the initial render of the component to prevent
       the applyFilters callback from being fired prematurely since it defaults to
       false */
    if (!isInitialRender.current) {
      return;
    }

    if (!showOptions) {
      props.applyFilters();
    }
  }, [showOptions]);

  React.useEffect(() => {
    // Close the dropdown when the user clicks off of it
    document.body.addEventListener('mouseup', closeDropdownIfClickedElsewhere);

    return () => {
      // Remove the event handler on the body when the component unmounts
      document.body.removeEventListener('mouseup', closeDropdownIfClickedElsewhere);
    };
  }, []);

  const closeDropdownIfClickedElsewhere = (event) => {
    let element = event.target;
    while (element.parentNode) {
      if (element.classList && element.classList.contains('close-assist')) {
        // Do nothing
        return;
      } else {
        element = element.parentNode;
      }
    }

    setShowOptions(false);
  };

  // build string to display selected statuses in filter tab
  React.useEffect(() => {
    const filterKeysToCheck = Object.keys(props.optionFilters);
    let displayString = '';
    let counter = 0;
    if (props.includeAllOption && props.optionFilters.all) {
      displayString = 'All';
    } else {
      filterKeysToCheck.forEach((key) => {
        if (props.optionFilters[key]) {
          counter = counter + 1;
          if (counter === 1) {
            displayString = props.optionToStatusMap[key];
          } else if (counter === 2) {
            displayString = `${displayString} + ${props.optionToStatusMap[key]}`;
          } else if (counter > 2) {
            displayString = `${counter} States`;
          }
        }
        if (counter === 0) {
          displayString = 'All';
        }
        if (props.includeAllOption && counter === 1 && props.optionToStatusMap.all) {
          displayString = 'All';
        }
        setSelectedCount(counter);
      });
    }
    setFilterDisplayString(displayString);
  }, [props.optionFilters]);

  React.useEffect(() => {
    isInitialRender.current = true;
  }, []);

  const options: CheckboxDropdownOptionValues[] = [];

  if (props.includeAllOption) {
    options.push({name: 'Select All', value: 'all'});
  }

  Object.keys(props.optionToStatusMap).forEach((status) => {
    options.push({name: props.optionToStatusMap[status], value: status});
  });

  const onSelect = (selected) => {
    props.onStatusChange(selected);
  };

  const toggleOptionVisibility = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="checkbox-dropdown">
      <span className="close-assist">
        <button onClick={toggleOptionVisibility} data-testid={'show-hide-button'}>
          Status: <StatusDotOverlap dotStyleMap={props.dotStyleMap} selectedStatuses={props.optionFilters} />
          <span className="bold">{filterDisplayString}</span> <span className="caret"></span>
        </button>
        {showOptions && (
          <div className="checkbox-dropdown-options" data-testid="options-list">
            {options.map((o, i) => {
              const checked = props.optionFilters[o.value];
              return (
                <CheckboxDropdownOption
                  key={`${i}-${o.value}`}
                  onChange={onSelect}
                  name={o.name}
                  value={o.value}
                  subcategory={o.subcategory}
                  checked={checked}
                  dotStyleMap={props.dotStyleMap}
                />
              );
            })}
          </div>
        )}
      </span>
    </div>
  );
};

export default CheckboxDropdown;
