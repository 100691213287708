import * as React from 'react';
import {v4} from 'uuid';
import {buildTextInput} from '../../../../libs/helpers';
import {UploadParams} from '../../edi-files/EdiFilesInterfaces';
import {Company} from '../../../shared/CommonInterfaces';
import {GenericErrorDisplay} from '../../../shared/GenericErrorDisplay';
import FileStorageService from '../../file-storage/FileStorageService';
import EdiFilesServiceV2 from '../../edi-files/EdiFilesServiceV2';
import InternalAPIServiceV2 from '../../../shared/services/InternalAPIServiceV2';
import ShipperUserEmailSelect from '../inputs/ShipperUserEmailSelect';
import {ShipperUsersData} from '../ShipperInfoInterfaces';
import ShipperInfoService from '../ShipperInfoService';
import {
  cancelCsvExternalOrderId,
  cancelCsvFlexeOrderId,
  cancelCsvFlexeOrderLineId,
  csvBulkCancellationHeaders,
  csvBulkCancellationInstructions,
  csvBulkCancellationTemplates
} from '../helpers/OutboundOrderCsv';
import {instructionsTableHeaders} from '../helpers/OutboundOrdersUploadInstructions';
import CsvBulkUploadModalOMS, {
  BeforeOpenHandlerCallback,
  Section,
  UploadHandlerCallback
} from '../generic/CsvBulkUploadModalOMS';

interface Props {
  authenticityToken: string;
  showUploadModal: boolean;
  currentCompany: Company;
  toggleUploadModal: () => void;
}

const flexeOrderId = <i>{cancelCsvFlexeOrderId}</i>;
const externalOrderId = <i>{cancelCsvExternalOrderId}</i>;
const flexeOrderLineId = <i>{cancelCsvFlexeOrderLineId}</i>;
const supplementalSections: Section[] = [
  {
    title: 'Valid ID Combinations',
    body: (
      <>
        <p>
          You can use any of the following combinations of ID columns to cancel orders or lines. Blank columns should be
          removed and all rows must have values populated in the remaining columns.
        </p>
        <ul>
          <li>
            To cancel entire orders: Use {flexeOrderId} or {externalOrderId} only
          </li>
          <li>
            To cancel individual lines: Use {flexeOrderLineId} and either {flexeOrderId} or {externalOrderId}
          </li>
        </ul>
      </>
    )
  }
];

const OutboundOrdersBulkCancelModal: React.FC<Props> = (props: Props) => {
  const shipperInfoService: ShipperInfoService = new ShipperInfoService();
  const fileStorageService: FileStorageService = new FileStorageService();
  const v2EdiFilesService: EdiFilesServiceV2 = new EdiFilesServiceV2();

  const [flexeEmailUsername, setFlexeEmailUsername] = React.useState<string>('');
  const [shipperEmailAddress, setShipperEmailAddress] = React.useState<string>('');
  const [shipperUserList, setShipperUserList] = React.useState<ShipperUsersData[]>([]);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  const closeModal = () => {
    setFlexeEmailUsername('');
    setShipperEmailAddress('');
    props.toggleUploadModal();
  };

  const getShipperUserList = async (callback: BeforeOpenHandlerCallback) => {
    try {
      const userData = await shipperInfoService.getShipperUsers(props.currentCompany.id.toString());

      if (userData.errors && userData.errors.length > 0) {
        callback([
          {
            header: 'Error fetching shipper user emails.',
            details: InternalAPIServiceV2.extractErrorSummaries(userData.errors)
          }
        ]);
      } else {
        setShipperUserList(userData.data);
        callback([]);
      }
    } catch (err) {
      callback([{header: `Error fetching shipper user emails. ${err}`}]);
    }
  };

  const handleUpload = async (params: UploadParams, type: string, callback: UploadHandlerCallback) => {
    const responseErrors: GenericErrorDisplay[] = [];
    if (!flexeEmailUsername && !shipperEmailAddress) {
      responseErrors.push({header: 'Please input at least one email address'});
    } else {
      setIsUploading(true);

      const fileKey = `outbound_cancel_${v4()}`;
      let response = await fileStorageService.uploadFile(params.file, fileKey, {returnAllErrors: true});
      if (response && !(response.errors && response.errors.length > 0)) {
        const flexeEmail = flexeEmailUsername ? flexeEmailUsername.trim() + '@flexe.com' : flexeEmailUsername;
        const emailList = [flexeEmail, shipperEmailAddress].filter((n) => n).join(',');
        const metadata = {
          email_distribution_list: emailList
        };
        response = await v2EdiFilesService.createEdiFile(type, fileKey, metadata, {returnAllErrors: true});
      }

      if (!response) {
        responseErrors.push({header: 'Storing or creating file failed. Please try again and contact Flexe'});
      } else if (response.errors && response.errors.length > 0) {
        const errorDetails = InternalAPIServiceV2.extractErrorSummaries(response.errors);
        responseErrors.push({
          header: 'Unexpected error while attempting to store or create the file:',
          details: errorDetails
        });
      }
    }
    callback(responseErrors);
    setIsUploading(false);

    if (responseErrors.length < 1) {
      closeModal();
    }
  };

  const disableSubmit = !(shipperEmailAddress || flexeEmailUsername.trim());
  return (
    <CsvBulkUploadModalOMS
      id="outbound-order-bulk-cancel-modal"
      title="Cancel Orders From a CSV"
      actionDescription="cancel orders"
      show={props.showUploadModal}
      csvTemplates={csvBulkCancellationTemplates}
      toggleModal={closeModal}
      instructionSupplementalSections={supplementalSections}
      instructionsTableHeaders={instructionsTableHeaders}
      instructionsTableRows={csvBulkCancellationInstructions}
      uploading={isUploading}
      handleUpload={handleUpload}
      handleBeforeOpen={getShipperUserList}
      headers={csvBulkCancellationHeaders}
      fileUploadType="incoming_csv_bulk_order_cancel" // This is arbitrary since we're not using backwards compatibility
      disableSubmit={disableSubmit}
    >
      <div>
        <p>
          This process can take a few minutes to complete. Once finished, a report with links to cancellation results
          will be sent the specified Flexe and/or shipper email addresses.
        </p>
        <p>Please specify the user(s) to receive the report. At least one user is required.</p>
        <span>
          <ShipperUserEmailSelect
            onUserEmailChange={setShipperEmailAddress}
            selectedUserEmail={shipperEmailAddress}
            allUserEmailData={shipperUserList}
          />
        </span>
        <span className="upload-choice-spacer">or</span>
        <span>
          {buildTextInput(
            'someone',
            flexeEmailUsername,
            (v: string) => setFlexeEmailUsername(v),
            '*Flexe email address to send cancellation summary to'
          )}
          <span className="at-flexe">@flexe.com</span>
        </span>
      </div>
    </CsvBulkUploadModalOMS>
  );
};

export default OutboundOrdersBulkCancelModal;
