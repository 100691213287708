import * as React from 'react';
import {useEffect, useState} from 'react';
import * as _ from 'lodash';
import InventoryService from '../../shared/services/InventoryService';
import {Packaging, SelectableWarehouse} from '../../shared/CommonInterfaces';
import {CsvColumns, CsvFieldType} from '../../../libs/CsvValidator';
import InventoryCSVUploadV2 from './InventoryCSVUploadV2';
import InventoryCSVUploadV3 from './InventoryCSVUploadV3';
import InventoryCSVUpload from './InventoryCSVUpload';

interface Props {
  showNewModal?: boolean; // TODO remove
  selectedWarehouse: SelectableWarehouse;
  authenticityToken: string;
  csvFormat: CsvColumns; // TODO remove when showNewModal is removed
  csvExample: any; // TODO remove when showNewModal is removed
  sampleCsvPath: string;
  useNewUiComponentsModal: boolean;
  disallowedPackagingTypes?: Packaging[];
  onAddInventory(items: Line[]);
  onEditInventory(item: Line);
  onDeleteInventory(id: number);
}

export interface AvailableInventory {
  id: number;
  sku: string;
  description: string;
  availability: WarehouseAvailability[];
}

export interface WarehouseAvailability {
  reservationId: number;
  warehouseName: string;
  availableEaches: number;
  availableCartons: number;
  availablePallets: number;
}

export interface Line {
  inventoryId: number;
  sku: string;
  description: string;
  packaging: Packaging;
  quantity: number;
  availability: WarehouseAvailability[];
}

export const INV_CSV_COLUMNS_SPEC: CsvColumns = {
  sku: {
    required: true,
    type: CsvFieldType.string,
    instructions: [
      'SKU',
      <span className="bold">Yes</span>,
      <React.Fragment>
        The SKU of an item to add.
        <br />
        <span className="italics">Must be enabled in Flexe Item Master</span>
      </React.Fragment>,
      'Alphanumeric string (special characters allowed)',
      'SKU-224113'
    ]
  },
  quantity: {
    required: true,
    type: CsvFieldType.integer,
    instructions: [
      'Quantity',
      <span className="bold">Yes</span>,
      'Number of items to add for this SKU.',
      'Positive integer (whole number)',
      '10'
    ]
  },
  unit: {
    required: true,
    type: CsvFieldType.enum,
    enum: Packaging,
    instructions: [
      'Unit',
      <span className="bold">Yes</span>,
      'Unit of measure for the quantity',
      <React.Fragment>
        Must be one of the following:
        <br />
        <span className="italics">each, carton, pallet</span>
      </React.Fragment>,
      'carton'
    ]
  }
};

// instructions* are used for the CSV modal, but tied to INV_CSV_COLUMNS_SPEC,
// so they live here.
export const InstructionsTableHeaders: React.ReactNode[] = [
  'Column Header',
  'Required?',
  'Description of Use',
  'Input Guidelines',
  'Sample Input'
];

export const InstructionsTableRows = buildInstructionsTableRows();

function buildInstructionsTableRows(): React.ReactNode[][] {
  const rows: React.ReactNode[][] = [];
  const csvFormat = INV_CSV_COLUMNS_SPEC;

  for (const csvFormatKey in csvFormat) {
    // eslint-disable-next-line no-prototype-builtins
    if (csvFormat.hasOwnProperty(csvFormatKey)) {
      const instructions = csvFormat[csvFormatKey].instructions;

      if (instructions) {
        rows.push(instructions);
      } else {
        rows.push([csvFormatKey, '', '', '', '']); // Prevent hiding rows from the table
      }
    }
  }

  return rows;
}

// TODO remove when showNewModal is removed
export const CsvInvUploadExample = {
  fields: ['sku', 'quantity', 'unit'],
  data: [
    ['1', 20, 'each'],
    ['2', 30, 'carton'],
    ['3', 25, 'carton']
  ]
};

// The templates are the same, but we should give the customer a meaningful filename.
// So we just maintain copies of them.
export const SampleInvCsvPathRetailFulfillment = '/static/files/RetailFulfillmentOrderInventoryBulkCreate.csv';
export const SampleInvCsvPathOrder = '/static/files/OrderCreateBulkAddInventory.csv';

const InventorySelection: React.FC<Props> = (props) => {
  const inventoryService = new InventoryService(props.authenticityToken);
  let filterTimeout;

  const [skuFilter, setSkuFilter] = React.useState<string>(null);
  const [showSkuOptions, setShowSkuOptions] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [warnings, setWarnings] = React.useState<string[]>([]);
  const [loadingSKU, setLoadingSKU] = React.useState<boolean>(false);
  const [loadingReservation, setLoadingReservation] = React.useState<boolean>(false);
  const [skuFilterResults, setSkuFilterResults] = useState<AvailableInventory[]>([]);
  const [allReservationInventory, setAllReservationInventory] = useState<AvailableInventory[]>([]);
  const [selections, setSelections] = useState<Line[]>([]);
  const [showCSVModal, setShowCSVModal] = useState<boolean>(false);

  useEffect(() => {
    if (skuFilter && skuFilter.length > 2 && props.selectedWarehouse) {
      getInventory();
    } else {
      // Clear results if there is no warehouse or the list would be too long
      setSkuFilterResults([]);
    }
  }, [skuFilter, props.selectedWarehouse]);

  /*
    This effect serves to load all inventory for all reservation(s) selected.
    The reason we are doing this in addition to doing it per sku filter is because the CSV validator requires a master
    catalog of all reservation inventory to validate against. We could use this catalog to serve the sku typeahead
    but the better solution for that is to fetch only the inventory we need based on the user's sku filter.
    Once we migrate the csv validator to its own service we no longer have to do one big expensive 'get all inventory
    for all reservations' call and instead only fetch inventory when we have a skuFilter.
 */
  useEffect(() => {
    if (props.selectedWarehouse) {
      setWarnings([]); // Clear the warning we'd add if there was no warehouse
      getInventory(true);

      //TODO: come up with a better solution to this issue
      if (selectedInventory.length > 0) {
        const warning =
          'Some inventory lines may no longer be ' + "valid because the 'Ship From' warehouse has changed";
        setWarnings([warning]);
      }
    } else {
      setAllReservationInventory([]);
      setWarnings(['No warehouse has been selected']);
    }
  }, [props.selectedWarehouse]);

  const getInventory = async (getAllInventory?: boolean) => {
    // TODO Errors and warnings are fragile. We should be appending
    //  or replacing based on the source of the error.
    setErrors([]);
    const errs = [];
    let token: string = null;
    let allInventory: AvailableInventory[] = [];

    let skuFilterToUse: string;
    if (getAllInventory) {
      skuFilterToUse = '';
      setLoadingReservation(true);
    } else {
      skuFilterToUse = skuFilter;
      setLoadingSKU(true);
    }

    try {
      //Fetching inventory reservation by reservation so we can differentiate the availability between warehouses
      do {
        const response = await inventoryService.getInventories(
          props.selectedWarehouse.reservationId,
          skuFilterToUse,
          token,
          true,
          true
        );

        //Filter out inventory that is not on-hand
        const summaries = response.inventory_summary
          .filter((inv) => inv.available_eaches > 0)
          .map((inv) => {
            return {
              id: inv.id,
              sku: inv.item_code,
              description: inv.description,
              availability: [
                {
                  reservationId: props.selectedWarehouse.reservationId,
                  warehouseName: props.selectedWarehouse.warehouseName,
                  availableEaches: inv.available_eaches,
                  availableCartons: inv.available_cartons,
                  availablePallets: inv.available_pallets
                }
              ]
            };
          });

        allInventory = allInventory.concat(summaries);
        token = response.continuation_token;
      } while (token != null);

      //aggregate warehouse availability
      allInventory = Object.values(
        allInventory.reduce((aggregator, it) => {
          aggregator[it.id] = aggregator[it.id] || {
            id: it.id,
            sku: it.sku,
            description: it.description,
            availability: []
          };
          aggregator[it.id].availability = aggregator[it.id].availability.concat(it.availability);
          return aggregator;
        }, {})
      );

      if (getAllInventory) {
        setAllReservationInventory(allInventory);
      } else {
        setSkuFilterResults(allInventory);
        setShowSkuOptions(true);
      }
    } catch (error) {
      errs.push(error.toString());
    } finally {
      setErrors(errs);
      if (getAllInventory) {
        setLoadingReservation(false);
      } else {
        setLoadingSKU(false);
      }
    }
  };

  const updateFilter = async (event) => {
    if (skuFilter && showSkuOptions) {
      setShowSkuOptions(false);
    }

    const newFilter: string = event.target.value;
    if (filterTimeout >= 500) {
      clearTimeout(filterTimeout);
      const err = 'Inventory filter request timed out';
      setErrors([err]);
    } else {
      const newSkuFilter: string = newFilter;
      filterTimeout = setTimeout(() => {
        setSkuFilter(newSkuFilter);
      }, 500);
    }
  };

  const handleInventorySelect = (inventoryId: number) => {
    clearAlerts();
    if (selections.find((it) => it.inventoryId === inventoryId)) {
      setShowSkuOptions(false);
      setErrors(['This SKU has already been selected']);
      return;
    }

    const inv = skuFilterResults.find((it) => it.id === inventoryId);
    const defaultPackaging = Packaging.each;
    const defaultQuantity = 1;
    const newSelection = {
      inventoryId: inv.id,
      sku: inv.sku,
      description: inv.description,
      packaging: defaultPackaging,
      quantity: defaultQuantity,
      availability: inv.availability
    };

    setSelections([...selections].concat(newSelection));
    props.onAddInventory([newSelection]);
    setSkuFilter(null);
    setShowSkuOptions(false);
    setErrors([]);
  };

  const handleCSVUpload = (validLines: Line[], lineErrors: string[]) => {
    const newLines: Line[] = [];
    const addlErrors: string[] = [];
    const summaryMessage = 'Each SKU can only be selected once per order.';

    validLines.forEach((line) => {
      if (selections.some((testLine) => testLine.inventoryId === line.inventoryId)) {
        addlErrors.push(
          `${summaryMessage} Since ${line.sku} was already selected, the row for that SKU in the file was ignored.`
        );
      } else if (newLines.some((testLine) => testLine.inventoryId === line.inventoryId)) {
        // It might be nice to group by SKU + UOM and add quantities, but out of scope
        //  and it's controversial/unclear how we should handle existing lines
        // (i.e. does the CSV replace the existing quantity? Or increment?)
        addlErrors.push(
          `${summaryMessage} Since ${line.sku} appeared in the file multiple times, only the first appearance was used.`
        );
      } else {
        newLines.push(line);
      }
    });

    setSelections([...selections].concat(newLines));
    if (newLines.length > 0) {
      props.onAddInventory(newLines);
    }
    if (!props.useNewUiComponentsModal) {
      setErrors(lineErrors.concat(addlErrors));
      setShowCSVModal(false);
    } else {
      const uploadErrors = lineErrors.concat(addlErrors);
      setErrors(uploadErrors);
      setShowCSVModal(uploadErrors.length !== 0);
    }
  };

  const skuOptions = skuFilterResults.map((inv) => {
    const label = inv.sku + ' - ' + inv.description;
    return (
      <li key={inv.sku}>
        <a onClick={() => handleInventorySelect(inv.id)} data-id={inv.id}>
          {label}
        </a>
      </li>
    );
  });

  const handlePackagingSelect = (inventoryId: number, packaging: Packaging) => {
    clearAlerts();
    const lines = [...selections];
    const lineIndex = lines.indexOf(lines.find((it) => it.inventoryId === inventoryId));

    //updates the quantity to the new max if the new packaging invalidates the current quantity.
    const line = {
      ...lines[lineIndex],
      packaging
    };
    const max = maxQuantityForLine(line);
    const quantity = Math.min(max, lines[lineIndex].quantity);
    const newLine = {
      ...lines[lineIndex],
      packaging,
      quantity
    };

    lines[lineIndex] = newLine;

    //update state and parent
    setSelections(lines);
    props.onEditInventory(newLine);
  };

  const handleQuantityChange = (inventoryId: number, quantity: number) => {
    clearAlerts();
    const lines = [...selections];
    const lineIndex = lines.indexOf(lines.find((it) => it.inventoryId === inventoryId));
    const newLine = {
      ...lines[lineIndex],
      quantity
    };
    lines[lineIndex] = newLine;

    //update state and parent
    setSelections(lines);
    props.onEditInventory(newLine);
  };

  const handleLineDeletion = (inventoryId: number) => {
    clearAlerts();
    const lines = [...selections];
    const lineIndex = lines.indexOf(lines.find((it) => it.inventoryId === inventoryId));
    lines.splice(lineIndex, 1);

    //update state and parent
    setSelections(lines);
    props.onDeleteInventory(inventoryId);
  };

  const maxQuantityForLine = (line: Line): number => {
    switch (line.packaging) {
      case Packaging.pallet:
        return line.availability.reduce((sum, current) => sum + current.availablePallets, 0);
      case Packaging.carton:
        return line.availability.reduce((sum, current) => sum + current.availableCartons, 0);
      case Packaging.each:
        return line.availability.reduce((sum, current) => sum + current.availableEaches, 0);
      default:
        return 0;
    }
  };

  const packagingOptions = [];
  const disallowedPackagingTypes = props.disallowedPackagingTypes || [];
  if (!disallowedPackagingTypes.includes(Packaging.each)) {
    packagingOptions.push(<option value={Packaging.each}>Each</option>);
  }
  if (!disallowedPackagingTypes.includes(Packaging.carton)) {
    packagingOptions.push(<option value={Packaging.carton}>Carton</option>);
  }
  if (!disallowedPackagingTypes.includes(Packaging.pallet)) {
    packagingOptions.push(<option value={Packaging.pallet}>Pallet</option>);
  }

  const selectedInventory = selections.map((line) => {
    const max = maxQuantityForLine(line);
    return (
      <tr key={`line_${line.inventoryId}`}>
        <td className="col-md-2">
          <span>{_.escape(line.sku)}</span>
        </td>
        <td className="col-md-3">
          <span>{_.escape(line.description)}</span>
        </td>
        <td className="col-md-3">
          <select
            placeholder="Select UoM"
            onChange={(event) => handlePackagingSelect(line.inventoryId, Packaging[event.target.value])}
            value={line.packaging}
          >
            {packagingOptions}
          </select>
        </td>
        <td className="col-md-2">
          <input
            value={line.quantity}
            type="number"
            min="1"
            max={max}
            onChange={(event) => handleQuantityChange(line.inventoryId, Math.min(Number(event.target.value), max))}
          />
        </td>
        <td className="col-md-1">
          <span>/{max}</span>
        </td>
        <td className="col-md-1">
          <button type="button" className="btn-danger" onClick={() => handleLineDeletion(line.inventoryId)}>
            Delete
          </button>
        </td>
      </tr>
    );
  });

  const warningDisplay = warnings.map((error, idx) => {
    return (
      <div className="alert alert-warning" role="alert" key={`warning:${idx}`}>
        {error}
      </div>
    );
  });

  const errorDisplay = errors.map((error, idx) => {
    return (
      <div className="alert alert-danger" role="alert" key={`error:${idx}`}>
        {error}
      </div>
    );
  });

  const clearAlerts = () => {
    setWarnings([]);
    setErrors([]);
  };

  return (
    <>
      {!props.useNewUiComponentsModal ? errorDisplay : null}
      {warningDisplay}
      <div className="row space-above">
        <div className="col-md-4">
          <h3>*Select a product:</h3>
        </div>
        <div className="col-md-4">
          {(loadingSKU || loadingReservation) && (
            <span className="sku-filter-loading">
              <i>Loading...</i>
            </span>
          )}
        </div>
      </div>
      <div className="inv-header">
        <div className="col-md-8">
          <input
            id="sku-filter"
            type="text"
            className="sku-filter"
            onChange={updateFilter}
            placeholder="Filter by SKU or description..."
          />

          {showSkuOptions && (
            <ul className="dropdown-menu right-align show">
              {skuOptions}
              {skuOptions.length === 0 && (
                <li key="no-option">
                  <a data-id={1234}>No Results</a>
                </li>
              )}
            </ul>
          )}
        </div>
        <div className="col-md-1 inv-or">
          <span>OR</span>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            className="upload-csv"
            onClick={() => setShowCSVModal(true)}
            disabled={loadingReservation || !props.selectedWarehouse}
          >
            Upload CSV
          </button>
        </div>
      </div>

      {/* TODO (future): Recent / Starred section goes here */}
      {selectedInventory.length > 0 && (
        <table className="table inventory-selection">
          <thead>
            <tr>
              <th>SKU ID</th>
              <th>Description</th>
              <th>Type</th>
              <th>Quantity</th>
              <th />
            </tr>
          </thead>
          <tbody>{selectedInventory}</tbody>
        </table>
      )}
      <hr />
      {props.useNewUiComponentsModal ? (
        <InventoryCSVUploadV3
          title="Add Inventory From a CSV"
          actionDescription="add inventory"
          reservationInventory={allReservationInventory}
          setValidLinesAndErrors={handleCSVUpload}
          templatePath={props.sampleCsvPath}
          showModal={showCSVModal}
          errors={errors}
          clearAlerts={clearAlerts}
          toggleModal={() => {
            clearAlerts();
            setShowCSVModal(!showCSVModal);
          }}
        />
      ) : props.showNewModal ? (
        <InventoryCSVUploadV2
          title="Add Inventory From a CSV"
          actionDescription="add inventory"
          reservationInventory={allReservationInventory}
          setValidLinesAndErrors={handleCSVUpload}
          csvTemplate={{filepath: props.sampleCsvPath, displayName: 'CSV template'}}
          showModal={showCSVModal}
          toggleModal={() => setShowCSVModal(!showCSVModal)}
        />
      ) : (
        <InventoryCSVUpload
          showModal={showCSVModal}
          csvFormat={props.csvFormat}
          csvExample={props.csvExample}
          sampleCsvPath={props.sampleCsvPath}
          reservationInventory={allReservationInventory}
          onUpload={handleCSVUpload}
          onCancel={() => setShowCSVModal(false)}
        />
      )}
    </>
  );
};

export default InventorySelection;
