import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {CarrierAccount} from '../SharedInterfaces';

interface DeleteAccountProps {
  account: CarrierAccount;
  deleteAccount: () => Promise<void>;
  toggleDeleteAccountModal: () => void;
}

export default function DeleteAccount({account, deleteAccount, toggleDeleteAccountModal}: DeleteAccountProps) {
  return (
    <LegacyModal
      id="delete_account_modal"
      title={`Delete Account (${account.carrierDisplayName} - ${account.accountNumber || 'NA'})`}
      show={true}
      size="small"
      toggleModal={toggleDeleteAccountModal}
      footer={<DeleteAccountFooter deleteAccount={deleteAccount} toggleDeleteAccountModal={toggleDeleteAccountModal} />}
      disableClose={false}
    >
      <div>
        <div className="row">
          {account.reservations.length > 0 && (
            <div className="col-md-12">
              <p>This account is currently enabled for the following reservations:</p>
              <ul className="col-md-12">
                {account.reservations.map((r) => (
                  <li key={r.id}>
                    <i>{r.name}</i>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <p className="col-md-12">
            Are you sure you wish to delete &nbsp;
            {account.carrierDisplayName}
            {account.accountNumber !== null ? `- ${account.accountNumber}` : ''}?
          </p>
        </div>
      </div>
    </LegacyModal>
  );
}

interface DeleteAccountFooterProps {
  deleteAccount: () => Promise<void>;
  toggleDeleteAccountModal: () => void;
}

function DeleteAccountFooter({deleteAccount, toggleDeleteAccountModal}: DeleteAccountFooterProps) {
  return (
    <div>
      <a onClick={toggleDeleteAccountModal}>Cancel</a>
      <button id="delete-account-agree" type="button" className="btn" onClick={deleteAccount}>
        Delete
      </button>
    </div>
  );
}
