import * as React from 'react';
import {Loader, Modal} from '@flexe/ui-components';
import {FC, useEffect, useMemo} from 'react';
import {ShipmentDetailsContext} from '../warehouse/outbound-shipments/shipment-details/ShipmentDetailsContext';
import {LpnSearchDetails} from '../lpns/LpnsInterfaces';
import SsccService from '../shared/services/SsccService';

interface Props {
  isOpen: boolean;
  title: string;
  shipmentId: number;
  toggleModal: () => void;
  cancelPrint: () => void;
}

export const PrintCartonSsccByLpnModal: FC<Props> = (props) => {
  const {shipmentId, toggleModal, isOpen, title} = props;
  const {lpnService, authenticityToken, setErrors} = React.useContext(ShipmentDetailsContext);
  const ssccService = useMemo(() => new SsccService(authenticityToken), [authenticityToken]);
  const [lpnsLoading, setLpnsLoading] = React.useState<boolean>(true);
  const [lpns, setLpns] = React.useState<LpnSearchDetails[]>([]);
  const [parentLpns, setParentLpns] = React.useState<LpnSearchDetails[]>([]);

  const fetchLpns = async () => {
    setLpnsLoading(true);
    if (shipmentId === null) {
      return;
    }

    try {
      const response = await lpnService.searchShipmentDetailsLpns({
        ReservationIds: [],
        ShipmentIds: [shipmentId],
        LpnBarcodes: [],
        Skus: []
      });

      if (response.errors.length === 0) {
        if (response.data.lpns) {
          setLpns(response.data.lpns);
          const parentLpnIds = response.data.lpns?.map((lpn) => {
            if (lpn.parentLpnId != null) {
              return lpn.parentLpnId;
            }
          });
          setParentLpns(response.data.lpns.filter((lpn) => parentLpnIds.includes(lpn.lpnId)));
        }
      } else {
        setErrors(response.errors.map((e) => e.detail));
      }
    } catch (e) {
      setLpns([]);
      setParentLpns([]);
    } finally {
      setLpnsLoading(false);
    }
  };

  useEffect(() => {
    fetchLpns();
  }, [shipmentId]);

  const handleToggle = () => {
    toggleModal();
  };

  const handlePrintClick = (lpnId: number) => {
    ssccService.getCartonSsccLabelsForParentLpn(lpnId, shipmentId);
  };

  return (
    <Modal size={'large'} isOpen={isOpen} toggleModal={handleToggle} title={title}>
      <div id="print-modal-contents">
        {lpnsLoading && <Loader loading={lpnsLoading} />}
        {!lpnsLoading && (
          <table className="table">
            <thead>
              <tr style={{borderTop: '0px'}}>
                <th>LPN</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {parentLpns.map((lpn, index) => {
                const childLpnCount = lpns.filter((l) => l.parentLpnId === lpn.lpnId).length;
                return (
                  <tr key={`lpn-row-${index}`}>
                    <td>{lpn.lpnBarcode}</td>
                    <td>{lpn.location.label}</td>
                    <td>
                      <button onClick={() => handlePrintClick(lpn.lpnId)}>Print {childLpnCount} Labels</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Modal>
  );
};

export default PrintCartonSsccByLpnModal;
