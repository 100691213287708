import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';
import {DocumentType, TransitionState} from '../../shared/CommonInterfaces';
import BatchWavingService from '../../shared/services/BatchWavingService';
import FlexeContext from '../../contexts/FlexeContext';
import {BatchDocument} from './BatchInterfaces';

interface BatchDocumentsProps {
  batchService: BatchWavingService;
  batchDocuments: BatchDocument[];
  batchId: number;
  batchStatus: TransitionState;
}

interface BatchDocumentsState {
  batchDocuments: BatchDocument[];
  documentPrinted: Map<number, boolean>;
  documentErrors: Map<number, string>;
}

class BatchDocuments extends React.Component<BatchDocumentsProps, BatchDocumentsState> {
  public static contextType = FlexeContext;
  public static contextTypes;

  public static getDerivedStateFromProps(newProps, prevState) {
    //parent has not updated us
    if (newProps.batchDocuments === prevState.batchDocuments) {
      return null;
    }

    const documentPrinted = new Map<number, boolean>();
    newProps.batchDocuments.forEach((document) => {
      documentPrinted[document.id] = document.printed;
    });

    return {
      batchDocuments: newProps.batchDocuments,
      documentPrinted
    };
  }

  private batchService: BatchWavingService;

  constructor(props) {
    super(props);
    this.batchService = this.props.batchService;

    const documentPrinted = new Map<number, boolean>();
    this.props.batchDocuments.forEach((document) => {
      documentPrinted[document.id] = document.printed;
    });
    this.state = {
      documentPrinted,
      documentErrors: new Map<number, string>(),
      batchDocuments: props.batchDocuments
    };
  }

  public render() {
    if (this.props.batchDocuments && this.props.batchDocuments.length > 0) {
      const docElements = this.props.batchDocuments.map((document) => {
        const error = this.state.documentErrors[document.id];
        const isPrinted = this.state.documentPrinted[document.id];
        const checkbox = (
          <input
            type="checkbox"
            checked={isPrinted}
            onChange={(event) => this.handlePrintCheckboxChange(this.props.batchId, document.id, event)}
          />
        );
        const checkboxMessage = isPrinted ? '\tPrinted' : '\tPrinted?';
        return (
          <div key={document.id}>
            <a
              id="batch-detail-component"
              className="batch-doc-hyperlink-padding"
              href={this.props.batchStatus === TransitionState.New ? null : `/wh/logged_document/${document.id}`}
              data-tip
              data-for={`document-${document.id}-tip`}
              target="_blank"
            >
              {document.name}{' '}
              {this.props.batchStatus === TransitionState.New ? null : `- (viewed ${document.views.length} times)`}
            </a>
            {(this.props.batchStatus === TransitionState.New || document.views.length > 0) && (
              <ReactTooltip className="document-tooltip" place="right" type="dark" id={`document-${document.id}-tip`}>
                {this.props.batchStatus === TransitionState.New
                  ? 'Shipments must be confirmed before labels are printed.'
                  : document.views.map((view, index) => {
                      return <p key={index}>{view.viewer}</p>;
                    })}
              </ReactTooltip>
            )}
          </div>
        );
      });
      return docElements;
    }

    return null;
  }

  private async handlePrintCheckboxChange(
    batchId: number,
    documentId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    //these variables are necessary in order to make lint happy
    const str = 'checked';
    const isChecked = event.target[str];
    const response = await this.batchService.toggleDocumentAsPrinted(batchId, documentId, isChecked);

    if (response && response.errors.length === 0) {
      this.setState((prevState) => ({
        documentPrinted: {
          ...prevState.documentPrinted,
          [documentId]: isChecked
        }
      }));
    } else {
      this.handleError('Unable to label document as printed', response, documentId);
    }
  }

  private handleError(message: string, response, documentId: number) {
    if (response) {
      const serverErrors = response.errors.map((e) => e.detail);
      message += `: ${serverErrors.join(', ')}`;
    }
    this.setState((prevState) => ({
      documentErrors: {
        ...prevState.documentErrors,
        [documentId]: message
      }
    }));
  }
}

export default BatchDocuments;
