import * as React from 'react';
import {useMemo, useState} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {GridColumnVisibilityModel, GridToolbarContainer, GridToolbarQuickFilter} from '@mui/x-data-grid';
import {flexeMuiTheme} from '../../shared/mui/default-mui-theme';
import {FlexeDataGrid} from '../../shared/mui/flexe-data-grid/FlexeDataGrid';
import {Warehouse} from '../../shared/CommonInterfaces';
import {LoadStop, LoadSummary} from './LoadInterfaces';
import {isLoadStatusTerminal, LoadStatusMap} from './LoadConstants';
import {LoadsGridColumnDefiniton, LoadsGridContent} from './LoadsGridColumnDefinition';

interface LoadsTableProps {
  data: LoadSummary[];
  isLoading: boolean;
  warehouse: Warehouse;
}

const baseUrl = '/wh';
const DEFAULT_PAGE_SIZE = 50;

export const LoadsTable = (props: LoadsTableProps) => {
  const onlyUnique = (value: string, index, array: string[]) => {
    return array.indexOf(value) === index;
  };

  const getDestinationId = (loadStops: LoadStop[]): string[] => {
    const destinationIds = loadStops
      .map((stop) => stop.destinationId)
      .filter((destinationId) => {
        return destinationId != null;
      })
      .filter(onlyUnique);
    if (destinationIds.length > 0) {
      return destinationIds;
    }
    return null;
  };

  const mapLoad = (load: LoadSummary): LoadsGridContent => {
    const status = LoadStatusMap.get(load.txnState).text;

    // hide dock location for terminal loads so it's not rendered or searchable
    const dockLocation = isLoadStatusTerminal(status) ? null : {id: load.dockLocationId, value: load.dockLocation};
    return {
      id: load.id,
      status, // convert to the front-end text so that filtering works
      mode: load.shipMode,
      scac: load.scac,
      createdAt: new Date(Date.parse(load.createdAt)),
      trailerNumber: load.trailerNumber,
      dockLocation,
      destinationId: getDestinationId(load.loadStops),
      shipmentCount: load.shipmentCount,
      loadGroup: load.loadGroup
    };
  };

  const rows = props.data.map((load) => mapLoad(load));
  const columns = LoadsGridColumnDefiniton(baseUrl, props.warehouse);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE
  });

  const columnVisibilityModel = useMemo<GridColumnVisibilityModel>(() => {
    const enableTxnStateColumn = rows.some(({status}) => status);
    const enableShipModeColumn = rows.some(({mode}) => mode);
    const enableCreatedAtColumn = rows.some(({createdAt}) => createdAt);
    const enableShipmentCountColumn = rows.some(({shipmentCount}) => shipmentCount);
    const enableScacColumn = rows.some(({scac}) => scac);
    const enableTrailerNumberColumn = rows.some(({trailerNumber}) => trailerNumber);
    const enableLoadingLocationColumn = rows.some(({dockLocation}) => dockLocation);
    const enableDestinationIdColumn = rows.some(({destinationId}) => destinationId);
    const model = {
      status: enableTxnStateColumn,
      mode: enableShipModeColumn,
      createdAt: enableCreatedAtColumn,
      shipmentCount: enableShipmentCountColumn,
      scac: enableScacColumn,
      trailerNumber: enableTrailerNumberColumn,
      loadingLocation: enableLoadingLocationColumn,
      destinationId: enableDestinationIdColumn
    };
    if (props.isLoading) {
      // Hide all columns until we know which ones to show
      for (const column of columns) {
        model[column.field] = false;
      }
    }
    return model;
  }, [columns, rows, props.isLoading]);

  // removes the export button but includes the quick filter
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter
          // make the search bar more visible
          sx={{'border': 1, 'borderColor': '#C9D1DC', 'backgroundColor': '#fff', 'padding-bottom': 0}}
          debounceMs={250}
        />
      </GridToolbarContainer>
    );
  };

  return (
    <ThemeProvider theme={flexeMuiTheme}>
      <FlexeDataGrid
        autoHeight
        columns={columns}
        rows={rows}
        loading={props.isLoading}
        pageSizeOptions={[20, 50, 100]} // max is 100
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        columnVisibilityModel={columnVisibilityModel}
        getRowHeight={() => 'auto'}
        sx={{
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '8px'}
        }}
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        slots={{toolbar: CustomToolbar}}
      />
    </ThemeProvider>
  );
};
