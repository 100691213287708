import * as moment from 'moment-timezone';

const formatTimeWithTimezone = (
  timezone: string,
  time: string | Date,
  format: string = 'MM/DD/YYYY h:mm a (z)'
): string => {
  return moment(time)
    .tz(timezone)
    .format(format);
};

export default formatTimeWithTimezone;
