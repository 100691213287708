import * as React from 'react';
import {ErrorInfo} from 'react';
interface ErrorBoundaryProps {
  setErrors?: (error: string) => void;
  fallbackComponent?: React.ReactElement;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(_error: string) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error: Error) {
    // You can also log the error to an error reporting service
    if (this.props.setErrors) {
      this.props.setErrors(error.message);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.fallbackComponent) {
        return this.props.fallbackComponent;
      }
      return <></>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
