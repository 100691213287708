import {useCallback, useContext, useEffect, useState} from 'react';
import {Shipment} from '../ShipmentInterfaces';
import {ManifestLpn} from '../../loads/ManifestInterfaces';
import {ShipmentDetailsContext} from './ShipmentDetailsContext';

export const useManifestLpns = (
  shipment: Shipment,
  isFreightTrailerLoadingEnabled: boolean,
  setTrailerLoadedState: (isTrailerLoaded: boolean) => void = null
) => {
  const {manifestContentsService, setErrors} = useContext(ShipmentDetailsContext);
  const [manifestLpns, setManifestLpns] = useState<ManifestLpn[]>([]);
  const [hasFetched, setHasFetched] = useState(false);

  const searchAndSetManifestLpnsAsync = useCallback(async () => {
    let continuationToken = null;
    const lpns: ManifestLpn[] = [];
    do {
      const resp = await manifestContentsService.searchManifestContentsByShipment({
        continuationToken,
        filters: {
          reservationId: shipment.reservation.id,
          shipmentId: shipment.id
        },
        pageSize: 100
      });

      if (resp.errors.length > 0) {
        setErrors((errors) => [...errors, ...resp.errors.map(() => 'Unable to fetch manifests.')]);
        return;
      }

      lpns.push(...resp.data.manifest_lpns);

      if (lpns.length > 0 && setTrailerLoadedState) {
        setTrailerLoadedState(true);
      }
      continuationToken = resp.data.continuation_token;
    } while (continuationToken);

    setHasFetched(true);
    setManifestLpns(lpns);
  }, [manifestContentsService, setErrors, setHasFetched, shipment.id, shipment.reservation.id]);

  useEffect(() => {
    if (!isFreightTrailerLoadingEnabled) {
      return;
    }

    searchAndSetManifestLpnsAsync();
  }, [isFreightTrailerLoadingEnabled, searchAndSetManifestLpnsAsync]);

  return {
    hasFetched,
    manifestLpns
  };
};
