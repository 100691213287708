import * as React from 'react';

export interface TypeAheadOption {
  value: string;
  displayName: string;
}

interface TypeAheadProps {
  name: string;
  options: TypeAheadOption[];
  placeholder: string;
  value: string;
  disabled?: boolean;
  dropdownCss?: string;
  typeaheadCss?: string;
  testId?: string;
  onRequestOptions(query);
  onSelect(value);
}

class TypeAhead extends React.Component<TypeAheadProps> {
  public render() {
    const {name, options, placeholder, value, disabled} = this.props;

    const dropdownCss = this.props.dropdownCss === undefined ? '' : this.props.dropdownCss;
    const typeaheadCss = this.props.typeaheadCss === undefined ? '' : this.props.typeaheadCss;

    const dropdownClass = `dropdown-menu show ${dropdownCss}`;
    const typeAheadOptions =
      options.length > 0 ? (
        <ul className={dropdownClass}>
          {options.map((option, idx) => {
            return (
              <li key={idx}>
                <a data-value={option.value} onClick={this.handleOptionSelect}>
                  {option.displayName}
                </a>
              </li>
            );
          })}
        </ul>
      ) : null;

    const typeaheadClass = `typeahead-component ${typeaheadCss}`;
    return (
      <div className={typeaheadClass}>
        <input
          name={name}
          data-testid={this.props.testId}
          disabled={disabled}
          autoComplete="off"
          className="form-control"
          type="text"
          value={value}
          onChange={this.handleTyping}
          placeholder={placeholder}
        />
        {typeAheadOptions}
      </div>
    );
  }

  private handleTyping = (event) => {
    const query = event.target.value;
    this.props.onRequestOptions(query);
  };

  private handleOptionSelect = (event) => {
    const value = event.currentTarget.getAttribute('data-value');
    this.props.onSelect(value);
  };
}

export default TypeAhead;
