import * as React from 'react';
import {FC} from 'react';
import OutboundOrdersService from '../OutboundOrdersService';

interface Props {
  outboundOrdersService: OutboundOrdersService;
  showOrderCancellationByCsvButton: boolean;
  toggleModal: () => void;
}

const BulkCancelOrdersModalButton: FC<Props> = (props) => {
  return (
    <>
      {(props.showOrderCancellationByCsvButton ||
        (props.outboundOrdersService && props.outboundOrdersService.isAdmin())) && (
        <button className="pull-right match-other-btns" onClick={props.toggleModal}>
          Cancel Orders/Lines by CSV
        </button>
      )}
    </>
  );
};
export default BulkCancelOrdersModalButton;
