import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import RetailFulfillmentService from '../shared/services/RetailFulfillmentService';
import {ApiResponse} from '../shared/CommonInterfaces';

interface BulkCancelModalProps {
  showModal: boolean;
  toggleModal: () => void;
  selectedOrderIds: Set<string>;
  retailFulfillmentService: RetailFulfillmentService;
  onSuccess: () => void;
  onError: (errors: string[]) => void;
}

const BulkCancelModal: React.FC<BulkCancelModalProps> = (props) => {
  const [cancelling, setCancelling] = React.useState(false); //prevent double clicks

  async function cancelOrders() {
    setCancelling(true);
    const orderIds: string[] = Array.from(props.selectedOrderIds);
    const response: ApiResponse<any> = await props.retailFulfillmentService.cancelOrders(orderIds);
    setCancelling(false);
    props.toggleModal();

    if (response.errors) {
      props.onError(response.errors.map((error) => error.detail));
      return;
    }

    props.onSuccess();
  }

  const footer = (
    <div id="bulk-cancel-modal-footer">
      <button id="bulk-cancel-modal-confirm-button" onClick={cancelOrders} disabled={cancelling}>
        Confirm Cancellation
      </button>
    </div>
  );

  return (
    <div>
      <LegacyModal
        id="bulk-cancel-modal"
        title="Cancel Shipments"
        size="small"
        show={props.showModal}
        toggleModal={props.toggleModal}
        footer={footer}
      >
        <p>Are you sure you would like to cancel the selected orders?</p>
      </LegacyModal>
    </div>
  );
};

export default BulkCancelModal;
