import * as React from 'react';
import {Destination} from '../../../warehouse/outbound-shipments/ShipmentInterfaces';
import {buildTextInput, getCityLabel, getPostalCodeLabel} from '../../../../libs/helpers';
import CountrySelect from '../../../shared/addresses/CountrySelect';
import RegionSelect from '../../../shared/addresses/RegionSelect';
import {FreightShipMode} from '../../../shared/constants';

export interface Props {
  onSelectShipmentType: (t: string) => void;
  onSetDestination: (d: Destination) => void;
  destination: Destination;
}

const DestinationDetails: React.FC<Props> = (props) => {
  const {
    name,
    address_line_1, // eslint-disable-line @typescript-eslint/naming-convention
    address_line_2, // eslint-disable-line @typescript-eslint/naming-convention
    city,
    state,
    country,
    postal_code, // eslint-disable-line @typescript-eslint/naming-convention
    email = '',
    phone = ''
  } = props.destination;

  const shipmentTypeOptions = [
    <option id={'1'} key={'freight_1'} value={FreightShipMode}>
      Freight
    </option>
  ];

  // Reminder if adding to the field type:
  //  field values must match their name in the Destination object
  const onDestinationFieldChange = (
    newValue: string,
    field:
      | 'name'
      | 'address_line_1'
      | 'address_line_2'
      | 'country'
      | 'city'
      | 'state'
      | 'postal_code'
      | 'phone'
      | 'email'
  ) => {
    const newDestination = {...props.destination};
    newDestination[field] = newValue;

    if (field === 'country') {
      newDestination.state = '';
    }
    props.onSetDestination(newDestination);
  };

  const cityLabel = `*${getCityLabel(country, true)}`;
  const postalCodeLabel = `*${getPostalCodeLabel(country, true)}`;
  return (
    <>
      <div id="origin-and-destination" className="form-step">
        <h2>1. Destination</h2>
        <div className="col-sm-6 inner">
          <h3>To Destination:</h3>
          {buildTextInput('*Name', name, (v) => onDestinationFieldChange(v, 'name'), 'Name of recipient')}
          {buildTextInput('*Address Line 1', address_line_1, (v) => onDestinationFieldChange(v, 'address_line_1'))}
          {buildTextInput('Address Line 2', address_line_2 || '', (v) => onDestinationFieldChange(v, 'address_line_2'))}
          <CountrySelect
            selectedCountryAbbr={country}
            onCountryAbbrChange={(newValue) => onDestinationFieldChange(newValue, 'country')}
          />
          <div>
            <div className="col-sm-4 inner">
              {buildTextInput(cityLabel, city, (v) => onDestinationFieldChange(v, 'city'))}
            </div>
            <div className="col-sm-4">
              <RegionSelect
                countryAbbr={country}
                selectedRegionAbbr={state}
                onRegionAbbrChange={(v) => onDestinationFieldChange(v, 'state')}
              />
            </div>
            <div className="col-sm-4 outer">
              {buildTextInput(
                postalCodeLabel,
                postal_code,
                (v) => onDestinationFieldChange(v, 'postal_code'),
                null,
                10
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-6 outer">
          <h3>Contact Info:</h3>
          {buildTextInput('Phone Number', phone, (v) => onDestinationFieldChange(v, 'phone'))}
          {buildTextInput('Email Address', email, (v) => onDestinationFieldChange(v, 'email'))}
          <h3>Shipment Type:</h3>
          <select name="shipmentType" onChange={(event) => props.onSelectShipmentType(event.target.value)}>
            {shipmentTypeOptions}
          </select>
        </div>
      </div>
      <hr />
    </>
  );
};

export default DestinationDetails;
