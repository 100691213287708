// Version for use in the <Filter /> component
import {addDays, parse as parseDate} from 'date-fns';
import {FilterType, FilterValue} from '../../../shared/CommonInterfaces';

export interface FilterDefinition {
  displayName: string;
  key: string;
  type: FilterType;
  allowMultiple?: boolean;
  defaultKey?: string;
}

export interface InputFilters {
  [index: string]: any; // TODO It would be really nice to change this from any, but hard to do because <Filter is shared
}
// Version to use for querying OMS
export interface ApiFilters {
  [index: string]: any; // This is more possible to type, but it gets tricky due to multiple values
}
// Naming is a little janky because the shared component uses FilterValue already
export interface TrackedFilterValues {
  // It's a little odd at first to track these side by side like this,
  //  instead of one set of indexed objects, but it makes it easier
  //  to make API calls this way
  inputFilters: InputFilters;
  apiFilters: ApiFilters;
}

export const makeBlankFilters = (): TrackedFilterValues => ({
  inputFilters: {},
  apiFilters: {}
});

export const filterKeyCreatedStarting = 'createdAtFrom';
export const getDefaultCreatedAtFilters = () => {
  // Grab now and move back a month and clear the time
  const timestamp = new Date();
  timestamp.setMonth(timestamp.getMonth() - 1);
  timestamp.setHours(0, 0, 0, 0);
  return [timestamp.toLocaleDateString(), timestamp.toISOString()];
};

export const getFilterDisplayValue = (filters: TrackedFilterValues, key: string): any => {
  if (!filters || !filters.inputFilters[key]) {
    return null;
  }

  // TODO stop storing hasErrors as a boolean
  if (key === 'hasErrors') {
    return filters.inputFilters[key] === true ? 'true' : 'false';
  }

  return filters.inputFilters[key];
};

export const extractNewFilters = (filters: FilterValue[]): TrackedFilterValues => {
  const resultingInputFilters: InputFilters = {};
  const resultingApiFilters: ApiFilters = {};

  filters.forEach((filterValue) => {
    const filter = filterValue.filter;
    const key = filter.key;
    const value = filterValue.value;

    if (key === 'hasErrors') {
      resultingInputFilters[key] = value === 'true';
      resultingApiFilters[key] = value;
    } else if (filter.allowMultiple) {
      let apiValue = value;
      const isLabel = key === 'labels';
      if (isLabel) {
        // We enforce this in the API repo, so we should display what we searched for
        value.labelKey = `${value.labelKey}`.trim().toLowerCase();
        apiValue = `${value.labelKey},${value.labelValue}`;
      }

      if (resultingInputFilters[key]) {
        const idx = resultingInputFilters[key].findIndex((element) => {
          if (isLabel) {
            return element.labelKey === value.labelKey;
          } else {
            return element === apiValue;
          }
        });
        if (idx < 0) {
          resultingApiFilters[key].push(apiValue);
          resultingInputFilters[key].push(value);
        } else if (isLabel) {
          // Label searches use AND logic, so it only makes sense to take the last value
          //  (other multi values are simple objects that won't change)
          resultingApiFilters[key][idx] = apiValue;
          resultingInputFilters[key][idx] = value;
        }
      } else {
        resultingApiFilters[key] = [apiValue];
        resultingInputFilters[key] = [value];
      }
    } else if (['createdAt', 'shipAfter', 'shipBefore', 'closedAt'].includes(key)) {
      const parsedDate = parseDate(value as string);
      // Start
      let exactKey = `${key}From`;
      resultingInputFilters[exactKey] = parsedDate.toLocaleDateString();
      resultingApiFilters[exactKey] = parsedDate.toISOString();

      // End
      exactKey = `${key}To`;
      resultingInputFilters[exactKey] = parsedDate.toLocaleDateString();
      // See note below about inclusivity
      resultingApiFilters[exactKey] = addDays(parsedDate, 1).toISOString();
    } else if (['createdAt', 'shipAfter', 'shipBefore', 'closedAt', 'updatedAt'].some((f) => !!key.match(f))) {
      const parsedDate = parseDate(value as string);
      // Inclusive start, exclusive end so offset by 1 day
      const filterDate = ['createdAtTo', 'shipAfterTo', 'shipBeforeTo', 'closedAtTo', 'updatedAtTo'].includes(key)
        ? addDays(parsedDate, 1)
        : parsedDate;
      // Note we display the input date to be consistent
      resultingInputFilters[key] = parsedDate.toLocaleDateString();
      resultingApiFilters[key] = filterDate.toISOString();
    } else {
      resultingInputFilters[key] = value;
      resultingApiFilters[key] = value;
    }
  });

  return {apiFilters: resultingApiFilters, inputFilters: resultingInputFilters};
};

export const extractFiltersFromSearchParams = (
  searchParams: URLSearchParams,
  filterDefs: FilterDefinition[]
): TrackedFilterValues => {
  const filterValues = {...makeBlankFilters()};

  filterDefs.forEach((filterDef) => {
    const key = filterDef.key;
    const searchValues = searchParams.getAll(key);
    const convertedValues = extractFiltersFromSearchParam(searchValues, filterDef);
    if (convertedValues) {
      filterValues.inputFilters[key] = convertedValues[0];
      filterValues.apiFilters[key] = convertedValues[1];
    }
  });

  return filterValues;
};

const extractFiltersFromSearchParam = (
  searchValues: string[],
  filterDef: FilterDefinition
): [any, string | string[]] | undefined => {
  if (searchValues.length < 1) {
    return undefined;
  }

  if (!filterDef.allowMultiple) {
    // For single values, just get the first from the query since multiple values mean someone is doing something weird
    const vals = searchParamToFilterValues(searchValues[0], filterDef);
    return vals && vals[0] && vals[1] ? [vals[0], vals[1]] : undefined;
  }

  //*** Handle multiple values
  const pairs = searchValues
    .map((v) => searchParamToFilterValues(v, filterDef))
    .filter((vals) => vals && vals[0] && vals[1]);

  // Ensure we don't set keys if we don't have any valid values
  if (pairs.length < 1) {
    return undefined;
  }

  const displayVals = pairs.map((v) => v[0]);
  const apiVals = pairs.map((v) => v[1]);
  return [displayVals, apiVals];
};

const searchParamToFilterValues = (searchValue: string, filterDef: FilterDefinition): [any, string] | undefined => {
  const filterType = filterDef.type;
  const apiValue = searchValue;
  let displayValue: any;

  if (filterType === FilterType.LabelSearch) {
    const splitValues = apiValue.split(',');
    if (splitValues.length === 2 && splitValues[0] && splitValues[1]) {
      displayValue = {labelKey: splitValues[0], labelValue: splitValues[1]};
    }
  } else if (filterType === FilterType.Date) {
    try {
      const date = new Date(apiValue);
      displayValue = date.toLocaleDateString();
    } catch (e) {
      /* empty */
    }
  } else if (filterType === FilterType.Present) {
    if (apiValue === 'true' || apiValue === 'false') {
      displayValue = apiValue === 'true';
    }
  } else {
    displayValue = apiValue;
  }

  return apiValue && displayValue ? [displayValue, apiValue] : undefined;
};

export const getSearchParamsFromFilters = (
  filterValues: TrackedFilterValues,
  filterDefs: FilterDefinition[]
): URLSearchParams => {
  const searchParams = new URLSearchParams();

  Object.keys(filterValues.apiFilters).forEach((key) => {
    const value = filterValues.apiFilters[key];
    // Realistically there won't be many filters, so just looking through the array instead of map
    const filterDef = filterDefs.find((def) => def.key === key);

    if (filterDef && filterDef.allowMultiple) {
      (value as string[]).forEach((v) => searchParams.append(key, v));
    } else if (filterDef) {
      searchParams.set(key, value as string);
    }
  });

  return searchParams;
};

export const orderFilterDefs: FilterDefinition[] = [
  {
    displayName: 'External Order ID',
    key: 'externalIds',
    type: FilterType.String,
    allowMultiple: true
  },
  {
    displayName: 'Flexe Order ID',
    key: 'ids',
    type: FilterType.String,
    allowMultiple: true
  },
  {
    displayName: 'Created (Exact)',
    key: 'createdAt',
    type: FilterType.Date
  },
  {
    displayName: 'Created Ending',
    key: 'createdAtTo',
    type: FilterType.Date
  },
  {
    displayName: 'Created Starting',
    key: filterKeyCreatedStarting,
    type: FilterType.Date
  },
  {
    displayName: 'Issues Only',
    key: 'hasErrors',
    type: FilterType.Present
  },
  {
    displayName: 'Labels',
    key: 'labels',
    type: FilterType.LabelSearch,
    allowMultiple: true
  },
  {
    displayName: 'Order Status',
    key: 'state',
    type: FilterType.Dropdown
  },
  {
    displayName: 'Order Type',
    key: 'orderType',
    type: FilterType.Dropdown
  },
  {
    displayName: 'Reservation',
    key: 'reservationIds',
    type: FilterType.Dropdown,
    allowMultiple: true
  },
  {
    displayName: 'Updated Ending',
    key: 'updatedAtTo',
    type: FilterType.Date
  },
  {
    displayName: 'Updated Starting',
    key: 'updatedAtFrom',
    type: FilterType.Date
  }
];

export const orderLineFilterDefs: FilterDefinition[] = [
  {
    displayName: 'External Line ID',
    key: 'externalIds',
    type: FilterType.String,
    allowMultiple: true
  },
  {
    displayName: 'Flexe Line ID',
    key: 'lineIds',
    type: FilterType.String,
    allowMultiple: true
  },
  {
    displayName: 'Flexe Order ID',
    key: 'orderIds',
    type: FilterType.String,
    allowMultiple: true
  },
  {
    displayName: 'Created Ending',
    key: 'createdAtTo',
    type: FilterType.Date
  },
  {
    displayName: 'Created Starting',
    key: filterKeyCreatedStarting,
    type: FilterType.Date
  },
  {
    displayName: 'Issues Only',
    key: 'hasErrors',
    type: FilterType.Present
  },
  {
    displayName: 'Labels',
    key: 'labels',
    type: FilterType.LabelSearch,
    allowMultiple: true,
    defaultKey: ''
  },
  {
    displayName: 'Reservation',
    key: 'reservationIds',
    type: FilterType.Dropdown,
    allowMultiple: true
  },
  {
    displayName: 'SKU',
    key: 'skus',
    type: FilterType.String,
    allowMultiple: true
  }
];
