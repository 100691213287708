/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import {useEffect, useState} from 'react';
import tokens from '@flexe/ui-tokens';
import {jsx} from '@emotion/react';
import {Button, Icon, InfoBox, Modal, Text, TextInput, useTable} from '@flexe/ui-components';
import axios from 'axios';
import {get} from 'lodash';
import TypeAhead, {TypeAheadOption} from '../../shared/TypeAhead';
import {Inventory, Item} from '../shared/DropoffInterfaces';
import {ApiResponse, Packaging} from '../../shared/CommonInterfaces';
import {renderItemLink} from '../../../libs/helpers';
import ContainersService, {AddItemsRequest} from '../../shared/services/ContainersService';

export interface AddInventoryModalProps {
  reservationId: number;
  isOpen: boolean;
  toggleModal: () => void;
  handleRefreshPage: () => void;
  handleApiError: (errTitle: string, response: ApiResponse<any>) => void;
  hasInnerOuterUoms: boolean;
  shipperCompanyId: number;
  containerDeliveryId: number;
  containersService: ContainersService;
}

interface InventoryProperties {
  quantity: number;
  packaging: string;
  purchaseOrder?: string;
  eachesPerInnerPack?: number;
  eachesPerOuterCase?: number;
}

const {tableStyles} = useTable({
  zebraStripe: true
});
export const AddInventoryModal: React.FC<AddInventoryModalProps> = (props) => {
  const {
    isOpen,
    toggleModal,
    reservationId,
    hasInnerOuterUoms,
    containersService,
    shipperCompanyId,
    containerDeliveryId,
    handleRefreshPage,
    handleApiError
  } = props;
  const [headers, setHeaders] = useState([
    'SKU',
    'Description',
    'Purchase Order',
    'Qty',
    'Unit of Measure',
    'Total',
    ''
  ]);
  const [skuQuery, setSkuQuery] = useState('');
  const [typeAheadOptions, setTypeAheadOptions] = useState<TypeAheadOption[]>([]);
  const [inventoryResponse, setInventoryResponse] = useState<Inventory[]>();
  const [packagingOptions, setPackingOptions] = useState<string[]>([]);
  //an array of inventories to add. this is an array of Inventory objects from the query API response
  const [inventoriesToAdd, setInventoriesToAdd] = useState<Inventory[]>([]);
  // a map of inventory id and inventory props entered by the user. Mapped based on inventoryesToAdd.
  // it used to build up request data to call the API to add inventories to the container delivery.
  const [inventoryPropMap, setInventoryPropMap] = useState(new Map<number, InventoryProperties>());
  const [isEverythingEntered, setIsEverythingEntered] = useState(false);
  const [isDuplicateSku, setIsDuplicateSku] = useState(false);

  useEffect(() => {
    setupHeaders();
  }, []);

  useEffect(() => {
    handleEnableAddButton();
  }, [inventoryPropMap]);

  useEffect(() => {
    handleEnableAddButton();
  }, [isEverythingEntered]);

  useEffect(() => {
    initializeInventoryPropMap();
  }, [inventoriesToAdd]);

  const handleEnableAddButton = () => {
    let enableAddInvButton = true;
    if (inventoriesToAdd.length === 0 || inventoryPropMap.size === 0) {
      setIsEverythingEntered(false);
      return;
    }
    if (hasInnerOuterUoms) {
      inventoryPropMap.forEach((inventoryProp) => {
        if (
          !inventoryProp.quantity ||
          !inventoryProp.purchaseOrder ||
          !inventoryProp.eachesPerInnerPack ||
          !inventoryProp.eachesPerOuterCase
        ) {
          enableAddInvButton = false;
        }
      });
    } else {
      inventoryPropMap.forEach((inventoryProp) => {
        if (!inventoryProp.quantity || !inventoryProp.packaging || !inventoryProp.purchaseOrder) {
          enableAddInvButton = false;
        }
      });
    }
    setIsEverythingEntered(enableAddInvButton);
  };

  const handleTypeAheadQuery = async (query) => {
    setSkuQuery(query);
    if (query.length > 2) {
      try {
        const response = await axios.get('/widgets/inventory_picker', {
          params: {
            q: query,
            reservation_id: reservationId,
            include_exact_match: true
          }
        });
        const invResponse = get(response, 'data.matches', []);
        const options = invResponse.map((inv) => {
          return {
            value: inv.sku,
            displayName: `${inv.sku} - ${inv.description}`
          };
        });
        setTypeAheadOptions(options);
        setInventoryResponse(invResponse);
      } catch (error) {
        //TODO handle error
      }
    } else {
      setTypeAheadOptions([]);
      setInventoryResponse([]);
    }
  };

  const setupHeaders = () => {
    const headersCopy = [...headers];
    if (hasInnerOuterUoms) {
      headersCopy.forEach((header, idx) => {
        if (header === 'Qty') {
          headersCopy.splice(idx, 1);
        }
      });
      headersCopy.splice(3, 0, 'Outer Case Qty');
    }
    setHeaders(headersCopy);
  };

  const initializeInventoryPropMap = () => {
    const newInventoryMap = new Map<number, InventoryProperties>(
      JSON.parse(JSON.stringify(Array.from(inventoryPropMap)))
    );
    inventoriesToAdd.forEach((inv) => {
      if (!inventoryPropMap.has(inv.id)) {
        newInventoryMap.set(inv.id, {
          quantity: null,
          packaging: Packaging.each,
          purchaseOrder: '',
          eachesPerInnerPack: null,
          eachesPerOuterCase: null
        });
      }
    });
    setInventoryPropMap(newInventoryMap);
  };

  const handleTypeAheadSelect = (sku) => {
    const selected = inventoryResponse.find((inv) => inv.sku === sku);
    const prevSelected = inventoriesToAdd.find((inv) => inv.sku === sku);
    if (prevSelected && !hasInnerOuterUoms) {
      setIsDuplicateSku(true);
      return;
    } else {
      setIsDuplicateSku(false);
    }
    setSkuQuery('');
    const updatedInventoriesToAdd = [...inventoriesToAdd];
    if (selected) {
      updatedInventoriesToAdd.push(selected);
      setInventoriesToAdd(updatedInventoriesToAdd);
    }
    const packagingTypes = [Packaging.each, Packaging.carton];
    setPackingOptions(packagingTypes);
    setTypeAheadOptions([]);
  };

  const onTrashCanIconClicked = (index: number) => {
    const newInventoryMap = new Map<number, InventoryProperties>(
      JSON.parse(JSON.stringify(Array.from(inventoryPropMap)))
    );
    newInventoryMap.delete(inventoriesToAdd[index].id);
    setInventoryPropMap(newInventoryMap);
    const inventoriesToAddCopy = [...inventoriesToAdd];
    inventoriesToAddCopy.splice(index, 1);
    setInventoriesToAdd(inventoriesToAddCopy);
  };

  const calculateTotalEachQty = ({qty, packaging, unitConversion, hasInnerOuter, eachesPerOuterCase}) => {
    if (hasInnerOuter) {
      const eachQty = qty * eachesPerOuterCase;
      return <Text>{eachQty + ' eaches'}</Text>;
    } else {
      if (packaging === Packaging.carton && unitConversion) {
        const eachQty = qty * unitConversion.carton.each;
        return <Text>{eachQty + (eachQty > 1 ? ' eaches' : ' each')}</Text>;
      } else {
        return <Text>{qty + (qty > 1 ? ' eaches' : ' each')}</Text>;
      }
    }
  };

  const handleInventoryMapUpdate = (inventoryId, newInventoryProperties: InventoryProperties) => {
    const newInventoryMap = new Map<number, InventoryProperties>(
      JSON.parse(JSON.stringify(Array.from(inventoryPropMap)))
    );
    newInventoryMap.set(inventoryId, newInventoryProperties);
    setInventoryPropMap(newInventoryMap);
  };

  const onAddButtonClicked = async () => {
    const request = buildAddItemsRequest();
    const response = await containersService.addItems(request);
    if (response.errors.length > 0) {
      handleApiError('Error when attempting to add new items to the container delivery', response);
    }
    setInventoriesToAdd([]);
    handleRefreshPage();
  };

  const buildAddItemsRequest = () => {
    const itemsToAdd: Item[] = [];
    inventoryPropMap.forEach((inv, invId) => {
      const item: Item = {
        id: invId,
        quantity: hasInnerOuterUoms ? inv.quantity * inv.eachesPerOuterCase : inv.quantity,
        packaging: inv.packaging,
        purchaseOrder: inv?.purchaseOrder
      };
      if (inv.eachesPerInnerPack && inv.eachesPerOuterCase) {
        item.uoms = {
          eachesPerInnerPack: inv?.eachesPerInnerPack,
          eachesPerOuterCase: inv?.eachesPerOuterCase
        };
      }
      itemsToAdd.push(item);
    });
    const newAddItemsRequest: AddItemsRequest = {
      companyId: shipperCompanyId,
      containerDeliveryId,
      items: itemsToAdd
    };
    return newAddItemsRequest;
  };

  return (
    <Modal isOpen={isOpen} toggleModal={toggleModal} title={'Add Inventory to this Delivery'}>
      <div>
        {isDuplicateSku && (
          <InfoBox infoType={'error'} info={'This SKU has already been added. Please choose a different one. '} />
        )}
        <Text color={tokens?.color.brand.asphalt.v50.value}>Search Inventory</Text>
        <div className="form-group">
          <TypeAhead
            name="sku_select"
            placeholder="Type to find item or SKU, then select to add"
            value={skuQuery}
            options={typeAheadOptions}
            onRequestOptions={handleTypeAheadQuery}
            onSelect={handleTypeAheadSelect}
            dropdownCss="typeahead-dropdown-menu"
          />
        </div>
        <table {...tableStyles}>
          <thead>
            <tr>
              {headers.map((header: any) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {inventoriesToAdd.map((inventory: Inventory, index) => {
              if (inventory) {
                return (
                  <tr key={index}>
                    <td>{renderItemLink(inventory.id, inventory.sku, true)}</td>
                    <td>{inventory.description}</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={inventoryPropMap.get(inventory.id)?.purchaseOrder || ''}
                        onChange={(newPo) =>
                          handleInventoryMapUpdate(inventory.id, {
                            quantity: inventoryPropMap.get(inventory.id)?.quantity,
                            packaging: inventoryPropMap.get(inventory.id)?.packaging,
                            purchaseOrder: newPo.target.value,
                            eachesPerInnerPack: inventoryPropMap.get(inventory.id)?.eachesPerInnerPack,
                            eachesPerOuterCase: inventoryPropMap.get(inventory.id)?.eachesPerOuterCase
                          })
                        }
                      />
                    </td>

                    <td>
                      <input
                        type="number"
                        className="form-control"
                        value={inventoryPropMap.get(inventory.id)?.quantity?.toString() || ''}
                        min={1}
                        onChange={(newQty) =>
                          handleInventoryMapUpdate(inventory.id, {
                            quantity: Number(newQty.target.value),
                            packaging: inventoryPropMap.get(inventory.id)?.packaging,
                            purchaseOrder: inventoryPropMap.get(inventory.id)?.purchaseOrder,
                            eachesPerInnerPack: inventoryPropMap.get(inventory.id)?.eachesPerInnerPack,
                            eachesPerOuterCase: inventoryPropMap.get(inventory.id)?.eachesPerOuterCase
                          })
                        }
                      />
                    </td>
                    <td className="no-break-line">
                      {hasInnerOuterUoms ? (
                        <div>
                          <div className="inner-outer-container">
                            <input
                              type="number"
                              className="form-control"
                              min={1}
                              value={inventoryPropMap.get(inventory.id)?.eachesPerInnerPack?.toString() || ''}
                              onChange={(newEachesPerInner) =>
                                handleInventoryMapUpdate(inventory.id, {
                                  quantity: inventoryPropMap.get(inventory.id)?.quantity,
                                  packaging: inventoryPropMap.get(inventory.id)?.packaging,
                                  purchaseOrder: inventoryPropMap.get(inventory.id)?.purchaseOrder,
                                  eachesPerInnerPack: Number(newEachesPerInner.target.value),
                                  eachesPerOuterCase: inventoryPropMap.get(inventory.id)?.eachesPerOuterCase
                                })
                              }
                            />
                            <div className="inner-outer-text">
                              <Text>each / inner pack</Text>
                            </div>
                          </div>
                          <div className="inner-outer-container">
                            <input
                              type="number"
                              className="form-control"
                              min={1}
                              value={inventoryPropMap.get(inventory.id)?.eachesPerOuterCase?.toString() || ''}
                              onChange={(newEachesPerOuter) =>
                                handleInventoryMapUpdate(inventory.id, {
                                  quantity: inventoryPropMap.get(inventory.id)?.quantity,
                                  packaging: inventoryPropMap.get(inventory.id)?.packaging,
                                  purchaseOrder: inventoryPropMap.get(inventory.id)?.purchaseOrder,
                                  eachesPerInnerPack: inventoryPropMap.get(inventory.id)?.eachesPerInnerPack,
                                  eachesPerOuterCase: Number(newEachesPerOuter.target.value)
                                })
                              }
                            />
                            <div className="inner-outer-text">
                              <Text>each / outer case</Text>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <select
                          className="form-control"
                          onChange={(newPackaging) =>
                            handleInventoryMapUpdate(inventory.id, {
                              quantity: inventoryPropMap.get(inventory.id)?.quantity,
                              packaging: newPackaging.target.value,
                              purchaseOrder: inventoryPropMap.get(inventory.id)?.purchaseOrder
                            })
                          }
                          defaultValue={Packaging.each}
                          value={inventoryPropMap.get(inventory.id)?.packaging}
                        >
                          {packagingOptions.map((packaging) => (
                            <option key={packaging} value={packaging}>
                              {packaging}
                            </option>
                          ))}
                        </select>
                      )}
                    </td>
                    <td className="no-break-line">
                      {calculateTotalEachQty({
                        qty: inventoryPropMap.get(inventory.id)?.quantity || 0,
                        packaging: inventoryPropMap.get(inventory.id)?.packaging,
                        unitConversion: inventory.unit_conversions,
                        hasInnerOuter: hasInnerOuterUoms,
                        eachesPerOuterCase: inventoryPropMap.get(inventory.id)?.eachesPerOuterCase || 0
                      })}
                    </td>
                    <td>
                      <Button testid="trash-can-button" visualType="icon" onPress={() => onTrashCanIconClicked(index)}>
                        <Icon icon="trash"></Icon>
                      </Button>
                    </td>
                  </tr>
                );
              } else {
                return null;
              }
            })}
          </tbody>
        </table>
        <div className="add-inventory-confirm-button">
          <Button visualType="primary" isDisabled={!isEverythingEntered} onPress={onAddButtonClicked}>
            Add Additional Inventory
          </Button>
        </div>
      </div>
    </Modal>
  );
};
