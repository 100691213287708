import * as React from 'react';

interface Props {
  showModal: boolean;
  zendeskLink: string;
  linkDescription: string;
}

export const DocumentationTooltip: React.FC<Props> = (props) => {
  if (props.showModal) {
    return (
      <div className="documentation-tooltip">
        <div className="tooltip-title">
          <a target="_blank" href={props.zendeskLink}>
            <i className="fa fa-sm fa-play-circle" title={props.linkDescription} /> {props.linkDescription}
          </a>
        </div>
        <div className="tooltip-subtitle">
          <p>
            Not what you are looking for?
            <a target="_blank" href="https://flexesupport.zendesk.com/hc/en-us">
              {' '}
              Go to zendesk homepage
            </a>
          </p>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

export default DocumentationTooltip;
