import * as React from 'react';
import {useEffect} from 'react';
import * as ReactTooltip from 'react-tooltip';
import RetailFulfillmentService, {
  RetailFulfillmentCreateRequest
} from '../../../shared/services/RetailFulfillmentService';
import {ResponseError, SelectableWarehouse} from '../../../shared/CommonInterfaces';
import InventorySelection, {
  CsvInvUploadExample,
  INV_CSV_COLUMNS_SPEC,
  Line,
  SampleInvCsvPathRetailFulfillment
} from '../../shared-v2/InventorySelection';
import SelectWarehouse from './SelectWarehouse';
import ShipmentDetails, {RetailShipmentDetails} from './ShipmentDetails';
import ShippingInformation, {RetailShippingInformation} from './ShippingInformation';
import Complete from './Complete';

export interface DisplayedAttribute {
  displayName: string;
  value: string;
}

interface Props {
  authenticityToken: string;
  warehouses: SelectableWarehouse[];
  routingDetailsOptions: DisplayedAttribute[];
  shipmentTypeOptions: DisplayedAttribute[];
  shipmentMethodOptions: DisplayedAttribute[];
  carrierOptions: DisplayedAttribute[];
  showNewInventoryCsvModal: boolean;

  //maps carriers to a list of service types. carriers are limited to carrierOptions
  carrierServiceOptions: Map<string, string[]>;

  //maps carriers to a list of billing accounts. carriers are limited to carrierOptions
  carrierBillingAccounts: Map<string, DisplayedAttribute[]>;
}

const NewRetailFulfillmentOrder: React.FC<Props> = (props) => {
  //Order Details
  const [shipmentDetails, setShipmentDetails] = React.useState<RetailShipmentDetails>({
    purchaseOrder: '',
    bolNumber: '',
    routingDetailsId: null,
    shipmentType: '',
    shipmentMethod: '',
    carrier: '',
    serviceType: '',
    carrierBillingAccountId: null,
    routeBy: null,
    shipByStart: null,
    shipByEnd: null
  });

  const [shippingInformation, setShippingInformation] = React.useState<RetailShippingInformation>({
    shipTo: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    phone: ''
  });

  const [selectedWarehouse, setSelectedWarehouse] = React.useState<SelectableWarehouse>(null);
  const [showCompleteForm, setShowCompleteForm] = React.useState(false);
  const [selectedInventory, setSelectedInventory] = React.useState<Line[]>([]);
  const [specialInstructions, setSpecialInstructions] = React.useState<string>('');
  const [formComplete, setFormComplete] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [processingRequest, setProcessingRequest] = React.useState<boolean>(false);

  const retailFulfillmentService = new RetailFulfillmentService(props.authenticityToken);
  const resultRef = React.useRef(null);

  useEffect(() => {
    checkFormCompletion();
  }, [selectedInventory, shippingInformation, shipmentDetails]);

  useEffect(() => {
    if (errors.length > 0) {
      resultRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [errors]);

  const onSelectedWarehouse = (selectableWarehouse: SelectableWarehouse) => {
    setSelectedWarehouse(selectableWarehouse);
    setShowCompleteForm(!selectableWarehouse.omniEnabled);
  };

  const onShipmentDetailChange = (name, value) => {
    setShipmentDetails((prevState) => ({...prevState, [name]: value}));
    setErrors([]);
  };

  const onShipmentDetailInputEvent = (event) => {
    const {name, value} = event.target;
    onShipmentDetailChange(name, value);
  };

  const onShippingInformationInputEvent = (event) => {
    const {name, value} = event.target;
    setShippingInformation((prevState) => ({...prevState, [name]: value}));
    setErrors([]);
  };

  const onAddInventory = (items: Line[]) => {
    setSelectedInventory(selectedInventory.concat(items));
    setErrors([]);
  };

  const onEditInventory = (item: Line) => {
    const lines = [...selectedInventory];
    const lineIndex = lines.indexOf(lines.find((it) => it.inventoryId === item.inventoryId));
    lines[lineIndex] = item;
    setSelectedInventory(lines);
    setErrors([]);
  };

  const onDeleteInventory = (inventoryId: number) => {
    const lines = [...selectedInventory];
    const lineIndex = lines.indexOf(lines.find((it) => it.inventoryId === inventoryId));
    lines.splice(lineIndex, 1);
    setSelectedInventory(lines);
    setErrors([]);
  };

  const onSpecialInstructionsInput = (instructions) => {
    setSpecialInstructions(instructions);
    setErrors([]);
  };

  const checkFormCompletion = () => {
    // eslint-disable-next-line
    const requiredFields: (string | Line[] | Date | number)[] = [
      shipmentDetails.purchaseOrder,
      shipmentDetails.bolNumber,
      shipmentDetails.shipmentType,
      shipmentDetails.shipmentMethod,
      shipmentDetails.shipByStart,
      shipmentDetails.shipByEnd,
      shippingInformation.shipTo,
      shippingInformation.addressLine1,
      shippingInformation.city,
      shippingInformation.state,
      shippingInformation.postalCode,
      selectedInventory
    ];

    if (shipmentDetails.shipmentType === 'parcel' && shipmentDetails.shipmentMethod === 'generated') {
      requiredFields.push(shipmentDetails.carrier);
      requiredFields.push(shipmentDetails.serviceType);
    } else if (shipmentDetails.shipmentMethod === 'vrs' && props.routingDetailsOptions.length > 0) {
      requiredFields.push(shipmentDetails.routingDetailsId);
    } else if (shipmentDetails.shipmentType === 'tbd') {
      const index = requiredFields.indexOf(shipmentDetails.shipmentMethod, 0);
      requiredFields.splice(index, 1);
    }

    const formIsComplete = requiredFields.every((field) => {
      if (!field) {
        return false;
      }
      switch (field.constructor.name) {
        case 'String':
          return (field as string).length > 0;
        case 'Array':
          return (field as []).length > 0;
        default:
          return true;
      }
    });

    setFormComplete(formIsComplete);
  };

  const onComplete = async () => {
    setErrors([]);
    setProcessingRequest(true);

    const request: RetailFulfillmentCreateRequest = {
      reservationId: selectedWarehouse.reservationId,
      shipmentDetails,
      shippingInformation,
      instructions: specialInstructions,
      inventory: selectedInventory.map((inv) => {
        return {
          sku: inv.sku,
          count: {
            amount: inv.quantity,
            unit: inv.packaging
          }
        };
      })
    };
    const response = await retailFulfillmentService.createOrder(request);

    if (response) {
      if (response.errors.length > 0) {
        setErrors(
          response.errors.map((e) => {
            return processError(e);
          })
        );
      } else {
        //redirect to completed shipment details page
        window.location.href = `/s/fulfillment/retail/${response.data.id}`;
      }
      setProcessingRequest(false);
    }
  };

  const processError = (error: ResponseError): string => {
    let detail = error.detail;
    if (error.source) {
      detail = detail.replace('value', `${error.source.pointer}`);
    }
    detail = detail.replace('must be an array', 'has not been selected');

    return `${error.title}: ${detail}`;
  };

  return (
    <div className="container-fluid" id="retail-new">
      <div>
        <div className="breadcrumbs delivery" ref={resultRef}>
          <a href="/s/fulfillment/retail">Fulfillment</a>
          <i className="fa fa-angle-right"></i>
          Retail
        </div>
        <h2>Request Retail Fulfillment Order</h2>

        {selectedWarehouse && selectedWarehouse.omniEnabled && (
          <div className="alert alert-warning" role="alert" key="omni-warning">
            <span>
              The reservation selected isn’t available to create a Retail Fulfillment Order. Please submit this request
              through <a href="/s/fulfillment/orders">Orders</a>
            </span>
          </div>
        )}

        {errors.map((error, idx) => {
          return (
            <div className="alert alert-danger" role="alert" key={`error:${idx}`}>
              {error}
            </div>
          );
        })}

        <ul className="pickup fulfillment" id="workflow-steps">
          <SelectWarehouse warehouses={props.warehouses} onSelect={(wh) => onSelectedWarehouse(wh)} />

          {showCompleteForm && (
            <ShipmentDetails
              shipmentDetails={shipmentDetails}
              routingDetailsOptions={props.routingDetailsOptions}
              shipmentTypeOptions={props.shipmentTypeOptions}
              shipmentMethodOptions={props.shipmentMethodOptions}
              carrierOptions={props.carrierOptions}
              carrierServiceOptions={props.carrierServiceOptions}
              carrierBillingAccountOptions={props.carrierBillingAccounts}
              onInput={(event) => onShipmentDetailInputEvent(event)}
              onChange={(name, value) => onShipmentDetailChange(name, value)}
            />
          )}

          {showCompleteForm && (
            <ShippingInformation
              shippingInformation={shippingInformation}
              onInput={(event) => onShippingInformationInputEvent(event)}
            />
          )}

          {showCompleteForm && (
            <li className="active" id="step_4">
              <span className="label">4</span>
              <div className="step-wrap">
                <h3>
                  <span>Select Inventory</span>
                  <a className="help-note" data-tip="Select inventory and specify quantities below.">
                    <i className="fa fa-lg fa-question-circle" />
                  </a>
                  <ReactTooltip place="right" />
                </h3>
              </div>
              <InventorySelection
                showNewModal={props.showNewInventoryCsvModal}
                authenticityToken={props.authenticityToken}
                selectedWarehouse={selectedWarehouse}
                csvFormat={INV_CSV_COLUMNS_SPEC}
                sampleCsvPath={SampleInvCsvPathRetailFulfillment}
                csvExample={CsvInvUploadExample}
                useNewUiComponentsModal={false}
                onAddInventory={onAddInventory}
                onEditInventory={onEditInventory}
                onDeleteInventory={onDeleteInventory}
              />
            </li>
          )}

          {showCompleteForm && (
            <Complete
              specialInstructions={specialInstructions}
              canComplete={formComplete}
              processing={processingRequest}
              onSpecialInstructionsInput={onSpecialInstructionsInput}
              onComplete={onComplete}
            />
          )}
        </ul>
      </div>
    </div>
  );
};

export default NewRetailFulfillmentOrder;
