import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const alertIcon = require('../../../../../assets/images/common/alert.svg');

interface SelectedShipmentsLabelProps {
  selectedShipmentsLabel: string;
  warningLabel: string;
}

const SelectedShipmentsLabel: React.FC<SelectedShipmentsLabelProps> = (props) => {
  return (
    <div>
      <div className="selected-shipments-count-label">{props.selectedShipmentsLabel}</div>
      {props.warningLabel.length > 0 ? (
        <div className="waving-warning-label" data-testid="waving-controls-warning">
          <img src={alertIcon} alt="alertIcon" /> {props.warningLabel}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SelectedShipmentsLabel;
