import * as React from 'react';
import InlineEdit from '../../shared/InlineEdit';
import ContainersService from '../../shared/services/ContainersService';
import {
  ContainerDelivery,
  ContainerDeliveryFinishedStates,
  ContainerDeliveryInProgressStates,
  ContainerDeliveryState
} from './DropoffInterfaces';

interface NotesProps {
  containerDelivery: ContainerDelivery;
  containersService: ContainersService;
  onUpdateInstructions: (instructions: string) => void;
  isShipper: boolean;
  allowEditNotesInShipperUI: boolean;
  onDocumentUploadError(errors: string[]);
}

const InboundNotes: React.FunctionComponent<NotesProps> = (props: NotesProps) => {
  const [editInProgress, setEditInProgress] = React.useState(false);
  const [notes, setNotes] = React.useState('');
  const [isShipper, setIsShipper] = React.useState(false);

  React.useEffect(() => {
    setNotes(props.containerDelivery.instructions);
  }, [props.containerDelivery.instructions]);

  React.useEffect(() => {
    setIsShipper(props.isShipper);
  }, [props.isShipper]);

  const handleToggleInstructionsEdit = () => {
    setNotes(props.containerDelivery.instructions);
    setEditInProgress(!editInProgress);
  };

  async function handleInstructionsUpdate(newInstructions: string) {
    const response = await props.containersService.updateInstructions(props.containerDelivery.id, newInstructions);
    if (response && response.errors.length === 0) {
      setEditInProgress(false);
      props.onUpdateInstructions(newInstructions);
      props.containerDelivery.instructions = newInstructions;
    } else {
      setEditInProgress(false);
      if (response && response.errors) {
        props.onDocumentUploadError(response.errors.map((error) => error.detail));
      }
    }
  }

  if (
    !isShipper &&
    props.containerDelivery.txnState === ContainerDeliveryState.new &&
    props.allowEditNotesInShipperUI
  ) {
    return (
      <InlineEdit
        textArea={true}
        placeholderText="No instructions provided."
        editing={editInProgress}
        value={notes}
        onToggleEdit={() => handleToggleInstructionsEdit()}
        onUpdate={(newInstructions) => handleInstructionsUpdate(newInstructions)}
      />
    );
  } else if (!isShipper && props.containerDelivery.txnState !== ContainerDeliveryState.new) {
    return (
      <InlineEdit
        textArea={true}
        placeholderText="No instructions provided."
        editing={editInProgress}
        value={notes}
        onToggleEdit={() => handleToggleInstructionsEdit()}
        onUpdate={(newInstructions) => handleInstructionsUpdate(newInstructions)}
      />
    );
  } else if (
    isShipper &&
    !ContainerDeliveryFinishedStates.includes(props.containerDelivery.txnState as ContainerDeliveryState) &&
    props.allowEditNotesInShipperUI
  ) {
    return (
      <InlineEdit
        textArea={true}
        placeholderText="No instructions provided."
        editing={editInProgress}
        value={notes}
        onToggleEdit={() => handleToggleInstructionsEdit()}
        onUpdate={(newInstructions) => handleInstructionsUpdate(newInstructions)}
      />
    );
  } else {
    return (
      <div className="inline-edit-wrapper">
        <span data-testid="inbound-new-notes" className="instruction-text">
          {props.containerDelivery.instructions}
        </span>
      </div>
    );
  }
};

export default InboundNotes;
