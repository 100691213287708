import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';
export interface RetailShippingInformation {
  shipTo: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  phone?: string;
}
interface Props {
  shippingInformation: RetailShippingInformation;
  onInput(event);
}

const ShippingInformation: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <li className="active" id="step_3">
        <span className="label">3</span>
        <div className="step-wrap">
          <h3>
            <span>Shipping Information</span>
            <a className="help-note" data-tip="Fill out the following fields for this retail fulfillment order.">
              <i className="fa fa-lg fa-question-circle" />
            </a>
            <ReactTooltip place="right" />
          </h3>
          <div className="input-wrap">
            <h4>Destination</h4>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group required">
                  <input
                    placeholder="Ship to..."
                    type="text"
                    title="Ship to..."
                    name="shipTo"
                    data-testid="ship-to-input"
                    value={props.shippingInformation.shipTo || ''}
                    onChange={(event) => props.onInput(event)}
                  ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group required">
                  <input
                    placeholder="Address Line 1"
                    type="text"
                    title="Address Line 1"
                    name="addressLine1"
                    data-testid="address-line-1-input"
                    value={props.shippingInformation.addressLine1 || ''}
                    onChange={(event) => props.onInput(event)}
                  ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    placeholder="Address Line 2 (optional)"
                    type="text"
                    title="Address Line 2"
                    name="addressLine2"
                    data-testid="address-line-2-input"
                    value={props.shippingInformation.addressLine2 || ''}
                    onChange={(event) => props.onInput(event)}
                  ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group required">
                  <input
                    placeholder="City..."
                    type="text"
                    title="City"
                    name="city"
                    data-testid="city-input"
                    value={props.shippingInformation.city || ''}
                    onChange={(event) => props.onInput(event)}
                  ></input>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group required">
                  <input
                    placeholder="State..."
                    data-testid="state-input"
                    type="text"
                    title="State"
                    name="state"
                    value={props.shippingInformation.state || ''}
                    onChange={(event) => props.onInput(event)}
                  ></input>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group required">
                  <input
                    placeholder="ZIP / Postal Code..."
                    data-testid="postal-code-input"
                    type="text"
                    title="PostalCode"
                    name="postalCode"
                    value={props.shippingInformation.postalCode || ''}
                    onChange={(event) => props.onInput(event)}
                  ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <input
                    placeholder="Phone..."
                    type="text"
                    title="Phone"
                    name="phone"
                    data-testid="phone-input"
                    value={props.shippingInformation.phone || ''}
                    onChange={(event) => props.onInput(event)}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

export default ShippingInformation;
