import * as React from 'react';
import OutboundShipmentService from '../../../shared/services/OutboundShipmentService';
import LocationsService from '../../../locations/LocationsService';
import WarehouseService from '../../../shared/services/WarehouseService';
import LpnService from '../../../shared/services/LpnService';
import OutboundManifestService from '../../../shared/services/OutboundManifestService';
import ManifestContentsService from '../../../shared/services/ManifestContentsService';
import PickConsolidationService from '../../../shared/services/PickConsolidationService';
import {Wave} from '../../batch-waving/WaveInterfaces';

export interface TShipmentDetailsContext {
  authenticityToken: string;
  shipmentId: number;
  wave: Wave;
  locationsService: LocationsService;
  lpnService: LpnService;
  manifestContentsService: ManifestContentsService;
  outboundManifestService: OutboundManifestService;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  shipmentService: OutboundShipmentService;
  warehouseService: WarehouseService;
  pickConsolidationService: PickConsolidationService;
}

export const ShipmentDetailsContext = React.createContext<TShipmentDetailsContext>({
  authenticityToken: '',
  shipmentId: -1,
  wave: null,
  locationsService: null,
  lpnService: null,
  manifestContentsService: null,
  outboundManifestService: null,
  setErrors: null,
  shipmentService: null,
  warehouseService: null,
  pickConsolidationService: null
});
