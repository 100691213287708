import {ApiResponse} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

export interface GetReportingDashboardResponse {
  url: string;
}
class OmniReportingService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/omni_reporting');
  }

  public async getDashboard(reportName: string): Promise<ApiResponse<GetReportingDashboardResponse>> {
    const data = {
      report_name: reportName
    };
    const response = (await this.makeGetRequest(`${this.baseUrl}/omni_dashboard`, data)) as Promise<
      ApiResponse<GetReportingDashboardResponse>
    >;

    return response;
  }
}

export default OmniReportingService;
