import axios from 'axios';
import * as React from 'react';
import {get} from 'lodash';
import * as uuidv4 from 'uuid/v4';
import PickupRequest from './PickupRequest';

interface StepFourProps {
  reservationId: number;
  requests: PickupRequest[];
  authenticityToken: string;
  successRedirect: string;
  onSubmitFailed();
}

interface StepFourState {
  pending: boolean;
  responseErrors?: any;
}

function Loading(props) {
  return (
    <div id="loading">
      <p className="note">Loading...</p>
    </div>
  );
}
function SuccessfulSubmission(props) {
  return (
    <div id="successful-submission">
      <p className="note">
        <i className="fa fa-check-circle text-success"></i>
        Your requests have been successfully submitted!
      </p>
    </div>
  );
}

function FailedSubmission({errors}) {
  const renderedErrors = errors.map((error, idx) => {
    return <li key={idx}>{error.title}</li>;
  });
  return (
    <div id="failed-submission">
      <p className="note">
        <i className="fa fa-times-circle text-danger"></i>
        None of your request were submitted. Please resubmit requests.
      </p>
      {renderedErrors.length ? <ul className="errors"> {renderedErrors} </ul> : null}
    </div>
  );
}

class StepFour extends React.Component<StepFourProps, StepFourState> {
  constructor(props) {
    super(props);
    this.state = {pending: true, responseErrors: null};
  }
  //api/v2/
  public componentDidMount() {
    axios
      .post('/api/v2/palletized/bulk_create_pickups', {
        authenticity_token: this.props.authenticityToken,
        meta: {correlationId: uuidv4()},
        data: {
          reservationId: this.props.reservationId,
          deliveryLines: this.props.requests.map((request) => request.data)
        }
      })
      .then((res) => {
        let responseErrors = get(res, 'data.errors');
        if (responseErrors && responseErrors.length) {
          this.props.onSubmitFailed();
        }
        const createdDeliveries = get(res, 'data.data.createdDeliveries');
        if (createdDeliveries && createdDeliveries.length) {
          responseErrors = null;
          window.location.href = this.props.successRedirect;
        }
        this.setState({
          responseErrors,
          pending: false
        });
      })
      .catch((error) => {
        this.props.onSubmitFailed();
        this.setState({
          responseErrors: get(error, 'response.data.errors', []),
          pending: false
        });
      });
  }

  public render() {
    let response = null;
    if (this.state.pending) {
      response = <Loading />;
    } else {
      if (this.state.responseErrors) {
        response = <FailedSubmission errors={this.state.responseErrors} />;
      } else {
        response = <SuccessfulSubmission />;
      }
    }

    return (
      <li id="step-4" className="active">
        <span className="label">4</span>
        <div className="step-wrap">
          <h3>
            <span>Requests Submitted</span>
          </h3>
          {response}
        </div>
      </li>
    );
  }
}

export default StepFour;
