import * as React from 'react';
import {FC} from 'react';
import {LegacyModal, Loader} from '@flexe/ui-components';
import {LpnSearchDetails, LpnSearchRequest, LpnsResponse} from '../../../lpns/LpnsInterfaces';
import {Shipment} from '../ShipmentInterfaces';
import ErrorDisplay from '../../../shared/ErrorDisplay';
import FlexeButton from '../../../shared/FlexeButton';
import {renderItemLink, renderLocationLink, renderLpnLink} from '../../../../libs/helpers';
import {getQTYUoM} from '../../../shared/utilities/DataFormatting';
import {ShipmentDetailsContext} from './ShipmentDetailsContext';
import {ShipmentLpnEditBoundary} from './ShipmentLpnEditBoundary';

interface Props {
  toggleModal: () => void;
  show: boolean;
  onConfirm: (palletCount: number) => void;
  lpn?: LpnsResponse;
  shipment: Shipment;
}

interface LpnReplacementModel {
  lpn?: LpnSearchDetails;
  error?: LpnSearchError;
}

enum LpnSearchError {
  LpnNotFound = 1, // Start counting at one so !! catches nulls only
  SkuMisMatch,
  ShipmentAssociation
}

export const ShipmentLpnReplaceModal: FC<Props> = (props) => {
  const {shipmentService} = React.useContext(ShipmentDetailsContext);
  if (!props.lpn || !props.show) {
    return null;
  }

  const [errorText, setErrorText] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [replacementLpn, setReplacementLpn] = React.useState<LpnReplacementModel>({});
  const [isReplaceable, setIsReplaceable] = React.useState<boolean>(true);

  const handleLpnSearchResult = async (searchText: string, lpn?: LpnSearchDetails) => {
    let error: LpnSearchError;
    if (searchText === '') {
      // Not an error state. Just resets the form to its initial state
    } else if (!lpn) {
      error = LpnSearchError.LpnNotFound;
      // eslint-disable-next-line no-extra-boolean-cast
    } else if (!!lpn.shipmentId) {
      error = LpnSearchError.ShipmentAssociation;
    } else if (invalidSkus(lpn)) {
      error = LpnSearchError.SkuMisMatch;
    }

    setReplacementLpn({
      lpn,
      error
    });
    setLoading(false);
  };

  /**
   * Check if the replacement lpn is invalid based on the skus it contains.
   * The current definition of equal is exactly the same skus on both lpns.
   *
   * @param lpnToValidate The lpn to compare against the one in props
   * @returns True if the replacement lpn is invalid
   */
  const invalidSkus = (lpnToValidate: LpnSearchDetails): boolean => {
    const originalSkus = new Set<string>();
    const replacementSkus = new Set<string>();

    props.lpn.contents.forEach((content) => originalSkus.add(content.item.sku));
    lpnToValidate.contents.forEach((content) => replacementSkus.add(content.item.sku));

    // Different sizes can't be equal
    if (originalSkus.size !== replacementSkus.size) {
      return true;
    }

    // Iterate the set of skus to validate both sets have the same values.
    // Don't return from the forEach because that will only break out of the lambda, not
    // the invalidSkus function
    let missingSku = false;
    originalSkus.forEach((sku) => {
      if (!replacementSkus.has(sku)) {
        missingSku = true;
      }
    });

    return missingSku;
  };

  /**
   * Perform the web requests to update the shipment
   */
  const doReplace = async () => {
    try {
      setLoading(true);

      const replaceResponse = await shipmentService.replaceLpnsOnShipment({
        lpnIdToRemove: props.lpn.lpnId,
        lpnIdToAdd: replacementLpn.lpn.lpnId,
        shipmentId: props.shipment.id
      });

      if (replaceResponse.errors && replaceResponse.errors.length > 0) {
        setErrorText(`Unable to replace LPN on the shipment! ${replaceResponse.errors[0].detail}`);
      } else {
        props.onConfirm(replaceResponse.data.num_pallets);
      }
    } catch (e) {
      setErrorText(`Unable to replace LPN! ${e.message}`);
    } finally {
      setLoading(false);
    }
  };

  const searchValidationFailureDom = () => {
    let dom = null;

    switch (replacementLpn.error) {
      case LpnSearchError.LpnNotFound:
        dom = (
          <span className="invalid">
            <b>No Results.</b> The LPN you're looking for couldn't be found. Make sure you've entered the LPN exactly.
            This form is case-sensitive.
          </span>
        );
        break;
      case LpnSearchError.SkuMisMatch:
        // eslint-disable-next-line no-case-declarations
        const pluralSku =
          props.lpn.contents.length > 0 || (replacementLpn.lpn && replacementLpn.lpn.contents.length > 0);

        dom = (
          <span className="invalid">
            <b>Can't Replace with this LPN.</b> This LPN contains {pluralSku ? 'different SKU(s) ' : 'a different SKU '}
            than {props.lpn.lpnBarcode} and can't replace it.
          </span>
        );
        break;
      case LpnSearchError.ShipmentAssociation:
        dom = (
          <span className="invalid">
            <b>Can't Replace with this LPN.</b> This LPN is already associated with shipment{' '}
            {replacementLpn.lpn.shipmentId}.
          </span>
        );
        break;
    }

    return dom;
  };

  return (
    <LegacyModal
      id="shipment-lpn-replace"
      transitionSpeed="fast"
      toggleModal={props.toggleModal}
      show={props.show}
      title={
        isReplaceable ? (
          <div>
            <h3>Replace LPN</h3>
            Select LPN with same SKU to replace {props.lpn.lpnBarcode}. Be sure to exchange the physical goods before
            shipping.
          </div>
        ) : (
          <h3>Can't Replace LPN</h3>
        )
      }
    >
      <div>
        <ErrorDisplay errorText={errorText} />
        <ShipmentLpnEditBoundary
          shipment={props.shipment}
          lpn={props.lpn}
          blockedAction={'replace'}
          toggleModal={props.toggleModal}
          onEditableChanged={setIsReplaceable}
        >
          <div>
            <p className="section-title">Removing from shipment:</p>
            <LpnDetails lpn={props.lpn} />
          </div>
          <hr />
          <div className="replace-with-container">
            <p className="section-title"> Replacing With:</p>
            <div className="search-container">
              <LpnSearchField
                handleSearchStart={() => {
                  setReplacementLpn({});
                  setLoading(true);
                }}
                handleSearchResult={handleLpnSearchResult}
                reservationId={props.shipment.reservation.id}
                isInvalid={!!replacementLpn.error}
              />
              <p className="subtext">
                <em>This LPN must contain the same SKU and it can't already be allocated to a different shipment</em>
              </p>
            </div>
            {searchValidationFailureDom()}
            <LpnDetails lpn={replacementLpn.lpn} />
          </div>
          {loading && (
            <div className="loader-container">
              <Loader loading={loading} />
            </div>
          )}
          <div className="action-buttons">
            <div className="right-buttons">
              <FlexeButton text={'Cancel'} handleClick={props.toggleModal} level={'collapsed-link'} />
              <span className="button-spacing" />
              <FlexeButton
                text={'Replace LPN'}
                handleClick={doReplace}
                level={'primary'}
                isDisabled={!replacementLpn.lpn || !!replacementLpn.error}
              />
            </div>
          </div>
        </ShipmentLpnEditBoundary>
      </div>
    </LegacyModal>
  );
};

interface LpnDetailsProps {
  lpn?: LpnsResponse;
}

export const LpnDetails: FC<LpnDetailsProps> = (props) => {
  if (!props.lpn) {
    return null;
  }

  return (
    <div className="replace-lpn-details">
      <h4>{renderLpnLink(props.lpn.lpnBarcode, false)}</h4> at{' '}
      {renderLocationLink(props.lpn.location.id, props.lpn.location.label)}
      {props.lpn.contents.map((lpnContent, idx) => (
        <div key={lpnContent.item.sku}>
          <div>{lpnContent.item.description}</div>
          <div>{renderItemLink(lpnContent.item.id, lpnContent.item.sku, false)}</div>
          <div className="quantity">
            &raquo; {getQTYUoM(lpnContent.quantity.amount, lpnContent.quantity.unit, false)}
          </div>
          {idx < props.lpn.contents.length - 1 && <hr />}
        </div>
      ))}
    </div>
  );
};

interface LpnSearchFieldProps {
  handleSearchStart: (searchText: string) => void;
  handleSearchResult: (searchText: string, lpn?: LpnSearchDetails) => void;
  reservationId: number;
  isInvalid: boolean;
}

const LpnSearchField: FC<LpnSearchFieldProps> = (props) => {
  const {lpnService} = React.useContext(ShipmentDetailsContext);
  const [errorText, setErrorText] = React.useState<string>('');
  const [searchText, setSearchText] = React.useState<string>('');

  const doSearch = async () => {
    props.handleSearchStart(searchText);

    const request: LpnSearchRequest = {
      LpnBarcodes: [searchText],
      ShipmentIds: [],
      ReservationIds: [props.reservationId],
      Skus: []
    };

    const response = await lpnService.searchShipmentDetailsLpns(request);

    if (response.errors && response.errors.length > 0) {
      setErrorText(`Unable to search for LPNs! ${response.errors[0].detail}`);
    } else {
      props.handleSearchResult(searchText, response.data.lpns[0]);
    }
  };

  // Search as you type implementation. 2 seconds after text input the search is run
  React.useEffect(() => {
    if (searchText !== '') {
      const delayDebounce = setTimeout(doSearch, 2000);
      return () => clearTimeout(delayDebounce);
    } else {
      props.handleSearchResult('');
    }
  }, [searchText]);

  const cssClasses = ['form-control'];
  if (props.isInvalid) {
    cssClasses.push('invalid');
  }

  return (
    <div className="lpn-search-field">
      <ErrorDisplay errorText={errorText} />
      <input
        className={cssClasses.join(' ')}
        value={searchText}
        placeholder={'LPN barcode'}
        title={'Search for an LPN by its barcode'}
        onChange={(event) => {
          setSearchText(event.currentTarget.value);
        }}
        autoFocus
      />
    </div>
  );
};
