import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';

interface ConfirmDeleteProps {
  toggleShowModal();
  deleteAttachment();
}

export const ConfirmDeleteAttachmentModal: React.FC<ConfirmDeleteProps> = (props) => {
  return (
    <LegacyModal
      id="confirm-delete-order-attachments-modal"
      title={'Delete this attachment?'}
      show={true}
      size="medium"
      toggleModal={props.toggleShowModal}
      footer={
        <div>
          <button id="Delete-agree" type="button" className="btn" onClick={props.deleteAttachment}>
            Yes, delete
          </button>
        </div>
      }
      disableClose={false}
    >
      <div>
        <p>
          Are you sure you want to delete this attachment? (We will also try to cancel all shipments that are associated
          with this attachment.)
        </p>
      </div>
    </LegacyModal>
  );
};
