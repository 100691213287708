import * as React from 'react';
import {useEffect} from 'react';

export const usePalletQuantityErrorMessage = (props) => {
  const [fieldValidationError, setFieldValidationError] = React.useState(null);
  const error = (
    <React.Fragment>
      <b>No Overages. </b>
      {'The number of pallets must be equal to, ' +
        `or less than, the expected ${props.recommendedPalletQuantity} pallet(s).`}
    </React.Fragment>
  );

  useEffect(() => {
    if (props.recommendedPalletQuantity != null && props.palletCount > props.recommendedPalletQuantity) {
      setFieldValidationError(error);
    } else {
      setFieldValidationError(null);
    }
  }, [props.palletCount]);

  return fieldValidationError;
};
