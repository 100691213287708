import * as React from 'react';
import {RetailOrderDetails} from '../../shared/services/RetailFulfillmentService';

interface HeaderPaneShipmentDetailsProps {
  orderDetails: RetailOrderDetails;
}

export const HeaderPaneShipmentDetails: React.FC<HeaderPaneShipmentDetailsProps> = (props) => {
  const shipmentDetails = (): React.ReactFragment => {
    const numberOfCartons = props.orderDetails.orderItems.reduce((sum, item) => sum + item.unitConversions.cartons, 0);

    return (
      <div id="shipment-details" className="col-md-3 quarters">
        <h4 className="text-uppercase">Shipment Details</h4>
        <table className="packing-list-table space-below">
          <thead>
            <tr>
              <th>
                <b>Pallet Qty</b>
              </th>
              <th>
                <b>Cartons</b>
              </th>
              {props.orderDetails.estimatedWeight && (
                <th>
                  <b>Est. Weight</b>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.orderDetails.palletsBuilt ? props.orderDetails.palletsBuilt : 'TBD'}</td>
              <td>{numberOfCartons}</td>
              {props.orderDetails.estimatedWeight && <td>{props.orderDetails.estimatedWeight}</td>}
            </tr>
          </tbody>
        </table>

        <ul className="col-sm-12 no-padding">
          <li className="row">
            <div className="bold col-sm-5">Ship to:</div>

            <div className="col-sm-6">{props.orderDetails.shipTo.name}</div>
          </li>

          <li className="row">{shipToAddress()}</li>

          {renderPropertyIfPresent(props.orderDetails.scac, 'SCAC:')}
          {renderPropertyIfPresent(props.orderDetails.trailerNumber, 'Trailer #:')}
          {renderPropertyIfPresent(props.orderDetails.proNumber, 'PRO #:')}
          {renderPropertyIfPresent(props.orderDetails.sealNumber, 'SEAL #:')}
        </ul>

        <div className="vertical-rule hidden-xs"></div>
      </div>
    );
  };

  const shipToAddress = (): React.ReactFragment => {
    const address = props.orderDetails.shipTo;
    const cityLevelAddress = `${address.locality}, ${address.region}, ${address.postcode}, ${address.country}`;

    // TODO: Address Line 4

    return (
      <React.Fragment>
        <div className="bold col-sm-5">Address:</div>

        <div className="col-sm-6 address-lines">
          {address.addressLine1 && <p>{address.addressLine1}</p>}
          {address.addressLine2 && <p>{address.addressLine2}</p>}
          {address.addressLine3 && <p>{address.addressLine3}</p>}

          {cityLevelAddress}
        </div>
      </React.Fragment>
    );
  };

  const renderPropertyIfPresent = (property: string, label: string): React.ReactFragment => {
    if (property) {
      return (
        <React.Fragment>
          <li className="row">
            <div className="bold col-sm-5">{label}</div>
            <div className="col-sm-6">{property}</div>
          </li>
        </React.Fragment>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  return <React.Fragment>{shipmentDetails()}</React.Fragment>;
};
