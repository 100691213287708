import {uniqueId} from 'lodash';

export type NotificationLevel = 'error' | 'success' | 'warning' | 'info';
export type NotificationLocation = 'in-place' | 'lower-right';
export interface NotificationDetails {
  id: string;
  level: NotificationLevel;
  location: NotificationLocation;
  message: string;
  durationMs: number;
}

/**
 * This method is currently a proof of concept and shouldn't
 * be used outside the User Management component.
 */
export const generateNotification = ({
  level = 'info',
  message,
  location = 'lower-right',
  durationMs = 5000
}: Partial<NotificationDetails> = {}) => {
  if (message) {
    window.dispatchEvent(
      new CustomEvent<NotificationDetails>('flexe.notification', {
        detail: {id: uniqueId(), level, location, message, durationMs}
      })
    );
  }
};
