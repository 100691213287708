import InternalAPIService from '../shared/services/InternalAPIService';
import {ApiResponse, CsvUploadResponse, Packaging, ResponseError} from '../shared/CommonInterfaces';
import {LocationCategoryStrings, LocationStatus} from '../locations/LocationsInterfaces';
import {SpaRulesResponse} from './SpaConfigurationsInterfaces';

export interface SpaRule {
  id: number;
  reservation_id: number;
  inventory_id: number;
  sku: string;
  packaging: string;
  itemVelocity: string;
  isHazmat: boolean;
  isLotCodeTracked: boolean;
  isExpirationDateTracked: boolean;
  isShipAlone: boolean;
  isShipAsIs: boolean;
  zoneNames: string;
  locationCategories: string;
  locationLabels: string;
  priority: number;
  csa: object;
}

export interface ImportInstruction {
  name: string;
  isRequired: string;
  description: string;
  input_guidelines: string;
  sample_values: string;
}

interface ImportInstructionResponse {
  import_instructions: ImportInstruction[];
}

export enum SpaEngineType {
  EngineDisabled = 'disabled',
  SkuConsolidation = 'sku_consolidation',
  Configurable = 'configurable_spa_engine',
  ExampleForDemo = 'example_engine_for_demo'
}

export enum ZoneCategory {
  HighestQtyZoneCategory = 'Consolidation',
  EmptyZoneCategory = 'Empty Location',
  NoCategory = ''
}

export interface SuggestedLocation {
  rank: number;
  id: number;
  label: string;
  category: LocationCategoryStrings;
  warehouseId: number;
  quantity: number;
  packaging: Packaging;
  txnState: LocationStatus;
  primaryPickLocation: boolean;
  zoneCategory: ZoneCategory;
}

export interface TestSuggestionsResponse {
  inventoryId: number;
  engine: SpaEngineType;
  locations: SuggestedLocation[];
  lpnBarcode?: string;
  steps: object[];
}

class SpaConfigurationsRulesService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/wh/spa_configurations/');
  }

  public async getRules(
    warehouseId: number,
    pageSize: number,
    continuationToken?: string
  ): Promise<ApiResponse<SpaRulesResponse>> {
    const url = `/api/v2/putaway/warehouses/${warehouseId}/rules`;
    return await this.makeGetRequest(url, {pageSize, continuationToken});
  }

  public async importRules(uploadParams, warehouseId): Promise<ApiResponse<CsvUploadResponse>> {
    const url = `/api/v2/putaway/warehouses/${warehouseId}/rules/import`;
    return await this.makePostRequest(url, uploadParams, 'multipart');
  }

  public async fetchImportInstructions(warehouseId): Promise<ApiResponse<ImportInstructionResponse>> {
    const url = `/api/v2/putaway/warehouses/${warehouseId}/rules/fetch_import_instructions`;
    return await this.makeGetRequest(url);
  }

  public async testSuggestions(
    warehouseId: number,
    inventoryId: number,
    packaging: Packaging
  ): Promise<ApiResponse<TestSuggestionsResponse>> {
    const route: string = `/api/v2/mobile/warehouses/${warehouseId}/putaway/suggestions`;
    return await this.makeGetRequest(route, {inventoryId, packaging});
  }

  public processErrors = (errors: ResponseError[]) => {
    return errors.map((error) => error.detail || error.title);
  };
}

export default SpaConfigurationsRulesService;
