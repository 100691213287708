import * as React from 'react';
import * as moment from 'moment';
import {decode} from 'html-entities';
import CardList, {ListItem} from '../../shared/CardList';
import {OrderType, SkuVariety, WaveParameters} from '../ecommerce-batches/BatchInterfaces';
import WaveTemplateService from '../../shared/services/WaveTemplateService';
import SimpleKebabMenu from '../../shared/SimpleKebabMenu';
import {WaveTemplate} from './WaveInterfaces';

interface WaveTemplateListProps {
  isPastDue: boolean;
  waveTemplates: WaveTemplate[];
  waveTemplateService: WaveTemplateService;
  reservationToSortationEnabled: object;
  reservationToPickToCarton: object;
  reservationIdToName: object;
  carriers: object;
  loadForWarehouse();
  setShowWaveModal(show: boolean);
  setTemplateToWave(template: WaveTemplate);
  errorCatapult(errors: string[]);
}

const waveButtonText = 'Wave';
const waveTemplateFormRoute = '/wh/fulfillment/ecommerce/waving/new_template';

const WaveTemplateList: React.FC<WaveTemplateListProps> = (props) => {
  const {
    isPastDue,
    waveTemplates,
    waveTemplateService,
    reservationToSortationEnabled,
    reservationToPickToCarton,
    reservationIdToName,
    carriers,
    loadForWarehouse,
    setShowWaveModal,
    setTemplateToWave,
    errorCatapult
  } = props;

  const formatWaveFilters = (waveFilters: WaveParameters) => {
    const details = [];

    if (isPastDue && waveFilters.cutoffDate) {
      details.push(`Ship by: ${moment.utc(waveFilters.cutoffDate).format('MMM DD')}`);
    }

    const reservationName = reservationIdToName[waveFilters.reservationId];
    if (reservationName) {
      details.push(reservationName);
    } else {
      details.push(`Reservation: ${waveFilters.reservationId}`);
    }

    if (reservationToPickToCarton[waveFilters.reservationId]) {
      details.push('Pick To Carton');
    } else if (reservationToSortationEnabled[waveFilters.reservationId]) {
      details.push('Rebin');
    }

    if (waveFilters.purchaseOrder) {
      details.push(`PO: ${waveFilters.purchaseOrder}`);
    }

    if (waveFilters.carriers) {
      const displayable = waveFilters.carriers.map((carrierEnum) => carriers[carrierEnum]);
      details.push(displayable.join(', '));
    }
    if (waveFilters.serviceTypes && waveFilters.serviceTypes.length > 0) {
      details.push(waveFilters.serviceTypes.join(', '));
    }
    if (waveFilters.shipmentType === OrderType.SingleItem) {
      details.push('Single Unit');
    } else if (waveFilters.shipmentType === OrderType.MultiItem) {
      details.push('Multi Unit');
    } // else ignore all other values
    if (waveFilters.skuVariety === SkuVariety.SingleSku) {
      details.push('Single SKU');
    } else if (waveFilters.skuVariety === SkuVariety.MultiSku) {
      details.push('Multi SKU');
    } // else ignore all other values

    if (waveFilters.shipAsIs) {
      details.push('Ship As Is');
    }
    if (waveFilters.siteToStoreOnly) {
      details.push('Site to Store');
    }
    if (waveFilters.includesHazmat) {
      details.push('Hazmat');
    }
    if (waveFilters.skus && waveFilters.skus.length > 0) {
      details.push(`SKU: ${waveFilters.skus.join(', ')}`);
    }
    if (waveFilters.postalCodeRange) {
      const range = waveFilters.postalCodeRange;
      details.push(`Postal Codes: ${range.start} - ${range.end}`);
    }

    if (waveFilters.customAttributes) {
      for (const [k, v] of Object.entries(waveFilters.customAttributes)) {
        details.push(`${k}: ${v}`);
      }
    }

    return details.join(', ');
  };

  const deleteTemplate = async (id) => {
    const response = await waveTemplateService.deleteWaveTemplate(id);
    if (response && response.data && response.data.status === 204) {
      loadForWarehouse();
    } else {
      errorCatapult(['An error occurred while deleting this template']);
    }
  };

  const getCallBackForTemplate = (template: WaveTemplate) => {
    return () => {
      setShowWaveModal(true);
      setTemplateToWave(template);
    };
  };

  const configureListItems = (templates: WaveTemplate[]): ListItem[] => {
    return templates.map((template) => {
      // \u00A0 - This is the unicode for a non-breaking space. &nbsp; does not work.
      const shipments = `${template.count}\u00A0shipments`;
      const templateName = decode(template.templateName);
      return {
        id: template.templateId,
        title: templateName,
        info: formatWaveFilters(template.waveFilters),
        actionHint: shipments,
        buttonText: waveButtonText,
        actionClick: getCallBackForTemplate(template),
        needsAttention: isPastDue,
        actionClickDisabled: !template.count,
        optionMenu: isPastDue ? null : (
          <SimpleKebabMenu
            title={templateName}
            id={template.templateId}
            deleteFunction={deleteTemplate}
            deleteEnabled
          />
        )
      };
    });
  };

  const title = isPastDue ? 'Past Due' : 'Wave by Template';
  return (
    <React.Fragment>
      <CardList
        listTitle={title}
        listItems={configureListItems(waveTemplates)}
        createRoute={isPastDue ? null : waveTemplateFormRoute}
        emptyListText={isPastDue ? '' : 'Click "Create New" to create new templates.'}
      />
    </React.Fragment>
  );
};

export default WaveTemplateList;
