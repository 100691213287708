import * as React from 'react';

interface Props {
  columns: any[];
  items: object[];
  pageSize: number;
  initialSortBy?: string;
  onSelectInventory(event);
}

interface State {
  currentPage: number;
  sortBy: string;
  sortDir: 'ASC' | 'DESC';
  filters: object;
}

const styles = {
  pageLink: {display: 'inline'}
};

class InventoryTable extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    // Initialize filters object based on specified filterable columns
    const filters = {};
    props.columns.forEach((col) => {
      if (col.filterable) {
        filters[col.prop] = '';
      }
    });

    this.state = {
      currentPage: 0,
      sortBy: props.initialSortBy,
      sortDir: 'DESC',
      filters
    };
  }

  public render() {
    const {items, columns, onSelectInventory, pageSize} = this.props;
    const {currentPage, filters} = this.state;
    const firstItemIdx = currentPage * pageSize;
    const lastItemIdx = Math.min(firstItemIdx + pageSize, items.length) - 1;
    const filteredItems = this.filterItems(items, filters);
    const currentPageItems = this.sortItems(filteredItems).slice(firstItemIdx, lastItemIdx + 1);

    return (
      <div className="inventory-table">
        <p className="pagination-label">
          {'Now showing inventory '}
          <span className="range">
            {firstItemIdx + 1} – {lastItemIdx + 1}
          </span>
          &nbsp;out of <span className="total">{items.length}</span> results.
        </p>
        <table className="table table-striped filterable-table">
          <thead>
            <tr className="main-controls manual-pickup">
              {columns.map((col, idx) => {
                return (
                  <th key={idx} className={col.prop}>
                    {col.sortable ? (
                      <a className="sort" href="#" data-property={col.prop} onClick={this.handleClickSort}>
                        <span>{col.title}</span>
                        <i className="fa fa-sort"></i>
                      </a>
                    ) : (
                      <span>{col.title}</span>
                    )}
                  </th>
                );
              })}
              {this.renderPageSwitch()}
            </tr>
            <tr className="filters">
              {columns.map((col, idx) => {
                return col.filterable ? (
                  <th key={idx}>
                    <input
                      id={`${col.prop}_filter`}
                      placeholder="Type to filter..."
                      type="text"
                      name={col.prop}
                      value={this.state.filters[col.prop]}
                      onChange={this.updateFilter}
                    />
                  </th>
                ) : (
                  <th key={idx}></th>
                );
              })}
              <th></th>
            </tr>
          </thead>
          <tfoot>
            <tr className="main-controls">
              {columns.map((_, idx) => {
                return <th key={idx}></th>;
              })}
              {this.renderPageSwitch()}
            </tr>
          </tfoot>
          <tbody>
            {currentPageItems.map((item: any, idx: number) => {
              return (
                <tr key={idx}>
                  {columns.map((col, colIdx) => {
                    return col.display ? (
                      <td key={colIdx}>{col.display(item)}</td>
                    ) : (
                      <td key={colIdx}>{item[col.prop]}</td>
                    );
                  })}
                  <td>
                    <a
                      className="btn table-action select"
                      href="#"
                      data-sku={item.sku}
                      data-packaging={item.packaging}
                      onClick={onSelectInventory}
                    >
                      <i className="fa fa-lg fa-check"></i>Select
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  private renderPageSwitch() {
    const {items, pageSize} = this.props;
    const {currentPage} = this.state;
    const prevDisabledClass = currentPage === 0 ? 'disabled' : '';
    const nextDisabledClass = currentPage >= items.length / pageSize - 1 ? 'disabled' : '';
    return (
      <th className="page-switch">
        <a
          className={`page-ctrl prev ${prevDisabledClass}`}
          href="#"
          data-page={currentPage - 1}
          style={styles.pageLink}
          onClick={this.changePage}
        >
          <i className="fa fa-lg fa-angle-double-left"></i>
        </a>
        <a
          className={`page-ctrl next ${nextDisabledClass}`}
          href="#"
          data-page={currentPage + 1}
          style={styles.pageLink}
          onClick={this.changePage}
        >
          <i className="fa fa-lg fa-angle-double-right"></i>
        </a>
      </th>
    );
  }

  private changePage = (event) => {
    event.preventDefault();
    const currentPage = parseInt(event.currentTarget.getAttribute('data-page'), 10);
    if (currentPage >= 0 && currentPage <= this.props.items.length / this.props.pageSize) {
      this.setState({currentPage});
    }
  };

  private sortItems(items) {
    const sortCol = this.props.columns.find((col) => {
      return col.prop === this.state.sortBy;
    });
    return this.state.sortBy
      ? items.slice(0).sort((item1, item2): number => {
          if (this.state.sortDir === 'ASC') {
            const temp = item1;
            item1 = item2;
            item2 = temp;
          }
          if (sortCol.sortBy) {
            return sortCol.sortBy(item1, item2);
          }
          let item1CompareVal = item1[this.state.sortBy];
          let item2CompareVal = item2[this.state.sortBy];
          if (typeof item1CompareVal === 'string') {
            item1CompareVal = item1CompareVal.toUpperCase();
            item2CompareVal = item2CompareVal.toUpperCase();
          }
          if (item1CompareVal < item2CompareVal) {
            return -1;
          }
          if (item1CompareVal > item2CompareVal) {
            return 1;
          }
          return 0;
        })
      : items;
  }

  private handleClickSort = (event) => {
    event.preventDefault();
    // const sortBy = event.currentTarget.dataset.property;
    const sortBy = event.currentTarget.getAttribute('data-property');
    // If this column was clicked on before, reverse the sort direction
    const sortDir = sortBy === this.state.sortBy && this.state.sortDir === 'DESC' ? 'ASC' : 'DESC';
    this.setState({sortBy, sortDir});
  };

  private updateFilter = (event) => {
    const filters = {...this.state.filters};
    filters[event.target.name] = event.target.value;
    this.setState({filters, currentPage: 0});
  };

  private filterItems = (items, filters) => {
    return items.filter((item) => {
      for (const columnProp in filters) {
        if (
          // eslint-disable-next-line no-prototype-builtins
          item.hasOwnProperty(columnProp) &&
          !item[columnProp].toUpperCase().includes(filters[columnProp].toUpperCase())
        ) {
          return false;
        }
      }
      return true;
    });
  };
}

export default InventoryTable;
