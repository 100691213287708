import * as React from 'react';
import {useEffect, useState} from 'react';
import {Loader} from '@flexe/ui-components';
import {Wave} from './WaveInterfaces';
import WavesTableEntry from './WavesTableEntry';
import {WaveContext} from './WaveContext';

interface Props {
  pageSize: number;
  setFetchingPage: any;
  setTotalWavesCount: any;
  currentPage: number;
}

const WavesTable: React.FC<Props> = ({pageSize, setFetchingPage, setTotalWavesCount, currentPage}) => {
  const {
    batchWavingService,
    reservationIds,
    warehouse,
    filterParams,
    setErrors,
    refreshing,
    setRefreshing
  } = React.useContext(WaveContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastPage, setLastPage] = useState<number>(0);
  const [previousPages, setPreviousPages] = useState<any>({});
  const [waves, setWaves] = useState<Wave[]>([]);
  const [batchCounts, setBatchCounts] = useState<object>({});
  const [continuationToken, setContinuationToken] = useState<string>(null);
  const [displayOrderByField, setDisplayOrderByField] = useState(false);

  useEffect(() => {
    setPreviousPages({});
    setLastPage(0);
    setLoading(true);
    setWaves([]);
    // Fetch with no continuation token
    fetchWaves(null);
  }, [reservationIds, filterParams]);

  // set page cache when new waves are retrieved
  useEffect(() => {
    const pages = previousPages;
    pages[currentPage] = waves;
    setPreviousPages(pages);
  }, [waves]);

  // when parent component changes page, fetch waves
  useEffect(() => {
    asyncGetWaves();
  }, [currentPage]);

  // fetch batch counts after fetching waves
  useEffect(() => {
    asyncGetBatchCounts();
  }, [waves, refreshing]);

  // get past page from cache
  const fetchLastPage = async () => {
    setLastPage(currentPage);
    setWaves(previousPages[currentPage]);
  };

  const fetchNextPage = async () => {
    // Check if we can just grab it from the cache
    if (previousPages[currentPage]) {
      setLastPage(currentPage);
      setWaves(previousPages[currentPage]);
      return;
    }

    // Else fetch a new page
    await fetchWaves(continuationToken);
  };

  // determine whether to get next page or last page
  // based on pagination control in parent
  const asyncGetWaves = async () => {
    if (currentPage < lastPage) {
      await fetchLastPage();
      setFetchingPage(false);
    } else {
      await fetchNextPage();
      setFetchingPage(false);
    }
  };

  const fetchWaves = async (currentContinuationToken) => {
    const response = await batchWavingService.getWaves(
      reservationIds,
      filterParams,
      currentContinuationToken,
      pageSize
    );

    if (response.data && response.data.waves) {
      setWaves(response.data.waves || []);
      setContinuationToken(response.data.continuationToken);
      setTotalWavesCount(response.data.totalCount);
      setFetchingPage(false);
      setLoading(false);
      setDisplayOrderByField(response.data.displayOrderByField);
    }
    if (response.errors && response.errors.length > 0) {
      const getWavesErrors = [];
      response.errors.forEach(() =>
        getWavesErrors.push(
          'An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.'
        )
      );
      setFetchingPage(false);
      setErrors(getWavesErrors);
      setLoading(false);
    }
  };

  const asyncGetBatchCounts = async () => {
    const waveIds = waves.map((w) => w.id);
    if (waveIds.length > 0) {
      const response = await batchWavingService.getBatchCountsByWaveIds(waveIds);

      if (response.data && response.data.batch_counts) {
        setBatchCounts(response.data.batch_counts);
        setRefreshing(false);
      }
      if (response.errors && response.errors.length > 0) {
        const getBatchesErrors = [];
        response.errors.forEach(
          () =>
            /* eslint-disable max-len */
            getBatchesErrors.push(
              'An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.'
            )
          /* eslint-enable max-len */
        );
        setErrors(getBatchesErrors);
        setRefreshing(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="waves-table">
        {(refreshing || loading) && <Loader loading={refreshing || loading} />}
        {!refreshing &&
          !loading &&
          waves.map((wave) => (
            <WavesTableEntry
              key={wave.id}
              wave={wave}
              batchCount={batchCounts[wave.id]}
              displayOrderByField={displayOrderByField}
            />
          ))}
      </div>
    </React.Fragment>
  );
};

export default WavesTable;
