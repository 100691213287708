import * as React from 'react';
import {cloneDeep, get} from 'lodash';
import {LegacyModal} from '@flexe/ui-components';
import {Address} from './SharedInterfaces';
import FulfillmentService from './FulfillmentService';

interface RepairProps {
  fulfillmentService: FulfillmentService;
  originalAddress: Address;
  shipmentId: number;
  showFixAddressModal: boolean;
  suggestedAddress: Address;
  handleUpdateErrors(errors);
  reloadShipments();
  toggleFixAddressModal();
}

interface RepairState {
  addressIsValid: boolean;
  addressToEdit: Address;
  forceGivenAddress: boolean;
  selectedAddress: Address;
  updatingShipment: boolean;
  validatingAddress: boolean;
  validationErrors: string[];
}

class RepairShipment extends React.Component<RepairProps, RepairState> {
  constructor(props) {
    super(props);
    this.state = {
      addressIsValid: false,
      addressToEdit: null,
      forceGivenAddress: false,
      selectedAddress: null,
      validatingAddress: false,
      validationErrors: [],
      updatingShipment: false
    };
  }

  public render() {
    return (
      <div className="repair-shipment-component">
        <LegacyModal
          id="fix-address-modal"
          title="Fix invalid address"
          show={this.props.showFixAddressModal}
          size="small"
          toggleModal={this.props.toggleFixAddressModal}
          disableClose={false}
        >
          <div>
            <div className="row">
              {!this.state.addressToEdit && this.getAddressesForRepair()}
              {this.state.addressToEdit && (
                <div className="col-sm-12">
                  <form onSubmit={this.handleValidateAddress} className="row no-margin">
                    {this.state.validationErrors && this.state.validationErrors.length > 0 && (
                      <div className="alert alert-danger" role="alert">
                        <ul>
                          {this.state.validationErrors.map((e, i) => (
                            <li key={i}>{e}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <label className="col-sm-12">
                      Line 1:
                      <input
                        type="text"
                        name="line1"
                        onChange={this.addressInputChange}
                        value={this.state.addressToEdit.line1}
                        required
                      />
                    </label>
                    <label className="col-sm-12">
                      Line 2 (optional):
                      <input
                        type="text"
                        name="line2"
                        onChange={this.addressInputChange}
                        value={this.state.addressToEdit.line2}
                      />
                    </label>
                    <label className="col-sm-8">
                      City:
                      <input
                        type="text"
                        name="locality"
                        onChange={this.addressInputChange}
                        value={this.state.addressToEdit.locality}
                        required
                      />
                    </label>
                    <label className="col-sm-4">
                      State/Prov:
                      <input
                        type="text"
                        name="region"
                        onChange={this.addressInputChange}
                        value={this.state.addressToEdit.region}
                        required
                      />
                    </label>
                    <label className="col-sm-8">
                      ZIP/Postal:
                      <input
                        type="text"
                        name="postcode"
                        onChange={this.addressInputChange}
                        value={this.state.addressToEdit.postcode}
                      />
                    </label>
                    <label className="col-sm-4">
                      Country:
                      <input
                        type="text"
                        name="country"
                        onChange={this.addressInputChange}
                        value={this.state.addressToEdit.country}
                        required
                      />
                    </label>
                    <label className="col-sm-8">
                      Skip Address Normalization:{' '}
                      <input
                        type="checkbox"
                        name="forceGivenAddress"
                        checked={this.state.forceGivenAddress ? true : false}
                        onChange={() =>
                          this.setState({
                            forceGivenAddress: !this.state.forceGivenAddress
                          })
                        }
                      />
                    </label>
                    <div className="col-sm-8 space-above">
                      {!this.state.addressIsValid && (
                        <button className="btn" disabled={this.state.validatingAddress}>
                          {this.state.validatingAddress && <i className="fa fa-spinner fa-spin no-padding"></i>}
                          <span>Validate Address</span>
                        </button>
                      )}
                    </div>
                    <div className="col-sm-4 space-above">
                      {this.state.validationErrors.length > 0 && (
                        <button
                          className="btn"
                          disabled={this.state.updatingShipment || this.state.validatingAddress}
                          onClick={this.handleUpdateAddress}
                        >
                          {this.state.updatingShipment && <i className="fa fa-spinner fa-spin no-padding"></i>}
                          <span>Use Address Anyway</span>
                        </button>
                      )}
                    </div>
                  </form>
                  {this.state.addressIsValid && (
                    <div className="row no-margin">
                      <p className="col-sm-12 no-padding successGreen">Your address has been validated!</p>
                      <div className="col-sm-12 no-padding">
                        <button
                          className="btn"
                          disabled={this.state.updatingShipment}
                          onClick={this.handleUpdateAddress}
                        >
                          {this.state.updatingShipment && <i className="fa fa-spinner fa-spin no-padding"></i>}
                          <span>Request New Shipping Label</span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </LegacyModal>
      </div>
    );
  }

  private getAddressesForRepair() {
    const addressesMatch = ['line1', 'line2', 'locality', 'region', 'country'].every((field) => {
      return this.props.originalAddress[field] === this.props.suggestedAddress[field];
    });

    if (addressesMatch) {
      return [
        this.getAddressTile(this.props.originalAddress, true, !!this.props.originalAddress.line2),
        <div key="original_false" className="col-sm-6">
          <p className="address-label">Suggested:</p>
          <span className="no-suggestion">
            No suggestion. Please, <span className="action">Edit</span> the address manually or if the original is
            correct, select <span className="action">Use Anyway</span>.
          </span>
        </div>
      ];
    } else {
      const showLine2 = !!(this.props.originalAddress.line2 || this.props.suggestedAddress.line2);
      return [
        this.getAddressTile(this.props.originalAddress, true, showLine2),
        this.getAddressTile(this.props.suggestedAddress, false, showLine2)
      ];
    }
  }

  private getAddressTile(address: Address, isOriginal = false, showLine2 = true) {
    return (
      <div key={`original_${isOriginal}`} className="col-sm-6">
        <div className={isOriginal ? 'original-address' : ''}>
          <p className="address-label">
            {isOriginal ? 'Original:' : 'Suggested:'}
            {isOriginal && (
              <a className="edit-original-address" onClick={this.handleEditAddress} title="Edit Original Address">
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </a>
            )}
          </p>
          <address>
            {address.line1}
            <br />
            {showLine2 && [address.line2 || '(null)', <br />]}
            {address.locality}, {address.region} {address.postcode}
            <br />
            {address.country}
          </address>
        </div>
        <div className="repair-options">
          {isOriginal ? (
            [
              <button data-address-key="original" className="btn-primary use-anyway" onClick={this.handleAddressSelect}>
                Use Anyway
              </button>,
              <button onClick={this.handleEditAddress}>Edit</button>
            ]
          ) : (
            <button data-address-key="suggested" className="btn-primary" onClick={this.handleAddressSelect}>
              Use Suggested
            </button>
          )}
        </div>
      </div>
    );
  }

  private handleEditAddress = () => {
    this.setState({
      addressToEdit: cloneDeep(this.props.originalAddress)
    });
  };

  private handleAddressSelect = (e) => {
    const addressKey = e.currentTarget.getAttribute('data-address-key');
    const forceGivenAddress = addressKey === 'original';
    const selectedAddress = forceGivenAddress
      ? cloneDeep(this.props.originalAddress)
      : cloneDeep(this.props.suggestedAddress);
    this.setState(
      {
        forceGivenAddress,
        selectedAddress,
        updatingShipment: true
      },
      this.updateAddress
    );
  };

  private handleValidateAddress = async (e) => {
    e.preventDefault();
    const addressToEdit = cloneDeep(this.state.addressToEdit);
    this.setState({validatingAddress: true});
    const addressInputs = get(e, 'target.elements');
    const addressParams = cloneDeep(addressToEdit);
    for (const input of addressInputs) {
      if (input.tagName !== 'INPUT') {
        continue;
      }
      addressParams[input.name] = input.value;
    }
    let errors = [];

    try {
      const response = await this.props.fulfillmentService.validateAddress(addressParams);
      if (!response.data.isValid) {
        errors = response.data.messages.map((m) => m.text);
        this.setState({
          selectedAddress: addressToEdit,
          validationErrors: errors
        });
      } else {
        this.setState({
          selectedAddress: response.data.address,
          addressIsValid: true,
          validationErrors: []
        });
      }
    } catch (errorResponse) {
      errors = this.props.fulfillmentService.processErrorResponse(errorResponse);
      this.setState({selectedAddress: addressToEdit, validationErrors: errors});
    } finally {
      this.setState({validatingAddress: false});
    }
  };

  private addressInputChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const addressToEdit = cloneDeep(this.state.addressToEdit);
    addressToEdit[field] = value;
    this.setState({addressToEdit, addressIsValid: false, validationErrors: []});
  };

  private handleUpdateAddress = () => {
    this.setState({updatingShipment: true}, this.updateAddress);
  };

  private async updateAddress() {
    let errors;
    try {
      await this.props.fulfillmentService.updateAddress(
        this.props.shipmentId,
        this.state.selectedAddress,
        this.state.forceGivenAddress
      );
      this.props.reloadShipments();
    } catch (errorResponse) {
      const errorItems = this.props.fulfillmentService
        .processErrorResponse(errorResponse)
        .map((error, i) => <li key={i}>{error}</li>);
      errors = (
        <div>
          There were issues updating your address:
          <ul>{errorItems}</ul>
        </div>
      );
      this.props.handleUpdateErrors(errors);
    } finally {
      this.setState(
        {
          addressIsValid: false,
          forceGivenAddress: false,
          selectedAddress: null,
          updatingShipment: false
        },
        this.props.toggleFixAddressModal
      );
    }
  }
}

export default RepairShipment;
