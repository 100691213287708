import * as React from 'react';
import {useCallback, useState} from 'react';
import {InfoType} from '@flexe/ui-components/dist/types/interfaces/InfoBoxInterfaces';
import {InfoBox} from '@flexe/ui-components';

export const useInfoBox = () => {
  const [noticeType, setNoticeType] = useState<InfoType>('info');
  const [message, setMessage] = useState('');

  const clearMessage = useCallback(() => {
    setTimeout(() => setMessage(''), 4000);
  }, [setMessage]);

  return {
    component: message !== '' ? <InfoBox info={message} infoType={noticeType} /> : null,
    setError(msg: string) {
      setNoticeType('error');
      setMessage(msg);
      clearMessage();
    },
    setInfo(msg: string) {
      setNoticeType('info');
      setMessage(msg);
      clearMessage();
    }
  };
};
