import * as React from 'react';
import {FC, useEffect, useRef, useState} from 'react';
import {formatDate} from './utilities/DataFormatting';

interface Props {
  value?: any;
  ghostText: string;
  startEnabled: boolean;
  inputType: string;
  maxLength?: number;
  minValue?: number;
  errorMessage?: string;
  onChange?: (val: any) => void;
  onSave?: () => void;
}

export const EditableValueDisplay: React.FC<Props> = (props) => {
  const [enabled, setEnabled] = useState<boolean>(props.startEnabled);

  const ref = useRef(null);
  const maxLength = props.maxLength || Number.MAX_SAFE_INTEGER;
  const minValue = 0;

  const displayString = (fallBackToGhostText: boolean) => {
    if (!props.value && fallBackToGhostText) {
      return props.ghostText;
    }

    if (props.value && props.value < minValue) {
      return minValue;
    }

    const str = props.value || '';
    switch (props.inputType) {
      case 'date': {
        if (enabled) {
          return formatDate(str, 'YYYY-MM-DD', 'UTC');
        } else {
          return formatDate(str, 'MMMM D', 'UTC');
        }
      }
      default: {
        return str.toString();
      }
    }
  };

  //focus on the input when editing so the user doesn't have to click on it
  useEffect(() => {
    if (enabled) {
      ref.current.focus();
    }
  }, [enabled]);

  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const onSave = () => {
    setEnabled(false);
    if (props.onSave) {
      props.onSave();
    }
  };

  return (
    <React.Fragment>
      {enabled ? (
        <div className="editable-value-display-wrapper">
          <div className="editable-value-display-input">
            <input
              type={props.inputType}
              value={displayString(false)}
              maxLength={maxLength}
              onChange={(event) => onChange(event.target.value)}
              ref={ref}
            />
            <a onClick={onSave}>Save</a>
          </div>
          {props.errorMessage && <div className="validation-error">{props.errorMessage}</div>}
        </div>
      ) : (
        <a onClick={() => setEnabled(true)}>{displayString(true)}</a>
      )}
    </React.Fragment>
  );
};

export default EditableValueDisplay;
