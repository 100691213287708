import {format} from 'date-fns';
import * as React from 'react';
import FlexeButton from '../shared/FlexeButton';
import SimpleKebabMenu from '../shared/SimpleKebabMenu';
import StatusTab from '../shared/StatusTab';
import * as WorkOrders from './WorkOrdersInterfaces';
import StartWorkOrderModal from './StartWorkOrderModal';
import {StartWorkOrderModalState} from './WorkOrdersIndex';

interface Props {
  workOrder: WorkOrders.KitWorkOrder;
  isShipper: boolean;
  showStartWorkOrderModal: StartWorkOrderModalState;
  toggleStartWorkOrderModalWithEvent: (event: any) => void;
  handleStart: (event: any) => void;
  disabled: boolean;
}

const WorkOrderListItem: React.FC<Props> = (props) => {
  const {
    workOrder,
    isShipper,
    showStartWorkOrderModal,
    disabled,
    toggleStartWorkOrderModalWithEvent,
    handleStart
  } = props;

  const renderNewIcon = () => {
    const now = new Date();
    const created = new Date(workOrder.createdAt);
    if (now.getTime() - created.getTime() < 3600000) {
      return <span className="new-indicator">New!</span>;
    }
  };

  const renderIdentifierColumn = () => {
    if (isShipper) {
      return (
        <div className="col-sm-2">
          <a href={`/s/work_orders/${workOrder.workOrderId}`} id={`detail-link-${workOrder.workOrderId}`}>
            <span className="wo-title-wrap">
              <span className="wo-title">{workOrder.kitSku}</span>
            </span>
            <span className="wo-subtitle">Work Order ID {workOrder.workOrderId}</span>
          </a>
        </div>
      );
    } else {
      return (
        <div className="col-sm-2">
          <a href={`/wh/work_orders/${workOrder.workOrderId}`} id={`detail-link-${workOrder.workOrderId}`}>
            <span className="wo-title-wrap">
              {renderNewIcon()}
              <span className="wo-title">{workOrder.shipper.name}</span>
            </span>
            <span className="wo-subtitle">Work Order ID {workOrder.workOrderId}</span>
          </a>
        </div>
      );
    }
  };

  const renderStatusColumn = () => {
    const statusMap = {
      new: 'neutral',
      inProgress: 'support',
      complete: 'valid',
      canceled: 'invalid'
    };
    return (
      <div className="col-sm-2">
        <StatusTab title={WorkOrders.WorkOrderStatusDisplay[workOrder.status]} status={statusMap[workOrder.status]} />
      </div>
    );
  };

  const renderWarehouseColumn = () => {
    if (isShipper) {
      return (
        <span className="col-sm-2">
          <span className="value-label">Warehouse</span>
          <span className="value">{workOrder.warehouse.name}</span>
        </span>
      );
    }
  };

  const renderTimelineColumn = () => {
    return (
      <div className="col-sm-3">
        <span className="col-sm-6">
          <span className="value-label">Date Requested</span>
          <span className="value">{format(workOrder.createdAt, 'MM/DD/YY @ h:mm a')}</span>
        </span>
        <span className="col-sm-1">
          <i className="fa fa-lg fa-chevron-circle-right"></i>
        </span>
        <span className="col-sm-5">
          <span className="value-label">Complete By</span>
          <span className="value">{format(workOrder.completeBy, 'MM/DD/YY')}</span>
        </span>
      </div>
    );
  };

  const renderSpacerColumns = () => {
    if (!isShipper && workOrder.status !== 'new') {
      return (
        <React.Fragment>
          <div className="col-sm-1"></div>
          <div className="col-sm-1"></div>
        </React.Fragment>
      );
    } else if (!isShipper) {
      return <div className="col-sm-1"></div>;
    }
  };

  const renderQuantityColumns = () => {
    return (
      <React.Fragment>
        <div className="col-sm-1">
          <span className="value-label-right">Qty Completed</span>
          <span className="value-right">{workOrder.quantityCompleted}</span>
        </div>
        <div className="col-sm-1">
          <span className="value-label-right">Qty Requested</span>
          <span className="value-right">{workOrder.quantityRequested}</span>
        </div>
      </React.Fragment>
    );
  };

  const renderStartColumn = () => {
    if (!isShipper && workOrder.status === 'new') {
      return (
        <div className="col-sm-1 flexe-btn-wrap">
          <FlexeButton
            testid={workOrder.workOrderId.toString()}
            id={workOrder.workOrderId.toString()}
            text="Start"
            level="confirm"
            handleClick={props.toggleStartWorkOrderModalWithEvent}
          />
        </div>
      );
    }
  };

  const renderActionsColumn = () => {
    if (isShipper) {
      return (
        <div className="col-sm-1 kebab-menu-wrap">
          <SimpleKebabMenu title={workOrder.kitSku} id={workOrder.workOrderId} />
        </div>
      );
    } else {
      const renderPrintIcon = () => {
        if (workOrder.status === 'new') {
          return <i className="fa fa-lg fa-print disabled-icon"></i>;
        } else {
          return (
            // hard code this path
            <a
              href={`/wh/work_orders/${workOrder.workOrderId}/instructions.pdf`}
              target="_blank"
              id="btn-print-work-order-docs"
            >
              <i className="fa fa-lg fa-print"></i>
            </a>
          );
        }
      };
      return (
        <div className="col-sm-1">
          <span className="col-sm-6 print-icon-wrap">{!isShipper && renderPrintIcon()}</span>
          <span className="col-sm-6 kebab-menu-wrap">
            <SimpleKebabMenu title={workOrder.kitSku} id={workOrder.workOrderId} />
          </span>
        </div>
      );
    }
  };

  return (
    <li className="work-order-list-item row">
      {renderIdentifierColumn()}
      {renderStatusColumn()}
      {renderWarehouseColumn()}
      {renderTimelineColumn()}
      {renderSpacerColumns()}
      {renderQuantityColumns()}
      {renderStartColumn()}
      {renderActionsColumn()}
      <StartWorkOrderModal
        showStartWorkOrderModal={showStartWorkOrderModal}
        toggleStartWorkOrderModalWithEvent={toggleStartWorkOrderModalWithEvent}
        disabled={disabled}
        workOrderId={workOrder.workOrderId}
        handleStart={handleStart}
      />
    </li>
  );
};

export default WorkOrderListItem;
