import * as React from 'react';
import {get} from 'lodash';
import {WarehouseLocation} from '../../shared/CommonInterfaces';
import TypeAhead, {TypeAheadOption} from '../../shared/TypeAhead';
import LocationsService from '../../locations/LocationsService';
import PickConsolidationService from '../../shared/services/PickConsolidationService';
import {LocationCategoryStrings, LocationStatus} from '../../locations/LocationsInterfaces';

interface StagingLocationTypeAheadState {
  locationQuery: string;
  outboundLocations: WarehouseLocation[];
  typeAheadOptions: TypeAheadOption[];
  errors?: string;
}

interface StagingLocationTypeAheadProps {
  warehouseId: number;
  locationsService: LocationsService;
  pickConsolidationService?: PickConsolidationService;
  usePickConsolidationService: boolean;
  shipmentIds?: number[];
  onSelectCallback: (locationId: string, locationLabel: string) => void;
  onTypeCallback?: (query: string) => void;
  currentLocationLabel?: string;
  disable?: boolean;
}

class StagingLocationTypeAhead extends React.Component<StagingLocationTypeAheadProps, StagingLocationTypeAheadState> {
  constructor(props) {
    super(props);

    let locationQuery: string = '';
    if (this.props.currentLocationLabel != null) {
      locationQuery = this.props.currentLocationLabel;
    }

    this.state = {
      locationQuery,
      outboundLocations: [],
      typeAheadOptions: []
    };
  }

  public render() {
    return (
      <div>
        {this.state.errors && <div className="alert alert-danger">{this.state.errors}</div>}
        <div className="space-below">
          <TypeAhead
            name="outbound-staging-location"
            placeholder="Type to search"
            disabled={this.props.disable}
            value={this.state.locationQuery}
            options={this.state.typeAheadOptions}
            onRequestOptions={this.handleTypeAheadQuery}
            onSelect={this.handleTypeAheadSelect}
          />
        </div>
      </div>
    );
  }

  private handleTypeAheadQuery = async (query: string) => {
    this.setState({locationQuery: query});
    if (this.props.onTypeCallback) {
      this.props.onTypeCallback(query);
    }
    if (query.length === 0) {
      this.handleTypeAheadSelect('');
      return;
    }
    if (query.length > 2) {
      try {
        let response;
        const limit = 10;
        if (this.props.pickConsolidationService !== null && this.props.usePickConsolidationService) {
          response = await this.props.pickConsolidationService.getStagingLocations(
            this.props.warehouseId,
            query,
            this.props.shipmentIds,
            limit
          );
        } else {
          response = await this.props.locationsService.getLocations(this.props.warehouseId, limit, null, {
            locationCategory: [LocationCategoryStrings.CATEGORY_OUTBOUND_STAGING],
            locationStatus: [LocationStatus.active],
            locationLabel: [query]
          });
        }
        const outboundLocations: WarehouseLocation[] = response.locations;
        const typeAheadOptions: TypeAheadOption[] = response.locations.map((loc) => {
          return {value: loc.id.toString(), displayName: loc.label};
        });
        this.setState({
          outboundLocations,
          typeAheadOptions
        });
      } catch {
        this.setState({
          outboundLocations: [],
          typeAheadOptions: [],
          errors: 'There was error retrieving outbound staging locations'
        });
      }
    }
  };

  private handleTypeAheadSelect = (locationId: string) => {
    let availableLocations: WarehouseLocation[] = [];
    let locationQuery: string;

    if (locationId) {
      availableLocations = this.state.outboundLocations.filter((locationContent) => {
        return locationContent.id === Number(locationId);
      });

      locationQuery = availableLocations.length > 0 ? get(availableLocations[0], 'label') : this.state.locationQuery;
    } else {
      locationQuery = '';
    }

    this.props.onSelectCallback(locationId, locationQuery);

    if (this.props.onTypeCallback) {
      this.props.onTypeCallback(locationQuery);
    }

    this.setState({
      locationQuery,
      typeAheadOptions: []
    });
  };
}

export default StagingLocationTypeAhead;
