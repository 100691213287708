import axios from 'axios';
import * as React from 'react';
import {get} from 'lodash';
import PalletizedDropoffRequest from './PalletizedDropoffRequest';

interface StepFourProps {
  reservationId: number;
  requests: PalletizedDropoffRequest[];
  authenticityToken: string;
  successRedirect: string;
}

interface StepFourState {
  successfulApiCall: boolean;
  pending: boolean;
  apiResponse?: any;
}

function Loading(props) {
  return (
    <div id="loading">
      <p className="note">Loading...</p>
    </div>
  );
}
function SuccessfulSubmission(props) {
  return (
    <div id="successful-submission">
      <p className="note">
        <i className="fa fa-check-circle text-success"></i>
        Your requests have been successfully submitted!
      </p>
    </div>
  );
}

function PartialSuccessSubmission(props) {
  const errors =
    props.data.data.errors.length > 0
      ? props.data.data.errors.map((error, index) => {
          return <li key={index}>{error}</li>;
        })
      : props.data.data.failed_uuids.map((failure, index) => {
          return <li key={index}>UUID {failure}</li>;
        });
  return (
    <div id="partial-success-submission">
      <p className="note">
        <i className="fa fa-exclamation-circle text-warning"></i>
        Some of your requests have been submitted successfully. The following shipments failed during processing:
      </p>
      <ul className="errors">{errors}</ul>
    </div>
  );
}

function FailedSubmission(props) {
  const errors = get(props, 'data.data.errors', []);
  const errorsList = errors.map((error, index) => {
    return <li key={index}>{error}</li>;
  });
  return (
    <div id="failed-submission">
      <p className="note">
        <i className="fa fa-times-circle text-danger"></i>
        None of your request were submitted. Please resubmit requests.
      </p>
      <ul className="errors">{errorsList}</ul>
    </div>
  );
}

class StepFour extends React.Component<StepFourProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      successfulApiCall: false
    };
  }

  public componentDidMount() {
    axios
      .post('/s/dropoffs/palletized/bulk_create', {
        authenticity_token: this.props.authenticityToken,
        data: this.prepareData(),
        reservation_id: this.props.reservationId
      })
      .then((res) => {
        this.setState({
          apiResponse: res.data,
          pending: false,
          successfulApiCall: true
        });
        if (res.data.data.succeeded_uuids && res.data.data.succeeded_uuids.length === this.props.requests.length) {
          window.location.href = this.props.successRedirect;
        }
      })
      .catch((error) => {
        this.setState({
          apiResponse: error.response.data,
          pending: false,
          successfulApiCall: false
        });
      });
  }

  public render() {
    let response = null;
    if (this.state.pending) {
      response = <Loading />;
    } else {
      const data = this.state.apiResponse.data;
      // eslint-disable-next-line no-prototype-builtins
      const failures = data && data.hasOwnProperty('failed_uuids') ? data.failed_uuids.length : 0;
      const successes =
        // eslint-disable-next-line no-prototype-builtins
        data && data.hasOwnProperty('succeeded_uuids') ? this.state.apiResponse.data.succeeded_uuids.length : 0;
      if (this.state.successfulApiCall && this.state.apiResponse && failures === 0 && successes > 0) {
        response = <SuccessfulSubmission />;
      } else if (this.state.successfulApiCall && this.state.apiResponse && failures > 0 && successes > 0) {
        response = <PartialSuccessSubmission data={this.state.apiResponse} />;
      } else {
        response = <FailedSubmission data={this.state.apiResponse} />;
      }
    }

    return (
      <li id="step-4" className="active">
        <span className="label">4</span>
        <div className="step-wrap">
          <h3>
            <span>Requests Submitted</span>
          </h3>
          {response}
        </div>
      </li>
    );
  }

  private prepareData() {
    return this.props.requests.map((request) => {
      return {
        move_date: request.moveDate,
        customer_uuid: request.shipmentId,
        trailer_number: request.trailerNumber,
        description: request.description,
        inventory_data: request.inventoryData.map((inventory) => {
          inventory.attachments = [];
          return inventory;
        })
      };
    });
  }
}

export default StepFour;
