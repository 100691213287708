export enum WorkOrderType {
  kitToStock = 'kitToStock'
}

export enum WorkOrderStatusDisplay {
  new = 'Not Started',
  inProgress = 'In Progress',
  cancelled = 'Canceled',
  canceled = 'Canceled', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  complete = 'Complete'
}

export enum CycleCountType {
  item = 'InventoryCycleCount',
  location = 'LocationCycleCount'
}

export interface KitWorkOrder {
  workOrderId: number;
  kitSku: string;
  kitDescription: string;
  quantityRequested: number;
  quantityCompleted: number;
  status: string;
  createdAt: Date;
  completeBy: Date;
  completedAt?: Date;
  shipper: WorkOrderShipper;
  warehouse: WorkOrderWarehouse;
}

export interface WipLocationInformation {
  locationId: number;
  locationName: string;
  kitId: number;
  maxKitsCanBeBuilt: number;
}

export interface KitWorkOrderDetail {
  workOrderId: number;
  reservationId: number;
  shipper: {id: number; name: string};
  warehouse: {id: number; name: string};
  completeBy: string;
  completedAt: string;
  completedById: string;
  createdAt: string;
  description: string;
  kitInventoryId: number;
  kitSku: string;
  quantityCompleted: number;
  quantityRequested: number;
  status: string;
  instructions: string;
}

// A SKU that is IN a kit
export interface KitItem {
  id: number;
  sku: string;
  description: string;
  packaging: string;
  count: number;
}

// A SKU that is a kit
// TODO: get the real structure of this from IMS
export interface KitSku {
  id: number;
  sku: string;
  description: string;
  kitItems: KitItem[];
}

export interface KitSkusRequest {
  reservationId: number;
}

export interface KitWorkOrderCreationRequest {
  reservationId: number;
  kitInventoryId: number;
  qtyRequested: number;
  instructions: string;
  companyId: number;
  completeByDate: Date;
}

export interface KitWorkOrderCreationResponse {
  createdKitWorkOrderId: number;
}

export interface KitWorkOrderStartRequest {
  id: number;
}

export interface KitWorkOrderStartResponse {
  updatedKitWorkOrder: KitWorkOrderDetail;
}

export interface KitWorkOrderCompleteResponse {
  kitWorkOrderId: number;
}

export interface WorkOrderReservation {
  displayName: string;
  id: number;
  warehouseCity: string;
  warehouseId: number;
  warehouseState: string;
}

export interface WorkOrderShipper {
  id: number;
  name: string;
}

export interface WorkOrderWarehouse {
  id: number;
  name: string;
}

export interface AddCompletedKitsRequest {
  kitsCompleted: number;
  locationId: number;
}

export interface AddCompletedKitsResponse {
  kitWorkOrderId: number;
}
