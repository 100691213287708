import InternalAPIServiceV2 from '../../shared/services/InternalAPIServiceV2';
import {Reservations} from './ReservationsInterfaces';

class ReservationsService extends InternalAPIServiceV2 {
  constructor() {
    super('/api/v1/reservations');
  }

  public async getReservations(warehouseId?: number): Promise<Reservations> {
    return await this.makeGetRequest(
      this.baseUrl,
      {warehouse_id: warehouseId},
      //need this to make axios respect content-type header, ref: https://github.com/axios/axios/issues/89
      {},
      {'Content-Type': 'application/json'}
    );
  }

  public async getReservationDetails(id: number): Promise<Reservations> {
    return await this.makeGetRequest(
      `${this.baseUrl}/${id}`,
      //need this to make axios respect content-type header, ref: https://github.com/axios/axios/issues/89
      {},
      {'Content-Type': 'application/json'}
    );
  }
}

export default ReservationsService;
