import * as React from 'react';

export interface SelectableReservation {
  id: number;
  warehouseName: string;
  warehouseCity: string;
  warehouseState: string;
  warehouseTimezone: string;
  crossdockEnabled: boolean;
}

interface ReservationSelectorProps {
  selectedReservation: SelectableReservation;
  reservations: SelectableReservation[];
  onSelect(selectedReservation: SelectableReservation);
}

const ReservationSelector: React.FC<ReservationSelectorProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    // Close the dropdown when the user clicks off of it
    document.body.addEventListener('mouseup', closeDropdownIfClickedElsewhere);

    return () => {
      // Remove the event handler on the body when the component unmounts
      document.body.removeEventListener('mouseup', closeDropdownIfClickedElsewhere);
    };
  });

  const getReservationFriendlyName = (res: SelectableReservation): string => {
    return `#${res.id}: ${res.warehouseName}: ${res.warehouseCity}, ${res.warehouseState}`;
  };

  const createReservationOption = (res: SelectableReservation, selectedReservation: SelectableReservation) => {
    const selectedClass = res.id === selectedReservation.id ? 'selected' : '';
    return (
      <li key={res.id}>
        <a href="#" onClick={(event) => handleSelect(event)} className={selectedClass} data-id={res.id}>
          {getReservationFriendlyName(res)}
        </a>
      </li>
    );
  };

  const reservationOptions = props.reservations.map((res) => createReservationOption(res, props.selectedReservation));

  const toggleDropdown = (event) => {
    event.preventDefault();
    setOpen(!open);
  };

  const closeDropdownIfClickedElsewhere = (event) => {
    let element = event.target;
    while (element.parentNode) {
      if (element.classList && element.classList.contains('reservation-selector')) {
        // Do nothing
        return;
      } else {
        element = element.parentNode;
      }
    }
    event.preventDefault();
    setOpen(false);
  };

  const handleSelect = (event) => {
    event.preventDefault();
    const selectedId = parseInt(event.currentTarget.getAttribute('data-id'), 10);

    const selectedReservation = props.reservations.find((res) => {
      return res.id === selectedId;
    });

    if (selectedReservation) {
      props.onSelect(selectedReservation);
      setOpen(false);
    }
  };

  return (
    <div className="dropdown reservation-selector">
      <a className="selected-reservation" title="Select a reservation..." onClick={(event) => toggleDropdown(event)}>
        <span className="val">{getReservationFriendlyName(props.selectedReservation)}</span>
        <span className="caret"></span>
      </a>
      {open && <ul className="dropdown-menu right-align show">{reservationOptions}</ul>}
    </div>
  );
};

export default ReservationSelector;
