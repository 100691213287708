import {Reservation} from '../outbound-orders/ReservationsInterfaces';

export interface OMSReservation {
  id: number;
  txnState: string;
  displayName: string;
  distributionByLot: boolean;
  omniEnabled: boolean;
  retailEnabled: boolean;
  ecommerceEnabled: boolean;
}

export interface OMSFeatureFlags {
  showOrderCancellationByCsvButton: boolean;
  showNewOrdersPage: boolean;
  showNewOrderLineModal: boolean;
  showCalculatedShipByDate: boolean;
  attachmentsTabEnabled: boolean;
}

export const reservationsToOMSReservations = (
  reservations: Reservation[],
  distributionByLotIds: string[]
): OMSReservation[] => {
  return reservations.map((res) => {
    return {
      id: +res.id,
      txnState: res.txn_state,
      displayName: `${res.id}: ${res.warehouse.name}, ${res.warehouse.address.locality}`,
      distributionByLot: distributionByLotIds.some((id) => id === res.id.toString()),
      omniEnabled: res.omni_enabled,
      retailEnabled: res.retail_enabled,
      ecommerceEnabled: res.ecommerce_enabled
    };
  });
};
