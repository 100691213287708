import * as React from 'react';
import * as _ from 'lodash';
import {useContext, useMemo} from 'react';
import * as moment from 'moment-timezone';
import {InventoryRequirement, PickStep} from '../WaveBatchInterfaces';
import {BatchDetailsContext} from './BatchDetailsContext';

interface LocationInfo {
  id: number;
  name: string;
}

interface FromLpnInfo {
  sourceLpn: string;
  isFullLpn: boolean;
}

interface PickStepViewModel {
  key: string;
  sourceLocation: LocationInfo[];
  status: string;
  requiredQuantity: number;
  pickedQuantity: number;
  pickedUom?: string;
  requiredUom: string;
  itemId: number;
  itemSku: string;
  lpnBarcode: string;
  locationName?: string;
  itemDescription?: string;
  fromLpnInfos: FromLpnInfo[];
  inventoryRequirement: InventoryRequirement | null;
}

interface Props {
  displayFromLpnColumn: boolean;
}

const BatchContentsTable: React.FC<Props> = ({displayFromLpnColumn}) => {
  const {batch} = useContext(BatchDetailsContext);

  const [totalPicked, totalExpected] = useMemo(() => {
    const totals = batch.pickSteps.reduce(
      (previous, pickStep) => ({
        picked: previous.picked + pickStep.pickedQuantity,
        expected: previous.expected + pickStep.requiredQuantity
      }),
      {picked: 0, expected: 0}
    );

    return [totals.picked, totals.expected];
  }, [batch]);

  const displayPickRequirementsColumn = batch.pickSteps.some((step) => step.inventoryRequirement);

  return (
    <div className="widget-container">
      <div className="container-fluid">
        <div className="header" style={{paddingBottom: '16px'}}>
          <h2>Contents</h2>
          <div className="widget-details">
            <div className="info-pair">
              <span className="title">Expected:</span>
              <span className="value">{totalExpected}</span>
            </div>
            <div className="info-pair">
              <span className="title">Picked:</span>
              <span className="value">{totalPicked}</span>
            </div>
          </div>
        </div>
        <table className="table contents-table">
          <thead>
            <tr>
              <th>SKU</th>
              <th>{displayPickRequirementsColumn ? 'Pick Requirements' : 'Description'}</th>
              <th>Expected</th>
              <th>Picked</th>
              <th>Loc. Picked</th>
              {displayFromLpnColumn && <th>From LPN</th>}
            </tr>
          </thead>
          <tbody>
            {generatePickStepViewModels(batch.pickSteps).map((viewModel) => (
              <BatchContentRow
                key={viewModel.key}
                displayFromLpnColumn={displayFromLpnColumn}
                displayPickRequirementsColumn={displayPickRequirementsColumn}
                pickStepViewModel={viewModel}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const generatePickStepViewModels = (steps: PickStep[]): PickStepViewModel[] => {
  const viewModelsByKey: {[key: string]: PickStepViewModel} = {};
  steps.forEach((step) => {
    const viewModelKey = `${step.itemId}${
      step.inventoryRequirement?.lotCode ? `-${step.inventoryRequirement.lotCode}` : ''
    }${step.inventoryRequirement?.expirationDate ? `-${step.inventoryRequirement?.expirationDate}` : ''}`;

    const existingViewModel = viewModelsByKey[viewModelKey];

    if (existingViewModel) {
      existingViewModel.requiredQuantity += step.requiredQuantity;
      existingViewModel.pickedQuantity += step.pickedQuantity;

      if (step.sourceLocation) {
        existingViewModel.sourceLocation = [
          ...existingViewModel.sourceLocation,
          {id: step.sourceLocation, name: step.locationName}
        ];
      }

      existingViewModel.fromLpnInfos = [...existingViewModel.fromLpnInfos, ...getFromLpnInfos(step)];
    } else {
      viewModelsByKey[viewModelKey] = {
        key: viewModelKey,
        sourceLocation: [...(step.sourceLocation ? [{id: step.sourceLocation, name: step.locationName}] : [])],
        status: step.status,
        requiredQuantity: step.requiredQuantity,
        requiredUom: step.requiredUom,
        pickedQuantity: step.pickedQuantity,
        pickedUom: step.pickedUom,
        itemId: step.itemId,
        itemSku: step.itemSku,
        lpnBarcode: step.lpnBarcode,
        itemDescription: step.itemDescription,
        fromLpnInfos: getFromLpnInfos(step),
        inventoryRequirement: step.inventoryRequirement
      };
    }
  });

  return Object.values(viewModelsByKey).sort((a, b) => (a.key > b.key ? 1 : a.key === b.key ? 0 : -1));
};

const BatchContentRow = ({
  displayFromLpnColumn,
  displayPickRequirementsColumn,
  pickStepViewModel
}: {
  displayFromLpnColumn: boolean;
  displayPickRequirementsColumn: boolean;
  pickStepViewModel: PickStepViewModel;
}) => (
  <tr>
    <td>
      <a href={`/wh/inventories/${pickStepViewModel.itemId}`} target="_blank">
        {pickStepViewModel.itemSku}
      </a>
      {displayPickRequirementsColumn && <div>{pickStepViewModel.itemDescription}</div>}
    </td>
    <td>
      {displayPickRequirementsColumn
        ? pickStepViewModel.inventoryRequirement && (
            <>
              <div>
                <span style={{fontWeight: 'bold'}}>LOT:</span> {pickStepViewModel.inventoryRequirement.lotCode}
              </div>
              {pickStepViewModel.inventoryRequirement.expirationDate && (
                <div>
                  <span style={{fontWeight: 'bold'}}>EXP:</span>{' '}
                  {moment(pickStepViewModel.inventoryRequirement.expirationDate).format('MMM D, YYYY')}
                </div>
              )}
            </>
          )
        : pickStepViewModel.itemDescription}
    </td>
    <td>{pickStepViewModel.requiredQuantity}</td>
    <td>{pickStepViewModel.pickedQuantity}</td>
    <td>
      {_.uniqBy<LocationInfo>(pickStepViewModel.sourceLocation, 'id').map((location, i) => (
        <span key={`${location.id}${i}`}>
          {i > 0 && ', '}
          <a href={`/wh/locations/${location.id}`} target="_blank">
            {location.name}
          </a>
        </span>
      ))}
    </td>
    {displayFromLpnColumn && <FromLpnTableCell fromLpnInfos={pickStepViewModel.fromLpnInfos} />}
  </tr>
);

const FromLpnTableCell = ({fromLpnInfos}: {fromLpnInfos: FromLpnInfo[]}) => {
  return (
    <td>
      {fromLpnInfos.map((fromLpnInfo, index) => (
        <div key={fromLpnInfo.sourceLpn}>
          <a href={`/wh/lpns/${fromLpnInfo.sourceLpn}`} target="_blank">
            {fromLpnInfo.sourceLpn}
          </a>
          {fromLpnInfo.isFullLpn && <span className="contents-table__full-lpn-label">Full LPN</span>}
          {index !== fromLpnInfos.length - 1 && ','}
        </div>
      ))}
    </td>
  );
};

const getFromLpnInfos = (pickStep: PickStep): FromLpnInfo[] => [
  ...(pickStep.status.toLowerCase() !== 'open' && pickStep.sourceLpn ? [pickStepToFromLpnInfo(pickStep)] : [])
];

const pickStepToFromLpnInfo = (pickStep: PickStep): FromLpnInfo => ({
  sourceLpn: pickStep.sourceLpn,
  isFullLpn: pickStep.sourceLpn !== null && pickStep.destinationLpn !== null
});

export default BatchContentsTable;
