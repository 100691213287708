import * as React from 'react';
import {SortOrder} from './CommonInterfaces';

interface SortableTableHeaderProps {
  headerText: string;
  isActive: boolean;
  sortDirection?: string;
}

const SortableTableHeader: React.FC<SortableTableHeaderProps> = (props) => {
  return (
    <div className="sortable-table-header">
      {props.headerText}
      {props.isActive && (
        <i className={`fa fa-sort-desc ${props.sortDirection === SortOrder.DESC ? '' : 'gray-icon'}`}></i>
      )}
      {props.isActive && (
        <i className={`fa fa-sort-asc ${props.sortDirection === SortOrder.ASC ? '' : 'gray-icon'}`}></i>
      )}
      {!props.isActive && <i className="fa fa-sort"></i>}
    </div>
  );
};

export default SortableTableHeader;
