import {TransitionState} from '../../shared/CommonInterfaces';
import {OrderStateCounts} from './BatchInterfaces';

/**
 * Get the count of orders that are in a given transition state from the counts object.
 * There is a one to many mapping between order state and transition state.
 * @param statusCounts An object that maps order state to count of orders in that state
 * @param transitionState Which batch state to look up order counts for
 * @returns An int that is the number of orders for a given state.
 */
export function getOrderCountForState(statusCounts: OrderStateCounts, transitionState: TransitionState): number {
  let count = 0;

  if (transitionState === TransitionState.Completed) {
    count = statusCounts.completed + statusCounts.packed;
  } else {
    count = statusCounts[transitionState];
  }

  return count;
}
