import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {GridColDef, GridPaginationModel, GridRenderCellParams, GridToolbar} from '@mui/x-data-grid';
import {Box, Pagination} from '@flexe/ui-components';
import {useCallback, useState} from 'react';
import {flexeMuiTheme} from '../shared/mui/default-mui-theme';
import {FlexeDataGrid} from '../shared/mui/flexe-data-grid/FlexeDataGrid';
import {Rule} from './SpaConfigurationsInterfaces';

interface Props {
  warehouseId: number;
  pageSize: number;
  rules: Rule[];
  totalRulesCount: number;
  currentPage: number;
  displayNewRulesTable: boolean;

  handlePageChange(currentPage);
  handlePageSizeChange(pageSize);
}

export function RulesTable(props: Props) {
  const {pageSize, rules, totalRulesCount, currentPage, displayNewRulesTable} = props;

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize
  });

  const pageChangeHandlerForDataGrid = useCallback((newPageModel: GridPaginationModel) => {
    const newPage = newPageModel.page;
    const newPageSize = newPageModel.pageSize;

    setPaginationModel(newPageModel);

    if (newPage !== paginationModel.page) {
      props.handlePageChange(newPage);
    }

    if (newPageSize !== paginationModel.pageSize) {
      props.handlePageSizeChange(newPageSize);
    }
  }, []);

  const pageChangeHandlerForTraditionalTable = useCallback((event) => {
    const size = parseInt(event.target.value, 10);
    props.handlePageSizeChange(size);
  }, []);

  return displayNewRulesTable ? (
    <ThemeProvider theme={flexeMuiTheme}>
      <div>
        <FlexeDataGrid
          columns={getColumns()}
          columnVisibilityModel={getColumnVisibilityModel(rules)}
          rows={rules}
          getRowHeight={() => 'auto'}
          rowCount={totalRulesCount}
          pageSizeOptions={[50, 100]} // we cannot go over 100. only possible for DataGridPro version
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(pageModel, callBackDetails) => pageChangeHandlerForDataGrid(pageModel)}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '8px'}
          }}
          disableColumnFilter
          disableColumnSelector
          disableRowSelectionOnClick
          disableDensitySelector
          slots={{toolbar: GridToolbar, noRowsOverlay: NoRowsOverlay}}
          disableVirtualization // require for test. Without this, not all columns will be visible during test
          slotProps={{
            toolbar: {
              // display only search box. remove export functionality
              showQuickFilter: true,
              printOptions: {disableToolbarButton: true},
              csvOptions: {disableToolbarButton: true}
            }
          }}
        />
      </div>
    </ThemeProvider>
  ) : (
    <div>
      <table className="spa rules table">
        <thead>
          <tr key="ruleTableHead">
            <th>Priority</th>
            <th>Reservation ID</th>
            <th>SKU</th>
            <th>Packaging</th>
            <th>Item Velocity</th>
            <th>Is Hazmat?</th>
            <th>Lot Code Tracked</th>
            <th>Expiration Date Tracked</th>
            <th>Ship Alone</th>
            <th>Ship As Is</th>
            <th>Route to Zone Name</th>
            <th>Route to Location Category</th>
            <th>Route to Location</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        {!rules.length
          ? renderEmptyTable()
          : rules.map((rule: Rule, locIdx: number) => {
              let ruleRows = [];
              ruleRows = ruleRows.concat(renderRuleRow(rule));
              return <tbody key={rule.id}>{ruleRows}</tbody>;
            })}
        <tfoot>
          <tr>
            <th colSpan={12}>
              <Pagination
                page={currentPage}
                pageSize={pageSize}
                paginationHandler={(page) => props.handlePageChange(page)}
                totalCount={totalRulesCount}
              />
            </th>
          </tr>
        </tfoot>
      </table>
      <div className="form-group pull-right">
        <label htmlFor="pageSizeSelect">Select Page Size</label>
        <select
          className="form-control"
          id="pageSizeSelect"
          value={props.pageSize}
          onChange={pageChangeHandlerForTraditionalTable}
        >
          <option>50</option>
          <option>100</option>
          <option>150</option>
          <option>200</option>
          <option>250</option>
          <option>300</option>
        </select>
      </div>
    </div>
  );
}

function getColumns(): GridColDef[] {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Priority',
      type: 'number',
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'reservationId',
      headerName: 'Reservation id',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'sku',
      headerName: 'Sku',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'packaging',
      headerName: 'Packaging',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'itemVelocity',
      headerName: 'Item Velocity',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'isHazmat',
      headerName: 'Hazmat?',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'isLotCodeTracked',
      headerName: 'Lot Code Tracked',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'isExpirationDateTracked',
      headerName: 'Expiration Date Tracked',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'isShipAlone',
      headerName: 'Ship Alone',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'isShipAsIs',
      headerName: 'Ship As Is',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'csa',
      headerName: 'Customer Specific Attributes',
      width: 220,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Rule>) => (
        <div>
          {Object.keys(params.row.csa).map((key, index) => {
            return (
              <div key={index}>
                {key}: {params.row.csa[key]}
              </div>
            );
          })}
        </div>
      )
    },
    {
      field: 'zoneNames',
      headerName: 'Route to Zone Name',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'routeToLocationCategory',
      headerName: 'Route to Location Category',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    },
    {
      field: 'routeToLocation',
      headerName: 'Route to Location',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true
    }
  ];

  return columns;
}

function NoRowsOverlay() {
  return <Box>No Zone Templates have been uploaded</Box>;
}

function renderEmptyTable() {
  return [
    <tbody key="emptyTable">
      <tr>
        <td colSpan={13} style={{textAlign: 'center', padding: '70px'}}>
          No Zone Templates have been uploaded
        </td>
      </tr>
    </tbody>
  ];
}

function renderRuleRow(rule: Rule) {
  return [
    <tr key={rule.id}>
      <td key="priority" data-testid="rule-priority">
        {rule.id}
      </td>
      <td key="reservationId">{rule.reservationId}</td>
      <td key="sku">{rule.sku}</td>
      <td key="packaging">{rule.packaging}</td>
      <td key="itemVelocity">{rule.itemVelocity}</td>
      <td key="isHazmat">{rule.isHazmat}</td>
      <td key="lotCodeTracked">{rule.isLotCodeTracked}</td>
      <td key="expireDateTracked">{rule.isExpirationDateTracked}</td>
      <td key="isShipAlone">{rule.isShipAlone}</td>
      <td key="isShipAsIs">{rule.isShipAsIs}</td>
      <td key="routeToZoneName">{rule.zoneNames}</td>
      <td key="routeToLocationCategory">{rule.routeToLocationCategory}</td>
      <td key="routeToLocation">{rule.routeToLocation}</td>
    </tr>
  ];
}

function getColumnVisibilityModel(rules: Rule[]) {
  const isCSAVisible = (rule) => Object.keys(rule.csa).length > 0;
  // if all CSA objects are empty, no need to render the CSA column
  const enableCsaColumn = rules.some(isCSAVisible);
  const model = {
    csa: enableCsaColumn
  };
  return model;
}

export default RulesTable;
