import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {TransitionState} from '../../../shared/CommonInterfaces';
import {TransitionStateDisplay} from '../../../shared/constants';
import {formatDate} from '../../../shared/utilities/DataFormatting';
import {PickWaveStatus} from '../WaveBatchInterfaces';
import {WaveDetailsContext} from './WaveDetailsContext';

interface Props {
  displayPutWallField: boolean;
  displayOrderByField: boolean;
}

const WavesDetailsHeader: React.FC<Props> = ({displayPutWallField, displayOrderByField}) => {
  const {wave} = useContext(WaveDetailsContext);
  const [waveTransitionStatus, setWaveTransitionStatus] = useState<TransitionState>(null);
  const waveDate = formatDate(new Date(wave.waveRequest?.createdAt), 'h:mm a, MMM D');
  useEffect(() => {
    setWaveTransitionStatus(waveStatusToTransitionState(wave.status));
  }, []);

  const waveStatusToTransitionState = (status: string) => {
    switch (status) {
      case PickWaveStatus.new:
      case PickWaveStatus.not_started:
        return TransitionState.NotStarted;
      case PickWaveStatus.in_progress:
        return TransitionState.InProgress;
      case PickWaveStatus.completed:
        return TransitionState.Completed;
      case PickWaveStatus.cancelled:
        return TransitionState.Cancelled;
      default:
        return TransitionState.NotStarted;
    }
  };

  const hasOrderByReqs = wave.waveAttributes.orderBy && displayOrderByField;
  return (
    <>
      <header>
        <h1>Wave #{wave.id}</h1>
        <span className={`wave-status ${waveTransitionStatus}`}>
          {TransitionStateDisplay.get(waveTransitionStatus)}
        </span>
      </header>
      {wave.reservation && (
        <div className="reservation-info">
          <span>
            {`#${wave.reservation?.id} - ${wave.reservation?.shipper_name}`} &raquo; {wave.reservation?.warehouse_name}
          </span>
        </div>
      )}
      <div className="wave-info">
        <div className="wave-info-section">
          <div className="info-pair">
            <span className="title">Waved at:</span>
            <span className="value">{waveDate}</span>
          </div>
          <div className="info-pair">
            <span className="title">Staging Location:</span>
            <span className="value">{wave.stagingLocation?.label ?? '--'}</span>
          </div>
        </div>

        <div className="divider"></div>

        <div className="wave-info-section">
          <div className="info-pair">
            <span className="title">Destination Id:</span>
            <span className="value">{wave.waveAttributes?.destinationTag ?? '--'}</span>
          </div>

          <div className="info-pair">
            <span className="title">Load Group:</span>
            <span className="value">{wave.waveAttributes?.loadGroup ?? '--'}</span>
          </div>
        </div>
        {(displayPutWallField || hasOrderByReqs) && (
          <>
            <div className="vertical-spacer"></div>
            <div className="wave-info-section">
              {displayPutWallField && (
                <div className="info-pair">
                  <span className="title">Put Wall:</span>
                  <span className="value">{wave.sortMhe?.label ?? '--'}</span>
                </div>
              )}
              {hasOrderByReqs && (
                <div className="info-pair">
                  <span className="title">Pick Requirements:</span>
                  <span className="value">Yes</span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WavesDetailsHeader;
