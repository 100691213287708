import * as React from 'react';
import {FilterOption} from './CommonInterfaces';

interface SelectorProps {
  className?: string;
  handleSelection: any;
  header: string;
  options: FilterOption[];
  selected: FilterOption;
  title: string;
}

interface SelectorState {
  showDropdown: boolean;
}

class Selector extends React.Component<SelectorProps, SelectorState> {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false
    };
  }

  public render() {
    const options = this.props.options
      ? this.props.options.map((option) => {
          return (
            <li key={option.value}>
              <a onClick={this.handleClick(option)}>{option.displayName}</a>
            </li>
          );
        })
      : null;

    return (
      <div className="selector-component">
        <h4>
          <div className={this.props.className ? `dropdown selector ${this.props.className}` : 'dropdown selector'}>
            <a onClick={this.toggleDropdown} title={this.props.title}>
              <span className="val">{this.props.selected.displayName}</span>
              <span className="caret"></span>
            </a>
            {this.state.showDropdown && (
              <ul className="dropdown-menu right-align show">
                <li className="dropdown-header">{this.props.header}</li>
                {options}
              </ul>
            )}
          </div>
        </h4>
      </div>
    );
  }

  private handleClick = (option) => () => {
    this.toggleDropdown();
    this.props.handleSelection(option);
  };

  private toggleDropdown = () => {
    this.setState({
      showDropdown: !this.state.showDropdown
    });
  };
}

export default Selector;
