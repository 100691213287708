import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';

interface Props {
  showBulkStartModal: boolean;
  disabled: boolean;
  transitionCycleCountState: (event: any) => Promise<void>;
  toggleBulkStartModal: () => void;
  numberOfCounts: number;
}

const CycleCountBulkStartModal: FunctionComponent<Props> = (props) => {
  return (
    <LegacyModal
      id="bulk-start-cycle-count-modal"
      title="Start Counts"
      show={props.showBulkStartModal}
      size="small"
      toggleModal={props.toggleBulkStartModal}
      footer={
        <div>
          <a onClick={props.toggleBulkStartModal}>Back</a>
          <button
            id="perform-cancel"
            type="button"
            className="btn btn-primary"
            disabled={props.disabled}
            onClick={props.transitionCycleCountState}
          >
            Start
          </button>
        </div>
      }
      disableClose={false}
    >
      <div>
        <b>Warning!</b>
        <p>
          Starting counts for <b>{props.numberOfCounts}</b> locations. Starting will lock all associated locations. This
          will block all inventory movements in and out of these locations until the counts have been completed and
          approved.
        </p>
      </div>
    </LegacyModal>
  );
};

export default CycleCountBulkStartModal;
