import * as React from 'react';
import {FC, useState} from 'react';
import {UpsertLoadState} from '../LoadInterfaces';
import {VacantDockLocation} from '../ManifestInterfaces';
import {UpsertMode} from './UpsertLoadModal';

interface Props {
  loadState: UpsertLoadState;
  upsertMode: UpsertMode;
  dockLocs: VacantDockLocation[];
  onEdit: (editState: UpsertLoadState) => void;
}

export const LoadInfo: FC<Props> = (props) => {
  const [loadState, setLoadState] = useState<UpsertLoadState>(props.loadState);

  const dockLocationOptions = () => {
    // provide a blank option to enable un-assignment
    const blankOption = (
      <option id={'dock-location-chosen-option'} key={'blank-doc'} value={''}>
        {''}
      </option>
    );

    const remainingOptions = props.dockLocs.map((loc) => (
      <option
        aria-label="dockLocationOptions"
        id={loc.location_id.toString()}
        key={loc.location_label}
        value={loc.location_label}
      >
        {loc.location_label}
      </option>
    ));

    return [blankOption, remainingOptions];
  };

  const handleScacChange = (newScac: string) => {
    const newState = {
      ...loadState,
      scac: newScac.toUpperCase()
    };
    setLoadState(newState);
    props.onEdit(newState);
  };

  const handleTrailerNumberChange = (newTrailerNumber: string) => {
    const newState: UpsertLoadState = {
      ...loadState,
      trailerNumber: newTrailerNumber
    };
    setLoadState(newState);
    props.onEdit(newState);
  };

  const handleProNumberChange = (newProNumber: string) => {
    const newState: UpsertLoadState = {
      ...loadState,
      proNumber: newProNumber
    };
    setLoadState(newState);
    props.onEdit(newState);
  };

  const handleSealNumberChange = (newSealNumber: string) => {
    const newState: UpsertLoadState = {
      ...loadState,
      sealNumber: newSealNumber
    };
    setLoadState(newState);
    props.onEdit(newState);
  };

  const handleDockLocationChange = (newDockLocationLabel: string) => {
    const newState: UpsertLoadState = {
      ...loadState,
      dockLocationLabel: newDockLocationLabel,
      dockLocationId: props.dockLocs.find((loc) => loc.location_label === newDockLocationLabel)?.location_id
    };
    setLoadState(newState);
    props.onEdit(newState);
  };

  return (
    <div>
      <div className="form-group">
        <label className="col-form-label">SCAC:</label>
        <input
          type="text"
          className="form-control"
          name="scac"
          aria-label="scac"
          value={loadState?.scac || ''}
          onChange={(e) => handleScacChange(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Trailer Number:</label>
        <input
          type="text"
          className="form-control"
          name="trailerNumber"
          aria-label="trailerNumber"
          value={loadState?.trailerNumber || ''}
          onChange={(e) => handleTrailerNumberChange(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Pro Number:</label>
        <input
          type="text"
          className="form-control"
          name="proNumber"
          aria-label="proNumber"
          value={loadState?.proNumber || ''}
          onChange={(e) => handleProNumberChange(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Seal Number:</label>
        <input
          type="text"
          className="form-control"
          name="sealNumber"
          aria-label="sealNumber"
          value={loadState?.sealNumber || ''}
          onChange={(e) => handleSealNumberChange(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Dock Location:</label>
        <select
          className="form-control"
          name="dockLocationSelect"
          aria-label="dockLocationSelect"
          id={'dock-location-select'}
          onChange={(e) => handleDockLocationChange(e.target.value)}
          value={loadState?.dockLocationLabel || ''}
        >
          {dockLocationOptions()}
        </select>
      </div>
      {props.upsertMode === UpsertMode.CREATE && (
        <div className="message">
          <p>* These fields can be filled out later.</p>
        </div>
      )}
    </div>
  );
};

export default LoadInfo;
