import * as React from 'react';
import classNames from 'classnames';
import * as Papa from 'papaparse';
import {cloneDeep} from 'lodash';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {LegacyModal} from '@flexe/ui-components';
import {UploadParams} from '../shipper/edi-files/EdiFilesInterfaces';
import CsvUploadDropzone from '../shared/CsvUploadDropzone';
import CsvUploadBulkCreateInstructionsTable from '../shared/CsvUploadBulkCreateInstructionsTable';
import FlexeButton from '../shared/FlexeButton';
import ShowHide from '../shared/ShowHide';
import {MoreExplicitUploadParams} from '../shared/CommonInterfaces';

// this type means that requiredHeaders,
// oldRequiredHeaders, and templateUpdateDateString
// must ALL be provided when backwardsCompatible={true}
type CompatibilityProps =
  | {
      backwardsCompatible?: false;
      requiredHeaders: string[][];
      fileUploadType: string;
      oldRequiredHeaders?: never;
      oldFileUploadType?: never;
      templateUpdateDateString?: never;
    }
  | {
      backwardsCompatible: true;
      requiredHeaders: string[][];
      fileUploadType: string;
      oldRequiredHeaders: string[][];
      oldFileUploadType: string;
      templateUpdateDateString: string;
    };

interface CommonProps {
  id: string;
  show: boolean;
  title: string;
  actionDescription: string;
  templatePath: string;
  instructionsTableHeaders: React.ReactNode[];
  instructionsTableRows: React.ReactNode[][];
  uploading: boolean;
  wrapperClass?: string;
  handleUpload: (params: UploadParams, type: string) => void;

  toggleModal(e: any): void;
}

export type Props = CommonProps & CompatibilityProps;

/*****
 * This component should not be used directly by developers.
 * Instead, use a wrapper such as CsvBulkCreateModal.
 *
 * This is the flexible inner workings owned by the front end team.
 *****/
const SpaConfigurationRulesBulkUploadModal: React.FC<Props> = (props: Props) => {
  const transitionTimeout = 1;
  const maxFileSizeMB = 2; // max .csv file size in MB
  const maxFileSize = maxFileSizeMB * 1024 * 1024;

  const [showVersionAlert, setShowVersionAlert] = React.useState<boolean>(false);
  const [showUploadInstructions, setShowUploadInstructions] = React.useState<boolean>(false);
  const [fileSelectErrors, setFileSelectErrors] = React.useState<React.ReactNode>(null);
  const [uploadFileType, setUploadFileType] = React.useState<string>(props.fileUploadType);
  const [uploadParams, setUploadParams] = React.useState<any>({file: {}});
  const [chosenFile, setChosenFile] = React.useState<any>({});

  React.useEffect(() => {
    if (chosenFile.name) {
      const uploadParamsState = cloneDeep(uploadParams);
      uploadParamsState.file = chosenFile;
      const oldRows = props.oldRequiredHeaders;
      const newRows = props.requiredHeaders;
      const [oldRequiredFields, oldOptionalFields] = buildRequiredAndOptionalCsvFieldArrays(oldRows);
      const [newRequiredFields, newOptionalFields] = buildRequiredAndOptionalCsvFieldArrays(newRows);

      if (props.requiredHeaders.length > 0) {
        validateNewHeaders(
          chosenFile,
          oldRequiredFields,
          newRequiredFields,
          oldOptionalFields,
          newOptionalFields,
          uploadParamsState
        );
      }
    }
  }, [chosenFile, props.requiredHeaders]);

  React.useEffect(() => {
    if (!props.show) {
      setChosenFile({});
    }
  }, [props.show]);

  const toggleVersionAlert = () => {
    setShowVersionAlert(!showVersionAlert);
  };

  const toggleInstructions = () => {
    setShowUploadInstructions(!showUploadInstructions);
  };

  const fileInputClearSelection = (e) => {
    e.target.value = null;
    setChosenFile({});
    setUploadParams({file: {}});
    setFileSelectErrors(null);
  };

  const fileInputHandleFileChange = (e) => {
    const files = e.target.files;
    let errors;
    const file = files[0];
    if (file && file.size <= maxFileSize) {
      if (file.name && file.name.match(/\.csv$/)) {
        setChosenFile(file);
      } else {
        errors = <span>The selected file is not in .csv format.</span>;
      }
    } else {
      errors = <span>Please select a .csv file less than {maxFileSizeMB}MB.</span>;
    }
    if (errors) {
      setFileSelectErrors(errors);
    }
  };

  const dropzoneClearSelection = () => {
    setFileSelectErrors(null);
    setUploadParams({file: {}});
  };

  const dropzoneHandleUploadFileChange = (selectedFile) => {
    let errors;
    const file = selectedFile;
    if (file && file.size <= maxFileSize) {
      if (file.name && file.name.match(/\.csv$/)) {
        setChosenFile(file);
      } else {
        errors = <span>The selected file is not in .csv format.</span>;
      }
    } else {
      errors = <span>Please select a .csv file less than {maxFileSizeMB}MB.</span>;
    }
    if (errors) {
      setFileSelectErrors(errors);
    }
  };

  const handleDrop = (acceptedFiles) => {
    dropzoneClearSelection();
    dropzoneHandleUploadFileChange(acceptedFiles[0]);
  };

  const handleDropzoneError = () => {
    setFileSelectErrors(<span>Please select a single CSV for upload.</span>);
  };

  const buildRequiredAndOptionalCsvFieldArrays = (rows): [string[], string[]] => {
    const requiredFields = [];
    const optionalFields = []; // This will include any deprecated fields so validation works
    if (rows && rows.length > 0) {
      rows.forEach((row) => {
        const name = row[0];
        if (row[1] === 'Yes') {
          requiredFields.push(name);
        } else {
          optionalFields.push(name);
        }
      });
    }
    return [requiredFields, optionalFields];
  };

  const validateNewHeaders = (
    file: File,
    oldReqFields: string[],
    newReqFields: string[],
    oldOptFields: string[],
    newOptFields: string[],
    uploadParamsFromState: MoreExplicitUploadParams
  ) => {
    const reader = new FileReader();

    reader.readAsText(file);
    // This is a bug in the Typescript compiler. It is fixed in version 3.6, and
    // we can remove this cast to "any" when we upgrade:
    // https://github.com/microsoft/TypeScript/issues/25510
    reader.onload = (event: any) => {
      const headers = Papa.parse(event.target.result.toString(), {
        header: false,
        preview: 1
      });
      const formattedHeaders = headers.data[0]
        ? headers.data[0].map((header) => {
            return header
              .trim()
              .toUpperCase()
              .replaceAll(' ', '_');
          })
        : headers.data[0];
      const allFields = newOptFields.concat(newReqFields);
      let validField = true;
      let erroneousHeader = '';
      // eslint-disable-next-line
      for (let i = 0; i < newReqFields.length; i++) {
        if (formattedHeaders === undefined) {
          validField = false;
          setFileSelectErrors(<span>The selected file is empty.</span>);
          break;
        }
        if (!formattedHeaders.includes(newReqFields[i])) {
          erroneousHeader = newReqFields[i];
          validField = false;
          break;
        }
      }
      let validHeader = true;
      if (validField) {
        // eslint-disable-next-line
        for (let i = 0; i < formattedHeaders.length; i++) {
          if (!allFields.includes(formattedHeaders[i])) {
            erroneousHeader = formattedHeaders[i];
            validHeader = false;
            break;
          }
        }
      }
      if (validField && validHeader) {
        headersMatchTemplate(uploadParamsFromState);
      } else {
        // TODO: make error feedback more robust
        if (!fileSelectErrors) {
          validateOldHeaders(file, oldReqFields, oldOptFields, uploadParamsFromState);
        }
      }
    };
  };

  const validateOldHeaders = (
    file: File,
    requiredFields: string[],
    optionalFields: string[],
    uploadParamsFromState: MoreExplicitUploadParams
  ) => {
    const reader = new FileReader();
    reader.readAsText(file);
    // This is a bug in the Typescript compiler. It is fixed in version 3.6, and
    // we can remove this cast to "any" when we upgrade:
    // https://github.com/microsoft/TypeScript/issues/25510
    reader.onload = (event: any) => {
      const headers = Papa.parse(event.target.result.toString(), {
        header: false,
        preview: 1
      });
      const allFields = optionalFields.concat(requiredFields);
      let validField = true;
      let erroneousHeader = '';
      // eslint-disable-next-line
      for (let i = 0; i < requiredFields.length; i++) {
        if (!headers.data[0].includes(requiredFields[i])) {
          erroneousHeader = requiredFields[i];
          validField = false;
          break;
        }
      }
      let validHeader = true;
      if (validField) {
        // eslint-disable-next-line
        for (let i = 0; i < headers.data[0].length; i++) {
          if (!allFields.includes(headers.data[0][i])) {
            erroneousHeader = headers.data[0][i];
            validHeader = false;
            break;
          }
        }
      }
      if (validField && validHeader) {
        headersMatchTemplate(uploadParamsFromState, true);
      } else {
        headersDoNotMatchTemplate();
      }
    };
  };

  const headersMatchTemplate = (params, isOldVersion: boolean = false) => {
    if (isOldVersion) {
      setShowVersionAlert(true);
      setUploadFileType(props.oldFileUploadType);
      setUploadParams(params);
    } else {
      submitNewTemplateCsv(params);
    }
  };

  const headersDoNotMatchTemplate = () => {
    setFileSelectErrors(<span>The selected file headers do not match the template.</span>);
  };

  const submitNewTemplateCsv = (params) => {
    setUploadParams(params);
    setUploadFileType(props.fileUploadType);
  };

  const submitOldTemplateCsv = () => {
    setUploadParams(uploadParams);
    setShowVersionAlert(false);
  };

  const handleUpload = () => {
    props.handleUpload(uploadParams, uploadFileType);
    setUploadParams({file: {}});
    setFileSelectErrors(null);
  };

  const renderModalTitle = () => {
    return (
      <div className="modal-title">
        <h4>{props.title}</h4>
        <p className="modal-subtitle">
          Upload a CSV to {props.actionDescription}. Please ensure you are using the{' '}
          <a href={props.templatePath}>CSV template</a> provided.
        </p>
        {props.backwardsCompatible && (
          <div className="inform-msg">
            <span>
              <i className="fa fa-lg fa-exclamation-circle" />
              Update! The CSV template changed on {props.templateUpdateDateString}. Please ensure you are using the
              latest version.
            </span>
          </div>
        )}
      </div>
    );
  };

  const modalClasses = ['react-modal', 'csv-bulk-create-modal', props.wrapperClass && props.wrapperClass];
  const dialogClasses = ['modal-dialog', 'modal-fullscreen'];
  const chosenFileClasses = ['filename', chosenFile.name && 'valid-file'];

  return (
    <React.Fragment>
      <TransitionGroup>
        {props.show && (
          <CSSTransition classNames="react-modal" timeout={transitionTimeout}>
            <div className={classNames(modalClasses)}>
              <div className="modal-backdrop"></div>
              <div className="modal" id={props.id} role="dialog" aria-labelledby={`${props.id}_label`}>
                <div className={classNames(dialogClasses)} role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <FlexeButton
                        text={<span aria-hidden="true">&times;</span>}
                        handleClick={props.toggleModal}
                        level="collapsed"
                      />
                      {renderModalTitle()}
                    </div>
                    <div className="modal-body">
                      <div className="inner-content">
                        {fileSelectErrors && (
                          <div>
                            <div className="alert alert-danger" role="alert">
                              {fileSelectErrors}
                            </div>
                          </div>
                        )}
                        <CsvUploadDropzone
                          actionDescription={props.actionDescription}
                          clearDropzoneFiles={dropzoneClearSelection}
                          handleFileDrop={handleDrop}
                          handleFileChange={dropzoneHandleUploadFileChange}
                          handleDropzoneError={handleDropzoneError}
                        />
                        <span className="upload-choice-spacer">or</span>
                        <span>
                          <label className="file-upload-mock-button" htmlFor="file-input">
                            Choose File
                          </label>
                          <input
                            className="file-upload-invisible-input"
                            id="file-input"
                            type="file"
                            accept=".csv"
                            onClick={fileInputClearSelection}
                            onChange={fileInputHandleFileChange}
                            data-testid="file-input"
                          />
                        </span>
                        <div className="chosen-file">
                          File:
                          <span className={classNames(chosenFileClasses)}>
                            {chosenFile.name ? ` ${chosenFile.name}` : ' No file chosen'}
                          </span>
                        </div>
                        <div>
                          <button
                            id="upload-agree"
                            type="button"
                            className="btn"
                            onClick={handleUpload}
                            disabled={!chosenFile.name || !!fileSelectErrors}
                          >
                            {props.uploading && <i className="fa fa-spinner fa-spin no-padding"></i>}
                            <span>Submit</span>
                          </button>
                        </div>
                        <div className="row">
                          <ShowHide
                            index={1}
                            headerText="See more about what inputs your file needs"
                            open={showUploadInstructions}
                            onClick={toggleInstructions}
                          >
                            <React.Fragment>
                              <span id="top-of-show-hide"></span>
                              {/* TODO: uncomment this and update the href if / when a PDF download option is added */}
                              {/* <a href={'#'}>
                              Download PDF <i className="fa fa-download" />
                            </a> */}
                              <CsvUploadBulkCreateInstructionsTable
                                headers={props.instructionsTableHeaders}
                                data={props.instructionsTableRows}
                              />
                              <a href="#top-of-show-hide">
                                Back to Top <i className="fa fa-arrow-up" />
                              </a>
                            </React.Fragment>
                          </ShowHide>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      <LegacyModal
        id="version-alert-modal"
        title="Out of Date Template"
        size="small"
        show={showVersionAlert}
        toggleModal={toggleVersionAlert}
        transitionSpeed="fast"
      >
        <div>
          <p>Your CSV upload template is out-of-date. This template will stop being supported in the near future.</p>
          <p className="bold">
            Please download the <a href={props.templatePath}>new example template</a> and update your process as soon as
            possible.
          </p>
          <button onClick={submitOldTemplateCsv}>Continue</button>
        </div>
      </LegacyModal>
    </React.Fragment>
  );
};

export default SpaConfigurationRulesBulkUploadModal;
