import * as React from 'react';
import {TransitionState} from '../../shared/CommonInterfaces';
import {TransitionStateDisplay} from '../../shared/constants';

interface CountsByStatus {
  not_started: number;
  in_progress: number;
  active: number;
  new: number;
  completed?: number;
  cancelled?: number;
}
interface CompactStatusTabsProps {
  statuses: TransitionState[];
  countsByProcessStatus: CountsByStatus;
  selectedTab: TransitionState;
  onTabSelect(status: TransitionState);
}

interface StatusTabProps {
  status: TransitionState;
  totalShipments: number;
  isSelected: boolean;
  onTabSelect();
}

const StatusTab: React.FC<StatusTabProps> = (props) => {
  const {status, totalShipments, isSelected, onTabSelect} = props;
  const getDotForStatus = () => {
    if (status !== TransitionState.Active) {
      return <span className={`dot ${status}`}></span>;
    }
  };

  return (
    <div className="status-tab">
      <button
        className={`btn btn-default${isSelected ? ' activeTab' : ''}`}
        type="button"
        onClick={() => onTabSelect()}
        aria-haspopup="true"
        aria-expanded="false"
      >
        {getDotForStatus()}
        {TransitionStateDisplay.get(status)}
        <span className="count">{totalShipments >= 0 ? totalShipments : ''}</span>
      </button>
    </div>
  );
};

const allStatuses = [
  TransitionState.Active,
  TransitionState.New,
  TransitionState.Confirmed,
  TransitionState.NotStarted,
  TransitionState.InProgress,
  TransitionState.Completed,
  TransitionState.Cancelled
];

const CompactStatusTabs: React.FC<CompactStatusTabsProps> = (props) => {
  const {countsByProcessStatus, statuses, selectedTab, onTabSelect} = props;
  const getTabs = () => {
    return (
      <div id="batch-waving-status-tabs">
        <div className="compact-status-tabs">
          {allStatuses.map((status) => {
            if (statuses.includes(status)) {
              return (
                <StatusTab
                  key={`${status}-tab`}
                  status={status}
                  totalShipments={countsByProcessStatus[status]}
                  isSelected={selectedTab === status}
                  onTabSelect={() => onTabSelect(status)}
                />
              );
            }
          })}
        </div>
      </div>
    );
  };

  return getTabs();
};
export default CompactStatusTabs;
