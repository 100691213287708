import * as React from 'react';
import LocationsService, {LocationContent} from '../../locations/LocationsService';
import OutboundStagingTypeAhead from './OutboundStagingTypeAhead';

interface OutboundStagingUpdateState {
  currentLocationId: string;
  errors?: string;
}

interface OutboundStagingUpdateProps {
  authenticityToken: string;
  orderId: number;
  orderState: string;
  warehouseId: number;
  currentLocationId?: string;
  currentLocationLabel?: string;
  pickStarted: boolean;
}

// DEPRECATED
class OutboundStagingUpdate extends React.Component<OutboundStagingUpdateProps, OutboundStagingUpdateState> {
  private locationsService: LocationsService;

  constructor(props) {
    super(props);

    let currentLocationId: string = '';
    if (this.props.currentLocationId != null) {
      currentLocationId = this.props.currentLocationId;
    }

    this.state = {
      currentLocationId
    };

    this.locationsService = new LocationsService(this.props.authenticityToken);

    this.handleTypeAheadSelect = this.handleTypeAheadSelect.bind(this);
  }

  public render() {
    const allowLocationChange =
      !this.props.pickStarted && ['confirmed', 'pickup_scheduled'].includes(this.props.orderState);
    return (
      <div className="row outbound-staging-update-component">
        <h4>{allowLocationChange && 'Set '} Preferred Staging Location</h4>
        <OutboundStagingTypeAhead
          authenticityToken={this.props.authenticityToken}
          warehouseId={this.props.warehouseId}
          locationsService={this.locationsService}
          onSelectCallback={this.handleTypeAheadSelect}
          currentLocationLabel={this.props.currentLocationLabel}
          disable={!allowLocationChange}
        />
        <input type="hidden" name="preferred_outbound_location_id" value={this.state.currentLocationId} />

        {allowLocationChange ? (
          <button id="complete-button" className="btn no-cta">
            {' '}
            <i className="fa fa-check"></i>
            {' Save'}
          </button>
        ) : (
          <UnmodifiableMessage orderState={this.props.orderState} pickStarted={this.props.pickStarted} />
        )}
      </div>
    );
  }

  private handleTypeAheadSelect = (locationId: string, locationContents: LocationContent[]) => {
    this.setState({
      currentLocationId: locationId
    });
  };
}

interface UnmodifiableMessageProps {
  orderState: string;
  pickStarted: boolean;
}

function UnmodifiableMessage(props: UnmodifiableMessageProps) {
  let message: string = 'Staging location cannot be modified';

  if (props.pickStarted) {
    message += '; picking for order has begun';
  } else if (!['confirmed', 'pickup_scheduled'].includes(props.orderState)) {
    // eslint-disable-next-line
    message += "; order must be in 'confirmed' or 'pickup_scheduled' state";
  } else {
    message += '; something went wrong';
  }

  return <i>{message}</i>;
}

export default OutboundStagingUpdate;
