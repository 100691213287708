import * as React from 'react';
import {get} from 'lodash';
import {stringify as queryStringify} from 'query-string';
import {Icon} from '@flexe/ui-components';
import {Inventory, InventoryProperties, PackingListItem} from '../DropoffInterfaces';

interface PrintProps {
  allInventories: Inventory[];
  allPackingLists: PackingListItem[];
  containerDeliveryId: number;
  palletLabelQuantities: {
    [inventoryId: string]: {
      shippable: number;
      damaged: number;
    };
  };
  updatedProperties?: {
    [inventoryId: number]: InventoryProperties;
  };
  handlePalletLabelCountChange(event);
}

function PrintContentLabels(props: PrintProps) {
  return (
    <div id="print_pallet_content_labels">
      <table className="table print-labels">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Description</th>
            <th># cartons per pallet</th>
            <th># shippable pallets?</th>
            <th># damaged pallets?</th>
            <th>Labels</th>
          </tr>
        </thead>
        <tbody>
          {props.allInventories.map((inv, index) => {
            const invPackingLists = props.allPackingLists.filter((pl) => pl.inventory.id === inv.id);
            let unit;
            if (invPackingLists.length > 1) {
              unit = 'mixed';
            } else if (invPackingLists.length === 1) {
              unit = invPackingLists[0].quantity.unit;
            }
            const labelQuantities = props.palletLabelQuantities[inv.id];
            const numShippable = get(labelQuantities, 'shippable') || 0;
            const numDamaged = get(labelQuantities, 'damaged') || 0;
            const totalPallets = numShippable + numDamaged;
            const params = queryStringify({
              inventoryId: inv.id,
              numShippable,
              numDamaged,
              unit
            });
            const labelUrl = `/api/v2/containers/${props.containerDeliveryId}/content_labels.pdf?${params}`;

            return (
              <tr key={index}>
                <td>{inv.sku}</td>
                <td>{inv.description}</td>
                <td>{getCartonsPerPallet(inv)}</td>
                <td>
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        name="palletLabelCount"
                        value={numShippable}
                        min={0}
                        data-id={inv.id}
                        data-type="shippable"
                        onChange={props.handlePalletLabelCountChange}
                      />
                      <span className="input-group-addon">labels</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control bg-red6"
                        name="palletLabelCount"
                        value={numDamaged}
                        min={0}
                        data-id={inv.id}
                        data-type="damaged"
                        onChange={props.handlePalletLabelCountChange}
                      />
                      <span className="input-group-addon">labels</span>
                    </div>
                  </div>
                </td>
                <td>
                  {totalPallets > 0 && (
                    <a className="btn" target="_blank" href={labelUrl}>
                      <Icon icon="print" /> Print
                    </a>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  function getCartonsPerPallet(inv: Inventory): number {
    if (props.updatedProperties && props.updatedProperties[inv.id]) {
      return props.updatedProperties[inv.id].properties.carton.unitsPerParent;
    }
    return get(inv.properties, 'carton.unitsPerParent', 0);
  }
}

export default PrintContentLabels;
