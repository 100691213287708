import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {format as formatDate} from 'date-fns';
import {ReactNode} from 'react';
import {OrderLine, OrderStatus} from '../OutboundOrdersInterfaces';
import {getOrderLineStatusDisplayLabel} from '../helpers/OrderLines';
import {timestampFormat} from '../helpers/Constants';
import {OMSFeatureFlags, OMSReservation} from '../../ecommerce-orders/OrdersAppInterfaces';
import OrderLineContents from '../inputs/OrderLineContents';

interface Props {
  orderLine: OrderLine;
  featureFlags: OMSFeatureFlags;
  allReservationsForShipper: OMSReservation[];
  toggleModal: () => void;
}

const buildCalculatedShipByDateCells = (featureFlags: OMSFeatureFlags) => {
  if (!featureFlags.showCalculatedShipByDate) {
    return [undefined, undefined];
  }

  return ['Calculated Ship by Date', 'TODO'];
};

const buildHeaderTableRow = (
  label1: string,
  value1: string | ReactNode,
  label2?: string,
  value2?: string | ReactNode
) => {
  return (
    <tr className="tight borderless">
      <td className="header-25pct">
        <b>{label1}:</b>
      </td>
      <td className="header-25pct">{value1}</td>
      <td className="header-25pct">{label2 && <b>{label2}:</b>}</td>
      <td className="header-remainder">{value2}</td>
    </tr>
  );
};

const buildHeaderTable = (orderLine: OrderLine, featureFlags: OMSFeatureFlags) => {
  const textClassName = 'subtle-hover';
  const lineStatus = getOrderLineStatusDisplayLabel(orderLine);
  const flexeOrderId = <div className={textClassName}>{orderLine.orderId}</div>;
  const flexeLineId = <div className={textClassName}>{orderLine.id}</div>;
  const externalLineId = <div className={textClassName}>{orderLine.externalId}</div>;
  const createdAt = <div className={textClassName}>{formatDate(orderLine.createdAt, timestampFormat)}</div>;
  const [calculatedShipByDateLabel, calculatedShipByDate] = buildCalculatedShipByDateCells(featureFlags);

  return (
    <table className="table outbound-orders-shared-table">
      <tbody>
        {buildHeaderTableRow('Status', lineStatus, 'Flexe Order ID', flexeOrderId)}
        {buildHeaderTableRow('Flexe Line ID', flexeLineId, 'Created', createdAt)}
        {buildHeaderTableRow('External Line ID', externalLineId, calculatedShipByDateLabel, calculatedShipByDate)}
      </tbody>
    </table>
  );
};

const OrderLineDetailModalV2: React.FC<Props> = (props: Props) => {
  const orderLine = props.orderLine;

  // TODO replace all references of placeholder with functions that work
  //  This is just used for iterative development
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const placeholderFunction = (_: any) => {
    return;
  };

  return (
    <LegacyModal
      id="order-line-detail-modal"
      title={`Order Line #${orderLine.id}`}
      show={true}
      size="medium"
      toggleModal={props.toggleModal}
      disableClose={false}
    >
      <div>
        <div className="row modal-detail-header">
          <div className="col-xs-6 no-padding">
            <h5>
              <i className="fa fa-list-ul"></i>
              &nbsp; Line Details
            </h5>
          </div>
        </div>
        <div className="row modal-detail-header">{buildHeaderTable(orderLine, props.featureFlags)}</div>
        <div className="row space-above-lg">
          <OrderLineContents
            order={{
              id: '1', // TODO populate actual order
              externalId: '1',
              hasErrors: false,
              state: OrderStatus.open,
              recipient: {
                name: '',
                phone: '',
                email: '',
                address: {line1: '', locality: '', region: '', country: '', postcode: ''}
              },
              shipping: {instructions: '', signatureConfirmation: '', labelReference1: '', labelReference2: ''},
              createdAt: '',
              completedAt: ''
            }}
            orderLine={props.orderLine}
            saveEdits={placeholderFunction}
            allReservationsForShipper={props.allReservationsForShipper}
          />
        </div>
      </div>
    </LegacyModal>
  );
};
export default OrderLineDetailModalV2;
