import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import DropDown, {DropDownOption, DropDownStyle} from '../DropDown';

interface Props {
  enableLpnCycleCounts: boolean;
  showAssignUserModal: boolean;
  disabled: boolean;
  targetState: string;
  knownMobileUsers: DropDownOption[];
  assignToUserId: string | number;
  toggleAssignUserModal: () => void;
  transitionCycleCountState: (event: any) => Promise<void>;
  handleSelectedMobileUser: (user: DropDownOption) => void;
}

const CycleCountAssignUserModal: FunctionComponent<Props> = (props) => {
  return (
    <LegacyModal
      id="assign-user-modal"
      title="Start Cycle Count"
      show={props.showAssignUserModal}
      size="small"
      toggleModal={props.toggleAssignUserModal}
      footer={
        <div>
          <a onClick={props.toggleAssignUserModal}>Cancel</a>
          <button
            id="perform-start"
            type="button"
            className="btn btn-primary"
            disabled={props.disabled}
            onClick={props.transitionCycleCountState}
            data-target-state={props.targetState}
          >
            Start Cycle Count
          </button>
        </div>
      }
      disableClose={false}
    >
      <div>
        <p>
          Starting this cycle count will make it available to mobile workers. Do you want to assign this cycle count to
          a specific user?
        </p>
        {props.knownMobileUsers.length > 0 && (
          <>
            <p>Select a user (optional):</p>
            <DropDown
              options={props.knownMobileUsers}
              selected={props.knownMobileUsers.find((user) => user.value === props.assignToUserId)}
              style={DropDownStyle.modal}
              onSelect={props.handleSelectedMobileUser}
            />
          </>
        )}
      </div>
    </LegacyModal>
  );
};

export default CycleCountAssignUserModal;
