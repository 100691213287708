import * as React from 'react';
import BatchWavingService from '../../shared/services/BatchWavingService';
import FlexeContext from '../../contexts/FlexeContext';
import {OrderStateCounts} from './BatchInterfaces';

interface GenBatchDocProps {
  batchService: BatchWavingService;
  batchId: number;
}

interface GenBatchDocState {
  generatingDoc: boolean;
}

class BatchGenerateInProgressDoc extends React.Component<GenBatchDocProps, GenBatchDocState> {
  public static contextType = FlexeContext;
  private batchService: BatchWavingService;

  constructor(props) {
    super(props);
    this.state = {
      generatingDoc: false
    };

    this.batchService = props.batchService;
  }

  public render() {
    return (
      <div id="batch-gen-in-progress-doc-component">
        <button
          id="batch-generate-in-progress-doc-button"
          onClick={this.handleGenerate}
          disabled={this.state.generatingDoc}
        >
          {this.state.generatingDoc
            ? 'Generating document - refresh page'
            : 'Generate Batch Document for Active Shipments'}
        </button>
      </div>
    );
  }

  private handleGenerate = async () => {
    if (this.state.generatingDoc) {
      return;
    }

    await this.batchService.generateInProgressDoc(this.props.batchId);
    this.setState({
      generatingDoc: true
    });
  };
}

export default BatchGenerateInProgressDoc;
