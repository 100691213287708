import * as React from 'react';

interface InlineEditProps {
  title?: string;
  placeholderText: string;
  editing: boolean;
  value: string;
  textArea: boolean;
  onToggleEdit(event: React.MouseEvent<HTMLAnchorElement>): void;
  onUpdate(value: string): void;
  onChange?(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
}

const InlineEdit: React.FC<InlineEditProps> = (props) => {
  const {title, placeholderText, editing, value, textArea, onToggleEdit, onUpdate, onChange} = props;

  const titleAddition = title ? `-${title}` : '';

  const [tempValue, setTempValue] = React.useState(value);

  React.useEffect(() => {
    if (value !== tempValue) {
      setTempValue(value);
    }
  }, [value, textArea]);

  const getEditType = () => {
    return textArea ? (
      <textarea value={tempValue} onChange={handleChange}></textarea>
    ) : (
      <input type="text" value={tempValue} onChange={handleChange}></input>
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event);
    }
    setTempValue(event.target.value);
  };

  const handleUpdate = () => {
    onUpdate(tempValue);
  };

  return (
    <div className="inline-edit-wrapper">
      {!editing ? (
        <div>
          <span className="inline-edit"></span>
          <span className="instruction-text">{value || placeholderText}</span>
          <a className="btn flat" title={`Edit${titleAddition}`} onClick={onToggleEdit}>
            <i className="fa fa-pencil"></i>
          </a>
        </div>
      ) : (
        <div>
          {getEditType()}
          <a className="btn flat update" title={`Update${titleAddition}`} onClick={handleUpdate}>
            <i className="fa fa-lg fa-check"></i>
          </a>
          <a className="btn flat cancel" title={`Cancel${titleAddition}`} onClick={onToggleEdit}>
            <i className="fa fa-lg fa-times"></i>
          </a>
        </div>
      )}
    </div>
  );
};

export default InlineEdit;
