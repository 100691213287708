import {ApiResponse} from '../CommonInterfaces';
import {Nav} from '../../layout/LayoutInterfaces';
import InternalAPIService from './InternalAPIService';

interface NavigationResponse {
  isShipper: boolean;
  navigation: Nav[];
  userNavigation: Nav[];
}

class NavigationService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/navigation');
  }

  public async getNavigation(): Promise<NavigationResponse> {
    const response: ApiResponse<NavigationResponse> = await this.makeGetRequest(this.baseUrl);
    if (response.data && (!response.errors || response.errors.length === 0)) {
      return response.data;
    }
    return {
      isShipper: false,
      navigation: [],
      userNavigation: []
    };
  }
}

export default NavigationService;
