import * as React from 'react';
import {Column, Heading} from '@flexe/ui-components';
import {Role, User, UserWithRoles, Warehouse as WarehouseInfo} from '../../userManagementInterfaces';
import Warehouse from './Warehouse';

interface Props {
  activeUsers: User[];
  adminUserNames: string;
  allWarehouseRoles: Role[];
  authenticityToken: string;
  isMobileEnabled: boolean;
  onRoleToggleAsync: (user: UserWithRoles, warehouseId: number, role: string, enableRole: boolean) => Promise<void>;
  warehouses: WarehouseInfo[];
}

const WarehouseUsers = ({
  activeUsers,
  adminUserNames,
  allWarehouseRoles,
  authenticityToken,
  isMobileEnabled,
  onRoleToggleAsync,
  warehouses
}: Props) => {
  // We should do this potential removal of the "mobile manager" role in the controller but
  // I'm opting to do it in the component for now since we still need to support the .erb view
  // and I'd like to touch that as little as possible to reduce the chance of regressions
  let warehouseRoles = [...allWarehouseRoles];

  if (!isMobileEnabled) {
    warehouseRoles = warehouseRoles.filter((role) => role.title !== 'mobile_manager');
  }

  return (
    <Column tag="section">
      <Heading level="h2">Warehouse Users</Heading>
      {warehouses.map((warehouse) => (
        <Warehouse
          key={warehouse.id}
          activeUsers={activeUsers}
          adminUserNames={adminUserNames}
          authenticityToken={authenticityToken}
          onRoleToggleAsync={onRoleToggleAsync}
          warehouse={warehouse}
          warehouseRoles={warehouseRoles}
        />
      ))}
    </Column>
  );
};

export default WarehouseUsers;
