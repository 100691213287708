import * as React from 'react';
import {Icon} from '@flexe/ui-components';
import {css} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import {getTotalLpnQuantityString, lpnContentsFromManifestLpns} from '../ShipmentHelper';
import {ManifestLpn, ManifestStatus, OutboundTrailerManifestDetail} from '../../loads/ManifestInterfaces';
import StatusDot from '../../../shared/StatusDot';
import {ShipmentDetailsContext} from './ShipmentDetailsContext';

type TManifestId = NonNullable<ManifestLpn['outbound_manifest_id']>;

export const ShipmentManifests: React.FC<{
  lpnsByManifestId: Record<TManifestId, ManifestLpn[]>;
}> = ({lpnsByManifestId}) => (
  <table className="table">
    <thead>
      <tr>
        <th>Load ID</th>
        <th>Status</th>
        <th>Location</th>
        <th>Contents</th>
      </tr>
    </thead>
    <tbody data-testid="shipment-manifests-tbody">
      {Object.entries(lpnsByManifestId).map(([manifestId, lpns]) => (
        <ManifestRow lpns={lpns} key={manifestId} manifestId={Number(manifestId)} />
      ))}
    </tbody>
  </table>
);

const ManifestRow: React.FC<{
  lpns: ManifestLpn[];
  manifestId: TManifestId;
}> = ({lpns, manifestId}) => {
  const {outboundManifestService, setErrors} = React.useContext(ShipmentDetailsContext);

  const [manifest, setManifest] = React.useState<OutboundTrailerManifestDetail | null>(null);
  React.useEffect(() => {
    outboundManifestService.getManifestDetail(manifestId).then((resp) => {
      if (resp.errors.length > 0) {
        setErrors((errors) => [
          ...errors,
          ...resp.errors.map(() => `Unable to fetch load information for outbound manifest ID: ${manifestId}`)
        ]);
        return;
      }

      setManifest(resp.data);
    });
  }, [manifestId, outboundManifestService, setErrors]);

  return (
    <tr>
      <td>
        <a href={`/wh/loads/${manifestId}`}>{manifestId}</a>
      </td>
      <td>{manifest ? <ManifestStatusDisplay status={manifest.status} /> : <LoadingWidget />}</td>
      <td>{manifest ? manifest.doorLocation : <LoadingWidget />}</td>
      <td>
        <ManifestLpns lpns={lpns} />
      </td>
    </tr>
  );
};

const LoadingWidget: React.FC = React.memo(() => {
  const styles = css({
    // TODO FE-579: this looks like it might be a real bug reported by eslint
    // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
    color: `${tokens?.color.base.gray.v500}`
  });

  return <Icon animation={'spin-fast'} icon={'circle-notch'} size={'lg'} {...styles} />;
});

const STATE_TO_DISPLAY_MAP: Record<
  ManifestStatus,
  {
    text: string;
    statusColor: React.ComponentProps<typeof StatusDot>['status'];
  }
> = {
  open: {
    text: 'In Progress',
    statusColor: 'support'
  },
  completed: {
    text: 'Completed',
    statusColor: 'valid'
  },
  cancelled: {
    text: 'Cancelled',
    statusColor: 'invalid'
  }
};

const ManifestStatusDisplay: React.FC<{status: ManifestStatus}> = ({status}) => (
  <>
    <StatusDot status={STATE_TO_DISPLAY_MAP[status].statusColor} />
    <span>{STATE_TO_DISPLAY_MAP[status].text}</span>
  </>
);

const ManifestLpns: React.FC<{lpns: ManifestLpn[]}> = ({lpns}) => (
  <>
    <div>{getTotalLpnQuantityString(lpnContentsFromManifestLpns(lpns))}</div>
    <div>
      {lpns.length} LPN{lpns.length === 1 ? '' : 's'}
    </div>
  </>
);
