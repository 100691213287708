import * as React from 'react';
import {Controller, FieldErrors, FieldValues, useForm} from 'react-hook-form';
import Select from 'react-select';
import FlexeButton from '../shared/FlexeButton';
import WorkOrdersService from '../shared/services/WorkOrdersService';
import {ApiResponse} from '../shared/CommonInterfaces';
import {KitWorkOrderDetail, WipLocationInformation} from './WorkOrdersInterfaces';
import * as WorkOrders from './WorkOrdersInterfaces';

interface Props {
  workOrdersService: WorkOrdersService;
  workOrder: KitWorkOrderDetail;
  onKitsCompletedSuccessfully: () => void;
  availableWIPLocations: WipLocationInformation[];
  isLoading: boolean;
}

interface LocationInformation {
  value: number;
  label: string;
  maxKitsCanBeBuilt: number;
}

interface Inputs {
  quantity: number;
  completedAtLocation: LocationInformation;
}

interface FormErrorObj {
  message: string;
}

const AddCompletedKitsForm: React.FC<Props> = (props) => {
  const isMountedRef = React.useRef(null);

  const [quantityProvided, setQuantityProvided] = React.useState<boolean>(false);
  const [locationSelected, setLocationSelected] = React.useState<boolean>(false);
  const [formErrors, setFormErrors] = React.useState<FormErrorObj>(null);
  const [maxKits, setMaxKits] = React.useState<number>(1);

  const {control, errors, handleSubmit, register, getValues, trigger, watch} = useForm<Inputs>({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const [locationOptions, setLocationOptions] = React.useState<LocationInformation[]>([
    {label: 'Select a location', value: null, maxKitsCanBeBuilt: null}
  ]);

  const quantity = getValues('quantity');
  const completedAtLocation = getValues('completedAtLocation');

  const handleError = (message) => {
    setFormErrors(message);
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    trigger('quantity');
    return () => {
      isMountedRef.current = false;
    };
  }, [maxKits]);

  React.useEffect(() => {
    setLocationOptions((prev) => {
      return props.availableWIPLocations.map((wip) => {
        return {value: wip.locationId, label: wip.locationName, maxKitsCanBeBuilt: wip.maxKitsCanBeBuilt};
      });
    });
  }, []);

  const toggleBooleanState = (conditional, hookToUse, thingToWatch) => {
    React.useEffect(() => {
      isMountedRef.current = true;
      if (conditional) {
        if (isMountedRef.current) {
          hookToUse(true);
        }
      } else {
        if (isMountedRef.current) {
          hookToUse(false);
        }
      }
      return () => {
        isMountedRef.current = false;
      };
    }, thingToWatch);
  };
  toggleBooleanState(watch('quantity'), setQuantityProvided, [watch('quantity')]);

  const onSubmit = async (data) => {
    // eslint-disable-next-line
    setFormErrors(null);
    const addCompletedRequest: WorkOrders.AddCompletedKitsRequest = {
      kitsCompleted: data.quantity,
      locationId: data.completedAtLocation.id
    };
    addCompletedKits(props.workOrder.workOrderId, addCompletedRequest);
  };

  const addCompletedKits = async (workOrderId: number, values: WorkOrders.AddCompletedKitsRequest) => {
    const response = await props.workOrdersService.addCompletedKits(workOrderId, values);
    if (!response.errors || response.errors.length === 0) {
      handleAddSuccess(response);
    } else {
      handleAddError(response);
    }
  };

  const handleAddSuccess = (response: ApiResponse<WorkOrders.AddCompletedKitsResponse>) => {
    props.onKitsCompletedSuccessfully();
  };

  const handleAddError = (response: ApiResponse<WorkOrders.AddCompletedKitsResponse>) => {
    setFormErrors({
      message: 'Unable to add completed kits due to error: ' + response.errors.map((e) => e.detail).join(', ')
    });
  };

  const renderValidation = (inputName: string, fieldErrors: FieldErrors<FieldValues>, state: any) => {
    const validationErrors = [];
    if (fieldErrors && fieldErrors.types) {
      for (const k in fieldErrors.types) {
        // eslint-disable-next-line no-prototype-builtins
        if (fieldErrors.types.hasOwnProperty(k)) {
          validationErrors.push(
            <p className="required-input" key={k}>
              <span className="validation-msg">{fieldErrors.types[k]} </span>
            </p>
          );
        }
      }
    }

    return (
      <React.Fragment>
        {!state && <p className="required-input">{<span className="validation-msg">{inputName} is </span>}required</p>}
        {state && (
          <p className="required-input valid">
            <i className="fas fa-check" /> required
          </p>
        )}
        {validationErrors}
      </React.Fragment>
    );
  };

  // this explicit call to set state is required for testing
  // without it, Jest state will not change when a location is chosen
  const triggerLocationState = (e) => {
    setLocationSelected(e[0] && e[0].id !== null);
    setMaxKits(e[0] && e[0].maxKitsCanBeBuilt ? e[0].maxKitsCanBeBuilt : 1);
    // return value required for onChange of controlled component
    return e[0];
  };

  return (
    <form
      data-testid="new-work-order-form"
      className="new-work-order-form"
      id="new-work-order-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {formErrors && <p className="creation-error">{formErrors.message}</p>}
      <div className="form-view-wrapper" id="form-view">
        <div className="form-body">
          <fieldset form="add-completed-kits-form" className="row" id="add-quantity-fieldset">
            <span className="form-field col-sm-6">
              <label htmlFor="quantity">Quantity</label>
              <input
                data-testid="quantity-field"
                className="add-quanity-input"
                name="quantity"
                id="quantity"
                type="number"
                min="1"
                ref={register({
                  required: true,
                  min: {value: 1, message: 'At least 1 kit must be built.'},
                  max: {
                    value: maxKits,
                    message: `Cannot build more than ${maxKits} kits at this location`
                  }
                })}
              />
              {renderValidation('Quantity', errors.quantity, quantity && true)}
            </span>
            <span className="form-field col-sm-6">
              <label htmlFor="locationSelect">Location</label>
              <div data-testid="location-select">
                <Controller
                  className="add-quantity-select"
                  as={Select}
                  name="completedAtLocation"
                  control={control}
                  data-testid="location-select-controller"
                  id="completedAtLocation"
                  placeholder="Location"
                  options={locationOptions}
                  isClearable={true}
                  isSearchable={true}
                  rules={{required: true}}
                  onChange={triggerLocationState}
                />
              </div>
              {locationSelected && completedAtLocation && (
                <div className={'form-max-kits-can-be-built'}>
                  <small>{completedAtLocation.maxKitsCanBeBuilt} kits available to build</small>
                </div>
              )}
              {renderValidation('Location', errors.completedAtLocation, locationSelected)}
            </span>
          </fieldset>
        </div>
      </div>
      <div className="form-buttons">
        <FlexeButton
          testid="submit-button-bottom"
          level="primary"
          text="Add"
          type="submit"
          isDisabled={props.isLoading || !quantity || !locationSelected || Object.keys(errors).length > 0}
        />
      </div>
    </form>
  );
};

export default AddCompletedKitsForm;
