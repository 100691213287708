import * as React from 'react';
import {FilterOption} from './CommonInterfaces';

interface Props {
  labelOption: FilterOption;
  showCloseButton?: boolean;
  onClose(optionValue: string);
}

const FilterLabel: React.FunctionComponent<Props> = (props) => {
  return (
    <div className="filter-label" data-testid={`filter-label-${props.labelOption.value}`}>
      <span className="label label-default">
        <button
          type="button"
          className="white close-button"
          onClick={() => props.onClose(props.labelOption.value.toString())}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {props.labelOption.displayName}
      </span>
    </div>
  );
};

export default FilterLabel;
