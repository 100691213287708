/** @jsxRuntime classic */
/** @jsx jsx */
import {useContext} from 'react';
import * as React from 'react';
import {css, jsx} from '@emotion/react';
import {InfoBox, Tabs} from '@flexe/ui-components';
import {FC, useEffect, useState} from 'react';
import DropoffContext from '../../contexts/DropoffContext';
import LpnContents from '../lpns/LpnContents';
import {LpnStatus} from '../../lpns/LpnsInterfaces';
import {AddInventoryModal} from '../shipper/AddInventoryModal';
import ContainersService from '../../shared/services/ContainersService';
import {ApiResponse} from '../../shared/CommonInterfaces';
import PackingListService from '../../shared/services/PackingListService';
import WarehouseService from '../../shared/services/WarehouseService';
import DropoffHelper from './DropoffHelper';
import {
  ContainerDelivery,
  ContainerDeliveryState,
  DeliverySummaryData,
  LpnContent,
  PackingLists,
  PoTabMapData,
  SkusTabData
} from './DropoffInterfaces';
import SkuContentRow from './SkuContentRow';
import PurchaseOrderContentRow from './PurchaseOrderContentRow';

enum DeliverySummaryTabKeys {
  SKU = 'skus',
  LPN = 'lpns',
  PO = 'purchaseOrders'
}

const deliverySummaryFill = css({
  minWidth: '-webkit-fill-available'
});

const PO_TABLE_HEADERS = ['PO#', 'Inbound Deliveries', 'Reservations'];

interface Props {
  packingLists: PackingLists;
  lpnContents: LpnContent[];
  lpnReceiveOnly: boolean;
  reservationId: number;
  containerDeliveryId: number;
  lpnStateData: Map<string, LpnStatus>;
  inProgressDropoffIds: number[];
  containerDeliveryState?: ContainerDeliveryState;
  isAsnLpns?: boolean;
  showEditLpnForAsn?: boolean;
  shipperProps?: {
    packingListService: PackingListService;
    containersService: ContainersService;
    warehouseService: WarehouseService;
    shipperCompanyId: number;
    containerDelivery: ContainerDelivery;
    isShipper: boolean;
    isContainerDeliveryActive: boolean;
    handleRefreshPage: () => void;
    handleApiError: (errTitle: string, response: ApiResponse<any>) => void;
  };

  recalculatePackingListsBasedOnLpns(lpnContents: LpnContent[]);
}

export const DeliverySummary: FC<Props> = (props) => {
  const {
    reservationId,
    containerDeliveryId,
    shipperProps: {
      isShipper,
      isContainerDeliveryActive,
      containerDelivery,
      containersService,
      warehouseService,
      packingListService,
      shipperCompanyId,
      handleRefreshPage,
      handleApiError
    } = {}
  } = props;
  const [selectedTab, setSelectedTab] = useState(DeliverySummaryTabKeys.SKU);
  const context = useContext(DropoffContext);
  const [poTabMapData, setPoTabMapData] = useState<PoTabMapData>(null);
  const [showLpnUoms, setShowLpnUoms] = useState<boolean>(false);
  const [showSkuUoms, setShowSkuUoms] = useState(false);
  const [showAddInventoryModal, setShowAddInventoryModal] = useState(false);
  const [showQuantityWarning, setShowQuantityWarning] = useState(false);

  useEffect(() => {
    loadPoTabData();
    setShowLpnUoms(
      props.lpnContents.some((lpnContent) => lpnContent.uoms?.eachesPerInnerPack && lpnContent.uoms?.eachesPerOuterCase)
    );
    setShowSkuUoms(
      props.packingLists.expected.some((expl) => expl.uoms?.eachesPerInnerPack && expl.uoms?.eachesPerOuterCase)
    );
  }, [props.packingLists]);

  const loadPoTabData = async () => {
    const selectedContainersService = context?.containersService ?? containersService;
    const purchaseOrderList = [];
    const poContainerIdsMap: Map<string, Set<number>> = new Map<string, Set<number>>();
    const containerIdResIdMap: Map<number, number> = new Map<number, number>();
    const reservationIdNameMap: Map<number, string> = new Map<number, string>();

    props.packingLists.expected.forEach((expl) => {
      purchaseOrderList.push(expl.purchaseOrderNumber);
    });

    if (purchaseOrderList.length > 0 && selectedContainersService) {
      const shipmentsResponse = await (context?.containersService ?? containersService).getShipmentsByPos(
        purchaseOrderList
      );
      if (shipmentsResponse && shipmentsResponse.errors.length === 0) {
        for (const dropoff of shipmentsResponse.data.dropoffs) {
          if (dropoff.packingLists != null && dropoff.packingLists.expected != null) {
            for (const expectedPackingList of dropoff.packingLists.expected) {
              if (expectedPackingList.metadata != null) {
                const curPoNumber = expectedPackingList.metadata.poNumber;
                if (!purchaseOrderList.includes(curPoNumber)) {
                  continue;
                }

                if (!poContainerIdsMap.has(curPoNumber)) {
                  poContainerIdsMap.set(curPoNumber, new Set());
                }
                poContainerIdsMap.get(curPoNumber).add(dropoff.id);
              }
            }
          }
          if (dropoff.reservation != null) {
            containerIdResIdMap.set(dropoff.id, dropoff.reservation.id);
          }
        }
      }
    }

    if (poContainerIdsMap.size > 0 && containerIdResIdMap.size > 0) {
      if (props.shipperProps != null) {
        const response = await warehouseService.getReservationDetails(props.reservationId);
        if (response) {
          const resForShipper = response.data.reservations[0];
          reservationIdNameMap.set(props.reservationId, `#${props.reservationId}: ${resForShipper.warehouse.name}`);
        }
      } else {
        const warehouseResponse = await (context?.warehouseService ?? warehouseService).getReservationsForWarehouse(
          context.warehouseId
        );
        if (warehouseResponse && warehouseResponse.errors.length === 0) {
          for (const res of warehouseResponse.data.reservations) {
            reservationIdNameMap.set(res.id, res.name);
          }
        }
      }
    }

    const purchaseOrderTabMapData: PoTabMapData = {
      poContainerIdsMap,
      containerIdResIdMap,
      reservationIdNameMap
    };
    setPoTabMapData(purchaseOrderTabMapData);
  };

  const onTabSelection = (key: DeliverySummaryTabKeys) => {
    if (key === selectedTab) {
      return;
    }
    setSelectedTab(key);
  };

  const handleAddInventoryModalToggle = () => {
    setShowAddInventoryModal(!showAddInventoryModal);
  };

  const hasInnerOuterUoms = () => {
    let hasInnerOuter = false;
    for (const pl of props.packingLists.expected) {
      if (pl.uoms?.eachesPerInnerPack && pl.uoms?.eachesPerOuterCase) {
        hasInnerOuter = true;
        break;
      }
    }
    return hasInnerOuter;
  };

  const getTabs = () => {
    const baseTabs = [
      {
        active: selectedTab === DeliverySummaryTabKeys.SKU,
        key: DeliverySummaryTabKeys.SKU,
        title: 'SKUs'
      }
    ];
    if (props.lpnContents?.length > 0) {
      baseTabs.push({
        active: selectedTab === DeliverySummaryTabKeys.LPN,
        key: DeliverySummaryTabKeys.LPN,
        title: 'LPNs'
      });
    }
    baseTabs.push({
      active: selectedTab === DeliverySummaryTabKeys.PO,
      key: DeliverySummaryTabKeys.PO,
      title: 'Purchase Orders'
    });
    return baseTabs;
  };

  const buildUniqueIdForRow = (row: SkusTabData) => {
    return [row.expectedPackingListId, row.shippablePackingListId, row.damagedPackingListId]
      .filter((id) => !!id)
      .join('-');
  };

  const renderTableHeader = () => {
    let tableHeaders = [];
    switch (selectedTab) {
      case DeliverySummaryTabKeys.SKU:
        return (
          <div className="row headers">
            <div className="col-md-2">SKU</div>
            <div className="col-md-2">Description</div>
            <div className="col-md-2">PO#</div>
            {showSkuUoms && <div className="col-md-1">Expected Inner/Outer UoM</div>}
            {showSkuUoms && <div className="col-md-1">Received Inner/Outer UoM</div>}
            <div className="col-md-1">Expected Qty/UoM</div>
            <div className="col-md-2">Received Qty/UoM</div>
            <div className="col-md-1">Damaged Received</div>
            {!isShipper && <div className="col-md-1">SKU Labels</div>}
          </div>
        );
      case DeliverySummaryTabKeys.LPN:
        return <div />; // Handled in LpnContent.tsx
      case DeliverySummaryTabKeys.PO:
        tableHeaders = PO_TABLE_HEADERS;
    }
    return (
      <div className="row headers">
        {tableHeaders.map((header, index) => (
          <div className="col-md-2" key={header}>
            {header}
          </div>
        ))}
      </div>
    );
  };

  const renderTableBody = () => {
    const tableDataRowsMap: DeliverySummaryData = DropoffHelper.buildDeliverySummary(props.packingLists, poTabMapData);
    switch (selectedTab) {
      case DeliverySummaryTabKeys.SKU:
        return (
          <div key={'delivery-summary-sku-content'}>
            {tableDataRowsMap.skus.map((dataRow) => {
              return (
                <SkuContentRow
                  containerDeliveryId={containerDeliveryId}
                  content={dataRow}
                  isShipper={isShipper}
                  showUoms={showSkuUoms}
                  key={'sku-content-row' + buildUniqueIdForRow(dataRow)}
                  shipperProps={{
                    containersService,
                    containerDelivery,
                    handleRefreshPage,
                    packingListService
                  }}
                  showQuantityCallback={() => setShowQuantityWarning(true)}
                  containerDeliveryState={props.containerDeliveryState}
                />
              );
            })}
          </div>
        );
      case DeliverySummaryTabKeys.LPN:
        return (
          <LpnContents
            lpnReceiveOnly={props.lpnReceiveOnly}
            originalPackingLists={props.packingLists}
            lpnStateData={props.lpnStateData}
            dropoffState={ContainerDeliveryState.confirmed}
            lpnContents={props.lpnContents}
            reservationId={props.reservationId}
            recalculatePackingListsBasedOnLpns={props.recalculatePackingListsBasedOnLpns}
            containerDeliveryId={props.containerDeliveryId}
            inProgressDropoffIds={props.inProgressDropoffIds}
            showUoms={showLpnUoms}
            isRenderedInDeliverySummary={true}
            isShipper={isShipper}
            containerDeliveryState={props.containerDeliveryState}
            showEditLpnForAsn={props.showEditLpnForAsn || false}
            isAsnLpns={props.isAsnLpns || false}
          />
        );
      case DeliverySummaryTabKeys.PO:
        return (
          <div>
            {tableDataRowsMap.purchaseOrders.map((poData) => {
              return (
                <PurchaseOrderContentRow
                  data={poData}
                  isShipper={isShipper}
                  key={'delivery-summary-po-content-' + poData.purchaseOrder}
                />
              );
            })}
          </div>
        );
    }
    return null;
  };

  const renderTable = () => (
    <div className="lpn-contents-component">
      <div className="table">
        {renderTableHeader()}
        {renderTableBody()}
      </div>
    </div>
  );

  return (
    <div id="container-delivery-summary">
      <div className="container-fluid">
        <div className="contents-container">
          <header css={deliverySummaryFill}>
            <div className="delivery-summary-header">
              <h2>Delivery Summary</h2>
              {isShipper && isContainerDeliveryActive && (
                <a
                  data-testid="add-inventories-button"
                  className="add-documents"
                  onClick={handleAddInventoryModalToggle}
                >
                  <i className="fa fa-plus"></i> Add Inventory
                </a>
              )}
            </div>
          </header>
          {showQuantityWarning &&
            [ContainerDeliveryState.receiving, ContainerDeliveryState.completed].includes(
              props.containerDeliveryState
            ) && <InfoBox info="Received quantities do not match expected quantities." infoType="warning" />}
          <Tabs tabs={getTabs()} tabEvent={(tabKey) => onTabSelection(tabKey)} />
          {renderTable()}
        </div>
      </div>
      <div>
        {isShipper && (
          <AddInventoryModal
            isOpen={showAddInventoryModal}
            toggleModal={handleAddInventoryModalToggle}
            reservationId={reservationId}
            hasInnerOuterUoms={hasInnerOuterUoms()}
            containersService={containersService}
            shipperCompanyId={shipperCompanyId}
            containerDeliveryId={containerDelivery.id}
            handleRefreshPage={handleRefreshPage}
            handleApiError={handleApiError}
          />
        )}
      </div>
    </div>
  );
};
