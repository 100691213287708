import * as React from 'react';
import {FC, useState} from 'react';
import {Icon} from '@flexe/ui-components';
import {CustomKebabMenu, KebabMenuOption} from '../../shared/CustomKebabMenu';
import {FreightShipMode, ParcelShipMode} from '../../shared/constants';
import {ManifestStatus} from './ManifestInterfaces';

interface Props {
  onStartEdits();
  onCancelEdits();
  onSaveEdits();
  onShip();
  onCancelLoad();
  shipMode: string; // edit behavior is different between parcel and freight
  editing: boolean; // the parent manages edit state for the edit modal whereas this class manages state for its controls
  loadStatus: string;
}

export const LoadDetailActions: FC<Props> = (props) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [savingEdits, setSavingEdits] = useState<boolean>(false);
  const [shipping, setShipping] = useState<boolean>(false);

  const {onStartEdits, onCancelEdits} = props;
  const shipped = props.loadStatus === ManifestStatus.completed;
  const cancelled = props.loadStatus === ManifestStatus.cancelled;
  const terminal = shipped || cancelled;

  const onSaveEdits = async () => {
    setSavingEdits(true);
    await props.onSaveEdits();
    setSavingEdits(false);
    setEditing(false);
  };

  const startEdit = () => {
    setEditing(true);
    onStartEdits();
  };

  const cancelEdits = () => {
    setEditing(false);
    onCancelEdits();
  };

  const onShip = async () => {
    setShipping(true);
    await props.onShip();
    setShipping(false);
  };

  const editButton = (
    <button type="button" className="load-btn-default" data-testid="edit-btn" onClick={startEdit} disabled={shipping}>
      Edit
    </button>
  );

  const goBackButton = (
    <button
      type="button"
      className="load-btn-go-back"
      data-testid="cancel-btn"
      onClick={cancelEdits}
      disabled={savingEdits}
    >
      Go Back
    </button>
  );

  const saveButton = (
    <button
      type="button"
      title={'Save'}
      disabled={savingEdits}
      className="load-btn-primary"
      data-testid="save-btn"
      onClick={onSaveEdits}
    >
      {!savingEdits && <div>Save</div>}
      {savingEdits && (
        <div>
          <Icon icon="circle-notch" animation="spin" size="lg" />
        </div>
      )}
    </button>
  );

  const shipButton = (
    <button
      type="button"
      title={'Complete the shipment'}
      disabled={shipping || props.loadStatus == null}
      className="load-btn-primary"
      data-testid="ship-btn"
      onClick={onShip}
    >
      {!shipped && !shipping && <div>Ship</div>}
      {shipping && (
        <div>
          <Icon icon="circle-notch" animation="spin" size="lg" />
        </div>
      )}
    </button>
  );

  function kebabOptions(): KebabMenuOption[] {
    return [
      !terminal && {
        optionText: 'Cancel Load',
        isActionDangerous: true,
        optionAction: props.onCancelLoad
      }
    ];
  }

  function showEditButton(): boolean {
    if (props.shipMode === FreightShipMode) {
      return !props.editing;
    } else if (props.shipMode === ParcelShipMode) {
      return !editing;
    }
  }

  return (
    <React.Fragment>
      <div id="loadDetailAction">
        {// the page may be still loading
        props.shipMode !== undefined && !terminal && (
          <div>
            <div className="action-buttons">
              {showEditButton() && editButton}
              {!showEditButton() && goBackButton}
              {!showEditButton() && saveButton}
              {showEditButton() && shipButton}
            </div>
            <div className="kebab-container">
              {!terminal && props.loadStatus != null && (
                <CustomKebabMenu options={kebabOptions()} data-testid="kebab-menu" />
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
