import * as React from 'react';
import {cloneDeep} from 'lodash';
import {v4 as uuidv4} from 'uuid';
import Select from 'react-select';

export interface CustomAttribute {
  id: string;
  name: string;
  value: string;
}

export const blankCustomAttribute = () => {
  return {
    id: uuidv4(),
    name: '',
    value: ''
  };
};

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  attributeList: CustomAttribute[];
  allowMultiple?: boolean;
  shipmentAttributes: string[];
  customAttributeWaveTemplateDropdownEnabled: boolean;
  updateAttributes(updated);
}

const CustomAttributesInputList: React.FC<Props> = ({
  attributeList,
  updateAttributes,
  shipmentAttributes,
  customAttributeWaveTemplateDropdownEnabled,
  allowMultiple = false
}) => {
  const editAttribute = (index: number, field: string, value: string) => {
    const copy = cloneDeep(attributeList);
    copy[index][field] = value;
    updateAttributes(copy);
  };

  const deleteAttribute = (index: number) => {
    const copy = cloneDeep(attributeList);
    copy.splice(index, 1);
    updateAttributes(copy);
  };

  const appendAttribute = (attribute: CustomAttribute) => {
    const copy = cloneDeep(attributeList);
    copy.push(attribute);
    updateAttributes(copy);
  };

  const shipmentAttributesForSelect = (availableAttributes) => {
    const formattedShipAttributes: SelectOption[] = [];
    // eslint-disable-next-line
    for (const i in availableAttributes) {
      formattedShipAttributes.push({value: availableAttributes[i], label: availableAttributes[i]});
    }
    return formattedShipAttributes;
  };
  // eslint-enable-next-line

  const renderCustomAttributeForm = (index: number) => {
    if (customAttributeWaveTemplateDropdownEnabled) {
      return (
        <Select
          className="wave-template-select"
          name="customAttributes"
          id={`custom-attributes-select-${index}`}
          isClearable={true}
          placeholder="Select a custom shipment attribute"
          options={shipmentAttributesForSelect(shipmentAttributes)}
          onChange={(e) => editAttribute(index, 'name', e ? e.value : '')}
        />
      );
    } else {
      return (
        <input
          data-testid={`custom-attributes-name-${index}`}
          className="wave-template-input text-input"
          placeholder="Name"
          onChange={(e) => editAttribute(index, 'name', e.target.value)}
        />
      );
    }
  };

  return (
    <React.Fragment>
      <div className="form-field custom-attributes">
        <label>
          Custom Client Attribute
          <br />
          <span className="input-hint">(optional) Custom attributes are passed through by the client</span>
        </label>

        {attributeList.map((attr, index) => {
          return (
            <div key={attr.id} className="custom-attributes-text">
              {renderCustomAttributeForm(index)}

              <input
                data-testid={`custom-attributes-value-${index}`}
                className="wave-template-input text-input"
                placeholder="Value"
                onChange={(e) => editAttribute(index, 'value', e.target.value)}
              />
              {allowMultiple && (
                <button
                  data-testid={`custom-attributes-delete-${index}`}
                  className="custom-attribute-delete"
                  onClick={() => deleteAttribute(index)}
                >
                  &times;
                </button>
              )}
            </div>
          );
        })}

        {allowMultiple && (
          <button
            type="button"
            data-testid="custom-attributes-more"
            className="custom-attributes-more"
            onClick={() => {
              appendAttribute(blankCustomAttribute());
            }}
          >
            More
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

export default CustomAttributesInputList;
