import * as React from 'react';
import {DisplayGenericErrors, GenericErrorDisplay} from '../../shared/GenericErrorDisplay';

export function renderContent(errors: string[], renderIfNoErrors: () => any) {
  return <div className="row">{errors.length > 0 ? renderErrors(errors) : renderIfNoErrors()}</div>;
}

function renderErrors(errors: string[]) {
  const genericError: GenericErrorDisplay = {
    header:
      'Error encountered while loading this component. Please try again later, or contact Flexe if the error persists.',
    details: errors
  };
  return DisplayGenericErrors([genericError]);
}
