import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LpnService from '../shared/services/LpnService';
import {FilterOption, Warehouse} from '../shared/CommonInterfaces';
import {LocationFilters} from '../locations/LocationsInterfaces';
import LpnDetail from './LpnDetail';

interface LpnProps {
  authenticityToken: string;
  warehouses: FilterOption[];
  currentWarehouse: FilterOption;
  isNewTableEnabled: boolean;
  warehouse: Warehouse;
  isShipper: boolean;
}

interface LpnState {
  lpnService: LpnService;
  filters: LocationFilters;
  selectedWarehouse?: Warehouse;
}

class Lpns extends React.Component<LpnProps, LpnState> {
  constructor(props) {
    super(props);

    this.state = {
      filters: {},
      selectedWarehouse: props.warehouse,
      lpnService: new LpnService(this.props.authenticityToken)
    };
  }

  public render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/(s|wh)/lpns/:id"
            render={(routeProps) => (
              <LpnDetail
                {...routeProps}
                {...this.props}
                warehouseId={this.props.warehouse && this.props.warehouse.id}
                isNewTableEnabled={this.props.isNewTableEnabled}
              />
            )}
          />
        </Switch>
      </Router>
    );
  }
}

export default Lpns;
