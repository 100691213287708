import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {Shipment} from '../ShipmentInterfaces';
import {getQTYUoM} from '../../../shared/utilities/DataFormatting';
import {renderItemLink} from '../../../../libs/helpers';

interface ShipmentSkuModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  shipment: Shipment;
}

const ShipmentSkuModal: React.FC<ShipmentSkuModalProps> = (props) => {
  const getModalTitle = () => {
    return (
      <div>
        <h4>SKUs Allocated for Shipment #{props.shipment && props.shipment.id}</h4>
      </div>
    );
  };

  return (
    <LegacyModal
      id={'shipment-details-modal'}
      size="medium"
      show={props.showModal}
      toggleModal={() => props.setShowModal(false)}
      title={getModalTitle()}
    >
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>SKU ID</th>
              <th>Total QTY</th>
            </tr>
          </thead>
          <tbody>
            {props.shipment &&
              props.shipment.line_items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="row">
                      <div>{item.description}</div>
                      <div>{renderItemLink(item.inventory_id, item.sku, false)}</div>
                    </td>
                    <td>{getQTYUoM(item.quantity, item.packaging, false)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </LegacyModal>
  );
};

export default ShipmentSkuModal;
