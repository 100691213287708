import {
  ApiResponse,
  FeatureFlagResponse,
  ReservationCarrierAccountResponse,
  ReservationsResponse,
  WarehouseConfigResponse,
  WarehouseData
} from '../CommonInterfaces';
import {ReservationLocationConfigResponse} from '../../locations/LocationsInterfaces';
import {Reservation, Reservations, Warehouse} from '../../shipper/outbound-orders/ReservationsInterfaces';
import InternalAPIService from './InternalAPIService';

export interface Warehouses {
  warehouses: WarehouseData[];
}

class WarehouseService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/wh/v2');
  }

  public async getReservations(warehouseId?: number): Promise<Reservations> {
    return await this.makeGetRequest(`${this.baseUrl}/reservations`, {
      warehouse_id: warehouseId,
      authenticity_token: this.authenticityToken
    });
  }

  public async getReservationDetails(id: number): Promise<Reservations> {
    return await this.makeGetRequest(`${this.baseUrl}/reservations/${id}`, {
      authenticity_token: this.authenticityToken
    });
  }

  public async getReservationsForWarehouse(warehouseId: number): Promise<ApiResponse<ReservationsResponse>> {
    return await this.makeGetRequest(`${this.baseUrl}/reservations/for_warehouse/${warehouseId}`, {
      authenticity_token: this.authenticityToken
    });
  }

  public async getWarehouseConfig(warehouseId: number): Promise<ApiResponse<WarehouseConfigResponse>> {
    return await this.makeGetRequest(`/wh/warehouses/${warehouseId}/config`, {
      authenticity_token: this.authenticityToken
    });
  }

  public async getReservationLocationConfig(
    reservationId: number
  ): Promise<ApiResponse<ReservationLocationConfigResponse>> {
    return await this.makeGetRequest(`/wh/v2/reservations/${reservationId}/location_config`, {
      authenticity_token: this.authenticityToken
    });
  }

  public async getReservationCarrierAccounts(
    reservationId: number
  ): Promise<ApiResponse<ReservationCarrierAccountResponse>> {
    return await this.makeGetRequest(`/wh/reservations/${reservationId}/carrier_accounts`, {
      authenticity_token: this.authenticityToken
    });
  }

  /*
   Retrieves the feature flag data of the provided flag. Result value can be an int or boolean or any type at all.
   */
  public async getFeatureFlag(
    featureFlag: string,
    warehouseId?: number,
    reservationId?: number,
    shipperCompanyId?: number,
    warehouseCompanyId?: number
  ): Promise<ApiResponse<FeatureFlagResponse>> {
    return await this.makeGetRequest(`/wh/v2/reservations/feature_flag/${featureFlag}`, {
      authenticity_token: this.authenticityToken,
      reservationId,
      warehouseId,
      shipperCompanyId,
      warehouseCompanyId
    });
  }

  public async getWarehouseInfo(resId: number, isShipper: boolean): Promise<ApiResponse<Warehouses>> {
    const params = {
      reservationIds: [resId],
      reservationIdForWarehouseCompanyId: isShipper ? resId : null
    };
    return await this.makeGetRequest('/api/v2/warehouses', params);
  }
}

export default WarehouseService;
