import * as React from 'react';
import {ParseResult} from 'papaparse';
import {useEffect, useState} from 'react';
import CsvValidator, {CsvColumns} from '../../../libs/CsvValidator';
import {AvailableInventory, Line} from './InventorySelection';
import {processValidParsedCsv} from './InventoryCSVUploadHelpers';

/* eslint-disable-next-line */
const Papa = require('papaparse');

interface Props {
  showModal: boolean;
  csvFormat: CsvColumns;
  csvExample: any;
  sampleCsvPath: string;
  reservationInventory: AvailableInventory[];
  onUpload(validLines: Line[], lineErrors: string[]);
  onCancel();
}

const InventoryCSVUpload: React.FC<Props> = (props) => {
  const [csv, setCsv] = React.useState<File>(null);
  const [parsedCSV, setParsedCSV] = React.useState<ParseResult>(null);
  const [errors, setErrors] = useState<string[]>([]);

  const csvExample = Papa.unparse(props.csvExample, {quotes: true});
  const validator = new CsvValidator();

  useEffect(() => {
    setCsv(null);
    setParsedCSV(null);
    setErrors([]);
  }, [props.showModal]);

  useEffect(() => {
    processCsv();
  }, [parsedCSV]);

  const handleUpload = () => {
    if (csv) {
      Papa.parse(csv, {
        complete: (results) => {
          if (results.errors.length === 0) {
            const [validationErrors, normalizedResults] = validator.validate(results, props.csvFormat);
            const errs = validationErrors.map((it) => it.message);

            if (errs.length === 0) {
              setParsedCSV(normalizedResults);
            } else {
              setErrors(errs);
            }
          } else {
            const errs = results.errors.map((it) => it.message);
            setErrors(errs);
          }
        },
        header: true,
        skipEmptyLines: 'greedy'
      });
    }
  };

  const processCsv = () => processValidParsedCsv(parsedCSV, props.reservationInventory, props.onUpload);

  return (
    <React.Fragment>
      {props.showModal && (
        <div className="modal" id="upload_csv_modal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button className="close" data-dismiss="modal" type="button" onClick={props.onCancel}>
                  ×
                </button>
                <h4 className="modal-title">Add Inventory by CSV Upload</h4>
              </div>
              {errors.map((error, idx) => {
                return (
                  <div className="alert alert-danger" role="alert" key={`error:${idx}`}>
                    {error}
                  </div>
                );
              })}
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-5">
                    <div id="import_csv_inventory_instructions">
                      <strong>Sample CSV File</strong>
                      <div className="well">
                        <p id="sample_csv">{csvExample}</p>
                      </div>
                      <a className="download-sample-csv" href={props.sampleCsvPath}>
                        Download a sample CSV file
                      </a>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h3>Select File To Import</h3>
                    <input
                      id="csv_file_upload"
                      type="file"
                      onClick={() => setCsv(null)}
                      onChange={(event) => setCsv(event.target.files[0])}
                    />
                    <div className="csv-errors"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 col-md-offset-4">
                    <a className="btn" id="add_inventory_csv_submit" type="button" onClick={handleUpload}>
                      Import Inventory From File
                    </a>
                    <a className="btn grey" data-dismiss="modal" type="button" onClick={props.onCancel}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default InventoryCSVUpload;
