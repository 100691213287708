import axios from 'axios';
import uuidv4 from 'uuid/v4';
import {isEmpty} from 'lodash';
import {ApiResponse, FilterOption, Packaging, ResponseError} from '../CommonInterfaces';
import {LpnSearchRequest, LpnSearchResponse, LpnsResponse} from '../../lpns/LpnsInterfaces';
import InternalAPIService from './InternalAPIService';

export interface LpnCorrectionRequest {
  reservationId: number;
  lpn: string;
  currentSku: string;
  targetSku?: string;
  targetLotCode?: string;
  targetExpirationDate?: Date;
  targetShipmentId?: number;
  targetQuantity?: number;
  targetUom?: Packaging;
}

export interface LpnMoveRequest {
  reservationId: number;
  lpn: string;
  sourceContainerDeliveryId: number;
  targetContainerDeliveryId: number;
}

class LpnService extends InternalAPIService {
  public currentWarehouse?: number;
  public selectedWarehouse?: FilterOption;
  public currentReservation?: number;
  public selectedReservation?: FilterOption;

  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/lpns');
  }

  public async getLpns(
    filters: any = {},
    pageSize: number,
    continuationToken: string = null
  ): Promise<ApiResponse<LpnsResponse>> {
    const data = {filters, pageSize, continuationToken};
    const res = await this.makePostRequest(`${this.baseUrl}/retrieve`, data);
    return res;
  }

  public async searchLpns(request: LpnSearchRequest): Promise<ApiResponse<LpnSearchResponse>> {
    const searchString = this.createSearchStringFromRequest(request);
    const res = await this.makeGetRequest(`${this.baseUrl}/search${searchString}`);
    return res;
  }

  public async searchShipmentDetailsLpns(request: LpnSearchRequest): Promise<ApiResponse<LpnSearchResponse>> {
    const searchString = this.createSearchStringFromRequest(request);
    const res = await this.makeGetRequest(`${this.baseUrl}/search-shipment-details-lpns${searchString}`);
    return res;
  }

  public async updateLpn(lpn: string, lpnData) {
    return await axios.patch(`${this.baseUrl}/update`, {
      authenticity_token: this.authenticityToken,
      meta: {correlationId: uuidv4()},
      data: {lpn, lpnData}
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public async correctLpnInbound(request: LpnCorrectionRequest): Promise<ApiResponse<{}>> {
    return await this.makePostRequest('/api/v2/lpn_inbound_corrections/correct_lpn', request);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public async moveLpnToDropoff(request: LpnMoveRequest): Promise<ApiResponse<{}>> {
    return await this.makePostRequest('/api/v2/lpn_inbound_corrections/change_inbound', request);
  }

  public processErrors = (errors: ResponseError[]) => {
    return errors.map((error) => error.detail || error.title);
  };

  private createSearchStringFromRequest(request: LpnSearchRequest): string {
    let searchString = '';
    searchString = this.appendParameter(searchString, 'reservationIds', request.ReservationIds);
    searchString = this.appendParameter(searchString, 'shipmentIds', request.ShipmentIds);
    searchString = this.appendParameter(searchString, 'lpnBarcodes', request.LpnBarcodes);
    searchString = this.appendParameter(searchString, 'skus', request.Skus);
    if (searchString.length > 0 && request.IncludeArchived != null) {
      searchString = searchString.concat(`&includeArchived=${request.IncludeArchived}`);
    }
    return searchString;
  }

  private appendParameter(baseString: string, parameterName: string, array: any[]): string {
    if (isEmpty(array)) {
      return baseString;
    }

    for (const item of array) {
      const prepend = isEmpty(baseString) ? '?' : '&';
      baseString = `${baseString}${prepend}${parameterName}[]=${item.toString()}`;
    }
    return baseString;
  }
}

export default LpnService;
