import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import OutboundOrdersService from '../OutboundOrdersService';
import {
  CreateOrderLineRequest,
  CreateOrderRequest,
  Labels,
  OutboundRecipient,
  OutboundShippingDetails
} from '../OutboundOrdersInterfaces';
import InventorySelection, {
  CsvInvUploadExample,
  INV_CSV_COLUMNS_SPEC,
  Line,
  SampleInvCsvPathOrder
} from '../../shared-v2/InventorySelection';
import {Destination} from '../../../warehouse/outbound-shipments/ShipmentInterfaces';
import {Packaging, SelectableWarehouse} from '../../../shared/CommonInterfaces';
import {DisplayGenericErrors, GenericErrorDisplay} from '../../../shared/GenericErrorDisplay';
import {FreightShipMode, OrderSourceUIOmnichannel} from '../../../shared/constants';
import {COUNTRY_ABBR_UNITED_STATES, getCityLabel, getPostalCodeLabel, getRegionLabel} from '../../../../libs/helpers';
import {formatDate} from '../../../shared/utilities/DataFormatting';
import DestinationDetails from './DestinationDetails';
import {
  AddAttachments,
  AttachmentRow,
  AttachmentTable,
  uploadAttachmentRows,
  validateAttachmentRows
} from './AttachmentTable';
import OrderAndCarrierDetails from './OrderAndCarrierDetails';
import OrderSummary from './OrderSummary';

interface Props {
  authenticityToken: string;
  warehouses: SelectableWarehouse[];
  showAttachmentsSection: boolean;
  showNewInventoryCsvModal: boolean;
  useNewUiComponentsModal: boolean;
}

const NewOmnichannelOrder: FC<Props> = (props) => {
  const outboundOrdersService = new OutboundOrdersService();
  const resultRef = React.useRef(null);

  const [selectedWarehouse, setSelectedWarehouse] = useState<SelectableWarehouse>(
    props.warehouses.length === 1 ? props.warehouses[0] : null
  );
  const [shipmentType, setShipmentType] = useState<string>(FreightShipMode);
  const [destination, setDestination] = useState<Destination>({
    name: '',
    address_line_1: '',
    city: '',
    state: '',
    postal_code: '',
    country: COUNTRY_ABBR_UNITED_STATES // Most customers work within the US today, so default to US
  } as Destination);
  const [poNumber, setPoNumber] = useState<string>(null);
  const [customerUUID, setCustomerUUID] = useState<string>(null);
  const [labels, setLabels] = useState<Labels>({});
  const [shipBy, setShipBy] = useState<Date>(null);
  const [shipAfter, setShipAfter] = useState<Date>(null);
  const [scac, setScac] = useState<string>(null);
  const [selectedInventory, setSelectedInventory] = useState<Line[]>([]);
  const [errors, setErrors] = useState<GenericErrorDisplay[]>([]);
  const [notes, setNotes] = useState<string>(undefined);
  const [showCompletionModal, setShowCompletionModal] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<number>(null);
  const [attachmentRows, setAttachmentRows] = useState<AttachmentRow[]>([]);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const showAttachmentsSection = props.showAttachmentsSection;
  const showNewInventoryCsvModal = props.showNewInventoryCsvModal;
  const disallowedPackagingTypes = [Packaging.pallet];

  useEffect(() => {
    clearAlerts();
  }, [
    selectedInventory,
    selectedWarehouse,
    destination,
    poNumber,
    customerUUID,
    shipAfter,
    shipBy,
    scac,
    notes,
    attachmentRows
  ]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      resultRef.current.scrollIntoView({behavior: 'smooth'});
    }
  });

  const onAddInventory = (items: Line[]) => {
    clearAlerts();
    setSelectedInventory(selectedInventory.concat(items));
  };

  const onEditInventory = (item: Line) => {
    clearAlerts();
    const lines = [...selectedInventory];
    const lineIndex = lines.indexOf(lines.find((it) => it.inventoryId === item.inventoryId));
    lines[lineIndex] = item;
    setSelectedInventory(lines);
  };

  const onDeleteInventory = (inventoryId: number) => {
    clearAlerts();
    const lines = [...selectedInventory];
    const lineIndex = lines.indexOf(lines.find((it) => it.inventoryId === inventoryId));
    lines.splice(lineIndex, 1);
    setSelectedInventory(lines);
  };

  const onSelectWarehouse = (wh: SelectableWarehouse) => {
    if (!wh) {
      setSelectedWarehouse(null);
    } else {
      setSelectedWarehouse(wh);
    }
  };

  const submitOrderRequest = async () => {
    setDisableSubmit(true);
    clearAlerts();

    // Run required field validation before attempting any messages
    const validationErrors: GenericErrorDisplay[] = [];
    const requiredFieldErrors = requiredFieldValidationErrors();
    if (requiredFieldErrors.length > 0) {
      validationErrors.push({
        header: 'Order was not created. Required inputs are invalid.',
        details: requiredFieldErrors
      });
    }

    const attachmentRowErrors = validateAttachmentRows(attachmentRows);
    if (attachmentRowErrors && attachmentRowErrors.length > 0) {
      validationErrors.push({
        header: 'Order was not created. Attachment(s) missing required values.',
        details: attachmentRowErrors
      });
    }

    // Stop if we had errors
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      setDisableSubmit(false);
      return;
    }

    // Attempt file upload first since failure means we have to go back and fix it
    const uploadErrors = await uploadAttachmentRows(attachmentRows, setAttachmentRows);
    if (uploadErrors && uploadErrors.length > 0) {
      const errorHeader =
        'Order was not created. ' +
        'Attachment(s) failed to upload. Please try again or delete unnecessary attachments.';
      setErrors([
        {
          header: errorHeader,
          details: uploadErrors
        }
      ]);
      setDisableSubmit(false);
      return;
    }

    const reservationId = selectedWarehouse ? selectedWarehouse.reservationId.toString() : null;

    const recipient: OutboundRecipient = {
      name: destination.name,
      phone: destination.phone,
      email: destination.email,
      address: {
        line1: destination.address_line_1,
        line2: destination.address_line_2,
        line3: destination.address_line_3,
        city: destination.city, // Typically, we use locality but Order Manager looks at 'city'
        locality: destination.city, // leaving locality here because it's a required value
        region: destination.state,
        postcode: destination.postal_code,
        country: destination.country
      }
    };

    const shipping: OutboundShippingDetails = {
      instructions: notes,
      labelReference1: '',
      labelReference2: '',
      signatureConfirmation: ''
    };

    const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
    const orderLabels: Labels = {
      ...labels,
      flexe_order_source: OrderSourceUIOmnichannel,
      ...(shipmentType === FreightShipMode && {flexe_ltl_v1: 'true'}),
      flexe_ship_within_end: formatDate(shipBy, dateFormat)
    };

    if (shipAfter) {
      orderLabels.flexe_ship_within_start = formatDate(shipAfter, dateFormat);
    }

    if (scac) {
      orderLabels.flexe_scac = scac;
    }

    const lines: CreateOrderLineRequest[] = selectedInventory.map((inv) => {
      return {
        sku: inv.sku,
        quantity: inv.quantity,
        unitOfMeasure: inv.packaging,
        labels: {
          flexe_order_source: OrderSourceUIOmnichannel
        }
      };
    });

    const request: CreateOrderRequest = {
      externalId: customerUUID, //externalId is required!
      recipient,
      shipping,
      labels: orderLabels,
      reservationId,
      lines,
      orderNumber: poNumber
    };

    const response = await outboundOrdersService.createOrder(request);

    if (response.status === 409) {
      setErrors([
        {
          header: 'Order was not created.',
          details: [`Customer Reference ID ${customerUUID} already exists for order #${response.data.id}`]
        }
      ]);

      setDisableSubmit(false);
      return;
    } else if (response.status !== 201) {
      if (response.data.errors && response.data.errors.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
        const responseDetails = response.data.errors.map((e) => `Error code ${e.code}: ${e.detail} ${e.source}.`);
        setErrors([
          {
            header: 'Order was not created.',
            details: responseDetails
          }
        ]);
      } else {
        setErrors([
          {
            header: 'Order was not created due to an unexpected error.'
          }
        ]);
      }

      setDisableSubmit(false);
      return;
    }

    setOrderId(response.data.id);
    const attachmentErrors = await AddAttachments(outboundOrdersService, response.data.id, attachmentRows);

    if (attachmentErrors && attachmentErrors.length > 0) {
      setErrors([
        {
          header: 'Failed to attach file(s). Please manually update the order.',
          details: attachmentErrors
        }
      ]);
    }

    // Leave submit disabled since this order is complete
    setShowCompletionModal(true);
  };

  const requiredFieldValidationErrors = () => {
    const requiredFields = new Map<string, string | Line[] | Date | number | SelectableWarehouse>([
      ['Name', destination.name],
      ['Street Address', destination.address_line_1],
      [getCityLabel(destination.country, true), destination.city],
      [getRegionLabel(destination.country, true), destination.state],
      [getPostalCodeLabel(destination.country, true), destination.postal_code],
      ['Country', destination.country],
      ['Customer Reference #', customerUUID],
      ['Ship By date', shipBy],
      ['Inventory', selectedInventory],
      ['Warehouse to ship from', selectedWarehouse]
    ]);
    const namesOfMissingFields: string[] = [];

    requiredFields.forEach((value, name) => {
      let valid: boolean;

      if (!value) {
        valid = false;
      } else {
        switch (value.constructor.name) {
          case 'String':
            valid = (value as string).length > 0;
            break;
          case 'Array':
            valid = (value as []).length > 0;
            break;
          default:
            valid = true;
            break;
        }
      }

      if (!valid) {
        namesOfMissingFields.push(name);
      }
    });

    const errs = namesOfMissingFields.map((e) => e + ' must be set');
    const today = new Date(Date.now());
    today.setHours(0, 0, 0, 0);

    if (shipBy && shipBy < today) {
      errs.push('Ship By must be today or later');
    }
    if (shipAfter && shipAfter >= shipBy) {
      errs.push('Ship After must be before Ship By');
    }
    if (scac && (scac.length < 2 || scac.length > 4 || !scac.match(/^[0-9a-zA-Z]+$/))) {
      errs.push('SCAC must be between 2 and 4 characters and contain only letters and numbers');
    }
    return errs;
  };

  const clearAlerts = () => {
    setErrors([]);
  };

  const navigation = (
    <div className="breadcrumbs delivery" ref={resultRef}>
      <a href="/s/fulfillment/orders">Orders</a>
      <span className="navigation-separator">/</span>
      Create new order
    </div>
  );

  const errorsDisplay = DisplayGenericErrors(errors);

  return (
    <React.Fragment>
      <div id="omnichannel-outbound-order-create">
        <div className="container-fluid">
          {navigation}
          <h1>Create New Order</h1>
          <i>* = required field</i>
          {!showCompletionModal && errorsDisplay}
          <DestinationDetails
            destination={destination}
            onSelectShipmentType={setShipmentType}
            onSetDestination={setDestination}
          />
          <OrderAndCarrierDetails
            warehouses={props.warehouses}
            customerUUID={customerUUID}
            labels={labels}
            poNumber={poNumber}
            scac={scac}
            onSetCustomerUUID={setCustomerUUID}
            onSelectWarehouse={onSelectWarehouse}
            onSetPoNumber={setPoNumber}
            onAddLabel={(label) =>
              setLabels((prevState) => ({
                ...prevState,
                [label]: 'true'
              }))
            } //prevents dupes
            onDeleteLabel={(label) => setLabels((prevState) => ({...prevState, [label]: 'false'}))}
            onSetShipByDate={setShipBy}
            onSetShipAfterDate={setShipAfter}
            onSetScac={setScac}
          />
          <div id="inventory-selection" className="form-step">
            <h2>3. Add Items</h2>
            <InventorySelection
              showNewModal={showNewInventoryCsvModal}
              selectedWarehouse={selectedWarehouse}
              authenticityToken={props.authenticityToken}
              csvFormat={INV_CSV_COLUMNS_SPEC}
              csvExample={CsvInvUploadExample}
              useNewUiComponentsModal={props.useNewUiComponentsModal}
              sampleCsvPath={SampleInvCsvPathOrder}
              disallowedPackagingTypes={disallowedPackagingTypes}
              onAddInventory={onAddInventory}
              onEditInventory={onEditInventory}
              onDeleteInventory={onDeleteInventory}
            />
          </div>
          {showAttachmentsSection && (
            <React.Fragment>
              <div className="no-bottom-right-padding form-step">
                <h2>4. Add Attachments</h2>
                <AttachmentTable attachmentRows={attachmentRows} setAttachmentRows={setAttachmentRows} />
              </div>
              <hr />
            </React.Fragment>
          )}
          <div id="order-summary" className="no-bottom-right-padding form-step">
            <h2>{showAttachmentsSection ? '5' : '4'}. Review and Submit</h2>
            <OrderSummary
              notes={notes}
              shipTo={destination}
              selectedWarehouse={selectedWarehouse}
              shipBy={shipBy}
              shipAfter={shipAfter}
              inventory={selectedInventory}
              attachmentRows={attachmentRows}
              onEnterNotes={setNotes}
            />
          </div>
          <hr />
          <div>
            <button type="button" className="submit-btn" disabled={disableSubmit} onClick={submitOrderRequest}>
              Submit Order
            </button>
          </div>
          <LegacyModal
            id="complete-order-modal"
            show={showCompletionModal}
            size="medium"
            title={`Order Number ${orderId} has been created!`}
            toggleModal={() => setShowCompletionModal(!showCompletionModal)}
          >
            <div className="content">
              <div>
                <h3>Some order details may still be needed within the order detail page.</h3>
                {showCompletionModal && errorsDisplay}
              </div>
              <div>
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn no-cta"
                    onClick={() => (window.location.href = `/s/fulfillment/orders/${orderId}`)}
                  >
                    View Order
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn-primary"
                    onClick={() => (window.location.href = '/s/fulfillment/orders')}
                  >
                    Continue to Outbound Orders
                  </button>
                </div>
              </div>
            </div>
          </LegacyModal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewOmnichannelOrder;
