import * as React from 'react';
import {findIndex} from 'lodash';
import {BatchAttribute, PickMethod} from './BatchInterfaces';

interface BatchAttributesProps {
  batchAttributes: BatchAttribute[];
}

class BatchAttributes extends React.Component<BatchAttributesProps, any> {
  public render() {
    const attributes = this.printableAttributes();
    return (
      <div className="batch-attributes-component">
        {attributes.map((attr) => (
          <div key={attr.name} id={`${attr.name.replace(/\s/g, '')}-text`}>
            <strong>{attr.name}:</strong> {attr.value}
          </div>
        ))}
      </div>
    );
  }

  private printableAttributes(): BatchAttribute[] {
    const isPickToCarton =
      findIndex(this.props.batchAttributes, {name: 'pick_to_carton', value: true} as BatchAttribute) >= 0;
    return this.props.batchAttributes
      .sort(this.batchAttributeComparator)
      .filter(
        // Exclude attributes which are false
        (attr) => attr.value !== false || attr.name === 'ship_as_is_requested'
      )
      .map(
        (attr) => {
          switch (attr.name) {
            case 'pick_method':
              return {
                name: 'Pick Method',
                value: attr.value === PickMethod.CLUSTER ? 'Cluster' : 'Bulk'
              };
            case 'ship_as_is_requested':
              return {
                name: 'Packaging Type',
                value: attr.value === true ? 'Ship As Is' : 'Overbox'
              };
            case 'hazmat':
              return {
                name: 'Hazmat',
                value: 'true'
              };
            case 'print_packing_slips_separately':
              return {
                name: 'Print Packing Slips Separately',
                value: 'true'
              };
            case 'site_to_store':
              return {
                name: 'Site To Store',
                value: 'true'
              };
            case 'pick_to_carton':
              return {
                name: 'Pick To Carton',
                value: 'true'
              };
            case 'pack_time_label_generation':
              if (!isPickToCarton) {
                return {
                  name: 'Pack Time Label Generation',
                  value: 'true'
                };
              }
            default:
              return null;
          }
        }
        // Remove nulls
      )
      .filter((obj) => obj);
  }

  // Compartor for sorting batch attributes into a consistent display order:
  // - always show Pick Method first
  // - always show Packaging Type second
  // - show other attributes in descending lex order
  private batchAttributeComparator(a, b): number {
    const attrOrderReverse = ['ship_as_is_requested', 'pick_method'];
    const aIndex = attrOrderReverse.indexOf(a.name);
    const bIndex = attrOrderReverse.indexOf(b.name);

    // If not explicitly ordered, sort by name
    if (aIndex === -1 && bIndex === -1) {
      if (a.name < b.name) {
        return 1;
      } else if (a.name > b.name) {
        return -1;
      } else {
        return 0;
      }
      // Otherwise sort by explicit ordering (anything not in the list is sorted after)
    } else {
      if (aIndex < bIndex) {
        return 1;
      } else if (aIndex > bIndex) {
        return -1;
      } else {
        return 0;
      }
    }
  }
}

export default BatchAttributes;
