import * as React from 'react';
import * as moment from 'moment';

interface PageReloaderProps {
  timerStartsAt: Date;
  timerUpdateFrequency: number;
  onReload();
}
const PageReloader: React.FC<PageReloaderProps> = (props) => {
  const {onReload, timerUpdateFrequency, timerStartsAt} = props;
  const [timerText, setTimerText] = React.useState<string>('Just now');

  React.useEffect(() => {
    updateTimer();
    const interval = setInterval(() => {
      updateTimer();
    }, timerUpdateFrequency);
    return () => clearInterval(interval);
  }, [timerStartsAt]);

  const updateTimer = () => {
    setTimerText(moment(timerStartsAt).fromNow());
  };

  return (
    <div id="page-reloader">
      <span>{timerText}</span>
      <button className="btn btn-default" onClick={() => onReload()}>
        <i className="fas fa-redo"></i>Refresh
      </button>
    </div>
  );
};

export default PageReloader;
