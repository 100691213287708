import * as React from 'react';
import classNames from 'classnames';

export interface StatusCounts {
  ghost?: number;
  neutral?: number;
  support?: number;
  inform?: number;
  valid?: number;
  invalid?: number;
}

interface Props {
  /*
   * Define this in the PARENT FILE
   * The keys for this object will vary depending on the use case.
   * Example: SelectedStatusFilters from ShipmentIndex
   */
  selectedStatuses: {[key: string]: boolean};
  /*
   * Define this in the PARENT FILE.
   * Use context-specific states as keys, status-dot specific states as values.
   * An example from CheckboxDropdown:
   * const dotStyleMap = {
   *   new: 'neutral',
   *   confirmed: 'support',
   *   completed: 'valid',
   *   cancelled: 'invalid',
   *   attention_required: 'invalid',
   *   voided: 'invalid',
   * };
   */
  dotStyleMap: {[key: string]: string};
}

const StatusDotOverlap: React.FC<Props> = (props) => {
  const countSelectedStatusCategories = () => {
    const counts: StatusCounts = {};
    const possibleStates = Object.keys(props.dotStyleMap);
    possibleStates.forEach((state) => {
      if (props.selectedStatuses[state]) {
        const newKey = props.dotStyleMap[state];
        counts[newKey] = (counts[newKey] || 0) + 1;
      }
    });
    return Object.keys(counts);
  };

  const statuses = countSelectedStatusCategories();
  const dotElements = [];
  statuses.forEach((status, i) => {
    dotElements.push(
      <span key={`${status}-${i}`} className={`status-dot overlap status-${status} dot-number-${i + 1}`} />
    );
  });

  const wrapClasses = [`overlap-wrap-${statuses.length}`];

  return (
    <span className="status-dot-overlap">
      <span className={classNames(wrapClasses)}>
        {dotElements.map((el) => {
          return el;
        })}
      </span>
    </span>
  );
};

export default StatusDotOverlap;
