/** @jsxRuntime classic */
/** @jsx jsx */
import {FC, useEffect, useState} from 'react';
import * as React from 'react';
import {Row, Text} from '@flexe/ui-components';
import * as moment from 'moment';
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import HeaderDetail from '../../shared/HeaderDetail';
import {Reservation, WarehouseData} from '../../shared/CommonInterfaces';
import {ContainerDelivery} from '../shared/DropoffInterfaces';
import WarehouseService from '../../shared/services/WarehouseService';
import UserService from '../../shared/services/UserService';

export interface InboundDeliveryWarehouseDetailsProps {
  container: ContainerDelivery;
  reservation: Reservation;
  isShipper: boolean;
  warehouseService: WarehouseService;
  userService: UserService;
}

const WarehouseInformation: FC<InboundDeliveryWarehouseDetailsProps> = (
  props: InboundDeliveryWarehouseDetailsProps
) => {
  const {
    reservation,
    container: {receiving},
    isShipper,
    warehouseService,
    userService
  } = props;
  const [warehouseData, setWarehouseData] = useState<WarehouseData>();
  const [userName, setUserName] = useState('');
  const [phone, setPhone] = useState('');
  const [receivingHrs, setReceivingHrs] = useState('');

  useEffect(() => {
    async function loadWarehouseInfo(resId: number) {
      return await warehouseService.getWarehouseInfo(resId, isShipper);
    }

    async function loadUserData(id: number, resId: number) {
      return await userService.getUserContactInfo(id, resId, isShipper);
    }

    loadWarehouseInfo(reservation.id).then((response) => {
      if (response && response.errors.length === 0) {
        setWarehouseData(response.data.warehouses[0]);
        setPhone(response.data.warehouses[0].phone);
        if (
          receiving?.startAt &&
          receiving.startAt?.length !== 0 &&
          receiving?.endAt &&
          receiving.endAt?.length !== 0
        ) {
          setReceivingHrs(receiving.startAt + ' to ' + receiving.endAt);
        } else if (
          response.data.warehouses[0].receivingStartAt &&
          response.data.warehouses[0].receivingStartAt?.length !== 0 &&
          response.data.warehouses[0].receivingEndAt &&
          response.data.warehouses[0].receivingEndAt?.length !== 0
        ) {
          setReceivingHrs(
            moment(new Date(response.data.warehouses[0].receivingStartAt))
              .utc()
              .format('hh:mm A') +
              ' to ' +
              moment(new Date(response.data.warehouses[0].receivingEndAt))
                .utc()
                .format('hh:mm A')
          );
        } else {
          setReceivingHrs('Not Specified');
        }
        loadUserData(response.data.warehouses[0].contactUser?.id, reservation.id).then((userResponse) => {
          if (userResponse && userResponse.errors.length === 0) {
            setUserName(userResponse.data.firstName + ' ' + userResponse.data.lastName);
          }
        });
      }
    });
  }, []);

  return (
    <div
      id="inbound-warehouse-info"
      className="inbound-warehouse-info"
      css={css`
        padding: ${tokens.spacing.v300.value}px;
      `}
    >
      <div
        className="container-fluid"
        css={css`
          height: 100%;
        `}
      >
        <div className="contents-container">
          <header>
            <div className="title-aligned">
              <Text level="Body1" bold={true}>
                Warehouse Information
              </Text>
            </div>
          </header>
          <div>
            <Row childWidths={['fill', 'fill']} alignmentVertical={'top'} type={'fill'}>
              <div id="address-info">
                {HeaderDetail({label: 'Address:', value: ' '})}
                <Text level="Body2" tag="p" bold={false}>
                  {warehouseData?.name}
                </Text>
                <Text level="Body2" tag="p" bold={false}>
                  {warehouseData?.line1}
                </Text>
                <Text level="Body2" tag="p" bold={false}>
                  {warehouseData?.line2}
                </Text>
                <Text level="Body2" tag="p" bold={false}>
                  {warehouseData?.locality + ', ' + warehouseData?.region + ' ' + warehouseData?.postcode}
                </Text>
              </div>
              <div id="additional-wh-info">
                <div>{HeaderDetail({label: 'Receiving Hours:', value: receivingHrs})}</div>
                <div>{HeaderDetail({label: 'Primary Contact:', value: userName})}</div>
                <div>{HeaderDetail({label: 'Phone Number:', value: phone ? phone : 'Not Specified'})}</div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseInformation;
