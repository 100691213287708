import * as React from 'react';
import {Column, Heading} from '@flexe/ui-components';
import {MobileUser, MobileWarehouse as MobileWarehouseInfo} from '../userManagementInterfaces';
import MobileWarehouse from './MobileWarehouse';

interface Props {
  authenticityToken: string;
  onRemoveMobileUserFromWarehouseAsync: (userId: number, warehouseId: number) => Promise<void>;
  users: MobileUser[];
  warehouses: MobileWarehouseInfo[];
}

const MobileWarehouses = ({authenticityToken, onRemoveMobileUserFromWarehouseAsync, users, warehouses}: Props) => (
  <Column tag="section">
    <Heading level="h2">Warehouses</Heading>
    {warehouses.map((warehouse) => (
      <MobileWarehouse
        authenticityToken={authenticityToken}
        key={warehouse.id}
        onRemoveMobileUserFromWarehouseAsync={onRemoveMobileUserFromWarehouseAsync}
        users={users}
        warehouse={warehouse}
      />
    ))}
  </Column>
);

export default MobileWarehouses;
