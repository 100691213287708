import * as React from 'react';
import axios from 'axios';
import {cloneDeep, get} from 'lodash';
import {LegacyModal, Table} from '@flexe/ui-components';
import {v4} from 'uuid';
import {Reservation, ReservationCarrierAccount} from './SharedInterfaces';

interface Props {
  authenticityToken: string;
  reservation: Reservation;
  canEdit: boolean;
  carrierAccounts: ReservationCarrierAccount[];
}

interface State {
  carrierAccounts: ReservationCarrierAccount[];
  accountIdToEnable: number;
  editing: boolean;
  showModal: boolean;
  errors: any;
}

let carrierAccountsSnapshot: ReservationCarrierAccount[] = [];

export class ReservationCarrierAccountList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      carrierAccounts: props.carrierAccounts,
      accountIdToEnable: null,
      editing: false,
      showModal: false,
      errors: null
    };
  }

  public render() {
    let modalFooter;
    let accountOptions;
    if (this.state.showModal) {
      modalFooter = (
        <div>
          <a type="button" onClick={this.toggleModal}>
            Cancel
          </a>
          <button id="deactivate-agree" type="button" className="btn" onClick={this.updateAccountStatuses}>
            Save Changes
          </button>
        </div>
      );
    }

    const carriers = this.getEnabledAccounts().map((carrier, index) => {
      return (
        <p key={index} className={index === 0 ? 'space-above space-below' : 'space-below'}>
          <strong>{carrier.carrierDisplayName}:</strong>
          &nbsp;
          {carrier.accountNumber}
        </p>
      );
    });

    const tableData = this.getDefaultAccountsTableData();

    if (this.state.carrierAccounts) {
      accountOptions = this.getDisabledAccounts().map((account) => {
        return (
          <option key={account.carrierAccountId} value={account.carrierAccountId}>
            {account.description && account.description.length > 0
              ? `${account.carrierDisplayName}: ${account.accountNumber} - ${account.description}`
              : `${account.carrierDisplayName}: ${account.accountNumber}`}
          </option>
        );
      });
    }

    return (
      <div className="reservation-carrier-accounts">
        {this.state.errors && (
          <div className="alert alert-danger" role="alert">
            {this.state.errors.map((e, i) => (
              <span key={i}>{e}</span>
            ))}
          </div>
        )}
        <h3>
          Carriers
          {this.props.canEdit && (
            <a onClick={this.toggleModal} data-testid="edit-carrier-account">
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
          )}
        </h3>
        <hr className="no-margin" />
        {carriers}
        {carriers.length < 1 && <p className="space-above space-below">No carrier accounts selected.</p>}
        {this.state.showModal && (
          <LegacyModal
            id="enabled_carrier_accounts_modal"
            title="Enable Carrier Accounts"
            show={this.state.showModal}
            size="small"
            toggleModal={this.toggleModal}
            footer={modalFooter}
            disableClose={false}
          >
            <div>
              {accountOptions.length > 0 && (
                <div className="row">
                  <label className="col-sm-10">
                    <select name="carrier" id="carrier-account-select" onChange={this.handleAccountChange}>
                      <option value="">Select an account...</option>
                      {accountOptions}
                    </select>
                  </label>
                  <div className="col-sm-2">
                    <button className="btn pull-right" onClick={this.setAccountActive}>
                      Add
                    </button>
                  </div>
                </div>
              )}
              <hr />
              {tableData.rows && tableData.rows.length > 0 && (
                <div className="row">
                  <h5>
                    Enabled for Reservation #{this.props.reservation.id} {this.props.reservation.nickname}
                  </h5>
                  <Table tableClass="table-striped" tableData={tableData} />
                </div>
              )}
            </div>
          </LegacyModal>
        )}
      </div>
    );
  }

  private toggleModal = () => {
    let carrierAccounts;
    if (this.state.showModal) {
      carrierAccounts = cloneDeep(carrierAccountsSnapshot);
      carrierAccountsSnapshot = [];
    } else {
      carrierAccounts = cloneDeep(this.state.carrierAccounts);
      carrierAccountsSnapshot = cloneDeep(this.state.carrierAccounts);
    }

    this.setState({showModal: !this.state.showModal, accountIdToEnable: null, carrierAccounts});
  };

  private getEnabledAccounts() {
    return this.state.carrierAccounts.filter(
      (a) => a.isActive && a.reservations.filter((r) => r.id === this.props.reservation.id).length > 0
    );
  }

  private getDisabledAccounts() {
    const enabledAccounts = this.getEnabledAccounts();
    return this.state.carrierAccounts.filter((a) => !enabledAccounts.includes(a));
  }

  private getDefaultAccountsTableData() {
    const headers = [{element: 'Carrier'}, {element: 'Account #'}, {element: 'Description'}, {element: ''}];
    let rows;
    const enabled = this.getEnabledAccounts();
    if (enabled && enabled.length > 0) {
      rows = enabled.map((account) => {
        const row = [
          <div>{account.carrierDisplayName}</div>,
          <div>{account.accountNumber}</div>,
          <div>{account.description}</div>,
          <div>
            <a data-id={account.carrierAccountId} onClick={this.setAccountInactive}>
              <i className="fa fa-times red3"></i>
            </a>
          </div>
        ];
        return row;
      });
    } else {
      rows = [];
    }
    return {
      headers,
      rows
    };
  }

  private handleAccountChange = (e) => {
    const accountIdToEnable = e.target.value;
    this.setState({accountIdToEnable});
  };

  private updateAccountStatuses = async () => {
    let errors;
    const account = this.state.accountIdToEnable;
    const reservationId = this.props.reservation.id;
    const activeStatuses = {};

    activeStatuses[reservationId] = this.state.carrierAccounts.map((a) => {
      return {
        carrierAccountId: a.carrierAccountId,
        isActive: a.isActive && a.reservations.filter((r) => r.id === this.props.reservation.id).length > 0
      };
    });

    try {
      const response = await this.enableAccountForReservation(activeStatuses);
      carrierAccountsSnapshot = [];
    } catch (errorResponse) {
      errors = this.processErrorResponse(errorResponse);
      this.setState({carrierAccounts: carrierAccountsSnapshot});
    } finally {
      this.setState({showModal: false, errors});
    }
  };

  private setAccountActive = (e) => {
    const accountId = this.state.accountIdToEnable;
    if (accountId) {
      let carrierAccounts = [...this.state.carrierAccounts];
      carrierAccounts = carrierAccounts.map((a) => {
        if (a.carrierAccountId.toString() === accountId.toString()) {
          a.isActive = true;
          a.reservations.push({id: this.props.reservation.id, name: this.props.reservation.warehouseName});
        }
        return a;
      });
      this.setState({carrierAccounts});
    }
  };

  private setAccountInactive = (e) => {
    const accountId = e.currentTarget.getAttribute('data-id');
    if (accountId) {
      let carrierAccounts = [...this.state.carrierAccounts];
      carrierAccounts = carrierAccounts.map((a) => {
        if (a.carrierAccountId.toString() === accountId.toString()) {
          a.isActive = false;
        }
        return a;
      });
      this.setState({carrierAccounts});
    }
  };

  private async enableAccountForReservation(activeStatuses) {
    // eslint-disable-next-line no-useless-catch
    try {
      const params = {
        authenticity_token: this.props.authenticityToken,
        meta: {
          correlationId: v4()
        },
        data: activeStatuses
      };
      const response = await axios.patch('/s/carrier_accounts/update_active_statuses', params);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  private processErrorResponse = (errorResponse: Response) => {
    const errors = get(errorResponse, 'response.data.errors');
    return errors.map((error) => error.detail);
  };
}

export default ReservationCarrierAccountList;
