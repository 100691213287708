import {format, isValid} from 'date-fns';
import * as React from 'react';
import RetailRequest from './RetailRequest';

interface StepThreeProps {
  rawRequests: any[];
  handleConfirmedRequests: any;
}

interface StepThreeState {
  validatedRequests: RetailRequest[];
}

class StepThree extends React.Component<StepThreeProps, StepThreeState> {
  constructor(props) {
    super(props);
    this.state = {
      validatedRequests: this.validateRequests(props.rawRequests)
    };
  }

  public componentWillReceiveProps(props) {
    this.setState({
      validatedRequests: this.validateRequests(props.rawRequests)
    });
  }

  public render() {
    let invalidRequests = false;
    const requests = this.state.validatedRequests ? (
      this.state.validatedRequests.map((request, index) => {
        const requestValid = Object.keys(request.errors).length === 0;
        if (!requestValid && !invalidRequests) {
          invalidRequests = true;
        }
        return (
          <tr key={index} className={requestValid ? '' : 'bg-danger'}>
            <td className={request.errors.po ? 'text-danger po' : 'po'}>{request.po || request.errors.po}</td>
            <td className={request.errors.bol ? 'text-danger bol' : 'bol'}>{request.bol || request.errors.bol}</td>
            <td className="routing-service">{request.routingService}</td>
            <td className={request.errors.routeByDeadline ? 'text-danger route-by-deadline' : 'route-by-deadline'}>
              {request.routeByDeadline && isValid(request.routeByDeadline) && !request.errors.routeByDeadline
                ? format(request.routeByDeadline, 'M/D/YYYY h:mm A')
                : request.errors.routeByDeadline || ''}
            </td>
            <td className={request.errors.shipWindowStart ? 'text-danger ship-window-start' : 'ship-window-start'}>
              {request.shipWindowStart && isValid(request.shipWindowStart)
                ? format(request.shipWindowStart, 'M/D/YYYY h:mm A')
                : request.errors.shipWindowStart || ''}
            </td>
            <td className={request.errors.shipWindowEnd ? 'text-danger ship-window-end' : 'ship-window-end'}>
              {request.shipWindowEnd && isValid(request.shipWindowEnd)
                ? format(request.shipWindowEnd, 'M/D/YYYY h:mm A')
                : request.errors.shipWindowEnd || ''}
            </td>
            <td className={request.errors.destination ? 'text-danger destination' : 'destination'}>
              {request.destination || request.errors.destination}
            </td>
            <td className={request.errors.address ? 'text-danger address' : 'address'}>
              {request.address || request.errors.address}
            </td>
            <td className="address2">{request.address2}</td>
            <td className={request.errors.city ? 'text-danger city' : 'city'}>{request.city || request.errors.city}</td>
            <td className={request.errors.state ? 'text-danger state' : 'state'}>
              {request.state || request.errors.state}
            </td>
            <td className={request.errors.postal ? 'text-danger postal' : 'postal'}>
              {request.postal || request.errors.postal}
            </td>
            <td className={request.errors.inventoryData ? 'text-danger inventory-items' : 'inventory-items'}>
              {request.errors.inventoryData ||
                request.inventoryData.map((inventory, invIndex) => {
                  return (
                    <div key={invIndex}>
                      {inventory.quantity} {inventory.unit}
                      {inventory.quantity > 1 ? 's' : ''} of {inventory.sku}
                    </div>
                  );
                })}
            </td>
          </tr>
        );
      })
    ) : (
      <tr></tr>
    );
    return (
      <li id="step-3" className="active">
        <span className="label">3</span>
        <div className="step-wrap">
          <h3>
            <span>Confirm Fulfillment Requests</span>
          </h3>
          <p className="note">Uploaded Requests</p>
          <table
            className={
              this.state.validatedRequests && this.state.validatedRequests.length > 0
                ? 'show table table-striped'
                : 'hidden'
            }
          >
            <thead>
              <tr>
                <th>PO</th>
                <th>BOL</th>
                <th>Routing Service</th>
                <th>Route By Deadline</th>
                <th>Ship Window Start</th>
                <th>Ship Window End</th>
                <th>Destination</th>
                <th>Address</th>
                <th>Address2</th>
                <th>City</th>
                <th>State</th>
                <th>Postal</th>
                <th>Inventory Items</th>
              </tr>
            </thead>
            <tbody>{requests}</tbody>
          </table>
          <div
            id="no-data"
            className={!this.state.validatedRequests || this.state.validatedRequests.length === 0 ? 'show' : 'hidden'}
          >
            <h4>
              <i className="fa fa-times-circle text-danger"></i>
              CSV contains no valid data. Please try and re-upload.
            </h4>
          </div>
          <div id="validation-success" className={!invalidRequests && this.state.validatedRequests ? 'show' : 'hidden'}>
            <h4>
              <i className="fa fa-check-circle text-success"></i> Requests validated successfully.
            </h4>
          </div>
          <div id="validation-failure" className={invalidRequests && this.state.validatedRequests ? 'show' : 'hidden'}>
            <h4>
              <i className="fa fa-times-circle text-danger"></i> Requests have errors, please fix and re-upload CSV.
            </h4>
          </div>
          <p className="note">
            <button type="button" className="btn" onClick={() => this.handleSubmission()} disabled={invalidRequests}>
              Submit Requests
            </button>
          </p>
        </div>
      </li>
    );
  }

  private handleSubmission() {
    this.props.handleConfirmedRequests(this.state.validatedRequests);
  }

  private validateRequests(requests: any[]): RetailRequest[] {
    let validatedRequests: RetailRequest[];
    if (requests && requests.length > 0) {
      validatedRequests = requests.map((request) => {
        return new RetailRequest(request);
      });
      const POs = validatedRequests.map((request) => request.po);
      const duplicatePOs = POs.filter((po, i) => POs.lastIndexOf(po) === i && POs.indexOf(po) !== i);
      if (duplicatePOs) {
        validatedRequests = this.collapseMultiLinePOs(duplicatePOs, validatedRequests);
      }
    }
    return validatedRequests;
  }

  private collapseMultiLinePOs(multiLinePOs: string[], requests: RetailRequest[]): RetailRequest[] {
    const results = requests.filter((request) => multiLinePOs.indexOf(request.po) === -1);
    multiLinePOs.forEach((po, index) => {
      const duplicates = requests.filter((request) => request.po === po);
      let inventory = duplicates[0].inventoryData.slice();
      for (let i = 1; i < duplicates.length; i++) {
        inventory = inventory.concat(duplicates[i].inventoryData);
      }
      duplicates[0].inventoryData = inventory.slice();
      results.push(duplicates[0]);
    });
    return results;
  }
}

export default StepThree;
