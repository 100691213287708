import * as React from 'react';

import {LegacyModal} from '@flexe/ui-components';
import {Reservation} from '../../shipper/outbound-orders/ReservationsInterfaces';
import {ShipmentPackaging, ShipmentPackagingField} from './Interfaces';

interface Props {
  show: boolean;
  hideModal: () => void;
  handleSubmit: () => void;
  handleShipmentPackagingFieldChange: (fieldName: ShipmentPackagingField, newValue: string) => void;
  shipmentPackaging: ShipmentPackaging;
  action: RequestType;
  warnings: string[];
  reservations: Reservation[];
}

export enum RequestType {
  CREATE = 'Create',
  UPDATE = 'Update'
}

const ShipmentPackagingDialog: React.FC<Props> = (props) => {
  const {shipmentPackaging} = props;

  const footer = (
    <div>
      <a id="a_cancel" onClick={props.hideModal}>
        Cancel
      </a>
      <button
        type="button"
        className="btn btn-primary"
        id="btn_action"
        onClick={props.handleSubmit}
        data-testid="btn_action"
      >
        {props.action} Packaging
      </button>
    </div>
  );

  if (!shipmentPackaging) {
    return null;
  }

  return (
    <LegacyModal
      id="default_overbox_modal"
      data-testid="default_overobx_modal"
      title={`${props.action} Packaging ${shipmentPackaging.description ? `- ${shipmentPackaging.description}` : ''}`}
      show={props.show}
      size="small"
      transitionSpeed="fast"
      toggleModal={props.hideModal}
      footer={footer}
    >
      <div className="row">
        {props.warnings.length > 0 && (
          <div className="alert alert-warning" role="alert">
            {props.warnings.map((e, i) => (
              <span key={i}>{e}</span>
            ))}
          </div>
        )}
        {props.action === RequestType.CREATE && (
          <div>
            <div className="row space-below space-above vertical-align">
              <label htmlFor="reservation-id-input" className="col-md-3">
                Reservation:
              </label>
              <select
                id="reservation-id-input"
                data-testid="reservation-id-input"
                onChange={(e) =>
                  props.handleShipmentPackagingFieldChange(ShipmentPackagingField.reservationId, e.target.value)
                }
                value={shipmentPackaging.reservationId}
              >
                <option value={null}>All Reservations</option>
                {props.reservations.map((r: Reservation) => {
                  return (
                    <option value={r.id}>
                      {r.id}: {r.warehouse.name}, {r.warehouse.address.locality}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="row space-below space-above vertical-align">
              <label htmlFor="description-input" className="col-md-3">
                Description:
              </label>
              <input
                type="string"
                name="description"
                id="description-input"
                data-testid="description-input"
                value={shipmentPackaging.description}
                className="col-md-4"
                onChange={(e) =>
                  props.handleShipmentPackagingFieldChange(ShipmentPackagingField.description, e.target.value)
                }
              />
            </div>
            <div className="row space-below space-above vertical-align">
              <label htmlFor="barcode-input" className="col-md-3">
                Barcode:
              </label>
              <input
                type="string"
                name="barcode"
                id="barcode-input"
                data-testid="barcode-input"
                value={shipmentPackaging.barcode}
                className="col-md-4"
                onChange={(e) =>
                  props.handleShipmentPackagingFieldChange(ShipmentPackagingField.barcode, e.target.value)
                }
              />
            </div>
          </div>
        )}
        <div className="row space-below space-above vertical-align">
          <label htmlFor="height-input" className="col-md-3">
            Height:
          </label>
          <input
            type="number"
            name="height"
            id="height-input"
            data-testid="height-input"
            step="1"
            min="1"
            value={shipmentPackaging.height}
            className="col-md-2"
            onChange={(e) => props.handleShipmentPackagingFieldChange(ShipmentPackagingField.height, e.target.value)}
          />
          <span className="col-md-2">inches</span>
        </div>
        <div className="row space-below space-above vertical-align">
          <label htmlFor="length-input" className="col-md-3">
            Length:
          </label>
          <input
            type="number"
            name="length"
            id="length-input"
            data-testid="length-input"
            step="1"
            min="1"
            value={shipmentPackaging.length}
            className="col-md-2"
            onChange={(e) => props.handleShipmentPackagingFieldChange(ShipmentPackagingField.length, e.target.value)}
          />
          <span className="col-md-2">inches</span>
        </div>
        <div className="row space-below space-above vertical-align">
          <label htmlFor="width-input" className="col-md-3">
            Width:
          </label>
          <input
            type="number"
            name="width"
            id="width-input"
            data-testid="width-input"
            step="1"
            min="1"
            value={shipmentPackaging.width}
            className="col-md-2"
            onChange={(e) => props.handleShipmentPackagingFieldChange(ShipmentPackagingField.width, e.target.value)}
          />
          <span className="col-md-2">inches</span>
        </div>
        <div className="row space-below space-above vertical-align">
          <label htmlFor="weight-input" className="col-md-3">
            Weight:
          </label>
          <input
            type="number"
            name="weight"
            id="weight-input"
            data-testid="weight-input"
            step="1"
            min="1"
            value={shipmentPackaging.weight}
            className="col-md-2"
            onChange={(e) => props.handleShipmentPackagingFieldChange(ShipmentPackagingField.weight, e.target.value)}
          />
          <span className="col-md-2">pounds</span>
        </div>
        <div className="row space-below space-above vertical-align">
          <label htmlFor="box-type-input" className="col-md-3">
            Box Type:
          </label>
          <select
            id="box-type-input"
            data-testid="box-type-input"
            onChange={(e) => props.handleShipmentPackagingFieldChange(ShipmentPackagingField.boxType, e.target.value)}
            value={shipmentPackaging.boxType}
          >
            <option value="box">Box</option>
            <option value="package">Package</option>
            <option value="envelope">FedEx Branded Envelope</option>
          </select>
        </div>
      </div>
    </LegacyModal>
  );
};

export default ShipmentPackagingDialog;
