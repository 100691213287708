import * as React from 'react';
import {Link} from 'react-router-dom';

interface LpnCellProps {
  baseUrl: string;
  lpnBarcode: string;
}
export function LpnCell({baseUrl, lpnBarcode}: LpnCellProps) {
  return (
    <Link
      className="flexe-mui-data-grid-link-cell"
      title={lpnBarcode}
      to={`${baseUrl}/lpns/${lpnBarcode}`}
      target="_blank"
    >
      {lpnBarcode}
    </Link>
  );
}
