import * as React from 'react';
import StatusTab from '../../shared/StatusTab';
import FilterBar from '../../shared/FilterBar';
import {Expense, ExpenseServiceInterface} from './ExpensesInterfaces';

interface Props {
  selectedTab?: string;
  expenseService: ExpenseServiceInterface;
  warehouseId: number;
  date: Date;
  creationCount: number;
}

const ExpensesTable: React.FC<Props> = (props) => {
  const remediationMessage = 'Please contact billing.support@flexe.com with questions.';

  // TODO: add support for variable notes length w/hover
  // TODO: highlight row(s)
  // TODO: router params for highlighting rows + CTA
  // TODO: sort button

  const [expenses, setExpenses] = React.useState<Expense[]>([]);
  const [selectedTab, setSelectedTab] = React.useState(props.selectedTab || 'ghost');
  const [warehouseId] = React.useState(props.warehouseId);
  const [date] = React.useState(props.date);
  const [expenseService] = React.useState(props.expenseService);

  const statusMap = {
    all: 'ghost',
    pending: 'neutral',
    approved: 'valid',
    denied: 'invalid'
  };

  React.useEffect(() => {
    const reloadData = async () => {
      setExpenses(await getExpenses());
    };
    reloadData();
  }, [props.creationCount]);

  React.useEffect(() => {
    if (expenses.length > 0) {
      const reloadData = async () => {
        setExpenses(setDocumentUrls(await getExpenseDocuments()));
      };
      reloadData();
    }
  }, [expenses.length]);

  // calls warehouser expense api
  // initial expenses could be passed in
  const getExpenses = async (): Promise<Expense[]> => {
    return expenseService.getExpenses(warehouseId, date);
  };

  const getExpenseDocuments = async (): Promise<Expense[]> => {
    return expenseService.getExpenseDocuments(warehouseId, expenses);
  };

  const filterExpensesBySelected = () => {
    return expenses.filter((e) => {
      return selectedTab === 'ghost' || statusMap[e.status.toLowerCase()] === selectedTab;
    });
  };

  const setDocumentUrls = (data: Expense[]) => {
    const newData = [];
    if (data !== undefined && data !== null) {
      data.forEach((element) => {
        expenses.find((x) => x.id === element.id).attachmentUrl = element.attachmentUrl;
      });
      expenses.forEach((expense) => newData.push(expense));
      return newData;
    } else {
      return expenses;
    }
  };

  const getTableRow = (e: Expense) => {
    // TODO: use fields retrieved from db model for prod + currency
    const changeToBillStr = e.status === 'Denied' ? 'NA' : `+${e.warehouseAmount}`;
    return (
      <React.Fragment key={e.id}>
        <tr>
          <td style={{fontWeight: 'bold', minWidth: '10px'}}>{e.id}</td>
          <td style={{fontWeight: 'bold'}}>{e.reservationName}</td>
          <td>
            <span className="first-row">{e.createdAt}</span>
            <span className="other-row">{e.createdBy}</span>
          </td>
          <td>
            <StatusTab title={e.status} status={statusMap[e.status.toLowerCase()]} />
          </td>
          <td>
            <span className="first-row">{e.category}</span>
            <span className="other-row">{e.expenseType}</span>
          </td>
          <td>{`${e.quantityAndUom}`}</td>
          {(e.markupString && <td>{e.markupString}</td>) || (
            <td>{`${e.currencySymbol}${Number(e.rate).toFixed(2)}`}</td>
          )}
          <td className={e.status.toLowerCase()}>{changeToBillStr}</td>
          <td>{e.submissionNotes}</td>
          <td>{e.approvalDate}</td>
          <td>{e.statusNotes}</td>
          <td>
            {e.attachmentUrl && (
              <a href={e.attachmentUrl} className="btn full-block" target="_blank">
                <i className="fa fa-download"></i>
              </a>
            )}
          </td>
        </tr>
        {e.status === 'Denied' && getDenialRow(e)}
      </React.Fragment>
    );
  };

  const getDenialRow = (e: Expense) => {
    return (
      <tr>
        <td colSpan={8} data-testid="denialOtherRow" className="denial-other-row">
          <i className="fa fa-exclamation-triangle invalid"></i>
          &nbsp;&nbsp;{e.statusNotes} <br /> {remediationMessage}
        </td>
      </tr>
    );
  };

  const getExpensesTable = () => {
    return (
      <table className="table expenses-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Reservation</th>
            <th>Date Created</th>
            <th>Status</th>
            <th>Expense</th>
            <th>Qty/Cost</th>
            <th>Rate/Markup</th>
            <th>Change to Bill</th>
            <th>Submission Notes</th>
            <th>Review Date</th>
            <th>Review Notes</th>
            <th>Attachment</th>
          </tr>
        </thead>
        <tbody data-testid="tableBody">
          {filterExpensesBySelected().map((e) => {
            return getTableRow(e);
          })}
        </tbody>
      </table>
    );
  };

  const sumOfType = (targetStatus: string) => {
    return expenses.reduce((prevSum, currExpense) => {
      return currExpense.status === targetStatus ? prevSum + 1 : prevSum;
    }, 0);
  };

  const getTabs = () => {
    const allCount = expenses.length;
    const pendingCount = sumOfType('Pending');
    const approvedCount = sumOfType('Approved');
    const deniedCount = sumOfType('Denied');

    return [
      {
        status: 'ghost',
        title: 'All Expenses',
        count: allCount,
        active: selectedTab === 'ghost'
      },
      {
        status: 'neutral',
        title: 'Pending',
        count: pendingCount,
        active: selectedTab === 'neutral'
      },
      {
        status: 'valid',
        title: 'Approved',
        count: approvedCount,
        active: selectedTab === 'valid'
      },
      {
        status: 'invalid',
        title: 'Denied',
        count: deniedCount,
        active: selectedTab === 'invalid'
      }
    ];
  };

  return (
    <div className="expenses">
      <h1>Additional Expenses</h1>
      <p>Please reach out to billing.support@flexe.com for expenses outside of the current billing period</p>
      <FilterBar data={getTabs()} handleTabSelect={setSelectedTab} />
      {getExpensesTable()}
    </div>
  );
};

export default ExpensesTable;
