import {LoadStatus} from './LoadInterfaces';
import {ManifestStatus} from './ManifestInterfaces';

export const LoadStatusMap = new Map<string, LoadStatus>([
  [ManifestStatus.open, {className: 'load-status-in-progress', text: 'In Progress'}],
  [ManifestStatus.cancelled, {className: 'load-status-cancelled', text: 'Cancelled'}],
  [ManifestStatus.completed, {className: 'load-status-completed', text: 'Completed'}],

  // in some cases the manifest state will have been translated to the front-end text so account for that here
  ['In Progress', {className: 'load-status-in-progress', text: 'In Progress'}],
  ['Cancelled', {className: 'load-status-cancelled', text: 'Cancelled'}],
  ['Completed', {className: 'load-status-completed', text: 'Completed'}]
]);

export const isLoadStatusTerminal = (loadStatus: string): boolean => {
  const cancelled = ManifestStatus.cancelled.toUpperCase();
  const completed = ManifestStatus.completed.toUpperCase();
  loadStatus = loadStatus.toUpperCase();

  return loadStatus === cancelled || loadStatus === completed;
};
