import {RegenerateFreightSsccLabels} from '../../warehouse/outbound-shipments/ShipmentInterfaces';
import {ApiResponse} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

class FreightSsccLabelService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '');
  }

  public async enqueueSsccLabelRegeneration(shipmentId: number): Promise<ApiResponse<RegenerateFreightSsccLabels>> {
    return await this.makePostRequest('/api/v2/shipment_documents/sscc_labels', {shipment_id: shipmentId});
  }
}

export default FreightSsccLabelService;
