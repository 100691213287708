import {RegenerateFreightPackingList} from '../../warehouse/outbound-shipments/ShipmentInterfaces';
import {ApiResponse} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

class FreightPackingListService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '');
  }

  public async enqueueFreightPackingListRegeneration(
    shipmentId: number
  ): Promise<ApiResponse<RegenerateFreightPackingList>> {
    return await this.makePostRequest('/api/v2/shipment_documents/freight_packing_list', {shipment_id: shipmentId});
  }
}

export default FreightPackingListService;
