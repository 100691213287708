import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {CarrierField} from './AccountFields';

export default function AccountDetails({account, carrierDefinition, show, toggleModal}) {
  const skipFields = SkipField(account.carrier);
  return (
    <LegacyModal
      id={`account-details-${account.carrierAccountId}`}
      show={show}
      toggleModal={toggleModal}
      title={`Account Details for ${account.carrierDisplayName}${
        account.accountNumber ? ` - ${account.accountNumber}` : ''
      }`}
      size="small"
    >
      <div className="row">
        {carrierDefinition.fields
          .filter((field) => {
            return !skipFields.includes(field.name);
          })
          .map((field) => (
            <CarrierField
              fieldName={field.name}
              fieldDisplayName={field.displayName}
              fieldValue={
                account[field.dataName] ? account[field.dataName] : account.externalAccountInfo[field.dataName]
              }
              fieldType={field.type}
              disabled={true}
              onChangeFunction={undefined}
              optional={field.optional}
            />
          ))}
      </div>
    </LegacyModal>
  );
}

function SkipField(carrier) {
  switch (carrier) {
    case 'ups':
    case 'dhl':
      return ['password', 'meta_data'];
    default:
      return ['meta_data'];
  }
}
