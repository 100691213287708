import axios from 'axios';
import {get} from 'lodash';
import {v4} from 'uuid';

import {ApiResponse} from '../../shared/CommonInterfaces';
import {FulfillmentEventType, HistogramEntries} from '../dashboard/DashboardInterfaces';
import {FulfillmentState} from './SharedInterfaces';

interface ShippingOptionParams {
  carrierAccountId: number;
  carrierBillingAccountId: string;
  serviceLevel: string;
}

class FulfillmentService {
  private authenticityToken: string;

  private baseUrl = '/s/fulfillment/ecommerce/';

  // deprecated
  private baseUrlV2 = '/s/fulfillment/ecommerce_v2/';

  private carriersBaseUrl = '/s/carrier_accounts/';

  private listState: FulfillmentState = {
    continuationTokens: [],
    counts: {},
    currentPage: 1,
    errors: null,
    filters: {},
    loading: true,
    newContinuationToken: null,
    newShipments: [],
    shipments: [],
    reservations: [],
    selectedAddress: null,
    selectedShipmentIds: [],
    selectedShipmentForRepair: null,
    selectedTab: 'issues',
    showCancelModal: false,
    showRetryModal: false,
    showFixAddressModal: false,
    usesOrderManager: false,
    distributionByLotEnabled: false,
    cancellationReason: null
  };

  constructor(authenticityToken: string) {
    this.authenticityToken = authenticityToken;
  }

  public async getShipments(state: string, filters: any = {}, continuationToken: string = null, pageSize: number = 0) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.post(`${this.baseUrl}shipments`, {
        authenticity_token: this.authenticityToken,
        continuationToken,
        filters,
        reservationId: filters ? filters.reservationId : null,
        state
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public async retryShipmentsWithIssues(shipmentIds: number[], filters: any = {}): Promise<ApiResponse<{}>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.patch(`${this.baseUrl}retry_shipments_with_issues`, {
        authenticity_token: this.authenticityToken,
        shipmentIds,
        reservationId: filters ? filters.reservationId : null,
        filters
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public downloadFulfillmentIssuesAsync = async (filters: any) => {
    const response = await axios.post('/s/fulfillment/ecommerce/shipments_with_issues.csv', {
      authenticity_token: this.authenticityToken,
      headers: {'Content-Type': 'application/json'},
      reservationId: filters.reservationId,
      filters: {...filters, reservationId: undefined}
    });

    const contentDisposition = response.headers['content-disposition'];
    // eslint-disable-next-line no-useless-escape
    const filename = contentDisposition.match(/filename=\"(.*)\"/)[1];

    const blob = new Blob([response.data], {type: 'octet/stream'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  };

  public async getShipment(shipmentId: number) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get(`${this.baseUrl}${shipmentId}/shipment`, {
        params: {
          authenticity_token: this.authenticityToken,
          status
        }
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async cancelShipments(shipmentIds: number[]) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.patch(`${this.baseUrl}cancel_shipments`, {
        authenticity_token: this.authenticityToken,
        shipmentIds
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async cancelShipmentsWithReason(shipmentIds: number[], cancelledReason: string) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.patch(`${this.baseUrl}cancel_shipments`, {
        authenticity_token: this.authenticityToken,
        shipmentIds,
        cancelledReason
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async validateAddress(addressParams) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.post(`${this.carriersBaseUrl}validate_address`, {
        authenticity_token: this.authenticityToken,
        meta: {
          correlationId: v4()
        },
        data: {
          address: addressParams
        }
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async updateAddress(shipmentId, addressParams, forceGivenAddress: boolean = false) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.put(`${this.baseUrlV2}${shipmentId}/update`, {
        authenticity_token: this.authenticityToken,
        meta: {
          correlationId: v4()
        },
        data: {
          address: addressParams,
          forceGivenAddress
        }
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async getShippingOptions(shipmentId: number) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get(`${this.baseUrlV2}${shipmentId}/all_shipping_options_for_shipment`, {
        params: {authenticity_token: this.authenticityToken}
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async setShippingOption(shipmentId: number, shippingOptionParams: ShippingOptionParams) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.patch(`${this.baseUrlV2}${shipmentId}/select_shipping_option_for_shipment`, {
        authenticity_token: this.authenticityToken,
        meta: {
          correlationId: v4()
        },
        data: shippingOptionParams
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async getHistograms(
    rangeEnd: string,
    width: number,
    numBins: number,
    type: FulfillmentEventType
  ): Promise<ApiResponse<HistogramEntries>> {
    const params = {
      authenticity_token: this.authenticityToken,
      type,
      width,
      numBins,
      rangeEnd
    };
    const response = await axios.get(`${this.baseUrl}histograms`, {params});
    return response.data;
  }

  public processErrorResponse = (errorResponse: Response) => {
    const errors = get(errorResponse, 'response.data.errors');
    return errors.map((error) => error.detail || error.title);
  };

  public setListCache(state: FulfillmentState) {
    this.listState = state;
  }

  public getListCache(): FulfillmentState {
    return this.listState;
  }
}

export default FulfillmentService;
