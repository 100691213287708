import {css} from '@emotion/react';
import tokens from '@flexe/ui-tokens';

export const rowStyle = css({
  borderTop: '1px solid #c5cad2',
  borderBottom: '1px solid #c5cad2'
});

export const userRowStyle = css`
  ${rowStyle};
  height: 56px;
`;

export const containerStyle = css({padding: `${tokens.spacing.v200.value}px ${tokens.spacing.v300.value}px`});
