import {Icon} from '@flexe/ui-components';
import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';
import {Role} from '../../userManagementInterfaces';

interface Props {
  role: Role;
}

const RoleTooltip = ({role}: Props) => {
  const tooltipId = `${role.title}-role-tooltip`;

  return (
    <>
      <a href="#" data-tip data-for={tooltipId}>
        <Icon icon="question-circle" />
      </a>
      <ReactTooltip place="top" effect="solid" id={tooltipId} className="react-tooltip--max-width-200">
        {role.description}
      </ReactTooltip>
    </>
  );
};

export default RoleTooltip;
