import * as React from 'react';
import {buildTextInput} from '../../../../libs/helpers';
import {AttachmentType} from '../OutboundOrdersInterfaces';
import {AttachmentRow} from './AttachmentTable';

const attachmentTypeDefaultValue = 'pseudo_option';
type BasicTextInputField = 'DisplayName' | 'ExternalId' | 'TrackingNumber';

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const attachmentTypeToName = (value: AttachmentType | string): string => {
  switch (value) {
    case 'insert':
      return 'Insert';
    case 'shipping_label':
      return 'Shipping Label';
  }

  return '';
};

function attachmentTypeOptions(rowKey: number, shippingLabelRowKey?: number): JSX.Element[] {
  // Don't allow duplicate shipping labels to be attached
  if (shippingLabelRowKey && shippingLabelRowKey && rowKey !== shippingLabelRowKey) {
    return [
      <option key={2} value="insert">
        {attachmentTypeToName('insert')}
      </option>
    ];
  }

  return [
    // The empty option appears to work best so ignore the parser
    <option key={1} value={attachmentTypeDefaultValue} hidden></option>,
    <option key={2} value="insert">
      {attachmentTypeToName('insert')}
    </option>,
    <option key={3} value="shipping_label">
      {attachmentTypeToName('shipping_label')}
    </option>
    // TODO add other options as we support them in the future
  ];
}

function displayTrackingNumberInput(attachmentType?: AttachmentType): boolean {
  return attachmentType === 'shipping_label';
}

interface Props {
  //onAddedFile tells us this is a row for the add attachment button
  onAddedFile?: (attachmentRow: AttachmentRow) => void;
  countOfExistingRows: number;
  attachmentRow: AttachmentRow;
  shippingLabelRowKey?: number;
  columnCount: number;
  // isReadOnly will cause us to ignore onDeleteRow and onModifyRow
  isReadOnly?: boolean;
  onDeleteRow?: (rowKey: number) => void;
  onModifyRow?: (newRow: AttachmentRow, setTypeToShippingLabel?: boolean) => void;
}

export const AttachmentTableRow: React.FC<Props> = (props) => {
  // state is really controlled by the parents so just make a constant for easier referencing
  const attachmentRow = props.attachmentRow;

  /*
   * Short-circuit for the final button.
   *  See note in AttachmentTable for details as to why we use this component at all.
   *  (as opposed to a separate component type).
   *  The crux of this is to use a button rather than the not-as-pretty standard input button (we hide that)
   */
  if (props.onAddedFile) {
    /*
     * File Handling
     */
    const fileInputRef = React.useRef(null);

    const fileButtonOnClick = () => {
      fileInputRef.current.click();
    };

    const fileInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRow = {...attachmentRow};

      if (event && event.target && event.target.files && event.target.files.length > 0) {
        updatedRow.file = event.target.files[0];
      } else {
        updatedRow.file = null;
      }
      props.onAddedFile(updatedRow);
    };

    // It shouldn't be necessary to span all columns, but might as well.
    // Keep it left aligned so the position of the button is more natural
    //  (type is above or to the right once the row is added)
    return (
      <tr>
        <td className="col-md-12" colSpan={props.columnCount}>
          <button className="btn" type="button" onClick={fileButtonOnClick}>
            {props.countOfExistingRows > 0 ? 'Add Another Attachment' : 'Add an Attachment'}
          </button>
          <input
            name="file"
            type="file"
            accept="image/png,application/pdf"
            onChange={fileInputOnChange}
            ref={fileInputRef}
            style={{display: 'none'}}
          />
        </td>
      </tr>
    );
  }

  /*
   * Simple Fields
   */
  // This doesn't use buildTextInput so it has a separate function
  const attachmentTypeOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event && event.target && props.onModifyRow) {
      if (event.target.value === 'insert') {
        props.onModifyRow({...attachmentRow, type: 'insert'});
      } else if (event.target.value === 'shipping_label') {
        props.onModifyRow({...attachmentRow, type: 'shipping_label'}, true);
      }
    }
  };

  const basicTextOnChange = (inputField: BasicTextInputField, value: string) => {
    if (props.onModifyRow) {
      // This should only be called if something actually changes, so we always update the object
      const newRow = {...attachmentRow};
      switch (inputField) {
        case 'DisplayName':
          newRow.displayName = value;
          break;
        case 'ExternalId':
          newRow.externalId = value;
          break;
        case 'TrackingNumber':
          newRow.trackingNumber = value;
          break;
      }
      props.onModifyRow(newRow);
    }
  };

  // Trim has to happen after input is done otherwise you can't use spaces at all
  const basicTextOnBlur = (inputField: BasicTextInputField) => {
    if (props.onModifyRow) {
      // It is unlikely that trim is needed, so we try to avoid changing the object
      if (inputField === 'DisplayName') {
        const trimmed = attachmentRow.displayName.trim();
        if (trimmed !== attachmentRow.displayName) {
          props.onModifyRow({...attachmentRow, displayName: trimmed});
        }
      } else if (inputField === 'ExternalId') {
        const trimmed = attachmentRow.externalId.trim();
        if (trimmed !== attachmentRow.externalId) {
          props.onModifyRow({...attachmentRow, externalId: trimmed});
        }
      } else if (inputField === 'TrackingNumber') {
        const trimmed = attachmentRow.trackingNumber.trim();
        if (trimmed !== attachmentRow.trackingNumber) {
          props.onModifyRow({...attachmentRow, trackingNumber: trimmed});
        }
      }
    }
  };

  const onDelete = () => {
    if (props.onDeleteRow) {
      props.onDeleteRow(attachmentRow.rowKey);
    }
  };

  /*
   * Various HTML config/values
   */
  let trackingNumberCell;
  if (!displayTrackingNumberInput(attachmentRow.type)) {
    trackingNumberCell = ''; // We still need to render the column so the delete button is in the right place
  } else if (props.isReadOnly) {
    trackingNumberCell = attachmentRow.trackingNumber;
  } else {
    trackingNumberCell = buildTextInput(
      '',
      attachmentRow.trackingNumber,
      (v) => basicTextOnChange('TrackingNumber', v),
      'DTracking Number',
      undefined,
      () => basicTextOnBlur('TrackingNumber')
    );
  }

  return (
    <tr>
      <td className="col-md-2">{attachmentRow.file ? attachmentRow.file.name : 'Error. Please delete this row.'}</td>
      <td className="col-md-2">
        {props.isReadOnly ? (
          attachmentTypeToName(attachmentRow.type)
        ) : (
          <select
            name="attachmentType"
            value={attachmentRow.type ? attachmentRow.type : attachmentTypeDefaultValue}
            onChange={(e) => attachmentTypeOnChange(e)}
          >
            {attachmentTypeOptions(attachmentRow.rowKey, props.shippingLabelRowKey)}
          </select>
        )}
      </td>
      <td className="col-md-2">
        {props.isReadOnly
          ? attachmentRow.displayName
          : buildTextInput(
              '',
              attachmentRow.displayName,
              (v) => basicTextOnChange('DisplayName', v),
              'Display Name',
              undefined,
              () => basicTextOnBlur('DisplayName')
            )}
      </td>
      <td className="col-md-2">
        {props.isReadOnly
          ? attachmentRow.externalId
          : buildTextInput(
              '',
              attachmentRow.externalId,
              (v) => basicTextOnChange('ExternalId', v),
              'External ID',
              undefined,
              () => basicTextOnBlur('ExternalId')
            )}
      </td>
      <td className="col-md-2">{trackingNumberCell}</td>
      {!props.isReadOnly && ( // Hide this entirely in read only mode
        <td className="col-md-2">
          <button type="button" className="btn-danger" onClick={onDelete}>
            Delete
          </button>
        </td>
      )}
    </tr>
  );
};
