import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {ContainerDelivery} from './DropoffInterfaces';

interface Props {
  showModal: boolean;
  toggleModal: () => void;
  containerDelivery: ContainerDelivery;
  onCompleteDropoff: () => void;
}

export const CompleteDropoffModal: React.FC<Props> = (props) => {
  return (
    <LegacyModal
      id="complete_modal"
      show={props.showModal}
      size="small"
      title="Complete Dropoff"
      toggleModal={props.toggleModal}
      footer={
        <div>
          <a className="btn" onClick={props.toggleModal}>
            Cancel
          </a>
          <a className="btn btn-modal-complete" onClick={props.onCompleteDropoff}>
            Complete
          </a>
        </div>
      }
    >
      <p>
        This delivery is scheduled to arrive in the future.
        <br />
        Are you sure you want to complete this delivery early?
      </p>
    </LegacyModal>
  );
};

export default CompleteDropoffModal;
