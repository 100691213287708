import * as React from 'react';
import classNames from 'classnames';

interface TabsProps {
  tabs: Tab[];
  pills?: boolean;
  stacked?: boolean;
  tabEvent(event: any): void;
}

interface Tab {
  active: boolean;
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  key: string;
  href?: string;
  pullRight?: boolean;
}

// ****************************
//
// DO NOT USE THIS COMPONENT ANYWHERE OTHER THAN app/javascript/components/shipper/account-settings/AccountSettings.tsx
//
// ****************************
class StopgapTabsDoNotUse extends React.Component<TabsProps> {
  public render() {
    const navClasses = ['nav', this.props.pills ? 'nav-pills' : 'nav-tabs', this.props.stacked && 'stacked'];

    const tabs = this.props.tabs.map((tab, index) => {
      const tabClasses = [tab.active && 'active', tab.pullRight && 'pull-right'];
      const linkClasses = [tab.href === window.location.pathname && 'active'];
      return (
        <li key={index} className={classNames(tabClasses)}>
          {tab.href && (
            <a
              data-key={tab.key}
              data-testid={tab.key}
              onClick={this.handleTabClick}
              href={tab.href}
              className={classNames(linkClasses)}
            >
              {tab.title && <h2>{tab.title}</h2>}
              {tab.subTitle && <div>{tab.subTitle}</div>}
            </a>
          )}
          {!tab.href && (
            <a data-key={tab.key} data-testid={tab.key} onClick={this.handleTabClick}>
              {tab.title && <h2>{tab.title}</h2>}
              {tab.subTitle && <div>{tab.subTitle}</div>}
            </a>
          )}
        </li>
      );
    });

    return (
      <ul className={classNames(navClasses)} id="tabs-component">
        {tabs}
      </ul>
    );
  }

  private handleTabClick = (event: any) => {
    const tabKey = event.currentTarget.getAttribute('data-key');
    this.props.tabEvent(tabKey);
  };
}

export default StopgapTabsDoNotUse;
