import * as React from 'react';

interface WarningModalProps {
  elementId: string;
  toggleModalHandler: () => void;
  onConfirmClick: () => void;
  confirmButtonText: React.ReactFragment;
  modalTitle: React.ReactFragment;
  modalBody: React.ReactFragment;
}

const WarningModal: React.FC<WarningModalProps> = (props) => {
  return (
    <div id={props.elementId} data-testid={props.elementId}>
      <div className="delete-modal">
        <div className="modal-backdrop"></div>
        <div className="modal" tabIndex={-1} role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  data-testid="close-btn"
                  onClick={props.toggleModalHandler}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5 className="modal-title">{props.modalTitle}</h5>
              </div>
              <div className="modal-body">{props.modalBody}</div>
              <div className="modal-footer">
                <button type="button" onClick={props.onConfirmClick} className="btn">
                  {props.confirmButtonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
