import {start} from 'repl';
import * as moment from 'moment';
import {isAfter} from 'date-fns';

interface ValidationErrors {
  [Key: string]: ErrorType;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
interface RetailRequest {
  address: string;
  address2: string;
  bol: string;
  city: string;
  destination: string;
  inventoryData: InventoryData[];
  po: string;
  postal: string;
  routeByDeadline: Date;
  routingService: string;
  shipWindowEnd: Date;
  shipWindowStart: Date;
  state: string;
  errors: ValidationErrors;
}

interface InventoryData {
  sku: string;
  quantity: number;
  unit: string;
  attachments?: string[];
}

enum ErrorType {
  InvalidDateTime = 'Date or Time not parsable',
  InvalidQuantity = 'Quantity must be a valid number',
  InvalidShipWindow = 'Ship Window Start must be before Ship Window End',
  Required = 'Value is required',
  RouteByDeadline = 'Route By Deadline cannot be after Ship Window End'
}

const DATE_FORMAT = 'M/D/YYYY H:m';

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
class RetailRequest implements RetailRequest {
  constructor(data) {
    this.routingService = data.routingService || null;
    this.address = data.address || null;
    this.address2 = data.address2 || null;
    this.bol = data.bol || null;
    this.city = data.city || null;
    this.destination = data.destination || null;
    this.po = data.po || null;
    this.postal = data.postal || null;
    this.state = data.state || null;
    this.errors = {};
    this.inventoryData = [];

    this.validateRequired(data);
    this.generateInventoryData(data);
    this.validateDateTimes(data);
  }

  private validateRequired(data) {
    const fieldsToIgnore = [
      'address2',
      'routingService',
      'routeByDeadlineDate',
      'routeByDeadlineTime',
      'shipWindowEndDate',
      'shipWindowEndTime',
      'shipWindowStartDate',
      'shipWindowStartTime',
      'sku',
      'quantity'
    ];
    for (const key in data) {
      if (fieldsToIgnore.indexOf(key) === -1 && data[key] === null) {
        this.errors[key] = ErrorType.Required;
      }
    }
  }

  private generateInventoryData(data) {
    if (data.sku && data.quantity && data.unit) {
      if (isNaN(data.quantity)) {
        this.errors.inventoryData = ErrorType.InvalidQuantity;
      } else {
        this.inventoryData.push({
          quantity: Number(data.quantity),
          sku: data.sku,
          unit: data.unit
        });
      }
    } else {
      this.errors.inventoryData = ErrorType.Required;
    }
  }

  private validateDateTimes(data) {
    const routeByMoment =
      data.routeByDeadlineDate && data.routeByDeadlineTime
        ? moment(`${data.routeByDeadlineDate} ${data.routeByDeadlineTime}`, DATE_FORMAT)
        : null;
    if (routeByMoment && routeByMoment.isValid()) {
      this.routeByDeadline = routeByMoment.toDate();
    } else if (routeByMoment && !routeByMoment.isValid()) {
      this.errors.routeByDeadline = ErrorType.InvalidDateTime;
    }

    const endMoment =
      data.shipWindowEndDate && data.shipWindowEndTime
        ? moment(`${data.shipWindowEndDate} ${data.shipWindowEndTime}`, DATE_FORMAT)
        : null;
    if (endMoment && endMoment.isValid()) {
      this.shipWindowEnd = endMoment.toDate();
    } else {
      this.errors.shipWindowEnd = this.validateDateTime(data, endMoment);
    }

    if (
      routeByMoment &&
      endMoment &&
      routeByMoment.isValid() &&
      endMoment.isValid() &&
      isAfter(this.routeByDeadline, this.shipWindowEnd)
    ) {
      this.errors.routeByDeadline = ErrorType.RouteByDeadline;
    }

    const startMoment =
      data.shipWindowStartDate && data.shipWindowStartTime
        ? moment(`${data.shipWindowStartDate} ${data.shipWindowStartTime}`, DATE_FORMAT)
        : null;
    if (startMoment && startMoment.isValid()) {
      this.shipWindowStart = startMoment.toDate();
    } else {
      this.errors.shipWindowStart = this.validateDateTime(data, startMoment);
    }
    if (
      startMoment &&
      endMoment &&
      startMoment.isValid() &&
      endMoment.isValid() &&
      isAfter(this.shipWindowStart, this.shipWindowEnd)
    ) {
      this.errors.shipWindowStart = ErrorType.InvalidShipWindow;
    }
  }

  private validateDateTime(data, endMoment) {
    let shipWindowError;
    if (endMoment && !endMoment.isValid()) {
      shipWindowError = ErrorType.InvalidDateTime;
    } else {
      shipWindowError = ErrorType.Required;
    }
    return shipWindowError;
  }
}

export default RetailRequest;
