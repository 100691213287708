import {ApiResponse} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

class ShippingLabelService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/fulfillment/ecommerce');
  }

  public async regenerateShipment(shipmentId: number, allowForTerminal: boolean): Promise<ApiResponse<number>> {
    return await this.makePostRequest(`${this.baseUrl}/order/${shipmentId}/regenerate_shipping_labels`, {
      allowForTerminal
    });
  }
}

export default ShippingLabelService;
