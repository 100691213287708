import * as React from 'react';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import {Loader} from '@flexe/ui-components';
import {FulfillmentType} from './DashboardInterfaces';
import {renderContent} from './Helpers';

interface Props {
  loading: boolean;
  fulfillmentType: FulfillmentType;
  titleDescriptor: string; // Open or Total
  totalOpen: number;
  issuesCount: number;
  newCount: number;
  progressCount: number;
  errors: string[];
}

const strokeWidth = 7;

class OpenFulfillmentActivityCard extends React.Component<Props> {
  public render() {
    return (
      <div className="card col-sm-12">
        <Loader loading={this.props.loading} />
        {!this.props.loading && renderContent(this.props.errors, this.renderGaugeRow)}
      </div>
    );
  }

  private renderGaugeRow = () => {
    const {issuesCount, newCount, progressCount} = this.props;

    return (
      <>
        {this.renderGaugeTitle()}
        {issuesCount >= 0 && this.renderGauge('red3', 'Issues', issuesCount)}
        {newCount >= 0 && this.renderGauge('newPurple', 'New', newCount)}
        {progressCount >= 0 && this.renderGauge('blue3', 'In Progress', progressCount)}
      </>
    );
  };

  private renderGaugeTitle = () => {
    const description = `Total ${this.props.fulfillmentType}s currently being processed${
      this.props.fulfillmentType === FulfillmentType.order ? ' that were created within the last 30 days.' : '.'
    }`;
    return (
      <div className="col-sm-3">
        <h4 className="grey3">
          {this.props.titleDescriptor} {this.props.fulfillmentType}s
        </h4>
        <h3>{this.props.totalOpen.toLocaleString()}</h3>
        <br />
        <small className="grey3">{description}</small>
      </div>
    );
  };

  private renderGauge = (className: string, title: string, count: number) => {
    const percentage = this.percentageForGauges(count);
    return (
      <div className="col-sm-3">
        <CircularProgressbarWithChildren className={className} strokeWidth={strokeWidth} value={percentage}>
          {/* This is an SVG so the gauge text scales with the gauge if it's resized due to layout changes */}
          <svg viewBox="0 0 100 100" className="gauge-text">
            <text x={50} y={50}>
              <tspan key="l" x="50%" textAnchor="middle" dy="-0.5em">
                {title}
              </tspan>
              <tspan key="v" x="50%" textAnchor="middle" dy="1.2em" className="ct">
                {count.toLocaleString()}
              </tspan>
            </text>
          </svg>
        </CircularProgressbarWithChildren>
      </div>
    );
  };

  private percentageForGauges = (count: number) => {
    const float = count / this.props.totalOpen;
    let percentage = 0;
    // if we're between 99-100%, round down to 99% - otherwise round up to nearest percent
    if (float >= 0.99 && float < 1) {
      percentage = Math.floor(float * 100);
    } else {
      percentage = Math.ceil(float * 100);
    }

    // passing 0 into these gauges renders as if 100^ for some reason, so fudge an infinitesimal percentage
    if (isNaN(percentage) || percentage === 0) {
      percentage = 0.000001;
    }

    return percentage;
  };
}

export default OpenFulfillmentActivityCard;
