import * as React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import WarehouseService from '../../shared/services/WarehouseService';
import ShipmentPackagingService from '../../shared/shipment-packages/ShipmentPackagingService';
import ShipmentPackagings from '../../shared/shipment-packages/ShipmentPackagings';
import {Company} from '../../shared/CommonInterfaces';
import StopgapTabsDoNotUse from './StopgapTabsDoNotUse';
import CarrierAccountsV2 from './carrier-accounts/CarrierAccountsV2';
import CarrierBillingAccountsV2 from './carrier-billing-accounts/CarrierBillingAccountsV2';
import CarrierBillingAccountService from './carrier-billing-accounts/CarrierBillingAccountService';
import VendorList from './vendors/VendorList';
import PackingSlips from './packing-slips/PackingSlips';
import ShippingLabelsAPIKey from './shipping-labels-api/ShippingLabelsAPIKey';
import VendorService from './vendors/VendorService';

interface Props {
  authenticityToken: string;
  currentCompany: Company;
  showEcommerceAccountInfo?: boolean;
}

const accountSettingsPath = '/s/account_settings';
const carrierAccountsPath = '/s/account_settings/carrier_accounts';
const carrierBillingAccountsPath = '/s/account_settings/carrier_billing_accounts';
const vendorsPath = '/s/account_settings/vendors';
const packingSlipsPath = '/s/account_settings/packing_slips';
const shippoAPIKeyPath = '/s/account_settings/shippo_api_key';
const defaultOverboxPath = '/s/account_settings/default_overboxes';

// eslint-disable-next-line @typescript-eslint/ban-types
class AccountSettings extends React.Component<Props, {}> {
  private carrierBillingAccountService: CarrierBillingAccountService;
  private vendorService: VendorService;
  private shipmentPackagingService: ShipmentPackagingService;
  private warehouseService: WarehouseService;

  constructor(props) {
    super(props);
    this.carrierBillingAccountService = new CarrierBillingAccountService(this.props.authenticityToken);
    this.vendorService = new VendorService(this.props.authenticityToken);
    this.shipmentPackagingService = new ShipmentPackagingService(this.props.authenticityToken);
    this.warehouseService = new WarehouseService(this.props.authenticityToken);
  }

  public render() {
    return (
      <Router>
        <div className="account-settings row no-margin">
          <div className="col-sm-2" style={{paddingLeft: 0}}>
            {/* This component is a stopgap DO NOT USE IT ANYWHERE ELSE! */}
            <StopgapTabsDoNotUse tabs={this.getTabs()} pills={true} stacked={true} tabEvent={() => null} />
          </div>
          <div className="account-settings__content col-sm-10">
            <Switch>
              <Route path={accountSettingsPath} exact>
                <Redirect to={carrierAccountsPath} />
              </Route>
              <Route
                path={carrierAccountsPath}
                exact
                render={(routeProps) => {
                  return (
                    <CarrierAccountsV2
                      {...routeProps}
                      {...this.props}
                      showAccountReference={this.props.showEcommerceAccountInfo}
                    />
                  );
                }}
              />
              <Route
                path={carrierBillingAccountsPath}
                exact
                render={(routeProps) => {
                  return (
                    <CarrierBillingAccountsV2
                      {...routeProps}
                      carrierBillingAccountService={this.carrierBillingAccountService}
                      vendorService={this.vendorService}
                      warehouseService={this.warehouseService}
                    />
                  );
                }}
              />
              {this.props.showEcommerceAccountInfo && (
                <Route
                  path={vendorsPath}
                  exact
                  render={() => <VendorList authenticityToken={this.props.authenticityToken} />}
                />
              )}
              <Route
                path={packingSlipsPath}
                exact
                render={(routeProps) => <PackingSlips {...routeProps} {...this.props} />}
              />
              <Route
                path={shippoAPIKeyPath}
                exact
                render={(routeProps) => <ShippingLabelsAPIKey {...routeProps} {...this.props} />}
              />
              <Route
                path={defaultOverboxPath}
                exact
                render={(routeProps) => (
                  <ShipmentPackagings
                    {...routeProps}
                    shipmentPackagingService={this.shipmentPackagingService}
                    warehouseService={this.warehouseService}
                    isShipper={true}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }

  private getTabs() {
    // accounts setting nav options
    let tabs = [
      {
        active: false,
        href: accountSettingsPath,
        key: null,
        subTitle: null,
        title: (
          <span>
            <i className="fa fa-gear"></i> Settings
          </span>
        )
      },
      {
        active: false,
        href: carrierAccountsPath,
        key: null,
        subTitle: 'Carrier Accounts',
        title: null
      },
      {
        active: false,
        href: carrierBillingAccountsPath,
        key: null,
        subTitle: '3rd Party Carrier Billing Accounts',
        title: null
      }
    ];

    if (this.props.showEcommerceAccountInfo) {
      tabs.push({
        active: false,
        href: vendorsPath,
        key: null,
        subTitle: 'Vendor Return Addresses',
        title: null
      });
    }

    tabs = tabs.concat([
      {
        active: false,
        href: packingSlipsPath,
        key: null,
        subTitle: 'Packing Slips',
        title: null
      },
      {
        active: false,
        href: shippoAPIKeyPath,
        key: null,
        subTitle: 'Shippo API Key',
        title: null
      },
      {
        active: false,
        href: defaultOverboxPath,
        key: null,
        subTitle: 'Packagings',
        title: null
      }
    ]);

    return tabs;
  }
}

export default AccountSettings;
