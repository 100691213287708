import * as React from 'react';
import BasicTableRow from './BasicTableRow';

interface Props {
  headers: React.ReactNode[];
  data: React.ReactNode[][];
}

const CsvUploadBulkCreateInstructionsTable: React.FC<Props> = (props: Props) => {
  return (
    <table className="instructions-table">
      <thead>
        <tr className="instructions-table-header-row">
          {Object.keys(props.headers).map((k, i) => {
            return <th key={`${k}-${i}`}>{props.headers[k]}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {props.data.map((datum, i) => {
          // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
          return <BasicTableRow data={datum} key={`${datum[i]}-${i}`} />;
        })}
      </tbody>
    </table>
  );
};

export default CsvUploadBulkCreateInstructionsTable;
