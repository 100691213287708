import {ApiResponse} from '../CommonInterfaces';
import {
  ManifestContentsSearchByManifestRequest,
  ManifestContentsSearchByShipmentRequest,
  ManifestContentsSearchResponse
} from '../../warehouse/loads/ManifestInterfaces';
import InternalAPIService from './InternalAPIService';

class ManifestContentsService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/manifest_contents');
  }

  public async searchManifestContentsByShipment(
    request: ManifestContentsSearchByShipmentRequest
  ): Promise<ApiResponse<ManifestContentsSearchResponse>> {
    return await this.makePostRequest(`${this.baseUrl}/search_by_shipment_id`, request);
  }

  public async searchManifestContentsByManifest(
    request: ManifestContentsSearchByManifestRequest
  ): Promise<ApiResponse<ManifestContentsSearchResponse>> {
    return await this.makePostRequest(`${this.baseUrl}/search_by_manifest_id`, request);
  }
}

export default ManifestContentsService;
