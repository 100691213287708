import {TransitionState} from '../../shared/CommonInterfaces';
import {DotStatus} from '../../shared/StatusDot';
import {PickStep, PickWaveStatus} from './WaveBatchInterfaces';

/**
 * Gets DotType for a given {@link TransitionState} to match up with the string literal types
 * in {@link StatusDot}
 *
 * @param transitionState A Transition State (usually calculated from PickSteps or WaveStatus)
 *
 * @returns DotType best matching given {@link TransitionState}
 */
export function transitionStateToDotStatus(transitionState: TransitionState): DotStatus {
  switch (transitionState) {
    case TransitionState.New:
    case TransitionState.NotStarted:
    case TransitionState.Confirmed:
      return 'neutral';
    case TransitionState.Active:
    case TransitionState.InProgress:
      return 'support';
    case TransitionState.Completed:
      return 'valid';
    case TransitionState.Cancelled:
      return 'invalid';
    default:
      return 'neutral';
  }
}

interface TextAndDotStatus {
  text: string;
  dotStatus: DotStatus;
}

/**
 * Calculated Text and DotType. Text is formatted and using terminology more friendly to the warehouse operator,
 * and the DotType matches up with the string literal types in {@link StatusDot}
 *
 * @param shipmentStatus The shipment status. I.E: 'confirmed' or 'new'
 *
 * @returns object containing Text and DotType. Text that is parsed for end-user consumption and a DotType that aligns
 * with the expected string literal for {@link StatusDot}
 */
export function getShipmentDotStatusAndText(shipmentStatus: string): TextAndDotStatus {
  switch (shipmentStatus) {
    case 'new':
      return {text: 'Not Started', dotStatus: 'neutral'};
    case 'confirmed':
      return {text: 'In Progress', dotStatus: 'support'};
    case 'packed':
      return {text: 'Packed', dotStatus: 'inform'};
    case 'completed':
      return {text: 'Shipped', dotStatus: 'valid'};
    case 'cancelled':
      return {text: 'Cancelled', dotStatus: 'invalid'};
    case 'voided':
      return {text: 'Voided', dotStatus: 'invalid'};
    case 'attention_required':
      return {text: 'Attention Required', dotStatus: 'invalid'};
    case 'failed':
    default:
      return {text: 'Failed', dotStatus: 'invalid'};
  }
}

/**
 * Calculates {@link TransitionState} from given wave status string
 *
 * @param status Wave status
 *
 * @returns {@link TransitionState} for the wave
 */
export function waveStatusToTransitionState(status: string): TransitionState {
  switch (status) {
    case PickWaveStatus.new:
    case PickWaveStatus.not_started:
      return TransitionState.NotStarted;
    case PickWaveStatus.in_progress:
      return TransitionState.InProgress;
    case PickWaveStatus.completed:
      return TransitionState.Completed;
    case PickWaveStatus.cancelled:
      return TransitionState.Cancelled;
    default:
      return TransitionState.NotStarted;
  }
}

const onlyUnique = (value, index, array) => array.indexOf(value) === index;

/**
 * Returns the {@link TransitionState} from given {@link PickStep}
 *
 * @param pickSteps PickSteps (usually from a single batch)
 *
 * @returns The calculated {@link TransitionState} for a given set of PickSteps
 */
export function calculateTransitionState(pickSteps: PickStep[]): TransitionState {
  const statuses = pickSteps.map((pickStep) => pickStep.status).filter(onlyUnique);

  let result = TransitionState.NotStarted;

  if (statuses.includes('in_progress')) {
    result = TransitionState.InProgress;
  } else if (statuses.includes('completed')) {
    result = statuses.length === 1 ? TransitionState.Completed : TransitionState.InProgress;
  }

  return result;
}

/**
 * Opens a new browser window for each document in passed documentIds
 *
 * @param documentIds List of document ids
 */
export function handlePrintAllDocuments(documentIds: number[]): void {
  for (const documentId of documentIds) {
    window.open(`/wh/logged_document/${documentId}`);
  }
}
