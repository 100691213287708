import * as React from 'react';
import {PackingListItem} from '../shared/DropoffInterfaces';
import {renderItemLink} from '../../../libs/helpers';

interface Props {
  expectedPackingLists: PackingListItem[];
  shippablePackingLists: PackingListItem[];
  damagedPackingLists: PackingListItem[];
}

export default function SkuSummary(props: Props) {
  // Build expected Rows
  const expectedRows: JSX.Element[] = [];

  props.expectedPackingLists.map((epl, idx) => {
    const spl: PackingListItem[] = props.shippablePackingLists.filter((spli) => {
      return epl.inventory.id === spli.inventory.id && epl.quantity.unit === spli.quantity.unit;
    });

    const dpl: PackingListItem[] = props.damagedPackingLists.filter((dpli) => {
      return epl.inventory.id === dpli.inventory.id && epl.quantity.unit === dpli.quantity.unit;
    });

    expectedRows.push(
      <tr key={`expected${idx}`}>
        <td>{renderItemLink(epl.inventory.id, epl.inventory.sku, false)}</td>
        <td>{epl.inventory.description}</td>
        <td>{epl.quantity.amount}</td>
        <td>{spl.reduce((result, item) => result + item.quantity.amount, 0)}</td>
        <td>{dpl.reduce((result, item) => result + item.quantity.amount, 0)}</td>
        <td>{epl.quantity.unit}</td>
      </tr>
    );
  });

  // Set up our aggregated unexpected packing list
  const unexpectedPackingList: Map<string, PackingListItem[]> = props.shippablePackingLists
    // check for any unexpected shippable items
    .filter((spl) => {
      return (
        props.expectedPackingLists.filter((pl) => {
          return pl.inventory.id === spl.inventory.id && pl.quantity.unit === spl.quantity.unit;
        }).length < 1
      );
    })
    .reduce((result, item) => {
      // Group by inventory id and unit
      const key = item.inventory.id + ' ' + item.quantity.unit;
      result.set(key, [...(result.get(key) || []), item]);
      return result;
    }, new Map<string, PackingListItem[]>());

  const unexpectedRows: JSX.Element[] = [];

  unexpectedPackingList.forEach((spl, key) => {
    const dpl: PackingListItem[] = props.damagedPackingLists.filter((dpli) => {
      return spl[0].inventory.id === dpli.inventory.id && spl[0].quantity.unit === dpli.quantity.unit;
    });

    unexpectedRows.push(
      <tr key={`unexpected${key}`}>
        <td>{renderItemLink(spl[0].inventory.id, spl[0].inventory.sku, false)}</td>
        <td>{spl[0].inventory.description}</td>
        <td>0</td>
        <td>{spl.reduce((result, item) => result + item.quantity.amount, 0)}</td>
        <td>{dpl.reduce((result, item) => result + item.quantity.amount, 0)}</td>
        <td>{spl[0].quantity.unit}</td>
      </tr>
    );
  });

  return (
    <div>
      <h3>SKU Summary</h3>
      <table className="table">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Description</th>
            <th>Expected</th>
            <th key="shippable">Shippable Received</th>
            <th key="damaged">Damaged Received</th>
            <th key="unit">Unit</th>
          </tr>
        </thead>
        <tbody>{[expectedRows, unexpectedRows]}</tbody>
      </table>
    </div>
  );
}
