import * as React from 'react';
import {FC, useEffect, useRef, useState} from 'react';
import {ImportCsvDropdownOption} from './shared/ItemMasterInterfaces';

interface Props {
  options: ImportCsvDropdownOption[];
  selected: ImportCsvDropdownOption;
  titleText: string;
  onSelect(option: ImportCsvDropdownOption);
}

const ImportCsvDropdown: FC<Props> = (props) => {
  const dropDown = useRef<HTMLElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    window.document.body.addEventListener('click', hideDropDown);

    return () => {
      window.document.body.removeEventListener('click', hideDropDown);
    };
  }, []);

  const setDropDownRef = (element) => {
    dropDown.current = element;
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const hideDropDown = (event) => {
    if (event.target !== dropDown.current) {
      setIsOpen(false);
    }
  };

  const handleSelect = (option) => {
    props.onSelect(option);
  };

  return (
    <div className={`btn-group ${isOpen ? 'open' : ''}`}>
      <a
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-haspopup="true"
        className="btn dropdown-toggle"
        type="button"
        ref={setDropDownRef}
        onClick={toggleOpen}
      >
        {props.titleText}
        <span className="caret"></span>
      </a>
      <ul className="dropdown-menu">
        {props.options.map((option) => {
          return (
            // This next line looks like the linter might have a point, and should probably be corrected.
            // TODO FE-576
            // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
            <li key={`${option.name}_${option.value}`}>
              <a onClick={() => handleSelect(option)}>{option.name}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ImportCsvDropdown;
