import * as React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Nav} from './LayoutInterfaces';
import Navigation from './Navigation';
// IF YOU ADD LODASH HERE, CHECK THE ITEM MASTER PAGE (IT MAY BREAK DUE TO _.sortByNat)

interface LayoutProps {
  authenticityToken?: string;
  homeUrl: string;
  navigation?: Nav[];
  sessionId?: string;
  userNavigation?: Nav[];
}

interface LayoutState {
  authenticityToken: string;
  homeUrl: string;
  navigation: Nav[];
  sessionId: string;
  userNavigation: Nav[];
}

class Layout extends React.Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props);
    this.state = {
      authenticityToken: this.props.authenticityToken || '',
      homeUrl: this.props.homeUrl,
      navigation: this.props.navigation || [],
      sessionId: this.props.sessionId || '',
      userNavigation: this.props.userNavigation || []
    };
  }

  public render() {
    return (
      <Router>
        <Route path="/:application" render={(routeProps) => <Navigation {...routeProps} {...this.state} />} />
      </Router>
    );
  }
}

export default Layout;
