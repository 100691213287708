import axios from 'axios';
// If you get undefined when invoking objectToFormData in jest environment,
// try `require('object-to-formdata')`
import objectToFormData from 'object-to-formdata';
import {v4} from 'uuid';
import {ApiRequest, ApiResponse} from '../CommonInterfaces';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
abstract class InternalAPIService {
  protected authenticityToken: string;
  protected baseUrl: string;

  protected constructor(authenticityToken: string, baseUrl: string) {
    this.authenticityToken = authenticityToken;
    this.baseUrl = baseUrl;
  }

  protected handleErrorResponse(error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data;
    }
    // Otherwise
    return null;
  }

  protected async makePostRequest(url: string, data, contentType: string = 'json', fileName?: string) {
    let requestParameters: ApiRequest<any> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data
    };
    if (contentType === 'multipart') {
      requestParameters = objectToFormData(requestParameters);
    }
    if (contentType === 'text/csv') {
      try {
        const response = await axios.post(url, requestParameters, {responseType: 'blob'});
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      } catch (error) {
        return this.handleErrorResponse(error);
      }

      return;
    }
    try {
      const response = await axios.post(url, requestParameters);
      return response.data as ApiResponse<any>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  protected async makeBasicPostRequest(url: string, data) {
    try {
      const response = await axios.post(url, data);
      return response.data as ApiResponse<any>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  protected async makePutRequest(url: string, data) {
    const requestParameters: ApiRequest<any> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data
    };
    try {
      const response = await axios.put(url, requestParameters);
      return response.data as ApiResponse<any>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  protected async makeGetRequest(url: string, params = null) {
    try {
      let response;
      if (params) {
        response = await axios.get(url, {
          params
        });
      } else {
        response = await axios.get(url);
      }
      return response.data as ApiResponse<any>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  protected async makePatchRequest(url: string, data) {
    const requestParameters: ApiRequest<any> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data
    };
    try {
      const response = await axios.patch(url, requestParameters);
      return response.data as ApiResponse<any>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  protected async makeDeleteRequest(url: string, data = {}) {
    const requestParameters: ApiRequest<any> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data
    };
    try {
      const response = await axios.delete(url, {data: requestParameters});

      // Replace no-content (empty response) with an empty response object
      if (response.status === 204) {
        return {
          meta: {},
          links: {},
          data: {},
          errors: []
        } as ApiResponse<any>;
      } else {
        return response.data as ApiResponse<any>;
      }
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }
}

export default InternalAPIService;
