import * as React from 'react';
import {cloneDeep} from 'lodash';
import {Document, DocumentNotableType, Reservation} from '../../../../shared/CommonInterfaces';
import {ContainerDeliveryState, LpnContent, PackingListItem} from '../../../shared/DropoffInterfaces';
import DocumentUploadModal from '../../../../shared/DocumentUploadModal';
import {LpnStatus} from '../../../../lpns/LpnsInterfaces';
import ConfirmExpectedLpnContentsStep from './ConfirmExpectedLpnContentsStep';
import ConfirmLpnContentsStep from './ConfirmLpnContentsStep';

interface LpnDropoffCompletionProps {
  lpnStateData: Map<string, LpnStatus>;
  lpnReceiveOnly: boolean;
  hasBol: boolean;
  lpnContents: LpnContent[];
  packingLists: {
    expected: PackingListItem[];
    shippable: PackingListItem[];
    damaged: PackingListItem[];
  };
  reservation: Reservation;
  receivingListPath: string;
  containerDeliveryId: number;
  documents: Document[];
  inProgressDropoffIds: number[];
  onDocumentSaved: (Document) => void;
  showEditLpnForAsn?: boolean;
  containerDeliveryState?: ContainerDeliveryState;
  recalculatePackingListsBasedOnLpns(lpnContents: LpnContent[]);
}

interface LpnDropoffCompletionState {
  showExpandos: boolean[];
  showUploadModal: boolean;
}

class LpnDropoffCompletionSteps extends React.Component<LpnDropoffCompletionProps, LpnDropoffCompletionState> {
  constructor(props) {
    super(props);
    this.state = {
      showExpandos: [true, true],
      showUploadModal: false
    };
  }

  public render() {
    return (
      <div id="lpn-dropoff-completion-component">
        <a
          className="btn cta space-below"
          href={this.props.receivingListPath}
          target="_blank"
          id="btn-print-receiving-list"
        >
          <i className="fa fa-print" aria-hidden="true"></i> Print Receiving List
        </a>
        {this.getPackingListStep().content}
        <DocumentUploadModal
          documents={this.props.documents}
          documentsRoute="/api/v2/documents"
          notableType={DocumentNotableType.container}
          notableId={this.props.containerDeliveryId}
          showUploadModal={this.state.showUploadModal}
          toggleUploadModal={this.toggleUploadModal}
          onDocumentSaved={this.props.onDocumentSaved}
        />
      </div>
    );
  }

  private toggleUploadModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showUploadModal: !prevState.showUploadModal
      };
    });
  };

  private getPackingListStep() {
    if (this.props.packingLists.expected.length > 0 && this.props.packingLists.expected[0].lpn) {
      return ConfirmExpectedLpnContentsStep({
        packingLists: this.props.packingLists,
        reservation: this.props.reservation,
        lpnContents: this.props.lpnContents,
        lpnReceiveOnly: this.props.lpnReceiveOnly,
        containerDeliveryId: this.props.containerDeliveryId,
        recalculatePackingListsBasedOnLpns: this.props.recalculatePackingListsBasedOnLpns,
        lpnStateData: this.props.lpnStateData,
        inProgressDropoffIds: this.props.inProgressDropoffIds,
        showEditLpnForAsn: this.props.showEditLpnForAsn,
        containerDeliveryState: this.props.containerDeliveryState
      });
    } else {
      return ConfirmLpnContentsStep({
        lpnStateData: this.props.lpnStateData,
        lpnReceiveOnly: this.props.lpnReceiveOnly,
        lpnContents: this.props.lpnContents,
        packingLists: this.props.packingLists,
        reservation: this.props.reservation,
        containerDeliveryId: this.props.containerDeliveryId,
        recalculatePackingListsBasedOnLpns: this.props.recalculatePackingListsBasedOnLpns,
        inProgressDropoffIds: this.props.inProgressDropoffIds,
        containerDeliveryState: this.props.containerDeliveryState
      });
    }
  }
}

export default LpnDropoffCompletionSteps;
