import * as React from 'react';
import BatchWavingService from '../../../shared/services/BatchWavingService';
import {WaveBatch} from '../WaveBatchInterfaces';
import {Wave} from '../WaveInterfaces';
import {Shipment} from '../../outbound-shipments/ShipmentInterfaces';

export interface TWaveDetailsContext {
  authenticityToken: string;
  batchWavingService: BatchWavingService;
  wave: Wave;
  batches: WaveBatch[];
  shipments: Shipment[];
}

export const WaveDetailsContext = React.createContext<TWaveDetailsContext>({
  authenticityToken: '',
  batchWavingService: null,
  wave: null,
  batches: [],
  shipments: []
});
