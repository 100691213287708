import * as React from 'react';
import {cloneDeep, uniqWith} from 'lodash';
import DatePicker from 'react-datepicker';
import Expando from '../../shared/Expando';
import DropDown, {DropDownOption, DropDownStyle, DropDownType} from '../../shared/DropDown';
import {Company, Document, DocumentNotableType, Packaging, Reservation} from '../../shared/CommonInterfaces';
// eslint-disable-next-line max-len
import EnterSkuProperties, {InventoryPropertyMap} from '../shared/completion-workflow/EnterSkuProperties';
import EnterSkuQuantities from '../shared/completion-workflow/EnterSkuQuantities';
import PrintContentLabels from '../shared/completion-workflow/PrintContentLabels';
import InventoryService from '../../shared/services/InventoryService';
import ActualInboundPackaging from '../shared/completion-workflow/ActualInboundPackaging';
import DocumentUploadModal from '../../shared/DocumentUploadModal';
import {Inventory, InventoryProperties, PackingListItem, ReceivedType} from './../shared/DropoffInterfaces';

export interface FulfillmentInboundStepsProps {
  containerDeliveryId: number;
  hasBol: boolean;
  printLabels: boolean;
  additionalPackingLists: PackingListItem[];
  expectedPackingLists: PackingListItem[];
  putAwayLocation: DropDownOption;
  putAwayLocations: DropDownOption[];
  receivingListPath: string;
  shipperCompany: Company;
  reservation: Reservation;
  actualArrivalTime: Date;
  maxArrivalDate?: Date;
  inventoryService: InventoryService;
  documents: Document[];
  notableId: number;
  enableInboundPackaging: boolean;
  showUploadDocumentModal: boolean;
  updatedProperties: {
    [inventoryId: number]: InventoryProperties;
  };
  palletLabelQuantities: {
    [inventoryId: string]: {
      shippable: number;
      damaged: number;
    };
  };
  handleSavedDoc(savedDoc: Document);
  onAddAdditionalSku(selectedInventory: Inventory, selectedPackaging: string);
  onPutawayLocationSelect(putAwayLocation: DropDownOption);
  onReceivedCountChange(id: number, qty: number, unit: Packaging, type: ReceivedType, additional: boolean);
  onActualInboundPackagingChange(actualPackagingType: string);
  onRemoveAdditionalSku(inventoryId: number, packaging: Packaging);
  onUpdateActualArrivalTime(actualArrivalTime);
  toggleUploadDocumentModal();
  handlePalletLabelCountChange(event);
  handleUpdatedProperties(properties: InventoryPropertyMap);
}

interface State {
  showExpandos: boolean[];
}

interface WorkflowStep {
  title: string;
  content: JSX.Element;
}

class FulfillmentInboundSteps extends React.Component<FulfillmentInboundStepsProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      showExpandos: [true, true, true, true, true]
    };
  }

  public render() {
    const {
      containerDeliveryId,
      additionalPackingLists,
      expectedPackingLists,
      onAddAdditionalSku,
      onPutawayLocationSelect,
      onReceivedCountChange,
      onRemoveAdditionalSku,
      onActualInboundPackagingChange,
      putAwayLocation,
      putAwayLocations,
      receivingListPath,
      shipperCompany,
      reservation,
      enableInboundPackaging
    } = this.props;

    const uniqByInventoryId = (a, b) => a.id === b.id;
    const allPackingLists = additionalPackingLists.concat(expectedPackingLists);
    const inventoryInContainer = uniqWith(
      allPackingLists.filter((pl) => pl.shippableAmount || pl.damagedAmount).map((pl) => pl.inventory),
      uniqByInventoryId
    );
    const allInventories = uniqWith(
      allPackingLists.map((pl) => pl.inventory),
      uniqByInventoryId
    );
    const newInventories = allInventories.filter((inv) => !inv.isInUse);
    const shippableNewInventories = inventoryInContainer.map((inv) => {
      if (!inv.isInUse) {
        return inv.id;
      }
    });

    const missingInventoryStep = {
      title: 'Enter missing SKU properties',
      content: (
        <EnterSkuProperties
          inventoryService={this.props.inventoryService}
          newInventories={newInventories}
          shippableNewInventories={shippableNewInventories}
          reservationId={reservation.id}
          handleUpdatedProperties={this.props.handleUpdatedProperties}
        />
      )
    };

    const iptStep = {
      title: 'Select Inbound Type',
      content: <ActualInboundPackaging onActualInboundPackagingChange={onActualInboundPackagingChange} />
    };

    const osdStep = {
      title: 'Enter SKU Quantities',
      content: (
        <EnterSkuQuantities
          additionalPackingLists={additionalPackingLists}
          expectedPackingLists={expectedPackingLists}
          receivingListPath={receivingListPath}
          reservationId={reservation.id}
          onAddAdditionalSku={onAddAdditionalSku}
          onReceivedCountChange={onReceivedCountChange}
          onRemoveAdditionalSku={onRemoveAdditionalSku}
        />
      )
    };

    const selectLocationStep = {
      title: 'Choose Staging Location',
      content: (
        <DropDown
          options={putAwayLocations}
          selected={putAwayLocation}
          style={DropDownStyle.ghost}
          type={DropDownType.secondary}
          onSelect={onPutawayLocationSelect}
        />
      )
    };

    const printContentLabelsStep = {
      title: 'Print Pallet Content Labels',
      content: (
        <PrintContentLabels
          allInventories={allInventories}
          allPackingLists={allPackingLists}
          containerDeliveryId={containerDeliveryId}
          palletLabelQuantities={this.props.palletLabelQuantities}
          handlePalletLabelCountChange={this.props.handlePalletLabelCountChange}
          updatedProperties={this.props.updatedProperties}
        />
      )
    };

    const addActualArrivalTimeStep = {
      title: 'Enter Inbound Container Arrival Time',
      content: (
        <span>
          <DatePicker
            selected={this.props.actualArrivalTime}
            onChange={this.props.onUpdateActualArrivalTime}
            maxDate={this.props.maxArrivalDate ? this.props.maxArrivalDate : new Date()}
            minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
            timeIntervals={15}
            showTimeSelect
            dateFormat="MMM dd - h:mm aa"
            placeholderText="Date/Time"
          />{' '}
          <b>*</b>{' '}
        </span>
      )
    };

    // block completion if mobile enabled reservation without inbound locations
    if (reservation.usesMobile && !(putAwayLocations && putAwayLocations.length > 0)) {
      return (
        <div className="alert alert-danger">
          <span>
            Locations have not been configured for inbounding.
            <br />
            Please <a href="/wh/locations">review locations</a> and ensure inbound locations have been categorized as
            "Inbound Staging".
          </span>
        </div>
      );
    } else {
      // enqueue workflow steps as needed...
      const steps: WorkflowStep[] = [osdStep];
      if (newInventories.length > 0 && shippableNewInventories.length > 0 && shipperCompany.enableTihiDuringInbound) {
        steps.push(missingInventoryStep);
      }
      if (putAwayLocations && putAwayLocations.length > 0) {
        steps.push(selectLocationStep);
      }
      if (this.props.printLabels) {
        steps.push(printContentLabelsStep);
      }
      steps.push(addActualArrivalTimeStep);
      if (enableInboundPackaging) {
        steps.push(iptStep);
      }

      return steps.map((step, idx) => {
        return (
          <Expando
            key={idx}
            index={idx + 1}
            headerIcon={<span className="workflow-step-index">{idx + 1}</span>}
            headerText={step.title}
            open={this.state.showExpandos[idx]}
            onClick={this.toggleExpando}
          >
            {step.content}
          </Expando>
        );
      });
    }
  }

  private toggleExpando = (event) => {
    // reading data-index off expando .header element
    const index = parseInt(event.currentTarget.getAttribute('data-index'), 10) - 1;
    const showExpandos = cloneDeep(this.state.showExpandos);
    showExpandos[index] = !showExpandos[index];
    this.setState({showExpandos});
  };
}

export default FulfillmentInboundSteps;
