import * as React from 'react';
import {Column, Heading} from '@flexe/ui-components';
import {Reservation as ReservationInfo, Role, User, UserWithRoles} from '../../userManagementInterfaces';
import Reservation from './Reservation';

interface Props {
  activeUsers: User[];
  adminUserNames: string;
  allReservationRoles: Role[];
  authenticityToken: string;
  onRoleToggleAsync: (user: UserWithRoles, reservationId: number, role: string, enableRole: boolean) => Promise<void>;
  reservations: ReservationInfo[];
}

const ReservationUsers = ({
  activeUsers,
  adminUserNames,
  allReservationRoles,
  authenticityToken,
  onRoleToggleAsync,
  reservations
}: Props) => {
  return (
    <Column tag="section">
      <Heading level="h2">Reservation Users</Heading>
      {reservations.map((reservation) => (
        <Reservation
          key={reservation.id}
          activeUsers={activeUsers}
          adminUserNames={adminUserNames}
          authenticityToken={authenticityToken}
          onRoleToggleAsync={onRoleToggleAsync}
          reservation={reservation}
          roles={allReservationRoles}
        />
      ))}
    </Column>
  );
};

export default ReservationUsers;
