import {Loader} from '@flexe/ui-components';
import * as React from 'react';
import {useEffect, useMemo, useRef, useState} from 'react';
import BatchWavingService from '../../../shared/services/BatchWavingService';
import {PickWaveStatus, WaveBatch} from '../WaveBatchInterfaces';
import {Wave} from '../WaveInterfaces';
import OutboundShipmentService from '../../../shared/services/OutboundShipmentService';
import {Shipment} from '../../outbound-shipments/ShipmentInterfaces';
import CustomKebabMenu, {KebabMenuOption} from '../../../shared/CustomKebabMenu';
import {WaveDetailsContext} from './WaveDetailsContext';
import WavesDetailsHeader from './WaveDetailsHeader';
import WaveProgress from './WaveProgress';
import WaveDocuments from './WaveDocuments';
import WaveContents from './WaveContents';
import CancelWaveModal from './CancelWaveModal';

interface Props {
  authenticityToken: string;
  waveId: number;
  waveDocRegenEnabled?: boolean;
}

const WavesDetails: React.FC<Props> = ({authenticityToken, waveId, waveDocRegenEnabled}) => {
  const batchWavingService = new BatchWavingService(authenticityToken);
  const outboundShipmentService = new OutboundShipmentService(authenticityToken);
  const [errors, setErrors] = useState<string[]>([]);
  const [batches, setBatches] = useState<WaveBatch[]>([]);
  const [wave, setWave] = useState<Wave>(null);
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCancellable, setIsCancellable] = useState<boolean>(false);
  const resultRef = useRef(null);
  const [showTerminateWaveActionModal, setShowTerminateWaveActionModal] = useState<boolean>(false);
  const [displayPutWallField, setDisplayPutWallField] = useState(false);
  const [displayOrderByField, setDisplayOrderByField] = useState(false);

  useEffect(() => {
    asyncGetWaveById();
  }, []);

  useEffect(() => {
    asyncGetWaveBatches();
    asyncGetShipments();
    setIsCancellable(isWaveCancellable());
  }, [wave]);

  const setErrorsAndPageState = (errorsArr: string[]) => {
    const nullWave: Wave = {
      id: waveId,
      reservation: null,
      barcode: null,
      stagingLocation: null,
      status: null,
      createdAt: null,
      waveRequest: null,
      sortMhe: null,
      documents: [],
      shipmentIds: [],
      waveAttributes: {
        fullPalletPull: true,
        sortationEnabled: true,
        pickMethod: 'cluster-pick',
        isOversized: false,
        overbox: true,
        packTimeLabelGeneration: true,
        pickToCarton: true,
        printPackingSlipsSeparately: false,
        shipAsIsRequested: true,
        shouldRebin: true
      }
    };
    setWave(nullWave);
    setIsLoading(false);
    setErrors(errorsArr);
  };

  const asyncGetWaveById = async () => {
    try {
      const response = await batchWavingService.getWaveById(waveId);

      if (response.data) {
        if (response.data.wave) {
          setWave(response.data.wave);
          setIsCancellable(isWaveCancellable());
        }

        setDisplayPutWallField(response.data.displayPutWallField);
        setDisplayOrderByField(response.data.displayOrderByField);
      }

      if (response.errors?.length > 0) {
        const getWaveErrors = response.errors.map((e) =>
          e.status.toString() === '404' ? `Wave not found with ID ${waveId}` : `${e.status} - ${e.detail}`
        );

        setErrorsAndPageState([...errors, ...getWaveErrors]);
      }
    } catch (error) {
      const getWaveErrors = [].concat(errors);
      getWaveErrors.push(error.toString());
      setErrorsAndPageState(getWaveErrors);
    }
  };

  const asyncTerminateWaveById = async () => {
    if (!wave) {
      setErrorsAndPageState(['Wave is not loaded.']);
      return;
    }
    if (!isCancellable) {
      setErrorsAndPageState(['Wave is not in a valid status to be cancelled.']);
      return;
    }
    try {
      setIsLoading(true);
      const response = await batchWavingService.terminateWaveById(waveId, {status: PickWaveStatus.cancelled});

      if (response.data && response.data.wave) {
        wave.status = response.data.wave.status;
        setWave(wave);
        setIsCancellable(isWaveCancellable());
      }

      if (response.errors?.length > 0) {
        const responseErrors = response.errors
          .filter((e) => e.detail !== `Wave ${wave.id} already terminated.`)
          .map((e) => e.detail);

        await asyncGetWaveById();
        setErrors((existingErrors) => [...existingErrors, ...responseErrors]);
      }
    } catch (error) {
      const responseErrors = [].concat(errors || []);
      responseErrors.push(error.toString());
      setErrorsAndPageState(responseErrors);
    }

    setIsLoading(false);
  };
  const NON_TERMINAL_WAVE_STATUSES = [PickWaveStatus.new, PickWaveStatus.in_progress];
  /**
   * returns TRUE if wave is present and not in a terminal status, then it is considered cancellable.
   */
  function isWaveCancellable() {
    return wave && NON_TERMINAL_WAVE_STATUSES.includes(wave.status as PickWaveStatus);
  }

  const asyncGetWaveBatches = async () => {
    if (!wave) {
      return;
    }
    const response = await batchWavingService.getWaveBatches(wave.id);
    if (response.data && response.data.batches) {
      setBatches(response.data.batches);
      setIsLoading(false);
    }
    if (response.errors && response.errors.length > 0) {
      const getBatchErrors = [].concat(errors || []);
      response.errors.map((e) => {
        getBatchErrors.push(`Could not load batches for Wave with ID ${wave.id}`);
      });
      setErrors(getBatchErrors);
      setIsLoading(false);
    }
  };

  const asyncGetShipments = async () => {
    if (!wave) {
      return;
    }

    const promiseList = wave.shipmentIds.map((id) => {
      return async () => outboundShipmentService.shipmentDetailsDeprecated(id);
    });

    const loadedShipments = [];
    await Promise.all(
      promiseList.map(async (promise) => {
        const response = await promise();

        if (response.data && response.data.shipment) {
          loadedShipments.push(response.data.shipment);
        }

        if (response.errors?.length > 0) {
          setShipments([]);
          setErrors((existingErrors) => [...existingErrors, `Could not load shipments for Wave with ID ${wave.id}`]);
        }
      })
    );

    setShipments(loadedShipments);
  };

  const contextValue = useMemo(
    () => ({
      authenticityToken,
      batchWavingService,
      wave,
      batches,
      shipments
    }),
    [wave, batches, shipments]
  );

  const renderNavigation = (
    <div className="breadcrumbs delivery" ref={resultRef}>
      <a href="/wh/waves">Waves & Batches</a>
      <span className="navigation-separator">/</span>
      Wave #{waveId}
    </div>
  );

  const isFreight = shipments.every((shipment) => shipment.transportation.ship_mode === 'freight');

  const toggleConfirmTerminateWaveActionModal = () => {
    setShowTerminateWaveActionModal(!showTerminateWaveActionModal);
  };

  const handleTerminateWave = () => {
    asyncTerminateWaveById().then(() => toggleConfirmTerminateWaveActionModal());
  };

  const handleWaveDocumentRegeneration = async () => {
    const response = await batchWavingService.regenWaveDocsById(waveId);
    if (response.errors.length > 0) {
      setErrors(response.errors.map((e) => e.detail));
    }
  };

  function kebabOptions(): KebabMenuOption[] {
    return [
      {
        optionText: 'Cancel Wave',
        isActionDangerous: true,
        optionAction: toggleConfirmTerminateWaveActionModal
      }
    ];
  }

  return (
    <WaveDetailsContext.Provider value={contextValue}>
      <div className="details-page">
        {errors &&
          errors.length > 0 &&
          errors.map((error, idx) => {
            return (
              <div className="alert alert-danger" role="alert" key={`error:${idx}`}>
                {error}
              </div>
            );
          })}
        <div className="details-background">
          <div className="details-container container-fluid">
            <div className="details">
              <div className="header-row">
                {renderNavigation}
                {isCancellable && (
                  <div className="kebab-right-offset">
                    <CustomKebabMenu options={kebabOptions()} />
                    <CancelWaveModal
                      toggleModal={toggleConfirmTerminateWaveActionModal}
                      show={showTerminateWaveActionModal}
                      wave={wave}
                      onClickConfirm={handleTerminateWave}
                      isFreight={isFreight}
                      disableCancelReason={isFreight && wave.hasLpnsAssociated ? 'lpns_associated' : undefined}
                    />
                  </div>
                )}
              </div>
              {isLoading && <Loader loading={isLoading} />}
              {!isLoading && (
                <WavesDetailsHeader
                  displayPutWallField={displayPutWallField}
                  displayOrderByField={displayOrderByField}
                />
              )}
            </div>
          </div>
        </div>
        <div className="box-container">
          {!isLoading && (
            <WaveDocuments waveDocRegenEnabled={waveDocRegenEnabled} onRegenClick={handleWaveDocumentRegeneration} />
          )}
          {!isLoading && <WaveProgress />}
          {!isLoading && <WaveContents />}
        </div>
      </div>
    </WaveDetailsContext.Provider>
  );
};

export default WavesDetails;
