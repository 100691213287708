import * as React from 'react';
import {FC} from 'react';

interface Props {
  showOmniOrderCreateBtn: boolean;
}

const CreateSingleOrderButton: FC<Props> = (props) => {
  return (
    props.showOmniOrderCreateBtn && (
      <a href="/s/fulfillment/omnichannel/orders/new" className="btn pull-right" id="new-manual-request">
        <i className="fa fa-plus" />
        &nbsp; Create New Order
      </a>
    )
  );
};
export default CreateSingleOrderButton;
