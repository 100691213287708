import {
  SsccLabelRequirementsResponse,
  SsccLabelsByShipmentIdResponse,
  SsccLabelsResponse
} from '../../documents/SsccInterfaces';
import {ApiResponse} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

class SsccService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2');
  }

  /**
   * Fetch a set of sscc labels (AKA pallet or carton labels) from the server.
   * @param shipmentId Required, shipmentId of the shipment associated to the labels
   * @returns A list of SSCC labels that match the filters
   */
  public async getSsccLabels(shipmentId: number): Promise<ApiResponse<SsccLabelsResponse>> {
    if (!shipmentId) {
      // hopefully we will be able to make this error handling
      // more graceful as we factor out the Documents section
      return {
        data: {labels: []},
        meta: {
          responseCreatedAt: '',
          correlationId: ''
        },
        links: {
          self: ''
        },
        errors: []
      };
    }
    const data = {
      shipment_id: shipmentId
    };
    const response = await this.makeGetRequest(`${this.baseUrl}/sscc`, data);
    return response;
  }

  /**
   * Trigger the generation or REgeneration of SSCC labels
   * @param shipmentId Required, shipmentId of the shipment associated to the labels
   * @param ssccType Required, the type of SSCC labels to generate (carton OR pallet)
   * @param regenerate Required, dictates if labels are generated or REgenerated
   * @returns no data; may return errors
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public async generateSsccLabels(shipmentId: number, ssccType: string, regenerate: boolean): Promise<ApiResponse<{}>> {
    const data = {
      shipment_id: shipmentId,
      sscc_type: ssccType,
      regenerate
    };
    const response = await this.makePostRequest(`${this.baseUrl}/sscc/labels`, data);
    return response;
  }

  /**
   * Fetch information about the SSCC label requirements for a set of shipmentIds
   * @param shipmentIds Required, array of shipmentIds to check for SSCC label info
   * @param reservationId Required, reservation that the shipments are associated with
   * @returns A list of SSCC label requirements
   */
  public async getSsccLabelRequirementsInfo(
    shipmentIds: number[],
    reservationId: number
  ): Promise<ApiResponse<SsccLabelRequirementsResponse>> {
    if (!shipmentIds?.length) {
      // hopefully we will be able to make this error handling
      // more graceful as we factor out the Documents section
      return {
        data: {
          requirementsInfo: {
            cartonLabelsRequiredForAnyShipment: false,
            palletLabelsRequiredForAnyShipment: false,
            cartonLabelRequirementsHaveBeenMetForAllShipments: true,
            palletLabelRequirementsHaveBeenMetForAllShipments: true
          }
        },
        meta: {
          responseCreatedAt: '',
          correlationId: ''
        },
        links: {
          self: ''
        },
        errors: []
      };
    }
    const data = {
      shipment_ids: shipmentIds,
      reservation_id: reservationId
    };
    const response = await this.makeGetRequest(`${this.baseUrl}/sscc/label_requirements`, data);
    return response;
  }

  /**
   * Fetch SSCC label and content information for a shipment
   * @param shipmentId Required, shipment id to check for SSCC label info
   * @returns A list of SSCC labels
   */
  public async getSsccLabelsByShipmentId(shipmentId: number): Promise<ApiResponse<SsccLabelsByShipmentIdResponse>> {
    return await this.makeGetRequest(`${this.baseUrl}/sscc/ssccs_for_shipment/${shipmentId}`);
  }

  /**
   * Fetch a combined PDF of carton SSCC labels for a given shipment and pallet LPN id
   * @param lpnId Required, shipment id of the lpn
   * @param shipmentId Required, lpn Id of the parent LPN to get all carton labels for
   * @returns A combined PDF of the carton labels for all the children of the given LPN id
   */
  public getCartonSsccLabelsForParentLpn(lpnId: number, shipmentId: number): void {
    window
      .open(`${this.baseUrl}/sscc/carton_sscc_labels_for_lpn?lpn_id=${lpnId}&shipment_id=${shipmentId}`, '_blank')
      .focus();
  }
}

export default SsccService;
