import * as React from 'react';
import {TransitionState} from '../../shared/CommonInterfaces';

interface BatchProgressBarProps {
  status: TransitionState;
  totalShipments?: number;
  completedShipments?: number;
}

const BatchProgressBar: React.FC<BatchProgressBarProps> = (props) => {
  const {status, totalShipments, completedShipments} = props;
  const progressBar = () => {
    switch (status) {
      case TransitionState.Completed:
      case TransitionState.InProgress:
        // eslint-disable-next-line no-case-declarations
        const completedPercentage = Math.floor(
          ((completedShipments ? completedShipments : 0) * 100) / (totalShipments ? totalShipments : 1)
        );
        return (
          <div className="progress">
            <div className="progress-bar progress-bar-completed" style={{width: `${completedPercentage}%`}}></div>
            <div
              className="progress-bar progress-bar-in-progress"
              style={{width: `${100 - completedPercentage}%`}}
            ></div>
            <span className="progress-bar-text">{`${completedPercentage}% Packed`}</span>
          </div>
        );
      case TransitionState.NotStarted:
        return (
          <div className="progress">
            <div className="progress-bar progress-bar-not-started" style={{width: '100%'}}></div>
            <span className="progress-bar-text">{'0% Packed'}</span>
          </div>
        );
      case TransitionState.Cancelled:
        return <div className="progress"></div>;
    }
  };

  return progressBar();
};
export default BatchProgressBar;
