import {createTheme} from '@mui/material/styles';

export const flexeMuiTheme = createTheme({
  typography: {
    fontFamily: 'Inter UI',
    // Tell Material UI what the default font size on our site is.
    // https://mui.com/material-ui/customization/typography/#html-font-size
    htmlFontSize: 10
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({theme}) => ({
          // These are required to override the following styles:
          // https://gitlab.com/flexe/warehouser/-/blob/f019ca1e808766b1ec858feab719abca29114416/app/assets/stylesheets/buttons.scss#L7-16
          '&:hover': {
            color: `${theme.palette.primary.main} !important`
          },
          '&:active, &:focus, &.selected': {
            color: `${theme.palette.primary.main} !important`,
            background: 'transparent'
          },
          // This is required to override the following style:
          // https://gitlab.com/flexe/warehouser/-/blob/f019ca1e808766b1ec858feab719abca29114416/app/assets/stylesheets/buttons.scss#L173
          ':last-child': {
            marginRight: 'initial'
          }
        })
      }
    },
    MuiInput: {
      styleOverrides: {
        // This is used to override the following styles:
        // https://gitlab.com/flexe/warehouser/-/blob/f019ca1e808766b1ec858feab719abca29114416/app/assets/stylesheets/internal.scss#L294
        input: {
          // &.MuiInput-input is used as a specificity booster
          '&.MuiInput-input': {
            'border': 0,
            'padding': '4px 0 5px',
            'height': 'initial',
            'borderRadius': 0,
            'boxShadow': 'none',
            '&:hover  &:active, &:focus, &.selected': {
              boxShadow: 'none'
            }
          }
        }
      }
    }
  }
});
