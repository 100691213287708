import axios from 'axios';
import * as moment from 'moment';
import {isEmpty} from 'lodash';
import {v4} from 'uuid';
import * as BatchWaving from '../../warehouse/ecommerce-batches/BatchInterfaces';
import {ApiRequest, ApiResponse, Warehouse} from '../CommonInterfaces';
import {UnresolvedWavingAlertsResponse} from '../../warehouse/ecommerce-batches/BatchInterfaces';
import {
  GetWaveResponse,
  GetWavesResponse,
  GetWavingSummaryRequest,
  GetWavingSummaryResponse,
  TerminateWaveRequest
} from '../../warehouse/batch-waving/WaveInterfaces';
import {
  FilterKeys,
  FilterParams,
  GetBatchCountsResponse,
  GetBatchesResponse,
  GetBatchResponse,
  PickWaveStatus
} from '../../warehouse/batch-waving/WaveBatchInterfaces';
import InternalAPIService from './InternalAPIService';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
class BatchWavingService extends InternalAPIService {
  public currentWarehouse?: number;
  public selectedWarehouse?: Warehouse;
  private ecommUrl: string = '/wh/fulfillment/ecommerce';
  private batchScheduleUrl: string = '/wh/fulfillment/batch_schedules';
  private shipmentAttributeUrl: string = '/wh/wave_template/get_all_shipment_attributes';

  constructor(authenticityToken: string) {
    super(authenticityToken, '/wh/wave');
  }

  public async getBatchSchedule(reservationId: number): Promise<ApiResponse<BatchWaving.BatchScheduleResponse>> {
    return await this.makeGetRequest(`${this.batchScheduleUrl}/${reservationId}`, {
      authenticity_token: this.authenticityToken
    });
  }

  public async createBatchSchedule(
    type: string,
    reservation,
    daysOfWeek: string[],
    hourOfDay: number,
    minuteOfHour: number
  ): Promise<ApiResponse<BatchWaving.CreateBatchScheduleResponse>> {
    const requestParameters: ApiRequest<BatchWaving.CreateBatchScheduleRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        type,
        reservation,
        daysOfWeek,
        hourOfDay,
        minuteOfHour
      }
    };
    try {
      const response = await axios.post(`${this.batchScheduleUrl}/${reservation}`, requestParameters);
      return response.data as ApiResponse<BatchWaving.BatchScheduleResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async deleteBatchSchedule(
    id: string,
    reservation
  ): Promise<ApiResponse<BatchWaving.DeleteBatchScheduleResponse>> {
    const requestParameters: ApiRequest<BatchWaving.DeleteBatchScheduleRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        id,
        reservation
      }
    };
    try {
      const response = await axios.delete(`${this.batchScheduleUrl}/${reservation}/${id}`, requestParameters);
      return {data: {status: response.status}} as ApiResponse<BatchWaving.DeleteBatchScheduleResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async getShipmentAttributes(
    reservationId: number
  ): Promise<ApiResponse<BatchWaving.ShipmentAttributeResponse>> {
    return await this.makeGetRequest(`${this.shipmentAttributeUrl}?reservationId=${reservationId}`, {
      authenticity_token: this.authenticityToken
    });
  }

  public async getOrders(
    filters: BatchWaving.OrderFilters,
    continuationToken?: string,
    pageSize?: number,
    sortOrder?: string
  ): Promise<ApiResponse<BatchWaving.OrdersResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BatchOrdersRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        continuationToken,
        filters,
        pageSize,
        sortOrder
      }
    };
    try {
      const response = await axios.post(`${this.ecommUrl}/orders`, requestParameters);
      return response.data as ApiResponse<BatchWaving.OrdersResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async getShipments(
    filters: BatchWaving.OrderFilters,
    continuationToken?: string,
    pageSize?: number,
    sortOrder?: string
  ): Promise<ApiResponse<BatchWaving.OrdersResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BatchOrdersRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        continuationToken,
        filters,
        pageSize,
        sortOrder
      }
    };
    try {
      const response = await axios.post(`${this.ecommUrl}/shipments`, requestParameters);
      return response.data as ApiResponse<BatchWaving.OrdersResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async getOrder(orderId: number): Promise<ApiResponse<BatchWaving.OrderDetail>> {
    try {
      const response = await axios.get(`${this.ecommUrl}/order/${orderId}/details`);
      return response.data as ApiResponse<BatchWaving.OrderDetail>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async skuTypeAhead(reservationId: number, skuQuery: string): Promise<ApiResponse<BatchWaving.SkuData>> {
    const requestParameters: ApiRequest<BatchWaving.SkuTypeAheadRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        reservationId,
        skuSubstring: skuQuery
      }
    };
    try {
      const response = await axios.post(`${this.baseUrl}/sku_type_ahead`, requestParameters);
      return response.data as ApiResponse<BatchWaving.SkuData>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async getCarrierInfo(reservationId: number): Promise<ApiResponse<BatchWaving.CarrierResponse>> {
    const requestParameters: ApiRequest<any> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        reservationId
      }
    };
    try {
      const response = await axios.post(`${this.baseUrl}/new_order_carrier_types`, requestParameters);
      return response.data as ApiResponse<BatchWaving.CarrierResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async getActiveShipOnDates(warehouseId: number): Promise<ApiResponse<BatchWaving.ActiveShipOnDatesResponse>> {
    try {
      const response = await axios.get(`${this.baseUrl}/active_ship_on_dates`, {
        params: {
          warehouseId
        }
      });
      return response.data as ApiResponse<BatchWaving.ActiveShipOnDatesResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async getCarrierReservationTotal(
    warehouseId: number,
    cutoffDate: string
  ): Promise<ApiResponse<Map<string, Map<number, number>>>> {
    try {
      const response = await axios.get(`${this.baseUrl}/carrier_reservation_total`, {
        params: {
          warehouseId,
          cutoffDate: moment(cutoffDate).format('YYYY-MM-DD')
        }
      });
      return response.data as Promise<ApiResponse<Map<string, Map<number, number>>>>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async waveByShipmentIds(
    waveParams: BatchWaving.WaveByShipmentIdsParams
  ): Promise<ApiResponse<BatchWaving.WaveByShipmentIdsResponse>> {
    const requestParameters = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        wave: waveParams
      }
    };

    try {
      const response = await axios.post(`${this.baseUrl}/batch_by_shipment_ids`, requestParameters);
      return response.data as ApiResponse<BatchWaving.WaveByShipmentIdsResponse>;
    } catch (error) {
      return error.response.status === cloudflareTimeoutResponseCode
        ? {
            meta: {
              correlationId: '',
              responseCreatedAt: ''
            },
            links: {
              self: ''
            },
            data: undefined,
            errors: [
              {
                status: cloudflareTimeoutResponseCode,
                title: 'The wave request timed out.',
                detail: `The Shipment${
                  waveParams.shipmentIds.length !== 1 ? 's are' : ' is'
                  // eslint-disable-next-line max-len
                } taking longer than expected to wave. Please reload the page to see the wave status and try again if your Shipment${
                  waveParams.shipmentIds.length !== 1 ? "s aren't" : " isn't"
                } waved.`,
                source: undefined
              }
            ]
          }
        : this.handleErrorResponse(error);
    }
  }

  public async previewWave(wave: BatchWaving.WaveParameters): Promise<ApiResponse<BatchWaving.PreviewResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BatchRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        wave
      }
    };
    try {
      const response = await axios.post(`${this.baseUrl}/preview_batches`, requestParameters);
      return response.data as ApiResponse<BatchWaving.PreviewResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async createWave(previewId: number): Promise<ApiResponse<BatchWaving.CreateResponse>> {
    const requestParameters: ApiRequest<BatchWaving.CreateRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        preview: {
          type: 'fulfillment_batch_preview',
          id: previewId
        }
      }
    };
    try {
      const response = await axios.post(`${this.baseUrl}/create_batches`, requestParameters);
      return response.data as ApiResponse<BatchWaving.CreateResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async immediateBatch(wave: BatchWaving.WaveParameters): Promise<ApiResponse<BatchWaving.CreateResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BatchRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        wave
      }
    };
    try {
      const response = await axios.post(`${this.baseUrl}/immediate_batch`, requestParameters);
      return response.data as ApiResponse<BatchWaving.CreateResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async cancelBatches(batchIds: number[]): Promise<ApiResponse<BatchWaving.CancelBatchResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BulkBatchRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        batchIds
      }
    };
    try {
      const response = await axios.post(`${this.baseUrl}/cancel_batches`, requestParameters);
      return response.data as ApiResponse<BatchWaving.CancelBatchResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async partiallyCompleteBatches(batchIds: number[]): Promise<ApiResponse<BatchWaving.PartialCompleteResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BulkBatchRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        batchIds
      }
    };
    try {
      const response = await axios.post(`${this.baseUrl}/complete_batches`, requestParameters);
      return response.data as ApiResponse<BatchWaving.PartialCompleteResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async completeBatches(batchIds: number[]): Promise<ApiResponse<BatchWaving.BulkCompleteResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BulkBatchRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        batchIds
      }
    };
    try {
      const response = await axios.post(`${this.ecommUrl}/complete_batches`, requestParameters);
      return response.data as ApiResponse<BatchWaving.BulkCompleteResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async confirmBatches(batchIds: number[]): Promise<ApiResponse<BatchWaving.BulkConfirmResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BulkBatchRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        batchIds
      }
    };
    try {
      const response = await axios.post(`${this.ecommUrl}/confirm_batches`, requestParameters);
      return response.data as ApiResponse<BatchWaving.BulkConfirmResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async getBatchesCompactInfo(
    request: BatchWaving.BatchesRequest
  ): Promise<ApiResponse<BatchWaving.CompactBatchesResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BatchesRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: request
    };
    try {
      const response = await axios.post(`${this.ecommUrl}/batches_compact_info`, requestParameters);
      return response.data as ApiResponse<BatchWaving.CompactBatchesResponse>; // uncomment for real data
      // TODO: remove the following line and above comment when development is complete.
      // return Promise.resolve(generateValidApiResponse(mockedBatches)); // uncomment for mock data
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async getBatches(request: BatchWaving.BatchesRequest): Promise<ApiResponse<BatchWaving.BatchesResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BatchesRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: request
    };
    try {
      const response = await axios.post(`${this.ecommUrl}/batches`, requestParameters);
      return response.data as ApiResponse<BatchWaving.BatchesResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async getBatch(batchId: number): Promise<ApiResponse<BatchWaving.BatchResponse>> {
    try {
      const response = await axios.get(`${this.ecommUrl}/batch/${batchId}/details`);
      return response.data as ApiResponse<BatchWaving.BatchResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async getWavingSummary(
    warehouseId: number,
    currentDate: string
  ): Promise<ApiResponse<GetWavingSummaryResponse>> {
    const query: GetWavingSummaryRequest = {
      warehouseId,
      currentDate
    };

    const mockResponse: GetWavingSummaryResponse = {
      total: -1000,
      unWaved: -700,
      notStarted: -300,
      inProgress: -500,
      packed: -800,
      complete: -2000,
      packedEnabled: true
    };
    return this.makeGetRequest(`${this.ecommUrl}/waving_summary`, query);
  }

  public async completeOrders(orderIds: number[]): Promise<ApiResponse<BatchWaving.BulkCompleteResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BulkOrderRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        orderIds
      }
    };
    try {
      const response = await axios.post(`${this.ecommUrl}/complete_orders_in_batches`, requestParameters);
      return response.data as ApiResponse<BatchWaving.BulkCompleteResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async confirmOrders(orderIds: number[]): Promise<ApiResponse<BatchWaving.BulkConfirmResponse>> {
    const requestParameters: ApiRequest<BatchWaving.BulkOrderRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        orderIds
      }
    };
    try {
      const response = await axios.post(`${this.ecommUrl}/confirm_orders_in_batches`, requestParameters);
      return response.data as ApiResponse<BatchWaving.BulkConfirmResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async generateInProgressDoc(batchId: number): Promise<ApiResponse<any>> {
    const url = `/wh/api/fulfillment/batch/${batchId}/generate_in_progress_doc`;
    return await this.makePostRequest(url, {batchId});
  }

  public async getAllCarriersAndServiceTypes(): Promise<
    ApiResponse<{
      carriers: string[];
      serviceTypes: string[];
      mappedShippingInfo: object;
    }>
  > {
    return await this.makeGetRequest(`${this.ecommUrl}/carrier_service_info`);
  }

  public async toggleDocumentAsPrinted(
    batchId: number,
    documentId: number,
    wasPrinted: boolean
  ): Promise<ApiResponse<any>> {
    const url = `/wh/api/fulfillment/batch/${batchId}/document_printed`;
    return await this.makePostRequest(url, {documentId, wasPrinted});
  }

  // Waving Alerts
  public async getUnresolvedWavingAlerts(warehouseId): Promise<ApiResponse<UnresolvedWavingAlertsResponse>> {
    const query = {
      warehouseId
    };
    return this.makeGetRequest(`${this.ecommUrl}/alerts/unresolved_alerts`, query);
  }

  public async markAlertsAsRead(alertIds: number[]): Promise<ApiResponse<any>> {
    const payload = {
      batchErrorIds: alertIds
    };
    return this.makePatchRequest(`${this.ecommUrl}/alerts/mark_alerts_as_read`, payload);
  }

  public async resolveAlerts(alertIds: number[]): Promise<ApiResponse<any>> {
    const payload = {
      ids: alertIds
    };
    return this.makePatchRequest(`${this.ecommUrl}/alerts/resolve`, payload);
  }

  public async getWaves(
    reservationIds,
    filterParams: FilterParams,
    continuationToken,
    pageSize
  ): Promise<ApiResponse<GetWavesResponse>> {
    let searchString = '';

    searchString = this.appendParameter(searchString, 'reservationIds', reservationIds || []);
    if (this.currentWarehouse) {
      const prepend = isEmpty(searchString) ? '?' : '&';
      searchString = `${searchString}${prepend}warehouseId=${this.currentWarehouse}`;
    }
    if (filterParams[FilterKeys.WaveId]) {
      searchString = this.appendParameter(searchString, 'waveIds', [filterParams[FilterKeys.WaveId]]);
    }
    if (filterParams[FilterKeys.ShipmentId]) {
      searchString = this.appendParameter(searchString, 'shipmentIds', [filterParams[FilterKeys.ShipmentId]]);
    }
    if (filterParams[FilterKeys.BatchId]) {
      searchString += `&batchId=${filterParams[FilterKeys.BatchId]}`;
    }
    if (filterParams[FilterKeys.Carrier]) {
      searchString += `&carrier=${filterParams[FilterKeys.Carrier]}`;
    }
    if (filterParams[FilterKeys.ServiceType]) {
      searchString += `&serviceType=${filterParams[FilterKeys.ServiceType]}`;
    }
    if (filterParams[FilterKeys.Status] === 'active') {
      searchString = this.appendParameter(searchString, 'statuses', [PickWaveStatus.new, PickWaveStatus.in_progress]);
    } else if (filterParams[FilterKeys.Status]) {
      searchString = this.appendParameter(searchString, 'statuses', [filterParams[FilterKeys.Status]]);
    }
    if (filterParams[FilterKeys.StagingLocation]) {
      searchString += `&stagingLocation=${filterParams[FilterKeys.StagingLocation]}`;
    }
    if (filterParams[FilterKeys.ShipBy]) {
      // TODO FE-577: this looks like a real error that should be fixed
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      searchString += `&shipBy=${filterParams[FilterKeys.ShipBy]}`;
    }
    if (continuationToken) {
      searchString += `&continuationToken=${continuationToken}`;
    }
    if (filterParams[FilterKeys.SortOrder]) {
      searchString += `&sortOrder=${filterParams[FilterKeys.SortOrder]}`;
    }
    if (filterParams[FilterKeys.LoadGroup]) {
      searchString += `&loadGroup=${filterParams[FilterKeys.LoadGroup]}`;
    }
    if (filterParams[FilterKeys.DestinationTag]) {
      searchString += `&destinationTag=${filterParams[FilterKeys.DestinationTag]}`;
    }
    if (filterParams[FilterKeys.ShipMode]) {
      searchString += `&shipMode=${filterParams[FilterKeys.ShipMode]}`;
    }
    if (pageSize) {
      searchString += `&pageSize=${pageSize}`;
    }

    return this.makeGetRequest(`${this.baseUrl}/waves${searchString}`);
  }

  public async getWaveBatches(waveId: number): Promise<ApiResponse<GetBatchesResponse>> {
    const searchString = this.appendParameter('', 'waveIds', [waveId]);
    return this.makeGetRequest(`${this.baseUrl}/pick_batches${searchString}`);
  }

  public async getBatchesByWaveIds(waveIds: number[]): Promise<ApiResponse<GetBatchesResponse>> {
    const searchString = this.appendParameter('', 'waveIds', waveIds);
    return this.makeGetRequest(`${this.baseUrl}/pick_batches${searchString}`);
  }

  public async getBatchCountsByWaveIds(waveIds: number[]): Promise<ApiResponse<GetBatchCountsResponse>> {
    const searchString = this.appendParameter('', 'waveIds', waveIds);
    return this.makeGetRequest(`${this.baseUrl}/pick_batch_counts${searchString}`);
  }

  public async getWaveById(waveId: number): Promise<ApiResponse<GetWaveResponse>> {
    const response = await this.makeGetRequest(`${this.baseUrl}/wave?id=${waveId}`);

    if (!response?.data) {
      return response;
    }

    const waveAttr = {};
    for (const curAttr of response.data.wave?.waveAttributes ?? []) {
      waveAttr[translateSnakeToCamel(curAttr.name)] = curAttr.value;
    }

    const copy = {...response};

    if (copy.data?.wave?.waveAttributes) {
      copy.data.wave.waveAttributes = waveAttr;
    }

    return copy;
  }

  public async terminateWaveById(waveId: number, data: TerminateWaveRequest): Promise<ApiResponse<GetWaveResponse>> {
    return this.makePatchRequest(`${this.baseUrl}/terminate?id=${waveId}`, data);
  }

  public async regenWaveDocsById(waveId: number): Promise<ApiResponse<never>> {
    return this.makePostRequest(`${this.baseUrl}/${waveId}/documents/regenerate`, {});
  }

  public async getBatchById(
    batchId: string,
    includeAdditionalInformation: boolean = false
  ): Promise<ApiResponse<GetBatchResponse>> {
    let queryString = `?id=${batchId}`;
    if (includeAdditionalInformation) {
      queryString += '&includeAdditionalInformation=true';
    }
    return this.makeGetRequest(`${this.baseUrl}/pick_batch${queryString}`);
  }

  public async getPickCompleteForFreightWave(shipmentId: number): Promise<ApiResponse<any>> {
    return this.makeGetRequest(`/wh/ui/wave/pick_complete_for_freight_wave?shipment_id=${shipmentId}`);
  }

  private appendParameter(baseString: string, parameterName: string, array: any[]): string {
    if (isEmpty(array)) {
      return baseString;
    }

    for (const item of array) {
      const prepend = isEmpty(baseString) ? '?' : '&';
      baseString = `${baseString}${prepend}${parameterName}[]=${item.toString()}`;
    }
    return baseString;
  }
}

const translateSnakeToCamel = (toTranslate: string): string | undefined => {
  if (!toTranslate) {
    return toTranslate;
  }

  return toTranslate.split('_').reduce((prev, cur) => {
    return `${prev}${cur.charAt(0).toUpperCase()}${cur.substring(1).toLowerCase()}`;
  });
};

// This is a special status code returned by Cloudflare when a timeout occurs
const cloudflareTimeoutResponseCode: number = 524;

export default BatchWavingService;
