import {useEffect, useState} from 'react';
import {ApiResponse, PaginatedResponse} from '../CommonInterfaces';

/**
 * Hook that eagerly loads all pages from a paginated API endpoint. Should not be used if loading all pages has
 * performance ramifications.
 * @param getDataAsync - Function to retrieve a page from an API endpoint given a continuation token.
 * @param mapData - Function to get and accumulate the results set.
 * @param onErrors - Called if an error is returned in the response.
 * @param shouldMakeRequest - Dictates when a request should be made (e.g., when a parameter is not null).
 */
export const useAllPaginatedResults = <ResponseData extends PaginatedResponse, Results extends unknown[]>({
  getDataAsync,
  mapData,
  onErrors,
  shouldMakeRequest
}: {
  getDataAsync: (continuationToken?: string) => Promise<ApiResponse<ResponseData>>;
  mapData: (response: ResponseData) => Results;
  onErrors: (errors: ApiResponse<ResponseData>['errors']) => void;
  shouldMakeRequest: boolean;
}) => {
  const [results, setResults] = useState([] as Results);
  const [hasFetched, setHasFetched] = useState(false);

  const fetchPaginatedResults = async () => {
    const resultsBuffer = [] as Results;
    let continuationToken: string | undefined;
    do {
      const resp = await getDataAsync(continuationToken);
      if (resp.errors.length > 0) {
        onErrors(resp.errors);
        return;
      }

      resultsBuffer.push(...mapData(resp.data));
      continuationToken = resp.data.continuationToken;
    } while (continuationToken);
    setHasFetched(true);
    setResults(resultsBuffer);
  };

  useEffect(() => {
    if (!shouldMakeRequest || hasFetched) {
      return;
    }
    fetchPaginatedResults();
  }, [hasFetched, fetchPaginatedResults, shouldMakeRequest]);

  return {
    results
  };
};
