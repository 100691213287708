import * as React from 'react';

interface PrintSsccLabelsButtonProps {
  orderId: number;
  barcodes?: string[];
  elementId: string;
  buttonText: React.ReactFragment;
  customClassName?: string;
}

export const PrintSsccLabelsButton: React.FC<PrintSsccLabelsButtonProps> = (props) => {
  const printButton = () => {
    let href = `/api/fulfillment/retail/order/${props.orderId}/sscc_labels`;

    if (props.barcodes && props.barcodes.length > 0) {
      href += '?';
      props.barcodes.forEach((barcode: string, index: number) => {
        if (index > 0) {
          href += '&';
        }

        href += `barcodes[]=${barcode}`;
      });
    }

    let className = 'btn btn-primary';
    if (props.customClassName) {
      className += ' ' + props.customClassName;
    }

    return (
      <a id={props.elementId} className={className} target="_blank" href={href}>
        {props.buttonText}
      </a>
    );
  };

  return printButton();
};

export default PrintSsccLabelsButton;
