import * as React from 'react';
import {Icon} from '@flexe/ui-components';
import {PickBatchDocument} from '../WaveInterfaces';

interface Props {
  className?: string;
  document?: PickBatchDocument;
  isWaveCancelled: boolean;
  printButtonPrefix: string;
}

const DocumentPrintButton = ({className, document, isWaveCancelled, printButtonPrefix}: Props) => {
  const handlePrintButtonClick = () => {
    if (document && !isWaveCancelled) {
      window.open(`/wh/logged_document/${document.id}`, '_blank').focus();
    }
  };

  return (
    <button
      className={document?.views.length > 0 ? className + ' printed' : className}
      key="print-btn"
      onClick={handlePrintButtonClick}
      disabled={!document || isWaveCancelled}
    >
      {document || isWaveCancelled ? (
        <>
          <Icon icon="print" /> {`${printButtonPrefix} (${document?.views.length || 0})`}
        </>
      ) : (
        <>
          <span className="creating-labels-icon-container">
            <Icon icon="circle-notch" animation="spin-fast" />
          </span>
          Creating Labels...
        </>
      )}
    </button>
  );
};

export default DocumentPrintButton;
