/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {InfoBox} from '@flexe/ui-components';
import {css, jsx} from '@emotion/react';
import DocumentUploadModal from '../../shared/DocumentUploadModal';
import {CompanyType, Document, DocumentNotableType, DocumentType} from '../../shared/CommonInterfaces';
import {InboundDocumentsRow} from './InboundDocumentsRow';

interface Props {
  documents: Document[];
  notableId: number;
  handleIsBolUploaded: (isUploaded: boolean) => void;
  isContainerDeliveryConfirmed: boolean;
  isShipper: boolean;
  isEditMode: boolean;
  onDeleteDocsFromUi?: (documentId: number) => void;
  handleRefreshPage?: () => void;

  onDocumentSaved(savedDoc: Document);
}

const InboundDocuments: FC<Props> = (props) => {
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const {isShipper, isEditMode, onDeleteDocsFromUi, handleRefreshPage} = props;

  useEffect(() => {
    setDocuments(props.documents);
  }, [props.documents]);

  const handleSavedDoc = (savedDoc: Document) => {
    if (isShipper) {
      savedDoc.uploader = CompanyType.shipper;
    } else {
      savedDoc.uploader = CompanyType.warehouse;
    }
    setDocuments(documents.concat([savedDoc]));
    handleRefreshPage();
  };

  const toggleUploadModal = () => {
    setShowUploadDocumentModal(!showUploadDocumentModal);
  };

  const checkUploadedBol = (): boolean => {
    let isBolUploaded = false;
    documents.forEach((doc) => {
      if (doc.documentType === DocumentType.billOfLading) {
        isBolUploaded = true;
      }
    });
    if (isBolUploaded) {
      props.handleIsBolUploaded(true);
    }
    return isBolUploaded;
  };

  return (
    <React.Fragment>
      <div id="documents" className={isShipper ? 'inbound-warehouse-info' : 'warehouse-document-section'}>
        <div className="container-fluid">
          <div className="documents-container">
            <header>
              <div className="title-aligned">
                <h2>Documents</h2>
              </div>
              {!isEditMode && (
                <div className="title-aligned right">
                  <a data-testid="document-upload-button" className="add-documents" onClick={toggleUploadModal}>
                    <i className="fa fa-plus"></i> Upload document
                  </a>
                  <div id="document-upload-modal">
                    <DocumentUploadModal
                      documents={documents}
                      documentsRoute="/api/v2/documents"
                      notableType={DocumentNotableType.container}
                      notableId={props.notableId}
                      showUploadModal={showUploadDocumentModal}
                      toggleUploadModal={toggleUploadModal}
                      onDocumentSaved={handleSavedDoc}
                    />
                  </div>
                </div>
              )}
            </header>
            <table className="table">
              <thead>
                <tr>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody
                css={css`
                  display: block;
                  max-height: 300px;
                  overflow: auto;
                `}
                data-testid="inbound-documents-table-body"
              >
                {documents.map((doc, idx) => {
                  return (
                    <InboundDocumentsRow
                      key={idx}
                      document={doc}
                      onDeleteDocsFromUi={onDeleteDocsFromUi}
                      isEditMode={isEditMode}
                    />
                  );
                })}
              </tbody>
            </table>
            {!isShipper && !checkUploadedBol() && props.isContainerDeliveryConfirmed && (
              <InfoBox info="Upload Signed Bill of Lading" infoType="warning"></InfoBox>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InboundDocuments;
