/** @jsxRuntime classic */
/** @jsx jsx */

import {css, jsx} from '@emotion/react';
import React, {useEffect, useState} from 'react';
import {Column} from '@flexe/ui-components';
import Notification from './Notification';
import {NotificationDetails} from './notification-utility';

const baseListStyle = css({'> ul': {margin: 0, padding: 0}});
const bottomRightContainerStyle = css`
  ${baseListStyle};
  position: fixed;
  right: 20px;
  bottom: 20px;
`;

/**
 * This component is currently a proof of concept and shouldn't
 * be used outside the User Management component.
 */
const Notifications = () => {
  const [notifications, setNotifications] = useState<NotificationDetails[]>([]);
  const [inPlaceNotifications, setInPlaceNotifications] = useState<NotificationDetails[]>([]);

  useEffect(() => {
    window.addEventListener('flexe.notification', (event: CustomEvent<NotificationDetails>) =>
      handleFlexeNotification(event)
    );

    return () => {
      window.removeEventListener('flexe.notification', (event: CustomEvent<NotificationDetails>) =>
        handleFlexeNotification(event)
      );
    };
  }, []);

  const handleFlexeNotification = (event: CustomEvent<NotificationDetails>) => {
    if (event.detail.location === 'lower-right') {
      setNotifications((oldNotifications) => [...oldNotifications, event.detail]);
    } else if (event.detail.location === 'in-place') {
      setInPlaceNotifications((oldNotifications) => [...oldNotifications, event.detail]);
    }
  };

  const handleNotificationExpired = (notificationId: string) => {
    setNotifications((oldNotifications) => [
      ...oldNotifications.filter((notification) => notification.id !== notificationId)
    ]);
  };

  const handleInPlaceNotificationExpired = (notificationId: string) => {
    setInPlaceNotifications((oldNotifications) => [
      ...oldNotifications.filter((notification) => notification.id !== notificationId)
    ]);
  };

  return (
    <React.Fragment>
      <div css={baseListStyle}>
        <Column tag="ul">
          {inPlaceNotifications.map((notification) => (
            <li key={notification.id}>
              <Notification
                id={notification.id}
                level={notification.level}
                message={notification.message}
                durationMs={notification.durationMs}
                onTimeExpired={handleInPlaceNotificationExpired}
              />
            </li>
          ))}
        </Column>
      </div>
      <div css={bottomRightContainerStyle}>
        <Column tag="ul">
          {notifications.map((notification) => (
            <li key={notification.id}>
              <Notification
                id={notification.id}
                level={notification.level}
                message={notification.message}
                durationMs={notification.durationMs}
                onTimeExpired={handleNotificationExpired}
                boxShadow
              />
            </li>
          ))}
        </Column>
      </div>
    </React.Fragment>
  );
};

export default Notifications;
