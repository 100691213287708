import {ApiResponse} from '../CommonInterfaces';
import {GetLoadsResponse, UpsertLoadRequest, UpsertLoadResponse} from '../../warehouse/loads/LoadInterfaces';
import InternalAPIService from './InternalAPIService';
class LoadsService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/transportation');
  }

  /**
   * POST
   * Fetch a set of loads from the server.
   * @param warehouseId Required, id of the warehouse for loads are being viewed
   * @param continuationToken Optional, continuation token for retrieving results beyond the first page
   * @param createdAfter Optional, the date which loads have to have been created after to be returned. When null, all
   * loads may be returned.
   * @returns an array of Load objects
   */
  public async getLoads(
    warehouseId: number,
    continuationToken: string,
    createdAfter: Date
  ): Promise<ApiResponse<GetLoadsResponse>> {
    const data = {
      warehouse_id: warehouseId,
      continuation_token: continuationToken,
      created_after: createdAfter?.toISOString()
    };
    return await this.makePostRequest(`${this.baseUrl}/loads`, data);
  }

  public async upsertLoad(data: UpsertLoadRequest): Promise<ApiResponse<UpsertLoadResponse>> {
    return await this.makePutRequest(`${this.baseUrl}/load`, data);
  }
}

export default LoadsService;
