/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import * as Papa from 'papaparse';
import {css, jsx} from '@emotion/react';
import {
  Button,
  DetailedInfoBox,
  Expander,
  FileSelector,
  Heading,
  Icon,
  Link,
  Modal,
  Text,
  useTable
} from '@flexe/ui-components';
import {ParseResult} from 'papaparse';
import {UploadParams} from '../../edi-files/EdiFilesInterfaces';
import BasicTableRow from '../../../shared/BasicTableRow';
import {GenericErrorDisplay} from '../../../shared/GenericErrorDisplay';

/**
 * Given the papa.Parse result (with config headers = false),
 * gets the received headers
 * @param parseResult The papa.Parse result
 * @param normalize Whether to normalize the headers, which...
 *  - strips "(optional)" and "(required)"
 *  - trims
 *  - converts to lower case
 *  - replaces underscore with space
 *  No normalization means "just return what was input)
 * @return Array of header strings
 */
function getHeadersFromParseResult(parseResult: ParseResult, normalize: boolean): string[] {
  if (!parseResult || !parseResult.data || !parseResult.data[0]) {
    return [];
  }

  if (!normalize) {
    return parseResult.data[0].map((header) => header);
  }

  return parseResult.data[0].map((header) => {
    // Remove optional and required from the column headers
    const idxOptional = header.indexOf('(optional)');
    if (idxOptional >= 0) {
      header = header.slice(0, idxOptional);
    }
    const idxRequired = header.indexOf('(required)');
    if (idxRequired >= 0) {
      header = header.slice(0, idxRequired);
    }

    return header
      .trim()
      .toLowerCase()
      .replaceAll('_', ' ');
  });
}

/**
 * Checks if all headersToTest are in headersToTestAgainst
 * @param headersToTest Array of header names
 * @param headersToTestAgainst Array of header names
 * @return Array of headers in headersToTest, but not headersToTestAgainst
 */
function getMissingHeaders(headersToTest: string[], headersToTestAgainst: string[]) {
  const result: string[] = [];

  headersToTest.forEach((header: string) => {
    if (!headersToTestAgainst.includes(header)) {
      result.push(header);
    }
  });

  return result;
}

/**
 * Given a list of all allowed headers, get
 * the required header names and all header names
 * @param inputHeaders Array of all header names + whether it's required (Yes for required).
 *  Format: [ ['header1', 'Yes'], ['header2', 'No'], ...]
 * @return Tuple part 1 = list of required header names, part 2 = list of all header names
 */
function buildRequiredAndAllCsvHeaderArrays(inputHeaders?: string[][]): [string[], string[]] {
  const requiredHeaders: string[] = [];
  const allHeaders: string[] = [];

  if (inputHeaders && inputHeaders.length > 0) {
    inputHeaders.forEach((header) => {
      const name = header[0];
      allHeaders.push(name);
      if (header[1] === 'Yes') {
        requiredHeaders.push(name);
      }
    });
  }

  return [requiredHeaders, allHeaders];
}

// this type means that headers,
// oldHeaders, and templateUpdateDateString
// must ALL be provided when backwardsCompatible={true}
type CompatibilityProps =
  | {
      backwardsCompatible?: false;
      headers: string[][];
      fileUploadType: string;
      oldHeaders?: never;
      oldFileUploadType?: never;
      templateUpdateDateString?: never;
    }
  | {
      backwardsCompatible: true;
      headers: string[][];
      fileUploadType: string;
      oldHeaders: string[][];
      oldFileUploadType: string;
      templateUpdateDateString: string;
    };

export type UploadHandlerCallback = (errors: GenericErrorDisplay[]) => void;
export type UploadHandler = (params: UploadParams, type: string, callback: UploadHandlerCallback) => void;

interface CommonProps {
  id: string;
  show: boolean;
  title: string;
  errors: any;
  clearAlerts: any;
  actionDescription: string;
  templatePath: string;
  instructionsTableHeaders: React.ReactNode[];
  instructionsTableRows: React.ReactNode[][];
  uploading: boolean;
  wrapperClass?: string;
  handleUpload: UploadHandler;
  children?: React.ReactNode;
  disableSubmit?: boolean;

  toggleModal(e: any): void;
}

export type Props = CommonProps & CompatibilityProps;

/*****
 * This component implements functionality the OMS team needs until
 * the front-end team can create a standard shared component
 *****/
const CsvBulkUploadModalOMSV2: React.FC<Props> = (props: Props) => {
  /* Misc constants */
  const maxFileSizeMB = 2; // max .csv file size in MB
  const maxFileSize = maxFileSizeMB * 1024 * 1024;

  /* State */
  const [showUploadInstructions, setShowUploadInstructions] = React.useState<boolean>(false);
  const [chosenFileErrors, setChosenFileErrors] = React.useState<GenericErrorDisplay[]>([]);
  const [chosenFileType, setChosenFileType] = React.useState<string>('');
  const [chosenFile, setChosenFile] = React.useState<File>(null);
  const [uploadErrors, setUploadErrors] = React.useState<GenericErrorDisplay[]>([]);

  /* Effect-based hooks */
  React.useEffect(() => {
    if (chosenFile && chosenFile.name) {
      validateHeaders(chosenFile);
    }
  }, [chosenFile]);

  React.useEffect(() => {
    if (!props.show) {
      clearChosenFile();
    }
  }, [props.show]);

  /* Basic file input handling */
  const clearChosenFile = () => {
    setChosenFileType('');
    setChosenFile(null);
    setChosenFileErrors([]);
    setUploadErrors([]);
  };

  const dropzoneClearSelection = () => {
    clearChosenFile();
  };

  const handleDropzoneError = () => {
    setChosenFileErrors([{header: 'Please select a single CSV for upload.'}]);
  };

  /* File change */
  const handleFileChange = (file) => {
    clearChosenFile();

    if (file) {
      const errors: GenericErrorDisplay[] = [];

      if (file.size > maxFileSize) {
        errors.push({header: `Please select a .csv file less than ${maxFileSizeMB} MB.`});
      } else if (file.name && file.name.match(/\.csv$/)) {
        setChosenFile(file);
      } else {
        errors.push({header: 'The selected file is not in .csv format.'});
      }
      setChosenFileErrors(errors);
    }
  };

  const fileInputHandleFileChange = (e) => {
    props.clearAlerts();
    handleFileChange(e.target.files[0]);
  };

  const handleDrop = (acceptedFiles) => {
    dropzoneClearSelection();
    props.clearAlerts();
    handleFileChange(acceptedFiles[0]);
  };

  const validateHeaders = (file: File) => {
    Papa.parse(file, {
      header: false,
      preview: 1,
      complete: (parseResult: ParseResult) => {
        const receivedHeaders = getHeadersFromParseResult(parseResult, true);
        // Start with new headers
        const [newReqHeaders, newAllHeaders] = buildRequiredAndAllCsvHeaderArrays(props.headers);

        const missingReqHeaders: string[] = getMissingHeaders(newReqHeaders, receivedHeaders);
        const extraHeaders: string[] = getMissingHeaders(receivedHeaders, newAllHeaders);

        if (missingReqHeaders.length < 1 && extraHeaders.length < 1) {
          handleHeadersMatchTemplate(false);
        } else {
          const newHeadersErrors: GenericErrorDisplay[] = [];
          if (missingReqHeaders.length > 0) {
            newHeadersErrors.push({
              header: 'The following required columns are missing from the file:',
              details: missingReqHeaders
            });
          }
          if (extraHeaders.length > 0) {
            newHeadersErrors.push({
              header: 'The following columns are in the file, but are unsupported:',
              details: extraHeaders
            });
          }

          if (props.backwardsCompatible && validateOldHeaders(parseResult)) {
            handleHeadersMatchTemplate(true);
          } else {
            setChosenFileErrors(newHeadersErrors);
          }
        }
      }
    });
  };

  const validateOldHeaders = (parseResult: ParseResult): boolean => {
    const receivedHeaders = getHeadersFromParseResult(parseResult, false);
    const [oldReqHeaders, oldAllHeaders] = buildRequiredAndAllCsvHeaderArrays(props.oldHeaders);

    const missingReqHeaders: string[] = getMissingHeaders(oldReqHeaders, receivedHeaders);
    if (missingReqHeaders.length > 0) {
      return false;
    }

    const extraHeaders: string[] = getMissingHeaders(receivedHeaders, oldAllHeaders);
    return extraHeaders.length < 1;
  };

  const handleHeadersMatchTemplate = (isOldVersion: boolean) => {
    if (isOldVersion) {
      setChosenFileType(props.oldFileUploadType);
    } else {
      setChosenFileType(props.fileUploadType);
    }
  };

  /* Upload handling */
  const handleUploadLocal = () => {
    // Clear errors to make it clearer on resubmit if
    //  the same error happens again
    setUploadErrors([]);
    props.handleUpload({file: chosenFile}, chosenFileType, handleUploadCallback);
  };

  const handleUploadCallback = (errors: GenericErrorDisplay[]) => {
    if (!errors || errors.length < 1) {
      // Success, so clear this out
      clearChosenFile();
    } else {
      setUploadErrors(errors);
    }
  };

  // Note it would be nice to not always disable the uploadErrors button
  // So they can correct the error without re-selecting the file
  // But submit doesn't work well if you do that.
  const haveErrors =
    (chosenFileErrors && chosenFileErrors.length > 0) ||
    (uploadErrors && uploadErrors.length > 0) ||
    props.errors.length > 0;
  const disableUpload =
    props.uploading || !chosenFile || !chosenFile.name || !chosenFileType || haveErrors || props.disableSubmit;

  const {tableStyles} = useTable({});

  const wrapperStyles = css({marginBottom: '10px'});
  const expanderStyles = css({marginTop: '10px'});
  return (
    <Modal
      size="full"
      isOpen={props.show}
      toggleModal={props.toggleModal as () => void}
      title={props.title}
      subtitle={
        <Text tag="p">
          Upload a CSV to {props.actionDescription}. Please ensure you are using the{' '}
          <Link href={props.templatePath}>CSV template</Link> provided.
        </Text>
      }
    >
      {haveErrors && (
        <Heading level="h5">
          Once the following errors have been addressed, please reselect the file to enable submission.
        </Heading>
      )}
      <FileSelector
        dropzoneProps={{
          onAcceptedFiles: () => {
            return;
          },
          onDropzoneError: handleDropzoneError,
          onFileDrop: handleDrop,
          onRejectedFiles: handleDropzoneError,
          acceptedFileTypes: {'text/csv': []}
        }}
        fileInputAccept=".csv"
        selectedFile={chosenFile && chosenFile.name}
        fileInputOnChange={fileInputHandleFileChange}
      />
      <div css={wrapperStyles}>
        <Button id="upload-agree" onPress={handleUploadLocal} isDisabled={disableUpload}>
          {props.uploading && <Icon icon="spinner" animation="spin-fast" label="Loading" />}Submit
        </Button>
      </div>
      {/* TODO: Refactor this into something more reusable.*/}
      {chosenFileErrors.map((error) => (
        <DetailedInfoBox info={error.header} key={error.header} infoType="error">
          {typeof error.details !== 'undefined' &&
            error.details.map((detail) => (
              <Text tag="p" key={detail}>
                {detail}
              </Text>
            ))}
        </DetailedInfoBox>
      ))}
      {uploadErrors.map((error) => (
        <DetailedInfoBox info={error.header} key={error.header} infoType="error">
          {typeof error.details !== 'undefined' &&
            error.details.map((detail) => (
              <Text tag="p" key={detail}>
                {detail}
              </Text>
            ))}
        </DetailedInfoBox>
      ))}
      {props.errors.length ? (
        <DetailedInfoBox info="Error" infoType="error">
          {props.errors.map((error) => (
            <Text tag="p" key={error}>
              {error}
            </Text>
          ))}
        </DetailedInfoBox>
      ) : null}
      <div css={expanderStyles}>
        <Expander
          isOpen={showUploadInstructions}
          onToggle={() => setShowUploadInstructions(!showUploadInstructions)}
          title="See more about what inputs your file needs"
        >
          <table {...tableStyles}>
            <thead>
              <tr className="instructions-table-header-row">
                {Object.keys(props.instructionsTableHeaders).map((k, i) => {
                  return <th key={`${k}-${i}`}>{props.instructionsTableHeaders[k]}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {props.instructionsTableRows.map((datum, i) => {
                // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
                return <BasicTableRow data={datum} key={`${datum[i]}-${i}`} />;
              })}
            </tbody>
          </table>
        </Expander>
      </div>
    </Modal>
  );
};

export default CsvBulkUploadModalOMSV2;
