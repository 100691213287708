import * as React from 'react';
import {Button, Modal} from '@flexe/ui-components';
import {useMemo} from 'react';
import {Location} from '../../ShipmentInterfaces';
import {renderLocationLink, renderTrailerManifestLink} from '../../../../../libs/helpers';
import {ResponseErrorV2} from '../../../../shared/CommonInterfaces';
import {UnpackWorkflowState} from './UnpackWorkflow';

export interface UnpackCompletedModalProps {
  workflowState: UnpackWorkflowState;
  setWorkflowState: (state: UnpackWorkflowState) => void;
  shipmentId: number;
  unpackLocation: Location;
  errors: ResponseErrorV2[];
  loadId: number;
}

export enum UnpackErrorCodes {
  Unknown = '0',
  TrailerLoaded = 'shipment_trailer_loaded',
  ComminglingViolation = 'commingling_violation'
}

export const UnpackCompletedModal: React.FC<UnpackCompletedModalProps> = (props) => {
  const storedLocation = useMemo(() => props.unpackLocation, [props.shipmentId]);

  if (![UnpackWorkflowState.completedSuccess, UnpackWorkflowState.completedError].includes(props.workflowState)) {
    return null;
  }

  const completeWorkflow = () => {
    props.setWorkflowState(UnpackWorkflowState.closed);
  };

  const locationDom = () => {
    if (storedLocation?.id && storedLocation?.name) {
      return renderLocationLink(storedLocation?.id, storedLocation?.name);
    } else {
      return 'Unknown Location';
    }
  };

  const errorDom = () => {
    switch (mostImportantError()) {
      case UnpackErrorCodes.TrailerLoaded:
        return (
          <p>
            This Shipment can't be unpacked because it's already been loaded. Please remove the Shipment from{' '}
            {props.loadId ? renderTrailerManifestLink(props.loadId) : 'Unknown Load'}{' '}
          </p>
        );
      case UnpackErrorCodes.ComminglingViolation:
        return (
          <p>
            This Shipment can't be unpacked at {locationDom()} because it contains a SKU that has a different Lot Code
            and/or Expiration Date as another SKU at this Location. Move conflicting units or this package to a
            different location to try again.
          </p>
        );
      default:
        return <p>This Shipment can't be unpacked because something didn't process. Please try again.</p>;
    }
  };

  const mostImportantError = () => {
    const codes = props.errors?.map((err) => err.code) ?? [];
    if (codes.includes(UnpackErrorCodes.TrailerLoaded)) return UnpackErrorCodes.TrailerLoaded;
    if (codes.includes(UnpackErrorCodes.ComminglingViolation)) return UnpackErrorCodes.ComminglingViolation;
    return UnpackErrorCodes.Unknown;
  };

  return (
    <Modal
      isOpen={true}
      toggleModal={completeWorkflow}
      title={
        props.workflowState === UnpackWorkflowState.completedError
          ? `Can't Unpack Shipment  #${props.shipmentId}`
          : `Shipment #${props.shipmentId} Unpacked`
      }
    >
      {props.workflowState === UnpackWorkflowState.completedSuccess ? (
        <p>
          Units associated with this LPN are now loose and unallocated. They can be Moved or Cycle Counted from this
          Location {locationDom()}
        </p>
      ) : (
        errorDom()
      )}
      <div className={'action-buttons align-right'}>
        <Button visualType={'primary'} onPress={completeWorkflow}>
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default UnpackCompletedModal;
