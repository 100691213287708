import * as React from 'react';
import {Modal, Text} from '@flexe/ui-components';
import {ModalProps} from '@flexe/ui-components/dist/types/components/Modal/Modal';
import FlexeButton from '../../shared/FlexeButton';

export interface Props extends Pick<ModalProps, 'isOpen' | 'toggleModal'> {
  onCancel: () => void;
  loadId: string;
}

export const CancelLoadModal: React.FC<Props> = (props) => {
  const {isOpen, toggleModal, onCancel, loadId} = props;

  return (
    <Modal isOpen={isOpen} toggleModal={toggleModal} title={'Cancel Load #' + loadId}>
      <div className="modal-contents">
        <Text>
          You are cancelling Load #{loadId}. This can’t be undone, though you can add these Shipments to a new Load
          afterwards.
        </Text>
        <Text>
          Please move any loaded LPNs from the Door Location to a Staging Location to prevent future conflicts.
        </Text>
      </div>
      <div className="action-buttons modal-footer">
        <FlexeButton text="Go Back" handleClick={toggleModal} level="secondary" />
        <FlexeButton text="Cancel Load" handleClick={onCancel} level="danger" />
      </div>
    </Modal>
  );
};

export default CancelLoadModal;
