import * as React from 'react';

export interface DropDownOption {
  name: string | number | JSX.Element;
  value: string | number;
}

export enum DropDownColor {
  default = '',
  grey = 'grey',
  cta = 'cta',
  issue = 'issue',
  white = 'white'
}

export enum DropDownStyle {
  default = '',
  flat = 'flat',
  ghost = 'ghost',
  modal = 'cycle-count-typeahead',
  matchFont = 'match-font'
}

export enum DropDownType {
  default = '',
  secondary = 'secondary'
}

interface DropDownProps {
  options: DropDownOption[];
  selected: DropDownOption;
  color?: DropDownColor;
  style?: DropDownStyle;
  type?: DropDownType;
  prefixText?: string;
  downloadLink?: string;
  hideSelectedName?: boolean;
  onSelect(option: DropDownOption);
}

interface DropDownState {
  open: boolean;
  selectedOption?: DropDownOption;
}

class DropDown extends React.Component<DropDownProps, DropDownState> {
  private dropDown: Element;

  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  public componentDidMount() {
    window.document.body.addEventListener('click', this.hideDropDown);
  }

  public componentWillUnmount() {
    window.document.body.removeEventListener('click', this.hideDropDown);
  }

  public render() {
    const {options, selected, color, style, type, prefixText, downloadLink, hideSelectedName, onSelect} = this.props;

    return (
      <div className={`btn-group ${this.state.open ? 'open' : ''}`}>
        <a
          aria-expanded={this.state.open ? 'true' : 'false'}
          aria-haspopup="true"
          className={`btn dropdown-toggle ${color || ''} ${style || ''} ${type || ''}`}
          type="button"
          ref={this.setDropDownRef}
          onClick={this.toggleOpen}
        >
          {prefixText ? prefixText : ''}
          {!hideSelectedName && selected ? selected.name : ''}
          <span className="caret"></span>
        </a>
        <ul className="dropdown-menu">
          {options.map(({name, value}, index) => {
            return (
              <li key={index}>
                {downloadLink && selected.value === 'Download' ? (
                  <a data-index={index} onClick={this.handleSelect} href={downloadLink}>
                    {name}
                  </a>
                ) : (
                  <a data-index={index} onClick={this.handleSelect}>
                    {name}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  private setDropDownRef = (element) => {
    this.dropDown = element;
  };

  private toggleOpen = () => {
    this.setState({open: !this.state.open});
  };

  private hideDropDown = (event) => {
    if (event.target !== this.dropDown) {
      this.setState({open: false});
    }
  };

  private handleSelect = (event) => {
    const index = event.target.getAttribute('data-index');
    const option = this.props.options[index];
    this.props.onSelect(option);
  };
}

export default DropDown;
