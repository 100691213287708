import * as React from 'react';

import {LegacyModal} from '@flexe/ui-components';
import {CarrierBillingAccount} from '../SharedInterfaces';

interface Props {
  show: boolean;
  hideModal: () => void;
  handleSubmit: () => void;
  account: CarrierBillingAccount;
}

const CarrierBillingAccountDeleteDialog: React.SFC<Props> = (props) => {
  const {account} = props;

  const footer = (
    <div>
      <a className="carrier-billing-account__cancel" onClick={props.hideModal}>
        Cancel
      </a>
      <button
        type="button"
        className="carrier-billing-account__submit-delete btn btn-primary"
        onClick={props.handleSubmit}
      >
        Delete Billing Account
      </button>
    </div>
  );

  if (!account) {
    return null;
  }

  return (
    <LegacyModal
      id="carrier_billing_account_delete_modal"
      title={'Delete Carrier Billing Account'}
      show={props.show}
      size="small"
      transitionSpeed="fast"
      toggleModal={props.hideModal}
      footer={footer}
    >
      <div>
        <div className="row">
          <p className="col-md-12">Are you sure you wish to delete {account.friendlyName}?</p>
        </div>
      </div>
    </LegacyModal>
  );
};

export default CarrierBillingAccountDeleteDialog;
