import * as React from 'react';
import ItemMasterService from '../item-master/ItemMasterService';

export interface ItemMasterContextValues {
  itemMasterService: ItemMasterService;
  isShipper: boolean;
  // more things will be added here as we discover what they are
}

const defaultValues: ItemMasterContextValues = {
  itemMasterService: null,
  isShipper: true
};

const ItemMasterContext = React.createContext(defaultValues);

export default ItemMasterContext;
