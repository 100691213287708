import * as React from 'react';
import {Pagination, Table, TableHeader} from '@flexe/ui-components';
import {format} from 'date-fns';
import {
  Carrier,
  OrderFilters,
  OrderResponse,
  OrderType,
  SkuSingleLineItem,
  WaveParameters
} from '../ecommerce-batches/BatchInterfaces';
import BatchWavingService from '../../shared/services/BatchWavingService';

interface FilterResultsTableProps {
  skuSummary: SkuSingleLineItem[];
  shipments: OrderResponse[];
  shipmentTotal: number;
  carriers: Map<string, string>;
  disableCreate: boolean;
  shipmentFilters: OrderFilters;
  wavingService: BatchWavingService;
  pageSize: number;
  currentPage: number;
  handlePagination(page);
  onSuccess();
  handleError(message, response);
}

const FilterResultsTable: React.FC<FilterResultsTableProps> = (props) => {
  const getSingleSkuWaveParameters = (sku: string) => {
    return {
      reservationId: props.shipmentFilters.reservationId,
      maxOrders: props.shipmentTotal,
      cutoffDate: props.shipmentFilters.cutoffDate,
      skus: [sku],
      carriers: props.shipmentFilters.carriers,
      serviceTypes: props.shipmentFilters.serviceTypes,
      orderType: props.shipmentFilters.orderType,
      includesMultiShipmentPo: props.shipmentFilters.includesMultiShipmentPo,
      shipmentType: OrderType.SingleItem,
      batchCount: 1
    };
  };
  const createSkuBatch = async (sku: string, shipmentsToBatch: number) => {
    const previewParameters: WaveParameters = getSingleSkuWaveParameters(sku);
    previewParameters.maxOrders = shipmentsToBatch;
    const response = await props.wavingService.immediateBatch(previewParameters);
    if (response && response.errors.length === 0) {
      props.onSuccess();
    } else {
      const message = 'There was an issue creating your batches';
      props.handleError(message, response);
    }
  };

  const getSkuSummaryData = () => {
    const headers = [
      //  SKU, description, shipalone (Y/N), quantity, counts per pallet
      {className: 'id-header', element: 'SKU'},
      {element: 'Description'},
      {element: 'Ship Alone'},
      {element: 'Quantity'},
      {element: 'Units Per Pallet'},
      {element: ''} // dummy pagination element to be right-aligned
    ] as TableHeader[];

    const rows = props.skuSummary.map((current: SkuSingleLineItem) => {
      return [
        <span>#{current.sku}</span>,
        current.description,
        current.shipAlone ? 'Yes' : 'No',
        current.quantity,
        current.eachesPerPallet,
        <button
          disabled={props.disableCreate}
          className="btn"
          id="create-sku-wave"
          onClick={() => {
            createSkuBatch(current.sku, current.quantity);
          }}
        >
          <i className="fa fa-share-square"></i> Batch all
        </button>
      ];
    });

    return {
      headers,
      rows
    };
  };

  const getTableData = () => {
    const headers = [
      {className: 'id-header', element: 'FLEXE ID'},
      {element: 'P.O.'},
      {element: 'Shipper'},
      {element: 'Carrier'},
      {element: 'Service Type'},
      {element: 'Ship By'},
      {element: 'Pick Method'},
      {
        className: 'pagination-header',
        element: (
          <Pagination
            page={props.currentPage}
            pageSize={props.pageSize}
            paginationHandler={props.handlePagination}
            totalCount={props.shipmentTotal}
          />
        )
      }
    ] as TableHeader[];

    const rows = props.shipments.map((current: OrderResponse) => {
      const carrierName = props.carriers.get(current.order.carrier);
      return [
        <span>#{current.order.id}</span>,
        current.order.purchaseOrder,
        current.shipperCompany.name,
        carrierName || '',
        current.order.serviceType,
        format(current.order.shipOn, 'M/D/YYYY'),
        current.order.shipAlone ? 'Shipalone' : 'Cluster',
        ''
      ];
    });

    return {
      headers,
      rows
    };
  };

  return (
    <div>
      {props.skuSummary.length > 0 && (
        <div className="sku-summary">
          <h4 className="table-title">High frequency SKU for single line shipments</h4>
          <Table tableClass="table-striped order-table" tableData={getSkuSummaryData()} />
          <p className="space-below" />
        </div>
      )}
      <h4 className="table-title">
        {props.shipmentTotal > 0
          ? `${props.shipmentTotal} New Shipments`
          : 'No New Shipments match your current criteria'}
      </h4>
      <Table tableClass="table-striped order-table" tableData={getTableData()} />
    </div>
  );
};

export default FilterResultsTable;
