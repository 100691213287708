import axios from 'axios';
import * as React from 'react';
import classNames from 'classnames';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {get} from 'lodash';
import FlexeButton from '../shared/FlexeButton';
import {Packaging} from '../shared/CommonInterfaces';
import TypeAhead, {TypeAheadOption} from '../shared/TypeAhead';
import {Inventory} from '../dropoffs/shared/DropoffInterfaces';
import TestSuggestionTable from './TestSuggestionTable';
import SpaConfigurationsRulesService, {SuggestedLocation} from './SpaConfigurationsRulesService';

export interface Props {
  warehouseId: number;
  id: string;
  show: boolean;
  title: string;
  actionDescription: string;
  uploading: boolean;
  wrapperClass?: string;

  toggleModal(): void;

  spaConfigurationsRulesService: SpaConfigurationsRulesService;
}

const TestRulesModal: React.FC<Props> = (props: Props) => {
  const transitionTimeout = 1;
  const [modelErrors, setModelErrors] = React.useState<React.ReactNode>(null);
  const [typeAheadOptions, setTypeAheadOptions] = React.useState<TypeAheadOption[]>([]);
  const [skuQuery, setSkuQuery] = React.useState<string>('');
  const [selectedPackaging, setSelectedPackaging] = React.useState<Packaging>(Packaging.each);
  const [selectedInventoryId, setSelectedInventoryId] = React.useState<number>(-1);
  const [noSuggestionsMsg, setNoSuggestionsMsg] = React.useState<string>(
    'Select an item then Generate putaway suggestions to view suggestion list'
  );
  const [enableSuggestionsButton, setEnableSuggestionsButton] = React.useState<boolean>(false);
  const [suggestedLocations, setSuggestedLocations] = React.useState<SuggestedLocation[]>([]);
  const [inventory, setInventory] = React.useState<Inventory[]>([]);
  const {spaConfigurationsRulesService, warehouseId} = props;

  const renderModalTitle = () => {
    return (
      <div className="modal-title">
        <h4>{props.title}</h4>
      </div>
    );
  };

  const renderSkuColumn = () => {
    return (
      <TypeAhead
        name="sku-select"
        placeholder="Type to select SKU..."
        value={skuQuery}
        options={typeAheadOptions}
        onRequestOptions={handleSkuQuery}
        onSelect={handleSkuSelect}
        dropdownCss="typeahead-dropdown-menu"
      />
    );
  };

  const renderPackagingColumn = () => {
    const options: any = [
      {value: Packaging.each, label: 'Eaches'},
      {value: Packaging.carton, label: 'Carton'}
    ];
    return (
      <select className="form-control" onChange={handlePackagingSelect} value={selectedPackaging}>
        {options.map((packaging) => (
          <option key={packaging.value} value={packaging.value}>
            {packaging.label}
          </option>
        ))}
      </select>
    );
  };

  const handlePackagingSelect = (event) => {
    setSelectedPackaging(event.target.value);
  };

  const handleSkuQuery = async (query) => {
    setSkuQuery(query);
    if (query.length > 2) {
      try {
        const response = await axios.get('/widgets/inventory_picker', {
          params: {
            q: query,
            warehouse_id: props.warehouseId
          }
        });
        const invResponse = get(response, 'data.matches', []);
        const options = invResponse.map((inv) => {
          return {
            value: inv.sku,
            displayName: `${inv.sku} - ${inv.description}`
          };
        });
        setTypeAheadOptions(options);
        setInventory(invResponse);
      } catch (error) {
        setTypeAheadOptions([]);
        setEnableSuggestionsButton(false);
      }
    } else {
      setSelectedInventoryId(-1);
      setTypeAheadOptions([]);
      setEnableSuggestionsButton(false);
    }
  };

  const handleSkuSelect = (sku) => {
    const selectedInventory = inventory.find((inv) => inv.sku === sku);
    setSelectedInventoryId(selectedInventory?.id);
    setSkuQuery(sku);
    setTypeAheadOptions([]);
    setEnableSuggestionsButton(true);
  };

  const fetchSuggestions = async () => {
    const response = await spaConfigurationsRulesService.testSuggestions(
      warehouseId,
      selectedInventoryId,
      selectedPackaging
    );
    if (response.errors.length === 0) {
      const locations = response.data.locations;
      setSuggestedLocations(locations);
      if (locations.length === 0) {
        setNoSuggestionsMsg('No suggestions generated. Please check your rule for the specified SKU.');
      }
      if (response.data.steps.length > 0) {
        // eslint-disable-next-line
        console.log('Got steps for recommendations generation');
        // eslint-disable-next-line
        console.log(response.data.steps);
      }
    } else {
      setModelErrors(response.errors);
    }
  };

  const modalClasses = ['react-modal', 'csv-bulk-create-modal', props.wrapperClass && props.wrapperClass];
  const dialogClasses = ['modal-dialog'];

  return (
    <React.Fragment>
      <TransitionGroup>
        {props.show && (
          <CSSTransition classNames="react-modal" timeout={transitionTimeout}>
            <div className={classNames(modalClasses)}>
              <div className="modal-backdrop"></div>
              <div className="modal" id={props.id} role="dialog" aria-labelledby={`${props.id}_label`}>
                <div className={classNames(dialogClasses)} role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <FlexeButton
                        text={<span aria-hidden="true">&times;</span>}
                        handleClick={props.toggleModal}
                        level="collapsed"
                      />
                      {renderModalTitle()}
                    </div>
                    <div className="modal-body">
                      <div className="inner-content">
                        {modelErrors && (
                          <div>
                            <div className="alert alert-danger" role="alert">
                              {modelErrors}
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-md-7">
                            <div className="form-group">
                              <label className="control-label">Select Item</label>
                              {renderSkuColumn()}
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label className="control-label">Packaging</label>
                              {renderPackagingColumn()}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>&nbsp;</label>
                              <div>
                                <FlexeButton
                                  level="primary"
                                  isDisabled={!enableSuggestionsButton}
                                  text={<>Generate Putaway Suggestions</>}
                                  handleClick={fetchSuggestions}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <TestSuggestionTable noSuggestionsMsg={noSuggestionsMsg} suggestions={suggestedLocations} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </React.Fragment>
  );
};

export default TestRulesModal;
