import * as React from 'react';
import {FunctionComponent, useState} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import FlexeButton from '../shared/FlexeButton';
import WorkOrdersService from '../shared/services/WorkOrdersService';

interface Props {
  visible: boolean;
  workOrderId: number;
  workOrdersService: WorkOrdersService;
  toggleModal: () => void;
}

const CancelRemainingModal: FunctionComponent<Props> = (props) => {
  const [message, setMessage] = useState<string>('');
  const [pageErrors, setPageErrors] = useState<string>('');

  const cancelRemaining = async () => {
    const response = await props.workOrdersService.completeKitWorkOrder(props.workOrderId, message);
    if (response && response.data && response.data.kitWorkOrderId) {
      window.location.reload();
    } else if (response && response.errors[0] && response.errors[0].detail) {
      setPageErrors(response.errors[0].detail);
    } else {
      setPageErrors('There was an issue cancelling the remaining kits.');
    }
  };

  return (
    <LegacyModal
      id="cancel-remaining-modal"
      title="Cancel Remaining Kits"
      show={props.visible}
      size="small"
      toggleModal={() => props.toggleModal()}
      footer={
        <React.Fragment>
          {pageErrors !== '' && <p className="cancel-error">{pageErrors}</p>}
          <FlexeButton
            testid="cancel-remaining-button-bottom"
            level="primary"
            text="Cancel Remaining"
            isDisabled={message === ''}
            handleClick={cancelRemaining}
          />
        </React.Fragment>
      }
      disableClose={false}
    >
      <div>
        <h4>Cancel the remaining kits and complete the work order?</h4>

        <p>Message:</p>
        <textarea
          data-testid="cancel-remaining-textarea"
          className="message-text"
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Please provide a brief message about the cancellation..."
        />
      </div>
    </LegacyModal>
  );
};

export default CancelRemainingModal;
