import * as React from 'react';
import {useRef, useState} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import DropDown, {DropDownOption, DropDownStyle} from '../../../shared/DropDown';
import FileStorageService from '../../file-storage/FileStorageService';
import OutboundOrdersService from '../OutboundOrdersService';
import {LabelList} from '../helpers/Labels';
import {
  AttachmentTypeOptionInsert,
  AttachmentTypeOptionMiscellaneousLabel,
  AttachmentTypeOptionShippingLabel,
  generateFileKey,
  getAttachmentTypeOptions
} from '../helpers/Attachments';

interface Props {
  fileStorageService: FileStorageService;
  outboundOrdersService: OutboundOrdersService;
  orderId: string;
  companyId: number;
  adminSignedIn: boolean;
  orderLabels: LabelList;
  toggleCreateAttachmentModal();
  reloadAttachments();
}

export const CreateAttachmentModal: React.FC<Props> = (props) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  // Default to a value that is allowed by all order types
  const [selectedAttachmentType, setSelectedAttachmentType] = useState(
    AttachmentTypeOptionMiscellaneousLabel.value as string
  );
  const [attachmentName, setAttachmentName] = useState('');
  const [errors, setErrors] = useState(null);
  const [externalId, setExternalId] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const submitButtonRef = useRef(null);

  const attachmentTypeOptions = getAttachmentTypeOptions(props.orderLabels);

  async function submitNewAttachment(event) {
    event.preventDefault();
    if (submitButtonRef.current) {
      submitButtonRef.current.setAttribute('disabled', 'disabled');
    }
    setSubmitLoading(true);
    if (
      !uploadedFile ||
      !attachmentName ||
      (selectedAttachmentType === AttachmentTypeOptionShippingLabel.value && !trackingNumber)
    ) {
      const requiredFieldErrors = ['Missing required fields'];
      setErrors(requiredFieldErrors);
    } else {
      const fileKey = generateFileKey(attachmentName);
      const uploadFileResponse = await props.fileStorageService.uploadFile(uploadedFile, fileKey);
      if (uploadFileResponse.status === 200) {
        const createAttachmentRequest = {
          type: selectedAttachmentType,
          fileGroup: selectedAttachmentType === AttachmentTypeOptionInsert.value ? 'Inserts' : null,
          fileKey,
          documentType: 'file',
          externalId: externalId.trim() || null,
          displayName: attachmentName.trim() || null,
          trackingNumber: trackingNumber.trim() || null
        };
        const createAttachmentResponse = await props.outboundOrdersService.createOrderAttachment(
          props.orderId,
          createAttachmentRequest
        );
        if (createAttachmentResponse.status === 201) {
          setErrors(null);
          props.reloadAttachments();
          props.toggleCreateAttachmentModal();
        } else {
          let createAttachmentErrors = [];
          if (
            createAttachmentResponse.data &&
            createAttachmentResponse.data.errors &&
            createAttachmentResponse.data.errors.length > 0
          ) {
            createAttachmentErrors = createAttachmentResponse.data.errors.map((e) => e.detail);
          } else {
            const unexpectedError = `Unexpected API error when creating attachment for ${fileKey}`;
            createAttachmentErrors.push(unexpectedError);
          }
          setErrors(createAttachmentErrors);
        }
      } else {
        let uploadFileErrors = [];
        if (uploadFileResponse.data && uploadFileResponse.data.errors && uploadFileResponse.data.errors.length > 0) {
          uploadFileErrors = uploadFileResponse.data.errors.map((e) => e.detail);
        } else {
          const unexpectedError = `Unexpected API error when uploading file ${fileKey}`;
          uploadFileErrors.push(unexpectedError);
        }
        setErrors(uploadFileErrors);
      }
    }
    if (submitButtonRef.current) {
      submitButtonRef.current.removeAttribute('disabled');
    }
    setSubmitLoading(false);
  }

  function getSelectedAttachmentType() {
    return attachmentTypeOptions.find((o) => o.value === selectedAttachmentType);
  }

  function setSelected(option: DropDownOption) {
    setSelectedAttachmentType(option.value as string);
  }

  return (
    <LegacyModal
      id="create-attachment-modal"
      title="New Attachment"
      show={true}
      size="small"
      toggleModal={props.toggleCreateAttachmentModal}
      disableClose={false}
    >
      <form>
        {errors && errors.length > 0 && (
          <div className="alert alert-danger space-above space-below" role="alert">
            <ul>
              {errors.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          </div>
        )}
        <div className="form-row space-below">
          <label htmlFor="attachment">Add attachment</label>
          <span className="clarify">(Supported file types: .pdf, .png)</span>
          <input
            name="attachment"
            type="file"
            accept="image/png,application/pdf"
            onChange={(e) => setUploadedFile(e.target.files[0])}
          />
        </div>

        <div className="form-row space-above space-below">
          <label htmlFor="fileType">Type</label>
          <DropDown
            style={DropDownStyle.default}
            options={attachmentTypeOptions}
            selected={getSelectedAttachmentType()}
            onSelect={setSelected}
          />
        </div>

        <div className="form-row space-above space-below">
          <label htmlFor="name">Name</label>
          <span className="required">*</span>
          <input type="text" value={attachmentName} name="name" onChange={(e) => setAttachmentName(e.target.value)} />
        </div>

        <div className="form-row space-above space-below">
          <label htmlFor="external-id">Unique ID</label>
          <span>(Optional)</span>
          <input type="text" value={externalId} name="external-id" onChange={(e) => setExternalId(e.target.value)} />
        </div>

        {selectedAttachmentType === AttachmentTypeOptionShippingLabel.value && (
          <div className="form-row space-above space-below">
            <label htmlFor="tracking-number">Tracking number</label>
            <span className="required">*</span>
            <input
              type="text"
              value={trackingNumber}
              name="tracking-number"
              onChange={(e) => setTrackingNumber(e.target.value)}
            />
          </div>
        )}

        <div className="pull-right space-above">
          {submitLoading && <i className="fas fa-spinner fa-spin" />}
          <button className="btn" onClick={submitNewAttachment} ref={submitButtonRef}>
            Save
          </button>
        </div>
      </form>
    </LegacyModal>
  );
};
