import * as React from 'react';
import {ParseResult} from 'papaparse';
import {UploadParams} from '../edi-files/EdiFilesInterfaces';
import CsvValidator from '../../../libs/CsvValidator';
import CsvBulkUploadModalOMSV2, {UploadHandlerCallback} from '../outbound-orders/generic/CsvBulkUploadModalOMSV2';
import {GenericErrorDisplay} from '../../shared/GenericErrorDisplay';
import {AvailableInventory, InstructionsTableHeaders, InstructionsTableRows, Line} from './InventorySelection';
import {csvFormatToModalHeaders, parseOnComplete} from './InventoryCSVUploadHelpers';

/* eslint-disable-next-line */
const Papa = require('papaparse');

interface Props {
  title: string;
  actionDescription: string;
  showModal: boolean;
  templatePath: string;
  errors: any;
  clearAlerts: any;
  setValidLinesAndErrors: (validLines: Line[], lineErrors: string[]) => any;
  reservationInventory: AvailableInventory[];
  toggleModal(e: any): void;
}

const InventoryCSVUploadV3: React.FC<Props> = (props) => {
  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  const handleUpload = (params: UploadParams, _: string, callback: UploadHandlerCallback) => {
    if (!params.file) {
      callback([
        {
          header:
            'File could not be accessed. Ensure one is selected and try again. Contact Flexe if this problem persists'
        }
      ]);
      return;
    }

    setIsUploading(true);
    const validator = new CsvValidator();
    const setErrorsOnModal = (errorStrings: string[]) => {
      const errors: GenericErrorDisplay[] = errorStrings.map((str) => ({header: str}));
      callback(errors);
    };

    Papa.parse(params.file as File, {
      header: true,
      skipEmptyLines: 'greedy',
      complete: (results: ParseResult) =>
        parseOnComplete(results, validator, props.reservationInventory, props.setValidLinesAndErrors, setErrorsOnModal)
    });
    setIsUploading(false);
  };

  const headers = csvFormatToModalHeaders();
  return (
    <CsvBulkUploadModalOMSV2
      id="inventory-csv-upload-modal-v3"
      title={props.title}
      errors={props.errors}
      actionDescription={props.actionDescription}
      show={props.showModal}
      templatePath={props.templatePath}
      toggleModal={props.toggleModal}
      instructionsTableHeaders={InstructionsTableHeaders}
      instructionsTableRows={InstructionsTableRows}
      uploading={isUploading}
      handleUpload={handleUpload}
      clearAlerts={props.clearAlerts}
      headers={headers}
      fileUploadType="inventory_lines" // This is arbitrary since we're not using backwards compatibility
    />
  );
};

export default InventoryCSVUploadV3;
