import {CsvErrorType} from '../shared/csv-upload/CsvUploadInterfaces';
import CsvUtil from '../shared/utilities/CsvUtil';
import PickupRequest from './PickupRequest';

class ValidatedPickupRequest implements PickupRequest {
  public data;
  public errors;

  constructor(data) {
    this.data = {
      moveDate: data['Shipment Date'] ? data['Shipment Date'].trim() : null,
      shipmentNumber: data['Shipment Number'] ? data['Shipment Number'].trim() : null,
      trailerNumber: data['Trailer Number'] ? data['Trailer Number'].trim() : null,
      shipmentDescription: data['Shipment Description'] ? data['Shipment Description'].trim() : null,
      itemCode: data.SKU ? data.SKU.trim() : null,
      cartonQty: data['Carton Qty'] ? data['Carton Qty'].trim() : null
    };
    this.errors = {};

    this.validateRequired();

    const {parsedMoveDate, moveDateError} = CsvUtil.validateDateTimes(this.data);

    this.data.moveDate = parsedMoveDate;
    if (moveDateError) {
      this.errors.moveDate = moveDateError;
    }
  }

  private validateRequired() {
    const requiredFields = ['moveDate', 'shipmentNumber', 'itemCode', 'cartonQty'];
    for (const key in this.data) {
      if (requiredFields.includes(key) && !this.data[key]) {
        this.errors[key] = CsvErrorType.Required;
      }
    }
  }
}

export default ValidatedPickupRequest;
