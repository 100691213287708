import * as React from 'react';
import FormField from './FormField';
import {AddressData, ShipToAddressErrors} from './CommonInterfaces';

interface Props {
  addresses: AddressData[];
  formErrors: ShipToAddressErrors;
  addressId?: number;
  setAddressId(reservationId: number);
  onSaveAddress(address: AddressData);
  onCancelAddress();
}

interface State {
  shipToAddress?: AddressData;
  shipToName: string;
}

class AddressSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shipToAddress: this.getAddressById(props.addressId),
      shipToName: ''
    };
  }

  public render() {
    let shipToAddressContent;
    let hideAddressSearch = false;

    if (this.props.addressId) {
      if (this.state.shipToAddress) {
        const {name, line1, line2, locality, region, postcode, country, phone} = this.state.shipToAddress;
        shipToAddressContent = (
          <div className="address-select saved-address">
            <address>
              <b>Name:</b> {name}
              <br />
              <b>Address Line 1:</b> {line1}
              <br />
              {line2 && (
                <span>
                  <b>Address Line 2:</b> {line2}
                  <br />
                </span>
              )}
              <b>City:</b> {locality}
              <br />
              <b>State:</b> {region}
              <br />
              <b>Zip / Postal Code:</b> {postcode}
              <br />
              <b>Country:</b> {country}
              <br />
              {phone && (
                <span>
                  <b>Phone:</b> {phone}
                </span>
              )}
            </address>
            <a href="#" id="use_different_address" onClick={this.createNewAddress}>
              + Use a different address
            </a>
          </div>
        );
      }
    } else {
      if (this.state.shipToAddress) {
        hideAddressSearch = true;
        shipToAddressContent = (
          <div className="address-select new-address">
            <h5>New Address</h5>

            <div className="row">
              <div className="col-md-12">
                <FormField
                  type="text"
                  label="Name"
                  placeholder="ex: John Doe"
                  required={true}
                  id="name"
                  name="name"
                  value={this.state.shipToAddress.name}
                  error={this.props.formErrors.name}
                  onChange={this.onChangeAddress}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <FormField
                  type="text"
                  label="Address Line 1"
                  placeholder="ex: 1234 5th Avenue"
                  required={true}
                  id="line1"
                  name="line1"
                  value={this.state.shipToAddress.line1}
                  error={this.props.formErrors.line1}
                  onChange={this.onChangeAddress}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <FormField
                  type="text"
                  label="Address Line 2"
                  placeholder="ex: Suite 42"
                  required={false}
                  id="line2"
                  name="line2"
                  value={this.state.shipToAddress.line2}
                  onChange={this.onChangeAddress}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <FormField
                  type="text"
                  label="City"
                  placeholder="ex: Los Angeles"
                  required={true}
                  id="locality"
                  name="locality"
                  value={this.state.shipToAddress.locality}
                  error={this.props.formErrors.locality}
                  onChange={this.onChangeAddress}
                />
              </div>
              <div className="col-md-3">
                <FormField
                  type="text"
                  label="State"
                  placeholder="ex: California"
                  required={true}
                  id="region"
                  name="region"
                  value={this.state.shipToAddress.region}
                  error={this.props.formErrors.region}
                  onChange={this.onChangeAddress}
                />
              </div>
              <div className="col-md-3">
                <FormField
                  type="text"
                  label="Postal Code"
                  placeholder="ex: 90028"
                  required={true}
                  name="postcode"
                  id="postcode"
                  value={this.state.shipToAddress.postcode}
                  error={this.props.formErrors.postcode}
                  onChange={this.onChangeAddress}
                />
              </div>
              <div className="col-md-3">
                <FormField
                  type="text"
                  placeholder="ex: US"
                  label="Country"
                  required={true}
                  maxLength={2}
                  id="country"
                  name="country"
                  value={this.state.shipToAddress.country}
                  error={this.props.formErrors.country}
                  onChange={this.onChangeAddress}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <FormField
                  type="tel"
                  placeholder="ex: (206) 123-4567"
                  label="Phone"
                  required={false}
                  id="phone"
                  name="phone"
                  value={this.state.shipToAddress.phone}
                  error={this.props.formErrors.phone}
                  onChange={this.onChangeAddress}
                />
              </div>
            </div>

            <div className="row buttons">
              <div className="col-md-12">
                <a href="#" className="btn" onClick={this.cancelCreateNewAddress}>
                  Cancel
                </a>
                <a href="#" className="btn" onClick={this.saveNewAddress}>
                  Save
                </a>
              </div>
            </div>
          </div>
        );
      } else if (this.state.shipToName) {
        shipToAddressContent = (
          <div className="no-address-found">
            <span>No Address Found</span>|
            <a href="#" id="add_an_address" onClick={this.createNewAddress}>
              + Add an address
            </a>
          </div>
        );
      }
    }

    return (
      <div>
        {!hideAddressSearch && (
          <div className="row">
            <div className="col-md-12">
              <div className="form-group required">
                <input
                  placeholder="Enter a contact or warehouse name..."
                  className="form-control"
                  required
                  type="text"
                  id="ship_to_name"
                  name="shipToName"
                  list="shipToNameList"
                  value={this.state.shipToName}
                  onChange={this.handleShipToChange}
                />
                <datalist id="shipToNameList">
                  {this.props.addresses.map((address) => {
                    const label = this.generateFullAddress(address);
                    return (
                      <option key={address.id} label={label}>
                        {address.id}
                      </option>
                    );
                  })}
                </datalist>
              </div>
            </div>
          </div>
        )}
        {shipToAddressContent}
      </div>
    );
  }

  private handleShipToChange = (event) => {
    const isSelectEvent =
      typeof event.nativeEvent.detail === 'undefined' || typeof event.nativeEvent.which === 'undefined';
    const selectedAddressId = parseInt(event.target.value, 10);
    let shipToName = event.target.value;
    let shipToAddress = null;
    let addressId = null;

    if (isSelectEvent && selectedAddressId) {
      shipToAddress = this.getAddressById(selectedAddressId);

      if (shipToAddress) {
        addressId = shipToAddress.id;
        shipToName = '';
      }
    }

    this.setState({shipToName, shipToAddress});
    this.props.setAddressId(addressId);
  };

  private onChangeAddress = (event) => {
    const shipToAddress = {...this.state.shipToAddress};
    const name = event.currentTarget.name || event.target.name;
    const value = event.currentTarget.value || event.target.value;
    shipToAddress[name] = value;
    this.setState({shipToAddress});
  };

  private createNewAddress = (event) => {
    event.preventDefault();
    this.props.setAddressId(null);
    this.setState({
      shipToName: '',
      shipToAddress: {
        id: null,
        name: '',
        line1: '',
        line2: '',
        locality: '',
        postcode: '',
        region: '',
        country: '',
        phone: ''
      }
    });
  };

  private cancelCreateNewAddress = (event) => {
    event.preventDefault();
    this.setState({shipToName: '', shipToAddress: null});
    this.props.setAddressId(null);
    this.props.onCancelAddress();
  };

  private saveNewAddress = (event) => {
    event.preventDefault();
    this.props.onSaveAddress(this.state.shipToAddress);
  };

  private generateFullAddress(address: ShipToAddressErrors) {
    const {name, line1, line2, locality, region, postcode, phone} = address;
    const line2text = line2 ? `${line2}, ` : '';
    const phoneText = phone ? `, ${phone}` : '';
    return `${name}, ${line1}, ${line2text}${locality}, ${region}, ${postcode}${phoneText}`;
  }

  private getAddressById(addressId: number) {
    return this.props.addresses.find((addr) => {
      return addr.id === addressId;
    });
  }
}

export default AddressSelect;
