import * as React from 'react';
import {Loader} from '@flexe/ui-components';
import {cloneDeep} from 'lodash';
import {Filter} from '../CommonInterfaces';
import WarehouseService from '../services/WarehouseService';
import {Reservation} from '../../shipper/outbound-orders/ReservationsInterfaces';
import {ShipmentPackaging, ShipmentPackagingField, ShipmentPackagingServiceInterface} from './Interfaces';
import ShipmentPackagingList from './ShipmentPackagingList';
import ShipmentPackagingDeleteDialog from './ShipmentPackagingDeleteDialog';
import ShipmentPackagingDialog, {RequestType} from './ShipmentPackagingDialog';
import {MAX_ONERATE_VOLUMES_INCHES} from './ShipmentPackagingService';

const EMPTY_PACKAGING: ShipmentPackaging = {
  id: undefined,
  height: 0,
  length: 0,
  width: 0,
  boxType: 'box',
  weight: 0,
  description: '',
  barcode: ''
};
interface Props {
  shipmentPackagingService: ShipmentPackagingServiceInterface;
  warehouseService: WarehouseService;
  isShipper: boolean;
}

interface State {
  shipmentPackagings: ShipmentPackaging[];
  errors: string[];
  warnings: string[];
  loading: boolean;
  showCreate: boolean;
  showUpdate: boolean;
  showDelete: boolean;
  selectedPackaging: ShipmentPackaging;
  reservations: Reservation[];
}

enum types {
  LOADED,
  SHOW_CREATE,
  HIDE_CREATE,
  ERROR,
  WARNING,
  CLEAR_WARNING,
  SHOW_UPDATE,
  HIDE_UPDATE,
  FIELD_CHANGE,
  SHOW_DELETE,
  HIDE_DELETE
}

type Action =
  | {type: types.LOADED; overboxes: ShipmentPackaging[]; reservations: Reservation[]}
  | {type: types.ERROR; errors: string[]}
  | {type: types.WARNING; warnings: string[]}
  | {type: types.CLEAR_WARNING}
  | {type: types.SHOW_CREATE}
  | {type: types.HIDE_CREATE}
  | {type: types.SHOW_UPDATE; result: ShipmentPackaging}
  | {type: types.HIDE_UPDATE}
  | {type: types.FIELD_CHANGE; result: ShipmentPackaging}
  | {type: types.SHOW_DELETE; result: ShipmentPackaging}
  | {type: types.HIDE_DELETE};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case types.LOADED: {
      return {
        ...state,
        loading: false,
        shipmentPackagings: action.overboxes,
        reservations: action.reservations
      };
    }
    case types.ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.errors
      };
    }
    case types.WARNING: {
      return {
        ...state,
        warnings: action.warnings
      };
    }
    case types.CLEAR_WARNING: {
      return {
        ...state,
        warnings: []
      };
    }
    case types.SHOW_CREATE: {
      return {
        ...state,
        showCreate: true,
        selectedPackaging: EMPTY_PACKAGING
      };
    }
    case types.HIDE_CREATE: {
      return {
        ...state,
        showCreate: false,
        warnings: [],
        selectedPackaging: null
      };
    }
    case types.SHOW_UPDATE: {
      return {
        ...state,
        showUpdate: true,
        selectedPackaging: action.result
      };
    }
    case types.HIDE_UPDATE: {
      return {
        ...state,
        showUpdate: false,
        warnings: [],
        selectedPackaging: null
      };
    }
    case types.FIELD_CHANGE: {
      return {
        ...state,
        selectedPackaging: action.result
      };
    }
    case types.SHOW_DELETE: {
      return {
        ...state,
        showDelete: true,
        selectedPackaging: action.result
      };
    }
    case types.HIDE_DELETE: {
      return {
        ...state,
        showDelete: false,
        warnings: [],
        selectedPackaging: null
      };
    }
  }
}

const ShipmentPackagings: React.FC<Props> = (props) => {
  const [state, dispatch] = React.useReducer(reducer, {
    shipmentPackagings: [],
    errors: [],
    warnings: [],
    loading: true,
    showCreate: false,
    showUpdate: false,
    showDelete: false,
    selectedPackaging: null,
    reservations: []
  });

  const showError = (errors: string[]) => {
    dispatch({
      type: types.ERROR,
      errors
    });
  };

  const fetchData = async (filters?: Filter[]) => {
    const [{shipmentPackagings, errors}, reservationResult] = await Promise.all([
      props.shipmentPackagingService.getShipmentPackagings(filters),
      props.warehouseService.getReservations()
    ]);
    if (Array.isArray(errors) && errors.length !== 0) {
      showError(errors);
    } else {
      let reservations: Reservation[] = [];
      if (reservationResult && reservationResult.data && reservationResult.data.reservations) {
        reservations = reservationResult.data.reservations;
      }
      dispatch({
        type: types.LOADED,
        overboxes: shipmentPackagings,
        reservations
      });
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    // Every time we change dimensions on the overbox that is being edited, check the new volume.
    // If the overbox is too large to be used in FedEx OneRate shipping, display a warning, but don't
    // block any user action.
    const overbox = state.selectedPackaging ? state.selectedPackaging : EMPTY_PACKAGING;
    const {length, width, height} = overbox;
    const volume = length * width * height;
    if (volume > MAX_ONERATE_VOLUMES_INCHES[overbox.boxType]) {
      dispatch({
        type: types.WARNING,
        warnings: [
          `${overbox.boxType} too big for FedEx OneRate: (current volume: ${volume}, max OneRate volume for ${
            overbox.boxType
          }: ${MAX_ONERATE_VOLUMES_INCHES[overbox.boxType]})`
        ]
      });
    } else {
      dispatch({type: types.CLEAR_WARNING});
    }
  }, [state.selectedPackaging]);

  const showCreateDialog = () => {
    dispatch({
      type: types.SHOW_CREATE
    });
  };

  const hideCreateDialog = () => {
    dispatch({
      type: types.HIDE_CREATE
    });
  };

  const handleCreatePackaging = async () => {
    const createData = async () => {
      const {errors} = await props.shipmentPackagingService.createPackaging(state.selectedPackaging);
      hideCreateDialog();
      if (Array.isArray && errors.length !== 0) {
        showError(errors);
      } else {
        fetchData();
      }
    };
    createData();
  };

  const showUpdateDialog = (id) => {
    const defaultPkg = state.shipmentPackagings.find((box) => box.id === id);
    dispatch({
      type: types.SHOW_UPDATE,
      result: defaultPkg
    });
  };

  const hideUpdateDialog = () => {
    dispatch({
      type: types.HIDE_UPDATE
    });
  };

  const handleSelectedOverboxFieldChange = (fieldName: ShipmentPackagingField, newValue: string) => {
    dispatch({
      type: types.FIELD_CHANGE,
      result: {
        ...state.selectedPackaging,
        [fieldName]: newValue
      }
    });
  };

  const handleUpdatePackaging = async () => {
    const updateData = async () => {
      const {errors} = await props.shipmentPackagingService.updatePackaging(state.selectedPackaging);
      hideUpdateDialog();
      if (Array.isArray && errors.length !== 0) {
        showError(errors);
      } else {
        fetchData();
      }
    };
    updateData();
  };

  const showDeleteDialog = (id) => {
    const defaultPkg = state.shipmentPackagings.find((box) => box.id === id);
    dispatch({
      type: types.SHOW_DELETE,
      result: defaultPkg
    });
  };

  const hideDeleteDialog = () => {
    dispatch({
      type: types.HIDE_DELETE
    });
  };

  const handleDeletePackaging = async () => {
    const deleteData = async () => {
      const {errors} = await props.shipmentPackagingService.deletePackaging(state.selectedPackaging);
      hideDeleteDialog();
      if (Array.isArray && errors.length !== 0) {
        showError(errors);
      } else {
        fetchData();
      }
    };
    deleteData();
  };

  const listProps = {
    handleUpdate: (id) => showUpdateDialog(id),
    handleDelete: (id) => showDeleteDialog(id),
    handleFetchPackagings: (filters) => fetchData(filters),
    shipmentPackagings: state.shipmentPackagings,
    isShipper: props.isShipper,
    reservations: state.reservations,
    filters: {}
  };

  return (
    <div>
      {state.loading ? (
        <Loader loading={true} />
      ) : (
        <React.Fragment>
          {state.errors.length > 0 && (
            <div className="alert alert-danger" role="alert">
              {state.errors.map((e, i) => (
                <span key={i}>{e}</span>
              ))}
            </div>
          )}
          <div className="row space-below-lg space-above-lg">
            <h2 className="col-md-6">Shipping Packages</h2>
            <div className="col-md-6">
              <a className="default-overbox-list__create btn pull-right" onClick={showCreateDialog}>
                <i className="fa fa-plus"></i>&nbsp; New Shipping Package
              </a>
            </div>
          </div>
          <ShipmentPackagingList {...listProps} />
          <div>
            <ShipmentPackagingDialog
              action={RequestType.CREATE}
              show={state.showCreate}
              hideModal={hideCreateDialog}
              handleSubmit={handleCreatePackaging}
              handleShipmentPackagingFieldChange={handleSelectedOverboxFieldChange}
              shipmentPackaging={state.selectedPackaging}
              warnings={state.warnings}
              reservations={state.reservations}
            />
            <ShipmentPackagingDialog
              action={RequestType.UPDATE}
              show={state.showUpdate}
              hideModal={hideUpdateDialog}
              handleShipmentPackagingFieldChange={handleSelectedOverboxFieldChange}
              handleSubmit={handleUpdatePackaging}
              shipmentPackaging={state.selectedPackaging}
              warnings={state.warnings}
              reservations={state.reservations}
            />
            <ShipmentPackagingDeleteDialog
              show={state.showDelete}
              hideModal={hideDeleteDialog}
              shipmentPackaging={state.selectedPackaging}
              handleSubmit={handleDeletePackaging}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ShipmentPackagings;
