import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {FilterOption, Inventory} from '../CommonInterfaces';
import {LocationContents} from '../../locations/LocationsService';
import {CycleCountType, ReservationFilterOption} from './CycleCountInterfaces';

interface Props {
  showCreateModal: boolean;
  cycleCountType: CycleCountType | string;
  creatingCycleCount: boolean;
  selectedReservation: ReservationFilterOption;
  selectedInventories: Inventory[];
  selectedLocations: LocationContents[];
  selectedWarehouse: FilterOption;
  countAllLocations: boolean;
  toggleCreateModal: (event: any) => Promise<void>;
  handleCreate: (event: any) => Promise<void>;
}

const CycleCountCreateModal: FunctionComponent<Props> = (props) => {
  return (
    <LegacyModal
      id="create-cycle-count-modal"
      title={`Create Cycle Count${props.countAllLocations ? ': All Locations' : ''}`}
      show={props.showCreateModal}
      size="small"
      toggleModal={props.toggleCreateModal}
      footer={
        <div>
          <a onClick={props.toggleCreateModal}>No, cancel</a>
          <button
            id="create-agree"
            type="button"
            className="btn cta"
            disabled={props.creatingCycleCount}
            onClick={props.handleCreate}
          >
            Yes, create
          </button>
        </div>
      }
      disableClose={false}
    >
      {props.cycleCountType === CycleCountType.item && props.selectedReservation ? (
        <div>
          <p>Are you sure you want to create this cycle count?</p>
          <dl>
            <dt>Reservation:</dt>
            <dd>
              {props.selectedReservation.displayName}
              <br />
            </dd>
          </dl>
          <dl>
            <dt>{props.selectedInventories.length} SKUs:</dt>
            <dd>
              <ul className="no-padding">
                {props.selectedInventories.map((s, i) => (
                  <li key={i}>{s.sku}</li>
                ))}
              </ul>
            </dd>
          </dl>
        </div>
      ) : (
        <div>
          <p>Are you sure you want to create this cycle count?</p>
          <dl>
            <dt>Warehouse:</dt>
            <dd>
              {props.selectedWarehouse.displayName}
              <br />
            </dd>
          </dl>
          <dl>
            <dt>{props.selectedLocations.length} Locations:</dt>
            <dd>
              <ul className="no-padding">
                {props.selectedLocations.map((s, i) => (
                  <li key={i}>{s.label}</li>
                ))}
              </ul>
            </dd>
          </dl>
        </div>
      )}
    </LegacyModal>
  );
};

export default CycleCountCreateModal;
