import {OrderInventory} from '../../ecommerce-batches/BatchInterfaces';

export interface MappedUnits {
  [key: string]: MappedUnit;
}

export interface MappedUnit {
  unit: string;
  baseUnit: string;
}

export const UnitNameMap: MappedUnits = {
  lb: {
    unit: 'lb',
    baseUnit: 'lb'
  },
  pound: {
    unit: 'lb',
    baseUnit: 'lb'
  },
  ounce: {
    unit: 'oz',
    baseUnit: 'lb'
  },
  oz: {
    unit: 'oz',
    baseUnit: 'lb'
  },
  foot: {
    unit: 'ft',
    baseUnit: 'in'
  },
  ft: {
    unit: 'ft',
    baseUnit: 'in'
  },
  inch: {
    unit: 'in',
    baseUnit: 'in'
  },
  in: {
    unit: 'in',
    baseUnit: 'in'
  },
  meter: {
    unit: 'm',
    baseUnit: 'cm'
  },
  m: {
    unit: 'm',
    baseUnit: 'cm'
  },
  centimeter: {
    unit: 'cm',
    baseUnit: 'cm'
  },
  cm: {
    unit: 'cm',
    baseUnit: 'cm'
  },
  millimeter: {
    unit: 'mm',
    baseUnit: 'cm'
  },
  mm: {
    unit: 'mm',
    baseUnit: 'cm'
  },
  kilogram: {
    unit: 'kg',
    baseUnit: 'kg'
  },
  kg: {
    unit: 'kg',
    baseUnit: 'kg'
  },
  gram: {
    unit: 'g',
    baseUnit: 'kg'
  },
  g: {
    unit: 'g',
    baseUnit: 'kg'
  }
};

export enum UnitType {
  METRIC = 'metric',
  STANDARD = 'standard'
}

export interface InventoryDimensionResponse {
  dimensions: SkuInventoryMap[];
}

export interface SkuInventoryMap {
  [sku: string]: InventoryDimensions;
}

export interface InventoryDimensions {
  each?: Dimensions;
  carton?: Dimensions;
  pallet?: Dimensions;
}

export interface Dimensions {
  weight: Dimension;
  length: Dimension;
  height: Dimension;
  width: Dimension;
}

export interface Dimension {
  amount: number;
  unit: string;
}

export interface ShipmentSplit {
  dimensions: Dimensions;
  items: OrderInventory[];
}

export interface SplitResponse {
  orderIds: number[];
}
