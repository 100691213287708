import * as React from 'react';
import {format} from 'date-fns';
import {cloneDeep, get, set} from 'lodash';
import {Link} from 'react-router-dom';
import {Loader} from '@flexe/ui-components';
import {
  displayPackaging,
  processErrorResponse,
  processErrorsInSuccessResponse,
  renderItemLink,
  renderLpnLink
} from '../../libs/helpers';
import ErrorDisplay from '../shared/ErrorDisplay';
import {LengthUnit, Measurement, Warehouse} from '../shared/CommonInterfaces';
import {dateFormat, time24hourFormat} from '../shared/constants';
import WarehouseService from '../shared/services/WarehouseService';
import ItemMasterService from '../shared/services/ItemMasterService';
import InventoryService from '../shared/services/InventoryService';
import {
  LocationCategoryEditableCategories,
  LocationCategoryStrings,
  LocationContentEntityType,
  LocationEntityType,
  LocationPrimaryPickLocation,
  LocationStatus,
  SYSTEM_RESERVED_CATEGORIES
} from './LocationsInterfaces';
import LocationsService, {LocationContent, LocationContents} from './LocationsService';
import {LocationContentsTable} from './LocationContentsTable';
import {LocationsHelper} from './LocationsHelper';
import FocusedMovementLogTable from './FocusedMovementLogTable';

interface Props {
  authenticityToken: string;
  match?: {
    params: {
      id: number;
    };
  };
  selectedWarehouse: Warehouse;
  enableZones: boolean;
  isNewTableEnabled: boolean;
}

interface State {
  editMode: boolean;
  locationLoading: boolean;
  location: LocationContents;
  editedData: any;
  formErrors: any;
  errorMessage?: string;
  successMessage?: string;
  directedPutAwayEnabled?: boolean;
  crossdockEnabled?: boolean;
}

const DIRECTED_PUT_AWAY_FEATURE_FLAG = 'directed-putaway';

class LocationsDetail extends React.Component<Props, State> {
  private locationsService: LocationsService;
  private warehouseService: WarehouseService;
  private itemMasterService: ItemMasterService;
  private inventoryService: InventoryService;
  private locationId: number;

  constructor(props: Props) {
    super(props);

    this.locationsService = new LocationsService(props.authenticityToken);
    this.warehouseService = new WarehouseService(props.authenticityToken);
    this.itemMasterService = new ItemMasterService();
    this.inventoryService = new InventoryService(props.authenticityToken);
    this.locationId = this.props.match.params.id;

    this.state = {
      editMode: false,
      locationLoading: false,
      location: {
        id: null,
        label: '',
        category: '',
        allocatable: null,
        state: null,
        barcodeText: '',
        createdAt: null,
        updatedAt: null,
        arrivalTime: null,
        warehouse: {
          type: null,
          id: null
        },
        sequenceId: null,
        palletCapacity: null,
        primaryPickLocation: null,
        putAwayZone: null,
        associatedEntity: {
          type: null,
          id: null
        },
        dimensions: {
          height: {
            amount: '',
            unit: null
          },
          width: {
            amount: '',
            unit: null
          },
          length: {
            amount: '',
            unit: null
          }
        },
        isDefaultLocation: null,
        lockInfo: null,
        type: null,
        contents: []
      },
      editedData: {},
      formErrors: {},
      errorMessage: null,
      successMessage: null,
      directedPutAwayEnabled: false,
      crossdockEnabled: false
    };
  }

  public async componentDidMount() {
    await this.getLocation(this.props.selectedWarehouse.id);
    await this.loadDirectedPutAwayFeatureFlag();
    await this.checkIfCrossdockEnabled();
  }

  public render() {
    return (
      <div className="locations-detail">
        {this.state.locationLoading ? (
          <Loader loading={true} />
        ) : (
          <div>
            <ErrorDisplay errorText={this.state.errorMessage} />
            {this.state.successMessage && (
              <div className="alert alert-success">
                <div className="content">{this.state.successMessage}</div>
              </div>
            )}
            {this.renderDetails()}
            <div className="container-fluid">
              {this.props.isNewTableEnabled ? (
                <LocationContentsTable
                  locationId={this.locationId}
                  itemMasterService={this.itemMasterService}
                  inventoryService={this.inventoryService}
                />
              ) : (
                this.renderLegacyContents()
              )}
            </div>
            <FocusedMovementLogTable
              warehouseId={this.props.selectedWarehouse.id}
              locationId={this.locationId}
              authenticityToken={this.props.authenticityToken}
              locationService={this.locationsService}
            />
          </div>
        )}
      </div>
    );
  }

  // Note the use of 'loc-label'. There was a conflict with the css somewhere when I added 'label'.
  private renderDetails() {
    const {editMode} = this.state;
    const {
      label,
      category,
      allocatable,
      barcodeText,
      createdAt,
      updatedAt,
      arrivalTime,
      warehouse,
      state,
      lockInfo
    } = this.state.location;
    const {height, width, length} = this.state.location.dimensions;
    const editedData = this.state.editedData || {};
    const formErrors = this.state.formErrors.locationData || {};
    return (
      <div id="topline-details" className={`container-fluid${editMode ? ' edit-mode' : ''}`}>
        <div className="row">
          <div className="col-sm-6 space-below">
            <div className="breadcrumbs">
              <Link to="/wh/locations">Locations</Link>
              <i className="fa fa-angle-right"></i>
              Location Detail
            </div>
          </div>
        </div>

        <div className="row">
          <h2 className="col-sm-8 space-below">{label}</h2>
          <div className="col-sm-4 action-buttons">
            {!SYSTEM_RESERVED_CATEGORIES.includes(this.state.location.category) &&
              this.state.location.category !== LocationCategoryStrings.CATEGORY_MHE && (
                <a href="#" className="btn edit-btn" onClick={this.toggleEditMode}>
                  {this.state.editMode ? 'Cancel' : 'Edit'}
                </a>
              )}
            {this.state.editMode && (
              <a href="#" className="btn update-btn" onClick={this.handleUpdateLocation}>
                Update Location
              </a>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 thirds">
            <h4 className="text-uppercase">Details</h4>
            <dl className="dl-horizontal">
              <dt>Location Name:</dt>
              <dd className="loc-label">
                {editMode ? (
                  <div className={`form-group form-inline${formErrors.label ? ' has-error' : ''}`}>
                    <input
                      className="form-control"
                      type="text"
                      name="label"
                      value={editedData.label || label || ''}
                      onChange={this.updateLocationState}
                    />
                    {formErrors.label && <span className="help-block">{formErrors.label}</span>}
                  </div>
                ) : (
                  label
                )}
              </dd>

              <dt>Category:</dt>
              <dd className="category">
                {editMode && !SYSTEM_RESERVED_CATEGORIES.includes(category) ? (
                  <div className={`form-group form-inline${formErrors.category ? ' has-error' : ''}`}>
                    <select
                      className="form-control"
                      name="category"
                      value={editedData.category || category || ''}
                      onChange={this.updateLocationState}
                    >
                      <option value="">Select a Category</option>
                      {LocationsHelper.FilterLocationCategoriesEditableCategories(this.state.crossdockEnabled).map(
                        (catKey) => {
                          return (
                            <option key={catKey} value={catKey}>
                              {LocationCategoryEditableCategories[catKey]}
                            </option>
                          );
                        }
                      )}
                    </select>
                    {formErrors.category && <span className="help-block">{formErrors.category}</span>}
                  </div>
                ) : (
                  LocationCategoryEditableCategories[category] || <span>&mdash;</span>
                )}
              </dd>

              <dt>Allocatable:</dt>
              <dd>{allocatable ? 'Yes' : 'No'}</dd>

              <dt>Barcode:</dt>
              <dd className="barcode">
                {editMode ? (
                  <div className={`form-group form-inline${formErrors.barcodeText ? ' has-error' : ''}`}>
                    <input
                      className="form-control"
                      type="text"
                      name="barcodeText"
                      value={editedData.barcodeText || barcodeText || ''}
                      onChange={this.updateLocationState}
                    />
                    {formErrors.barcodeText && <span className="help-block">{formErrors.barcodeText}</span>}
                  </div>
                ) : (
                  barcodeText || <span>&mdash;</span>
                )}
              </dd>

              <dt>Created:</dt>
              <dd>{format(createdAt, `${dateFormat} ${time24hourFormat}`)}</dd>

              <dt>Last updated:</dt>
              <dd>{format(updatedAt, `${dateFormat} ${time24hourFormat}`)}</dd>

              <dt>Last arrival:</dt>
              <dd>
                {arrivalTime == null ? <span>&mdash;</span> : format(arrivalTime, `${dateFormat} ${time24hourFormat}`)}
              </dd>

              <dt>Warehouse:</dt>
              <dd>{warehouse.id}</dd>

              {this.renderDirectedPutAwayLocationDetails()}
              {this.renderSequenceId()}
              {this.renderAssociatedEntityDetails()}
              {this.renderPrimaryPickLocation()}
              {this.renderPutAwayZone()}
            </dl>
            <div className="vertical-rule hidden-xs"></div>
          </div>

          <div className="col-sm-6 thirds">
            <h4 className="text-uppercase">Status</h4>
            <dl className="dl-horizontal">
              <dt>Status:</dt>
              <dd className="state capitalize">
                {editMode ? (
                  <div className={`form-group form-inline${formErrors.state ? ' has-error' : ''}`}>
                    <select
                      className="form-control"
                      name="state"
                      value={editedData.state || state || ''}
                      onChange={this.updateLocationState}
                    >
                      <option value={LocationStatus.active}>Active</option>
                      <option value={LocationStatus.locked}>Locked</option>
                      <option value={LocationStatus.inactive}>Inactive</option>
                    </select>
                    {formErrors.state && <span className="help-block">{formErrors.state}</span>}
                  </div>
                ) : (
                  state
                )}
              </dd>

              <dt>Lock Info:</dt>
              <dd className="lock-info">
                {state !== LocationStatus.locked ? <span>&mdash;</span> : lockInfo || <span>&mdash;</span>}
              </dd>
            </dl>

            <h4 className="text-uppercase">Dimensions</h4>
            <dl className="dl-horizontal">
              <dt>Height:</dt>
              <dd className="row height">{this.renderDimension('height', height, formErrors)}</dd>

              <dt>Width:</dt>
              <dd className="row width">{this.renderDimension('width', width, formErrors)}</dd>

              <dt>Length:</dt>
              <dd className="row length">{this.renderDimension('length', length, formErrors)}</dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders the pallet capacity if the directed put away feature flag is enabled.
   * It will display m dash if the property is null or undefined.
   *
   * @return  {JSX.Element[]} JSX elements that render the pallet capacity for floor location.
   */
  private renderDirectedPutAwayLocationDetails(): JSX.Element[] {
    const {category, palletCapacity} = this.state.location;
    const isFloorOrRackStorage = category === 'rack_storage' || category === 'floor_storage';
    if (isFloorOrRackStorage && this.state.directedPutAwayEnabled) {
      let key = 0;
      return [
        <dt key={key++}>Pallet Capacity:</dt>,
        <dd key={key++}>{palletCapacity == null ? <span>&mdash;</span> : palletCapacity}</dd>
      ];
    } else {
      return undefined;
    }
  }

  /**
   * Renders the sequence id
   * It will display m dash if the property is null or undefined.
   *
   * @return  {JSX.Element[]} JSX elements that render the sequence id for floor location.
   */
  private renderSequenceId(): JSX.Element[] {
    const {sequenceId} = this.state.location;
    let key = 0;
    return [
      <dt key={key++}>Sequence Id:</dt>,
      <dd key={key++}>{sequenceId == null ? <span>&mdash;</span> : sequenceId}</dd>
    ];
  }

  private renderAssociatedEntityDetails(): JSX.Element[] {
    const loc = this.state.location;
    const associatedId = loc.associatedEntity.id;
    let key = 0;
    if (loc.associatedEntity.type === LocationEntityType.INVENTORY) {
      return [
        <dt key={key++}>Associated Item</dt>,
        <dd key={key++}>
          <a href={'/wh/inventories/' + associatedId}>Item {associatedId}</a>
        </dd>
      ];
    } else if (loc.associatedEntity.type === LocationEntityType.SHIPMENT) {
      return [
        <dt key={key++}>Shipment</dt>,
        <dd key={key++}>
          <a href={'/wh/fulfillment/shipments/' + associatedId}>#{associatedId}</a>
        </dd>
      ];
    } else {
      return undefined;
    }
  }

  /**
   * Renders the primaryPickLocation
   *
   * @return  {JSX.Element[]} JSX elements that render the primaryPickLocation.
   */
  private renderPrimaryPickLocation(): JSX.Element[] {
    const editedPrimaryPickLocation = this.state.editedData.primaryPickLocation;
    const formErrors = this.state.formErrors.locationData || {};
    const primaryPickLocation = this.state.location.primaryPickLocation;
    const primaryPickLocationText = this.state.location.primaryPickLocation
      ? LocationPrimaryPickLocation.true
      : LocationPrimaryPickLocation.false;
    let key = 0;
    if (this.state.editMode) {
      return [
        <dt key={key++}>Primary Pick Loc:</dt>,
        <dd key={key++} className="primary pick location">
          <div className={`form-group form-inline${formErrors.primaryPickLocation ? ' has-error' : ''}`}>
            <input
              type="checkbox"
              name="primaryPickLocation"
              checked={editedPrimaryPickLocation != null ? editedPrimaryPickLocation : primaryPickLocation}
              onChange={this.updateLocationState}
            />
            {formErrors.primaryPickLocation && <span className="help-block">{formErrors.primaryPickLocation}</span>}
          </div>
        </dd>
      ];
    } else {
      return [<dt key={key++}>Primary Pick Loc:</dt>, <dd key={key++}>{primaryPickLocationText}</dd>];
    }
  }

  private renderPutAwayZone(): JSX.Element {
    const putAwayZone = this.state.location.putAwayZone;
    const editedPutAwayZone = this.state.editedData.putAwayZone;
    const formErrors = this.state.formErrors.locationData || {};
    let key = 0;
    if (this.props.enableZones) {
      if (this.state.editMode) {
        return (
          <>
            <dt key={key++}>Put Away Zone:</dt>
            <dd key={key++}>
              <div className={`form-group form-inline${formErrors.putAwayZone ? ' has-error' : ''}`}>
                <input
                  className="form-control"
                  type="text"
                  name="putAwayZone"
                  value={editedPutAwayZone || putAwayZone || ''}
                  onChange={this.updateLocationState}
                />
                {formErrors.putAwayZone && <span className="help-block">{formErrors.putAwayZone}</span>}
              </div>
            </dd>
          </>
        );
      } else {
        return (
          <>
            <dt key={key++}>Put Away Zone:</dt>
            <dd key={key++}>{putAwayZone == null ? <span>&mdash;</span> : putAwayZone}</dd>
          </>
        );
      }
    } else {
      return <></>;
    }
  }

  private renderLegacyContents() {
    const contents = this.state.location.contents;
    if (!contents || !contents.length) {
      return <p>This location has no contents</p>;
    } else {
      const hasLpnContents =
        contents.filter((item) => item.entity && item.entity.type === LocationContentEntityType.LPN).length > 0;
      const hasLotCode =
        contents.filter((item) => item.inventoryTrackingData && item.inventoryTrackingData.lotCode).length > 0;
      return (
        <table className="table">
          <thead>
            <tr>
              <th>Reservation ID</th>
              <th>SKU</th>
              {hasLpnContents ? <th>LPN</th> : null}
              {hasLotCode ? <th>Lot Code</th> : null}
              <th>Quantity</th>
              <th>Unit</th>
            </tr>
          </thead>
          <tbody>
            {contents.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>{item.reservation.id}</td>
                  <td>{renderItemLink(item.inventory.id, item.inventory.sku, false)}</td>
                  {hasLpnContents ? this.renderLPN(item) : null}
                  {hasLotCode ? this.renderLotCode(item) : null}
                  <td>{item.quantity.amount}</td>
                  <td>{displayPackaging(item.quantity.unit, item.quantity.amount)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  }

  private renderLPN(item: LocationContent) {
    if (!item.entity || item.entity.type !== LocationContentEntityType.LPN) {
      return <td>-</td>;
    } else {
      return <td>{renderLpnLink(item.entity.id, false)}</td>;
    }
  }

  private renderLotCode(item: LocationContent) {
    if (!item.inventoryTrackingData || !item.inventoryTrackingData.lotCode) {
      return <td>-</td>;
    } else {
      const lot = item.inventoryTrackingData.lotCode;
      return <td>{lot}</td>;
    }
  }

  private renderDimension(key: string, dimension: Measurement, formErrors?: object) {
    const amount = get(dimension, 'amount');
    const unit = get(dimension, 'unit');
    const editedDimension = get(this.state.editedData, `dimensions[${key}]`) || {};
    const amountError = get(formErrors, `dimensions[${key}].amount`);
    const unitError = get(formErrors, `dimensions[${key}].unit`);
    if (this.state.editMode) {
      return [
        <div
          key="amount"
          className={`dimension amount col-md-3 form-group form-inline${amountError ? ' has-error' : ''}`}
        >
          <input
            className="form-control"
            type="number"
            name="amount"
            dim-pointer={`dimensions.${key}`}
            value={editedDimension.amount || amount || ''}
            onChange={this.updateLocationDimensionState}
          />
          {amountError && <span className="help-block">{amountError}</span>}
        </div>,
        <div key="unit" className={`dimension unit col-md-9 form-group form-inline${unitError ? ' has-error' : ''}`}>
          <select
            className="form-control"
            name="unit"
            dim-pointer={`dimensions.${key}`}
            value={editedDimension.unit || unit || ''}
            onChange={this.updateLocationDimensionState}
          >
            <option value="">Select a Unit</option>
            {Object.keys(LengthUnit).map((unitKey) => {
              return (
                <option key={unitKey} value={unitKey}>
                  {LengthUnit[unitKey]}
                </option>
              );
            })}
          </select>
          {unitError && <span className="help-block">{unitError}</span>}
        </div>
      ];
    } else {
      if (amount) {
        return `${amount} ${unit}`;
      } else {
        return <span>&mdash;</span>;
      }
    }
  }

  private async getLocation(warehouseId: number) {
    this.setState({locationLoading: true});

    // The new table loads contents in pages from Inventory Service.
    // Locations with many contents can take a while to load from
    // LocationService.getLocation and we end up just ignoring those
    // contents in the new table. So we'll take advantage of a page
    // limit quirk where reducing the location page size to 1
    // dramatically reduces the number of location contents fetched:
    // https://gitlab.com/flexe/warehouser/-/blob/cf89ddeeddef18ac5f12623c0b5fc469476a4a15/app/services/locations/query_manager.rb#L486
    const pageSize = this.props.isNewTableEnabled ? 1 : undefined;
    const location = (
      await this.locationsService.getLocations(warehouseId, pageSize, null, {locationId: this.locationId})
    )?.locations[0];

    if (location === undefined) {
      this.setState({errorMessage: 'Error loading location details', locationLoading: false});
    } else {
      this.setState({location, locationLoading: false});
    }
  }

  private toggleEditMode = (event) => {
    event.preventDefault();
    const location = this.state.location;
    const editedData = {};
    this.setState({
      location,
      editedData,
      editMode: !this.state.editMode,
      errorMessage: null,
      formErrors: {}
    });
  };

  private updateLocationDimensionState = (event) => {
    const dimPointer = event.currentTarget.getAttribute('dim-pointer');
    const amountPointer = `${dimPointer}.amount`;
    const unitPointer = `${dimPointer}.unit`;
    const key = event.currentTarget.name;
    const value = event.currentTarget.value;
    const editedData = cloneDeep(this.state.editedData);
    const formErrors = cloneDeep(this.state.formErrors);

    // If the user changes just the amount or the unit, pick up the existing data
    // for the other since it has to be sent in as a matched pair
    const newAmount =
      key === 'amount' ? value : get(editedData, amountPointer) || get(this.state.location, amountPointer);
    const newUnit = key === 'unit' ? value : get(editedData, unitPointer) || get(this.state.location, unitPointer);

    // Update the location property value
    set(editedData, amountPointer, newAmount);
    set(editedData, unitPointer, newUnit);

    // Clear out the error message associated with the property
    set(formErrors.locationData, amountPointer, null);
    set(formErrors.locationData, unitPointer, null);
    this.setState({editedData, formErrors});
  };

  private updateLocationState = (event) => {
    const key = event.currentTarget.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const editedData = cloneDeep(this.state.editedData);
    const formErrors = cloneDeep(this.state.formErrors);
    // Update the location property value
    set(editedData, key, value);
    // Clear out the error message associated with the property
    set(formErrors.locationData, key, null);
    this.setState({editedData, formErrors});
  };

  private handleUpdateLocation = (event) => {
    event.preventDefault();
    if (Object.keys(this.state.editedData).length > 0) {
      set(this.state.editedData, 'id', this.locationId);
      this.updateLocation();
    } else {
      this.toggleEditMode(event);
    }
  };

  private async updateLocation() {
    try {
      const response = await this.locationsService.updateLocation(
        this.state.location.warehouse.id,
        this.state.editedData
      );

      // determine what the current data looks like. update request returns only the applied data.
      const updatedData = get(response, 'data.data');
      const location = Object.assign(cloneDeep(this.state.location), updatedData);
      location.dimensions = Object.assign(cloneDeep(this.state.location.dimensions), updatedData.dimensions || {});

      // handle multistatus responses
      if (response.status === 207) {
        const formErrors = processErrorsInSuccessResponse(response);
        this.setState({
          location,
          formErrors,
          editedData: {},
          errorMessage: 'Some data could not be updated. Please fix the form errors below.'
        });
      } else {
        this.setState({
          location,
          editedData: {},
          successMessage: 'Location successfully updated!',
          errorMessage: null,
          editMode: false
        });
        setTimeout(() => this.setState({successMessage: null}), 5000);
      }
    } catch (error) {
      const formErrors = processErrorResponse(error);
      this.setState({
        errorMessage: 'The location could not be updated. Please fix the form errors below.',
        formErrors
      });
    }
  }

  /**
   * Get directed put away feature flag based on the current warehouse id.
   * Parse the return value and set it to the component state.
   */
  private async loadDirectedPutAwayFeatureFlag() {
    const warehouseId: number = this.props.selectedWarehouse.id;
    const response = await this.warehouseService.getFeatureFlag(DIRECTED_PUT_AWAY_FEATURE_FLAG, warehouseId);
    this.setState({
      directedPutAwayEnabled: !!response.data.value
    });
  }

  /**
   * Load all reservations associated with a given warehouse and,
   * if any have crossdock enabled, set crossdockEnabled as true in the component state.
   */
  private async checkIfCrossdockEnabled() {
    const warehouseId: number = this.props.selectedWarehouse.id;
    const response = await this.warehouseService.getReservationsForWarehouse(warehouseId);
    this.setState({
      crossdockEnabled: response.data.reservations.some((res) => res.crossdockEnabled)
    });
  }
}

export default LocationsDetail;
