import * as React from 'react';
import ExpensesTable from './ExpensesTable';
import ExpenseService from './ExpenseService';
import {AllPricings} from './ExpensesInterfaces';
import WarehouseExpenseCreator from './WarehouseExpenseCreator';

// TODO: interface for pageData

interface Props {
  warehouseId: number;
  date: Date;
  authenticityToken?: string;
  pageData: any;
  showAddExpenseButton: boolean;
  costBasedExpenseCategoryList: string[];
  selectTab: string;
  changePeriodClosed: boolean;
  warehouseExpenseAllPricings: AllPricings;
  reservationsInfo: any;
  billingPeriod: string;
  statementDate: string;
}

const LiveStatementPreviewPage: React.FC<Props> = (props) => {
  const [data] = React.useState(props.pageData);
  const [warehouseId] = React.useState(props.warehouseId);
  const [date] = React.useState(props.date);
  const [showAddExpenseButton] = React.useState(props.showAddExpenseButton);
  const [costBasedExpenseCategoryList] = React.useState(props.costBasedExpenseCategoryList);
  const [selectTab] = React.useState(props.selectTab);
  const [changePeriodClosed] = React.useState(props.changePeriodClosed);
  const [warehouseExpenseAllPricings] = React.useState(props.warehouseExpenseAllPricings);
  const [reservationsInfo] = React.useState(props.reservationsInfo);
  const [billingPeriod] = React.useState(props.billingPeriod);
  const [statementDate] = React.useState(props.statementDate);
  const [authenticityToken] = React.useState(props.authenticityToken);
  const [creationCount, setCreationCount] = React.useState(0);

  let rand = 0;

  return (
    <div className="container-fluid">
      <div className="breadcrumbs">
        <a href={data.statements_path}>Statements</a>
        <i className="fa fa-angle-right"></i>
        <a href={data.warehouse_path}>{data.warehouse_name}</a>
        <i className="fa fa-angle-right"></i>
        {data.statement_preview_time}
      </div>

      <div className="space-below">
        <h2 style={{marginBottom: '5px', display: 'inline'}}>Statement Preview</h2>
        {showAddExpenseButton && (
          <div style={{float: 'right'}}>
            <WarehouseExpenseCreator
              warehouseId={warehouseId}
              allPricings={warehouseExpenseAllPricings}
              changePeriodClosed={changePeriodClosed}
              reservations={reservationsInfo}
              billingPeriod={billingPeriod}
              statementDate={statementDate}
              costBasedExpenseCategoryList={costBasedExpenseCategoryList}
              authenticityToken={authenticityToken}
              onCreateExpenseSuccess={() => {
                setCreationCount(creationCount + 1);
              }}
            />
          </div>
        )}
      </div>
      <h4>{data.statement_preview_as_of_time}</h4>

      <dl className="dl-horizontal wide-label space-below-lg">
        <dt>Warehouse:</dt>
        <dd>{data.warehouse_friendly_name}</dd>
        <dt>Billing period:</dt>
        <dd>
          {data.billing_period_start_date} &ndash; {data.billing_period_end_date}
        </dd>
        <dt>Days in billing cycle:</dt>
        <dd>{data.days_in_billing_cycle} days</dd>
      </dl>

      {data &&
        data.line_items_by_reservation &&
        data.line_items_by_reservation.map((elt) => {
          rand += 1;
          return (
            <React.Fragment key={rand}>
              <h4>{elt[0]}</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Qty</th>
                    <th className="money">Rate</th>
                    <th className="money">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {elt[1].line_items.map((li) => {
                    return (
                      <tr key={li.rate}>
                        <td>{li.description}</td>
                        <td>{li.quantity_and_uom}</td>
                        <td className="money">{li.rate}</td>
                        <td className="money">{li.amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Subtotal</td>
                    <td colSpan={2}></td>
                    <td className="money">{elt[1].subtotal}</td>
                  </tr>
                </tfoot>
              </table>
              <br />
            </React.Fragment>
          );
        })}

      <table className="table">
        <tfoot>
          <tr>
            <td>Total for all reservations</td>
            <td className="money">{data.total_across_reservations}</td>
          </tr>
        </tfoot>
      </table>

      {/* hmm maybe we don't want to create a new service each render */}
      {
        <ExpensesTable
          selectedTab={selectTab != null ? selectTab : 'ghost'}
          warehouseId={warehouseId}
          date={date}
          expenseService={new ExpenseService(authenticityToken)}
          creationCount={creationCount}
        />
      }
    </div>
  );
};

export default LiveStatementPreviewPage;
