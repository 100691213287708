import * as React from 'react';
import {RetailOrderDetails} from '../../shared/services/RetailFulfillmentService';
import Fulfillment from '../../shipper/ecommerce-shipments/Fulfillment';

interface HeaderPaneOrderInformationProps {
  orderDetails: RetailOrderDetails;
  enablePurchaseOrder: boolean;
}

export const HeaderPaneOrderInformation: React.FC<HeaderPaneOrderInformationProps> = (props) => {
  const orderInformation = (): React.ReactFragment => {
    return (
      <div id="order-information" className="col-md-3 quarters">
        <h4 className="text-uppercase">Order Information</h4>

        <dl className="dl-horizontal">
          {reservationInfo()}
          {purchaseOrder()}
          {shipmentType()}
          {shipmentMethod()}
          {routingDetails()}
          {masterBillOfLadingNumber()}
          {pickStarted()}
          {pickStatus()}
          {destinationRetailer()}
        </dl>

        <div className="vertical-rule hidden-xs"></div>
      </div>
    );
  };

  const reservationInfo = (): React.ReactFragment => {
    let reservationName: string = '#' + props.orderDetails.reservation.id.toString();

    if (props.orderDetails.shipperCompany.warehouseVisibleNickname) {
      reservationName += '\u00A0\u2014\u00A0' + props.orderDetails.shipperCompany.warehouseVisibleNickname;
    }

    return (
      <React.Fragment>
        <dt>Reservation:</dt>
        <dd>{reservationName}</dd>
      </React.Fragment>
    );
  };

  const purchaseOrder = (): React.ReactFragment => {
    if (props.enablePurchaseOrder) {
      return (
        <React.Fragment>
          <dt>Customer UUID:</dt>
          <dd data-testid="customer-uuid">{props.orderDetails.customerUuid}</dd>

          <dt>Purchase Order:</dt>
          <dd data-testid="purchase-order">{props.orderDetails.purchaseOrder}</dd>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <dt>Purchase Order:</dt>
          <dd data-testid="purchase-order">{props.orderDetails.customerUuid}</dd>
        </React.Fragment>
      );
    }
  };

  const shipmentType = (): React.ReactFragment => {
    let displayShipmentType = props.orderDetails.shipmentType;

    switch (props.orderDetails.shipmentType) {
      case 'ltl':
        displayShipmentType = 'LTL';
        break;
      case 'parcel':
        displayShipmentType = 'Parcel';
        break;
      case 'tbd':
        displayShipmentType = 'To be determined';
        break;
    }

    return (
      <React.Fragment>
        <dt>Shipment Type:</dt>
        <dd>{displayShipmentType}</dd>
      </React.Fragment>
    );
  };

  const shipmentMethod = (): React.ReactFragment => {
    if (!props.orderDetails.shipmentMethod) {
      return <React.Fragment></React.Fragment>;
    }

    let displayShipmentMethod = props.orderDetails.shipmentMethod;

    switch (props.orderDetails.shipmentMethod) {
      case 'generated':
        displayShipmentMethod = 'Generated by FLEXE';
        break;
      case 'upload':
        displayShipmentMethod = 'Upload';
        break;
      case 'vrs':
        displayShipmentMethod = 'VRS';
        break;
    }

    return (
      <React.Fragment>
        <dt>Shipment Method:</dt>
        <dd>{displayShipmentMethod}</dd>
      </React.Fragment>
    );
  };

  const routingDetails = (): React.ReactFragment => {
    if (props.orderDetails.routingDetails) {
      return (
        <React.Fragment>
          <dt>Routing:</dt>
          <dd>{props.orderDetails.routingDetails.displayName}</dd>
        </React.Fragment>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  const masterBillOfLadingNumber = (): React.ReactFragment => {
    if (props.orderDetails.masterBillOfLadingNumber) {
      return (
        <React.Fragment>
          <dt>Master BOL #:</dt>
          <dd>{props.orderDetails.masterBillOfLadingNumber}</dd>
        </React.Fragment>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  const PickStatusRelevantStates = ['confirmed', 'pickup_scheduled'];

  const pickStatus = (): React.ReactFragment => {
    if (PickStatusRelevantStates.includes(props.orderDetails.state) && props.orderDetails.allowShowingPickStatus) {
      return (
        <React.Fragment>
          <dt>Pick Status:</dt>
          <dd>{props.orderDetails.pickStatus}</dd>
        </React.Fragment>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  const pickStartedRelevantStates = ['confirmed', 'pickup_scheduled'];

  const pickStarted = (): React.ReactFragment => {
    if (pickStartedRelevantStates.includes(props.orderDetails.state) && !props.orderDetails.allowShowingPickStatus) {
      return (
        <React.Fragment>
          <dt>Pick Started:</dt>
          <dd>{props.orderDetails.pickingHasStarted ? 'Yes' : 'No'}</dd>
        </React.Fragment>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  const destinationRetailer = (): React.ReactFragment => {
    if (props.orderDetails.destinationRetailer) {
      return (
        <React.Fragment>
          <dt>Destination Retailer:</dt>
          <dd>{props.orderDetails.destinationRetailer}</dd>
        </React.Fragment>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  return <React.Fragment>{orderInformation()}</React.Fragment>;
};
