export interface MobileUser {
  id: number;
  name: string;
  userName: string;
  displayName: string;
  isProcessing: boolean;
  canBeDeleted: boolean;
}

export interface MobileWarehouse {
  address: string;
  id: number;
  name: string;
  users: MobileUser[];
}

export interface Reservation {
  id: number;
  users: UserWithRoles[];
  warehouse: ReservationWarehouse;
}

export interface UserWithRoles {
  id: number;
  name: string;
  roles: string[];
  isProcessing: boolean;
}

interface ReservationWarehouse {
  address: string;
  name: string;
}

export interface PageData {
  activeUsers: User[];
  adminUserNames: string;
  allReservationRoles: Role[];
  allWarehouseRoles: Role[];
  companyName: string;
  correspondenceOnlyUsers: User[];
  inactiveUsers: User[];
  isMobileEnabled: boolean;
  allCompanyRoles?: Role[];
  mobileUsers: MobileUser[];
  mobileWarehouses: MobileWarehouse[];
  reservations: Reservation[];
  warehouses: Warehouse[];
}

export interface UserManagementProps {
  authenticityToken: string;
  currentUserId: number;
  initialTab: string | null;
  notice: string | null;
}

export interface Role {
  title: string;
  description: string;
  canBeToggled: boolean;
}

export interface Warehouse {
  address: string;
  id: number;
  isInventoryControlRoleEnabled: boolean;
  name: string;
  users: UserWithRoles[];
}

export interface InputFieldData {
  value: string;
  error: string;
}

export interface Site {
  id: number;
  isInventoryControlRoleEnabled?: boolean;
  name: string;
}

export enum SiteType {
  Warehouse,
  Reservation
}

export interface User {
  email: string;
  id: number;
  isAdmin: boolean;
  name: string;
  isProcessing: boolean;
  isActive: boolean;
  canBeDeactivated: boolean;
  companyRoles?: string[];
}
