import * as React from 'react';
import {FC, useEffect, useMemo, useState} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {
  getGridNumericOperators,
  GridActionsCellItem,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
  GridToolbarContainer
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import {LoadShipment} from '../LoadInterfaces';
import {flexeMuiTheme} from '../../../shared/mui/default-mui-theme';
import {FlexeDataGrid} from '../../../shared/mui/flexe-data-grid/FlexeDataGrid';
import {ItemLinkCell} from '../../../shared/mui/flexe-data-grid/ItemLinkCell';
import {Shipment} from '../../outbound-shipments/ShipmentInterfaces';
import {SortOrder} from '../../../shared/CommonInterfaces';
import ShipmentStatusMap from '../../outbound-shipments/ShipmentConstants';
import {FreightShipMode} from '../../../shared/constants';

interface Props {
  shipMode: string;
  shipments: Shipment[];
  isLoading: boolean;
  pageNumber: number;
  pageSize: number;
  rowCount: number;
  onPageNumberChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSortDirectionChange: (dir: SortOrder) => void;
  onShipmentIdFilterChange: (id: number | null) => void;
  onShipmentDelete: (shipmentId: number) => () => Promise<void>;
}

const DEFAULT_COLUMN_WIDTH = 200;

const LoadShipmentsTable: FC<Props> = (props) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: props.pageNumber,
    pageSize: props.pageSize
  });
  const [rows, setRows] = useState<LoadShipment[]>([]);

  // reload when the dataset changes
  useEffect(() => {
    setRows(
      props.shipments?.map((shipment, _) => {
        return {
          id: shipment.id.toString(),
          status: shipment.status,
          orderId: shipment.order_id?.toString(),
          reservationId: shipment.reservation.id.toString()
        };
      }) || []
    );
  }, [props.shipments]);

  // listen for page changes that are sometimes triggered by the parent
  useEffect(() => {
    if (props.pageNumber !== paginationModel.page) {
      setPaginationModel({
        page: props.pageNumber,
        pageSize: props.pageSize
      });
    }
  }, [props.pageNumber]);

  const shipmentIdFilterOperator = () => {
    // use MUI's default numeric operator with only exact matching enabled
    const numericOperators = getGridNumericOperators();
    const equalsOperator = numericOperators.find((op) => ['='].includes(op.value));
    equalsOperator.requiresFilterValue = true;
    return [equalsOperator];
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        renderCell: (params) => {
          return (
            <ItemLinkCell
              baseUrl={`../shipments`}
              item={{
                id: params.id,
                value: params.row.id
              }}
            />
          );
        },
        minWidth: DEFAULT_COLUMN_WIDTH,
        filterable: true,
        sortable: true,
        filterOperators: shipmentIdFilterOperator(),
        flex: 1
      },
      {
        field: 'reservationId',
        headerName: 'Reservation ID',
        type: 'string',
        minWidth: DEFAULT_COLUMN_WIDTH,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        flex: 1
      },
      {
        field: 'orderId',
        headerName: 'Order ID',
        type: 'string',
        minWidth: DEFAULT_COLUMN_WIDTH,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        flex: 1
      },
      {
        field: 'status',
        headerName: 'Status',
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          const statusObject = ShipmentStatusMap.get(params.value);
          const className = `shipment-status-dot ${statusObject.className}`;
          return (
            <div>
              <span className={className} />
              {statusObject.text}
            </div>
          );
        },
        minWidth: DEFAULT_COLUMN_WIDTH,
        flex: 1
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            showInMenu={true}
            onClick={props.onShipmentDelete(parseInt(params.row.id, 10))}
            label={'Remove Shipment'}
          />
        ]
      }
    ],
    [props.shipments]
  );

  const columnVisibilityModel = useMemo<GridColumnVisibilityModel>(() => {
    return {
      id: !props.isLoading,
      status: !props.isLoading,
      reservationId: !props.isLoading,
      orderId: !props.isLoading,
      actions: !props.isLoading && props.shipMode !== FreightShipMode
    };
  }, [columns, rows, props.isLoading]);

  const handlePageChange = (newPaginationModel: GridPaginationModel, _: GridCallbackDetails) => {
    setPaginationModel(newPaginationModel);
    props.onPageNumberChange(newPaginationModel.page);
    props.onPageSizeChange(newPaginationModel.pageSize);
  };

  const onFilter = (params: GridFilterModel, _: GridCallbackDetails) => {
    // assuming size since we only allow one filter type
    const input = String(params.items[0].value);

    // a shipment id was applied but is now being removed
    if (input === 'undefined') {
      props.onShipmentIdFilterChange(null);
    } else {
      props.onShipmentIdFilterChange(parseInt(input, 10));
    }
  };

  const onSortModelChange = (params: GridSortModel, _: GridCallbackDetails) => {
    if (params.length === 0) {
      return;
    }

    // sort field cannot change when shipment id is the only option
    props.onSortDirectionChange(params[0].sort === 'asc' ? SortOrder.ASC : SortOrder.DESC);
  };

  return (
    <div className="contents-table">
      <ThemeProvider theme={flexeMuiTheme}>
        <FlexeDataGrid
          autoHeight
          columns={columns}
          rows={rows}
          rowCount={props.rowCount}
          loading={props.isLoading}
          filterMode={'server'}
          onFilterModelChange={onFilter}
          pageSizeOptions={[20, 50, 100]} // max is 100
          paginationMode={'server'}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          sortingMode={'server'}
          onSortModelChange={onSortModelChange}
          columnVisibilityModel={columnVisibilityModel}
          getRowHeight={() => 'auto'}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '8px'}
          }}
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          slots={{toolbar: CustomToolbar}}
        />
      </ThemeProvider>
    </div>
  );

  // removes the export button and quick filter
  function CustomToolbar() {
    return <GridToolbarContainer />;
  }
};

export default LoadShipmentsTable;
