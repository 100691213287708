import * as React from 'react';
import DatePicker from 'react-datepicker';
import {LegacyModal} from '@flexe/ui-components';
import * as ReactTooltip from 'react-tooltip';
import {CarrierDetails, Carriers, CreateParams} from '../SharedInterfaces';
import {CarrierFieldTypesMap} from './AccountFields';

interface CreateAccountProps {
  showAccountReference: boolean;
  createAccountParams: CreateParams;
  carrierDefinitions: Carriers;
  selectedCarrier: CarrierDetails;
  errors: string[];
  toggleCreateAccountModal: () => void;
  handleCreateAccount: () => void;
  handleCreateAccountInfoChange: (e: any) => void;
  handleCreateAccountCarrierChange: (e: any) => void;
  handleCreateAccountDescriptionChange: (e: any) => void;
  handleCreateAccountReferenceChange: (e: any) => void;
}

export default function CreateAccount({
  showAccountReference,
  createAccountParams,
  carrierDefinitions,
  selectedCarrier,
  errors,
  toggleCreateAccountModal,
  handleCreateAccount,
  handleCreateAccountInfoChange,
  handleCreateAccountCarrierChange,
  handleCreateAccountDescriptionChange,
  handleCreateAccountReferenceChange
}: CreateAccountProps) {
  const [validReference, setValidReference] = React.useState(true);
  const errorsRef = React.useRef(null);

  React.useEffect(() => {
    if (!showAccountReference) {
      return;
    }

    // eslint-disable-next-line no-useless-escape
    if (!createAccountParams.accountReference || /^[a-zA-Z0-9_\.\-]+$/.test(createAccountParams.accountReference)) {
      setValidReference(true);
    } else {
      setValidReference(false);
    }
  }, [createAccountParams.accountReference]);

  React.useEffect(() => {
    if (errors.length > 0 && errorsRef.current) {
      errorsRef.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [errors]);

  return (
    <LegacyModal
      id="create_carrier_account_modal"
      title="Create New Carrier Account"
      show={true}
      size="small"
      toggleModal={toggleCreateAccountModal}
      footer={
        <CreateAccountFooter
          validReference={validReference}
          toggleCreateAccountModal={toggleCreateAccountModal}
          handleCreateAccount={handleCreateAccount}
        />
      }
      disableClose={true}
      hideClose={true}
    >
      <div>
        {errors && errors.length > 0 && (
          <div className="alert alert-danger popup-error" role="alert" ref={errorsRef}>
            <span>Carrier Account Creation Errors Detected:</span>
            <br />
            <ul>
              {errors.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          </div>
        )}
        <div className="row">
          <label className="col-md-12 space-below">
            Carrier
            <br />
            <select
              data-testid="select-carrier"
              name="carrier"
              id="carrier-select"
              onChange={handleCreateAccountCarrierChange}
            >
              <option value="">Select a carrier...</option>
              {Object.keys(carrierDefinitions).map((carrier) => {
                const details = carrierDefinitions[carrier];
                return (
                  <option key={carrier} value={carrier}>
                    {details.displayName}
                  </option>
                );
              })}
            </select>
            <span className="required-indicator">&#42; Required</span>
          </label>
        </div>
        {selectedCarrier && (
          <div>
            {showAccountReference && (
              <div className="row account-reference">
                <label data-tip data-for="account-reference-create-tip" htmlFor="carrier-account-reference">
                  Account Reference
                </label>
                <input
                  name="accountReference"
                  id="carrier-account-reference"
                  onChange={handleCreateAccountReferenceChange}
                  value={createAccountParams.accountReference || ''}
                  placeholder="Example: my-fave-account.1"
                  style={{background: validReference ? 'transparent' : 'red'}}
                />
                <ReactTooltip id="account-reference-create-tip" place="bottom">
                  Use this to easily identify the account
                </ReactTooltip>
                <p>allowed characters: A-Z a-z 0-9 - _ .</p>
              </div>
            )}
            <div className="row">
              <label htmlFor="carrier-description">Description</label>
              <br />
              <textarea
                name="description"
                id="carrier-description"
                onChange={handleCreateAccountDescriptionChange}
                placeholder="Add any additional notes..."
              ></textarea>
            </div>
            {selectedCarrier.usageNotes && (
              <div className="row">
                <p className="col-md-12 space-below-lg">
                  <i>Note: {selectedCarrier.usageNotes}</i>
                </p>
              </div>
            )}
            {!selectedCarrier.usageNotes && <br />}
            <div className="row">
              {enumerateCarrierInfoFields(selectedCarrier, createAccountParams, handleCreateAccountInfoChange)}
            </div>
          </div>
        )}
      </div>
    </LegacyModal>
  );
}

function CreateAccountFooter({validReference, toggleCreateAccountModal, handleCreateAccount}) {
  return (
    <div>
      <a onClick={toggleCreateAccountModal}>Cancel</a>
      <button
        id="create-account-agree"
        type="button"
        className="btn"
        onClick={handleCreateAccount}
        disabled={!validReference}
      >
        Create Account
      </button>
    </div>
  );
}

const enumerateCarrierInfoFields = (selectedCarrier, createAccountParams, handleCreateAccountInfoChange) => {
  if (!selectedCarrier || !selectedCarrier.fields) {
    return [];
  }

  return selectedCarrier.fields.map((field) => {
    return (
      <label key={selectedCarrier + '_' + field.name} className="carrier-account-field col-md-6 space-below">
        {field.displayName}
        <br />
        {getInputObject(field, createAccountParams, handleCreateAccountInfoChange)}
        <br />
        {!field.optional && <span className="required-indicator">&#42; Required</span>}
      </label>
    );
  });
};

const getInputObject = (field, createAccountParams, handleCreateAccountInfoChange) => {
  if (field.type === 'Date') {
    return (
      <DatePicker
        placeholderText={field.display_name}
        name={field.name}
        dateFormat="MM/dd/yyyy"
        selected={createAccountParams.accountInfo[field.name]}
        onChange={(date) => handleCreateAccountInfoChange({target: {name: field.name, type: 'Date', value: date}})}
      />
    );
  }

  return (
    <input
      name={field.name}
      type={CarrierFieldTypesMap(field.type)}
      disabled={field.readOnly || false}
      defaultValue={field.default ? field.default : ''}
      onChange={handleCreateAccountInfoChange}
    />
  );
};
