import * as React from 'react';
import * as moment from 'moment';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {cloneDeep} from 'lodash';
import {ApiResponse, Reservation} from '../../shared/CommonInterfaces';
import SelectorWithCheckbox from '../../shared/SelectorWithCheckbox';
import BatchWavingService from '../../shared/services/BatchWavingService';
import {DAYS_OF_WEEK_MAP} from '../../shared/BatchScheduleInterfaces';
import DoWhenClickOutside from '../../shared/DoWhenClickOutside';
import {SelectOption} from '../../shared/FlexeSelect';
import {CustomKebabMenu, KebabMenuOption} from '../../shared/CustomKebabMenu';
import {BatchSchedule, CreateBatchScheduleResponse, DeleteBatchScheduleResponse} from './BatchInterfaces';

interface Props {
  reservations: Reservation[];
  reservationIdToName: {}; // eslint-disable-line @typescript-eslint/ban-types
  selectedReservationId?: number; // used for initializing to current filter
  showBatchSchedulingModal: boolean;
  showAddNewButton: boolean;
  batchService: BatchWavingService;
  closeBatchSchedulingModal();
}

const BatchSchedulingModal: FunctionComponent<Props> = (props) => {
  const [selectedReservation, setSelectedReservation] = React.useState<Reservation>(
    (props.selectedReservationId &&
      props.reservations.find((reservation) => {
        return reservation.id === props.selectedReservationId;
      })) ||
      props.reservations[0]
  );

  const [batchScheduleData, setBatchScheduleData] = React.useState<BatchSchedule[]>([]);
  const [showBatchSchedulingForm, setShowBatchSchedulingForm] = React.useState<boolean>(false);
  const [timeOptions, setTimeOptions] = React.useState<boolean>(false);
  const [selectedTime, setSelectedTime] = React.useState<string>('');
  const [hourOfDay, setHourOfDay] = React.useState<number>();
  const [minuteOfHour, setMinuteofHour] = React.useState<number>();
  const [showTimeDropdownOptions, setShowTimeDropdownOptions] = React.useState<boolean>(false);
  const [selectedDays, setSelectedDays] = React.useState<string[]>([]);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [success, setSuccess] = React.useState<string>('');
  const [batchDayOptions, setBatchDayOptions] = React.useState<SelectOption[]>([]);

  React.useEffect(() => {
    loadBatchSchedule();
  }, [selectedReservation]);

  React.useEffect(() => {
    daysOfWeekOptions();
  }, []);

  const reservationDropdown = () => {
    const reservationOptions = props.reservations.map((reservation) => {
      return {
        name: `${props.reservationIdToName[reservation.id]}`,
        value: `${reservation.id}`
      };
    });

    const onReservationSelect = (value: string) => {
      const selected = props.reservations.find((reservation) => {
        return reservation.id === parseInt(value, 10);
      });
      setSelectedReservation(selected);
    };

    return (
      <SelectorWithCheckbox
        options={reservationOptions}
        selected={[selectedReservation.id.toString()]}
        onSelect={onReservationSelect}
      />
    );
  };

  const batchScheduleTimesDropdown = () => {
    const times: string[] = [];
    const minutesInADay = moment.duration(1, 'day').as('m');
    const startTimeMoment = moment('24:00', 'hh:mm');

    for (let i = 0; i <= minutesInADay; i += 15) {
      startTimeMoment.add(i === 0 ? 0 : 15, 'm');
      times.push(startTimeMoment.format('h:mma'));
    }

    return (
      <div className="schedule-times-dropdown btn-group">
        <DoWhenClickOutside callback={handleClickOutside}>
          <button
            className={`btn btn-default dropdown-toggle${showTimeDropdownOptions ? ' focus' : ''}`}
            type="button"
            id="filter-dropdown"
            data-testid="filter-dropdown"
            data-toggle="dropdown"
            onClick={() => handleClickDropdownButton()}
            aria-haspopup="true"
            aria-expanded="false"
          >
            {selectedTime ? selectedTime : ' Time '}
            <span className="caret"></span>
          </button>
          {showTimeDropdownOptions && (
            <ul className="dropdown-menu show">
              {times.map((time, idx) => {
                return (
                  <li key={`dropdown-value-${idx}`}>
                    <a data-testid={`${idx}-anchor`} onClick={() => handleDropdownOptionSelect(time)}>
                      {time}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </DoWhenClickOutside>
      </div>
    );
  };

  const daysOfWeekOptions = () => {
    const options: SelectOption[] = [];
    DAYS_OF_WEEK_MAP.forEach((v, k) => {
      options.push({name: k, value: v});
    });
    setBatchDayOptions(options);
  };

  const daysOfWeekDropdown = () => {
    return (
      <SelectorWithCheckbox
        options={batchDayOptions}
        selected={selectedDays}
        title={'Days'}
        onSelect={onDaySelect}
        keepOptionsOpen={true}
      />
    );
  };

  const onDaySelect = (value: string) => {
    const selectedDaysClone = cloneDeep(selectedDays);
    const index = selectedDaysClone.indexOf(value);

    if (index > -1) {
      selectedDaysClone.splice(index, 1);
    } else {
      selectedDaysClone.push(value);
    }
    setSelectedDays(selectedDaysClone);
  };

  const handleDropdownOptionSelect = (value: string) => {
    parsedMinuteAndHour(value);
    setShowTimeDropdownOptions(false);
    setSelectedTime(value);
  };

  const parsedMinuteAndHour = (value: string) => {
    const militaryTime = moment(value, 'h:mma').format('HH:mma');
    const time = militaryTime.match(/(\d+):(\d+)/);
    const hour = parseInt(time[1], 10);
    const minute = parseInt(time[2], 10);

    setHourOfDay(hour);
    setMinuteofHour(minute);
  };

  const handleClickDropdownButton = () => {
    setTimeOptions(false);
    setShowTimeDropdownOptions(!showTimeDropdownOptions);
  };

  const handleClickOutside = () => {
    setTimeOptions(false);
    setShowTimeDropdownOptions(false);
  };

  const loadBatchSchedule = async () => {
    const response = await props.batchService.getBatchSchedule(selectedReservation.id);
    if (response && response.errors.length === 0) {
      setBatchDataAndMergedTimestamps(response.data.batchSchedules);
    } else {
      setBatchScheduleData([]);
    }
  };

  const createBatchSchedule = async () => {
    const validatedDays: string[] = validateNewBatchSchedule();
    if (selectedDays.length > 0 && validatedDays.length === 0) {
      setErrors(['Day(s) already exists in schedule.']);
      return;
    }

    const type: string = 'batch_schedule';
    const response = await props.batchService.createBatchSchedule(
      type,
      selectedReservation.id,
      validatedDays,
      hourOfDay,
      minuteOfHour
    );

    if (response && response.errors.length === 0) {
      const newSchedule = [...batchScheduleData, response.data.batchSchedules[0]];
      setBatchDataAndMergedTimestamps(newSchedule);
      setShowBatchSchedulingForm(false);
      setSelectedTime('');
      setSelectedDays([]);
      setErrors([]);
      setSuccess('Schedule created successfully.');
    } else {
      handleError('There was an issue creating your batch schedule', response);
    }
  };

  const validateNewBatchSchedule = (): string[] => {
    let existingDaysOfWeek: string[] = [];
    batchScheduleData.forEach((schedule) => {
      if (schedule.hourOfDay === hourOfDay && schedule.minuteOfHour === minuteOfHour) {
        existingDaysOfWeek = schedule.daysOfWeek;
      }
    });
    return selectedDays.filter((day) => !existingDaysOfWeek.includes(day));
  };

  const deleteBatchSchedule = async (id) => {
    const response = await props.batchService.deleteBatchSchedule(id, selectedReservation.id);

    if (response && response.data && response.data.status === 204) {
      const batchScheduleCopy = [...batchScheduleData];
      const updatedBatchSchedule = batchScheduleCopy.filter((schedule) => schedule.id !== id);

      setBatchScheduleData(updatedBatchSchedule);
      setSuccess('Schedule successfully deleted.');
    } else {
      handleError('There was an issue deleting your batch schedule', response);
    }
  };

  const handleError = (
    message: string,
    response: ApiResponse<CreateBatchScheduleResponse | DeleteBatchScheduleResponse>
  ) => {
    if (response) {
      const serverErrors = response.errors.map((e) => e.detail);
      message += `: ${serverErrors.join(', ')}`;
    }
    setErrors([message]);
  };

  const setBatchDataAndMergedTimestamps = (schedule: BatchSchedule[]) => {
    const seenItems: Map<string, BatchSchedule> = new Map();
    schedule.forEach((scheduleData) => {
      const time = `${scheduleData.hourOfDay} : ${scheduleData.minuteOfHour}`;

      if (!seenItems.has(time)) {
        seenItems.set(time, scheduleData);
      } else {
        seenItems.get(time).daysOfWeek.push(...scheduleData.daysOfWeek);
      }
    });

    const updatedObjects = Array.from(seenItems.values());
    setBatchScheduleData(updatedObjects);
  };

  const currentDate = () => {
    const momentDate = moment().format('dddd, MMMM Do YYYY');
    return momentDate;
  };

  const todayDate = new Date(Date.now());

  const updatedTime = (scheduleData: BatchSchedule) => {
    const time = new Date(0, 0, 0, scheduleData.hourOfDay, scheduleData.minuteOfHour, 0, 0);
    const momentTime = moment(time);
    return momentTime.format('h:mm a');
  };

  const renderedSchedule = () => {
    const rendered = batchScheduleData.map((scheduleData) => {
      const sortedDaysOfWeek = [];
      batchDayOptions.forEach((day) => {
        if (scheduleData.daysOfWeek.includes(day.value)) {
          sortedDaysOfWeek.push(day.value);
        }
      });

      return (
        <tr key={scheduleData.id}>
          <td className="listed-times">{updatedTime(scheduleData)}</td>
          <td className="capitalize">{sortedDaysOfWeek.map((day: string) => `${day}`).join(', ')}</td>
          <td className="float-right">
            {props.showAddNewButton && (
              <CustomKebabMenu options={createOptions(scheduleData.id)} displayBeside={true} />
            )}
          </td>
        </tr>
      );
    });
    return rendered;
  };

  const createOptions = (id: string): KebabMenuOption[] => {
    const options: KebabMenuOption[] = [];

    const deleteOption: KebabMenuOption = {
      optionText: 'Delete',
      optionAction: () => deleteBatchSchedule(id),
      isActionDangerous: true
    };

    options.push(deleteOption);
    return options;
  };

  const displaySchedulingOptions = () => {
    return (
      <div className="add-schedule">
        <p>Add a new batch schedule</p>
        <div className="batch-schedule-form">
          <div data-testid="times-dropdown" className="form">
            {batchScheduleTimesDropdown()}
          </div>
          <div data-testid="days-dropdown" className="form">
            {daysOfWeekDropdown()}
          </div>
          <div className="form-action">
            <button data-testid="add-button" className="btn btn-primary" onClick={() => createBatchSchedule()}>
              Add
            </button>
          </div>
          <div className="form-action">
            <a data-testid="cancel-link" onClick={() => cancelCreate()}>
              Cancel
            </a>
          </div>
        </div>
      </div>
    );
  };

  const cancelCreate = () => {
    setShowBatchSchedulingForm(false);
    setSelectedTime('');
    setSelectedDays([]);
    setErrors([]);
    setSuccess('');
  };

  return (
    <LegacyModal
      id="batch-scheduling-modal"
      title={
        <div className="batch-scheduling-modal-title">
          <span className="modal-title">Batch Schedule</span>
          {props.showAddNewButton && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setShowBatchSchedulingForm(true);
                setSuccess('');
              }}
            >
              Add New
            </button>
          )}
        </div>
      }
      show={props.showBatchSchedulingModal}
      size="medium"
      toggleModal={props.closeBatchSchedulingModal}
      disableClose={false}
    >
      <div>
        <div className="date">{currentDate()}</div>
        <div
          onClick={() => {
            setErrors([]);
            setSuccess('');
          }}
        >
          {reservationDropdown()}
        </div>
        {showBatchSchedulingForm && displaySchedulingOptions()}
        {errors && <p className="error-message">{errors}</p>}
        {success && <p className="success-message">{success}</p>}
        {batchScheduleData && batchScheduleData.length > 0 ? (
          <div className="batch-schedule-list">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Time</th>
                  <th scope="col">Day of Week</th>
                </tr>
              </thead>
              <tbody>{renderedSchedule()}</tbody>
            </table>
          </div>
        ) : (
          <div>
            <p>
              To find out more about batch schedules, contact{' '}
              <a href="mailto:support@flexe.com" target="_blank">
                support@flexe.com
              </a>
              .
            </p>
          </div>
        )}
      </div>
    </LegacyModal>
  );
};

export default BatchSchedulingModal;
