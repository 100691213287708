import * as React from 'react';
import StatusDot from './StatusDot';

interface Props {
  title: string;
  status: string;
  count?: number;
  active?: boolean;
}

const StatusTab: React.FC<Props> = (props) => {
  return (
    <span className="status-tab">
      <StatusDot status={props.status as React.ComponentProps<typeof StatusDot>['status']} />
      <span className="status-label">{props.title}</span>
      <span className="status-count" data-testid={`${props.status}-count`}>
        {props.count}
      </span>
    </span>
  );
};

export default StatusTab;
