import * as React from 'react';
import {FunctionComponent} from 'react';
import {cloneDeep, get} from 'lodash';
import {format as formatDate} from 'date-fns';
import {Inventory, Packaging, WarehouseLocation} from '../CommonInterfaces';
import FlexeButton from '../FlexeButton';
import {Item} from '../../shared/services/ItemMasterService';
import {calculateDeltaForCountedItem, isPlaceholderItem} from './helpers';
import {CycleCountItem, CycleCountQuantities, CycleCountStatus} from './CycleCountInterfaces';

interface Props {
  item: CycleCountItem;
  ccState: string;
  isEditing: boolean;
  byLocation: boolean;
  viewByLocation: boolean;
  forShipper: boolean;
  enableLpnCycleCounts: boolean;
  toggleLpnDetailsModalWithData: (data: string) => void;
  toggleLpnDetailsModalWithEvent: (event: any) => void;
  toggleItemDetailsModalWithId: (id: string) => void;
  onUpdateItem: (item: CycleCountItem) => void;
  enableLpnPropertiesCCButton: boolean;
  editMetadataOnLooseGoods: boolean;
  enableMobileCountConfiguredUomUpdates: boolean;
  inventoryInfoForCCItem: Item[];
}

const CycleCountItemListItem: FunctionComponent<Props> = (props) => {
  const buildRowData = () => {
    if (props.ccState === CycleCountStatus.new || props.ccState === CycleCountStatus.pendingStart) {
      return getUnstartedCountRowData();
    } else if (isPlaceholder()) {
      return getDummyRowData();
    } else if (props.isEditing) {
      return getEditableRowData(handleItemUpdate);
    } else {
      return getStaticRowData();
    }
  };

  // ---------------------------------
  // get the various kinds of row data
  // ---------------------------------
  const getBasicRowData = () => {
    return {
      identifier: getIdentifier(),
      user: getTimeAwareActor(),
      lpnBarcode: props.enableLpnCycleCounts && getLpnBarcode(),
      actualCartons: getDefaultCell(),
      actualEaches: getDefaultCell(),
      expectedCartons: getDefaultCell(),
      expectedEaches: getDefaultCell(),
      totalActualEaches: getDefaultCell(),
      totalExpectedEaches: getDefaultCell(),
      difference: getDefaultCell(),
      editProperties: getEditProperties()
    };
  };

  const getEditProperties = () => {
    let metadataIsDisabled = false;
    if (props.inventoryInfoForCCItem && props.inventoryInfoForCCItem.length > 0) {
      metadataIsDisabled =
        props.inventoryInfoForCCItem[0].lotCodeTrackingEnabled === false &&
        props.inventoryInfoForCCItem[0].expiryDateTrackingEnabled === false;
    } else {
      metadataIsDisabled = true;
    }
    if (
      (props.ccState === CycleCountStatus.inProgress || props.ccState === CycleCountStatus.inReview) &&
      props.item.lpnBarcode
    ) {
      return (
        <td className={'lpn-barcode'}>
          <FlexeButton
            text="Edit Properties"
            testid={props.item.lpnBarcode}
            handleClick={props.toggleLpnDetailsModalWithEvent}
            isDisabled={metadataIsDisabled}
          />
        </td>
      );
    } else if (
      (props.ccState === CycleCountStatus.inProgress || props.ccState === CycleCountStatus.inReview) &&
      props.item.metadata?.expected?.id &&
      props.editMetadataOnLooseGoods
    ) {
      return (
        <td className={'lpn-barcode'}>
          <FlexeButton
            text="Edit Properties"
            testid={props.item.metadata.expected.id.toString()}
            handleClick={() => {
              props.toggleItemDetailsModalWithId(props.item.metadata.expected.id.toString());
            }}
            isDisabled={metadataIsDisabled}
          />
        </td>
      );
    }
  };

  const getDummyRowData = () => {
    return convert(getBasicRowData());
  };

  const getUnstartedCountRowData = () => {
    const rowData = getBasicRowData();

    rowData.expectedCartons = getStaticQuantity(props.item.expectedQuantities, Packaging.carton);
    rowData.expectedEaches = getStaticQuantity(props.item.expectedQuantities, Packaging.each);

    const [, totalExpectedEaches] = getTotals();
    rowData.totalExpectedEaches = totalExpectedEaches;

    return convert(rowData);
  };

  const getEditableRowData = (onChangeFn: (event) => void) => {
    const rowData = getBasicRowData();

    rowData.actualCartons = getEditableQuantity(Packaging.carton, onChangeFn);
    rowData.actualEaches = getEditableQuantity(Packaging.each, onChangeFn);

    rowData.expectedCartons = getStaticQuantity(props.item.expectedQuantities, Packaging.carton);
    rowData.expectedEaches = getStaticQuantity(props.item.expectedQuantities, Packaging.each);

    const [totalActualEaches, totalExpectedEaches, difference] = getTotals();
    rowData.totalActualEaches = totalActualEaches;
    rowData.totalExpectedEaches = totalExpectedEaches;
    rowData.difference = difference;

    return convert(rowData);
  };

  const getStaticRowData = () => {
    const rowData = getBasicRowData();

    // fill in "actual" counts with -- if they aren't provided
    rowData.actualCartons = getStaticQuantityIfPresent(props.item.countedQuantities, Packaging.carton);
    rowData.actualEaches = getStaticQuantityIfPresent(props.item.countedQuantities, Packaging.each);

    // fill in "expected" counts always with a 0
    rowData.expectedCartons = getStaticQuantity(props.item.expectedQuantities, Packaging.carton);
    rowData.expectedEaches = getStaticQuantity(props.item.expectedQuantities, Packaging.each);

    const [totalActualEaches, totalExpectedEaches, difference] = getTotals();
    rowData.totalActualEaches = totalActualEaches;
    rowData.totalExpectedEaches = totalExpectedEaches;
    rowData.difference = difference;

    return convert(rowData);
  };

  // -----------------------------------------------------
  // helper functions for building and formatting row data
  // -----------------------------------------------------
  const isPlaceholder = () => isPlaceholderItem(props.item);
  const getDefaultCell = () => <td>&mdash;</td>;
  const getStaticQuantity = (qty: CycleCountQuantities, key: Packaging) => {
    return getQuantity(qty[key] || 0);
  };

  const getTotals = () => {
    const [showDelta, totalCountedEaches, totalExpectedEaches, delta] = calculateDeltaForCountedItem(
      props.item,
      props.inventoryInfoForCCItem,
      props.enableMobileCountConfiguredUomUpdates
    );

    const totalCountedElement = getQuantity(totalCountedEaches);
    const totalExpectedElement = getQuantity(totalExpectedEaches);
    const renderDelta = (diff) => {
      if (diff > 0) {
        return (
          <td>
            <b className="red2">&#43;{diff}</b>
          </td>
        );
      } else if (diff < 0) {
        return (
          <td>
            <b className="red2">&#45;{Math.abs(diff)}</b>
          </td>
        );
      } else {
        return getQuantity(diff);
      }
    };
    const deltaElement = showDelta === 0 ? getDefaultCell() : renderDelta(delta);
    return [totalCountedElement, totalExpectedElement, deltaElement];
  };

  const getEditableQuantity = (key: Packaging, onChangeFn: (event) => void) => {
    const qty = props.item.countedQuantities;
    return (
      <td className={'input quantity'}>
        <input
          type="number"
          min="0"
          value={qty[key] !== undefined ? qty[key] : ''}
          data-inventory-id={get(props.item, 'inventory.id')}
          data-location-id={get(props.item, 'location.id')}
          data-unit={key}
          data-testid={`${get(props.item, 'location.id')}-${get(props.item, 'inventory.id')}-${key}`}
          onChange={onChangeFn}
        />
      </td>
    );
  };

  const getStaticQuantityIfPresent = (qty: CycleCountQuantities, key: Packaging) => {
    return qty[key] === undefined ? getDefaultCell() : getStaticQuantity(qty, key);
  };

  const getQuantity = (qty: number) => <td className={'quantity'}>{qty}</td>;
  const getInventory = (inv: Inventory) => {
    if (isPlaceholder() && inv.id === undefined) {
      return getDefaultCell();
    }

    const linkPrefix = props.forShipper ? 's' : 'wh';
    const skuLine = `${inv.sku || get(inv, 'itemCode', '')}:`;
    return (
      <td className={'identifier'}>
        <a target="_blank" href={`/${linkPrefix}/inventories/${inv.id}`} tabIndex={-1}>
          {skuLine}
          <br />
          {inv.description}
        </a>
      </td>
    );
  };

  const getLocation = (loc: WarehouseLocation) => {
    if (isPlaceholder() && loc.id === undefined) {
      return getDefaultCell();
    }

    return props.forShipper ? (
      <td className={'identifier'}>{loc.label}</td>
    ) : (
      <td className={'identifier'}>
        <a target="_blank" href={`/wh/locations/${loc.id}`} tabIndex={-1}>
          {loc.label}
        </a>
      </td>
    );
  };

  const getIdentifier = () => {
    return (
      <React.Fragment>
        {props.viewByLocation ? getInventory(props.item.inventory) : getLocation(props.item.location)}
      </React.Fragment>
    );
  };

  const getTimeAwareActor = () => {
    const twa = props.item.counted;
    const user = get(twa, 'by.name');
    const time = get(twa, 'at') ? formatDate(twa.at, 'MM/DD/YY h:mma') : null;
    return user && time ? (
      <td className={'user'}>
        {user}:<br />
        {time}
      </td>
    ) : (
      getDefaultCell()
    );
  };

  const getLpnBarcode = () => {
    const linkPrefix = props.forShipper ? 's' : 'wh';
    if (
      props.ccState === CycleCountStatus.new ||
      props.ccState === CycleCountStatus.pendingStart ||
      props.ccState === CycleCountStatus.completed ||
      props.enableLpnPropertiesCCButton
    ) {
      return (
        <td className={'lpn-barcode'}>
          <a target="_blank" href={`/${linkPrefix}/lpns/${props.item.lpnBarcode}`}>
            {props.item.lpnBarcode}
          </a>
        </td>
      );
    } else {
      return (
        <td className={'lpn-barcode'}>
          <FlexeButton
            text={props.item.lpnBarcode}
            testid={props.item.lpnBarcode}
            level={'collapsed-link'}
            handleClick={props.toggleLpnDetailsModalWithEvent}
          />
        </td>
      );
    }
  };

  // --------------------
  // item update function
  // --------------------
  const handleItemUpdate = (event) => {
    const target = event.currentTarget;
    const unit = target.getAttribute('data-unit');
    const qty = target.value;
    const matched: CycleCountItem = cloneDeep(props.item);
    if (qty === '') {
      delete matched.countedQuantities[unit];
    } else {
      matched.countedQuantities[unit] = parseInt(qty, 10);
    }
    props.onUpdateItem(matched);
  };

  // -----------------------------------------
  // construct the array of elements to return
  // -----------------------------------------
  const convert = (rowData) => {
    if (props.enableLpnCycleCounts && !props.enableLpnPropertiesCCButton && props.item.lpnBarcode) {
      return (
        <React.Fragment>
          {rowData.lpnBarcode}
          {rowData.user}
          {rowData.identifier}
          {rowData.actualCartons}
          {rowData.expectedCartons}
          {rowData.actualEaches}
          {rowData.expectedEaches}
          {rowData.totalActualEaches}
          {rowData.totalExpectedEaches}
          {rowData.difference}
        </React.Fragment>
      );
    } else if (props.enableLpnCycleCounts && props.enableLpnPropertiesCCButton && props.item.lpnBarcode) {
      return (
        <React.Fragment>
          {rowData.lpnBarcode}
          {rowData.user}
          {rowData.identifier}
          {rowData.actualCartons}
          {rowData.expectedCartons}
          {rowData.actualEaches}
          {rowData.expectedEaches}
          {rowData.totalActualEaches}
          {rowData.totalExpectedEaches}
          {rowData.difference}
          {rowData.editProperties}
        </React.Fragment>
      );
    } else {
      if (props.item.metadata && props.editMetadataOnLooseGoods) {
        return (
          <React.Fragment>
            {rowData.identifier}
            {rowData.user}
            {rowData.actualCartons}
            {rowData.expectedCartons}
            {rowData.actualEaches}
            {rowData.expectedEaches}
            {rowData.totalActualEaches}
            {rowData.totalExpectedEaches}
            {rowData.difference}
            {rowData.editProperties}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {rowData.identifier}
            {rowData.user}
            {rowData.actualCartons}
            {rowData.expectedCartons}
            {rowData.actualEaches}
            {rowData.expectedEaches}
            {rowData.totalActualEaches}
            {rowData.totalExpectedEaches}
            {rowData.difference}
          </React.Fragment>
        );
      }
    }
  };

  return buildRowData();
};

export default CycleCountItemListItem;
