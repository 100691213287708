import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import OutboundOrders from '../outbound-orders/OutboundOrders';
import OutboundOrderDetail from '../outbound-orders/OutboundOrderDetail';
import OutboundOrdersService from '../outbound-orders/OutboundOrdersService';
import {Company} from '../../shared/CommonInterfaces';
import OutboundOrdersManualCreate from '../outbound-orders/OutboundOrdersManualCreate';
import OutboundOrdersV2 from '../outbound-orders/search-page/OutboundOrdersV2';
import {OMSFeatureFlags, OMSReservation} from './OrdersAppInterfaces';

interface AppProps {
  authenticityToken: string;
  currentCompany: Company;
  currentEnvironment: string;
  omsReservations: OMSReservation[];
  featureFlags: OMSFeatureFlags;
}

function EcommerceOrdersApp(props: AppProps) {
  const outboundOrdersService = new OutboundOrdersService();
  const adminSignedIn = window.localStorage.getItem('adminSignedIn') === 'true';

  // TODO Remove these in next step of using omsReservations as source of truth
  const temporaryProps = {
    shipperHasOmniReservation: props.omsReservations.some((r) => r.omniEnabled),
    distributionByLotReservationIds: props.omsReservations
      .filter((r) => r.distributionByLot)
      .map((r) => r.id.toString())
  };
  return (
    <Router>
      <Switch>
        {
          <Route
            path="/s/fulfillment/orders"
            exact
            render={(routeProps) =>
              props.featureFlags.showNewOrdersPage ? (
                <OutboundOrdersV2
                  {...routeProps}
                  {...props}
                  {...temporaryProps}
                  outboundOrdersService={outboundOrdersService}
                />
              ) : (
                <OutboundOrders
                  {...routeProps}
                  {...props}
                  {...temporaryProps}
                  outboundOrdersService={outboundOrdersService}
                />
              )
            }
          />
        }
        {
          <Route
            path="/s/fulfillment/orders/new"
            exact
            render={(routeProps) => (
              <OutboundOrdersManualCreate {...routeProps} {...props} outboundOrdersService={outboundOrdersService} />
            )}
          />
        }
        {
          <Route
            path="/s/fulfillment/orders/:id"
            render={(routeProps) => (
              <OutboundOrderDetail
                {...routeProps}
                {...props}
                {...temporaryProps}
                adminSignedIn={adminSignedIn}
                outboundOrdersService={outboundOrdersService}
              />
            )}
          />
        }
      </Switch>
    </Router>
  );
}
export default EcommerceOrdersApp;
