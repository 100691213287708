import {Reservation} from '../../shared/CommonInterfaces';

export interface ReservationActivity {
  reservation: Reservation;
  dropoffs: {
    container: number;
    palletized: number;
  };
  pickups: {
    palletized: number;
  };
}

export enum FulfillmentType {
  order = 'order',
  shipment = 'shipment'
}

export enum FulfillmentEventType {
  create = 'created',
  complete = 'completed'
}

export enum FulfillmentWindow {
  past24Hours = 'past24Hours',
  past7Days = 'past7Days',
  past12Weeks = 'past12Weeks'
}

export interface HistogramCount {
  lowerBound: string;
  count: number;
}

export interface HistogramEntries {
  items: HistogramCount[];
}

export interface HistogramChartData {
  x: string;
  y: number;
}
