import * as React from 'react';
import {useState} from 'react';
import * as ReactTooltip from 'react-tooltip';
import {AttachmentType, CreateOrderAttachmentRequest} from '../OutboundOrdersInterfaces';
import OutboundOrdersService from '../OutboundOrdersService';
import FileStorageService from '../../file-storage/FileStorageService';
import {generateFileKey} from '../helpers/Attachments';
import {AttachmentTableRow} from './AttachmentTableRow';

interface Props {
  attachmentRows: AttachmentRow[];
  setAttachmentRows?: (rows: AttachmentRow[]) => any;
}

export interface AttachmentRow {
  rowKey: number;
  file: File;
  type?: AttachmentType;
  displayName: string;
  externalId: string;
  trackingNumber: string;
  fileKey?: string;
}

const typeTooltip = (
  <ReactTooltip id="typeHeader" place="top" effect="solid" multiline>
    Only one Shipping Label may be attached.
    <br />
    Multiple Insert attachments are allowed.
  </ReactTooltip>
);

const trackingNumberTooltip = (
  <ReactTooltip id="trackingNumberHeader" place="top" effect="solid" multiline>
    Required when type is Shipping Label.
    <br />
    Not allowed for other types.
  </ReactTooltip>
);

function getColumnCount(isReadOnly: boolean): number {
  return isReadOnly ? 5 : 6;
}

function getColumnHeaders(isReadOnly: boolean): JSX.Element[] {
  if (isReadOnly) {
    // Don't return required markers or deletion button
    return [
      <th key={1} className="col-md-2">
        File
      </th>,
      // No need for attachment-type-header since there is no blank option to handle
      <th key={2} className="col-md-2">
        Type
      </th>,
      <th key={3} className="col-md-3">
        Display Name
      </th>,
      <th key={4} className="col-md-2">
        External ID
      </th>,
      <th key={5} className="col-md-3">
        Tracking Number
      </th>
    ];
  }

  // Note that tooltips won't appear directly over the icon.
  // I think that's a bit more usable personally, even if the bubble looks a little offset.
  return [
    <th key={1} className="col-md-2">
      File
    </th>,
    <th key={2} className="col-md-2 attachment-type-header" data-tip={true} data-for="typeHeader">
      *Type &#9432;{typeTooltip}
    </th>,
    <th key={3} className="col-md-2">
      *Display Name
    </th>,
    <th key={4} className="col-md-2">
      External ID
    </th>,
    <th key={5} className="col-md-3" data-tip={true} data-for="trackingNumberHeader">
      *Tracking Number &#9432;{trackingNumberTooltip}
    </th>,
    <th key={6} className="col-md-1"></th> // Empty column for deletion button
  ];
}

/* Builds a row to use for the "add attachment" button */
function makeAddAttachmentRow(rowKey: number): AttachmentRow {
  return {
    rowKey,
    file: null,
    type: undefined,
    displayName: '',
    externalId: '',
    trackingNumber: ''
  };
}

export const AttachmentTable: React.FC<Props> = (props) => {
  /*
   * addAttachmentRow is a version of AttachmentTableRow that is used solely for the add attachment button
   *  It has two key benefits:
   *      1) It lets us create an absolutely unique rowKey which helps react out
   *      2) We can then reuse the rowKey so react thinks the add attachment button is the
   *          same row. This is more accessible since tabbing will go to the net column in the row
   *          of the added file.
   */

  // Seed a positive rowKey since 0 is falsy
  const [addAttachmentRow, setAddAttachmentRow] = useState<AttachmentRow>(makeAddAttachmentRow(1));
  const isReadOnly = !props.setAttachmentRows;

  const onAddedFile = (attachmentRow: AttachmentRow) => {
    if (props.setAttachmentRows && attachmentRow.file) {
      // Force the new row to insert if shipping label is already used
      if (props.attachmentRows.find((testAttachmentRow) => testAttachmentRow.type === 'shipping_label')) {
        attachmentRow.type = 'insert';
      }
      props.setAttachmentRows(props.attachmentRows.concat(attachmentRow));
      setAddAttachmentRow(makeAddAttachmentRow(attachmentRow.rowKey + 1));
    }
  };

  const onDeleteRow = (rowKey: number) => {
    if (props.setAttachmentRows) {
      const index = props.attachmentRows.findIndex((attachmentRow) => attachmentRow.rowKey === rowKey);
      if (index >= 0) {
        const newRows = [...props.attachmentRows];
        newRows.splice(index, 1);
        props.setAttachmentRows(newRows);
      }
    }
  };

  const onModifyRow = (newAttachmentRow: AttachmentRow, setTypeToShippingLabel?: boolean) => {
    if (props.setAttachmentRows) {
      // Row key should not change
      const updateIndex = props.attachmentRows.findIndex(
        (attachmentRow) => attachmentRow.rowKey === newAttachmentRow.rowKey
      );
      if (updateIndex >= 0) {
        const newRows = [...props.attachmentRows];

        // If we set a row to shipping label, force everything else to not be shipping label
        if (setTypeToShippingLabel) {
          for (let loopIndex = 0; loopIndex < newRows.length; loopIndex++) {
            if (updateIndex !== loopIndex && newRows[loopIndex].type !== 'insert') {
              newRows[loopIndex] = {...newRows[loopIndex], type: 'insert'};
            }
          }
        }

        newRows[updateIndex] = newAttachmentRow;
        props.setAttachmentRows(newRows);
      }
    }
  };

  // Basic variables
  const countOfExistingRows = props.attachmentRows ? props.attachmentRows.length : 0;
  const columnHeaders = getColumnHeaders(isReadOnly);
  const columnCount = getColumnCount(isReadOnly);

  // Only one shipping label is allowed
  const shippingLabelAttachmentRow = props.attachmentRows.find(
    (attachmentRow) => attachmentRow.type === 'shipping_label'
  );
  const shippingLabelRowKey = shippingLabelAttachmentRow ? shippingLabelAttachmentRow.rowKey : undefined;

  return (
    <table className="table">
      {countOfExistingRows > 0 && (
        <thead>
          <tr>{columnHeaders}</tr>
        </thead>
      )}
      <tbody>
        {props.attachmentRows.map((attachmentRow) => (
          <AttachmentTableRow
            key={attachmentRow.rowKey}
            isReadOnly={isReadOnly}
            attachmentRow={attachmentRow}
            shippingLabelRowKey={shippingLabelRowKey}
            countOfExistingRows={countOfExistingRows}
            columnCount={columnCount}
            onModifyRow={onModifyRow}
            onDeleteRow={onDeleteRow}
          />
        ))}
        {!isReadOnly && (
          <AttachmentTableRow
            key={addAttachmentRow.rowKey}
            isReadOnly={isReadOnly}
            attachmentRow={addAttachmentRow}
            shippingLabelRowKey={shippingLabelRowKey}
            countOfExistingRows={countOfExistingRows}
            columnCount={columnCount}
            onAddedFile={onAddedFile}
          />
        )}
      </tbody>
    </table>
  );
};

/****************
 * The rest of this is helper functions to perform the actual upload
 ****************/

function buildAttachmentRowErrorLabel(attachmentRow: AttachmentRow, index: number): string {
  if (attachmentRow.displayName) {
    return attachmentRow.displayName;
  } else if (attachmentRow.file) {
    return attachmentRow.file.name;
  }

  return `File on line ${index + 1}`;
}

function buildErrorDetail(label: string, detail?: string) {
  return detail ? `${label}: ${detail}` : label;
}

// Should be called prior to attempting upload
export function validateAttachmentRows(attachmentRows: AttachmentRow[]): string[] {
  const errors: string[] = [];
  attachmentRows.forEach((attachmentRow, index) => {
    if (
      !attachmentRow.displayName ||
      !attachmentRow.file ||
      !attachmentRow.type ||
      (attachmentRow.type === 'shipping_label' && !attachmentRow.trackingNumber)
    ) {
      errors.push(buildErrorDetail(buildAttachmentRowErrorLabel(attachmentRow, index)));
    }
  });

  return errors;
}

// Must call validation prior to this function.
// This just puts the files into the database (does not associate them with the order)
export async function uploadAttachmentRows(
  attachmentRows: AttachmentRow[],
  setAttachmentRows: (attachmentRows) => void
): Promise<string[]> {
  // If no rows, we're done
  if (attachmentRows.length < 1) {
    return [];
  }

  const errors: string[] = [];
  const fileStorageService = new FileStorageService();
  let madeUpload = false;
  let index = -1;
  for (const attachmentRow of attachmentRows) {
    index++;

    // A file key is our indication that it has been uploaded already
    if (attachmentRow.fileKey) {
      continue;
    }

    const fileKey = generateFileKey(attachmentRow.displayName);
    const errorDetail = await uploadFile(fileStorageService, fileKey, attachmentRow.file);

    if (errorDetail) {
      errors.push(buildErrorDetail(buildAttachmentRowErrorLabel(attachmentRow, index), errorDetail));
    } else {
      madeUpload = true;
      // Note this will no longer work as coded if we allow users to change a file,
      //  rather that just delete and re-add
      attachmentRow.fileKey = fileKey;
    }
  }

  if (madeUpload) {
    setAttachmentRows([...attachmentRows]);
  }

  return errors;
}

// Performs an actual file upload
async function uploadFile(fileStorageService: FileStorageService, fileKey: string, file: File): Promise<string> {
  if (!fileKey) {
    return 'File key was not generated';
  }
  if (!file) {
    return 'File was not provided';
  }

  const fileStorageResponse = await fileStorageService.uploadFile(file, fileKey);
  if (fileStorageResponse.status !== 200) {
    if (fileStorageResponse.data && fileStorageResponse.data.errors && fileStorageResponse.data.errors.length > 0) {
      return fileStorageResponse.data.errors.map((e) => e.detail).join(', ');
    } else {
      return 'Unexpected file storage API error';
    }
  }

  return '';
}

// Adds attachments to orders
export async function AddAttachments(
  outboundOrdersService: OutboundOrdersService,
  orderId: string,
  attachmentRows: AttachmentRow[]
): Promise<string[]> {
  if (attachmentRows.length < 1) {
    return [];
  }

  if (!outboundOrdersService || !orderId) {
    return ['Attachments could not be added to the order due to an internal error'];
  }

  const errors: string[] = [];
  let index = -1;
  for (const attachmentRow of attachmentRows) {
    index++;

    // Start with required properties
    const createOrderAttachmentRequest: CreateOrderAttachmentRequest = {
      type: attachmentRow.type,
      fileKey: attachmentRow.fileKey,
      documentType: 'file'
    };

    /* Add optional properties */
    if (attachmentRow.type === 'insert') {
      createOrderAttachmentRequest.fileGroup = 'Inserts';
    }
    if (attachmentRow.displayName) {
      createOrderAttachmentRequest.displayName = attachmentRow.displayName;
    }
    if (attachmentRow.externalId) {
      createOrderAttachmentRequest.externalId = attachmentRow.externalId;
    }
    if (attachmentRow.type === 'shipping_label' && attachmentRow.trackingNumber) {
      createOrderAttachmentRequest.trackingNumber = attachmentRow.trackingNumber;
    }

    const createAttachmentResponse = await outboundOrdersService.createOrderAttachment(
      orderId,
      createOrderAttachmentRequest
    );

    if (createAttachmentResponse.status !== 201) {
      let errorDetail: string;
      if (
        createAttachmentResponse.data &&
        createAttachmentResponse.data.errors &&
        createAttachmentResponse.data.errors.length > 0
      ) {
        errorDetail = createAttachmentResponse.data.errors.map((e) => e.detail).join(', ');
      } else {
        errorDetail = 'Unexpected file storage API error';
      }

      errors.push(buildErrorDetail(buildAttachmentRowErrorLabel(attachmentRow, index), errorDetail));
    }
  }

  return errors;
}
