import * as React from 'react';
import {get} from 'lodash';
import {addDays, addHours, addWeeks, format as formatDate, startOfHour, startOfToday, startOfWeek} from 'date-fns';
import * as moment from 'moment-timezone';
import {ApiResponse, Counts, FlexeColors, ResponseError, ResponseErrorV2} from '../../shared/CommonInterfaces';
import FulfillmentService from '../ecommerce-shipments/FulfillmentService';
import {DropDownOption} from '../../shared/DropDown';
import OutboundOrdersService from '../outbound-orders/OutboundOrdersService';
import {OrdersHistogramResponse, OutboundOrdersResponse} from '../outbound-orders/OutboundOrdersInterfaces';
import {ShipmentsResponse} from '../ecommerce-shipments/SharedInterfaces';
import {InternalAPIServiceV2Options} from '../../shared/services/InternalAPIServiceV2';
import {
  FulfillmentEventType,
  FulfillmentType,
  FulfillmentWindow,
  HistogramChartData,
  HistogramCount,
  HistogramEntries
} from './DashboardInterfaces';
import HistoryFulfillmentActivityCard from './HistoryFulfillmentActivityCard';
import CompletedFulfillmentActivityCard from './CompletedFulfillmentActivityCard';
import OpenFulfillmentActivityCard from './OpenFulfillmentActivityCard';

interface Props {
  authenticityToken: string;
  outboundOrdersService?: OutboundOrdersService;
  fulfillmentService?: FulfillmentService;
}

interface State {
  errorsOpenCounts: string[];
  errorsOrderIssueCount: string[];
  errorsShipmentCounts: string[];
  errorsCompletedCount: string[];
  errorsCreatedHistogram: string[];
  errorsCompletedHistogram: string[];
  loadingOpenCounts: boolean;
  loadingCompletedCounts: boolean;
  loadingCreatedHistogram: boolean;
  loadingCompletedHistogram: boolean;
  orderIssues: number;
  shipmentIssues: number;
  openCounts: Counts | number;
  completedTodayCounts: Counts | number;
  createdFulfillmentHistogramWindow: FulfillmentWindow;
  completedFulfillmentHistogramWindow: FulfillmentWindow;
  createdHistogramCounts: HistogramChartData[];
  completedHistogramCounts: HistogramChartData[];
  fulfillmentType: FulfillmentType;
}
const ordersUrl = '/s/fulfillment/orders';
const shipmentsUrl = '/s/fulfillment/ecommerce';
const returnAllErrors: InternalAPIServiceV2Options = {
  returnAllErrors: true
};

interface FulfillmentWindowMapping {
  interval: number;
  resultSize: number;
  end: () => string;
  dateFormat: string;
}
const fulfillmentWindowMappings: Record<FulfillmentWindow, FulfillmentWindowMapping> = {
  [FulfillmentWindow.past24Hours]: {
    interval: 1,
    resultSize: 24,
    end: () => addHours(startOfHour(new Date()).toISOString(), 1).toISOString(),
    dateFormat: 'HH:mm'
  },
  [FulfillmentWindow.past7Days]: {
    interval: 24,
    resultSize: 7,
    end: () => addDays(startOfToday().toISOString(), 1).toISOString(),
    dateFormat: 'MM/DD'
  },
  [FulfillmentWindow.past12Weeks]: {
    interval: 24 * 7,
    resultSize: 12,
    end: () => addWeeks(startOfWeek(new Date(), {weekStartsOn: 1}), 1).toISOString(),
    dateFormat: 'MM/DD'
  }
};

class FulfillmentActivity extends React.Component<Props, State> {
  private outboundOrdersService: OutboundOrdersService;
  private fulfillmentService: FulfillmentService;
  private thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30));

  constructor(props) {
    super(props);
    this.outboundOrdersService = props.ecommerceOrdersService || new OutboundOrdersService();
    this.fulfillmentService = props.fulfillmentService || new FulfillmentService(props.authenticityToken);
    this.state = {
      errorsOpenCounts: [],
      errorsOrderIssueCount: [],
      errorsShipmentCounts: [],
      errorsCompletedCount: [],
      errorsCreatedHistogram: [],
      errorsCompletedHistogram: [],
      loadingOpenCounts: true,
      loadingCompletedCounts: true,
      loadingCreatedHistogram: true,
      loadingCompletedHistogram: true,
      orderIssues: null,
      shipmentIssues: null,
      openCounts: null,
      completedTodayCounts: null,
      createdFulfillmentHistogramWindow: FulfillmentWindow.past24Hours,
      completedFulfillmentHistogramWindow: FulfillmentWindow.past24Hours,
      createdHistogramCounts: null,
      completedHistogramCounts: null,
      fulfillmentType: FulfillmentType.order
    };
  }

  public async componentDidMount() {
    this.getFulfillmentActivity();
  }

  public render() {
    return (
      <div className="space-below">
        {(this.state.orderIssues > 0 || this.state.shipmentIssues > 0) && this.renderBanners()}
        {this.renderHeader()}
        <div>
          {this.renderDefinitions()}
          {this.renderGauges()}
          {this.renderHistograms()}
        </div>
      </div>
    );
  }

  private renderBanners = () => {
    return (
      <div className="row space-below">
        <div className="col-sm-12">
          {this.state.orderIssues > 0 && this.renderOrderIssuesBanner()}
          {this.state.shipmentIssues > 0 && this.renderShipmentIssuesBanner()}
        </div>
      </div>
    );
  };

  private renderOrderIssuesBanner = () => {
    return (
      <div className="card col-sm-12 issue space-below">
        <span className="pull-left">{this.state.orderIssues} orders with issues</span>
        <a href={ordersUrl} className="pull-right">
          Go to Orders
        </a>
      </div>
    );
  };

  private renderShipmentIssuesBanner = () => {
    return (
      <div className="card col-sm-12 issue space-below">
        <span className="pull-left">{this.state.shipmentIssues} shipments with issues</span>
        <a href={shipmentsUrl} className="pull-right">
          Go to Shipments
        </a>
      </div>
    );
  };

  private renderHeader = () => {
    return (
      <div className="row space-below">
        <div className="col-sm-12">
          <h2 className="pull-left">eCommerce Fulfillment Activity</h2>
        </div>
        <div className="col-sm-12">
          <a
            className={`btn${this.state.fulfillmentType === FulfillmentType.order ? ' selected' : ''}`}
            data-type={FulfillmentType.order}
            onClick={this.setFulfillmentType}
          >
            <i className="fa fa-cube"></i>
            &nbsp; Orders
          </a>
          <a
            className={`btn${this.state.fulfillmentType === FulfillmentType.shipment ? ' selected' : ''}`}
            data-type={FulfillmentType.shipment}
            onClick={this.setFulfillmentType}
          >
            <i className="fa fa-cubes"></i>
            &nbsp; Shipments
          </a>
        </div>
      </div>
    );
  };

  private renderDefinitions = () => {
    let definition: string;
    if (this.state.fulfillmentType === FulfillmentType.order) {
      definition = 'Orders represent fulfillment requests, which may be split into multiple parcel shipments.';
    } else {
      definition = 'Shipments represent individual parcels, which fulfill an Order.';
    }
    return (
      <div key="definitions" className="row">
        <div className="col-sm-12 space-below grey3">
          <i>{definition}</i>
        </div>
      </div>
    );
  };

  private renderGauges = () => {
    const {
      fulfillmentType,
      loadingOpenCounts,
      loadingCompletedCounts,
      openCounts,
      orderIssues,
      completedTodayCounts,
      errorsOpenCounts,
      errorsCompletedCount,
      errorsOrderIssueCount
    } = this.state;
    let url: string;
    let title: string;
    let newCt = -1;
    let inProgressCt = -1;
    let totalOpen;
    let issuesCount;
    let completedCount;
    let errorsForOpenCard = errorsOpenCounts;
    if (this.state.fulfillmentType === FulfillmentType.shipment) {
      url = shipmentsUrl;
      title = 'Total';
      newCt = Number(get(openCounts, 'new') || 0);
      inProgressCt = Number(get(openCounts, 'inProgress') || 0);

      completedCount = Number(get(completedTodayCounts, 'completed') || 0);
      issuesCount = Number(get(openCounts, 'issues') || 0);
      totalOpen = issuesCount + newCt + inProgressCt;
    } else {
      url = ordersUrl;
      title = 'Open';
      totalOpen = Number(openCounts || 0);
      issuesCount = Number(orderIssues || 0);
      completedCount = Number(completedTodayCounts || 0);

      errorsForOpenCard = errorsForOpenCard.concat(errorsOrderIssueCount);
    }

    return (
      <a key="gauges" className="row today black space-below" href={url}>
        <div className="col-sm-8">
          <OpenFulfillmentActivityCard
            loading={loadingOpenCounts}
            fulfillmentType={fulfillmentType}
            titleDescriptor={title}
            errors={errorsForOpenCard}
            totalOpen={totalOpen}
            issuesCount={issuesCount}
            newCount={newCt}
            progressCount={inProgressCt}
          />
        </div>
        <div className="col-sm-4">
          <CompletedFulfillmentActivityCard
            loading={loadingCompletedCounts}
            fulfillmentType={fulfillmentType}
            completedCount={completedCount}
            errors={errorsCompletedCount}
          />
        </div>
      </a>
    );
  };

  private renderHistograms = () => {
    const {
      loadingCreatedHistogram,
      loadingCompletedHistogram,
      createdFulfillmentHistogramWindow,
      completedFulfillmentHistogramWindow,
      createdHistogramCounts,
      completedHistogramCounts,
      fulfillmentType,
      errorsCompletedHistogram,
      errorsCreatedHistogram
    } = this.state;

    return (
      <div className="row space-above">
        <div className="col-md-12 col-lg-6 space-below">
          <HistoryFulfillmentActivityCard
            loading={loadingCreatedHistogram}
            title="Created"
            color={FlexeColors.newPurple}
            fulfillmentType={fulfillmentType}
            histogramCounts={createdHistogramCounts}
            errors={errorsCreatedHistogram}
            currentTimeWindow={createdFulfillmentHistogramWindow}
            onSetTimeWindow={this.setCreatedFulfillmentHistogramWindow}
          />
        </div>
        <div className="col-md-12 col-lg-6 space-below">
          <HistoryFulfillmentActivityCard
            loading={loadingCompletedHistogram}
            title="Completed"
            color={FlexeColors.successGreen}
            fulfillmentType={fulfillmentType}
            histogramCounts={completedHistogramCounts}
            errors={errorsCompletedHistogram}
            currentTimeWindow={completedFulfillmentHistogramWindow}
            onSetTimeWindow={this.setCompletedFulfillmentHistogramWindow}
          />
        </div>
      </div>
    );
  };

  private getFulfillmentActivity = async () => {
    this.getCounts();
    this.getCreatedHistogram();
    this.getCompletedHistogram();
  };

  private getCounts = async () => {
    this.setState({loadingOpenCounts: true, loadingCompletedCounts: true});

    const openOrderIssueCountsPromise = this.getOrderIssueCount();
    const openShipmentCountsPromise = this.getOpenShipmentCounts();

    let openCountsPromise: Promise<[Counts | number, string[]]>;
    let completedCountsPromise: Promise<[Counts | number, string[]]>;
    if (this.state.fulfillmentType === FulfillmentType.order) {
      openCountsPromise = this.getOpenOrderCount();
      completedCountsPromise = this.getCompletedOrderCounts();
    } else {
      openCountsPromise = openShipmentCountsPromise;
      completedCountsPromise = this.getCompletedShipmentCounts();
    }

    const openIssueCountResponse = await openOrderIssueCountsPromise;
    const openShipmentCountsResponse = await openShipmentCountsPromise;
    const openCountsResponse = await openCountsPromise;
    const completedCountsResponse = await completedCountsPromise;

    this.setState({
      orderIssues: openIssueCountResponse[0],
      errorsOrderIssueCount: openIssueCountResponse[1],
      shipmentIssues: get(openShipmentCountsResponse[0], 'issues'),
      errorsShipmentCounts: openShipmentCountsResponse[1], // We won't use it, but tracking it for consistency
      completedTodayCounts: completedCountsResponse[0],
      errorsCompletedCount: completedCountsResponse[1],
      loadingOpenCounts: false,
      loadingCompletedCounts: false,
      openCounts: openCountsResponse[0],
      errorsOpenCounts: openCountsResponse[1]
    });
  };

  private getCreatedHistogram = async () => {
    this.setState({loadingCreatedHistogram: true});
    const response = await this.getHistogramData(
      FulfillmentEventType.create,
      this.state.createdFulfillmentHistogramWindow
    );
    this.setState({
      createdHistogramCounts: response[0],
      errorsCreatedHistogram: response[1],
      loadingCreatedHistogram: false
    });
  };

  private getCompletedHistogram = async () => {
    this.setState({loadingCompletedHistogram: true});
    const response = await this.getHistogramData(
      FulfillmentEventType.complete,
      this.state.completedFulfillmentHistogramWindow
    );
    this.setState({
      completedHistogramCounts: response[0],
      errorsCompletedHistogram: response[1],
      loadingCompletedHistogram: false
    });
  };

  // current counts of shipments in "open" states
  // This is probably a bug - it's returning only new shipments, instead of non-terminal shipments. -- Linnea
  private getOpenShipmentCounts = async () => {
    const response = await this.fulfillmentService.getShipments('new', {}, null, 0);
    return this.processShipmentsResponse(response);
  };

  private getOpenOrderCount = async () => {
    const response = await this.outboundOrdersService.getOrdersWithState(
      'open',
      {createdAtFrom: this.thirtyDaysAgo.toJSON()},
      null,
      0,
      '',
      returnAllErrors
    );
    return this.processOutboundOrderResponse(response);
  };

  private getOrderIssueCount = async () => {
    const response = await this.outboundOrdersService.getOrdersErrors(
      'open',
      {createdAtFrom: this.thirtyDaysAgo.toJSON()},
      null,
      0,
      '',
      returnAllErrors
    );
    return this.processOutboundOrderResponse(response);
  };

  private getCompletedShipmentCounts = async () => {
    const [startOfDay, endOfDay] = this.startAndEndOfToday();
    const response = await this.fulfillmentService.getShipments(
      'new',
      {completed_at_start: startOfDay, completed_at_end: endOfDay},
      null,
      0
    );
    return this.processShipmentsResponse(response);
  };

  private getCompletedOrderCounts = async (): Promise<[number, string[]]> => {
    const interval = 1;
    const resultSize = 24;
    const d = new Date();
    d.setHours(24, 0, 0, 0); //tomorrow @ midnight
    const end = d.toISOString();

    const histogramResponse = await this.outboundOrdersService.getHistograms(
      end,
      interval,
      resultSize,
      FulfillmentEventType.complete,
      returnAllErrors
    );
    const processedResponse = this.processHistogramResponse(histogramResponse);
    const rawCounts = processedResponse[0];
    const total = rawCounts ? rawCounts.map((item) => item.count).reduce((prev, curr) => prev + curr, 0) : -1;
    return [total, processedResponse[1]];
  };

  // start and end of the day on the client
  private startAndEndOfToday = () => {
    const m = moment();
    const startOfDay = m.startOf('day').format();
    const endOfDay = m.endOf('day').format();
    return [startOfDay, endOfDay];
  };

  private processShipmentsResponse = (response: ApiResponse<ShipmentsResponse>): [Counts, string[]] => {
    if (!response) {
      return [null, []];
    }

    const errors = this.buildErrorsFromResponse(response.errors);
    const counts = errors.length > 0 || !response.data ? null : response.data.counts;
    return [counts, errors];
  };

  private processHistogramResponse = (
    response: ApiResponse<HistogramEntries> | OrdersHistogramResponse
  ): [HistogramCount[], string[]] => {
    if (!response) {
      return [null, []];
    }

    const errors = this.buildErrorsFromResponse(response.errors);
    const counts = errors.length > 0 || !response.data ? [] : response.data.items;
    return [counts, errors];
  };

  private processOutboundOrderResponse = (response: OutboundOrdersResponse): [number, string[]] => {
    if (!response) {
      return [null, []];
    }

    const errors = this.buildErrorsFromResponse(response.errors);
    const count = errors.length > 0 ? -1 : response.total;
    return [count, errors];
  };

  private buildErrorsFromResponse(errors: ResponseError[] | ResponseErrorV2[]): string[] {
    if (!errors) {
      return [];
    }

    return errors.map((e) => e.detail || e.title);
  }

  private setFulfillmentType = (event) => {
    const fulfillmentType = event.currentTarget.getAttribute('data-type') as FulfillmentType;
    this.setState({fulfillmentType}, this.getFulfillmentActivity);
  };

  private setCreatedFulfillmentHistogramWindow = (option: DropDownOption) => {
    const createdFulfillmentHistogramWindow = option.value as FulfillmentWindow;
    this.setState({createdFulfillmentHistogramWindow}, this.getCreatedHistogram);
  };

  private setCompletedFulfillmentHistogramWindow = (option: DropDownOption) => {
    const completedFulfillmentHistogramWindow = option.value as FulfillmentWindow;
    this.setState({completedFulfillmentHistogramWindow}, this.getCompletedHistogram);
  };

  private getHistogramData = async (
    type: FulfillmentEventType,
    window: FulfillmentWindow
  ): Promise<[HistogramChartData[], string[]]> => {
    const {interval, resultSize, end} = fulfillmentWindowMappings[window];
    let histogramResponse: ApiResponse<HistogramEntries> | OrdersHistogramResponse;
    if (this.state.fulfillmentType === FulfillmentType.order) {
      histogramResponse = await this.outboundOrdersService.getHistograms(
        end(),
        interval,
        resultSize,
        type,
        returnAllErrors
      );
    } else {
      histogramResponse = await this.fulfillmentService.getHistograms(end(), interval, resultSize, type);
    }

    const processedResponse = this.processHistogramResponse(histogramResponse);
    const rawCounts = processedResponse[0];

    // Returns undefined when counts is falsy to prevent the widget from loading empty
    const countsAsChart = rawCounts && rawCounts.length > 0 ? this.countToChart(rawCounts, window) : undefined;
    return [countsAsChart, processedResponse[1]];
  };

  private countToChart = (counts: HistogramCount[], fulfillmentWindow: FulfillmentWindow) => {
    const dateFormat = fulfillmentWindowMappings[fulfillmentWindow].dateFormat;
    return counts
      .map((c: HistogramCount) => {
        return {
          x: formatDate(c.lowerBound, dateFormat),
          y: c.count
        } as HistogramChartData;
      })
      .reverse();
  };
}

export default FulfillmentActivity;
