import * as React from 'react';

interface Props {
  name: string;
  requiresAction: boolean;
}

const ConfirmationFormRow: React.FC<Props> = (props) => {
  const className = (): string => {
    if (props.requiresAction) {
      return 'confirmation-form-field required';
    } else {
      return 'confirmation-form-field';
    }
  };

  return (
    <React.Fragment>
      <div className={className()} data-testid={`modal-form-field-${props.name}`}>
        <div className="key">{props.name}</div>
        <div className="value">{props.children}</div>
      </div>
    </React.Fragment>
  );
};

export default ConfirmationFormRow;
