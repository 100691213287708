import * as React from 'react';
import SortableTableHeader from '../../../shared/SortableTableHeader';
import {SortOrder} from '../../../shared/CommonInterfaces';

interface ShipmentTableHeaderProps {
  columnsToHide: ColumnNames[]; // The columns that should be hidden
  enableSortableHeaders: boolean; // True if the caller wants the headers to be sortable
  onSortChanged: (sortState: SortState) => void;
  isFreightLoadGroupUIEnabled: boolean;
}

export type SortableColumnNames = 'shipmentId' | 'po';

export type ColumnNames =
  | SortableColumnNames
  | 'loadDetails'
  | 'orderId'
  | 'status'
  | 'carrier'
  | 'pro/track'
  | 'shipBy'
  | 'contents'
  | 'lpns'
  | 'destination'
  | 'remove';

export interface SortState {
  columnName?: SortableColumnNames;
  direction?: SortOrder;
}

const ShipmentTableHeader: React.FC<ShipmentTableHeaderProps> = (props) => {
  const [selectedSort, setSelectedSort] = React.useState<SortState>({});

  const shouldShowColumn = (columnName: ColumnNames) => {
    return !props.columnsToHide.includes(columnName);
  };

  const handleSortClick = (id: SortableColumnNames) => {
    const updatedSort = {
      columnName: id,
      direction: SortOrder.DESC
    };

    if (selectedSort.columnName === updatedSort.columnName) {
      updatedSort.direction = selectedSort.direction === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }

    // Set state to update button states
    setSelectedSort(updatedSort);
    // Propagate event so data is sorted.
    props.onSortChanged(updatedSort);
  };

  /**
   * Creates a column header that is sortable if configured to be so; otherwise it is just a regular header
   * @param columnName The id name of the column
   * @param displayText The text to display as the column's header text
   * @returns A React Fragment ready for the dom.
   */
  const createSortableColumn = (columnName: SortableColumnNames, displayText: string) => {
    return (
      <React.Fragment>
        {props.enableSortableHeaders && (
          <a
            className="sortable-header"
            onClick={() => {
              handleSortClick(columnName);
            }}
          >
            <SortableTableHeader
              headerText={displayText}
              isActive={selectedSort.columnName === columnName}
              sortDirection={selectedSort.direction}
            />
          </a>
        )}
        {!props.enableSortableHeaders && displayText}
      </React.Fragment>
    );
  };

  return (
    <thead className="shipment-table__header">
      <tr>
        {shouldShowColumn('shipmentId') && <th>{createSortableColumn('shipmentId', 'Shipment ID')}</th>}
        {shouldShowColumn('loadDetails') && !props.isFreightLoadGroupUIEnabled && <th>Destination ID</th>}
        {shouldShowColumn('orderId') && <th>Order ID</th>}
        {shouldShowColumn('po') && <th>{createSortableColumn('po', 'P.O.')}</th>}
        {shouldShowColumn('status') && <th>Status</th>}
        {shouldShowColumn('carrier') && <th>Carrier</th>}
        {shouldShowColumn('pro/track') && <th>PRO/Track #</th>}
        {shouldShowColumn('shipBy') && <th>Ship By</th>}
        {shouldShowColumn('contents') && <th>Contents</th>}
        {shouldShowColumn('lpns') && <th>LPNs</th>}
        {shouldShowColumn('destination') && <th>Destination</th>}
        {shouldShowColumn('loadDetails') && props.isFreightLoadGroupUIEnabled && <th>Load Details</th>}
        {shouldShowColumn('remove') && <th>{/* No display name */}</th>}
      </tr>
    </thead>
  );
};

export default ShipmentTableHeader;
