import {Modal} from '@flexe/ui-components';
import * as React from 'react';
import {Document, DocumentNotableType} from '../../shared/CommonInterfaces';
import DocumentManagerSmall from '../../shared/DocumentManagerSmall';
import FileDownloadLink from '../../shared/FileDownloadLink';
import {RetailOrderDetails} from '../../shared/services/RetailFulfillmentService';

interface HeaderPaneDocumentsProps {
  orderDetails: RetailOrderDetails;
  documents: Document[];
  onDocumentSavedHandler: (document: Document) => void;
  orderHasGeneratedLabels: boolean; // TODO: pull from API
  onErrorHandler: (error: string) => void;
}

export const HeaderPaneDocuments: React.FC<HeaderPaneDocumentsProps> = (props) => {
  const [showUploadModal, setShowUploadModal] = React.useState(false);
  const [showInstructionsModal, setShowInstructionsModal] = React.useState(false);

  const documentsAndLabels = (): React.ReactFragment => {
    return (
      <div id="documents-and-labels" className="col-md-3 quarters">
        <DocumentManagerSmall
          documents={props.documents}
          documentsRoute="/api/v2/documents"
          notableType={DocumentNotableType.retailFulfillmentOrder}
          notableId={props.orderDetails.id}
          showUploadModal={showUploadModal}
          toggleUploadModal={() => {
            setShowUploadModal(!showUploadModal);
          }}
          onDocumentSaved={props.onDocumentSavedHandler}
          warehouseTimeZone={props.orderDetails.warehouse.timezone}
          showDocumentCreationTime={true}
        />

        {instructions()}
        {trackingNumbers()}
      </div>
    );
  };

  const instructions = (): React.ReactFragment => {
    if (!props.orderDetails.instructions) {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <div>
        <table className="packing-list-table space-above space-below">
          <thead>
            <tr>
              <th>
                <b>Instructions</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p data-testid="instructions-p-dom">
                  {props.orderDetails.instructions.substring(0, 100)}
                  {props.orderDetails.instructions.length >= 100 && (
                    <React.Fragment>
                      ...
                      <br />
                      <a
                        onClick={() => {
                          setShowInstructionsModal(true);
                        }}
                      >
                        <i className="fa fa-chevron-down"></i> View More
                      </a>
                    </React.Fragment>
                  )}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <Modal
          isOpen={showInstructionsModal}
          toggleModal={() => setShowInstructionsModal(false)}
          title={`Shipper Instructions for Retail Order #${props.orderDetails.id}`}
          data-testid="instruction-modal-dom"
        >
          <p className="instruction-text-full">{props.orderDetails.instructions}</p>
        </Modal>
      </div>
    );
  };

  const trackingNumbers = (): React.ReactFragment => {
    if (!props.orderHasGeneratedLabels) {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <table className="packing-list-table space-above space-below">
        <thead>
          <tr>
            <th>
              <b>Tracking Numbers</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <FileDownloadLink
                href={`/wh/fulfillment/retail/${props.orderDetails.id}/tracking_number_csv`}
                text="Tracking Number CSV"
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return <React.Fragment>{documentsAndLabels()}</React.Fragment>;
};
