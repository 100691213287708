/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import classNames from 'classnames';
import {DetailedErrorsTable, DetailedInfoBox, InfoBox} from '@flexe/ui-components';
import {ReactNode} from 'react';
import {css, jsx} from '@emotion/react';
import {CsvDataError, GenericUploadError, ResponseError} from './CommonInterfaces';

export interface Props {
  responseErrors: ResponseError[];
  genericErrors: GenericUploadError[];
  csvErrors: CsvDataError[];
  errorBoxTitle?: string;
}

const CsvPostUploadStep: React.FC<Props> = (props: Props) => {
  const CsvPostUploadStepClasses = ['csv-post-upload-step'];

  const columnNameStyles = css({
    fontFamily: 'monospace',
    background: '#E3E7EC',
    padding: '4px',
    borderRadius: '5px'
  });

  const renderAffectedRows = (err: CsvDataError): ReactNode => {
    if (err.rows_affected.length < 6) {
      return err.rows_affected.map((rowNumber: number, i: number) => {
        return `${i > 0 ? ', ' : ''}${rowNumber + 1}`;
      });
    }
    return `${err.rows_affected[0] + 1}, ${err.rows_affected[1] + 1}, ${err.rows_affected[2] + 1} ... + ${err
      .rows_affected.length - 3} more`;
  };

  const renderCsvUploadErrorTableRow = (err: CsvDataError) => {
    return (
      <tr>
        <td>{err.name}</td>
        <td>
          <span css={columnNameStyles}>{err.column_name}</span> {err.message}
        </td>
        <td>{renderAffectedRows(err)}</td>
      </tr>
    );
  };

  const renderGenericErrorTableRow = (err: any) => {
    return (
      <tr>
        <td>{err.name}</td>
        <td>{err.message}</td>
      </tr>
    );
  };

  const renderResponseErrors = () => {
    return (
      <React.Fragment>
        <h3>Errors occurred during upload</h3>
        <ul>
          {props.responseErrors.map((err, i) => {
            return (
              <li key={`${i}-${err.title}`}>
                <span className="error-name">{err.title}</span>
                {err.detail}
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  };

  const renderUploadResponse = () => {
    return (
      <React.Fragment>
        {props.genericErrors.length > 0 || props.csvErrors.length > 0 ? (
          <DetailedInfoBox
            info={props.errorBoxTitle ? props.errorBoxTitle : 'Errors occurred during data processing'}
            infoType="error"
          >
            {props.genericErrors.length > 0 && (
              <DetailedErrorsTable
                details={props.genericErrors}
                renderInfoBoxDetailTableRow={renderGenericErrorTableRow}
              />
            )}
            {props.csvErrors.length > 0 && (
              <DetailedErrorsTable
                headers={['Error', 'Detail', 'Affected Rows']}
                details={props.csvErrors}
                renderInfoBoxDetailTableRow={renderCsvUploadErrorTableRow}
              />
            )}
          </DetailedInfoBox>
        ) : (
          <InfoBox info="Success! CSV uploaded without errors" infoType="success" />
        )}
      </React.Fragment>
    );
  };

  const renderGenericErrors = () => {
    return (
      <React.Fragment>
        <h3>
          <i className="fa fa-exclamation-triangle invalid" /> Errors occurred during data processing
        </h3>
        <ul className="error-list">
          {props.genericErrors.map((err, i) => {
            return (
              <li key={`${i}-${err.name}`} className="generic-error">
                <span className="error-name">{err.name}</span>
                <span className="error-msg">{err.message}</span>
              </li>
            );
          })}
          {props.csvErrors.map((err, i) => {
            return (
              <li key={`${i}-${err.name}`} className="csv-error">
                <span className="error-name">{err.name}</span>
                <span className="sub-error">
                  <span className="sub-error-title">{err.column_name}: </span>
                  <span className="sub-error-msg">{err.message}</span>
                </span>
                <span className="sub-error">
                  <span className="sub-error-title">affected rows: </span>
                  <ul className="sub-error-list">
                    {err.rows_affected.map((row, j) => {
                      return <li key={`${row}-${j}`}>{`${j > 0 ? ', ' : ''}${row}`}</li>;
                    })}
                  </ul>
                </span>
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  };

  return (
    <div className={classNames(CsvPostUploadStepClasses)}>
      {props.responseErrors && props.responseErrors.length ? (
        <DetailedInfoBox info={''} infoType={'info'}>
          <DetailedErrorsTable
            details={props.responseErrors}
            renderInfoBoxDetailTableRow={renderGenericErrorTableRow}
          />
        </DetailedInfoBox>
      ) : (
        renderUploadResponse()
      )}
    </div>
  );
};

export default CsvPostUploadStep;
