import InternalAPIServiceV2 from '../../shared/services/InternalAPIServiceV2';
import {ShipperUsers} from './ShipperInfoInterfaces';

class ShipperInfoService extends InternalAPIServiceV2 {
  constructor() {
    super('/v2/shipper-info');
  }

  public async getShipperUsers(companyId: string): Promise<ShipperUsers> {
    return await this.makeGetRequest(`${this.baseUrl}?company-id=${companyId}`, null, null, null, {
      returnAllErrors: true
    });
  }
}

export default ShipperInfoService;
