import {CsvErrorType, ValidationErrors} from '../shared/csv-upload/CsvUploadInterfaces';
import CsvUtil from '../shared/utilities/CsvUtil';

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
interface PalletizedDropoffRequest {
  moveDate: Date;
  shipmentId: string;
  trailerNumber: string;
  description: string;
  inventoryData: InventoryData[];
  errors: ValidationErrors;
}

interface InventoryData {
  sku: string;
  quantity: number;
  attachments?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
class PalletizedDropoffRequest implements PalletizedDropoffRequest {
  constructor(data) {
    this.errors = {};

    this.shipmentId = data.shipmentId || null;
    this.trailerNumber = data.trailerNumber || null;
    this.description = data.description || null;
    this.inventoryData = [];

    this.validateRequired(data);
    this.generateInventoryData(data);

    const {parsedMoveDate, moveDateError} = CsvUtil.validateDateTimes(data);

    this.moveDate = parsedMoveDate;
    if (moveDateError) {
      this.errors.moveDate = moveDateError;
    }
  }

  private validateRequired(data) {
    const fieldsToIgnore = ['trailerNumber', 'description'];
    for (const key in data) {
      if (fieldsToIgnore.indexOf(key) === -1 && data[key] === null) {
        this.errors[key] = CsvErrorType.Required;
      }
    }
  }

  private generateInventoryData(data) {
    if (data.sku && data.quantity) {
      this.inventoryData.push({
        quantity: data.quantity,
        sku: data.sku
      });
    } else {
      this.errors.inventoryData = CsvErrorType.Required;
    }
  }
}

export default PalletizedDropoffRequest;
