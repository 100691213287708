import * as React from 'react';
import * as moment from 'moment-timezone';
import {LegacyModal} from '@flexe/ui-components';
import {format} from 'date-fns';
import BatchWavingService from '../../shared/services/BatchWavingService';
import FlexeButton from '../../shared/FlexeButton';
import FlexeLinkButton from '../../shared/FlexeLinkButton';
import {PickMethodDisplay, TransitionStateDisplay} from '../../shared/constants';
import {TransitionState} from '../../shared/CommonInterfaces';
import {renderFulfillmentBatch} from '../../../libs/helpers';
import {BatchData, BatchDocument, BatchError} from './BatchInterfaces';
import BatchProgressBar from './BatchProgressBar';
import CompactBatchTileKebabMenu from './CompactBatchTileKebabMenu';

interface CompactBatchTileProps {
  batchData: BatchData;
  batchService: BatchWavingService;
  carriers: object;
  batchError?: BatchError;
  reservationDisplayName: string;
  handleBatchConfirm: (batchData) => void;
  handleBatchCancelled: () => void;
  usePackedTerms: boolean;
}

const CompactBatchTile: React.FC<CompactBatchTileProps> = (props) => {
  const {batchData, carriers, batchError, reservationDisplayName, handleBatchConfirm} = props;

  const [showDocumentsModal, setShowDocumentsModal] = React.useState(false);

  const getPickMethod = (): string => {
    const pickMethod = batchData.batchAttributes.filter((attr) => attr.name === 'pick_method');
    return pickMethod.length === 1 ? PickMethodDisplay.get(pickMethod[0].value) : '--';
  };

  const getPicker = (): string => {
    switch (batchData.processStatus) {
      case TransitionState.InProgress:
      case TransitionState.Completed:
        return batchData.metadata && batchData.metadata.pickStartedBy ? batchData.metadata.pickStartedBy : '--';
      default:
        return '--';
    }
  };

  const getPickClock = (): string => {
    if (batchData.metadata && batchData.metadata.pickStartedAt) {
      switch (batchData.processStatus) {
        case TransitionState.InProgress:
        case TransitionState.Completed:
          return batchData.packStartedAt
            ? moment.utc(batchData.metadata.pickStartedAt).from(batchData.packStartedAt, true)
            : moment.utc(batchData.metadata.pickStartedAt).fromNow(true);
      }
    }
    return '--';
  };

  const getPickStartTime = (): string => {
    if (batchData.metadata && batchData.metadata.pickStartedAt) {
      switch (batchData.processStatus) {
        case TransitionState.InProgress:
        case TransitionState.Completed:
          return moment
            .utc(batchData.metadata.pickStartedAt)
            .tz(moment.tz.guess())
            .format('hh:mm a');
      }
    }
    return '--';
  };

  const getCarrierOrServiceType = (): string => {
    if (batchData.serviceTypes && batchData.serviceTypes.length > 0) {
      return batchData.serviceTypes.join(', ');
    } else if (batchData.waveParameters) {
      if (batchData.waveParameters.serviceTypes && batchData.waveParameters.serviceTypes.length > 0) {
        return batchData.waveParameters.serviceTypes[0];
      } else if (batchData.waveParameters.carriers) {
        const carrier = batchData.waveParameters.carriers[0];
        return carriers[carrier];
      }
    }
    return '--';
  };

  const getCreatedAt = (): string => {
    const createdAt = moment.utc(batchData.created);
    if (createdAt.isSame(new Date(Date.now()), 'day')) {
      return format(batchData.created, 'hh:mm a');
    }
    return createdAt.fromNow();
  };

  const renderPrintButton = (document: BatchDocument) => {
    return (
      <FlexeLinkButton
        href={`/wh/logged_document/${document.id}`}
        level={document.views.length > 0 ? null : 'primary'}
        title={`1 document(s), opened ${document.views.length} times`}
        newTab
      >
        Print
      </FlexeLinkButton>
    );
  };

  const renderOpenModalPrintButton = (documents: BatchDocument[]) => {
    const isPTLG = batchData.batchAttributes.some((attribute) => {
      return attribute.name === 'pack_time_label_generation' && attribute.value;
    });
    let minViews = Number.MAX_SAFE_INTEGER;
    documents.forEach((document) => {
      // if a batch is PTLG, then the warehouse doesn't need to reprint the split-order-xxx.pdf documents.
      // So we are excluding it from affecting whether a batch's documents are all printed or not
      const shouldBeCounted = (isPTLG && !document.name.startsWith('split-order')) || !isPTLG;
      if (shouldBeCounted && document.views.length < minViews) {
        minViews = document.views.length;
      }
    });
    return (
      <FlexeButton
        text="Print"
        handleClick={() => setShowDocumentsModal(true)}
        level={minViews > 0 ? null : 'primary'}
        title={`${documents.length} document(s), opened ${minViews} times`}
      />
    );
  };

  const renderDocumentModal = (data: BatchData) => {
    const docDivs = data.documents.map((document) => {
      return (
        <div key={`batch_${data.id}_document_${document.id}`} className="document-modal-row row">
          <div className="col-md-9">
            <p>{document.name}</p>
            <span className="title">Created At: </span>
            <span className="value">
              {moment
                .utc(document.createdAt)
                .tz(moment.tz.guess())
                .format('MM/DD/YYYY h:mm a (z)')}
            </span>
          </div>
          <div className="col-md-3 align-right">{renderPrintButton(document)}</div>
        </div>
      );
    });
    return (
      <LegacyModal
        id={`batch-${data.id}-documents-modal`}
        show={showDocumentsModal}
        size="small"
        toggleModal={() => setShowDocumentsModal(false)}
        title={`Batch ${data.id} Documents`}
        transitionSpeed="fast"
      >
        <div>{docDivs}</div>
      </LegacyModal>
    );
  };

  const renderConfirmPrintButton = () => {
    if (batchData.status !== TransitionState.New) {
      if (batchData.documents.length > 0) {
        if (batchData.documents.length === 1) {
          return renderPrintButton(batchData.documents[0]);
        } else {
          return renderOpenModalPrintButton(batchData.documents);
        }
      } else {
        return (
          <FlexeButton text="Generating..." level="processing" title="Documents generating, please wait." isDisabled />
        );
      }
    } else {
      return <FlexeButton text={'Confirm'} handleClick={() => handleBatchConfirm(batchData)} level={'confirm'} />;
    }
  };

  const generalInfoSection = (
    <div className="general-info-section">
      {batchData.paused && (
        <div className="paused-flag">
          <span>Paused</span>
        </div>
      )}
      <span>
        <b>{renderFulfillmentBatch(batchData.id)}</b>
      </span>
      <br />
      <span>{reservationDisplayName}</span>
      <div className="status">
        <span className={`dot ${batchData.processStatus}`}></span>
        <span className="status-text">{TransitionStateDisplay.get(batchData.processStatus)}</span>
      </div>
    </div>
  );

  const progressSection = (
    <div className="progress-section">
      <span className="shipment-numbers">{`${batchData.totalOrderCount} Shipments`}</span>
      <span className="shipment-numbers">{`${
        batchData.processStatus === TransitionState.InProgress
          ? batchData.totalOrderCount - batchData.completedOrderCount
          : 0
      } In Progress`}</span>
      <span className="shipment-numbers complete">{`${batchData.completedOrderCount} ${
        props.usePackedTerms ? 'Packed' : 'Complete'
      }`}</span>
      <BatchProgressBar
        status={batchData.processStatus}
        totalShipments={batchData.totalOrderCount}
        completedShipments={batchData.completedOrderCount}
      />
    </div>
  );

  const pickingInfoSection = (
    <div className="picking-info-section">
      <div className="info-pair">
        <span className="title">Picker</span>
        <br />
        <span className="value">{getPicker()}</span>
      </div>
      <div className="info-pair">
        <span className="title">Method</span>
        <br />
        <span className="value">{getPickMethod()}</span>
      </div>
      <div className="info-pair margin-top">
        <span className="title">Pick Clock</span>
        <br />
        <span className="value">{getPickClock()}</span>
      </div>
      <div className="info-pair margin-top">
        <span className="title">Start Time</span>
        <br />
        <span className="value">{getPickStartTime()}</span>
      </div>
    </div>
  );

  const packingInfoSection = (
    <div className="packing-info-section">
      <div className="carrier">
        <div className="info-pair">
          <span className="title">Carrier</span>
          <br />
          <span className="value">{getCarrierOrServiceType()}</span>
        </div>
        <div className="info-pair margin-top">
          <span className="title">Created At</span>
          <br />
          <span className="value">{getCreatedAt()}</span>
        </div>
      </div>
      <div className="functions">{renderConfirmPrintButton()}</div>
    </div>
  );

  const kebabMenu = (
    <div className="kebab-menu-wrapper">
      <CompactBatchTileKebabMenu
        batch={batchData}
        batchService={props.batchService}
        onBatchCancelled={props.handleBatchCancelled}
      />
    </div>
  );

  return (
    <div className="compact-batch-tile" data-testid="compact-batch-tile-test">
      {generalInfoSection}
      <span className="divider"></span>
      {progressSection}
      <span className="divider"></span>
      {pickingInfoSection}
      <span className="divider"></span>
      {packingInfoSection}
      {kebabMenu}
      {renderDocumentModal(batchData)}
    </div>
  );
};

export default CompactBatchTile;
