import * as React from 'react';
import * as qs from 'query-string';
import {Link, RouteProps} from 'react-router-dom';
import {cloneDeep, get} from 'lodash';
import {addDays, format as formatDate, parse as parseDate} from 'date-fns';
import {Loader, Pagination, Table, TableHeader} from '@flexe/ui-components';
import EdiFilesServiceV2 from '../edi-files/EdiFilesServiceV2';
import FileStorageService from '../file-storage/FileStorageService';
import WarehouseService from '../../shared/services/WarehouseService';
import EdiFilesService from '../edi-files/EdiFilesService';
import Filters from '../shared-v2/Filters';
import {UploadParams} from '../edi-files/EdiFilesInterfaces';
import {Company, FilterOption, FilterType, Packaging} from '../../shared/CommonInterfaces';
import {OMSFeatureFlags, OMSReservation, reservationsToOMSReservations} from '../ecommerce-orders/OrdersAppInterfaces';
import {
  OrderLine,
  OrderStatus,
  OrderStatusDisplay,
  OrdersViewType,
  OrderType,
  OutboundOrder
} from './OutboundOrdersInterfaces';
import OutboundOrdersService from './OutboundOrdersService';
import OrderLineDetailModal from './modals/OrderLineDetailModal';
import ConfirmCancelLineModal from './modals/ConfirmLineCancelModal';
import OutboundOrdersUploadModal from './modals/OutboundOrdersUploadModal';
import {
  csvCreateFileUploadTypeV1,
  csvCreateFileUploadTypeV2,
  csvCreateHeadersV1,
  csvCreateHeadersV2,
  csvCreateOrderByLotHeaders
} from './helpers/OutboundOrderCsv';
import OutboundOrdersBulkCancelModal from './modals/OutboundOrdersBulkCancelModal';
import ReservationsService from './ReservationsService';
import {Reservation} from './ReservationsInterfaces';
import {getOrderLineStatusDisplayLabel, isLineCancelable} from './helpers/OrderLines';

const tsFormat = 'MM/DD/YY h:mma';

export interface ServiceTypes {
  [index: string]: string[];
}

export interface Props {
  authenticityToken: string;
  currentCompany: Company;
  omsReservations: OMSReservation[];
  ediFilesService?: EdiFilesService;
  outboundOrdersService?: OutboundOrdersService;
  shipperHasOmniReservation: boolean;
  v2EdiFilesService?: EdiFilesServiceV2;
  warehouseService?: WarehouseService;
  fileStorageService?: FileStorageService;
  distributionByLotReservationIds: string[];
  featureFlags: OMSFeatureFlags;
}

interface State {
  continuationTokens: string[];
  errors: any;
  currentPage: number;
  filters: {
    [index: string]: any;
  };
  loading: boolean;
  showConfirmCancelLinesModal: boolean;
  showOrderLineModal: boolean;
  selectedOrderLineToView: OrderLine;
  selectedOrderLineIds: string[];
  viewType: OrdersViewType;
  orders: OutboundOrder[];
  lines: OrderLine[];
  total: number;
  reservations: Reservation[];
  reservationOptions: FilterOption[];
  carriers: string[];
  serviceTypes: ServiceTypes;
  showUploadModal: boolean;
  showUploadInstructions: boolean;
  uploadParams: UploadParams;
  fileSelectErrors: any;
  uploadFileType: string;
  totalCount: number;
  show: boolean;
  showBulkCancellationModal: boolean;
}

class OutboundOrders extends React.Component<RouteProps & Props, State> {
  private outboundOrdersService: OutboundOrdersService;
  private templatePath: string;
  private ediFilesService: EdiFilesService;
  private reservationsService: ReservationsService;
  private v2EdiFilesService: EdiFilesServiceV2;
  private fileStorageService: FileStorageService;
  private warehouseService: WarehouseService;

  constructor(props) {
    super(props);
    this.ediFilesService = props.ediFilesService || new EdiFilesService(props.authenticityToken);
    this.outboundOrdersService = props.outboundOrdersService || new OutboundOrdersService();
    this.reservationsService = new ReservationsService();
    this.state = {
      continuationTokens: [],
      currentPage: 1,
      errors: null,
      filters: {},
      loading: true,
      showConfirmCancelLinesModal: false,
      showOrderLineModal: false,
      selectedOrderLineToView: null,
      selectedOrderLineIds: [],
      viewType: OrdersViewType.orders,
      orders: [],
      lines: [],
      total: 0,
      carriers: [],
      serviceTypes: {},
      showUploadModal: false,
      showUploadInstructions: false,
      uploadParams: {file: {}},
      fileSelectErrors: null,
      uploadFileType: 'outbound_order_csv_v2',
      totalCount: 0,
      show: false,
      showBulkCancellationModal: false,
      reservations: [],
      reservationOptions: []
    };
    this.templatePath =
      props.distributionByLotReservationIds.length > 0
        ? '/static/files/OutboundOrderByLotBulkCreate.csv'
        : '/static/files/OutboundOrderBulkCreateV2.csv';
    this.v2EdiFilesService = props.v2EdiFilesService || new EdiFilesServiceV2();
    this.fileStorageService = props.fileStorageService || new FileStorageService();
    this.warehouseService = props.warehouseService || new WarehouseService(props.authenticityToken);
  }

  public async componentDidMount() {
    const injectInitialQueryParams = (params: {[key: string]: any}) => {
      const queryParamNames = Object.keys(params).filter((paramName: string) => paramName !== 'viewType');
      if (OrdersViewType.orders !== this.extractViewType(params) || queryParamNames.length > 0) {
        return params;
      }

      return {
        ...this.initialFilterParams,
        ...params
      };
    };

    this.loadServiceTypes();

    const queryString = window.location.search || '';
    const queryParams = injectInitialQueryParams(qs.parse(queryString));
    this.loadFromQueryParams(queryParams);

    window.onpopstate = () => this.loadFromQueryParams(window.history.state);
  }

  private oneMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1));
  private initialFilterParams = {createdAtFrom: this.oneMonthAgo.toJSON()};

  public render() {
    const {
      errors,
      lines,
      loading,
      selectedOrderLineToView,
      selectedOrderLineIds,
      showConfirmCancelLinesModal,
      showOrderLineModal,
      viewType,
      showBulkCancellationModal
    } = this.state;
    const {authenticityToken} = this.props;
    const tableClass = 'table-striped outbound-orders-shared-table';
    const tableData = viewType === OrdersViewType.orders ? this.getOrdersTableData() : this.getLinesTableData();

    const distributionByLotShipper = this.props.distributionByLotReservationIds.length > 0;
    const csvHeaders = distributionByLotShipper ? csvCreateOrderByLotHeaders : csvCreateHeadersV2;

    return (
      <div className="container-fluid fulfillment-index outbound-orders">
        {errors && errors.length > 0 && (
          <div className="alert alert-danger space-above space-below">
            <ul>
              {' '}
              {errors.map((e, i) => (
                <li key={i}>{e}</li>
              ))}{' '}
            </ul>
          </div>
        )}
        <div className="row">
          <div className="col-sm-6">
            <h1>Outbound Orders</h1>
          </div>
          <div className="col-sm-6">
            {
              <button className="pull-right match-other-btns" onClick={this.toggleUploadModal}>
                <i className="fa fa-file-text"></i> CSV Import
              </button>
            }
            <a href="/s/fulfillment/ecommerce" className="btn pull-right">
              View Shipments
            </a>
            {this.shouldNewButtonBeShown() && (
              <a href={'/s/fulfillment/orders/new'} className="btn pull-right" id="new-manual-request">
                <i className="fa fa-plus"></i>
                &nbsp; Create New Order
              </a>
            )}
            {this.props.shipperHasOmniReservation && (
              <a href="/s/fulfillment/omnichannel/orders/new" className="btn pull-right">
                <i className="fa fa-plus" />
                &nbsp; Create New Order
              </a>
            )}
            {selectedOrderLineIds && selectedOrderLineIds.length > 0 && (
              <button className="btn cta pull-right" onClick={this.cancelOrderLines}>
                Cancel Lines
              </button>
            )}
            {(this.outboundOrdersService.isAdmin() || this.props.featureFlags.showOrderCancellationByCsvButton) && (
              <button className="pull-right match-other-btns" onClick={this.toggleBulkCancellationModal}>
                Cancel Orders/Lines by CSV
              </button>
            )}
          </div>
        </div>
        <div id="fulfillment-component">
          <div className="row filters">
            <div className="btn-group view-toggle">
              <a
                id="orders-toggle"
                className={`btn ${viewType === OrdersViewType.orders ? 'cta' : 'secondary'}`}
                onClick={this.toggleViewType}
              >
                <i className="fa fa-shopping-cart"></i>
                &nbsp; Orders
              </a>
              <a
                id="lines-toggle"
                className={`btn ${viewType === OrdersViewType.lines ? 'cta' : 'secondary'}`}
                onClick={this.toggleViewType}
              >
                <i className="fa fa-list-ul"></i>
                &nbsp; Lines
              </a>
            </div>
            {!loading && viewType === OrdersViewType.orders && (
              <Filters
                filters={this.getOrdersFilters()}
                filterChangeHandler={(filters) => this.handleFilterChange(filters)}
              />
            )}
            {!loading && viewType === OrdersViewType.lines && (
              <Filters
                filters={this.getLinesFilters()}
                filterChangeHandler={(filters) => this.handleFilterChange(filters)}
              />
            )}
          </div>
          {!loading && tableData.rows.length > 0 && <Table tableClass={tableClass} tableData={tableData} />}
          {!loading && tableData.rows.length === 0 && <p>No {viewType} matching the current selection.</p>}
          <Loader loading={loading} />
          {showConfirmCancelLinesModal && (
            <ConfirmCancelLineModal
              lines={lines.filter((l) => selectedOrderLineIds.includes(l.id))}
              toggleShowModal={this.toggleCancelModal}
              cancelLines={this.cancelOrderLines}
            />
          )}
          {showOrderLineModal && (
            <OrderLineDetailModal
              line={selectedOrderLineToView}
              authenticityToken={authenticityToken}
              currentCompany={this.props.currentCompany}
              reservations={reservationsToOMSReservations(
                this.state.reservations,
                this.props.distributionByLotReservationIds
              )}
              toggleOrderLineModal={this.toggleOrderLineModal}
              reloadOrderLine={this.reloadOrderLine}
              distributionByLotReservationIds={this.props.distributionByLotReservationIds}
              showCalculatedShipByDate={this.props.featureFlags.showCalculatedShipByDate}
            />
          )}
        </div>
        <OutboundOrdersUploadModal
          csvTemplate={{filepath: this.templatePath, displayName: 'CSV template'}}
          distributionByLotShipper={distributionByLotShipper}
          serviceTypes={this.state.serviceTypes}
          carriers={this.state.carriers}
          showUploadModal={this.state.showUploadModal}
          headers={csvHeaders}
          fileUploadType={csvCreateFileUploadTypeV2}
          fileStorageService={this.fileStorageService}
          ediFilesServiceV2={this.v2EdiFilesService}
          // TODO: delete oldRequiredHeaders once old template has been retired
          oldHeaders={csvCreateHeadersV1}
          oldFileUploadType={csvCreateFileUploadTypeV1}
          toggleUploadModal={this.toggleUploadModal}
        />
        <OutboundOrdersBulkCancelModal
          currentCompany={this.props.currentCompany}
          showUploadModal={showBulkCancellationModal}
          toggleUploadModal={this.toggleBulkCancellationModal}
          authenticityToken={this.props.authenticityToken}
        />
      </div>
    );
  }

  // private

  private fetchResults = async () => {
    let ordersResponse;
    let orderLinesResponse;
    let reservationsResponse;
    this.setState({loading: true});

    const continuationToken =
      this.state.currentPage > 1 ? this.state.continuationTokens[this.state.currentPage - 2] : null;
    if (this.state.viewType === OrdersViewType.orders) {
      [ordersResponse, reservationsResponse] = await Promise.all([
        this.outboundOrdersService.getOrders(this.state.filters, continuationToken),
        this.reservationsService.getReservations()
      ]);
    } else {
      [orderLinesResponse, reservationsResponse] = await Promise.all([
        this.outboundOrdersService.getLines(this.state.filters, continuationToken),
        this.reservationsService.getReservations()
      ]);
    }

    const orderErrors = this.processOrdersResponse(ordersResponse || orderLinesResponse);
    const resErrors = this.processReservationsResponse(reservationsResponse);
    const errors = [...orderErrors, ...resErrors];

    this.setState({errors, loading: false});
  };

  private processOrdersResponse = (ordersResponse) => {
    let errors = [];
    if (ordersResponse && (!ordersResponse.errors || ordersResponse.errors.length === 0)) {
      const state = {
        continuationTokens: [get(ordersResponse, 'continuationToken')],
        total: get(ordersResponse, 'total')
      } as any;
      if (this.state.viewType === OrdersViewType.orders) {
        state.orders = get(ordersResponse, 'outboundOrders') || ([] as OutboundOrder[]);
      } else {
        let lines = get(ordersResponse, 'lines') || ([] as OrderLine[]);
        lines = this.outboundOrdersService.assignOrderLineState(lines);
        state.lines = lines;
      }
      this.setState({...state});
    } else if (ordersResponse && ordersResponse.errors) {
      errors = this.outboundOrdersService.processErrors(ordersResponse.errors);
    }
    return errors;
  };

  private processReservationsResponse = (reservationData) => {
    let errors = [];
    if (reservationData && reservationData.data && reservationData.data.reservations) {
      const placeholderOption: FilterOption[] = [
        {
          displayName: 'Choose a reservation...',
          value: ''
        }
      ];
      const options: FilterOption[] = reservationData.data.reservations
        .filter((res) => {
          return res.txn_state !== 'new';
        })
        .map((res) => {
          return {
            displayName: `${res.id}: ${res.warehouse.name}, ${res.warehouse.address.locality}`,
            value: res.id.toString()
          };
        });

      const allOptions = placeholderOption.concat(options).sort((a, b) => {
        return a.value > b.value ? 1 : a.value < b.value ? -1 : 0;
      });
      this.setState({reservations: reservationData.data.reservations, reservationOptions: allOptions});
    } else {
      errors = [
        'Error fetching reservations. Filtering by reservations will be unavailable. Please check your connection ' +
          'and refresh. Contact Flexe if the error persists.'
      ].concat(this.state.errors);
      this.setState({errors});
    }
    return errors;
  };

  private reloadOrderLine = async () => {
    let errors;
    const line = this.state.selectedOrderLineToView;
    const response = await this.outboundOrdersService.getLines({lineIds: [line.id]}, null, 1, line.orderId);
    if (response && (!response.errors || response.errors.length === 0)) {
      let lines = get(response, 'lines');
      if (lines) {
        lines = this.outboundOrdersService.assignOrderLineState(lines);
      }
      this.setState({selectedOrderLineToView: lines[0]});
    } else if (response.errors) {
      errors = this.outboundOrdersService.processErrors(response.errors);
      this.setState({selectedOrderLineToView: null});
    }
    this.setState({errors});
  };

  private extractViewType = (params) => {
    return get(params, 'viewType') && params.viewType === OrdersViewType.lines
      ? OrdersViewType.lines
      : OrdersViewType.orders;
  };

  private serializeLabelFilters = (filterObj) => {
    if (filterObj.labels) {
      filterObj.labels = [].concat(filterObj.labels).map((filterLabelObj) => qs.stringify(filterLabelObj));
    }
    return filterObj;
  };

  private deserializeLabelFilters = (filterObj) => {
    if (filterObj.labels) {
      filterObj.labels = [].concat(filterObj.labels).map((kv) => {
        const kvPair = kv.split(',');
        const labelKey = kvPair[0];
        const labelValue = kvPair[1];
        return {labelKey, labelValue};
      });
    }
    return filterObj;
  };

  private async loadFromQueryParams(params: object) {
    this.setState({viewType: this.extractViewType(params)}, () =>
      this.setState({filters: this.extractFilters(params)}, () => {
        this.fetchResults();
        if (this.state.filters && this.state.filters.labels) {
          // deserialize from the query string so that filter can be rendered properly
          const newFilters = this.deserializeLabelFilters(this.state.filters);
          this.setState({filters: newFilters});
        }
      })
    );
  }

  private extractFilters = (params) => {
    const filters = this.state.viewType === OrdersViewType.orders ? this.getOrdersFilters() : this.getLinesFilters();
    const validFilterKeys = filters.map((f) => f.key);
    if (params) {
      Object.keys(params).forEach((f) => {
        if (!validFilterKeys.includes(f) && f !== 'viewType') {
          delete params[f];
        } else if (f === 'hasErrors') {
          params[f] = params[f] === 'true' || params[f] === true;
        }
      });
      window.history.replaceState(params, '', `${window.location.pathname}?${qs.stringify(params)}`);
      const filtersToApply = params;
      if (filtersToApply.viewType) {
        delete filtersToApply.viewType;
      }
      return filtersToApply;
    } else {
      return params;
    }
  };

  private toggleViewType = () => {
    const viewType = this.state.viewType === OrdersViewType.orders ? OrdersViewType.lines : OrdersViewType.orders;

    this.setState({
      continuationTokens: [],
      currentPage: 1,
      viewType,
      filters: {},
      orders: [],
      lines: [],
      selectedOrderLineIds: []
    });

    this.loadFromQueryParams({
      ...this.initialFilterParams,
      ...{viewType}
    });
  };

  private toggleCancelModal = () => {
    this.setState({
      showConfirmCancelLinesModal: !this.state.showConfirmCancelLinesModal
    });
  };

  private toggleOrderLineModal = () => {
    this.setState({showOrderLineModal: !this.state.showOrderLineModal});
  };

  private selectOrderLineToView = (event) => {
    const lineId = event.currentTarget.getAttribute('data-id');
    const selectedOrderLineToView = this.state.lines.find((l) => l.id === lineId);
    if (selectedOrderLineToView) {
      this.setState({selectedOrderLineToView, showOrderLineModal: true});
    }
  };

  private cancelOrderLines = async () => {
    let errors = [];
    await Promise.all(
      this.state.selectedOrderLineIds.map(async (lineId) => {
        const l = this.state.lines.find((line) => line.id === lineId);
        // We check cancelable here because the select all checkbox makes the assumption that all lines are checked
        //  There are better ways to do this, but we'll worry about that later.
        if (l && isLineCancelable(l)) {
          const response = await this.outboundOrdersService.cancelOrderLine(l.orderId, l.id);
          if (response && response.errors && response.errors.length > 0) {
            const newErrors = this.outboundOrdersService.processErrors(response.errors);
            errors = errors.concat(newErrors);
          }
        }
      })
    );
    if (errors.length === 0) {
      this.setState({selectedOrderLineIds: []}, this.fetchResults);
    }
    this.setState({errors, showConfirmCancelLinesModal: false});
  };

  private toggleBulkCancellationModal = () => {
    this.setState({showBulkCancellationModal: !this.state.showBulkCancellationModal});
  };

  private getOrdersTableData() {
    const headers = [
      {
        className: 'issue-header header-icon',
        element: <i className="fa fa-exclamation-triangle red3"></i>
      },
      {className: 'id-header header-9pct', element: 'FLEXE Order ID'},
      {className: 'header-14pct', element: 'External Order ID'},
      {className: 'header-7pct', element: 'Status'},
      {className: 'header-15pct', element: 'Reservation'},
      {className: 'header-18pct', element: 'Name'},
      {className: 'header-18pct', element: 'Address'},
      {className: 'header-9pct', element: 'Created'},
      {
        className: 'pagination-header header-remainder',
        element: (
          <Pagination
            page={this.state.currentPage}
            pageSize={50}
            paginationHandler={(page) => this.handlePagination(page)}
            totalCount={this.state.total}
          />
        )
      }
    ] as TableHeader[];
    const rows = this.buildOrdersRows();
    return {
      headers,
      rows
    };
  }

  private buildOrdersRows() {
    if (this.state.orders && this.state.orders.length > 0) {
      const rows = this.state.orders.map((order: OutboundOrder) => {
        const url = `/s/fulfillment/orders/${order.id}`;
        const row = [
          order.hasErrors ? <i className="fa fa-exclamation-triangle red3"></i> : null,
          <Link to={url}>{order.id}</Link>,
          <div>{order.externalId}</div>,
          <div className={`label label-default label-div subtle-hover ${order.state}`}>
            {OrderStatusDisplay[order.state]}
          </div>,
          <div>{this.getReservationToDisplay(order.requestedReservationIds, order.plannedReservationIds)}</div>,
          <div>{order.recipient.name}</div>,
          <div>{order.recipient.address.line1}</div>,
          <div>{order.createdAt ? formatDate(order.createdAt, tsFormat) : null}</div>,
          <Link className="pull-right" to={url}>
            <i className="fa fa-chevron-right"></i>
          </Link>
        ];
        return row;
      });
      return rows;
    }
    return [];
  }

  private getReservationToDisplay(requestedReservationIds: string[], plannedReservationIds: string[]) {
    requestedReservationIds = requestedReservationIds || [];
    plannedReservationIds = plannedReservationIds || [];
    const reservations = new Set([...requestedReservationIds, ...plannedReservationIds]);

    if (reservations.size > 1) {
      return <i>multiple</i>;
    } else if (reservations.size === 1) {
      return this.getDisplayNameForReservation(reservations.values().next().value);
    } else {
      return '-';
    }
  }

  private getDisplayNameForReservation(reservationId: string) {
    let displayName;
    this.state.reservationOptions.forEach((value) => {
      if (value.value === reservationId) {
        displayName = value.displayName;
      }
    });
    // fallback to id just in case something went wrong instead of displaying nothing
    return displayName ? displayName : reservationId;
  }

  private getOrdersFilters() {
    const availableFilters = [
      {
        displayName: 'External Order ID',
        key: 'externalIds',
        type: FilterType.String,
        value: this.getFilterValue('externalIds'),
        allowMultiple: true
      },
      {
        displayName: 'FLEXE Order ID',
        key: 'ids',
        type: FilterType.String,
        value: this.getFilterValue('ids'),
        allowMultiple: true
      },
      {
        displayName: 'Order Status',
        key: 'state',
        type: FilterType.Dropdown,
        options: [
          {
            displayName: 'Choose a status...',
            value: ''
          },
          {
            displayName: OrderStatusDisplay[OrderStatus.open],
            value: OrderStatus.open
          },
          {
            displayName: OrderStatusDisplay[OrderStatus.closed],
            value: OrderStatus.closed
          }
        ],
        value: this.getFilterValue('state')
      },
      {
        displayName: 'Order Type',
        key: 'orderType',
        type: FilterType.Dropdown,
        options: [
          {
            displayName: 'Choose an order type...',
            value: ''
          },
          {
            displayName: OrderType.distribution,
            value: OrderType.distribution
          },
          {
            displayName: OrderType.ecommerce,
            value: OrderType.ecommerce
          }
        ],
        value: this.getFilterValue('orderType')
      },
      {
        displayName: 'Reservation',
        key: 'reservationIds',
        type: FilterType.Dropdown,
        options: this.state.reservationOptions,
        allowMultiple: true,
        value: this.getFilterValue('reservationIds')
      },
      {
        displayName: 'Created (Exact)',
        key: 'createdAt',
        type: FilterType.Date,
        value: this.getFilterValue('createdAt')
      },
      {
        displayName: 'Created Starting',
        key: 'createdAtFrom',
        type: FilterType.Date,
        value: this.getFilterValue('createdAtFrom')
      },
      {
        displayName: 'Created Ending',
        key: 'createdAtTo',
        type: FilterType.Date,
        value: this.getFilterValue('createdAtTo')
      },
      {
        displayName: 'Updated Starting',
        key: 'updatedAtFrom',
        type: FilterType.Date,
        value: this.getFilterValue('updatedAtFrom')
      },
      {
        displayName: 'Updated Ending',
        key: 'updatedAtTo',
        type: FilterType.Date,
        value: this.getFilterValue('updatedAtTo')
      },
      {
        displayName: 'Issues Only',
        key: 'hasErrors',
        type: FilterType.Present,
        value: this.getFilterValue('hasErrors')
      },
      {
        displayName: 'Labels',
        key: 'labels',
        type: FilterType.LabelSearch,
        allowMultiple: true,
        value: this.getFilterValue('labels'),
        defaultKey: this.getDefaultLabelSearchKey()
      }
    ];
    return availableFilters;
  }

  private getDefaultLabelSearchKey() {
    // Preserve behavior for companies previously using the feature flag
    // TODO OMS-12755 remove company id from front end
    if (this.props.currentCompany.id === 3379) {
      return 'shopify_order_id';
    } else {
      return 'shopify_order_number';
    }
  }

  private getLinesTableData() {
    const headers = [
      {
        className: 'select-header header-icon',
        element: (
          <input
            type="checkbox"
            checked={
              this.state.selectedOrderLineIds && this.state.selectedOrderLineIds.length === this.state.lines.length
            }
            onChange={this.handleSelectAllLines}
          />
        )
      },
      {
        className: 'issue-header header-icon',
        element: <i className="fa fa-exclamation-triangle red3"></i>
      },
      {className: 'id-header header-9pct', element: 'FLEXE Line ID'},
      {className: 'header-14pct', element: 'External Line ID'},
      {className: 'id-header header-9pct', element: 'FLEXE Order ID'},
      {className: 'header-12pct', element: 'Status'},
      {className: 'header-15pct', element: 'Reservation'},
      {className: 'header-10pct', element: 'SKU'},
      {className: 'header-7pct', element: 'Quantity'},
      {className: 'header-5pct', element: 'Unit of Measure'},
      {className: 'header-9pct', element: 'Created'},
      {
        className: 'pagination-header header-remainder',
        element: (
          <Pagination
            page={this.state.currentPage}
            pageSize={50}
            paginationHandler={(page) => this.handlePagination(page)}
            totalCount={this.state.total}
          />
        )
      }
    ] as TableHeader[];
    const rows = this.buildLinesRows();
    return {
      headers,
      rows
    };
  }

  private buildLinesRows() {
    if (this.state.lines && this.state.lines.length > 0) {
      const rows = this.state.lines.map((line: OrderLine) => {
        const ordersUrl = `/s/fulfillment/orders/${line.orderId}`;
        const row = [
          isLineCancelable(line) ? (
            <input
              type="checkbox"
              checked={this.state.selectedOrderLineIds.indexOf(line.id) > -1}
              data-line-id={line.id}
              onChange={this.handleSelectOrderLine}
            />
          ) : null,
          line.hasErrors ? <i className="fa fa-exclamation-triangle red3"></i> : null,
          <a data-id={line.id} onClick={this.selectOrderLineToView}>
            {line.id}
          </a>,
          <div>{line.externalId}</div>,
          <Link to={ordersUrl}>{line.orderId}</Link>,
          getOrderLineStatusDisplayLabel(line),
          <div>{this.getReservationToDisplay(line.requestedReservationIds, line.plannedReservationIds)}</div>,
          <div>{line.sku}</div>,
          <div>{line.quantity}</div>,
          <div>{line.unitOfMeasure || Packaging.each}</div>,
          <div>{line.createdAt ? formatDate(line.createdAt, tsFormat) : null}</div>,
          <a data-id={line.id} onClick={this.selectOrderLineToView} className="pull-right">
            <i className="fa fa-chevron-right"></i>
          </a>
        ];
        return row;
      });
      return rows;
    }
    return [];
  }

  private handleSelectOrderLine = async (event) => {
    const target = event.currentTarget;
    const checked = target.checked;
    const lineId = target.getAttribute('data-line-id');
    const selectedOrderLineIds = cloneDeep(this.state.selectedOrderLineIds);
    if (checked) {
      selectedOrderLineIds.push(lineId);
    } else {
      const index = selectedOrderLineIds.indexOf(lineId);
      selectedOrderLineIds.splice(index, 1);
    }
    this.setState({selectedOrderLineIds});
  };

  private handleSelectAllLines = (event) => {
    this.setState({
      selectedOrderLineIds: event.currentTarget.checked ? this.state.lines.map((l) => l.id) : []
    });
  };

  private getLinesFilters() {
    return [
      {
        displayName: 'External Line ID',
        key: 'externalIds',
        type: FilterType.String,
        value: this.getFilterValue('externalIds'),
        allowMultiple: true
      },
      {
        displayName: 'FLEXE Line ID',
        key: 'lineIds',
        type: FilterType.String,
        value: this.getFilterValue('ids'),
        allowMultiple: true
      },
      // {
      //   displayName: 'Status',
      //   key: 'status',
      //   type: FilterType.Dropdown,
      //   options: [
      //     {
      //       displayName: 'Choose a status...',
      //       value: ''
      //     },
      //     {
      //       displayName: OrderLineStatusDisplay[OrderLineStatus.unallocated],
      //       value: OrderLineStatus.unallocated
      //     },
      //     {
      //       displayName: OrderLineStatusDisplay[OrderLineStatus.allocated],
      //       value: OrderLineStatus.allocated
      //     },
      //     {
      //       displayName: OrderLineStatusDisplay[OrderLineStatus.partiallyShipped],
      //       value: OrderLineStatus.partiallyShipped
      //     },
      //     {
      //       displayName: OrderLineStatusDisplay[OrderLineStatus.shipped],
      //       value: OrderLineStatus.shipped
      //     },
      //     {
      //       displayName: OrderLineStatusDisplay[OrderLineStatus.overshipped],
      //       value: OrderLineStatus.overshipped
      //     },
      //     {
      //       displayName: OrderLineStatusDisplay[OrderLineStatus.cancelled],
      //       value: OrderLineStatus.cancelled
      //     },
      //   ]
      // },
      {
        displayName: 'Reservation',
        key: 'reservationIds',
        type: FilterType.Dropdown,
        options: this.state.reservationOptions,
        allowMultiple: true,
        value: this.getFilterValue('reservationIds')
      },
      {
        displayName: 'SKU',
        key: 'skus',
        type: FilterType.String,
        value: this.getFilterValue('skus'),
        allowMultiple: true
      },
      {
        displayName: 'FLEXE Order ID',
        key: 'orderIds',
        type: FilterType.String,
        value: this.getFilterValue('orderIds'),
        allowMultiple: true
      },
      {
        displayName: 'Issues Only',
        key: 'hasErrors',
        type: FilterType.Present,
        value: this.getFilterValue('hasErrors')
      },
      {
        displayName: 'Labels',
        key: 'labels',
        type: FilterType.LabelSearch,
        allowMultiple: true,
        value: this.getFilterValue('labels'),
        defaultKey: ''
      }
    ];
  }

  private getFilterValue(key: string) {
    const {filters} = this.state;
    if (filters && ![null, undefined].includes(filters[key])) {
      if (key === 'hasErrors') {
        return filters[key] === true ? 'true' : 'false';
      } else {
        return filters[key];
      }
    }
    return null;
  }

  private async handleFilterChange(filters) {
    let errors;
    this.setState({loading: true});
    const filterValues: any = {};
    const valuesForLabelFilter = new Set<string>();
    filters.forEach((selected) => {
      const key = selected.filter.key;
      if (key === 'hasErrors') {
        filterValues[key] = selected.value === 'true' ? true : false;
      } else if (selected.filter.allowMultiple) {
        let val = selected.value;
        if (key === 'labels') {
          valuesForLabelFilter.add(JSON.stringify(val));
          val = `${val.labelKey},${val.labelValue}`;
        }
        if (filterValues[key]) {
          if (!filterValues[key].includes(val)) {
            filterValues[key].push(val);
          }
        } else {
          try {
            // perhaps we got a csv of ids
            filterValues[key] = key === 'labels' ? [].concat(val) : val.split(',');
          } catch {
            filterValues[key] = [].concat(val);
          }
        }
      } else if (['createdAt', 'shipAfter', 'shipBefore', 'closedAt'].includes(key)) {
        const localDateStart = parseDate(selected.value);
        const localDateEnd = addDays(localDateStart, 1);
        filterValues[`${key}From`] = localDateStart.toISOString();
        filterValues[`${key}To`] = localDateEnd.toISOString();
      } else if (['createdAt', 'shipAfter', 'shipBefore', 'closedAt', 'updatedAt'].some((f) => !!key.match(f))) {
        const localDate = parseDate(selected.value);
        // inclusive start, exclusive end so offset by 1 day
        const filterDate = ['createdAtTo', 'shipAfterTo', 'shipBeforeTo', 'closedAtTo', 'updatedAtTo'].includes(key)
          ? addDays(localDate, 1)
          : localDate;
        filterValues[key] = filterDate.toISOString();
      } else {
        filterValues[key] = selected.value;
      }
    });
    const response = await this.outboundOrdersService[`get${this.state.viewType}`](filterValues);
    const params = filterValues;
    params.viewType = this.state.viewType;
    window.history.pushState(params, '', `${window.location.pathname}?${qs.stringify(params)}`);
    if (response && (!response.errors || response.errors.length === 0)) {
      if (filterValues.labels) {
        // Prevents us from displaying duplicate filter badges
        filterValues.labels = Array.from(valuesForLabelFilter).map((v) => JSON.parse(v));
      }
      const state = {
        continuationTokens: [get(response, 'continuationToken')],
        currentPage: 1,
        filters: filterValues,
        total: get(response, 'total')
      } as any;
      if (this.state.viewType === OrdersViewType.orders) {
        state.orders = get(response, 'outboundOrders') as OutboundOrder[];
      } else {
        let lines = get(response, 'lines') || ([] as OrderLine[]);
        lines = this.outboundOrdersService.assignOrderLineState(lines);
        state.lines = lines;
      }

      this.setState({...state});
    } else if (response.errors) {
      errors = this.outboundOrdersService.processErrors(response.errors);
    }
    this.setState({errors, loading: false});
  }

  private async handlePagination(page: number) {
    let errors;
    this.setState({loading: true, selectedOrderLineIds: []});
    const continuationToken = page > 1 ? this.state.continuationTokens[page - 2] : null;
    const response = await this.outboundOrdersService[`get${this.state.viewType}`](
      this.state.filters,
      continuationToken
    );
    const updatedContinuationTokens = this.state.continuationTokens.slice();
    updatedContinuationTokens.push(get(response, 'continuationToken'));

    if (response && (!response.errors || response.errors.length === 0)) {
      const state = {
        continuationTokens: updatedContinuationTokens,
        currentPage: page,
        total: get(response, 'total')
      } as any;
      if (this.state.viewType === OrdersViewType.orders) {
        state.orders = get(response, 'outboundOrders') as OutboundOrder[];
      } else {
        let lines = get(response, 'lines') || ([] as OrderLine[]);
        lines = this.outboundOrdersService.assignOrderLineState(lines);
        state.lines = lines;
      }

      this.setState({...state});
    } else if (response.errors) {
      errors = this.outboundOrdersService.processErrors(response.errors);
    }
    this.setState({errors, loading: false});
  }

  private shouldNewButtonBeShown() {
    return false;
  }

  // CSV UPLOAD STUFF STARTS HERE
  private toggleUploadModal = () => {
    this.setState({
      showUploadModal: !this.state.showUploadModal
    });
  };

  private loadServiceTypes = async () => {
    const data = await this.ediFilesService.getCarriersAndServiceTypes();
    // this riduculousness is to remove a ® character from the
    // API-returned value for the carrier UPS
    const cleanedServiceTypes = data.data.serviceTypes;
    // eslint-disable-next-line
    cleanedServiceTypes['UPS'] = cleanedServiceTypes['UPS®'];
    delete cleanedServiceTypes['UPS®'];

    this.setState({
      carriers: data.data.carriers,
      serviceTypes: cleanedServiceTypes
    });
  };
}

export default OutboundOrders;
